import { makeStyles, Tooltip, Typography } from "@material-ui/core"
import React, { FC, useCallback, useEffect, useState } from "react"
import { getBucketPath } from "../../graphql/media"
import { useApiService } from "../../providers/ApiServiceProvider"
import { useMediaImages } from "../../store"
import { Media } from "../../types/types"

const useStyles = makeStyles({
    videoCard: {
        height: 154,
        width: 163,
        minHeight: 154,
        minWidth: 163,
        boxShadow: "0px 4px 12px rgba(54, 53, 69, 0.15)",
        "&:hover": {
            boxShadow: "0px 10px 28px rgba(54, 53, 69, 0.15)"
        },
        "&:active": {
            boxShadow: "0px 2px 8px rgba(54, 53, 69, 0.15)"
        }
    },
    imageSection: {
        height: 91,
        width: 163,
        minHeight: 91,
        minWidth: 163,
        backgroundColor: "#D4D3D3"
    },
    textSection: {
        padding: 12,
        textAlign: "left"
    },
    title: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "16px",
        color: "#2F232D",
        marginBottom: 8,
        marginTop: 0,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    showTitle: {
        color: "#6A6068",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }
})

interface Props {
    media: Media
    index: number
}

const VideoCard: FC<Props> = ({ media, index }) => {
    const classes = useStyles()
    const ApiService = useApiService()
    const [mediaImages, setMediaImages] = useMediaImages()
    const [hasData, setHasData] = useState<boolean>(false)

    const getSignedUrl = useCallback(async () => {
        // Delay the load time to avoid a bunch of CORS issues
        console.log(`[VideoCard] media.url ${media.url}`)


        window.setTimeout(async () => {
            if (!media.url) {
                return
            }
            const bucketPath = getBucketPath(media.url)
            const urlResponse = await ApiService.getReadableSignedUrl(`${bucketPath}/${media.image}`, media.type)
            if (!urlResponse) {
                return
            }
            setMediaImages(oldMediaImages => ({
                ...oldMediaImages,
                [media.uuid]: urlResponse.data
            }))
        }, index * 300)
    }, [ApiService, index, media.image, media.type, media.url, media.uuid, setMediaImages])

    useEffect(() => {
        if (!hasData) {
            setHasData(true)
            getSignedUrl()
        }
    }, [getSignedUrl, hasData])

    const handleImageError = (e: any) => {
        const src = "/default_thumbnail.png"
        e.target.src = src
    }
    return (
        <div className={classes.videoCard}>
            {mediaImages?.[media.uuid] ? (
                <img
                    className={classes.imageSection}
                    src={mediaImages?.[media.uuid]}
                    alt="video screenshot"
                    onError={handleImageError}
                />
            ) : (
                <div className={classes.imageSection} />
            )}
            <section className={classes.textSection}>
                <Tooltip title={media.title}>
                    <h6 className={classes.title}>{media.title}</h6>
                </Tooltip>
                <Typography variant="overline" component="p" className={classes.showTitle}>
                    {media.show}
                </Typography>
            </section>
        </div>
    )
}

export default VideoCard
