import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { LoginData, MediaWithArtifact, ResultsVideo, UserData } from "./types/types"

const mediaImages = atom<{ [key: string]: string } | undefined>({
    key: "mediaImages",
    default: undefined
})

export const useMediaImages = () => useRecoilState(mediaImages)

export const useGetMediaImages = () => useRecoilValue(mediaImages)

const resultsVideo = atom<ResultsVideo | undefined>({
    key: "resultsVideo",
    default: undefined
})

export const useResultsVideo = () => useRecoilState(resultsVideo)

export const useGetResultsVideo = () => useRecoilValue(resultsVideo)

export const useSetResultsVideo = () => useSetRecoilState(resultsVideo)

const loginData = atom<LoginData | undefined>({
    key: "loginData",
    default: undefined
})

export const useLoginData = () => useRecoilState(loginData)

export const useGetLoginData = () => useRecoilValue(loginData)

export const userData = atom<UserData | undefined>({
    key: "userData",
    default: undefined
})

const tokenExpired = atom<boolean>({
    key: "tokenExpired",
    default: false
})

export const useTokenExpired = () => useRecoilState(tokenExpired)

export const useGetTokenExpired = () => useRecoilValue(tokenExpired)

export const useSetTokenExpired = () => useSetRecoilState(tokenExpired)

const article = atom<MediaWithArtifact | undefined>({
    key: "article",
    default: undefined
})

export const useArticle = () => useRecoilState(article)

export const useGetArticle = () => useRecoilValue(article)

export const useSetArticle = () => useSetRecoilState(article)

const btInstance = atom<any | undefined>({
    key: "btInstance",
    default: undefined
})

export const useBtInstance = () => useRecoilState(btInstance)

export const useGetBtInstance = () => useRecoilValue(btInstance)

export const useSetBtInstance = () => useSetRecoilState(btInstance)

export const orgSlugState = atom<string>({
    key: "org-slug-state",
    default: "dbmo"
})
