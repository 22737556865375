import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core"
import React, { FC, useState } from "react"
import LinesEllipsis from "react-lines-ellipsis"
import { useHistory } from "react-router"
import { GetMediaVars, useGetArticlePublishers, useGetProcessedMedia } from "../../graphql/media"
import { useSetArticle } from "../../store"
import { Media } from "../../types/types"
import SearchInput from "../chooseVideo/SearchInput"

const useStyles = makeStyles({
    chooseArticle: {
        maxWidth: 724
    },
    titleRow: {
        display: "flex",
        marginBottom: 10,
        alignItems: "center"
    },
    title: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "14px",
        color: "#2F232D",
        flex: 1
    },
    formControl: {
        minWidth: 175,
        marginTop: 15,
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12
    },
    menuItem: {
        fontSize: 14,
        padding: 5
    },
    publisherSelect: {
        paddingLeft: 5
    },
    card: {
        border: 0,
        margin: 0,
        outline: 0,
        backgroundColor: "transparent",
        cursor: "pointer",
        textAlign: "left",
        width: 163,
        height: 146,
        background: "#FFFFFF",
        boxShadow: "0px 4px 12px rgba(54, 53, 69, 0.15)",
        padding: 12,
        "&:hover": {
            boxShadow: "0px 10px 28px rgba(54, 53, 69, 0.15)"
        },
        "&:active": {
            boxShadow: "0px 2px 8px rgba(54, 53, 69, 0.15)"
        }
    },
    cardTitle: {
        height: 80,
        marginBottom: 12
    },
    loading: {
        paddingTop: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    loadingCircle: {
        color: "#004FFF"
    }
})

const ChooseArticle: FC = () => {
    const classes = useStyles()
    const [publisher, setPublisher] = useState<string>("")
    const [searchQuery, setSearchQuery] = useState<string>("")
    const history = useHistory()
    const publishers = useGetArticlePublishers()

    if (!publisher.length && publishers?.length > 0) {
        setPublisher(publishers[0])
    }

    const vars: GetMediaVars = {
        mediaWhereExp: {
            "publisher": { _eq: publisher },
            is_processed: {_eq: true}
        }
    }
    const mediaData = useGetProcessedMedia(publisher ? vars : {})
    const setArticle = useSetArticle()

    const showArticle = (media: Media) =>
        !searchQuery ||
        (searchQuery &&
            (media.title.toLowerCase().includes(searchQuery) ||
                media.season_episode?.toLowerCase().includes(searchQuery) ||
                media.publisher?.toLowerCase().includes(searchQuery)))

    const open = (media: Media) => () => {
        setArticle(media)
        history.push(`/article-results/${media.uuid}`)
    }

    const onPublisherSelect = (selection: string) => {
        if (publishers.includes(selection)) {
            setPublisher(selection)
        }
    }

    return (
        <Box marginTop={3} className={classes.chooseArticle}>
            <div className={classes.titleRow}>
                <h1 className={classes.title}>Choose Article</h1>
                {publishers && publishers.length && (
                    <>
                        <FormControl className={classes.formControl} size="small">
                            <Select
                                value={publisher}
                                classes={{ root: classes.publisherSelect }}
                                onChange={event => onPublisherSelect(event.target.value as string)}
                            >
                                {publishers.map((publisher, index) => (
                                    <MenuItem key={index} value={publisher} className={classes.menuItem}>
                                        {publisher}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText style={{ marginTop: 0 }}>Publisher</FormHelperText>
                        </FormControl>
                    </>
                )}
            </div>
            <Box marginBottom={3}>
                <SearchInput placeholder="Search" setSearchQuery={setSearchQuery} />
            </Box>
            {mediaData.data ? (
                <Grid container={true} spacing={3}>
                    {mediaData.data.media.map(media => {
                        if (showArticle(media)) {
                            return (
                                <Grid item={true} key={`media choice ${media.uuid}`}>
                                    <button className={classes.card} onClick={open(media)}>
                                        <div className={classes.cardTitle}>
                                            <Typography variant="caption" style={{ fontWeight: 600 }}>
                                                <LinesEllipsis text={media.title} maxLine={5} basedOn="letters" />
                                            </Typography>
                                        </div>
                                        <div style={{ marginBottom: 4 }}>
                                            <Typography variant="overline" style={{ color: "#6A6068" }}>
                                                {media.season_episode}
                                            </Typography>
                                        </div>
                                        <Typography variant="overline">{media.publisher}</Typography>
                                        {/* <Badge
                                            badgeContent={media.type.toUpperCase().substring(0, 1)}
                                            color="secondary"
                                        ></Badge> */}
                                    </button>
                                </Grid>
                            )
                        }
                        return null
                    })}
                </Grid>
            ) : (
                <div className={classes.loading}>
                    <CircularProgress className={classes.loadingCircle} />
                </div>
            )}
        </Box>
    )
}

export default ChooseArticle
