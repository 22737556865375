import { Box, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import Upload from "gcs-browser-upload"
import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { FileText } from "react-feather"
import { useHistory } from "react-router"
import { CeleryTask, clientName, MediaType, uploadBucket, uploadBucketInputFolder } from "../../constants"
import { useInsertMedia, useUpdateMedia } from "../../graphql/media"
import { useApiService } from "../../providers/ApiServiceProvider"
import { useGetLoginData, useSetResultsVideo } from "../../store"
import { Media } from "../../types/types"
import Button from "../Button"
import ErrorMessage from "../ErrorMessage"
import Input from "../Input"
import UploadProgress from "./UploadProgress"

const useStyles = makeStyles({
    dropzone: {
        border: "1px dashed #D4D3D3",
        borderRadius: 8,
        width: 501,
        height: 334,
        textAlign: "center",
        outline: 0,
        backgroundColor: "#FFFFFF",
        cursor: "pointer"
    },
    dropzoneHover: {
        backgroundColor: "#ECF4FE"
    },
    dropzoneTitle: {
        marginBottom: 8
    },
    dropzoneSubtitle: {
        color: "#6A6068"
    },
    dropzoneSubtitleClick: {
        color: "#73B3FF",
        fontWeight: 600
    },
    select: {
        marginTop: 16,
        width: 263,
        padding: 8,
        border: "1px solid #004FFF",
        color: "#004FFF",
        outline: 0
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    button: {
        marginTop: 16,
        width: "100%"
    },
    selectedVideo: {
        backgroundColor: "#D9E4FF",
        height: 126,
        width: 420,
        paddingLeft: 32,
        paddingRight: 32,
        marginTop: 16,
        display: "flex",
        alignItems: "center"
    },
    fileIcon: {
        marginTop: 109,
        marginBottom: 24
    },
    filmIcon: {
        marginBottom: 24,
        minWidth: 32
    },
    fileName: {
        overflowWrap: "anywhere"
    },
    uploading: {
        width: 420,
        height: 297,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    loadingCircle: {
        color: "#004FFF"
    },
    useCache: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "20px",
        color: "#004FFF"
    },
    or: {
        textAlign: "center",
        marginTop: 12,
        marginBottom: 12
    }
})

interface FileData {
    uuid: string
    filename: string
    type: string
    url: string
}

interface Props {
    media?: Media
    setMedia: (media?: Media) => void
}

const UploadVideo: FC<Props> = ({ media, setMedia }) => {
    const classes = useStyles()
    const history = useHistory()
    const ApiService = useApiService()
    const [insertMedia, insertMediaResult] = useInsertMedia()
    const [updateMedia] = useUpdateMedia()
    const loginData = useGetLoginData()
    const setResultsMedia = useSetResultsVideo()
    const [uploadedStarted, setUploadStarted] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [mediaUrl, setMediaUrl] = useState<string | undefined>(undefined)
    const [videoTitle, setVideoTitle] = useState<string>("")
    const [fileData, setFileData] = useState<FileData | undefined>(undefined)
    const [videoSeasonEpisode, setVideoSeasonEpisode] = useState<string>("")
    const [videoPublisher, setVideoPublisher] = useState<string>("")
    const [videoShow, setVideoShow] = useState<string>("")
    const [progress, setProgress] = useState<number>(0)
    const uploadRef = useRef<{ start: () => void; cancel: () => void } | null>(null)

    const getReadableSignedUrl = useCallback(async () => {
        if (!media) {
            return
        }
        const readableSignedUrlResponse = await ApiService.getReadableSignedUrl(
            `${uploadBucketInputFolder}/${clientName}/${media.file_name}`,
            media.type
        )
        if (!readableSignedUrlResponse) {
            setError(true)
            return
        }
        setMediaUrl(readableSignedUrlResponse.data)
    }, [ApiService, media])

    useEffect(() => {
        if (media && !mediaUrl) {
            getReadableSignedUrl()
        }
    }, [loginData, media, mediaUrl, history, ApiService, getReadableSignedUrl])

    const onChunkUpload = (info: {
        totalBytes: number
        uploadedBytes: number
        chunkIndex: number
        chunkLength: number
    }) => setProgress((info.uploadedBytes / info.totalBytes) * 100)

    const uploadFileWithSignedUrl = async (id: string, url: string, file: File): Promise<string | undefined> => {
        uploadRef.current = new Upload({
            id,
            url,
            file,
            onChunkUpload
        })
        try {
            await uploadRef.current?.start()
            uploadRef.current = null
            return "success"
        } catch (error) {
            console.error(error)
            setError(true)
            return
        }
    }

    const uploadFile = async (files: File[]) => {
        if (!loginData || !loginData.userId) {
            setError(true)
            return
        }

        if (!files || files.length === 0) {
            window.alert("No files found.")
            return
        }

        setMediaUrl(undefined)
        setUploadStarted(true)
        setUploading(true)
        const file = files[0]
        const insertMediaResponse = await insertMedia({
            variables: {
                user_id: loginData.userId,
                type: file.type.includes("video") ? MediaType.VIDEO : MediaType.AUDIO
            }
        })
        if (insertMediaResult.error || !insertMediaResponse.data) {
            setError(true)
            return
        }
        const uuid = insertMediaResponse.data.insert_media.returning[0].uuid
        const filename = `${uuid}_${file.name}`
        const url = `gs://${uploadBucket}/${uploadBucketInputFolder}/${filename}`
        const fileData = { uuid, filename, type: file.type, url }
        setFileData(fileData)
        await updateMedia({
            variables: {
                uuid: fileData.uuid,
                file_name: fileData.filename,
                url: fileData.url,
                title: videoTitle ? videoTitle : "",
                season_episode: videoSeasonEpisode ? videoSeasonEpisode : "",
                publisher: videoPublisher ? videoPublisher : "",
                show: videoShow ? videoShow : ""
            }
        })
        const urlResponse = await ApiService.getSignedUrl(
            `${uploadBucketInputFolder}/${filename}`,
            window.location.origin
        )
        if (!urlResponse) {
            setError(true)
            return
        }
        const uploadResponse = await uploadFileWithSignedUrl(uuid, urlResponse.data, file)
        if (!uploadResponse) {
            setError(true)
            return
        }
        const triggerResponse = await ApiService.triggerPipeline(loginData.userId, [uuid], CeleryTask.process_media)
        if (!triggerResponse) {
            setError(true)
            return
        }
        const readableSignedUrlResponse = await ApiService.getReadableSignedUrl(
            `${uploadBucketInputFolder}/${clientName}/${filename}`,
            file.type
        )
        if (!readableSignedUrlResponse) {
            setError(true)
            return
        }
        setUploading(false)
        setMediaUrl(readableSignedUrlResponse.data)
        setMedia({
            uuid,
            file_name: filename,
            type: file.type,
            url,
            title: videoTitle,
            season_episode: videoSeasonEpisode,
            publisher: videoPublisher,
            show: videoShow,
            image: "", // produced by the pipeline,
            is_processed: false,
            created_at: "",
            updated_at: ""
        })
    }

    const cancelUpload = async () => {
        setMedia(undefined)
        setUploading(false)
        setUploadStarted(false)
        setError(false)
        try {
            uploadRef.current?.cancel()
        } catch (error) {
            console.error("Failed to cancel file upload", error)
        }
    }

    const viewResults = async () => {
        if (!media || !mediaUrl) {
            setError(true)
            return
        }
        if (fileData) {
            await updateMedia({
                variables: {
                    uuid: fileData.uuid,
                    file_name: fileData.filename,
                    url: fileData.url,
                    title: videoTitle ? videoTitle : media.title,
                    season_episode: videoSeasonEpisode ? videoSeasonEpisode : media.season_episode,
                    publisher: videoPublisher ? videoPublisher : media.publisher,
                    show: videoShow ? videoShow : media.show
                }
            })
        }
        const updatedMedia = {
            ...media,
            title: videoTitle ? videoTitle : media.title,
            season_episode: videoSeasonEpisode ? videoSeasonEpisode : media.season_episode,
            publisher: videoPublisher ? videoPublisher : media.publisher,
            show: videoShow ? videoShow : media.show
        }
        setResultsMedia({
            media: updatedMedia,
            mediaUrl
        })
        history.push(`/results/${updatedMedia.uuid}`)
    }

    const onChangeVideoTitle = (event: ChangeEvent<HTMLInputElement>) => setVideoTitle(event.target.value)

    const onChangeVideoShow = (event: ChangeEvent<HTMLInputElement>) => setVideoShow(event.target.value)

    const onChangeVideoSeasonEpisode = (event: ChangeEvent<HTMLInputElement>) =>
        setVideoSeasonEpisode(event.target.value)

    const onChangeVideoPublisher = (event: ChangeEvent<HTMLInputElement>) => setVideoPublisher(event.target.value)

    return (
        <>
            {uploading ? (
                <UploadProgress filename={fileData?.filename ?? ""} progress={progress} cancelUpload={cancelUpload} />
            ) : mediaUrl && uploadedStarted ? (
                <video
                    src={mediaUrl}
                    controls={true}
                    width={420}
                    controlsList="nodownload"
                    disablePictureInPicture={true}
                />
            ) : (
                <>
                    <Dropzone onDrop={files => uploadFile(files)}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                                {...getRootProps()}
                                className={clsx(classes.dropzone, isDragActive && classes.dropzoneHover)}
                            >
                                <FileText color="#73B3FF" size={32} strokeWidth={2} className={classes.fileIcon} />
                                <input {...getInputProps()} />
                                <Typography variant="h6" className={classes.dropzoneTitle}>
                                    Drag file here
                                </Typography>
                                <Typography variant="body2" className={classes.dropzoneSubtitle}>
                                    or <span className={classes.dropzoneSubtitleClick}>click</span> to browse
                                </Typography>
                            </div>
                        )}
                    </Dropzone>
                    {/*<Typography variant="body2" className={classes.or}>*/}
                    {/*    or*/}
                    {/*</Typography>*/}
                    {/*<CopyUrlInput upload={uploadFromUrl} />*/}
                </>
            )}
            {uploadedStarted && (
                <section>
                    <Box marginBottom={2} marginTop={3}>
                        <Input onChange={onChangeVideoTitle} label="Title*" placeholder="Title" />
                    </Box>
                    <Box marginBottom={2}>
                        <Input onChange={onChangeVideoShow} label="Show" placeholder="Show" />
                    </Box>
                    <Box marginBottom={2}>
                        <Input
                            onChange={onChangeVideoSeasonEpisode}
                            label="Season/Episode"
                            placeholder="Season/Episode"
                        />
                    </Box>
                    <Input onChange={onChangeVideoPublisher} label="Publisher" placeholder="Publisher" />
                    <Box marginTop={3} className={classes.center}>
                        <Button
                            onClick={viewResults}
                            disabled={!mediaUrl || uploading || (uploadedStarted && !videoTitle)}
                            className={classes.button}
                        >
                            View Results
                        </Button>
                    </Box>
                </section>
            )}
            <ErrorMessage error={error} />
        </>
    )
}

export default UploadVideo
