import React, { FC } from "react"
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core"

const Theme: FC = ({ children }) => {
    const theme = createMuiTheme({
        overrides: {
            MuiCssBaseline: {
                "@global": {
                    "*": {
                        boxSizing: "border-box"
                    },
                    body: {
                        backgroundColor: "#F9F9FB"
                    }
                }
            },
            MuiSlider: {
                thumb: {
                    "&:hover": {
                        boxShadow: "0px 2px 8px rgba(54, 53, 69, 0.15)"
                    },
                    "&:active": {
                        boxShadow: "0px 2px 8px rgba(54, 53, 69, 0.15)"
                    }
                }
            }
        },
        typography: {
            body1: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 16,
                lineHeight: "24px",
                color: "#2F232D"
            },
            body2: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 14,
                lineHeight: "20px",
                color: "#2F232D"
            },
            subtitle1: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 16,
                lineHeight: "24px",
                color: "#2F232D"
            },
            subtitle2: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 14,
                lineHeight: "20px",
                color: "#2F232D"
            },
            caption: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 10,
                lineHeight: "16px",
                color: "#2F232D"
            },
            overline: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 10,
                lineHeight: "12px",
                color: "#2F232D",
                textTransform: "none"
            },
            h4: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 34,
                lineHeight: "48px",
                letterSpacing: "0.01em",
                color: "#2F232D"
            },
            h5: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 28,
                lineHeight: "36px",
                letterSpacing: "0.01em",
                color: "#2F232D"
            },
            h6: {
                fontFamily: "Work Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 20,
                lineHeight: "32px",
                letterSpacing: "0.01em",
                color: "#2F232D"
            }
        }
    })
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

export default Theme
