import { LoginState, UserCreationState } from "../constants"

export enum TopicSheetStatus {
    PROCESSING = "processing",
    SUCCESS = "success",
    FAIL = "fail"
}

export enum CacheMode {
    CACHE_ENABLED = "cache_enabled", // if cache exists use and but do not update values, if cache miss update values
    CACHE_BUST = "cache_bust", // ignore existing cache and always update with new values
    CACHE_DISABLED = "cache_disable" // does not use existing cache, does not update values
}

export enum LocalStorageKey {
    GET_SESSION = "getSessionStorage",
    SET_SESSION = "sessionStorage"
}

export const MediaTypeColor = {
    video: "primary",
    text: "secondary",
    audio: "error"
} as { [index: string]: string }

export interface FormError {
    field: string
    messages: string[]
}

export interface BaseResponse {
    data?: any
    message?: string
    error?: boolean
    formErrors?: FormError[]
}

export interface StringResponse extends BaseResponse {
    data: string
}

export interface UserCreationResponse extends BaseResponse {
    data: {
        userCreationState: UserCreationState
    }
}

export interface ErrorResponse {
    error: string
    message: string
    statusCode: number
}

export interface AuthenticateResponse extends BaseResponse {
    data: LoginData
}

export interface UserData {
    userId?: number
    loginState?: LoginState
}

export interface LoginData {
    loginState: LoginState
    accessToken?: string
    accessTokenExp?: number
    refreshToken?: string
    refreshTokenExp?: number
    userId?: number
}

interface MediaBase {
    uuid: string
    type: string
    image: string
    title: string
    url?: string
    file_name?: string
    publisher?: string
    season_episode?: string
    show?: string
    source_url?: string
    is_processed: boolean
    created_at: string
    updated_at: string
}

export interface VideoWithArtifact extends Media {
    file_name: string
    url: string
    latest_media_artifact?: {
        artifact_json: ArtifactJson
    }
}

export interface Media extends MediaBase {
    media_text?: string
}

export interface MediaWithArtifact extends Media {
    latest_media_artifact?: {
        artifact_json: ArtifactJson
    }
}

export interface ArtifactJson {
    media_uuid: string
    video_duration: number
    topics: Topic[]
    themes: Topic[]
    video_frames: Frame[]
    phrases: Word[]
    summaryImages?: SummaryImage[]
    summaryFrames?: SummaryImage[]
    attributes?: string[]
    similarity_matches?: SimilarityMatch[]
}

export interface Coordinates {
    x: number
    y: number
}

export interface FrameObject {
    name: string
    bounding_box?: Coordinates[] // tl, tr, br, bl
    topics?: SimpleTopic[]
}

export interface Frame {
    id: number
    timestamp: number
    objects: FrameObject[]
}

export interface Word {
    id: number
    word: string
    phrase: string
    time: number[] // 0: start, 1: end
    topics: SimpleTopic[]
}

export interface Topic {
    id: string
    topic: string
    confidence: number
    signal: number
    frames: Frame[]
    phrases: Word[]
    topicCategory?: TopicCategory
}

export interface SimpleTopic {
    id: string
    name: string
}

export interface TopicCategory {
    id: string
    name: string
}

export interface ResultsVideo {
    media: Media
    mediaUrl?: string
}

export interface SummaryImage {
    filename: string
    frameId: number
    timestamp: number
}

export interface BulkImportResult {
    uuid: string
    url: string
}

export interface TopicSheet {
    id: number
    user_id: number
    filename: string
    status: TopicSheetStatus
}

export interface SimilarityMatch {
    match_media_uuid: string
    similarity_score: number
    score_details: {}
}

export interface TriggerPipelineRequestBody {
    userId: number
    mediaUuids: string[]
    pipeline: string
    cacheMode: CacheMode
    topicSheetName?: string
    isDefault?: boolean
}

export interface BulkImportRequestBody {
    userId: number
    manifestPathAndFilename: string
    pipeline: string
    cacheMode: CacheMode
}
