import React, { FC, MouseEvent } from "react"
import { CircularProgress, Grid, makeStyles, Tooltip } from "@material-ui/core"
import clsx from "clsx"
import { Topic } from "../../types/types"
import TextButton from "./TextButton"
import { ChevronDown, ChevronUp } from "react-feather"

const useStyles = makeStyles({
    topic: {
        height: 32,
        width: "fit-content",
        border: "1px solid #73B3FF",
        paddingTop: 13,
        paddingBottom: 13,
        paddingLeft: 12,
        paddingRight: 12,
        backgroundColor: "#F2F6FF",
        borderRadius: 120,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Work Sans, sans-serif",
        color: "#2F232D",
        fontSize: 14,
        lineHeight: "14px",
        "&:hover": {
            backgroundColor: "#D7E9FF"
        },
        "&:active": {
            backgroundColor: "#5C8FCC"
        },
        "&:focus": {
            outline: "none"
        }
    },
    selectedTopic: {
        backgroundColor: "#73B3FF"
    },
    loadingCircle: {
        color: "#73B3FF"
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 24,
        paddingRight: 24,
        marginTop: 24
    },
    buttonContent: {
        width: 144,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    topicsFewer: {
        paddingLeft: 24,
        paddingRight: 24
    },
    topics: {
        maxHeight: 616,
        overflowY: "auto",
        paddingLeft: 24,
        paddingRight: 24
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
})

interface Props {
    topics?: Topic[]
    selectedTopics?: Topic[]
    onSelectTopic: (topic: Topic) => (event: MouseEvent) => void
    showAllTopics: boolean
    showTopicExpander: boolean
    onChangeShowAllTopics: () => void
}

const Topics: FC<Props> = ({
    topics,
    selectedTopics,
    onSelectTopic,
    showAllTopics,
    showTopicExpander,
    onChangeShowAllTopics
}) => {
    const classes = useStyles()

    const topicInSelectedTopics = (topic: Topic) => selectedTopics?.map(topic => topic.id).includes(topic.id)

    return (
        <>
            {topics ? (
                <>
                    <div className={clsx(showAllTopics ? classes.topics : classes.topicsFewer)}>
                        <Grid container={true} spacing={1}>
                            {topics.map(topic => (
                                <Grid item={true} key={`topic ${topic?.id}`}>
                                    <Tooltip title={`Confidence ${topic.confidence ?? ""}`}>
                                        <button
                                            className={clsx(
                                                classes.topic,
                                                topicInSelectedTopics(topic) && classes.selectedTopic
                                            )}
                                            onClick={onSelectTopic(topic)}
                                        >
                                            {topic.topic}
                                            {topic.frames.length > 0 && "*"}
                                        </button>
                                    </Tooltip>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    {showTopicExpander && (
                        <div className={classes.buttonContainer}>
                            <TextButton onClick={onChangeShowAllTopics}>
                                {showAllTopics ? (
                                    <div className={classes.buttonContent}>
                                        {"Show Fewer"}
                                        <ChevronUp color="#2F232D" />
                                    </div>
                                ) : (
                                    <div className={classes.buttonContent}>
                                        {"Show All Topics"}
                                        <ChevronDown color="#2F232D" />
                                    </div>
                                )}
                            </TextButton>
                        </div>
                    )}
                </>
            ) : (
                <div className={classes.center}>
                    <CircularProgress className={classes.loadingCircle} />
                </div>
            )}
        </>
    )
}

export default Topics
