import * as Sentry from "@sentry/react"
import { useEffect, useRef } from "react"
import { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login"
import { useSentryLogger } from "./constants"

export const isGoogleLoginResponse = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
): response is GoogleLoginResponse => {
    return (response as GoogleLoginResponse).googleId !== undefined
}

export const tryAgain = () => window.location.reload()

export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const getTimeFromSeconds = (duration: number) => {
    const minutes = Math.floor(duration / 60)
    duration = Math.floor(duration % 60)
    const seconds = duration >= 10 ? duration : "0" + duration
    return minutes + ":" + seconds
}

export const logError = (error: Error) => {
    if (useSentryLogger) {
        Sentry.captureException(error)
    } else {
        console.error(error)
    }
}

export const logInfo = (message: string) => {
    if (useSentryLogger) {
        Sentry.captureMessage(message)
    } else {
        console.log(message)
    }
}
