import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { FC, MouseEvent, useEffect, useRef } from "react"
import { Word } from "../../types/types"

const useStyles = makeStyles({
    selectedWord: {
        color: "#73B3FF",
        backgroundColor: "#D7E9FF",
        fontWeight: 600,
        padding: 0,
        paddingLeft: 1,
        paddingRight: 1
    },
    unselectedWord: {
        color: "#2F232D",
        backgroundColor: "transparent",
        fontWeight: "normal",
        padding: 0,
        paddingLeft: 1,
        paddingRight: 1
    },
    word: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontSize: 14,
        lineHeight: "20px",
        outline: 0,
        margin: 0,
        border: 0,
        borderRadius: 0
    },
    topicWord: {
        cursor: "pointer"
    }
})

interface Props {
    word: Word
    onClick: (word: Word) => (event: MouseEvent) => void
    showSpace: boolean
    selectedWord?: Word
    setWordRefs: (wordRefs: { [key: string]: HTMLButtonElement }) => void
    wordRefs: { [key: string]: HTMLButtonElement }
}

const TranscriptWord: FC<Props> = ({ word, onClick, showSpace, selectedWord, setWordRefs, wordRefs }) => {
    const classes = useStyles()
    const ref = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        // console.log(`selectedWord ${selectedWord}`)
        if (ref.current && !wordRefs[word.id]) {
            wordRefs[word.id] = ref.current
        }
    })

    return (
        <span key={`transcript word ${word.id}`}>
            <button
                className={clsx(
                    classes.word,
                    word.id === selectedWord?.id ? classes.selectedWord : classes.unselectedWord,
                    word.topics.length > 0 ? classes.topicWord : undefined
                )}
                onClick={word.topics.length > 0 ? onClick(word) : undefined}
                ref={ref}
            >
                {word.word}
            </button>
            <span>{showSpace && " "}</span>
        </span>
    )
}

export default TranscriptWord
