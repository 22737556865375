import { Typography } from "@material-ui/core"
import React, { FC } from "react"

interface Props {
    error: boolean
}

const ErrorMessage: FC<Props> = ({ error }) => {
    return (
        <>
            {error && (
                <Typography variant="body1">
                    {"There was an error "}
                    <span role="img" aria-label="disappointed face">
                        😞
                    </span>
                </Typography>
            )}
        </>
    )
}

export default ErrorMessage
