import { Switch as MuiSwitch, withStyles } from "@material-ui/core"

const Switch = withStyles({
    switchBase: {
        "&$checked": {
            color: "#5E65F6"
        },
        "&$checked + $track": {
            backgroundColor: "#5E65F6"
        }
    },
    checked: {
        "&$switchBase": {
            color: "#5E65F6",
            "&:hover": {
                backgroundColor: "rgba(94,177,246,0.08)"
            }
        }
    },
    track: {}
})(MuiSwitch)

export default Switch
