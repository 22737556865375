import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    makeStyles,
    Typography
} from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import LinesEllipsis from "react-lines-ellipsis"
import { Link } from "react-router-dom"
import { getBucketPath, useGetVideo } from "../../graphql/media"
import { useApiService } from "../../providers/ApiServiceProvider"
import { useMediaImages } from "../../store"
import { SimilarityMatch, StringResponse } from "../../types/types"
import CodeIcon from "./CodeIcon"

const VideoWidth = 304

const useStyles = makeStyles({
    match: {
        width: VideoWidth,
        background: "#ffffff",
        boxShadow: "0px 4px 12px rgba(54, 53, 69, 0.15)",
        margin: 8
    },
    matchTop: {
        width: 304,
        minHeight: 166
    },
    matchBottom: {
        padding: 12
    },
    matchTitle: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "19.2px",
        color: "#2F232D"
    },
    percent: {
        marginBottom: 4
    },
    percentRoot: {
        border: "0.5px solid #D4D3D3",
        backgroundColor: "#FFFFFF",
        borderRadius: 32,
        height: 4
    },
    percentBar: {
        backgroundColor: "#73B3FF",
        borderRadius: 32
    },
    button: {
        border: 0,
        padding: 0,
        margin: 0,
        outline: 0,
        backgroundColor: "transparent",
        cursor: "pointer"
    },
    code: {
        whiteSpace: "pre-wrap",
        fontSize: 14,
        lineHeight: "20px"
    },
    dialogTitle: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 28,
        lineHeight: "36px",
        color: "#2F232D"
    },
    dialogClose: {
        position: "relative",
        top: 24,
        right: 32
    },
    dialogPaper: {
        width: 689
    }
})

interface Props {
    similarityMatch: SimilarityMatch
}

const Match: FC<Props> = ({ similarityMatch }) => {
    const classes = useStyles()
    const mediaData = useGetVideo({ variables: { media_uuid: similarityMatch.match_media_uuid } })
    const ApiService = useApiService()
    const [mediaImages, setMediaImages] = useMediaImages()
    const [mediaUrl, setMediaUrl] = useState<string | undefined>(undefined)
    const [showCode, setShowCode] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            let readableSignedUrlResponse: StringResponse | undefined
            if (mediaData.data && mediaData.data.media[0]) {
                const bucketPath = getBucketPath(mediaData.data.media[0].url)
                readableSignedUrlResponse = await ApiService.getReadableSignedUrl(
                    `${bucketPath}/${mediaData.data?.media[0].file_name.split(".")[0] ?? ""}_thumb.jpg`,
                    mediaData.data.media[0].type ?? ""
                )
            }

            if (readableSignedUrlResponse === undefined) {
                return
            } else {
                setMediaImages(oldMediaImages => ({
                    ...oldMediaImages,
                    [similarityMatch.match_media_uuid]: readableSignedUrlResponse!.data
                }))
            }
        })()
        ;(async () => {
            let readableSignedUrlResponse: StringResponse | undefined
            if (mediaData.data && mediaData.data.media[0]) {
                const bucketPath = getBucketPath(mediaData.data.media[0].url)
                readableSignedUrlResponse = await ApiService.getReadableSignedUrl(
                    `${bucketPath}/${mediaData.data.media[0].file_name ?? ""}`,
                    mediaData.data.media[0].type ?? ""
                )
            }
            if (!readableSignedUrlResponse) {
                return
            }
            setMediaUrl(readableSignedUrlResponse.data)
        })()
    }, [ApiService, mediaData.data, setMediaImages, similarityMatch.match_media_uuid])

    const openModal = () => setShowCode(!showCode)
    const closeModal = () => setShowCode(false)

    const media = mediaData.data ? mediaData.data.media[0] : undefined

    return (
        <div className={classes.match}>
            <div className={classes.matchTop}>
                <video
                    src={mediaUrl}
                    preload="none"
                    controls={true}
                    width={VideoWidth}
                    poster={mediaImages?.[similarityMatch.match_media_uuid]}
                    controlsList="nodownload"
                    disablePictureInPicture={true}
                />
            </div>

            {media ? (
                <div className={classes.matchBottom}>
                    <Box marginBottom={1} className={classes.matchTitle}>
                        <LinesEllipsis text={media.title} maxLine={2} basedOn="letters" />
                    </Box>
                    <Box marginBottom={1}>
                        <Typography variant="subtitle2">{media.publisher}</Typography>
                    </Box>
                    <div className={classes.percent}>
                        <Typography variant="caption">
                            {Math.floor(similarityMatch.similarity_score * 100)}% Match
                        </Typography>
                    </div>
                    <Box marginBottom={1}>
                        <LinearProgress
                            classes={{
                                root: classes.percentRoot,
                                bar: classes.percentBar
                            }}
                            variant="determinate"
                            value={similarityMatch.similarity_score * 100}
                        />
                    </Box>
                    <button className={classes.button} onClick={openModal}>
                        <CodeIcon />
                    </button>
                    <Dialog
                        open={showCode}
                        onClose={closeModal}
                        classes={{
                            paper: classes.dialogPaper
                        }}
                    >
                        <DialogTitle disableTypography={true}>
                            <Typography variant="h5" style={{ fontWeight: 600 }}>
                                Video Information
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <pre className={classes.code}>{JSON.stringify(similarityMatch, null, 2)}</pre>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <div>
                        <Link to={`/results/${media.uuid}`}>View Video Results</Link>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    )
}

export default Match
