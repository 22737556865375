import { Box, FormControl, FormHelperText, makeStyles, MenuItem, Select } from "@material-ui/core"
import React, { FC, useState } from "react"
import { useRecoilState } from "recoil"
import { orgSlugState } from "../../store"
import { Media } from "../../types/types"
import SearchInput from "./SearchInput"
import UploadVideo from "./UploadVideo"
import VideoChoices from "./VideoChoices"

const useStyles = makeStyles({
    title: {
        marginBottom: 40
    },
    content: {
        display: "flex"
    },
    videos: {
        width: 756,
        marginRight: 16
    },
    recentUploads: {
        marginTop: 0,
        marginBottom: 12,
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "14px",
        color: "#2F232D"
    },
    formControl: {
        minWidth: 175,
        marginTop: 15,
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12
    },
    menuItem: {
        fontSize: 14,
        padding: 5
    },
    orgSelect: {
        paddingLeft: 5
    },
})

const ChooseVideo: FC = () => {
    const [orgSlug, setOrgSlug] = useRecoilState(orgSlugState)
    const classes = useStyles()
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [media, setMedia] = useState<Media | undefined>(undefined)

    const organizations = [
        {
            name: "DBMO",
            slug: "dbmo"
        },
        {
            name: "OverTier NFL",
            slug: "overtier-nfl"
        }
    ]
    const onOrgSelect = (orgSlug: string) => {
        setOrgSlug(orgSlug)
    }

    return (
        <Box marginTop={3}>
            <h1 className={classes.recentUploads}>Recent Uploads</h1>
            <FormControl className={classes.formControl} size="small">
                
                <Select
                    value={orgSlug}
                    classes={{ root: classes.orgSelect }}
                    onChange={event => onOrgSelect(event.target.value as string)}
                >
                    {organizations.map((org, index) => (
                        <MenuItem key={index} value={org.slug} className={classes.menuItem}>
                            {org.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ marginTop: 0 }}>Organization</FormHelperText>
            </FormControl>
            <section className={classes.content}>
                <section className={classes.videos}>
                    <SearchInput placeholder="Search" setSearchQuery={setSearchQuery} />
                    <VideoChoices searchQuery={searchQuery} setMedia={setMedia} />
                </section>
                <section>
                    <UploadVideo media={media} setMedia={setMedia} />
                </section>
            </section>
        </Box>
    )
}

export default ChooseVideo
