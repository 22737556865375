import React, { ChangeEvent, FC } from "react"
import { Box, makeStyles } from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"

const useStyles = makeStyles({
    input: {
        height: 40,
        width: 501,
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        borderRadius: 8,
        paddingLeft: 16,
        paddingRight: 16,
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "20px",
        color: "#2F232D",
        outline: 0,
        "&::placeholder": {
            fontFamily: "Work Sans, sans-serif",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: "20px",
            color: "#6A6068"
        }
    },
    label: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "15px",
        color: "#2F232D"
    }
})

interface Props {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    label?: string
    placeholder?: string
    type?: string
    style?: CSSProperties
}

const Input: FC<Props> = ({ onChange, label, placeholder, type, style }) => {
    const classes = useStyles()
    return (
        <>
            {label && (
                <Box marginBottom={1}>
                    <label className={classes.label} id={`${label} label`}>
                        {label}
                    </label>
                </Box>
            )}
            <input
                placeholder={placeholder}
                className={classes.input}
                style={style}
                onChange={onChange}
                aria-describedby={`${label} label`}
                type={type}
            />
        </>
    )
}

export default Input
