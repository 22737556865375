/**
 * This use braintree-web-react follows the example here https://www.npmjs.com/package/braintree-web-react
 * It request a client token from the DBMO API and then uses Brainstree's Drop-in UI to 
 * post a user's CC info to Braintree.
 * Finally on a successful POST to Brainstree, it sends the payment method nounce to the DBMO API.
 * 
 * See here for sample CC numbers https://developers.braintreepayments.com/guides/credit-cards/testing-go-live/php
 * 
 * 
 * 
 */





import { makeStyles } from "@material-ui/core"
import axios from "axios"
import { BraintreeDropIn } from "braintree-web-react"
import React, { FC, useEffect, useState } from "react"


const API_BASE = "http://localhost:8080"

const useStyles = makeStyles({
    register: {}
})

const BtSandbox: FC = () => {
    const classes = useStyles()
    const [submitState, setSubmitState] = useState<string>("")
    const [clientToken, setClientToken] = useState<string>("")

    // The Braintree instance that is returned once the BraintreeDropIn plugins initializes.
    let btInstance: any

    useEffect(() => {
        console.log(`A call to ge the clientToken from the DBMO server`)
        async function scoped() {
            const tokenResponse = await axios.get(`${API_BASE}/billing/client-token`)
            setClientToken(tokenResponse.data.clientToken)
        }
        scoped()
    }, [])

    const setInstance = (newInstance: any) => {
        console.log(`in setInstance ${newInstance}`)
        btInstance = newInstance
    }

    const onSubmit = async () => {
        setSubmitState("pending")
        console.log(`Requesting a payment method nonce from BRainstree. instacen ${btInstance}`)
        const { nonce } = await btInstance.requestPaymentMethod().catch((err: any) => {
            console.log(err)
        })
        console.log(`Finally send payment nonce to DBMO API.`)
        const response = await axios.post(
            `${API_BASE}/billing/payment-method`,
            { nonce: nonce }
        )
        setSubmitState("")
        console.log(response)
    }

    return (
        <div className={classes.register}>
            <h1>Braintree Sandbox</h1> 
            <div>
                { clientToken ? (
                    <BraintreeDropIn
                        options={{ authorization: clientToken }}
                        onInstance={setInstance}
                    />
                ) : (
                    <div>
                        <h2>Loading...</h2>
                    </div>
                )}
                
                <button onClick={onSubmit}>Submit</button>
            </div>
        </div>
    )
}
export default BtSandbox