import React, { FC, MouseEvent } from "react"
import { CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { Topic, Word } from "../../types/types"
import clsx from "clsx"
import { getTimeFromSeconds } from "../../helpers"

const BarWidth = 625

const useStyles = makeStyles({
    words: {
        width: BarWidth,
        height: 40,
        backgroundColor: "#ECF4FE",
        marginTop: 8,
        position: "relative"
    },
    topicWord: {
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(115, 178, 255, 0.4)",
        height: 40,
        border: 0,
        outline: 0,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#73B3FF",
            width: "8px !important"
        },
        zIndex: 1
    },
    noTopicWord: {
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#ECF4FE",
        height: 40,
        border: 0,
        outline: 0
    },
    selectedWord: {
        height: 55,
        backgroundColor: "#73B3FF",
        boxShadow: "0px 4px 16px rgba(54, 53, 69, 0.15)",
        border: 0
    },
    loadingCircle: {
        color: "#73B3FF"
    },
    videoTimeSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 8
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    title: {
        marginBottom: 12
    },
    wordTime: {
        color: "#2F232D",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        top: 55
    }
})

interface Props {
    words?: Word[]
    selectedTopics: Topic[]
    topics?: Topic[]
    setWord: (word: Word) => void
    selectedWord?: Word
    duration: number
}

const Words: FC<Props> = ({ words, selectedTopics, topics, setWord, selectedWord, duration }) => {
    const classes = useStyles()

    const onClick = (word: Word) => (event: MouseEvent) => {
        if (!selectedTopics.length) {
            setWord(word)
        }

        const selectedTopicIds = selectedTopics.map(t => t.id)

        for (let topic of word.topics) {
            if (selectedTopicIds.includes(topic.id)) {
                setWord(word)
                return
            }
        }
    }

    const getWordCount = () => {
        if (selectedTopics.length === 0) {
            return words?.filter(word => word.topics.length > 0).length ?? 0
        }
        return words?.filter(words => hasAtLeastOneSelectedTopic(words)).length ?? 0
    }

    const hasAtLeastOneSelectedTopic = (word: Word): boolean => {
        let hasATopic = false
        selectedTopics
            .map(topic => topic.id)
            .forEach(topicId => {
                if (word.topics.map(topic => topic.id).includes(topicId)) {
                    hasATopic = true
                }
            })
        return hasATopic
    }

    const wordPosition = (word: Word) => {
        if (duration === 0) {
            return
        }
        const pixelsPerSecond = BarWidth / duration
        const positionInSeconds = word.time[0] / 1000
        return positionInSeconds * pixelsPerSecond
    }

    return (
        <>
            {words ? (
                <>
                    <Typography variant="h6" className={classes.title}>
                        {getWordCount()} Associated Words in the Transcript
                    </Typography>
                    <section className={classes.videoTimeSection}>
                        <Typography variant="caption">0:00</Typography>
                        <Typography variant="caption">{getTimeFromSeconds(duration)}</Typography>
                    </section>
                    <div className={classes.words}>
                        {words.map(word => (
                            <div key={`word ${word.id}`}>
                                <div
                                    className={clsx(
                                        selectedTopics.length === 0 || hasAtLeastOneSelectedTopic(word)
                                            ? classes.topicWord
                                            : classes.noTopicWord,
                                        selectedWord?.id === word.id && classes.selectedWord
                                    )}
                                    style={{
                                        left: wordPosition(word),
                                        width: words.length <= 300 ? 2 : 1
                                    }}
                                    onClick={onClick(word)}
                                />
                                {selectedWord?.id === word.id && (
                                    <Typography
                                        className={classes.wordTime}
                                        style={{ left: wordPosition(word) }}
                                        variant="caption"
                                    >
                                        {getTimeFromSeconds(word.time[0] / 1000)}
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <section className={classes.center}>
                    <CircularProgress className={classes.loadingCircle} />
                </section>
            )}
        </>
    )
}

export default Words
