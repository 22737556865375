import { ApolloProvider } from "@apollo/react-hooks"
import * as Sentry from "@sentry/react"
import { InMemoryCache } from "apollo-cache-inmemory"
import ApolloClient from "apollo-client"
import { ApolloLink, split } from "apollo-link"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import { HttpLink } from "apollo-link-http"
import { WebSocketLink } from "apollo-link-ws"
import { getMainDefinition } from "apollo-utilities"
import React, { FC } from "react"
import { useHistory } from "react-router"
import { ClientOptions, SubscriptionClient } from "subscriptions-transport-ws"
import { graphqlHttpUri, graphqlWsUri } from "../constants"
import { logError, logInfo } from "../helpers"
import { useStorageStore } from "../hooks/useStorageStore"

class CustomSubscriptionClient extends SubscriptionClient {
    constructor(url: string, options?: ClientOptions, webSocketImpl?: unknown, webSocketProtocols?: string | string[]) {
        // connects before the setting up the following workaround if not forced lazy
        super(url, { ...options, lazy: true }, webSocketImpl, webSocketProtocols)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.maxConnectTimeGenerator.setMin(this.maxConnectTimeGenerator.max)
        const { lazy = false } = options || {}
        if (!lazy) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.connect()
        }
    }
}

export const GraphQLProvider: FC = ({ children }) => {
    const { loginData, removeLoginData } = useStorageStore()
    const history = useHistory()
    
    if (!loginData) {
        console.log(`[GraphQLProvider] no loginData found.`)
        return <></>
    }
    Sentry.setUser({
        id: `${loginData.userId}`,
        email: "",
        ip_address: "auto"
    })

    const client = new ApolloClient({
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.forEach(({ message, locations, path }) => {
                        logError(
                            Error(
                                `[GraphQL error] userId: ${loginData.userId}: Message: ${message}, Location: ${locations}, Path: ${path}, accessToken: ${loginData.accessToken}`
                            )
                        )
                        if (message.includes("JWTExpire")) {
                            logInfo(`token expired for userId ${loginData.userId}. Redirecting to login`)
                            window.alert("Your API token expired. You will need to log in again.")
                            removeLoginData()
                            history.push("/login")
                        } else {
                            window.alert(`Unknown server error, try refreshing. If this problem continues please contact support. error message: ${message}`)
                        }
                    }
                    )
                }
                if (networkError) {
                    logError(
                        Error(
                            `[Network error]userId: ${loginData.userId} - networkError.name: ${networkError.name}, Message: ${networkError.message}, accessToken: ${loginData.accessToken}`
                        )
                    )
                }
                

            }),
            setContext((_, { headers }) => {
                return {
                    headers: {
                        ...headers,
                        authorization: `Bearer ${loginData.accessToken}`
                    }
                }
            }),
            split(
                ({ query }) => {
                    const definition = getMainDefinition(query)
                    return definition.kind === "OperationDefinition" && definition.operation === "subscription"
                },
                new WebSocketLink(
                    new CustomSubscriptionClient(graphqlWsUri, {
                        lazy: true,
                        timeout: 10000,
                        reconnect: true,
                        connectionParams: () => {
                            return {
                                headers: {
                                    authorization: `Bearer ${loginData.accessToken}`
                                }
                            }
                        }
                    })
                ),
                new HttpLink({
                    uri: graphqlHttpUri,
                    headers: {
                        authorization: `Bearer ${loginData.accessToken}`
                    }
                })
            )
        ]),
        cache: new InMemoryCache()
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}
