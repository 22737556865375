import React, { FC } from "react"
import { LinearProgress, makeStyles, Typography } from "@material-ui/core"
import { FileText, XCircle } from "react-feather"
import { Canceler } from "axios"

const useStyles = makeStyles({
    uploadProgress: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        width: 501,
        height: 282,
        paddingTop: 223,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24
    },
    upload: {
        height: 35,
        width: 453,
        display: "flex",
        alignItems: "center"
    },
    progressSection: {
        marginLeft: 8,
        width: 413
    },
    nameCloseIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 6
    },
    filename: {
        textOverflow: "ellipsis"
    },
    progressRoot: {
        border: "1px solid #D4D3D3",
        backgroundColor: "#FFFFFF",
        height: 4
    },
    progressBar: {
        backgroundColor: "#73B3FF"
    },
    cancel: {
        cursor: "pointer"
    }
})

interface Props {
    filename: string
    progress: number
    cancelUpload?: Canceler
}

const UploadProgress: FC<Props> = ({ filename, progress, cancelUpload }) => {
    const classes = useStyles()

    const onCancel = () => (cancelUpload ? cancelUpload() : undefined)

    return (
        <section className={classes.uploadProgress}>
            <div className={classes.upload}>
                <FileText color="#73B3FF" size={32} strokeWidth={1} />
                <div className={classes.progressSection}>
                    <div className={classes.nameCloseIcon}>
                        <Typography variant="body2" className={classes.filename}>
                            {filename}
                        </Typography>
                        <XCircle color="#6A6068" size={12} className={classes.cancel} onClick={onCancel} />
                    </div>
                    <LinearProgress
                        classes={{
                            root: classes.progressRoot,
                            bar: classes.progressBar
                        }}
                        variant="determinate"
                        value={progress}
                    />
                </div>
            </div>
        </section>
    )
}

export default UploadProgress
