import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { MediaType, uploadBucket, uploadBucketOutputFolder } from "../constants"
import { ArtifactJson, Media, MediaWithArtifact, VideoWithArtifact } from "../types/types"

export interface ProcessedVideo {
    media: Media[]
}

export interface ProcessedMedia {
    media: Media[]
}

export interface ProcessedFullMedia {
    media: MediaWithArtifact[]
}

export const getBucketPath = (mediaUrl: string): string => {
    /*
    TODO this is temporary until we start saving the bucket path in the media table. See DBMO-544
    */
    const bucketAndInputFolder = `${uploadBucket}/${uploadBucketOutputFolder}`
    const pieces = mediaUrl.split(bucketAndInputFolder)
    if (pieces.length !== 2) {
        throw Error(
            `[getBucketPath] Failed to parse media url: ${mediaUrl}. It is expected to contain ${bucketAndInputFolder}`
        )
    }
    const pathPieces = pieces[1].split("/")
    if (pathPieces.length < 2) {
        throw Error(`[getBucketPath] Failed to remove filename from: ${pieces[1]}. Expected at least one sub folder`)
    }

    return `${uploadBucketOutputFolder}${pathPieces.slice(0, pathPieces.length - 1).join("/")}`
}

export interface ArticlePublishers {
    media: Array<{ publisher: string }>
}

export const useGetArticlePublishers = () => {
    return ["LA Times"]
    // const result = useQuery<ArticlePublishers>(
    //     gql`
    //         query GetArticlePublishers {
    //             media(distinct_on: publisher, where: { type: { _eq: "text" } }) {
    //                 publisher
    //             }
    //         }
    //     `,
    //     {
    //         fetchPolicy: "no-cache"
    //     }
    // )

    // return result?.data ? result.data.media.map(p => p.publisher).sort((a, b) => (a > b ? 1 : 0)) : []
}

export interface QueryExp {
    _eq?: string
    _nin?: string[]
    _is_null?: boolean
}

export interface GetMediaVars {
    mediaWhereExp?: {
        is_processed: { _eq: boolean }
        publisher?: QueryExp
        _or?: { publisher: QueryExp }[]
        type?: QueryExp
        org?: { slug: QueryExp }
    }
}

/**
 * This is used exclusively to get text on the Choose articles page. It does not need the latest_media_artifact
 * @param options
 */
export const useGetProcessedMedia = (options: GetMediaVars = {}) =>
    useQuery<ProcessedMedia, GetMediaVars>(
        gql`
            query get_media($mediaWhereExp: media_bool_exp) {
                media(where: $mediaWhereExp, order_by: { title: asc }) {
                    uuid
                    type
                    url
                    title
                    publisher
                    season_episode
                    image
                    show
                    is_processed
                    media_text
                    updated_at
                    created_at
                }
            }
        `,
        {
            variables: {
                ...options
            },
            fetchPolicy: "no-cache"
        }
    )

/**
 *
 * @param options
 */
export const useGetFullProcessedMedia = (uuid: string) =>
    useQuery<ProcessedFullMedia, { uuid: string }>(
        gql`
            query get_media($publisherExp: String_comparison_exp, $uuid: uuid) {
                media(
                    where: {
                        is_processed: { _eq: true }
                        publisher: $publisherExp
                        type: { _eq: "text" }
                        uuid: { _eq: $uuid }
                    }
                    order_by: { title: asc }
                ) {
                    uuid
                    type
                    title
                    publisher
                    season_episode
                    image
                    show
                    is_processed
                    media_text
                    latest_media_artifact {
                        artifact_json
                    }
                }
            }
        `,
        {
            variables: {
                uuid
            },
            fetchPolicy: "no-cache"
        }
    )

export interface GetVideoWithArtifact {
    media: VideoWithArtifact[]
}

export interface GetVideoVars {
    media_uuid: string
}

export const useGetVideo = (options: { variables: GetVideoVars }) =>
    useQuery<GetVideoWithArtifact, GetVideoVars>(
        gql`
            query get_media($media_uuid: uuid) {
                media(where: { uuid: { _eq: $media_uuid } }) {
                    uuid
                    file_name
                    type
                    url
                    title
                    publisher
                    season_episode
                    image
                    show
                    is_processed
                    updated_at
                    created_at
                    latest_media_artifact {
                        artifact_json
                    }
                }
            }
        `,
        options
    )

export interface InsertMediaData {
    insert_media: {
        returning: Array<{ uuid: string }>
    }
}

export interface InsertMediaVars {
    user_id: number
    type: MediaType
}

export const useInsertMedia = () =>
    useMutation<InsertMediaData, InsertMediaVars>(
        gql`
            mutation insert_media($user_id: Int, $type: media_type_enum) {
                insert_media(objects: { user_id: $user_id, type: $type }) {
                    returning {
                        uuid
                    }
                }
            }
        `
    )

export interface UpdateMediaVars {
    file_name: string
    url: string
    uuid: string
    title: string
    season_episode?: string
    publisher?: string
    show?: string
}

export const useUpdateMedia = () =>
    useMutation<InsertMediaData, UpdateMediaVars>(
        gql`
            mutation update_media(
                $file_name: String
                $url: String
                $uuid: uuid
                $title: String
                $season_episode: String
                $publisher: String
                $show: String
            ) {
                update_media(
                    _set: {
                        file_name: $file_name
                        url: $url
                        title: $title
                        season_episode: $season_episode
                        publisher: $publisher
                        show: $show
                    }
                    where: { uuid: { _eq: $uuid } }
                ) {
                    returning {
                        uuid
                    }
                }
            }
        `
    )

export interface GetMediaArtifactData {
    latest_media_artifact: Array<{
        artifact_json: ArtifactJson
    }>
}

export interface GetMediaArtifactVars {
    media_uuid: string
}

export const useGetMediaArtifact = (options: { variables: GetMediaArtifactVars }) =>
    useSubscription<GetMediaArtifactData, GetMediaArtifactVars>(
        gql`
            subscription get_transcript($media_uuid: uuid) {
                latest_media_artifact(where: { media_uuid: { _eq: $media_uuid } }) {
                    artifact_json
                }
            }
        `,
        options
    )
