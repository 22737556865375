import { Box, Typography } from "@material-ui/core"
import React, { FC, useState } from "react"
import { CeleryTask } from "../../constants"
import { useSessionStore } from "../../hooks/useSessionStore"
import { useApiService } from "../../providers/ApiServiceProvider"
import Button from "../Button"
import ErrorMessage from "../ErrorMessage"

const UpdateTopicSheet: FC = () => {
    const ApiService = useApiService()
    const sessionStore = useSessionStore()
    const loginData = sessionStore.session

    const [error, setError] = useState<boolean>(false)
    const [working, setWorking] = useState<boolean>(false)

    const onClick = async () => {
        if (!loginData?.userId) {
            setError(true)
            return
        }
        setWorking(true)
        const triggerResponse = await ApiService.triggerPipeline(loginData.userId, [], CeleryTask.update_topic_sheet)
        if (!triggerResponse) {
            setError(true)
        }
        setWorking(false)
    }

    return (
        <>
            <Button onClick={onClick} style={{ width: "100%" }} disabled={working}>
                Update Topic Sheet
            </Button>
            <Box marginTop={1}>
                <Typography variant="body1">
                    Turns the current Google Sheets topics file into the default topic sheet for Pipeline 1
                </Typography>
            </Box>
            <ErrorMessage error={error} />
        </>
    )
}

export default UpdateTopicSheet
