import { Box, makeStyles, Typography } from "@material-ui/core"
import React, { ChangeEvent, FC } from "react"
import { TopicSheet } from "../types/types"

const useStyles = makeStyles({
    select: {
        marginTop: 16,
        width: 263,
        padding: 8,
        border: "1px solid #D4D3D3",
        borderRadius: 8,
        color: "#2F232D",
        outline: 0
    },
    label: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "15px",
        color: "#2F232D"
    }
})

interface Props {
    topicSheets?: TopicSheet[]
    selectedTopicSheet?: TopicSheet
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void
    keySuffix: string
}

const SelectTopicSheet: FC<Props> = ({ topicSheets, onChange, keySuffix }) => {
    const classes = useStyles()

    return (
        <>
            {topicSheets ? (
                <>
                    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        Choose topic sheet:
                    </Typography>
                    <Box marginBottom={1}>
                        <select className={classes.select} onChange={onChange}>
                            <option value="">{""}</option>
                            {topicSheets.map(sheet => (
                                <option key={sheet.filename + keySuffix} value={sheet.filename}>
                                    {sheet.filename}
                                </option>
                            ))}
                        </select>
                    </Box>
                </>
            ) : (
                <Typography variant="subtitle2">Loading topic sheets...</Typography>
            )}
        </>
    )
}

export default SelectTopicSheet
