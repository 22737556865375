import config from "./config.json"

export const baseUrl = `${config.API_PROTOCOL}://${config.API_URL}`
export const commit_sha = config.COMMIT_SHA
export const revision_id = config.REVISION_ID
export const build_id = config.BUILD_ID

export enum UserCreationState {
    UNAPPROVED = "unapproved",
    APPROVED = "approved"
}

export enum MediaType {
    VIDEO = "video",
    AUDIO = "audio"
}

export enum LoginState {
    NOT_REGISTERED,
    NOT_APPROVED,
    SUCCESS,
    UNKNOWN
}

export enum CeleryTask {
    process_media = "process_media",
    update_topic_sheet = "update_topic_sheet"
}

export const projectId = config.PROJECT_ID

export const useSentryLogger: boolean = config.USE_SENTRY_LOGGER === "true"
export const sentryProjectTag = config.SENTRY_PROJECT_TAG
export const uploadBucket = config.MEDIA_BUCKET_NAME
export const uploadBucketInputFolder = "media-input"
export const uploadBucketOutputFolder = "media-output"
export const clientName = "dbmo"
export const summaryImagesFolder = "summary_frames"

const graphqlPort = config.GRAPHQL_PORT
const graphqlAddress = config.GRAPHQL_URL
const wsProtocol = config.GRAPHQL_WS_PROTOCOL
const httpProtocol = config.GRAPHQL_HTTP_PROTOCOL

export const graphqlWsUri = `${wsProtocol}://${graphqlAddress}:${graphqlPort}/v1/graphql`
export const graphqlHttpUri = `${httpProtocol}://${graphqlAddress}:${graphqlPort}/v1/graphql`

export const VideoWidth = 625

export const mediaUuidKey = "mediaUuid"

export const userIdKey = "dbmo-userId"
export const accessTokenKey = "dbmo-accessToken"
export const accessTokenExpKey = "dbmo-accessTokenExp"
export const refreshTokenKey = "dbmo-refreshToken"
export const refreshTokenExpKey = "dbmo-refreshTokenExp"
export const refreshTokenIntervalInMillieconds = 15 * 60 * 1000
