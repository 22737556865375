import { ArtifactJson, Frame, Topic, Word } from "../../../types/types"

const fixedAttributes: { [key: string]: string[] } = {
    // Billions S2E12
    "9d6f8252-d905-49cc-bcc5-ccb10d607a33": [
        "cinematography dark/gray",
        "dialog-heavy",
        "law-enforcement/prosecution",
        "drama",
        "marital issues/divorce",
        "legal",
        "finance",
        "adult language"
    ],
    // D&M s2e2
    "69e11cdb-f936-4060-86d8-92580066358b": ["comedy", "magazine", "adult language"],
    // D&M s2e46
    "80803e0c-2265-4f24-8d50-8639659ce660": ["comedy", "magazine", "adult language"],
    // The Circus s5e13
    "6e5939f7-a293-4a87-8390-1f54c7e2ccc7": ["documentary", "serious", "interview"],
    // HowStuffWorks - Salt
    "34fe7545-9d59-455a-a1b2-68de7fccae07": ["heavy voiceover", "informative/educational", "food"],
    // HowStuffWorks - Corn
    "dc071d5e-d0d7-4a69-8294-926044171b48": ["heavy voiceover", "informative/educational", "food", "science"],
    // dn2020-0429_vid_1min.mp4
    "0195f5ae-e9d2-4e16-be3e-e34de6748645": ["political", "news"],
    // 38m_720p_the-talk_s11_e48.mp4
    "fbad46f5-b020-45c2-ba50-672b71612718": ["Interview"],
    // Amazing Race S32 E1,
    "bd32c1f5-9bdb-434f-b90b-5ada7f511159": ["Fast Pace", "Travel"]
}

// used to sort themes/topics by confidence score (descending)
const compareTopicsByConfidenceScore = (a: Topic, b: Topic) => {
    if (a.confidence === b.confidence) {
        return 0
    }

    return a.confidence < b.confidence ? 1 : -1
}

export const getAttributesFromArtifact = (
    mediaArtifact: ArtifactJson,
    { getThemeAttributes }: { getThemeAttributes: boolean }
) => {
    const { media_uuid, themes } = mediaArtifact
    let attributes: string[] = []
    if (getThemeAttributes) {
        // add theme attributes (currently limiting to 9)
        let sortedThemes: Topic[] = themes.slice().sort(compareTopicsByConfidenceScore).slice(0, 9)

        sortedThemes.forEach(theme => {
            const topic = theme.topic
            const confidence = theme.confidence.toFixed(3)

            attributes.push(`${topic} ${confidence}`)
        })
    }

    const hardCodedAttributes = fixedAttributes?.[media_uuid]
    if (hardCodedAttributes && hardCodedAttributes.length) {
        attributes = [...hardCodedAttributes, ...attributes]
    }

    return attributes
}

export const getTopicsFromArtifact = (
    mediaArtifact: ArtifactJson,
    topicFilter: string,
    topicConfidence: number,
    getAllTopics: boolean
): { topics: Topic[]; allTopics: Topic[] } => {
    let newTopics = mediaArtifact.topics
        .filter(topic => topic.confidence >= topicConfidence && topic.signal > 0)
        .sort(compareTopicsByConfidenceScore)

    if (topicFilter !== "All") {
        newTopics = newTopics.filter(topic => topic.topicCategory?.name === topicFilter)
    }

    return {
        topics: getAllTopics ? newTopics : newTopics.slice(0, 9),
        allTopics: newTopics
    }
}

export const getWordsAndFramesFromArtifact = (
    newTopics: Topic[],
    mediaArtifact: ArtifactJson
): {
    newWords: Word[]
    newFrames: Frame[]
} => {
    const seenWordIds: number[] = []
    const newWords: Word[] = []
    const seenFrameIds: number[] = []
    const newFrames: Frame[] = []
    newTopics.forEach(topic => {
        mediaArtifact.phrases?.forEach(word => {
            if (word.topics.map(topic => topic.id).includes(topic.id) && !seenWordIds.includes(word.id)) {
                seenWordIds.push(word.id)
                newWords.push(word)
            }
        })
        mediaArtifact.video_frames?.forEach(frame => {
            frame.objects.forEach(object => {
                if (object?.topics?.map(topic => topic.id).includes(topic.id) && !seenFrameIds.includes(frame.id)) {
                    seenFrameIds.push(frame.id)
                    newFrames.push(frame)
                }
            })
        })
    })

    return { newWords, newFrames }
}

export const getTotalInstancesCount = ({
    newTopics,
    words,
    frames
}: {
    newTopics: Topic[]
    words?: Word[]
    frames?: Frame[]
}) => {
    const seenTopicWordIds: number[] = []
    const topicWords: Word[] = []
    const seenTopicFrameIds: number[] = []
    const topicFrames: Frame[] = []

    newTopics.forEach(topic => {
        words?.forEach(word => {
            if (word.topics.map(topic => topic.id).includes(topic.id) && !seenTopicWordIds.includes(word.id)) {
                seenTopicWordIds.push(word.id)
                topicWords.push(word)
            }
        })
        frames?.forEach(frame => {
            frame.objects.forEach(object => {
                if (
                    object?.topics?.map(topic => topic.id).includes(topic.id) &&
                    !seenTopicFrameIds.includes(frame.id)
                ) {
                    seenTopicFrameIds.push(frame.id)
                    topicFrames.push(frame)
                }
            })
        })
    })

    return topicWords.length + topicFrames.length
}
