import { Box, makeStyles, Typography } from "@material-ui/core"
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"
import {
    build_id,
    CeleryTask,
    clientName,
    commit_sha,
    revision_id,
    uploadBucket,
    uploadBucketInputFolder
} from "../../constants"
import { GetMediaVars, useGetProcessedMedia } from "../../graphql/media"
import { Pipeline, useGetPipelines } from "../../graphql/pipeline"
import { useSessionStore } from "../../hooks/useSessionStore"
import { useApiService } from "../../providers/ApiServiceProvider"
import { useSetResultsVideo } from "../../store"
import { BulkImportResult, Media } from "../../types/types"
import DbmoButton from "../Button"
import Input from "../Input"
import ChangeDefaultTopicSheet from "./ChangeDefaultTopicSheet"
import UpdateTopicSheet from "./UpdateTopicSheet"

const DARK_COLOR = "#2F232D"

const useStyles = makeStyles({
    triggerPipeline: {
        width: 501
    },
    select: {
        marginTop: 16,
        width: 263,
        padding: 8,
        border: "1px solid #D4D3D3",
        borderRadius: 8,
        color: "#2F232D",
        outline: 0
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    button: {
        marginTop: 16,
        width: "100%"
    },
    useCache: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "20px",
        color: DARK_COLOR
    },
    videoTitle: {
        height: 49
    },
    sideNavSection: {
        marginBottom: 16,
        paddingBottom: 16,
        borderBottom: `1px solid ${DARK_COLOR}`
    }
})

interface Props {
    media?: Media
    setMedia: (media?: Media) => void
    isAdmin?: boolean
}

const TriggerPipeline: FC<Props> = ({ media }) => {
    const classes = useStyles()
    const history = useHistory()
    const ApiService = useApiService()
    const setResultsMedia = useSetResultsVideo()
    const sessionStore = useSessionStore()
    const loginData = sessionStore.session

    const vars: GetMediaVars = {
        mediaWhereExp: {
            publisher: { _nin: ["Los Angeles Times", "The Atlantic"] },
            type: { _eq: "video" },
            is_processed: {_eq: true}
        }
    }

    const mediaData = useGetProcessedMedia(vars)

    const [fetching, setFetching] = useState<boolean>(false)

    // trigger single video form
    const [error, setError] = useState<boolean>(false)
    const [mediaUrl, setMediaUrl] = useState<string | undefined>(undefined)
    const pipelinesData = useGetPipelines()
    const [pipeline, setPipeline] = useState<Pipeline | undefined>(undefined)

    // bulk importer form
    const [manifestPathAndFilename, setManifestBucketPath] = useState<string>("")
    const [bulkImportError, setBulkImportError] = useState<boolean>(false)

    // re-run all videos form
    const [rerunError, setRerunError] = useState<boolean>(false)

    const getReadableSignedUrl = useCallback(async () => {
        if (!media) {
            return
        }
        const readableSignedUrlResponse = await ApiService.getReadableSignedUrl(
            `${uploadBucketInputFolder}/${clientName}/${media.file_name}`,
            media.type
        )
        if (!readableSignedUrlResponse) {
            setError(true)
            return
        }
        setMediaUrl(readableSignedUrlResponse.data)
    }, [ApiService, media])

    useEffect(() => {
        if (media && !mediaUrl) {
            getReadableSignedUrl()
        }
    }, [loginData, media, mediaUrl, history, ApiService, getReadableSignedUrl])

    const onChangePipeline = (event: ChangeEvent<HTMLSelectElement>) => {
        if (!pipelinesData.data) {
            setError(true)
            return
        }
        const selectedPipeline = pipelinesData.data.pipeline.find(p => p.dag_name === event.target.value)
        setPipeline(selectedPipeline)
    }

    const triggerPipeline = async () => {
        if (!media || !loginData?.userId) {
            setError(true)
            return
        }
        setFetching(true)
        const topicSheetName = undefined
        const triggerResponse = await ApiService.triggerPipeline(
            loginData.userId,
            [media.uuid],
            pipeline?.dag_name ?? CeleryTask.process_media,
            topicSheetName
        )
        if (!triggerResponse) {
            setError(true)
            setFetching(false)
            return
        }
        const readableSignedUrlResponse = await ApiService.getReadableSignedUrl(
            `${uploadBucketInputFolder}/${clientName}/${media.file_name}`,
            media.type
        )
        setFetching(false)
        if (!readableSignedUrlResponse) {
            setError(true)

            return
        }
        setResultsMedia({
            media,
            mediaUrl: readableSignedUrlResponse.data
        })
    }

    const bulkImport = async () => {
        setBulkImportError(false)
        if (!manifestPathAndFilename || !loginData) {
            setBulkImportError(true)
            return
        }
        setFetching(true)
        const response = await ApiService.bulkImport(
            loginData.userId!,
            manifestPathAndFilename,
            pipeline?.dag_name ?? CeleryTask.process_media
        )
        setFetching(false)
        if (!response) {
            setBulkImportError(true)
            return
        } else if (!!response.data.error) {
            let formErrors = ""
            if (response.formErrors) {
                response.formErrors.forEach(formError => {
                    formErrors = `${formErrors}   ${formError.field}: ${formError.messages.join("\n")}`
                })
            }
            window.alert(`ERROR: ${response.message}\n${formErrors}`)
            setBulkImportError(true)
            return
        } else {
            // Handle success
            let message = `Success. importing ${response.data.results.length} videos.`
            const locations = response.data.results.map((res: BulkImportResult) => res.url)
            message = `${message}\n${locations.join("\n")}`
            window.alert(message)

            setManifestBucketPath("")
        }
    }

    const getAllMediaUuids = (mediaData: any): string[] => {
        return mediaData.data?.media.map((media: Media) => {
            return media.uuid
        })
    }

    const rerunAllVideos = async () => {
        if (!loginData?.userId) {
            setError(true)
            return
        }
        setRerunError(false)
        setFetching(true)
        const topicSheetName = undefined
        const mediaUuids = getAllMediaUuids(mediaData)
        const response = await ApiService.triggerPipeline(
            loginData.userId,
            mediaUuids,
            pipeline?.dag_name ?? CeleryTask.process_media,
            topicSheetName
        )
        setFetching(false)
        if (!response) {
            setRerunError(true)
            return
        } else {
            // Handle success
            const message = `${response.data.message}\n\n${response.data.mediaUuids.join("\n")}`
            window.alert(message)
        }
    }

    const onDownloadManifest = () => {
        window.location.href = "/bulk_import_example/manifest.csv"
    }

    const onChangeBulkImportPath = (event: ChangeEvent<HTMLInputElement>) => {
        setManifestBucketPath(event.target.value)
    }

    return (
        <div className={classes.triggerPipeline}>
            <div className={classes.sideNavSection}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Trigger Pipeline
                </Typography>
                <Box marginTop={2}>
                    {pipelinesData.data ? (
                        <>
                            <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                                Choose pipeline (optional):
                            </Typography>
                            <select className={classes.select} onChange={onChangePipeline}>
                                <option value="">{""}</option>
                                {pipelinesData.data.pipeline.map((pipeline, i) => (
                                    <option key={i} value={pipeline.dag_name}>
                                        {pipeline.dag_name}
                                    </option>
                                ))}
                            </select>
                        </>
                    ) : (
                        <Typography variant="subtitle2">Loading pipelines...</Typography>
                    )}
                </Box>
                <Box marginTop={2} className={classes.videoTitle}>
                    {media ? (
                        <>
                            <Typography variant="subtitle1">
                                {media.show && `${media.show}`}
                                {media.show && media.season_episode && " | "}
                                {media.season_episode && `${media.season_episode}`}
                                {(media.show || media.season_episode) && media.publisher && " | "}
                                {media.publisher && `${media.publisher}`}
                            </Typography>
                            <Typography>Updated at: {media.updated_at}</Typography>
                        </>
                    ) : (
                        <Typography variant="subtitle1">No video selected</Typography>
                    )}
                </Box>
                <Box marginTop={2} className={classes.center}>
                    <DbmoButton onClick={triggerPipeline} disabled={!media} className={classes.button}>
                        Trigger Pipeline
                    </DbmoButton>
                </Box>
                {error && (
                    <Typography variant="body1">
                        {"There was an error "}
                        <span role="img" aria-label="disappointed face">
                            😞
                        </span>
                    </Typography>
                )}
            </div>

            <div className={classes.sideNavSection}>
                <ChangeDefaultTopicSheet />
            </div>

            <div className={classes.sideNavSection}>
                <UpdateTopicSheet />
            </div>

            {/* Rerun all videos section */}
            <div className={classes.sideNavSection}>
                <Box marginTop={2}>
                    <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                        Re-run all processed videos.
                    </Typography>
                </Box>
                <Box marginTop={2} className={classes.center}>
                    <DbmoButton onClick={rerunAllVideos} disabled={fetching} className={classes.button}>
                        Re-run Videos
                    </DbmoButton>
                </Box>
                {rerunError && (
                    <Typography variant="body1">
                        {"There was an error "}
                        <span role="img" aria-label="disappointed face">
                            😞
                        </span>
                    </Typography>
                )}
            </div>

            {/* Bulk import section */}
            <div className={classes.sideNavSection}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Bulk Import from Google Bucket
                </Typography>
                <Box marginTop={2}>
                    <Input
                        onChange={onChangeBulkImportPath}
                        label="Manifest file location"
                        placeholder="path/to/manifest.csv"
                    />
                </Box>
                <Box marginTop={2}>
                    <Typography variant="body1">
                        Enter the location to the manifest CSV file on the {uploadBucket} Google bucket, i.e.
                        media-input/dbmo/path/to/videos/my_manifest.csv. Do not include the bucket name.
                    </Typography>
                    <Typography variant="body1">
                        The directory containing the manifest file and all the audio, video, and text files in the
                        manifest file. The bulk import feature starts a pipeline run for every media entry listed in the
                        manifest file. Media types are determined from the filename extension. Valid extensions are mp3,
                        mp4, and txt.
                    </Typography>
                </Box>
                <Box marginTop={2}>
                    <DbmoButton onClick={onDownloadManifest} className={classes.button}>
                        Download Example manifest.csv
                    </DbmoButton>
                </Box>
                <Box marginTop={2} className={classes.center}>
                    <DbmoButton
                        onClick={bulkImport}
                        disabled={!manifestPathAndFilename || fetching}
                        className={classes.button}
                    >
                        Bulk Import
                    </DbmoButton>
                </Box>
                {bulkImportError && (
                    <Typography variant="body1">
                        {"There was an error "}
                        <span role="img" aria-label="disappointed face">
                            😞
                        </span>
                    </Typography>
                )}
            </div>

            {/* Build info */}
            <div>
                <div>Commit SHA: {commit_sha}</div>
                <div>Revision Id: {revision_id}</div>
                <div>Build Id: {build_id}</div>
            </div>
        </div>
    )
}

export default TriggerPipeline
