import { Box, makeStyles, Tooltip } from "@material-ui/core"
import React, { FC } from "react"
import { FileText, Settings, Video } from "react-feather"
import { Link, useLocation } from "react-router-dom"
import { useGetResultsVideo } from "../store"
import Logo from "./Logo"

const useStyles = makeStyles({
    navbar: {
        height: 48,
        width: "100%",
        backgroundColor: "#F9F9FB",
        borderBottom: "1px solid #D4D3D3",
        paddingLeft: 48,
        paddingRight: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        zIndex: 100
    },
    logo: {
        borderRadius: "50%",
        height: 16,
        width: 16,
        backgroundColor: "#004FFF"
    },
    sideLink: {
        height: 16
    },
    logoLink: {
        height: 24
    },
    sideLinks: {
        display: "flex",
        alignItems: "center"
    }
})

const Navigation: FC = () => {
    const classes = useStyles()
    const location = useLocation()
    const resultsMedia = useGetResultsVideo()

    const getAdminRoute = (route: string): string => {
        if (route.includes("choose-video")) {
            return "/select-video"
        } else if (route.includes("select-video")) {
            return "/choose-video"
        } else if (route.includes("/results")) {
            return `/admin/${resultsMedia?.media.uuid}`
        } else if (route.includes("admin")) {
            return `/results/${resultsMedia?.media.uuid}`
        }
        return "/choose-video"
    }

    return (
        <nav className={classes.navbar}>
            <Link to="/choose-video" className={classes.logoLink}>
                <Logo />
            </Link>
            <div className={classes.sideLinks}>
                <Box marginRight={2} className={classes.sideLink}>
                    <Link to={"/choose-article"} className={classes.sideLink}>
                        <Tooltip title="Choose Articles">
                            <FileText color="#5E65F6" size={16} strokeWidth={2} />
                        </Tooltip>
                    </Link>
                </Box>

                <Box marginRight={2} className={classes.sideLink}>
                    <Link to={"/choose-video"} className={classes.sideLink}>
                        <Tooltip title="Choose Videos">
                            <Video color="#5E65F6" size={16} strokeWidth={2} />
                        </Tooltip>
                    </Link>
                </Box>

                <Box marginRight={2} className={classes.sideLink}>
                    <div className={classes.sideLink}>
                        <Link to={getAdminRoute(location.pathname)} className={classes.sideLink}>
                            <Tooltip title="Admin">
                                <Settings color="#5E65F6" size={16} strokeWidth={2} />
                            </Tooltip>
                        </Link>
                    </div>
                </Box>
            </div>
        </nav>
    )
}

export default Navigation
