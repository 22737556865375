import { CircularProgress, Grid, makeStyles } from "@material-ui/core"
import React, { FC, MouseEvent } from "react"
import { useHistory, useLocation, useParams } from "react-router"
import { useRecoilValue } from "recoil"
import { GetMediaVars, useGetProcessedMedia } from "../../graphql/media"
import { orgSlugState } from "../../store"
import { Media } from "../../types/types"
import VideoCard from "./VideoCard"

const useStyles = makeStyles({
    videoChoices: {
        display: "flex",
        flex: "grow",
        paddingTop: 12,
        overflowY: "scroll",
        marginLeft: -12
    },
    videoChoice: {
        border: 0,
        outline: 0,
        padding: 12,
        margin: 0,
        backgroundColor: "transparent",
        cursor: "pointer"
    },
    selectedChoice: {
        border: "4px solid #004FFF"
    },
    text: {
        overflowWrap: "break-word",
        color: "#004FFF"
    },
    loading: {
        paddingTop: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    loadingCircle: {
        color: "#004FFF"
    }
})

interface Props {
    searchQuery?: string
    setMedia: (media: Media) => void
}

const VideoChoices: FC<Props> = ({ searchQuery, setMedia }) => {
    const classes = useStyles()
    const { mode } = useParams<{ mode: string }>()

    const orgSlug = useRecoilValue(orgSlugState)
    
    const vars: GetMediaVars = {
        mediaWhereExp: {
            _or: [
                { publisher: { _nin: ["Los Angeles Times", "The Atlantic"] } },
                { publisher: { _is_null: true } }
            ],
            type: { _eq: "video" },
            org: { slug: { _eq: orgSlug } },
            is_processed: {_eq: true}
        }
    }
    if (mode === 'atlantic') {
        vars.mediaWhereExp = {
            publisher: { _eq: "The Atlantic" },
            type: { _eq: "text" },
            is_processed: {_eq: true}
        }
    }
    const mediaData = useGetProcessedMedia(vars)
    
    const history = useHistory()
    const location = useLocation()

    const onClick = (uuid: string) => (event: MouseEvent<HTMLButtonElement>) => {
        const media = mediaData.data?.media.find(m => m.uuid === uuid)
        if (!media) {
            return
        }
        setMedia(media)
        if (location.pathname === "/select-video") {
            return
        }
        history.push(`/results/${media.uuid}`)
    }

    const showCard = (media: Media) => {
        const filename = !!media.file_name ? media.file_name : ""

        if (!searchQuery) {
            return true
        }
        return (filename.toLowerCase().includes(searchQuery) ||
                media.title.toLowerCase().includes(searchQuery) ||
                media.season_episode?.toLowerCase().includes(searchQuery) ||
                media.publisher?.toLowerCase().includes(searchQuery)
        )
    }

    return (
        <div className={classes.videoChoices}>
            {mediaData.data ? (
                <Grid container={true}>
                    {mediaData.data.media.map((media, index) => {
                        if (showCard(media)) {
                            return (
                                <Grid item={true} key={`video choice ${media.uuid}`}>
                                    <button
                                        key={media.uuid}
                                        className={classes.videoChoice}
                                        onClick={onClick(media.uuid)}
                                    >
                                        <VideoCard media={media} index={index} />
                                    </button>
                                </Grid>
                            )
                        }
                        return null
                    })}
                </Grid>
            ) : (
                <div className={classes.loading}>
                    <CircularProgress className={classes.loadingCircle} />
                </div>
            )}
        </div>
    )
}

export default VideoChoices
