import React, { useCallback, useEffect } from "react"
import { atom, useRecoilState } from "recoil"
import { LoginState } from "../constants"
import { useApiService } from "../providers/ApiServiceProvider"
import { AuthenticateResponse, LoginData } from "../types/types"
import { useStorageStore } from "./useStorageStore"

const sessionState = atom<LoginData | undefined>({
    key: "Session",
    default: undefined
})

/**
 * This is for in memory login data and does not persist through refresh.
 */
export const useSessionStore = () => {
    const [session, setSession] = useRecoilState(sessionState)
    const storageStore = useStorageStore()
    const ApiService = useApiService()
    const [isBootstrapped, setIsBootstrapped] = React.useState<boolean>(false)
    const [path, setPath] = React.useState<string>("")

    useEffect(() => {
        if (!isBootstrapped) {
            if (storageStore.loginData) {
                console.log(`storageStore.loginData ${storageStore.loginData}`)
                setSession(storageStore.loginData)
            }
        }
        setIsBootstrapped(true)
    }, [isBootstrapped, storageStore.loginData, setSession])

    useEffect(() => {
        if (storageStore.lastPath && storageStore.lastPath.length > 1) {
            setPath(storageStore.lastPath)
        }
    }, [storageStore.lastPath])

    return {
        login: useCallback(
            async (username: string, password: string): Promise<AuthenticateResponse> => {
                const response = await ApiService.authenticate(username, password)
                if (!response) {
                    return ({
                        error: "Unknown error",
                        message: "Unknown error. Response was falsey",
                        statusCode: 500,
                        data: {
                            loginState: LoginState.UNKNOWN
                        }
                    } as unknown) as AuthenticateResponse
                } else if (response.error) {
                    return response
                }

                // All is good so contninue setting loginData
                storageStore.setLoginData(response.data)
                return response
            },
            [ApiService, storageStore]
        ),
        session,
        isBootstrapped,
        path
    }
}
