import { makeStyles } from "@material-ui/core"
import React, { FC } from "react"
import { CookiesProvider } from "react-cookie"
import { Route, Switch } from "react-router"
import { BrowserRouter } from "react-router-dom"
import { LastLocationProvider } from "react-router-last-location"
import { RecoilRoot } from "recoil"
import { useSessionStore } from "../hooks/useSessionStore"
import ApiServiceProvider from "../providers/ApiServiceProvider"
import { GraphQLProvider } from "../providers/GraphQLProvider"
import Admin from "./admin/Admin"
import Comparison from "./admin/Comparison"
import SelectVideo from "./admin/SelectVideo"
import ArticleResults from "./articles/ArticleResults"
import ChooseArticle from "./articles/ChooseArticle"
import BtSandbox from "./BtSandbox"
import ChooseVideo from "./chooseVideo/ChooseVideo"
import Login from "./Login"
import Navigation from "./Navigation"
import Register from "./Register"
import Results from "./results/Results"
import Theme from "./Theme"

const useStyles = makeStyles({
    app: {
        backgroundColor: "#F9F9FB",
        height: "100vh",
        overflow: "auto"
    },
    content: {
        display: "flex",
        flex: "grow",
        color: "black",
        paddingTop: 48,
        paddingBottom: 40,
        width: "fit-content",
        margin: "auto"
    }
})

const Root = () => {
    const classes = useStyles()
    const sessionStore = useSessionStore()

    // Wait until we check persistent storage for login data.
    if (!sessionStore.isBootstrapped) {
        return null
    }

    return (
        <>
            {/* PUBLIC ROUTES */}
            {!sessionStore.session && (
                <Switch>
                    <Route exact={true} path="/">
                        <div className={classes.app}>
                            <main className={classes.content}>
                                <Login />
                            </main>
                        </div>
                    </Route>
                    <Route path="/register">
                        <div className={classes.app}>
                            <main className={classes.content}>
                                <Register />
                            </main>
                        </div>
                    </Route>

                    <Route path="/bt-sandbox">
                        <div className={classes.app}>
                            <main className={classes.content}>
                                <BtSandbox />
                            </main>
                        </div>
                    </Route>

                    <Route path="*">
                        <main className={classes.content}>
                            <Login />
                        </main>
                    </Route>
                </Switch>
            )}

            {/* PRIVATE ROUTES */}
            {sessionStore.session && (
                <GraphQLProvider>
                    <Switch>
                        <Route path="/choose-video/:mode">
                            <ChooseVideo />
                        </Route>
                        <Route path="/select-video">
                            <SelectVideo />
                        </Route>
                        <Route path="/results/:uuid">
                            <Results />
                        </Route>
                        <Route path="/choose-article">
                            <ChooseArticle />
                        </Route>
                        <Route path="/article-results/:uuid">
                            <ArticleResults />
                        </Route>
                        <Route path="/admin/:uuid">
                            <Admin />
                        </Route>
                        <Route path="/comparison">
                            <Comparison />
                        </Route>
                        {/* A catch all for bad URLs. In a "real" app this would redirect to a page not found component */}
                        <Route path="*">
                            <ChooseVideo />
                        </Route>
                    </Switch>
                </GraphQLProvider>
            )}
        </>
    )
}

const App: FC = () => {
    const classes = useStyles()

    return (
        <Theme>
            <CookiesProvider>
                <RecoilRoot>
                    <ApiServiceProvider>
                        <BrowserRouter>
                            <LastLocationProvider>
                                <div className={classes.app}>
                                    <Navigation />
                                    <main className={classes.content}>
                                        <Root />
                                    </main>
                                </div>
                            </LastLocationProvider>
                        </BrowserRouter>
                    </ApiServiceProvider>
                </RecoilRoot>
            </CookiesProvider>
        </Theme>
    )
}

export default App
