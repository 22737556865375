import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useGetFullProcessedMedia } from "../../graphql/media"
import { MediaWithArtifact, SimilarityMatch } from "../../types/types"
import Button from "../Button"
import Match from "./Match"

const useStyles = makeStyles({
    results: {
        display: "flex",
        marginTop: 24
    },
    resultsSection: {
        width: 501,
        marginRight: 48,
        marginTop: 24
    },
    line: {
        width: 501,
        height: 1,
        backgroundColor: "#D4D3D3",
        marginTop: 24,
        marginBottom: 24
    },
    bold: {
        fontWeight: 600
    },
    mediaText: {
        backgroundColor: "#FFFFFF",
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        height: 400,
        width: 501,
        overflowY: "auto",
        borderRadius: "8px 8px 0px 0px",
        border: "1px solid #D4D3D3",
        marginTop: 16,
        position: "relative"
    },
    button: {
        width: "100%"
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    matches: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        borderRadius: 8,
        width: 721
    },
    matchesTopSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #D4D3D3",
        minHeight: 72,
        padding: 24,
        paddingLeft: 48,
        paddingRight: 48
    },
    matchesBottomSection: {
        paddingLeft: 36,
        paddingRight: 36,
        paddingTop: 12,
        paddingBottom: 12,
        display: "flex",
        flexWrap: "wrap"
    },
    loading: {
        paddingTop: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    loadingCircle: {
        color: "#004FFF"
    }
})

const ArticleResults: FC = () => {
    const NUM_MATCHES = 6
    const classes = useStyles()
    const history = useHistory()
    const { uuid } = useParams<{ uuid: string }>()
    const [media, setMedia] = useState<MediaWithArtifact>()
    const { data } = useGetFullProcessedMedia(uuid)

    useEffect(() => {
        if (!media && data) {
            setMedia(data.media[0])
        }
    }, [data, media, uuid])

    const chooseAnother = () => history.push("/choose-article")

    return (
        <div className={classes.results}>
            <section className={classes.resultsSection}>
                {media ? (
                    <>
                        <Box marginBottom={3}>
                            <Typography className={classes.bold} variant="h5" component="h1">
                                {media.title}
                            </Typography>
                        </Box>
                        <Typography variant="subtitle1">
                            {media.show && `${media.show}`}
                            {media.show && media.season_episode && " | "}
                            {media.season_episode && `${media.season_episode}`}
                            {(media.show || media.season_episode) && media.publisher && " | "}
                            {media.publisher && `${media.publisher}`}
                        </Typography>
                    </>
                ) : (
                    <div className={classes.loading}>
                        <CircularProgress className={classes.loadingCircle} />
                    </div>
                )}
                <div className={classes.line} />
                {media && <div className={classes.mediaText}>{media.media_text}</div>}
                <Box className={classes.center} marginTop={3}>
                    <Button className={classes.button} onClick={chooseAnother}>
                        Choose Another Asset
                    </Button>
                </Box>
            </section>
            <section className={classes.matches}>
                <div className={classes.matchesTopSection}>
                    <Typography className={classes.bold} variant="h5" component="h2">
                        Matches
                    </Typography>
                    <Typography variant="subtitle2">
                        {media?.latest_media_artifact?.artifact_json.similarity_matches?.slice(0, NUM_MATCHES).length ??
                            0}
                        Media Assets Found
                    </Typography>
                </div>
                {media ? (
                    <div className={classes.matchesBottomSection}>
                        {media.latest_media_artifact?.artifact_json.similarity_matches
                            ?.slice(0, NUM_MATCHES)
                            .map((match: SimilarityMatch) => {
                                return <Match key={`match ${match.match_media_uuid}`} similarityMatch={match} />
                            })}
                    </div>
                ) : (
                    <div className={classes.loading}>
                        <CircularProgress className={classes.loadingCircle} />
                    </div>
                )}
            </section>
        </div>
    )
}

export default ArticleResults
