export const objectLabelData = [
    {
    frame_number: 30,
    time_in_milliseconds: 1001.0000000000001,
    labels: [
    {
    description: "Black",
    confidence: 98.5137939453125
    },
    {
    description: "Darkness",
    confidence: 97.84991145133972
    },
    {
    description: "White",
    confidence: 97.05721139907837
    },
    {
    description: "Sky",
    confidence: 95.50930857658386
    },
    {
    description: "Red",
    confidence: 93.64120364189148
    },
    {
    description: "Light",
    confidence: 92.31409430503845
    },
    {
    description: "Brown",
    confidence: 89.29230570793152
    },
    {
    description: "Text",
    confidence: 89.01308178901672
    },
    {
    description: "Night",
    confidence: 86.5154504776001
    },
    {
    description: "Atmosphere",
    confidence: 86.26090288162231
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 59,
    time_in_milliseconds: 1968.0000000000002,
    labels: [
    {
    description: "News",
    confidence: 77.50797271728516
    },
    {
    description: "Photo caption",
    confidence: 72.76957631111145
    },
    {
    description: "Official",
    confidence: 70.51995396614075
    },
    {
    description: "Event",
    confidence: 62.736958265304565
    },
    {
    description: "Businessperson",
    confidence: 60.628485679626465
    }
    ],
    objects: [
    {
    description: "Tie",
    confidence: 0.9638057351112366,
    bounding_box: [
    {
    x: 0.39071786403656006,
    y: 0.557913601398468
    },
    {
    x: 0.4566049575805664,
    y: 0.557913601398468
    },
    {
    x: 0.4566049575805664,
    y: 0.8416071534156799
    },
    {
    x: 0.39071786403656006,
    y: 0.8416071534156799
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8826584219932556,
    bounding_box: [
    {
    x: 0.16922211647033691,
    y: 0.1818915754556656
    },
    {
    x: 0.5993244647979736,
    y: 0.1818915754556656
    },
    {
    x: 0.5993244647979736,
    y: 0.9385451674461365
    },
    {
    x: 0.16922211647033691,
    y: 0.9385451674461365
    }
    ]
    },
    {
    description: "Suit",
    confidence: 0.6420298218727112,
    bounding_box: [
    {
    x: 0.17007307708263397,
    y: 0.3492525517940521
    },
    {
    x: 0.5995714664459229,
    y: 0.3492525517940521
    },
    {
    x: 0.5995714664459229,
    y: 0.9523311853408813
    },
    {
    x: 0.17007307708263397,
    y: 0.9523311853408813
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 523,
    time_in_milliseconds: 17451,
    labels: [
    {
    description: "Black",
    confidence: 98.46998453140259
    },
    {
    description: "Darkness",
    confidence: 96.94660305976868
    },
    {
    description: "White",
    confidence: 96.28557562828064
    },
    {
    description: "Sky",
    confidence: 93.81980895996094
    },
    {
    description: "Text",
    confidence: 93.07222962379456
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Light",
    confidence: 90.99878668785095
    },
    {
    description: "Font",
    confidence: 88.54238986968994
    },
    {
    description: "Brown",
    confidence: 88.46124410629272
    },
    {
    description: "Atmosphere",
    confidence: 80.43394088745117
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 552,
    time_in_milliseconds: 18418,
    labels: [
    {
    description: "Dog",
    confidence: 99.53705072402954
    },
    {
    description: "Mammal",
    confidence: 98.90478253364563
    },
    {
    description: "Vertebrate",
    confidence: 98.51104021072388
    },
    {
    description: "Dog breed",
    confidence: 98.38448762893677
    },
    {
    description: "Canidae",
    confidence: 98.13780188560486
    },
    {
    description: "Beagle",
    confidence: 97.77058959007263
    },
    {
    description: "Carnivore",
    confidence: 93.4210479259491
    },
    {
    description: "Pocket beagle",
    confidence: 91.77473783493042
    },
    {
    description: "Puppy",
    confidence: 89.14316296577454
    },
    {
    description: "Snout",
    confidence: 83.67116451263428
    }
    ],
    objects: [
    {
    description: "Dog",
    confidence: 0.901736855506897,
    bounding_box: [
    {
    x: 0.3213675916194916,
    y: 0.15809033811092377
    },
    {
    x: 0.6578839421272278,
    y: 0.15809033811092377
    },
    {
    x: 0.6578839421272278,
    y: 0.8837296962738037
    },
    {
    x: 0.3213675916194916,
    y: 0.8837296962738037
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 610,
    time_in_milliseconds: 20353,
    labels: [
    {
    description: "Text",
    confidence: 98.9824891090393
    },
    {
    description: "Font",
    confidence: 98.6213207244873
    },
    {
    description: "Black",
    confidence: 98.35326671600342
    },
    {
    description: "Darkness",
    confidence: 97.57517576217651
    },
    {
    description: "White",
    confidence: 95.47066688537598
    },
    {
    description: "Light",
    confidence: 91.74991846084595
    },
    {
    description: "Sky",
    confidence: 88.57545852661133
    },
    {
    description: "Monochrome photography",
    confidence: 88.26785087585449
    },
    {
    description: "Logo",
    confidence: 87.7498209476471
    },
    {
    description: "Black-and-white",
    confidence: 85.3537917137146
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 726,
    time_in_milliseconds: 24224,
    labels: [
    {
    description: "Mammal",
    confidence: 98.90478253364563
    },
    {
    description: "Dog",
    confidence: 98.76207113265991
    },
    {
    description: "Dog breed",
    confidence: 98.38448762893677
    },
    {
    description: "Canidae",
    confidence: 97.65394330024719
    },
    {
    description: "Snout",
    confidence: 87.47147917747498
    },
    {
    description: "Carnivore",
    confidence: 86.67572736740112
    },
    {
    description: "Companion dog",
    confidence: 81.61945343017578
    },
    {
    description: "Irish terrier",
    confidence: 75.03463625907898
    },
    {
    description: "Terrier",
    confidence: 72.61983156204224
    },
    {
    description: "Norfolk terrier",
    confidence: 67.9669439792633
    }
    ],
    objects: [
    {
    description: "Dog",
    confidence: 0.9725871682167053,
    bounding_box: [
    {
    x: 0.10353278368711472,
    y: 0
    },
    {
    x: 0.8342425227165222,
    y: 0
    },
    {
    x: 0.8342425227165222,
    y: 0.9916722774505615
    },
    {
    x: 0.10353278368711472,
    y: 0.9916722774505615
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 755,
    time_in_milliseconds: 25192,
    labels: [
    {
    description: "Dog",
    confidence: 99.53705072402954
    },
    {
    description: "Mammal",
    confidence: 98.90478253364563
    },
    {
    description: "Vertebrate",
    confidence: 98.51104021072388
    },
    {
    description: "Dog breed",
    confidence: 98.38448762893677
    },
    {
    description: "Canidae",
    confidence: 98.13780188560486
    },
    {
    description: "Labrador retriever",
    confidence: 97.60615825653076
    },
    {
    description: "Retriever",
    confidence: 95.66346406936646
    },
    {
    description: "Carnivore",
    confidence: 88.34781050682068
    },
    {
    description: "Grass",
    confidence: 87.48446106910706
    },
    {
    description: "Sporting Group",
    confidence: 85.86117625236511
    }
    ],
    objects: [
    {
    description: "Dog",
    confidence: 0.964637041091919,
    bounding_box: [
    {
    x: 0.5113914608955383,
    y: 0.3082236051559448
    },
    {
    x: 0.7304964661598206,
    y: 0.3082236051559448
    },
    {
    x: 0.7304964661598206,
    y: 0.9252871870994568
    },
    {
    x: 0.5113914608955383,
    y: 0.9252871870994568
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 813,
    time_in_milliseconds: 27127.000000000004,
    labels: [
    {
    description: "Mammal",
    confidence: 98.90478253364563
    },
    {
    description: "Vertebrate",
    confidence: 98.51104021072388
    },
    {
    description: "Dog",
    confidence: 97.37859964370728
    },
    {
    description: "Canidae",
    confidence: 96.56330347061157
    },
    {
    description: "Dog breed",
    confidence: 96.4802622795105
    },
    {
    description: "Carnivore",
    confidence: 85.46947240829468
    },
    {
    description: "Sporting Group",
    confidence: 73.61697554588318
    },
    {
    description: "Non-Sporting Group",
    confidence: 69.34886574745178
    },
    {
    description: "Labrador retriever",
    confidence: 58.35462808609009
    },
    {
    description: "Irish soft-coated wheaten terrier",
    confidence: 54.29007411003113
    }
    ],
    objects: [
    {
    description: "Dog",
    confidence: 0.7821013331413269,
    bounding_box: [
    {
    x: 0.41346123814582825,
    y: 0.5626525282859802
    },
    {
    x: 0.7246367931365967,
    y: 0.5626525282859802
    },
    {
    x: 0.7246367931365967,
    y: 0.9940332770347595
    },
    {
    x: 0.41346123814582825,
    y: 0.9940332770347595
    }
    ]
    },
    {
    description: "Animal",
    confidence: 0.7259420156478882,
    bounding_box: [
    {
    x: 0.41672447323799133,
    y: 0.5550389885902405
    },
    {
    x: 0.7376055121421814,
    y: 0.5550389885902405
    },
    {
    x: 0.7376055121421814,
    y: 0.9953136444091797
    },
    {
    x: 0.41672447323799133,
    y: 0.9953136444091797
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.7141085267066956,
    bounding_box: [
    {
    x: 0.7896995544433594,
    y: 0.25246551632881165
    },
    {
    x: 0.8900744318962097,
    y: 0.25246551632881165
    },
    {
    x: 0.8900744318962097,
    y: 0.48571887612342834
    },
    {
    x: 0.7896995544433594,
    y: 0.48571887612342834
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7132821679115295,
    bounding_box: [
    {
    x: 0.12426578253507614,
    y: 0.09385422617197037
    },
    {
    x: 0.5182661414146423,
    y: 0.09385422617197037
    },
    {
    x: 0.5182661414146423,
    y: 0.9902939796447754
    },
    {
    x: 0.12426578253507614,
    y: 0.9902939796447754
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5809862017631531,
    bounding_box: [
    {
    x: 0.14421875774860382,
    y: 0.4656730890274048
    },
    {
    x: 0.505046546459198,
    y: 0.4656730890274048
    },
    {
    x: 0.505046546459198,
    y: 0.9861555695533752
    },
    {
    x: 0.14421875774860382,
    y: 0.9861555695533752
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5186289548873901,
    bounding_box: [
    {
    x: 0.5586768984794617,
    y: 0.07708805054426193
    },
    {
    x: 0.7609786987304688,
    y: 0.07708805054426193
    },
    {
    x: 0.7609786987304688,
    y: 0.4859395921230316
    },
    {
    x: 0.5586768984794617,
    y: 0.4859395921230316
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 842,
    time_in_milliseconds: 28094,
    labels: [
    {
    description: "Mammal",
    confidence: 98.90478253364563
    },
    {
    description: "Vertebrate",
    confidence: 98.51104021072388
    },
    {
    description: "Dog",
    confidence: 98.33251237869263
    },
    {
    description: "Canidae",
    confidence: 97.40216135978699
    },
    {
    description: "Dog breed",
    confidence: 96.15269899368286
    },
    {
    description: "Carnivore",
    confidence: 86.67572736740112
    },
    {
    description: "Golden retriever",
    confidence: 75.2165138721466
    },
    {
    description: "Sporting Group",
    confidence: 75.06803870201111
    },
    {
    description: "Labrador retriever",
    confidence: 75.06690621376038
    },
    {
    description: "Retriever",
    confidence: 74.09089207649231
    }
    ],
    objects: [
    {
    description: "Dog",
    confidence: 0.9180311560630798,
    bounding_box: [
    {
    x: 0.4326045513153076,
    y: 0.5454737544059753
    },
    {
    x: 0.7527207732200623,
    y: 0.5454737544059753
    },
    {
    x: 0.7527207732200623,
    y: 0.9933436512947083
    },
    {
    x: 0.4326045513153076,
    y: 0.9933436512947083
    }
    ]
    },
    {
    description: "Lamp",
    confidence: 0.9148947596549988,
    bounding_box: [
    {
    x: 0.7927448749542236,
    y: 0.2507670819759369
    },
    {
    x: 0.9033334255218506,
    y: 0.2507670819759369
    },
    {
    x: 0.9033334255218506,
    y: 0.4941352307796478
    },
    {
    x: 0.7927448749542236,
    y: 0.4941352307796478
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.9032236337661743,
    bounding_box: [
    {
    x: 0.1380901038646698,
    y: 0.5042049288749695
    },
    {
    x: 0.5328418612480164,
    y: 0.5042049288749695
    },
    {
    x: 0.5328418612480164,
    y: 0.9973958134651184
    },
    {
    x: 0.1380901038646698,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.8034101128578186,
    bounding_box: [
    {
    x: 0.1465846747159958,
    y: 0.5135385394096375
    },
    {
    x: 0.5386232137680054,
    y: 0.5135385394096375
    },
    {
    x: 0.5386232137680054,
    y: 0.9965246319770813
    },
    {
    x: 0.1465846747159958,
    y: 0.9965246319770813
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7439422011375427,
    bounding_box: [
    {
    x: 0.13222944736480713,
    y: 0.07302826642990112
    },
    {
    x: 0.5810381770133972,
    y: 0.07302826642990112
    },
    {
    x: 0.5810381770133972,
    y: 0.9893891215324402
    },
    {
    x: 0.13222944736480713,
    y: 0.9893891215324402
    }
    ]
    },
    {
    description: "Pillow",
    confidence: 0.5271538496017456,
    bounding_box: [
    {
    x: 0.0010526560945436358,
    y: 0.7432939410209656
    },
    {
    x: 0.17203806340694427,
    y: 0.7432939410209656
    },
    {
    x: 0.17203806340694427,
    y: 0.992598295211792
    },
    {
    x: 0.0010526560945436358,
    y: 0.992598295211792
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 987,
    time_in_milliseconds: 32933,
    labels: [
    {
    description: "Wall",
    confidence: 90.39760231971741
    },
    {
    description: "Room",
    confidence: 71.39228582382202
    },
    {
    description: "Visual arts",
    confidence: 71.12529277801514
    },
    {
    description: "Plaster",
    confidence: 64.92275595664978
    },
    {
    description: "Window",
    confidence: 63.830190896987915
    },
    {
    description: "Art",
    confidence: 62.534767389297485
    },
    {
    description: "Interior design",
    confidence: 62.47112154960632
    },
    {
    description: "Paint",
    confidence: 61.543792486190796
    },
    {
    description: "Plant",
    confidence: 58.5474967956543
    },
    {
    description: "Molding",
    confidence: 52.53527760505676
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 1103,
    time_in_milliseconds: 36803.00000000001,
    labels: [
    {
    description: "Dog",
    confidence: 99.53705072402954
    },
    {
    description: "Mammal",
    confidence: 98.90478253364563
    },
    {
    description: "Vertebrate",
    confidence: 98.51104021072388
    },
    {
    description: "Dog breed",
    confidence: 98.38448762893677
    },
    {
    description: "Canidae",
    confidence: 98.13780188560486
    },
    {
    description: "Carnivore",
    confidence: 93.4210479259491
    },
    {
    description: "Golden retriever",
    confidence: 92.2198474407196
    },
    {
    description: "Companion dog",
    confidence: 90.01193046569824
    },
    {
    description: "Puppy",
    confidence: 87.27348446846008
    },
    {
    description: "Snout",
    confidence: 82.51876831054688
    }
    ],
    objects: [
    {
    description: "Dog",
    confidence: 0.8682196736335754,
    bounding_box: [
    {
    x: 0.03420853614807129,
    y: 0.10229022055864334
    },
    {
    x: 0.9973958134651184,
    y: 0.10229022055864334
    },
    {
    x: 0.9973958134651184,
    y: 0.9940822124481201
    },
    {
    x: 0.03420853614807129,
    y: 0.9940822124481201
    }
    ]
    },
    {
    description: "Animal",
    confidence: 0.5663651823997498,
    bounding_box: [
    {
    x: 0.08191358298063278,
    y: 0.11365511268377304
    },
    {
    x: 0.9973958134651184,
    y: 0.11365511268377304
    },
    {
    x: 0.9973958134651184,
    y: 0.9973958134651184
    },
    {
    x: 0.08191358298063278,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5601597428321838,
    bounding_box: [
    {
    x: 0.5827026963233948,
    y: 0.10504250973463058
    },
    {
    x: 0.8822144865989685,
    y: 0.10504250973463058
    },
    {
    x: 0.8822144865989685,
    y: 0.4920056164264679
    },
    {
    x: 0.5827026963233948,
    y: 0.4920056164264679
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 1190,
    time_in_milliseconds: 39707,
    labels: [
    {
    description: "Drawing",
    confidence: 83.87206792831421
    },
    {
    description: "Hand",
    confidence: 79.1852056980133
    },
    {
    description: "Finger",
    confidence: 77.639639377594
    },
    {
    description: "Paper",
    confidence: 74.89729523658752
    },
    {
    description: "Sketch",
    confidence: 62.903422117233276
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    },
    {
    description: "Writing",
    confidence: 50.03697872161865
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5379313826560974,
    bounding_box: [
    {
    x: 0.1739487498998642,
    y: 0
    },
    {
    x: 0.7881132960319519,
    y: 0
    },
    {
    x: 0.7881132960319519,
    y: 0.9905993342399597
    },
    {
    x: 0.1739487498998642,
    y: 0.9905993342399597
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 1277,
    time_in_milliseconds: 42609,
    labels: [
    {
    description: "Text",
    confidence: 99.0282654762268
    },
    {
    description: "Font",
    confidence: 98.81365299224854
    },
    {
    description: "White",
    confidence: 95.47066688537598
    },
    {
    description: "Line",
    confidence: 87.488454580307
    },
    {
    description: "Logo",
    confidence: 81.71098828315735
    },
    {
    description: "Calligraphy",
    confidence: 76.92461609840393
    },
    {
    description: "Brand",
    confidence: 72.38689661026001
    },
    {
    description: "Design",
    confidence: 72.03227281570435
    },
    {
    description: "Graphics",
    confidence: 67.48287677764893
    },
    {
    description: "Illustration",
    confidence: 64.0210211277008
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 1393,
    time_in_milliseconds: 46480.00000000001,
    labels: [
    {
    description: "Mammal",
    confidence: 98.90478253364563
    },
    {
    description: "Dog",
    confidence: 98.76207113265991
    },
    {
    description: "Vertebrate",
    confidence: 98.51104021072388
    },
    {
    description: "Canidae",
    confidence: 97.40216135978699
    },
    {
    description: "Dog breed",
    confidence: 95.84342241287231
    },
    {
    description: "English cocker spaniel",
    confidence: 92.98799633979797
    },
    {
    description: "Golden retriever",
    confidence: 90.23076891899109
    },
    {
    description: "Carnivore",
    confidence: 88.34781050682068
    },
    {
    description: "American cocker spaniel",
    confidence: 85.18958687782288
    },
    {
    description: "Sporting Group",
    confidence: 76.29860639572144
    }
    ],
    objects: [
    {
    description: "Dog",
    confidence: 0.7282297611236572,
    bounding_box: [
    {
    x: 0.4091179668903351,
    y: 0.6721612811088562
    },
    {
    x: 0.8401983380317688,
    y: 0.6721612811088562
    },
    {
    x: 0.8401983380317688,
    y: 0.9964410662651062
    },
    {
    x: 0.4091179668903351,
    y: 0.9964410662651062
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7071459293365479,
    bounding_box: [
    {
    x: 0.09713470935821533,
    y: 0.022078564390540123
    },
    {
    x: 0.7042010426521301,
    y: 0.022078564390540123
    },
    {
    x: 0.7042010426521301,
    y: 0.9973958134651184
    },
    {
    x: 0.09713470935821533,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Lamp",
    confidence: 0.5995644927024841,
    bounding_box: [
    {
    x: 0.8909160494804382,
    y: 0.23803985118865967
    },
    {
    x: 0.9951276779174805,
    y: 0.23803985118865967
    },
    {
    x: 0.9951276779174805,
    y: 0.5465001463890076
    },
    {
    x: 0.8909160494804382,
    y: 0.5465001463890076
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5850344896316528,
    bounding_box: [
    {
    x: 0.11014429479837418,
    y: 0.534895122051239
    },
    {
    x: 0.591282069683075,
    y: 0.534895122051239
    },
    {
    x: 0.591282069683075,
    y: 0.9935690760612488
    },
    {
    x: 0.11014429479837418,
    y: 0.9935690760612488
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 1480,
    time_in_milliseconds: 49382.00000000001,
    labels: [
    {
    description: "Dog",
    confidence: 99.53705072402954
    },
    {
    description: "Mammal",
    confidence: 98.90478253364563
    },
    {
    description: "Vertebrate",
    confidence: 98.51104021072388
    },
    {
    description: "Dog breed",
    confidence: 98.38448762893677
    },
    {
    description: "Canidae",
    confidence: 98.13780188560486
    },
    {
    description: "Pembroke welsh corgi",
    confidence: 96.67752981185913
    },
    {
    description: "Welsh Corgi",
    confidence: 96.62489295005798
    },
    {
    description: "Carnivore",
    confidence: 93.4210479259491
    },
    {
    description: "Cardigan welsh corgi",
    confidence: 86.43900752067566
    },
    {
    description: "Norwegian lundehund",
    confidence: 85.84834337234497
    }
    ],
    objects: [
    {
    description: "Dog",
    confidence: 0.9649755358695984,
    bounding_box: [
    {
    x: 0.23686669766902924,
    y: 0
    },
    {
    x: 0.8542873859405518,
    y: 0
    },
    {
    x: 0.8542873859405518,
    y: 0.9919344782829285
    },
    {
    x: 0.23686669766902924,
    y: 0.9919344782829285
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 1567,
    time_in_milliseconds: 52285.00000000001,
    labels: [
    {
    description: "Dog breed",
    confidence: 98.38448762893677
    },
    {
    description: "Dog",
    confidence: 97.66467809677124
    },
    {
    description: "Mammal",
    confidence: 97.27270603179932
    },
    {
    description: "Canidae",
    confidence: 97.19749093055725
    },
    {
    description: "Snout",
    confidence: 88.70266675949097
    },
    {
    description: "Companion dog",
    confidence: 87.4758243560791
    },
    {
    description: "Russell terrier",
    confidence: 82.21755027770996
    },
    {
    description: "Carnivore",
    confidence: 81.66593909263611
    },
    {
    description: "Photo caption",
    confidence: 79.43977117538452
    },
    {
    description: "Jack russell terrier",
    confidence: 70.98347544670105
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 1654,
    time_in_milliseconds: 55188,
    labels: [
    {
    description: "Event",
    confidence: 87.79114484786987
    },
    {
    description: "Crowd",
    confidence: 74.99034404754639
    },
    {
    description: "Gesture",
    confidence: 63.760846853256226
    },
    {
    description: "Audience",
    confidence: 63.35796117782593
    },
    {
    description: "Speech",
    confidence: 62.46108412742615
    },
    {
    description: "Ceremony",
    confidence: 58.61864686012268
    },
    {
    description: "Competition event",
    confidence: 56.94236755371094
    },
    {
    description: "Competition",
    confidence: 53.258490562438965
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8587371706962585,
    bounding_box: [
    {
    x: 0.006480032112449408,
    y: 0.19137221574783325
    },
    {
    x: 0.21729803085327148,
    y: 0.19137221574783325
    },
    {
    x: 0.21729803085327148,
    y: 0.7072198390960693
    },
    {
    x: 0.006480032112449408,
    y: 0.7072198390960693
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.8440060615539551,
    bounding_box: [
    {
    x: 0.4471418559551239,
    y: 0.2524314820766449
    },
    {
    x: 0.5449274182319641,
    y: 0.2524314820766449
    },
    {
    x: 0.5449274182319641,
    y: 0.3866412341594696
    },
    {
    x: 0.4471418559551239,
    y: 0.3866412341594696
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8321564793586731,
    bounding_box: [
    {
    x: 0.0013541909866034985,
    y: 0.12369320541620255
    },
    {
    x: 0.09020843356847763,
    y: 0.12369320541620255
    },
    {
    x: 0.09020843356847763,
    y: 0.48943641781806946
    },
    {
    x: 0.0013541909866034985,
    y: 0.48943641781806946
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8218088746070862,
    bounding_box: [
    {
    x: 0.844663679599762,
    y: 0.22819681465625763
    },
    {
    x: 0.9941772818565369,
    y: 0.22819681465625763
    },
    {
    x: 0.9941772818565369,
    y: 0.6974266171455383
    },
    {
    x: 0.844663679599762,
    y: 0.6974266171455383
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8179976940155029,
    bounding_box: [
    {
    x: 0.5853695869445801,
    y: 0.13248386979103088
    },
    {
    x: 0.7250551581382751,
    y: 0.13248386979103088
    },
    {
    x: 0.7250551581382751,
    y: 0.6668238639831543
    },
    {
    x: 0.5853695869445801,
    y: 0.6668238639831543
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8093781471252441,
    bounding_box: [
    {
    x: 0.8383613228797913,
    y: 0.226876899600029
    },
    {
    x: 0.9973958134651184,
    y: 0.226876899600029
    },
    {
    x: 0.9973958134651184,
    y: 0.9686335921287537
    },
    {
    x: 0.8383613228797913,
    y: 0.9686335921287537
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8043571710586548,
    bounding_box: [
    {
    x: 0.6389948725700378,
    y: 0.20538340508937836
    },
    {
    x: 0.8597808480262756,
    y: 0.20538340508937836
    },
    {
    x: 0.8597808480262756,
    y: 0.9469613432884216
    },
    {
    x: 0.6389948725700378,
    y: 0.9469613432884216
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7986541390419006,
    bounding_box: [
    {
    x: 0.8203112483024597,
    y: 0.028787454590201378
    },
    {
    x: 0.9016332030296326,
    y: 0.028787454590201378
    },
    {
    x: 0.9016332030296326,
    y: 0.22521424293518066
    },
    {
    x: 0.8203112483024597,
    y: 0.22521424293518066
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7942174673080444,
    bounding_box: [
    {
    x: 0.8902082443237305,
    y: 0
    },
    {
    x: 0.9957908987998962,
    y: 0
    },
    {
    x: 0.9957908987998962,
    y: 0.18144463002681732
    },
    {
    x: 0.8902082443237305,
    y: 0.18144463002681732
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7933607697486877,
    bounding_box: [
    {
    x: 0.3060413599014282,
    y: 0.1222166121006012
    },
    {
    x: 0.46816250681877136,
    y: 0.1222166121006012
    },
    {
    x: 0.46816250681877136,
    y: 0.5811572670936584
    },
    {
    x: 0.3060413599014282,
    y: 0.5811572670936584
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 1770,
    time_in_milliseconds: 59059.00000000001,
    labels: [
    {
    description: "Speech",
    confidence: 97.44755029678345
    },
    {
    description: "Public speaking",
    confidence: 90.14335870742798
    },
    {
    description: "Event",
    confidence: 89.33819532394409
    },
    {
    description: "Spokesperson",
    confidence: 79.47734594345093
    },
    {
    description: "News",
    confidence: 73.56364727020264
    },
    {
    description: "Orator",
    confidence: 73.19639325141907
    },
    {
    description: "News conference",
    confidence: 69.24800872802734
    },
    {
    description: "Crowd",
    confidence: 62.68818974494934
    }
    ],
    objects: [
    {
    description: "Tie",
    confidence: 0.8985092639923096,
    bounding_box: [
    {
    x: 0.6104520559310913,
    y: 0.3171215355396271
    },
    {
    x: 0.6546676754951477,
    y: 0.3171215355396271
    },
    {
    x: 0.6546676754951477,
    y: 0.7088809013366699
    },
    {
    x: 0.6104520559310913,
    y: 0.7088809013366699
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8669148683547974,
    bounding_box: [
    {
    x: 0.5024137496948242,
    y: 0.12640118598937988
    },
    {
    x: 0.771324634552002,
    y: 0.12640118598937988
    },
    {
    x: 0.771324634552002,
    y: 0.9803751111030579
    },
    {
    x: 0.5024137496948242,
    y: 0.9803751111030579
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8589320182800293,
    bounding_box: [
    {
    x: 0.05759142339229584,
    y: 0.5851340889930725
    },
    {
    x: 0.14671410620212555,
    y: 0.5851340889930725
    },
    {
    x: 0.14671410620212555,
    y: 0.7980707287788391
    },
    {
    x: 0.05759142339229584,
    y: 0.7980707287788391
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.843716025352478,
    bounding_box: [
    {
    x: 0.8187857270240784,
    y: 0
    },
    {
    x: 0.8982576727867126,
    y: 0
    },
    {
    x: 0.8982576727867126,
    y: 0.289724737405777
    },
    {
    x: 0.8187857270240784,
    y: 0.289724737405777
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.834309995174408,
    bounding_box: [
    {
    x: 0.8916872143745422,
    y: 0.12957465648651123
    },
    {
    x: 0.9759809970855713,
    y: 0.12957465648651123
    },
    {
    x: 0.9759809970855713,
    y: 0.35340559482574463
    },
    {
    x: 0.8916872143745422,
    y: 0.35340559482574463
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7917200326919556,
    bounding_box: [
    {
    x: 0.9302335381507874,
    y: 0.18204165995121002
    },
    {
    x: 0.9973958134651184,
    y: 0.18204165995121002
    },
    {
    x: 0.9973958134651184,
    y: 0.46746134757995605
    },
    {
    x: 0.9302335381507874,
    y: 0.46746134757995605
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7536396384239197,
    bounding_box: [
    {
    x: 0.21063350141048431,
    y: 0.7150385975837708
    },
    {
    x: 0.3115314543247223,
    y: 0.7150385975837708
    },
    {
    x: 0.3115314543247223,
    y: 0.9823142886161804
    },
    {
    x: 0.21063350141048431,
    y: 0.9823142886161804
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7327033877372742,
    bounding_box: [
    {
    x: 0.9029960632324219,
    y: 0.3663055896759033
    },
    {
    x: 0.9798122048377991,
    y: 0.3663055896759033
    },
    {
    x: 0.9798122048377991,
    y: 0.5043089389801025
    },
    {
    x: 0.9029960632324219,
    y: 0.5043089389801025
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7159884572029114,
    bounding_box: [
    {
    x: 0.18691764771938324,
    y: 0.6592348217964172
    },
    {
    x: 0.2615322470664978,
    y: 0.6592348217964172
    },
    {
    x: 0.2615322470664978,
    y: 0.8309667110443115
    },
    {
    x: 0.18691764771938324,
    y: 0.8309667110443115
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6469351053237915,
    bounding_box: [
    {
    x: 0.4499204456806183,
    y: 0.6504176259040833
    },
    {
    x: 0.5354781746864319,
    y: 0.6504176259040833
    },
    {
    x: 0.5354781746864319,
    y: 0.8276944160461426
    },
    {
    x: 0.4499204456806183,
    y: 0.8276944160461426
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 1799,
    time_in_milliseconds: 60026,
    labels: [
    {
    description: "News",
    confidence: 95.27192711830139
    },
    {
    description: "Speech",
    confidence: 84.77863669395447
    },
    {
    description: "Newscaster",
    confidence: 84.3259334564209
    },
    {
    description: "Television program",
    confidence: 82.94028043746948
    },
    {
    description: "Official",
    confidence: 82.19693303108215
    },
    {
    description: "Photo caption",
    confidence: 80.8187484741211
    },
    {
    description: "Media",
    confidence: 76.53514742851257
    },
    {
    description: "Suit",
    confidence: 74.21299815177917
    },
    {
    description: "Television presenter",
    confidence: 71.18257284164429
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    }
    ],
    objects: [
    {
    description: "Tie",
    confidence: 0.9366672039031982,
    bounding_box: [
    {
    x: 0.6912201046943665,
    y: 0.42967626452445984
    },
    {
    x: 0.7503545880317688,
    y: 0.42967626452445984
    },
    {
    x: 0.7503545880317688,
    y: 0.7349840998649597
    },
    {
    x: 0.6912201046943665,
    y: 0.7349840998649597
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7448594570159912,
    bounding_box: [
    {
    x: 0.40661001205444336,
    y: 0.0447474904358387
    },
    {
    x: 0.9878080487251282,
    y: 0.0447474904358387
    },
    {
    x: 0.9878080487251282,
    y: 0.9915563464164734
    },
    {
    x: 0.40661001205444336,
    y: 0.9915563464164734
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.593557596206665,
    bounding_box: [
    {
    x: 0.498068243265152,
    y: 0.09255918860435486
    },
    {
    x: 0.9708869457244873,
    y: 0.09255918860435486
    },
    {
    x: 0.9708869457244873,
    y: 0.9698635339736938
    },
    {
    x: 0.498068243265152,
    y: 0.9698635339736938
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.5635751485824585,
    bounding_box: [
    {
    x: 0.6440256237983704,
    y: 0.16926604509353638
    },
    {
    x: 0.7946433424949646,
    y: 0.16926604509353638
    },
    {
    x: 0.7946433424949646,
    y: 0.25928017497062683
    },
    {
    x: 0.6440256237983704,
    y: 0.25928017497062683
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 1886,
    time_in_milliseconds: 62929,
    labels: [
    {
    description: "Font",
    confidence: 91.68663620948792
    },
    {
    description: "Sky",
    confidence: 82.20894932746887
    },
    {
    description: "City",
    confidence: 56.19292855262756
    },
    {
    description: "Talent show",
    confidence: 54.42596077919006
    },
    {
    description: "Music",
    confidence: 52.64713764190674
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8621677160263062,
    bounding_box: [
    {
    x: 0.6552640795707703,
    y: 0.605368435382843
    },
    {
    x: 0.8634527325630188,
    y: 0.605368435382843
    },
    {
    x: 0.8634527325630188,
    y: 0.9775671362876892
    },
    {
    x: 0.6552640795707703,
    y: 0.9775671362876892
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5869395136833191,
    bounding_box: [
    {
    x: 0.6558067202568054,
    y: 0.6085858345031738
    },
    {
    x: 0.845698356628418,
    y: 0.6085858345031738
    },
    {
    x: 0.845698356628418,
    y: 0.974989652633667
    },
    {
    x: 0.6558067202568054,
    y: 0.974989652633667
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 1973,
    time_in_milliseconds: 65833,
    labels: [
    {
    description: "Sky",
    confidence: 92.81086325645447
    },
    {
    description: "Font",
    confidence: 88.54238986968994
    },
    {
    description: "City",
    confidence: 87.76041269302368
    },
    {
    description: "Human settlement",
    confidence: 84.82491374015808
    },
    {
    description: "Urban area",
    confidence: 80.29192090034485
    },
    {
    description: "Cityscape",
    confidence: 77.40944027900696
    },
    {
    description: "Metropolis",
    confidence: 76.389080286026
    },
    {
    description: "Metropolitan area",
    confidence: 73.60722422599792
    },
    {
    description: "Tourism",
    confidence: 66.17092490196228
    },
    {
    description: "Real estate",
    confidence: 65.16035199165344
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8595627546310425,
    bounding_box: [
    {
    x: 0.7722113132476807,
    y: 0.5797807574272156
    },
    {
    x: 0.9056245684623718,
    y: 0.5797807574272156
    },
    {
    x: 0.9056245684623718,
    y: 0.8972534537315369
    },
    {
    x: 0.7722113132476807,
    y: 0.8972534537315369
    }
    ]
    },
    {
    description: "Drum",
    confidence: 0.8278982043266296,
    bounding_box: [
    {
    x: 0.7299802303314209,
    y: 0.8335237503051758
    },
    {
    x: 0.8211455941200256,
    y: 0.8335237503051758
    },
    {
    x: 0.8211455941200256,
    y: 0.9601011872291565
    },
    {
    x: 0.7299802303314209,
    y: 0.9601011872291565
    }
    ]
    },
    {
    description: "Drum",
    confidence: 0.8013352155685425,
    bounding_box: [
    {
    x: 0.8294413089752197,
    y: 0.8342481255531311
    },
    {
    x: 0.9117538332939148,
    y: 0.8342481255531311
    },
    {
    x: 0.9117538332939148,
    y: 0.9604410529136658
    },
    {
    x: 0.8294413089752197,
    y: 0.9604410529136658
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5867417454719543,
    bounding_box: [
    {
    x: 0.7737568020820618,
    y: 0.6787685751914978
    },
    {
    x: 0.8933280110359192,
    y: 0.6787685751914978
    },
    {
    x: 0.8933280110359192,
    y: 0.857982337474823
    },
    {
    x: 0.7737568020820618,
    y: 0.857982337474823
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2031,
    time_in_milliseconds: 67768,
    labels: [
    {
    description: "Sport venue",
    confidence: 81.42653703689575
    },
    {
    description: "Advertising",
    confidence: 75.93206763267517
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Stadium",
    confidence: 72.74929285049438
    },
    {
    description: "Billboard",
    confidence: 66.7517900466919
    },
    {
    description: "Building",
    confidence: 65.51225781440735
    },
    {
    description: "Architecture",
    confidence: 65.45118689537048
    },
    {
    description: "Signage",
    confidence: 64.00713324546814
    },
    {
    description: "Facade",
    confidence: 56.49074912071228
    },
    {
    description: "Arena",
    confidence: 55.075037479400635
    }
    ],
    objects: [
    {
    description: "Saxophone",
    confidence: 0.8840704560279846,
    bounding_box: [
    {
    x: 0.77642822265625,
    y: 0.7049915790557861
    },
    {
    x: 0.8601331114768982,
    y: 0.7049915790557861
    },
    {
    x: 0.8601331114768982,
    y: 0.9667733311653137
    },
    {
    x: 0.77642822265625,
    y: 0.9667733311653137
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7983524203300476,
    bounding_box: [
    {
    x: 0.7364623546600342,
    y: 0.6052612066268921
    },
    {
    x: 0.879831850528717,
    y: 0.6052612066268921
    },
    {
    x: 0.879831850528717,
    y: 0.9661362171173096
    },
    {
    x: 0.7364623546600342,
    y: 0.9661362171173096
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5462498664855957,
    bounding_box: [
    {
    x: 0.7330606579780579,
    y: 0.6324545741081238
    },
    {
    x: 0.8752015233039856,
    y: 0.6324545741081238
    },
    {
    x: 0.8752015233039856,
    y: 0.9634010195732117
    },
    {
    x: 0.7330606579780579,
    y: 0.9634010195732117
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2060,
    time_in_milliseconds: 68735,
    labels: [
    {
    description: "Advertising",
    confidence: 74.48536157608032
    },
    {
    description: "Architecture",
    confidence: 72.63345718383789
    },
    {
    description: "Sport venue",
    confidence: 72.57787585258484
    },
    {
    description: "Building",
    confidence: 68.90960931777954
    },
    {
    description: "Stadium",
    confidence: 65.52152633666992
    },
    {
    description: "Signage",
    confidence: 60.69386601448059
    },
    {
    description: "Facade",
    confidence: 59.421342611312866
    },
    {
    description: "Billboard",
    confidence: 54.19527888298035
    },
    {
    description: "Arena",
    confidence: 53.884804248809814
    },
    {
    description: "Tourist attraction",
    confidence: 52.82806754112244
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.757575273513794,
    bounding_box: [
    {
    x: 0.6403636336326599,
    y: 0.5951794385910034
    },
    {
    x: 0.8771805763244629,
    y: 0.5951794385910034
    },
    {
    x: 0.8771805763244629,
    y: 0.9703376293182373
    },
    {
    x: 0.6403636336326599,
    y: 0.9703376293182373
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2089,
    time_in_milliseconds: 69703,
    labels: [
    {
    description: "Advertising",
    confidence: 70.8530068397522
    },
    {
    description: "Music",
    confidence: 57.42889642715454
    },
    {
    description: "Building",
    confidence: 55.366337299346924
    },
    {
    description: "Billboard",
    confidence: 54.19527888298035
    },
    {
    description: "Sport venue",
    confidence: 54.18516397476196
    },
    {
    description: "Night",
    confidence: 53.93705368041992
    },
    {
    description: "Guitar",
    confidence: 52.94291973114014
    },
    {
    description: "Stadium",
    confidence: 52.083128690719604
    },
    {
    description: "Signage",
    confidence: 50.7346510887146
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8609026074409485,
    bounding_box: [
    {
    x: 0.6389535665512085,
    y: 0.5837374329566956
    },
    {
    x: 0.9006219506263733,
    y: 0.5837374329566956
    },
    {
    x: 0.9006219506263733,
    y: 0.9712933897972107
    },
    {
    x: 0.6389535665512085,
    y: 0.9712933897972107
    }
    ]
    },
    {
    description: "Guitar",
    confidence: 0.5656182169914246,
    bounding_box: [
    {
    x: 0.6477342844009399,
    y: 0.5885310769081116
    },
    {
    x: 0.9257071614265442,
    y: 0.5885310769081116
    },
    {
    x: 0.9257071614265442,
    y: 0.9665045738220215
    },
    {
    x: 0.6477342844009399,
    y: 0.9665045738220215
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2118,
    time_in_milliseconds: 70670,
    labels: [
    {
    description: "Music",
    confidence: 69.88388895988464
    },
    {
    description: "Guitar",
    confidence: 67.71937608718872
    },
    {
    description: "Plucked string instruments",
    confidence: 61.19311451911926
    },
    {
    description: "Night",
    confidence: 57.542914152145386
    },
    {
    description: "Sport venue",
    confidence: 54.18516397476196
    },
    {
    description: "Advertising",
    confidence: 50.91468095779419
    },
    {
    description: "Stage",
    confidence: 50.12027025222778
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7507284879684448,
    bounding_box: [
    {
    x: 0.6360911130905151,
    y: 0.580555260181427
    },
    {
    x: 0.8967626690864563,
    y: 0.580555260181427
    },
    {
    x: 0.8967626690864563,
    y: 0.9689426422119141
    },
    {
    x: 0.6360911130905151,
    y: 0.9689426422119141
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2147,
    time_in_milliseconds: 71638,
    labels: [
    {
    description: "Night",
    confidence: 91.6934609413147
    },
    {
    description: "Metropolitan area",
    confidence: 89.06492590904236
    },
    {
    description: "Mode of transport",
    confidence: 80.81701397895813
    },
    {
    description: "Metropolis",
    confidence: 77.5740921497345
    },
    {
    description: "City",
    confidence: 74.4107186794281
    },
    {
    description: "Building",
    confidence: 66.96145534515381
    },
    {
    description: "Architecture",
    confidence: 65.45118689537048
    },
    {
    description: "Traffic",
    confidence: 64.82314467430115
    },
    {
    description: "Street",
    confidence: 54.58909869194031
    },
    {
    description: "Vehicle",
    confidence: 51.87400579452515
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8944157361984253,
    bounding_box: [
    {
    x: 0.6656436324119568,
    y: 0.6050590872764587
    },
    {
    x: 0.8659785389900208,
    y: 0.6050590872764587
    },
    {
    x: 0.8659785389900208,
    y: 0.959430992603302
    },
    {
    x: 0.6656436324119568,
    y: 0.959430992603302
    }
    ]
    },
    {
    description: "Shoe",
    confidence: 0.7434538006782532,
    bounding_box: [
    {
    x: 0.8245067000389099,
    y: 0.841052234172821
    },
    {
    x: 0.939495325088501,
    y: 0.841052234172821
    },
    {
    x: 0.939495325088501,
    y: 0.9593263268470764
    },
    {
    x: 0.8245067000389099,
    y: 0.9593263268470764
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2176,
    time_in_milliseconds: 72605,
    labels: [
    {
    description: "Metropolitan area",
    confidence: 91.79646372795105
    },
    {
    description: "Advertising",
    confidence: 89.81176614761353
    },
    {
    description: "Urban area",
    confidence: 89.54816460609436
    },
    {
    description: "Billboard",
    confidence: 86.57117486000061
    },
    {
    description: "City",
    confidence: 85.04703640937805
    },
    {
    description: "Metropolis",
    confidence: 81.2443494796753
    },
    {
    description: "Architecture",
    confidence: 78.71291637420654
    },
    {
    description: "Building",
    confidence: 74.91106390953064
    },
    {
    description: "Night",
    confidence: 71.85397148132324
    },
    {
    description: "Downtown",
    confidence: 68.73734593391418
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8044608235359192,
    bounding_box: [
    {
    x: 0.6732378005981445,
    y: 0.6056612133979797
    },
    {
    x: 0.8576776385307312,
    y: 0.6056612133979797
    },
    {
    x: 0.8576776385307312,
    y: 0.9705623984336853
    },
    {
    x: 0.6732378005981445,
    y: 0.9705623984336853
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.7265331149101257,
    bounding_box: [
    {
    x: 0.5153615474700928,
    y: 0.3401566743850708
    },
    {
    x: 0.6171489357948303,
    y: 0.3401566743850708
    },
    {
    x: 0.6171489357948303,
    y: 0.4320939779281616
    },
    {
    x: 0.5153615474700928,
    y: 0.4320939779281616
    }
    ]
    },
    {
    description: "Shoe",
    confidence: 0.6953685283660889,
    bounding_box: [
    {
    x: 0.8227108120918274,
    y: 0.8350692391395569
    },
    {
    x: 0.9534770846366882,
    y: 0.8350692391395569
    },
    {
    x: 0.9534770846366882,
    y: 0.9614017605781555
    },
    {
    x: 0.8227108120918274,
    y: 0.9614017605781555
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.677944540977478,
    bounding_box: [
    {
    x: 0.446641206741333,
    y: 0.2019217610359192
    },
    {
    x: 0.6342286467552185,
    y: 0.2019217610359192
    },
    {
    x: 0.6342286467552185,
    y: 0.7775368094444275
    },
    {
    x: 0.446641206741333,
    y: 0.7775368094444275
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2205,
    time_in_milliseconds: 73574,
    labels: [
    {
    description: "Urban area",
    confidence: 80.29192090034485
    },
    {
    description: "City",
    confidence: 78.14302444458008
    },
    {
    description: "Advertising",
    confidence: 69.73137259483337
    },
    {
    description: "Metropolitan area",
    confidence: 69.08340454101562
    },
    {
    description: "Downtown",
    confidence: 58.5002064704895
    },
    {
    description: "Building",
    confidence: 56.98837637901306
    },
    {
    description: "Metropolis",
    confidence: 51.93195343017578
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.7964267730712891,
    bounding_box: [
    {
    x: 0.42726942896842957,
    y: 0.3420419991016388
    },
    {
    x: 0.5261289477348328,
    y: 0.3420419991016388
    },
    {
    x: 0.5261289477348328,
    y: 0.43605220317840576
    },
    {
    x: 0.42726942896842957,
    y: 0.43605220317840576
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7260062098503113,
    bounding_box: [
    {
    x: 0.7123913168907166,
    y: 0.6102499961853027
    },
    {
    x: 0.9183047413825989,
    y: 0.6102499961853027
    },
    {
    x: 0.9183047413825989,
    y: 0.9720931649208069
    },
    {
    x: 0.7123913168907166,
    y: 0.9720931649208069
    }
    ]
    },
    {
    description: "Guitar",
    confidence: 0.7031322121620178,
    bounding_box: [
    {
    x: 0.7079670429229736,
    y: 0.6984086036682129
    },
    {
    x: 0.936356246471405,
    y: 0.6984086036682129
    },
    {
    x: 0.936356246471405,
    y: 0.9661223292350769
    },
    {
    x: 0.7079670429229736,
    y: 0.9661223292350769
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6049526929855347,
    bounding_box: [
    {
    x: 0.3636261522769928,
    y: 0.20127542316913605
    },
    {
    x: 0.5693129897117615,
    y: 0.20127542316913605
    },
    {
    x: 0.5693129897117615,
    y: 0.7698507905006409
    },
    {
    x: 0.3636261522769928,
    y: 0.7698507905006409
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2234,
    time_in_milliseconds: 74541,
    labels: [
    {
    description: "City",
    confidence: 76.02869868278503
    },
    {
    description: "Architecture",
    confidence: 65.45118689537048
    },
    {
    description: "Event",
    confidence: 62.736958265304565
    },
    {
    description: "Advertising",
    confidence: 59.40808653831482
    },
    {
    description: "Downtown",
    confidence: 53.86883020401001
    },
    {
    description: "Performance",
    confidence: 50.89532732963562
    },
    {
    description: "Building",
    confidence: 50.7937490940094
    }
    ],
    objects: [
    {
    description: "Guitar",
    confidence: 0.8654119968414307,
    bounding_box: [
    {
    x: 0.7323222756385803,
    y: 0.6481156945228577
    },
    {
    x: 0.9703001976013184,
    y: 0.6481156945228577
    },
    {
    x: 0.9703001976013184,
    y: 0.9617252349853516
    },
    {
    x: 0.7323222756385803,
    y: 0.9617252349853516
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.7696215510368347,
    bounding_box: [
    {
    x: 0.34037086367607117,
    y: 0.3452565670013428
    },
    {
    x: 0.44088008999824524,
    y: 0.3452565670013428
    },
    {
    x: 0.44088008999824524,
    y: 0.4403899013996124
    },
    {
    x: 0.34037086367607117,
    y: 0.4403899013996124
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.763920247554779,
    bounding_box: [
    {
    x: 0.7230406403541565,
    y: 0.5885277390480042
    },
    {
    x: 0.9275074601173401,
    y: 0.5885277390480042
    },
    {
    x: 0.9275074601173401,
    y: 0.9706193804740906
    },
    {
    x: 0.7230406403541565,
    y: 0.9706193804740906
    }
    ]
    },
    {
    description: "Building",
    confidence: 0.7549962997436523,
    bounding_box: [
    {
    x: 0.6785292029380798,
    y: 0.07488401234149933
    },
    {
    x: 0.9077213406562805,
    y: 0.07488401234149933
    },
    {
    x: 0.9077213406562805,
    y: 0.5762638449668884
    },
    {
    x: 0.6785292029380798,
    y: 0.5762638449668884
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6664544343948364,
    bounding_box: [
    {
    x: 0.25516030192375183,
    y: 0.20111823081970215
    },
    {
    x: 0.49484944343566895,
    y: 0.20111823081970215
    },
    {
    x: 0.49484944343566895,
    y: 0.8600305914878845
    },
    {
    x: 0.25516030192375183,
    y: 0.8600305914878845
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2263,
    time_in_milliseconds: 75509,
    labels: [
    {
    description: "Advertising",
    confidence: 83.25837254524231
    },
    {
    description: "Banner",
    confidence: 69.2878782749176
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Signage",
    confidence: 66.9992446899414
    },
    {
    description: "Event",
    confidence: 62.736958265304565
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Display advertising",
    confidence: 59.183698892593384
    },
    {
    description: "Art",
    confidence: 58.13031792640686
    },
    {
    description: "City",
    confidence: 51.347553730010986
    }
    ],
    objects: [
    {
    description: "Guitar",
    confidence: 0.8755143284797668,
    bounding_box: [
    {
    x: 0.7373442649841309,
    y: 0.7041687369346619
    },
    {
    x: 0.9890260100364685,
    y: 0.7041687369346619
    },
    {
    x: 0.9890260100364685,
    y: 0.964122474193573
    },
    {
    x: 0.7373442649841309,
    y: 0.964122474193573
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8534901142120361,
    bounding_box: [
    {
    x: 0.7285849452018738,
    y: 0.5834603309631348
    },
    {
    x: 0.9044992923736572,
    y: 0.5834603309631348
    },
    {
    x: 0.9044992923736572,
    y: 0.9722196459770203
    },
    {
    x: 0.7285849452018738,
    y: 0.9722196459770203
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8235473036766052,
    bounding_box: [
    {
    x: 0.5404570698738098,
    y: 0.08907052129507065
    },
    {
    x: 0.7420080304145813,
    y: 0.08907052129507065
    },
    {
    x: 0.7420080304145813,
    y: 0.8009040355682373
    },
    {
    x: 0.5404570698738098,
    y: 0.8009040355682373
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8124499320983887,
    bounding_box: [
    {
    x: 0.7733023166656494,
    y: 0.07727900892496109
    },
    {
    x: 0.9207928776741028,
    y: 0.07727900892496109
    },
    {
    x: 0.9207928776741028,
    y: 0.5372301936149597
    },
    {
    x: 0.7733023166656494,
    y: 0.5372301936149597
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7923183441162109,
    bounding_box: [
    {
    x: 0.6431578993797302,
    y: 0.06228269636631012
    },
    {
    x: 0.9126774668693542,
    y: 0.06228269636631012
    },
    {
    x: 0.9126774668693542,
    y: 0.5636754631996155
    },
    {
    x: 0.6431578993797302,
    y: 0.5636754631996155
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5475656986236572,
    bounding_box: [
    {
    x: 0.7246423363685608,
    y: 0.611609160900116
    },
    {
    x: 0.8966130614280701,
    y: 0.611609160900116
    },
    {
    x: 0.8966130614280701,
    y: 0.9703440070152283
    },
    {
    x: 0.7246423363685608,
    y: 0.9703440070152283
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2292,
    time_in_milliseconds: 76476,
    labels: [
    {
    description: "Advertising",
    confidence: 81.11130595207214
    },
    {
    description: "Poster",
    confidence: 70.43402791023254
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Display advertising",
    confidence: 58.137744665145874
    },
    {
    description: "Billboard",
    confidence: 50.98722577095032
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7748667597770691,
    bounding_box: [
    {
    x: 0.7792365550994873,
    y: 0.5894482731819153
    },
    {
    x: 0.9082373976707458,
    y: 0.5894482731819153
    },
    {
    x: 0.9082373976707458,
    y: 0.9499792456626892
    },
    {
    x: 0.7792365550994873,
    y: 0.9499792456626892
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.743086040019989,
    bounding_box: [
    {
    x: 0.7785386443138123,
    y: 0.6865522265434265
    },
    {
    x: 0.9026123881340027,
    y: 0.6865522265434265
    },
    {
    x: 0.9026123881340027,
    y: 0.8538083434104919
    },
    {
    x: 0.7785386443138123,
    y: 0.8538083434104919
    }
    ]
    },
    {
    description: "Drum",
    confidence: 0.6634626388549805,
    bounding_box: [
    {
    x: 0.8278571963310242,
    y: 0.8318787217140198
    },
    {
    x: 0.9113429188728333,
    y: 0.8318787217140198
    },
    {
    x: 0.9113429188728333,
    y: 0.9595205783843994
    },
    {
    x: 0.8278571963310242,
    y: 0.9595205783843994
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6535277366638184,
    bounding_box: [
    {
    x: 0.3590643107891083,
    y: 0.06153131648898125
    },
    {
    x: 0.6073111295700073,
    y: 0.06153131648898125
    },
    {
    x: 0.6073111295700073,
    y: 0.8590795993804932
    },
    {
    x: 0.3590643107891083,
    y: 0.8590795993804932
    }
    ]
    },
    {
    description: "Drum",
    confidence: 0.6392938494682312,
    bounding_box: [
    {
    x: 0.7257092595100403,
    y: 0.8322382569313049
    },
    {
    x: 0.8211324214935303,
    y: 0.8322382569313049
    },
    {
    x: 0.8211324214935303,
    y: 0.9606075882911682
    },
    {
    x: 0.7257092595100403,
    y: 0.9606075882911682
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6308295726776123,
    bounding_box: [
    {
    x: 0.5173675417900085,
    y: 0.08291717618703842
    },
    {
    x: 0.7307143807411194,
    y: 0.08291717618703842
    },
    {
    x: 0.7307143807411194,
    y: 0.6238953471183777
    },
    {
    x: 0.5173675417900085,
    y: 0.6238953471183777
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.57505202293396,
    bounding_box: [
    {
    x: 0.372205525636673,
    y: 0.3239977955818176
    },
    {
    x: 0.6011114716529846,
    y: 0.3239977955818176
    },
    {
    x: 0.6011114716529846,
    y: 0.8147795796394348
    },
    {
    x: 0.372205525636673,
    y: 0.8147795796394348
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5304532051086426,
    bounding_box: [
    {
    x: 0.7788887619972229,
    y: 0.585695207118988
    },
    {
    x: 0.9046846032142639,
    y: 0.585695207118988
    },
    {
    x: 0.9046846032142639,
    y: 0.8880974650382996
    },
    {
    x: 0.7788887619972229,
    y: 0.8880974650382996
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5180540084838867,
    bounding_box: [
    {
    x: 0.6246529817581177,
    y: 0.3041761517524719
    },
    {
    x: 0.7406486868858337,
    y: 0.3041761517524719
    },
    {
    x: 0.7406486868858337,
    y: 0.5253060460090637
    },
    {
    x: 0.6246529817581177,
    y: 0.5253060460090637
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5171352624893188,
    bounding_box: [
    {
    x: 0.6194896101951599,
    y: 0.1248282790184021
    },
    {
    x: 0.743205726146698,
    y: 0.1248282790184021
    },
    {
    x: 0.743205726146698,
    y: 0.55577152967453
    },
    {
    x: 0.6194896101951599,
    y: 0.55577152967453
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2321,
    time_in_milliseconds: 77444,
    labels: [
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Performance",
    confidence: 57.672929763793945
    },
    {
    description: "Music",
    confidence: 55.19570708274841
    },
    {
    description: "Stage",
    confidence: 53.78609299659729
    }
    ],
    objects: [
    {
    description: "Pants",
    confidence: 0.912662923336029,
    bounding_box: [
    {
    x: 0.47703495621681213,
    y: 0.5019717216491699
    },
    {
    x: 0.5767541527748108,
    y: 0.5019717216491699
    },
    {
    x: 0.5767541527748108,
    y: 0.9006525874137878
    },
    {
    x: 0.47703495621681213,
    y: 0.9006525874137878
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8286433815956116,
    bounding_box: [
    {
    x: 0.44273385405540466,
    y: 0.15128307044506073
    },
    {
    x: 0.6035597324371338,
    y: 0.15128307044506073
    },
    {
    x: 0.6035597324371338,
    y: 0.9391334652900696
    },
    {
    x: 0.44273385405540466,
    y: 0.9391334652900696
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8134044408798218,
    bounding_box: [
    {
    x: 0.8346112370491028,
    y: 0.6143270134925842
    },
    {
    x: 0.9559071660041809,
    y: 0.6143270134925842
    },
    {
    x: 0.9559071660041809,
    y: 0.9642811417579651
    },
    {
    x: 0.8346112370491028,
    y: 0.9642811417579651
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8091397285461426,
    bounding_box: [
    {
    x: 0.6200937032699585,
    y: 0.6073237061500549
    },
    {
    x: 0.7794975638389587,
    y: 0.6073237061500549
    },
    {
    x: 0.7794975638389587,
    y: 0.9290105700492859
    },
    {
    x: 0.6200937032699585,
    y: 0.9290105700492859
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7349480986595154,
    bounding_box: [
    {
    x: 0.4514256417751312,
    y: 0.27130547165870667
    },
    {
    x: 0.5926530957221985,
    y: 0.27130547165870667
    },
    {
    x: 0.5926530957221985,
    y: 0.5585612654685974
    },
    {
    x: 0.4514256417751312,
    y: 0.5585612654685974
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2350,
    time_in_milliseconds: 78411,
    labels: [
    {
    description: "Fashion",
    confidence: 79.79211807250977
    },
    {
    description: "Music",
    confidence: 78.75866889953613
    },
    {
    description: "Footwear",
    confidence: 70.08925676345825
    },
    {
    description: "Photography",
    confidence: 67.78864860534668
    },
    {
    description: "Street fashion",
    confidence: 57.965320348739624
    },
    {
    description: "Performance",
    confidence: 57.672929763793945
    },
    {
    description: "Talent show",
    confidence: 54.42596077919006
    },
    {
    description: "Music artist",
    confidence: 53.43286991119385
    },
    {
    description: "Musician",
    confidence: 52.96785831451416
    }
    ],
    objects: [
    {
    description: "Pants",
    confidence: 0.9100662469863892,
    bounding_box: [
    {
    x: 0.4710613489151001,
    y: 0.5047459006309509
    },
    {
    x: 0.5658445358276367,
    y: 0.5047459006309509
    },
    {
    x: 0.5658445358276367,
    y: 0.9056439399719238
    },
    {
    x: 0.4710613489151001,
    y: 0.9056439399719238
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8727505207061768,
    bounding_box: [
    {
    x: 0.43568024039268494,
    y: 0.15679824352264404
    },
    {
    x: 0.588287353515625,
    y: 0.15679824352264404
    },
    {
    x: 0.588287353515625,
    y: 0.9377955794334412
    },
    {
    x: 0.43568024039268494,
    y: 0.9377955794334412
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8657784461975098,
    bounding_box: [
    {
    x: 0.8305135369300842,
    y: 0.6072986721992493
    },
    {
    x: 0.9573862552642822,
    y: 0.6072986721992493
    },
    {
    x: 0.9573862552642822,
    y: 0.9653087258338928
    },
    {
    x: 0.8305135369300842,
    y: 0.9653087258338928
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8485432267189026,
    bounding_box: [
    {
    x: 0.6197298765182495,
    y: 0.5986025929450989
    },
    {
    x: 0.7845132350921631,
    y: 0.5986025929450989
    },
    {
    x: 0.7845132350921631,
    y: 0.9552159905433655
    },
    {
    x: 0.6197298765182495,
    y: 0.9552159905433655
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6586797833442688,
    bounding_box: [
    {
    x: 0.4401251971721649,
    y: 0.26820120215415955
    },
    {
    x: 0.5788576602935791,
    y: 0.26820120215415955
    },
    {
    x: 0.5788576602935791,
    y: 0.5752968192100525
    },
    {
    x: 0.4401251971721649,
    y: 0.5752968192100525
    }
    ]
    },
    {
    description: "Saxophone",
    confidence: 0.5511846542358398,
    bounding_box: [
    {
    x: 0.8549036383628845,
    y: 0.683704137802124
    },
    {
    x: 0.9423384070396423,
    y: 0.683704137802124
    },
    {
    x: 0.9423384070396423,
    y: 0.9613914489746094
    },
    {
    x: 0.8549036383628845,
    y: 0.9613914489746094
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2379,
    time_in_milliseconds: 79379,
    labels: [
    {
    description: "Snapshot",
    confidence: 83.58544111251831
    },
    {
    description: "Footwear",
    confidence: 72.9487657546997
    },
    {
    description: "Design",
    confidence: 72.03227281570435
    },
    {
    description: "Photography",
    confidence: 70.60428261756897
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Advertising",
    confidence: 69.12822127342224
    },
    {
    description: "Street",
    confidence: 59.71112847328186
    },
    {
    description: "City",
    confidence: 56.19292855262756
    },
    {
    description: "Performance",
    confidence: 54.72791790962219
    },
    {
    description: "Street fashion",
    confidence: 53.160905838012695
    }
    ],
    objects: [
    {
    description: "Hat",
    confidence: 0.9039465188980103,
    bounding_box: [
    {
    x: 0.7621138691902161,
    y: 0.5917103290557861
    },
    {
    x: 0.8555219173431396,
    y: 0.5917103290557861
    },
    {
    x: 0.8555219173431396,
    y: 0.7103167176246643
    },
    {
    x: 0.7621138691902161,
    y: 0.7103167176246643
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.9030656814575195,
    bounding_box: [
    {
    x: 0.468463271856308,
    y: 0.5070648193359375
    },
    {
    x: 0.5606975555419922,
    y: 0.5070648193359375
    },
    {
    x: 0.5606975555419922,
    y: 0.9026350975036621
    },
    {
    x: 0.468463271856308,
    y: 0.9026350975036621
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8508735299110413,
    bounding_box: [
    {
    x: 0.36772504448890686,
    y: 0.46920979022979736
    },
    {
    x: 0.43779274821281433,
    y: 0.46920979022979736
    },
    {
    x: 0.43779274821281433,
    y: 0.8433787822723389
    },
    {
    x: 0.36772504448890686,
    y: 0.8433787822723389
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8448374271392822,
    bounding_box: [
    {
    x: 0.7166035175323486,
    y: 0.5968579053878784
    },
    {
    x: 0.906480610370636,
    y: 0.5968579053878784
    },
    {
    x: 0.906480610370636,
    y: 0.9659382700920105
    },
    {
    x: 0.7166035175323486,
    y: 0.9659382700920105
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8305122256278992,
    bounding_box: [
    {
    x: 0.4352262020111084,
    y: 0.18692155182361603
    },
    {
    x: 0.5873468518257141,
    y: 0.18692155182361603
    },
    {
    x: 0.5873468518257141,
    y: 0.9593048095703125
    },
    {
    x: 0.4352262020111084,
    y: 0.9593048095703125
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.800726056098938,
    bounding_box: [
    {
    x: 0.44995591044425964,
    y: 0.20295678079128265
    },
    {
    x: 0.5842849612236023,
    y: 0.20295678079128265
    },
    {
    x: 0.5842849612236023,
    y: 0.9541058540344238
    },
    {
    x: 0.44995591044425964,
    y: 0.9541058540344238
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7249091267585754,
    bounding_box: [
    {
    x: 0.4472980499267578,
    y: 0.277328759431839
    },
    {
    x: 0.581836462020874,
    y: 0.277328759431839
    },
    {
    x: 0.581836462020874,
    y: 0.546168327331543
    },
    {
    x: 0.4472980499267578,
    y: 0.546168327331543
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6599554419517517,
    bounding_box: [
    {
    x: 0.7185604572296143,
    y: 0.6657406687736511
    },
    {
    x: 0.8988003134727478,
    y: 0.6657406687736511
    },
    {
    x: 0.8988003134727478,
    y: 0.96051424741745
    },
    {
    x: 0.7185604572296143,
    y: 0.96051424741745
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2408,
    time_in_milliseconds: 80347.00000000001,
    labels: [
    {
    description: "Metropolitan area",
    confidence: 96.41379714012146
    },
    {
    description: "Metropolis",
    confidence: 95.80591917037964
    },
    {
    description: "City",
    confidence: 95.09462118148804
    },
    {
    description: "Urban area",
    confidence: 95.06698250770569
    },
    {
    description: "Blue",
    confidence: 94.45302486419678
    },
    {
    description: "Night",
    confidence: 93.23667883872986
    },
    {
    description: "Human settlement",
    confidence: 90.19722938537598
    },
    {
    description: "Light",
    confidence: 90.11393785476685
    },
    {
    description: "Lighting",
    confidence: 89.2389714717865
    },
    {
    description: "Landmark",
    confidence: 89.13570046424866
    }
    ],
    objects: [
    {
    description: "Building",
    confidence: 0.8055190443992615,
    bounding_box: [
    {
    x: 0.004158918280154467,
    y: 0.013492402620613575
    },
    {
    x: 0.1606692373752594,
    y: 0.013492402620613575
    },
    {
    x: 0.1606692373752594,
    y: 0.9973958134651184
    },
    {
    x: 0.004158918280154467,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7998732328414917,
    bounding_box: [
    {
    x: 0.7289398312568665,
    y: 0.6033270955085754
    },
    {
    x: 0.8672769665718079,
    y: 0.6033270955085754
    },
    {
    x: 0.8672769665718079,
    y: 0.9221699237823486
    },
    {
    x: 0.7289398312568665,
    y: 0.9221699237823486
    }
    ]
    },
    {
    description: "Building",
    confidence: 0.6752367615699768,
    bounding_box: [
    {
    x: 0.6124606132507324,
    y: 0.0033171887043863535
    },
    {
    x: 0.9507133364677429,
    y: 0.0033171887043863535
    },
    {
    x: 0.9507133364677429,
    y: 0.5901495814323425
    },
    {
    x: 0.6124606132507324,
    y: 0.5901495814323425
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6359364986419678,
    bounding_box: [
    {
    x: 0.7328470349311829,
    y: 0.6853839755058289
    },
    {
    x: 0.8588676452636719,
    y: 0.6853839755058289
    },
    {
    x: 0.8588676452636719,
    y: 0.8714662194252014
    },
    {
    x: 0.7328470349311829,
    y: 0.8714662194252014
    }
    ]
    },
    {
    description: "Building",
    confidence: 0.6246161460876465,
    bounding_box: [
    {
    x: 0.1784050464630127,
    y: 0.018196547403931618
    },
    {
    x: 0.5582855343818665,
    y: 0.018196547403931618
    },
    {
    x: 0.5582855343818665,
    y: 0.9753480553627014
    },
    {
    x: 0.1784050464630127,
    y: 0.9753480553627014
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.565180242061615,
    bounding_box: [
    {
    x: 0.7513840198516846,
    y: 0.8379927277565002
    },
    {
    x: 0.9178562164306641,
    y: 0.8379927277565002
    },
    {
    x: 0.9178562164306641,
    y: 0.9559668898582458
    },
    {
    x: 0.7513840198516846,
    y: 0.9559668898582458
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2466,
    time_in_milliseconds: 82282.00000000001,
    labels: [
    {
    description: "Urban area",
    confidence: 94.42672729492188
    },
    {
    description: "Night",
    confidence: 94.29901242256165
    },
    {
    description: "Yellow",
    confidence: 93.84577870368958
    },
    {
    description: "Street",
    confidence: 91.81252717971802
    },
    {
    description: "Metropolis",
    confidence: 89.74182605743408
    },
    {
    description: "Metropolitan area",
    confidence: 89.06492590904236
    },
    {
    description: "Light",
    confidence: 87.91419267654419
    },
    {
    description: "City",
    confidence: 87.84880042076111
    },
    {
    description: "Town",
    confidence: 85.18347144126892
    },
    {
    description: "Human settlement",
    confidence: 84.82491374015808
    }
    ],
    objects: [
    {
    description: "Car",
    confidence: 0.8484296798706055,
    bounding_box: [
    {
    x: 0.4646212160587311,
    y: 0.7598328590393066
    },
    {
    x: 0.6113080382347107,
    y: 0.7598328590393066
    },
    {
    x: 0.6113080382347107,
    y: 0.9549739360809326
    },
    {
    x: 0.4646212160587311,
    y: 0.9549739360809326
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7844452857971191,
    bounding_box: [
    {
    x: 0.626074492931366,
    y: 0.5918132066726685
    },
    {
    x: 0.8723361492156982,
    y: 0.5918132066726685
    },
    {
    x: 0.8723361492156982,
    y: 0.9740254282951355
    },
    {
    x: 0.626074492931366,
    y: 0.9740254282951355
    }
    ]
    },
    {
    description: "Car",
    confidence: 0.5716201663017273,
    bounding_box: [
    {
    x: 0.33448147773742676,
    y: 0.7238385081291199
    },
    {
    x: 0.4359021484851837,
    y: 0.7238385081291199
    },
    {
    x: 0.4359021484851837,
    y: 0.8704478740692139
    },
    {
    x: 0.33448147773742676,
    y: 0.8704478740692139
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2495,
    time_in_milliseconds: 83249.00000000001,
    labels: [
    {
    description: "Photograph",
    confidence: 94.9849009513855
    },
    {
    description: "Light",
    confidence: 92.88426041603088
    },
    {
    description: "Night",
    confidence: 88.82278800010681
    },
    {
    description: "Yellow",
    confidence: 88.20182085037231
    },
    {
    description: "Urban area",
    confidence: 87.3231589794159
    },
    {
    description: "Snapshot",
    confidence: 86.0749900341034
    },
    {
    description: "Lighting",
    confidence: 84.34011340141296
    },
    {
    description: "Metropolitan area",
    confidence: 73.60722422599792
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Metropolis",
    confidence: 72.67807722091675
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8024115562438965,
    bounding_box: [
    {
    x: 0.7300200462341309,
    y: 0.592773973941803
    },
    {
    x: 0.8976264595985413,
    y: 0.592773973941803
    },
    {
    x: 0.8976264595985413,
    y: 0.964799165725708
    },
    {
    x: 0.7300200462341309,
    y: 0.964799165725708
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.752975583076477,
    bounding_box: [
    {
    x: 0.7309325337409973,
    y: 0.6789323687553406
    },
    {
    x: 0.892647922039032,
    y: 0.6789323687553406
    },
    {
    x: 0.892647922039032,
    y: 0.9626622200012207
    },
    {
    x: 0.7309325337409973,
    y: 0.9626622200012207
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.5345083475112915,
    bounding_box: [
    {
    x: 0.7727818489074707,
    y: 0.5919651389122009
    },
    {
    x: 0.8523988127708435,
    y: 0.5919651389122009
    },
    {
    x: 0.8523988127708435,
    y: 0.7314622402191162
    },
    {
    x: 0.7727818489074707,
    y: 0.7314622402191162
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2524,
    time_in_milliseconds: 84217,
    labels: [
    {
    description: "Metropolis",
    confidence: 87.81577944755554
    },
    {
    description: "City",
    confidence: 87.3947024345398
    },
    {
    description: "Urban area",
    confidence: 87.3231589794159
    },
    {
    description: "Human settlement",
    confidence: 85.28644442558289
    },
    {
    description: "Night",
    confidence: 84.8365068435669
    },
    {
    description: "Metropolitan area",
    confidence: 83.90477299690247
    },
    {
    description: "Town",
    confidence: 82.93768167495728
    },
    {
    description: "Architecture",
    confidence: 76.32943391799927
    },
    {
    description: "Building",
    confidence: 74.40816164016724
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8447863459587097,
    bounding_box: [
    {
    x: 0.6714036464691162,
    y: 0.6093878149986267
    },
    {
    x: 0.8597161173820496,
    y: 0.6093878149986267
    },
    {
    x: 0.8597161173820496,
    y: 0.969027578830719
    },
    {
    x: 0.6714036464691162,
    y: 0.969027578830719
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6081631183624268,
    bounding_box: [
    {
    x: 0.6802146434783936,
    y: 0.6356597542762756
    },
    {
    x: 0.8640554547309875,
    y: 0.6356597542762756
    },
    {
    x: 0.8640554547309875,
    y: 0.9667572975158691
    },
    {
    x: 0.6802146434783936,
    y: 0.9667572975158691
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2553,
    time_in_milliseconds: 85185,
    labels: [
    {
    description: "Night",
    confidence: 93.84546279907227
    },
    {
    description: "Light",
    confidence: 89.94030356407166
    },
    {
    description: "Urban area",
    confidence: 83.51601958274841
    },
    {
    description: "Electronic signage",
    confidence: 82.91256427764893
    },
    {
    description: "Metropolitan area",
    confidence: 79.21884059906006
    },
    {
    description: "Architecture",
    confidence: 72.63345718383789
    },
    {
    description: "Street",
    confidence: 69.58826780319214
    },
    {
    description: "City",
    confidence: 68.61301064491272
    },
    {
    description: "Building",
    confidence: 67.7990734577179
    },
    {
    description: "Metropolis",
    confidence: 65.78791737556458
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7900118827819824,
    bounding_box: [
    {
    x: 0.6434298753738403,
    y: 0.6002045273780823
    },
    {
    x: 0.8626498579978943,
    y: 0.6002045273780823
    },
    {
    x: 0.8626498579978943,
    y: 0.96287602186203
    },
    {
    x: 0.6434298753738403,
    y: 0.96287602186203
    }
    ]
    },
    {
    description: "Building",
    confidence: 0.6363914608955383,
    bounding_box: [
    {
    x: 0.010021568275988102,
    y: 0
    },
    {
    x: 0.34888756275177,
    y: 0
    },
    {
    x: 0.34888756275177,
    y: 0.7024131417274475
    },
    {
    x: 0.010021568275988102,
    y: 0.7024131417274475
    }
    ]
    },
    {
    description: "Musical instrument",
    confidence: 0.6352230906486511,
    bounding_box: [
    {
    x: 0.6619396805763245,
    y: 0.7210007309913635
    },
    {
    x: 0.8842297196388245,
    y: 0.7210007309913635
    },
    {
    x: 0.8842297196388245,
    y: 0.957176148891449
    },
    {
    x: 0.6619396805763245,
    y: 0.957176148891449
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.63382887840271,
    bounding_box: [
    {
    x: 0.6484581828117371,
    y: 0.6101562976837158
    },
    {
    x: 0.8795997500419617,
    y: 0.6101562976837158
    },
    {
    x: 0.8795997500419617,
    y: 0.9658772349357605
    },
    {
    x: 0.6484581828117371,
    y: 0.9658772349357605
    }
    ]
    },
    {
    description: "Building",
    confidence: 0.5725697875022888,
    bounding_box: [
    {
    x: 0.32926133275032043,
    y: 0.0062160915695130825
    },
    {
    x: 0.6978546977043152,
    y: 0.0062160915695130825
    },
    {
    x: 0.6978546977043152,
    y: 0.5801457762718201
    },
    {
    x: 0.32926133275032043,
    y: 0.5801457762718201
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2582,
    time_in_milliseconds: 86152,
    labels: [
    {
    description: "Electronic signage",
    confidence: 86.14726066589355
    },
    {
    description: "Night",
    confidence: 78.50902676582336
    },
    {
    description: "Neon sign",
    confidence: 73.056560754776
    },
    {
    description: "Building",
    confidence: 71.01791501045227
    },
    {
    description: "Neon",
    confidence: 68.89573335647583
    },
    {
    description: "Signage",
    confidence: 60.045307874679565
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8298170566558838,
    bounding_box: [
    {
    x: 0.733548641204834,
    y: 0.6186535954475403
    },
    {
    x: 0.8664653301239014,
    y: 0.6186535954475403
    },
    {
    x: 0.8664653301239014,
    y: 0.9608997702598572
    },
    {
    x: 0.733548641204834,
    y: 0.9608997702598572
    }
    ]
    },
    {
    description: "Saxophone",
    confidence: 0.7973297238349915,
    bounding_box: [
    {
    x: 0.768871009349823,
    y: 0.6846107840538025
    },
    {
    x: 0.8531351089477539,
    y: 0.6846107840538025
    },
    {
    x: 0.8531351089477539,
    y: 0.9622805714607239
    },
    {
    x: 0.768871009349823,
    y: 0.9622805714607239
    }
    ]
    },
    {
    description: "Building",
    confidence: 0.6742632389068604,
    bounding_box: [
    {
    x: 0.003528576111420989,
    y: 0.010810848325490952
    },
    {
    x: 0.30908843874931335,
    y: 0.010810848325490952
    },
    {
    x: 0.30908843874931335,
    y: 0.7758777737617493
    },
    {
    x: 0.003528576111420989,
    y: 0.7758777737617493
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5981304049491882,
    bounding_box: [
    {
    x: 0.31774380803108215,
    y: 0.5973413586616516
    },
    {
    x: 0.36423084139823914,
    y: 0.5973413586616516
    },
    {
    x: 0.36423084139823914,
    y: 0.7832691073417664
    },
    {
    x: 0.31774380803108215,
    y: 0.7832691073417664
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2611,
    time_in_milliseconds: 87120,
    labels: [
    {
    description: "Building",
    confidence: 73.68544340133667
    },
    {
    description: "Architecture",
    confidence: 65.45118689537048
    },
    {
    description: "Function hall",
    confidence: 55.012547969818115
    },
    {
    description: "Interior design",
    confidence: 54.307204484939575
    }
    ],
    objects: [
    {
    description: "Saxophone",
    confidence: 0.8574312329292297,
    bounding_box: [
    {
    x: 0.7721802592277527,
    y: 0.6656315922737122
    },
    {
    x: 0.8427109122276306,
    y: 0.6656315922737122
    },
    {
    x: 0.8427109122276306,
    y: 0.9591678977012634
    },
    {
    x: 0.7721802592277527,
    y: 0.9591678977012634
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8462308645248413,
    bounding_box: [
    {
    x: 0.7402939796447754,
    y: 0.5941848754882812
    },
    {
    x: 0.8699677586555481,
    y: 0.5941848754882812
    },
    {
    x: 0.8699677586555481,
    y: 0.9621618390083313
    },
    {
    x: 0.7402939796447754,
    y: 0.9621618390083313
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2640,
    time_in_milliseconds: 88088.00000000001,
    labels: [
    {
    description: "Orange",
    confidence: 85.05952954292297
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9121209979057312,
    bounding_box: [
    {
    x: 0.6674978733062744,
    y: 0.6081360578536987
    },
    {
    x: 0.8636474013328552,
    y: 0.6081360578536987
    },
    {
    x: 0.8636474013328552,
    y: 0.9682124257087708
    },
    {
    x: 0.6674978733062744,
    y: 0.9682124257087708
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5673632025718689,
    bounding_box: [
    {
    x: 0.6756162643432617,
    y: 0.6319132447242737
    },
    {
    x: 0.8650298118591309,
    y: 0.6319132447242737
    },
    {
    x: 0.8650298118591309,
    y: 0.9679984450340271
    },
    {
    x: 0.6756162643432617,
    y: 0.9679984450340271
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.552147388458252,
    bounding_box: [
    {
    x: 0.8230295777320862,
    y: 0.8342186808586121
    },
    {
    x: 0.949009120464325,
    y: 0.8342186808586121
    },
    {
    x: 0.949009120464325,
    y: 0.9583438038825989
    },
    {
    x: 0.8230295777320862,
    y: 0.9583438038825989
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.506718099117279,
    bounding_box: [
    {
    x: 0.7805414199829102,
    y: 0.7309431433677673
    },
    {
    x: 0.8345260620117188,
    y: 0.7309431433677673
    },
    {
    x: 0.8345260620117188,
    y: 0.9504700303077698
    },
    {
    x: 0.7805414199829102,
    y: 0.9504700303077698
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 2669,
    time_in_milliseconds: 89056,
    labels: [
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Library",
    confidence: 66.96746349334717
    },
    {
    description: "Book",
    confidence: 62.727195024490356
    },
    {
    description: "Games",
    confidence: 52.11578011512756
    },
    {
    description: "Vision care",
    confidence: 51.88118815422058
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8908661603927612,
    bounding_box: [
    {
    x: 0.40446606278419495,
    y: 0.45568105578422546
    },
    {
    x: 0.5321128964424133,
    y: 0.45568105578422546
    },
    {
    x: 0.5321128964424133,
    y: 0.549623966217041
    },
    {
    x: 0.40446606278419495,
    y: 0.549623966217041
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8868733644485474,
    bounding_box: [
    {
    x: 0.6265470385551453,
    y: 0.005235473159700632
    },
    {
    x: 0.9661547541618347,
    y: 0.005235473159700632
    },
    {
    x: 0.9661547541618347,
    y: 0.9736433625221252
    },
    {
    x: 0.6265470385551453,
    y: 0.9736433625221252
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8554694056510925,
    bounding_box: [
    {
    x: 0.2634510397911072,
    y: 0.2647989094257355
    },
    {
    x: 0.8625224232673645,
    y: 0.2647989094257355
    },
    {
    x: 0.8625224232673645,
    y: 0.983627200126648
    },
    {
    x: 0.2634510397911072,
    y: 0.983627200126648
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5628054738044739,
    bounding_box: [
    {
    x: 0.27485525608062744,
    y: 0.5121294260025024
    },
    {
    x: 0.8483445644378662,
    y: 0.5121294260025024
    },
    {
    x: 0.8483445644378662,
    y: 0.9807410836219788
    },
    {
    x: 0.27485525608062744,
    y: 0.9807410836219788
    }
    ]
    },
    {
    description: "Television",
    confidence: 0.5424569845199585,
    bounding_box: [
    {
    x: 0.00341166858561337,
    y: 0.0003482398751657456
    },
    {
    x: 0.39086484909057617,
    y: 0.0003482398751657456
    },
    {
    x: 0.39086484909057617,
    y: 0.4793771207332611
    },
    {
    x: 0.00341166858561337,
    y: 0.4793771207332611
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 3597,
    time_in_milliseconds: 120019,
    labels: [
    {
    description: "Advertising",
    confidence: 78.85558605194092
    },
    {
    description: "Banner",
    confidence: 55.654722452163696
    },
    {
    description: "Drink",
    confidence: 55.160027742385864
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7777001261711121,
    bounding_box: [
    {
    x: 0.33126407861709595,
    y: 0.4330786168575287
    },
    {
    x: 0.4602481424808502,
    y: 0.4330786168575287
    },
    {
    x: 0.4602481424808502,
    y: 0.8547483086585999
    },
    {
    x: 0.33126407861709595,
    y: 0.8547483086585999
    }
    ]
    },
    {
    description: "Drink",
    confidence: 0.7372180223464966,
    bounding_box: [
    {
    x: 0.4688087999820709,
    y: 0.551404595375061
    },
    {
    x: 0.5616844296455383,
    y: 0.551404595375061
    },
    {
    x: 0.5616844296455383,
    y: 0.8829479813575745
    },
    {
    x: 0.4688087999820709,
    y: 0.8829479813575745
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.696211040019989,
    bounding_box: [
    {
    x: 0.32584553956985474,
    y: 0.08869161456823349
    },
    {
    x: 0.645428478717804,
    y: 0.08869161456823349
    },
    {
    x: 0.645428478717804,
    y: 0.9121753573417664
    },
    {
    x: 0.32584553956985474,
    y: 0.9121753573417664
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5399118661880493,
    bounding_box: [
    {
    x: 0.3282873332500458,
    y: 0.4407813549041748
    },
    {
    x: 0.46321800351142883,
    y: 0.4407813549041748
    },
    {
    x: 0.46321800351142883,
    y: 0.8667142987251282
    },
    {
    x: 0.3282873332500458,
    y: 0.8667142987251282
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 3655,
    time_in_milliseconds: 121955.00000000001,
    labels: [
    {
    description: "Advertising",
    confidence: 75.93206763267517
    },
    {
    description: "Drink",
    confidence: 55.160027742385864
    },
    {
    description: "Banner",
    confidence: 51.35267376899719
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7977712154388428,
    bounding_box: [
    {
    x: 0.33054405450820923,
    y: 0.43457362055778503
    },
    {
    x: 0.4587874710559845,
    y: 0.43457362055778503
    },
    {
    x: 0.4587874710559845,
    y: 0.8458113670349121
    },
    {
    x: 0.33054405450820923,
    y: 0.8458113670349121
    }
    ]
    },
    {
    description: "Drink",
    confidence: 0.7842010259628296,
    bounding_box: [
    {
    x: 0.4716491401195526,
    y: 0.5601296424865723
    },
    {
    x: 0.5572080016136169,
    y: 0.5601296424865723
    },
    {
    x: 0.5572080016136169,
    y: 0.8800621628761292
    },
    {
    x: 0.4716491401195526,
    y: 0.8800621628761292
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.7649335861206055,
    bounding_box: [
    {
    x: 0.3248947858810425,
    y: 0.08747599273920059
    },
    {
    x: 0.6445805430412292,
    y: 0.08747599273920059
    },
    {
    x: 0.6445805430412292,
    y: 0.9142942428588867
    },
    {
    x: 0.3248947858810425,
    y: 0.9142942428588867
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5311658382415771,
    bounding_box: [
    {
    x: 0.32690373063087463,
    y: 0.45189404487609863
    },
    {
    x: 0.4624421298503876,
    y: 0.45189404487609863
    },
    {
    x: 0.4624421298503876,
    y: 0.8609764575958252
    },
    {
    x: 0.32690373063087463,
    y: 0.8609764575958252
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 3684,
    time_in_milliseconds: 122922.00000000001,
    labels: [
    {
    description: "Library",
    confidence: 79.49305176734924
    },
    {
    description: "Book",
    confidence: 69.25677061080933
    },
    {
    description: "Glasses",
    confidence: 65.22085666656494
    },
    {
    description: "Bookcase",
    confidence: 60.60473918914795
    },
    {
    description: "Publication",
    confidence: 55.21233677864075
    },
    {
    description: "Librarian",
    confidence: 54.93796467781067
    },
    {
    description: "Author",
    confidence: 51.33486986160278
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9083138108253479,
    bounding_box: [
    {
    x: 0.4059402048587799,
    y: 0.386414498090744
    },
    {
    x: 0.5430591702461243,
    y: 0.386414498090744
    },
    {
    x: 0.5430591702461243,
    y: 0.484416127204895
    },
    {
    x: 0.4059402048587799,
    y: 0.484416127204895
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8579084873199463,
    bounding_box: [
    {
    x: 0.3301713466644287,
    y: 0.22793497145175934
    },
    {
    x: 0.8691155910491943,
    y: 0.22793497145175934
    },
    {
    x: 0.8691155910491943,
    y: 0.9955855011940002
    },
    {
    x: 0.3301713466644287,
    y: 0.9955855011940002
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8414695858955383,
    bounding_box: [
    {
    x: 0.6111159324645996,
    y: 0.019757362082600594
    },
    {
    x: 0.9804671406745911,
    y: 0.019757362082600594
    },
    {
    x: 0.9804671406745911,
    y: 0.9801549911499023
    },
    {
    x: 0.6111159324645996,
    y: 0.9801549911499023
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6183132529258728,
    bounding_box: [
    {
    x: 0.3352266550064087,
    y: 0.5293949246406555
    },
    {
    x: 0.862101137638092,
    y: 0.5293949246406555
    },
    {
    x: 0.862101137638092,
    y: 0.9853737950325012
    },
    {
    x: 0.3352266550064087,
    y: 0.9853737950325012
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 3742,
    time_in_milliseconds: 124858,
    labels: [
    {
    description: "Text",
    confidence: 98.57326745986938
    },
    {
    description: "Font",
    confidence: 87.53489255905151
    },
    {
    description: "Document",
    confidence: 85.63002347946167
    },
    {
    description: "Adaptation",
    confidence: 77.25779414176941
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Paper",
    confidence: 68.60209107398987
    },
    {
    description: "Paper product",
    confidence: 53.288841247558594
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 3858,
    time_in_milliseconds: 128728.00000000001,
    labels: [
    {
    description: "Library",
    confidence: 75.59160590171814
    },
    {
    description: "Glasses",
    confidence: 72.36500382423401
    },
    {
    description: "Bookcase",
    confidence: 58.36062431335449
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Book",
    confidence: 53.07499170303345
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9223908185958862,
    bounding_box: [
    {
    x: 0.4765748679637909,
    y: 0.3480384647846222
    },
    {
    x: 0.6223357319831848,
    y: 0.3480384647846222
    },
    {
    x: 0.6223357319831848,
    y: 0.4390450716018677
    },
    {
    x: 0.4765748679637909,
    y: 0.4390450716018677
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.870459794998169,
    bounding_box: [
    {
    x: 0.29632627964019775,
    y: 0.1985587328672409
    },
    {
    x: 0.7724131941795349,
    y: 0.1985587328672409
    },
    {
    x: 0.7724131941795349,
    y: 0.9946573376655579
    },
    {
    x: 0.29632627964019775,
    y: 0.9946573376655579
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8153238296508789,
    bounding_box: [
    {
    x: 0.6177449226379395,
    y: 0.007872619666159153
    },
    {
    x: 0.9809738993644714,
    y: 0.007872619666159153
    },
    {
    x: 0.9809738993644714,
    y: 0.993187427520752
    },
    {
    x: 0.6177449226379395,
    y: 0.993187427520752
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7738656997680664,
    bounding_box: [
    {
    x: 0.28657591342926025,
    y: 0.5633461475372314
    },
    {
    x: 0.7672024369239807,
    y: 0.5633461475372314
    },
    {
    x: 0.7672024369239807,
    y: 0.9910439848899841
    },
    {
    x: 0.28657591342926025,
    y: 0.9910439848899841
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6714261770248413,
    bounding_box: [
    {
    x: 0.2999527156352997,
    y: 0.5455387234687805
    },
    {
    x: 0.77077317237854,
    y: 0.5455387234687805
    },
    {
    x: 0.77077317237854,
    y: 0.9872594475746155
    },
    {
    x: 0.2999527156352997,
    y: 0.9872594475746155
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6069737076759338,
    bounding_box: [
    {
    x: 0.3021395802497864,
    y: 0.27914875745773315
    },
    {
    x: 0.7748259902000427,
    y: 0.27914875745773315
    },
    {
    x: 0.7748259902000427,
    y: 0.9973958134651184
    },
    {
    x: 0.3021395802497864,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4003,
    time_in_milliseconds: 133567,
    labels: [
    {
    description: "Majorelle blue",
    confidence: 89.36188817024231
    },
    {
    description: "Cobalt blue",
    confidence: 83.32540392875671
    },
    {
    description: "Electric blue",
    confidence: 80.4395079612732
    },
    {
    description: "Advertising",
    confidence: 73.68201017379761
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Graphic design",
    confidence: 64.512699842453
    },
    {
    description: "Display advertising",
    confidence: 60.59373617172241
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.8120677471160889,
    bounding_box: [
    {
    x: 0.30927321314811707,
    y: 0.025519022718071938
    },
    {
    x: 0.6721354126930237,
    y: 0.025519022718071938
    },
    {
    x: 0.6721354126930237,
    y: 0.9607071876525879
    },
    {
    x: 0.30927321314811707,
    y: 0.9607071876525879
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4061,
    time_in_milliseconds: 135502,
    labels: [
    {
    description: "Text",
    confidence: 89.7243082523346
    },
    {
    description: "Font",
    confidence: 83.14544558525085
    },
    {
    description: "Greeting card",
    confidence: 78.22802066802979
    }
    ],
    objects: [
    {
    description: "Book",
    confidence: 0.7180665135383606,
    bounding_box: [
    {
    x: 0.13116712868213654,
    y: 0.028687676414847374
    },
    {
    x: 0.8370127081871033,
    y: 0.028687676414847374
    },
    {
    x: 0.8370127081871033,
    y: 0.9640116095542908
    },
    {
    x: 0.13116712868213654,
    y: 0.9640116095542908
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4148,
    time_in_milliseconds: 138404,
    labels: [
    {
    description: "Library",
    confidence: 75.59160590171814
    },
    {
    description: "Glasses",
    confidence: 74.8481273651123
    },
    {
    description: "Bookcase",
    confidence: 66.83416962623596
    },
    {
    description: "Book",
    confidence: 57.679176330566406
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Media",
    confidence: 52.03367471694946
    },
    {
    description: "Author",
    confidence: 50.89436173439026
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9257552027702332,
    bounding_box: [
    {
    x: 0.4841412305831909,
    y: 0.4017236530780792
    },
    {
    x: 0.6237683296203613,
    y: 0.4017236530780792
    },
    {
    x: 0.6237683296203613,
    y: 0.4946277141571045
    },
    {
    x: 0.4841412305831909,
    y: 0.4946277141571045
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8528424501419067,
    bounding_box: [
    {
    x: 0.6201229691505432,
    y: 0.02046790160238743
    },
    {
    x: 0.9827008247375488,
    y: 0.02046790160238743
    },
    {
    x: 0.9827008247375488,
    y: 0.9830097556114197
    },
    {
    x: 0.6201229691505432,
    y: 0.9830097556114197
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8383939862251282,
    bounding_box: [
    {
    x: 0.24132277071475983,
    y: 0.2102775126695633
    },
    {
    x: 0.7685213685035706,
    y: 0.2102775126695633
    },
    {
    x: 0.7685213685035706,
    y: 0.9876007437705994
    },
    {
    x: 0.24132277071475983,
    y: 0.9876007437705994
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7251126170158386,
    bounding_box: [
    {
    x: 0.24864234030246735,
    y: 0.5558558106422424
    },
    {
    x: 0.7753651738166809,
    y: 0.5558558106422424
    },
    {
    x: 0.7753651738166809,
    y: 0.981910765171051
    },
    {
    x: 0.24864234030246735,
    y: 0.981910765171051
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4264,
    time_in_milliseconds: 142275,
    labels: [
    {
    description: "News",
    confidence: 85.96103191375732
    },
    {
    description: "Electric blue",
    confidence: 85.72543859481812
    },
    {
    description: "Newscaster",
    confidence: 81.89145922660828
    },
    {
    description: "Newsreader",
    confidence: 78.01467180252075
    },
    {
    description: "Media",
    confidence: 69.25264596939087
    },
    {
    description: "Journalist",
    confidence: 66.23656153678894
    },
    {
    description: "Event",
    confidence: 62.736958265304565
    },
    {
    description: "Official",
    confidence: 58.48895311355591
    },
    {
    description: "Spokesperson",
    confidence: 57.639408111572266
    },
    {
    description: "Television presenter",
    confidence: 56.7499577999115
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8767371773719788,
    bounding_box: [
    {
    x: 0.3022926449775696,
    y: 0.08775725215673447
    },
    {
    x: 0.6830348968505859,
    y: 0.08775725215673447
    },
    {
    x: 0.6830348968505859,
    y: 0.8958085179328918
    },
    {
    x: 0.3022926449775696,
    y: 0.8958085179328918
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.8701401948928833,
    bounding_box: [
    {
    x: 0.4608774483203888,
    y: 0.4647204875946045
    },
    {
    x: 0.549081027507782,
    y: 0.4647204875946045
    },
    {
    x: 0.549081027507782,
    y: 0.6721832752227783
    },
    {
    x: 0.4608774483203888,
    y: 0.6721832752227783
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8650010824203491,
    bounding_box: [
    {
    x: 0.2990136444568634,
    y: 0.20030651986598969
    },
    {
    x: 0.4438604414463043,
    y: 0.20030651986598969
    },
    {
    x: 0.4438604414463043,
    y: 0.7428679466247559
    },
    {
    x: 0.2990136444568634,
    y: 0.7428679466247559
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.8438407182693481,
    bounding_box: [
    {
    x: 0.4113118350505829,
    y: 0.2666725516319275
    },
    {
    x: 0.5504373908042908,
    y: 0.2666725516319275
    },
    {
    x: 0.5504373908042908,
    y: 0.33834245800971985
    },
    {
    x: 0.4113118350505829,
    y: 0.33834245800971985
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8116559386253357,
    bounding_box: [
    {
    x: 0.3046933114528656,
    y: 0.35360395908355713
    },
    {
    x: 0.7019175887107849,
    y: 0.35360395908355713
    },
    {
    x: 0.7019175887107849,
    y: 0.8974068760871887
    },
    {
    x: 0.3046933114528656,
    y: 0.8974068760871887
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7376062870025635,
    bounding_box: [
    {
    x: 0.582615077495575,
    y: 0.2985723614692688
    },
    {
    x: 0.6938914656639099,
    y: 0.2985723614692688
    },
    {
    x: 0.6938914656639099,
    y: 0.6437569260597229
    },
    {
    x: 0.582615077495575,
    y: 0.6437569260597229
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4467,
    time_in_milliseconds: 149049,
    labels: [
    {
    description: "Glasses",
    confidence: 77.77518033981323
    },
    {
    description: "Library",
    confidence: 77.71359086036682
    },
    {
    description: "Book",
    confidence: 73.74535799026489
    },
    {
    description: "Bookcase",
    confidence: 70.45313119888306
    },
    {
    description: "Publication",
    confidence: 61.657220125198364
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9295205473899841,
    bounding_box: [
    {
    x: 0.4140506088733673,
    y: 0.40110254287719727
    },
    {
    x: 0.554905116558075,
    y: 0.40110254287719727
    },
    {
    x: 0.554905116558075,
    y: 0.5017303824424744
    },
    {
    x: 0.4140506088733673,
    y: 0.5017303824424744
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8448893427848816,
    bounding_box: [
    {
    x: 0.616737425327301,
    y: 0.02125667780637741
    },
    {
    x: 0.980893075466156,
    y: 0.02125667780637741
    },
    {
    x: 0.980893075466156,
    y: 0.9814090728759766
    },
    {
    x: 0.616737425327301,
    y: 0.9814090728759766
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.812520444393158,
    bounding_box: [
    {
    x: 0.3310735523700714,
    y: 0.23612628877162933
    },
    {
    x: 0.8633984327316284,
    y: 0.23612628877162933
    },
    {
    x: 0.8633984327316284,
    y: 0.9953968524932861
    },
    {
    x: 0.3310735523700714,
    y: 0.9953968524932861
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.62579745054245,
    bounding_box: [
    {
    x: 0.32371950149536133,
    y: 0.5402728915214539
    },
    {
    x: 0.8585922718048096,
    y: 0.5402728915214539
    },
    {
    x: 0.8585922718048096,
    y: 0.9848591685295105
    },
    {
    x: 0.32371950149536133,
    y: 0.9848591685295105
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4699,
    time_in_milliseconds: 156790.00000000003,
    labels: [
    {
    description: "Text",
    confidence: 96.52604460716248
    },
    {
    description: "Font",
    confidence: 81.95118308067322
    },
    {
    description: "Water",
    confidence: 80.73040843009949
    },
    {
    description: "Adaptation",
    confidence: 74.08301830291748
    },
    {
    description: "Document",
    confidence: 57.83052444458008
    },
    {
    description: "World",
    confidence: 53.13754081726074
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.534345269203186,
    bounding_box: [
    {
    x: 0,
    y: 0.051178228110075
    },
    {
    x: 0.9973958134651184,
    y: 0.051178228110075
    },
    {
    x: 0.9973958134651184,
    y: 0.9870128035545349
    },
    {
    x: 0,
    y: 0.9870128035545349
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4786,
    time_in_milliseconds: 159692,
    labels: [
    {
    description: "Library",
    confidence: 77.71359086036682
    },
    {
    description: "Bookcase",
    confidence: 72.53415584564209
    },
    {
    description: "Glasses",
    confidence: 72.36500382423401
    },
    {
    description: "Book",
    confidence: 70.70399522781372
    },
    {
    description: "Publication",
    confidence: 58.104801177978516
    },
    {
    description: "Shelving",
    confidence: 54.762256145477295
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9380819201469421,
    bounding_box: [
    {
    x: 0.43524622917175293,
    y: 0.3922770917415619
    },
    {
    x: 0.5764720439910889,
    y: 0.3922770917415619
    },
    {
    x: 0.5764720439910889,
    y: 0.488506555557251
    },
    {
    x: 0.43524622917175293,
    y: 0.488506555557251
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.84681636095047,
    bounding_box: [
    {
    x: 0.6175253987312317,
    y: 0.023287706077098846
    },
    {
    x: 0.9793393611907959,
    y: 0.023287706077098846
    },
    {
    x: 0.9793393611907959,
    y: 0.9806168675422668
    },
    {
    x: 0.6175253987312317,
    y: 0.9806168675422668
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8348861336708069,
    bounding_box: [
    {
    x: 0.3254197835922241,
    y: 0.22225232422351837
    },
    {
    x: 0.8706631660461426,
    y: 0.22225232422351837
    },
    {
    x: 0.8706631660461426,
    y: 0.9893054366111755
    },
    {
    x: 0.3254197835922241,
    y: 0.9893054366111755
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7153618335723877,
    bounding_box: [
    {
    x: 0.3247331380844116,
    y: 0.5390279293060303
    },
    {
    x: 0.8646018505096436,
    y: 0.5390279293060303
    },
    {
    x: 0.8646018505096436,
    y: 0.9876746535301208
    },
    {
    x: 0.3247331380844116,
    y: 0.9876746535301208
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4902,
    time_in_milliseconds: 163563.00000000003,
    labels: [
    {
    description: "Selfie",
    confidence: 84.0704083442688
    },
    {
    description: "Majorelle blue",
    confidence: 83.71732831001282
    },
    {
    description: "Animation",
    confidence: 81.7365288734436
    },
    {
    description: "Facial hair",
    confidence: 81.6852867603302
    },
    {
    description: "Fun",
    confidence: 76.84727907180786
    },
    {
    description: "Glasses",
    confidence: 75.91426968574524
    },
    {
    description: "Photography",
    confidence: 74.84625577926636
    },
    {
    description: "Technology",
    confidence: 73.20890426635742
    },
    {
    description: "Screenshot",
    confidence: 69.05704736709595
    },
    {
    description: "Eyewear",
    confidence: 68.63138675689697
    }
    ],
    objects: [
    {
    description: "Sunglasses",
    confidence: 0.8407505750656128,
    bounding_box: [
    {
    x: 0.4436678886413574,
    y: 0.253357857465744
    },
    {
    x: 0.5699540376663208,
    y: 0.253357857465744
    },
    {
    x: 0.5699540376663208,
    y: 0.3346378803253174
    },
    {
    x: 0.4436678886413574,
    y: 0.3346378803253174
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7681406140327454,
    bounding_box: [
    {
    x: 0.21930116415023804,
    y: 0.10589832812547684
    },
    {
    x: 0.7809355854988098,
    y: 0.10589832812547684
    },
    {
    x: 0.7809355854988098,
    y: 0.8714751601219177
    },
    {
    x: 0.21930116415023804,
    y: 0.8714751601219177
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 4931,
    time_in_milliseconds: 164531,
    labels: [
    {
    description: "Library",
    confidence: 70.123690366745
    },
    {
    description: "Book",
    confidence: 68.95201206207275
    },
    {
    description: "Glasses",
    confidence: 67.38030314445496
    },
    {
    description: "Bookcase",
    confidence: 60.60473918914795
    },
    {
    description: "Publication",
    confidence: 56.72217011451721
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9320157766342163,
    bounding_box: [
    {
    x: 0.42612457275390625,
    y: 0.41226887702941895
    },
    {
    x: 0.5616716742515564,
    y: 0.41226887702941895
    },
    {
    x: 0.5616716742515564,
    y: 0.511112630367279
    },
    {
    x: 0.42612457275390625,
    y: 0.511112630367279
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8588818907737732,
    bounding_box: [
    {
    x: 0.6169615983963013,
    y: 0.016917167231440544
    },
    {
    x: 0.977421224117279,
    y: 0.016917167231440544
    },
    {
    x: 0.977421224117279,
    y: 0.9863302111625671
    },
    {
    x: 0.6169615983963013,
    y: 0.9863302111625671
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8524884581565857,
    bounding_box: [
    {
    x: 0.3256525993347168,
    y: 0.24577821791172028
    },
    {
    x: 0.8777144551277161,
    y: 0.24577821791172028
    },
    {
    x: 0.8777144551277161,
    y: 0.9970096945762634
    },
    {
    x: 0.3256525993347168,
    y: 0.9970096945762634
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6474669575691223,
    bounding_box: [
    {
    x: 0.31712302565574646,
    y: 0.5402893424034119
    },
    {
    x: 0.8765040636062622,
    y: 0.5402893424034119
    },
    {
    x: 0.8765040636062622,
    y: 0.988453209400177
    },
    {
    x: 0.31712302565574646,
    y: 0.988453209400177
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6029242277145386,
    bounding_box: [
    {
    x: 0.3324742019176483,
    y: 0.5565209984779358
    },
    {
    x: 0.8613529205322266,
    y: 0.5565209984779358
    },
    {
    x: 0.8613529205322266,
    y: 0.9739871621131897
    },
    {
    x: 0.3324742019176483,
    y: 0.9739871621131897
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 5047,
    time_in_milliseconds: 168401,
    labels: [
    {
    description: "News",
    confidence: 85.74806451797485
    },
    {
    description: "Chin",
    confidence: 85.04008650779724
    },
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Media",
    confidence: 63.671886920928955
    },
    {
    description: "Electric blue",
    confidence: 58.18745493888855
    },
    {
    description: "Spokesperson",
    confidence: 57.639408111572266
    },
    {
    description: "Official",
    confidence: 50.00404119491577
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7731842994689941,
    bounding_box: [
    {
    x: 0.22926412522792816,
    y: 0.14104780554771423
    },
    {
    x: 0.7509315013885498,
    y: 0.14104780554771423
    },
    {
    x: 0.7509315013885498,
    y: 0.8740271329879761
    },
    {
    x: 0.22926412522792816,
    y: 0.8740271329879761
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.7489108443260193,
    bounding_box: [
    {
    x: 0.24635349214076996,
    y: 0.6516949534416199
    },
    {
    x: 0.7474997639656067,
    y: 0.6516949534416199
    },
    {
    x: 0.7474997639656067,
    y: 0.8618712425231934
    },
    {
    x: 0.24635349214076996,
    y: 0.8618712425231934
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 5192,
    time_in_milliseconds: 173239,
    labels: [
    {
    description: "Library",
    confidence: 83.08793902397156
    },
    {
    description: "Bookcase",
    confidence: 63.09630870819092
    },
    {
    description: "Book",
    confidence: 58.80803465843201
    },
    {
    description: "Librarian",
    confidence: 54.63555455207825
    },
    {
    description: "Author",
    confidence: 51.81126594543457
    },
    {
    description: "Glasses",
    confidence: 51.58061981201172
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9281417727470398,
    bounding_box: [
    {
    x: 0.45565417408943176,
    y: 0.3770216703414917
    },
    {
    x: 0.5977947115898132,
    y: 0.3770216703414917
    },
    {
    x: 0.5977947115898132,
    y: 0.4667454957962036
    },
    {
    x: 0.45565417408943176,
    y: 0.4667454957962036
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8688499927520752,
    bounding_box: [
    {
    x: 0.6225685477256775,
    y: 0.016336197033524513
    },
    {
    x: 0.9773570895195007,
    y: 0.016336197033524513
    },
    {
    x: 0.9773570895195007,
    y: 0.9817050099372864
    },
    {
    x: 0.6225685477256775,
    y: 0.9817050099372864
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.829573392868042,
    bounding_box: [
    {
    x: 0.3146681487560272,
    y: 0.22032545506954193
    },
    {
    x: 0.8655374050140381,
    y: 0.22032545506954193
    },
    {
    x: 0.8655374050140381,
    y: 0.986653745174408
    },
    {
    x: 0.3146681487560272,
    y: 0.986653745174408
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7314220070838928,
    bounding_box: [
    {
    x: 0.3182539939880371,
    y: 0.5431506037712097
    },
    {
    x: 0.8462620377540588,
    y: 0.5431506037712097
    },
    {
    x: 0.8462620377540588,
    y: 0.9832136631011963
    },
    {
    x: 0.3182539939880371,
    y: 0.9832136631011963
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5256863236427307,
    bounding_box: [
    {
    x: 0.3209671676158905,
    y: 0.5574721693992615
    },
    {
    x: 0.8475489616394043,
    y: 0.5574721693992615
    },
    {
    x: 0.8475489616394043,
    y: 0.9881711006164551
    },
    {
    x: 0.3209671676158905,
    y: 0.9881711006164551
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 5221,
    time_in_milliseconds: 174208,
    labels: [
    {
    description: "Text",
    confidence: 97.49382734298706
    },
    {
    description: "Font",
    confidence: 80.4016649723053
    },
    {
    description: "Adaptation",
    confidence: 77.25779414176941
    },
    {
    description: "Organism",
    confidence: 76.71774625778198
    },
    {
    description: "Document",
    confidence: 64.31780457496643
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6148701906204224,
    bounding_box: [
    {
    x: 0.005504362750798464,
    y: 0.14545194804668427
    },
    {
    x: 0.9973958134651184,
    y: 0.14545194804668427
    },
    {
    x: 0.9973958134651184,
    y: 0.3709106147289276
    },
    {
    x: 0.005504362750798464,
    y: 0.3709106147289276
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 5337,
    time_in_milliseconds: 178078,
    labels: [
    {
    description: "Library",
    confidence: 83.54663252830505
    },
    {
    description: "Bookcase",
    confidence: 70.45313119888306
    },
    {
    description: "Book",
    confidence: 67.00026392936707
    },
    {
    description: "Glasses",
    confidence: 59.71137881278992
    },
    {
    description: "Media",
    confidence: 53.86474132537842
    },
    {
    description: "Publication",
    confidence: 52.655547857284546
    },
    {
    description: "Author",
    confidence: 51.57938599586487
    },
    {
    description: "Shelving",
    confidence: 50.00336170196533
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9340725541114807,
    bounding_box: [
    {
    x: 0.4403797686100006,
    y: 0.34548553824424744
    },
    {
    x: 0.5741971135139465,
    y: 0.34548553824424744
    },
    {
    x: 0.5741971135139465,
    y: 0.43756723403930664
    },
    {
    x: 0.4403797686100006,
    y: 0.43756723403930664
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8423128128051758,
    bounding_box: [
    {
    x: 0.6235930323600769,
    y: 0.019775642082095146
    },
    {
    x: 0.9814742207527161,
    y: 0.019775642082095146
    },
    {
    x: 0.9814742207527161,
    y: 0.9841929078102112
    },
    {
    x: 0.6235930323600769,
    y: 0.9841929078102112
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.830197274684906,
    bounding_box: [
    {
    x: 0.28453049063682556,
    y: 0.1943412572145462
    },
    {
    x: 0.8486367464065552,
    y: 0.1943412572145462
    },
    {
    x: 0.8486367464065552,
    y: 0.9892389178276062
    },
    {
    x: 0.28453049063682556,
    y: 0.9892389178276062
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6942420601844788,
    bounding_box: [
    {
    x: 0.30888986587524414,
    y: 0.5178353190422058
    },
    {
    x: 0.8477481007575989,
    y: 0.5178353190422058
    },
    {
    x: 0.8477481007575989,
    y: 0.9758638739585876
    },
    {
    x: 0.30888986587524414,
    y: 0.9758638739585876
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 5656,
    time_in_milliseconds: 188721,
    labels: [
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Photo caption",
    confidence: 72.97320365905762
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Moustache",
    confidence: 58.82037878036499
    },
    {
    description: "News",
    confidence: 58.79448056221008
    },
    {
    description: "Advertising",
    confidence: 58.05258750915527
    },
    {
    description: "Facial hair",
    confidence: 55.52889108657837
    },
    {
    description: "World",
    confidence: 53.13754081726074
    },
    {
    description: "Banner",
    confidence: 51.35267376899719
    }
    ],
    objects: [
    {
    description: "Hat",
    confidence: 0.8610668182373047,
    bounding_box: [
    {
    x: 0.12623260915279388,
    y: 0.10515911132097244
    },
    {
    x: 0.32694384455680847,
    y: 0.10515911132097244
    },
    {
    x: 0.32694384455680847,
    y: 0.32775506377220154
    },
    {
    x: 0.12623260915279388,
    y: 0.32775506377220154
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7949320673942566,
    bounding_box: [
    {
    x: 0.10138124227523804,
    y: 0.09551545977592468
    },
    {
    x: 0.35271427035331726,
    y: 0.09551545977592468
    },
    {
    x: 0.35271427035331726,
    y: 0.9485362768173218
    },
    {
    x: 0.10138124227523804,
    y: 0.9485362768173218
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7732700109481812,
    bounding_box: [
    {
    x: 0.2778468132019043,
    y: 0.11032291501760483
    },
    {
    x: 0.6477718949317932,
    y: 0.11032291501760483
    },
    {
    x: 0.6477718949317932,
    y: 0.9322940707206726
    },
    {
    x: 0.2778468132019043,
    y: 0.9322940707206726
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6665584444999695,
    bounding_box: [
    {
    x: 0.2717128098011017,
    y: 0.553267240524292
    },
    {
    x: 0.6453897953033447,
    y: 0.553267240524292
    },
    {
    x: 0.6453897953033447,
    y: 0.9318355917930603
    },
    {
    x: 0.2717128098011017,
    y: 0.9318355917930603
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.6581584215164185,
    bounding_box: [
    {
    x: 0.12488805502653122,
    y: 0.10471420735120773
    },
    {
    x: 0.3271110951900482,
    y: 0.10471420735120773
    },
    {
    x: 0.3271110951900482,
    y: 0.33789193630218506
    },
    {
    x: 0.12488805502653122,
    y: 0.33789193630218506
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6287543773651123,
    bounding_box: [
    {
    x: 0.1145021840929985,
    y: 0.511145293712616
    },
    {
    x: 0.3152856230735779,
    y: 0.511145293712616
    },
    {
    x: 0.3152856230735779,
    y: 0.9303159713745117
    },
    {
    x: 0.1145021840929985,
    y: 0.9303159713745117
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 5714,
    time_in_milliseconds: 190657,
    labels: [
    {
    description: "Glasses",
    confidence: 84.24429297447205
    },
    {
    description: "Library",
    confidence: 75.31934380531311
    },
    {
    description: "Bookcase",
    confidence: 69.51121091842651
    },
    {
    description: "Book",
    confidence: 64.40427899360657
    },
    {
    description: "Furniture",
    confidence: 56.74918293952942
    },
    {
    description: "Shelving",
    confidence: 55.818337202072144
    },
    {
    description: "Vision care",
    confidence: 51.88118815422058
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9358749389648438,
    bounding_box: [
    {
    x: 0.4501248300075531,
    y: 0.3768516480922699
    },
    {
    x: 0.5925044417381287,
    y: 0.3768516480922699
    },
    {
    x: 0.5925044417381287,
    y: 0.46489474177360535
    },
    {
    x: 0.4501248300075531,
    y: 0.46489474177360535
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8900898098945618,
    bounding_box: [
    {
    x: 0.6252717971801758,
    y: 0.01839139312505722
    },
    {
    x: 0.9798104763031006,
    y: 0.01839139312505722
    },
    {
    x: 0.9798104763031006,
    y: 0.9847877025604248
    },
    {
    x: 0.6252717971801758,
    y: 0.9847877025604248
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.863419234752655,
    bounding_box: [
    {
    x: 0.33407244086265564,
    y: 0.2045639604330063
    },
    {
    x: 0.842013955116272,
    y: 0.2045639604330063
    },
    {
    x: 0.842013955116272,
    y: 0.9969828724861145
    },
    {
    x: 0.33407244086265564,
    y: 0.9969828724861145
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.649924635887146,
    bounding_box: [
    {
    x: 0.3235061764717102,
    y: 0.5486173629760742
    },
    {
    x: 0.8404354453086853,
    y: 0.5486173629760742
    },
    {
    x: 0.8404354453086853,
    y: 0.9873037934303284
    },
    {
    x: 0.3235061764717102,
    y: 0.9873037934303284
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 5975,
    time_in_milliseconds: 199366,
    labels: [
    {
    description: "News",
    confidence: 98.21553230285645
    },
    {
    description: "Suit",
    confidence: 88.39132189750671
    },
    {
    description: "Speech",
    confidence: 87.37871050834656
    },
    {
    description: "Official",
    confidence: 83.40255618095398
    },
    {
    description: "Businessperson",
    confidence: 76.86492800712585
    },
    {
    description: "Technology",
    confidence: 73.20890426635742
    },
    {
    description: "Event",
    confidence: 72.28018045425415
    },
    {
    description: "Spokesperson",
    confidence: 72.08143472671509
    },
    {
    description: "Formal wear",
    confidence: 68.06071996688843
    },
    {
    description: "Gesture",
    confidence: 67.72465705871582
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8900390267372131,
    bounding_box: [
    {
    x: 0.41747215390205383,
    y: 0.19569416344165802
    },
    {
    x: 0.6345462799072266,
    y: 0.19569416344165802
    },
    {
    x: 0.6345462799072266,
    y: 0.8366411328315735
    },
    {
    x: 0.41747215390205383,
    y: 0.8366411328315735
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8762050867080688,
    bounding_box: [
    {
    x: 0.12820959091186523,
    y: 0.2038538008928299
    },
    {
    x: 0.3375818431377411,
    y: 0.2038538008928299
    },
    {
    x: 0.3375818431377411,
    y: 0.840871274471283
    },
    {
    x: 0.12820959091186523,
    y: 0.840871274471283
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8701736330986023,
    bounding_box: [
    {
    x: 0.7720863819122314,
    y: 0.19324642419815063
    },
    {
    x: 0.8701255917549133,
    y: 0.19324642419815063
    },
    {
    x: 0.8701255917549133,
    y: 0.8398354649543762
    },
    {
    x: 0.7720863819122314,
    y: 0.8398354649543762
    }
    ]
    },
    {
    description: "Tie",
    confidence: 0.7949456572532654,
    bounding_box: [
    {
    x: 0.2207460254430771,
    y: 0.3729933500289917
    },
    {
    x: 0.2677578628063202,
    y: 0.3729933500289917
    },
    {
    x: 0.2677578628063202,
    y: 0.581524670124054
    },
    {
    x: 0.2207460254430771,
    y: 0.581524670124054
    }
    ]
    },
    {
    description: "Tie",
    confidence: 0.7909864187240601,
    bounding_box: [
    {
    x: 0.5074812173843384,
    y: 0.3517549932003021
    },
    {
    x: 0.5441854000091553,
    y: 0.3517549932003021
    },
    {
    x: 0.5441854000091553,
    y: 0.622753918170929
    },
    {
    x: 0.5074812173843384,
    y: 0.622753918170929
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7314642667770386,
    bounding_box: [
    {
    x: 0.13132350146770477,
    y: 0.31191664934158325
    },
    {
    x: 0.32747116684913635,
    y: 0.31191664934158325
    },
    {
    x: 0.32747116684913635,
    y: 0.8210251927375793
    },
    {
    x: 0.13132350146770477,
    y: 0.8210251927375793
    }
    ]
    },
    {
    description: "Coat",
    confidence: 0.6896746754646301,
    bounding_box: [
    {
    x: 0.42301085591316223,
    y: 0.2935006320476532
    },
    {
    x: 0.6240141987800598,
    y: 0.2935006320476532
    },
    {
    x: 0.6240141987800598,
    y: 0.7600109577178955
    },
    {
    x: 0.42301085591316223,
    y: 0.7600109577178955
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.6108866930007935,
    bounding_box: [
    {
    x: 0.4692586362361908,
    y: 0.6201955676078796
    },
    {
    x: 0.5965709090232849,
    y: 0.6201955676078796
    },
    {
    x: 0.5965709090232849,
    y: 0.843508243560791
    },
    {
    x: 0.4692586362361908,
    y: 0.843508243560791
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5548095703125,
    bounding_box: [
    {
    x: 0.7782332897186279,
    y: 0.23219983279705048
    },
    {
    x: 0.8668981194496155,
    y: 0.23219983279705048
    },
    {
    x: 0.8668981194496155,
    y: 0.8227600455284119
    },
    {
    x: 0.7782332897186279,
    y: 0.8227600455284119
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6004,
    time_in_milliseconds: 200333,
    labels: [
    {
    description: "Suit",
    confidence: 96.77292704582214
    },
    {
    description: "News",
    confidence: 96.38084769248962
    },
    {
    description: "Formal wear",
    confidence: 88.64681720733643
    },
    {
    description: "Event",
    confidence: 77.5066614151001
    },
    {
    description: "Official",
    confidence: 72.1026599407196
    },
    {
    description: "Gesture",
    confidence: 69.73661184310913
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Businessperson",
    confidence: 64.19032216072083
    },
    {
    description: "Tuxedo",
    confidence: 63.17713260650635
    },
    {
    description: "White-collar worker",
    confidence: 63.04906606674194
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8641130328178406,
    bounding_box: [
    {
    x: 0.15164466202259064,
    y: 0.1960168331861496
    },
    {
    x: 0.3350489139556885,
    y: 0.1960168331861496
    },
    {
    x: 0.3350489139556885,
    y: 0.8514900803565979
    },
    {
    x: 0.15164466202259064,
    y: 0.8514900803565979
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8627349138259888,
    bounding_box: [
    {
    x: 0.5862571001052856,
    y: 0.18636132776737213
    },
    {
    x: 0.8010062575340271,
    y: 0.18636132776737213
    },
    {
    x: 0.8010062575340271,
    y: 0.8488412499427795
    },
    {
    x: 0.5862571001052856,
    y: 0.8488412499427795
    }
    ]
    },
    {
    description: "Tie",
    confidence: 0.8407651782035828,
    bounding_box: [
    {
    x: 0.6769292950630188,
    y: 0.330873042345047
    },
    {
    x: 0.7140478491783142,
    y: 0.330873042345047
    },
    {
    x: 0.7140478491783142,
    y: 0.594793975353241
    },
    {
    x: 0.6769292950630188,
    y: 0.594793975353241
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8322241306304932,
    bounding_box: [
    {
    x: 0.30144202709198,
    y: 0.18056394159793854
    },
    {
    x: 0.5087776780128479,
    y: 0.18056394159793854
    },
    {
    x: 0.5087776780128479,
    y: 0.844886839389801
    },
    {
    x: 0.30144202709198,
    y: 0.844886839389801
    }
    ]
    },
    {
    description: "Dress",
    confidence: 0.8200726509094238,
    bounding_box: [
    {
    x: 0.15183813869953156,
    y: 0.3194170892238617
    },
    {
    x: 0.3204994797706604,
    y: 0.3194170892238617
    },
    {
    x: 0.3204994797706604,
    y: 0.8399448394775391
    },
    {
    x: 0.15183813869953156,
    y: 0.8399448394775391
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8076117634773254,
    bounding_box: [
    {
    x: 0.7677420973777771,
    y: 0.20972205698490143
    },
    {
    x: 0.8707504272460938,
    y: 0.20972205698490143
    },
    {
    x: 0.8707504272460938,
    y: 0.8385317921638489
    },
    {
    x: 0.7677420973777771,
    y: 0.8385317921638489
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.7814012169837952,
    bounding_box: [
    {
    x: 0.6371085047721863,
    y: 0.5755665898323059
    },
    {
    x: 0.7722699642181396,
    y: 0.5755665898323059
    },
    {
    x: 0.7722699642181396,
    y: 0.8411743640899658
    },
    {
    x: 0.6371085047721863,
    y: 0.8411743640899658
    }
    ]
    },
    {
    description: "Coat",
    confidence: 0.7546811103820801,
    bounding_box: [
    {
    x: 0.3105224072933197,
    y: 0.30024003982543945
    },
    {
    x: 0.4988943040370941,
    y: 0.30024003982543945
    },
    {
    x: 0.4988943040370941,
    y: 0.7885938286781311
    },
    {
    x: 0.3105224072933197,
    y: 0.7885938286781311
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7485869526863098,
    bounding_box: [
    {
    x: 0.591105580329895,
    y: 0.2903171479701996
    },
    {
    x: 0.7872985005378723,
    y: 0.2903171479701996
    },
    {
    x: 0.7872985005378723,
    y: 0.7208414673805237
    },
    {
    x: 0.591105580329895,
    y: 0.7208414673805237
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6422551870346069,
    bounding_box: [
    {
    x: 0.7682574391365051,
    y: 0.22486412525177002
    },
    {
    x: 0.8738183975219727,
    y: 0.22486412525177002
    },
    {
    x: 0.8738183975219727,
    y: 0.8187418580055237
    },
    {
    x: 0.7682574391365051,
    y: 0.8187418580055237
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6033,
    time_in_milliseconds: 201301.00000000003,
    labels: [
    {
    description: "News",
    confidence: 98.48092198371887
    },
    {
    description: "Product",
    confidence: 88.28333020210266
    },
    {
    description: "Event",
    confidence: 77.15283632278442
    },
    {
    description: "Media",
    confidence: 74.761962890625
    },
    {
    description: "Newscaster",
    confidence: 69.095379114151
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Suit",
    confidence: 64.45162892341614
    },
    {
    description: "Official",
    confidence: 63.61849308013916
    },
    {
    description: "Automotive exterior",
    confidence: 62.86581754684448
    },
    {
    description: "Screenshot",
    confidence: 62.14386820793152
    }
    ],
    objects: [
    {
    description: "Dress",
    confidence: 0.8872202634811401,
    bounding_box: [
    {
    x: 0.24491411447525024,
    y: 0.3469676971435547
    },
    {
    x: 0.4066941440105438,
    y: 0.3469676971435547
    },
    {
    x: 0.4066941440105438,
    y: 0.8482928276062012
    },
    {
    x: 0.24491411447525024,
    y: 0.8482928276062012
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8640719056129456,
    bounding_box: [
    {
    x: 0.36559775471687317,
    y: 0.2502749264240265
    },
    {
    x: 0.43720877170562744,
    y: 0.2502749264240265
    },
    {
    x: 0.43720877170562744,
    y: 0.4644472301006317
    },
    {
    x: 0.36559775471687317,
    y: 0.4644472301006317
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8613867163658142,
    bounding_box: [
    {
    x: 0.626382052898407,
    y: 0.14395809173583984
    },
    {
    x: 0.8574904799461365,
    y: 0.14395809173583984
    },
    {
    x: 0.8574904799461365,
    y: 0.8573378920555115
    },
    {
    x: 0.626382052898407,
    y: 0.8573378920555115
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8439828753471375,
    bounding_box: [
    {
    x: 0.6239607930183411,
    y: 0.18183796107769012
    },
    {
    x: 0.8571994304656982,
    y: 0.18183796107769012
    },
    {
    x: 0.8571994304656982,
    y: 0.8575524687767029
    },
    {
    x: 0.6239607930183411,
    y: 0.8575524687767029
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8405300974845886,
    bounding_box: [
    {
    x: 0.22708521783351898,
    y: 0.21338701248168945
    },
    {
    x: 0.4224984347820282,
    y: 0.21338701248168945
    },
    {
    x: 0.4224984347820282,
    y: 0.8368696570396423
    },
    {
    x: 0.22708521783351898,
    y: 0.8368696570396423
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8357267379760742,
    bounding_box: [
    {
    x: 0.46092191338539124,
    y: 0.19214987754821777
    },
    {
    x: 0.6618186831474304,
    y: 0.19214987754821777
    },
    {
    x: 0.6618186831474304,
    y: 0.8370022177696228
    },
    {
    x: 0.46092191338539124,
    y: 0.8370022177696228
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8169084191322327,
    bounding_box: [
    {
    x: 0.4684869349002838,
    y: 0.24267613887786865
    },
    {
    x: 0.6545827984809875,
    y: 0.24267613887786865
    },
    {
    x: 0.6545827984809875,
    y: 0.8249689936637878
    },
    {
    x: 0.4684869349002838,
    y: 0.8249689936637878
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7926301956176758,
    bounding_box: [
    {
    x: 0.3631495237350464,
    y: 0.23964476585388184
    },
    {
    x: 0.4368917644023895,
    y: 0.23964476585388184
    },
    {
    x: 0.4368917644023895,
    y: 0.4665529429912567
    },
    {
    x: 0.3631495237350464,
    y: 0.4665529429912567
    }
    ]
    },
    {
    description: "Tire",
    confidence: 0.7266067266464233,
    bounding_box: [
    {
    x: 0.19079935550689697,
    y: 0.5816905498504639
    },
    {
    x: 0.2651872932910919,
    y: 0.5816905498504639
    },
    {
    x: 0.2651872932910919,
    y: 0.8432212471961975
    },
    {
    x: 0.19079935550689697,
    y: 0.8432212471961975
    }
    ]
    },
    {
    description: "Suit",
    confidence: 0.7076669931411743,
    bounding_box: [
    {
    x: 0.36286306381225586,
    y: 0.2616766393184662
    },
    {
    x: 0.43858277797698975,
    y: 0.2616766393184662
    },
    {
    x: 0.43858277797698975,
    y: 0.46442511677742004
    },
    {
    x: 0.36286306381225586,
    y: 0.46442511677742004
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6062,
    time_in_milliseconds: 202268,
    labels: [
    {
    description: "Glasses",
    confidence: 72.36500382423401
    },
    {
    description: "Library",
    confidence: 70.123690366745
    },
    {
    description: "Media",
    confidence: 66.0740077495575
    },
    {
    description: "Book",
    confidence: 54.51059937477112
    },
    {
    description: "Bookcase",
    confidence: 53.32465171813965
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9298614859580994,
    bounding_box: [
    {
    x: 0.48118236660957336,
    y: 0.34146618843078613
    },
    {
    x: 0.6283237338066101,
    y: 0.34146618843078613
    },
    {
    x: 0.6283237338066101,
    y: 0.4404468238353729
    },
    {
    x: 0.48118236660957336,
    y: 0.4404468238353729
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8299548029899597,
    bounding_box: [
    {
    x: 0.13111519813537598,
    y: 0.16953329741954803
    },
    {
    x: 0.8909820914268494,
    y: 0.16953329741954803
    },
    {
    x: 0.8909820914268494,
    y: 0.9964580535888672
    },
    {
    x: 0.13111519813537598,
    y: 0.9964580535888672
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8035783171653748,
    bounding_box: [
    {
    x: 0.6180993318557739,
    y: 0.009909743443131447
    },
    {
    x: 0.9810536503791809,
    y: 0.009909743443131447
    },
    {
    x: 0.9810536503791809,
    y: 0.9814052581787109
    },
    {
    x: 0.6180993318557739,
    y: 0.9814052581787109
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6746339201927185,
    bounding_box: [
    {
    x: 0.31835129857063293,
    y: 0.5507456660270691
    },
    {
    x: 0.842158317565918,
    y: 0.5507456660270691
    },
    {
    x: 0.842158317565918,
    y: 0.9918277859687805
    },
    {
    x: 0.31835129857063293,
    y: 0.9918277859687805
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6207,
    time_in_milliseconds: 207107,
    labels: [
    {
    description: "Majorelle blue",
    confidence: 88.30555081367493
    },
    {
    description: "Yellow",
    confidence: 83.63301753997803
    },
    {
    description: "Art",
    confidence: 77.70091891288757
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Modern art",
    confidence: 69.45001482963562
    },
    {
    description: "Event",
    confidence: 62.736958265304565
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Television program",
    confidence: 59.092557430267334
    },
    {
    description: "Media",
    confidence: 52.03367471694946
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9072745442390442,
    bounding_box: [
    {
    x: 0.5647894740104675,
    y: 0.21492813527584076
    },
    {
    x: 0.779837429523468,
    y: 0.21492813527584076
    },
    {
    x: 0.779837429523468,
    y: 0.8613635897636414
    },
    {
    x: 0.5647894740104675,
    y: 0.8613635897636414
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.9061233997344971,
    bounding_box: [
    {
    x: 0.3412250280380249,
    y: 0.18022163212299347
    },
    {
    x: 0.5837607979774475,
    y: 0.18022163212299347
    },
    {
    x: 0.5837607979774475,
    y: 0.8583521842956543
    },
    {
    x: 0.3412250280380249,
    y: 0.8583521842956543
    }
    ]
    },
    {
    description: "Scarf",
    confidence: 0.8936015963554382,
    bounding_box: [
    {
    x: 0.39269694685935974,
    y: 0.33270394802093506
    },
    {
    x: 0.5356670022010803,
    y: 0.33270394802093506
    },
    {
    x: 0.5356670022010803,
    y: 0.7319158911705017
    },
    {
    x: 0.39269694685935974,
    y: 0.7319158911705017
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8569665551185608,
    bounding_box: [
    {
    x: 0.2118225246667862,
    y: 0.23089651763439178
    },
    {
    x: 0.3813445270061493,
    y: 0.23089651763439178
    },
    {
    x: 0.3813445270061493,
    y: 0.8612920641899109
    },
    {
    x: 0.2118225246667862,
    y: 0.8612920641899109
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.7801046967506409,
    bounding_box: [
    {
    x: 0.6283985376358032,
    y: 0.21043767035007477
    },
    {
    x: 0.7194533944129944,
    y: 0.21043767035007477
    },
    {
    x: 0.7194533944129944,
    y: 0.35079190135002136
    },
    {
    x: 0.6283985376358032,
    y: 0.35079190135002136
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6137365102767944,
    bounding_box: [
    {
    x: 0.34320518374443054,
    y: 0.16128301620483398
    },
    {
    x: 0.5900189280509949,
    y: 0.16128301620483398
    },
    {
    x: 0.5900189280509949,
    y: 0.8530364632606506
    },
    {
    x: 0.34320518374443054,
    y: 0.8530364632606506
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.5298891663551331,
    bounding_box: [
    {
    x: 0.2271604984998703,
    y: 0.25406715273857117
    },
    {
    x: 0.31749066710472107,
    y: 0.25406715273857117
    },
    {
    x: 0.31749066710472107,
    y: 0.4589187800884247
    },
    {
    x: 0.2271604984998703,
    y: 0.4589187800884247
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6265,
    time_in_milliseconds: 209042,
    labels: [
    {
    description: "Library",
    confidence: 77.97207832336426
    },
    {
    description: "Bookcase",
    confidence: 74.99610185623169
    },
    {
    description: "Book",
    confidence: 74.42776560783386
    },
    {
    description: "Glasses",
    confidence: 72.36500382423401
    },
    {
    description: "Publication",
    confidence: 62.17889189720154
    },
    {
    description: "Shelving",
    confidence: 53.6568284034729
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Author",
    confidence: 50.078314542770386
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9339398145675659,
    bounding_box: [
    {
    x: 0.38540706038475037,
    y: 0.39524152874946594
    },
    {
    x: 0.5228391885757446,
    y: 0.39524152874946594
    },
    {
    x: 0.5228391885757446,
    y: 0.4914452135562897
    },
    {
    x: 0.38540706038475037,
    y: 0.4914452135562897
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8410215973854065,
    bounding_box: [
    {
    x: 0.625953197479248,
    y: 0.0012918954016640782
    },
    {
    x: 0.9689793586730957,
    y: 0.0012918954016640782
    },
    {
    x: 0.9689793586730957,
    y: 0.9773375391960144
    },
    {
    x: 0.625953197479248,
    y: 0.9773375391960144
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8255234956741333,
    bounding_box: [
    {
    x: 0.31151506304740906,
    y: 0.22019769251346588
    },
    {
    x: 0.8163105249404907,
    y: 0.22019769251346588
    },
    {
    x: 0.8163105249404907,
    y: 0.9973958134651184
    },
    {
    x: 0.31151506304740906,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6439,
    time_in_milliseconds: 214848,
    labels: [
    {
    description: "Text",
    confidence: 97.28782176971436
    },
    {
    description: "Blue",
    confidence: 94.45302486419678
    },
    {
    description: "Sky",
    confidence: 88.57545852661133
    },
    {
    description: "Font",
    confidence: 80.4016649723053
    },
    {
    description: "Cloud",
    confidence: 76.76413059234619
    },
    {
    description: "Document",
    confidence: 72.4943220615387
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Paper",
    confidence: 66.6322410106659
    },
    {
    description: "Architecture",
    confidence: 65.45118689537048
    },
    {
    description: "Paper product",
    confidence: 57.73124098777771
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6238282918930054,
    bounding_box: [
    {
    x: 0.014863516204059124,
    y: 0.13394109904766083
    },
    {
    x: 0.9916273951530457,
    y: 0.13394109904766083
    },
    {
    x: 0.9916273951530457,
    y: 0.9912972450256348
    },
    {
    x: 0.014863516204059124,
    y: 0.9912972450256348
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6468,
    time_in_milliseconds: 215815,
    labels: [
    {
    description: "Text",
    confidence: 97.25608229637146
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Adaptation",
    confidence: 77.25779414176941
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Document",
    confidence: 65.95351099967957
    },
    {
    description: "World",
    confidence: 53.13754081726074
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.539181113243103,
    bounding_box: [
    {
    x: 0.00005314778536558151,
    y: 0.15504832565784454
    },
    {
    x: 0.9973958134651184,
    y: 0.15504832565784454
    },
    {
    x: 0.9973958134651184,
    y: 0.40709567070007324
    },
    {
    x: 0.00005314778536558151,
    y: 0.40709567070007324
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6555,
    time_in_milliseconds: 218718.00000000003,
    labels: [
    {
    description: "Library",
    confidence: 73.65115880966187
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Book",
    confidence: 65.93479514122009
    },
    {
    description: "Bookcase",
    confidence: 63.09630870819092
    },
    {
    description: "Media",
    confidence: 53.86474132537842
    },
    {
    description: "Publication",
    confidence: 52.655547857284546
    },
    {
    description: "Author",
    confidence: 50.98612308502197
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9358869194984436,
    bounding_box: [
    {
    x: 0.43975022435188293,
    y: 0.36022672057151794
    },
    {
    x: 0.5791860222816467,
    y: 0.36022672057151794
    },
    {
    x: 0.5791860222816467,
    y: 0.4618881642818451
    },
    {
    x: 0.43975022435188293,
    y: 0.4618881642818451
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8805296421051025,
    bounding_box: [
    {
    x: 0.6240089535713196,
    y: 0.01823563501238823
    },
    {
    x: 0.9775946736335754,
    y: 0.01823563501238823
    },
    {
    x: 0.9775946736335754,
    y: 0.9865767359733582
    },
    {
    x: 0.6240089535713196,
    y: 0.9865767359733582
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8585366606712341,
    bounding_box: [
    {
    x: 0.3400104343891144,
    y: 0.20153777301311493
    },
    {
    x: 0.7602014541625977,
    y: 0.20153777301311493
    },
    {
    x: 0.7602014541625977,
    y: 0.9973958134651184
    },
    {
    x: 0.3400104343891144,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6816,
    time_in_milliseconds: 227427.00000000003,
    labels: [
    {
    description: "Text",
    confidence: 97.42321968078613
    },
    {
    description: "Font",
    confidence: 80.4016649723053
    },
    {
    description: "Adaptation",
    confidence: 79.11608219146729
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Document",
    confidence: 68.17102432250977
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6117486953735352,
    bounding_box: [
    {
    x: 0.0014572837390005589,
    y: 0.14147412776947021
    },
    {
    x: 0.9898471236228943,
    y: 0.14147412776947021
    },
    {
    x: 0.9898471236228943,
    y: 0.39145490527153015
    },
    {
    x: 0.0014572837390005589,
    y: 0.39145490527153015
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 6932,
    time_in_milliseconds: 231297,
    labels: [
    {
    description: "Library",
    confidence: 74.49277639389038
    },
    {
    description: "Book",
    confidence: 73.74535799026489
    },
    {
    description: "Bookcase",
    confidence: 71.95543050765991
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Publication",
    confidence: 59.97973680496216
    },
    {
    description: "Shelving",
    confidence: 56.82857036590576
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Author",
    confidence: 50.078314542770386
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9392926096916199,
    bounding_box: [
    {
    x: 0.45639848709106445,
    y: 0.363213449716568
    },
    {
    x: 0.6036227345466614,
    y: 0.363213449716568
    },
    {
    x: 0.6036227345466614,
    y: 0.45325636863708496
    },
    {
    x: 0.45639848709106445,
    y: 0.45325636863708496
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8728768825531006,
    bounding_box: [
    {
    x: 0.6248160004615784,
    y: 0.019694583490490913
    },
    {
    x: 0.9807393550872803,
    y: 0.019694583490490913
    },
    {
    x: 0.9807393550872803,
    y: 0.9852797389030457
    },
    {
    x: 0.6248160004615784,
    y: 0.9852797389030457
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8461203575134277,
    bounding_box: [
    {
    x: 0.30606797337532043,
    y: 0.19054578244686127
    },
    {
    x: 0.7625752091407776,
    y: 0.19054578244686127
    },
    {
    x: 0.7625752091407776,
    y: 0.9973958134651184
    },
    {
    x: 0.30606797337532043,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7742236256599426,
    bounding_box: [
    {
    x: 0.29555025696754456,
    y: 0.5545645356178284
    },
    {
    x: 0.7607874274253845,
    y: 0.5545645356178284
    },
    {
    x: 0.7607874274253845,
    y: 0.980844259262085
    },
    {
    x: 0.29555025696754456,
    y: 0.980844259262085
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.550548255443573,
    bounding_box: [
    {
    x: 0.3125016987323761,
    y: 0.5245718955993652
    },
    {
    x: 0.7549752593040466,
    y: 0.5245718955993652
    },
    {
    x: 0.7549752593040466,
    y: 0.989138126373291
    },
    {
    x: 0.3125016987323761,
    y: 0.989138126373291
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 7077,
    time_in_milliseconds: 236135.00000000003,
    labels: [
    {
    description: "Text",
    confidence: 97.65229225158691
    },
    {
    description: "Font",
    confidence: 86.67431473731995
    },
    {
    description: "Line",
    confidence: 86.46460175514221
    },
    {
    description: "Screenshot",
    confidence: 69.54739689826965
    },
    {
    description: "Parallel",
    confidence: 52.36695408821106
    },
    {
    description: "Number",
    confidence: 50.872987508773804
    },
    {
    description: "Rectangle",
    confidence: 50.47368407249451
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 7367,
    time_in_milliseconds: 245812,
    labels: [
    {
    description: "Bookcase",
    confidence: 80.8361291885376
    },
    {
    description: "Library",
    confidence: 76.92928910255432
    },
    {
    description: "Glasses",
    confidence: 76.88568830490112
    },
    {
    description: "Book",
    confidence: 75.29716491699219
    },
    {
    description: "Shelving",
    confidence: 70.2454924583435
    },
    {
    description: "Publication",
    confidence: 62.68370747566223
    },
    {
    description: "Furniture",
    confidence: 59.37383770942688
    },
    {
    description: "Shelf",
    confidence: 50.827109813690186
    },
    {
    description: "Collection",
    confidence: 50.01777410507202
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9351646304130554,
    bounding_box: [
    {
    x: 0.43216371536254883,
    y: 0.39632460474967957
    },
    {
    x: 0.5732453465461731,
    y: 0.39632460474967957
    },
    {
    x: 0.5732453465461731,
    y: 0.4911911189556122
    },
    {
    x: 0.43216371536254883,
    y: 0.4911911189556122
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.854051947593689,
    bounding_box: [
    {
    x: 0.6270415186882019,
    y: 0.01867850311100483
    },
    {
    x: 0.9839217662811279,
    y: 0.01867850311100483
    },
    {
    x: 0.9839217662811279,
    y: 0.9810009002685547
    },
    {
    x: 0.6270415186882019,
    y: 0.9810009002685547
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.808975875377655,
    bounding_box: [
    {
    x: 0.3181365728378296,
    y: 0.21575134992599487
    },
    {
    x: 0.7935873866081238,
    y: 0.21575134992599487
    },
    {
    x: 0.7935873866081238,
    y: 0.9973958134651184
    },
    {
    x: 0.3181365728378296,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 7454,
    time_in_milliseconds: 248715,
    labels: [
    {
    description: "Text",
    confidence: 98.59602451324463
    },
    {
    description: "Font",
    confidence: 88.54238986968994
    },
    {
    description: "Document",
    confidence: 78.25700044631958
    },
    {
    description: "Organism",
    confidence: 76.71774625778198
    },
    {
    description: "Adaptation",
    confidence: 74.08301830291748
    },
    {
    description: "Paper",
    confidence: 54.30682301521301
    },
    {
    description: "Writing",
    confidence: 50.9225070476532
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 7628,
    time_in_milliseconds: 254521.00000000003,
    labels: [
    {
    description: "Glasses",
    confidence: 77.77518033981323
    },
    {
    description: "Library",
    confidence: 73.36719036102295
    },
    {
    description: "Vision care",
    confidence: 51.88118815422058
    },
    {
    description: "Book",
    confidence: 50.69376230239868
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9305571913719177,
    bounding_box: [
    {
    x: 0.4482711851596832,
    y: 0.3738856315612793
    },
    {
    x: 0.5879845023155212,
    y: 0.3738856315612793
    },
    {
    x: 0.5879845023155212,
    y: 0.461988240480423
    },
    {
    x: 0.4482711851596832,
    y: 0.461988240480423
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8783217668533325,
    bounding_box: [
    {
    x: 0.31076815724372864,
    y: 0.21748913824558258
    },
    {
    x: 0.8805865049362183,
    y: 0.21748913824558258
    },
    {
    x: 0.8805865049362183,
    y: 0.991408109664917
    },
    {
    x: 0.31076815724372864,
    y: 0.991408109664917
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8506370186805725,
    bounding_box: [
    {
    x: 0.616370439529419,
    y: 0.01451912522315979
    },
    {
    x: 0.9788971543312073,
    y: 0.01451912522315979
    },
    {
    x: 0.9788971543312073,
    y: 0.9820022583007812
    },
    {
    x: 0.616370439529419,
    y: 0.9820022583007812
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 7715,
    time_in_milliseconds: 257424.00000000003,
    labels: [
    {
    description: "Vehicle",
    confidence: 96.3193953037262
    },
    {
    description: "Car",
    confidence: 92.43602156639099
    },
    {
    description: "Motor vehicle",
    confidence: 90.10037183761597
    },
    {
    description: "Soil",
    confidence: 63.71765732765198
    },
    {
    description: "Automotive exterior",
    confidence: 62.86581754684448
    },
    {
    description: "Off-roading",
    confidence: 59.817177057266235
    },
    {
    description: "Landscape",
    confidence: 59.30580496788025
    },
    {
    description: "City car",
    confidence: 57.80327320098877
    },
    {
    description: "Classic car",
    confidence: 56.92451000213623
    },
    {
    description: "Compact car",
    confidence: 56.30393028259277
    }
    ],
    objects: [
    {
    description: "Toy vehicle",
    confidence: 0.8661748170852661,
    bounding_box: [
    {
    x: 0.4033466875553131,
    y: 0.08187942951917648
    },
    {
    x: 0.6925945281982422,
    y: 0.08187942951917648
    },
    {
    x: 0.6925945281982422,
    y: 0.7410340905189514
    },
    {
    x: 0.4033466875553131,
    y: 0.7410340905189514
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 7773,
    time_in_milliseconds: 259359.00000000003,
    labels: [
    {
    description: "Land vehicle",
    confidence: 99.61627125740051
    },
    {
    description: "Vehicle",
    confidence: 99.03031587600708
    },
    {
    description: "Off-roading",
    confidence: 98.15940260887146
    },
    {
    description: "Nature",
    confidence: 95.3452467918396
    },
    {
    description: "Car",
    confidence: 95.31705975532532
    },
    {
    description: "Off-road racing",
    confidence: 89.51652646064758
    },
    {
    description: "Soil",
    confidence: 87.80663013458252
    },
    {
    description: "Mud",
    confidence: 87.45541572570801
    },
    {
    description: "Automotive exterior",
    confidence: 84.83927845954895
    },
    {
    description: "Off-road vehicle",
    confidence: 79.69638109207153
    }
    ],
    objects: [
    {
    description: "Car",
    confidence: 0.9121601581573486,
    bounding_box: [
    {
    x: 0.3710468113422394,
    y: 0.28860199451446533
    },
    {
    x: 0.6870246529579163,
    y: 0.28860199451446533
    },
    {
    x: 0.6870246529579163,
    y: 0.5596137642860413
    },
    {
    x: 0.3710468113422394,
    y: 0.5596137642860413
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 7802,
    time_in_milliseconds: 260326.00000000003,
    labels: [
    {
    description: "Off-roading",
    confidence: 96.43729329109192
    },
    {
    description: "Automotive exterior",
    confidence: 93.46473813056946
    },
    {
    description: "Vehicle",
    confidence: 92.72748827934265
    },
    {
    description: "Off-road racing",
    confidence: 89.91496562957764
    },
    {
    description: "Car",
    confidence: 86.99979186058044
    },
    {
    description: "Bumper",
    confidence: 78.18993926048279
    },
    {
    description: "Soil",
    confidence: 78.00834774971008
    },
    {
    description: "Tree",
    confidence: 68.69218349456787
    },
    {
    description: "Auto part",
    confidence: 68.21197867393494
    },
    {
    description: "Mud",
    confidence: 66.92233085632324
    }
    ],
    objects: [
    {
    description: "Car",
    confidence: 0.7822225689888,
    bounding_box: [
    {
    x: 0.3122027516365051,
    y: 0.41869187355041504
    },
    {
    x: 0.5913612246513367,
    y: 0.41869187355041504
    },
    {
    x: 0.5913612246513367,
    y: 0.5960386991500854
    },
    {
    x: 0.3122027516365051,
    y: 0.5960386991500854
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 7831,
    time_in_milliseconds: 261294.00000000003,
    labels: [
    {
    description: "Off-road racing",
    confidence: 96.98141813278198
    },
    {
    description: "Off-roading",
    confidence: 96.71146273612976
    },
    {
    description: "Vehicle",
    confidence: 90.45347571372986
    },
    {
    description: "Off-road vehicle",
    confidence: 88.30991983413696
    },
    {
    description: "Rally raid",
    confidence: 76.56693458557129
    },
    {
    description: "Car",
    confidence: 75.84530115127563
    },
    {
    description: "World rally championship",
    confidence: 75.07081031799316
    },
    {
    description: "Soil",
    confidence: 74.89721179008484
    },
    {
    description: "Geological phenomenon",
    confidence: 73.9093005657196
    },
    {
    description: "Rallying",
    confidence: 67.633056640625
    }
    ],
    objects: [
    {
    description: "Car",
    confidence: 0.5247215032577515,
    bounding_box: [
    {
    x: 0.42140984535217285,
    y: 0.2677114009857178
    },
    {
    x: 0.6144077181816101,
    y: 0.2677114009857178
    },
    {
    x: 0.6144077181816101,
    y: 0.5458377003669739
    },
    {
    x: 0.42140984535217285,
    y: 0.5458377003669739
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 8092,
    time_in_milliseconds: 270003,
    labels: [
    {
    description: "Glasses",
    confidence: 77.77518033981323
    },
    {
    description: "Library",
    confidence: 76.92928910255432
    },
    {
    description: "Book",
    confidence: 65.1863157749176
    },
    {
    description: "Bookcase",
    confidence: 64.64904546737671
    },
    {
    description: "Librarian",
    confidence: 54.93796467781067
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9407033324241638,
    bounding_box: [
    {
    x: 0.42573949694633484,
    y: 0.35694655776023865
    },
    {
    x: 0.5765151977539062,
    y: 0.35694655776023865
    },
    {
    x: 0.5765151977539062,
    y: 0.449908584356308
    },
    {
    x: 0.42573949694633484,
    y: 0.449908584356308
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8632119297981262,
    bounding_box: [
    {
    x: 0.2928658425807953,
    y: 0.19819492101669312
    },
    {
    x: 0.8589019775390625,
    y: 0.19819492101669312
    },
    {
    x: 0.8589019775390625,
    y: 0.9910494685173035
    },
    {
    x: 0.2928658425807953,
    y: 0.9910494685173035
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8491876125335693,
    bounding_box: [
    {
    x: 0.6287025213241577,
    y: 0.005302680656313896
    },
    {
    x: 0.96816086769104,
    y: 0.005302680656313896
    },
    {
    x: 0.96816086769104,
    y: 0.9731807112693787
    },
    {
    x: 0.6287025213241577,
    y: 0.9731807112693787
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6029040813446045,
    bounding_box: [
    {
    x: 0.29391172528266907,
    y: 0.5359759330749512
    },
    {
    x: 0.8583054542541504,
    y: 0.5359759330749512
    },
    {
    x: 0.8583054542541504,
    y: 0.9875741600990295
    },
    {
    x: 0.29391172528266907,
    y: 0.9875741600990295
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 8295,
    time_in_milliseconds: 276777,
    labels: [
    {
    description: "Text",
    confidence: 98.2957124710083
    },
    {
    description: "Font",
    confidence: 86.67431473731995
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Document",
    confidence: 61.57703399658203
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 8498,
    time_in_milliseconds: 283549.00000000006,
    labels: [
    {
    description: "Library",
    confidence: 80.96507787704468
    },
    {
    description: "Book",
    confidence: 66.29728078842163
    },
    {
    description: "Bookcase",
    confidence: 63.88285160064697
    },
    {
    description: "Glasses",
    confidence: 59.71137881278992
    },
    {
    description: "Publication",
    confidence: 52.655547857284546
    },
    {
    description: "Author",
    confidence: 51.0759711265564
    },
    {
    description: "Librarian",
    confidence: 50.22874474525452
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9244369268417358,
    bounding_box: [
    {
    x: 0.41070982813835144,
    y: 0.39182543754577637
    },
    {
    x: 0.5508183836936951,
    y: 0.39182543754577637
    },
    {
    x: 0.5508183836936951,
    y: 0.48364195227622986
    },
    {
    x: 0.41070982813835144,
    y: 0.48364195227622986
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8538541793823242,
    bounding_box: [
    {
    x: 0.6209652423858643,
    y: 0.014250531792640686
    },
    {
    x: 0.9786739349365234,
    y: 0.014250531792640686
    },
    {
    x: 0.9786739349365234,
    y: 0.9853015542030334
    },
    {
    x: 0.6209652423858643,
    y: 0.9853015542030334
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8279221653938293,
    bounding_box: [
    {
    x: 0.31537196040153503,
    y: 0.21024012565612793
    },
    {
    x: 0.8630766868591309,
    y: 0.21024012565612793
    },
    {
    x: 0.8630766868591309,
    y: 0.996162474155426
    },
    {
    x: 0.31537196040153503,
    y: 0.996162474155426
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5656659603118896,
    bounding_box: [
    {
    x: 0.3083525002002716,
    y: 0.5378231406211853
    },
    {
    x: 0.8602735996246338,
    y: 0.5378231406211853
    },
    {
    x: 0.8602735996246338,
    y: 0.9865540862083435
    },
    {
    x: 0.3083525002002716,
    y: 0.9865540862083435
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 8672,
    time_in_milliseconds: 289356,
    labels: [ ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6630797386169434,
    bounding_box: [
    {
    x: 0.07836902886629105,
    y: 0.1377210170030594
    },
    {
    x: 0.8465430736541748,
    y: 0.1377210170030594
    },
    {
    x: 0.8465430736541748,
    y: 0.8501421809196472
    },
    {
    x: 0.07836902886629105,
    y: 0.8501421809196472
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 8759,
    time_in_milliseconds: 292258.00000000006,
    labels: [
    {
    description: "Library",
    confidence: 79.49305176734924
    },
    {
    description: "Book",
    confidence: 72.03788161277771
    },
    {
    description: "Glasses",
    confidence: 70.90374231338501
    },
    {
    description: "Bookcase",
    confidence: 69.82871890068054
    },
    {
    description: "Publication",
    confidence: 58.104801177978516
    },
    {
    description: "Shelving",
    confidence: 53.6568284034729
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Librarian",
    confidence: 51.521944999694824
    },
    {
    description: "Author",
    confidence: 51.33486986160278
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9367017149925232,
    bounding_box: [
    {
    x: 0.41844236850738525,
    y: 0.4145776927471161
    },
    {
    x: 0.5557317137718201,
    y: 0.4145776927471161
    },
    {
    x: 0.5557317137718201,
    y: 0.5081582069396973
    },
    {
    x: 0.41844236850738525,
    y: 0.5081582069396973
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.869888424873352,
    bounding_box: [
    {
    x: 0.6227533221244812,
    y: 0.009882752783596516
    },
    {
    x: 0.9813966155052185,
    y: 0.009882752783596516
    },
    {
    x: 0.9813966155052185,
    y: 0.9857401251792908
    },
    {
    x: 0.6227533221244812,
    y: 0.9857401251792908
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8586903810501099,
    bounding_box: [
    {
    x: 0.3193040192127228,
    y: 0.255862295627594
    },
    {
    x: 0.8680963516235352,
    y: 0.255862295627594
    },
    {
    x: 0.8680963516235352,
    y: 0.9901713728904724
    },
    {
    x: 0.3193040192127228,
    y: 0.9901713728904724
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7404859066009521,
    bounding_box: [
    {
    x: 0.31845831871032715,
    y: 0.28581544756889343
    },
    {
    x: 0.8629576563835144,
    y: 0.28581544756889343
    },
    {
    x: 0.8629576563835144,
    y: 0.9937682151794434
    },
    {
    x: 0.31845831871032715,
    y: 0.9937682151794434
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7264677882194519,
    bounding_box: [
    {
    x: 0.30878740549087524,
    y: 0.5505812764167786
    },
    {
    x: 0.8597593307495117,
    y: 0.5505812764167786
    },
    {
    x: 0.8597593307495117,
    y: 0.9879887104034424
    },
    {
    x: 0.30878740549087524,
    y: 0.9879887104034424
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 8875,
    time_in_milliseconds: 296129,
    labels: [
    {
    description: "Text",
    confidence: 97.37935066223145
    },
    {
    description: "Blue",
    confidence: 93.71774196624756
    },
    {
    description: "Sky",
    confidence: 86.2245500087738
    },
    {
    description: "Font",
    confidence: 78.25835347175598
    },
    {
    description: "Document",
    confidence: 78.06095480918884
    },
    {
    description: "Cloud",
    confidence: 71.0040271282196
    },
    {
    description: "Paper",
    confidence: 70.24929523468018
    },
    {
    description: "Paper product",
    confidence: 62.170207500457764
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6854321956634521,
    bounding_box: [
    {
    x: 0.005348218604922295,
    y: 0.13421379029750824
    },
    {
    x: 0.9953987002372742,
    y: 0.13421379029750824
    },
    {
    x: 0.9953987002372742,
    y: 0.9912733435630798
    },
    {
    x: 0.005348218604922295,
    y: 0.9912733435630798
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 8904,
    time_in_milliseconds: 297096,
    labels: [
    {
    description: "Text",
    confidence: 97.19057083129883
    },
    {
    description: "Font",
    confidence: 84.10601615905762
    },
    {
    description: "Adaptation",
    confidence: 74.08301830291748
    },
    {
    description: "Document",
    confidence: 64.31780457496643
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6880057454109192,
    bounding_box: [
    {
    x: 0,
    y: 0.022853756323456764
    },
    {
    x: 0.9862510561943054,
    y: 0.022853756323456764
    },
    {
    x: 0.9862510561943054,
    y: 0.40051987767219543
    },
    {
    x: 0,
    y: 0.40051987767219543
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 8991,
    time_in_milliseconds: 300000,
    labels: [
    {
    description: "Library",
    confidence: 85.11699438095093
    },
    {
    description: "Bookcase",
    confidence: 71.95543050765991
    },
    {
    description: "Book",
    confidence: 65.1863157749176
    },
    {
    description: "Glasses",
    confidence: 62.70098090171814
    },
    {
    description: "Librarian",
    confidence: 54.02596592903137
    },
    {
    description: "Shelving",
    confidence: 51.28196477890015
    },
    {
    description: "Author",
    confidence: 50.40324330329895
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9289129376411438,
    bounding_box: [
    {
    x: 0.40520235896110535,
    y: 0.3684706389904022
    },
    {
    x: 0.5416950583457947,
    y: 0.3684706389904022
    },
    {
    x: 0.5416950583457947,
    y: 0.46246182918548584
    },
    {
    x: 0.40520235896110535,
    y: 0.46246182918548584
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8601057529449463,
    bounding_box: [
    {
    x: 0.6246933341026306,
    y: 0.01919199712574482
    },
    {
    x: 0.9749504923820496,
    y: 0.01919199712574482
    },
    {
    x: 0.9749504923820496,
    y: 0.9823451042175293
    },
    {
    x: 0.6246933341026306,
    y: 0.9823451042175293
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8422330617904663,
    bounding_box: [
    {
    x: 0.3052555024623871,
    y: 0.22783713042736053
    },
    {
    x: 0.8523050546646118,
    y: 0.22783713042736053
    },
    {
    x: 0.8523050546646118,
    y: 0.9908449053764343
    },
    {
    x: 0.3052555024623871,
    y: 0.9908449053764343
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6933737993240356,
    bounding_box: [
    {
    x: 0.3106778860092163,
    y: 0.5377467274665833
    },
    {
    x: 0.8472366333007812,
    y: 0.5377467274665833
    },
    {
    x: 0.8472366333007812,
    y: 0.9824187159538269
    },
    {
    x: 0.3106778860092163,
    y: 0.9824187159538269
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 9252,
    time_in_milliseconds: 308709,
    labels: [
    {
    description: "Text",
    confidence: 97.91299104690552
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Document",
    confidence: 55.99638223648071
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6556552648544312,
    bounding_box: [
    {
    x: 0.0027651116251945496,
    y: 0.28414425253868103
    },
    {
    x: 0.9957664608955383,
    y: 0.28414425253868103
    },
    {
    x: 0.9957664608955383,
    y: 0.9920599460601807
    },
    {
    x: 0.0027651116251945496,
    y: 0.9920599460601807
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 9426,
    time_in_milliseconds: 314514,
    labels: [
    {
    description: "Text",
    confidence: 96.69932126998901
    },
    {
    description: "Font",
    confidence: 87.53489255905151
    },
    {
    description: "Technology",
    confidence: 75.60265064239502
    },
    {
    description: "Electronic device",
    confidence: 66.12973213195801
    },
    {
    description: "Icon",
    confidence: 57.787519693374634
    },
    {
    description: "Screenshot",
    confidence: 57.46917724609375
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 9716,
    time_in_milliseconds: 324190,
    labels: [
    {
    description: "Library",
    confidence: 76.66484713554382
    },
    {
    description: "Glasses",
    confidence: 75.91426968574524
    },
    {
    description: "Bookcase",
    confidence: 73.92945289611816
    },
    {
    description: "Book",
    confidence: 69.8504626750946
    },
    {
    description: "Librarian",
    confidence: 59.85058546066284
    },
    {
    description: "Publication",
    confidence: 55.21233677864075
    },
    {
    description: "Author",
    confidence: 51.33486986160278
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9186989665031433,
    bounding_box: [
    {
    x: 0.4028913080692291,
    y: 0.3828141689300537
    },
    {
    x: 0.5417019724845886,
    y: 0.3828141689300537
    },
    {
    x: 0.5417019724845886,
    y: 0.4801121950149536
    },
    {
    x: 0.4028913080692291,
    y: 0.4801121950149536
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8330101370811462,
    bounding_box: [
    {
    x: 0.6284053921699524,
    y: 0.004435580689460039
    },
    {
    x: 0.9680125713348389,
    y: 0.004435580689460039
    },
    {
    x: 0.9680125713348389,
    y: 0.9789544939994812
    },
    {
    x: 0.6284053921699524,
    y: 0.9789544939994812
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.782964825630188,
    bounding_box: [
    {
    x: 0.2372492104768753,
    y: 0.2139647752046585
    },
    {
    x: 0.8245329856872559,
    y: 0.2139647752046585
    },
    {
    x: 0.8245329856872559,
    y: 0.9973958134651184
    },
    {
    x: 0.2372492104768753,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6227532029151917,
    bounding_box: [
    {
    x: 0.23583853244781494,
    y: 0.21542491018772125
    },
    {
    x: 0.8528361916542053,
    y: 0.21542491018772125
    },
    {
    x: 0.8528361916542053,
    y: 0.9772658348083496
    },
    {
    x: 0.23583853244781494,
    y: 0.9772658348083496
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5004088878631592,
    bounding_box: [
    {
    x: 0.24289686977863312,
    y: 0.5143353343009949
    },
    {
    x: 0.8366531729698181,
    y: 0.5143353343009949
    },
    {
    x: 0.8366531729698181,
    y: 0.9955281615257263
    },
    {
    x: 0.24289686977863312,
    y: 0.9955281615257263
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 9861,
    time_in_milliseconds: 329028,
    labels: [
    {
    description: "Display window",
    confidence: 84.4846785068512
    },
    {
    description: "Snapshot",
    confidence: 83.58544111251831
    },
    {
    description: "Electric blue",
    confidence: 67.55332946777344
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Uniform",
    confidence: 64.62947130203247
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Advertising",
    confidence: 60.64619421958923
    },
    {
    description: "Art",
    confidence: 58.13031792640686
    },
    {
    description: "Display case",
    confidence: 56.85863494873047
    },
    {
    description: "Fictional character",
    confidence: 52.79175639152527
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8700196743011475,
    bounding_box: [
    {
    x: 0.49685224890708923,
    y: 0.17185784876346588
    },
    {
    x: 0.6680924892425537,
    y: 0.17185784876346588
    },
    {
    x: 0.6680924892425537,
    y: 0.8959252238273621
    },
    {
    x: 0.49685224890708923,
    y: 0.8959252238273621
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.8664129972457886,
    bounding_box: [
    {
    x: 0.36994990706443787,
    y: 0.08004765212535858
    },
    {
    x: 0.4798743426799774,
    y: 0.08004765212535858
    },
    {
    x: 0.4798743426799774,
    y: 0.18564237654209137
    },
    {
    x: 0.36994990706443787,
    y: 0.18564237654209137
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.854409396648407,
    bounding_box: [
    {
    x: 0.30653688311576843,
    y: 0.12305581569671631
    },
    {
    x: 0.5155056118965149,
    y: 0.12305581569671631
    },
    {
    x: 0.5155056118965149,
    y: 0.8995499610900879
    },
    {
    x: 0.30653688311576843,
    y: 0.8995499610900879
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.715501606464386,
    bounding_box: [
    {
    x: 0.30050599575042725,
    y: 0.23787569999694824
    },
    {
    x: 0.5110430717468262,
    y: 0.23787569999694824
    },
    {
    x: 0.5110430717468262,
    y: 0.7283661961555481
    },
    {
    x: 0.30050599575042725,
    y: 0.7283661961555481
    }
    ]
    },
    {
    description: "Coat",
    confidence: 0.6819770932197571,
    bounding_box: [
    {
    x: 0.49865129590034485,
    y: 0.2810499370098114
    },
    {
    x: 0.6638154983520508,
    y: 0.2810499370098114
    },
    {
    x: 0.6638154983520508,
    y: 0.678227424621582
    },
    {
    x: 0.49865129590034485,
    y: 0.678227424621582
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.5713053345680237,
    bounding_box: [
    {
    x: 0.5220099091529846,
    y: 0.4305809736251831
    },
    {
    x: 0.6258804202079773,
    y: 0.4305809736251831
    },
    {
    x: 0.6258804202079773,
    y: 0.8876168131828308
    },
    {
    x: 0.5220099091529846,
    y: 0.8876168131828308
    }
    ]
    },
    {
    description: "Belt",
    confidence: 0.5448964834213257,
    bounding_box: [
    {
    x: 0.3466954529285431,
    y: 0.4742601215839386
    },
    {
    x: 0.46760353446006775,
    y: 0.4742601215839386
    },
    {
    x: 0.46760353446006775,
    y: 0.5681524276733398
    },
    {
    x: 0.3466954529285431,
    y: 0.5681524276733398
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 9890,
    time_in_milliseconds: 329996.00000000006,
    labels: [
    {
    description: "Photograph",
    confidence: 94.80249285697937
    },
    {
    description: "Display window",
    confidence: 84.88172888755798
    },
    {
    description: "Snapshot",
    confidence: 84.54840183258057
    },
    {
    description: "Majorelle blue",
    confidence: 81.65128827095032
    },
    {
    description: "Electric blue",
    confidence: 70.31877636909485
    },
    {
    description: "Poster",
    confidence: 66.76775813102722
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Advertising",
    confidence: 63.80993723869324
    },
    {
    description: "Display case",
    confidence: 63.73390555381775
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    }
    ],
    objects: [
    {
    description: "Hat",
    confidence: 0.8931006789207458,
    bounding_box: [
    {
    x: 0.3687809407711029,
    y: 0.0794544368982315
    },
    {
    x: 0.48134589195251465,
    y: 0.0794544368982315
    },
    {
    x: 0.48134589195251465,
    y: 0.18590939044952393
    },
    {
    x: 0.3687809407711029,
    y: 0.18590939044952393
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8715176582336426,
    bounding_box: [
    {
    x: 0.49418410658836365,
    y: 0.17099611461162567
    },
    {
    x: 0.6692141890525818,
    y: 0.17099611461162567
    },
    {
    x: 0.6692141890525818,
    y: 0.8993375897407532
    },
    {
    x: 0.49418410658836365,
    y: 0.8993375897407532
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8493760228157043,
    bounding_box: [
    {
    x: 0.3048720061779022,
    y: 0.11761479824781418
    },
    {
    x: 0.5183349251747131,
    y: 0.11761479824781418
    },
    {
    x: 0.5183349251747131,
    y: 0.8951200842857361
    },
    {
    x: 0.3048720061779022,
    y: 0.8951200842857361
    }
    ]
    },
    {
    description: "Coat",
    confidence: 0.781814694404602,
    bounding_box: [
    {
    x: 0.30130138993263245,
    y: 0.24205565452575684
    },
    {
    x: 0.5113233923912048,
    y: 0.24205565452575684
    },
    {
    x: 0.5113233923912048,
    y: 0.7166568636894226
    },
    {
    x: 0.30130138993263245,
    y: 0.7166568636894226
    }
    ]
    },
    {
    description: "Coat",
    confidence: 0.7677696347236633,
    bounding_box: [
    {
    x: 0.4996615946292877,
    y: 0.2767080068588257
    },
    {
    x: 0.664631724357605,
    y: 0.2767080068588257
    },
    {
    x: 0.664631724357605,
    y: 0.66940838098526
    },
    {
    x: 0.4996615946292877,
    y: 0.66940838098526
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7400074601173401,
    bounding_box: [
    {
    x: 0.29894712567329407,
    y: 0.23913384974002838
    },
    {
    x: 0.5131627917289734,
    y: 0.23913384974002838
    },
    {
    x: 0.5131627917289734,
    y: 0.7298038601875305
    },
    {
    x: 0.29894712567329407,
    y: 0.7298038601875305
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.7300902009010315,
    bounding_box: [
    {
    x: 0.523517906665802,
    y: 0.4705859124660492
    },
    {
    x: 0.6233486533164978,
    y: 0.4705859124660492
    },
    {
    x: 0.6233486533164978,
    y: 0.8856267333030701
    },
    {
    x: 0.523517906665802,
    y: 0.8856267333030701
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.6139220595359802,
    bounding_box: [
    {
    x: 0.33915114402770996,
    y: 0.6945642828941345
    },
    {
    x: 0.47846272587776184,
    y: 0.6945642828941345
    },
    {
    x: 0.47846272587776184,
    y: 0.8993451595306396
    },
    {
    x: 0.33915114402770996,
    y: 0.8993451595306396
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.5067306160926819,
    bounding_box: [
    {
    x: 0.6014208197593689,
    y: 0.6189033389091492
    },
    {
    x: 0.6617118120193481,
    y: 0.6189033389091492
    },
    {
    x: 0.6617118120193481,
    y: 0.8222859501838684
    },
    {
    x: 0.6014208197593689,
    y: 0.8222859501838684
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 9977,
    time_in_milliseconds: 332899,
    labels: [
    {
    description: "Library",
    confidence: 79.24304008483887
    },
    {
    description: "Bookcase",
    confidence: 71.66143655776978
    },
    {
    description: "Glasses",
    confidence: 70.90374231338501
    },
    {
    description: "Book",
    confidence: 69.8504626750946
    },
    {
    description: "Publication",
    confidence: 55.21233677864075
    },
    {
    description: "Author",
    confidence: 51.163989305496216
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9263953566551208,
    bounding_box: [
    {
    x: 0.39813467860221863,
    y: 0.39278337359428406
    },
    {
    x: 0.5380197763442993,
    y: 0.39278337359428406
    },
    {
    x: 0.5380197763442993,
    y: 0.4910506308078766
    },
    {
    x: 0.39813467860221863,
    y: 0.4910506308078766
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8404480218887329,
    bounding_box: [
    {
    x: 0.6276734471321106,
    y: 0.022687802091240883
    },
    {
    x: 0.9799239039421082,
    y: 0.022687802091240883
    },
    {
    x: 0.9799239039421082,
    y: 0.980912446975708
    },
    {
    x: 0.6276734471321106,
    y: 0.980912446975708
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8167017698287964,
    bounding_box: [
    {
    x: 0.30058810114860535,
    y: 0.21998046338558197
    },
    {
    x: 0.8471317291259766,
    y: 0.21998046338558197
    },
    {
    x: 0.8471317291259766,
    y: 0.9949226975440979
    },
    {
    x: 0.30058810114860535,
    y: 0.9949226975440979
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.654285192489624,
    bounding_box: [
    {
    x: 0.29982301592826843,
    y: 0.5381642580032349
    },
    {
    x: 0.8441828489303589,
    y: 0.5381642580032349
    },
    {
    x: 0.8441828489303589,
    y: 0.9806053042411804
    },
    {
    x: 0.29982301592826843,
    y: 0.9806053042411804
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 10035,
    time_in_milliseconds: 334834,
    labels: [
    {
    description: "Text",
    confidence: 98.20230007171631
    },
    {
    description: "Document",
    confidence: 87.15858459472656
    },
    {
    description: "Font",
    confidence: 84.90258455276489
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Paper",
    confidence: 70.97843289375305
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Paper product",
    confidence: 59.435683488845825
    },
    {
    description: "History",
    confidence: 57.59063363075256
    },
    {
    description: "Writing",
    confidence: 50.03697872161865
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5555786490440369,
    bounding_box: [
    {
    x: 0.009679257869720459,
    y: 0.28737059235572815
    },
    {
    x: 0.9938194155693054,
    y: 0.28737059235572815
    },
    {
    x: 0.9938194155693054,
    y: 0.9811062812805176
    },
    {
    x: 0.009679257869720459,
    y: 0.9811062812805176
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 10093,
    time_in_milliseconds: 336770.00000000006,
    labels: [
    {
    description: "Text",
    confidence: 98.06565046310425
    },
    {
    description: "Font",
    confidence: 86.67431473731995
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Document",
    confidence: 60.5738639831543
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5208032131195068,
    bounding_box: [
    {
    x: 0.018888389691710472,
    y: 0.2873825132846832
    },
    {
    x: 0.9973958134651184,
    y: 0.2873825132846832
    },
    {
    x: 0.9973958134651184,
    y: 0.9809387922286987
    },
    {
    x: 0.018888389691710472,
    y: 0.9809387922286987
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 10267,
    time_in_milliseconds: 342575,
    labels: [
    {
    description: "Library",
    confidence: 85.11699438095093
    },
    {
    description: "Bookcase",
    confidence: 83.61117839813232
    },
    {
    description: "Glasses",
    confidence: 77.77518033981323
    },
    {
    description: "Book",
    confidence: 75.50777792930603
    },
    {
    description: "Shelving",
    confidence: 69.19801831245422
    },
    {
    description: "Publication",
    confidence: 62.68370747566223
    },
    {
    description: "Librarian",
    confidence: 59.85058546066284
    },
    {
    description: "Furniture",
    confidence: 59.37383770942688
    },
    {
    description: "Collection",
    confidence: 52.126169204711914
    },
    {
    description: "Author",
    confidence: 51.7352819442749
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9358848929405212,
    bounding_box: [
    {
    x: 0.42488527297973633,
    y: 0.39878344535827637
    },
    {
    x: 0.5604618787765503,
    y: 0.39878344535827637
    },
    {
    x: 0.5604618787765503,
    y: 0.4893721342086792
    },
    {
    x: 0.42488527297973633,
    y: 0.4893721342086792
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8642694354057312,
    bounding_box: [
    {
    x: 0.6269260048866272,
    y: 0.024297721683979034
    },
    {
    x: 0.9807600378990173,
    y: 0.024297721683979034
    },
    {
    x: 0.9807600378990173,
    y: 0.9817798137664795
    },
    {
    x: 0.6269260048866272,
    y: 0.9817798137664795
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8069320917129517,
    bounding_box: [
    {
    x: 0.3080439865589142,
    y: 0.22129367291927338
    },
    {
    x: 0.8422367572784424,
    y: 0.22129367291927338
    },
    {
    x: 0.8422367572784424,
    y: 0.9965705871582031
    },
    {
    x: 0.3080439865589142,
    y: 0.9965705871582031
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6720582246780396,
    bounding_box: [
    {
    x: 0.30001625418663025,
    y: 0.5479915738105774
    },
    {
    x: 0.8413882255554199,
    y: 0.5479915738105774
    },
    {
    x: 0.8413882255554199,
    y: 0.9817951321601868
    },
    {
    x: 0.30001625418663025,
    y: 0.9817951321601868
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 10383,
    time_in_milliseconds: 346446,
    labels: [
    {
    description: "Text",
    confidence: 95.61272263526917
    },
    {
    description: "Product",
    confidence: 88.28333020210266
    },
    {
    description: "Font",
    confidence: 83.14544558525085
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Icon",
    confidence: 63.26647400856018
    },
    {
    description: "Logo",
    confidence: 58.70729088783264
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    },
    {
    description: "Rectangle",
    confidence: 50.47368407249451
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8875294923782349,
    bounding_box: [
    {
    x: 0.20185929536819458,
    y: 0.28329750895500183
    },
    {
    x: 0.3002174496650696,
    y: 0.28329750895500183
    },
    {
    x: 0.3002174496650696,
    y: 0.48868170380592346
    },
    {
    x: 0.20185929536819458,
    y: 0.48868170380592346
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 10441,
    time_in_milliseconds: 348382,
    labels: [
    {
    description: "Glasses",
    confidence: 74.8481273651123
    },
    {
    description: "Library",
    confidence: 73.9333987236023
    },
    {
    description: "Bookcase",
    confidence: 69.82871890068054
    },
    {
    description: "Book",
    confidence: 67.34131574630737
    },
    {
    description: "Publication",
    confidence: 53.551673889160156
    },
    {
    description: "Author",
    confidence: 50.078314542770386
    },
    {
    description: "Shelving",
    confidence: 50.00336170196533
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9288223385810852,
    bounding_box: [
    {
    x: 0.40879449248313904,
    y: 0.3948476314544678
    },
    {
    x: 0.5426918864250183,
    y: 0.3948476314544678
    },
    {
    x: 0.5426918864250183,
    y: 0.48499226570129395
    },
    {
    x: 0.40879449248313904,
    y: 0.48499226570129395
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8806471824645996,
    bounding_box: [
    {
    x: 0.6253482103347778,
    y: 0.014151726849377155
    },
    {
    x: 0.9793212413787842,
    y: 0.014151726849377155
    },
    {
    x: 0.9793212413787842,
    y: 0.9825626015663147
    },
    {
    x: 0.6253482103347778,
    y: 0.9825626015663147
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7974543571472168,
    bounding_box: [
    {
    x: 0.32903221249580383,
    y: 0.22872145473957062
    },
    {
    x: 0.8328484296798706,
    y: 0.22872145473957062
    },
    {
    x: 0.8328484296798706,
    y: 0.9962880611419678
    },
    {
    x: 0.32903221249580383,
    y: 0.9962880611419678
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6130064725875854,
    bounding_box: [
    {
    x: 0.3221721351146698,
    y: 0.5370382070541382
    },
    {
    x: 0.8354649543762207,
    y: 0.5370382070541382
    },
    {
    x: 0.8354649543762207,
    y: 0.9816520810127258
    },
    {
    x: 0.3221721351146698,
    y: 0.9816520810127258
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 10586,
    time_in_milliseconds: 353220,
    labels: [
    {
    description: "Text",
    confidence: 98.21270108222961
    },
    {
    description: "Document",
    confidence: 88.45756649971008
    },
    {
    description: "Font",
    confidence: 84.90258455276489
    },
    {
    description: "Adaptation",
    confidence: 77.25779414176941
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Paper",
    confidence: 70.97843289375305
    },
    {
    description: "Paper product",
    confidence: 60.89872121810913
    },
    {
    description: "History",
    confidence: 57.59063363075256
    },
    {
    description: "Engineering",
    confidence: 57.17431902885437
    },
    {
    description: "Writing",
    confidence: 54.79733347892761
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 10615,
    time_in_milliseconds: 354187,
    labels: [
    {
    description: "Text",
    confidence: 98.19189310073853
    },
    {
    description: "Font",
    confidence: 88.23639750480652
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Document",
    confidence: 51.79322361946106
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 10905,
    time_in_milliseconds: 363864.00000000006,
    labels: [
    {
    description: "Library",
    confidence: 81.6836953163147
    },
    {
    description: "Glasses",
    confidence: 74.8481273651123
    },
    {
    description: "Book",
    confidence: 71.51680588722229
    },
    {
    description: "Bookcase",
    confidence: 68.2041585445404
    },
    {
    description: "Publication",
    confidence: 58.75436067581177
    },
    {
    description: "Vision care",
    confidence: 54.48664426803589
    },
    {
    description: "Librarian",
    confidence: 53.09946537017822
    },
    {
    description: "Author",
    confidence: 50.70470571517944
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.924887478351593,
    bounding_box: [
    {
    x: 0.4181077778339386,
    y: 0.3708936870098114
    },
    {
    x: 0.555993378162384,
    y: 0.3708936870098114
    },
    {
    x: 0.555993378162384,
    y: 0.46011340618133545
    },
    {
    x: 0.4181077778339386,
    y: 0.46011340618133545
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8537837266921997,
    bounding_box: [
    {
    x: 0.32153069972991943,
    y: 0.21795934438705444
    },
    {
    x: 0.8302093148231506,
    y: 0.21795934438705444
    },
    {
    x: 0.8302093148231506,
    y: 0.9973958134651184
    },
    {
    x: 0.32153069972991943,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8530257940292358,
    bounding_box: [
    {
    x: 0.626665472984314,
    y: 0.015660583972930908
    },
    {
    x: 0.980224072933197,
    y: 0.015660583972930908
    },
    {
    x: 0.980224072933197,
    y: 0.9829521775245667
    },
    {
    x: 0.626665472984314,
    y: 0.9829521775245667
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5727183222770691,
    bounding_box: [
    {
    x: 0.31458398699760437,
    y: 0.5510377287864685
    },
    {
    x: 0.8297250270843506,
    y: 0.5510377287864685
    },
    {
    x: 0.8297250270843506,
    y: 0.9841449856758118
    },
    {
    x: 0.31458398699760437,
    y: 0.9841449856758118
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 11746,
    time_in_milliseconds: 391924.00000000006,
    labels: [
    {
    description: "Text",
    confidence: 98.39999079704285
    },
    {
    description: "Document",
    confidence: 89.17433619499207
    },
    {
    description: "Font",
    confidence: 88.82409334182739
    },
    {
    description: "Adaptation",
    confidence: 79.11608219146729
    },
    {
    description: "Paper",
    confidence: 75.33518671989441
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Paper product",
    confidence: 62.170207500457764
    },
    {
    description: "Writing",
    confidence: 58.546656370162964
    },
    {
    description: "Engineering",
    confidence: 55.61065077781677
    },
    {
    description: "History",
    confidence: 54.10714149475098
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5738475918769836,
    bounding_box: [
    {
    x: 0,
    y: 0.3025846779346466
    },
    {
    x: 0.9947669506072998,
    y: 0.3025846779346466
    },
    {
    x: 0.9947669506072998,
    y: 0.9913626909255981
    },
    {
    x: 0,
    y: 0.9913626909255981
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 11775,
    time_in_milliseconds: 392893,
    labels: [
    {
    description: "Text",
    confidence: 98.11861515045166
    },
    {
    description: "Font",
    confidence: 87.1282696723938
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Document",
    confidence: 67.09485054016113
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5665961503982544,
    bounding_box: [
    {
    x: 0.0019740478601306677,
    y: 0.27750712633132935
    },
    {
    x: 0.9880041480064392,
    y: 0.27750712633132935
    },
    {
    x: 0.9880041480064392,
    y: 0.9782906174659729
    },
    {
    x: 0.0019740478601306677,
    y: 0.9782906174659729
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 11949,
    time_in_milliseconds: 398699,
    labels: [
    {
    description: "Library",
    confidence: 83.31785798072815
    },
    {
    description: "Bookcase",
    confidence: 74.99610185623169
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Book",
    confidence: 65.56457877159119
    },
    {
    description: "Shelving",
    confidence: 60.46971678733826
    },
    {
    description: "Furniture",
    confidence: 56.74918293952942
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9378650188446045,
    bounding_box: [
    {
    x: 0.44050267338752747,
    y: 0.35396692156791687
    },
    {
    x: 0.5785011053085327,
    y: 0.35396692156791687
    },
    {
    x: 0.5785011053085327,
    y: 0.44488176703453064
    },
    {
    x: 0.44050267338752747,
    y: 0.44488176703453064
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8388739228248596,
    bounding_box: [
    {
    x: 0.6220741271972656,
    y: 0.016279185190796852
    },
    {
    x: 0.9838340878486633,
    y: 0.016279185190796852
    },
    {
    x: 0.9838340878486633,
    y: 0.9862250685691833
    },
    {
    x: 0.6220741271972656,
    y: 0.9862250685691833
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.812541663646698,
    bounding_box: [
    {
    x: 0.30958911776542664,
    y: 0.21045438945293427
    },
    {
    x: 0.8054570555686951,
    y: 0.21045438945293427
    },
    {
    x: 0.8054570555686951,
    y: 0.9971190094947815
    },
    {
    x: 0.30958911776542664,
    y: 0.9971190094947815
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.723275363445282,
    bounding_box: [
    {
    x: 0.30282798409461975,
    y: 0.5370596647262573
    },
    {
    x: 0.8120437860488892,
    y: 0.5370596647262573
    },
    {
    x: 0.8120437860488892,
    y: 0.9833580851554871
    },
    {
    x: 0.30282798409461975,
    y: 0.9833580851554871
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 12848,
    time_in_milliseconds: 428694,
    labels: [
    {
    description: "Text",
    confidence: 96.38836979866028
    },
    {
    description: "Product",
    confidence: 88.28333020210266
    },
    {
    description: "Font",
    confidence: 81.95118308067322
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5852161049842834,
    bounding_box: [
    {
    x: 0.11540449410676956,
    y: 0.14600329101085663
    },
    {
    x: 0.8925435543060303,
    y: 0.14600329101085663
    },
    {
    x: 0.8925435543060303,
    y: 0.8811407685279846
    },
    {
    x: 0.11540449410676956,
    y: 0.8811407685279846
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 12906,
    time_in_milliseconds: 430630,
    labels: [
    {
    description: "Bookcase",
    confidence: 74.99610185623169
    },
    {
    description: "Library",
    confidence: 72.2136378288269
    },
    {
    description: "Book",
    confidence: 71.2501585483551
    },
    {
    description: "Glasses",
    confidence: 67.38030314445496
    },
    {
    description: "Publication",
    confidence: 58.75436067581177
    },
    {
    description: "Author",
    confidence: 51.57938599586487
    },
    {
    description: "Librarian",
    confidence: 50.87888836860657
    },
    {
    description: "Shelving",
    confidence: 50.00336170196533
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9264945983886719,
    bounding_box: [
    {
    x: 0.39753594994544983,
    y: 0.39643439650535583
    },
    {
    x: 0.5374009609222412,
    y: 0.39643439650535583
    },
    {
    x: 0.5374009609222412,
    y: 0.5117811560630798
    },
    {
    x: 0.39753594994544983,
    y: 0.5117811560630798
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8326094150543213,
    bounding_box: [
    {
    x: 0.6251912713050842,
    y: 0.01747402921319008
    },
    {
    x: 0.9810537695884705,
    y: 0.01747402921319008
    },
    {
    x: 0.9810537695884705,
    y: 0.9819695949554443
    },
    {
    x: 0.6251912713050842,
    y: 0.9819695949554443
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8193439841270447,
    bounding_box: [
    {
    x: 0.3084825575351715,
    y: 0.23518486320972443
    },
    {
    x: 0.8174639940261841,
    y: 0.23518486320972443
    },
    {
    x: 0.8174639940261841,
    y: 0.9973958134651184
    },
    {
    x: 0.3084825575351715,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6724865436553955,
    bounding_box: [
    {
    x: 0.30256807804107666,
    y: 0.536040723323822
    },
    {
    x: 0.822222888469696,
    y: 0.536040723323822
    },
    {
    x: 0.822222888469696,
    y: 0.9843452572822571
    },
    {
    x: 0.30256807804107666,
    y: 0.9843452572822571
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 13022,
    time_in_milliseconds: 434500,
    labels: [
    {
    description: "Blue",
    confidence: 95.00666856765747
    },
    {
    description: "Aqua",
    confidence: 92.05432534217834
    },
    {
    description: "Logo",
    confidence: 88.75848054885864
    },
    {
    description: "Text",
    confidence: 86.92919611930847
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Azure",
    confidence: 83.85488390922546
    },
    {
    description: "Majorelle blue",
    confidence: 82.44125843048096
    },
    {
    description: "Cobalt blue",
    confidence: 81.42207860946655
    },
    {
    description: "Brand",
    confidence: 74.53083395957947
    },
    {
    description: "Electric blue",
    confidence: 74.2108941078186
    }
    ],
    objects: [
    {
    description: "Animal",
    confidence: 0.6521627902984619,
    bounding_box: [
    {
    x: 0.41145965456962585,
    y: 0.16898424923419952
    },
    {
    x: 0.5865398645401001,
    y: 0.16898424923419952
    },
    {
    x: 0.5865398645401001,
    y: 0.4317973852157593
    },
    {
    x: 0.41145965456962585,
    y: 0.4317973852157593
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5535077452659607,
    bounding_box: [
    {
    x: 0.20577247440814972,
    y: 0.08678168058395386
    },
    {
    x: 0.7886462807655334,
    y: 0.08678168058395386
    },
    {
    x: 0.7886462807655334,
    y: 0.8651697635650635
    },
    {
    x: 0.20577247440814972,
    y: 0.8651697635650635
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 13080,
    time_in_milliseconds: 436436.00000000006,
    labels: [
    {
    description: "Library",
    confidence: 76.92928910255432
    },
    {
    description: "Glasses",
    confidence: 74.8481273651123
    },
    {
    description: "Bookcase",
    confidence: 74.7333824634552
    },
    {
    description: "Book",
    confidence: 69.5561945438385
    },
    {
    description: "Shelving",
    confidence: 58.72377157211304
    },
    {
    description: "Publication",
    confidence: 55.21233677864075
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Librarian",
    confidence: 51.840853691101074
    },
    {
    description: "Author",
    confidence: 50.70470571517944
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9257830381393433,
    bounding_box: [
    {
    x: 0.41254985332489014,
    y: 0.40060386061668396
    },
    {
    x: 0.5540300011634827,
    y: 0.40060386061668396
    },
    {
    x: 0.5540300011634827,
    y: 0.4927773177623749
    },
    {
    x: 0.41254985332489014,
    y: 0.4927773177623749
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8623281717300415,
    bounding_box: [
    {
    x: 0.626741349697113,
    y: 0.01927516795694828
    },
    {
    x: 0.9783102869987488,
    y: 0.01927516795694828
    },
    {
    x: 0.9783102869987488,
    y: 0.9815337657928467
    },
    {
    x: 0.626741349697113,
    y: 0.9815337657928467
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8039332032203674,
    bounding_box: [
    {
    x: 0.3126608729362488,
    y: 0.22173835337162018
    },
    {
    x: 0.8201878666877747,
    y: 0.22173835337162018
    },
    {
    x: 0.8201878666877747,
    y: 0.9973958134651184
    },
    {
    x: 0.3126608729362488,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.609222412109375,
    bounding_box: [
    {
    x: 0.3008327782154083,
    y: 0.5444995760917664
    },
    {
    x: 0.8215482234954834,
    y: 0.5444995760917664
    },
    {
    x: 0.8215482234954834,
    y: 0.9802437424659729
    },
    {
    x: 0.3008327782154083,
    y: 0.9802437424659729
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 13892,
    time_in_milliseconds: 463529,
    labels: [
    {
    description: "Design",
    confidence: 72.03227281570435
    },
    {
    description: "Advertising",
    confidence: 71.87626361846924
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Leisure",
    confidence: 68.09553503990173
    },
    {
    description: "Photo caption",
    confidence: 67.62973666191101
    },
    {
    description: "Sitting",
    confidence: 67.5679862499237
    },
    {
    description: "Brand",
    confidence: 65.46861529350281
    },
    {
    description: "Electric blue",
    confidence: 63.79328370094299
    },
    {
    description: "Furniture",
    confidence: 63.24014663696289
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9077448844909668,
    bounding_box: [
    {
    x: 0.34191402792930603,
    y: 0.24925100803375244
    },
    {
    x: 0.6151936650276184,
    y: 0.24925100803375244
    },
    {
    x: 0.6151936650276184,
    y: 0.8314023017883301
    },
    {
    x: 0.34191402792930603,
    y: 0.8314023017883301
    }
    ]
    },
    {
    description: "Shoe",
    confidence: 0.8473274111747742,
    bounding_box: [
    {
    x: 0.31684696674346924,
    y: 0.589677631855011
    },
    {
    x: 0.40583837032318115,
    y: 0.589677631855011
    },
    {
    x: 0.40583837032318115,
    y: 0.6986286044120789
    },
    {
    x: 0.31684696674346924,
    y: 0.6986286044120789
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7629063129425049,
    bounding_box: [
    {
    x: 0.45003971457481384,
    y: 0.35764726996421814
    },
    {
    x: 0.6139262914657593,
    y: 0.35764726996421814
    },
    {
    x: 0.6139262914657593,
    y: 0.5306543111801147
    },
    {
    x: 0.45003971457481384,
    y: 0.5306543111801147
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.6509937047958374,
    bounding_box: [
    {
    x: 0.39326465129852295,
    y: 0.47947290539741516
    },
    {
    x: 0.5770483613014221,
    y: 0.47947290539741516
    },
    {
    x: 0.5770483613014221,
    y: 0.7982416749000549
    },
    {
    x: 0.39326465129852295,
    y: 0.7982416749000549
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6106040477752686,
    bounding_box: [
    {
    x: 0.43574997782707214,
    y: 0.3528794050216675
    },
    {
    x: 0.6167221665382385,
    y: 0.3528794050216675
    },
    {
    x: 0.6167221665382385,
    y: 0.5434911847114563
    },
    {
    x: 0.43574997782707214,
    y: 0.5434911847114563
    }
    ]
    },
    {
    description: "Chair",
    confidence: 0.5904347896575928,
    bounding_box: [
    {
    x: 0.4116387665271759,
    y: 0.4751916825771332
    },
    {
    x: 0.6002375483512878,
    y: 0.4751916825771332
    },
    {
    x: 0.6002375483512878,
    y: 0.8262521624565125
    },
    {
    x: 0.4116387665271759,
    y: 0.8262521624565125
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 13979,
    time_in_milliseconds: 466432,
    labels: [
    {
    description: "Library",
    confidence: 80.96507787704468
    },
    {
    description: "Bookcase",
    confidence: 77.2508978843689
    },
    {
    description: "Book",
    confidence: 71.51680588722229
    },
    {
    description: "Glasses",
    confidence: 70.90374231338501
    },
    {
    description: "Shelving",
    confidence: 61.292511224746704
    },
    {
    description: "Publication",
    confidence: 57.42812752723694
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Author",
    confidence: 51.250267028808594
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9318796396255493,
    bounding_box: [
    {
    x: 0.43497419357299805,
    y: 0.3922519385814667
    },
    {
    x: 0.56798255443573,
    y: 0.3922519385814667
    },
    {
    x: 0.56798255443573,
    y: 0.4823823869228363
    },
    {
    x: 0.43497419357299805,
    y: 0.4823823869228363
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.863704264163971,
    bounding_box: [
    {
    x: 0.6233674883842468,
    y: 0.022625766694545746
    },
    {
    x: 0.979398250579834,
    y: 0.022625766694545746
    },
    {
    x: 0.979398250579834,
    y: 0.9841277599334717
    },
    {
    x: 0.6233674883842468,
    y: 0.9841277599334717
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8273019790649414,
    bounding_box: [
    {
    x: 0.33839717507362366,
    y: 0.23424607515335083
    },
    {
    x: 0.8546180725097656,
    y: 0.23424607515335083
    },
    {
    x: 0.8546180725097656,
    y: 0.9949133396148682
    },
    {
    x: 0.33839717507362366,
    y: 0.9949133396148682
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6933364272117615,
    bounding_box: [
    {
    x: 0.3364541232585907,
    y: 0.5385204553604126
    },
    {
    x: 0.8487191200256348,
    y: 0.5385204553604126
    },
    {
    x: 0.8487191200256348,
    y: 0.981742799282074
    },
    {
    x: 0.3364541232585907,
    y: 0.981742799282074
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14124,
    time_in_milliseconds: 471270.00000000006,
    labels: [
    {
    description: "Advertising",
    confidence: 70.30551433563232
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Electric blue",
    confidence: 67.55332946777344
    },
    {
    description: "Photo caption",
    confidence: 66.4606511592865
    },
    {
    description: "Brand",
    confidence: 66.15683436393738
    },
    {
    description: "Sitting",
    confidence: 66.1043107509613
    },
    {
    description: "Leisure",
    confidence: 63.88171315193176
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Furniture",
    confidence: 61.48442625999451
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.905020534992218,
    bounding_box: [
    {
    x: 0.3393048346042633,
    y: 0.25394824147224426
    },
    {
    x: 0.6179916858673096,
    y: 0.25394824147224426
    },
    {
    x: 0.6179916858673096,
    y: 0.8330584764480591
    },
    {
    x: 0.3393048346042633,
    y: 0.8330584764480591
    }
    ]
    },
    {
    description: "Shoe",
    confidence: 0.8841720819473267,
    bounding_box: [
    {
    x: 0.31684187054634094,
    y: 0.589611828327179
    },
    {
    x: 0.4079921543598175,
    y: 0.589611828327179
    },
    {
    x: 0.4079921543598175,
    y: 0.6982188820838928
    },
    {
    x: 0.31684187054634094,
    y: 0.6982188820838928
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.7419968843460083,
    bounding_box: [
    {
    x: 0.39293181896209717,
    y: 0.48603975772857666
    },
    {
    x: 0.5797512531280518,
    y: 0.48603975772857666
    },
    {
    x: 0.5797512531280518,
    y: 0.8050000667572021
    },
    {
    x: 0.39293181896209717,
    y: 0.8050000667572021
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7186393141746521,
    bounding_box: [
    {
    x: 0.43277695775032043,
    y: 0.3530457019805908
    },
    {
    x: 0.6179816126823425,
    y: 0.3530457019805908
    },
    {
    x: 0.6179816126823425,
    y: 0.5431550145149231
    },
    {
    x: 0.43277695775032043,
    y: 0.5431550145149231
    }
    ]
    },
    {
    description: "Chair",
    confidence: 0.5879305601119995,
    bounding_box: [
    {
    x: 0.41045817732810974,
    y: 0.48404550552368164
    },
    {
    x: 0.6036452651023865,
    y: 0.48404550552368164
    },
    {
    x: 0.6036452651023865,
    y: 0.8307694792747498
    },
    {
    x: 0.41045817732810974,
    y: 0.8307694792747498
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14153,
    time_in_milliseconds: 472238,
    labels: [
    {
    description: "Footwear",
    confidence: 72.9487657546997
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Display device",
    confidence: 67.80250072479248
    },
    {
    description: "Advertising",
    confidence: 63.80993723869324
    },
    {
    description: "Shoe",
    confidence: 62.66842484474182
    },
    {
    description: "Electric blue",
    confidence: 58.18745493888855
    },
    {
    description: "World",
    confidence: 57.50783085823059
    },
    {
    description: "Games",
    confidence: 52.11578011512756
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Shoe",
    confidence: 0.8558058142662048,
    bounding_box: [
    {
    x: 0.27577587962150574,
    y: 0.3138522207736969
    },
    {
    x: 0.5835006237030029,
    y: 0.3138522207736969
    },
    {
    x: 0.5835006237030029,
    y: 0.8964378833770752
    },
    {
    x: 0.27577587962150574,
    y: 0.8964378833770752
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5155845880508423,
    bounding_box: [
    {
    x: 0.2796483635902405,
    y: 0.18321000039577484
    },
    {
    x: 0.7258809208869934,
    y: 0.18321000039577484
    },
    {
    x: 0.7258809208869934,
    y: 0.9071688055992126
    },
    {
    x: 0.2796483635902405,
    y: 0.9071688055992126
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14240,
    time_in_milliseconds: 475141,
    labels: [
    {
    description: "Library",
    confidence: 80.72300553321838
    },
    {
    description: "Bookcase",
    confidence: 70.45313119888306
    },
    {
    description: "Book",
    confidence: 64.7996723651886
    },
    {
    description: "Glasses",
    confidence: 51.58061981201172
    },
    {
    description: "Publication",
    confidence: 50.70810317993164
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9198530316352844,
    bounding_box: [
    {
    x: 0.44908562302589417,
    y: 0.3902221620082855
    },
    {
    x: 0.5839570164680481,
    y: 0.3902221620082855
    },
    {
    x: 0.5839570164680481,
    y: 0.4807785451412201
    },
    {
    x: 0.44908562302589417,
    y: 0.4807785451412201
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8484899401664734,
    bounding_box: [
    {
    x: 0.6258445978164673,
    y: 0.020534010604023933
    },
    {
    x: 0.9770357608795166,
    y: 0.020534010604023933
    },
    {
    x: 0.9770357608795166,
    y: 0.9779666066169739
    },
    {
    x: 0.6258445978164673,
    y: 0.9779666066169739
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8446798324584961,
    bounding_box: [
    {
    x: 0.3397902250289917,
    y: 0.2105264663696289
    },
    {
    x: 0.8465807437896729,
    y: 0.2105264663696289
    },
    {
    x: 0.8465807437896729,
    y: 0.9963467717170715
    },
    {
    x: 0.3397902250289917,
    y: 0.9963467717170715
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7388618588447571,
    bounding_box: [
    {
    x: 0.3324486315250397,
    y: 0.5243438482284546
    },
    {
    x: 0.8526409864425659,
    y: 0.5243438482284546
    },
    {
    x: 0.8526409864425659,
    y: 0.9844813346862793
    },
    {
    x: 0.3324486315250397,
    y: 0.9844813346862793
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14356,
    time_in_milliseconds: 479012,
    labels: [
    {
    description: "Majorelle blue",
    confidence: 81.3697874546051
    },
    {
    description: "Advertising",
    confidence: 72.81543612480164
    },
    {
    description: "Electric blue",
    confidence: 72.47074842453003
    },
    {
    description: "Design",
    confidence: 72.03227281570435
    },
    {
    description: "Footwear",
    confidence: 70.08925676345825
    },
    {
    description: "Sitting",
    confidence: 68.86659860610962
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Brand",
    confidence: 68.46358180046082
    },
    {
    description: "Leisure",
    confidence: 68.09553503990173
    },
    {
    description: "Photography",
    confidence: 67.78864860534668
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9102853536605835,
    bounding_box: [
    {
    x: 0.3400251567363739,
    y: 0.25376126170158386
    },
    {
    x: 0.6163609623908997,
    y: 0.25376126170158386
    },
    {
    x: 0.6163609623908997,
    y: 0.8325223922729492
    },
    {
    x: 0.3400251567363739,
    y: 0.8325223922729492
    }
    ]
    },
    {
    description: "Shoe",
    confidence: 0.8565911650657654,
    bounding_box: [
    {
    x: 0.31727948784828186,
    y: 0.5900300741195679
    },
    {
    x: 0.4064195454120636,
    y: 0.5900300741195679
    },
    {
    x: 0.4064195454120636,
    y: 0.6987057328224182
    },
    {
    x: 0.31727948784828186,
    y: 0.6987057328224182
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7125216722488403,
    bounding_box: [
    {
    x: 0.44622111320495605,
    y: 0.359614759683609
    },
    {
    x: 0.6137691140174866,
    y: 0.359614759683609
    },
    {
    x: 0.6137691140174866,
    y: 0.5318425297737122
    },
    {
    x: 0.44622111320495605,
    y: 0.5318425297737122
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6481679677963257,
    bounding_box: [
    {
    x: 0.4324203431606293,
    y: 0.3538815975189209
    },
    {
    x: 0.6164839863777161,
    y: 0.3538815975189209
    },
    {
    x: 0.6164839863777161,
    y: 0.5446456670761108
    },
    {
    x: 0.4324203431606293,
    y: 0.5446456670761108
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.6373787522315979,
    bounding_box: [
    {
    x: 0.39387765526771545,
    y: 0.4830150306224823
    },
    {
    x: 0.5772870182991028,
    y: 0.4830150306224823
    },
    {
    x: 0.5772870182991028,
    y: 0.7920331358909607
    },
    {
    x: 0.39387765526771545,
    y: 0.7920331358909607
    }
    ]
    },
    {
    description: "Chair",
    confidence: 0.5343635082244873,
    bounding_box: [
    {
    x: 0.41145792603492737,
    y: 0.4802858531475067
    },
    {
    x: 0.6023251414299011,
    y: 0.4802858531475067
    },
    {
    x: 0.6023251414299011,
    y: 0.8257415294647217
    },
    {
    x: 0.41145792603492737,
    y: 0.8257415294647217
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14385,
    time_in_milliseconds: 479979.00000000006,
    labels: [
    {
    description: "Eyewear",
    confidence: 97.24193811416626
    },
    {
    description: "Majorelle blue",
    confidence: 86.47130131721497
    },
    {
    description: "Glasses",
    confidence: 82.4069619178772
    },
    {
    description: "News",
    confidence: 79.51502203941345
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Media",
    confidence: 68.68262887001038
    },
    {
    description: "Sunglasses",
    confidence: 64.22461867332458
    },
    {
    description: "Electric blue",
    confidence: 63.79328370094299
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Vision care",
    confidence: 56.652915477752686
    }
    ],
    objects: [
    {
    description: "Sunglasses",
    confidence: 0.8882512450218201,
    bounding_box: [
    {
    x: 0.3907191753387451,
    y: 0.42815566062927246
    },
    {
    x: 0.5994427800178528,
    y: 0.42815566062927246
    },
    {
    x: 0.5994427800178528,
    y: 0.560329794883728
    },
    {
    x: 0.3907191753387451,
    y: 0.560329794883728
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7719478607177734,
    bounding_box: [
    {
    x: 0.33151698112487793,
    y: 0.23834443092346191
    },
    {
    x: 0.6710279583930969,
    y: 0.23834443092346191
    },
    {
    x: 0.6710279583930969,
    y: 0.8903035521507263
    },
    {
    x: 0.33151698112487793,
    y: 0.8903035521507263
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14443,
    time_in_milliseconds: 481914.00000000006,
    labels: [
    {
    description: "Library",
    confidence: 82.62462615966797
    },
    {
    description: "Book",
    confidence: 73.74535799026489
    },
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Bookcase",
    confidence: 72.53415584564209
    },
    {
    description: "Publication",
    confidence: 61.1176073551178
    },
    {
    description: "Shelving",
    confidence: 55.818337202072144
    },
    {
    description: "Librarian",
    confidence: 53.40993404388428
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Author",
    confidence: 51.7352819442749
    },
    {
    description: "Collection",
    confidence: 50.01777410507202
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9281831979751587,
    bounding_box: [
    {
    x: 0.40999552607536316,
    y: 0.41498568654060364
    },
    {
    x: 0.5485754609107971,
    y: 0.41498568654060364
    },
    {
    x: 0.5485754609107971,
    y: 0.5028039216995239
    },
    {
    x: 0.40999552607536316,
    y: 0.5028039216995239
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8687373995780945,
    bounding_box: [
    {
    x: 0.6258590817451477,
    y: 0.01755678281188011
    },
    {
    x: 0.9825804829597473,
    y: 0.01755678281188011
    },
    {
    x: 0.9825804829597473,
    y: 0.9841296672821045
    },
    {
    x: 0.6258590817451477,
    y: 0.9841296672821045
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8323742151260376,
    bounding_box: [
    {
    x: 0.3349340260028839,
    y: 0.2514601945877075
    },
    {
    x: 0.7729776501655579,
    y: 0.2514601945877075
    },
    {
    x: 0.7729776501655579,
    y: 0.9944953918457031
    },
    {
    x: 0.3349340260028839,
    y: 0.9944953918457031
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7554595470428467,
    bounding_box: [
    {
    x: 0.34098079800605774,
    y: 0.26874154806137085
    },
    {
    x: 0.7795164585113525,
    y: 0.26874154806137085
    },
    {
    x: 0.7795164585113525,
    y: 0.9962361454963684
    },
    {
    x: 0.34098079800605774,
    y: 0.9962361454963684
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6392609477043152,
    bounding_box: [
    {
    x: 0.3600209057331085,
    y: 0.5545926690101624
    },
    {
    x: 0.7646650671958923,
    y: 0.5545926690101624
    },
    {
    x: 0.7646650671958923,
    y: 0.990516185760498
    },
    {
    x: 0.3600209057331085,
    y: 0.990516185760498
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14791,
    time_in_milliseconds: 493526,
    labels: [
    {
    description: "Eyewear",
    confidence: 99.60833191871643
    },
    {
    description: "Hair",
    confidence: 96.4884340763092
    },
    {
    description: "Glasses",
    confidence: 95.03257274627686
    },
    {
    description: "Sunglasses",
    confidence: 88.02315592765808
    },
    {
    description: "Vision care",
    confidence: 85.37813425064087
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Chin",
    confidence: 82.75078535079956
    },
    {
    description: "Cool",
    confidence: 81.33383393287659
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    }
    ],
    objects: [
    {
    description: "Sunglasses",
    confidence: 0.9408124089241028,
    bounding_box: [
    {
    x: 0.4043579399585724,
    y: 0.4671303331851959
    },
    {
    x: 0.5589394569396973,
    y: 0.4671303331851959
    },
    {
    x: 0.5589394569396973,
    y: 0.5859113931655884
    },
    {
    x: 0.4043579399585724,
    y: 0.5859113931655884
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8725621104240417,
    bounding_box: [
    {
    x: 0.34310463070869446,
    y: 0.21507184207439423
    },
    {
    x: 0.8826982975006104,
    y: 0.21507184207439423
    },
    {
    x: 0.8826982975006104,
    y: 0.9824069142341614
    },
    {
    x: 0.34310463070869446,
    y: 0.9824069142341614
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7359100580215454,
    bounding_box: [
    {
    x: 0.3559187352657318,
    y: 0.5543089509010315
    },
    {
    x: 0.8728988170623779,
    y: 0.5543089509010315
    },
    {
    x: 0.8728988170623779,
    y: 0.9929658770561218
    },
    {
    x: 0.3559187352657318,
    y: 0.9929658770561218
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5694105625152588,
    bounding_box: [
    {
    x: 0.3477010726928711,
    y: 0.3655529320240021
    },
    {
    x: 0.8838207721710205,
    y: 0.3655529320240021
    },
    {
    x: 0.8838207721710205,
    y: 0.9890079498291016
    },
    {
    x: 0.3477010726928711,
    y: 0.9890079498291016
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14849,
    time_in_milliseconds: 495461,
    labels: [
    {
    description: "Hair",
    confidence: 97.11661338806152
    },
    {
    description: "Shelving",
    confidence: 92.70606637001038
    },
    {
    description: "Blue",
    confidence: 91.18428230285645
    },
    {
    description: "Bookcase",
    confidence: 90.64713716506958
    },
    {
    description: "Room",
    confidence: 89.02457356452942
    },
    {
    description: "Shelf",
    confidence: 88.60302567481995
    },
    {
    description: "Furniture",
    confidence: 88.15898299217224
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Chin",
    confidence: 82.75078535079956
    },
    {
    description: "Desk",
    confidence: 76.57583951950073
    }
    ],
    objects: [
    {
    description: "Bookcase",
    confidence: 0.8156601786613464,
    bounding_box: [
    {
    x: 0.4879377782344818,
    y: 0.007896755822002888
    },
    {
    x: 0.9225205779075623,
    y: 0.007896755822002888
    },
    {
    x: 0.9225205779075623,
    y: 0.6410841345787048
    },
    {
    x: 0.4879377782344818,
    y: 0.6410841345787048
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.796594500541687,
    bounding_box: [
    {
    x: 0.41549602150917053,
    y: 0.07226972281932831
    },
    {
    x: 0.9901934862136841,
    y: 0.07226972281932831
    },
    {
    x: 0.9901934862136841,
    y: 0.9868671298027039
    },
    {
    x: 0.41549602150917053,
    y: 0.9868671298027039
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.7698409557342529,
    bounding_box: [
    {
    x: 0.4166533648967743,
    y: 0.492897629737854
    },
    {
    x: 0.9938714504241943,
    y: 0.492897629737854
    },
    {
    x: 0.9938714504241943,
    y: 0.9907659888267517
    },
    {
    x: 0.4166533648967743,
    y: 0.9907659888267517
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.7541933655738831,
    bounding_box: [
    {
    x: 0.8146631121635437,
    y: 0.4308660328388214
    },
    {
    x: 0.9798707962036133,
    y: 0.4308660328388214
    },
    {
    x: 0.9798707962036133,
    y: 0.5376545786857605
    },
    {
    x: 0.8146631121635437,
    y: 0.5376545786857605
    }
    ]
    },
    {
    description: "Television",
    confidence: 0.7214707732200623,
    bounding_box: [
    {
    x: 0.0010865199146792293,
    y: 0
    },
    {
    x: 0.1847219616174698,
    y: 0
    },
    {
    x: 0.1847219616174698,
    y: 0.5106052756309509
    },
    {
    x: 0.0010865199146792293,
    y: 0.5106052756309509
    }
    ]
    },
    {
    description: "Furniture",
    confidence: 0.6887304186820984,
    bounding_box: [
    {
    x: 0.4693007171154022,
    y: 0.011065974831581116
    },
    {
    x: 0.945706307888031,
    y: 0.011065974831581116
    },
    {
    x: 0.945706307888031,
    y: 0.688319206237793
    },
    {
    x: 0.4693007171154022,
    y: 0.688319206237793
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5782490372657776,
    bounding_box: [
    {
    x: 0.4261831045150757,
    y: 0.04891391471028328
    },
    {
    x: 0.9960322380065918,
    y: 0.04891391471028328
    },
    {
    x: 0.9960322380065918,
    y: 0.9836532473564148
    },
    {
    x: 0.4261831045150757,
    y: 0.9836532473564148
    }
    ]
    },
    {
    description: "Furniture",
    confidence: 0.5648769736289978,
    bounding_box: [
    {
    x: 0,
    y: 0.4987083673477173
    },
    {
    x: 0.48516586422920227,
    y: 0.4987083673477173
    },
    {
    x: 0.48516586422920227,
    y: 0.99629145860672
    },
    {
    x: 0,
    y: 0.99629145860672
    }
    ]
    },
    {
    description: "Book",
    confidence: 0.5034812688827515,
    bounding_box: [
    {
    x: 0.5092525482177734,
    y: 0.05228089168667793
    },
    {
    x: 0.7554956078529358,
    y: 0.05228089168667793
    },
    {
    x: 0.7554956078529358,
    y: 0.3404275178909302
    },
    {
    x: 0.5092525482177734,
    y: 0.3404275178909302
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14878,
    time_in_milliseconds: 496429.00000000006,
    labels: [
    {
    description: "Shelving",
    confidence: 99.22452569007874
    },
    {
    description: "Shelf",
    confidence: 98.93106818199158
    },
    {
    description: "Bookcase",
    confidence: 98.90809059143066
    },
    {
    description: "Furniture",
    confidence: 95.7312285900116
    },
    {
    description: "Room",
    confidence: 91.9360876083374
    },
    {
    description: "Book",
    confidence: 83.46867561340332
    },
    {
    description: "Building",
    confidence: 77.17916965484619
    },
    {
    description: "Publication",
    confidence: 72.01608419418335
    },
    {
    description: "Library",
    confidence: 69.5090651512146
    },
    {
    description: "Interior design",
    confidence: 54.307204484939575
    }
    ],
    objects: [
    {
    description: "Bookcase",
    confidence: 0.7996505498886108,
    bounding_box: [
    {
    x: 0.46228551864624023,
    y: 0.0019322549924254417
    },
    {
    x: 0.9860172271728516,
    y: 0.0019322549924254417
    },
    {
    x: 0.9860172271728516,
    y: 0.9973958134651184
    },
    {
    x: 0.46228551864624023,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Television",
    confidence: 0.5523035526275635,
    bounding_box: [
    {
    x: 0.001591973821632564,
    y: 0
    },
    {
    x: 0.18432225286960602,
    y: 0
    },
    {
    x: 0.18432225286960602,
    y: 0.5039065480232239
    },
    {
    x: 0.001591973821632564,
    y: 0.5039065480232239
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 14965,
    time_in_milliseconds: 499332.00000000006,
    labels: [
    {
    description: "Library",
    confidence: 76.13135576248169
    },
    {
    description: "Book",
    confidence: 70.70399522781372
    },
    {
    description: "Bookcase",
    confidence: 65.76210856437683
    },
    {
    description: "Publication",
    confidence: 59.97973680496216
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    }
    ],
    objects: [
    {
    description: "Bookcase",
    confidence: 0.8729148507118225,
    bounding_box: [
    {
    x: 0.6285582780838013,
    y: 0.012180265039205551
    },
    {
    x: 0.9802572727203369,
    y: 0.012180265039205551
    },
    {
    x: 0.9802572727203369,
    y: 0.9925461411476135
    },
    {
    x: 0.6285582780838013,
    y: 0.9925461411476135
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8033334016799927,
    bounding_box: [
    {
    x: 0.32967063784599304,
    y: 0.2850918471813202
    },
    {
    x: 0.7496693730354309,
    y: 0.2850918471813202
    },
    {
    x: 0.7496693730354309,
    y: 0.9917253255844116
    },
    {
    x: 0.32967063784599304,
    y: 0.9917253255844116
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15081,
    time_in_milliseconds: 503203.00000000006,
    labels: [
    {
    description: "Finger",
    confidence: 64.58866596221924
    },
    {
    description: "Glasses",
    confidence: 56.08964562416077
    }
    ],
    objects: [
    {
    description: "Watch",
    confidence: 0.9124917387962341,
    bounding_box: [
    {
    x: 0.6162123680114746,
    y: 0.8657128810882568
    },
    {
    x: 0.6989343166351318,
    y: 0.8657128810882568
    },
    {
    x: 0.6989343166351318,
    y: 0.9867907166481018
    },
    {
    x: 0.6162123680114746,
    y: 0.9867907166481018
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8656185865402222,
    bounding_box: [
    {
    x: 0.35696592926979065,
    y: 0.2804838716983795
    },
    {
    x: 0.8907780647277832,
    y: 0.2804838716983795
    },
    {
    x: 0.8907780647277832,
    y: 0.9942147731781006
    },
    {
    x: 0.35696592926979065,
    y: 0.9942147731781006
    }
    ]
    },
    {
    description: "Television",
    confidence: 0.6585996150970459,
    bounding_box: [
    {
    x: 0.0015032306546345353,
    y: 0
    },
    {
    x: 0.18506771326065063,
    y: 0
    },
    {
    x: 0.18506771326065063,
    y: 0.5057752728462219
    },
    {
    x: 0.0015032306546345353,
    y: 0.5057752728462219
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6564347743988037,
    bounding_box: [
    {
    x: 0.5091285109519958,
    y: 0.012238971889019012
    },
    {
    x: 0.9748253226280212,
    y: 0.012238971889019012
    },
    {
    x: 0.9748253226280212,
    y: 0.9611005187034607
    },
    {
    x: 0.5091285109519958,
    y: 0.9611005187034607
    }
    ]
    },
    {
    description: "Vase",
    confidence: 0.6315792798995972,
    bounding_box: [
    {
    x: 0.7170652747154236,
    y: 0.04199573025107384
    },
    {
    x: 0.8112204074859619,
    y: 0.04199573025107384
    },
    {
    x: 0.8112204074859619,
    y: 0.3290630877017975
    },
    {
    x: 0.7170652747154236,
    y: 0.3290630877017975
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.5575920343399048,
    bounding_box: [
    {
    x: 0.4576666057109833,
    y: 0.6650294661521912
    },
    {
    x: 0.5767927169799805,
    y: 0.6650294661521912
    },
    {
    x: 0.5767927169799805,
    y: 0.7599541544914246
    },
    {
    x: 0.4576666057109833,
    y: 0.7599541544914246
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15168,
    time_in_milliseconds: 506106.00000000006,
    labels: [
    {
    description: "Shelving",
    confidence: 99.22452569007874
    },
    {
    description: "Shelf",
    confidence: 98.93106818199158
    },
    {
    description: "Bookcase",
    confidence: 98.90809059143066
    },
    {
    description: "Furniture",
    confidence: 95.65931558609009
    },
    {
    description: "Room",
    confidence: 92.1927809715271
    },
    {
    description: "Book",
    confidence: 86.25819683074951
    },
    {
    description: "Building",
    confidence: 78.01280617713928
    },
    {
    description: "Publication",
    confidence: 76.54074430465698
    },
    {
    description: "Library",
    confidence: 69.5090651512146
    },
    {
    description: "Interior design",
    confidence: 54.307204484939575
    }
    ],
    objects: [
    {
    description: "Bookcase",
    confidence: 0.6683486104011536,
    bounding_box: [
    {
    x: 0.491347074508667,
    y: 0.009507241658866405
    },
    {
    x: 0.9577276706695557,
    y: 0.009507241658866405
    },
    {
    x: 0.9577276706695557,
    y: 0.9958792328834534
    },
    {
    x: 0.491347074508667,
    y: 0.9958792328834534
    }
    ]
    },
    {
    description: "Television",
    confidence: 0.6164787411689758,
    bounding_box: [
    {
    x: 0.0012682345695793629,
    y: 0
    },
    {
    x: 0.1848294734954834,
    y: 0
    },
    {
    x: 0.1848294734954834,
    y: 0.5103144645690918
    },
    {
    x: 0.0012682345695793629,
    y: 0.5103144645690918
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.535783588886261,
    bounding_box: [
    {
    x: 0.9071151614189148,
    y: 0.5814109444618225
    },
    {
    x: 0.9972941875457764,
    y: 0.5814109444618225
    },
    {
    x: 0.9972941875457764,
    y: 0.9956575036048889
    },
    {
    x: 0.9071151614189148,
    y: 0.9956575036048889
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15284,
    time_in_milliseconds: 509976,
    labels: [
    {
    description: "Book",
    confidence: 66.29728078842163
    },
    {
    description: "Publication",
    confidence: 55.98449110984802
    },
    {
    description: "Bookcase",
    confidence: 54.92061376571655
    },
    {
    description: "Library",
    confidence: 54.79663610458374
    }
    ],
    objects: [
    {
    description: "Bookcase",
    confidence: 0.8870693445205688,
    bounding_box: [
    {
    x: 0.6260700821876526,
    y: 0.012205115519464016
    },
    {
    x: 0.9803414344787598,
    y: 0.012205115519464016
    },
    {
    x: 0.9803414344787598,
    y: 0.9868246912956238
    },
    {
    x: 0.6260700821876526,
    y: 0.9868246912956238
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8176964521408081,
    bounding_box: [
    {
    x: 0.33001676201820374,
    y: 0.2748166620731354
    },
    {
    x: 0.7752518653869629,
    y: 0.2748166620731354
    },
    {
    x: 0.7752518653869629,
    y: 0.9883928298950195
    },
    {
    x: 0.33001676201820374,
    y: 0.9883928298950195
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15429,
    time_in_milliseconds: 514815.00000000006,
    labels: [
    {
    description: "Eyewear",
    confidence: 99.61617588996887
    },
    {
    description: "Hair",
    confidence: 96.63026928901672
    },
    {
    description: "Glasses",
    confidence: 95.23036479949951
    },
    {
    description: "Sunglasses",
    confidence: 88.64800930023193
    },
    {
    description: "Forehead",
    confidence: 87.55422234535217
    },
    {
    description: "Vision care",
    confidence: 85.09948253631592
    },
    {
    description: "Chin",
    confidence: 85.04008650779724
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Cool",
    confidence: 70.3183650970459
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    }
    ],
    objects: [
    {
    description: "Sunglasses",
    confidence: 0.9314795136451721,
    bounding_box: [
    {
    x: 0.45878568291664124,
    y: 0.4674231708049774
    },
    {
    x: 0.6198250651359558,
    y: 0.4674231708049774
    },
    {
    x: 0.6198250651359558,
    y: 0.5945250988006592
    },
    {
    x: 0.45878568291664124,
    y: 0.5945250988006592
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8320668339729309,
    bounding_box: [
    {
    x: 0.36416804790496826,
    y: 0.22388257086277008
    },
    {
    x: 0.9744847416877747,
    y: 0.22388257086277008
    },
    {
    x: 0.9744847416877747,
    y: 0.9912559986114502
    },
    {
    x: 0.36416804790496826,
    y: 0.9912559986114502
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5583978891372681,
    bounding_box: [
    {
    x: 0.35330983996391296,
    y: 0.423875093460083
    },
    {
    x: 0.9762263298034668,
    y: 0.423875093460083
    },
    {
    x: 0.9762263298034668,
    y: 0.9937729239463806
    },
    {
    x: 0.35330983996391296,
    y: 0.9937729239463806
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15487,
    time_in_milliseconds: 516750,
    labels: [
    {
    description: "Shelving",
    confidence: 99.10499453544617
    },
    {
    description: "Shelf",
    confidence: 98.85039925575256
    },
    {
    description: "Bookcase",
    confidence: 98.62487316131592
    },
    {
    description: "Furniture",
    confidence: 96.03460431098938
    },
    {
    description: "Room",
    confidence: 93.14624667167664
    },
    {
    description: "Book",
    confidence: 75.08392333984375
    },
    {
    description: "Building",
    confidence: 71.77337408065796
    },
    {
    description: "Library",
    confidence: 66.96746349334717
    },
    {
    description: "Table",
    confidence: 63.79098296165466
    },
    {
    description: "Publication",
    confidence: 58.75436067581177
    }
    ],
    objects: [
    {
    description: "Bookcase",
    confidence: 0.797557532787323,
    bounding_box: [
    {
    x: 0.4740285575389862,
    y: 0.0016221072291955352
    },
    {
    x: 0.9385934472084045,
    y: 0.0016221072291955352
    },
    {
    x: 0.9385934472084045,
    y: 0.9755848050117493
    },
    {
    x: 0.4740285575389862,
    y: 0.9755848050117493
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7159449458122253,
    bounding_box: [
    {
    x: 0.6882638931274414,
    y: 0.0837627574801445
    },
    {
    x: 0.9973958134651184,
    y: 0.0837627574801445
    },
    {
    x: 0.9973958134651184,
    y: 0.9942129254341125
    },
    {
    x: 0.6882638931274414,
    y: 0.9942129254341125
    }
    ]
    },
    {
    description: "Television",
    confidence: 0.6607063412666321,
    bounding_box: [
    {
    x: 0.0013118184870108962,
    y: 0
    },
    {
    x: 0.18422411382198334,
    y: 0
    },
    {
    x: 0.18422411382198334,
    y: 0.5096489787101746
    },
    {
    x: 0.0013118184870108962,
    y: 0.5096489787101746
    }
    ]
    },
    {
    description: "Vase",
    confidence: 0.5152415037155151,
    bounding_box: [
    {
    x: 0.7184258103370667,
    y: 0.03306138515472412
    },
    {
    x: 0.815143883228302,
    y: 0.03306138515472412
    },
    {
    x: 0.815143883228302,
    y: 0.3187236487865448
    },
    {
    x: 0.7184258103370667,
    y: 0.3187236487865448
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15516,
    time_in_milliseconds: 517717,
    labels: [
    {
    description: "Shelving",
    confidence: 99.28873181343079
    },
    {
    description: "Bookcase",
    confidence: 99.06004667282104
    },
    {
    description: "Shelf",
    confidence: 98.94344806671143
    },
    {
    description: "Furniture",
    confidence: 96.03460431098938
    },
    {
    description: "Room",
    confidence: 91.85948967933655
    },
    {
    description: "Book",
    confidence: 85.8968734741211
    },
    {
    description: "Building",
    confidence: 78.56568098068237
    },
    {
    description: "Library",
    confidence: 77.97207832336426
    },
    {
    description: "Publication",
    confidence: 75.53622126579285
    },
    {
    description: "Interior design",
    confidence: 54.307204484939575
    }
    ],
    objects: [
    {
    description: "Bookcase",
    confidence: 0.7880618572235107,
    bounding_box: [
    {
    x: 0.46055540442466736,
    y: 0.00442624744027853
    },
    {
    x: 0.9865267276763916,
    y: 0.00442624744027853
    },
    {
    x: 0.9865267276763916,
    y: 0.9973958134651184
    },
    {
    x: 0.46055540442466736,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Television",
    confidence: 0.6131341457366943,
    bounding_box: [
    {
    x: 0.0017595469253137708,
    y: 0
    },
    {
    x: 0.18451352417469025,
    y: 0
    },
    {
    x: 0.18451352417469025,
    y: 0.507641613483429
    },
    {
    x: 0.0017595469253137708,
    y: 0.507641613483429
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15603,
    time_in_milliseconds: 520620,
    labels: [
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Media",
    confidence: 60.72695255279541
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8910716772079468,
    bounding_box: [
    {
    x: 0.4755610525608063,
    y: 0.4035862982273102
    },
    {
    x: 0.6021669507026672,
    y: 0.4035862982273102
    },
    {
    x: 0.6021669507026672,
    y: 0.4903428554534912
    },
    {
    x: 0.4755610525608063,
    y: 0.4903428554534912
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8701968789100647,
    bounding_box: [
    {
    x: 0.2666310966014862,
    y: 0.261106938123703
    },
    {
    x: 0.8527772426605225,
    y: 0.261106938123703
    },
    {
    x: 0.8527772426605225,
    y: 0.9870126247406006
    },
    {
    x: 0.2666310966014862,
    y: 0.9870126247406006
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8195924162864685,
    bounding_box: [
    {
    x: 0.626060426235199,
    y: 0.023555882275104523
    },
    {
    x: 0.9818808436393738,
    y: 0.023555882275104523
    },
    {
    x: 0.9818808436393738,
    y: 0.9678098559379578
    },
    {
    x: 0.626060426235199,
    y: 0.9678098559379578
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7799888849258423,
    bounding_box: [
    {
    x: 0.32239869236946106,
    y: 0.513546347618103
    },
    {
    x: 0.8162084221839905,
    y: 0.513546347618103
    },
    {
    x: 0.8162084221839905,
    y: 0.9748104214668274
    },
    {
    x: 0.32239869236946106,
    y: 0.9748104214668274
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.722772479057312,
    bounding_box: [
    {
    x: 0.2833267152309418,
    y: 0.2957579493522644
    },
    {
    x: 0.8367154598236084,
    y: 0.2957579493522644
    },
    {
    x: 0.8367154598236084,
    y: 0.9915869235992432
    },
    {
    x: 0.2833267152309418,
    y: 0.9915869235992432
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15922,
    time_in_milliseconds: 531264,
    labels: [
    {
    description: "Muscle",
    confidence: 85.95128655433655
    },
    {
    description: "Advertising",
    confidence: 74.86580014228821
    },
    {
    description: "Abdomen",
    confidence: 72.66467213630676
    },
    {
    description: "Briefs",
    confidence: 69.06792521476746
    },
    {
    description: "Barechested",
    confidence: 68.49467158317566
    },
    {
    description: "Thigh",
    confidence: 67.74523258209229
    },
    {
    description: "Undergarment",
    confidence: 64.82027173042297
    },
    {
    description: "Underpants",
    confidence: 62.66925930976868
    },
    {
    description: "Chest",
    confidence: 61.48759722709656
    },
    {
    description: "Trunk",
    confidence: 57.45025873184204
    }
    ],
    objects: [
    {
    description: "Underpants",
    confidence: 0.8552369475364685,
    bounding_box: [
    {
    x: 0.4076182544231415,
    y: 0.7627618312835693
    },
    {
    x: 0.5661723017692566,
    y: 0.7627618312835693
    },
    {
    x: 0.5661723017692566,
    y: 0.9099864959716797
    },
    {
    x: 0.4076182544231415,
    y: 0.9099864959716797
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5047626495361328,
    bounding_box: [
    {
    x: 0.3317848742008209,
    y: 0.11176369339227676
    },
    {
    x: 0.667945921421051,
    y: 0.11176369339227676
    },
    {
    x: 0.667945921421051,
    y: 0.9414127469062805
    },
    {
    x: 0.3317848742008209,
    y: 0.9414127469062805
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 15980,
    time_in_milliseconds: 533200,
    labels: [
    {
    description: "Library",
    confidence: 75.59160590171814
    },
    {
    description: "Glasses",
    confidence: 65.22085666656494
    },
    {
    description: "Book",
    confidence: 57.679176330566406
    },
    {
    description: "Bookcase",
    confidence: 57.41544961929321
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9215930700302124,
    bounding_box: [
    {
    x: 0.3783038854598999,
    y: 0.3269978165626526
    },
    {
    x: 0.5125798583030701,
    y: 0.3269978165626526
    },
    {
    x: 0.5125798583030701,
    y: 0.4145984351634979
    },
    {
    x: 0.3783038854598999,
    y: 0.4145984351634979
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8695387840270996,
    bounding_box: [
    {
    x: 0.20778398215770721,
    y: 0.19966191053390503
    },
    {
    x: 0.788044273853302,
    y: 0.19966191053390503
    },
    {
    x: 0.788044273853302,
    y: 0.9973958134651184
    },
    {
    x: 0.20778398215770721,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8483428955078125,
    bounding_box: [
    {
    x: 0.6239486336708069,
    y: 0.02903393842279911
    },
    {
    x: 0.9805874824523926,
    y: 0.02903393842279911
    },
    {
    x: 0.9805874824523926,
    y: 0.9822056889533997
    },
    {
    x: 0.6239486336708069,
    y: 0.9822056889533997
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5135653614997864,
    bounding_box: [
    {
    x: 0.23243969678878784,
    y: 0.4974006712436676
    },
    {
    x: 0.7741940021514893,
    y: 0.4974006712436676
    },
    {
    x: 0.7741940021514893,
    y: 0.9901902675628662
    },
    {
    x: 0.23243969678878784,
    y: 0.9901902675628662
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 16183,
    time_in_milliseconds: 539973.0000000001,
    labels: [
    {
    description: "Text",
    confidence: 97.96839356422424
    },
    {
    description: "Product",
    confidence: 88.28333020210266
    },
    {
    description: "Font",
    confidence: 86.16248369216919
    },
    {
    description: "Document",
    confidence: 76.83281302452087
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Paper",
    confidence: 54.30682301521301
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7045440673828125,
    bounding_box: [
    {
    x: 0.03974634036421776,
    y: 0.31385499238967896
    },
    {
    x: 0.9952831268310547,
    y: 0.31385499238967896
    },
    {
    x: 0.9952831268310547,
    y: 0.9898306727409363
    },
    {
    x: 0.03974634036421776,
    y: 0.9898306727409363
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 16299,
    time_in_milliseconds: 543844,
    labels: [
    {
    description: "Glasses",
    confidence: 72.36500382423401
    },
    {
    description: "Library",
    confidence: 67.93658137321472
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    },
    {
    description: "Vision care",
    confidence: 56.652915477752686
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.917575478553772,
    bounding_box: [
    {
    x: 0.49608027935028076,
    y: 0.32475361227989197
    },
    {
    x: 0.6261317133903503,
    y: 0.32475361227989197
    },
    {
    x: 0.6261317133903503,
    y: 0.4099770486354828
    },
    {
    x: 0.49608027935028076,
    y: 0.4099770486354828
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8879712820053101,
    bounding_box: [
    {
    x: 0.3312610685825348,
    y: 0.1993706226348877
    },
    {
    x: 0.7653059363365173,
    y: 0.1993706226348877
    },
    {
    x: 0.7653059363365173,
    y: 0.9972659945487976
    },
    {
    x: 0.3312610685825348,
    y: 0.9972659945487976
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.7759577631950378,
    bounding_box: [
    {
    x: 0.6144119501113892,
    y: 0.011220662854611874
    },
    {
    x: 0.9789636731147766,
    y: 0.011220662854611874
    },
    {
    x: 0.9789636731147766,
    y: 0.9904158115386963
    },
    {
    x: 0.6144119501113892,
    y: 0.9904158115386963
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7607802152633667,
    bounding_box: [
    {
    x: 0.3788646459579468,
    y: 0.5243473649024963
    },
    {
    x: 0.7497801780700684,
    y: 0.5243473649024963
    },
    {
    x: 0.7497801780700684,
    y: 0.9871442317962646
    },
    {
    x: 0.3788646459579468,
    y: 0.9871442317962646
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7557131052017212,
    bounding_box: [
    {
    x: 0.35746607184410095,
    y: 0.3666307032108307
    },
    {
    x: 0.7600929141044617,
    y: 0.3666307032108307
    },
    {
    x: 0.7600929141044617,
    y: 0.9906119108200073
    },
    {
    x: 0.35746607184410095,
    y: 0.9906119108200073
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 16792,
    time_in_milliseconds: 560292,
    labels: [
    {
    description: "Text",
    confidence: 98.3268678188324
    },
    {
    description: "Font",
    confidence: 87.53489255905151
    },
    {
    description: "Document",
    confidence: 87.38189935684204
    },
    {
    description: "Adaptation",
    confidence: 74.08301830291748
    },
    {
    description: "Paper",
    confidence: 72.87415862083435
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Paper product",
    confidence: 57.73124098777771
    },
    {
    description: "History",
    confidence: 54.10714149475098
    },
    {
    description: "Writing",
    confidence: 52.57389545440674
    },
    {
    description: "Engineering",
    confidence: 52.01485753059387
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 16908,
    time_in_milliseconds: 564164,
    labels: [
    {
    description: "Glasses",
    confidence: 72.36500382423401
    },
    {
    description: "Library",
    confidence: 72.2136378288269
    },
    {
    description: "Bookcase",
    confidence: 69.82871890068054
    },
    {
    description: "Book",
    confidence: 65.93479514122009
    },
    {
    description: "Publication",
    confidence: 54.4025719165802
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Author",
    confidence: 51.658010482788086
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9302046298980713,
    bounding_box: [
    {
    x: 0.42819905281066895,
    y: 0.38045844435691833
    },
    {
    x: 0.5760907530784607,
    y: 0.38045844435691833
    },
    {
    x: 0.5760907530784607,
    y: 0.47624528408050537
    },
    {
    x: 0.42819905281066895,
    y: 0.47624528408050537
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8797979950904846,
    bounding_box: [
    {
    x: 0.6268613934516907,
    y: 0.02058257907629013
    },
    {
    x: 0.9804777503013611,
    y: 0.02058257907629013
    },
    {
    x: 0.9804777503013611,
    y: 0.9869051575660706
    },
    {
    x: 0.6268613934516907,
    y: 0.9869051575660706
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8571916222572327,
    bounding_box: [
    {
    x: 0.28422144055366516,
    y: 0.20475797355175018
    },
    {
    x: 0.8315072059631348,
    y: 0.20475797355175018
    },
    {
    x: 0.8315072059631348,
    y: 0.9973958134651184
    },
    {
    x: 0.28422144055366516,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5797153115272522,
    bounding_box: [
    {
    x: 0.2722395360469818,
    y: 0.5663125514984131
    },
    {
    x: 0.8355779647827148,
    y: 0.5663125514984131
    },
    {
    x: 0.8355779647827148,
    y: 0.986229419708252
    },
    {
    x: 0.2722395360469818,
    y: 0.986229419708252
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 17807,
    time_in_milliseconds: 594160.0000000001,
    labels: [
    {
    description: "Entertainment",
    confidence: 92.86551475524902
    },
    {
    description: "Audience",
    confidence: 91.9227123260498
    },
    {
    description: "Nightclub",
    confidence: 91.84405207633972
    },
    {
    description: "Crowd",
    confidence: 90.31341671943665
    },
    {
    description: "Performance",
    confidence: 88.46435546875
    },
    {
    description: "Display device",
    confidence: 88.1899893283844
    },
    {
    description: "Music venue",
    confidence: 84.6052348613739
    },
    {
    description: "Disco",
    confidence: 82.99574255943298
    },
    {
    description: "Stage",
    confidence: 82.94956088066101
    },
    {
    description: "Event",
    confidence: 82.4295163154602
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.867104172706604,
    bounding_box: [
    {
    x: 0.628975510597229,
    y: 0.38561391830444336
    },
    {
    x: 0.7773275375366211,
    y: 0.38561391830444336
    },
    {
    x: 0.7773275375366211,
    y: 0.8130243420600891
    },
    {
    x: 0.628975510597229,
    y: 0.8130243420600891
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8628411293029785,
    bounding_box: [
    {
    x: 0.20644111931324005,
    y: 0.6798667907714844
    },
    {
    x: 0.3169872760772705,
    y: 0.6798667907714844
    },
    {
    x: 0.3169872760772705,
    y: 0.8471696972846985
    },
    {
    x: 0.20644111931324005,
    y: 0.8471696972846985
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8562243580818176,
    bounding_box: [
    {
    x: 0.4138435125350952,
    y: 0.643189549446106
    },
    {
    x: 0.5205253958702087,
    y: 0.643189549446106
    },
    {
    x: 0.5205253958702087,
    y: 0.8465836048126221
    },
    {
    x: 0.4138435125350952,
    y: 0.8465836048126221
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8400372862815857,
    bounding_box: [
    {
    x: 0.47804102301597595,
    y: 0.5990576148033142
    },
    {
    x: 0.5597766041755676,
    y: 0.5990576148033142
    },
    {
    x: 0.5597766041755676,
    y: 0.840225875377655
    },
    {
    x: 0.47804102301597595,
    y: 0.840225875377655
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8297278881072998,
    bounding_box: [
    {
    x: 0.14799682796001434,
    y: 0.5393242835998535
    },
    {
    x: 0.2190714031457901,
    y: 0.5393242835998535
    },
    {
    x: 0.2190714031457901,
    y: 0.7347530722618103
    },
    {
    x: 0.14799682796001434,
    y: 0.7347530722618103
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8195174336433411,
    bounding_box: [
    {
    x: 0.45166417956352234,
    y: 0.5372554659843445
    },
    {
    x: 0.5120879411697388,
    y: 0.5372554659843445
    },
    {
    x: 0.5120879411697388,
    y: 0.7296448349952698
    },
    {
    x: 0.45166417956352234,
    y: 0.7296448349952698
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.814342200756073,
    bounding_box: [
    {
    x: 0.31509485840797424,
    y: 0.5759223699569702
    },
    {
    x: 0.4115200936794281,
    y: 0.5759223699569702
    },
    {
    x: 0.4115200936794281,
    y: 0.8300408720970154
    },
    {
    x: 0.31509485840797424,
    y: 0.8300408720970154
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7648205757141113,
    bounding_box: [
    {
    x: 0.563764750957489,
    y: 0.538722813129425
    },
    {
    x: 0.6335115432739258,
    y: 0.538722813129425
    },
    {
    x: 0.6335115432739258,
    y: 0.7910051345825195
    },
    {
    x: 0.563764750957489,
    y: 0.7910051345825195
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6156020760536194,
    bounding_box: [
    {
    x: 0.184916689991951,
    y: 0.5711714625358582
    },
    {
    x: 0.27846667170524597,
    y: 0.5711714625358582
    },
    {
    x: 0.27846667170524597,
    y: 0.8186518549919128
    },
    {
    x: 0.184916689991951,
    y: 0.8186518549919128
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.580711841583252,
    bounding_box: [
    {
    x: 0.3986782133579254,
    y: 0.5392486453056335
    },
    {
    x: 0.45540979504585266,
    y: 0.5392486453056335
    },
    {
    x: 0.45540979504585266,
    y: 0.7236941456794739
    },
    {
    x: 0.3986782133579254,
    y: 0.7236941456794739
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 17836,
    time_in_milliseconds: 595128,
    labels: [
    {
    description: "Display device",
    confidence: 91.12656116485596
    },
    {
    description: "Led display",
    confidence: 78.87312173843384
    },
    {
    description: "Event",
    confidence: 73.25606346130371
    },
    {
    description: "News",
    confidence: 71.14530205726624
    },
    {
    description: "Media",
    confidence: 69.79514360427856
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Flat panel display",
    confidence: 66.50039553642273
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Audience",
    confidence: 61.054885387420654
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8478984236717224,
    bounding_box: [
    {
    x: 0.6588542461395264,
    y: 0.20892876386642456
    },
    {
    x: 0.8618928790092468,
    y: 0.20892876386642456
    },
    {
    x: 0.8618928790092468,
    y: 0.8458101749420166
    },
    {
    x: 0.6588542461395264,
    y: 0.8458101749420166
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7621155977249146,
    bounding_box: [
    {
    x: 0.24547135829925537,
    y: 0.5733156800270081
    },
    {
    x: 0.5163651704788208,
    y: 0.5733156800270081
    },
    {
    x: 0.5163651704788208,
    y: 0.8412529826164246
    },
    {
    x: 0.24547135829925537,
    y: 0.8412529826164246
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7092139720916748,
    bounding_box: [
    {
    x: 0.22243082523345947,
    y: 0.4711219072341919
    },
    {
    x: 0.29669979214668274,
    y: 0.4711219072341919
    },
    {
    x: 0.29669979214668274,
    y: 0.6465886235237122
    },
    {
    x: 0.22243082523345947,
    y: 0.6465886235237122
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6743085384368896,
    bounding_box: [
    {
    x: 0.12665323913097382,
    y: 0.4758521020412445
    },
    {
    x: 0.23505555093288422,
    y: 0.4758521020412445
    },
    {
    x: 0.23505555093288422,
    y: 0.831851065158844
    },
    {
    x: 0.12665323913097382,
    y: 0.831851065158844
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 17865,
    time_in_milliseconds: 596095,
    labels: [
    {
    description: "News",
    confidence: 96.24449610710144
    },
    {
    description: "Cobalt blue",
    confidence: 93.01844835281372
    },
    {
    description: "Majorelle blue",
    confidence: 92.68139004707336
    },
    {
    description: "Blue",
    confidence: 91.18428230285645
    },
    {
    description: "Newsreader",
    confidence: 81.86472058296204
    },
    {
    description: "Electric blue",
    confidence: 81.10349774360657
    },
    {
    description: "Display device",
    confidence: 80.53919672966003
    },
    {
    description: "Newscaster",
    confidence: 77.01355814933777
    },
    {
    description: "Television program",
    confidence: 75.79622864723206
    },
    {
    description: "Media",
    confidence: 55.50802946090698
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8861029744148254,
    bounding_box: [
    {
    x: 0.5232272148132324,
    y: 0.23848223686218262
    },
    {
    x: 0.8371403813362122,
    y: 0.23848223686218262
    },
    {
    x: 0.8371403813362122,
    y: 0.8463592529296875
    },
    {
    x: 0.5232272148132324,
    y: 0.8463592529296875
    }
    ]
    },
    {
    description: "Tie",
    confidence: 0.827807605266571,
    bounding_box: [
    {
    x: 0.6576972007751465,
    y: 0.4751514196395874
    },
    {
    x: 0.7031173706054688,
    y: 0.4751514196395874
    },
    {
    x: 0.7031173706054688,
    y: 0.7044758796691895
    },
    {
    x: 0.6576972007751465,
    y: 0.7044758796691895
    }
    ]
    },
    {
    description: "Coat",
    confidence: 0.7015401124954224,
    bounding_box: [
    {
    x: 0.5427829623222351,
    y: 0.4232388436794281
    },
    {
    x: 0.8231237530708313,
    y: 0.4232388436794281
    },
    {
    x: 0.8231237530708313,
    y: 0.8356119990348816
    },
    {
    x: 0.5427829623222351,
    y: 0.8356119990348816
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6718904972076416,
    bounding_box: [
    {
    x: 0.5190500617027283,
    y: 0.27958008646965027
    },
    {
    x: 0.8350000977516174,
    y: 0.27958008646965027
    },
    {
    x: 0.8350000977516174,
    y: 0.8456525802612305
    },
    {
    x: 0.5190500617027283,
    y: 0.8456525802612305
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 17894,
    time_in_milliseconds: 597063,
    labels: [
    {
    description: "Library",
    confidence: 63.93662095069885
    },
    {
    description: "Glasses",
    confidence: 62.70098090171814
    },
    {
    description: "Media",
    confidence: 61.78134083747864
    },
    {
    description: "Bookcase",
    confidence: 52.21534967422485
    },
    {
    description: "Author",
    confidence: 51.250267028808594
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9233139157295227,
    bounding_box: [
    {
    x: 0.4654388129711151,
    y: 0.40814802050590515
    },
    {
    x: 0.6065228581428528,
    y: 0.40814802050590515
    },
    {
    x: 0.6065228581428528,
    y: 0.5006771683692932
    },
    {
    x: 0.4654388129711151,
    y: 0.5006771683692932
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8497421741485596,
    bounding_box: [
    {
    x: 0.6243037581443787,
    y: 0.01918872818350792
    },
    {
    x: 0.9806206226348877,
    y: 0.01918872818350792
    },
    {
    x: 0.9806206226348877,
    y: 0.9761077761650085
    },
    {
    x: 0.6243037581443787,
    y: 0.9761077761650085
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8244937658309937,
    bounding_box: [
    {
    x: 0.31904348731040955,
    y: 0.2514455318450928
    },
    {
    x: 0.8327265977859497,
    y: 0.2514455318450928
    },
    {
    x: 0.8327265977859497,
    y: 0.9906162619590759
    },
    {
    x: 0.31904348731040955,
    y: 0.9906162619590759
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6433264017105103,
    bounding_box: [
    {
    x: 0.30602705478668213,
    y: 0.5547682642936707
    },
    {
    x: 0.8366391062736511,
    y: 0.5547682642936707
    },
    {
    x: 0.8366391062736511,
    y: 0.9863648414611816
    },
    {
    x: 0.30602705478668213,
    y: 0.9863648414611816
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 18358,
    time_in_milliseconds: 612545.0000000001,
    labels: [
    {
    description: "News",
    confidence: 98.45540523529053
    },
    {
    description: "Speech",
    confidence: 87.23933100700378
    },
    {
    description: "Official",
    confidence: 84.93348360061646
    },
    {
    description: "Spokesperson",
    confidence: 82.8370988368988
    },
    {
    description: "Event",
    confidence: 72.28018045425415
    },
    {
    description: "Media",
    confidence: 71.27792239189148
    },
    {
    description: "Television program",
    confidence: 63.30549716949463
    },
    {
    description: "Photo caption",
    confidence: 62.31793165206909
    },
    {
    description: "Speaker",
    confidence: 59.33330059051514
    },
    {
    description: "Newscaster",
    confidence: 53.33144664764404
    }
    ],
    objects: [
    {
    description: "Tie",
    confidence: 0.9224410653114319,
    bounding_box: [
    {
    x: 0.440796822309494,
    y: 0.4207146465778351
    },
    {
    x: 0.48620712757110596,
    y: 0.4207146465778351
    },
    {
    x: 0.48620712757110596,
    y: 0.7598029971122742
    },
    {
    x: 0.440796822309494,
    y: 0.7598029971122742
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8207066059112549,
    bounding_box: [
    {
    x: 0.2648136615753174,
    y: 0.14279071986675262
    },
    {
    x: 0.6790919899940491,
    y: 0.14279071986675262
    },
    {
    x: 0.6790919899940491,
    y: 0.8260346055030823
    },
    {
    x: 0.2648136615753174,
    y: 0.8260346055030823
    }
    ]
    },
    {
    description: "Suit",
    confidence: 0.5422698855400085,
    bounding_box: [
    {
    x: 0.2700066864490509,
    y: 0.19316937029361725
    },
    {
    x: 0.6845144629478455,
    y: 0.19316937029361725
    },
    {
    x: 0.6845144629478455,
    y: 0.8236642479896545
    },
    {
    x: 0.2700066864490509,
    y: 0.8236642479896545
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 18735,
    time_in_milliseconds: 625124,
    labels: [
    {
    description: "Library",
    confidence: 84.45051312446594
    },
    {
    description: "Bookcase",
    confidence: 76.52037739753723
    },
    {
    description: "Glasses",
    confidence: 75.91426968574524
    },
    {
    description: "Book",
    confidence: 68.95201206207275
    },
    {
    description: "Shelving",
    confidence: 63.58347535133362
    },
    {
    description: "Furniture",
    confidence: 56.74918293952942
    },
    {
    description: "Publication",
    confidence: 55.21233677864075
    },
    {
    description: "Librarian",
    confidence: 50.87888836860657
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9189075231552124,
    bounding_box: [
    {
    x: 0.439635306596756,
    y: 0.3812732994556427
    },
    {
    x: 0.5762950778007507,
    y: 0.3812732994556427
    },
    {
    x: 0.5762950778007507,
    y: 0.4726100265979767
    },
    {
    x: 0.439635306596756,
    y: 0.4726100265979767
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8582240343093872,
    bounding_box: [
    {
    x: 0.62275630235672,
    y: 0.016764946281909943
    },
    {
    x: 0.9825456738471985,
    y: 0.016764946281909943
    },
    {
    x: 0.9825456738471985,
    y: 0.9875532984733582
    },
    {
    x: 0.62275630235672,
    y: 0.9875532984733582
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8367905020713806,
    bounding_box: [
    {
    x: 0.3340592086315155,
    y: 0.22450293600559235
    },
    {
    x: 0.7797659039497375,
    y: 0.22450293600559235
    },
    {
    x: 0.7797659039497375,
    y: 0.9971391558647156
    },
    {
    x: 0.3340592086315155,
    y: 0.9971391558647156
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5618244409561157,
    bounding_box: [
    {
    x: 0.35241857171058655,
    y: 0.5519617199897766
    },
    {
    x: 0.7742363810539246,
    y: 0.5519617199897766
    },
    {
    x: 0.7742363810539246,
    y: 0.9897546172142029
    },
    {
    x: 0.35241857171058655,
    y: 0.9897546172142029
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 19315,
    time_in_milliseconds: 644477,
    labels: [
    {
    description: "Text",
    confidence: 96.99421525001526
    },
    {
    description: "Font",
    confidence: 81.95118308067322
    },
    {
    description: "Adaptation",
    confidence: 74.08301830291748
    },
    {
    description: "Document",
    confidence: 69.83636021614075
    },
    {
    description: "Paper",
    confidence: 50.96068978309631
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6530006527900696,
    bounding_box: [
    {
    x: 0.010464644990861416,
    y: 0.06035788357257843
    },
    {
    x: 0.9973958134651184,
    y: 0.06035788357257843
    },
    {
    x: 0.9973958134651184,
    y: 0.42861509323120117
    },
    {
    x: 0.010464644990861416,
    y: 0.42861509323120117
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 19402,
    time_in_milliseconds: 647380,
    labels: [
    {
    description: "Library",
    confidence: 73.36719036102295
    },
    {
    description: "Book",
    confidence: 70.13974189758301
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Bookcase",
    confidence: 61.45830154418945
    },
    {
    description: "Publication",
    confidence: 55.98449110984802
    },
    {
    description: "Author",
    confidence: 51.41788125038147
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9364576935768127,
    bounding_box: [
    {
    x: 0.43795570731163025,
    y: 0.3554399907588959
    },
    {
    x: 0.5826409459114075,
    y: 0.3554399907588959
    },
    {
    x: 0.5826409459114075,
    y: 0.4507380723953247
    },
    {
    x: 0.43795570731163025,
    y: 0.4507380723953247
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8287756443023682,
    bounding_box: [
    {
    x: 0.3343156576156616,
    y: 0.20479625463485718
    },
    {
    x: 0.8337878584861755,
    y: 0.20479625463485718
    },
    {
    x: 0.8337878584861755,
    y: 0.9940728545188904
    },
    {
    x: 0.3343156576156616,
    y: 0.9940728545188904
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8271227478981018,
    bounding_box: [
    {
    x: 0.6293609738349915,
    y: 0.019846150651574135
    },
    {
    x: 0.9805282950401306,
    y: 0.019846150651574135
    },
    {
    x: 0.9805282950401306,
    y: 0.9777325987815857
    },
    {
    x: 0.6293609738349915,
    y: 0.9777325987815857
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 19721,
    time_in_milliseconds: 658024,
    labels: [
    {
    description: "Face",
    confidence: 95.77996134757996
    },
    {
    description: "News",
    confidence: 93.84433031082153
    },
    {
    description: "Facial hair",
    confidence: 88.47724199295044
    },
    {
    description: "Head",
    confidence: 88.42743635177612
    },
    {
    description: "Forehead",
    confidence: 86.29716038703918
    },
    {
    description: "Chin",
    confidence: 85.04008650779724
    },
    {
    description: "Beard",
    confidence: 72.55929708480835
    },
    {
    description: "Media",
    confidence: 65.32303094863892
    },
    {
    description: "Photo caption",
    confidence: 59.25080180168152
    },
    {
    description: "Moustache",
    confidence: 57.44209885597229
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8410480618476868,
    bounding_box: [
    {
    x: 0.32622459530830383,
    y: 0.14291320741176605
    },
    {
    x: 0.6575134992599487,
    y: 0.14291320741176605
    },
    {
    x: 0.6575134992599487,
    y: 0.8915822505950928
    },
    {
    x: 0.32622459530830383,
    y: 0.8915822505950928
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6274734139442444,
    bounding_box: [
    {
    x: 0.34095683693885803,
    y: 0.6554440855979919
    },
    {
    x: 0.6402485370635986,
    y: 0.6554440855979919
    },
    {
    x: 0.6402485370635986,
    y: 0.8915941715240479
    },
    {
    x: 0.34095683693885803,
    y: 0.8915941715240479
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 19837,
    time_in_milliseconds: 661894,
    labels: [
    {
    description: "Facial hair",
    confidence: 98.04535508155823
    },
    {
    description: "Beard",
    confidence: 87.12003827095032
    },
    {
    description: "Ball",
    confidence: 64.56920504570007
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9224971532821655,
    bounding_box: [
    {
    x: 0.14216171205043793,
    y: 0.13725817203521729
    },
    {
    x: 0.4554023742675781,
    y: 0.13725817203521729
    },
    {
    x: 0.4554023742675781,
    y: 0.8900985717773438
    },
    {
    x: 0.14216171205043793,
    y: 0.8900985717773438
    }
    ]
    },
    {
    description: "Rugby ball",
    confidence: 0.9134117960929871,
    bounding_box: [
    {
    x: 0.5805057883262634,
    y: 0.1387108415365219
    },
    {
    x: 0.8253071308135986,
    y: 0.1387108415365219
    },
    {
    x: 0.8253071308135986,
    y: 0.8485816121101379
    },
    {
    x: 0.5805057883262634,
    y: 0.8485816121101379
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5264159440994263,
    bounding_box: [
    {
    x: 0.14212672412395477,
    y: 0.6578587293624878
    },
    {
    x: 0.43961089849472046,
    y: 0.6578587293624878
    },
    {
    x: 0.43961089849472046,
    y: 0.8864893913269043
    },
    {
    x: 0.14212672412395477,
    y: 0.8864893913269043
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 19895,
    time_in_milliseconds: 663830,
    labels: [
    {
    description: "Library",
    confidence: 74.21393394470215
    },
    {
    description: "Bookcase",
    confidence: 71.06386423110962
    },
    {
    description: "Book",
    confidence: 70.70399522781372
    },
    {
    description: "Glasses",
    confidence: 65.22085666656494
    },
    {
    description: "Publication",
    confidence: 58.75436067581177
    },
    {
    description: "Author",
    confidence: 51.41788125038147
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9294001460075378,
    bounding_box: [
    {
    x: 0.412439227104187,
    y: 0.3978583812713623
    },
    {
    x: 0.5481488108634949,
    y: 0.3978583812713623
    },
    {
    x: 0.5481488108634949,
    y: 0.49127197265625
    },
    {
    x: 0.412439227104187,
    y: 0.49127197265625
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8772991895675659,
    bounding_box: [
    {
    x: 0.6282334923744202,
    y: 0.017142003402113914
    },
    {
    x: 0.9774162173271179,
    y: 0.017142003402113914
    },
    {
    x: 0.9774162173271179,
    y: 0.9851191639900208
    },
    {
    x: 0.6282334923744202,
    y: 0.9851191639900208
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8388691544532776,
    bounding_box: [
    {
    x: 0.3239540159702301,
    y: 0.23051393032073975
    },
    {
    x: 0.8407046794891357,
    y: 0.23051393032073975
    },
    {
    x: 0.8407046794891357,
    y: 0.9921417236328125
    },
    {
    x: 0.3239540159702301,
    y: 0.9921417236328125
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7009186744689941,
    bounding_box: [
    {
    x: 0.3194228708744049,
    y: 0.5387523174285889
    },
    {
    x: 0.8326354026794434,
    y: 0.5387523174285889
    },
    {
    x: 0.8326354026794434,
    y: 0.9834601879119873
    },
    {
    x: 0.3194228708744049,
    y: 0.9834601879119873
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 19982,
    time_in_milliseconds: 666732.0000000001,
    labels: [
    {
    description: "Text",
    confidence: 93.07222962379456
    },
    {
    description: "Technology",
    confidence: 82.41574764251709
    },
    {
    description: "Font",
    confidence: 74.9562680721283
    },
    {
    description: "Electronics",
    confidence: 73.08112978935242
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Electronic device",
    confidence: 70.89406251907349
    },
    {
    description: "Screenshot",
    confidence: 66.80778861045837
    },
    {
    description: "Computer icon",
    confidence: 66.24621152877808
    },
    {
    description: "Gadget",
    confidence: 53.95982265472412
    },
    {
    description: "Computer program",
    confidence: 50.476640462875366
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 20011,
    time_in_milliseconds: 667701,
    labels: [
    {
    description: "Text",
    confidence: 94.49374079704285
    },
    {
    description: "Green",
    confidence: 91.94968938827515
    },
    {
    description: "Font",
    confidence: 83.14544558525085
    },
    {
    description: "Technology",
    confidence: 77.10137367248535
    },
    {
    description: "Organism",
    confidence: 76.71774625778198
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 20040,
    time_in_milliseconds: 668668,
    labels: [
    {
    description: "Text",
    confidence: 95.2957808971405
    },
    {
    description: "Font",
    confidence: 84.10601615905762
    },
    {
    description: "Organism",
    confidence: 76.71774625778198
    },
    {
    description: "Technology",
    confidence: 76.42231583595276
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    }
    ],
    objects: [
    {
    description: "Sunglasses",
    confidence: 0.585970938205719,
    bounding_box: [
    {
    x: 0.29399704933166504,
    y: 0.598766028881073
    },
    {
    x: 0.5321009755134583,
    y: 0.598766028881073
    },
    {
    x: 0.5321009755134583,
    y: 0.9210844039916992
    },
    {
    x: 0.29399704933166504,
    y: 0.9210844039916992
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 20069,
    time_in_milliseconds: 669636.0000000001,
    labels: [
    {
    description: "Text",
    confidence: 94.08422112464905
    },
    {
    description: "Font",
    confidence: 83.14544558525085
    },
    {
    description: "Organism",
    confidence: 81.16517663002014
    },
    {
    description: "Technology",
    confidence: 75.60265064239502
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    },
    {
    description: "Plant",
    confidence: 58.5474967956543
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 20098,
    time_in_milliseconds: 670603.0000000001,
    labels: [
    {
    description: "Text",
    confidence: 95.35306096076965
    },
    {
    description: "Font",
    confidence: 84.10601615905762
    },
    {
    description: "Technology",
    confidence: 77.10137367248535
    },
    {
    description: "Organism",
    confidence: 76.71774625778198
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    }
    ],
    objects: [
    {
    description: "Sunglasses",
    confidence: 0.729815661907196,
    bounding_box: [
    {
    x: 0.29638662934303284,
    y: 0.5975945591926575
    },
    {
    x: 0.5284653902053833,
    y: 0.5975945591926575
    },
    {
    x: 0.5284653902053833,
    y: 0.9205026626586914
    },
    {
    x: 0.29638662934303284,
    y: 0.9205026626586914
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 20127,
    time_in_milliseconds: 671570,
    labels: [
    {
    description: "Text",
    confidence: 93.84183883666992
    },
    {
    description: "Green",
    confidence: 90.68462252616882
    },
    {
    description: "Organism",
    confidence: 83.42533707618713
    },
    {
    description: "Font",
    confidence: 83.14544558525085
    },
    {
    description: "Technology",
    confidence: 77.10137367248535
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    },
    {
    description: "Plant",
    confidence: 58.5474967956543
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 20359,
    time_in_milliseconds: 679312,
    labels: [
    {
    description: "Text",
    confidence: 95.56402564048767
    },
    {
    description: "Font",
    confidence: 84.90258455276489
    },
    {
    description: "Organism",
    confidence: 76.71774625778198
    },
    {
    description: "Technology",
    confidence: 76.42231583595276
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    }
    ],
    objects: [
    {
    description: "Sunglasses",
    confidence: 0.6149981021881104,
    bounding_box: [
    {
    x: 0.29728618264198303,
    y: 0.6039238572120667
    },
    {
    x: 0.5283939838409424,
    y: 0.6039238572120667
    },
    {
    x: 0.5283939838409424,
    y: 0.920272171497345
    },
    {
    x: 0.29728618264198303,
    y: 0.920272171497345
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 20388,
    time_in_milliseconds: 680280.0000000001,
    labels: [
    {
    description: "Text",
    confidence: 93.70877742767334
    },
    {
    description: "Font",
    confidence: 81.95118308067322
    },
    {
    description: "Organism",
    confidence: 79.41054701805115
    },
    {
    description: "Technology",
    confidence: 76.42231583595276
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 20417,
    time_in_milliseconds: 681247.0000000001,
    labels: [
    {
    description: "Library",
    confidence: 75.86227059364319
    },
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Book",
    confidence: 73.03446531295776
    },
    {
    description: "Bookcase",
    confidence: 68.86523962020874
    },
    {
    description: "Publication",
    confidence: 59.37875509262085
    },
    {
    description: "Author",
    confidence: 50.800591707229614
    },
    {
    description: "Shelving",
    confidence: 50.00336170196533
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9270607233047485,
    bounding_box: [
    {
    x: 0.4056941270828247,
    y: 0.39554715156555176
    },
    {
    x: 0.5424332618713379,
    y: 0.39554715156555176
    },
    {
    x: 0.5424332618713379,
    y: 0.48671045899391174
    },
    {
    x: 0.4056941270828247,
    y: 0.48671045899391174
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8863784074783325,
    bounding_box: [
    {
    x: 0.6250439286231995,
    y: 0.01562761887907982
    },
    {
    x: 0.9784476161003113,
    y: 0.01562761887907982
    },
    {
    x: 0.9784476161003113,
    y: 0.9816389679908752
    },
    {
    x: 0.6250439286231995,
    y: 0.9816389679908752
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8195676803588867,
    bounding_box: [
    {
    x: 0.32753369212150574,
    y: 0.2195306420326233
    },
    {
    x: 0.8167741298675537,
    y: 0.2195306420326233
    },
    {
    x: 0.8167741298675537,
    y: 0.9961689114570618
    },
    {
    x: 0.32753369212150574,
    y: 0.9961689114570618
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 20533,
    time_in_milliseconds: 685118,
    labels: [
    {
    description: "Darth vader",
    confidence: 92.85563230514526
    },
    {
    description: "Fictional character",
    confidence: 92.30775833129883
    },
    {
    description: "Supervillain",
    confidence: 89.9842619895935
    },
    {
    description: "Pc game",
    confidence: 87.29831576347351
    },
    {
    description: "Movie",
    confidence: 83.42772722244263
    },
    {
    description: "Screenshot",
    confidence: 75.82467198371887
    },
    {
    description: "Darkness",
    confidence: 74.96129870414734
    },
    {
    description: "Fiction",
    confidence: 74.28412437438965
    },
    {
    description: "Digital compositing",
    confidence: 69.30153965950012
    },
    {
    description: "Scene",
    confidence: 65.17949104309082
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7980352640151978,
    bounding_box: [
    {
    x: 0.6915845274925232,
    y: 0.5467293858528137
    },
    {
    x: 0.820070207118988,
    y: 0.5467293858528137
    },
    {
    x: 0.820070207118988,
    y: 0.8849759101867676
    },
    {
    x: 0.6915845274925232,
    y: 0.8849759101867676
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7439208030700684,
    bounding_box: [
    {
    x: 0.007275896146893501,
    y: 0.396099328994751
    },
    {
    x: 0.3179022967815399,
    y: 0.396099328994751
    },
    {
    x: 0.3179022967815399,
    y: 0.8825240135192871
    },
    {
    x: 0.007275896146893501,
    y: 0.8825240135192871
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6939498782157898,
    bounding_box: [
    {
    x: 0.34845730662345886,
    y: 0.19821108877658844
    },
    {
    x: 0.5962578058242798,
    y: 0.19821108877658844
    },
    {
    x: 0.5962578058242798,
    y: 0.8892515301704407
    },
    {
    x: 0.34845730662345886,
    y: 0.8892515301704407
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 20620,
    time_in_milliseconds: 688020,
    labels: [
    {
    description: "Face",
    confidence: 95.38267254829407
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "People",
    confidence: 93.43461394309998
    },
    {
    description: "Movie",
    confidence: 89.6511971950531
    },
    {
    description: "Head",
    confidence: 88.42743635177612
    },
    {
    description: "Screenshot",
    confidence: 86.73320412635803
    },
    {
    description: "Conversation",
    confidence: 81.19204044342041
    },
    {
    description: "Human",
    confidence: 81.16180300712585
    },
    {
    description: "Fun",
    confidence: 80.11429905891418
    },
    {
    description: "Pc game",
    confidence: 78.50531935691833
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8534411787986755,
    bounding_box: [
    {
    x: 0.009542048908770084,
    y: 0.3888481557369232
    },
    {
    x: 0.35479483008384705,
    y: 0.3888481557369232
    },
    {
    x: 0.35479483008384705,
    y: 0.883734941482544
    },
    {
    x: 0.009542048908770084,
    y: 0.883734941482544
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8274061679840088,
    bounding_box: [
    {
    x: 0.39771905541419983,
    y: 0.24985472857952118
    },
    {
    x: 0.9930225610733032,
    y: 0.24985472857952118
    },
    {
    x: 0.9930225610733032,
    y: 0.8984492421150208
    },
    {
    x: 0.39771905541419983,
    y: 0.8984492421150208
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7008134126663208,
    bounding_box: [
    {
    x: 0.3913750946521759,
    y: 0.2245073914527893
    },
    {
    x: 0.9852724075317383,
    y: 0.2245073914527893
    },
    {
    x: 0.9852724075317383,
    y: 0.8970289826393127
    },
    {
    x: 0.3913750946521759,
    y: 0.8970289826393127
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6540656685829163,
    bounding_box: [
    {
    x: 0.034115020185709,
    y: 0.5526698231697083
    },
    {
    x: 0.34879669547080994,
    y: 0.5526698231697083
    },
    {
    x: 0.34879669547080994,
    y: 0.8810434341430664
    },
    {
    x: 0.034115020185709,
    y: 0.8810434341430664
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6467416882514954,
    bounding_box: [
    {
    x: 0.40544891357421875,
    y: 0.4743337631225586
    },
    {
    x: 0.9891278743743896,
    y: 0.4743337631225586
    },
    {
    x: 0.9891278743743896,
    y: 0.8897087574005127
    },
    {
    x: 0.40544891357421875,
    y: 0.8897087574005127
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 20678,
    time_in_milliseconds: 689955,
    labels: [
    {
    description: "Glasses",
    confidence: 74.8481273651123
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Library",
    confidence: 67.29269623756409
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    },
    {
    description: "Book",
    confidence: 58.25233459472656
    },
    {
    description: "Vision care",
    confidence: 54.48664426803589
    },
    {
    description: "Bookcase",
    confidence: 54.3973445892334
    },
    {
    description: "Media",
    confidence: 52.03367471694946
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9358766078948975,
    bounding_box: [
    {
    x: 0.5050726532936096,
    y: 0.35977068543434143
    },
    {
    x: 0.6432657241821289,
    y: 0.35977068543434143
    },
    {
    x: 0.6432657241821289,
    y: 0.4578719139099121
    },
    {
    x: 0.5050726532936096,
    y: 0.4578719139099121
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8448393940925598,
    bounding_box: [
    {
    x: 0.6261193156242371,
    y: 0.022858867421746254
    },
    {
    x: 0.9731757044792175,
    y: 0.022858867421746254
    },
    {
    x: 0.9731757044792175,
    y: 0.9805741310119629
    },
    {
    x: 0.6261193156242371,
    y: 0.9805741310119629
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8415115475654602,
    bounding_box: [
    {
    x: 0.3555346727371216,
    y: 0.23809529840946198
    },
    {
    x: 0.7700504660606384,
    y: 0.23809529840946198
    },
    {
    x: 0.7700504660606384,
    y: 0.9973958134651184
    },
    {
    x: 0.3555346727371216,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 20968,
    time_in_milliseconds: 699633,
    labels: [
    {
    description: "Text",
    confidence: 98.25422763824463
    },
    {
    description: "Font",
    confidence: 87.53489255905151
    },
    {
    description: "Document",
    confidence: 86.23466491699219
    },
    {
    description: "Adaptation",
    confidence: 77.25779414176941
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Paper",
    confidence: 70.24929523468018
    },
    {
    description: "Cloud",
    confidence: 59.4909131526947
    },
    {
    description: "Paper product",
    confidence: 57.73124098777771
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6023632287979126,
    bounding_box: [
    {
    x: 0.0030050792265683413,
    y: 0.3223252594470978
    },
    {
    x: 0.9973958134651184,
    y: 0.3223252594470978
    },
    {
    x: 0.9973958134651184,
    y: 0.9876234531402588
    },
    {
    x: 0.0030050792265683413,
    y: 0.9876234531402588
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 21113,
    time_in_milliseconds: 704471,
    labels: [
    {
    description: "Book",
    confidence: 60.874515771865845
    },
    {
    description: "Library",
    confidence: 57.640135288238525
    },
    {
    description: "Author",
    confidence: 52.10326910018921
    },
    {
    description: "Media",
    confidence: 52.03367471694946
    },
    {
    description: "Glasses",
    confidence: 51.58061981201172
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9250418543815613,
    bounding_box: [
    {
    x: 0.4527762234210968,
    y: 0.3812355697154999
    },
    {
    x: 0.5942118763923645,
    y: 0.3812355697154999
    },
    {
    x: 0.5942118763923645,
    y: 0.4734055697917938
    },
    {
    x: 0.4527762234210968,
    y: 0.4734055697917938
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8586878180503845,
    bounding_box: [
    {
    x: 0.6220887303352356,
    y: 0.015296664088964462
    },
    {
    x: 0.9789929389953613,
    y: 0.015296664088964462
    },
    {
    x: 0.9789929389953613,
    y: 0.9893434047698975
    },
    {
    x: 0.6220887303352356,
    y: 0.9893434047698975
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8585777282714844,
    bounding_box: [
    {
    x: 0.3036896586418152,
    y: 0.19687479734420776
    },
    {
    x: 0.7782918810844421,
    y: 0.19687479734420776
    },
    {
    x: 0.7782918810844421,
    y: 0.9973958134651184
    },
    {
    x: 0.3036896586418152,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 21635,
    time_in_milliseconds: 721888,
    labels: [
    {
    description: "Text",
    confidence: 98.27497005462646
    },
    {
    description: "Font",
    confidence: 87.1282696723938
    },
    {
    description: "Document",
    confidence: 77.45833396911621
    },
    {
    description: "Organism",
    confidence: 71.51590585708618
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Paper",
    confidence: 54.30682301521301
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6218748688697815,
    bounding_box: [
    {
    x: 0.009040414355695248,
    y: 0.2845573425292969
    },
    {
    x: 0.9876934885978699,
    y: 0.2845573425292969
    },
    {
    x: 0.9876934885978699,
    y: 0.9885554313659668
    },
    {
    x: 0.009040414355695248,
    y: 0.9885554313659668
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 21693,
    time_in_milliseconds: 723823,
    labels: [
    {
    description: "Text",
    confidence: 98.19189310073853
    },
    {
    description: "Font",
    confidence: 87.90220022201538
    },
    {
    description: "Document",
    confidence: 69.51519250869751
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5765684247016907,
    bounding_box: [
    {
    x: 0.01670076884329319,
    y: 0.3192998468875885
    },
    {
    x: 0.9886965155601501,
    y: 0.3192998468875885
    },
    {
    x: 0.9886965155601501,
    y: 0.9838131666183472
    },
    {
    x: 0.01670076884329319,
    y: 0.9838131666183472
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 21780,
    time_in_milliseconds: 726726,
    labels: [
    {
    description: "Glasses",
    confidence: 62.70098090171814
    },
    {
    description: "Library",
    confidence: 62.166959047317505
    },
    {
    description: "Book",
    confidence: 58.80803465843201
    },
    {
    description: "Bookcase",
    confidence: 53.32465171813965
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9186608791351318,
    bounding_box: [
    {
    x: 0.48104432225227356,
    y: 0.3649880886077881
    },
    {
    x: 0.6143710017204285,
    y: 0.3649880886077881
    },
    {
    x: 0.6143710017204285,
    y: 0.4529871940612793
    },
    {
    x: 0.48104432225227356,
    y: 0.4529871940612793
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8690718412399292,
    bounding_box: [
    {
    x: 0.6213765740394592,
    y: 0.021540576592087746
    },
    {
    x: 0.9786191582679749,
    y: 0.021540576592087746
    },
    {
    x: 0.9786191582679749,
    y: 0.9853588938713074
    },
    {
    x: 0.6213765740394592,
    y: 0.9853588938713074
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8514878749847412,
    bounding_box: [
    {
    x: 0.3354470729827881,
    y: 0.2001190185546875
    },
    {
    x: 0.7498454451560974,
    y: 0.2001190185546875
    },
    {
    x: 0.7498454451560974,
    y: 0.9973958134651184
    },
    {
    x: 0.3354470729827881,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6219606399536133,
    bounding_box: [
    {
    x: 0.35137224197387695,
    y: 0.5326175093650818
    },
    {
    x: 0.7417845726013184,
    y: 0.5326175093650818
    },
    {
    x: 0.7417845726013184,
    y: 0.9897066950798035
    },
    {
    x: 0.35137224197387695,
    y: 0.9897066950798035
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22012,
    time_in_milliseconds: 734466,
    labels: [
    {
    description: "Sky",
    confidence: 91.27334952354431
    },
    {
    description: "Hill station",
    confidence: 86.97320222854614
    },
    {
    description: "Atmospheric phenomenon",
    confidence: 85.69148182868958
    },
    {
    description: "Morning",
    confidence: 82.09600448608398
    },
    {
    description: "Bird's-eye view",
    confidence: 80.86029887199402
    },
    {
    description: "Aerial photography",
    confidence: 78.75497341156006
    },
    {
    description: "City",
    confidence: 75.52539110183716
    },
    {
    description: "Landscape",
    confidence: 75.0670850276947
    },
    {
    description: "Evening",
    confidence: 65.49444198608398
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    }
    ],
    objects: [
    {
    description: "Building",
    confidence: 0.8011741638183594,
    bounding_box: [
    {
    x: 0.34484922885894775,
    y: 0.46235501766204834
    },
    {
    x: 0.7419729232788086,
    y: 0.46235501766204834
    },
    {
    x: 0.7419729232788086,
    y: 0.6702287793159485
    },
    {
    x: 0.34484922885894775,
    y: 0.6702287793159485
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5295084118843079,
    bounding_box: [
    {
    x: 0.5978821516036987,
    y: 0.4565279483795166
    },
    {
    x: 0.6693320274353027,
    y: 0.4565279483795166
    },
    {
    x: 0.6693320274353027,
    y: 0.6694462895393372
    },
    {
    x: 0.5978821516036987,
    y: 0.6694462895393372
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22041,
    time_in_milliseconds: 735435.0000000001,
    labels: [
    {
    description: "Sky",
    confidence: 82.20894932746887
    },
    {
    description: "Urban area",
    confidence: 74.97134804725647
    },
    {
    description: "Font",
    confidence: 74.9562680721283
    },
    {
    description: "Metropolitan area",
    confidence: 69.08340454101562
    },
    {
    description: "City",
    confidence: 62.14529275894165
    },
    {
    description: "Landscape",
    confidence: 59.30580496788025
    },
    {
    description: "Sport venue",
    confidence: 54.18516397476196
    },
    {
    description: "Signage",
    confidence: 50.7346510887146
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.528776228427887,
    bounding_box: [
    {
    x: 0.5892102122306824,
    y: 0.5450716614723206
    },
    {
    x: 0.6446481347084045,
    y: 0.5450716614723206
    },
    {
    x: 0.6446481347084045,
    y: 0.7269253134727478
    },
    {
    x: 0.5892102122306824,
    y: 0.7269253134727478
    }
    ]
    },
    {
    description: "Table",
    confidence: 0.5186722278594971,
    bounding_box: [
    {
    x: 0.27102041244506836,
    y: 0.5979570746421814
    },
    {
    x: 0.4203190803527832,
    y: 0.5979570746421814
    },
    {
    x: 0.4203190803527832,
    y: 0.7177413105964661
    },
    {
    x: 0.27102041244506836,
    y: 0.7177413105964661
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22099,
    time_in_milliseconds: 737370,
    labels: [
    {
    description: "Black",
    confidence: 98.45905303955078
    },
    {
    description: "Darkness",
    confidence: 96.90123200416565
    },
    {
    description: "White",
    confidence: 95.94447612762451
    },
    {
    description: "Sky",
    confidence: 93.81980895996094
    },
    {
    description: "Text",
    confidence: 92.87942051887512
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Light",
    confidence: 90.79046249389648
    },
    {
    description: "Font",
    confidence: 87.90220022201538
    },
    {
    description: "Brown",
    confidence: 87.63669729232788
    },
    {
    description: "Atmosphere",
    confidence: 80.43394088745117
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 22128,
    time_in_milliseconds: 738337,
    labels: [
    {
    description: "Yellow",
    confidence: 83.63301753997803
    },
    {
    description: "Plant",
    confidence: 72.71697521209717
    },
    {
    description: "Tree",
    confidence: 68.69218349456787
    },
    {
    description: "Flower",
    confidence: 58.05104374885559
    }
    ],
    objects: [
    {
    description: "Food",
    confidence: 0.7070934176445007,
    bounding_box: [
    {
    x: 0.49005401134490967,
    y: 0.2385459542274475
    },
    {
    x: 0.7187235951423645,
    y: 0.2385459542274475
    },
    {
    x: 0.7187235951423645,
    y: 0.5075205564498901
    },
    {
    x: 0.49005401134490967,
    y: 0.5075205564498901
    }
    ]
    },
    {
    description: "Food",
    confidence: 0.6603331565856934,
    bounding_box: [
    {
    x: 0.5424643158912659,
    y: 0.4038868248462677
    },
    {
    x: 0.7584396004676819,
    y: 0.4038868248462677
    },
    {
    x: 0.7584396004676819,
    y: 0.8442850708961487
    },
    {
    x: 0.5424643158912659,
    y: 0.8442850708961487
    }
    ]
    },
    {
    description: "Food",
    confidence: 0.5877066850662231,
    bounding_box: [
    {
    x: 0.4706698954105377,
    y: 0.11068444699048996
    },
    {
    x: 0.765855073928833,
    y: 0.11068444699048996
    },
    {
    x: 0.765855073928833,
    y: 0.30170688033103943
    },
    {
    x: 0.4706698954105377,
    y: 0.30170688033103943
    }
    ]
    },
    {
    description: "Food",
    confidence: 0.5795994400978088,
    bounding_box: [
    {
    x: 0.5375859141349792,
    y: 0
    },
    {
    x: 0.808807373046875,
    y: 0
    },
    {
    x: 0.808807373046875,
    y: 0.12882821261882782
    },
    {
    x: 0.5375859141349792,
    y: 0.12882821261882782
    }
    ]
    },
    {
    description: "Food",
    confidence: 0.5584999918937683,
    bounding_box: [
    {
    x: 0.2823619544506073,
    y: 0.1642073094844818
    },
    {
    x: 0.4765681028366089,
    y: 0.1642073094844818
    },
    {
    x: 0.4765681028366089,
    y: 0.44334566593170166
    },
    {
    x: 0.2823619544506073,
    y: 0.44334566593170166
    }
    ]
    },
    {
    description: "Food",
    confidence: 0.5501775145530701,
    bounding_box: [
    {
    x: 0.19630873203277588,
    y: 0.25432565808296204
    },
    {
    x: 0.3474210500717163,
    y: 0.25432565808296204
    },
    {
    x: 0.3474210500717163,
    y: 0.5793232321739197
    },
    {
    x: 0.19630873203277588,
    y: 0.5793232321739197
    }
    ]
    },
    {
    description: "Food",
    confidence: 0.541953980922699,
    bounding_box: [
    {
    x: 0.7902433276176453,
    y: 0.2606465816497803
    },
    {
    x: 0.9936736226081848,
    y: 0.2606465816497803
    },
    {
    x: 0.9936736226081848,
    y: 0.9609417915344238
    },
    {
    x: 0.7902433276176453,
    y: 0.9609417915344238
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22186,
    time_in_milliseconds: 740272,
    labels: [
    {
    description: "Home",
    confidence: 97.31005430221558
    },
    {
    description: "House",
    confidence: 96.23461365699768
    },
    {
    description: "Property",
    confidence: 96.21080160140991
    },
    {
    description: "Tree",
    confidence: 91.28860235214233
    },
    {
    description: "Siding",
    confidence: 89.49786424636841
    },
    {
    description: "Yard",
    confidence: 85.73811054229736
    },
    {
    description: "Real estate",
    confidence: 82.32425451278687
    },
    {
    description: "Leaf",
    confidence: 80.58615326881409
    },
    {
    description: "Building",
    confidence: 80.06145358085632
    },
    {
    description: "Lawn",
    confidence: 76.20675563812256
    }
    ],
    objects: [
    {
    description: "Window",
    confidence: 0.7816587090492249,
    bounding_box: [
    {
    x: 0.7731751799583435,
    y: 0.4689621925354004
    },
    {
    x: 0.8537068963050842,
    y: 0.4689621925354004
    },
    {
    x: 0.8537068963050842,
    y: 0.7509710192680359
    },
    {
    x: 0.7731751799583435,
    y: 0.7509710192680359
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.7744632959365845,
    bounding_box: [
    {
    x: 0.26916423439979553,
    y: 0.6250436902046204
    },
    {
    x: 0.3463786840438843,
    y: 0.6250436902046204
    },
    {
    x: 0.3463786840438843,
    y: 0.7675945162773132
    },
    {
    x: 0.26916423439979553,
    y: 0.7675945162773132
    }
    ]
    },
    {
    description: "House",
    confidence: 0.742000937461853,
    bounding_box: [
    {
    x: 0,
    y: 0.05063774064183235
    },
    {
    x: 0.3356090784072876,
    y: 0.05063774064183235
    },
    {
    x: 0.3356090784072876,
    y: 0.833366870880127
    },
    {
    x: 0,
    y: 0.833366870880127
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.7276736497879028,
    bounding_box: [
    {
    x: 0.8671941161155701,
    y: 0.333986759185791
    },
    {
    x: 0.9957404732704163,
    y: 0.333986759185791
    },
    {
    x: 0.9957404732704163,
    y: 0.7538228631019592
    },
    {
    x: 0.8671941161155701,
    y: 0.7538228631019592
    }
    ]
    },
    {
    description: "House",
    confidence: 0.701502799987793,
    bounding_box: [
    {
    x: 0.6544187664985657,
    y: 0.06660621613264084
    },
    {
    x: 0.993660032749176,
    y: 0.06660621613264084
    },
    {
    x: 0.993660032749176,
    y: 0.9269201159477234
    },
    {
    x: 0.6544187664985657,
    y: 0.9269201159477234
    }
    ]
    },
    {
    description: "House",
    confidence: 0.6843159794807434,
    bounding_box: [
    {
    x: 0.26912784576416016,
    y: 0.5642439723014832
    },
    {
    x: 0.7368450164794922,
    y: 0.5642439723014832
    },
    {
    x: 0.7368450164794922,
    y: 0.8078694343566895
    },
    {
    x: 0.26912784576416016,
    y: 0.8078694343566895
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.6448716521263123,
    bounding_box: [
    {
    x: 0.5918272733688354,
    y: 0.6287198662757874
    },
    {
    x: 0.7177217602729797,
    y: 0.6287198662757874
    },
    {
    x: 0.7177217602729797,
    y: 0.7690706849098206
    },
    {
    x: 0.5918272733688354,
    y: 0.7690706849098206
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.6102169752120972,
    bounding_box: [
    {
    x: 0.007797669153660536,
    y: 0.3373936116695404
    },
    {
    x: 0.12257406115531921,
    y: 0.3373936116695404
    },
    {
    x: 0.12257406115531921,
    y: 0.770155131816864
    },
    {
    x: 0.007797669153660536,
    y: 0.770155131816864
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.550030529499054,
    bounding_box: [
    {
    x: 0.4296349585056305,
    y: 0.6268380284309387
    },
    {
    x: 0.49785280227661133,
    y: 0.6268380284309387
    },
    {
    x: 0.49785280227661133,
    y: 0.7737074494361877
    },
    {
    x: 0.4296349585056305,
    y: 0.7737074494361877
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22302,
    time_in_milliseconds: 744143,
    labels: [
    {
    description: "House",
    confidence: 97.78926968574524
    },
    {
    description: "Home",
    confidence: 97.521311044693
    },
    {
    description: "Property",
    confidence: 95.95962762832642
    },
    {
    description: "Building",
    confidence: 88.9872133731842
    },
    {
    description: "Real estate",
    confidence: 85.74708104133606
    },
    {
    description: "Residential area",
    confidence: 82.03637599945068
    },
    {
    description: "Architecture",
    confidence: 81.7379355430603
    },
    {
    description: "Cottage",
    confidence: 79.96838688850403
    },
    {
    description: "Tree",
    confidence: 77.83679962158203
    },
    {
    description: "Neighbourhood",
    confidence: 68.86566877365112
    }
    ],
    objects: [
    {
    description: "House",
    confidence: 0.8785818219184875,
    bounding_box: [
    {
    x: 0.290309876203537,
    y: 0.001996608916670084
    },
    {
    x: 0.8195953369140625,
    y: 0.001996608916670084
    },
    {
    x: 0.8195953369140625,
    y: 0.7829211354255676
    },
    {
    x: 0.290309876203537,
    y: 0.7829211354255676
    }
    ]
    },
    {
    description: "Flowerpot",
    confidence: 0.5099483728408813,
    bounding_box: [
    {
    x: 0.8327761292457581,
    y: 0.685657799243927
    },
    {
    x: 0.9945814609527588,
    y: 0.685657799243927
    },
    {
    x: 0.9945814609527588,
    y: 0.9228329658508301
    },
    {
    x: 0.8327761292457581,
    y: 0.9228329658508301
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22360,
    time_in_milliseconds: 746078.0000000001,
    labels: [
    {
    description: "Facial expression",
    confidence: 94.47934031486511
    },
    {
    description: "Collage",
    confidence: 84.03338193893433
    },
    {
    description: "Snapshot",
    confidence: 82.91832208633423
    },
    {
    description: "Photography",
    confidence: 73.7997055053711
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Art",
    confidence: 69.46530342102051
    },
    {
    description: "Smile",
    confidence: 69.44891214370728
    },
    {
    description: "Conversation",
    confidence: 52.54520773887634
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9005511403083801,
    bounding_box: [
    {
    x: 0.728839635848999,
    y: 0.06449490040540695
    },
    {
    x: 0.8802894949913025,
    y: 0.06449490040540695
    },
    {
    x: 0.8802894949913025,
    y: 0.34926310181617737
    },
    {
    x: 0.728839635848999,
    y: 0.34926310181617737
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.899104654788971,
    bounding_box: [
    {
    x: 0.6945762038230896,
    y: 0.37109628319740295
    },
    {
    x: 0.8859717845916748,
    y: 0.37109628319740295
    },
    {
    x: 0.8859717845916748,
    y: 0.6531416773796082
    },
    {
    x: 0.6945762038230896,
    y: 0.6531416773796082
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8842941522598267,
    bounding_box: [
    {
    x: 0.4162348508834839,
    y: 0.05224399268627167
    },
    {
    x: 0.5665951371192932,
    y: 0.05224399268627167
    },
    {
    x: 0.5665951371192932,
    y: 0.3463372588157654
    },
    {
    x: 0.4162348508834839,
    y: 0.3463372588157654
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.866071343421936,
    bounding_box: [
    {
    x: 0.7157805562019348,
    y: 0.6452335715293884
    },
    {
    x: 0.9359459280967712,
    y: 0.6452335715293884
    },
    {
    x: 0.9359459280967712,
    y: 0.9597083926200867
    },
    {
    x: 0.7157805562019348,
    y: 0.9597083926200867
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8622393608093262,
    bounding_box: [
    {
    x: 0.4046225845813751,
    y: 0.6582387685775757
    },
    {
    x: 0.6078822016716003,
    y: 0.6582387685775757
    },
    {
    x: 0.6078822016716003,
    y: 0.9645593166351318
    },
    {
    x: 0.4046225845813751,
    y: 0.9645593166351318
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8612426519393921,
    bounding_box: [
    {
    x: 0.10722222179174423,
    y: 0.6773058772087097
    },
    {
    x: 0.2778676450252533,
    y: 0.6773058772087097
    },
    {
    x: 0.2778676450252533,
    y: 0.9672643542289734
    },
    {
    x: 0.10722222179174423,
    y: 0.9672643542289734
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8566659092903137,
    bounding_box: [
    {
    x: 0.08679819107055664,
    y: 0.34685254096984863
    },
    {
    x: 0.27587786316871643,
    y: 0.34685254096984863
    },
    {
    x: 0.27587786316871643,
    y: 0.6545774340629578
    },
    {
    x: 0.08679819107055664,
    y: 0.6545774340629578
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8422945737838745,
    bounding_box: [
    {
    x: 0.42416325211524963,
    y: 0.3764873445034027
    },
    {
    x: 0.5619195103645325,
    y: 0.3764873445034027
    },
    {
    x: 0.5619195103645325,
    y: 0.6522945761680603
    },
    {
    x: 0.42416325211524963,
    y: 0.6522945761680603
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8257176876068115,
    bounding_box: [
    {
    x: 0.4125765562057495,
    y: 0.05448468402028084
    },
    {
    x: 0.5610584020614624,
    y: 0.05448468402028084
    },
    {
    x: 0.5610584020614624,
    y: 0.34765625
    },
    {
    x: 0.4125765562057495,
    y: 0.34765625
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8073490858078003,
    bounding_box: [
    {
    x: 0.06540199369192123,
    y: 0.0432853065431118
    },
    {
    x: 0.2977629005908966,
    y: 0.0432853065431118
    },
    {
    x: 0.2977629005908966,
    y: 0.350818395614624
    },
    {
    x: 0.06540199369192123,
    y: 0.350818395614624
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22447,
    time_in_milliseconds: 748981,
    labels: [
    {
    description: "Face",
    confidence: 98.83621335029602
    },
    {
    description: "Hair",
    confidence: 98.61084222793579
    },
    {
    description: "Eyebrow",
    confidence: 95.22201418876648
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "Skin",
    confidence: 93.81709694862366
    },
    {
    description: "Forehead",
    confidence: 93.54414343833923
    },
    {
    description: "Cheek",
    confidence: 91.10504984855652
    },
    {
    description: "Chin",
    confidence: 90.26594758033752
    },
    {
    description: "Beauty",
    confidence: 88.88567686080933
    },
    {
    description: "Nose",
    confidence: 88.68343830108643
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.838334858417511,
    bounding_box: [
    {
    x: 0.4129258692264557,
    y: 0.9059441685676575
    },
    {
    x: 0.6063979864120483,
    y: 0.9059441685676575
    },
    {
    x: 0.6063979864120483,
    y: 0.9934027194976807
    },
    {
    x: 0.4129258692264557,
    y: 0.9934027194976807
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8068007826805115,
    bounding_box: [
    {
    x: 0.21671904623508453,
    y: 0.17336149513721466
    },
    {
    x: 0.7301974296569824,
    y: 0.17336149513721466
    },
    {
    x: 0.7301974296569824,
    y: 0.9023795127868652
    },
    {
    x: 0.21671904623508453,
    y: 0.9023795127868652
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5383745431900024,
    bounding_box: [
    {
    x: 0.21722401678562164,
    y: 0.6503263115882874
    },
    {
    x: 0.6989750862121582,
    y: 0.6503263115882874
    },
    {
    x: 0.6989750862121582,
    y: 0.8926672339439392
    },
    {
    x: 0.21722401678562164,
    y: 0.8926672339439392
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5346684455871582,
    bounding_box: [
    {
    x: 0.30710873007774353,
    y: 0
    },
    {
    x: 0.7057693004608154,
    y: 0
    },
    {
    x: 0.7057693004608154,
    y: 0.09951887279748917
    },
    {
    x: 0.30710873007774353,
    y: 0.09951887279748917
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22476,
    time_in_milliseconds: 749949.0000000001,
    labels: [
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Cool",
    confidence: 70.3183650970459
    },
    {
    description: "Muscle",
    confidence: 67.94060468673706
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Screenshot",
    confidence: 52.303582429885864
    },
    {
    description: "Black hair",
    confidence: 51.65854096412659
    },
    {
    description: "Photo caption",
    confidence: 51.30242705345154
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.912487804889679,
    bounding_box: [
    {
    x: 0.31024402379989624,
    y: 0.17940805852413177
    },
    {
    x: 0.6680021286010742,
    y: 0.17940805852413177
    },
    {
    x: 0.6680021286010742,
    y: 0.8969845771789551
    },
    {
    x: 0.31024402379989624,
    y: 0.8969845771789551
    }
    ]
    },
    {
    description: "Couch",
    confidence: 0.8430610299110413,
    bounding_box: [
    {
    x: 0.030252059921622276,
    y: 0.5113426446914673
    },
    {
    x: 0.9147120118141174,
    y: 0.5113426446914673
    },
    {
    x: 0.9147120118141174,
    y: 0.9256569743156433
    },
    {
    x: 0.030252059921622276,
    y: 0.9256569743156433
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.8428831100463867,
    bounding_box: [
    {
    x: 0.2520156800746918,
    y: 0.5088098049163818
    },
    {
    x: 0.7490811944007874,
    y: 0.5088098049163818
    },
    {
    x: 0.7490811944007874,
    y: 0.8936155438423157
    },
    {
    x: 0.2520156800746918,
    y: 0.8936155438423157
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6474334597587585,
    bounding_box: [
    {
    x: 0.561955988407135,
    y: 0.9021751880645752
    },
    {
    x: 0.7550234198570251,
    y: 0.9021751880645752
    },
    {
    x: 0.7550234198570251,
    y: 0.9959292411804199
    },
    {
    x: 0.561955988407135,
    y: 0.9959292411804199
    }
    ]
    },
    {
    description: "Pillow",
    confidence: 0.6292282938957214,
    bounding_box: [
    {
    x: 0.10123340040445328,
    y: 0.6375200152397156
    },
    {
    x: 0.3239307105541229,
    y: 0.6375200152397156
    },
    {
    x: 0.3239307105541229,
    y: 0.8957515358924866
    },
    {
    x: 0.10123340040445328,
    y: 0.8957515358924866
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22563,
    time_in_milliseconds: 752852.0000000001,
    labels: [
    {
    description: "Face",
    confidence: 98.53704571723938
    },
    {
    description: "Hair",
    confidence: 98.36366772651672
    },
    {
    description: "Forehead",
    confidence: 94.42563056945801
    },
    {
    description: "Eyebrow",
    confidence: 93.22017431259155
    },
    {
    description: "Nose",
    confidence: 89.84259366989136
    },
    {
    description: "Skin",
    confidence: 89.55914974212646
    },
    {
    description: "Chin",
    confidence: 89.45538997650146
    },
    {
    description: "Head",
    confidence: 89.01773691177368
    },
    {
    description: "Cheek",
    confidence: 87.71830201148987
    },
    {
    description: "Beauty",
    confidence: 85.96392869949341
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.7345834970474243,
    bounding_box: [
    {
    x: 0.4270187318325043,
    y: 0.9306635856628418
    },
    {
    x: 0.5990315079689026,
    y: 0.9306635856628418
    },
    {
    x: 0.5990315079689026,
    y: 0.9950923323631287
    },
    {
    x: 0.4270187318325043,
    y: 0.9950923323631287
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7008373141288757,
    bounding_box: [
    {
    x: 0.21847783029079437,
    y: 0.19260573387145996
    },
    {
    x: 0.7331526279449463,
    y: 0.19260573387145996
    },
    {
    x: 0.7331526279449463,
    y: 0.9145389199256897
    },
    {
    x: 0.21847783029079437,
    y: 0.9145389199256897
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5404269099235535,
    bounding_box: [
    {
    x: 0.22512789070606232,
    y: 0.6283148527145386
    },
    {
    x: 0.7264277935028076,
    y: 0.6283148527145386
    },
    {
    x: 0.7264277935028076,
    y: 0.8927212357521057
    },
    {
    x: 0.22512789070606232,
    y: 0.8927212357521057
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22650,
    time_in_milliseconds: 755755,
    labels: [
    {
    description: "Facial expression",
    confidence: 94.4792091846466
    },
    {
    description: "Collage",
    confidence: 85.92194318771362
    },
    {
    description: "Photography",
    confidence: 72.45198488235474
    },
    {
    description: "Art",
    confidence: 72.07983136177063
    },
    {
    description: "Hair coloring",
    confidence: 67.12601780891418
    },
    {
    description: "Step cutting",
    confidence: 63.239264488220215
    },
    {
    description: "Smile",
    confidence: 54.042959213256836
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9144185185432434,
    bounding_box: [
    {
    x: 0.7041247487068176,
    y: 0.36724114418029785
    },
    {
    x: 0.9143555760383606,
    y: 0.36724114418029785
    },
    {
    x: 0.9143555760383606,
    y: 0.6566452383995056
    },
    {
    x: 0.7041247487068176,
    y: 0.6566452383995056
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8933855295181274,
    bounding_box: [
    {
    x: 0.08081041276454926,
    y: 0.3458728492259979
    },
    {
    x: 0.26587995886802673,
    y: 0.3458728492259979
    },
    {
    x: 0.26587995886802673,
    y: 0.6614204049110413
    },
    {
    x: 0.08081041276454926,
    y: 0.6614204049110413
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8915081024169922,
    bounding_box: [
    {
    x: 0.7218678593635559,
    y: 0.6574315428733826
    },
    {
    x: 0.9626355767250061,
    y: 0.6574315428733826
    },
    {
    x: 0.9626355767250061,
    y: 0.9873115420341492
    },
    {
    x: 0.7218678593635559,
    y: 0.9873115420341492
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8868083357810974,
    bounding_box: [
    {
    x: 0.7320635914802551,
    y: 0.04831548407673836
    },
    {
    x: 0.8868368268013,
    y: 0.04831548407673836
    },
    {
    x: 0.8868368268013,
    y: 0.344372034072876
    },
    {
    x: 0.7320635914802551,
    y: 0.344372034072876
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8844619989395142,
    bounding_box: [
    {
    x: 0.037469323724508286,
    y: 0.024847732856869698
    },
    {
    x: 0.28719404339790344,
    y: 0.024847732856869698
    },
    {
    x: 0.28719404339790344,
    y: 0.3342795670032501
    },
    {
    x: 0.037469323724508286,
    y: 0.3342795670032501
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.8466850519180298,
    bounding_box: [
    {
    x: 0.4658193290233612,
    y: 0.8162898421287537
    },
    {
    x: 0.5964997410774231,
    y: 0.8162898421287537
    },
    {
    x: 0.5964997410774231,
    y: 0.9823534488677979
    },
    {
    x: 0.4658193290233612,
    y: 0.9823534488677979
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8442392945289612,
    bounding_box: [
    {
    x: 0.43004655838012695,
    y: 0.03563816100358963
    },
    {
    x: 0.5791694521903992,
    y: 0.03563816100358963
    },
    {
    x: 0.5791694521903992,
    y: 0.34633922576904297
    },
    {
    x: 0.43004655838012695,
    y: 0.34633922576904297
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8347899913787842,
    bounding_box: [
    {
    x: 0.35576310753822327,
    y: 0.6675159931182861
    },
    {
    x: 0.6133387088775635,
    y: 0.6675159931182861
    },
    {
    x: 0.6133387088775635,
    y: 0.9882423281669617
    },
    {
    x: 0.35576310753822327,
    y: 0.9882423281669617
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.830447256565094,
    bounding_box: [
    {
    x: 0.4221384525299072,
    y: 0.3675577640533447
    },
    {
    x: 0.5785810351371765,
    y: 0.3675577640533447
    },
    {
    x: 0.5785810351371765,
    y: 0.6616747975349426
    },
    {
    x: 0.4221384525299072,
    y: 0.6616747975349426
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8268203139305115,
    bounding_box: [
    {
    x: 0.07842967659235,
    y: 0.6913716793060303
    },
    {
    x: 0.2580139935016632,
    y: 0.6913716793060303
    },
    {
    x: 0.2580139935016632,
    y: 0.9874105453491211
    },
    {
    x: 0.07842967659235,
    y: 0.9874105453491211
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22708,
    time_in_milliseconds: 757690,
    labels: [
    {
    description: "Face",
    confidence: 98.52043390274048
    },
    {
    description: "Hair",
    confidence: 97.98632860183716
    },
    {
    description: "Facial expression",
    confidence: 94.47641372680664
    },
    {
    description: "Eyebrow",
    confidence: 92.43066310882568
    },
    {
    description: "Skin",
    confidence: 91.37958288192749
    },
    {
    description: "Forehead",
    confidence: 90.5433714389801
    },
    {
    description: "Nose",
    confidence: 89.84259366989136
    },
    {
    description: "Head",
    confidence: 89.47408199310303
    },
    {
    description: "Cheek",
    confidence: 87.71830201148987
    },
    {
    description: "Beauty",
    confidence: 87.0676040649414
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8281829953193665,
    bounding_box: [
    {
    x: 0.2179827243089676,
    y: 0.17821525037288666
    },
    {
    x: 0.7307577133178711,
    y: 0.17821525037288666
    },
    {
    x: 0.7307577133178711,
    y: 0.9082076549530029
    },
    {
    x: 0.2179827243089676,
    y: 0.9082076549530029
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.7982304096221924,
    bounding_box: [
    {
    x: 0.4268395006656647,
    y: 0.9282179474830627
    },
    {
    x: 0.5990988612174988,
    y: 0.9282179474830627
    },
    {
    x: 0.5990988612174988,
    y: 0.9946362972259521
    },
    {
    x: 0.4268395006656647,
    y: 0.9946362972259521
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6050602793693542,
    bounding_box: [
    {
    x: 0.21716679632663727,
    y: 0.6122955679893494
    },
    {
    x: 0.7056176662445068,
    y: 0.6122955679893494
    },
    {
    x: 0.7056176662445068,
    y: 0.8944230079650879
    },
    {
    x: 0.21716679632663727,
    y: 0.8944230079650879
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5371779799461365,
    bounding_box: [
    {
    x: 0.42337122559547424,
    y: 0.9069941639900208
    },
    {
    x: 0.6066746115684509,
    y: 0.9069941639900208
    },
    {
    x: 0.6066746115684509,
    y: 0.993735134601593
    },
    {
    x: 0.42337122559547424,
    y: 0.993735134601593
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5295377373695374,
    bounding_box: [
    {
    x: 0.0016325443284586072,
    y: 0.5249759554862976
    },
    {
    x: 0.09546134620904922,
    y: 0.5249759554862976
    },
    {
    x: 0.09546134620904922,
    y: 0.9031798243522644
    },
    {
    x: 0.0016325443284586072,
    y: 0.9031798243522644
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22737,
    time_in_milliseconds: 758658,
    labels: [
    {
    description: "Face",
    confidence: 98.17479252815247
    },
    {
    description: "Facial expression",
    confidence: 94.46841478347778
    },
    {
    description: "Nose",
    confidence: 90.69914221763611
    },
    {
    description: "Head",
    confidence: 90.14164209365845
    },
    {
    description: "Skin",
    confidence: 89.55914974212646
    },
    {
    description: "Chin",
    confidence: 86.49129867553711
    },
    {
    description: "Forehead",
    confidence: 86.29716038703918
    },
    {
    description: "Cheek",
    confidence: 86.12985014915466
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Smile",
    confidence: 69.44891214370728
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.83115553855896,
    bounding_box: [
    {
    x: 0.22446270287036896,
    y: 0.17902608215808868
    },
    {
    x: 0.7201557159423828,
    y: 0.17902608215808868
    },
    {
    x: 0.7201557159423828,
    y: 0.9075683951377869
    },
    {
    x: 0.22446270287036896,
    y: 0.9075683951377869
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.7705045342445374,
    bounding_box: [
    {
    x: 0.4291304647922516,
    y: 0.9301370978355408
    },
    {
    x: 0.5990278720855713,
    y: 0.9301370978355408
    },
    {
    x: 0.5990278720855713,
    y: 0.9942858219146729
    },
    {
    x: 0.4291304647922516,
    y: 0.9942858219146729
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5690694451332092,
    bounding_box: [
    {
    x: 0.43140164017677307,
    y: 0.9106950759887695
    },
    {
    x: 0.600192129611969,
    y: 0.9106950759887695
    },
    {
    x: 0.600192129611969,
    y: 0.9960658550262451
    },
    {
    x: 0.43140164017677307,
    y: 0.9960658550262451
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5018718838691711,
    bounding_box: [
    {
    x: 0.0015466362237930298,
    y: 0.5255057215690613
    },
    {
    x: 0.09356364607810974,
    y: 0.5255057215690613
    },
    {
    x: 0.09356364607810974,
    y: 0.9039490818977356
    },
    {
    x: 0.0015466362237930298,
    y: 0.9039490818977356
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22824,
    time_in_milliseconds: 761561,
    labels: [
    {
    description: "Hair",
    confidence: 98.18891882896423
    },
    {
    description: "Face",
    confidence: 95.38267254829407
    },
    {
    description: "Photograph",
    confidence: 94.9849009513855
    },
    {
    description: "Beauty",
    confidence: 90.06934762001038
    },
    {
    description: "Eyebrow",
    confidence: 87.63675689697266
    },
    {
    description: "Hairstyle",
    confidence: 86.86584234237671
    },
    {
    description: "Skin",
    confidence: 85.9302818775177
    },
    {
    description: "Black hair",
    confidence: 85.9204888343811
    },
    {
    description: "Long hair",
    confidence: 83.73913168907166
    },
    {
    description: "Snapshot",
    confidence: 83.58544111251831
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8954751491546631,
    bounding_box: [
    {
    x: 0.3351898193359375,
    y: 0.1506873518228531
    },
    {
    x: 0.698612630367279,
    y: 0.1506873518228531
    },
    {
    x: 0.698612630367279,
    y: 0.8905858397483826
    },
    {
    x: 0.3351898193359375,
    y: 0.8905858397483826
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7120569348335266,
    bounding_box: [
    {
    x: 0.38897815346717834,
    y: 0.5292287468910217
    },
    {
    x: 0.6670758128166199,
    y: 0.5292287468910217
    },
    {
    x: 0.6670758128166199,
    y: 0.8936291337013245
    },
    {
    x: 0.38897815346717834,
    y: 0.8936291337013245
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22940,
    time_in_milliseconds: 765431,
    labels: [
    {
    description: "Face",
    confidence: 98.81072640419006
    },
    {
    description: "Hair",
    confidence: 98.76293540000916
    },
    {
    description: "Eyebrow",
    confidence: 95.50839066505432
    },
    {
    description: "Facial expression",
    confidence: 94.43857669830322
    },
    {
    description: "Forehead",
    confidence: 93.15457940101624
    },
    {
    description: "Skin",
    confidence: 92.90940165519714
    },
    {
    description: "Cheek",
    confidence: 91.10504984855652
    },
    {
    description: "Nose",
    confidence: 89.84259366989136
    },
    {
    description: "Beauty",
    confidence: 89.27432894706726
    },
    {
    description: "Head",
    confidence: 89.01773691177368
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8115642070770264,
    bounding_box: [
    {
    x: 0.42609450221061707,
    y: 0.9255903363227844
    },
    {
    x: 0.5993276238441467,
    y: 0.9255903363227844
    },
    {
    x: 0.5993276238441467,
    y: 0.9940146803855896
    },
    {
    x: 0.42609450221061707,
    y: 0.9940146803855896
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.801059901714325,
    bounding_box: [
    {
    x: 0.2042141556739807,
    y: 0.16753727197647095
    },
    {
    x: 0.7247660160064697,
    y: 0.16753727197647095
    },
    {
    x: 0.7247660160064697,
    y: 0.900127649307251
    },
    {
    x: 0.2042141556739807,
    y: 0.900127649307251
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5622639656066895,
    bounding_box: [
    {
    x: 0.3039708733558655,
    y: 0
    },
    {
    x: 0.6649178862571716,
    y: 0
    },
    {
    x: 0.6649178862571716,
    y: 0.10142415016889572
    },
    {
    x: 0.3039708733558655,
    y: 0.10142415016889572
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5491034388542175,
    bounding_box: [
    {
    x: 0.4208774268627167,
    y: 0.908947765827179
    },
    {
    x: 0.6061299443244934,
    y: 0.908947765827179
    },
    {
    x: 0.6061299443244934,
    y: 0.9935240149497986
    },
    {
    x: 0.4208774268627167,
    y: 0.9935240149497986
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5028110146522522,
    bounding_box: [
    {
    x: 0.0017327946843579412,
    y: 0.5227406620979309
    },
    {
    x: 0.09457498788833618,
    y: 0.5227406620979309
    },
    {
    x: 0.09457498788833618,
    y: 0.9046039581298828
    },
    {
    x: 0.0017327946843579412,
    y: 0.9046039581298828
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 22969,
    time_in_milliseconds: 766399,
    labels: [
    {
    description: "Hair",
    confidence: 98.46413731575012
    },
    {
    description: "Face",
    confidence: 96.09515070915222
    },
    {
    description: "Photograph",
    confidence: 94.80249285697937
    },
    {
    description: "Beauty",
    confidence: 89.60622549057007
    },
    {
    description: "Hairstyle",
    confidence: 88.78235816955566
    },
    {
    description: "Black hair",
    confidence: 88.13074827194214
    },
    {
    description: "Long hair",
    confidence: 86.80175542831421
    },
    {
    description: "Eyebrow",
    confidence: 86.39556765556335
    },
    {
    description: "Skin",
    confidence: 85.9302818775177
    },
    {
    description: "Snapshot",
    confidence: 82.91832208633423
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8798040151596069,
    bounding_box: [
    {
    x: 0.33750268816947937,
    y: 0.1513769030570984
    },
    {
    x: 0.7041402459144592,
    y: 0.1513769030570984
    },
    {
    x: 0.7041402459144592,
    y: 0.8913078308105469
    },
    {
    x: 0.33750268816947937,
    y: 0.8913078308105469
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6304351091384888,
    bounding_box: [
    {
    x: 0.39298829436302185,
    y: 0.5389693379402161
    },
    {
    x: 0.6737134456634521,
    y: 0.5389693379402161
    },
    {
    x: 0.6737134456634521,
    y: 0.8932356238365173
    },
    {
    x: 0.39298829436302185,
    y: 0.8932356238365173
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23056,
    time_in_milliseconds: 769301,
    labels: [
    {
    description: "Face",
    confidence: 95.38267254829407
    },
    {
    description: "Forehead",
    confidence: 87.55422234535217
    },
    {
    description: "Nose",
    confidence: 86.9929552078247
    },
    {
    description: "Chin",
    confidence: 82.75078535079956
    },
    {
    description: "Cheek",
    confidence: 79.94794845581055
    },
    {
    description: "Cool",
    confidence: 75.34600496292114
    },
    {
    description: "Facial hair",
    confidence: 75.05148649215698
    },
    {
    description: "Muscle",
    confidence: 72.29806184768677
    },
    {
    description: "Mouth",
    confidence: 67.94861555099487
    },
    {
    description: "Photography",
    confidence: 67.78864860534668
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.904776930809021,
    bounding_box: [
    {
    x: 0.2899393141269684,
    y: 0.15134866535663605
    },
    {
    x: 0.6764204502105713,
    y: 0.15134866535663605
    },
    {
    x: 0.6764204502105713,
    y: 0.9051821827888489
    },
    {
    x: 0.2899393141269684,
    y: 0.9051821827888489
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.8248558640480042,
    bounding_box: [
    {
    x: 0.27383264899253845,
    y: 0.49758651852607727
    },
    {
    x: 0.7051802277565002,
    y: 0.49758651852607727
    },
    {
    x: 0.7051802277565002,
    y: 0.8894340991973877
    },
    {
    x: 0.27383264899253845,
    y: 0.8894340991973877
    }
    ]
    },
    {
    description: "Couch",
    confidence: 0.6500787734985352,
    bounding_box: [
    {
    x: 0.02136395312845707,
    y: 0.5172141194343567
    },
    {
    x: 0.884464681148529,
    y: 0.5172141194343567
    },
    {
    x: 0.884464681148529,
    y: 0.9126003384590149
    },
    {
    x: 0.02136395312845707,
    y: 0.9126003384590149
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.636894702911377,
    bounding_box: [
    {
    x: 0.4762495458126068,
    y: 0.9233327507972717
    },
    {
    x: 0.6526749730110168,
    y: 0.9233327507972717
    },
    {
    x: 0.6526749730110168,
    y: 0.996176540851593
    },
    {
    x: 0.4762495458126068,
    y: 0.996176540851593
    }
    ]
    },
    {
    description: "Pillow",
    confidence: 0.5576076507568359,
    bounding_box: [
    {
    x: 0.10050678998231888,
    y: 0.634705126285553
    },
    {
    x: 0.3077559769153595,
    y: 0.634705126285553
    },
    {
    x: 0.3077559769153595,
    y: 0.8941689133644104
    },
    {
    x: 0.10050678998231888,
    y: 0.8941689133644104
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23201,
    time_in_milliseconds: 774140,
    labels: [
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Photo caption",
    confidence: 57.24363327026367
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8641296625137329,
    bounding_box: [
    {
    x: 0.2482321709394455,
    y: 0.1722377985715866
    },
    {
    x: 0.6655575633049011,
    y: 0.1722377985715866
    },
    {
    x: 0.6655575633049011,
    y: 0.912020742893219
    },
    {
    x: 0.2482321709394455,
    y: 0.912020742893219
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.7536887526512146,
    bounding_box: [
    {
    x: 0.3942907154560089,
    y: 0.5189214944839478
    },
    {
    x: 0.5105351805686951,
    y: 0.5189214944839478
    },
    {
    x: 0.5105351805686951,
    y: 0.8346905708312988
    },
    {
    x: 0.3942907154560089,
    y: 0.8346905708312988
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7096907496452332,
    bounding_box: [
    {
    x: 0.2476213425397873,
    y: 0.477421373128891
    },
    {
    x: 0.6644976139068604,
    y: 0.477421373128891
    },
    {
    x: 0.6644976139068604,
    y: 0.8883233666419983
    },
    {
    x: 0.2476213425397873,
    y: 0.8883233666419983
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5050782561302185,
    bounding_box: [
    {
    x: 0.2308950275182724,
    y: 0
    },
    {
    x: 0.7531693577766418,
    y: 0
    },
    {
    x: 0.7531693577766418,
    y: 0.09727545827627182
    },
    {
    x: 0.2308950275182724,
    y: 0.09727545827627182
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23433,
    time_in_milliseconds: 781881.0000000001,
    labels: [
    {
    description: "Face",
    confidence: 98.9897608757019
    },
    {
    description: "Hair",
    confidence: 98.89399409294128
    },
    {
    description: "Eyebrow",
    confidence: 95.927494764328
    },
    {
    description: "Forehead",
    confidence: 95.43538689613342
    },
    {
    description: "Facial expression",
    confidence: 94.43857669830322
    },
    {
    description: "Skin",
    confidence: 90.59545993804932
    },
    {
    description: "Chin",
    confidence: 90.59295654296875
    },
    {
    description: "Hairstyle",
    confidence: 90.28685688972473
    },
    {
    description: "Head",
    confidence: 89.84001874923706
    },
    {
    description: "Cheek",
    confidence: 89.78834748268127
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8495743870735168,
    bounding_box: [
    {
    x: 0.4215640723705292,
    y: 0.9289101958274841
    },
    {
    x: 0.6024367213249207,
    y: 0.9289101958274841
    },
    {
    x: 0.6024367213249207,
    y: 0.9943775534629822
    },
    {
    x: 0.4215640723705292,
    y: 0.9943775534629822
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7937121391296387,
    bounding_box: [
    {
    x: 0.2259194403886795,
    y: 0.1725781112909317
    },
    {
    x: 0.7162656784057617,
    y: 0.1725781112909317
    },
    {
    x: 0.7162656784057617,
    y: 0.9094293713569641
    },
    {
    x: 0.2259194403886795,
    y: 0.9094293713569641
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5516055822372437,
    bounding_box: [
    {
    x: 0.2786371111869812,
    y: 0
    },
    {
    x: 0.6411870121955872,
    y: 0
    },
    {
    x: 0.6411870121955872,
    y: 0.09553999453783035
    },
    {
    x: 0.2786371111869812,
    y: 0.09553999453783035
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5188080072402954,
    bounding_box: [
    {
    x: 0.23339827358722687,
    y: 0.6543900370597839
    },
    {
    x: 0.7175013422966003,
    y: 0.6543900370597839
    },
    {
    x: 0.7175013422966003,
    y: 0.8924331665039062
    },
    {
    x: 0.23339827358722687,
    y: 0.8924331665039062
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23520,
    time_in_milliseconds: 784784,
    labels: [
    {
    description: "Facial expression",
    confidence: 94.47879791259766
    },
    {
    description: "Collage",
    confidence: 84.20796394348145
    },
    {
    description: "Photography",
    confidence: 70.60428261756897
    },
    {
    description: "Art",
    confidence: 65.5128538608551
    },
    {
    description: "Smile",
    confidence: 54.042959213256836
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8946195244789124,
    bounding_box: [
    {
    x: 0.7228675484657288,
    y: 0.6575025320053101
    },
    {
    x: 0.9628987908363342,
    y: 0.6575025320053101
    },
    {
    x: 0.9628987908363342,
    y: 0.988471508026123
    },
    {
    x: 0.7228675484657288,
    y: 0.988471508026123
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8880695700645447,
    bounding_box: [
    {
    x: 0.42208871245384216,
    y: 0.35333606600761414
    },
    {
    x: 0.5593110918998718,
    y: 0.35333606600761414
    },
    {
    x: 0.5593110918998718,
    y: 0.6542410850524902
    },
    {
    x: 0.42208871245384216,
    y: 0.6542410850524902
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8739265203475952,
    bounding_box: [
    {
    x: 0.07515568286180496,
    y: 0.342033714056015
    },
    {
    x: 0.2682579755783081,
    y: 0.342033714056015
    },
    {
    x: 0.2682579755783081,
    y: 0.6636375784873962
    },
    {
    x: 0.07515568286180496,
    y: 0.6636375784873962
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8661428093910217,
    bounding_box: [
    {
    x: 0.7347471117973328,
    y: 0.04967045411467552
    },
    {
    x: 0.8820226788520813,
    y: 0.04967045411467552
    },
    {
    x: 0.8820226788520813,
    y: 0.341925710439682
    },
    {
    x: 0.7347471117973328,
    y: 0.341925710439682
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8569486737251282,
    bounding_box: [
    {
    x: 0.052582696080207825,
    y: 0.025775665417313576
    },
    {
    x: 0.29004061222076416,
    y: 0.025775665417313576
    },
    {
    x: 0.29004061222076416,
    y: 0.34100285172462463
    },
    {
    x: 0.052582696080207825,
    y: 0.34100285172462463
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8539708256721497,
    bounding_box: [
    {
    x: 0.7044655680656433,
    y: 0.3822273910045624
    },
    {
    x: 0.9144332408905029,
    y: 0.3822273910045624
    },
    {
    x: 0.9144332408905029,
    y: 0.6576346755027771
    },
    {
    x: 0.7044655680656433,
    y: 0.6576346755027771
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8458918333053589,
    bounding_box: [
    {
    x: 0.4263947904109955,
    y: 0.03242205083370209
    },
    {
    x: 0.58013516664505,
    y: 0.03242205083370209
    },
    {
    x: 0.58013516664505,
    y: 0.34474796056747437
    },
    {
    x: 0.4263947904109955,
    y: 0.34474796056747437
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.836197555065155,
    bounding_box: [
    {
    x: 0.46740198135375977,
    y: 0.8166646957397461
    },
    {
    x: 0.5949985980987549,
    y: 0.8166646957397461
    },
    {
    x: 0.5949985980987549,
    y: 0.9833657741546631
    },
    {
    x: 0.46740198135375977,
    y: 0.9833657741546631
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8149353265762329,
    bounding_box: [
    {
    x: 0.07888316363096237,
    y: 0.6827726364135742
    },
    {
    x: 0.25366270542144775,
    y: 0.6827726364135742
    },
    {
    x: 0.25366270542144775,
    y: 0.9855818748474121
    },
    {
    x: 0.07888316363096237,
    y: 0.9855818748474121
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8006907105445862,
    bounding_box: [
    {
    x: 0.3475315570831299,
    y: 0.6622511148452759
    },
    {
    x: 0.6152698993682861,
    y: 0.6622511148452759
    },
    {
    x: 0.6152698993682861,
    y: 0.9877718091011047
    },
    {
    x: 0.3475315570831299,
    y: 0.9877718091011047
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23578,
    time_in_milliseconds: 786719,
    labels: [
    {
    description: "Hair",
    confidence: 97.88822531700134
    },
    {
    description: "Eyebrow",
    confidence: 87.63675689697266
    },
    {
    description: "Beauty",
    confidence: 85.96392869949341
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Hairstyle",
    confidence: 83.63670110702515
    },
    {
    description: "Long hair",
    confidence: 78.18315625190735
    },
    {
    description: "Black hair",
    confidence: 75.74896216392517
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8537556529045105,
    bounding_box: [
    {
    x: 0.28427937626838684,
    y: 0.1254488080739975
    },
    {
    x: 0.654215395450592,
    y: 0.1254488080739975
    },
    {
    x: 0.654215395450592,
    y: 0.8835840821266174
    },
    {
    x: 0.28427937626838684,
    y: 0.8835840821266174
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23636,
    time_in_milliseconds: 788655.0000000001,
    labels: [
    {
    description: "Room",
    confidence: 76.46538615226746
    },
    {
    description: "Photography",
    confidence: 67.78864860534668
    }
    ],
    objects: [
    {
    description: "Lighting",
    confidence: 0.7123793959617615,
    bounding_box: [
    {
    x: 0.3552232086658478,
    y: 0.3991053104400635
    },
    {
    x: 0.43932998180389404,
    y: 0.3991053104400635
    },
    {
    x: 0.43932998180389404,
    y: 0.8956255316734314
    },
    {
    x: 0.3552232086658478,
    y: 0.8956255316734314
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.6853833794593811,
    bounding_box: [
    {
    x: 0.43722736835479736,
    y: 0.6299230456352234
    },
    {
    x: 0.6304559111595154,
    y: 0.6299230456352234
    },
    {
    x: 0.6304559111595154,
    y: 0.8971734046936035
    },
    {
    x: 0.43722736835479736,
    y: 0.8971734046936035
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.5525731444358826,
    bounding_box: [
    {
    x: 0.7545060515403748,
    y: 0.5168485045433044
    },
    {
    x: 0.887470006942749,
    y: 0.5168485045433044
    },
    {
    x: 0.887470006942749,
    y: 0.8126652836799622
    },
    {
    x: 0.7545060515403748,
    y: 0.8126652836799622
    }
    ]
    },
    {
    description: "Door",
    confidence: 0.542230486869812,
    bounding_box: [
    {
    x: 0.10105910152196884,
    y: 0.2647181749343872
    },
    {
    x: 0.256349116563797,
    y: 0.2647181749343872
    },
    {
    x: 0.256349116563797,
    y: 0.8947877883911133
    },
    {
    x: 0.10105910152196884,
    y: 0.8947877883911133
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23665,
    time_in_milliseconds: 789622.0000000001,
    labels: [
    {
    description: "Hair",
    confidence: 98.47051501274109
    },
    {
    description: "Hairstyle",
    confidence: 88.15163969993591
    },
    {
    description: "Eyebrow",
    confidence: 87.63675689697266
    },
    {
    description: "Beauty",
    confidence: 87.60483860969543
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Long hair",
    confidence: 83.2375168800354
    },
    {
    description: "Black hair",
    confidence: 81.19335770606995
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Smile",
    confidence: 69.44891214370728
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7658722996711731,
    bounding_box: [
    {
    x: 0.2773910164833069,
    y: 0.12986330687999725
    },
    {
    x: 0.6536874175071716,
    y: 0.12986330687999725
    },
    {
    x: 0.6536874175071716,
    y: 0.8999683260917664
    },
    {
    x: 0.2773910164833069,
    y: 0.8999683260917664
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23723,
    time_in_milliseconds: 791557,
    labels: [
    {
    description: "Facial expression",
    confidence: 94.47848796844482
    },
    {
    description: "Collage",
    confidence: 84.20796394348145
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Photography",
    confidence: 76.4001190662384
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Art",
    confidence: 69.46530342102051
    },
    {
    description: "Smile",
    confidence: 54.042959213256836
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9027262330055237,
    bounding_box: [
    {
    x: 0.7228748798370361,
    y: 0.6562467813491821
    },
    {
    x: 0.9632337689399719,
    y: 0.6562467813491821
    },
    {
    x: 0.9632337689399719,
    y: 0.9885203242301941
    },
    {
    x: 0.7228748798370361,
    y: 0.9885203242301941
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.896598219871521,
    bounding_box: [
    {
    x: 0.7107282280921936,
    y: 0.3741987645626068
    },
    {
    x: 0.9093704223632812,
    y: 0.3741987645626068
    },
    {
    x: 0.9093704223632812,
    y: 0.6548901796340942
    },
    {
    x: 0.7107282280921936,
    y: 0.6548901796340942
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8708645105361938,
    bounding_box: [
    {
    x: 0.736927330493927,
    y: 0.04381782189011574
    },
    {
    x: 0.8955292701721191,
    y: 0.04381782189011574
    },
    {
    x: 0.8955292701721191,
    y: 0.33705034852027893
    },
    {
    x: 0.736927330493927,
    y: 0.33705034852027893
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8677677512168884,
    bounding_box: [
    {
    x: 0.05480600893497467,
    y: 0.02614385075867176
    },
    {
    x: 0.291708379983902,
    y: 0.02614385075867176
    },
    {
    x: 0.291708379983902,
    y: 0.3407162129878998
    },
    {
    x: 0.05480600893497467,
    y: 0.3407162129878998
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8632735013961792,
    bounding_box: [
    {
    x: 0.07803956419229507,
    y: 0.3433206379413605
    },
    {
    x: 0.26463791728019714,
    y: 0.3433206379413605
    },
    {
    x: 0.26463791728019714,
    y: 0.6663275957107544
    },
    {
    x: 0.07803956419229507,
    y: 0.6663275957107544
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8441749215126038,
    bounding_box: [
    {
    x: 0.4206710755825043,
    y: 0.3550513982772827
    },
    {
    x: 0.5608431696891785,
    y: 0.3550513982772827
    },
    {
    x: 0.5608431696891785,
    y: 0.6566776633262634
    },
    {
    x: 0.4206710755825043,
    y: 0.6566776633262634
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8373751640319824,
    bounding_box: [
    {
    x: 0.4109569787979126,
    y: 0.029027370736002922
    },
    {
    x: 0.5627425909042358,
    y: 0.029027370736002922
    },
    {
    x: 0.5627425909042358,
    y: 0.3383486568927765
    },
    {
    x: 0.4109569787979126,
    y: 0.3383486568927765
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8114619851112366,
    bounding_box: [
    {
    x: 0.3633407652378082,
    y: 0.6624657511711121
    },
    {
    x: 0.6090478897094727,
    y: 0.6624657511711121
    },
    {
    x: 0.6090478897094727,
    y: 0.9885354042053223
    },
    {
    x: 0.3633407652378082,
    y: 0.9885354042053223
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7965671420097351,
    bounding_box: [
    {
    x: 0.46180257201194763,
    y: 0.8209586143493652
    },
    {
    x: 0.589763343334198,
    y: 0.8209586143493652
    },
    {
    x: 0.589763343334198,
    y: 0.981403648853302
    },
    {
    x: 0.46180257201194763,
    y: 0.981403648853302
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7908226847648621,
    bounding_box: [
    {
    x: 0.04976882040500641,
    y: 0.03112996369600296
    },
    {
    x: 0.2939525246620178,
    y: 0.03112996369600296
    },
    {
    x: 0.2939525246620178,
    y: 0.33795416355133057
    },
    {
    x: 0.04976882040500641,
    y: 0.33795416355133057
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23781,
    time_in_milliseconds: 793492.0000000001,
    labels: [
    {
    description: "Hair",
    confidence: 98.40220808982849
    },
    {
    description: "Hairstyle",
    confidence: 87.57384419441223
    },
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Long hair",
    confidence: 80.42210340499878
    },
    {
    description: "Black hair",
    confidence: 75.055992603302
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Lace wig",
    confidence: 53.56379151344299
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.815599262714386,
    bounding_box: [
    {
    x: 0.28719446063041687,
    y: 0.11773812770843506
    },
    {
    x: 0.654788613319397,
    y: 0.11773812770843506
    },
    {
    x: 0.654788613319397,
    y: 0.885911762714386
    },
    {
    x: 0.28719446063041687,
    y: 0.885911762714386
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23839,
    time_in_milliseconds: 795427,
    labels: [
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Muscle",
    confidence: 82.23339319229126
    },
    {
    description: "Cheek",
    confidence: 79.94794845581055
    },
    {
    description: "Cool",
    confidence: 70.3183650970459
    },
    {
    description: "Photography",
    confidence: 67.78864860534668
    },
    {
    description: "Jaw",
    confidence: 57.406723499298096
    },
    {
    description: "Black hair",
    confidence: 56.975001096725464
    },
    {
    description: "Screenshot",
    confidence: 55.96149563789368
    },
    {
    description: "Photo caption",
    confidence: 53.15094590187073
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8739650249481201,
    bounding_box: [
    {
    x: 0.3133067190647125,
    y: 0.13454283773899078
    },
    {
    x: 0.6572151184082031,
    y: 0.13454283773899078
    },
    {
    x: 0.6572151184082031,
    y: 0.9088129997253418
    },
    {
    x: 0.3133067190647125,
    y: 0.9088129997253418
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.8385037183761597,
    bounding_box: [
    {
    x: 0.2448945790529251,
    y: 0.49029541015625
    },
    {
    x: 0.7303311228752136,
    y: 0.49029541015625
    },
    {
    x: 0.7303311228752136,
    y: 0.8931107521057129
    },
    {
    x: 0.2448945790529251,
    y: 0.8931107521057129
    }
    ]
    },
    {
    description: "Couch",
    confidence: 0.6812848448753357,
    bounding_box: [
    {
    x: 0.02951057441532612,
    y: 0.4958394765853882
    },
    {
    x: 0.9188342690467834,
    y: 0.4958394765853882
    },
    {
    x: 0.9188342690467834,
    y: 0.920176088809967
    },
    {
    x: 0.02951057441532612,
    y: 0.920176088809967
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6774390339851379,
    bounding_box: [
    {
    x: 0.4783399999141693,
    y: 0.9231894612312317
    },
    {
    x: 0.660496711730957,
    y: 0.9231894612312317
    },
    {
    x: 0.660496711730957,
    y: 0.9964422583580017
    },
    {
    x: 0.4783399999141693,
    y: 0.9964422583580017
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6361826658248901,
    bounding_box: [
    {
    x: 0.28176185488700867,
    y: 0.0004309080250095576
    },
    {
    x: 0.6391870379447937,
    y: 0.0004309080250095576
    },
    {
    x: 0.6391870379447937,
    y: 0.09439494460821152
    },
    {
    x: 0.28176185488700867,
    y: 0.09439494460821152
    }
    ]
    },
    {
    description: "Pillow",
    confidence: 0.5582742094993591,
    bounding_box: [
    {
    x: 0.10106894373893738,
    y: 0.5976559519767761
    },
    {
    x: 0.27039051055908203,
    y: 0.5976559519767761
    },
    {
    x: 0.27039051055908203,
    y: 0.8180024027824402
    },
    {
    x: 0.10106894373893738,
    y: 0.8180024027824402
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23897,
    time_in_milliseconds: 797363,
    labels: [
    {
    description: "Hair",
    confidence: 98.11214804649353
    },
    {
    description: "Hairstyle",
    confidence: 86.59101724624634
    },
    {
    description: "Eyebrow",
    confidence: 86.39556765556335
    },
    {
    description: "Beauty",
    confidence: 86.38556003570557
    },
    {
    description: "Black hair",
    confidence: 83.56799483299255
    },
    {
    description: "Long hair",
    confidence: 83.40937495231628
    },
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Smile",
    confidence: 81.12622499465942
    },
    {
    description: "Human",
    confidence: 74.6764600276947
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8446218967437744,
    bounding_box: [
    {
    x: 0.2845940887928009,
    y: 0.11058457940816879
    },
    {
    x: 0.6866137385368347,
    y: 0.11058457940816879
    },
    {
    x: 0.6866137385368347,
    y: 0.8917801380157471
    },
    {
    x: 0.2845940887928009,
    y: 0.8917801380157471
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6670584678649902,
    bounding_box: [
    {
    x: 0.2815782129764557,
    y: 0.45884212851524353
    },
    {
    x: 0.6775724291801453,
    y: 0.45884212851524353
    },
    {
    x: 0.6775724291801453,
    y: 0.8884005546569824
    },
    {
    x: 0.2815782129764557,
    y: 0.8884005546569824
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5511821508407593,
    bounding_box: [
    {
    x: 0.3796814978122711,
    y: 0.926325798034668
    },
    {
    x: 0.5657787919044495,
    y: 0.926325798034668
    },
    {
    x: 0.5657787919044495,
    y: 0.9973958134651184
    },
    {
    x: 0.3796814978122711,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23926,
    time_in_milliseconds: 798331,
    labels: [
    {
    description: "Sitting",
    confidence: 72.03651666641235
    },
    {
    description: "Muscle",
    confidence: 67.94060468673706
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Black hair",
    confidence: 51.65854096412659
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8697153925895691,
    bounding_box: [
    {
    x: 0.1374860256910324,
    y: 0.1654445379972458
    },
    {
    x: 0.6051010489463806,
    y: 0.1654445379972458
    },
    {
    x: 0.6051010489463806,
    y: 0.9108602404594421
    },
    {
    x: 0.1374860256910324,
    y: 0.9108602404594421
    }
    ]
    },
    {
    description: "Couch",
    confidence: 0.8452277183532715,
    bounding_box: [
    {
    x: 0.06527897715568542,
    y: 0.492226243019104
    },
    {
    x: 0.9034183025360107,
    y: 0.492226243019104
    },
    {
    x: 0.9034183025360107,
    y: 0.9127313494682312
    },
    {
    x: 0.06527897715568542,
    y: 0.9127313494682312
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.8057544231414795,
    bounding_box: [
    {
    x: 0.24936909973621368,
    y: 0.409191757440567
    },
    {
    x: 0.5461108684539795,
    y: 0.409191757440567
    },
    {
    x: 0.5461108684539795,
    y: 0.8682406544685364
    },
    {
    x: 0.24936909973621368,
    y: 0.8682406544685364
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7892754673957825,
    bounding_box: [
    {
    x: 0.5536929965019226,
    y: 0.8969326615333557
    },
    {
    x: 0.7627233862876892,
    y: 0.8969326615333557
    },
    {
    x: 0.7627233862876892,
    y: 0.9953969120979309
    },
    {
    x: 0.5536929965019226,
    y: 0.9953969120979309
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7040679454803467,
    bounding_box: [
    {
    x: 0.2673308253288269,
    y: 0.41544684767723083
    },
    {
    x: 0.5173973441123962,
    y: 0.41544684767723083
    },
    {
    x: 0.5173973441123962,
    y: 0.8867919445037842
    },
    {
    x: 0.2673308253288269,
    y: 0.8867919445037842
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5795607566833496,
    bounding_box: [
    {
    x: 0.22193722426891327,
    y: 0.0011339420452713966
    },
    {
    x: 0.40172073245048523,
    y: 0.0011339420452713966
    },
    {
    x: 0.40172073245048523,
    y: 0.09791745990514755
    },
    {
    x: 0.22193722426891327,
    y: 0.09791745990514755
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 23955,
    time_in_milliseconds: 799298,
    labels: [
    {
    description: "Shoulder",
    confidence: 80.12816905975342
    },
    {
    description: "Photography",
    confidence: 70.60428261756897
    },
    {
    description: "Muscle",
    confidence: 67.94060468673706
    },
    {
    description: "Hand",
    confidence: 66.00293517112732
    },
    {
    description: "Neck",
    confidence: 59.907013177871704
    },
    {
    description: "Photo shoot",
    confidence: 59.817856550216675
    },
    {
    description: "Jaw",
    confidence: 57.406723499298096
    },
    {
    description: "Model",
    confidence: 55.10011911392212
    },
    {
    description: "Flesh",
    confidence: 53.55120301246643
    },
    {
    description: "Black hair",
    confidence: 51.65854096412659
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8436757326126099,
    bounding_box: [
    {
    x: 0.39086297154426575,
    y: 0.05735442414879799
    },
    {
    x: 0.6142439246177673,
    y: 0.05735442414879799
    },
    {
    x: 0.6142439246177673,
    y: 0.5042791962623596
    },
    {
    x: 0.39086297154426575,
    y: 0.5042791962623596
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8261992931365967,
    bounding_box: [
    {
    x: 0.30755314230918884,
    y: 0.5439230799674988
    },
    {
    x: 0.63411945104599,
    y: 0.5439230799674988
    },
    {
    x: 0.63411945104599,
    y: 0.9681767821311951
    },
    {
    x: 0.30755314230918884,
    y: 0.9681767821311951
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8166266083717346,
    bounding_box: [
    {
    x: 0.8016541600227356,
    y: 0.5494956374168396
    },
    {
    x: 0.9939168095588684,
    y: 0.5494956374168396
    },
    {
    x: 0.9939168095588684,
    y: 0.9690433144569397
    },
    {
    x: 0.8016541600227356,
    y: 0.9690433144569397
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8114282488822937,
    bounding_box: [
    {
    x: 0.791945219039917,
    y: 0.09132051467895508
    },
    {
    x: 0.9954044818878174,
    y: 0.09132051467895508
    },
    {
    x: 0.9954044818878174,
    y: 0.5023952722549438
    },
    {
    x: 0.791945219039917,
    y: 0.5023952722549438
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7812352776527405,
    bounding_box: [
    {
    x: 0.38607630133628845,
    y: 0.0568358488380909
    },
    {
    x: 0.6063339114189148,
    y: 0.0568358488380909
    },
    {
    x: 0.6063339114189148,
    y: 0.5017483830451965
    },
    {
    x: 0.38607630133628845,
    y: 0.5017483830451965
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.7591841816902161,
    bounding_box: [
    {
    x: 0.0020834757015109062,
    y: 0.18316899240016937
    },
    {
    x: 0.10151546448469162,
    y: 0.18316899240016937
    },
    {
    x: 0.10151546448469162,
    y: 0.272924542427063
    },
    {
    x: 0.0020834757015109062,
    y: 0.272924542427063
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7458382844924927,
    bounding_box: [
    {
    x: 0.0034495529253035784,
    y: 0.5145387053489685
    },
    {
    x: 0.17751359939575195,
    y: 0.5145387053489685
    },
    {
    x: 0.17751359939575195,
    y: 0.9776827692985535
    },
    {
    x: 0.0034495529253035784,
    y: 0.9776827692985535
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7387640476226807,
    bounding_box: [
    {
    x: 0.0013905145460739732,
    y: 0.057466503232717514
    },
    {
    x: 0.20681096613407135,
    y: 0.057466503232717514
    },
    {
    x: 0.20681096613407135,
    y: 0.5042338371276855
    },
    {
    x: 0.0013905145460739732,
    y: 0.5042338371276855
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7300300002098083,
    bounding_box: [
    {
    x: 0.7972095012664795,
    y: 0.2549375295639038
    },
    {
    x: 0.993174135684967,
    y: 0.2549375295639038
    },
    {
    x: 0.993174135684967,
    y: 0.5027580261230469
    },
    {
    x: 0.7972095012664795,
    y: 0.5027580261230469
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6889955997467041,
    bounding_box: [
    {
    x: 0.38986489176750183,
    y: 0.659968912601471
    },
    {
    x: 0.5450834035873413,
    y: 0.659968912601471
    },
    {
    x: 0.5450834035873413,
    y: 0.9598417282104492
    },
    {
    x: 0.38986489176750183,
    y: 0.9598417282104492
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24100,
    time_in_milliseconds: 804137,
    labels: [
    {
    description: "Skin",
    confidence: 89.55914974212646
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Snapshot",
    confidence: 82.91832208633423
    },
    {
    description: "Neck",
    confidence: 75.38272738456726
    },
    {
    description: "Shoulder",
    confidence: 75.07902979850769
    },
    {
    description: "Mouth",
    confidence: 67.94861555099487
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Jaw",
    confidence: 63.020527362823486
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Ear",
    confidence: 58.22066068649292
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8644385933876038,
    bounding_box: [
    {
    x: 0.4551330804824829,
    y: 0.5257487297058105
    },
    {
    x: 0.6688814759254456,
    y: 0.5257487297058105
    },
    {
    x: 0.6688814759254456,
    y: 0.9722283482551575
    },
    {
    x: 0.4551330804824829,
    y: 0.9722283482551575
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8598582744598389,
    bounding_box: [
    {
    x: 0.27586618065834045,
    y: 0.2034999579191208
    },
    {
    x: 0.5917338132858276,
    y: 0.2034999579191208
    },
    {
    x: 0.5917338132858276,
    y: 0.5036705732345581
    },
    {
    x: 0.27586618065834045,
    y: 0.5036705732345581
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7821308374404907,
    bounding_box: [
    {
    x: 0.7976695895195007,
    y: 0.5693803429603577
    },
    {
    x: 0.9944959282875061,
    y: 0.5693803429603577
    },
    {
    x: 0.9944959282875061,
    y: 0.966185986995697
    },
    {
    x: 0.7976695895195007,
    y: 0.966185986995697
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7810665369033813,
    bounding_box: [
    {
    x: 0.8278397917747498,
    y: 0.09381020814180374
    },
    {
    x: 0.9961032867431641,
    y: 0.09381020814180374
    },
    {
    x: 0.9961032867431641,
    y: 0.51120525598526
    },
    {
    x: 0.8278397917747498,
    y: 0.51120525598526
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7643608450889587,
    bounding_box: [
    {
    x: 0,
    y: 0.05296871066093445
    },
    {
    x: 0.20642752945423126,
    y: 0.05296871066093445
    },
    {
    x: 0.20642752945423126,
    y: 0.5121971964836121
    },
    {
    x: 0,
    y: 0.5121971964836121
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7554596066474915,
    bounding_box: [
    {
    x: 0.0022730042692273855,
    y: 0.5034429430961609
    },
    {
    x: 0.17853379249572754,
    y: 0.5034429430961609
    },
    {
    x: 0.17853379249572754,
    y: 0.96958327293396
    },
    {
    x: 0.0022730042692273855,
    y: 0.96958327293396
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7467410564422607,
    bounding_box: [
    {
    x: 0.8341424465179443,
    y: 0.2674524486064911
    },
    {
    x: 0.9907850623130798,
    y: 0.2674524486064911
    },
    {
    x: 0.9907850623130798,
    y: 0.5049408674240112
    },
    {
    x: 0.8341424465179443,
    y: 0.5049408674240112
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.7114132046699524,
    bounding_box: [
    {
    x: 0.0033865959849208593,
    y: 0.17455311119556427
    },
    {
    x: 0.09445032477378845,
    y: 0.17455311119556427
    },
    {
    x: 0.09445032477378845,
    y: 0.26857903599739075
    },
    {
    x: 0.0033865959849208593,
    y: 0.26857903599739075
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6926392912864685,
    bounding_box: [
    {
    x: 0.4603544771671295,
    y: 0.6033841967582703
    },
    {
    x: 0.6565855145454407,
    y: 0.6033841967582703
    },
    {
    x: 0.6565855145454407,
    y: 0.9655077457427979
    },
    {
    x: 0.4603544771671295,
    y: 0.9655077457427979
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5942789912223816,
    bounding_box: [
    {
    x: 0.7869251370429993,
    y: 0.7953900694847107
    },
    {
    x: 0.9919552803039551,
    y: 0.7953900694847107
    },
    {
    x: 0.9919552803039551,
    y: 0.9672511219978333
    },
    {
    x: 0.7869251370429993,
    y: 0.9672511219978333
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24129,
    time_in_milliseconds: 805105,
    labels: [
    {
    description: "Hair",
    confidence: 98.66788387298584
    },
    {
    description: "Face",
    confidence: 98.17479252815247
    },
    {
    description: "Facial expression",
    confidence: 94.43857669830322
    },
    {
    description: "Forehead",
    confidence: 92.69290566444397
    },
    {
    description: "Eyebrow",
    confidence: 92.6535427570343
    },
    {
    description: "Chin",
    confidence: 88.93871307373047
    },
    {
    description: "Hairstyle",
    confidence: 88.48372101783752
    },
    {
    description: "Head",
    confidence: 87.62359619140625
    },
    {
    description: "Beauty",
    confidence: 87.0676040649414
    },
    {
    description: "Nose",
    confidence: 86.9929552078247
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8212618827819824,
    bounding_box: [
    {
    x: 0.4257980287075043,
    y: 0.9310924410820007
    },
    {
    x: 0.5981157422065735,
    y: 0.9310924410820007
    },
    {
    x: 0.5981157422065735,
    y: 0.9946407675743103
    },
    {
    x: 0.4257980287075043,
    y: 0.9946407675743103
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7966510057449341,
    bounding_box: [
    {
    x: 0.27431562542915344,
    y: 0.24296168982982635
    },
    {
    x: 0.7250449657440186,
    y: 0.24296168982982635
    },
    {
    x: 0.7250449657440186,
    y: 0.905590832233429
    },
    {
    x: 0.27431562542915344,
    y: 0.905590832233429
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6758632063865662,
    bounding_box: [
    {
    x: 0.27742502093315125,
    y: 0.6494849324226379
    },
    {
    x: 0.7299378514289856,
    y: 0.6494849324226379
    },
    {
    x: 0.7299378514289856,
    y: 0.8960890173912048
    },
    {
    x: 0.27742502093315125,
    y: 0.8960890173912048
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5340878963470459,
    bounding_box: [
    {
    x: 0.2784673869609833,
    y: 0
    },
    {
    x: 0.6387538313865662,
    y: 0
    },
    {
    x: 0.6387538313865662,
    y: 0.09552409499883652
    },
    {
    x: 0.2784673869609833,
    y: 0.09552409499883652
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24158,
    time_in_milliseconds: 806072,
    labels: [
    {
    description: "Font",
    confidence: 86.16248369216919
    },
    {
    description: "Sky",
    confidence: 82.20894932746887
    },
    {
    description: "Architecture",
    confidence: 65.45118689537048
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "City",
    confidence: 59.57461595535278
    },
    {
    description: "Graphics",
    confidence: 57.50575661659241
    },
    {
    description: "Mountain",
    confidence: 57.033783197402954
    },
    {
    description: "Screenshot",
    confidence: 55.96149563789368
    },
    {
    description: "Advertising",
    confidence: 54.89712953567505
    },
    {
    description: "Sport venue",
    confidence: 54.18516397476196
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 24274,
    time_in_milliseconds: 809942,
    labels: [
    {
    description: "Library",
    confidence: 80.2349865436554
    },
    {
    description: "Glasses",
    confidence: 79.34867739677429
    },
    {
    description: "Bookcase",
    confidence: 64.26844000816345
    },
    {
    description: "Book",
    confidence: 59.870845079422
    },
    {
    description: "Vision care",
    confidence: 51.88118815422058
    },
    {
    description: "Author",
    confidence: 51.0759711265564
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9281864166259766,
    bounding_box: [
    {
    x: 0.4536050856113434,
    y: 0.319778174161911
    },
    {
    x: 0.5929804444313049,
    y: 0.319778174161911
    },
    {
    x: 0.5929804444313049,
    y: 0.40107259154319763
    },
    {
    x: 0.4536050856113434,
    y: 0.40107259154319763
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8517013788223267,
    bounding_box: [
    {
    x: 0.6274083256721497,
    y: 0.011973828077316284
    },
    {
    x: 0.979996383190155,
    y: 0.011973828077316284
    },
    {
    x: 0.979996383190155,
    y: 0.9874024391174316
    },
    {
    x: 0.6274083256721497,
    y: 0.9874024391174316
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8246462345123291,
    bounding_box: [
    {
    x: 0.31779298186302185,
    y: 0.1784072071313858
    },
    {
    x: 0.8214852809906006,
    y: 0.1784072071313858
    },
    {
    x: 0.8214852809906006,
    y: 0.9973958134651184
    },
    {
    x: 0.31779298186302185,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24361,
    time_in_milliseconds: 812846,
    labels: [
    {
    description: "Media",
    confidence: 53.86474132537842
    },
    {
    description: "Library",
    confidence: 52.66340374946594
    },
    {
    description: "Author",
    confidence: 51.57938599586487
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8888575434684753,
    bounding_box: [
    {
    x: 0.4055696725845337,
    y: 0.36840495467185974
    },
    {
    x: 0.5440483689308167,
    y: 0.36840495467185974
    },
    {
    x: 0.5440483689308167,
    y: 0.4580289423465729
    },
    {
    x: 0.4055696725845337,
    y: 0.4580289423465729
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8690406084060669,
    bounding_box: [
    {
    x: 0.627093493938446,
    y: 0.005021603778004646
    },
    {
    x: 0.9680101871490479,
    y: 0.005021603778004646
    },
    {
    x: 0.9680101871490479,
    y: 0.9680089354515076
    },
    {
    x: 0.627093493938446,
    y: 0.9680089354515076
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8473851680755615,
    bounding_box: [
    {
    x: 0.317106157541275,
    y: 0.22325249016284943
    },
    {
    x: 0.8116760849952698,
    y: 0.22325249016284943
    },
    {
    x: 0.8116760849952698,
    y: 0.994267463684082
    },
    {
    x: 0.317106157541275,
    y: 0.994267463684082
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8290896415710449,
    bounding_box: [
    {
    x: 0.07895010709762573,
    y: 0.7147124409675598
    },
    {
    x: 0.19126681983470917,
    y: 0.7147124409675598
    },
    {
    x: 0.19126681983470917,
    y: 0.9336745738983154
    },
    {
    x: 0.07895010709762573,
    y: 0.9336745738983154
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24593,
    time_in_milliseconds: 820587,
    labels: [
    {
    description: "Glasses",
    confidence: 93.1408941745758
    },
    {
    description: "Snapshot",
    confidence: 83.58544111251831
    },
    {
    description: "Community",
    confidence: 83.16273093223572
    },
    {
    description: "Organ",
    confidence: 78.70044112205505
    },
    {
    description: "Eye",
    confidence: 75.86227655410767
    },
    {
    description: "Houseplant",
    confidence: 75.45304298400879
    },
    {
    description: "Window",
    confidence: 73.62905740737915
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Eyewear",
    confidence: 68.63138675689697
    },
    {
    description: "Vision care",
    confidence: 68.09117197990417
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9009849429130554,
    bounding_box: [
    {
    x: 0.39411064982414246,
    y: 0.39672455191612244
    },
    {
    x: 0.5249311327934265,
    y: 0.39672455191612244
    },
    {
    x: 0.5249311327934265,
    y: 0.4863494634628296
    },
    {
    x: 0.39411064982414246,
    y: 0.4863494634628296
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.8997756242752075,
    bounding_box: [
    {
    x: 0.5126828551292419,
    y: 0.3111458122730255
    },
    {
    x: 0.6570122838020325,
    y: 0.3111458122730255
    },
    {
    x: 0.6570122838020325,
    y: 0.4140598773956299
    },
    {
    x: 0.5126828551292419,
    y: 0.4140598773956299
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8531407713890076,
    bounding_box: [
    {
    x: 0.3175097107887268,
    y: 0.1969151645898819
    },
    {
    x: 0.7885317802429199,
    y: 0.1969151645898819
    },
    {
    x: 0.7885317802429199,
    y: 0.9926002621650696
    },
    {
    x: 0.3175097107887268,
    y: 0.9926002621650696
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24622,
    time_in_milliseconds: 821554.0000000001,
    labels: [
    {
    description: "Hair",
    confidence: 98.2119619846344
    },
    {
    description: "Facial hair",
    confidence: 94.11326050758362
    },
    {
    description: "Eyewear",
    confidence: 94.03190016746521
    },
    {
    description: "Glasses",
    confidence: 91.87448620796204
    },
    {
    description: "Beard",
    confidence: 91.71856641769409
    },
    {
    description: "Hairstyle",
    confidence: 85.60138940811157
    },
    {
    description: "Fun",
    confidence: 80.11429905891418
    },
    {
    description: "Cool",
    confidence: 78.80233526229858
    },
    {
    description: "Arm",
    confidence: 75.60437321662903
    },
    {
    description: "Shoulder",
    confidence: 75.07902979850769
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9055806398391724,
    bounding_box: [
    {
    x: 0.4984310567378998,
    y: 0.3039620518684387
    },
    {
    x: 0.6510883569717407,
    y: 0.3039620518684387
    },
    {
    x: 0.6510883569717407,
    y: 0.4112764596939087
    },
    {
    x: 0.4984310567378998,
    y: 0.4112764596939087
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8344644904136658,
    bounding_box: [
    {
    x: 0.10916394740343094,
    y: 0.004369502887129784
    },
    {
    x: 0.9973958134651184,
    y: 0.004369502887129784
    },
    {
    x: 0.9973958134651184,
    y: 0.9940688014030457
    },
    {
    x: 0.10916394740343094,
    y: 0.9940688014030457
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7108199000358582,
    bounding_box: [
    {
    x: 0.13907116651535034,
    y: 0.020616911351680756
    },
    {
    x: 0.9957451820373535,
    y: 0.020616911351680756
    },
    {
    x: 0.9957451820373535,
    y: 0.9961723685264587
    },
    {
    x: 0.13907116651535034,
    y: 0.9961723685264587
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24651,
    time_in_milliseconds: 822522,
    labels: [
    {
    description: "Hair",
    confidence: 98.6552894115448
    },
    {
    description: "Facial hair",
    confidence: 96.85657620429993
    },
    {
    description: "Beard",
    confidence: 95.28738856315613
    },
    {
    description: "Eyewear",
    confidence: 95.2597975730896
    },
    {
    description: "Glasses",
    confidence: 88.84918093681335
    },
    {
    description: "Hairstyle",
    confidence: 88.78235816955566
    },
    {
    description: "Dreadlocks",
    confidence: 85.14953255653381
    },
    {
    description: "Afro",
    confidence: 83.44154357910156
    },
    {
    description: "Moustache",
    confidence: 75.02559423446655
    },
    {
    description: "Vision care",
    confidence: 70.48851251602173
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9010811448097229,
    bounding_box: [
    {
    x: 0.5675665736198425,
    y: 0.3068960905075073
    },
    {
    x: 0.7299758791923523,
    y: 0.3068960905075073
    },
    {
    x: 0.7299758791923523,
    y: 0.42140328884124756
    },
    {
    x: 0.5675665736198425,
    y: 0.42140328884124756
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8233660459518433,
    bounding_box: [
    {
    x: 0.1965097337961197,
    y: 0.013190875761210918
    },
    {
    x: 0.9938010573387146,
    y: 0.013190875761210918
    },
    {
    x: 0.9938010573387146,
    y: 0.9923670887947083
    },
    {
    x: 0.1965097337961197,
    y: 0.9923670887947083
    }
    ]
    },
    {
    description: "Cabinetry",
    confidence: 0.5549231767654419,
    bounding_box: [
    {
    x: 0.0007217717356979847,
    y: 0.6965598464012146
    },
    {
    x: 0.12203802913427353,
    y: 0.6965598464012146
    },
    {
    x: 0.12203802913427353,
    y: 0.9973958134651184
    },
    {
    x: 0.0007217717356979847,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24680,
    time_in_milliseconds: 823489,
    labels: [
    {
    description: "Font",
    confidence: 95.31624913215637
    },
    {
    description: "Text",
    confidence: 91.89756512641907
    },
    {
    description: "Electric blue",
    confidence: 77.94914841651917
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Graphics",
    confidence: 52.729588747024536
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7271715998649597,
    bounding_box: [
    {
    x: 0.1084192767739296,
    y: 0
    },
    {
    x: 0.9956879019737244,
    y: 0
    },
    {
    x: 0.9956879019737244,
    y: 0.9972923398017883
    },
    {
    x: 0.1084192767739296,
    y: 0.9972923398017883
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24709,
    time_in_milliseconds: 824457,
    labels: [
    {
    description: "Text",
    confidence: 94.66919302940369
    },
    {
    description: "Font",
    confidence: 93.46455931663513
    },
    {
    description: "Blue",
    confidence: 92.6959753036499
    },
    {
    description: "Electric blue",
    confidence: 85.72543859481812
    },
    {
    description: "Graphic design",
    confidence: 76.93672180175781
    },
    {
    description: "Logo",
    confidence: 72.43244647979736
    },
    {
    description: "Graphics",
    confidence: 69.15693283081055
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 24825,
    time_in_milliseconds: 828327,
    labels: [
    {
    description: "Black",
    confidence: 98.49877953529358
    },
    {
    description: "Darkness",
    confidence: 97.3947525024414
    },
    {
    description: "White",
    confidence: 96.54507637023926
    },
    {
    description: "Sky",
    confidence: 93.36194396018982
    },
    {
    description: "Light",
    confidence: 91.6901707649231
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Text",
    confidence: 91.21394753456116
    },
    {
    description: "Brown",
    confidence: 88.69289755821228
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Atmosphere",
    confidence: 82.49121904373169
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 24912,
    time_in_milliseconds: 831230,
    labels: [
    {
    description: "Font",
    confidence: 94.6948766708374
    },
    {
    description: "Text",
    confidence: 91.89756512641907
    },
    {
    description: "Electric blue",
    confidence: 75.65793991088867
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Graphics",
    confidence: 57.50575661659241
    },
    {
    description: "Logo",
    confidence: 53.27439308166504
    },
    {
    description: "Graphic design",
    confidence: 51.51030421257019
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7934837341308594,
    bounding_box: [
    {
    x: 0.12409356981515884,
    y: 0
    },
    {
    x: 0.9939970970153809,
    y: 0
    },
    {
    x: 0.9939970970153809,
    y: 0.9960043430328369
    },
    {
    x: 0.12409356981515884,
    y: 0.9960043430328369
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 24970,
    time_in_milliseconds: 833165.0000000001,
    labels: [
    {
    description: "Text",
    confidence: 95.04482746124268
    },
    {
    description: "Font",
    confidence: 93.83147358894348
    },
    {
    description: "Electric blue",
    confidence: 81.70627355575562
    },
    {
    description: "Graphic design",
    confidence: 74.03979897499084
    },
    {
    description: "Logo",
    confidence: 73.63916635513306
    },
    {
    description: "Graphics",
    confidence: 69.15693283081055
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 25057,
    time_in_milliseconds: 836069.0000000001,
    labels: [
    {
    description: "Library",
    confidence: 68.88632774353027
    },
    {
    description: "Bookcase",
    confidence: 67.86776781082153
    },
    {
    description: "Glasses",
    confidence: 65.22085666656494
    },
    {
    description: "Book",
    confidence: 51.52108073234558
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9270697236061096,
    bounding_box: [
    {
    x: 0.36059388518333435,
    y: 0.455722838640213
    },
    {
    x: 0.48821261525154114,
    y: 0.455722838640213
    },
    {
    x: 0.48821261525154114,
    y: 0.5770972371101379
    },
    {
    x: 0.36059388518333435,
    y: 0.5770972371101379
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8328554630279541,
    bounding_box: [
    {
    x: 0.6042410731315613,
    y: 0.012862268835306168
    },
    {
    x: 0.9800572991371155,
    y: 0.012862268835306168
    },
    {
    x: 0.9800572991371155,
    y: 0.9894272685050964
    },
    {
    x: 0.6042410731315613,
    y: 0.9894272685050964
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8192400932312012,
    bounding_box: [
    {
    x: 0.30384382605552673,
    y: 0.24513764679431915
    },
    {
    x: 0.7810125946998596,
    y: 0.24513764679431915
    },
    {
    x: 0.7810125946998596,
    y: 0.9973958134651184
    },
    {
    x: 0.30384382605552673,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 25144,
    time_in_milliseconds: 838971,
    labels: [
    {
    description: "Media",
    confidence: 80.35634160041809
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Television program",
    confidence: 63.69372010231018
    },
    {
    description: "Television",
    confidence: 57.94331431388855
    },
    {
    description: "News",
    confidence: 56.27369284629822
    },
    {
    description: "Games",
    confidence: 52.11578011512756
    },
    {
    description: "Display device",
    confidence: 51.81824564933777
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9366914629936218,
    bounding_box: [
    {
    x: 0.26877450942993164,
    y: 0.27580368518829346
    },
    {
    x: 0.40998923778533936,
    y: 0.27580368518829346
    },
    {
    x: 0.40998923778533936,
    y: 0.37494516372680664
    },
    {
    x: 0.26877450942993164,
    y: 0.37494516372680664
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8643602728843689,
    bounding_box: [
    {
    x: 0.1224074736237526,
    y: 0.09434935450553894
    },
    {
    x: 0.5093095898628235,
    y: 0.09434935450553894
    },
    {
    x: 0.5093095898628235,
    y: 0.8548097610473633
    },
    {
    x: 0.1224074736237526,
    y: 0.8548097610473633
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8603578805923462,
    bounding_box: [
    {
    x: 0.5030966401100159,
    y: 0.11705806106328964
    },
    {
    x: 0.8580424189567566,
    y: 0.11705806106328964
    },
    {
    x: 0.8580424189567566,
    y: 0.8417436480522156
    },
    {
    x: 0.5030966401100159,
    y: 0.8417436480522156
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.8238556385040283,
    bounding_box: [
    {
    x: 0.131858691573143,
    y: 0.3825390040874481
    },
    {
    x: 0.5076300501823425,
    y: 0.3825390040874481
    },
    {
    x: 0.5076300501823425,
    y: 0.837536096572876
    },
    {
    x: 0.131858691573143,
    y: 0.837536096572876
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7824261784553528,
    bounding_box: [
    {
    x: 0.5031334757804871,
    y: 0.33973005414009094
    },
    {
    x: 0.7410016059875488,
    y: 0.33973005414009094
    },
    {
    x: 0.7410016059875488,
    y: 0.7538514733314514
    },
    {
    x: 0.5031334757804871,
    y: 0.7538514733314514
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 25260,
    time_in_milliseconds: 842842,
    labels: [
    {
    description: "Media",
    confidence: 65.32303094863892
    },
    {
    description: "Games",
    confidence: 63.133203983306885
    },
    {
    description: "News",
    confidence: 58.79448056221008
    },
    {
    description: "Photo caption",
    confidence: 51.30242705345154
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9437046647071838,
    bounding_box: [
    {
    x: 0.19156049191951752,
    y: 0.30541059374809265
    },
    {
    x: 0.3372493088245392,
    y: 0.30541059374809265
    },
    {
    x: 0.3372493088245392,
    y: 0.4009338319301605
    },
    {
    x: 0.19156049191951752,
    y: 0.4009338319301605
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8533319234848022,
    bounding_box: [
    {
    x: 0.11050745099782944,
    y: 0.11323251575231552
    },
    {
    x: 0.4906117022037506,
    y: 0.11323251575231552
    },
    {
    x: 0.4906117022037506,
    y: 0.8652419447898865
    },
    {
    x: 0.11050745099782944,
    y: 0.8652419447898865
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8345695734024048,
    bounding_box: [
    {
    x: 0.5078185796737671,
    y: 0.19197504222393036
    },
    {
    x: 0.8448994159698486,
    y: 0.19197504222393036
    },
    {
    x: 0.8448994159698486,
    y: 0.8333770632743835
    },
    {
    x: 0.5078185796737671,
    y: 0.8333770632743835
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.8250599503517151,
    bounding_box: [
    {
    x: 0.547577440738678,
    y: 0.48469623923301697
    },
    {
    x: 0.8236095905303955,
    y: 0.48469623923301697
    },
    {
    x: 0.8236095905303955,
    y: 0.8333309292793274
    },
    {
    x: 0.547577440738678,
    y: 0.8333309292793274
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7805037498474121,
    bounding_box: [
    {
    x: 0.12242002040147781,
    y: 0.3050881326198578
    },
    {
    x: 0.4959016740322113,
    y: 0.3050881326198578
    },
    {
    x: 0.4959016740322113,
    y: 0.8318889141082764
    },
    {
    x: 0.12242002040147781,
    y: 0.8318889141082764
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6626226305961609,
    bounding_box: [
    {
    x: 0.5070407390594482,
    y: 0.3722810447216034
    },
    {
    x: 0.7043232917785645,
    y: 0.3722810447216034
    },
    {
    x: 0.7043232917785645,
    y: 0.7359762787818909
    },
    {
    x: 0.5070407390594482,
    y: 0.7359762787818909
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6057475805282593,
    bounding_box: [
    {
    x: 0.5074289441108704,
    y: 0.3801329433917999
    },
    {
    x: 0.708519697189331,
    y: 0.3801329433917999
    },
    {
    x: 0.708519697189331,
    y: 0.7299724221229553
    },
    {
    x: 0.5074289441108704,
    y: 0.7299724221229553
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 25318,
    time_in_milliseconds: 844777,
    labels: [
    {
    description: "Musical instrument",
    confidence: 96.64944410324097
    },
    {
    description: "Pianist",
    confidence: 94.74963545799255
    },
    {
    description: "Music",
    confidence: 94.65753436088562
    },
    {
    description: "Electronic instrument",
    confidence: 94.30441856384277
    },
    {
    description: "Musical keyboard",
    confidence: 92.85652041435242
    },
    {
    description: "Piano",
    confidence: 92.15835332870483
    },
    {
    description: "Musician",
    confidence: 92.07488298416138
    },
    {
    description: "Jazz pianist",
    confidence: 91.45305156707764
    },
    {
    description: "Keyboard",
    confidence: 90.87203741073608
    },
    {
    description: "Electronic device",
    confidence: 87.44567036628723
    }
    ],
    objects: [
    {
    description: "Piano",
    confidence: 0.9515441656112671,
    bounding_box: [
    {
    x: 0.5483614206314087,
    y: 0.4992196559906006
    },
    {
    x: 0.9687301516532898,
    y: 0.4992196559906006
    },
    {
    x: 0.9687301516532898,
    y: 0.9883444309234619
    },
    {
    x: 0.5483614206314087,
    y: 0.9883444309234619
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8879976868629456,
    bounding_box: [
    {
    x: 0.2695408761501312,
    y: 0.09177326411008835
    },
    {
    x: 0.6270539164543152,
    y: 0.09177326411008835
    },
    {
    x: 0.6270539164543152,
    y: 0.9810304045677185
    },
    {
    x: 0.2695408761501312,
    y: 0.9810304045677185
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.658742368221283,
    bounding_box: [
    {
    x: 0.46067145466804504,
    y: 0.05761798098683357
    },
    {
    x: 0.5814066529273987,
    y: 0.05761798098683357
    },
    {
    x: 0.5814066529273987,
    y: 0.20661909878253937
    },
    {
    x: 0.46067145466804504,
    y: 0.20661909878253937
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.553672730922699,
    bounding_box: [
    {
    x: 0.2768477499485016,
    y: 0.7276656031608582
    },
    {
    x: 0.6289703845977783,
    y: 0.7276656031608582
    },
    {
    x: 0.6289703845977783,
    y: 0.9966537952423096
    },
    {
    x: 0.2768477499485016,
    y: 0.9966537952423096
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5173717737197876,
    bounding_box: [
    {
    x: 0.3162841498851776,
    y: 0.3217012882232666
    },
    {
    x: 0.5864928364753723,
    y: 0.3217012882232666
    },
    {
    x: 0.5864928364753723,
    y: 0.7624035477638245
    },
    {
    x: 0.3162841498851776,
    y: 0.7624035477638245
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 25347,
    time_in_milliseconds: 845744,
    labels: [
    {
    description: "Musical instrument",
    confidence: 96.19255065917969
    },
    {
    description: "Pianist",
    confidence: 96.14108800888062
    },
    {
    description: "Piano",
    confidence: 96.07977867126465
    },
    {
    description: "Electronic instrument",
    confidence: 95.44816017150879
    },
    {
    description: "Jazz pianist",
    confidence: 94.481360912323
    },
    {
    description: "Musical keyboard",
    confidence: 94.37687397003174
    },
    {
    description: "Music",
    confidence: 92.57444143295288
    },
    {
    description: "Keyboard",
    confidence: 92.14370846748352
    },
    {
    description: "Keyboard player",
    confidence: 92.12223291397095
    },
    {
    description: "Musician",
    confidence: 91.76992774009705
    }
    ],
    objects: [
    {
    description: "Piano",
    confidence: 0.935965895652771,
    bounding_box: [
    {
    x: 0.5573157668113708,
    y: 0.49541565775871277
    },
    {
    x: 0.964174747467041,
    y: 0.49541565775871277
    },
    {
    x: 0.964174747467041,
    y: 0.985562264919281
    },
    {
    x: 0.5573157668113708,
    y: 0.985562264919281
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8444116711616516,
    bounding_box: [
    {
    x: 0.258618026971817,
    y: 0.11025957018136978
    },
    {
    x: 0.6681334376335144,
    y: 0.11025957018136978
    },
    {
    x: 0.6681334376335144,
    y: 0.9709696173667908
    },
    {
    x: 0.258618026971817,
    y: 0.9709696173667908
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7412104606628418,
    bounding_box: [
    {
    x: 0.28844839334487915,
    y: 0.25615957379341125
    },
    {
    x: 0.6201446652412415,
    y: 0.25615957379341125
    },
    {
    x: 0.6201446652412415,
    y: 0.8579554557800293
    },
    {
    x: 0.28844839334487915,
    y: 0.8579554557800293
    }
    ]
    },
    {
    description: "Musical keyboard",
    confidence: 0.6787077784538269,
    bounding_box: [
    {
    x: 0.5564973950386047,
    y: 0.5129905343055725
    },
    {
    x: 0.9470353126525879,
    y: 0.5129905343055725
    },
    {
    x: 0.9470353126525879,
    y: 0.993105411529541
    },
    {
    x: 0.5564973950386047,
    y: 0.993105411529541
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6377401947975159,
    bounding_box: [
    {
    x: 0.2435787320137024,
    y: 0.7250087857246399
    },
    {
    x: 0.5862789750099182,
    y: 0.7250087857246399
    },
    {
    x: 0.5862789750099182,
    y: 0.9947546124458313
    },
    {
    x: 0.2435787320137024,
    y: 0.9947546124458313
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 25376,
    time_in_milliseconds: 846712,
    labels: [
    {
    description: "News",
    confidence: 77.9337465763092
    },
    {
    description: "Television program",
    confidence: 74.81932640075684
    },
    {
    description: "Media",
    confidence: 73.36138486862183
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Newscaster",
    confidence: 59.91051197052002
    },
    {
    description: "Photo caption",
    confidence: 56.05892539024353
    },
    {
    description: "Games",
    confidence: 52.11578011512756
    },
    {
    description: "Television",
    confidence: 51.40594244003296
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9239394664764404,
    bounding_box: [
    {
    x: 0.22647017240524292,
    y: 0.3142077624797821
    },
    {
    x: 0.36790570616722107,
    y: 0.3142077624797821
    },
    {
    x: 0.36790570616722107,
    y: 0.40105271339416504
    },
    {
    x: 0.22647017240524292,
    y: 0.40105271339416504
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8648800849914551,
    bounding_box: [
    {
    x: 0.5500754117965698,
    y: 0.14771537482738495
    },
    {
    x: 0.844336211681366,
    y: 0.14771537482738495
    },
    {
    x: 0.844336211681366,
    y: 0.8392617106437683
    },
    {
    x: 0.5500754117965698,
    y: 0.8392617106437683
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.84414142370224,
    bounding_box: [
    {
    x: 0.1230761706829071,
    y: 0.10861415416002274
    },
    {
    x: 0.49034634232521057,
    y: 0.10861415416002274
    },
    {
    x: 0.49034634232521057,
    y: 0.8566901087760925
    },
    {
    x: 0.1230761706829071,
    y: 0.8566901087760925
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7586398124694824,
    bounding_box: [
    {
    x: 0.5605976581573486,
    y: 0.34055665135383606
    },
    {
    x: 0.8066497445106506,
    y: 0.34055665135383606
    },
    {
    x: 0.8066497445106506,
    y: 0.7606885433197021
    },
    {
    x: 0.5605976581573486,
    y: 0.7606885433197021
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7056036591529846,
    bounding_box: [
    {
    x: 0.13094042241573334,
    y: 0.28893932700157166
    },
    {
    x: 0.4871494770050049,
    y: 0.28893932700157166
    },
    {
    x: 0.4871494770050049,
    y: 0.8353995084762573
    },
    {
    x: 0.13094042241573334,
    y: 0.8353995084762573
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 26797,
    time_in_milliseconds: 894127.0000000001,
    labels: [
    {
    description: "Media",
    confidence: 81.00442886352539
    },
    {
    description: "News",
    confidence: 79.13691997528076
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Television program",
    confidence: 64.82622623443604
    },
    {
    description: "Television",
    confidence: 64.20230269432068
    },
    {
    description: "Display device",
    confidence: 57.94420838356018
    },
    {
    description: "Photo caption",
    confidence: 56.05892539024353
    },
    {
    description: "Newscaster",
    confidence: 53.33144664764404
    },
    {
    description: "Games",
    confidence: 52.11578011512756
    },
    {
    description: "Glasses",
    confidence: 51.58061981201172
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9391664862632751,
    bounding_box: [
    {
    x: 0.27404260635375977,
    y: 0.268702894449234
    },
    {
    x: 0.42649808526039124,
    y: 0.268702894449234
    },
    {
    x: 0.42649808526039124,
    y: 0.3641264736652374
    },
    {
    x: 0.27404260635375977,
    y: 0.3641264736652374
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8964236378669739,
    bounding_box: [
    {
    x: 0.11899510771036148,
    y: 0.08725570887327194
    },
    {
    x: 0.5075289011001587,
    y: 0.08725570887327194
    },
    {
    x: 0.5075289011001587,
    y: 0.8517662882804871
    },
    {
    x: 0.11899510771036148,
    y: 0.8517662882804871
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8821533918380737,
    bounding_box: [
    {
    x: 0.5429049730300903,
    y: 0.14421065151691437
    },
    {
    x: 0.8618637919425964,
    y: 0.14421065151691437
    },
    {
    x: 0.8618637919425964,
    y: 0.8457327485084534
    },
    {
    x: 0.5429049730300903,
    y: 0.8457327485084534
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7051842212677002,
    bounding_box: [
    {
    x: 0.5543147921562195,
    y: 0.3391375243663788
    },
    {
    x: 0.7182299494743347,
    y: 0.3391375243663788
    },
    {
    x: 0.7182299494743347,
    y: 0.7720842361450195
    },
    {
    x: 0.5543147921562195,
    y: 0.7720842361450195
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6606363654136658,
    bounding_box: [
    {
    x: 0.5535048842430115,
    y: 0.33744320273399353
    },
    {
    x: 0.7147747874259949,
    y: 0.33744320273399353
    },
    {
    x: 0.7147747874259949,
    y: 0.751796543598175
    },
    {
    x: 0.5535048842430115,
    y: 0.751796543598175
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6057007312774658,
    bounding_box: [
    {
    x: 0.12080974131822586,
    y: 0.11337649822235107
    },
    {
    x: 0.5117045640945435,
    y: 0.11337649822235107
    },
    {
    x: 0.5117045640945435,
    y: 0.845784604549408
    },
    {
    x: 0.12080974131822586,
    y: 0.845784604549408
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 26855,
    time_in_milliseconds: 896062,
    labels: [
    {
    description: "Musical instrument",
    confidence: 97.55585193634033
    },
    {
    description: "Musical keyboard",
    confidence: 96.13060355186462
    },
    {
    description: "Electronic instrument",
    confidence: 95.49548625946045
    },
    {
    description: "Piano",
    confidence: 95.44095993041992
    },
    {
    description: "Keyboard player",
    confidence: 95.4114317893982
    },
    {
    description: "Pianist",
    confidence: 95.09443640708923
    },
    {
    description: "Music",
    confidence: 94.71074938774109
    },
    {
    description: "Keyboard",
    confidence: 94.25138235092163
    },
    {
    description: "Jazz pianist",
    confidence: 91.87808632850647
    },
    {
    description: "Musician",
    confidence: 91.06877446174622
    }
    ],
    objects: [
    {
    description: "Piano",
    confidence: 0.9368561506271362,
    bounding_box: [
    {
    x: 0.5465503334999084,
    y: 0.4953932762145996
    },
    {
    x: 0.9755398631095886,
    y: 0.4953932762145996
    },
    {
    x: 0.9755398631095886,
    y: 0.9900346398353577
    },
    {
    x: 0.5465503334999084,
    y: 0.9900346398353577
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8882112503051758,
    bounding_box: [
    {
    x: 0.27339863777160645,
    y: 0.11226610094308853
    },
    {
    x: 0.6634700894355774,
    y: 0.11226610094308853
    },
    {
    x: 0.6634700894355774,
    y: 0.9896134734153748
    },
    {
    x: 0.27339863777160645,
    y: 0.9896134734153748
    }
    ]
    },
    {
    description: "Musical keyboard",
    confidence: 0.8024353384971619,
    bounding_box: [
    {
    x: 0.5929934978485107,
    y: 0.6657953858375549
    },
    {
    x: 0.900706946849823,
    y: 0.6657953858375549
    },
    {
    x: 0.900706946849823,
    y: 0.981269121170044
    },
    {
    x: 0.5929934978485107,
    y: 0.981269121170044
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7057679295539856,
    bounding_box: [
    {
    x: 0.279718816280365,
    y: 0.10769964009523392
    },
    {
    x: 0.6490366458892822,
    y: 0.10769964009523392
    },
    {
    x: 0.6490366458892822,
    y: 0.9846374988555908
    },
    {
    x: 0.279718816280365,
    y: 0.9846374988555908
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.576589047908783,
    bounding_box: [
    {
    x: 0.3039819002151489,
    y: 0.32549160718917847
    },
    {
    x: 0.5026668906211853,
    y: 0.32549160718917847
    },
    {
    x: 0.5026668906211853,
    y: 0.818181574344635
    },
    {
    x: 0.3039819002151489,
    y: 0.818181574344635
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5721337795257568,
    bounding_box: [
    {
    x: 0.26888105273246765,
    y: 0.7452940344810486
    },
    {
    x: 0.6359320282936096,
    y: 0.7452940344810486
    },
    {
    x: 0.6359320282936096,
    y: 0.9973958134651184
    },
    {
    x: 0.26888105273246765,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.5619535446166992,
    bounding_box: [
    {
    x: 0.45001137256622314,
    y: 0.10018376260995865
    },
    {
    x: 0.5581803321838379,
    y: 0.10018376260995865
    },
    {
    x: 0.5581803321838379,
    y: 0.29193708300590515
    },
    {
    x: 0.45001137256622314,
    y: 0.29193708300590515
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 27087,
    time_in_milliseconds: 903802,
    labels: [
    {
    description: "Library",
    confidence: 82.15657472610474
    },
    {
    description: "Bookcase",
    confidence: 69.51121091842651
    },
    {
    description: "Book",
    confidence: 68.95201206207275
    },
    {
    description: "Furniture",
    confidence: 56.74918293952942
    },
    {
    description: "Glasses",
    confidence: 56.08964562416077
    },
    {
    description: "Publication",
    confidence: 55.21233677864075
    },
    {
    description: "Author",
    confidence: 50.40324330329895
    },
    {
    description: "Shelving",
    confidence: 50.00336170196533
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9004339575767517,
    bounding_box: [
    {
    x: 0.44015994668006897,
    y: 0.40801307559013367
    },
    {
    x: 0.5676143169403076,
    y: 0.40801307559013367
    },
    {
    x: 0.5676143169403076,
    y: 0.4951060116291046
    },
    {
    x: 0.44015994668006897,
    y: 0.4951060116291046
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8655485510826111,
    bounding_box: [
    {
    x: 0.6194207072257996,
    y: 0.008272922597825527
    },
    {
    x: 0.9843875765800476,
    y: 0.008272922597825527
    },
    {
    x: 0.9843875765800476,
    y: 0.9802210927009583
    },
    {
    x: 0.6194207072257996,
    y: 0.9802210927009583
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.818096935749054,
    bounding_box: [
    {
    x: 0.3473930060863495,
    y: 0.26281777024269104
    },
    {
    x: 0.7801616787910461,
    y: 0.26281777024269104
    },
    {
    x: 0.7801616787910461,
    y: 0.9918607473373413
    },
    {
    x: 0.3473930060863495,
    y: 0.9918607473373413
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5083361864089966,
    bounding_box: [
    {
    x: 0.367491751909256,
    y: 0.5496874451637268
    },
    {
    x: 0.7689768671989441,
    y: 0.5496874451637268
    },
    {
    x: 0.7689768671989441,
    y: 0.9926199913024902
    },
    {
    x: 0.367491751909256,
    y: 0.9926199913024902
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 27174,
    time_in_milliseconds: 906705,
    labels: [
    {
    description: "Pianist",
    confidence: 95.96484899520874
    },
    {
    description: "Musical instrument",
    confidence: 95.80846428871155
    },
    {
    description: "Electronic instrument",
    confidence: 95.78070044517517
    },
    {
    description: "Piano",
    confidence: 95.7547664642334
    },
    {
    description: "Musical keyboard",
    confidence: 94.04011368751526
    },
    {
    description: "Keyboard",
    confidence: 92.586350440979
    },
    {
    description: "Keyboard player",
    confidence: 90.9686803817749
    },
    {
    description: "Musician",
    confidence: 90.90965986251831
    },
    {
    description: "Music",
    confidence: 89.29287195205688
    },
    {
    description: "Jazz pianist",
    confidence: 87.1574342250824
    }
    ],
    objects: [
    {
    description: "Piano",
    confidence: 0.9467580914497375,
    bounding_box: [
    {
    x: 0.548494815826416,
    y: 0.5008344054222107
    },
    {
    x: 0.9654219746589661,
    y: 0.5008344054222107
    },
    {
    x: 0.9654219746589661,
    y: 0.989960253238678
    },
    {
    x: 0.548494815826416,
    y: 0.989960253238678
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8924738168716431,
    bounding_box: [
    {
    x: 0.18404650688171387,
    y: 0.08409709483385086
    },
    {
    x: 0.6176048517227173,
    y: 0.08409709483385086
    },
    {
    x: 0.6176048517227173,
    y: 0.9939584136009216
    },
    {
    x: 0.18404650688171387,
    y: 0.9939584136009216
    }
    ]
    },
    {
    description: "Musical keyboard",
    confidence: 0.7712539434432983,
    bounding_box: [
    {
    x: 0.5488986372947693,
    y: 0.5145286321640015
    },
    {
    x: 0.9449775815010071,
    y: 0.5145286321640015
    },
    {
    x: 0.9449775815010071,
    y: 0.9911913871765137
    },
    {
    x: 0.5488986372947693,
    y: 0.9911913871765137
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7504162192344666,
    bounding_box: [
    {
    x: 0.1833917647600174,
    y: 0.3371330201625824
    },
    {
    x: 0.46585196256637573,
    y: 0.3371330201625824
    },
    {
    x: 0.46585196256637573,
    y: 0.870384693145752
    },
    {
    x: 0.1833917647600174,
    y: 0.870384693145752
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6794830560684204,
    bounding_box: [
    {
    x: 0.18516004085540771,
    y: 0.1605025976896286
    },
    {
    x: 0.5610972046852112,
    y: 0.1605025976896286
    },
    {
    x: 0.5610972046852112,
    y: 0.9517598152160645
    },
    {
    x: 0.18516004085540771,
    y: 0.9517598152160645
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 27261,
    time_in_milliseconds: 909609,
    labels: [
    {
    description: "Glasses",
    confidence: 75.91426968574524
    },
    {
    description: "Library",
    confidence: 75.04546046257019
    },
    {
    description: "Bookcase",
    confidence: 65.39577841758728
    },
    {
    description: "Book",
    confidence: 60.874515771865845
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9346838593482971,
    bounding_box: [
    {
    x: 0.4204310178756714,
    y: 0.3906432092189789
    },
    {
    x: 0.5559054613113403,
    y: 0.3906432092189789
    },
    {
    x: 0.5559054613113403,
    y: 0.4838930666446686
    },
    {
    x: 0.4204310178756714,
    y: 0.4838930666446686
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8719946146011353,
    bounding_box: [
    {
    x: 0.3327040672302246,
    y: 0.2315930724143982
    },
    {
    x: 0.8938149809837341,
    y: 0.2315930724143982
    },
    {
    x: 0.8938149809837341,
    y: 0.9908089637756348
    },
    {
    x: 0.3327040672302246,
    y: 0.9908089637756348
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.862483561038971,
    bounding_box: [
    {
    x: 0.6043125987052917,
    y: 0.026316121220588684
    },
    {
    x: 0.977969229221344,
    y: 0.026316121220588684
    },
    {
    x: 0.977969229221344,
    y: 0.9806906580924988
    },
    {
    x: 0.6043125987052917,
    y: 0.9806906580924988
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6567131876945496,
    bounding_box: [
    {
    x: 0.3399019241333008,
    y: 0.5225251317024231
    },
    {
    x: 0.8799719214439392,
    y: 0.5225251317024231
    },
    {
    x: 0.8799719214439392,
    y: 0.9857210516929626
    },
    {
    x: 0.3399019241333008,
    y: 0.9857210516929626
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 27319,
    time_in_milliseconds: 911543,
    labels: [
    {
    description: "Glasses",
    confidence: 83.378666639328
    },
    {
    description: "Library",
    confidence: 62.166959047317505
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    },
    {
    description: "Collection",
    confidence: 53.982263803482056
    },
    {
    description: "Vision care",
    confidence: 51.88118815422058
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9306334257125854,
    bounding_box: [
    {
    x: 0.45352816581726074,
    y: 0.4162013828754425
    },
    {
    x: 0.5978389382362366,
    y: 0.4162013828754425
    },
    {
    x: 0.5978389382362366,
    y: 0.5218331217765808
    },
    {
    x: 0.45352816581726074,
    y: 0.5218331217765808
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7883753776550293,
    bounding_box: [
    {
    x: 0.3839815855026245,
    y: 0.23347212374210358
    },
    {
    x: 0.9375713467597961,
    y: 0.23347212374210358
    },
    {
    x: 0.9375713467597961,
    y: 0.9848966002464294
    },
    {
    x: 0.3839815855026245,
    y: 0.9848966002464294
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.7777784466743469,
    bounding_box: [
    {
    x: 0.6913983821868896,
    y: 0.03889118880033493
    },
    {
    x: 0.9945134520530701,
    y: 0.03889118880033493
    },
    {
    x: 0.9945134520530701,
    y: 0.9902560710906982
    },
    {
    x: 0.6913983821868896,
    y: 0.9902560710906982
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5535796284675598,
    bounding_box: [
    {
    x: 0.39613184332847595,
    y: 0.31065380573272705
    },
    {
    x: 0.9554800987243652,
    y: 0.31065380573272705
    },
    {
    x: 0.9554800987243652,
    y: 0.9937171339988708
    },
    {
    x: 0.39613184332847595,
    y: 0.9937171339988708
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28015,
    time_in_milliseconds: 934767,
    labels: [
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Bookcase",
    confidence: 73.65607023239136
    },
    {
    description: "Media",
    confidence: 58.347392082214355
    },
    {
    description: "Shelving",
    confidence: 53.6568284034729
    },
    {
    description: "Collection",
    confidence: 50.01777410507202
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9349328279495239,
    bounding_box: [
    {
    x: 0.4856456220149994,
    y: 0.35792410373687744
    },
    {
    x: 0.6224392056465149,
    y: 0.35792410373687744
    },
    {
    x: 0.6224392056465149,
    y: 0.4570891857147217
    },
    {
    x: 0.4856456220149994,
    y: 0.4570891857147217
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8456931710243225,
    bounding_box: [
    {
    x: 0.69003826379776,
    y: 0.03199027106165886
    },
    {
    x: 0.9967565536499023,
    y: 0.03199027106165886
    },
    {
    x: 0.9967565536499023,
    y: 0.9969353079795837
    },
    {
    x: 0.69003826379776,
    y: 0.9969353079795837
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8023295402526855,
    bounding_box: [
    {
    x: 0.3775876760482788,
    y: 0.20105379819869995
    },
    {
    x: 0.8071368336677551,
    y: 0.20105379819869995
    },
    {
    x: 0.8071368336677551,
    y: 0.993838369846344
    },
    {
    x: 0.3775876760482788,
    y: 0.993838369846344
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5372568368911743,
    bounding_box: [
    {
    x: 0.38524794578552246,
    y: 0.5334658622741699
    },
    {
    x: 0.796032726764679,
    y: 0.5334658622741699
    },
    {
    x: 0.796032726764679,
    y: 0.9863402843475342
    },
    {
    x: 0.38524794578552246,
    y: 0.9863402843475342
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28218,
    time_in_milliseconds: 941540.0000000001,
    labels: [
    {
    description: "Library",
    confidence: 76.66484713554382
    },
    {
    description: "Bookcase",
    confidence: 75.25627613067627
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Glasses",
    confidence: 62.70098090171814
    },
    {
    description: "Shelving",
    confidence: 61.292511224746704
    },
    {
    description: "Furniture",
    confidence: 59.37383770942688
    },
    {
    description: "Book",
    confidence: 58.80803465843201
    },
    {
    description: "Media",
    confidence: 58.347392082214355
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9358925819396973,
    bounding_box: [
    {
    x: 0.5168487429618835,
    y: 0.38745781779289246
    },
    {
    x: 0.6555821299552917,
    y: 0.38745781779289246
    },
    {
    x: 0.6555821299552917,
    y: 0.47942987084388733
    },
    {
    x: 0.5168487429618835,
    y: 0.47942987084388733
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8351145386695862,
    bounding_box: [
    {
    x: 0.249609112739563,
    y: 0.20335358381271362
    },
    {
    x: 0.773677408695221,
    y: 0.20335358381271362
    },
    {
    x: 0.773677408695221,
    y: 0.9920920729637146
    },
    {
    x: 0.249609112739563,
    y: 0.9920920729637146
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8218489289283752,
    bounding_box: [
    {
    x: 0.6260587573051453,
    y: 0.025109000504016876
    },
    {
    x: 0.978877067565918,
    y: 0.025109000504016876
    },
    {
    x: 0.978877067565918,
    y: 0.9816980957984924
    },
    {
    x: 0.6260587573051453,
    y: 0.9816980957984924
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.807266891002655,
    bounding_box: [
    {
    x: 0.26164451241493225,
    y: 0.5518772006034851
    },
    {
    x: 0.765355110168457,
    y: 0.5518772006034851
    },
    {
    x: 0.765355110168457,
    y: 0.9879700541496277
    },
    {
    x: 0.26164451241493225,
    y: 0.9879700541496277
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28334,
    time_in_milliseconds: 945411,
    labels: [
    {
    description: "Crowd",
    confidence: 98.42362999916077
    },
    {
    description: "Protest",
    confidence: 96.68893814086914
    },
    {
    description: "People",
    confidence: 96.29892110824585
    },
    {
    description: "Event",
    confidence: 90.41119813919067
    },
    {
    description: "Public event",
    confidence: 90.13221859931946
    },
    {
    description: "Community",
    confidence: 86.4475667476654
    },
    {
    description: "Public space",
    confidence: 82.81257748603821
    },
    {
    description: "Pedestrian",
    confidence: 74.05824065208435
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Recreation",
    confidence: 59.429895877838135
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8903126120567322,
    bounding_box: [
    {
    x: 0.20365405082702637,
    y: 0.5969668626785278
    },
    {
    x: 0.3419433534145355,
    y: 0.5969668626785278
    },
    {
    x: 0.3419433534145355,
    y: 0.8942428231239319
    },
    {
    x: 0.20365405082702637,
    y: 0.8942428231239319
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8899040222167969,
    bounding_box: [
    {
    x: 0.9085519909858704,
    y: 0.5651302933692932
    },
    {
    x: 0.9927938580513,
    y: 0.5651302933692932
    },
    {
    x: 0.9927938580513,
    y: 0.9892728328704834
    },
    {
    x: 0.9085519909858704,
    y: 0.9892728328704834
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8882253766059875,
    bounding_box: [
    {
    x: 0.307306706905365,
    y: 0.6122920513153076
    },
    {
    x: 0.389658659696579,
    y: 0.6122920513153076
    },
    {
    x: 0.389658659696579,
    y: 0.8950886726379395
    },
    {
    x: 0.307306706905365,
    y: 0.8950886726379395
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8816831707954407,
    bounding_box: [
    {
    x: 0.14465293288230896,
    y: 0.6268981695175171
    },
    {
    x: 0.24556773900985718,
    y: 0.6268981695175171
    },
    {
    x: 0.24556773900985718,
    y: 0.8772430419921875
    },
    {
    x: 0.14465293288230896,
    y: 0.8772430419921875
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8814353346824646,
    bounding_box: [
    {
    x: 0.5370936393737793,
    y: 0.5276082754135132
    },
    {
    x: 0.7524538040161133,
    y: 0.5276082754135132
    },
    {
    x: 0.7524538040161133,
    y: 0.9920336604118347
    },
    {
    x: 0.5370936393737793,
    y: 0.9920336604118347
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8716561198234558,
    bounding_box: [
    {
    x: 0.7112229466438293,
    y: 0.5205492377281189
    },
    {
    x: 0.8462490439414978,
    y: 0.5205492377281189
    },
    {
    x: 0.8462490439414978,
    y: 0.9929458498954773
    },
    {
    x: 0.7112229466438293,
    y: 0.9929458498954773
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8487696647644043,
    bounding_box: [
    {
    x: 0.104808010160923,
    y: 0.6385772228240967
    },
    {
    x: 0.16995376348495483,
    y: 0.6385772228240967
    },
    {
    x: 0.16995376348495483,
    y: 0.8496100902557373
    },
    {
    x: 0.104808010160923,
    y: 0.8496100902557373
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8375483155250549,
    bounding_box: [
    {
    x: 0.02698397822678089,
    y: 0.6591630578041077
    },
    {
    x: 0.10687369108200073,
    y: 0.6591630578041077
    },
    {
    x: 0.10687369108200073,
    y: 0.8725923895835876
    },
    {
    x: 0.02698397822678089,
    y: 0.8725923895835876
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.829309344291687,
    bounding_box: [
    {
    x: 0.9218884110450745,
    y: 0.6518256664276123
    },
    {
    x: 0.9972217679023743,
    y: 0.6518256664276123
    },
    {
    x: 0.9972217679023743,
    y: 0.8714385032653809
    },
    {
    x: 0.9218884110450745,
    y: 0.8714385032653809
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8286098837852478,
    bounding_box: [
    {
    x: 0.5329757332801819,
    y: 0.5167882442474365
    },
    {
    x: 0.7554262280464172,
    y: 0.5167882442474365
    },
    {
    x: 0.7554262280464172,
    y: 0.9922844767570496
    },
    {
    x: 0.5329757332801819,
    y: 0.9922844767570496
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28392,
    time_in_milliseconds: 947346,
    labels: [
    {
    description: "Crowd",
    confidence: 98.3838438987732
    },
    {
    description: "People",
    confidence: 97.31158018112183
    },
    {
    description: "Fan",
    confidence: 89.0029788017273
    },
    {
    description: "Product",
    confidence: 88.28333020210266
    },
    {
    description: "Pilgrimage",
    confidence: 82.34826922416687
    },
    {
    description: "Event",
    confidence: 80.97490668296814
    },
    {
    description: "Audience",
    confidence: 78.68969440460205
    },
    {
    description: "Demonstration",
    confidence: 76.53055191040039
    },
    {
    description: "Public event",
    confidence: 76.42449140548706
    },
    {
    description: "Protest",
    confidence: 74.29521083831787
    }
    ],
    objects: [
    {
    description: "Building",
    confidence: 0.7081665396690369,
    bounding_box: [
    {
    x: 0.14412130415439606,
    y: 0.0025445541832596064
    },
    {
    x: 0.6032083630561829,
    y: 0.0025445541832596064
    },
    {
    x: 0.6032083630561829,
    y: 0.13799221813678741
    },
    {
    x: 0.14412130415439606,
    y: 0.13799221813678741
    }
    ]
    },
    {
    description: "Building",
    confidence: 0.5708298087120056,
    bounding_box: [
    {
    x: 0,
    y: 0.012899856083095074
    },
    {
    x: 0.3909659683704376,
    y: 0.012899856083095074
    },
    {
    x: 0.3909659683704376,
    y: 0.29476991295814514
    },
    {
    x: 0,
    y: 0.29476991295814514
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28450,
    time_in_milliseconds: 949282,
    labels: [
    {
    description: "Community",
    confidence: 80.91204762458801
    },
    {
    description: "Crowd",
    confidence: 80.50702214241028
    },
    {
    description: "Collage",
    confidence: 80.38873076438904
    },
    {
    description: "Event",
    confidence: 72.28018045425415
    },
    {
    description: "Photomontage",
    confidence: 65.5818521976471
    },
    {
    description: "Protest",
    confidence: 52.09819674491882
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.6128606796264648,
    bounding_box: [
    {
    x: 0.8772265911102295,
    y: 0.8912500739097595
    },
    {
    x: 0.9703094363212585,
    y: 0.8912500739097595
    },
    {
    x: 0.9703094363212585,
    y: 0.996953010559082
    },
    {
    x: 0.8772265911102295,
    y: 0.996953010559082
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28508,
    time_in_milliseconds: 951216,
    labels: [
    {
    description: "Crowd",
    confidence: 97.71270751953125
    },
    {
    description: "People",
    confidence: 96.42146229743958
    },
    {
    description: "Event",
    confidence: 92.34861135482788
    },
    {
    description: "Community",
    confidence: 89.39472436904907
    },
    {
    description: "Public space",
    confidence: 85.41156649589539
    },
    {
    description: "Protest",
    confidence: 83.97365808486938
    },
    {
    description: "Public event",
    confidence: 81.52826428413391
    },
    {
    description: "Pedestrian",
    confidence: 71.54207825660706
    },
    {
    description: "Demonstration",
    confidence: 53.072136640548706
    },
    {
    description: "Audience",
    confidence: 51.61588788032532
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8583542704582214,
    bounding_box: [
    {
    x: 0.40918469429016113,
    y: 0.3888307511806488
    },
    {
    x: 0.6350175738334656,
    y: 0.3888307511806488
    },
    {
    x: 0.6350175738334656,
    y: 0.9973958730697632
    },
    {
    x: 0.40918469429016113,
    y: 0.9973958730697632
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8557472229003906,
    bounding_box: [
    {
    x: 0.1446252018213272,
    y: 0.6871785521507263
    },
    {
    x: 0.42017436027526855,
    y: 0.6871785521507263
    },
    {
    x: 0.42017436027526855,
    y: 0.9959433078765869
    },
    {
    x: 0.1446252018213272,
    y: 0.9959433078765869
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8508946299552917,
    bounding_box: [
    {
    x: 0.002284418558701873,
    y: 0.5894702076911926
    },
    {
    x: 0.15815633535385132,
    y: 0.5894702076911926
    },
    {
    x: 0.15815633535385132,
    y: 0.9931593537330627
    },
    {
    x: 0.002284418558701873,
    y: 0.9931593537330627
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8448801040649414,
    bounding_box: [
    {
    x: 0.2934991419315338,
    y: 0.3003687262535095
    },
    {
    x: 0.3929959535598755,
    y: 0.3003687262535095
    },
    {
    x: 0.3929959535598755,
    y: 0.5070972442626953
    },
    {
    x: 0.2934991419315338,
    y: 0.5070972442626953
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8194773197174072,
    bounding_box: [
    {
    x: 0.8268029689788818,
    y: 0.26716095209121704
    },
    {
    x: 0.9479823708534241,
    y: 0.26716095209121704
    },
    {
    x: 0.9479823708534241,
    y: 0.7164636254310608
    },
    {
    x: 0.8268029689788818,
    y: 0.7164636254310608
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.803074836730957,
    bounding_box: [
    {
    x: 0.31669583916664124,
    y: 0.4400664269924164
    },
    {
    x: 0.4392823278903961,
    y: 0.4400664269924164
    },
    {
    x: 0.4392823278903961,
    y: 0.8242203593254089
    },
    {
    x: 0.31669583916664124,
    y: 0.8242203593254089
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.7960265874862671,
    bounding_box: [
    {
    x: 0.06988546997308731,
    y: 0.38201001286506653
    },
    {
    x: 0.1413266509771347,
    y: 0.38201001286506653
    },
    {
    x: 0.1413266509771347,
    y: 0.6272458434104919
    },
    {
    x: 0.06988546997308731,
    y: 0.6272458434104919
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.7832454442977905,
    bounding_box: [
    {
    x: 0.835197925567627,
    y: 0.3326256573200226
    },
    {
    x: 0.9451548457145691,
    y: 0.3326256573200226
    },
    {
    x: 0.9451548457145691,
    y: 0.5374599695205688
    },
    {
    x: 0.835197925567627,
    y: 0.5374599695205688
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7817971110343933,
    bounding_box: [
    {
    x: 0.9128268361091614,
    y: 0.394515722990036
    },
    {
    x: 0.9973958134651184,
    y: 0.394515722990036
    },
    {
    x: 0.9973958134651184,
    y: 0.9952969551086426
    },
    {
    x: 0.9128268361091614,
    y: 0.9952969551086426
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7687943577766418,
    bounding_box: [
    {
    x: 0.680325448513031,
    y: 0.5394332408905029
    },
    {
    x: 0.8723740577697754,
    y: 0.5394332408905029
    },
    {
    x: 0.8723740577697754,
    y: 0.9836533665657043
    },
    {
    x: 0.680325448513031,
    y: 0.9836533665657043
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28595,
    time_in_milliseconds: 954119,
    labels: [
    {
    description: "Fire",
    confidence: 95.26437520980835
    },
    {
    description: "Explosion",
    confidence: 86.43344044685364
    },
    {
    description: "Flame",
    confidence: 83.30721855163574
    },
    {
    description: "Heat",
    confidence: 82.87727236747742
    },
    {
    description: "News",
    confidence: 80.9249997138977
    },
    {
    description: "Luxury vehicle",
    confidence: 77.79097557067871
    },
    {
    description: "Event",
    confidence: 74.06390309333801
    },
    {
    description: "Fire marshal",
    confidence: 63.1140410900116
    },
    {
    description: "Firefighter",
    confidence: 61.96666359901428
    },
    {
    description: "Vehicle",
    confidence: 54.37730550765991
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8066311478614807,
    bounding_box: [
    {
    x: 0.4527842700481415,
    y: 0.28587666153907776
    },
    {
    x: 0.7933046817779541,
    y: 0.28587666153907776
    },
    {
    x: 0.7933046817779541,
    y: 0.8399065732955933
    },
    {
    x: 0.4527842700481415,
    y: 0.8399065732955933
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28711,
    time_in_milliseconds: 957990,
    labels: [
    {
    description: "Land vehicle",
    confidence: 99.61627125740051
    },
    {
    description: "Vehicle",
    confidence: 99.03031587600708
    },
    {
    description: "Motor vehicle",
    confidence: 97.83723950386047
    },
    {
    description: "Car",
    confidence: 95.31705975532532
    },
    {
    description: "Yellow",
    confidence: 89.36073184013367
    },
    {
    description: "Automotive design",
    confidence: 72.28396534919739
    },
    {
    description: "Automotive wheel system",
    confidence: 65.40399193763733
    },
    {
    description: "Classic car",
    confidence: 60.02411246299744
    },
    {
    description: "Graphics",
    confidence: 59.6038281917572
    },
    {
    description: "Custom car",
    confidence: 55.36571145057678
    }
    ],
    objects: [
    {
    description: "Wheel",
    confidence: 0.9023010730743408,
    bounding_box: [
    {
    x: 0.7773308753967285,
    y: 0.6023345589637756
    },
    {
    x: 0.914618730545044,
    y: 0.6023345589637756
    },
    {
    x: 0.914618730545044,
    y: 0.85589599609375
    },
    {
    x: 0.7773308753967285,
    y: 0.85589599609375
    }
    ]
    },
    {
    description: "Wheel",
    confidence: 0.8784664273262024,
    bounding_box: [
    {
    x: 0.26538097858428955,
    y: 0.6129083037376404
    },
    {
    x: 0.42128005623817444,
    y: 0.6129083037376404
    },
    {
    x: 0.42128005623817444,
    y: 0.8740528225898743
    },
    {
    x: 0.26538097858428955,
    y: 0.8740528225898743
    }
    ]
    },
    {
    description: "Wheel",
    confidence: 0.853278398513794,
    bounding_box: [
    {
    x: 0.1775888353586197,
    y: 0.6993066668510437
    },
    {
    x: 0.28334808349609375,
    y: 0.6993066668510437
    },
    {
    x: 0.28334808349609375,
    y: 0.8521483540534973
    },
    {
    x: 0.1775888353586197,
    y: 0.8521483540534973
    }
    ]
    },
    {
    description: "Wheel",
    confidence: 0.8484087586402893,
    bounding_box: [
    {
    x: 0.6758086681365967,
    y: 0.619181215763092
    },
    {
    x: 0.7868382930755615,
    y: 0.619181215763092
    },
    {
    x: 0.7868382930755615,
    y: 0.8218398690223694
    },
    {
    x: 0.6758086681365967,
    y: 0.8218398690223694
    }
    ]
    },
    {
    description: "Toy vehicle",
    confidence: 0.645918607711792,
    bounding_box: [
    {
    x: 0.04074987396597862,
    y: 0.2150295227766037
    },
    {
    x: 0.9623035788536072,
    y: 0.2150295227766037
    },
    {
    x: 0.9623035788536072,
    y: 0.8779010772705078
    },
    {
    x: 0.04074987396597862,
    y: 0.8779010772705078
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28740,
    time_in_milliseconds: 958958.0000000001,
    labels: [
    {
    description: "Motor vehicle",
    confidence: 97.83723950386047
    },
    {
    description: "Vehicle",
    confidence: 94.05226111412048
    },
    {
    description: "Mode of transport",
    confidence: 90.29945135116577
    },
    {
    description: "Yellow",
    confidence: 89.36073184013367
    },
    {
    description: "Transport",
    confidence: 86.29129528999329
    },
    {
    description: "Car",
    confidence: 84.02158617973328
    },
    {
    description: "Automotive design",
    confidence: 82.15083479881287
    },
    {
    description: "Cartoon",
    confidence: 81.18122220039368
    },
    {
    description: "Illustration",
    confidence: 77.99686789512634
    },
    {
    description: "Monster truck",
    confidence: 72.14975953102112
    }
    ],
    objects: [
    {
    description: "Wheel",
    confidence: 0.807952880859375,
    bounding_box: [
    {
    x: 0.7779597640037537,
    y: 0.6046921610832214
    },
    {
    x: 0.9157887101173401,
    y: 0.6046921610832214
    },
    {
    x: 0.9157887101173401,
    y: 0.8559393882751465
    },
    {
    x: 0.7779597640037537,
    y: 0.8559393882751465
    }
    ]
    },
    {
    description: "Tire",
    confidence: 0.657607913017273,
    bounding_box: [
    {
    x: 0.2711624801158905,
    y: 0.6644232273101807
    },
    {
    x: 0.41165030002593994,
    y: 0.6644232273101807
    },
    {
    x: 0.41165030002593994,
    y: 0.8687081933021545
    },
    {
    x: 0.2711624801158905,
    y: 0.8687081933021545
    }
    ]
    },
    {
    description: "Toy vehicle",
    confidence: 0.561008095741272,
    bounding_box: [
    {
    x: 0.04201899841427803,
    y: 0.19259560108184814
    },
    {
    x: 0.9665394425392151,
    y: 0.19259560108184814
    },
    {
    x: 0.9665394425392151,
    y: 0.8788358569145203
    },
    {
    x: 0.04201899841427803,
    y: 0.8788358569145203
    }
    ]
    },
    {
    description: "Wheel",
    confidence: 0.5414011478424072,
    bounding_box: [
    {
    x: 0.2418094128370285,
    y: 0.6552162766456604
    },
    {
    x: 0.420312762260437,
    y: 0.6552162766456604
    },
    {
    x: 0.420312762260437,
    y: 0.8700168132781982
    },
    {
    x: 0.2418094128370285,
    y: 0.8700168132781982
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 28856,
    time_in_milliseconds: 962828.0000000001,
    labels: [
    {
    description: "People",
    confidence: 95.15836238861084
    },
    {
    description: "Crowd",
    confidence: 84.30541753768921
    },
    {
    description: "Youth",
    confidence: 81.82269334793091
    },
    {
    description: "Community",
    confidence: 81.46491646766663
    },
    {
    description: "Technology",
    confidence: 73.20890426635742
    },
    {
    description: "Event",
    confidence: 62.736958265304565
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    },
    {
    description: "Passenger",
    confidence: 60.477542877197266
    },
    {
    description: "Selfie",
    confidence: 57.72786736488342
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.849783182144165,
    bounding_box: [
    {
    x: 0.7666164040565491,
    y: 0.4653671979904175
    },
    {
    x: 0.8306725025177002,
    y: 0.4653671979904175
    },
    {
    x: 0.8306725025177002,
    y: 0.6803731918334961
    },
    {
    x: 0.7666164040565491,
    y: 0.6803731918334961
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8440467715263367,
    bounding_box: [
    {
    x: 0.3272242248058319,
    y: 0.4807296693325043
    },
    {
    x: 0.3895523250102997,
    y: 0.4807296693325043
    },
    {
    x: 0.3895523250102997,
    y: 0.7750890851020813
    },
    {
    x: 0.3272242248058319,
    y: 0.7750890851020813
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8419367671012878,
    bounding_box: [
    {
    x: 0.5699077248573303,
    y: 0.49074411392211914
    },
    {
    x: 0.634086012840271,
    y: 0.49074411392211914
    },
    {
    x: 0.634086012840271,
    y: 0.8436985611915588
    },
    {
    x: 0.5699077248573303,
    y: 0.8436985611915588
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8384332060813904,
    bounding_box: [
    {
    x: 0.23291660845279694,
    y: 0.46616873145103455
    },
    {
    x: 0.29343894124031067,
    y: 0.46616873145103455
    },
    {
    x: 0.29343894124031067,
    y: 0.809873104095459
    },
    {
    x: 0.23291660845279694,
    y: 0.809873104095459
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8289133310317993,
    bounding_box: [
    {
    x: 0.3899194002151489,
    y: 0.5359116196632385
    },
    {
    x: 0.4747548997402191,
    y: 0.5359116196632385
    },
    {
    x: 0.4747548997402191,
    y: 0.8218593597412109
    },
    {
    x: 0.3899194002151489,
    y: 0.8218593597412109
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.826506495475769,
    bounding_box: [
    {
    x: 0.4838516414165497,
    y: 0.558323085308075
    },
    {
    x: 0.5512875318527222,
    y: 0.558323085308075
    },
    {
    x: 0.5512875318527222,
    y: 0.8374610543251038
    },
    {
    x: 0.4838516414165497,
    y: 0.8374610543251038
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8067607283592224,
    bounding_box: [
    {
    x: 0.740031898021698,
    y: 0.42253339290618896
    },
    {
    x: 0.7890431880950928,
    y: 0.42253339290618896
    },
    {
    x: 0.7890431880950928,
    y: 0.7119743227958679
    },
    {
    x: 0.740031898021698,
    y: 0.7119743227958679
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7062302827835083,
    bounding_box: [
    {
    x: 0.6388725638389587,
    y: 0.5218028426170349
    },
    {
    x: 0.7072663307189941,
    y: 0.5218028426170349
    },
    {
    x: 0.7072663307189941,
    y: 0.8438327312469482
    },
    {
    x: 0.6388725638389587,
    y: 0.8438327312469482
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.684425413608551,
    bounding_box: [
    {
    x: 0.6026670336723328,
    y: 0.39252936840057373
    },
    {
    x: 0.6526666283607483,
    y: 0.39252936840057373
    },
    {
    x: 0.6526666283607483,
    y: 0.583828866481781
    },
    {
    x: 0.6026670336723328,
    y: 0.583828866481781
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6801176071166992,
    bounding_box: [
    {
    x: 0.3898651897907257,
    y: 0.5591837763786316
    },
    {
    x: 0.4745423495769501,
    y: 0.5591837763786316
    },
    {
    x: 0.4745423495769501,
    y: 0.7964015007019043
    },
    {
    x: 0.3898651897907257,
    y: 0.7964015007019043
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29001,
    time_in_milliseconds: 967666,
    labels: [
    {
    description: "Crowd",
    confidence: 98.8213062286377
    },
    {
    description: "People",
    confidence: 97.70232439041138
    },
    {
    description: "Protest",
    confidence: 96.8468964099884
    },
    {
    description: "Fan",
    confidence: 94.4879412651062
    },
    {
    description: "Event",
    confidence: 90.3218150138855
    },
    {
    description: "Audience",
    confidence: 88.92759680747986
    },
    {
    description: "Product",
    confidence: 88.28333020210266
    },
    {
    description: "Public event",
    confidence: 85.96760630607605
    },
    {
    description: "Demonstration",
    confidence: 84.7669780254364
    },
    {
    description: "Rebellion",
    confidence: 84.23609137535095
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8249065279960632,
    bounding_box: [
    {
    x: 0.31664958596229553,
    y: 0.6398568153381348
    },
    {
    x: 0.46134862303733826,
    y: 0.6398568153381348
    },
    {
    x: 0.46134862303733826,
    y: 0.99238520860672
    },
    {
    x: 0.31664958596229553,
    y: 0.99238520860672
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8052622079849243,
    bounding_box: [
    {
    x: 0.4625433385372162,
    y: 0.7969455718994141
    },
    {
    x: 0.5921227335929871,
    y: 0.7969455718994141
    },
    {
    x: 0.5921227335929871,
    y: 0.9939412474632263
    },
    {
    x: 0.4625433385372162,
    y: 0.9939412474632263
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7985256910324097,
    bounding_box: [
    {
    x: 0.1086946651339531,
    y: 0.2781725227832794
    },
    {
    x: 0.1862066239118576,
    y: 0.2781725227832794
    },
    {
    x: 0.1862066239118576,
    y: 0.45953747630119324
    },
    {
    x: 0.1086946651339531,
    y: 0.45953747630119324
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.768151044845581,
    bounding_box: [
    {
    x: 0.22894752025604248,
    y: 0.29835575819015503
    },
    {
    x: 0.2860719859600067,
    y: 0.29835575819015503
    },
    {
    x: 0.2860719859600067,
    y: 0.4782566726207733
    },
    {
    x: 0.22894752025604248,
    y: 0.4782566726207733
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.7420607209205627,
    bounding_box: [
    {
    x: 0.10864906758069992,
    y: 0.29259994626045227
    },
    {
    x: 0.18613576889038086,
    y: 0.29259994626045227
    },
    {
    x: 0.18613576889038086,
    y: 0.4636867940425873
    },
    {
    x: 0.10864906758069992,
    y: 0.4636867940425873
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.7167481184005737,
    bounding_box: [
    {
    x: 0.8496124148368835,
    y: 0.7563549876213074
    },
    {
    x: 0.9339504241943359,
    y: 0.7563549876213074
    },
    {
    x: 0.9339504241943359,
    y: 0.941986083984375
    },
    {
    x: 0.8496124148368835,
    y: 0.941986083984375
    }
    ]
    },
    {
    description: "Flag",
    confidence: 0.7082943916320801,
    bounding_box: [
    {
    x: 0.41974756121635437,
    y: 0.05316232144832611
    },
    {
    x: 0.6698911786079407,
    y: 0.05316232144832611
    },
    {
    x: 0.6698911786079407,
    y: 0.6674187779426575
    },
    {
    x: 0.41974756121635437,
    y: 0.6674187779426575
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.7017236948013306,
    bounding_box: [
    {
    x: 0.3173249065876007,
    y: 0.6698676943778992
    },
    {
    x: 0.4633052349090576,
    y: 0.6698676943778992
    },
    {
    x: 0.4633052349090576,
    y: 0.995467483997345
    },
    {
    x: 0.3173249065876007,
    y: 0.995467483997345
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6919493675231934,
    bounding_box: [
    {
    x: 0.04640002176165581,
    y: 0.31767427921295166
    },
    {
    x: 0.11136898398399353,
    y: 0.31767427921295166
    },
    {
    x: 0.11136898398399353,
    y: 0.48318609595298767
    },
    {
    x: 0.04640002176165581,
    y: 0.48318609595298767
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6744061708450317,
    bounding_box: [
    {
    x: 0.491462379693985,
    y: 0.6685380339622498
    },
    {
    x: 0.6034168601036072,
    y: 0.6685380339622498
    },
    {
    x: 0.6034168601036072,
    y: 0.8682872653007507
    },
    {
    x: 0.491462379693985,
    y: 0.8682872653007507
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29117,
    time_in_milliseconds: 971537,
    labels: [
    {
    description: "Conversation",
    confidence: 83.48562121391296
    },
    {
    description: "Fun",
    confidence: 76.84727907180786
    },
    {
    description: "Interaction",
    confidence: 73.0389416217804
    },
    {
    description: "Adaptation",
    confidence: 66.96612238883972
    },
    {
    description: "Happy",
    confidence: 64.1736388206482
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    },
    {
    description: "Gesture",
    confidence: 63.760846853256226
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Temple",
    confidence: 61.13089919090271
    },
    {
    description: "Leisure",
    confidence: 56.186121702194214
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8488550186157227,
    bounding_box: [
    {
    x: 0.5395681262016296,
    y: 0.009575014002621174
    },
    {
    x: 0.8974443078041077,
    y: 0.009575014002621174
    },
    {
    x: 0.8974443078041077,
    y: 0.9908307194709778
    },
    {
    x: 0.5395681262016296,
    y: 0.9908307194709778
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8130210041999817,
    bounding_box: [
    {
    x: 0.0021113313268870115,
    y: 0.04290200397372246
    },
    {
    x: 0.38635075092315674,
    y: 0.04290200397372246
    },
    {
    x: 0.38635075092315674,
    y: 0.9973958134651184
    },
    {
    x: 0.0021113313268870115,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.739829421043396,
    bounding_box: [
    {
    x: 0.18503688275814056,
    y: 0.558437705039978
    },
    {
    x: 0.43891096115112305,
    y: 0.558437705039978
    },
    {
    x: 0.43891096115112305,
    y: 0.8213629722595215
    },
    {
    x: 0.18503688275814056,
    y: 0.8213629722595215
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6640710234642029,
    bounding_box: [
    {
    x: 0.5675632953643799,
    y: 0.12496738880872726
    },
    {
    x: 0.8950648903846741,
    y: 0.12496738880872726
    },
    {
    x: 0.8950648903846741,
    y: 0.9915027618408203
    },
    {
    x: 0.5675632953643799,
    y: 0.9915027618408203
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.5566893815994263,
    bounding_box: [
    {
    x: 0.0030689279083162546,
    y: 0.463409423828125
    },
    {
    x: 0.12222999334335327,
    y: 0.463409423828125
    },
    {
    x: 0.12222999334335327,
    y: 0.9973958134651184
    },
    {
    x: 0.0030689279083162546,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5071151256561279,
    bounding_box: [
    {
    x: 0.011015844531357288,
    y: 0.43657270073890686
    },
    {
    x: 0.3166866600513458,
    y: 0.43657270073890686
    },
    {
    x: 0.3166866600513458,
    y: 0.9922455549240112
    },
    {
    x: 0.011015844531357288,
    y: 0.9922455549240112
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29204,
    time_in_milliseconds: 974440,
    labels: [
    {
    description: "Protest",
    confidence: 97.96529412269592
    },
    {
    description: "People",
    confidence: 95.61020135879517
    },
    {
    description: "Public event",
    confidence: 92.30413436889648
    },
    {
    description: "Crowd",
    confidence: 86.74113750457764
    },
    {
    description: "Event",
    confidence: 83.80592465400696
    },
    {
    description: "Community",
    confidence: 83.49722623825073
    },
    {
    description: "Pedestrian",
    confidence: 75.12237429618835
    },
    {
    description: "Tree",
    confidence: 68.69218349456787
    },
    {
    description: "Rebellion",
    confidence: 56.887584924697876
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8710588812828064,
    bounding_box: [
    {
    x: 0.6400393843650818,
    y: 0.5122376084327698
    },
    {
    x: 0.7759127616882324,
    y: 0.5122376084327698
    },
    {
    x: 0.7759127616882324,
    y: 0.988907516002655
    },
    {
    x: 0.6400393843650818,
    y: 0.988907516002655
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8510081171989441,
    bounding_box: [
    {
    x: 0.5188074111938477,
    y: 0.4914107024669647
    },
    {
    x: 0.6882560849189758,
    y: 0.4914107024669647
    },
    {
    x: 0.6882560849189758,
    y: 0.9926611185073853
    },
    {
    x: 0.5188074111938477,
    y: 0.9926611185073853
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8340667486190796,
    bounding_box: [
    {
    x: 0.08986262232065201,
    y: 0.6431642174720764
    },
    {
    x: 0.2035457342863083,
    y: 0.6431642174720764
    },
    {
    x: 0.2035457342863083,
    y: 0.9927988648414612
    },
    {
    x: 0.08986262232065201,
    y: 0.9927988648414612
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8089057803153992,
    bounding_box: [
    {
    x: 0.1897820234298706,
    y: 0.3431991636753082
    },
    {
    x: 0.4496401846408844,
    y: 0.3431991636753082
    },
    {
    x: 0.4496401846408844,
    y: 0.9973957538604736
    },
    {
    x: 0.1897820234298706,
    y: 0.9973957538604736
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6599243879318237,
    bounding_box: [
    {
    x: 0.10524636507034302,
    y: 0.730134904384613
    },
    {
    x: 0.19198286533355713,
    y: 0.730134904384613
    },
    {
    x: 0.19198286533355713,
    y: 0.9782246947288513
    },
    {
    x: 0.10524636507034302,
    y: 0.9782246947288513
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6437259912490845,
    bounding_box: [
    {
    x: 0.7471716403961182,
    y: 0.533602237701416
    },
    {
    x: 0.8622443675994873,
    y: 0.533602237701416
    },
    {
    x: 0.8622443675994873,
    y: 0.9343525767326355
    },
    {
    x: 0.7471716403961182,
    y: 0.9343525767326355
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6408773064613342,
    bounding_box: [
    {
    x: 0.2152368426322937,
    y: 0.5216628313064575
    },
    {
    x: 0.3797600269317627,
    y: 0.5216628313064575
    },
    {
    x: 0.3797600269317627,
    y: 0.9966232776641846
    },
    {
    x: 0.2152368426322937,
    y: 0.9966232776641846
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6224674582481384,
    bounding_box: [
    {
    x: 0.6656472086906433,
    y: 0.6751847267150879
    },
    {
    x: 0.7623134255409241,
    y: 0.6751847267150879
    },
    {
    x: 0.7623134255409241,
    y: 0.9079296588897705
    },
    {
    x: 0.6656472086906433,
    y: 0.9079296588897705
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5822689533233643,
    bounding_box: [
    {
    x: 0.5291040539741516,
    y: 0.6645670533180237
    },
    {
    x: 0.6593823432922363,
    y: 0.6645670533180237
    },
    {
    x: 0.6593823432922363,
    y: 0.99124675989151
    },
    {
    x: 0.5291040539741516,
    y: 0.99124675989151
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5775228142738342,
    bounding_box: [
    {
    x: 0.8733934760093689,
    y: 0.5286224484443665
    },
    {
    x: 0.9932789206504822,
    y: 0.5286224484443665
    },
    {
    x: 0.9932789206504822,
    y: 0.8640528321266174
    },
    {
    x: 0.8733934760093689,
    y: 0.8640528321266174
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29233,
    time_in_milliseconds: 975408,
    labels: [
    {
    description: "Pedestrian",
    confidence: 88.823002576828
    },
    {
    description: "Public event",
    confidence: 85.3402853012085
    },
    {
    description: "Protest",
    confidence: 83.5558831691742
    },
    {
    description: "Event",
    confidence: 74.06390309333801
    },
    {
    description: "Crowd",
    confidence: 69.15826201438904
    },
    {
    description: "Street",
    confidence: 66.2841260433197
    },
    {
    description: "Thoroughfare",
    confidence: 57.66003131866455
    },
    {
    description: "Road",
    confidence: 52.706509828567505
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9067507386207581,
    bounding_box: [
    {
    x: 0.23050427436828613,
    y: 0.6087176203727722
    },
    {
    x: 0.2955511808395386,
    y: 0.6087176203727722
    },
    {
    x: 0.2955511808395386,
    y: 0.8513241410255432
    },
    {
    x: 0.23050427436828613,
    y: 0.8513241410255432
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8737933039665222,
    bounding_box: [
    {
    x: 0.772064745426178,
    y: 0.4825446605682373
    },
    {
    x: 0.8654609322547913,
    y: 0.4825446605682373
    },
    {
    x: 0.8654609322547913,
    y: 0.9205928444862366
    },
    {
    x: 0.772064745426178,
    y: 0.9205928444862366
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8477993011474609,
    bounding_box: [
    {
    x: 0.672607421875,
    y: 0.4646809995174408
    },
    {
    x: 0.7703559398651123,
    y: 0.4646809995174408
    },
    {
    x: 0.7703559398651123,
    y: 0.8344666957855225
    },
    {
    x: 0.672607421875,
    y: 0.8344666957855225
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8412140011787415,
    bounding_box: [
    {
    x: 0.8543724417686462,
    y: 0.46631792187690735
    },
    {
    x: 0.9036970138549805,
    y: 0.46631792187690735
    },
    {
    x: 0.9036970138549805,
    y: 0.6731262803077698
    },
    {
    x: 0.8543724417686462,
    y: 0.6731262803077698
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8217461705207825,
    bounding_box: [
    {
    x: 0.3769497871398926,
    y: 0.4371391534805298
    },
    {
    x: 0.503030002117157,
    y: 0.4371391534805298
    },
    {
    x: 0.503030002117157,
    y: 0.99028080701828
    },
    {
    x: 0.3769497871398926,
    y: 0.99028080701828
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8191493153572083,
    bounding_box: [
    {
    x: 0.8956170678138733,
    y: 0.47139477729797363
    },
    {
    x: 0.9488185048103333,
    y: 0.47139477729797363
    },
    {
    x: 0.9488185048103333,
    y: 0.6814847588539124
    },
    {
    x: 0.8956170678138733,
    y: 0.6814847588539124
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8063639402389526,
    bounding_box: [
    {
    x: 0.9493476748466492,
    y: 0.4414398968219757
    },
    {
    x: 0.9973958134651184,
    y: 0.4414398968219757
    },
    {
    x: 0.9973958134651184,
    y: 0.8528135418891907
    },
    {
    x: 0.9493476748466492,
    y: 0.8528135418891907
    }
    ]
    },
    {
    description: "Footwear",
    confidence: 0.6047313809394836,
    bounding_box: [
    {
    x: 0.8091488480567932,
    y: 0.8082637190818787
    },
    {
    x: 0.8878860473632812,
    y: 0.8082637190818787
    },
    {
    x: 0.8878860473632812,
    y: 0.9354327321052551
    },
    {
    x: 0.8091488480567932,
    y: 0.9354327321052551
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6022819876670837,
    bounding_box: [
    {
    x: 0.3909243047237396,
    y: 0.5137026906013489
    },
    {
    x: 0.4823733866214752,
    y: 0.5137026906013489
    },
    {
    x: 0.4823733866214752,
    y: 0.7538201212882996
    },
    {
    x: 0.3909243047237396,
    y: 0.7538201212882996
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5896780490875244,
    bounding_box: [
    {
    x: 0.4820280373096466,
    y: 0.5758911371231079
    },
    {
    x: 0.5261548757553101,
    y: 0.5758911371231079
    },
    {
    x: 0.5261548757553101,
    y: 0.7908868193626404
    },
    {
    x: 0.4820280373096466,
    y: 0.7908868193626404
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29320,
    time_in_milliseconds: 978310.0000000001,
    labels: [
    {
    description: "Crowd",
    confidence: 95.18840312957764
    },
    {
    description: "People",
    confidence: 94.33480501174927
    },
    {
    description: "Pedestrian",
    confidence: 92.90128350257874
    },
    {
    description: "Public space",
    confidence: 91.27401113510132
    },
    {
    description: "Town",
    confidence: 89.08006548881531
    },
    {
    description: "Walking",
    confidence: 83.2370400428772
    },
    {
    description: "Human settlement",
    confidence: 82.87564516067505
    },
    {
    description: "Neighbourhood",
    confidence: 81.39209151268005
    },
    {
    description: "Tourism",
    confidence: 80.66632747650146
    },
    {
    description: "Community",
    confidence: 80.28326034545898
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8924568295478821,
    bounding_box: [
    {
    x: 0.32975509762763977,
    y: 0.6834233403205872
    },
    {
    x: 0.3893028199672699,
    y: 0.6834233403205872
    },
    {
    x: 0.3893028199672699,
    y: 0.9860696792602539
    },
    {
    x: 0.32975509762763977,
    y: 0.9860696792602539
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8867788314819336,
    bounding_box: [
    {
    x: 0.6547377705574036,
    y: 0.6833248138427734
    },
    {
    x: 0.7092320322990417,
    y: 0.6833248138427734
    },
    {
    x: 0.7092320322990417,
    y: 0.9611286520957947
    },
    {
    x: 0.6547377705574036,
    y: 0.9611286520957947
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.880944550037384,
    bounding_box: [
    {
    x: 0.700446367263794,
    y: 0.694601833820343
    },
    {
    x: 0.7703379988670349,
    y: 0.694601833820343
    },
    {
    x: 0.7703379988670349,
    y: 0.9892973303794861
    },
    {
    x: 0.700446367263794,
    y: 0.9892973303794861
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8789648413658142,
    bounding_box: [
    {
    x: 0.27567216753959656,
    y: 0.6785943508148193
    },
    {
    x: 0.33204352855682373,
    y: 0.6785943508148193
    },
    {
    x: 0.33204352855682373,
    y: 0.9549657702445984
    },
    {
    x: 0.27567216753959656,
    y: 0.9549657702445984
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8728169798851013,
    bounding_box: [
    {
    x: 0.5757836699485779,
    y: 0.6901703476905823
    },
    {
    x: 0.6350712776184082,
    y: 0.6901703476905823
    },
    {
    x: 0.6350712776184082,
    y: 0.95980304479599
    },
    {
    x: 0.5757836699485779,
    y: 0.95980304479599
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8625478148460388,
    bounding_box: [
    {
    x: 0.376897931098938,
    y: 0.6836560368537903
    },
    {
    x: 0.4319257438182831,
    y: 0.6836560368537903
    },
    {
    x: 0.4319257438182831,
    y: 0.9654802680015564
    },
    {
    x: 0.376897931098938,
    y: 0.9654802680015564
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8566900491714478,
    bounding_box: [
    {
    x: 0.1888139694929123,
    y: 0.6742004752159119
    },
    {
    x: 0.2599541246891022,
    y: 0.6742004752159119
    },
    {
    x: 0.2599541246891022,
    y: 0.9770479202270508
    },
    {
    x: 0.1888139694929123,
    y: 0.9770479202270508
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8543177843093872,
    bounding_box: [
    {
    x: 0.7657735347747803,
    y: 0.7034640908241272
    },
    {
    x: 0.8239536285400391,
    y: 0.7034640908241272
    },
    {
    x: 0.8239536285400391,
    y: 0.9540884494781494
    },
    {
    x: 0.7657735347747803,
    y: 0.9540884494781494
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8531022667884827,
    bounding_box: [
    {
    x: 0.4970666170120239,
    y: 0.6479405164718628
    },
    {
    x: 0.5887596011161804,
    y: 0.6479405164718628
    },
    {
    x: 0.5887596011161804,
    y: 0.988777220249176
    },
    {
    x: 0.4970666170120239,
    y: 0.988777220249176
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.813211977481842,
    bounding_box: [
    {
    x: 0.8474399447441101,
    y: 0.6669042110443115
    },
    {
    x: 0.9249313473701477,
    y: 0.6669042110443115
    },
    {
    x: 0.9249313473701477,
    y: 0.9500067830085754
    },
    {
    x: 0.8474399447441101,
    y: 0.9500067830085754
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29436,
    time_in_milliseconds: 982181,
    labels: [
    {
    description: "Motor vehicle",
    confidence: 90.10037183761597
    },
    {
    description: "Crowd",
    confidence: 88.70906829833984
    },
    {
    description: "Public event",
    confidence: 79.49384450912476
    },
    {
    description: "Event",
    confidence: 77.15283632278442
    },
    {
    description: "Vehicle",
    confidence: 77.12700963020325
    },
    {
    description: "Tree",
    confidence: 74.69744682312012
    },
    {
    description: "Spring",
    confidence: 71.86405658721924
    },
    {
    description: "Pedestrian",
    confidence: 68.28728914260864
    },
    {
    description: "Plant",
    confidence: 68.2474136352539
    },
    {
    description: "Flower",
    confidence: 67.10947155952454
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8370022773742676,
    bounding_box: [
    {
    x: 0.19667817652225494,
    y: 0.6770321726799011
    },
    {
    x: 0.27424356341362,
    y: 0.6770321726799011
    },
    {
    x: 0.27424356341362,
    y: 0.9777267575263977
    },
    {
    x: 0.19667817652225494,
    y: 0.9777267575263977
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8297457695007324,
    bounding_box: [
    {
    x: 0.9140334725379944,
    y: 0.6237647533416748
    },
    {
    x: 0.9962480068206787,
    y: 0.6237647533416748
    },
    {
    x: 0.9962480068206787,
    y: 0.9934191703796387
    },
    {
    x: 0.9140334725379944,
    y: 0.9934191703796387
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8269686102867126,
    bounding_box: [
    {
    x: 0.3157564103603363,
    y: 0.6704020500183105
    },
    {
    x: 0.38938185572624207,
    y: 0.6704020500183105
    },
    {
    x: 0.38938185572624207,
    y: 0.9561417698860168
    },
    {
    x: 0.3157564103603363,
    y: 0.9561417698860168
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8052550554275513,
    bounding_box: [
    {
    x: 0.2768392562866211,
    y: 0.6819918751716614
    },
    {
    x: 0.3291434645652771,
    y: 0.6819918751716614
    },
    {
    x: 0.3291434645652771,
    y: 0.9556043744087219
    },
    {
    x: 0.2768392562866211,
    y: 0.9556043744087219
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7706536650657654,
    bounding_box: [
    {
    x: 0.8784460425376892,
    y: 0.6504554748535156
    },
    {
    x: 0.931187093257904,
    y: 0.6504554748535156
    },
    {
    x: 0.931187093257904,
    y: 0.8259108066558838
    },
    {
    x: 0.8784460425376892,
    y: 0.8259108066558838
    }
    ]
    },
    {
    description: "Car",
    confidence: 0.7283015251159668,
    bounding_box: [
    {
    x: 0.603298008441925,
    y: 0.6806047558784485
    },
    {
    x: 0.9406843781471252,
    y: 0.6806047558784485
    },
    {
    x: 0.9406843781471252,
    y: 0.993890106678009
    },
    {
    x: 0.603298008441925,
    y: 0.993890106678009
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5849688649177551,
    bounding_box: [
    {
    x: 0.9235565066337585,
    y: 0.6839823126792908
    },
    {
    x: 0.9966258406639099,
    y: 0.6839823126792908
    },
    {
    x: 0.9966258406639099,
    y: 0.8764422535896301
    },
    {
    x: 0.9235565066337585,
    y: 0.8764422535896301
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5316160917282104,
    bounding_box: [
    {
    x: 0.315745085477829,
    y: 0.6676910519599915
    },
    {
    x: 0.39317095279693604,
    y: 0.6676910519599915
    },
    {
    x: 0.39317095279693604,
    y: 0.9299162030220032
    },
    {
    x: 0.315745085477829,
    y: 0.9299162030220032
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.514525830745697,
    bounding_box: [
    {
    x: 0.3439130485057831,
    y: 0.8571365475654602
    },
    {
    x: 0.6533641219139099,
    y: 0.8571365475654602
    },
    {
    x: 0.6533641219139099,
    y: 0.9968141913414001
    },
    {
    x: 0.3439130485057831,
    y: 0.9968141913414001
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29523,
    time_in_milliseconds: 985084.0000000001,
    labels: [
    {
    description: "Speech",
    confidence: 89.76832032203674
    },
    {
    description: "Official",
    confidence: 88.71045112609863
    },
    {
    description: "Speaker",
    confidence: 77.15585827827454
    },
    {
    description: "Government",
    confidence: 76.95707678794861
    },
    {
    description: "Suit",
    confidence: 70.87061405181885
    },
    {
    description: "Public speaking",
    confidence: 70.49763202667236
    },
    {
    description: "Event",
    confidence: 67.04323887825012
    },
    {
    description: "Spokesperson",
    confidence: 52.18558311462402
    }
    ],
    objects: [
    {
    description: "Tie",
    confidence: 0.9487029910087585,
    bounding_box: [
    {
    x: 0.4654732048511505,
    y: 0.40704527497291565
    },
    {
    x: 0.5183584094047546,
    y: 0.40704527497291565
    },
    {
    x: 0.5183584094047546,
    y: 0.7769780158996582
    },
    {
    x: 0.4654732048511505,
    y: 0.7769780158996582
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8413057327270508,
    bounding_box: [
    {
    x: 0.30959850549697876,
    y: 0.09862590581178665
    },
    {
    x: 0.6619361639022827,
    y: 0.09862590581178665
    },
    {
    x: 0.6619361639022827,
    y: 0.9850614070892334
    },
    {
    x: 0.30959850549697876,
    y: 0.9850614070892334
    }
    ]
    },
    {
    description: "Chair",
    confidence: 0.6382374167442322,
    bounding_box: [
    {
    x: 0.6275741457939148,
    y: 0.20467786490917206
    },
    {
    x: 0.8811277747154236,
    y: 0.20467786490917206
    },
    {
    x: 0.8811277747154236,
    y: 0.5426689982414246
    },
    {
    x: 0.6275741457939148,
    y: 0.5426689982414246
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29552,
    time_in_milliseconds: 986051,
    labels: [
    {
    description: "Design",
    confidence: 76.26352906227112
    },
    {
    description: "Art",
    confidence: 73.99447560310364
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Collage",
    confidence: 56.94475769996643
    },
    {
    description: "Media",
    confidence: 53.86474132537842
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8784640431404114,
    bounding_box: [
    {
    x: 0.4247150719165802,
    y: 0.006288114469498396
    },
    {
    x: 0.5424996018409729,
    y: 0.006288114469498396
    },
    {
    x: 0.5424996018409729,
    y: 0.20491428673267365
    },
    {
    x: 0.4247150719165802,
    y: 0.20491428673267365
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8700373768806458,
    bounding_box: [
    {
    x: 0.6231049299240112,
    y: 0.006340184714645147
    },
    {
    x: 0.7366674542427063,
    y: 0.006340184714645147
    },
    {
    x: 0.7366674542427063,
    y: 0.20325429737567902
    },
    {
    x: 0.6231049299240112,
    y: 0.20325429737567902
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8661820888519287,
    bounding_box: [
    {
    x: 0.8313813209533691,
    y: 0.004201550967991352
    },
    {
    x: 0.9488521218299866,
    y: 0.004201550967991352
    },
    {
    x: 0.9488521218299866,
    y: 0.20459258556365967
    },
    {
    x: 0.8313813209533691,
    y: 0.20459258556365967
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8646804094314575,
    bounding_box: [
    {
    x: 0.20292870700359344,
    y: 0.276498407125473
    },
    {
    x: 0.6869620680809021,
    y: 0.276498407125473
    },
    {
    x: 0.6869620680809021,
    y: 0.995476484298706
    },
    {
    x: 0.20292870700359344,
    y: 0.995476484298706
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8355840444564819,
    bounding_box: [
    {
    x: 0.17774422466754913,
    y: 0.006829535122960806
    },
    {
    x: 0.3527153730392456,
    y: 0.006829535122960806
    },
    {
    x: 0.3527153730392456,
    y: 0.20556168258190155
    },
    {
    x: 0.17774422466754913,
    y: 0.20556168258190155
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7905954718589783,
    bounding_box: [
    {
    x: 0.18107424676418304,
    y: 0.2332015484571457
    },
    {
    x: 0.6869065761566162,
    y: 0.2332015484571457
    },
    {
    x: 0.6869065761566162,
    y: 0.99454665184021
    },
    {
    x: 0.18107424676418304,
    y: 0.99454665184021
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7532387971878052,
    bounding_box: [
    {
    x: 0.010073392651975155,
    y: 0.006596968974918127
    },
    {
    x: 0.13795803487300873,
    y: 0.006596968974918127
    },
    {
    x: 0.13795803487300873,
    y: 0.20447897911071777
    },
    {
    x: 0.010073392651975155,
    y: 0.20447897911071777
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6774380803108215,
    bounding_box: [
    {
    x: 0.19474484026432037,
    y: 0.600050151348114
    },
    {
    x: 0.6852798461914062,
    y: 0.600050151348114
    },
    {
    x: 0.6852798461914062,
    y: 0.9887957572937012
    },
    {
    x: 0.19474484026432037,
    y: 0.9887957572937012
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6423359513282776,
    bounding_box: [
    {
    x: 0.20901070535182953,
    y: 0.5833891034126282
    },
    {
    x: 0.6882277131080627,
    y: 0.5833891034126282
    },
    {
    x: 0.6882277131080627,
    y: 0.9869473576545715
    },
    {
    x: 0.20901070535182953,
    y: 0.9869473576545715
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5984349250793457,
    bounding_box: [
    {
    x: 0.7388831973075867,
    y: 0.5623485445976257
    },
    {
    x: 0.8069534301757812,
    y: 0.5623485445976257
    },
    {
    x: 0.8069534301757812,
    y: 0.7460243701934814
    },
    {
    x: 0.7388831973075867,
    y: 0.7460243701934814
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29581,
    time_in_milliseconds: 987020.0000000001,
    labels: [
    {
    description: "Photograph",
    confidence: 95.27485370635986
    },
    {
    description: "Ceremony",
    confidence: 94.15773749351501
    },
    {
    description: "Event",
    confidence: 92.72708296775818
    },
    {
    description: "Function hall",
    confidence: 91.9115960597992
    },
    {
    description: "Wedding",
    confidence: 88.72620463371277
    },
    {
    description: "Dress",
    confidence: 86.69325709342957
    },
    {
    description: "Wedding reception",
    confidence: 85.58270931243896
    },
    {
    description: "Bride",
    confidence: 84.37628149986267
    },
    {
    description: "Tradition",
    confidence: 84.01296138763428
    },
    {
    description: "Fashion",
    confidence: 77.71079540252686
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9148250818252563,
    bounding_box: [
    {
    x: 0.6243739724159241,
    y: 0.453824520111084
    },
    {
    x: 0.6904556155204773,
    y: 0.453824520111084
    },
    {
    x: 0.6904556155204773,
    y: 0.7622979283332825
    },
    {
    x: 0.6243739724159241,
    y: 0.7622979283332825
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.9072811603546143,
    bounding_box: [
    {
    x: 0.6846566796302795,
    y: 0.4186234474182129
    },
    {
    x: 0.7550384402275085,
    y: 0.4186234474182129
    },
    {
    x: 0.7550384402275085,
    y: 0.7864204049110413
    },
    {
    x: 0.6846566796302795,
    y: 0.7864204049110413
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.9055719971656799,
    bounding_box: [
    {
    x: 0.2747826874256134,
    y: 0.38727328181266785
    },
    {
    x: 0.3316684663295746,
    y: 0.38727328181266785
    },
    {
    x: 0.3316684663295746,
    y: 0.7098106741905212
    },
    {
    x: 0.2747826874256134,
    y: 0.7098106741905212
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.9040135741233826,
    bounding_box: [
    {
    x: 0.8401730060577393,
    y: 0.42806103825569153
    },
    {
    x: 0.9407263398170471,
    y: 0.42806103825569153
    },
    {
    x: 0.9407263398170471,
    y: 0.8658064007759094
    },
    {
    x: 0.8401730060577393,
    y: 0.8658064007759094
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.9010881781578064,
    bounding_box: [
    {
    x: 0.9017500877380371,
    y: 0.3822089731693268
    },
    {
    x: 0.9962299466133118,
    y: 0.3822089731693268
    },
    {
    x: 0.9962299466133118,
    y: 0.8913499116897583
    },
    {
    x: 0.9017500877380371,
    y: 0.8913499116897583
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.9001466631889343,
    bounding_box: [
    {
    x: 0.06537580490112305,
    y: 0.3998328149318695
    },
    {
    x: 0.17160288989543915,
    y: 0.3998328149318695
    },
    {
    x: 0.17160288989543915,
    y: 0.8439197540283203
    },
    {
    x: 0.06537580490112305,
    y: 0.8439197540283203
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8975353837013245,
    bounding_box: [
    {
    x: 0.33024361729621887,
    y: 0.426756888628006
    },
    {
    x: 0.37935498356819153,
    y: 0.426756888628006
    },
    {
    x: 0.37935498356819153,
    y: 0.7019584774971008
    },
    {
    x: 0.33024361729621887,
    y: 0.7019584774971008
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8959678411483765,
    bounding_box: [
    {
    x: 0.3733949363231659,
    y: 0.4276445209980011
    },
    {
    x: 0.4220292568206787,
    y: 0.4276445209980011
    },
    {
    x: 0.4220292568206787,
    y: 0.7053486704826355
    },
    {
    x: 0.3733949363231659,
    y: 0.7053486704826355
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8931627869606018,
    bounding_box: [
    {
    x: 0.4532717168331146,
    y: 0.3962517976760864
    },
    {
    x: 0.6339316964149475,
    y: 0.3962517976760864
    },
    {
    x: 0.6339316964149475,
    y: 0.9845244288444519
    },
    {
    x: 0.4532717168331146,
    y: 0.9845244288444519
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8872783780097961,
    bounding_box: [
    {
    x: 0.7491238117218018,
    y: 0.3734295666217804
    },
    {
    x: 0.828010082244873,
    y: 0.3734295666217804
    },
    {
    x: 0.828010082244873,
    y: 0.8130986094474792
    },
    {
    x: 0.7491238117218018,
    y: 0.8130986094474792
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29697,
    time_in_milliseconds: 990890.0000000001,
    labels: [
    {
    description: "Event",
    confidence: 88.31942081451416
    },
    {
    description: "Performance",
    confidence: 86.81097030639648
    },
    {
    description: "Stage",
    confidence: 79.47136759757996
    },
    {
    description: "Ceremony",
    confidence: 72.66086935997009
    },
    {
    description: "Performing arts",
    confidence: 65.30972719192505
    },
    {
    description: "Wedding",
    confidence: 62.57161498069763
    },
    {
    description: "Performance art",
    confidence: 62.347739934921265
    },
    {
    description: "Party",
    confidence: 59.11022424697876
    },
    {
    description: "Crowd",
    confidence: 57.80550241470337
    },
    {
    description: "Music",
    confidence: 55.19570708274841
    }
    ],
    objects: [
    {
    description: "Wheel",
    confidence: 0.9144894480705261,
    bounding_box: [
    {
    x: 0.20604757964611053,
    y: 0.6963738799095154
    },
    {
    x: 0.33322176337242126,
    y: 0.6963738799095154
    },
    {
    x: 0.33322176337242126,
    y: 0.9022476077079773
    },
    {
    x: 0.20604757964611053,
    y: 0.9022476077079773
    }
    ]
    },
    {
    description: "Tire",
    confidence: 0.909607470035553,
    bounding_box: [
    {
    x: 0.6468783020973206,
    y: 0.6753740310668945
    },
    {
    x: 0.765165388584137,
    y: 0.6753740310668945
    },
    {
    x: 0.765165388584137,
    y: 0.885101318359375
    },
    {
    x: 0.6468783020973206,
    y: 0.885101318359375
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8718270659446716,
    bounding_box: [
    {
    x: 0.38508716225624084,
    y: 0.2712361216545105
    },
    {
    x: 0.5916345715522766,
    y: 0.2712361216545105
    },
    {
    x: 0.5916345715522766,
    y: 0.9698023200035095
    },
    {
    x: 0.38508716225624084,
    y: 0.9698023200035095
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8285260200500488,
    bounding_box: [
    {
    x: 0.8558921217918396,
    y: 0.40309667587280273
    },
    {
    x: 0.9943132996559143,
    y: 0.40309667587280273
    },
    {
    x: 0.9943132996559143,
    y: 0.9327175617218018
    },
    {
    x: 0.8558921217918396,
    y: 0.9327175617218018
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.824804961681366,
    bounding_box: [
    {
    x: 0.7531392574310303,
    y: 0.43851804733276367
    },
    {
    x: 0.8717153072357178,
    y: 0.43851804733276367
    },
    {
    x: 0.8717153072357178,
    y: 0.9037930965423584
    },
    {
    x: 0.7531392574310303,
    y: 0.9037930965423584
    }
    ]
    },
    {
    description: "Dress",
    confidence: 0.8008717894554138,
    bounding_box: [
    {
    x: 0.7542827725410461,
    y: 0.4553578794002533
    },
    {
    x: 0.8694236278533936,
    y: 0.4553578794002533
    },
    {
    x: 0.8694236278533936,
    y: 0.9041586518287659
    },
    {
    x: 0.7542827725410461,
    y: 0.9041586518287659
    }
    ]
    },
    {
    description: "Dress",
    confidence: 0.6794829368591309,
    bounding_box: [
    {
    x: 0.38980233669281006,
    y: 0.3954230546951294
    },
    {
    x: 0.5673810839653015,
    y: 0.3954230546951294
    },
    {
    x: 0.5673810839653015,
    y: 0.9892137050628662
    },
    {
    x: 0.38980233669281006,
    y: 0.9892137050628662
    }
    ]
    },
    {
    description: "Pants",
    confidence: 0.6660735011100769,
    bounding_box: [
    {
    x: 0.878376305103302,
    y: 0.6279886364936829
    },
    {
    x: 0.9750117659568787,
    y: 0.6279886364936829
    },
    {
    x: 0.9750117659568787,
    y: 0.9138691425323486
    },
    {
    x: 0.878376305103302,
    y: 0.9138691425323486
    }
    ]
    },
    {
    description: "Tire",
    confidence: 0.6177992224693298,
    bounding_box: [
    {
    x: 0.5726293325424194,
    y: 0.6818264126777649
    },
    {
    x: 0.6569967865943909,
    y: 0.6818264126777649
    },
    {
    x: 0.6569967865943909,
    y: 0.8619888424873352
    },
    {
    x: 0.5726293325424194,
    y: 0.8619888424873352
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29726,
    time_in_milliseconds: 991857.0000000001,
    labels: [
    {
    description: "Hair",
    confidence: 98.37164878845215
    },
    {
    description: "Forehead",
    confidence: 93.54414343833923
    },
    {
    description: "Chin",
    confidence: 90.26594758033752
    },
    {
    description: "Facial hair",
    confidence: 89.54910635948181
    },
    {
    description: "Eyebrow",
    confidence: 86.39556765556335
    },
    {
    description: "Beard",
    confidence: 80.90378046035767
    },
    {
    description: "Jaw",
    confidence: 78.2585620880127
    },
    {
    description: "Neck",
    confidence: 76.76558494567871
    },
    {
    description: "Moustache",
    confidence: 72.86909222602844
    },
    {
    description: "Ear",
    confidence: 51.23199820518494
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8008156418800354,
    bounding_box: [
    {
    x: 0.16474205255508423,
    y: 0.044592831283807755
    },
    {
    x: 0.8306968212127686,
    y: 0.044592831283807755
    },
    {
    x: 0.8306968212127686,
    y: 0.9973958134651184
    },
    {
    x: 0.16474205255508423,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7098420262336731,
    bounding_box: [
    {
    x: 0.19074422121047974,
    y: 0.6406053900718689
    },
    {
    x: 0.8130912184715271,
    y: 0.6406053900718689
    },
    {
    x: 0.8130912184715271,
    y: 0.9921510219573975
    },
    {
    x: 0.19074422121047974,
    y: 0.9921510219573975
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6163362860679626,
    bounding_box: [
    {
    x: 0.5774361491203308,
    y: 0.49149250984191895
    },
    {
    x: 0.7621221542358398,
    y: 0.49149250984191895
    },
    {
    x: 0.7621221542358398,
    y: 0.7921598553657532
    },
    {
    x: 0.5774361491203308,
    y: 0.7921598553657532
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5560805797576904,
    bounding_box: [
    {
    x: 0.059692058712244034,
    y: 0.2933543622493744
    },
    {
    x: 0.28438329696655273,
    y: 0.2933543622493744
    },
    {
    x: 0.28438329696655273,
    y: 0.9099884033203125
    },
    {
    x: 0.059692058712244034,
    y: 0.9099884033203125
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29813,
    time_in_milliseconds: 994760,
    labels: [
    {
    description: "Jaw",
    confidence: 57.406723499298096
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8764919638633728,
    bounding_box: [
    {
    x: 0.019250793382525444,
    y: 0.03299880027770996
    },
    {
    x: 0.6200447678565979,
    y: 0.03299880027770996
    },
    {
    x: 0.6200447678565979,
    y: 0.9919002056121826
    },
    {
    x: 0.019250793382525444,
    y: 0.9919002056121826
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.8052627444267273,
    bounding_box: [
    {
    x: 0.007645641919225454,
    y: 0.54900062084198
    },
    {
    x: 0.6305792927742004,
    y: 0.54900062084198
    },
    {
    x: 0.6305792927742004,
    y: 0.9919865727424622
    },
    {
    x: 0.007645641919225454,
    y: 0.9919865727424622
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29842,
    time_in_milliseconds: 995728.0000000001,
    labels: [
    {
    description: "Food",
    confidence: 63.45244646072388
    },
    {
    description: "Dish",
    confidence: 61.7725670337677
    },
    {
    description: "Cuisine",
    confidence: 55.97306489944458
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8966655135154724,
    bounding_box: [
    {
    x: 0.007025748025625944,
    y: 0.014340419322252274
    },
    {
    x: 0.443990558385849,
    y: 0.014340419322252274
    },
    {
    x: 0.443990558385849,
    y: 0.9869417548179626
    },
    {
    x: 0.007025748025625944,
    y: 0.9869417548179626
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5315452218055725,
    bounding_box: [
    {
    x: 0.0044589596800506115,
    y: 0.39733099937438965
    },
    {
    x: 0.43958473205566406,
    y: 0.39733099937438965
    },
    {
    x: 0.43958473205566406,
    y: 0.9841929078102112
    },
    {
    x: 0.0044589596800506115,
    y: 0.9841929078102112
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 29871,
    time_in_milliseconds: 996695,
    labels: [
    {
    description: "Motor vehicle",
    confidence: 97.83723950386047
    },
    {
    description: "Vehicle",
    confidence: 94.69120502471924
    },
    {
    description: "Mode of transport",
    confidence: 91.84823036193848
    },
    {
    description: "Transport",
    confidence: 85.13096570968628
    },
    {
    description: "Car",
    confidence: 82.24735856056213
    },
    {
    description: "Automotive design",
    confidence: 72.28396534919739
    },
    {
    description: "Illustration",
    confidence: 66.88148975372314
    },
    {
    description: "Graphics",
    confidence: 66.35181903839111
    },
    {
    description: "Monster truck",
    confidence: 62.56032586097717
    },
    {
    description: "Animation",
    confidence: 55.824828147888184
    }
    ],
    objects: [
    {
    description: "Wheel",
    confidence: 0.7424517869949341,
    bounding_box: [
    {
    x: 0.7796649932861328,
    y: 0.6035124063491821
    },
    {
    x: 0.9149302840232849,
    y: 0.6035124063491821
    },
    {
    x: 0.9149302840232849,
    y: 0.856550931930542
    },
    {
    x: 0.7796649932861328,
    y: 0.856550931930542
    }
    ]
    },
    {
    description: "Tire",
    confidence: 0.549517810344696,
    bounding_box: [
    {
    x: 0.24577701091766357,
    y: 0.6550530791282654
    },
    {
    x: 0.41815951466560364,
    y: 0.6550530791282654
    },
    {
    x: 0.41815951466560364,
    y: 0.8708264231681824
    },
    {
    x: 0.24577701091766357,
    y: 0.8708264231681824
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 30016,
    time_in_milliseconds: 1001534,
    labels: [
    {
    description: "Graphic design",
    confidence: 64.512699842453
    },
    {
    description: "Illustration",
    confidence: 60.17356514930725
    },
    {
    description: "Cg artwork",
    confidence: 57.85433650016785
    },
    {
    description: "Games",
    confidence: 52.11578011512756
    },
    {
    description: "Graphics",
    confidence: 50.36579966545105
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6279559135437012,
    bounding_box: [
    {
    x: 0.04042721167206764,
    y: 0.0823974683880806
    },
    {
    x: 0.9605687260627747,
    y: 0.0823974683880806
    },
    {
    x: 0.9605687260627747,
    y: 0.9686856865882874
    },
    {
    x: 0.04042721167206764,
    y: 0.9686856865882874
    }
    ]
    },
    {
    description: "Luggage & bags",
    confidence: 0.5913280248641968,
    bounding_box: [
    {
    x: 0.03741918504238129,
    y: 0.4707849323749542
    },
    {
    x: 0.2126835137605667,
    y: 0.4707849323749542
    },
    {
    x: 0.2126835137605667,
    y: 0.7536304593086243
    },
    {
    x: 0.03741918504238129,
    y: 0.7536304593086243
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 30045,
    time_in_milliseconds: 1002501.0000000001,
    labels: [
    {
    description: "Black",
    confidence: 98.44525456428528
    },
    {
    description: "Darkness",
    confidence: 97.61468768119812
    },
    {
    description: "Sky",
    confidence: 95.50930857658386
    },
    {
    description: "White",
    confidence: 95.47066688537598
    },
    {
    description: "Text",
    confidence: 94.49374079704285
    },
    {
    description: "Font",
    confidence: 91.89635515213013
    },
    {
    description: "Light",
    confidence: 90.89804291725159
    },
    {
    description: "Atmosphere",
    confidence: 86.26090288162231
    },
    {
    description: "Brown",
    confidence: 86.07587814331055
    },
    {
    description: "Black-and-white",
    confidence: 80.72261214256287
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 30074,
    time_in_milliseconds: 1003469,
    labels: [
    {
    description: "Library",
    confidence: 83.31785798072815
    },
    {
    description: "Bookcase",
    confidence: 72.24631905555725
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Book",
    confidence: 68.64173412322998
    },
    {
    description: "Publication",
    confidence: 54.4025719165802
    },
    {
    description: "Author",
    confidence: 51.33486986160278
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9334362149238586,
    bounding_box: [
    {
    x: 0.3863396644592285,
    y: 0.3967592418193817
    },
    {
    x: 0.5265761017799377,
    y: 0.3967592418193817
    },
    {
    x: 0.5265761017799377,
    y: 0.48695454001426697
    },
    {
    x: 0.3863396644592285,
    y: 0.48695454001426697
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8239690661430359,
    bounding_box: [
    {
    x: 0.6242245435714722,
    y: 0.0017049204325303435
    },
    {
    x: 0.9707157015800476,
    y: 0.0017049204325303435
    },
    {
    x: 0.9707157015800476,
    y: 0.985523521900177
    },
    {
    x: 0.6242245435714722,
    y: 0.985523521900177
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8182880878448486,
    bounding_box: [
    {
    x: 0.2587922513484955,
    y: 0.2301551103591919
    },
    {
    x: 0.838125467300415,
    y: 0.2301551103591919
    },
    {
    x: 0.838125467300415,
    y: 0.9945976734161377
    },
    {
    x: 0.2587922513484955,
    y: 0.9945976734161377
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5962731242179871,
    bounding_box: [
    {
    x: 0.26181328296661377,
    y: 0.5424296259880066
    },
    {
    x: 0.8344874978065491,
    y: 0.5424296259880066
    },
    {
    x: 0.8344874978065491,
    y: 0.9776711463928223
    },
    {
    x: 0.26181328296661377,
    y: 0.9776711463928223
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 30219,
    time_in_milliseconds: 1008307,
    labels: [
    {
    description: "Blue",
    confidence: 95.43808102607727
    },
    {
    description: "Text",
    confidence: 89.7243082523346
    },
    {
    description: "Font",
    confidence: 84.10601615905762
    },
    {
    description: "Electric blue",
    confidence: 76.88719034194946
    },
    {
    description: "Graphic design",
    confidence: 75.19109845161438
    },
    {
    description: "Design",
    confidence: 74.84626173973083
    },
    {
    description: "Logo",
    confidence: 64.45081830024719
    },
    {
    description: "Graphics",
    confidence: 62.49194145202637
    },
    {
    description: "Brand",
    confidence: 58.21748375892639
    },
    {
    description: "Company",
    confidence: 53.8402259349823
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.6879037022590637,
    bounding_box: [
    {
    x: 0.4335344135761261,
    y: 0.38356292247772217
    },
    {
    x: 0.5420628786087036,
    y: 0.38356292247772217
    },
    {
    x: 0.5420628786087036,
    y: 0.5338199138641357
    },
    {
    x: 0.4335344135761261,
    y: 0.5338199138641357
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6415042281150818,
    bounding_box: [
    {
    x: 0.422212690114975,
    y: 0.3512726128101349
    },
    {
    x: 0.5622567534446716,
    y: 0.3512726128101349
    },
    {
    x: 0.5622567534446716,
    y: 0.7016479969024658
    },
    {
    x: 0.422212690114975,
    y: 0.7016479969024658
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 30248,
    time_in_milliseconds: 1009274,
    labels: [
    {
    description: "Text",
    confidence: 95.11113166809082
    },
    {
    description: "Font",
    confidence: 94.35950517654419
    },
    {
    description: "Blue",
    confidence: 92.6959753036499
    },
    {
    description: "Electric blue",
    confidence: 84.79061126708984
    },
    {
    description: "Graphic design",
    confidence: 75.4598081111908
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Logo",
    confidence: 72.43244647979736
    },
    {
    description: "Graphics",
    confidence: 69.15693283081055
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 30364,
    time_in_milliseconds: 1013145.0000000001,
    labels: [
    {
    description: "Black",
    confidence: 98.49877953529358
    },
    {
    description: "Darkness",
    confidence: 97.3947525024414
    },
    {
    description: "White",
    confidence: 96.54507637023926
    },
    {
    description: "Sky",
    confidence: 93.36194396018982
    },
    {
    description: "Light",
    confidence: 91.6901707649231
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Text",
    confidence: 91.21394753456116
    },
    {
    description: "Brown",
    confidence: 88.69289755821228
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Atmosphere",
    confidence: 82.49121904373169
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 30451,
    time_in_milliseconds: 1016048,
    labels: [
    {
    description: "Text",
    confidence: 95.46195864677429
    },
    {
    description: "Font",
    confidence: 94.15251016616821
    },
    {
    description: "Blue",
    confidence: 92.6959753036499
    },
    {
    description: "Electric blue",
    confidence: 84.79061126708984
    },
    {
    description: "Graphic design",
    confidence: 73.73085021972656
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Logo",
    confidence: 70.02647519111633
    },
    {
    description: "Graphics",
    confidence: 68.34848523139954
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 30567,
    time_in_milliseconds: 1019918,
    labels: [
    {
    description: "Font",
    confidence: 91.33999943733215
    },
    {
    description: "Text",
    confidence: 89.01308178901672
    },
    {
    description: "Electric blue",
    confidence: 82.25684762001038
    },
    {
    description: "Design",
    confidence: 73.01200032234192
    },
    {
    description: "Graphic design",
    confidence: 71.27410173416138
    },
    {
    description: "Graphics",
    confidence: 65.15349745750427
    },
    {
    description: "Logo",
    confidence: 62.4750554561615
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8729909062385559,
    bounding_box: [
    {
    x: 0.4446909725666046,
    y: 0.39061757922172546
    },
    {
    x: 0.5589773654937744,
    y: 0.39061757922172546
    },
    {
    x: 0.5589773654937744,
    y: 0.4757707417011261
    },
    {
    x: 0.4446909725666046,
    y: 0.4757707417011261
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 30596,
    time_in_milliseconds: 1020887,
    labels: [
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Library",
    confidence: 69.19872164726257
    },
    {
    description: "Book",
    confidence: 60.874515771865845
    },
    {
    description: "Bookcase",
    confidence: 56.44093155860901
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9282345175743103,
    bounding_box: [
    {
    x: 0.42433419823646545,
    y: 0.34289395809173584
    },
    {
    x: 0.5608011484146118,
    y: 0.34289395809173584
    },
    {
    x: 0.5608011484146118,
    y: 0.42764684557914734
    },
    {
    x: 0.42433419823646545,
    y: 0.42764684557914734
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8536175489425659,
    bounding_box: [
    {
    x: 0.6231825947761536,
    y: 0.02113623172044754
    },
    {
    x: 0.9751406311988831,
    y: 0.02113623172044754
    },
    {
    x: 0.9751406311988831,
    y: 0.9795014262199402
    },
    {
    x: 0.6231825947761536,
    y: 0.9795014262199402
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8523340225219727,
    bounding_box: [
    {
    x: 0.32083481550216675,
    y: 0.20978069305419922
    },
    {
    x: 0.8671017289161682,
    y: 0.20978069305419922
    },
    {
    x: 0.8671017289161682,
    y: 0.9889581799507141
    },
    {
    x: 0.32083481550216675,
    y: 0.9889581799507141
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7620324492454529,
    bounding_box: [
    {
    x: 0.3143186569213867,
    y: 0.2541636824607849
    },
    {
    x: 0.8605348467826843,
    y: 0.2541636824607849
    },
    {
    x: 0.8605348467826843,
    y: 0.9890993237495422
    },
    {
    x: 0.3143186569213867,
    y: 0.9890993237495422
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7140749096870422,
    bounding_box: [
    {
    x: 0.33911705017089844,
    y: 0.5091034770011902
    },
    {
    x: 0.8573994040489197,
    y: 0.5091034770011902
    },
    {
    x: 0.8573994040489197,
    y: 0.9824778437614441
    },
    {
    x: 0.33911705017089844,
    y: 0.9824778437614441
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 30944,
    time_in_milliseconds: 1032498,
    labels: [
    {
    description: "Hair",
    confidence: 98.53523373603821
    },
    {
    description: "Facial hair",
    confidence: 97.26971983909607
    },
    {
    description: "Beard",
    confidence: 95.58234214782715
    },
    {
    description: "Eyewear",
    confidence: 93.7157154083252
    },
    {
    description: "Glasses",
    confidence: 92.88020730018616
    },
    {
    description: "Hairstyle",
    confidence: 86.29165291786194
    },
    {
    description: "Moustache",
    confidence: 85.25015115737915
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Finger",
    confidence: 81.05190992355347
    },
    {
    description: "Fun",
    confidence: 76.84727907180786
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9030745625495911,
    bounding_box: [
    {
    x: 0.4834063947200775,
    y: 0.2934460937976837
    },
    {
    x: 0.6359767913818359,
    y: 0.2934460937976837
    },
    {
    x: 0.6359767913818359,
    y: 0.39660441875457764
    },
    {
    x: 0.4834063947200775,
    y: 0.39660441875457764
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8572426438331604,
    bounding_box: [
    {
    x: 0.1301863044500351,
    y: 0.015537910163402557
    },
    {
    x: 0.9959470629692078,
    y: 0.015537910163402557
    },
    {
    x: 0.9959470629692078,
    y: 0.9935007691383362
    },
    {
    x: 0.1301863044500351,
    y: 0.9935007691383362
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 30973,
    time_in_milliseconds: 1033466.0000000001,
    labels: [
    {
    description: "Hair",
    confidence: 97.88822531700134
    },
    {
    description: "Shoulder",
    confidence: 96.01980447769165
    },
    {
    description: "Facial hair",
    confidence: 95.37612199783325
    },
    {
    description: "Eyewear",
    confidence: 93.7157154083252
    },
    {
    description: "Arm",
    confidence: 93.48418116569519
    },
    {
    description: "Houseplant",
    confidence: 93.23737025260925
    },
    {
    description: "Glasses",
    confidence: 90.87082743644714
    },
    {
    description: "Beard",
    confidence: 89.73230123519897
    },
    {
    description: "Joint",
    confidence: 86.21743321418762
    },
    {
    description: "Hairstyle",
    confidence: 84.23047661781311
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.894891619682312,
    bounding_box: [
    {
    x: 0.5489054322242737,
    y: 0.3013087213039398
    },
    {
    x: 0.6923325657844543,
    y: 0.3013087213039398
    },
    {
    x: 0.6923325657844543,
    y: 0.4066040515899658
    },
    {
    x: 0.5489054322242737,
    y: 0.4066040515899658
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8237768411636353,
    bounding_box: [
    {
    x: 0.23767425119876862,
    y: 0.005839746445417404
    },
    {
    x: 0.9697635769844055,
    y: 0.005839746445417404
    },
    {
    x: 0.9697635769844055,
    y: 0.9973958134651184
    },
    {
    x: 0.23767425119876862,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5765718221664429,
    bounding_box: [
    {
    x: 0.000748724618460983,
    y: 0.6979483962059021
    },
    {
    x: 0.12198210507631302,
    y: 0.6979483962059021
    },
    {
    x: 0.12198210507631302,
    y: 0.9971857070922852
    },
    {
    x: 0.000748724618460983,
    y: 0.9971857070922852
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 31002,
    time_in_milliseconds: 1034433,
    labels: [
    {
    description: "News",
    confidence: 89.13459777832031
    },
    {
    description: "Media",
    confidence: 79.83176112174988
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Glasses",
    confidence: 67.38030314445496
    },
    {
    description: "Photo caption",
    confidence: 62.31793165206909
    },
    {
    description: "Television program",
    confidence: 58.63468647003174
    },
    {
    description: "Television",
    confidence: 54.89833950996399
    },
    {
    description: "Official",
    confidence: 50.00404119491577
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9372460842132568,
    bounding_box: [
    {
    x: 0.2841474711894989,
    y: 0.33977413177490234
    },
    {
    x: 0.4170205593109131,
    y: 0.33977413177490234
    },
    {
    x: 0.4170205593109131,
    y: 0.42895376682281494
    },
    {
    x: 0.2841474711894989,
    y: 0.42895376682281494
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9120644330978394,
    bounding_box: [
    {
    x: 0.6527448892593384,
    y: 0.2974230945110321
    },
    {
    x: 0.7857382893562317,
    y: 0.2974230945110321
    },
    {
    x: 0.7857382893562317,
    y: 0.3912803828716278
    },
    {
    x: 0.6527448892593384,
    y: 0.3912803828716278
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8244816660881042,
    bounding_box: [
    {
    x: 0.49805450439453125,
    y: 0.11543098092079163
    },
    {
    x: 0.8717682957649231,
    y: 0.11543098092079163
    },
    {
    x: 0.8717682957649231,
    y: 0.852206826210022
    },
    {
    x: 0.49805450439453125,
    y: 0.852206826210022
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8234402537345886,
    bounding_box: [
    {
    x: 0.12881909310817719,
    y: 0.15481416881084442
    },
    {
    x: 0.5009020566940308,
    y: 0.15481416881084442
    },
    {
    x: 0.5009020566940308,
    y: 0.8483223915100098
    },
    {
    x: 0.12881909310817719,
    y: 0.8483223915100098
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5893850326538086,
    bounding_box: [
    {
    x: 0.49519631266593933,
    y: 0.18923382461071014
    },
    {
    x: 0.8713963627815247,
    y: 0.18923382461071014
    },
    {
    x: 0.8713963627815247,
    y: 0.8348278999328613
    },
    {
    x: 0.49519631266593933,
    y: 0.8348278999328613
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 31089,
    time_in_milliseconds: 1037336,
    labels: [
    {
    description: "Eyewear",
    confidence: 98.6725926399231
    },
    {
    description: "Hair",
    confidence: 98.52413535118103
    },
    {
    description: "Facial hair",
    confidence: 96.7782199382782
    },
    {
    description: "Beard",
    confidence: 93.78136396408081
    },
    {
    description: "Cool",
    confidence: 93.5880720615387
    },
    {
    description: "Glasses",
    confidence: 93.54138970375061
    },
    {
    description: "Hairstyle",
    confidence: 89.17961716651917
    },
    {
    description: "Head",
    confidence: 87.62359619140625
    },
    {
    description: "Fun",
    confidence: 87.1378481388092
    },
    {
    description: "Snapshot",
    confidence: 83.2730770111084
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9267959594726562,
    bounding_box: [
    {
    x: 0.49484214186668396,
    y: 0.2628861367702484
    },
    {
    x: 0.6517539024353027,
    y: 0.2628861367702484
    },
    {
    x: 0.6517539024353027,
    y: 0.3773939907550812
    },
    {
    x: 0.49484214186668396,
    y: 0.3773939907550812
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8437895774841309,
    bounding_box: [
    {
    x: 0.12697045505046844,
    y: 0
    },
    {
    x: 0.9862225651741028,
    y: 0
    },
    {
    x: 0.9862225651741028,
    y: 0.9860545992851257
    },
    {
    x: 0.12697045505046844,
    y: 0.9860545992851257
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 31118,
    time_in_milliseconds: 1038303.0000000001,
    labels: [
    {
    description: "Hair",
    confidence: 98.93831610679626
    },
    {
    description: "Photograph",
    confidence: 94.9849009513855
    },
    {
    description: "Facial hair",
    confidence: 94.81169581413269
    },
    {
    description: "Facial expression",
    confidence: 94.43857669830322
    },
    {
    description: "Glasses",
    confidence: 93.38595271110535
    },
    {
    description: "Hairstyle",
    confidence: 92.35385060310364
    },
    {
    description: "Beard",
    confidence: 92.34867691993713
    },
    {
    description: "Dreadlocks",
    confidence: 89.49100375175476
    },
    {
    description: "Houseplant",
    confidence: 87.16990947723389
    },
    {
    description: "Moustache",
    confidence: 85.81510186195374
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9399089813232422,
    bounding_box: [
    {
    x: 0.4958380162715912,
    y: 0.28432419896125793
    },
    {
    x: 0.6836608052253723,
    y: 0.28432419896125793
    },
    {
    x: 0.6836608052253723,
    y: 0.40517890453338623
    },
    {
    x: 0.4958380162715912,
    y: 0.40517890453338623
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8221132755279541,
    bounding_box: [
    {
    x: 0.26412343978881836,
    y: 0.4563382863998413
    },
    {
    x: 0.8975837826728821,
    y: 0.4563382863998413
    },
    {
    x: 0.8975837826728821,
    y: 0.9940255284309387
    },
    {
    x: 0.26412343978881836,
    y: 0.9940255284309387
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7810674905776978,
    bounding_box: [
    {
    x: 0.27185818552970886,
    y: 0.04286333546042442
    },
    {
    x: 0.8957860469818115,
    y: 0.04286333546042442
    },
    {
    x: 0.8957860469818115,
    y: 0.9937556385993958
    },
    {
    x: 0.27185818552970886,
    y: 0.9937556385993958
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5612028241157532,
    bounding_box: [
    {
    x: 0.0009599595796316862,
    y: 0.6943144798278809
    },
    {
    x: 0.1203598901629448,
    y: 0.6943144798278809
    },
    {
    x: 0.1203598901629448,
    y: 0.9973958134651184
    },
    {
    x: 0.0009599595796316862,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 31147,
    time_in_milliseconds: 1039271,
    labels: [
    {
    description: "Hair",
    confidence: 97.92969822883606
    },
    {
    description: "Eyewear",
    confidence: 97.3164975643158
    },
    {
    description: "Facial hair",
    confidence: 95.7175076007843
    },
    {
    description: "Glasses",
    confidence: 95.23036479949951
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "Beard",
    confidence: 89.73230123519897
    },
    {
    description: "Fun",
    confidence: 88.57806921005249
    },
    {
    description: "Head",
    confidence: 87.62359619140625
    },
    {
    description: "Hairstyle",
    confidence: 86.29165291786194
    },
    {
    description: "Snapshot",
    confidence: 85.86586713790894
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9097023606300354,
    bounding_box: [
    {
    x: 0.48410525918006897,
    y: 0.2575664818286896
    },
    {
    x: 0.6445181965827942,
    y: 0.2575664818286896
    },
    {
    x: 0.6445181965827942,
    y: 0.37715163826942444
    },
    {
    x: 0.48410525918006897,
    y: 0.37715163826942444
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7381035685539246,
    bounding_box: [
    {
    x: 0.10811977833509445,
    y: 0.003160301595926285
    },
    {
    x: 0.9973958134651184,
    y: 0.003160301595926285
    },
    {
    x: 0.9973958134651184,
    y: 0.9869096875190735
    },
    {
    x: 0.10811977833509445,
    y: 0.9869096875190735
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 31176,
    time_in_milliseconds: 1040239,
    labels: [
    {
    description: "News",
    confidence: 92.33269095420837
    },
    {
    description: "Media",
    confidence: 76.53514742851257
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Photo caption",
    confidence: 61.63979768753052
    },
    {
    description: "Vision care",
    confidence: 54.48664426803589
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.940495491027832,
    bounding_box: [
    {
    x: 0.6327671408653259,
    y: 0.2993716299533844
    },
    {
    x: 0.7664759159088135,
    y: 0.2993716299533844
    },
    {
    x: 0.7664759159088135,
    y: 0.39597129821777344
    },
    {
    x: 0.6327671408653259,
    y: 0.39597129821777344
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9401695728302002,
    bounding_box: [
    {
    x: 0.18762850761413574,
    y: 0.3455698788166046
    },
    {
    x: 0.3220006823539734,
    y: 0.3455698788166046
    },
    {
    x: 0.3220006823539734,
    y: 0.43417635560035706
    },
    {
    x: 0.18762850761413574,
    y: 0.43417635560035706
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8534454703330994,
    bounding_box: [
    {
    x: 0.5076766610145569,
    y: 0.11349721997976303
    },
    {
    x: 0.8728980422019958,
    y: 0.11349721997976303
    },
    {
    x: 0.8728980422019958,
    y: 0.8503085970878601
    },
    {
    x: 0.5076766610145569,
    y: 0.8503085970878601
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8494448065757751,
    bounding_box: [
    {
    x: 0.12327062338590622,
    y: 0.1679643988609314
    },
    {
    x: 0.5007707476615906,
    y: 0.1679643988609314
    },
    {
    x: 0.5007707476615906,
    y: 0.8479588031768799
    },
    {
    x: 0.12327062338590622,
    y: 0.8479588031768799
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6552470922470093,
    bounding_box: [
    {
    x: 0.1208488717675209,
    y: 0.1978413462638855
    },
    {
    x: 0.49803265929222107,
    y: 0.1978413462638855
    },
    {
    x: 0.49803265929222107,
    y: 0.8431522846221924
    },
    {
    x: 0.1208488717675209,
    y: 0.8431522846221924
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5803244113922119,
    bounding_box: [
    {
    x: 0.5047683119773865,
    y: 0.15885527431964874
    },
    {
    x: 0.870477020740509,
    y: 0.15885527431964874
    },
    {
    x: 0.870477020740509,
    y: 0.8363872170448303
    },
    {
    x: 0.5047683119773865,
    y: 0.8363872170448303
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 31263,
    time_in_milliseconds: 1043142,
    labels: [
    {
    description: "Eyewear",
    confidence: 88.56593370437622
    },
    {
    description: "News",
    confidence: 85.3078544139862
    },
    {
    description: "Media",
    confidence: 77.54791378974915
    },
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Photography",
    confidence: 67.78864860534668
    },
    {
    description: "Photo caption",
    confidence: 66.4606511592865
    },
    {
    description: "Facial hair",
    confidence: 64.79135155677795
    },
    {
    description: "Television program",
    confidence: 56.72747492790222
    },
    {
    description: "Vision care",
    confidence: 56.652915477752686
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9037908911705017,
    bounding_box: [
    {
    x: 0.6281816363334656,
    y: 0.2973540127277374
    },
    {
    x: 0.76181560754776,
    y: 0.2973540127277374
    },
    {
    x: 0.76181560754776,
    y: 0.40568456053733826
    },
    {
    x: 0.6281816363334656,
    y: 0.40568456053733826
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.8898065090179443,
    bounding_box: [
    {
    x: 0.24133841693401337,
    y: 0.31581196188926697
    },
    {
    x: 0.367655485868454,
    y: 0.31581196188926697
    },
    {
    x: 0.367655485868454,
    y: 0.4015665352344513
    },
    {
    x: 0.24133841693401337,
    y: 0.4015665352344513
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8642029762268066,
    bounding_box: [
    {
    x: 0.5039406418800354,
    y: 0.11736983805894852
    },
    {
    x: 0.8656530380249023,
    y: 0.11736983805894852
    },
    {
    x: 0.8656530380249023,
    y: 0.8523758053779602
    },
    {
    x: 0.5039406418800354,
    y: 0.8523758053779602
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8243990540504456,
    bounding_box: [
    {
    x: 0.1366620808839798,
    y: 0.15402670204639435
    },
    {
    x: 0.5034359097480774,
    y: 0.15402670204639435
    },
    {
    x: 0.5034359097480774,
    y: 0.8498022556304932
    },
    {
    x: 0.1366620808839798,
    y: 0.8498022556304932
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6154962182044983,
    bounding_box: [
    {
    x: 0.1400054544210434,
    y: 0.20703142881393433
    },
    {
    x: 0.5026622414588928,
    y: 0.20703142881393433
    },
    {
    x: 0.5026622414588928,
    y: 0.8369529843330383
    },
    {
    x: 0.1400054544210434,
    y: 0.8369529843330383
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5633547902107239,
    bounding_box: [
    {
    x: 0.5002385377883911,
    y: 0.16070671379566193
    },
    {
    x: 0.8666815161705017,
    y: 0.16070671379566193
    },
    {
    x: 0.8666815161705017,
    y: 0.8332180380821228
    },
    {
    x: 0.5002385377883911,
    y: 0.8332180380821228
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 31350,
    time_in_milliseconds: 1046045.0000000001,
    labels: [
    {
    description: "News",
    confidence: 88.6796236038208
    },
    {
    description: "Media",
    confidence: 75.98154544830322
    },
    {
    description: "Photo caption",
    confidence: 72.34803438186646
    },
    {
    description: "Facial hair",
    confidence: 60.71390509605408
    },
    {
    description: "Glasses",
    confidence: 56.08964562416077
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9429358243942261,
    bounding_box: [
    {
    x: 0.6251009106636047,
    y: 0.30293646454811096
    },
    {
    x: 0.7638011574745178,
    y: 0.30293646454811096
    },
    {
    x: 0.7638011574745178,
    y: 0.396289587020874
    },
    {
    x: 0.6251009106636047,
    y: 0.396289587020874
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9304847717285156,
    bounding_box: [
    {
    x: 0.25009000301361084,
    y: 0.37716326117515564
    },
    {
    x: 0.3670137822628021,
    y: 0.37716326117515564
    },
    {
    x: 0.3670137822628021,
    y: 0.46956324577331543
    },
    {
    x: 0.25009000301361084,
    y: 0.46956324577331543
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8885335922241211,
    bounding_box: [
    {
    x: 0.1458587497472763,
    y: 0.2093011736869812
    },
    {
    x: 0.5014992356300354,
    y: 0.2093011736869812
    },
    {
    x: 0.5014992356300354,
    y: 0.8329260945320129
    },
    {
    x: 0.1458587497472763,
    y: 0.8329260945320129
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8387303948402405,
    bounding_box: [
    {
    x: 0.5003184080123901,
    y: 0.12365468591451645
    },
    {
    x: 0.8758461475372314,
    y: 0.12365468591451645
    },
    {
    x: 0.8758461475372314,
    y: 0.8564354777336121
    },
    {
    x: 0.5003184080123901,
    y: 0.8564354777336121
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5832257270812988,
    bounding_box: [
    {
    x: 0.4989236891269684,
    y: 0.16282792389392853
    },
    {
    x: 0.8751854300498962,
    y: 0.16282792389392853
    },
    {
    x: 0.8751854300498962,
    y: 0.8386168479919434
    },
    {
    x: 0.4989236891269684,
    y: 0.8386168479919434
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32017,
    time_in_milliseconds: 1068301.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.62460494041443
    },
    {
    description: "Eyewear",
    confidence: 96.36379480361938
    },
    {
    description: "Facial hair",
    confidence: 96.07393741607666
    },
    {
    description: "Photograph",
    confidence: 95.82339525222778
    },
    {
    description: "Glasses",
    confidence: 95.67949175834656
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "Beard",
    confidence: 92.43355393409729
    },
    {
    description: "Houseplant",
    confidence: 91.59384965896606
    },
    {
    description: "Moustache",
    confidence: 89.53900933265686
    },
    {
    description: "Hairstyle",
    confidence: 88.63683342933655
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9339562058448792,
    bounding_box: [
    {
    x: 0.5042063593864441,
    y: 0.2625942826271057
    },
    {
    x: 0.6721416115760803,
    y: 0.2625942826271057
    },
    {
    x: 0.6721416115760803,
    y: 0.3846045434474945
    },
    {
    x: 0.5042063593864441,
    y: 0.3846045434474945
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8793832659721375,
    bounding_box: [
    {
    x: 0.30512872338294983,
    y: 0.4731263220310211
    },
    {
    x: 0.9002685546875,
    y: 0.4731263220310211
    },
    {
    x: 0.9002685546875,
    y: 0.9950566291809082
    },
    {
    x: 0.30512872338294983,
    y: 0.9950566291809082
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7826690077781677,
    bounding_box: [
    {
    x: 0.3045935332775116,
    y: 0.06880230456590652
    },
    {
    x: 0.9024536609649658,
    y: 0.06880230456590652
    },
    {
    x: 0.9024536609649658,
    y: 0.9941093325614929
    },
    {
    x: 0.3045935332775116,
    y: 0.9941093325614929
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32278,
    time_in_milliseconds: 1077009,
    labels: [
    {
    description: "Hair",
    confidence: 98.35554361343384
    },
    {
    description: "Eyewear",
    confidence: 97.52029180526733
    },
    {
    description: "Personal protective equipment",
    confidence: 92.11993217468262
    },
    {
    description: "Glasses",
    confidence: 89.0495777130127
    },
    {
    description: "Hairstyle",
    confidence: 88.63683342933655
    },
    {
    description: "Snapshot",
    confidence: 85.50759553909302
    },
    {
    description: "Mask",
    confidence: 80.55126070976257
    },
    {
    description: "Gas mask",
    confidence: 75.84145665168762
    },
    {
    description: "Cool",
    confidence: 75.34600496292114
    },
    {
    description: "Long hair",
    confidence: 74.88930821418762
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9210883378982544,
    bounding_box: [
    {
    x: 0.15049047768115997,
    y: 0.08477989584207535
    },
    {
    x: 0.9961628913879395,
    y: 0.08477989584207535
    },
    {
    x: 0.9961628913879395,
    y: 0.9946132302284241
    },
    {
    x: 0.15049047768115997,
    y: 0.9946132302284241
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.8675888776779175,
    bounding_box: [
    {
    x: 0.5133834481239319,
    y: 0.27362215518951416
    },
    {
    x: 0.6743625998497009,
    y: 0.27362215518951416
    },
    {
    x: 0.6743625998497009,
    y: 0.3881794214248657
    },
    {
    x: 0.5133834481239319,
    y: 0.3881794214248657
    }
    ]
    },
    {
    description: "Sunglasses",
    confidence: 0.7730798721313477,
    bounding_box: [
    {
    x: 0.5146069526672363,
    y: 0.2778157591819763
    },
    {
    x: 0.6716060638427734,
    y: 0.2778157591819763
    },
    {
    x: 0.6716060638427734,
    y: 0.3940446078777313
    },
    {
    x: 0.5146069526672363,
    y: 0.3940446078777313
    }
    ]
    },
    {
    description: "Cabinetry",
    confidence: 0.533414900302887,
    bounding_box: [
    {
    x: 0.00041992473416030407,
    y: 0.6950109004974365
    },
    {
    x: 0.12104005366563797,
    y: 0.6950109004974365
    },
    {
    x: 0.12104005366563797,
    y: 0.9973958134651184
    },
    {
    x: 0.00041992473416030407,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32307,
    time_in_milliseconds: 1077976,
    labels: [
    {
    description: "Hair",
    confidence: 98.73782396316528
    },
    {
    description: "Facial hair",
    confidence: 97.60547876358032
    },
    {
    description: "Beard",
    confidence: 95.77029347419739
    },
    {
    description: "Photograph",
    confidence: 95.6760823726654
    },
    {
    description: "Eyewear",
    confidence: 95.2597975730896
    },
    {
    description: "Glasses",
    confidence: 94.87643837928772
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "Moustache",
    confidence: 93.2311475276947
    },
    {
    description: "Hairstyle",
    confidence: 90.20282626152039
    },
    {
    description: "Houseplant",
    confidence: 89.29799199104309
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9470051527023315,
    bounding_box: [
    {
    x: 0.5200382471084595,
    y: 0.2758948802947998
    },
    {
    x: 0.6971087455749512,
    y: 0.2758948802947998
    },
    {
    x: 0.6971087455749512,
    y: 0.4034405052661896
    },
    {
    x: 0.5200382471084595,
    y: 0.4034405052661896
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8325615525245667,
    bounding_box: [
    {
    x: 0.3258378803730011,
    y: 0.47797155380249023
    },
    {
    x: 0.9067476987838745,
    y: 0.47797155380249023
    },
    {
    x: 0.9067476987838745,
    y: 0.9918083548545837
    },
    {
    x: 0.3258378803730011,
    y: 0.9918083548545837
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7745923399925232,
    bounding_box: [
    {
    x: 0.31806692481040955,
    y: 0.05754133686423302
    },
    {
    x: 0.9161688089370728,
    y: 0.05754133686423302
    },
    {
    x: 0.9161688089370728,
    y: 0.9900962710380554
    },
    {
    x: 0.31806692481040955,
    y: 0.9900962710380554
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32336,
    time_in_milliseconds: 1078944,
    labels: [
    {
    description: "Hair",
    confidence: 98.71164560317993
    },
    {
    description: "Facial hair",
    confidence: 98.38277101516724
    },
    {
    description: "Eyewear",
    confidence: 97.90372848510742
    },
    {
    description: "Beard",
    confidence: 97.56913185119629
    },
    {
    description: "Glasses",
    confidence: 95.80317735671997
    },
    {
    description: "Moustache",
    confidence: 94.10225749015808
    },
    {
    description: "Hairstyle",
    confidence: 90.28685688972473
    },
    {
    description: "Vision care",
    confidence: 84.03350114822388
    },
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Fun",
    confidence: 76.84727907180786
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9330602884292603,
    bounding_box: [
    {
    x: 0.505911111831665,
    y: 0.27626684308052063
    },
    {
    x: 0.6746151447296143,
    y: 0.27626684308052063
    },
    {
    x: 0.6746151447296143,
    y: 0.40408560633659363
    },
    {
    x: 0.505911111831665,
    y: 0.40408560633659363
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7543052434921265,
    bounding_box: [
    {
    x: 0.13480956852436066,
    y: 0.07997451722621918
    },
    {
    x: 0.9669151306152344,
    y: 0.07997451722621918
    },
    {
    x: 0.9669151306152344,
    y: 0.9968626499176025
    },
    {
    x: 0.13480956852436066,
    y: 0.9968626499176025
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7359623312950134,
    bounding_box: [
    {
    x: 0.1289062649011612,
    y: 0.06151915714144707
    },
    {
    x: 0.9825339317321777,
    y: 0.06151915714144707
    },
    {
    x: 0.9825339317321777,
    y: 0.991382896900177
    },
    {
    x: 0.1289062649011612,
    y: 0.991382896900177
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5758346319198608,
    bounding_box: [
    {
    x: 0.21718357503414154,
    y: 0.44548919796943665
    },
    {
    x: 0.9358911514282227,
    y: 0.44548919796943665
    },
    {
    x: 0.9358911514282227,
    y: 0.9963052272796631
    },
    {
    x: 0.21718357503414154,
    y: 0.9963052272796631
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5376571416854858,
    bounding_box: [
    {
    x: 0.08079269528388977,
    y: 0.09338277578353882
    },
    {
    x: 0.235739603638649,
    y: 0.09338277578353882
    },
    {
    x: 0.235739603638649,
    y: 0.32280904054641724
    },
    {
    x: 0.08079269528388977,
    y: 0.32280904054641724
    }
    ]
    },
    {
    description: "Watch",
    confidence: 0.5125077366828918,
    bounding_box: [
    {
    x: 0.8247547745704651,
    y: 0.8304234147071838
    },
    {
    x: 0.9032290577888489,
    y: 0.8304234147071838
    },
    {
    x: 0.9032290577888489,
    y: 0.9468483924865723
    },
    {
    x: 0.8247547745704651,
    y: 0.9468483924865723
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32394,
    time_in_milliseconds: 1080880,
    labels: [
    {
    description: "Eyewear",
    confidence: 97.5822925567627
    },
    {
    description: "Hair",
    confidence: 97.41137027740479
    },
    {
    description: "Facial hair",
    confidence: 95.37612199783325
    },
    {
    description: "Glasses",
    confidence: 92.4062728881836
    },
    {
    description: "Beard",
    confidence: 91.90535545349121
    },
    {
    description: "Moustache",
    confidence: 83.77387523651123
    },
    {
    description: "Vision care",
    confidence: 80.36164045333862
    },
    {
    description: "Finger",
    confidence: 73.21174144744873
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9346053600311279,
    bounding_box: [
    {
    x: 0.5153931975364685,
    y: 0.2656252682209015
    },
    {
    x: 0.6874220967292786,
    y: 0.2656252682209015
    },
    {
    x: 0.6874220967292786,
    y: 0.3844069242477417
    },
    {
    x: 0.5153931975364685,
    y: 0.3844069242477417
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8769220113754272,
    bounding_box: [
    {
    x: 0.14167951047420502,
    y: 0.471856027841568
    },
    {
    x: 0.8993921279907227,
    y: 0.471856027841568
    },
    {
    x: 0.8993921279907227,
    y: 0.9915366172790527
    },
    {
    x: 0.14167951047420502,
    y: 0.9915366172790527
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7694992423057556,
    bounding_box: [
    {
    x: 0.05531718209385872,
    y: 0.060006704181432724
    },
    {
    x: 0.9280516505241394,
    y: 0.060006704181432724
    },
    {
    x: 0.9280516505241394,
    y: 0.9902319312095642
    },
    {
    x: 0.05531718209385872,
    y: 0.9902319312095642
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32452,
    time_in_milliseconds: 1082815,
    labels: [
    {
    description: "Hair",
    confidence: 98.17695617675781
    },
    {
    description: "Eyewear",
    confidence: 94.58502531051636
    },
    {
    description: "Glasses",
    confidence: 94.42289471626282
    },
    {
    description: "Facial hair",
    confidence: 90.16304612159729
    },
    {
    description: "Hairstyle",
    confidence: 86.59101724624634
    },
    {
    description: "Beard",
    confidence: 85.26655435562134
    },
    {
    description: "Snapshot",
    confidence: 83.2730770111084
    },
    {
    description: "Vision care",
    confidence: 82.44438767433167
    },
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Moustache",
    confidence: 78.49346995353699
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9263041615486145,
    bounding_box: [
    {
    x: 0.5379961133003235,
    y: 0.284819632768631
    },
    {
    x: 0.7204174995422363,
    y: 0.284819632768631
    },
    {
    x: 0.7204174995422363,
    y: 0.4111127555370331
    },
    {
    x: 0.5379961133003235,
    y: 0.4111127555370331
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8250772356987,
    bounding_box: [
    {
    x: 0.24629364907741547,
    y: 0.4666720926761627
    },
    {
    x: 0.8945724964141846,
    y: 0.4666720926761627
    },
    {
    x: 0.8945724964141846,
    y: 0.9922875165939331
    },
    {
    x: 0.24629364907741547,
    y: 0.9922875165939331
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7995427846908569,
    bounding_box: [
    {
    x: 0.20709432661533356,
    y: 0.06831597536802292
    },
    {
    x: 0.9427841305732727,
    y: 0.06831597536802292
    },
    {
    x: 0.9427841305732727,
    y: 0.9932311177253723
    },
    {
    x: 0.20709432661533356,
    y: 0.9932311177253723
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.552811861038208,
    bounding_box: [
    {
    x: 0.0008747797110117972,
    y: 0.6944136619567871
    },
    {
    x: 0.12245398759841919,
    y: 0.6944136619567871
    },
    {
    x: 0.12245398759841919,
    y: 0.9973958134651184
    },
    {
    x: 0.0008747797110117972,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5268465876579285,
    bounding_box: [
    {
    x: 0.08081550151109695,
    y: 0.09037990123033524
    },
    {
    x: 0.23675870895385742,
    y: 0.09037990123033524
    },
    {
    x: 0.23675870895385742,
    y: 0.3196415603160858
    },
    {
    x: 0.08081550151109695,
    y: 0.3196415603160858
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5061127543449402,
    bounding_box: [
    {
    x: 0.04631459712982178,
    y: 0.42969730496406555
    },
    {
    x: 0.10908758640289307,
    y: 0.42969730496406555
    },
    {
    x: 0.10908758640289307,
    y: 0.7076823115348816
    },
    {
    x: 0.04631459712982178,
    y: 0.7076823115348816
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32481,
    time_in_milliseconds: 1083782.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.80399703979492
    },
    {
    description: "Facial hair",
    confidence: 96.96858525276184
    },
    {
    description: "Eyewear",
    confidence: 95.79759240150452
    },
    {
    description: "Photograph",
    confidence: 95.59142589569092
    },
    {
    description: "Beard",
    confidence: 94.92135643959045
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "Glasses",
    confidence: 93.90499591827393
    },
    {
    description: "Hairstyle",
    confidence: 90.28685688972473
    },
    {
    description: "Moustache",
    confidence: 89.86974358558655
    },
    {
    description: "Houseplant",
    confidence: 87.99492716789246
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9324969053268433,
    bounding_box: [
    {
    x: 0.5074413418769836,
    y: 0.28349611163139343
    },
    {
    x: 0.6788449883460999,
    y: 0.28349611163139343
    },
    {
    x: 0.6788449883460999,
    y: 0.3993896543979645
    },
    {
    x: 0.5074413418769836,
    y: 0.3993896543979645
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8320425748825073,
    bounding_box: [
    {
    x: 0.31151166558265686,
    y: 0.47985681891441345
    },
    {
    x: 0.9076201915740967,
    y: 0.47985681891441345
    },
    {
    x: 0.9076201915740967,
    y: 0.9927389621734619
    },
    {
    x: 0.31151166558265686,
    y: 0.9927389621734619
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7265994548797607,
    bounding_box: [
    {
    x: 0.30803588032722473,
    y: 0.07682580500841141
    },
    {
    x: 0.9091813564300537,
    y: 0.07682580500841141
    },
    {
    x: 0.9091813564300537,
    y: 0.9949512481689453
    },
    {
    x: 0.30803588032722473,
    y: 0.9949512481689453
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32510,
    time_in_milliseconds: 1084750,
    labels: [
    {
    description: "Hair",
    confidence: 98.31254482269287
    },
    {
    description: "Facial hair",
    confidence: 97.94670939445496
    },
    {
    description: "Beard",
    confidence: 96.77845239639282
    },
    {
    description: "Photograph",
    confidence: 95.27485370635986
    },
    {
    description: "Moustache",
    confidence: 94.15571093559265
    },
    {
    description: "Glasses",
    confidence: 93.22423934936523
    },
    {
    description: "Eyewear",
    confidence: 86.2449049949646
    },
    {
    description: "Hairstyle",
    confidence: 84.23047661781311
    },
    {
    description: "Snapshot",
    confidence: 83.58544111251831
    },
    {
    description: "Houseplant",
    confidence: 80.80047369003296
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9399594664573669,
    bounding_box: [
    {
    x: 0.49857187271118164,
    y: 0.25354138016700745
    },
    {
    x: 0.661904513835907,
    y: 0.25354138016700745
    },
    {
    x: 0.661904513835907,
    y: 0.36446884274482727
    },
    {
    x: 0.49857187271118164,
    y: 0.36446884274482727
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.757240355014801,
    bounding_box: [
    {
    x: 0.11002985388040543,
    y: 0.0743766650557518
    },
    {
    x: 0.9901410937309265,
    y: 0.0743766650557518
    },
    {
    x: 0.9901410937309265,
    y: 0.9939033389091492
    },
    {
    x: 0.11002985388040543,
    y: 0.9939033389091492
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.754655122756958,
    bounding_box: [
    {
    x: 0.13859428465366364,
    y: 0.4866240322589874
    },
    {
    x: 0.9589667916297913,
    y: 0.4866240322589874
    },
    {
    x: 0.9589667916297913,
    y: 0.9934936761856079
    },
    {
    x: 0.13859428465366364,
    y: 0.9934936761856079
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5173009634017944,
    bounding_box: [
    {
    x: 0.08092649281024933,
    y: 0.09144720435142517
    },
    {
    x: 0.23701728880405426,
    y: 0.09144720435142517
    },
    {
    x: 0.23701728880405426,
    y: 0.32143911719322205
    },
    {
    x: 0.08092649281024933,
    y: 0.32143911719322205
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32713,
    time_in_milliseconds: 1091524,
    labels: [
    {
    description: "Hair",
    confidence: 98.81724715232849
    },
    {
    description: "Facial hair",
    confidence: 98.11971187591553
    },
    {
    description: "Beard",
    confidence: 97.0778226852417
    },
    {
    description: "Moustache",
    confidence: 93.99375319480896
    },
    {
    description: "Eyewear",
    confidence: 91.54864549636841
    },
    {
    description: "Hairstyle",
    confidence: 89.17961716651917
    },
    {
    description: "Glasses",
    confidence: 84.24429297447205
    },
    {
    description: "Snapshot",
    confidence: 83.2730770111084
    },
    {
    description: "Afro",
    confidence: 72.55913615226746
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9395490288734436,
    bounding_box: [
    {
    x: 0.4623030126094818,
    y: 0.26594725251197815
    },
    {
    x: 0.6396805644035339,
    y: 0.26594725251197815
    },
    {
    x: 0.6396805644035339,
    y: 0.3960728943347931
    },
    {
    x: 0.4623030126094818,
    y: 0.3960728943347931
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.823359489440918,
    bounding_box: [
    {
    x: 0.2582053244113922,
    y: 0.4806349575519562
    },
    {
    x: 0.9271962642669678,
    y: 0.4806349575519562
    },
    {
    x: 0.9271962642669678,
    y: 0.99164879322052
    },
    {
    x: 0.2582053244113922,
    y: 0.99164879322052
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7919150590896606,
    bounding_box: [
    {
    x: 0.263658732175827,
    y: 0.07327599078416824
    },
    {
    x: 0.9301266670227051,
    y: 0.07327599078416824
    },
    {
    x: 0.9301266670227051,
    y: 0.9845516085624695
    },
    {
    x: 0.263658732175827,
    y: 0.9845516085624695
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32742,
    time_in_milliseconds: 1092491,
    labels: [
    {
    description: "Hair",
    confidence: 98.05321097373962
    },
    {
    description: "Facial hair",
    confidence: 98.0261504650116
    },
    {
    description: "Eyewear",
    confidence: 95.63094973564148
    },
    {
    description: "Beard",
    confidence: 95.2366054058075
    },
    {
    description: "Finger",
    confidence: 89.71214294433594
    },
    {
    description: "Glasses",
    confidence: 87.97047138214111
    },
    {
    description: "Moustache",
    confidence: 86.84353232383728
    },
    {
    description: "Hairstyle",
    confidence: 82.93982148170471
    },
    {
    description: "Sign language",
    confidence: 79.30985689163208
    },
    {
    description: "Vision care",
    confidence: 76.22490525245667
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9476051926612854,
    bounding_box: [
    {
    x: 0.5070247650146484,
    y: 0.2628190815448761
    },
    {
    x: 0.6813018321990967,
    y: 0.2628190815448761
    },
    {
    x: 0.6813018321990967,
    y: 0.3823569118976593
    },
    {
    x: 0.5070247650146484,
    y: 0.3823569118976593
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7766823172569275,
    bounding_box: [
    {
    x: 0.07601078599691391,
    y: 0.057608217000961304
    },
    {
    x: 0.9511410593986511,
    y: 0.057608217000961304
    },
    {
    x: 0.9511410593986511,
    y: 0.9911967515945435
    },
    {
    x: 0.07601078599691391,
    y: 0.9911967515945435
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5944169163703918,
    bounding_box: [
    {
    x: 0.16086220741271973,
    y: 0.4932403564453125
    },
    {
    x: 0.9483819007873535,
    y: 0.4932403564453125
    },
    {
    x: 0.9483819007873535,
    y: 0.9900040030479431
    },
    {
    x: 0.16086220741271973,
    y: 0.9900040030479431
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5436431169509888,
    bounding_box: [
    {
    x: 0.08217870444059372,
    y: 0.0904257521033287
    },
    {
    x: 0.23710882663726807,
    y: 0.0904257521033287
    },
    {
    x: 0.23710882663726807,
    y: 0.32055577635765076
    },
    {
    x: 0.08217870444059372,
    y: 0.32055577635765076
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.530319094657898,
    bounding_box: [
    {
    x: 0.0016984106041491032,
    y: 0.729961097240448
    },
    {
    x: 0.1118420884013176,
    y: 0.729961097240448
    },
    {
    x: 0.1118420884013176,
    y: 0.9969987273216248
    },
    {
    x: 0.0016984106041491032,
    y: 0.9969987273216248
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32771,
    time_in_milliseconds: 1093458,
    labels: [
    {
    description: "Hair",
    confidence: 98.48300218582153
    },
    {
    description: "Facial hair",
    confidence: 97.38906025886536
    },
    {
    description: "Beard",
    confidence: 95.48582434654236
    },
    {
    description: "Glasses",
    confidence: 95.18197178840637
    },
    {
    description: "Eyewear",
    confidence: 95.0524091720581
    },
    {
    description: "Moustache",
    confidence: 92.12673902511597
    },
    {
    description: "Hairstyle",
    confidence: 87.11950778961182
    },
    {
    description: "Forehead",
    confidence: 86.29716038703918
    },
    {
    description: "Vision care",
    confidence: 80.98602890968323
    },
    {
    description: "T-shirt",
    confidence: 73.92740249633789
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9444265365600586,
    bounding_box: [
    {
    x: 0.5036352276802063,
    y: 0.2542022168636322
    },
    {
    x: 0.6757314205169678,
    y: 0.2542022168636322
    },
    {
    x: 0.6757314205169678,
    y: 0.372763991355896
    },
    {
    x: 0.5036352276802063,
    y: 0.372763991355896
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8611712455749512,
    bounding_box: [
    {
    x: 0.09529487043619156,
    y: 0.5068028569221497
    },
    {
    x: 0.9739696383476257,
    y: 0.5068028569221497
    },
    {
    x: 0.9739696383476257,
    y: 0.9939597249031067
    },
    {
    x: 0.09529487043619156,
    y: 0.9939597249031067
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8442767262458801,
    bounding_box: [
    {
    x: 0.059953466057777405,
    y: 0.06577136367559433
    },
    {
    x: 0.988641083240509,
    y: 0.06577136367559433
    },
    {
    x: 0.988641083240509,
    y: 0.9922674298286438
    },
    {
    x: 0.059953466057777405,
    y: 0.9922674298286438
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.554750919342041,
    bounding_box: [
    {
    x: 0.06927219778299332,
    y: 0.0850321352481842
    },
    {
    x: 0.2367904782295227,
    y: 0.0850321352481842
    },
    {
    x: 0.2367904782295227,
    y: 0.32070615887641907
    },
    {
    x: 0.06927219778299332,
    y: 0.32070615887641907
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32829,
    time_in_milliseconds: 1095394,
    labels: [
    {
    description: "Hair",
    confidence: 98.76293540000916
    },
    {
    description: "Facial hair",
    confidence: 96.3833749294281
    },
    {
    description: "Beard",
    confidence: 95.08129954338074
    },
    {
    description: "Eyewear",
    confidence: 92.98391938209534
    },
    {
    description: "Glasses",
    confidence: 92.09486246109009
    },
    {
    description: "Hairstyle",
    confidence: 90.44738411903381
    },
    {
    description: "Moustache",
    confidence: 90.28738141059875
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    },
    {
    description: "Smile",
    confidence: 75.41265487670898
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9403923153877258,
    bounding_box: [
    {
    x: 0.5004809498786926,
    y: 0.28961479663848877
    },
    {
    x: 0.6772656440734863,
    y: 0.28961479663848877
    },
    {
    x: 0.6772656440734863,
    y: 0.4086587429046631
    },
    {
    x: 0.5004809498786926,
    y: 0.4086587429046631
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.9178696870803833,
    bounding_box: [
    {
    x: 0.10964742302894592,
    y: 0.4857168197631836
    },
    {
    x: 0.9664764404296875,
    y: 0.4857168197631836
    },
    {
    x: 0.9664764404296875,
    y: 0.9962027668952942
    },
    {
    x: 0.10964742302894592,
    y: 0.9962027668952942
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.816789448261261,
    bounding_box: [
    {
    x: 0.07109306752681732,
    y: 0.057343605905771255
    },
    {
    x: 0.9864893555641174,
    y: 0.057343605905771255
    },
    {
    x: 0.9864893555641174,
    y: 0.9903636574745178
    },
    {
    x: 0.07109306752681732,
    y: 0.9903636574745178
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5366690754890442,
    bounding_box: [
    {
    x: 0.08254369348287582,
    y: 0.08949429541826248
    },
    {
    x: 0.2384592890739441,
    y: 0.08949429541826248
    },
    {
    x: 0.2384592890739441,
    y: 0.32056719064712524
    },
    {
    x: 0.08254369348287582,
    y: 0.32056719064712524
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 32858,
    time_in_milliseconds: 1096361,
    labels: [
    {
    description: "News",
    confidence: 86.76874041557312
    },
    {
    description: "Media",
    confidence: 72.57829308509827
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Photo caption",
    confidence: 65.11752009391785
    },
    {
    description: "Facial hair",
    confidence: 60.71390509605408
    },
    {
    description: "Official",
    confidence: 50.00404119491577
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9428563117980957,
    bounding_box: [
    {
    x: 0.6340432167053223,
    y: 0.30161812901496887
    },
    {
    x: 0.7725377678871155,
    y: 0.30161812901496887
    },
    {
    x: 0.7725377678871155,
    y: 0.40018728375434875
    },
    {
    x: 0.6340432167053223,
    y: 0.40018728375434875
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9181117415428162,
    bounding_box: [
    {
    x: 0.22729350626468658,
    y: 0.33574363589286804
    },
    {
    x: 0.3604362905025482,
    y: 0.33574363589286804
    },
    {
    x: 0.3604362905025482,
    y: 0.4289553165435791
    },
    {
    x: 0.22729350626468658,
    y: 0.4289553165435791
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8474620580673218,
    bounding_box: [
    {
    x: 0.4958369731903076,
    y: 0.11023540049791336
    },
    {
    x: 0.8738696575164795,
    y: 0.11023540049791336
    },
    {
    x: 0.8738696575164795,
    y: 0.8494148850440979
    },
    {
    x: 0.4958369731903076,
    y: 0.8494148850440979
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8308186531066895,
    bounding_box: [
    {
    x: 0.12313234806060791,
    y: 0.16401870548725128
    },
    {
    x: 0.504245936870575,
    y: 0.16401870548725128
    },
    {
    x: 0.504245936870575,
    y: 0.8517505526542664
    },
    {
    x: 0.12313234806060791,
    y: 0.8517505526542664
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6206943988800049,
    bounding_box: [
    {
    x: 0.49427247047424316,
    y: 0.15127259492874146
    },
    {
    x: 0.8729410767555237,
    y: 0.15127259492874146
    },
    {
    x: 0.8729410767555237,
    y: 0.8326689600944519
    },
    {
    x: 0.49427247047424316,
    y: 0.8326689600944519
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5378943085670471,
    bounding_box: [
    {
    x: 0.12420397996902466,
    y: 0.21084938943386078
    },
    {
    x: 0.501094400882721,
    y: 0.21084938943386078
    },
    {
    x: 0.501094400882721,
    y: 0.8398499488830566
    },
    {
    x: 0.12420397996902466,
    y: 0.8398499488830566
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 33699,
    time_in_milliseconds: 1124423,
    labels: [
    {
    description: "News",
    confidence: 93.53971481323242
    },
    {
    description: "Facial hair",
    confidence: 73.0988085269928
    },
    {
    description: "Photo caption",
    confidence: 69.29265856742859
    },
    {
    description: "Media",
    confidence: 68.68262887001038
    },
    {
    description: "Glasses",
    confidence: 62.70098090171814
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Beard",
    confidence: 50.39592981338501
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9364339709281921,
    bounding_box: [
    {
    x: 0.6312958002090454,
    y: 0.26735296845436096
    },
    {
    x: 0.7660391330718994,
    y: 0.26735296845436096
    },
    {
    x: 0.7660391330718994,
    y: 0.35927629470825195
    },
    {
    x: 0.6312958002090454,
    y: 0.35927629470825195
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9120269417762756,
    bounding_box: [
    {
    x: 0.2601574957370758,
    y: 0.31142088770866394
    },
    {
    x: 0.38889405131340027,
    y: 0.31142088770866394
    },
    {
    x: 0.38889405131340027,
    y: 0.39428654313087463
    },
    {
    x: 0.2601574957370758,
    y: 0.39428654313087463
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8611700534820557,
    bounding_box: [
    {
    x: 0.5022547245025635,
    y: 0.12126220017671585
    },
    {
    x: 0.8736235499382019,
    y: 0.12126220017671585
    },
    {
    x: 0.8736235499382019,
    y: 0.8492390513420105
    },
    {
    x: 0.5022547245025635,
    y: 0.8492390513420105
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8366460800170898,
    bounding_box: [
    {
    x: 0.13447177410125732,
    y: 0.14481467008590698
    },
    {
    x: 0.5078994631767273,
    y: 0.14481467008590698
    },
    {
    x: 0.5078994631767273,
    y: 0.8496339917182922
    },
    {
    x: 0.13447177410125732,
    y: 0.8496339917182922
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5114907026290894,
    bounding_box: [
    {
    x: 0.49897345900535583,
    y: 0.1540556102991104
    },
    {
    x: 0.8720539212226868,
    y: 0.1540556102991104
    },
    {
    x: 0.8720539212226868,
    y: 0.837183952331543
    },
    {
    x: 0.49897345900535583,
    y: 0.837183952331543
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 33931,
    time_in_milliseconds: 1132164,
    labels: [
    {
    description: "News",
    confidence: 86.16944551467896
    },
    {
    description: "Media",
    confidence: 78.01285982131958
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    },
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Facial hair",
    confidence: 70.8098292350769
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Photo caption",
    confidence: 66.86744093894958
    },
    {
    description: "Television program",
    confidence: 59.98697280883789
    },
    {
    description: "Vision care",
    confidence: 56.652915477752686
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9316869378089905,
    bounding_box: [
    {
    x: 0.23948365449905396,
    y: 0.33192387223243713
    },
    {
    x: 0.36575376987457275,
    y: 0.33192387223243713
    },
    {
    x: 0.36575376987457275,
    y: 0.42626655101776123
    },
    {
    x: 0.23948365449905396,
    y: 0.42626655101776123
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9311940670013428,
    bounding_box: [
    {
    x: 0.6208251118659973,
    y: 0.27972909808158875
    },
    {
    x: 0.7577858567237854,
    y: 0.27972909808158875
    },
    {
    x: 0.7577858567237854,
    y: 0.38473668694496155
    },
    {
    x: 0.6208251118659973,
    y: 0.38473668694496155
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8659980893135071,
    bounding_box: [
    {
    x: 0.4999948740005493,
    y: 0.11028795689344406
    },
    {
    x: 0.8735911250114441,
    y: 0.11028795689344406
    },
    {
    x: 0.8735911250114441,
    y: 0.8524455428123474
    },
    {
    x: 0.4999948740005493,
    y: 0.8524455428123474
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8523918986320496,
    bounding_box: [
    {
    x: 0.13716880977153778,
    y: 0.16152381896972656
    },
    {
    x: 0.5054153800010681,
    y: 0.16152381896972656
    },
    {
    x: 0.5054153800010681,
    y: 0.8492715358734131
    },
    {
    x: 0.13716880977153778,
    y: 0.8492715358734131
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7844570875167847,
    bounding_box: [
    {
    x: 0.49843868613243103,
    y: 0.273690402507782
    },
    {
    x: 0.8703847527503967,
    y: 0.273690402507782
    },
    {
    x: 0.8703847527503967,
    y: 0.828751266002655
    },
    {
    x: 0.49843868613243103,
    y: 0.828751266002655
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.776978075504303,
    bounding_box: [
    {
    x: 0.13942335546016693,
    y: 0.2797802984714508
    },
    {
    x: 0.5020416378974915,
    y: 0.2797802984714508
    },
    {
    x: 0.5020416378974915,
    y: 0.8284204006195068
    },
    {
    x: 0.13942335546016693,
    y: 0.8284204006195068
    }
    ]
    },
    {
    description: "Sunglasses",
    confidence: 0.6581618785858154,
    bounding_box: [
    {
    x: 0.6209059357643127,
    y: 0.28401872515678406
    },
    {
    x: 0.7563672065734863,
    y: 0.28401872515678406
    },
    {
    x: 0.7563672065734863,
    y: 0.3869023323059082
    },
    {
    x: 0.6209059357643127,
    y: 0.3869023323059082
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 34163,
    time_in_milliseconds: 1139905,
    labels: [
    {
    description: "News",
    confidence: 88.03184628486633
    },
    {
    description: "Media",
    confidence: 75.69145560264587
    },
    {
    description: "Facial hair",
    confidence: 68.087238073349
    },
    {
    description: "Photo caption",
    confidence: 67.98793077468872
    },
    {
    description: "Glasses",
    confidence: 65.22085666656494
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9378182291984558,
    bounding_box: [
    {
    x: 0.6334424018859863,
    y: 0.30528688430786133
    },
    {
    x: 0.771399199962616,
    y: 0.30528688430786133
    },
    {
    x: 0.771399199962616,
    y: 0.3998993933200836
    },
    {
    x: 0.6334424018859863,
    y: 0.3998993933200836
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9108808636665344,
    bounding_box: [
    {
    x: 0.28201887011528015,
    y: 0.31740304827690125
    },
    {
    x: 0.41463157534599304,
    y: 0.31740304827690125
    },
    {
    x: 0.41463157534599304,
    y: 0.4001312255859375
    },
    {
    x: 0.28201887011528015,
    y: 0.4001312255859375
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8044826984405518,
    bounding_box: [
    {
    x: 0.11997547745704651,
    y: 0.1494217962026596
    },
    {
    x: 0.5083746910095215,
    y: 0.1494217962026596
    },
    {
    x: 0.5083746910095215,
    y: 0.8594180941581726
    },
    {
    x: 0.11997547745704651,
    y: 0.8594180941581726
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7925815582275391,
    bounding_box: [
    {
    x: 0.4959944188594818,
    y: 0.1058381125330925
    },
    {
    x: 0.8737751841545105,
    y: 0.1058381125330925
    },
    {
    x: 0.8737751841545105,
    y: 0.8551374077796936
    },
    {
    x: 0.4959944188594818,
    y: 0.8551374077796936
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6410289406776428,
    bounding_box: [
    {
    x: 0.1268434077501297,
    y: 0.22732698917388916
    },
    {
    x: 0.5075443983078003,
    y: 0.22732698917388916
    },
    {
    x: 0.5075443983078003,
    y: 0.8392109274864197
    },
    {
    x: 0.1268434077501297,
    y: 0.8392109274864197
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5128945112228394,
    bounding_box: [
    {
    x: 0.49732837080955505,
    y: 0.1555820256471634
    },
    {
    x: 0.8743324875831604,
    y: 0.1555820256471634
    },
    {
    x: 0.8743324875831604,
    y: 0.83456951379776
    },
    {
    x: 0.49732837080955505,
    y: 0.83456951379776
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 34366,
    time_in_milliseconds: 1146678,
    labels: [
    {
    description: "News",
    confidence: 91.19541049003601
    },
    {
    description: "Media",
    confidence: 72.97748327255249
    },
    {
    description: "Photo caption",
    confidence: 71.90579771995544
    },
    {
    description: "Glasses",
    confidence: 59.71137881278992
    },
    {
    description: "Television program",
    confidence: 53.06329131126404
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.941337525844574,
    bounding_box: [
    {
    x: 0.647765576839447,
    y: 0.27559804916381836
    },
    {
    x: 0.7854768633842468,
    y: 0.27559804916381836
    },
    {
    x: 0.7854768633842468,
    y: 0.37827369570732117
    },
    {
    x: 0.647765576839447,
    y: 0.37827369570732117
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9194340705871582,
    bounding_box: [
    {
    x: 0.22062784433364868,
    y: 0.3220233917236328
    },
    {
    x: 0.34829553961753845,
    y: 0.3220233917236328
    },
    {
    x: 0.34829553961753845,
    y: 0.4173954427242279
    },
    {
    x: 0.22062784433364868,
    y: 0.4173954427242279
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.870516836643219,
    bounding_box: [
    {
    x: 0.12700046598911285,
    y: 0.16802966594696045
    },
    {
    x: 0.5050113201141357,
    y: 0.16802966594696045
    },
    {
    x: 0.5050113201141357,
    y: 0.8516414165496826
    },
    {
    x: 0.12700046598911285,
    y: 0.8516414165496826
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8439635038375854,
    bounding_box: [
    {
    x: 0.4988038241863251,
    y: 0.12471776455640793
    },
    {
    x: 0.8781330585479736,
    y: 0.12471776455640793
    },
    {
    x: 0.8781330585479736,
    y: 0.8531712889671326
    },
    {
    x: 0.4988038241863251,
    y: 0.8531712889671326
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6324700117111206,
    bounding_box: [
    {
    x: 0.12621484696865082,
    y: 0.21797631680965424
    },
    {
    x: 0.5013999938964844,
    y: 0.21797631680965424
    },
    {
    x: 0.5013999938964844,
    y: 0.8442184329032898
    },
    {
    x: 0.12621484696865082,
    y: 0.8442184329032898
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 34482,
    time_in_milliseconds: 1150549,
    labels: [
    {
    description: "News",
    confidence: 88.52225542068481
    },
    {
    description: "Media",
    confidence: 77.30557322502136
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    },
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Photo caption",
    confidence: 68.66405010223389
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Vision care",
    confidence: 56.652915477752686
    },
    {
    description: "Facial hair",
    confidence: 55.52889108657837
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9345669746398926,
    bounding_box: [
    {
    x: 0.6393780708312988,
    y: 0.2963443100452423
    },
    {
    x: 0.7789737582206726,
    y: 0.2963443100452423
    },
    {
    x: 0.7789737582206726,
    y: 0.395736962556839
    },
    {
    x: 0.6393780708312988,
    y: 0.395736962556839
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9084156155586243,
    bounding_box: [
    {
    x: 0.24898378551006317,
    y: 0.30903923511505127
    },
    {
    x: 0.37742993235588074,
    y: 0.30903923511505127
    },
    {
    x: 0.37742993235588074,
    y: 0.3938467502593994
    },
    {
    x: 0.24898378551006317,
    y: 0.3938467502593994
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8440721035003662,
    bounding_box: [
    {
    x: 0.12902522087097168,
    y: 0.15552227199077606
    },
    {
    x: 0.5071542263031006,
    y: 0.15552227199077606
    },
    {
    x: 0.5071542263031006,
    y: 0.8472582697868347
    },
    {
    x: 0.12902522087097168,
    y: 0.8472582697868347
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7994714379310608,
    bounding_box: [
    {
    x: 0.49724218249320984,
    y: 0.10641634464263916
    },
    {
    x: 0.8733236193656921,
    y: 0.10641634464263916
    },
    {
    x: 0.8733236193656921,
    y: 0.8579895496368408
    },
    {
    x: 0.49724218249320984,
    y: 0.8579895496368408
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6265585422515869,
    bounding_box: [
    {
    x: 0.50091153383255,
    y: 0.2183169275522232
    },
    {
    x: 0.8731899857521057,
    y: 0.2183169275522232
    },
    {
    x: 0.8731899857521057,
    y: 0.8293769359588623
    },
    {
    x: 0.50091153383255,
    y: 0.8293769359588623
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5313202738761902,
    bounding_box: [
    {
    x: 0.13795459270477295,
    y: 0.18846219778060913
    },
    {
    x: 0.5097886323928833,
    y: 0.18846219778060913
    },
    {
    x: 0.5097886323928833,
    y: 0.8331515192985535
    },
    {
    x: 0.13795459270477295,
    y: 0.8331515192985535
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 34656,
    time_in_milliseconds: 1156355,
    labels: [
    {
    description: "Hair",
    confidence: 98.75585436820984
    },
    {
    description: "Facial hair",
    confidence: 98.45536351203918
    },
    {
    description: "Beard",
    confidence: 97.18632102012634
    },
    {
    description: "Moustache",
    confidence: 94.71114873886108
    },
    {
    description: "Glasses",
    confidence: 92.6021933555603
    },
    {
    description: "Eyewear",
    confidence: 92.55768656730652
    },
    {
    description: "Hairstyle",
    confidence: 87.97082304954529
    },
    {
    description: "Houseplant",
    confidence: 85.66744923591614
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Dreadlocks",
    confidence: 75.5329966545105
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9358919262886047,
    bounding_box: [
    {
    x: 0.5134841799736023,
    y: 0.2530636489391327
    },
    {
    x: 0.6802585124969482,
    y: 0.2530636489391327
    },
    {
    x: 0.6802585124969482,
    y: 0.3714159429073334
    },
    {
    x: 0.5134841799736023,
    y: 0.3714159429073334
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8275494575500488,
    bounding_box: [
    {
    x: 0.30633634328842163,
    y: 0.06288072466850281
    },
    {
    x: 0.9092482924461365,
    y: 0.06288072466850281
    },
    {
    x: 0.9092482924461365,
    y: 0.9880070686340332
    },
    {
    x: 0.30633634328842163,
    y: 0.9880070686340332
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8244898319244385,
    bounding_box: [
    {
    x: 0.304099440574646,
    y: 0.4793602228164673
    },
    {
    x: 0.8971819281578064,
    y: 0.4793602228164673
    },
    {
    x: 0.8971819281578064,
    y: 0.9917244911193848
    },
    {
    x: 0.304099440574646,
    y: 0.9917244911193848
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5313692688941956,
    bounding_box: [
    {
    x: 0.7215371131896973,
    y: 0
    },
    {
    x: 0.9944736361503601,
    y: 0
    },
    {
    x: 0.9944736361503601,
    y: 0.7510338425636292
    },
    {
    x: 0.7215371131896973,
    y: 0.7510338425636292
    }
    ]
    },
    {
    description: "Cabinetry",
    confidence: 0.5215039849281311,
    bounding_box: [
    {
    x: 0.0004968968569301069,
    y: 0.6981890797615051
    },
    {
    x: 0.12129504233598709,
    y: 0.6981890797615051
    },
    {
    x: 0.12129504233598709,
    y: 0.9973056316375732
    },
    {
    x: 0.0004968968569301069,
    y: 0.9973056316375732
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 34772,
    time_in_milliseconds: 1160225.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.73782396316528
    },
    {
    description: "Facial hair",
    confidence: 97.20691442489624
    },
    {
    description: "Beard",
    confidence: 95.2366054058075
    },
    {
    description: "Eyewear",
    confidence: 95.0524091720581
    },
    {
    description: "Glasses",
    confidence: 92.6969051361084
    },
    {
    description: "Hairstyle",
    confidence: 90.20282626152039
    },
    {
    description: "Moustache",
    confidence: 88.44340443611145
    },
    {
    description: "Vision care",
    confidence: 74.2175042629242
    },
    {
    description: "Houseplant",
    confidence: 69.15513873100281
    },
    {
    description: "Afro",
    confidence: 68.60107183456421
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9410451054573059,
    bounding_box: [
    {
    x: 0.4826619327068329,
    y: 0.26463958621025085
    },
    {
    x: 0.6541727781295776,
    y: 0.26463958621025085
    },
    {
    x: 0.6541727781295776,
    y: 0.3916538953781128
    },
    {
    x: 0.4826619327068329,
    y: 0.3916538953781128
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8245017528533936,
    bounding_box: [
    {
    x: 0.11950550228357315,
    y: 0.06010003760457039
    },
    {
    x: 0.9831199049949646,
    y: 0.06010003760457039
    },
    {
    x: 0.9831199049949646,
    y: 0.9865841269493103
    },
    {
    x: 0.11950550228357315,
    y: 0.9865841269493103
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8098820447921753,
    bounding_box: [
    {
    x: 0.1459067314863205,
    y: 0.49269044399261475
    },
    {
    x: 0.9720109105110168,
    y: 0.49269044399261475
    },
    {
    x: 0.9720109105110168,
    y: 0.9893720746040344
    },
    {
    x: 0.1459067314863205,
    y: 0.9893720746040344
    }
    ]
    },
    {
    description: "Bracelet",
    confidence: 0.6721975207328796,
    bounding_box: [
    {
    x: 0.6781466603279114,
    y: 0.7520031929016113
    },
    {
    x: 0.7725082039833069,
    y: 0.7520031929016113
    },
    {
    x: 0.7725082039833069,
    y: 0.9654553532600403
    },
    {
    x: 0.6781466603279114,
    y: 0.9654553532600403
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5027140974998474,
    bounding_box: [
    {
    x: 0.07891792804002762,
    y: 0.0890117883682251
    },
    {
    x: 0.23871105909347534,
    y: 0.0890117883682251
    },
    {
    x: 0.23871105909347534,
    y: 0.3174913227558136
    },
    {
    x: 0.07891792804002762,
    y: 0.3174913227558136
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 34801,
    time_in_milliseconds: 1161194,
    labels: [
    {
    description: "Hair",
    confidence: 98.77687096595764
    },
    {
    description: "Facial hair",
    confidence: 98.10147881507874
    },
    {
    description: "Beard",
    confidence: 97.18632102012634
    },
    {
    description: "Eyewear",
    confidence: 95.63094973564148
    },
    {
    description: "Photograph",
    confidence: 95.27485370635986
    },
    {
    description: "Moustache",
    confidence: 94.46578025817871
    },
    {
    description: "Glasses",
    confidence: 94.30001974105835
    },
    {
    description: "Hairstyle",
    confidence: 89.63527083396912
    },
    {
    description: "Snapshot",
    confidence: 84.34103727340698
    },
    {
    description: "Smile",
    confidence: 81.12622499465942
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9413576126098633,
    bounding_box: [
    {
    x: 0.5086604356765747,
    y: 0.27782541513442993
    },
    {
    x: 0.6856458187103271,
    y: 0.27782541513442993
    },
    {
    x: 0.6856458187103271,
    y: 0.40064266324043274
    },
    {
    x: 0.5086604356765747,
    y: 0.40064266324043274
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8009471297264099,
    bounding_box: [
    {
    x: 0.2871219217777252,
    y: 0.4735298156738281
    },
    {
    x: 0.9092413187026978,
    y: 0.4735298156738281
    },
    {
    x: 0.9092413187026978,
    y: 0.9906721711158752
    },
    {
    x: 0.2871219217777252,
    y: 0.9906721711158752
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7761120200157166,
    bounding_box: [
    {
    x: 0.3042396605014801,
    y: 0.05829542502760887
    },
    {
    x: 0.9123332500457764,
    y: 0.05829542502760887
    },
    {
    x: 0.9123332500457764,
    y: 0.9859454035758972
    },
    {
    x: 0.3042396605014801,
    y: 0.9859454035758972
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 34888,
    time_in_milliseconds: 1164096,
    labels: [
    {
    description: "Hair",
    confidence: 98.12582731246948
    },
    {
    description: "Facial hair",
    confidence: 97.30031490325928
    },
    {
    description: "Beard",
    confidence: 94.75650191307068
    },
    {
    description: "Eyewear",
    confidence: 94.320547580719
    },
    {
    description: "Moustache",
    confidence: 87.89899945259094
    },
    {
    description: "Glasses",
    confidence: 85.38076281547546
    },
    {
    description: "Vision care",
    confidence: 68.75328421592712
    },
    {
    description: "Muscle",
    confidence: 64.24193978309631
    },
    {
    description: "Houseplant",
    confidence: 61.23513579368591
    },
    {
    description: "Finger",
    confidence: 60.68736910820007
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9313251376152039,
    bounding_box: [
    {
    x: 0.5192741751670837,
    y: 0.2538449466228485
    },
    {
    x: 0.6878473162651062,
    y: 0.2538449466228485
    },
    {
    x: 0.6878473162651062,
    y: 0.37463828921318054
    },
    {
    x: 0.5192741751670837,
    y: 0.37463828921318054
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7605472803115845,
    bounding_box: [
    {
    x: 0.24359501898288727,
    y: 0.06461212784051895
    },
    {
    x: 0.9422515034675598,
    y: 0.06461212784051895
    },
    {
    x: 0.9422515034675598,
    y: 0.9942885041236877
    },
    {
    x: 0.24359501898288727,
    y: 0.9942885041236877
    }
    ]
    },
    {
    description: "Bracelet",
    confidence: 0.6987487077713013,
    bounding_box: [
    {
    x: 0.6350657939910889,
    y: 0.7685495018959045
    },
    {
    x: 0.7265641093254089,
    y: 0.7685495018959045
    },
    {
    x: 0.7265641093254089,
    y: 0.9758077263832092
    },
    {
    x: 0.6350657939910889,
    y: 0.9758077263832092
    }
    ]
    },
    {
    description: "Nightstand",
    confidence: 0.5867084264755249,
    bounding_box: [
    {
    x: 0.0007379345479421318,
    y: 0.6950400471687317
    },
    {
    x: 0.12120632082223892,
    y: 0.6950400471687317
    },
    {
    x: 0.12120632082223892,
    y: 0.9965579509735107
    },
    {
    x: 0.0007379345479421318,
    y: 0.9965579509735107
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 34917,
    time_in_milliseconds: 1165064,
    labels: [
    {
    description: "Hair",
    confidence: 98.54605793952942
    },
    {
    description: "Facial hair",
    confidence: 97.55393266677856
    },
    {
    description: "Beard",
    confidence: 95.77029347419739
    },
    {
    description: "Moustache",
    confidence: 91.97301864624023
    },
    {
    description: "Glasses",
    confidence: 91.76015257835388
    },
    {
    description: "Eyewear",
    confidence: 90.26086330413818
    },
    {
    description: "Hairstyle",
    confidence: 86.86584234237671
    },
    {
    description: "Snapshot",
    confidence: 82.91832208633423
    },
    {
    description: "Smile",
    confidence: 81.12622499465942
    },
    {
    description: "Dreadlocks",
    confidence: 80.78565001487732
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9402869343757629,
    bounding_box: [
    {
    x: 0.5176433324813843,
    y: 0.2715170085430145
    },
    {
    x: 0.6945354342460632,
    y: 0.2715170085430145
    },
    {
    x: 0.6945354342460632,
    y: 0.39681294560432434
    },
    {
    x: 0.5176433324813843,
    y: 0.39681294560432434
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8344238996505737,
    bounding_box: [
    {
    x: 0.29249995946884155,
    y: 0.4765155017375946
    },
    {
    x: 0.9104630947113037,
    y: 0.4765155017375946
    },
    {
    x: 0.9104630947113037,
    y: 0.991714358329773
    },
    {
    x: 0.29249995946884155,
    y: 0.991714358329773
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7795281410217285,
    bounding_box: [
    {
    x: 0.2992789149284363,
    y: 0.06302215903997421
    },
    {
    x: 0.9154362082481384,
    y: 0.06302215903997421
    },
    {
    x: 0.9154362082481384,
    y: 0.9910933971405029
    },
    {
    x: 0.2992789149284363,
    y: 0.9910933971405029
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5323958992958069,
    bounding_box: [
    {
    x: 0.7333104610443115,
    y: 0
    },
    {
    x: 0.9963728785514832,
    y: 0
    },
    {
    x: 0.9963728785514832,
    y: 0.7458723187446594
    },
    {
    x: 0.7333104610443115,
    y: 0.7458723187446594
    }
    ]
    },
    {
    description: "Cabinetry",
    confidence: 0.5041701197624207,
    bounding_box: [
    {
    x: 0.0006895018741488457,
    y: 0.6979163289070129
    },
    {
    x: 0.1218048632144928,
    y: 0.6979163289070129
    },
    {
    x: 0.1218048632144928,
    y: 0.9968119263648987
    },
    {
    x: 0.0006895018741488457,
    y: 0.9968119263648987
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35091,
    time_in_milliseconds: 1170869.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.15207719802856
    },
    {
    description: "Facial hair",
    confidence: 95.96086144447327
    },
    {
    description: "Eyewear",
    confidence: 93.36798787117004
    },
    {
    description: "Beard",
    confidence: 93.22097301483154
    },
    {
    description: "Glasses",
    confidence: 87.4778687953949
    },
    {
    description: "Hairstyle",
    confidence: 86.29165291786194
    },
    {
    description: "Moustache",
    confidence: 82.81242251396179
    },
    {
    description: "Finger",
    confidence: 76.73051953315735
    },
    {
    description: "Dreadlocks",
    confidence: 76.10557675361633
    },
    {
    description: "Vision care",
    confidence: 69.94730830192566
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9411987066268921,
    bounding_box: [
    {
    x: 0.5217826962471008,
    y: 0.26412904262542725
    },
    {
    x: 0.69203782081604,
    y: 0.26412904262542725
    },
    {
    x: 0.69203782081604,
    y: 0.3886891305446625
    },
    {
    x: 0.5217826962471008,
    y: 0.3886891305446625
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7779292464256287,
    bounding_box: [
    {
    x: 0.22924315929412842,
    y: 0.057628098875284195
    },
    {
    x: 0.9584868550300598,
    y: 0.057628098875284195
    },
    {
    x: 0.9584868550300598,
    y: 0.9900708198547363
    },
    {
    x: 0.22924315929412842,
    y: 0.9900708198547363
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7744323015213013,
    bounding_box: [
    {
    x: 0.23693466186523438,
    y: 0.46900567412376404
    },
    {
    x: 0.9403448700904846,
    y: 0.46900567412376404
    },
    {
    x: 0.9403448700904846,
    y: 0.9938554763793945
    },
    {
    x: 0.23693466186523438,
    y: 0.9938554763793945
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5923375487327576,
    bounding_box: [
    {
    x: 0.20419572293758392,
    y: 0.636089563369751
    },
    {
    x: 0.341188907623291,
    y: 0.636089563369751
    },
    {
    x: 0.341188907623291,
    y: 0.9247210621833801
    },
    {
    x: 0.20419572293758392,
    y: 0.9247210621833801
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35149,
    time_in_milliseconds: 1172804,
    labels: [
    {
    description: "Hair",
    confidence: 98.40953350067139
    },
    {
    description: "Facial hair",
    confidence: 97.4734902381897
    },
    {
    description: "Eyewear",
    confidence: 97.38759398460388
    },
    {
    description: "Beard",
    confidence: 94.70040798187256
    },
    {
    description: "Glasses",
    confidence: 91.87448620796204
    },
    {
    description: "Moustache",
    confidence: 90.08175730705261
    },
    {
    description: "Finger",
    confidence: 88.46139311790466
    },
    {
    description: "Hairstyle",
    confidence: 86.59101724624634
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Vision care",
    confidence: 79.36168909072876
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9422710537910461,
    bounding_box: [
    {
    x: 0.5324200987815857,
    y: 0.2608640789985657
    },
    {
    x: 0.7086024880409241,
    y: 0.2608640789985657
    },
    {
    x: 0.7086024880409241,
    y: 0.3797397315502167
    },
    {
    x: 0.5324200987815857,
    y: 0.3797397315502167
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7747032642364502,
    bounding_box: [
    {
    x: 0.09199608117341995,
    y: 0.05587957799434662
    },
    {
    x: 0.9386388659477234,
    y: 0.05587957799434662
    },
    {
    x: 0.9386388659477234,
    y: 0.9880528450012207
    },
    {
    x: 0.09199608117341995,
    y: 0.9880528450012207
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.6882193088531494,
    bounding_box: [
    {
    x: 0.08128375560045242,
    y: 0.09148681163787842
    },
    {
    x: 0.23762676119804382,
    y: 0.09148681163787842
    },
    {
    x: 0.23762676119804382,
    y: 0.32305416464805603
    },
    {
    x: 0.08128375560045242,
    y: 0.32305416464805603
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6365644335746765,
    bounding_box: [
    {
    x: 0.14767900109291077,
    y: 0.48874223232269287
    },
    {
    x: 0.9429914951324463,
    y: 0.48874223232269287
    },
    {
    x: 0.9429914951324463,
    y: 0.9917516112327576
    },
    {
    x: 0.14767900109291077,
    y: 0.9917516112327576
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35236,
    time_in_milliseconds: 1175707,
    labels: [
    {
    description: "Hair",
    confidence: 98.84644746780396
    },
    {
    description: "Facial hair",
    confidence: 98.6018419265747
    },
    {
    description: "Beard",
    confidence: 98.22091460227966
    },
    {
    description: "Eyewear",
    confidence: 96.23547792434692
    },
    {
    description: "Moustache",
    confidence: 94.89961862564087
    },
    {
    description: "Glasses",
    confidence: 92.20112562179565
    },
    {
    description: "Hairstyle",
    confidence: 89.63527083396912
    },
    {
    description: "Vision care",
    confidence: 77.37371325492859
    },
    {
    description: "Smile",
    confidence: 75.41265487670898
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9414865374565125,
    bounding_box: [
    {
    x: 0.5209908485412598,
    y: 0.28046759963035583
    },
    {
    x: 0.6942322850227356,
    y: 0.28046759963035583
    },
    {
    x: 0.6942322850227356,
    y: 0.4009220600128174
    },
    {
    x: 0.5209908485412598,
    y: 0.4009220600128174
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.9088602662086487,
    bounding_box: [
    {
    x: 0.12199592590332031,
    y: 0.4905746877193451
    },
    {
    x: 0.949600875377655,
    y: 0.4905746877193451
    },
    {
    x: 0.949600875377655,
    y: 0.99425208568573
    },
    {
    x: 0.12199592590332031,
    y: 0.99425208568573
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7666592597961426,
    bounding_box: [
    {
    x: 0.05248013511300087,
    y: 0.05406078323721886
    },
    {
    x: 0.9636346697807312,
    y: 0.05406078323721886
    },
    {
    x: 0.9636346697807312,
    y: 0.9919520020484924
    },
    {
    x: 0.05248013511300087,
    y: 0.9919520020484924
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35352,
    time_in_milliseconds: 1179579,
    labels: [
    {
    description: "Hair",
    confidence: 98.78031611442566
    },
    {
    description: "Facial hair",
    confidence: 98.25758934020996
    },
    {
    description: "Beard",
    confidence: 97.83521294593811
    },
    {
    description: "Eyewear",
    confidence: 96.36379480361938
    },
    {
    description: "Glasses",
    confidence: 92.78955459594727
    },
    {
    description: "Moustache",
    confidence: 90.86881279945374
    },
    {
    description: "Hairstyle",
    confidence: 88.3222758769989
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Vision care",
    confidence: 81.76144361495972
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.941167950630188,
    bounding_box: [
    {
    x: 0.512013852596283,
    y: 0.28174641728401184
    },
    {
    x: 0.6850441098213196,
    y: 0.28174641728401184
    },
    {
    x: 0.6850441098213196,
    y: 0.4043111503124237
    },
    {
    x: 0.512013852596283,
    y: 0.4043111503124237
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7692146301269531,
    bounding_box: [
    {
    x: 0.11777707189321518,
    y: 0.49718260765075684
    },
    {
    x: 0.9704909920692444,
    y: 0.49718260765075684
    },
    {
    x: 0.9704909920692444,
    y: 0.9943578243255615
    },
    {
    x: 0.11777707189321518,
    y: 0.9943578243255615
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7335858941078186,
    bounding_box: [
    {
    x: 0.08183171600103378,
    y: 0.05487193539738655
    },
    {
    x: 0.9873478412628174,
    y: 0.05487193539738655
    },
    {
    x: 0.9873478412628174,
    y: 0.9914231896400452
    },
    {
    x: 0.08183171600103378,
    y: 0.9914231896400452
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5561981201171875,
    bounding_box: [
    {
    x: 0.08156992495059967,
    y: 0.09024173766374588
    },
    {
    x: 0.23756133019924164,
    y: 0.09024173766374588
    },
    {
    x: 0.23756133019924164,
    y: 0.3206968903541565
    },
    {
    x: 0.08156992495059967,
    y: 0.3206968903541565
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35381,
    time_in_milliseconds: 1180545,
    labels: [
    {
    description: "Hair",
    confidence: 97.71169424057007
    },
    {
    description: "Glasses",
    confidence: 86.65847182273865
    },
    {
    description: "Facial hair",
    confidence: 85.54058074951172
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Houseplant",
    confidence: 79.74522113800049
    },
    {
    description: "Beard",
    confidence: 77.9535710811615
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    },
    {
    description: "Moustache",
    confidence: 68.87235641479492
    },
    {
    description: "Vision care",
    confidence: 68.09117197990417
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9417142271995544,
    bounding_box: [
    {
    x: 0.5102488398551941,
    y: 0.2772625982761383
    },
    {
    x: 0.6880596280097961,
    y: 0.2772625982761383
    },
    {
    x: 0.6880596280097961,
    y: 0.3995964527130127
    },
    {
    x: 0.5102488398551941,
    y: 0.3995964527130127
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.826967179775238,
    bounding_box: [
    {
    x: 0.27503982186317444,
    y: 0.4777339994907379
    },
    {
    x: 0.9344913959503174,
    y: 0.4777339994907379
    },
    {
    x: 0.9344913959503174,
    y: 0.9929287433624268
    },
    {
    x: 0.27503982186317444,
    y: 0.9929287433624268
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.748042106628418,
    bounding_box: [
    {
    x: 0.276696115732193,
    y: 0.06557467579841614
    },
    {
    x: 0.9442102909088135,
    y: 0.06557467579841614
    },
    {
    x: 0.9442102909088135,
    y: 0.9965784549713135
    },
    {
    x: 0.276696115732193,
    y: 0.9965784549713135
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5365768671035767,
    bounding_box: [
    {
    x: 0.0008907679002732038,
    y: 0.6984508037567139
    },
    {
    x: 0.12198448926210403,
    y: 0.6984508037567139
    },
    {
    x: 0.12198448926210403,
    y: 0.9971892237663269
    },
    {
    x: 0.0008907679002732038,
    y: 0.9971892237663269
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35410,
    time_in_milliseconds: 1181513.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.83031845092773
    },
    {
    description: "Facial hair",
    confidence: 98.04535508155823
    },
    {
    description: "Beard",
    confidence: 96.33930921554565
    },
    {
    description: "Eyewear",
    confidence: 95.95322608947754
    },
    {
    description: "Photograph",
    confidence: 95.27485370635986
    },
    {
    description: "Moustache",
    confidence: 93.99375319480896
    },
    {
    description: "Glasses",
    confidence: 93.90499591827393
    },
    {
    description: "Hairstyle",
    confidence: 90.02647399902344
    },
    {
    description: "Dreadlocks",
    confidence: 89.49100375175476
    },
    {
    description: "Snapshot",
    confidence: 84.54840183258057
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9408036470413208,
    bounding_box: [
    {
    x: 0.5160946249961853,
    y: 0.2676149308681488
    },
    {
    x: 0.6880147457122803,
    y: 0.2676149308681488
    },
    {
    x: 0.6880147457122803,
    y: 0.39122602343559265
    },
    {
    x: 0.5160946249961853,
    y: 0.39122602343559265
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8725551962852478,
    bounding_box: [
    {
    x: 0.30617672204971313,
    y: 0.4765380620956421
    },
    {
    x: 0.9048238396644592,
    y: 0.4765380620956421
    },
    {
    x: 0.9048238396644592,
    y: 0.9909165501594543
    },
    {
    x: 0.30617672204971313,
    y: 0.9909165501594543
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8117009997367859,
    bounding_box: [
    {
    x: 0.3054142892360687,
    y: 0.05664023384451866
    },
    {
    x: 0.9121785163879395,
    y: 0.05664023384451866
    },
    {
    x: 0.9121785163879395,
    y: 0.9874598979949951
    },
    {
    x: 0.3054142892360687,
    y: 0.9874598979949951
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5429995059967041,
    bounding_box: [
    {
    x: 0.7265141606330872,
    y: 0
    },
    {
    x: 0.9952479004859924,
    y: 0
    },
    {
    x: 0.9952479004859924,
    y: 0.7374016642570496
    },
    {
    x: 0.7265141606330872,
    y: 0.7374016642570496
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5311322808265686,
    bounding_box: [
    {
    x: 0.08402862399816513,
    y: 0.09215426445007324
    },
    {
    x: 0.23588991165161133,
    y: 0.09215426445007324
    },
    {
    x: 0.23588991165161133,
    y: 0.3210541903972626
    },
    {
    x: 0.08402862399816513,
    y: 0.3210541903972626
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5297760367393494,
    bounding_box: [
    {
    x: 0.04590711370110512,
    y: 0.4294643700122833
    },
    {
    x: 0.1096252053976059,
    y: 0.4294643700122833
    },
    {
    x: 0.1096252053976059,
    y: 0.7086634635925293
    },
    {
    x: 0.04590711370110512,
    y: 0.7086634635925293
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35468,
    time_in_milliseconds: 1183449,
    labels: [
    {
    description: "Hair",
    confidence: 98.48911166191101
    },
    {
    description: "Eyewear",
    confidence: 98.23066592216492
    },
    {
    description: "Facial hair",
    confidence: 95.96086144447327
    },
    {
    description: "Glasses",
    confidence: 95.18197178840637
    },
    {
    description: "Finger",
    confidence: 92.14057922363281
    },
    {
    description: "Beard",
    confidence: 91.42735004425049
    },
    {
    description: "Hairstyle",
    confidence: 86.86584234237671
    },
    {
    description: "Sign language",
    confidence: 83.63817930221558
    },
    {
    description: "Thumb",
    confidence: 83.58018398284912
    },
    {
    description: "Cool",
    confidence: 83.27280879020691
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.937958300113678,
    bounding_box: [
    {
    x: 0.5211554765701294,
    y: 0.25906285643577576
    },
    {
    x: 0.6990488171577454,
    y: 0.25906285643577576
    },
    {
    x: 0.6990488171577454,
    y: 0.38391003012657166
    },
    {
    x: 0.5211554765701294,
    y: 0.38391003012657166
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8541964888572693,
    bounding_box: [
    {
    x: 0.0954286977648735,
    y: 0.05556666478514671
    },
    {
    x: 0.9712865948677063,
    y: 0.05556666478514671
    },
    {
    x: 0.9712865948677063,
    y: 0.9915809035301208
    },
    {
    x: 0.0954286977648735,
    y: 0.9915809035301208
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.567938506603241,
    bounding_box: [
    {
    x: 0.16490580141544342,
    y: 0.4952550232410431
    },
    {
    x: 0.9632603526115417,
    y: 0.4952550232410431
    },
    {
    x: 0.9632603526115417,
    y: 0.9918040633201599
    },
    {
    x: 0.16490580141544342,
    y: 0.9918040633201599
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5091860294342041,
    bounding_box: [
    {
    x: 0.0014716259902343154,
    y: 0.6973109245300293
    },
    {
    x: 0.11977493762969971,
    y: 0.6973109245300293
    },
    {
    x: 0.11977493762969971,
    y: 0.996948778629303
    },
    {
    x: 0.0014716259902343154,
    y: 0.996948778629303
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35497,
    time_in_milliseconds: 1184416.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.5869824886322
    },
    {
    description: "Facial hair",
    confidence: 95.44806480407715
    },
    {
    description: "Beard",
    confidence: 92.51722693443298
    },
    {
    description: "Moustache",
    confidence: 89.86974358558655
    },
    {
    description: "Glasses",
    confidence: 88.20264935493469
    },
    {
    description: "Hairstyle",
    confidence: 87.11950778961182
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Houseplant",
    confidence: 73.3078122138977
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9461128115653992,
    bounding_box: [
    {
    x: 0.5081192255020142,
    y: 0.2958017587661743
    },
    {
    x: 0.6862805485725403,
    y: 0.2958017587661743
    },
    {
    x: 0.6862805485725403,
    y: 0.4176989793777466
    },
    {
    x: 0.5081192255020142,
    y: 0.4176989793777466
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7779395580291748,
    bounding_box: [
    {
    x: 0.3058512508869171,
    y: 0.4843277633190155
    },
    {
    x: 0.9080431461334229,
    y: 0.4843277633190155
    },
    {
    x: 0.9080431461334229,
    y: 0.9906587600708008
    },
    {
    x: 0.3058512508869171,
    y: 0.9906587600708008
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7645919322967529,
    bounding_box: [
    {
    x: 0.3098585903644562,
    y: 0.05262115225195885
    },
    {
    x: 0.9139398336410522,
    y: 0.05262115225195885
    },
    {
    x: 0.9139398336410522,
    y: 0.9876745343208313
    },
    {
    x: 0.3098585903644562,
    y: 0.9876745343208313
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5055961012840271,
    bounding_box: [
    {
    x: 0.08151869475841522,
    y: 0.09175919741392136
    },
    {
    x: 0.23765897750854492,
    y: 0.09175919741392136
    },
    {
    x: 0.23765897750854492,
    y: 0.31992289423942566
    },
    {
    x: 0.08151869475841522,
    y: 0.31992289423942566
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35526,
    time_in_milliseconds: 1185384,
    labels: [
    {
    description: "Book",
    confidence: 77.6689887046814
    },
    {
    description: "Glasses",
    confidence: 76.88568830490112
    },
    {
    description: "Bookcase",
    confidence: 75.76913833618164
    },
    {
    description: "Library",
    confidence: 71.32934331893921
    },
    {
    description: "Publication",
    confidence: 64.1063928604126
    },
    {
    description: "Author",
    confidence: 52.378225326538086
    },
    {
    description: "Bookselling",
    confidence: 50.88120102882385
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.911561131477356,
    bounding_box: [
    {
    x: 0.3832383155822754,
    y: 0.41687408089637756
    },
    {
    x: 0.5161501169204712,
    y: 0.41687408089637756
    },
    {
    x: 0.5161501169204712,
    y: 0.5019881129264832
    },
    {
    x: 0.3832383155822754,
    y: 0.5019881129264832
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.862019956111908,
    bounding_box: [
    {
    x: 0.6255137324333191,
    y: 0.027820011600852013
    },
    {
    x: 0.9782617092132568,
    y: 0.027820011600852013
    },
    {
    x: 0.9782617092132568,
    y: 0.9863308072090149
    },
    {
    x: 0.6255137324333191,
    y: 0.9863308072090149
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8019411563873291,
    bounding_box: [
    {
    x: 0.31703028082847595,
    y: 0.2487504929304123
    },
    {
    x: 0.722572386264801,
    y: 0.2487504929304123
    },
    {
    x: 0.722572386264801,
    y: 0.9973958134651184
    },
    {
    x: 0.31703028082847595,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35584,
    time_in_milliseconds: 1187319,
    labels: [
    {
    description: "News",
    confidence: 89.42439556121826
    },
    {
    description: "Photo caption",
    confidence: 76.02911591529846
    },
    {
    description: "Media",
    confidence: 75.98154544830322
    },
    {
    description: "Eyewear",
    confidence: 68.63138675689697
    },
    {
    description: "Facial hair",
    confidence: 68.087238073349
    },
    {
    description: "Glasses",
    confidence: 67.38030314445496
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Vision care",
    confidence: 54.48664426803589
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9192734360694885,
    bounding_box: [
    {
    x: 0.6325783729553223,
    y: 0.3084943890571594
    },
    {
    x: 0.7651163935661316,
    y: 0.3084943890571594
    },
    {
    x: 0.7651163935661316,
    y: 0.40010130405426025
    },
    {
    x: 0.6325783729553223,
    y: 0.40010130405426025
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.8932641744613647,
    bounding_box: [
    {
    x: 0.19311515986919403,
    y: 0.31856009364128113
    },
    {
    x: 0.32797718048095703,
    y: 0.31856009364128113
    },
    {
    x: 0.32797718048095703,
    y: 0.4056812524795532
    },
    {
    x: 0.19311515986919403,
    y: 0.4056812524795532
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.840965986251831,
    bounding_box: [
    {
    x: 0.12451062351465225,
    y: 0.16092035174369812
    },
    {
    x: 0.4986276626586914,
    y: 0.16092035174369812
    },
    {
    x: 0.4986276626586914,
    y: 0.854661226272583
    },
    {
    x: 0.12451062351465225,
    y: 0.854661226272583
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7683938145637512,
    bounding_box: [
    {
    x: 0.49270978569984436,
    y: 0.14025186002254486
    },
    {
    x: 0.8699827194213867,
    y: 0.14025186002254486
    },
    {
    x: 0.8699827194213867,
    y: 0.8507189750671387
    },
    {
    x: 0.49270978569984436,
    y: 0.8507189750671387
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5716522932052612,
    bounding_box: [
    {
    x: 0.4969557821750641,
    y: 0.2499244660139084
    },
    {
    x: 0.8701230883598328,
    y: 0.2499244660139084
    },
    {
    x: 0.8701230883598328,
    y: 0.8333301544189453
    },
    {
    x: 0.4969557821750641,
    y: 0.8333301544189453
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35816,
    time_in_milliseconds: 1195060,
    labels: [
    {
    description: "Hair",
    confidence: 98.91923069953918
    },
    {
    description: "Facial hair",
    confidence: 96.61180377006531
    },
    {
    description: "Beard",
    confidence: 95.72392106056213
    },
    {
    description: "Moustache",
    confidence: 92.12673902511597
    },
    {
    description: "Hairstyle",
    confidence: 91.12992286682129
    },
    {
    description: "Eyewear",
    confidence: 86.2449049949646
    },
    {
    description: "Glasses",
    confidence: 83.378666639328
    },
    {
    description: "Outerwear",
    confidence: 74.90161657333374
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    },
    {
    description: "T-shirt",
    confidence: 65.85062146186829
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9311628937721252,
    bounding_box: [
    {
    x: 0.4777536690235138,
    y: 0.29482653737068176
    },
    {
    x: 0.651964008808136,
    y: 0.29482653737068176
    },
    {
    x: 0.651964008808136,
    y: 0.41583672165870667
    },
    {
    x: 0.4777536690235138,
    y: 0.41583672165870667
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8179463744163513,
    bounding_box: [
    {
    x: 0.2924710810184479,
    y: 0.4802124798297882
    },
    {
    x: 0.9051952362060547,
    y: 0.4802124798297882
    },
    {
    x: 0.9051952362060547,
    y: 0.9927467107772827
    },
    {
    x: 0.2924710810184479,
    y: 0.9927467107772827
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7798091173171997,
    bounding_box: [
    {
    x: 0.29875192046165466,
    y: 0.058051977306604385
    },
    {
    x: 0.9061628580093384,
    y: 0.058051977306604385
    },
    {
    x: 0.9061628580093384,
    y: 0.9894275665283203
    },
    {
    x: 0.29875192046165466,
    y: 0.9894275665283203
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 35990,
    time_in_milliseconds: 1200866,
    labels: [
    {
    description: "Hair",
    confidence: 98.65952134132385
    },
    {
    description: "Eyewear",
    confidence: 96.70583605766296
    },
    {
    description: "Facial hair",
    confidence: 93.88203024864197
    },
    {
    description: "Glasses",
    confidence: 92.50534772872925
    },
    {
    description: "Beard",
    confidence: 91.81267023086548
    },
    {
    description: "Hairstyle",
    confidence: 89.73842859268188
    },
    {
    description: "Moustache",
    confidence: 88.70149254798889
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Vision care",
    confidence: 80.6193470954895
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9353567957878113,
    bounding_box: [
    {
    x: 0.492102712392807,
    y: 0.2933872640132904
    },
    {
    x: 0.6676444411277771,
    y: 0.2933872640132904
    },
    {
    x: 0.6676444411277771,
    y: 0.4165630638599396
    },
    {
    x: 0.492102712392807,
    y: 0.4165630638599396
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8623973727226257,
    bounding_box: [
    {
    x: 0.17433644831180573,
    y: 0.4846833646297455
    },
    {
    x: 0.9450690746307373,
    y: 0.4846833646297455
    },
    {
    x: 0.9450690746307373,
    y: 0.9948368072509766
    },
    {
    x: 0.17433644831180573,
    y: 0.9948368072509766
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7883017063140869,
    bounding_box: [
    {
    x: 0.14591486752033234,
    y: 0.08048708736896515
    },
    {
    x: 0.9640011191368103,
    y: 0.08048708736896515
    },
    {
    x: 0.9640011191368103,
    y: 0.9856159687042236
    },
    {
    x: 0.14591486752033234,
    y: 0.9856159687042236
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7532104849815369,
    bounding_box: [
    {
    x: 0.14002087712287903,
    y: 0.05855949595570564
    },
    {
    x: 0.975569486618042,
    y: 0.05855949595570564
    },
    {
    x: 0.975569486618042,
    y: 0.9922874569892883
    },
    {
    x: 0.14002087712287903,
    y: 0.9922874569892883
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 36019,
    time_in_milliseconds: 1201833,
    labels: [
    {
    description: "Hair",
    confidence: 99.02000427246094
    },
    {
    description: "Facial hair",
    confidence: 97.03981280326843
    },
    {
    description: "Beard",
    confidence: 95.28738856315613
    },
    {
    description: "Hairstyle",
    confidence: 93.1064248085022
    },
    {
    description: "Moustache",
    confidence: 92.2766387462616
    },
    {
    description: "Glasses",
    confidence: 86.65847182273865
    },
    {
    description: "Afro",
    confidence: 81.5172791481018
    },
    {
    description: "Houseplant",
    confidence: 79.29196357727051
    },
    {
    description: "Dreadlocks",
    confidence: 78.54666113853455
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9404997825622559,
    bounding_box: [
    {
    x: 0.49301639199256897,
    y: 0.2920258641242981
    },
    {
    x: 0.6786245703697205,
    y: 0.2920258641242981
    },
    {
    x: 0.6786245703697205,
    y: 0.4166669547557831
    },
    {
    x: 0.49301639199256897,
    y: 0.4166669547557831
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8083934187889099,
    bounding_box: [
    {
    x: 0.31770023703575134,
    y: 0.4809567630290985
    },
    {
    x: 0.9116761684417725,
    y: 0.4809567630290985
    },
    {
    x: 0.9116761684417725,
    y: 0.9913285970687866
    },
    {
    x: 0.31770023703575134,
    y: 0.9913285970687866
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7721692323684692,
    bounding_box: [
    {
    x: 0.3120117485523224,
    y: 0.0420171283185482
    },
    {
    x: 0.9157758951187134,
    y: 0.0420171283185482
    },
    {
    x: 0.9157758951187134,
    y: 0.9844915866851807
    },
    {
    x: 0.3120117485523224,
    y: 0.9844915866851807
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5664501786231995,
    bounding_box: [
    {
    x: 0.19817040860652924,
    y: 0.634842574596405
    },
    {
    x: 0.3462854325771332,
    y: 0.634842574596405
    },
    {
    x: 0.3462854325771332,
    y: 0.9253062605857849
    },
    {
    x: 0.19817040860652924,
    y: 0.9253062605857849
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 36077,
    time_in_milliseconds: 1203769,
    labels: [
    {
    description: "Hair",
    confidence: 98.66371750831604
    },
    {
    description: "Facial hair",
    confidence: 96.85657620429993
    },
    {
    description: "Beard",
    confidence: 94.92135643959045
    },
    {
    description: "Photograph",
    confidence: 94.80249285697937
    },
    {
    description: "Moustache",
    confidence: 91.31665229797363
    },
    {
    description: "Glasses",
    confidence: 91.27241373062134
    },
    {
    description: "Hairstyle",
    confidence: 87.97082304954529
    },
    {
    description: "Eyewear",
    confidence: 87.50287890434265
    },
    {
    description: "Houseplant",
    confidence: 84.4780683517456
    },
    {
    description: "Smile",
    confidence: 84.0890645980835
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9327253699302673,
    bounding_box: [
    {
    x: 0.504411518573761,
    y: 0.28943324089050293
    },
    {
    x: 0.6862403750419617,
    y: 0.28943324089050293
    },
    {
    x: 0.6862403750419617,
    y: 0.4200066030025482
    },
    {
    x: 0.504411518573761,
    y: 0.4200066030025482
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8464187979698181,
    bounding_box: [
    {
    x: 0.29034313559532166,
    y: 0.47090134024620056
    },
    {
    x: 0.9024970531463623,
    y: 0.47090134024620056
    },
    {
    x: 0.9024970531463623,
    y: 0.9949665069580078
    },
    {
    x: 0.29034313559532166,
    y: 0.9949665069580078
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7799359560012817,
    bounding_box: [
    {
    x: 0.2988590598106384,
    y: 0.0459422767162323
    },
    {
    x: 0.9005997180938721,
    y: 0.0459422767162323
    },
    {
    x: 0.9005997180938721,
    y: 0.9905321598052979
    },
    {
    x: 0.2988590598106384,
    y: 0.9905321598052979
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5270243287086487,
    bounding_box: [
    {
    x: 0.7193040251731873,
    y: 0
    },
    {
    x: 0.9960246086120605,
    y: 0
    },
    {
    x: 0.9960246086120605,
    y: 0.7557024359703064
    },
    {
    x: 0.7193040251731873,
    y: 0.7557024359703064
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 36106,
    time_in_milliseconds: 1204737,
    labels: [
    {
    description: "Library",
    confidence: 77.97207832336426
    },
    {
    description: "Bookcase",
    confidence: 74.7333824634552
    },
    {
    description: "Glasses",
    confidence: 72.36500382423401
    },
    {
    description: "Book",
    confidence: 71.51680588722229
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Furniture",
    confidence: 61.48442625999451
    },
    {
    description: "Publication",
    confidence: 56.72217011451721
    },
    {
    description: "Shelving",
    confidence: 55.818337202072144
    },
    {
    description: "Author",
    confidence: 51.499366760253906
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9211621880531311,
    bounding_box: [
    {
    x: 0.5072787404060364,
    y: 0.40561893582344055
    },
    {
    x: 0.6339196562767029,
    y: 0.40561893582344055
    },
    {
    x: 0.6339196562767029,
    y: 0.49208593368530273
    },
    {
    x: 0.5072787404060364,
    y: 0.49208593368530273
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.848402738571167,
    bounding_box: [
    {
    x: 0.6233050227165222,
    y: 0.026593750342726707
    },
    {
    x: 0.9775692820549011,
    y: 0.026593750342726707
    },
    {
    x: 0.9775692820549011,
    y: 0.9844830632209778
    },
    {
    x: 0.6233050227165222,
    y: 0.9844830632209778
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8379620909690857,
    bounding_box: [
    {
    x: 0.2676536738872528,
    y: 0.23207612335681915
    },
    {
    x: 0.7642653584480286,
    y: 0.23207612335681915
    },
    {
    x: 0.7642653584480286,
    y: 0.9917089939117432
    },
    {
    x: 0.2676536738872528,
    y: 0.9917089939117432
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7466050386428833,
    bounding_box: [
    {
    x: 0.2740495502948761,
    y: 0.560430109500885
    },
    {
    x: 0.7539023756980896,
    y: 0.560430109500885
    },
    {
    x: 0.7539023756980896,
    y: 0.9779155254364014
    },
    {
    x: 0.2740495502948761,
    y: 0.9779155254364014
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 36164,
    time_in_milliseconds: 1206672,
    labels: [
    {
    description: "News",
    confidence: 92.42706298828125
    },
    {
    description: "Media",
    confidence: 79.26967740058899
    },
    {
    description: "Facial hair",
    confidence: 75.05148649215698
    },
    {
    description: "Glasses",
    confidence: 70.90374231338501
    },
    {
    description: "Eyewear",
    confidence: 68.63138675689697
    },
    {
    description: "Photo caption",
    confidence: 64.09758925437927
    },
    {
    description: "Vision care",
    confidence: 51.88118815422058
    },
    {
    description: "Television",
    confidence: 51.40594244003296
    },
    {
    description: "Beard",
    confidence: 50.39592981338501
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9416401386260986,
    bounding_box: [
    {
    x: 0.6209123730659485,
    y: 0.3029247522354126
    },
    {
    x: 0.7650274634361267,
    y: 0.3029247522354126
    },
    {
    x: 0.7650274634361267,
    y: 0.3982926905155182
    },
    {
    x: 0.6209123730659485,
    y: 0.3982926905155182
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9332553744316101,
    bounding_box: [
    {
    x: 0.2721277177333832,
    y: 0.33405613899230957
    },
    {
    x: 0.4066723585128784,
    y: 0.33405613899230957
    },
    {
    x: 0.4066723585128784,
    y: 0.4234670400619507
    },
    {
    x: 0.2721277177333832,
    y: 0.4234670400619507
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8233031630516052,
    bounding_box: [
    {
    x: 0.12521019577980042,
    y: 0.1601756513118744
    },
    {
    x: 0.5107972025871277,
    y: 0.1601756513118744
    },
    {
    x: 0.5107972025871277,
    y: 0.8522820472717285
    },
    {
    x: 0.12521019577980042,
    y: 0.8522820472717285
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8000133037567139,
    bounding_box: [
    {
    x: 0.49574923515319824,
    y: 0.10789502412080765
    },
    {
    x: 0.8727572560310364,
    y: 0.10789502412080765
    },
    {
    x: 0.8727572560310364,
    y: 0.8562807440757751
    },
    {
    x: 0.49574923515319824,
    y: 0.8562807440757751
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6997098922729492,
    bounding_box: [
    {
    x: 0.130660742521286,
    y: 0.23180067539215088
    },
    {
    x: 0.5065328478813171,
    y: 0.23180067539215088
    },
    {
    x: 0.5065328478813171,
    y: 0.8357170224189758
    },
    {
    x: 0.130660742521286,
    y: 0.8357170224189758
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6638565063476562,
    bounding_box: [
    {
    x: 0.49754154682159424,
    y: 0.2046833634376526
    },
    {
    x: 0.8724203705787659,
    y: 0.2046833634376526
    },
    {
    x: 0.8724203705787659,
    y: 0.8343364596366882
    },
    {
    x: 0.49754154682159424,
    y: 0.8343364596366882
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 36860,
    time_in_milliseconds: 1229895,
    labels: [
    {
    description: "Hair",
    confidence: 98.6379861831665
    },
    {
    description: "Facial hair",
    confidence: 96.01807594299316
    },
    {
    description: "Photograph",
    confidence: 95.1403021812439
    },
    {
    description: "Beard",
    confidence: 93.84764432907104
    },
    {
    description: "Moustache",
    confidence: 89.30952548980713
    },
    {
    description: "Hairstyle",
    confidence: 87.11950778961182
    },
    {
    description: "Glasses",
    confidence: 85.02119183540344
    },
    {
    description: "Houseplant",
    confidence: 83.28838348388672
    },
    {
    description: "Snapshot",
    confidence: 82.50965476036072
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9358552694320679,
    bounding_box: [
    {
    x: 0.5090718865394592,
    y: 0.2764631509780884
    },
    {
    x: 0.6828119158744812,
    y: 0.2764631509780884
    },
    {
    x: 0.6828119158744812,
    y: 0.39640653133392334
    },
    {
    x: 0.5090718865394592,
    y: 0.39640653133392334
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8146792054176331,
    bounding_box: [
    {
    x: 0.27827170491218567,
    y: 0.48813214898109436
    },
    {
    x: 0.9547901153564453,
    y: 0.48813214898109436
    },
    {
    x: 0.9547901153564453,
    y: 0.9912276268005371
    },
    {
    x: 0.27827170491218567,
    y: 0.9912276268005371
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7194017767906189,
    bounding_box: [
    {
    x: 0.2826235592365265,
    y: 0.060679227113723755
    },
    {
    x: 0.9536607265472412,
    y: 0.060679227113723755
    },
    {
    x: 0.9536607265472412,
    y: 0.995136022567749
    },
    {
    x: 0.2826235592365265,
    y: 0.995136022567749
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5059829950332642,
    bounding_box: [
    {
    x: 0.046312782913446426,
    y: 0.4332106113433838
    },
    {
    x: 0.10932503640651703,
    y: 0.4332106113433838
    },
    {
    x: 0.10932503640651703,
    y: 0.7112123370170593
    },
    {
    x: 0.046312782913446426,
    y: 0.7112123370170593
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5051982998847961,
    bounding_box: [
    {
    x: 0.0009735214989632368,
    y: 0.7040978074073792
    },
    {
    x: 0.12108699232339859,
    y: 0.7040978074073792
    },
    {
    x: 0.12108699232339859,
    y: 0.9973958134651184
    },
    {
    x: 0.0009735214989632368,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 36947,
    time_in_milliseconds: 1232798,
    labels: [
    {
    description: "Hair",
    confidence: 98.827064037323
    },
    {
    description: "Facial hair",
    confidence: 94.11326050758362
    },
    {
    description: "Houseplant",
    confidence: 92.43600368499756
    },
    {
    description: "Hairstyle",
    confidence: 91.18933081626892
    },
    {
    description: "Eyewear",
    confidence: 90.94614386558533
    },
    {
    description: "Glasses",
    confidence: 90.4336929321289
    },
    {
    description: "Afro",
    confidence: 89.71545100212097
    },
    {
    description: "Beard",
    confidence: 89.46991562843323
    },
    {
    description: "Dreadlocks",
    confidence: 87.96210885047913
    },
    {
    description: "Moustache",
    confidence: 85.25015115737915
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9294509887695312,
    bounding_box: [
    {
    x: 0.497066468000412,
    y: 0.26407840847969055
    },
    {
    x: 0.6620364785194397,
    y: 0.26407840847969055
    },
    {
    x: 0.6620364785194397,
    y: 0.3893905580043793
    },
    {
    x: 0.497066468000412,
    y: 0.3893905580043793
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7165055871009827,
    bounding_box: [
    {
    x: 0.31059378385543823,
    y: 0.3656865656375885
    },
    {
    x: 0.9840748906135559,
    y: 0.3656865656375885
    },
    {
    x: 0.9840748906135559,
    y: 0.9913946390151978
    },
    {
    x: 0.31059378385543823,
    y: 0.9913946390151978
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.71134352684021,
    bounding_box: [
    {
    x: 0.2768997848033905,
    y: 0.015226632356643677
    },
    {
    x: 0.9940645694732666,
    y: 0.015226632356643677
    },
    {
    x: 0.9940645694732666,
    y: 0.9971266984939575
    },
    {
    x: 0.2768997848033905,
    y: 0.9971266984939575
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 37034,
    time_in_milliseconds: 1235701,
    labels: [
    {
    description: "Hair",
    confidence: 98.93511533737183
    },
    {
    description: "Facial hair",
    confidence: 95.65275311470032
    },
    {
    description: "Photograph",
    confidence: 94.80249285697937
    },
    {
    description: "Beard",
    confidence: 93.50811839103699
    },
    {
    description: "Hairstyle",
    confidence: 92.53571629524231
    },
    {
    description: "Moustache",
    confidence: 91.97301864624023
    },
    {
    description: "Eyewear",
    confidence: 90.94614386558533
    },
    {
    description: "Glasses",
    confidence: 89.95565176010132
    },
    {
    description: "Dreadlocks",
    confidence: 88.48406672477722
    },
    {
    description: "Houseplant",
    confidence: 83.59917402267456
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9430590867996216,
    bounding_box: [
    {
    x: 0.49254724383354187,
    y: 0.2881205677986145
    },
    {
    x: 0.6720643639564514,
    y: 0.2881205677986145
    },
    {
    x: 0.6720643639564514,
    y: 0.408090740442276
    },
    {
    x: 0.49254724383354187,
    y: 0.408090740442276
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8057766556739807,
    bounding_box: [
    {
    x: 0.30885931849479675,
    y: 0.48677995800971985
    },
    {
    x: 0.8991611003875732,
    y: 0.48677995800971985
    },
    {
    x: 0.8991611003875732,
    y: 0.9913418292999268
    },
    {
    x: 0.30885931849479675,
    y: 0.9913418292999268
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7547093629837036,
    bounding_box: [
    {
    x: 0.29993870854377747,
    y: 0.05539095774292946
    },
    {
    x: 0.8914871215820312,
    y: 0.05539095774292946
    },
    {
    x: 0.8914871215820312,
    y: 0.9948945641517639
    },
    {
    x: 0.29993870854377747,
    y: 0.9948945641517639
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 37121,
    time_in_milliseconds: 1238604,
    labels: [
    {
    description: "Hair",
    confidence: 98.36366772651672
    },
    {
    description: "Eyewear",
    confidence: 97.38759398460388
    },
    {
    description: "Facial hair",
    confidence: 95.9022581577301
    },
    {
    description: "Beard",
    confidence: 92.68110990524292
    },
    {
    description: "Glasses",
    confidence: 90.27918577194214
    },
    {
    description: "Moustache",
    confidence: 86.3451361656189
    },
    {
    description: "Hairstyle",
    confidence: 85.96354722976685
    },
    {
    description: "Vision care",
    confidence: 76.47057771682739
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    },
    {
    description: "Houseplant",
    confidence: 70.20333409309387
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9346139430999756,
    bounding_box: [
    {
    x: 0.510923445224762,
    y: 0.26519283652305603
    },
    {
    x: 0.6895599365234375,
    y: 0.26519283652305603
    },
    {
    x: 0.6895599365234375,
    y: 0.38655582070350647
    },
    {
    x: 0.510923445224762,
    y: 0.38655582070350647
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8124197721481323,
    bounding_box: [
    {
    x: 0.25189346075057983,
    y: 0.04349735751748085
    },
    {
    x: 0.8737058043479919,
    y: 0.04349735751748085
    },
    {
    x: 0.8737058043479919,
    y: 0.9973958134651184
    },
    {
    x: 0.25189346075057983,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.733791708946228,
    bounding_box: [
    {
    x: 0.27764254808425903,
    y: 0.4505394697189331
    },
    {
    x: 0.872641384601593,
    y: 0.4505394697189331
    },
    {
    x: 0.872641384601593,
    y: 0.9969558715820312
    },
    {
    x: 0.27764254808425903,
    y: 0.9969558715820312
    }
    ]
    },
    {
    description: "Nightstand",
    confidence: 0.6048184037208557,
    bounding_box: [
    {
    x: 0.0010727314511314034,
    y: 0.7021605372428894
    },
    {
    x: 0.1215202584862709,
    y: 0.7021605372428894
    },
    {
    x: 0.1215202584862709,
    y: 0.9973958134651184
    },
    {
    x: 0.0010727314511314034,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 37179,
    time_in_milliseconds: 1240539,
    labels: [
    {
    description: "News",
    confidence: 88.83408904075623
    },
    {
    description: "Media",
    confidence: 75.69145560264587
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    },
    {
    description: "Photo caption",
    confidence: 53.15094590187073
    },
    {
    description: "Official",
    confidence: 50.00404119491577
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.940964937210083,
    bounding_box: [
    {
    x: 0.26852867007255554,
    y: 0.31075388193130493
    },
    {
    x: 0.4065762460231781,
    y: 0.31075388193130493
    },
    {
    x: 0.4065762460231781,
    y: 0.3945276737213135
    },
    {
    x: 0.26852867007255554,
    y: 0.3945276737213135
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9348950386047363,
    bounding_box: [
    {
    x: 0.6407621502876282,
    y: 0.2862255275249481
    },
    {
    x: 0.7845311760902405,
    y: 0.2862255275249481
    },
    {
    x: 0.7845311760902405,
    y: 0.386900931596756
    },
    {
    x: 0.6407621502876282,
    y: 0.386900931596756
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8477953672409058,
    bounding_box: [
    {
    x: 0.12761631608009338,
    y: 0.1493779718875885
    },
    {
    x: 0.5083102583885193,
    y: 0.1493779718875885
    },
    {
    x: 0.5083102583885193,
    y: 0.855640172958374
    },
    {
    x: 0.12761631608009338,
    y: 0.855640172958374
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7782466411590576,
    bounding_box: [
    {
    x: 0.4965115487575531,
    y: 0.10447633266448975
    },
    {
    x: 0.8737439513206482,
    y: 0.10447633266448975
    },
    {
    x: 0.8737439513206482,
    y: 0.8518000245094299
    },
    {
    x: 0.4965115487575531,
    y: 0.8518000245094299
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6817306876182556,
    bounding_box: [
    {
    x: 0.13346357643604279,
    y: 0.2408968210220337
    },
    {
    x: 0.5080142617225647,
    y: 0.2408968210220337
    },
    {
    x: 0.5080142617225647,
    y: 0.8355147838592529
    },
    {
    x: 0.13346357643604279,
    y: 0.8355147838592529
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5753353238105774,
    bounding_box: [
    {
    x: 0.4977503716945648,
    y: 0.16006483137607574
    },
    {
    x: 0.8755168318748474,
    y: 0.16006483137607574
    },
    {
    x: 0.8755168318748474,
    y: 0.8341497778892517
    },
    {
    x: 0.4977503716945648,
    y: 0.8341497778892517
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 37991,
    time_in_milliseconds: 1267633,
    labels: [
    {
    description: "News",
    confidence: 88.52225542068481
    },
    {
    description: "Glasses",
    confidence: 80.04875183105469
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Media",
    confidence: 75.08222460746765
    },
    {
    description: "Photo caption",
    confidence: 67.62973666191101
    },
    {
    description: "Facial hair",
    confidence: 64.79135155677795
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Vision care",
    confidence: 61.489856243133545
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9375677704811096,
    bounding_box: [
    {
    x: 0.6391034722328186,
    y: 0.2996397018432617
    },
    {
    x: 0.7814733386039734,
    y: 0.2996397018432617
    },
    {
    x: 0.7814733386039734,
    y: 0.39660680294036865
    },
    {
    x: 0.6391034722328186,
    y: 0.39660680294036865
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.904166579246521,
    bounding_box: [
    {
    x: 0.20081768929958344,
    y: 0.3177073299884796
    },
    {
    x: 0.32533547282218933,
    y: 0.3177073299884796
    },
    {
    x: 0.32533547282218933,
    y: 0.4052550494670868
    },
    {
    x: 0.20081768929958344,
    y: 0.4052550494670868
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8690480589866638,
    bounding_box: [
    {
    x: 0.12816904485225677,
    y: 0.1567569524049759
    },
    {
    x: 0.5048210024833679,
    y: 0.1567569524049759
    },
    {
    x: 0.5048210024833679,
    y: 0.8528785705566406
    },
    {
    x: 0.12816904485225677,
    y: 0.8528785705566406
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8217991590499878,
    bounding_box: [
    {
    x: 0.4964611530303955,
    y: 0.1157732605934143
    },
    {
    x: 0.87570720911026,
    y: 0.1157732605934143
    },
    {
    x: 0.87570720911026,
    y: 0.8555291295051575
    },
    {
    x: 0.4964611530303955,
    y: 0.8555291295051575
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.585595428943634,
    bounding_box: [
    {
    x: 0.4964282214641571,
    y: 0.1938282996416092
    },
    {
    x: 0.8719255328178406,
    y: 0.1938282996416092
    },
    {
    x: 0.8719255328178406,
    y: 0.8352382183074951
    },
    {
    x: 0.4964282214641571,
    y: 0.8352382183074951
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 38542,
    time_in_milliseconds: 1286018,
    labels: [
    {
    description: "News",
    confidence: 84.36566591262817
    },
    {
    description: "Eyewear",
    confidence: 80.6023359298706
    },
    {
    description: "Media",
    confidence: 79.4614851474762
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Photo caption",
    confidence: 64.09758925437927
    },
    {
    description: "Vision care",
    confidence: 61.489856243133545
    },
    {
    description: "Facial hair",
    confidence: 55.52889108657837
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9425080418586731,
    bounding_box: [
    {
    x: 0.6433115601539612,
    y: 0.2852621078491211
    },
    {
    x: 0.7933130264282227,
    y: 0.2852621078491211
    },
    {
    x: 0.7933130264282227,
    y: 0.399617463350296
    },
    {
    x: 0.6433115601539612,
    y: 0.399617463350296
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9143669605255127,
    bounding_box: [
    {
    x: 0.21643127501010895,
    y: 0.3207154870033264
    },
    {
    x: 0.35188570618629456,
    y: 0.3207154870033264
    },
    {
    x: 0.35188570618629456,
    y: 0.40474510192871094
    },
    {
    x: 0.21643127501010895,
    y: 0.40474510192871094
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8264855146408081,
    bounding_box: [
    {
    x: 0.49381113052368164,
    y: 0.10302314907312393
    },
    {
    x: 0.8741680979728699,
    y: 0.10302314907312393
    },
    {
    x: 0.8741680979728699,
    y: 0.8522839546203613
    },
    {
    x: 0.49381113052368164,
    y: 0.8522839546203613
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8123369216918945,
    bounding_box: [
    {
    x: 0.13116414844989777,
    y: 0.15396256744861603
    },
    {
    x: 0.5119656920433044,
    y: 0.15396256744861603
    },
    {
    x: 0.5119656920433044,
    y: 0.8493533134460449
    },
    {
    x: 0.13116414844989777,
    y: 0.8493533134460449
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6242998242378235,
    bounding_box: [
    {
    x: 0.49235865473747253,
    y: 0.17144502699375153
    },
    {
    x: 0.8749642372131348,
    y: 0.17144502699375153
    },
    {
    x: 0.8749642372131348,
    y: 0.8365229964256287
    },
    {
    x: 0.49235865473747253,
    y: 0.8365229964256287
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 38571,
    time_in_milliseconds: 1286986,
    labels: [
    {
    description: "Hair",
    confidence: 97.98632860183716
    },
    {
    description: "Eyewear",
    confidence: 95.2597975730896
    },
    {
    description: "Glasses",
    confidence: 89.0495777130127
    },
    {
    description: "Hairstyle",
    confidence: 86.29165291786194
    },
    {
    description: "Eating",
    confidence: 86.22310757637024
    },
    {
    description: "Houseplant",
    confidence: 81.38628602027893
    },
    {
    description: "Smile",
    confidence: 78.8342833518982
    },
    {
    description: "Afro",
    confidence: 77.15334296226501
    },
    {
    description: "Vision care",
    confidence: 74.84965324401855
    },
    {
    description: "Finger",
    confidence: 74.5478868484497
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9400509595870972,
    bounding_box: [
    {
    x: 0.49802717566490173,
    y: 0.2896532714366913
    },
    {
    x: 0.6895689964294434,
    y: 0.2896532714366913
    },
    {
    x: 0.6895689964294434,
    y: 0.410394549369812
    },
    {
    x: 0.49802717566490173,
    y: 0.410394549369812
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8039265275001526,
    bounding_box: [
    {
    x: 0.30929771065711975,
    y: 0.4715852737426758
    },
    {
    x: 0.9249340295791626,
    y: 0.4715852737426758
    },
    {
    x: 0.9249340295791626,
    y: 0.9933719635009766
    },
    {
    x: 0.30929771065711975,
    y: 0.9933719635009766
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7105704545974731,
    bounding_box: [
    {
    x: 0.29259729385375977,
    y: 0.05615069344639778
    },
    {
    x: 0.9402062892913818,
    y: 0.05615069344639778
    },
    {
    x: 0.9402062892913818,
    y: 0.9973958134651184
    },
    {
    x: 0.29259729385375977,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 38658,
    time_in_milliseconds: 1289888.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.3388364315033
    },
    {
    description: "Facial hair",
    confidence: 97.67988324165344
    },
    {
    description: "Beard",
    confidence: 96.12749814987183
    },
    {
    description: "Moustache",
    confidence: 91.89468622207642
    },
    {
    description: "Hairstyle",
    confidence: 86.29165291786194
    },
    {
    description: "Glasses",
    confidence: 83.378666639328
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    },
    {
    description: "Houseplant",
    confidence: 73.6934244632721
    },
    {
    description: "Afro",
    confidence: 64.55721259117126
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.939541757106781,
    bounding_box: [
    {
    x: 0.4988357722759247,
    y: 0.30194270610809326
    },
    {
    x: 0.6876168251037598,
    y: 0.30194270610809326
    },
    {
    x: 0.6876168251037598,
    y: 0.42154255509376526
    },
    {
    x: 0.4988357722759247,
    y: 0.42154255509376526
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8520330786705017,
    bounding_box: [
    {
    x: 0.25923243165016174,
    y: 0.4618256092071533
    },
    {
    x: 0.9033714532852173,
    y: 0.4618256092071533
    },
    {
    x: 0.9033714532852173,
    y: 0.9932723045349121
    },
    {
    x: 0.25923243165016174,
    y: 0.9932723045349121
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7668623328208923,
    bounding_box: [
    {
    x: 0.26995569467544556,
    y: 0.05569334700703621
    },
    {
    x: 0.9107141494750977,
    y: 0.05569334700703621
    },
    {
    x: 0.9107141494750977,
    y: 0.9938984513282776
    },
    {
    x: 0.26995569467544556,
    y: 0.9938984513282776
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 38977,
    time_in_milliseconds: 1300532.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.75585436820984
    },
    {
    description: "Eyewear",
    confidence: 98.36269617080688
    },
    {
    description: "Facial hair",
    confidence: 95.58630585670471
    },
    {
    description: "Glasses",
    confidence: 93.90499591827393
    },
    {
    description: "Beard",
    confidence: 93.78136396408081
    },
    {
    description: "Forehead",
    confidence: 89.98682498931885
    },
    {
    description: "Hairstyle",
    confidence: 88.92095685005188
    },
    {
    description: "Moustache",
    confidence: 87.15966939926147
    },
    {
    description: "Vision care",
    confidence: 84.49730277061462
    },
    {
    description: "Snapshot",
    confidence: 82.50965476036072
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9252667427062988,
    bounding_box: [
    {
    x: 0.5017940998077393,
    y: 0.2737235128879547
    },
    {
    x: 0.6792998313903809,
    y: 0.2737235128879547
    },
    {
    x: 0.6792998313903809,
    y: 0.4021313190460205
    },
    {
    x: 0.5017940998077393,
    y: 0.4021313190460205
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8699547052383423,
    bounding_box: [
    {
    x: 0.19130606949329376,
    y: 0.483557790517807
    },
    {
    x: 0.9422717094421387,
    y: 0.483557790517807
    },
    {
    x: 0.9422717094421387,
    y: 0.9913331270217896
    },
    {
    x: 0.19130606949329376,
    y: 0.9913331270217896
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8419994711875916,
    bounding_box: [
    {
    x: 0.19620828330516815,
    y: 0.04739120602607727
    },
    {
    x: 0.9492195248603821,
    y: 0.04739120602607727
    },
    {
    x: 0.9492195248603821,
    y: 0.9950345754623413
    },
    {
    x: 0.19620828330516815,
    y: 0.9950345754623413
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5841977596282959,
    bounding_box: [
    {
    x: 0.00020267422951292247,
    y: 0.7031214833259583
    },
    {
    x: 0.12162968516349792,
    y: 0.7031214833259583
    },
    {
    x: 0.12162968516349792,
    y: 0.997127115726471
    },
    {
    x: 0.00020267422951292247,
    y: 0.997127115726471
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39006,
    time_in_milliseconds: 1301500,
    labels: [
    {
    description: "Hair",
    confidence: 98.72674345970154
    },
    {
    description: "Eyewear",
    confidence: 98.58126640319824
    },
    {
    description: "Facial hair",
    confidence: 97.35997319221497
    },
    {
    description: "Beard",
    confidence: 95.81626653671265
    },
    {
    description: "Glasses",
    confidence: 94.04132962226868
    },
    {
    description: "Moustache",
    confidence: 91.57096743583679
    },
    {
    description: "Forehead",
    confidence: 89.98682498931885
    },
    {
    description: "Hairstyle",
    confidence: 87.35474944114685
    },
    {
    description: "Afro",
    confidence: 84.2711865901947
    },
    {
    description: "Vision care",
    confidence: 84.03350114822388
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9369121789932251,
    bounding_box: [
    {
    x: 0.5104838013648987,
    y: 0.2750230133533478
    },
    {
    x: 0.6850555539131165,
    y: 0.2750230133533478
    },
    {
    x: 0.6850555539131165,
    y: 0.40054163336753845
    },
    {
    x: 0.5104838013648987,
    y: 0.40054163336753845
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8879289627075195,
    bounding_box: [
    {
    x: 0.1761774867773056,
    y: 0.06780480593442917
    },
    {
    x: 0.9233188033103943,
    y: 0.06780480593442917
    },
    {
    x: 0.9233188033103943,
    y: 0.9973958134651184
    },
    {
    x: 0.1761774867773056,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5551278591156006,
    bounding_box: [
    {
    x: 0.16323521733283997,
    y: 0.5000193119049072
    },
    {
    x: 0.9169272184371948,
    y: 0.5000193119049072
    },
    {
    x: 0.9169272184371948,
    y: 0.9940432906150818
    },
    {
    x: 0.16323521733283997,
    y: 0.9940432906150818
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5067309737205505,
    bounding_box: [
    {
    x: 0.7316710352897644,
    y: 0
    },
    {
    x: 0.9966283440589905,
    y: 0
    },
    {
    x: 0.9966283440589905,
    y: 0.7391404509544373
    },
    {
    x: 0.7316710352897644,
    y: 0.7391404509544373
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39035,
    time_in_milliseconds: 1302468,
    labels: [
    {
    description: "Hair",
    confidence: 98.6552894115448
    },
    {
    description: "Eyewear",
    confidence: 95.45212984085083
    },
    {
    description: "Facial hair",
    confidence: 94.81169581413269
    },
    {
    description: "Beard",
    confidence: 90.46363830566406
    },
    {
    description: "Glasses",
    confidence: 90.4336929321289
    },
    {
    description: "Hairstyle",
    confidence: 88.48372101783752
    },
    {
    description: "Moustache",
    confidence: 86.3451361656189
    },
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Houseplant",
    confidence: 80.59768676757812
    },
    {
    description: "Smile",
    confidence: 80.0797700881958
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9409236907958984,
    bounding_box: [
    {
    x: 0.5156805515289307,
    y: 0.28321269154548645
    },
    {
    x: 0.6968111991882324,
    y: 0.28321269154548645
    },
    {
    x: 0.6968111991882324,
    y: 0.40666404366493225
    },
    {
    x: 0.5156805515289307,
    y: 0.40666404366493225
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7648068070411682,
    bounding_box: [
    {
    x: 0.2535282075405121,
    y: 0.4823899567127228
    },
    {
    x: 0.9545127153396606,
    y: 0.4823899567127228
    },
    {
    x: 0.9545127153396606,
    y: 0.9906934499740601
    },
    {
    x: 0.2535282075405121,
    y: 0.9906934499740601
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7445179224014282,
    bounding_box: [
    {
    x: 0.23588471114635468,
    y: 0.05954216420650482
    },
    {
    x: 0.9574999213218689,
    y: 0.05954216420650482
    },
    {
    x: 0.9574999213218689,
    y: 0.9973958134651184
    },
    {
    x: 0.23588471114635468,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39064,
    time_in_milliseconds: 1303435.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.49514365196228
    },
    {
    description: "Facial hair",
    confidence: 97.72764444351196
    },
    {
    description: "Eyewear",
    confidence: 96.99482321739197
    },
    {
    description: "Beard",
    confidence: 95.33769488334656
    },
    {
    description: "Glasses",
    confidence: 92.09486246109009
    },
    {
    description: "Moustache",
    confidence: 90.96090793609619
    },
    {
    description: "Hairstyle",
    confidence: 87.35474944114685
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Vision care",
    confidence: 82.44438767433167
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9421133399009705,
    bounding_box: [
    {
    x: 0.5108692049980164,
    y: 0.3022487461566925
    },
    {
    x: 0.6986832618713379,
    y: 0.3022487461566925
    },
    {
    x: 0.6986832618713379,
    y: 0.42821869254112244
    },
    {
    x: 0.5108692049980164,
    y: 0.42821869254112244
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8861114978790283,
    bounding_box: [
    {
    x: 0.019143398851156235,
    y: 0.5130789279937744
    },
    {
    x: 0.9236762523651123,
    y: 0.5130789279937744
    },
    {
    x: 0.9236762523651123,
    y: 0.9955832958221436
    },
    {
    x: 0.019143398851156235,
    y: 0.9955832958221436
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7535960078239441,
    bounding_box: [
    {
    x: 0.009431680664420128,
    y: 0.053956832736730576
    },
    {
    x: 0.9187211990356445,
    y: 0.053956832736730576
    },
    {
    x: 0.9187211990356445,
    y: 0.9966045022010803
    },
    {
    x: 0.009431680664420128,
    y: 0.9966045022010803
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39122,
    time_in_milliseconds: 1305370.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 99.03542399406433
    },
    {
    description: "Hairstyle",
    confidence: 93.65895986557007
    },
    {
    description: "Head",
    confidence: 88.42743635177612
    },
    {
    description: "Glasses",
    confidence: 85.38076281547546
    },
    {
    description: "Black hair",
    confidence: 85.28931140899658
    },
    {
    description: "Facial hair",
    confidence: 84.9034070968628
    },
    {
    description: "Snapshot",
    confidence: 83.2730770111084
    },
    {
    description: "Eyewear",
    confidence: 82.89366364479065
    },
    {
    description: "Beard",
    confidence: 79.31737303733826
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.8541280627250671,
    bounding_box: [
    {
    x: 0.1312088966369629,
    y: 0.5054817795753479
    },
    {
    x: 0.9961793422698975,
    y: 0.5054817795753479
    },
    {
    x: 0.9961793422698975,
    y: 0.9973549246788025
    },
    {
    x: 0.1312088966369629,
    y: 0.9973549246788025
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7908061742782593,
    bounding_box: [
    {
    x: 0.11991912126541138,
    y: 0.050139110535383224
    },
    {
    x: 0.9908336997032166,
    y: 0.050139110535383224
    },
    {
    x: 0.9908336997032166,
    y: 0.9912517666816711
    },
    {
    x: 0.11991912126541138,
    y: 0.9912517666816711
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.6348885297775269,
    bounding_box: [
    {
    x: 0.5481646060943604,
    y: 0.2629677951335907
    },
    {
    x: 0.7154482007026672,
    y: 0.2629677951335907
    },
    {
    x: 0.7154482007026672,
    y: 0.37051644921302795
    },
    {
    x: 0.5481646060943604,
    y: 0.37051644921302795
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5727452635765076,
    bounding_box: [
    {
    x: 0.08257117867469788,
    y: 0.09004359692335129
    },
    {
    x: 0.239577054977417,
    y: 0.09004359692335129
    },
    {
    x: 0.239577054977417,
    y: 0.31953346729278564
    },
    {
    x: 0.08257117867469788,
    y: 0.31953346729278564
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39180,
    time_in_milliseconds: 1307306,
    labels: [
    {
    description: "Eyewear",
    confidence: 99.46216940879822
    },
    {
    description: "Hair",
    confidence: 98.00390005111694
    },
    {
    description: "Glasses",
    confidence: 96.25548720359802
    },
    {
    description: "Facial hair",
    confidence: 96.07393741607666
    },
    {
    description: "Beard",
    confidence: 91.90535545349121
    },
    {
    description: "Fun",
    confidence: 87.68272995948792
    },
    {
    description: "Vision care",
    confidence: 87.47982978820801
    },
    {
    description: "Snapshot",
    confidence: 85.07856130599976
    },
    {
    description: "Hairstyle",
    confidence: 84.74541306495667
    },
    {
    description: "T-shirt",
    confidence: 83.30071568489075
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9185925722122192,
    bounding_box: [
    {
    x: 0.49337664246559143,
    y: 0.27965524792671204
    },
    {
    x: 0.6721734404563904,
    y: 0.27965524792671204
    },
    {
    x: 0.6721734404563904,
    y: 0.4068491756916046
    },
    {
    x: 0.49337664246559143,
    y: 0.4068491756916046
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8676579594612122,
    bounding_box: [
    {
    x: 0.10713770240545273,
    y: 0.014714565128087997
    },
    {
    x: 0.9910757541656494,
    y: 0.014714565128087997
    },
    {
    x: 0.9910757541656494,
    y: 0.9835371971130371
    },
    {
    x: 0.10713770240545273,
    y: 0.9835371971130371
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7330695390701294,
    bounding_box: [
    {
    x: 0.10834764689207077,
    y: 0.4706553518772125
    },
    {
    x: 0.9865286946296692,
    y: 0.4706553518772125
    },
    {
    x: 0.9865286946296692,
    y: 0.9889642000198364
    },
    {
    x: 0.10834764689207077,
    y: 0.9889642000198364
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39209,
    time_in_milliseconds: 1308274,
    labels: [
    {
    description: "Hair",
    confidence: 98.84324073791504
    },
    {
    description: "Facial hair",
    confidence: 97.60547876358032
    },
    {
    description: "Beard",
    confidence: 95.86184620857239
    },
    {
    description: "Moustache",
    confidence: 94.15571093559265
    },
    {
    description: "Glasses",
    confidence: 91.52262210845947
    },
    {
    description: "Hairstyle",
    confidence: 90.7419741153717
    },
    {
    description: "Eyewear",
    confidence: 90.26086330413818
    },
    {
    description: "Houseplant",
    confidence: 81.94087147712708
    },
    {
    description: "Smile",
    confidence: 75.41265487670898
    },
    {
    description: "Vision care",
    confidence: 65.7684862613678
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9373373985290527,
    bounding_box: [
    {
    x: 0.5104907751083374,
    y: 0.2784145474433899
    },
    {
    x: 0.6971862316131592,
    y: 0.2784145474433899
    },
    {
    x: 0.6971862316131592,
    y: 0.407505601644516
    },
    {
    x: 0.5104907751083374,
    y: 0.407505601644516
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8061376810073853,
    bounding_box: [
    {
    x: 0.32341697812080383,
    y: 0.48164698481559753
    },
    {
    x: 0.8963894844055176,
    y: 0.48164698481559753
    },
    {
    x: 0.8963894844055176,
    y: 0.9891300201416016
    },
    {
    x: 0.32341697812080383,
    y: 0.9891300201416016
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7393462657928467,
    bounding_box: [
    {
    x: 0.31396713852882385,
    y: 0.05914561077952385
    },
    {
    x: 0.891869068145752,
    y: 0.05914561077952385
    },
    {
    x: 0.891869068145752,
    y: 0.9900066256523132
    },
    {
    x: 0.31396713852882385,
    y: 0.9900066256523132
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.573157548904419,
    bounding_box: [
    {
    x: 0.7317885756492615,
    y: 0
    },
    {
    x: 0.9952525496482849,
    y: 0
    },
    {
    x: 0.9952525496482849,
    y: 0.7271270751953125
    },
    {
    x: 0.7317885756492615,
    y: 0.7271270751953125
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5700295567512512,
    bounding_box: [
    {
    x: 0.19804637134075165,
    y: 0.6356599926948547
    },
    {
    x: 0.34446266293525696,
    y: 0.6356599926948547
    },
    {
    x: 0.34446266293525696,
    y: 0.9271141886711121
    },
    {
    x: 0.19804637134075165,
    y: 0.9271141886711121
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5101612210273743,
    bounding_box: [
    {
    x: 0.0012057346757501364,
    y: 0.7034671902656555
    },
    {
    x: 0.12243622541427612,
    y: 0.7034671902656555
    },
    {
    x: 0.12243622541427612,
    y: 0.9970803260803223
    },
    {
    x: 0.0012057346757501364,
    y: 0.9970803260803223
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39238,
    time_in_milliseconds: 1309241,
    labels: [
    {
    description: "Bookcase",
    confidence: 72.53415584564209
    },
    {
    description: "Library",
    confidence: 70.123690366745
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Glasses",
    confidence: 65.22085666656494
    },
    {
    description: "Book",
    confidence: 63.999754190444946
    },
    {
    description: "Furniture",
    confidence: 59.37383770942688
    },
    {
    description: "Shelving",
    confidence: 55.818337202072144
    },
    {
    description: "Publication",
    confidence: 51.70950889587402
    },
    {
    description: "Author",
    confidence: 50.18947720527649
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.932134211063385,
    bounding_box: [
    {
    x: 0.5101914405822754,
    y: 0.45387694239616394
    },
    {
    x: 0.6483363509178162,
    y: 0.45387694239616394
    },
    {
    x: 0.6483363509178162,
    y: 0.5523666143417358
    },
    {
    x: 0.5101914405822754,
    y: 0.5523666143417358
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8651077151298523,
    bounding_box: [
    {
    x: 0.629370391368866,
    y: 0.021075235679745674
    },
    {
    x: 0.9780423641204834,
    y: 0.021075235679745674
    },
    {
    x: 0.9780423641204834,
    y: 0.9791135191917419
    },
    {
    x: 0.629370391368866,
    y: 0.9791135191917419
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8604552149772644,
    bounding_box: [
    {
    x: 0.24372322857379913,
    y: 0.25629058480262756
    },
    {
    x: 0.7700724005699158,
    y: 0.25629058480262756
    },
    {
    x: 0.7700724005699158,
    y: 0.9950449466705322
    },
    {
    x: 0.24372322857379913,
    y: 0.9950449466705322
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7907463312149048,
    bounding_box: [
    {
    x: 0.2535161077976227,
    y: 0.5803578495979309
    },
    {
    x: 0.7660651206970215,
    y: 0.5803578495979309
    },
    {
    x: 0.7660651206970215,
    y: 0.9823909401893616
    },
    {
    x: 0.2535161077976227,
    y: 0.9823909401893616
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39412,
    time_in_milliseconds: 1315046,
    labels: [
    {
    description: "News",
    confidence: 94.73349452018738
    },
    {
    description: "Photo caption",
    confidence: 77.1245002746582
    },
    {
    description: "Media",
    confidence: 74.761962890625
    },
    {
    description: "Facial hair",
    confidence: 64.79135155677795
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Glasses",
    confidence: 51.58061981201172
    },
    {
    description: "Television program",
    confidence: 50.1481294631958
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9504910707473755,
    bounding_box: [
    {
    x: 0.6316320300102234,
    y: 0.2985837161540985
    },
    {
    x: 0.7745277285575867,
    y: 0.2985837161540985
    },
    {
    x: 0.7745277285575867,
    y: 0.40014421939849854
    },
    {
    x: 0.6316320300102234,
    y: 0.40014421939849854
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9262570142745972,
    bounding_box: [
    {
    x: 0.3066452145576477,
    y: 0.42333540320396423
    },
    {
    x: 0.4407227337360382,
    y: 0.42333540320396423
    },
    {
    x: 0.4407227337360382,
    y: 0.5507946610450745
    },
    {
    x: 0.3066452145576477,
    y: 0.5507946610450745
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8450495004653931,
    bounding_box: [
    {
    x: 0.12697331607341766,
    y: 0.2024482637643814
    },
    {
    x: 0.506822943687439,
    y: 0.2024482637643814
    },
    {
    x: 0.506822943687439,
    y: 0.8431599736213684
    },
    {
    x: 0.12697331607341766,
    y: 0.8431599736213684
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8284566402435303,
    bounding_box: [
    {
    x: 0.49801361560821533,
    y: 0.10907545685768127
    },
    {
    x: 0.8720231652259827,
    y: 0.10907545685768127
    },
    {
    x: 0.8720231652259827,
    y: 0.8623816967010498
    },
    {
    x: 0.49801361560821533,
    y: 0.8623816967010498
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6175869107246399,
    bounding_box: [
    {
    x: 0.49844738841056824,
    y: 0.20252728462219238
    },
    {
    x: 0.8711385726928711,
    y: 0.20252728462219238
    },
    {
    x: 0.8711385726928711,
    y: 0.8343048095703125
    },
    {
    x: 0.49844738841056824,
    y: 0.8343048095703125
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5160136222839355,
    bounding_box: [
    {
    x: 0.13452336192131042,
    y: 0.49765536189079285
    },
    {
    x: 0.4980601370334625,
    y: 0.49765536189079285
    },
    {
    x: 0.4980601370334625,
    y: 0.8379815220832825
    },
    {
    x: 0.13452336192131042,
    y: 0.8379815220832825
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39673,
    time_in_milliseconds: 1323756,
    labels: [
    {
    description: "Hair",
    confidence: 98.22307229042053
    },
    {
    description: "Glasses",
    confidence: 93.1408941745758
    },
    {
    description: "Facial hair",
    confidence: 92.76607632637024
    },
    {
    description: "Houseplant",
    confidence: 88.85076642036438
    },
    {
    description: "Beard",
    confidence: 87.12003827095032
    },
    {
    description: "Moustache",
    confidence: 86.68073415756226
    },
    {
    description: "Hairstyle",
    confidence: 85.19834280014038
    },
    {
    description: "Smile",
    confidence: 83.48232507705688
    },
    {
    description: "Eyewear",
    confidence: 80.6023359298706
    },
    {
    description: "Room",
    confidence: 71.39228582382202
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9427177906036377,
    bounding_box: [
    {
    x: 0.5059216022491455,
    y: 0.26343610882759094
    },
    {
    x: 0.6860778331756592,
    y: 0.26343610882759094
    },
    {
    x: 0.6860778331756592,
    y: 0.3881015479564667
    },
    {
    x: 0.5059216022491455,
    y: 0.3881015479564667
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7564448118209839,
    bounding_box: [
    {
    x: 0.26439937949180603,
    y: 0.46674081683158875
    },
    {
    x: 0.9280166625976562,
    y: 0.46674081683158875
    },
    {
    x: 0.9280166625976562,
    y: 0.9918923377990723
    },
    {
    x: 0.26439937949180603,
    y: 0.9918923377990723
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7306503653526306,
    bounding_box: [
    {
    x: 0.2811276614665985,
    y: 0.05392098426818848
    },
    {
    x: 0.9312313795089722,
    y: 0.05392098426818848
    },
    {
    x: 0.9312313795089722,
    y: 0.9958128333091736
    },
    {
    x: 0.2811276614665985,
    y: 0.9958128333091736
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5344078540802002,
    bounding_box: [
    {
    x: 0.04531519487500191,
    y: 0.4312264621257782
    },
    {
    x: 0.10875268280506134,
    y: 0.4312264621257782
    },
    {
    x: 0.10875268280506134,
    y: 0.7092175483703613
    },
    {
    x: 0.04531519487500191,
    y: 0.7092175483703613
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5196985602378845,
    bounding_box: [
    {
    x: 0.20028536021709442,
    y: 0.640322208404541
    },
    {
    x: 0.34457242488861084,
    y: 0.640322208404541
    },
    {
    x: 0.34457242488861084,
    y: 0.9252324104309082
    },
    {
    x: 0.20028536021709442,
    y: 0.9252324104309082
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5192243456840515,
    bounding_box: [
    {
    x: 0.7272520661354065,
    y: 0
    },
    {
    x: 0.9970439076423645,
    y: 0
    },
    {
    x: 0.9970439076423645,
    y: 0.7305309176445007
    },
    {
    x: 0.7272520661354065,
    y: 0.7305309176445007
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39760,
    time_in_milliseconds: 1326658.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.37164878845215
    },
    {
    description: "Photograph",
    confidence: 95.1403021812439
    },
    {
    description: "Facial expression",
    confidence: 94.47140097618103
    },
    {
    description: "Eyewear",
    confidence: 93.7157154083252
    },
    {
    description: "People",
    confidence: 93.43461394309998
    },
    {
    description: "Glasses",
    confidence: 93.38595271110535
    },
    {
    description: "Facial hair",
    confidence: 90.96471667289734
    },
    {
    description: "Smile",
    confidence: 90.88641405105591
    },
    {
    description: "Hairstyle",
    confidence: 87.11950778961182
    },
    {
    description: "Beard",
    confidence: 86.54796481132507
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9270620942115784,
    bounding_box: [
    {
    x: 0.5249469876289368,
    y: 0.2654712200164795
    },
    {
    x: 0.7138500213623047,
    y: 0.2654712200164795
    },
    {
    x: 0.7138500213623047,
    y: 0.3900158107280731
    },
    {
    x: 0.5249469876289368,
    y: 0.3900158107280731
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7542871236801147,
    bounding_box: [
    {
    x: 0.24560920894145966,
    y: 0.04236817732453346
    },
    {
    x: 0.9451136589050293,
    y: 0.04236817732453346
    },
    {
    x: 0.9451136589050293,
    y: 0.9933123588562012
    },
    {
    x: 0.24560920894145966,
    y: 0.9933123588562012
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6507707238197327,
    bounding_box: [
    {
    x: 0.24250061810016632,
    y: 0.47051942348480225
    },
    {
    x: 0.9485462307929993,
    y: 0.47051942348480225
    },
    {
    x: 0.9485462307929993,
    y: 0.9941272735595703
    },
    {
    x: 0.24250061810016632,
    y: 0.9941272735595703
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5464029908180237,
    bounding_box: [
    {
    x: 0.20104973018169403,
    y: 0.6394505500793457
    },
    {
    x: 0.3454452455043793,
    y: 0.6394505500793457
    },
    {
    x: 0.3454452455043793,
    y: 0.9174504280090332
    },
    {
    x: 0.20104973018169403,
    y: 0.9174504280090332
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5210467576980591,
    bounding_box: [
    {
    x: 0.0011069148313254118,
    y: 0.7065157294273376
    },
    {
    x: 0.121993787586689,
    y: 0.7065157294273376
    },
    {
    x: 0.121993787586689,
    y: 0.9973958134651184
    },
    {
    x: 0.0011069148313254118,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 39905,
    time_in_milliseconds: 1331497,
    labels: [
    {
    description: "Hair",
    confidence: 98.73782396316528
    },
    {
    description: "Facial hair",
    confidence: 97.96697497367859
    },
    {
    description: "Beard",
    confidence: 96.8167245388031
    },
    {
    description: "Moustache",
    confidence: 93.71269345283508
    },
    {
    description: "Glasses",
    confidence: 92.09486246109009
    },
    {
    description: "Hairstyle",
    confidence: 89.17961716651917
    },
    {
    description: "Eyewear",
    confidence: 84.73531007766724
    },
    {
    description: "Snapshot",
    confidence: 82.91832208633423
    },
    {
    description: "Smile",
    confidence: 80.0797700881958
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9363979697227478,
    bounding_box: [
    {
    x: 0.48678144812583923,
    y: 0.2669227421283722
    },
    {
    x: 0.66341632604599,
    y: 0.2669227421283722
    },
    {
    x: 0.66341632604599,
    y: 0.38985738158226013
    },
    {
    x: 0.48678144812583923,
    y: 0.38985738158226013
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7783571481704712,
    bounding_box: [
    {
    x: 0.28736811876296997,
    y: 0.060354720801115036
    },
    {
    x: 0.9195968508720398,
    y: 0.060354720801115036
    },
    {
    x: 0.9195968508720398,
    y: 0.9905652403831482
    },
    {
    x: 0.28736811876296997,
    y: 0.9905652403831482
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7350926995277405,
    bounding_box: [
    {
    x: 0.27852514386177063,
    y: 0.4732818305492401
    },
    {
    x: 0.9104536771774292,
    y: 0.4732818305492401
    },
    {
    x: 0.9104536771774292,
    y: 0.9916315078735352
    },
    {
    x: 0.27852514386177063,
    y: 0.9916315078735352
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40079,
    time_in_milliseconds: 1337302.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.47680330276489
    },
    {
    description: "Eyewear",
    confidence: 97.64163494110107
    },
    {
    description: "Facial hair",
    confidence: 91.43354296684265
    },
    {
    description: "Glasses",
    confidence: 89.78612422943115
    },
    {
    description: "Hairstyle",
    confidence: 87.97082304954529
    },
    {
    description: "Beard",
    confidence: 87.82284259796143
    },
    {
    description: "Finger",
    confidence: 83.34709405899048
    },
    {
    description: "Vision care",
    confidence: 75.70610046386719
    },
    {
    description: "Dreadlocks",
    confidence: 74.94876980781555
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9235050678253174,
    bounding_box: [
    {
    x: 0.4961280822753906,
    y: 0.28555160760879517
    },
    {
    x: 0.670030415058136,
    y: 0.28555160760879517
    },
    {
    x: 0.670030415058136,
    y: 0.40448057651519775
    },
    {
    x: 0.4961280822753906,
    y: 0.40448057651519775
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.774780809879303,
    bounding_box: [
    {
    x: 0.19080860912799835,
    y: 0.05239382013678551
    },
    {
    x: 0.9450914859771729,
    y: 0.05239382013678551
    },
    {
    x: 0.9450914859771729,
    y: 0.994365394115448
    },
    {
    x: 0.19080860912799835,
    y: 0.994365394115448
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7434269189834595,
    bounding_box: [
    {
    x: 0.17157287895679474,
    y: 0.05812634527683258
    },
    {
    x: 0.9257604479789734,
    y: 0.05812634527683258
    },
    {
    x: 0.9257604479789734,
    y: 0.9853703379631042
    },
    {
    x: 0.17157287895679474,
    y: 0.9853703379631042
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6101117730140686,
    bounding_box: [
    {
    x: 0.27431103587150574,
    y: 0.4770409166812897
    },
    {
    x: 0.8943475484848022,
    y: 0.4770409166812897
    },
    {
    x: 0.8943475484848022,
    y: 0.9909111261367798
    },
    {
    x: 0.27431103587150574,
    y: 0.9909111261367798
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40224,
    time_in_milliseconds: 1342141,
    labels: [
    {
    description: "Hair",
    confidence: 98.57708811759949
    },
    {
    description: "Facial hair",
    confidence: 97.70393967628479
    },
    {
    description: "Beard",
    confidence: 96.58311605453491
    },
    {
    description: "Eyewear",
    confidence: 92.08218455314636
    },
    {
    description: "Moustache",
    confidence: 89.30952548980713
    },
    {
    description: "Hairstyle",
    confidence: 87.35474944114685
    },
    {
    description: "Glasses",
    confidence: 75.91426968574524
    },
    {
    description: "Vision care",
    confidence: 65.7684862613678
    },
    {
    description: "Afro",
    confidence: 59.719640016555786
    },
    {
    description: "Dreadlocks",
    confidence: 52.51859426498413
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9376961588859558,
    bounding_box: [
    {
    x: 0.5073210597038269,
    y: 0.2586183249950409
    },
    {
    x: 0.6778680682182312,
    y: 0.2586183249950409
    },
    {
    x: 0.6778680682182312,
    y: 0.3772927224636078
    },
    {
    x: 0.5073210597038269,
    y: 0.3772927224636078
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8198796510696411,
    bounding_box: [
    {
    x: 0.1605152040719986,
    y: 0.058009739965200424
    },
    {
    x: 0.9635706543922424,
    y: 0.058009739965200424
    },
    {
    x: 0.9635706543922424,
    y: 0.9939138293266296
    },
    {
    x: 0.1605152040719986,
    y: 0.9939138293266296
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.637927770614624,
    bounding_box: [
    {
    x: 0.17600996792316437,
    y: 0.5020573735237122
    },
    {
    x: 0.9774608016014099,
    y: 0.5020573735237122
    },
    {
    x: 0.9774608016014099,
    y: 0.9973958134651184
    },
    {
    x: 0.17600996792316437,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40311,
    time_in_milliseconds: 1345044,
    labels: [
    {
    description: "Hair",
    confidence: 98.83356094360352
    },
    {
    description: "Eyewear",
    confidence: 97.80526757240295
    },
    {
    description: "Facial hair",
    confidence: 97.23858833312988
    },
    {
    description: "Beard",
    confidence: 96.29759788513184
    },
    {
    description: "Moustache",
    confidence: 93.2936429977417
    },
    {
    description: "Glasses",
    confidence: 90.4336929321289
    },
    {
    description: "Hairstyle",
    confidence: 88.78235816955566
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "T-shirt",
    confidence: 81.18906021118164
    },
    {
    description: "Vision care",
    confidence: 77.37371325492859
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.93480384349823,
    bounding_box: [
    {
    x: 0.4992997348308563,
    y: 0.26469162106513977
    },
    {
    x: 0.6744721531867981,
    y: 0.26469162106513977
    },
    {
    x: 0.6744721531867981,
    y: 0.38331663608551025
    },
    {
    x: 0.4992997348308563,
    y: 0.38331663608551025
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7949376702308655,
    bounding_box: [
    {
    x: 0.1954195648431778,
    y: 0.06158136948943138
    },
    {
    x: 0.9368184208869934,
    y: 0.06158136948943138
    },
    {
    x: 0.9368184208869934,
    y: 0.9952435493469238
    },
    {
    x: 0.1954195648431778,
    y: 0.9952435493469238
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7286151647567749,
    bounding_box: [
    {
    x: 0.19340521097183228,
    y: 0.4846864640712738
    },
    {
    x: 0.9361748099327087,
    y: 0.4846864640712738
    },
    {
    x: 0.9361748099327087,
    y: 0.9910471439361572
    },
    {
    x: 0.19340521097183228,
    y: 0.9910471439361572
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5477433800697327,
    bounding_box: [
    {
    x: 0.0827602669596672,
    y: 0.0951336994767189
    },
    {
    x: 0.2382993996143341,
    y: 0.0951336994767189
    },
    {
    x: 0.2382993996143341,
    y: 0.32279741764068604
    },
    {
    x: 0.0827602669596672,
    y: 0.32279741764068604
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40369,
    time_in_milliseconds: 1346978,
    labels: [
    {
    description: "Hair",
    confidence: 98.36366772651672
    },
    {
    description: "Facial hair",
    confidence: 97.17468023300171
    },
    {
    description: "Beard",
    confidence: 94.22947764396667
    },
    {
    description: "Hairstyle",
    confidence: 87.97082304954529
    },
    {
    description: "Moustache",
    confidence: 86.84353232383728
    },
    {
    description: "Glasses",
    confidence: 86.04933619499207
    },
    {
    description: "Eyewear",
    confidence: 82.89366364479065
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    },
    {
    description: "Portrait",
    confidence: 74.97205138206482
    },
    {
    description: "Houseplant",
    confidence: 72.9094386100769
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9437544941902161,
    bounding_box: [
    {
    x: 0.4946744441986084,
    y: 0.2932552695274353
    },
    {
    x: 0.6804240345954895,
    y: 0.2932552695274353
    },
    {
    x: 0.6804240345954895,
    y: 0.41124698519706726
    },
    {
    x: 0.4946744441986084,
    y: 0.41124698519706726
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.745354175567627,
    bounding_box: [
    {
    x: 0.2532467246055603,
    y: 0.06288949400186539
    },
    {
    x: 0.9670102000236511,
    y: 0.06288949400186539
    },
    {
    x: 0.9670102000236511,
    y: 0.9965052604675293
    },
    {
    x: 0.2532467246055603,
    y: 0.9965052604675293
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7024658918380737,
    bounding_box: [
    {
    x: 0.23995435237884521,
    y: 0.470363050699234
    },
    {
    x: 0.9571468830108643,
    y: 0.470363050699234
    },
    {
    x: 0.9571468830108643,
    y: 0.9914467334747314
    },
    {
    x: 0.23995435237884521,
    y: 0.9914467334747314
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5973314642906189,
    bounding_box: [
    {
    x: 0.20272500813007355,
    y: 0.640132486820221
    },
    {
    x: 0.3397713899612427,
    y: 0.640132486820221
    },
    {
    x: 0.3397713899612427,
    y: 0.9113321304321289
    },
    {
    x: 0.20272500813007355,
    y: 0.9113321304321289
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5418028235435486,
    bounding_box: [
    {
    x: 0.08134198933839798,
    y: 0.09259602427482605
    },
    {
    x: 0.23830941319465637,
    y: 0.09259602427482605
    },
    {
    x: 0.23830941319465637,
    y: 0.32082921266555786
    },
    {
    x: 0.08134198933839798,
    y: 0.32082921266555786
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5408832430839539,
    bounding_box: [
    {
    x: 0.0009188688709400594,
    y: 0.7026593089103699
    },
    {
    x: 0.1215033233165741,
    y: 0.7026593089103699
    },
    {
    x: 0.1215033233165741,
    y: 0.9973958134651184
    },
    {
    x: 0.0009188688709400594,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5320996046066284,
    bounding_box: [
    {
    x: 0.04530326649546623,
    y: 0.4333827495574951
    },
    {
    x: 0.1093587726354599,
    y: 0.4333827495574951
    },
    {
    x: 0.1093587726354599,
    y: 0.7089986205101013
    },
    {
    x: 0.04530326649546623,
    y: 0.7089986205101013
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40456,
    time_in_milliseconds: 1349881,
    labels: [
    {
    description: "Hair",
    confidence: 98.48300218582153
    },
    {
    description: "Eyewear",
    confidence: 97.24193811416626
    },
    {
    description: "Facial hair",
    confidence: 96.81777954101562
    },
    {
    description: "Beard",
    confidence: 94.41088438034058
    },
    {
    description: "Glasses",
    confidence: 90.1199221611023
    },
    {
    description: "Moustache",
    confidence: 89.1919493675232
    },
    {
    description: "Forehead",
    confidence: 86.29716038703918
    },
    {
    description: "Hairstyle",
    confidence: 85.60138940811157
    },
    {
    description: "Vision care",
    confidence: 76.22490525245667
    },
    {
    description: "Finger",
    confidence: 71.64893746376038
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9290586709976196,
    bounding_box: [
    {
    x: 0.48854389786720276,
    y: 0.26582640409469604
    },
    {
    x: 0.6679829955101013,
    y: 0.26582640409469604
    },
    {
    x: 0.6679829955101013,
    y: 0.3903476297855377
    },
    {
    x: 0.48854389786720276,
    y: 0.3903476297855377
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7522326707839966,
    bounding_box: [
    {
    x: 0.2399299144744873,
    y: 0.04942202940583229
    },
    {
    x: 0.9455106258392334,
    y: 0.04942202940583229
    },
    {
    x: 0.9455106258392334,
    y: 0.9931855797767639
    },
    {
    x: 0.2399299144744873,
    y: 0.9931855797767639
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7333590388298035,
    bounding_box: [
    {
    x: 0.25640231370925903,
    y: 0.4694625437259674
    },
    {
    x: 0.9288897514343262,
    y: 0.4694625437259674
    },
    {
    x: 0.9288897514343262,
    y: 0.9933457374572754
    },
    {
    x: 0.25640231370925903,
    y: 0.9933457374572754
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5737236142158508,
    bounding_box: [
    {
    x: 0.20121431350708008,
    y: 0.6397538781166077
    },
    {
    x: 0.33954617381095886,
    y: 0.6397538781166077
    },
    {
    x: 0.33954617381095886,
    y: 0.9021100997924805
    },
    {
    x: 0.20121431350708008,
    y: 0.9021100997924805
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5065144896507263,
    bounding_box: [
    {
    x: 0.7214669585227966,
    y: 0
    },
    {
    x: 0.9938685297966003,
    y: 0
    },
    {
    x: 0.9938685297966003,
    y: 0.7398324012756348
    },
    {
    x: 0.7214669585227966,
    y: 0.7398324012756348
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40485,
    time_in_milliseconds: 1350849.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 97.61541485786438
    },
    {
    description: "Eyewear",
    confidence: 92.08218455314636
    },
    {
    description: "Facial hair",
    confidence: 91.85858368873596
    },
    {
    description: "Houseplant",
    confidence: 90.22219181060791
    },
    {
    description: "Beard",
    confidence: 86.54796481132507
    },
    {
    description: "Glasses",
    confidence: 82.4069619178772
    },
    {
    description: "Moustache",
    confidence: 75.98623633384705
    },
    {
    description: "Plant",
    confidence: 72.04007506370544
    },
    {
    description: "Vision care",
    confidence: 70.48851251602173
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9413455128669739,
    bounding_box: [
    {
    x: 0.5148616433143616,
    y: 0.2674000561237335
    },
    {
    x: 0.6868157386779785,
    y: 0.2674000561237335
    },
    {
    x: 0.6868157386779785,
    y: 0.3889092206954956
    },
    {
    x: 0.5148616433143616,
    y: 0.3889092206954956
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7974596619606018,
    bounding_box: [
    {
    x: 0.12478142976760864,
    y: 0.48845091462135315
    },
    {
    x: 0.9367227554321289,
    y: 0.48845091462135315
    },
    {
    x: 0.9367227554321289,
    y: 0.9932489395141602
    },
    {
    x: 0.12478142976760864,
    y: 0.9932489395141602
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.779229462146759,
    bounding_box: [
    {
    x: 0.07817191630601883,
    y: 0.04162532836198807
    },
    {
    x: 0.9379878044128418,
    y: 0.04162532836198807
    },
    {
    x: 0.9379878044128418,
    y: 0.9880537390708923
    },
    {
    x: 0.07817191630601883,
    y: 0.9880537390708923
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5257130265235901,
    bounding_box: [
    {
    x: 0.20169848203659058,
    y: 0.6396704316139221
    },
    {
    x: 0.35064008831977844,
    y: 0.6396704316139221
    },
    {
    x: 0.35064008831977844,
    y: 0.9092965126037598
    },
    {
    x: 0.20169848203659058,
    y: 0.9092965126037598
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40514,
    time_in_milliseconds: 1351817,
    labels: [
    {
    description: "Hair",
    confidence: 98.75229001045227
    },
    {
    description: "Facial hair",
    confidence: 97.14186787605286
    },
    {
    description: "Beard",
    confidence: 95.72392106056213
    },
    {
    description: "Moustache",
    confidence: 92.9079532623291
    },
    {
    description: "Glasses",
    confidence: 92.6969051361084
    },
    {
    description: "Hairstyle",
    confidence: 90.02647399902344
    },
    {
    description: "Eyewear",
    confidence: 89.4751250743866
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Dreadlocks",
    confidence: 81.0230016708374
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9422258138656616,
    bounding_box: [
    {
    x: 0.4974137842655182,
    y: 0.26400068402290344
    },
    {
    x: 0.671933114528656,
    y: 0.26400068402290344
    },
    {
    x: 0.671933114528656,
    y: 0.38196924328804016
    },
    {
    x: 0.4974137842655182,
    y: 0.38196924328804016
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.793106734752655,
    bounding_box: [
    {
    x: 0.28338152170181274,
    y: 0.45531368255615234
    },
    {
    x: 0.9299951195716858,
    y: 0.45531368255615234
    },
    {
    x: 0.9299951195716858,
    y: 0.993671715259552
    },
    {
    x: 0.28338152170181274,
    y: 0.993671715259552
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7592690587043762,
    bounding_box: [
    {
    x: 0.2961721122264862,
    y: 0.05798417329788208
    },
    {
    x: 0.9319912195205688,
    y: 0.05798417329788208
    },
    {
    x: 0.9319912195205688,
    y: 0.9862620830535889
    },
    {
    x: 0.2961721122264862,
    y: 0.9862620830535889
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5911427140235901,
    bounding_box: [
    {
    x: 0.20020566880702972,
    y: 0.6383740901947021
    },
    {
    x: 0.3423328697681427,
    y: 0.6383740901947021
    },
    {
    x: 0.3423328697681427,
    y: 0.9197628498077393
    },
    {
    x: 0.20020566880702972,
    y: 0.9197628498077393
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5177057385444641,
    bounding_box: [
    {
    x: 0.00020224321633577347,
    y: 0.7036173343658447
    },
    {
    x: 0.12109410762786865,
    y: 0.7036173343658447
    },
    {
    x: 0.12109410762786865,
    y: 0.9973958134651184
    },
    {
    x: 0.00020224321633577347,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40543,
    time_in_milliseconds: 1352785,
    labels: [
    {
    description: "Eyewear",
    confidence: 98.23066592216492
    },
    {
    description: "Hair",
    confidence: 98.00390005111694
    },
    {
    description: "Glasses",
    confidence: 93.38595271110535
    },
    {
    description: "Facial hair",
    confidence: 93.36670637130737
    },
    {
    description: "Finger",
    confidence: 90.39629101753235
    },
    {
    description: "Beard",
    confidence: 86.1425518989563
    },
    {
    description: "Arm",
    confidence: 84.90605354309082
    },
    {
    description: "Hairstyle",
    confidence: 82.93982148170471
    },
    {
    description: "Moustache",
    confidence: 81.46239519119263
    },
    {
    description: "Shoulder",
    confidence: 80.12816905975342
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9332252740859985,
    bounding_box: [
    {
    x: 0.5039848685264587,
    y: 0.24792170524597168
    },
    {
    x: 0.6751250624656677,
    y: 0.24792170524597168
    },
    {
    x: 0.6751250624656677,
    y: 0.3647785186767578
    },
    {
    x: 0.5039848685264587,
    y: 0.3647785186767578
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7841812372207642,
    bounding_box: [
    {
    x: 0.26709800958633423,
    y: 0.05166472867131233
    },
    {
    x: 0.9331895709037781,
    y: 0.05166472867131233
    },
    {
    x: 0.9331895709037781,
    y: 0.9945087432861328
    },
    {
    x: 0.26709800958633423,
    y: 0.9945087432861328
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6839179992675781,
    bounding_box: [
    {
    x: 0.28274890780448914,
    y: 0.47571155428886414
    },
    {
    x: 0.914247989654541,
    y: 0.47571155428886414
    },
    {
    x: 0.914247989654541,
    y: 0.9962183237075806
    },
    {
    x: 0.28274890780448914,
    y: 0.9962183237075806
    }
    ]
    },
    {
    description: "Nightstand",
    confidence: 0.5784153342247009,
    bounding_box: [
    {
    x: 0.000414997834013775,
    y: 0.7045348286628723
    },
    {
    x: 0.1197119876742363,
    y: 0.7045348286628723
    },
    {
    x: 0.1197119876742363,
    y: 0.9973958134651184
    },
    {
    x: 0.000414997834013775,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40601,
    time_in_milliseconds: 1354720,
    labels: [
    {
    description: "Hair",
    confidence: 98.72674345970154
    },
    {
    description: "Eyewear",
    confidence: 97.80526757240295
    },
    {
    description: "Facial hair",
    confidence: 94.53142881393433
    },
    {
    description: "Beard",
    confidence: 91.22539162635803
    },
    {
    description: "Dreadlocks",
    confidence: 90.13617634773254
    },
    {
    description: "Hairstyle",
    confidence: 89.3006443977356
    },
    {
    description: "Glasses",
    confidence: 87.21618056297302
    },
    {
    description: "Arm",
    confidence: 83.45222473144531
    },
    {
    description: "Forehead",
    confidence: 82.17551112174988
    },
    {
    description: "Moustache",
    confidence: 81.46239519119263
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9407105445861816,
    bounding_box: [
    {
    x: 0.5018369555473328,
    y: 0.26676440238952637
    },
    {
    x: 0.6743337512016296,
    y: 0.26676440238952637
    },
    {
    x: 0.6743337512016296,
    y: 0.3876591622829437
    },
    {
    x: 0.5018369555473328,
    y: 0.3876591622829437
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7749682664871216,
    bounding_box: [
    {
    x: 0.2562362551689148,
    y: 0.05933554843068123
    },
    {
    x: 0.9432924389839172,
    y: 0.05933554843068123
    },
    {
    x: 0.9432924389839172,
    y: 0.991584062576294
    },
    {
    x: 0.2562362551689148,
    y: 0.991584062576294
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.644595205783844,
    bounding_box: [
    {
    x: 0.27155306935310364,
    y: 0.46713200211524963
    },
    {
    x: 0.9013323783874512,
    y: 0.46713200211524963
    },
    {
    x: 0.9013323783874512,
    y: 0.993466854095459
    },
    {
    x: 0.27155306935310364,
    y: 0.993466854095459
    }
    ]
    },
    {
    description: "Nightstand",
    confidence: 0.5615720152854919,
    bounding_box: [
    {
    x: 0.00041545857675373554,
    y: 0.7063672542572021
    },
    {
    x: 0.11854592710733414,
    y: 0.7063672542572021
    },
    {
    x: 0.11854592710733414,
    y: 0.9973958134651184
    },
    {
    x: 0.00041545857675373554,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5032109022140503,
    bounding_box: [
    {
    x: 0.7214064002037048,
    y: 0
    },
    {
    x: 0.9945383071899414,
    y: 0
    },
    {
    x: 0.9945383071899414,
    y: 0.7375422120094299
    },
    {
    x: 0.7214064002037048,
    y: 0.7375422120094299
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40688,
    time_in_milliseconds: 1357622,
    labels: [
    {
    description: "Hair",
    confidence: 98.11214804649353
    },
    {
    description: "Facial hair",
    confidence: 95.14801502227783
    },
    {
    description: "Beard",
    confidence: 91.01678729057312
    },
    {
    description: "Glasses",
    confidence: 89.78612422943115
    },
    {
    description: "Moustache",
    confidence: 86.3451361656189
    },
    {
    description: "Eyewear",
    confidence: 86.2449049949646
    },
    {
    description: "Hairstyle",
    confidence: 85.19834280014038
    },
    {
    description: "Houseplant",
    confidence: 78.81877422332764
    },
    {
    description: "Vision care",
    confidence: 61.489856243133545
    },
    {
    description: "Plant",
    confidence: 58.5474967956543
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9242558479309082,
    bounding_box: [
    {
    x: 0.4894044101238251,
    y: 0.2669123709201813
    },
    {
    x: 0.6608675122261047,
    y: 0.2669123709201813
    },
    {
    x: 0.6608675122261047,
    y: 0.3891031742095947
    },
    {
    x: 0.4894044101238251,
    y: 0.3891031742095947
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8024871945381165,
    bounding_box: [
    {
    x: 0.2835983633995056,
    y: 0.47277024388313293
    },
    {
    x: 0.9376299977302551,
    y: 0.47277024388313293
    },
    {
    x: 0.9376299977302551,
    y: 0.993227481842041
    },
    {
    x: 0.2835983633995056,
    y: 0.993227481842041
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7481966614723206,
    bounding_box: [
    {
    x: 0.2831588089466095,
    y: 0.06145673617720604
    },
    {
    x: 0.9433372020721436,
    y: 0.06145673617720604
    },
    {
    x: 0.9433372020721436,
    y: 0.9835070967674255
    },
    {
    x: 0.2831588089466095,
    y: 0.9835070967674255
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5406284928321838,
    bounding_box: [
    {
    x: 0.7236953377723694,
    y: 0
    },
    {
    x: 0.9964008927345276,
    y: 0
    },
    {
    x: 0.9964008927345276,
    y: 0.7345723509788513
    },
    {
    x: 0.7236953377723694,
    y: 0.7345723509788513
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40717,
    time_in_milliseconds: 1358591.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.49514365196228
    },
    {
    description: "Houseplant",
    confidence: 95.99817395210266
    },
    {
    description: "Facial hair",
    confidence: 94.43196654319763
    },
    {
    description: "Eyewear",
    confidence: 94.320547580719
    },
    {
    description: "Beard",
    confidence: 91.71856641769409
    },
    {
    description: "Hairstyle",
    confidence: 89.3006443977356
    },
    {
    description: "Glasses",
    confidence: 88.84918093681335
    },
    {
    description: "Moustache",
    confidence: 87.75661587715149
    },
    {
    description: "Plant",
    confidence: 76.47647857666016
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9455989003181458,
    bounding_box: [
    {
    x: 0.5114611983299255,
    y: 0.2933415472507477
    },
    {
    x: 0.6944403052330017,
    y: 0.2933415472507477
    },
    {
    x: 0.6944403052330017,
    y: 0.4193384349346161
    },
    {
    x: 0.5114611983299255,
    y: 0.4193384349346161
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7846547961235046,
    bounding_box: [
    {
    x: 0.32666704058647156,
    y: 0.45460405945777893
    },
    {
    x: 0.9196306467056274,
    y: 0.45460405945777893
    },
    {
    x: 0.9196306467056274,
    y: 0.9973957538604736
    },
    {
    x: 0.32666704058647156,
    y: 0.9973957538604736
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7579175233840942,
    bounding_box: [
    {
    x: 0.13807937502861023,
    y: 0.042059216648340225
    },
    {
    x: 0.9149472713470459,
    y: 0.042059216648340225
    },
    {
    x: 0.9149472713470459,
    y: 0.9907384514808655
    },
    {
    x: 0.13807937502861023,
    y: 0.9907384514808655
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6809733510017395,
    bounding_box: [
    {
    x: 0.10165932029485703,
    y: 0.05006423220038414
    },
    {
    x: 0.9068517684936523,
    y: 0.05006423220038414
    },
    {
    x: 0.9068517684936523,
    y: 0.990776002407074
    },
    {
    x: 0.10165932029485703,
    y: 0.990776002407074
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40746,
    time_in_milliseconds: 1359558,
    labels: [
    {
    description: "Hair",
    confidence: 98.58205914497375
    },
    {
    description: "Facial hair",
    confidence: 94.62777972221375
    },
    {
    description: "Houseplant",
    confidence: 93.75562071800232
    },
    {
    description: "Beard",
    confidence: 90.90986847877502
    },
    {
    description: "Hairstyle",
    confidence: 90.11608958244324
    },
    {
    description: "Glasses",
    confidence: 88.84918093681335
    },
    {
    description: "Eyewear",
    confidence: 87.50287890434265
    },
    {
    description: "Moustache",
    confidence: 85.63084006309509
    },
    {
    description: "Dreadlocks",
    confidence: 84.94908213615417
    },
    {
    description: "Plant",
    confidence: 72.3915159702301
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9433114528656006,
    bounding_box: [
    {
    x: 0.5086638331413269,
    y: 0.28417104482650757
    },
    {
    x: 0.69146728515625,
    y: 0.28417104482650757
    },
    {
    x: 0.69146728515625,
    y: 0.40702250599861145
    },
    {
    x: 0.5086638331413269,
    y: 0.40702250599861145
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7592097520828247,
    bounding_box: [
    {
    x: 0.19028018414974213,
    y: 0.039353854954242706
    },
    {
    x: 0.9197540283203125,
    y: 0.039353854954242706
    },
    {
    x: 0.9197540283203125,
    y: 0.9904319643974304
    },
    {
    x: 0.19028018414974213,
    y: 0.9904319643974304
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7138579487800598,
    bounding_box: [
    {
    x: 0.306503564119339,
    y: 0.4493665397167206
    },
    {
    x: 0.9210025072097778,
    y: 0.4493665397167206
    },
    {
    x: 0.9210025072097778,
    y: 0.9963549375534058
    },
    {
    x: 0.306503564119339,
    y: 0.9963549375534058
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5772996544837952,
    bounding_box: [
    {
    x: 0.1999230980873108,
    y: 0.6392062902450562
    },
    {
    x: 0.3475154936313629,
    y: 0.6392062902450562
    },
    {
    x: 0.3475154936313629,
    y: 0.8913446068763733
    },
    {
    x: 0.1999230980873108,
    y: 0.8913446068763733
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40804,
    time_in_milliseconds: 1361494.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.26501607894897
    },
    {
    description: "Facial hair",
    confidence: 97.57990837097168
    },
    {
    description: "Beard",
    confidence: 95.2366054058075
    },
    {
    description: "Moustache",
    confidence: 91.14124774932861
    },
    {
    description: "Hairstyle",
    confidence: 84.74541306495667
    },
    {
    description: "Glasses",
    confidence: 83.378666639328
    },
    {
    description: "Houseplant",
    confidence: 83.1293523311615
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Plant",
    confidence: 62.44105100631714
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9378780126571655,
    bounding_box: [
    {
    x: 0.5081301331520081,
    y: 0.2865154445171356
    },
    {
    x: 0.6933532357215881,
    y: 0.2865154445171356
    },
    {
    x: 0.6933532357215881,
    y: 0.4030974209308624
    },
    {
    x: 0.5081301331520081,
    y: 0.4030974209308624
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7194064259529114,
    bounding_box: [
    {
    x: 0.2702295184135437,
    y: 0.052820902317762375
    },
    {
    x: 0.9502786993980408,
    y: 0.052820902317762375
    },
    {
    x: 0.9502786993980408,
    y: 0.9878251552581787
    },
    {
    x: 0.2702295184135437,
    y: 0.9878251552581787
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6804181337356567,
    bounding_box: [
    {
    x: 0.26565662026405334,
    y: 0.47660648822784424
    },
    {
    x: 0.8938803672790527,
    y: 0.47660648822784424
    },
    {
    x: 0.8938803672790527,
    y: 0.994258463382721
    },
    {
    x: 0.26565662026405334,
    y: 0.994258463382721
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 40862,
    time_in_milliseconds: 1363428,
    labels: [
    {
    description: "Hair",
    confidence: 98.37164878845215
    },
    {
    description: "Facial hair",
    confidence: 93.63383650779724
    },
    {
    description: "Eyewear",
    confidence: 92.55768656730652
    },
    {
    description: "Beard",
    confidence: 91.12194776535034
    },
    {
    description: "Moustache",
    confidence: 90.38788676261902
    },
    {
    description: "Glasses",
    confidence: 87.21618056297302
    },
    {
    description: "Hairstyle",
    confidence: 87.11950778961182
    },
    {
    description: "Vision care",
    confidence: 72.36137390136719
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    },
    {
    description: "Plant",
    confidence: 58.5474967956543
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.939070999622345,
    bounding_box: [
    {
    x: 0.5138360857963562,
    y: 0.2793523669242859
    },
    {
    x: 0.6914424896240234,
    y: 0.2793523669242859
    },
    {
    x: 0.6914424896240234,
    y: 0.40271759033203125
    },
    {
    x: 0.5138360857963562,
    y: 0.40271759033203125
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8164142966270447,
    bounding_box: [
    {
    x: 0.3203215003013611,
    y: 0.46825334429740906
    },
    {
    x: 0.907871425151825,
    y: 0.46825334429740906
    },
    {
    x: 0.907871425151825,
    y: 0.989482045173645
    },
    {
    x: 0.3203215003013611,
    y: 0.989482045173645
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7540760040283203,
    bounding_box: [
    {
    x: 0.23865056037902832,
    y: 0.0499005950987339
    },
    {
    x: 0.9152405261993408,
    y: 0.0499005950987339
    },
    {
    x: 0.9152405261993408,
    y: 0.9910828471183777
    },
    {
    x: 0.23865056037902832,
    y: 0.9910828471183777
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41007,
    time_in_milliseconds: 1368267,
    labels: [
    {
    description: "Hair",
    confidence: 98.9097535610199
    },
    {
    description: "Facial hair",
    confidence: 96.23391628265381
    },
    {
    description: "Facial expression",
    confidence: 94.43857669830322
    },
    {
    description: "Glasses",
    confidence: 93.83495450019836
    },
    {
    description: "Beard",
    confidence: 93.71429085731506
    },
    {
    description: "Eyewear",
    confidence: 92.55768656730652
    },
    {
    description: "Hairstyle",
    confidence: 91.99153184890747
    },
    {
    description: "Moustache",
    confidence: 90.86881279945374
    },
    {
    description: "Afro",
    confidence: 89.34439420700073
    },
    {
    description: "Smile",
    confidence: 84.0890645980835
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9411856532096863,
    bounding_box: [
    {
    x: 0.491255521774292,
    y: 0.2878473103046417
    },
    {
    x: 0.6759686470031738,
    y: 0.2878473103046417
    },
    {
    x: 0.6759686470031738,
    y: 0.41144728660583496
    },
    {
    x: 0.491255521774292,
    y: 0.41144728660583496
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8377804160118103,
    bounding_box: [
    {
    x: 0.3222452998161316,
    y: 0.47016504406929016
    },
    {
    x: 0.9240147471427917,
    y: 0.47016504406929016
    },
    {
    x: 0.9240147471427917,
    y: 0.9921704530715942
    },
    {
    x: 0.3222452998161316,
    y: 0.9921704530715942
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7342288494110107,
    bounding_box: [
    {
    x: 0.2971208393573761,
    y: 0.04596688970923424
    },
    {
    x: 0.9281253814697266,
    y: 0.04596688970923424
    },
    {
    x: 0.9281253814697266,
    y: 0.9899209141731262
    },
    {
    x: 0.2971208393573761,
    y: 0.9899209141731262
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6354349851608276,
    bounding_box: [
    {
    x: 0.20159661769866943,
    y: 0.6374786496162415
    },
    {
    x: 0.34275129437446594,
    y: 0.6374786496162415
    },
    {
    x: 0.34275129437446594,
    y: 0.8959097266197205
    },
    {
    x: 0.20159661769866943,
    y: 0.8959097266197205
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5170992612838745,
    bounding_box: [
    {
    x: 0.7216441035270691,
    y: 0
    },
    {
    x: 0.9955818057060242,
    y: 0
    },
    {
    x: 0.9955818057060242,
    y: 0.7364982962608337
    },
    {
    x: 0.7216441035270691,
    y: 0.7364982962608337
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41036,
    time_in_milliseconds: 1369234.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 97.86621332168579
    },
    {
    description: "Eyewear",
    confidence: 96.48454785346985
    },
    {
    description: "Glasses",
    confidence: 94.87643837928772
    },
    {
    description: "Facial hair",
    confidence: 92.9253876209259
    },
    {
    description: "Hairstyle",
    confidence: 85.19834280014038
    },
    {
    description: "Vision care",
    confidence: 83.29547047615051
    },
    {
    description: "Beard",
    confidence: 83.19377899169922
    },
    {
    description: "Moustache",
    confidence: 73.98975491523743
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Finger",
    confidence: 67.49656200408936
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.928402841091156,
    bounding_box: [
    {
    x: 0.5355483889579773,
    y: 0.26338300108909607
    },
    {
    x: 0.7294497489929199,
    y: 0.26338300108909607
    },
    {
    x: 0.7294497489929199,
    y: 0.3996538817882538
    },
    {
    x: 0.5355483889579773,
    y: 0.3996538817882538
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7457729578018188,
    bounding_box: [
    {
    x: 0.09627359360456467,
    y: 0.035423386842012405
    },
    {
    x: 0.8933599591255188,
    y: 0.035423386842012405
    },
    {
    x: 0.8933599591255188,
    y: 0.9893634915351868
    },
    {
    x: 0.09627359360456467,
    y: 0.9893634915351868
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.537795901298523,
    bounding_box: [
    {
    x: 0.3011680543422699,
    y: 0.4465853273868561
    },
    {
    x: 0.9078433513641357,
    y: 0.4465853273868561
    },
    {
    x: 0.9078433513641357,
    y: 0.9934853315353394
    },
    {
    x: 0.3011680543422699,
    y: 0.9934853315353394
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41065,
    time_in_milliseconds: 1370202,
    labels: [
    {
    description: "Hair",
    confidence: 98.86240363121033
    },
    {
    description: "Facial hair",
    confidence: 98.20747971534729
    },
    {
    description: "Beard",
    confidence: 97.292959690094
    },
    {
    description: "Moustache",
    confidence: 94.75889801979065
    },
    {
    description: "Hairstyle",
    confidence: 89.41669464111328
    },
    {
    description: "Glasses",
    confidence: 87.97047138214111
    },
    {
    description: "Houseplant",
    confidence: 80.59768676757812
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    },
    {
    description: "Plant",
    confidence: 62.44105100631714
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9382839202880859,
    bounding_box: [
    {
    x: 0.49828365445137024,
    y: 0.2911665141582489
    },
    {
    x: 0.6835100054740906,
    y: 0.2911665141582489
    },
    {
    x: 0.6835100054740906,
    y: 0.4100940525531769
    },
    {
    x: 0.49828365445137024,
    y: 0.4100940525531769
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7558791637420654,
    bounding_box: [
    {
    x: 0.30100417137145996,
    y: 0.4755386412143707
    },
    {
    x: 0.9198421835899353,
    y: 0.4755386412143707
    },
    {
    x: 0.9198421835899353,
    y: 0.9934349060058594
    },
    {
    x: 0.30100417137145996,
    y: 0.9934349060058594
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7518097758293152,
    bounding_box: [
    {
    x: 0.3071797788143158,
    y: 0.04569725692272186
    },
    {
    x: 0.9214050769805908,
    y: 0.04569725692272186
    },
    {
    x: 0.9214050769805908,
    y: 0.9883928894996643
    },
    {
    x: 0.3071797788143158,
    y: 0.9883928894996643
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6327285766601562,
    bounding_box: [
    {
    x: 0.19857873022556305,
    y: 0.6347946524620056
    },
    {
    x: 0.3464510440826416,
    y: 0.6347946524620056
    },
    {
    x: 0.3464510440826416,
    y: 0.9233735203742981
    },
    {
    x: 0.19857873022556305,
    y: 0.9233735203742981
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5326756834983826,
    bounding_box: [
    {
    x: 0.7257511615753174,
    y: 0
    },
    {
    x: 0.9966415762901306,
    y: 0
    },
    {
    x: 0.9966415762901306,
    y: 0.7407673001289368
    },
    {
    x: 0.7257511615753174,
    y: 0.7407673001289368
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41094,
    time_in_milliseconds: 1371169,
    labels: [
    {
    description: "News",
    confidence: 94.91932988166809
    },
    {
    description: "Media",
    confidence: 74.08702969551086
    },
    {
    description: "Photo caption",
    confidence: 61.63979768753052
    },
    {
    description: "Facial hair",
    confidence: 60.71390509605408
    },
    {
    description: "Glasses",
    confidence: 56.08964562416077
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9455770254135132,
    bounding_box: [
    {
    x: 0.6354894042015076,
    y: 0.2986099421977997
    },
    {
    x: 0.7817928791046143,
    y: 0.2986099421977997
    },
    {
    x: 0.7817928791046143,
    y: 0.39878037571907043
    },
    {
    x: 0.6354894042015076,
    y: 0.39878037571907043
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9384958744049072,
    bounding_box: [
    {
    x: 0.2024540901184082,
    y: 0.3636611998081207
    },
    {
    x: 0.34261879324913025,
    y: 0.3636611998081207
    },
    {
    x: 0.34261879324913025,
    y: 0.4579526484012604
    },
    {
    x: 0.2024540901184082,
    y: 0.4579526484012604
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8501640558242798,
    bounding_box: [
    {
    x: 0.14043490588665009,
    y: 0.17938269674777985
    },
    {
    x: 0.5019603967666626,
    y: 0.17938269674777985
    },
    {
    x: 0.5019603967666626,
    y: 0.8509643077850342
    },
    {
    x: 0.14043490588665009,
    y: 0.8509643077850342
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8129058480262756,
    bounding_box: [
    {
    x: 0.5044774413108826,
    y: 0.10547160357236862
    },
    {
    x: 0.8700051307678223,
    y: 0.10547160357236862
    },
    {
    x: 0.8700051307678223,
    y: 0.8514888286590576
    },
    {
    x: 0.5044774413108826,
    y: 0.8514888286590576
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6494876146316528,
    bounding_box: [
    {
    x: 0.1431681215763092,
    y: 0.4827282130718231
    },
    {
    x: 0.493487149477005,
    y: 0.4827282130718231
    },
    {
    x: 0.493487149477005,
    y: 0.8341732025146484
    },
    {
    x: 0.1431681215763092,
    y: 0.8341732025146484
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6409059762954712,
    bounding_box: [
    {
    x: 0.5037670731544495,
    y: 0.19322608411312103
    },
    {
    x: 0.8743986487388611,
    y: 0.19322608411312103
    },
    {
    x: 0.8743986487388611,
    y: 0.8335676789283752
    },
    {
    x: 0.5037670731544495,
    y: 0.8335676789283752
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41210,
    time_in_milliseconds: 1375041.0000000002,
    labels: [
    {
    description: "News",
    confidence: 92.6117479801178
    },
    {
    description: "Newscaster",
    confidence: 70.41324973106384
    },
    {
    description: "Newsreader",
    confidence: 64.93844985961914
    },
    {
    description: "Television presenter",
    confidence: 54.667240381240845
    },
    {
    description: "Media",
    confidence: 52.03367471694946
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9170442223548889,
    bounding_box: [
    {
    x: 0.2112942934036255,
    y: 0.33869877457618713
    },
    {
    x: 0.3398023843765259,
    y: 0.33869877457618713
    },
    {
    x: 0.3398023843765259,
    y: 0.42889904975891113
    },
    {
    x: 0.2112942934036255,
    y: 0.42889904975891113
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8391908407211304,
    bounding_box: [
    {
    x: 0.13412098586559296,
    y: 0.17103303968906403
    },
    {
    x: 0.5032961964607239,
    y: 0.17103303968906403
    },
    {
    x: 0.5032961964607239,
    y: 0.8542481064796448
    },
    {
    x: 0.13412098586559296,
    y: 0.8542481064796448
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5748780965805054,
    bounding_box: [
    {
    x: 0.13524852693080902,
    y: 0.19941674172878265
    },
    {
    x: 0.5004249215126038,
    y: 0.19941674172878265
    },
    {
    x: 0.5004249215126038,
    y: 0.8437425494194031
    },
    {
    x: 0.13524852693080902,
    y: 0.8437425494194031
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5119940042495728,
    bounding_box: [
    {
    x: 0.4695906639099121,
    y: 0.08978527784347534
    },
    {
    x: 0.8674767017364502,
    y: 0.08978527784347534
    },
    {
    x: 0.8674767017364502,
    y: 0.8534407019615173
    },
    {
    x: 0.4695906639099121,
    y: 0.8534407019615173
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41239,
    time_in_milliseconds: 1376007,
    labels: [
    {
    description: "News",
    confidence: 77.9337465763092
    },
    {
    description: "Glasses",
    confidence: 59.71137881278992
    },
    {
    description: "Media",
    confidence: 56.994253396987915
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9074956774711609,
    bounding_box: [
    {
    x: 0.21859635412693024,
    y: 0.2817937433719635
    },
    {
    x: 0.3498562276363373,
    y: 0.2817937433719635
    },
    {
    x: 0.3498562276363373,
    y: 0.3685619831085205
    },
    {
    x: 0.21859635412693024,
    y: 0.3685619831085205
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8305461406707764,
    bounding_box: [
    {
    x: 0.129612997174263,
    y: 0.13046547770500183
    },
    {
    x: 0.5010665655136108,
    y: 0.13046547770500183
    },
    {
    x: 0.5010665655136108,
    y: 0.851120114326477
    },
    {
    x: 0.129612997174263,
    y: 0.851120114326477
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7334706783294678,
    bounding_box: [
    {
    x: 0.14140602946281433,
    y: 0.3051847815513611
    },
    {
    x: 0.49729394912719727,
    y: 0.3051847815513611
    },
    {
    x: 0.49729394912719727,
    y: 0.8305099010467529
    },
    {
    x: 0.14140602946281433,
    y: 0.8305099010467529
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6461082696914673,
    bounding_box: [
    {
    x: 0.5016581416130066,
    y: 0.15229423344135284
    },
    {
    x: 0.865139901638031,
    y: 0.15229423344135284
    },
    {
    x: 0.865139901638031,
    y: 0.8404133915901184
    },
    {
    x: 0.5016581416130066,
    y: 0.8404133915901184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41268,
    time_in_milliseconds: 1376975.0000000002,
    labels: [
    {
    description: "News",
    confidence: 74.6500015258789
    },
    {
    description: "Media",
    confidence: 55.50802946090698
    },
    {
    description: "Newscaster",
    confidence: 54.58983778953552
    },
    {
    description: "Speech",
    confidence: 52.0534873008728
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.895426332950592,
    bounding_box: [
    {
    x: 0.14446024596691132,
    y: 0.3235005736351013
    },
    {
    x: 0.28278717398643494,
    y: 0.3235005736351013
    },
    {
    x: 0.28278717398643494,
    y: 0.4076915681362152
    },
    {
    x: 0.14446024596691132,
    y: 0.4076915681362152
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8366295695304871,
    bounding_box: [
    {
    x: 0.11475372314453125,
    y: 0.18078772723674774
    },
    {
    x: 0.5025457143783569,
    y: 0.18078772723674774
    },
    {
    x: 0.5025457143783569,
    y: 0.8523109555244446
    },
    {
    x: 0.11475372314453125,
    y: 0.8523109555244446
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5458791255950928,
    bounding_box: [
    {
    x: 0.11328486353158951,
    y: 0.20289601385593414
    },
    {
    x: 0.4998779594898224,
    y: 0.20289601385593414
    },
    {
    x: 0.4998779594898224,
    y: 0.8458459377288818
    },
    {
    x: 0.11328486353158951,
    y: 0.8458459377288818
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41326,
    time_in_milliseconds: 1378910,
    labels: [
    {
    description: "Media",
    confidence: 58.347392082214355
    },
    {
    description: "News",
    confidence: 57.56690502166748
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.918208122253418,
    bounding_box: [
    {
    x: 0.1568373590707779,
    y: 0.3566495180130005
    },
    {
    x: 0.2946643531322479,
    y: 0.3566495180130005
    },
    {
    x: 0.2946643531322479,
    y: 0.4475799798965454
    },
    {
    x: 0.1568373590707779,
    y: 0.4475799798965454
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8245441317558289,
    bounding_box: [
    {
    x: 0.11189069598913193,
    y: 0.18034331500530243
    },
    {
    x: 0.505708634853363,
    y: 0.18034331500530243
    },
    {
    x: 0.505708634853363,
    y: 0.8500054478645325
    },
    {
    x: 0.11189069598913193,
    y: 0.8500054478645325
    }
    ]
    },
    {
    description: "Furniture",
    confidence: 0.5537462830543518,
    bounding_box: [
    {
    x: 0.5673562288284302,
    y: 0.6740064024925232
    },
    {
    x: 0.8547191619873047,
    y: 0.6740064024925232
    },
    {
    x: 0.8547191619873047,
    y: 0.8359992504119873
    },
    {
    x: 0.5673562288284302,
    y: 0.8359992504119873
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41355,
    time_in_milliseconds: 1379878.0000000002,
    labels: [
    {
    description: "News",
    confidence: 88.6796236038208
    },
    {
    description: "Media",
    confidence: 65.32303094863892
    },
    {
    description: "Photo caption",
    confidence: 56.05892539024353
    },
    {
    description: "Television program",
    confidence: 55.72540760040283
    },
    {
    description: "Newscaster",
    confidence: 51.123636960983276
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9239392876625061,
    bounding_box: [
    {
    x: 0.1628519743680954,
    y: 0.376619428396225
    },
    {
    x: 0.2955237329006195,
    y: 0.376619428396225
    },
    {
    x: 0.2955237329006195,
    y: 0.46272680163383484
    },
    {
    x: 0.1628519743680954,
    y: 0.46272680163383484
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8399162888526917,
    bounding_box: [
    {
    x: 0.11845753341913223,
    y: 0.19113248586654663
    },
    {
    x: 0.497793048620224,
    y: 0.19113248586654663
    },
    {
    x: 0.497793048620224,
    y: 0.8437632918357849
    },
    {
    x: 0.11845753341913223,
    y: 0.8437632918357849
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5677952170372009,
    bounding_box: [
    {
    x: 0.5059293508529663,
    y: 0.06945925205945969
    },
    {
    x: 0.8718970417976379,
    y: 0.06945925205945969
    },
    {
    x: 0.8718970417976379,
    y: 0.8499153256416321
    },
    {
    x: 0.5059293508529663,
    y: 0.8499153256416321
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5173941850662231,
    bounding_box: [
    {
    x: 0.11775544285774231,
    y: 0.4729764461517334
    },
    {
    x: 0.49711084365844727,
    y: 0.4729764461517334
    },
    {
    x: 0.49711084365844727,
    y: 0.8491799831390381
    },
    {
    x: 0.11775544285774231,
    y: 0.8491799831390381
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41384,
    time_in_milliseconds: 1380846,
    labels: [
    {
    description: "News",
    confidence: 94.47503685951233
    },
    {
    description: "Media",
    confidence: 80.01057505607605
    },
    {
    description: "Glasses",
    confidence: 70.90374231338501
    },
    {
    description: "Photo caption",
    confidence: 64.09758925437927
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    },
    {
    description: "Facial hair",
    confidence: 55.52889108657837
    },
    {
    description: "Television program",
    confidence: 54.68852519989014
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9481289982795715,
    bounding_box: [
    {
    x: 0.6162111759185791,
    y: 0.24597853422164917
    },
    {
    x: 0.761634111404419,
    y: 0.24597853422164917
    },
    {
    x: 0.761634111404419,
    y: 0.34133803844451904
    },
    {
    x: 0.6162111759185791,
    y: 0.34133803844451904
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9379457831382751,
    bounding_box: [
    {
    x: 0.15428072214126587,
    y: 0.3845045566558838
    },
    {
    x: 0.2925592362880707,
    y: 0.3845045566558838
    },
    {
    x: 0.2925592362880707,
    y: 0.4735020101070404
    },
    {
    x: 0.15428072214126587,
    y: 0.4735020101070404
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8735648989677429,
    bounding_box: [
    {
    x: 0.11821051687002182,
    y: 0.19421903789043427
    },
    {
    x: 0.49723851680755615,
    y: 0.19421903789043427
    },
    {
    x: 0.49723851680755615,
    y: 0.8433826565742493
    },
    {
    x: 0.11821051687002182,
    y: 0.8433826565742493
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8689588308334351,
    bounding_box: [
    {
    x: 0.4975174367427826,
    y: 0.09685054421424866
    },
    {
    x: 0.8727720379829407,
    y: 0.09685054421424866
    },
    {
    x: 0.8727720379829407,
    y: 0.8488560914993286
    },
    {
    x: 0.4975174367427826,
    y: 0.8488560914993286
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6979254484176636,
    bounding_box: [
    {
    x: 0.49441441893577576,
    y: 0.20750974118709564
    },
    {
    x: 0.8722748160362244,
    y: 0.20750974118709564
    },
    {
    x: 0.8722748160362244,
    y: 0.8343782424926758
    },
    {
    x: 0.49441441893577576,
    y: 0.8343782424926758
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41413,
    time_in_milliseconds: 1381814,
    labels: [
    {
    description: "News",
    confidence: 81.2539279460907
    },
    {
    description: "Media",
    confidence: 77.30557322502136
    },
    {
    description: "Photo caption",
    confidence: 62.94999122619629
    },
    {
    description: "Glasses",
    confidence: 59.71137881278992
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9491046071052551,
    bounding_box: [
    {
    x: 0.6135072112083435,
    y: 0.26555725932121277
    },
    {
    x: 0.7504124641418457,
    y: 0.26555725932121277
    },
    {
    x: 0.7504124641418457,
    y: 0.3628414571285248
    },
    {
    x: 0.6135072112083435,
    y: 0.3628414571285248
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9230426549911499,
    bounding_box: [
    {
    x: 0.2159184366464615,
    y: 0.363113671541214
    },
    {
    x: 0.3514957129955292,
    y: 0.363113671541214
    },
    {
    x: 0.3514957129955292,
    y: 0.45741820335388184
    },
    {
    x: 0.2159184366464615,
    y: 0.45741820335388184
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8735821843147278,
    bounding_box: [
    {
    x: 0.5092038512229919,
    y: 0.09746468812227249
    },
    {
    x: 0.873028576374054,
    y: 0.09746468812227249
    },
    {
    x: 0.873028576374054,
    y: 0.8443921208381653
    },
    {
    x: 0.5092038512229919,
    y: 0.8443921208381653
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8213606476783752,
    bounding_box: [
    {
    x: 0.13003994524478912,
    y: 0.183829203248024
    },
    {
    x: 0.5031101107597351,
    y: 0.183829203248024
    },
    {
    x: 0.5031101107597351,
    y: 0.8511157035827637
    },
    {
    x: 0.13003994524478912,
    y: 0.8511157035827637
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7602583765983582,
    bounding_box: [
    {
    x: 0.509553074836731,
    y: 0.22596822679042816
    },
    {
    x: 0.8721031546592712,
    y: 0.22596822679042816
    },
    {
    x: 0.8721031546592712,
    y: 0.8328275680541992
    },
    {
    x: 0.509553074836731,
    y: 0.8328275680541992
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5099542737007141,
    bounding_box: [
    {
    x: 0.12238556891679764,
    y: 0.47699296474456787
    },
    {
    x: 0.5000048279762268,
    y: 0.47699296474456787
    },
    {
    x: 0.5000048279762268,
    y: 0.8428477644920349
    },
    {
    x: 0.12238556891679764,
    y: 0.8428477644920349
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41558,
    time_in_milliseconds: 1386651,
    labels: [
    {
    description: "News",
    confidence: 92.96567440032959
    },
    {
    description: "Media",
    confidence: 75.98154544830322
    },
    {
    description: "Glasses",
    confidence: 75.91426968574524
    },
    {
    description: "Eyewear",
    confidence: 68.63138675689697
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Photo caption",
    confidence: 61.63979768753052
    },
    {
    description: "Official",
    confidence: 59.97892618179321
    },
    {
    description: "Vision care",
    confidence: 51.88118815422058
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9417112469673157,
    bounding_box: [
    {
    x: 0.6126232147216797,
    y: 0.29450723528862
    },
    {
    x: 0.7749342322349548,
    y: 0.29450723528862
    },
    {
    x: 0.7749342322349548,
    y: 0.40058407187461853
    },
    {
    x: 0.6126232147216797,
    y: 0.40058407187461853
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9120683670043945,
    bounding_box: [
    {
    x: 0.22699160873889923,
    y: 0.3062445819377899
    },
    {
    x: 0.35530272126197815,
    y: 0.3062445819377899
    },
    {
    x: 0.35530272126197815,
    y: 0.39204132556915283
    },
    {
    x: 0.22699160873889923,
    y: 0.39204132556915283
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8330928087234497,
    bounding_box: [
    {
    x: 0.13940955698490143,
    y: 0.15754513442516327
    },
    {
    x: 0.5060599446296692,
    y: 0.15754513442516327
    },
    {
    x: 0.5060599446296692,
    y: 0.8537268042564392
    },
    {
    x: 0.13940955698490143,
    y: 0.8537268042564392
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7856925129890442,
    bounding_box: [
    {
    x: 0.49653592705726624,
    y: 0.09712556004524231
    },
    {
    x: 0.8712121844291687,
    y: 0.09712556004524231
    },
    {
    x: 0.8712121844291687,
    y: 0.8514697551727295
    },
    {
    x: 0.49653592705726624,
    y: 0.8514697551727295
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7092655301094055,
    bounding_box: [
    {
    x: 0.4937862157821655,
    y: 0.2455684095621109
    },
    {
    x: 0.870103657245636,
    y: 0.2455684095621109
    },
    {
    x: 0.870103657245636,
    y: 0.8345330357551575
    },
    {
    x: 0.4937862157821655,
    y: 0.8345330357551575
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 41906,
    time_in_milliseconds: 1398263.0000000002,
    labels: [
    {
    description: "News",
    confidence: 89.28080201148987
    },
    {
    description: "Glasses",
    confidence: 80.04875183105469
    },
    {
    description: "Facial hair",
    confidence: 73.0988085269928
    },
    {
    description: "Media",
    confidence: 71.27792239189148
    },
    {
    description: "Photo caption",
    confidence: 68.98387670516968
    },
    {
    description: "Eyewear",
    confidence: 68.63138675689697
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Beard",
    confidence: 55.145829916000366
    },
    {
    description: "Official",
    confidence: 52.653950452804565
    },
    {
    description: "Vision care",
    confidence: 51.88118815422058
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9453438520431519,
    bounding_box: [
    {
    x: 0.6258148550987244,
    y: 0.28928419947624207
    },
    {
    x: 0.7586405873298645,
    y: 0.28928419947624207
    },
    {
    x: 0.7586405873298645,
    y: 0.3804825246334076
    },
    {
    x: 0.6258148550987244,
    y: 0.3804825246334076
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9122527241706848,
    bounding_box: [
    {
    x: 0.22569729387760162,
    y: 0.3051499128341675
    },
    {
    x: 0.3534572124481201,
    y: 0.3051499128341675
    },
    {
    x: 0.3534572124481201,
    y: 0.3918379247188568
    },
    {
    x: 0.22569729387760162,
    y: 0.3918379247188568
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8576696515083313,
    bounding_box: [
    {
    x: 0.5089593529701233,
    y: 0.11285952478647232
    },
    {
    x: 0.8798236846923828,
    y: 0.11285952478647232
    },
    {
    x: 0.8798236846923828,
    y: 0.8583976626396179
    },
    {
    x: 0.5089593529701233,
    y: 0.8583976626396179
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8340829014778137,
    bounding_box: [
    {
    x: 0.13608214259147644,
    y: 0.15602563321590424
    },
    {
    x: 0.5006828308105469,
    y: 0.15602563321590424
    },
    {
    x: 0.5006828308105469,
    y: 0.8496527075767517
    },
    {
    x: 0.13608214259147644,
    y: 0.8496527075767517
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7333692312240601,
    bounding_box: [
    {
    x: 0.14268605411052704,
    y: 0.22104720771312714
    },
    {
    x: 0.5011264681816101,
    y: 0.22104720771312714
    },
    {
    x: 0.5011264681816101,
    y: 0.8349031805992126
    },
    {
    x: 0.14268605411052704,
    y: 0.8349031805992126
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6443972587585449,
    bounding_box: [
    {
    x: 0.5106000304222107,
    y: 0.20673789083957672
    },
    {
    x: 0.87298983335495,
    y: 0.20673789083957672
    },
    {
    x: 0.87298983335495,
    y: 0.8364822268486023
    },
    {
    x: 0.5106000304222107,
    y: 0.8364822268486023
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 42370,
    time_in_milliseconds: 1413745.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.6379861831665
    },
    {
    description: "Facial hair",
    confidence: 94.81169581413269
    },
    {
    description: "Beard",
    confidence: 92.26258397102356
    },
    {
    description: "Moustache",
    confidence: 90.77540040016174
    },
    {
    description: "Hairstyle",
    confidence: 89.83785510063171
    },
    {
    description: "Glasses",
    confidence: 88.20264935493469
    },
    {
    description: "Eyewear",
    confidence: 84.73531007766724
    },
    {
    description: "Outerwear",
    confidence: 73.502516746521
    },
    {
    description: "Houseplant",
    confidence: 72.49754667282104
    },
    {
    description: "Dreadlocks",
    confidence: 72.16598391532898
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9359352588653564,
    bounding_box: [
    {
    x: 0.48582983016967773,
    y: 0.2822124660015106
    },
    {
    x: 0.6541292667388916,
    y: 0.2822124660015106
    },
    {
    x: 0.6541292667388916,
    y: 0.3987596035003662
    },
    {
    x: 0.48582983016967773,
    y: 0.3987596035003662
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8048439025878906,
    bounding_box: [
    {
    x: 0.30917826294898987,
    y: 0.4190332889556885
    },
    {
    x: 0.882482647895813,
    y: 0.4190332889556885
    },
    {
    x: 0.882482647895813,
    y: 0.9958701133728027
    },
    {
    x: 0.30917826294898987,
    y: 0.9958701133728027
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7986230254173279,
    bounding_box: [
    {
    x: 0.3168685734272003,
    y: 0.06318992376327515
    },
    {
    x: 0.891899585723877,
    y: 0.06318992376327515
    },
    {
    x: 0.891899585723877,
    y: 0.9938254952430725
    },
    {
    x: 0.3168685734272003,
    y: 0.9938254952430725
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5558767914772034,
    bounding_box: [
    {
    x: 0.19960302114486694,
    y: 0.6372146606445312
    },
    {
    x: 0.3462654650211334,
    y: 0.6372146606445312
    },
    {
    x: 0.3462654650211334,
    y: 0.9238469004631042
    },
    {
    x: 0.19960302114486694,
    y: 0.9238469004631042
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 42457,
    time_in_milliseconds: 1416649,
    labels: [
    {
    description: "Eyewear",
    confidence: 98.6282229423523
    },
    {
    description: "Hair",
    confidence: 98.49514365196228
    },
    {
    description: "Glasses",
    confidence: 96.4924693107605
    },
    {
    description: "Photograph",
    confidence: 94.80249285697937
    },
    {
    description: "Facial expression",
    confidence: 94.43857669830322
    },
    {
    description: "Facial hair",
    confidence: 92.05658435821533
    },
    {
    description: "Cool",
    confidence: 89.92597460746765
    },
    {
    description: "Finger",
    confidence: 89.58792686462402
    },
    {
    description: "Hairstyle",
    confidence: 88.15163969993591
    },
    {
    description: "Snapshot",
    confidence: 85.75297594070435
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9248984456062317,
    bounding_box: [
    {
    x: 0.5033658146858215,
    y: 0.25357189774513245
    },
    {
    x: 0.6684765219688416,
    y: 0.25357189774513245
    },
    {
    x: 0.6684765219688416,
    y: 0.3668621778488159
    },
    {
    x: 0.5033658146858215,
    y: 0.3668621778488159
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7908815145492554,
    bounding_box: [
    {
    x: 0.23776757717132568,
    y: 0.05645881965756416
    },
    {
    x: 0.91023188829422,
    y: 0.05645881965756416
    },
    {
    x: 0.91023188829422,
    y: 0.9955737590789795
    },
    {
    x: 0.23776757717132568,
    y: 0.9955737590789795
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7114458084106445,
    bounding_box: [
    {
    x: 0.2672908902168274,
    y: 0.447196364402771
    },
    {
    x: 0.884016752243042,
    y: 0.447196364402771
    },
    {
    x: 0.884016752243042,
    y: 0.995229184627533
    },
    {
    x: 0.2672908902168274,
    y: 0.995229184627533
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 42515,
    time_in_milliseconds: 1418584,
    labels: [
    {
    description: "Hair",
    confidence: 98.9675760269165
    },
    {
    description: "Facial hair",
    confidence: 97.7966845035553
    },
    {
    description: "Beard",
    confidence: 96.04039788246155
    },
    {
    description: "Photograph",
    confidence: 94.80249285697937
    },
    {
    description: "Moustache",
    confidence: 94.46578025817871
    },
    {
    description: "Glasses",
    confidence: 92.96894073486328
    },
    {
    description: "Hairstyle",
    confidence: 92.77154803276062
    },
    {
    description: "Eyewear",
    confidence: 91.54864549636841
    },
    {
    description: "Dreadlocks",
    confidence: 89.49100375175476
    },
    {
    description: "Houseplant",
    confidence: 87.06194162368774
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9426396489143372,
    bounding_box: [
    {
    x: 0.4982527494430542,
    y: 0.2788250148296356
    },
    {
    x: 0.6797686219215393,
    y: 0.2788250148296356
    },
    {
    x: 0.6797686219215393,
    y: 0.39968040585517883
    },
    {
    x: 0.4982527494430542,
    y: 0.39968040585517883
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8598232865333557,
    bounding_box: [
    {
    x: 0.31791630387306213,
    y: 0.45774999260902405
    },
    {
    x: 0.9001257419586182,
    y: 0.45774999260902405
    },
    {
    x: 0.9001257419586182,
    y: 0.9936950206756592
    },
    {
    x: 0.31791630387306213,
    y: 0.9936950206756592
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7539899349212646,
    bounding_box: [
    {
    x: 0.31803128123283386,
    y: 0.03728076443076134
    },
    {
    x: 0.9021725654602051,
    y: 0.03728076443076134
    },
    {
    x: 0.9021725654602051,
    y: 0.990367591381073
    },
    {
    x: 0.31803128123283386,
    y: 0.990367591381073
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6680598855018616,
    bounding_box: [
    {
    x: 0.199623703956604,
    y: 0.6370218396186829
    },
    {
    x: 0.3452347218990326,
    y: 0.6370218396186829
    },
    {
    x: 0.3452347218990326,
    y: 0.9184238314628601
    },
    {
    x: 0.199623703956604,
    y: 0.9184238314628601
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.592452347278595,
    bounding_box: [
    {
    x: 0.7219794392585754,
    y: 0
    },
    {
    x: 0.9958066344261169,
    y: 0
    },
    {
    x: 0.9958066344261169,
    y: 0.7334301471710205
    },
    {
    x: 0.7219794392585754,
    y: 0.7334301471710205
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 42544,
    time_in_milliseconds: 1419551.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.66371750831604
    },
    {
    description: "Facial hair",
    confidence: 97.88421392440796
    },
    {
    description: "Beard",
    confidence: 95.81626653671265
    },
    {
    description: "Moustache",
    confidence: 94.36439275741577
    },
    {
    description: "Glasses",
    confidence: 93.54138970375061
    },
    {
    description: "Eyewear",
    confidence: 92.08218455314636
    },
    {
    description: "Hairstyle",
    confidence: 88.48372101783752
    },
    {
    description: "Houseplant",
    confidence: 84.33679938316345
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Dreadlocks",
    confidence: 81.0230016708374
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9447280168533325,
    bounding_box: [
    {
    x: 0.5025814175605774,
    y: 0.2626456022262573
    },
    {
    x: 0.6642962098121643,
    y: 0.2626456022262573
    },
    {
    x: 0.6642962098121643,
    y: 0.3724135458469391
    },
    {
    x: 0.5025814175605774,
    y: 0.3724135458469391
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8617280721664429,
    bounding_box: [
    {
    x: 0.3227304518222809,
    y: 0.46470507979393005
    },
    {
    x: 0.8867744207382202,
    y: 0.46470507979393005
    },
    {
    x: 0.8867744207382202,
    y: 0.9953553676605225
    },
    {
    x: 0.3227304518222809,
    y: 0.9953553676605225
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8106138706207275,
    bounding_box: [
    {
    x: 0.3218561112880707,
    y: 0.05589556694030762
    },
    {
    x: 0.8984462022781372,
    y: 0.05589556694030762
    },
    {
    x: 0.8984462022781372,
    y: 0.9917774200439453
    },
    {
    x: 0.3218561112880707,
    y: 0.9917774200439453
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6414591073989868,
    bounding_box: [
    {
    x: 0.1985977292060852,
    y: 0.6367180943489075
    },
    {
    x: 0.3461410105228424,
    y: 0.6367180943489075
    },
    {
    x: 0.3461410105228424,
    y: 0.9241756796836853
    },
    {
    x: 0.1985977292060852,
    y: 0.9241756796836853
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 42631,
    time_in_milliseconds: 1422454.0000000002,
    labels: [
    {
    description: "Eyewear",
    confidence: 98.7920880317688
    },
    {
    description: "Hair",
    confidence: 98.17695617675781
    },
    {
    description: "Glasses",
    confidence: 95.84338665008545
    },
    {
    description: "Photograph",
    confidence: 95.1403021812439
    },
    {
    description: "Facial hair",
    confidence: 94.9847936630249
    },
    {
    description: "Moustache",
    confidence: 88.82724046707153
    },
    {
    description: "Beard",
    confidence: 88.15127611160278
    },
    {
    description: "Vision care",
    confidence: 86.1897349357605
    },
    {
    description: "Snapshot",
    confidence: 84.7389817237854
    },
    {
    description: "Hairstyle",
    confidence: 84.23047661781311
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9340390563011169,
    bounding_box: [
    {
    x: 0.5044524073600769,
    y: 0.24902868270874023
    },
    {
    x: 0.6842150092124939,
    y: 0.24902868270874023
    },
    {
    x: 0.6842150092124939,
    y: 0.36525264382362366
    },
    {
    x: 0.5044524073600769,
    y: 0.36525264382362366
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8128741383552551,
    bounding_box: [
    {
    x: 0.21745353937149048,
    y: 0.06903448700904846
    },
    {
    x: 0.9504675269126892,
    y: 0.06903448700904846
    },
    {
    x: 0.9504675269126892,
    y: 0.9965487718582153
    },
    {
    x: 0.21745353937149048,
    y: 0.9965487718582153
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.772283136844635,
    bounding_box: [
    {
    x: 0.22167079150676727,
    y: 0.45633769035339355
    },
    {
    x: 0.9425422549247742,
    y: 0.45633769035339355
    },
    {
    x: 0.9425422549247742,
    y: 0.9928092360496521
    },
    {
    x: 0.22167079150676727,
    y: 0.9928092360496521
    }
    ]
    },
    {
    description: "Watch",
    confidence: 0.5536569356918335,
    bounding_box: [
    {
    x: 0.8284232020378113,
    y: 0.8347861766815186
    },
    {
    x: 0.9038010239601135,
    y: 0.8347861766815186
    },
    {
    x: 0.9038010239601135,
    y: 0.9470458626747131
    },
    {
    x: 0.8284232020378113,
    y: 0.9470458626747131
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5062771439552307,
    bounding_box: [
    {
    x: 0.7188745141029358,
    y: 0
    },
    {
    x: 0.9940603375434875,
    y: 0
    },
    {
    x: 0.9940603375434875,
    y: 0.7675605416297913
    },
    {
    x: 0.7188745141029358,
    y: 0.7675605416297913
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 42747,
    time_in_milliseconds: 1426324,
    labels: [
    {
    description: "Hair",
    confidence: 98.27492833137512
    },
    {
    description: "Eyewear",
    confidence: 96.59837484359741
    },
    {
    description: "Facial hair",
    confidence: 95.44806480407715
    },
    {
    description: "Glasses",
    confidence: 94.98130083084106
    },
    {
    description: "Beard",
    confidence: 91.99665188789368
    },
    {
    description: "Moustache",
    confidence: 89.30952548980713
    },
    {
    description: "Hairstyle",
    confidence: 87.11950778961182
    },
    {
    description: "Vision care",
    confidence: 78.53339314460754
    },
    {
    description: "Houseplant",
    confidence: 76.69059634208679
    },
    {
    description: "Smile",
    confidence: 69.44891214370728
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9347206950187683,
    bounding_box: [
    {
    x: 0.49438032507896423,
    y: 0.26285722851753235
    },
    {
    x: 0.6628278493881226,
    y: 0.26285722851753235
    },
    {
    x: 0.6628278493881226,
    y: 0.38434484601020813
    },
    {
    x: 0.49438032507896423,
    y: 0.38434484601020813
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8425459265708923,
    bounding_box: [
    {
    x: 0.2550509572029114,
    y: 0.44120466709136963
    },
    {
    x: 0.8819505572319031,
    y: 0.44120466709136963
    },
    {
    x: 0.8819505572319031,
    y: 0.9973958134651184
    },
    {
    x: 0.2550509572029114,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7986862659454346,
    bounding_box: [
    {
    x: 0.13389600813388824,
    y: 0.04896780848503113
    },
    {
    x: 0.8854789733886719,
    y: 0.04896780848503113
    },
    {
    x: 0.8854789733886719,
    y: 0.9944584369659424
    },
    {
    x: 0.13389600813388824,
    y: 0.9944584369659424
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7811431288719177,
    bounding_box: [
    {
    x: 0.15942977368831635,
    y: 0.03965787962079048
    },
    {
    x: 0.8859658241271973,
    y: 0.03965787962079048
    },
    {
    x: 0.8859658241271973,
    y: 0.9854876399040222
    },
    {
    x: 0.15942977368831635,
    y: 0.9854876399040222
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 42921,
    time_in_milliseconds: 1432130,
    labels: [
    {
    description: "Hair",
    confidence: 97.81931042671204
    },
    {
    description: "Facial hair",
    confidence: 93.99965405464172
    },
    {
    description: "Eyewear",
    confidence: 91.54864549636841
    },
    {
    description: "Beard",
    confidence: 88.76736760139465
    },
    {
    description: "Finger",
    confidence: 86.34986281394958
    },
    {
    description: "Glasses",
    confidence: 83.378666639328
    },
    {
    description: "Moustache",
    confidence: 79.224693775177
    },
    {
    description: "Sign language",
    confidence: 76.67648792266846
    },
    {
    description: "Thumb",
    confidence: 74.681156873703
    },
    {
    description: "Vision care",
    confidence: 73.88024926185608
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9198893904685974,
    bounding_box: [
    {
    x: 0.5111214518547058,
    y: 0.2458662986755371
    },
    {
    x: 0.6807418465614319,
    y: 0.2458662986755371
    },
    {
    x: 0.6807418465614319,
    y: 0.362933874130249
    },
    {
    x: 0.5111214518547058,
    y: 0.362933874130249
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7919966578483582,
    bounding_box: [
    {
    x: 0.18704305589199066,
    y: 0.060752853751182556
    },
    {
    x: 0.9097974896430969,
    y: 0.060752853751182556
    },
    {
    x: 0.9097974896430969,
    y: 0.9892130494117737
    },
    {
    x: 0.18704305589199066,
    y: 0.9892130494117737
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7770845293998718,
    bounding_box: [
    {
    x: 0.2255888432264328,
    y: 0.45142149925231934
    },
    {
    x: 0.8799495697021484,
    y: 0.45142149925231934
    },
    {
    x: 0.8799495697021484,
    y: 0.9970283508300781
    },
    {
    x: 0.2255888432264328,
    y: 0.9970283508300781
    }
    ]
    },
    {
    description: "Watch",
    confidence: 0.6873491406440735,
    bounding_box: [
    {
    x: 0.8277872204780579,
    y: 0.8332559466362
    },
    {
    x: 0.9034667015075684,
    y: 0.8332559466362
    },
    {
    x: 0.9034667015075684,
    y: 0.946891725063324
    },
    {
    x: 0.8277872204780579,
    y: 0.946891725063324
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 42979,
    time_in_milliseconds: 1434065,
    labels: [
    {
    description: "Hair",
    confidence: 96.95512652397156
    },
    {
    description: "Eyewear",
    confidence: 96.48454785346985
    },
    {
    description: "Facial hair",
    confidence: 89.54910635948181
    },
    {
    description: "Finger",
    confidence: 87.41403818130493
    },
    {
    description: "Glasses",
    confidence: 86.94331049919128
    },
    {
    description: "Sign language",
    confidence: 79.40428256988525
    },
    {
    description: "Thumb",
    confidence: 76.04182958602905
    },
    {
    description: "Beard",
    confidence: 75.24787187576294
    },
    {
    description: "Vision care",
    confidence: 72.36137390136719
    },
    {
    description: "Moustache",
    confidence: 71.65217995643616
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9140640497207642,
    bounding_box: [
    {
    x: 0.5011574029922485,
    y: 0.2509557008743286
    },
    {
    x: 0.6730473041534424,
    y: 0.2509557008743286
    },
    {
    x: 0.6730473041534424,
    y: 0.36878836154937744
    },
    {
    x: 0.5011574029922485,
    y: 0.36878836154937744
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.790730893611908,
    bounding_box: [
    {
    x: 0.2265501469373703,
    y: 0.04898766055703163
    },
    {
    x: 0.925409734249115,
    y: 0.04898766055703163
    },
    {
    x: 0.925409734249115,
    y: 0.9955353140830994
    },
    {
    x: 0.2265501469373703,
    y: 0.9955353140830994
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6598996520042419,
    bounding_box: [
    {
    x: 0.265084832906723,
    y: 0.44641879200935364
    },
    {
    x: 0.8688066005706787,
    y: 0.44641879200935364
    },
    {
    x: 0.8688066005706787,
    y: 0.9922189712524414
    },
    {
    x: 0.265084832906723,
    y: 0.9922189712524414
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 43153,
    time_in_milliseconds: 1439872,
    labels: [
    {
    description: "Hair",
    confidence: 98.78031611442566
    },
    {
    description: "Facial hair",
    confidence: 98.15548062324524
    },
    {
    description: "Beard",
    confidence: 97.11419939994812
    },
    {
    description: "Moustache",
    confidence: 94.71114873886108
    },
    {
    description: "Glasses",
    confidence: 90.58366417884827
    },
    {
    description: "Eyewear",
    confidence: 90.26086330413818
    },
    {
    description: "Hairstyle",
    confidence: 89.41669464111328
    },
    {
    description: "Snapshot",
    confidence: 82.03043341636658
    },
    {
    description: "Dreadlocks",
    confidence: 79.31421995162964
    },
    {
    description: "Houseplant",
    confidence: 79.05795574188232
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9295265674591064,
    bounding_box: [
    {
    x: 0.50180584192276,
    y: 0.2554819881916046
    },
    {
    x: 0.6739590167999268,
    y: 0.2554819881916046
    },
    {
    x: 0.6739590167999268,
    y: 0.37514829635620117
    },
    {
    x: 0.50180584192276,
    y: 0.37514829635620117
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8490601778030396,
    bounding_box: [
    {
    x: 0.32656922936439514,
    y: 0.4547032117843628
    },
    {
    x: 0.9112176895141602,
    y: 0.4547032117843628
    },
    {
    x: 0.9112176895141602,
    y: 0.9939191937446594
    },
    {
    x: 0.32656922936439514,
    y: 0.9939191937446594
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7835860848426819,
    bounding_box: [
    {
    x: 0.319052129983902,
    y: 0.04689379408955574
    },
    {
    x: 0.9067362546920776,
    y: 0.04689379408955574
    },
    {
    x: 0.9067362546920776,
    y: 0.9927082657814026
    },
    {
    x: 0.319052129983902,
    y: 0.9927082657814026
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5134983658790588,
    bounding_box: [
    {
    x: 0.1979496031999588,
    y: 0.6368363499641418
    },
    {
    x: 0.348093181848526,
    y: 0.6368363499641418
    },
    {
    x: 0.348093181848526,
    y: 0.9221770167350769
    },
    {
    x: 0.1979496031999588,
    y: 0.9221770167350769
    }
    ]
    },
    {
    description: "Chest of drawers",
    confidence: 0.5119795799255371,
    bounding_box: [
    {
    x: 0.0009208009578287601,
    y: 0.7047728896141052
    },
    {
    x: 0.12183178216218948,
    y: 0.7047728896141052
    },
    {
    x: 0.12183178216218948,
    y: 0.9973958134651184
    },
    {
    x: 0.0009208009578287601,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5050327777862549,
    bounding_box: [
    {
    x: 0.04596421495079994,
    y: 0.43155720829963684
    },
    {
    x: 0.10858339071273804,
    y: 0.43155720829963684
    },
    {
    x: 0.10858339071273804,
    y: 0.711327075958252
    },
    {
    x: 0.04596421495079994,
    y: 0.711327075958252
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 43240,
    time_in_milliseconds: 1442774,
    labels: [
    {
    description: "Hair",
    confidence: 98.70781302452087
    },
    {
    description: "Eyewear",
    confidence: 97.38759398460388
    },
    {
    description: "Facial hair",
    confidence: 97.14186787605286
    },
    {
    description: "Beard",
    confidence: 96.25557065010071
    },
    {
    description: "Glasses",
    confidence: 93.22423934936523
    },
    {
    description: "Moustache",
    confidence: 92.05036759376526
    },
    {
    description: "Hairstyle",
    confidence: 89.3006443977356
    },
    {
    description: "Afro",
    confidence: 77.87261009216309
    },
    {
    description: "Dreadlocks",
    confidence: 76.66683793067932
    },
    {
    description: "Vision care",
    confidence: 69.37032341957092
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9195610284805298,
    bounding_box: [
    {
    x: 0.459573894739151,
    y: 0.2647855281829834
    },
    {
    x: 0.6278409361839294,
    y: 0.2647855281829834
    },
    {
    x: 0.6278409361839294,
    y: 0.41810402274131775
    },
    {
    x: 0.459573894739151,
    y: 0.41810402274131775
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.752307116985321,
    bounding_box: [
    {
    x: 0.28637585043907166,
    y: 0.4198768436908722
    },
    {
    x: 0.8926565647125244,
    y: 0.4198768436908722
    },
    {
    x: 0.8926565647125244,
    y: 0.9911540746688843
    },
    {
    x: 0.28637585043907166,
    y: 0.9911540746688843
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7054162621498108,
    bounding_box: [
    {
    x: 0.2618519067764282,
    y: 0.057344112545251846
    },
    {
    x: 0.9424179196357727,
    y: 0.057344112545251846
    },
    {
    x: 0.9424179196357727,
    y: 0.9910582900047302
    },
    {
    x: 0.2618519067764282,
    y: 0.9910582900047302
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.556640088558197,
    bounding_box: [
    {
    x: 0.20362158119678497,
    y: 0.639354407787323
    },
    {
    x: 0.3424626290798187,
    y: 0.639354407787323
    },
    {
    x: 0.3424626290798187,
    y: 0.9082661271095276
    },
    {
    x: 0.20362158119678497,
    y: 0.9082661271095276
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 43327,
    time_in_milliseconds: 1445677.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.41673970222473
    },
    {
    description: "Facial hair",
    confidence: 97.10846543312073
    },
    {
    description: "Beard",
    confidence: 95.08129954338074
    },
    {
    description: "Moustache",
    confidence: 92.77341961860657
    },
    {
    description: "Glasses",
    confidence: 91.52262210845947
    },
    {
    description: "Houseplant",
    confidence: 90.77382683753967
    },
    {
    description: "Hairstyle",
    confidence: 87.35474944114685
    },
    {
    description: "Eyewear",
    confidence: 80.6023359298706
    },
    {
    description: "Smile",
    confidence: 78.8342833518982
    },
    {
    description: "Dreadlocks",
    confidence: 76.94332599639893
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9454119205474854,
    bounding_box: [
    {
    x: 0.5197083950042725,
    y: 0.2421199232339859
    },
    {
    x: 0.6951358318328857,
    y: 0.2421199232339859
    },
    {
    x: 0.6951358318328857,
    y: 0.36230000853538513
    },
    {
    x: 0.5197083950042725,
    y: 0.36230000853538513
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7943121790885925,
    bounding_box: [
    {
    x: 0.28373903036117554,
    y: 0.04456453397870064
    },
    {
    x: 0.9312040209770203,
    y: 0.04456453397870064
    },
    {
    x: 0.9312040209770203,
    y: 0.9884078502655029
    },
    {
    x: 0.28373903036117554,
    y: 0.9884078502655029
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7586933374404907,
    bounding_box: [
    {
    x: 0.2654721140861511,
    y: 0.4479545056819916
    },
    {
    x: 0.9365891814231873,
    y: 0.4479545056819916
    },
    {
    x: 0.9365891814231873,
    y: 0.9941070079803467
    },
    {
    x: 0.2654721140861511,
    y: 0.9941070079803467
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5360639691352844,
    bounding_box: [
    {
    x: 0.04570934176445007,
    y: 0.43298566341400146
    },
    {
    x: 0.1090918704867363,
    y: 0.43298566341400146
    },
    {
    x: 0.1090918704867363,
    y: 0.711076021194458
    },
    {
    x: 0.04570934176445007,
    y: 0.711076021194458
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5197094082832336,
    bounding_box: [
    {
    x: 0.732546865940094,
    y: 0
    },
    {
    x: 0.9970948696136475,
    y: 0
    },
    {
    x: 0.9970948696136475,
    y: 0.7218353748321533
    },
    {
    x: 0.732546865940094,
    y: 0.7218353748321533
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 43356,
    time_in_milliseconds: 1446645,
    labels: [
    {
    description: "Hair",
    confidence: 98.83031845092773
    },
    {
    description: "Facial hair",
    confidence: 97.86279797554016
    },
    {
    description: "Beard",
    confidence: 96.08412384986877
    },
    {
    description: "Moustache",
    confidence: 93.10405254364014
    },
    {
    description: "Hairstyle",
    confidence: 90.7419741153717
    },
    {
    description: "Glasses",
    confidence: 90.1199221611023
    },
    {
    description: "Eyewear",
    confidence: 84.73531007766724
    },
    {
    description: "Dreadlocks",
    confidence: 80.30431270599365
    },
    {
    description: "Houseplant",
    confidence: 77.2621214389801
    },
    {
    description: "Afro",
    confidence: 69.18997764587402
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9368576407432556,
    bounding_box: [
    {
    x: 0.48555788397789,
    y: 0.2697736918926239
    },
    {
    x: 0.6547510623931885,
    y: 0.2697736918926239
    },
    {
    x: 0.6547510623931885,
    y: 0.38947129249572754
    },
    {
    x: 0.48555788397789,
    y: 0.38947129249572754
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8581352829933167,
    bounding_box: [
    {
    x: 0.33380281925201416,
    y: 0.4590916931629181
    },
    {
    x: 0.8878107666969299,
    y: 0.4590916931629181
    },
    {
    x: 0.8878107666969299,
    y: 0.9964773654937744
    },
    {
    x: 0.33380281925201416,
    y: 0.9964773654937744
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7942247986793518,
    bounding_box: [
    {
    x: 0.3277509808540344,
    y: 0.05499476194381714
    },
    {
    x: 0.8962879180908203,
    y: 0.05499476194381714
    },
    {
    x: 0.8962879180908203,
    y: 0.9933686852455139
    },
    {
    x: 0.3277509808540344,
    y: 0.9933686852455139
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5438017249107361,
    bounding_box: [
    {
    x: 0.1983310431241989,
    y: 0.6368390917778015
    },
    {
    x: 0.3475534915924072,
    y: 0.6368390917778015
    },
    {
    x: 0.3475534915924072,
    y: 0.9252082705497742
    },
    {
    x: 0.1983310431241989,
    y: 0.9252082705497742
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 43385,
    time_in_milliseconds: 1447613,
    labels: [
    {
    description: "News",
    confidence: 89.84020352363586
    },
    {
    description: "Facial hair",
    confidence: 75.05148649215698
    },
    {
    description: "Media",
    confidence: 68.0826723575592
    },
    {
    description: "Glasses",
    confidence: 67.38030314445496
    },
    {
    description: "Photo caption",
    confidence: 64.09758925437927
    },
    {
    description: "Beard",
    confidence: 55.145829916000366
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9366788864135742,
    bounding_box: [
    {
    x: 0.6229837536811829,
    y: 0.2949601709842682
    },
    {
    x: 0.7562334537506104,
    y: 0.2949601709842682
    },
    {
    x: 0.7562334537506104,
    y: 0.38979288935661316
    },
    {
    x: 0.6229837536811829,
    y: 0.38979288935661316
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9356823563575745,
    bounding_box: [
    {
    x: 0.2315642237663269,
    y: 0.3278619050979614
    },
    {
    x: 0.36731377243995667,
    y: 0.3278619050979614
    },
    {
    x: 0.36731377243995667,
    y: 0.4233747720718384
    },
    {
    x: 0.2315642237663269,
    y: 0.4233747720718384
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.854784369468689,
    bounding_box: [
    {
    x: 0.5062561631202698,
    y: 0.10893253237009048
    },
    {
    x: 0.8759691715240479,
    y: 0.10893253237009048
    },
    {
    x: 0.8759691715240479,
    y: 0.8599838614463806
    },
    {
    x: 0.5062561631202698,
    y: 0.8599838614463806
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8260731101036072,
    bounding_box: [
    {
    x: 0.14014895260334015,
    y: 0.14392541348934174
    },
    {
    x: 0.5091319680213928,
    y: 0.14392541348934174
    },
    {
    x: 0.5091319680213928,
    y: 0.8612151741981506
    },
    {
    x: 0.14014895260334015,
    y: 0.8612151741981506
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6754144430160522,
    bounding_box: [
    {
    x: 0.5073394775390625,
    y: 0.23727911710739136
    },
    {
    x: 0.8746302723884583,
    y: 0.23727911710739136
    },
    {
    x: 0.8746302723884583,
    y: 0.8326951861381531
    },
    {
    x: 0.5073394775390625,
    y: 0.8326951861381531
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5457379817962646,
    bounding_box: [
    {
    x: 0.1411166936159134,
    y: 0.16769754886627197
    },
    {
    x: 0.5058942437171936,
    y: 0.16769754886627197
    },
    {
    x: 0.5058942437171936,
    y: 0.84145188331604
    },
    {
    x: 0.1411166936159134,
    y: 0.84145188331604
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 43588,
    time_in_milliseconds: 1454386,
    labels: [
    {
    description: "Hair",
    confidence: 98.51848483085632
    },
    {
    description: "Facial hair",
    confidence: 95.51810026168823
    },
    {
    description: "Beard",
    confidence: 91.81267023086548
    },
    {
    description: "Glasses",
    confidence: 91.52262210845947
    },
    {
    description: "Eyewear",
    confidence: 90.94614386558533
    },
    {
    description: "Hairstyle",
    confidence: 88.15163969993591
    },
    {
    description: "Moustache",
    confidence: 87.89899945259094
    },
    {
    description: "Dreadlocks",
    confidence: 87.60700225830078
    },
    {
    description: "Houseplant",
    confidence: 84.61738228797913
    },
    {
    description: "Smile",
    confidence: 77.31727361679077
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9360243678092957,
    bounding_box: [
    {
    x: 0.49981698393821716,
    y: 0.28192010521888733
    },
    {
    x: 0.6724934577941895,
    y: 0.28192010521888733
    },
    {
    x: 0.6724934577941895,
    y: 0.40534064173698425
    },
    {
    x: 0.49981698393821716,
    y: 0.40534064173698425
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8465743660926819,
    bounding_box: [
    {
    x: 0.3224841058254242,
    y: 0.45461809635162354
    },
    {
    x: 0.9081000089645386,
    y: 0.45461809635162354
    },
    {
    x: 0.9081000089645386,
    y: 0.9935508370399475
    },
    {
    x: 0.3224841058254242,
    y: 0.9935508370399475
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7429996132850647,
    bounding_box: [
    {
    x: 0.3078310191631317,
    y: 0.059422314167022705
    },
    {
    x: 0.9031500816345215,
    y: 0.059422314167022705
    },
    {
    x: 0.9031500816345215,
    y: 0.9973958134651184
    },
    {
    x: 0.3078310191631317,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5657835602760315,
    bounding_box: [
    {
    x: 0.19762523472309113,
    y: 0.6361699104309082
    },
    {
    x: 0.34894803166389465,
    y: 0.6361699104309082
    },
    {
    x: 0.34894803166389465,
    y: 0.9280975461006165
    },
    {
    x: 0.19762523472309113,
    y: 0.9280975461006165
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 44023,
    time_in_milliseconds: 1468900,
    labels: [
    {
    description: "Hair",
    confidence: 98.39476346969604
    },
    {
    description: "Facial hair",
    confidence: 94.81169581413269
    },
    {
    description: "Eyewear",
    confidence: 94.58502531051636
    },
    {
    description: "Glasses",
    confidence: 92.20112562179565
    },
    {
    description: "Beard",
    confidence: 91.52594804763794
    },
    {
    description: "Hairstyle",
    confidence: 88.15163969993591
    },
    {
    description: "Moustache",
    confidence: 87.0031476020813
    },
    {
    description: "Houseplant",
    confidence: 81.38628602027893
    },
    {
    description: "Vision care",
    confidence: 75.14652609825134
    },
    {
    description: "Afro",
    confidence: 71.7419683933258
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9232946634292603,
    bounding_box: [
    {
    x: 0.5093446969985962,
    y: 0.2631278932094574
    },
    {
    x: 0.687093198299408,
    y: 0.2631278932094574
    },
    {
    x: 0.687093198299408,
    y: 0.38492634892463684
    },
    {
    x: 0.5093446969985962,
    y: 0.38492634892463684
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.716018557548523,
    bounding_box: [
    {
    x: 0.10537157207727432,
    y: 0.04952588677406311
    },
    {
    x: 0.9556459784507751,
    y: 0.04952588677406311
    },
    {
    x: 0.9556459784507751,
    y: 0.9913287162780762
    },
    {
    x: 0.10537157207727432,
    y: 0.9913287162780762
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6245538592338562,
    bounding_box: [
    {
    x: 0.23121774196624756,
    y: 0.44003570079803467
    },
    {
    x: 0.9189253449440002,
    y: 0.44003570079803467
    },
    {
    x: 0.9189253449440002,
    y: 0.9961426258087158
    },
    {
    x: 0.23121774196624756,
    y: 0.9961426258087158
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 44052,
    time_in_milliseconds: 1469868.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.15207719802856
    },
    {
    description: "Eyewear",
    confidence: 94.58502531051636
    },
    {
    description: "Facial hair",
    confidence: 93.50277781486511
    },
    {
    description: "Glasses",
    confidence: 92.30489730834961
    },
    {
    description: "Beard",
    confidence: 89.05680179595947
    },
    {
    description: "Hairstyle",
    confidence: 87.11950778961182
    },
    {
    description: "Moustache",
    confidence: 81.74706697463989
    },
    {
    description: "Vision care",
    confidence: 79.20454740524292
    },
    {
    description: "T-shirt",
    confidence: 75.82082152366638
    },
    {
    description: "Finger",
    confidence: 67.49656200408936
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9248440861701965,
    bounding_box: [
    {
    x: 0.5262344479560852,
    y: 0.28364965319633484
    },
    {
    x: 0.7051674723625183,
    y: 0.28364965319633484
    },
    {
    x: 0.7051674723625183,
    y: 0.4259714186191559
    },
    {
    x: 0.5262344479560852,
    y: 0.4259714186191559
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.81471848487854,
    bounding_box: [
    {
    x: 0.25061410665512085,
    y: 0.45069751143455505
    },
    {
    x: 0.8859038352966309,
    y: 0.45069751143455505
    },
    {
    x: 0.8859038352966309,
    y: 0.9964151382446289
    },
    {
    x: 0.25061410665512085,
    y: 0.9964151382446289
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8104451298713684,
    bounding_box: [
    {
    x: 0.12014609575271606,
    y: 0.05198034644126892
    },
    {
    x: 0.9677979946136475,
    y: 0.05198034644126892
    },
    {
    x: 0.9677979946136475,
    y: 0.9935606718063354
    },
    {
    x: 0.12014609575271606,
    y: 0.9935606718063354
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7294890284538269,
    bounding_box: [
    {
    x: 0.12994404137134552,
    y: 0.05955863371491432
    },
    {
    x: 0.9426515102386475,
    y: 0.05955863371491432
    },
    {
    x: 0.9426515102386475,
    y: 0.9973958134651184
    },
    {
    x: 0.12994404137134552,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Lighting",
    confidence: 0.5033732652664185,
    bounding_box: [
    {
    x: 0.04504741355776787,
    y: 0.4327852725982666
    },
    {
    x: 0.10763244330883026,
    y: 0.4327852725982666
    },
    {
    x: 0.10763244330883026,
    y: 0.7006164193153381
    },
    {
    x: 0.04504741355776787,
    y: 0.7006164193153381
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 44110,
    time_in_milliseconds: 1471803,
    labels: [
    {
    description: "Hair",
    confidence: 98.79393577575684
    },
    {
    description: "Facial hair",
    confidence: 97.03981280326843
    },
    {
    description: "Beard",
    confidence: 94.81201767921448
    },
    {
    description: "Photograph",
    confidence: 94.80249285697937
    },
    {
    description: "Moustache",
    confidence: 91.31665229797363
    },
    {
    description: "Hairstyle",
    confidence: 90.28685688972473
    },
    {
    description: "Glasses",
    confidence: 89.95565176010132
    },
    {
    description: "Eyewear",
    confidence: 89.4751250743866
    },
    {
    description: "Dreadlocks",
    confidence: 83.4962785243988
    },
    {
    description: "Snapshot",
    confidence: 82.91832208633423
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9450494050979614,
    bounding_box: [
    {
    x: 0.49035659432411194,
    y: 0.2894626259803772
    },
    {
    x: 0.6662005186080933,
    y: 0.2894626259803772
    },
    {
    x: 0.6662005186080933,
    y: 0.4039839804172516
    },
    {
    x: 0.49035659432411194,
    y: 0.4039839804172516
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8376424908638,
    bounding_box: [
    {
    x: 0.3234019875526428,
    y: 0.457958459854126
    },
    {
    x: 0.8979723453521729,
    y: 0.457958459854126
    },
    {
    x: 0.8979723453521729,
    y: 0.9949593544006348
    },
    {
    x: 0.3234019875526428,
    y: 0.9949593544006348
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7847093939781189,
    bounding_box: [
    {
    x: 0.3238653242588043,
    y: 0.04905067756772041
    },
    {
    x: 0.9057360887527466,
    y: 0.04905067756772041
    },
    {
    x: 0.9057360887527466,
    y: 0.9892389178276062
    },
    {
    x: 0.3238653242588043,
    y: 0.9892389178276062
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6444255709648132,
    bounding_box: [
    {
    x: 0.19876998662948608,
    y: 0.6383205056190491
    },
    {
    x: 0.34526774287223816,
    y: 0.6383205056190491
    },
    {
    x: 0.34526774287223816,
    y: 0.9270625710487366
    },
    {
    x: 0.19876998662948608,
    y: 0.9270625710487366
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6331459879875183,
    bounding_box: [
    {
    x: 0.3121206760406494,
    y: 0.060923799872398376
    },
    {
    x: 0.8922513127326965,
    y: 0.060923799872398376
    },
    {
    x: 0.8922513127326965,
    y: 0.9951480031013489
    },
    {
    x: 0.3121206760406494,
    y: 0.9951480031013489
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5308942794799805,
    bounding_box: [
    {
    x: 0.7201413512229919,
    y: 0
    },
    {
    x: 0.9947540163993835,
    y: 0
    },
    {
    x: 0.9947540163993835,
    y: 0.7426872849464417
    },
    {
    x: 0.7201413512229919,
    y: 0.7426872849464417
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 44313,
    time_in_milliseconds: 1478577,
    labels: [
    {
    description: "News",
    confidence: 86.37343645095825
    },
    {
    description: "Glasses",
    confidence: 81.30679726600647
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Facial hair",
    confidence: 64.79135155677795
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Media",
    confidence: 60.72695255279541
    },
    {
    description: "Photo caption",
    confidence: 58.299434185028076
    },
    {
    description: "Vision care",
    confidence: 56.652915477752686
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9395923018455505,
    bounding_box: [
    {
    x: 0.6374248266220093,
    y: 0.2915389835834503
    },
    {
    x: 0.7757517695426941,
    y: 0.2915389835834503
    },
    {
    x: 0.7757517695426941,
    y: 0.3917368948459625
    },
    {
    x: 0.6374248266220093,
    y: 0.3917368948459625
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9325489401817322,
    bounding_box: [
    {
    x: 0.21362662315368652,
    y: 0.31790828704833984
    },
    {
    x: 0.3525294363498688,
    y: 0.31790828704833984
    },
    {
    x: 0.3525294363498688,
    y: 0.404666930437088
    },
    {
    x: 0.21362662315368652,
    y: 0.404666930437088
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.831011176109314,
    bounding_box: [
    {
    x: 0.5074970126152039,
    y: 0.1071864441037178
    },
    {
    x: 0.8744380474090576,
    y: 0.1071864441037178
    },
    {
    x: 0.8744380474090576,
    y: 0.8555564880371094
    },
    {
    x: 0.5074970126152039,
    y: 0.8555564880371094
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8168156743049622,
    bounding_box: [
    {
    x: 0.1313353031873703,
    y: 0.13682430982589722
    },
    {
    x: 0.5050671696662903,
    y: 0.13682430982589722
    },
    {
    x: 0.5050671696662903,
    y: 0.8582341074943542
    },
    {
    x: 0.1313353031873703,
    y: 0.8582341074943542
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.720255434513092,
    bounding_box: [
    {
    x: 0.5090966820716858,
    y: 0.2772550880908966
    },
    {
    x: 0.873583972454071,
    y: 0.2772550880908966
    },
    {
    x: 0.873583972454071,
    y: 0.8320415019989014
    },
    {
    x: 0.5090966820716858,
    y: 0.8320415019989014
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.634399950504303,
    bounding_box: [
    {
    x: 0.13229508697986603,
    y: 0.1997232586145401
    },
    {
    x: 0.504729688167572,
    y: 0.1997232586145401
    },
    {
    x: 0.504729688167572,
    y: 0.8380112051963806
    },
    {
    x: 0.13229508697986603,
    y: 0.8380112051963806
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 44603,
    time_in_milliseconds: 1488253.0000000002,
    labels: [
    {
    description: "Glasses",
    confidence: 81.87457919120789
    },
    {
    description: "News",
    confidence: 80.9249997138977
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Facial hair",
    confidence: 76.73787474632263
    },
    {
    description: "Media",
    confidence: 75.08222460746765
    },
    {
    description: "Photo caption",
    confidence: 65.58767557144165
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Vision care",
    confidence: 60.090118646621704
    },
    {
    description: "Beard",
    confidence: 52.905696630477905
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9259068369865417,
    bounding_box: [
    {
    x: 0.624559760093689,
    y: 0.3113704025745392
    },
    {
    x: 0.7614373564720154,
    y: 0.3113704025745392
    },
    {
    x: 0.7614373564720154,
    y: 0.39966848492622375
    },
    {
    x: 0.624559760093689,
    y: 0.39966848492622375
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9104383587837219,
    bounding_box: [
    {
    x: 0.24269123375415802,
    y: 0.23447537422180176
    },
    {
    x: 0.37778985500335693,
    y: 0.23447537422180176
    },
    {
    x: 0.37778985500335693,
    y: 0.32081523537635803
    },
    {
    x: 0.24269123375415802,
    y: 0.32081523537635803
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8189395070075989,
    bounding_box: [
    {
    x: 0.14426730573177338,
    y: 0.10258378833532333
    },
    {
    x: 0.5058124661445618,
    y: 0.10258378833532333
    },
    {
    x: 0.5058124661445618,
    y: 0.8537569642066956
    },
    {
    x: 0.14426730573177338,
    y: 0.8537569642066956
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8050761222839355,
    bounding_box: [
    {
    x: 0.5079612135887146,
    y: 0.10180455446243286
    },
    {
    x: 0.8720188736915588,
    y: 0.10180455446243286
    },
    {
    x: 0.8720188736915588,
    y: 0.8555254936218262
    },
    {
    x: 0.5079612135887146,
    y: 0.8555254936218262
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 44690,
    time_in_milliseconds: 1491156.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.8592267036438
    },
    {
    description: "Facial hair",
    confidence: 94.72115635871887
    },
    {
    description: "Beard",
    confidence: 92.08660125732422
    },
    {
    description: "Hairstyle",
    confidence: 91.81295037269592
    },
    {
    description: "Glasses",
    confidence: 89.0495777130127
    },
    {
    description: "Eyewear",
    confidence: 88.56593370437622
    },
    {
    description: "Moustache",
    confidence: 87.61157393455505
    },
    {
    description: "Dreadlocks",
    confidence: 84.74690318107605
    },
    {
    description: "Houseplant",
    confidence: 76.69059634208679
    },
    {
    description: "Afro",
    confidence: 75.17322301864624
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9416770339012146,
    bounding_box: [
    {
    x: 0.4791721999645233,
    y: 0.28212979435920715
    },
    {
    x: 0.6611747145652771,
    y: 0.28212979435920715
    },
    {
    x: 0.6611747145652771,
    y: 0.4022899866104126
    },
    {
    x: 0.4791721999645233,
    y: 0.4022899866104126
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8943628072738647,
    bounding_box: [
    {
    x: 0.3177763819694519,
    y: 0.4553252160549164
    },
    {
    x: 0.8882932662963867,
    y: 0.4553252160549164
    },
    {
    x: 0.8882932662963867,
    y: 0.9950401782989502
    },
    {
    x: 0.3177763819694519,
    y: 0.9950401782989502
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7933871150016785,
    bounding_box: [
    {
    x: 0.3255176246166229,
    y: 0.05504627525806427
    },
    {
    x: 0.8948652744293213,
    y: 0.05504627525806427
    },
    {
    x: 0.8948652744293213,
    y: 0.9919649958610535
    },
    {
    x: 0.3255176246166229,
    y: 0.9919649958610535
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5340135097503662,
    bounding_box: [
    {
    x: 0.19866658747196198,
    y: 0.6375097036361694
    },
    {
    x: 0.34528493881225586,
    y: 0.6375097036361694
    },
    {
    x: 0.34528493881225586,
    y: 0.9238477349281311
    },
    {
    x: 0.19866658747196198,
    y: 0.9238477349281311
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.504438042640686,
    bounding_box: [
    {
    x: 0.7233874201774597,
    y: 0
    },
    {
    x: 0.9957730770111084,
    y: 0
    },
    {
    x: 0.9957730770111084,
    y: 0.7502588629722595
    },
    {
    x: 0.7233874201774597,
    y: 0.7502588629722595
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45038,
    time_in_milliseconds: 1502767,
    labels: [
    {
    description: "News",
    confidence: 76.14815831184387
    },
    {
    description: "Glasses",
    confidence: 74.8481273651123
    },
    {
    description: "Media",
    confidence: 68.0826723575592
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    },
    {
    description: "Facial hair",
    confidence: 60.71390509605408
    },
    {
    description: "Photo caption",
    confidence: 57.24363327026367
    },
    {
    description: "Vision care",
    confidence: 56.652915477752686
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9361985325813293,
    bounding_box: [
    {
    x: 0.23587799072265625,
    y: 0.29563164710998535
    },
    {
    x: 0.37444350123405457,
    y: 0.29563164710998535
    },
    {
    x: 0.37444350123405457,
    y: 0.38560155034065247
    },
    {
    x: 0.23587799072265625,
    y: 0.38560155034065247
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9358499646186829,
    bounding_box: [
    {
    x: 0.6305034160614014,
    y: 0.29735368490219116
    },
    {
    x: 0.7713287472724915,
    y: 0.29735368490219116
    },
    {
    x: 0.7713287472724915,
    y: 0.3934348523616791
    },
    {
    x: 0.6305034160614014,
    y: 0.3934348523616791
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.891453742980957,
    bounding_box: [
    {
    x: 0.13427244126796722,
    y: 0.12548334896564484
    },
    {
    x: 0.5062727332115173,
    y: 0.12548334896564484
    },
    {
    x: 0.5062727332115173,
    y: 0.8598716855049133
    },
    {
    x: 0.13427244126796722,
    y: 0.8598716855049133
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.822810709476471,
    bounding_box: [
    {
    x: 0.5038583874702454,
    y: 0.1057506576180458
    },
    {
    x: 0.8744080662727356,
    y: 0.1057506576180458
    },
    {
    x: 0.8744080662727356,
    y: 0.8524518609046936
    },
    {
    x: 0.5038583874702454,
    y: 0.8524518609046936
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.584094762802124,
    bounding_box: [
    {
    x: 0.5039147138595581,
    y: 0.17085029184818268
    },
    {
    x: 0.8741828799247742,
    y: 0.17085029184818268
    },
    {
    x: 0.8741828799247742,
    y: 0.8329129219055176
    },
    {
    x: 0.5039147138595581,
    y: 0.8329129219055176
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5691770315170288,
    bounding_box: [
    {
    x: 0.13153977692127228,
    y: 0.17598700523376465
    },
    {
    x: 0.5076987147331238,
    y: 0.17598700523376465
    },
    {
    x: 0.5076987147331238,
    y: 0.8389477133750916
    },
    {
    x: 0.13153977692127228,
    y: 0.8389477133750916
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45125,
    time_in_milliseconds: 1505671,
    labels: [
    {
    description: "Hair",
    confidence: 98.00390005111694
    },
    {
    description: "Eyewear",
    confidence: 93.7157154083252
    },
    {
    description: "Glasses",
    confidence: 93.1408941745758
    },
    {
    description: "Houseplant",
    confidence: 92.97394156455994
    },
    {
    description: "Hairstyle",
    confidence: 86.86584234237671
    },
    {
    description: "Facial hair",
    confidence: 83.44762921333313
    },
    {
    description: "Vision care",
    confidence: 74.2175042629242
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    },
    {
    description: "Dreadlocks",
    confidence: 72.4882423877716
    },
    {
    description: "Plant",
    confidence: 69.68648433685303
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9329536557197571,
    bounding_box: [
    {
    x: 0.49859026074409485,
    y: 0.26018500328063965
    },
    {
    x: 0.6614571809768677,
    y: 0.26018500328063965
    },
    {
    x: 0.6614571809768677,
    y: 0.3758779466152191
    },
    {
    x: 0.49859026074409485,
    y: 0.3758779466152191
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8825167417526245,
    bounding_box: [
    {
    x: 0.32716888189315796,
    y: 0.4617605209350586
    },
    {
    x: 0.8880178332328796,
    y: 0.4617605209350586
    },
    {
    x: 0.8880178332328796,
    y: 0.9966058135032654
    },
    {
    x: 0.32716888189315796,
    y: 0.9966058135032654
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.78696209192276,
    bounding_box: [
    {
    x: 0.3293618857860565,
    y: 0.06994976848363876
    },
    {
    x: 0.8984076976776123,
    y: 0.06994976848363876
    },
    {
    x: 0.8984076976776123,
    y: 0.9935210347175598
    },
    {
    x: 0.3293618857860565,
    y: 0.9935210347175598
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5586530566215515,
    bounding_box: [
    {
    x: 0.1971202939748764,
    y: 0.635435163974762
    },
    {
    x: 0.346361368894577,
    y: 0.635435163974762
    },
    {
    x: 0.346361368894577,
    y: 0.9293103218078613
    },
    {
    x: 0.1971202939748764,
    y: 0.9293103218078613
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45241,
    time_in_milliseconds: 1509542.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 97.98632860183716
    },
    {
    description: "Facial hair",
    confidence: 93.22534203529358
    },
    {
    description: "Glasses",
    confidence: 89.61105942726135
    },
    {
    description: "Beard",
    confidence: 89.19722437858582
    },
    {
    description: "Moustache",
    confidence: 85.81510186195374
    },
    {
    description: "Hairstyle",
    confidence: 84.74541306495667
    },
    {
    description: "Houseplant",
    confidence: 78.81877422332764
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    },
    {
    description: "Elder",
    confidence: 76.10121369361877
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9369649291038513,
    bounding_box: [
    {
    x: 0.5002369284629822,
    y: 0.3036203384399414
    },
    {
    x: 0.6816105842590332,
    y: 0.3036203384399414
    },
    {
    x: 0.6816105842590332,
    y: 0.4244356155395508
    },
    {
    x: 0.5002369284629822,
    y: 0.4244356155395508
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7678347826004028,
    bounding_box: [
    {
    x: 0.27157992124557495,
    y: 0.4648444354534149
    },
    {
    x: 0.9733895659446716,
    y: 0.4648444354534149
    },
    {
    x: 0.9733895659446716,
    y: 0.9920125007629395
    },
    {
    x: 0.27157992124557495,
    y: 0.9920125007629395
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6913160085678101,
    bounding_box: [
    {
    x: 0.2642165422439575,
    y: 0.06584861874580383
    },
    {
    x: 0.9801170825958252,
    y: 0.06584861874580383
    },
    {
    x: 0.9801170825958252,
    y: 0.9955209493637085
    },
    {
    x: 0.2642165422439575,
    y: 0.9955209493637085
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.602259635925293,
    bounding_box: [
    {
    x: 0.20079761743545532,
    y: 0.6365128755569458
    },
    {
    x: 0.34648725390434265,
    y: 0.6365128755569458
    },
    {
    x: 0.34648725390434265,
    y: 0.9200485348701477
    },
    {
    x: 0.20079761743545532,
    y: 0.9200485348701477
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45299,
    time_in_milliseconds: 1511476,
    labels: [
    {
    description: "Hair",
    confidence: 98.51276278495789
    },
    {
    description: "Eyewear",
    confidence: 96.80743217468262
    },
    {
    description: "Facial hair",
    confidence: 96.3833749294281
    },
    {
    description: "Glasses",
    confidence: 94.98130083084106
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "Skin",
    confidence: 92.90940165519714
    },
    {
    description: "Finger",
    confidence: 89.05665874481201
    },
    {
    description: "Hairstyle",
    confidence: 88.92095685005188
    },
    {
    description: "Beard",
    confidence: 88.76736760139465
    },
    {
    description: "Head",
    confidence: 87.62359619140625
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9297003149986267,
    bounding_box: [
    {
    x: 0.5100682377815247,
    y: 0.26812517642974854
    },
    {
    x: 0.6809790134429932,
    y: 0.26812517642974854
    },
    {
    x: 0.6809790134429932,
    y: 0.38076546788215637
    },
    {
    x: 0.5100682377815247,
    y: 0.38076546788215637
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8493452072143555,
    bounding_box: [
    {
    x: 0.29821446537971497,
    y: 0.05750340595841408
    },
    {
    x: 0.899518609046936,
    y: 0.05750340595841408
    },
    {
    x: 0.899518609046936,
    y: 0.9923320412635803
    },
    {
    x: 0.29821446537971497,
    y: 0.9923320412635803
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7525565028190613,
    bounding_box: [
    {
    x: 0.3039208948612213,
    y: 0.4443087875843048
    },
    {
    x: 0.8844575881958008,
    y: 0.4443087875843048
    },
    {
    x: 0.8844575881958008,
    y: 0.996513843536377
    },
    {
    x: 0.3039208948612213,
    y: 0.996513843536377
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5112186670303345,
    bounding_box: [
    {
    x: 0.19925512373447418,
    y: 0.6391115188598633
    },
    {
    x: 0.3452790677547455,
    y: 0.6391115188598633
    },
    {
    x: 0.3452790677547455,
    y: 0.9253556132316589
    },
    {
    x: 0.19925512373447418,
    y: 0.9253556132316589
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45328,
    time_in_milliseconds: 1512444,
    labels: [
    {
    description: "Hair",
    confidence: 97.1860945224762
    },
    {
    description: "Glasses",
    confidence: 83.378666639328
    },
    {
    description: "Facial hair",
    confidence: 81.6852867603302
    },
    {
    description: "Houseplant",
    confidence: 76.39358043670654
    },
    {
    description: "Beard",
    confidence: 74.62639212608337
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    },
    {
    description: "Dreadlocks",
    confidence: 64.92187976837158
    },
    {
    description: "Plant",
    confidence: 62.44105100631714
    },
    {
    description: "Moustache",
    confidence: 61.320847272872925
    },
    {
    description: "Afro",
    confidence: 59.35209393501282
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9101859927177429,
    bounding_box: [
    {
    x: 0.5336126089096069,
    y: 0.26157405972480774
    },
    {
    x: 0.7274336814880371,
    y: 0.26157405972480774
    },
    {
    x: 0.7274336814880371,
    y: 0.39781710505485535
    },
    {
    x: 0.5336126089096069,
    y: 0.39781710505485535
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7259562611579895,
    bounding_box: [
    {
    x: 0.25049248337745667,
    y: 0.028155870735645294
    },
    {
    x: 0.9504414796829224,
    y: 0.028155870735645294
    },
    {
    x: 0.9504414796829224,
    y: 0.9932270646095276
    },
    {
    x: 0.25049248337745667,
    y: 0.9932270646095276
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6773729920387268,
    bounding_box: [
    {
    x: 0.25271525979042053,
    y: 0.43934714794158936
    },
    {
    x: 0.921621561050415,
    y: 0.43934714794158936
    },
    {
    x: 0.921621561050415,
    y: 0.9971825480461121
    },
    {
    x: 0.25271525979042053,
    y: 0.9971825480461121
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5566415190696716,
    bounding_box: [
    {
    x: 0.20246563851833344,
    y: 0.6410347819328308
    },
    {
    x: 0.34350869059562683,
    y: 0.6410347819328308
    },
    {
    x: 0.34350869059562683,
    y: 0.9129726886749268
    },
    {
    x: 0.20246563851833344,
    y: 0.9129726886749268
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45386,
    time_in_milliseconds: 1514379.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 97.96813130378723
    },
    {
    description: "Houseplant",
    confidence: 91.44854545593262
    },
    {
    description: "Facial hair",
    confidence: 90.96471667289734
    },
    {
    description: "Glasses",
    confidence: 86.94331049919128
    },
    {
    description: "Hairstyle",
    confidence: 83.63670110702515
    },
    {
    description: "Beard",
    confidence: 81.26524090766907
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Moustache",
    confidence: 77.30385661125183
    },
    {
    description: "Dreadlocks",
    confidence: 77.21710205078125
    },
    {
    description: "Plant",
    confidence: 71.24049663543701
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.936607301235199,
    bounding_box: [
    {
    x: 0.5100279450416565,
    y: 0.26471829414367676
    },
    {
    x: 0.6766664385795593,
    y: 0.26471829414367676
    },
    {
    x: 0.6766664385795593,
    y: 0.3765188753604889
    },
    {
    x: 0.5100279450416565,
    y: 0.3765188753604889
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8969452381134033,
    bounding_box: [
    {
    x: 0.3403901159763336,
    y: 0.46301817893981934
    },
    {
    x: 0.9135814905166626,
    y: 0.46301817893981934
    },
    {
    x: 0.9135814905166626,
    y: 0.9949460029602051
    },
    {
    x: 0.3403901159763336,
    y: 0.9949460029602051
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8060576915740967,
    bounding_box: [
    {
    x: 0.3333204984664917,
    y: 0.054241299629211426
    },
    {
    x: 0.9174239635467529,
    y: 0.054241299629211426
    },
    {
    x: 0.9174239635467529,
    y: 0.9899241328239441
    },
    {
    x: 0.3333204984664917,
    y: 0.9899241328239441
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5051261782646179,
    bounding_box: [
    {
    x: 0.7135303020477295,
    y: 0
    },
    {
    x: 0.9957407116889954,
    y: 0
    },
    {
    x: 0.9957407116889954,
    y: 0.7341058850288391
    },
    {
    x: 0.7135303020477295,
    y: 0.7341058850288391
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45415,
    time_in_milliseconds: 1515347,
    labels: [
    {
    description: "Hair",
    confidence: 98.7905502319336
    },
    {
    description: "Facial hair",
    confidence: 97.84107208251953
    },
    {
    description: "Beard",
    confidence: 96.17052674293518
    },
    {
    description: "Moustache",
    confidence: 92.9079532623291
    },
    {
    description: "Glasses",
    confidence: 92.78955459594727
    },
    {
    description: "Eyewear",
    confidence: 92.55768656730652
    },
    {
    description: "Hairstyle",
    confidence: 90.59877395629883
    },
    {
    description: "Dreadlocks",
    confidence: 89.16069865226746
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    },
    {
    description: "Smile",
    confidence: 69.44891214370728
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9358854293823242,
    bounding_box: [
    {
    x: 0.4857748746871948,
    y: 0.2621555030345917
    },
    {
    x: 0.6495051980018616,
    y: 0.2621555030345917
    },
    {
    x: 0.6495051980018616,
    y: 0.37099489569664
    },
    {
    x: 0.4857748746871948,
    y: 0.37099489569664
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8450185656547546,
    bounding_box: [
    {
    x: 0.3054615259170532,
    y: 0.43236565589904785
    },
    {
    x: 0.9047680497169495,
    y: 0.43236565589904785
    },
    {
    x: 0.9047680497169495,
    y: 0.9934155941009521
    },
    {
    x: 0.3054615259170532,
    y: 0.9934155941009521
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7928155064582825,
    bounding_box: [
    {
    x: 0.3188715875148773,
    y: 0.06395218521356583
    },
    {
    x: 0.9073355197906494,
    y: 0.06395218521356583
    },
    {
    x: 0.9073355197906494,
    y: 0.9920546412467957
    },
    {
    x: 0.3188715875148773,
    y: 0.9920546412467957
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45618,
    time_in_milliseconds: 1522120.0000000002,
    labels: [
    {
    description: "Eyewear",
    confidence: 98.36269617080688
    },
    {
    description: "Hair",
    confidence: 98.31254482269287
    },
    {
    description: "Facial hair",
    confidence: 97.44580388069153
    },
    {
    description: "Beard",
    confidence: 94.92135643959045
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "Moustache",
    confidence: 92.9079532623291
    },
    {
    description: "Glasses",
    confidence: 92.6021933555603
    },
    {
    description: "Vision care",
    confidence: 85.32347679138184
    },
    {
    description: "Forehead",
    confidence: 84.60902571678162
    },
    {
    description: "Hairstyle",
    confidence: 84.23047661781311
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9404691457748413,
    bounding_box: [
    {
    x: 0.5239786505699158,
    y: 0.25908640027046204
    },
    {
    x: 0.6888402104377747,
    y: 0.25908640027046204
    },
    {
    x: 0.6888402104377747,
    y: 0.3837117850780487
    },
    {
    x: 0.5239786505699158,
    y: 0.3837117850780487
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8725822567939758,
    bounding_box: [
    {
    x: 0.2900831997394562,
    y: 0.44427868723869324
    },
    {
    x: 0.9181438684463501,
    y: 0.44427868723869324
    },
    {
    x: 0.9181438684463501,
    y: 0.993864893913269
    },
    {
    x: 0.2900831997394562,
    y: 0.993864893913269
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8081619739532471,
    bounding_box: [
    {
    x: 0.26280611753463745,
    y: 0.05214641988277435
    },
    {
    x: 0.9231001734733582,
    y: 0.05214641988277435
    },
    {
    x: 0.9231001734733582,
    y: 0.9882736802101135
    },
    {
    x: 0.26280611753463745,
    y: 0.9882736802101135
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45647,
    time_in_milliseconds: 1523088,
    labels: [
    {
    description: "Hair",
    confidence: 98.55138063430786
    },
    {
    description: "Eyewear",
    confidence: 88.56593370437622
    },
    {
    description: "Hairstyle",
    confidence: 87.97082304954529
    },
    {
    description: "Shoulder",
    confidence: 87.3346209526062
    },
    {
    description: "Arm",
    confidence: 85.29558181762695
    },
    {
    description: "Muscle",
    confidence: 85.17463803291321
    },
    {
    description: "Glasses",
    confidence: 84.64290499687195
    },
    {
    description: "Facial hair",
    confidence: 83.44762921333313
    },
    {
    description: "Dreadlocks",
    confidence: 81.4911961555481
    },
    {
    description: "Houseplant",
    confidence: 80.18001914024353
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9432310461997986,
    bounding_box: [
    {
    x: 0.5142663717269897,
    y: 0.27638816833496094
    },
    {
    x: 0.6865658760070801,
    y: 0.27638816833496094
    },
    {
    x: 0.6865658760070801,
    y: 0.3987133800983429
    },
    {
    x: 0.5142663717269897,
    y: 0.3987133800983429
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7744969725608826,
    bounding_box: [
    {
    x: 0.2625165283679962,
    y: 0.050631407648324966
    },
    {
    x: 0.9400266408920288,
    y: 0.050631407648324966
    },
    {
    x: 0.9400266408920288,
    y: 0.9935428500175476
    },
    {
    x: 0.2625165283679962,
    y: 0.9935428500175476
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5844431519508362,
    bounding_box: [
    {
    x: 0.20118576288223267,
    y: 0.6378834247589111
    },
    {
    x: 0.344664067029953,
    y: 0.6378834247589111
    },
    {
    x: 0.344664067029953,
    y: 0.9200331568717957
    },
    {
    x: 0.20118576288223267,
    y: 0.9200331568717957
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45705,
    time_in_milliseconds: 1525024,
    labels: [
    {
    description: "Hair",
    confidence: 97.81931042671204
    },
    {
    description: "Facial hair",
    confidence: 94.9847936630249
    },
    {
    description: "Beard",
    confidence: 91.32717847824097
    },
    {
    description: "Glasses",
    confidence: 86.36079430580139
    },
    {
    description: "Eyewear",
    confidence: 86.2449049949646
    },
    {
    description: "Moustache",
    confidence: 84.85227823257446
    },
    {
    description: "Vision care",
    confidence: 69.94730830192566
    },
    {
    description: "Houseplant",
    confidence: 69.68988180160522
    },
    {
    description: "Plant",
    confidence: 58.5474967956543
    },
    {
    description: "Interior design",
    confidence: 54.307204484939575
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9169029593467712,
    bounding_box: [
    {
    x: 0.5115036964416504,
    y: 0.2801205813884735
    },
    {
    x: 0.6803514957427979,
    y: 0.2801205813884735
    },
    {
    x: 0.6803514957427979,
    y: 0.39924004673957825
    },
    {
    x: 0.5115036964416504,
    y: 0.39924004673957825
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7074611186981201,
    bounding_box: [
    {
    x: 0.28017568588256836,
    y: 0.054895270615816116
    },
    {
    x: 0.9407874941825867,
    y: 0.054895270615816116
    },
    {
    x: 0.9407874941825867,
    y: 0.9888237118721008
    },
    {
    x: 0.28017568588256836,
    y: 0.9888237118721008
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6006788611412048,
    bounding_box: [
    {
    x: 0.3066284954547882,
    y: 0.45865511894226074
    },
    {
    x: 0.9097726345062256,
    y: 0.45865511894226074
    },
    {
    x: 0.9097726345062256,
    y: 0.9900450706481934
    },
    {
    x: 0.3066284954547882,
    y: 0.9900450706481934
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.500308096408844,
    bounding_box: [
    {
    x: 0.19986043870449066,
    y: 0.6384813189506531
    },
    {
    x: 0.3478744328022003,
    y: 0.6384813189506531
    },
    {
    x: 0.3478744328022003,
    y: 0.9253590703010559
    },
    {
    x: 0.19986043870449066,
    y: 0.9253590703010559
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 45821,
    time_in_milliseconds: 1528893,
    labels: [
    {
    description: "Hair",
    confidence: 98.00390005111694
    },
    {
    description: "Facial hair",
    confidence: 95.22618055343628
    },
    {
    description: "Glasses",
    confidence: 89.61105942726135
    },
    {
    description: "Beard",
    confidence: 89.60235118865967
    },
    {
    description: "Moustache",
    confidence: 89.07240629196167
    },
    {
    description: "Houseplant",
    confidence: 89.03189897537231
    },
    {
    description: "Eyewear",
    confidence: 84.73531007766724
    },
    {
    description: "Hairstyle",
    confidence: 82.93982148170471
    },
    {
    description: "Plant",
    confidence: 66.16107821464539
    },
    {
    description: "Outerwear",
    confidence: 65.77929258346558
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9432387351989746,
    bounding_box: [
    {
    x: 0.5094817876815796,
    y: 0.2568812370300293
    },
    {
    x: 0.6851581931114197,
    y: 0.2568812370300293
    },
    {
    x: 0.6851581931114197,
    y: 0.3792797029018402
    },
    {
    x: 0.5094817876815796,
    y: 0.3792797029018402
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7957448363304138,
    bounding_box: [
    {
    x: 0.3054946959018707,
    y: 0.43925702571868896
    },
    {
    x: 0.9144818782806396,
    y: 0.43925702571868896
    },
    {
    x: 0.9144818782806396,
    y: 0.9945420622825623
    },
    {
    x: 0.3054946959018707,
    y: 0.9945420622825623
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7286840677261353,
    bounding_box: [
    {
    x: 0.31130272150039673,
    y: 0.0528666190803051
    },
    {
    x: 0.9038071036338806,
    y: 0.0528666190803051
    },
    {
    x: 0.9038071036338806,
    y: 0.9939654469490051
    },
    {
    x: 0.31130272150039673,
    y: 0.9939654469490051
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6036322116851807,
    bounding_box: [
    {
    x: 0.19963371753692627,
    y: 0.6388960480690002
    },
    {
    x: 0.3445986807346344,
    y: 0.6388960480690002
    },
    {
    x: 0.3445986807346344,
    y: 0.9278693795204163
    },
    {
    x: 0.19963371753692627,
    y: 0.9278693795204163
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.580314040184021,
    bounding_box: [
    {
    x: 0.72474604845047,
    y: 0
    },
    {
    x: 0.9961920380592346,
    y: 0
    },
    {
    x: 0.9961920380592346,
    y: 0.7422584891319275
    },
    {
    x: 0.72474604845047,
    y: 0.7422584891319275
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 46024,
    time_in_milliseconds: 1535668.0000000002,
    labels: [
    {
    description: "News",
    confidence: 87.68876791000366
    },
    {
    description: "Media",
    confidence: 75.98154544830322
    },
    {
    description: "Facial hair",
    confidence: 75.05148649215698
    },
    {
    description: "Glasses",
    confidence: 72.36500382423401
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    },
    {
    description: "Photo caption",
    confidence: 60.11592149734497
    },
    {
    description: "Vision care",
    confidence: 54.48664426803589
    },
    {
    description: "Beard",
    confidence: 50.39592981338501
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9467767477035522,
    bounding_box: [
    {
    x: 0.6357594728469849,
    y: 0.3004547655582428
    },
    {
    x: 0.7743079662322998,
    y: 0.3004547655582428
    },
    {
    x: 0.7743079662322998,
    y: 0.39676204323768616
    },
    {
    x: 0.6357594728469849,
    y: 0.39676204323768616
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9428543448448181,
    bounding_box: [
    {
    x: 0.25016048550605774,
    y: 0.319357305765152
    },
    {
    x: 0.3949754238128662,
    y: 0.319357305765152
    },
    {
    x: 0.3949754238128662,
    y: 0.415600061416626
    },
    {
    x: 0.25016048550605774,
    y: 0.415600061416626
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8515821099281311,
    bounding_box: [
    {
    x: 0.13946425914764404,
    y: 0.1394590139389038
    },
    {
    x: 0.5039831399917603,
    y: 0.1394590139389038
    },
    {
    x: 0.5039831399917603,
    y: 0.8476062417030334
    },
    {
    x: 0.13946425914764404,
    y: 0.8476062417030334
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8326322436332703,
    bounding_box: [
    {
    x: 0.510492742061615,
    y: 0.10586417466402054
    },
    {
    x: 0.87425297498703,
    y: 0.10586417466402054
    },
    {
    x: 0.87425297498703,
    y: 0.8501027226448059
    },
    {
    x: 0.510492742061615,
    y: 0.8501027226448059
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6848325133323669,
    bounding_box: [
    {
    x: 0.13815337419509888,
    y: 0.4845079183578491
    },
    {
    x: 0.4998203217983246,
    y: 0.4845079183578491
    },
    {
    x: 0.4998203217983246,
    y: 0.8313474059104919
    },
    {
    x: 0.13815337419509888,
    y: 0.8313474059104919
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6832301616668701,
    bounding_box: [
    {
    x: 0.5106020569801331,
    y: 0.22125816345214844
    },
    {
    x: 0.8716493248939514,
    y: 0.22125816345214844
    },
    {
    x: 0.8716493248939514,
    y: 0.8284133076667786
    },
    {
    x: 0.5106020569801331,
    y: 0.8284133076667786
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 46372,
    time_in_milliseconds: 1547279,
    labels: [
    {
    description: "Hair",
    confidence: 98.27492833137512
    },
    {
    description: "Facial hair",
    confidence: 97.14186787605286
    },
    {
    description: "Photograph",
    confidence: 94.80249285697937
    },
    {
    description: "Beard",
    confidence: 93.71429085731506
    },
    {
    description: "Glasses",
    confidence: 92.09486246109009
    },
    {
    description: "Houseplant",
    confidence: 91.80896878242493
    },
    {
    description: "Moustache",
    confidence: 90.58448076248169
    },
    {
    description: "Eyewear",
    confidence: 87.50287890434265
    },
    {
    description: "Hairstyle",
    confidence: 84.23047661781311
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9455967545509338,
    bounding_box: [
    {
    x: 0.5137824416160583,
    y: 0.25433099269866943
    },
    {
    x: 0.686279833316803,
    y: 0.25433099269866943
    },
    {
    x: 0.686279833316803,
    y: 0.36991965770721436
    },
    {
    x: 0.5137824416160583,
    y: 0.36991965770721436
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8588331341743469,
    bounding_box: [
    {
    x: 0.33509060740470886,
    y: 0.4633044898509979
    },
    {
    x: 0.8995455503463745,
    y: 0.4633044898509979
    },
    {
    x: 0.8995455503463745,
    y: 0.993965744972229
    },
    {
    x: 0.33509060740470886,
    y: 0.993965744972229
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8126375079154968,
    bounding_box: [
    {
    x: 0.33222147822380066,
    y: 0.05834951996803284
    },
    {
    x: 0.9112783670425415,
    y: 0.05834951996803284
    },
    {
    x: 0.9112783670425415,
    y: 0.9898859262466431
    },
    {
    x: 0.33222147822380066,
    y: 0.9898859262466431
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6205770373344421,
    bounding_box: [
    {
    x: 0.19809526205062866,
    y: 0.6368683576583862
    },
    {
    x: 0.348265677690506,
    y: 0.6368683576583862
    },
    {
    x: 0.348265677690506,
    y: 0.9269357323646545
    },
    {
    x: 0.19809526205062866,
    y: 0.9269357323646545
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5254589319229126,
    bounding_box: [
    {
    x: 0.7229334712028503,
    y: 0
    },
    {
    x: 0.9935510754585266,
    y: 0
    },
    {
    x: 0.9935510754585266,
    y: 0.7485691905021667
    },
    {
    x: 0.7229334712028503,
    y: 0.7485691905021667
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 46575,
    time_in_milliseconds: 1554052.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.11214804649353
    },
    {
    description: "Facial hair",
    confidence: 95.84220051765442
    },
    {
    description: "Beard",
    confidence: 90.69057703018188
    },
    {
    description: "Houseplant",
    confidence: 89.12132382392883
    },
    {
    description: "Moustache",
    confidence: 88.1760835647583
    },
    {
    description: "Glasses",
    confidence: 87.4778687953949
    },
    {
    description: "Hairstyle",
    confidence: 82.93982148170471
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Room",
    confidence: 74.44316148757935
    },
    {
    description: "Dreadlocks",
    confidence: 69.81099247932434
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.937581479549408,
    bounding_box: [
    {
    x: 0.5056459307670593,
    y: 0.26607707142829895
    },
    {
    x: 0.675379753112793,
    y: 0.26607707142829895
    },
    {
    x: 0.675379753112793,
    y: 0.380816787481308
    },
    {
    x: 0.5056459307670593,
    y: 0.380816787481308
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.895946204662323,
    bounding_box: [
    {
    x: 0.3352409899234772,
    y: 0.462691068649292
    },
    {
    x: 0.908068060874939,
    y: 0.462691068649292
    },
    {
    x: 0.908068060874939,
    y: 0.9946746230125427
    },
    {
    x: 0.3352409899234772,
    y: 0.9946746230125427
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7650347352027893,
    bounding_box: [
    {
    x: 0.3182084858417511,
    y: 0.06743288785219193
    },
    {
    x: 0.9072726964950562,
    y: 0.06743288785219193
    },
    {
    x: 0.9072726964950562,
    y: 0.9925305843353271
    },
    {
    x: 0.3182084858417511,
    y: 0.9925305843353271
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5572553277015686,
    bounding_box: [
    {
    x: 0.19597579538822174,
    y: 0.6359317302703857
    },
    {
    x: 0.3477664887905121,
    y: 0.6359317302703857
    },
    {
    x: 0.3477664887905121,
    y: 0.9295747876167297
    },
    {
    x: 0.19597579538822174,
    y: 0.9295747876167297
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 46720,
    time_in_milliseconds: 1558891,
    labels: [
    {
    description: "Hair",
    confidence: 97.86621332168579
    },
    {
    description: "Facial hair",
    confidence: 96.07393741607666
    },
    {
    description: "Eyewear",
    confidence: 92.08218455314636
    },
    {
    description: "Beard",
    confidence: 91.81267023086548
    },
    {
    description: "Houseplant",
    confidence: 89.89484906196594
    },
    {
    description: "Glasses",
    confidence: 88.42616081237793
    },
    {
    description: "Moustache",
    confidence: 87.0031476020813
    },
    {
    description: "Plant",
    confidence: 71.24049663543701
    },
    {
    description: "Vision care",
    confidence: 70.48851251602173
    },
    {
    description: "Smile",
    confidence: 69.44891214370728
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9330650568008423,
    bounding_box: [
    {
    x: 0.5172969698905945,
    y: 0.2630825936794281
    },
    {
    x: 0.6861042976379395,
    y: 0.2630825936794281
    },
    {
    x: 0.6861042976379395,
    y: 0.37850221991539
    },
    {
    x: 0.5172969698905945,
    y: 0.37850221991539
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8748472929000854,
    bounding_box: [
    {
    x: 0.3302273750305176,
    y: 0.46074822545051575
    },
    {
    x: 0.9199787974357605,
    y: 0.46074822545051575
    },
    {
    x: 0.9199787974357605,
    y: 0.992443323135376
    },
    {
    x: 0.3302273750305176,
    y: 0.992443323135376
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7663533687591553,
    bounding_box: [
    {
    x: 0.31358805298805237,
    y: 0.06445280462503433
    },
    {
    x: 0.9302695989608765,
    y: 0.06445280462503433
    },
    {
    x: 0.9302695989608765,
    y: 0.9869212508201599
    },
    {
    x: 0.31358805298805237,
    y: 0.9869212508201599
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5844211578369141,
    bounding_box: [
    {
    x: 0.19997482001781464,
    y: 0.6391944289207458
    },
    {
    x: 0.3461184501647949,
    y: 0.6391944289207458
    },
    {
    x: 0.3461184501647949,
    y: 0.9302883744239807
    },
    {
    x: 0.19997482001781464,
    y: 0.9302883744239807
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 46894,
    time_in_milliseconds: 1564696.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 97.84326553344727
    },
    {
    description: "Facial hair",
    confidence: 94.89953517913818
    },
    {
    description: "Glasses",
    confidence: 93.61687898635864
    },
    {
    description: "Beard",
    confidence: 91.99665188789368
    },
    {
    description: "Eyewear",
    confidence: 90.26086330413818
    },
    {
    description: "Hairstyle",
    confidence: 84.23047661781311
    },
    {
    description: "Moustache",
    confidence: 83.99984240531921
    },
    {
    description: "T-shirt",
    confidence: 79.50526475906372
    },
    {
    description: "Finger",
    confidence: 73.21174144744873
    },
    {
    description: "Vision care",
    confidence: 72.76947498321533
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9327698349952698,
    bounding_box: [
    {
    x: 0.5176015496253967,
    y: 0.2291068285703659
    },
    {
    x: 0.7077453136444092,
    y: 0.2291068285703659
    },
    {
    x: 0.7077453136444092,
    y: 0.36423882842063904
    },
    {
    x: 0.5176015496253967,
    y: 0.36423882842063904
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7164152264595032,
    bounding_box: [
    {
    x: 0.12817616760730743,
    y: 0.0237033162266016
    },
    {
    x: 0.9795109629631042,
    y: 0.0237033162266016
    },
    {
    x: 0.9795109629631042,
    y: 0.9931901097297668
    },
    {
    x: 0.12817616760730743,
    y: 0.9931901097297668
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6274313926696777,
    bounding_box: [
    {
    x: 0.20222316682338715,
    y: 0.4510577619075775
    },
    {
    x: 0.9404649138450623,
    y: 0.4510577619075775
    },
    {
    x: 0.9404649138450623,
    y: 0.9971075057983398
    },
    {
    x: 0.20222316682338715,
    y: 0.9971075057983398
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5967391133308411,
    bounding_box: [
    {
    x: 0.7261776328086853,
    y: 0
    },
    {
    x: 0.9944559931755066,
    y: 0
    },
    {
    x: 0.9944559931755066,
    y: 0.7297787070274353
    },
    {
    x: 0.7261776328086853,
    y: 0.7297787070274353
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5520836710929871,
    bounding_box: [
    {
    x: 0.05164353549480438,
    y: 0.4361032545566559
    },
    {
    x: 0.10146952420473099,
    y: 0.4361032545566559
    },
    {
    x: 0.10146952420473099,
    y: 0.7061225771903992
    },
    {
    x: 0.05164353549480438,
    y: 0.7061225771903992
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5360236167907715,
    bounding_box: [
    {
    x: 0.003186581889167428,
    y: 0.7104673385620117
    },
    {
    x: 0.11732352524995804,
    y: 0.7104673385620117
    },
    {
    x: 0.11732352524995804,
    y: 0.9941578507423401
    },
    {
    x: 0.003186581889167428,
    y: 0.9941578507423401
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 46923,
    time_in_milliseconds: 1565664,
    labels: [
    {
    description: "Hair",
    confidence: 98.15207719802856
    },
    {
    description: "Eyewear",
    confidence: 96.23547792434692
    },
    {
    description: "Facial hair",
    confidence: 96.07393741607666
    },
    {
    description: "Glasses",
    confidence: 92.6969051361084
    },
    {
    description: "Beard",
    confidence: 92.68110990524292
    },
    {
    description: "Moustache",
    confidence: 89.97657299041748
    },
    {
    description: "Houseplant",
    confidence: 87.89535164833069
    },
    {
    description: "Hairstyle",
    confidence: 85.60138940811157
    },
    {
    description: "Vision care",
    confidence: 73.15760254859924
    },
    {
    description: "Smile",
    confidence: 72.91945219039917
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9364435076713562,
    bounding_box: [
    {
    x: 0.5122995972633362,
    y: 0.2358851283788681
    },
    {
    x: 0.6881846785545349,
    y: 0.2358851283788681
    },
    {
    x: 0.6881846785545349,
    y: 0.3546554148197174
    },
    {
    x: 0.5122995972633362,
    y: 0.3546554148197174
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7158301472663879,
    bounding_box: [
    {
    x: 0.2935961186885834,
    y: 0.040207576006650925
    },
    {
    x: 0.93770432472229,
    y: 0.040207576006650925
    },
    {
    x: 0.93770432472229,
    y: 0.9973958134651184
    },
    {
    x: 0.2935961186885834,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6027892827987671,
    bounding_box: [
    {
    x: 0.19991613924503326,
    y: 0.6393454670906067
    },
    {
    x: 0.3465540111064911,
    y: 0.6393454670906067
    },
    {
    x: 0.3465540111064911,
    y: 0.9286379218101501
    },
    {
    x: 0.19991613924503326,
    y: 0.9286379218101501
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5981481671333313,
    bounding_box: [
    {
    x: 0.31360936164855957,
    y: 0.453892320394516
    },
    {
    x: 0.931652843952179,
    y: 0.453892320394516
    },
    {
    x: 0.931652843952179,
    y: 0.9918076992034912
    },
    {
    x: 0.31360936164855957,
    y: 0.9918076992034912
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5384548902511597,
    bounding_box: [
    {
    x: 0.7180135846138,
    y: 0
    },
    {
    x: 0.9932608008384705,
    y: 0
    },
    {
    x: 0.9932608008384705,
    y: 0.7270966172218323
    },
    {
    x: 0.7180135846138,
    y: 0.7270966172218323
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47155,
    time_in_milliseconds: 1573405,
    labels: [
    {
    description: "Eyewear",
    confidence: 98.36269617080688
    },
    {
    description: "Hair",
    confidence: 98.20058345794678
    },
    {
    description: "Facial hair",
    confidence: 98.1903612613678
    },
    {
    description: "Beard",
    confidence: 95.99631428718567
    },
    {
    description: "Glasses",
    confidence: 91.76015257835388
    },
    {
    description: "Moustache",
    confidence: 87.89899945259094
    },
    {
    description: "Finger",
    confidence: 84.4146192073822
    },
    {
    description: "Vision care",
    confidence: 83.52736830711365
    },
    {
    description: "Hairstyle",
    confidence: 82.93982148170471
    },
    {
    description: "Arm",
    confidence: 79.10864353179932
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9454835653305054,
    bounding_box: [
    {
    x: 0.5145504474639893,
    y: 0.21876776218414307
    },
    {
    x: 0.675544023513794,
    y: 0.21876776218414307
    },
    {
    x: 0.675544023513794,
    y: 0.3250828683376312
    },
    {
    x: 0.5145504474639893,
    y: 0.3250828683376312
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.805508017539978,
    bounding_box: [
    {
    x: 0.14534923434257507,
    y: 0.0413244254887104
    },
    {
    x: 0.9717828035354614,
    y: 0.0413244254887104
    },
    {
    x: 0.9717828035354614,
    y: 0.9892492890357971
    },
    {
    x: 0.14534923434257507,
    y: 0.9892492890357971
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5352678298950195,
    bounding_box: [
    {
    x: 0.18485373258590698,
    y: 0.4756157398223877
    },
    {
    x: 0.954646646976471,
    y: 0.4756157398223877
    },
    {
    x: 0.954646646976471,
    y: 0.9969928860664368
    },
    {
    x: 0.18485373258590698,
    y: 0.9969928860664368
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47242,
    time_in_milliseconds: 1576308,
    labels: [
    {
    description: "Hair",
    confidence: 98.40220808982849
    },
    {
    description: "Facial hair",
    confidence: 98.24108481407166
    },
    {
    description: "Eyewear",
    confidence: 96.36379480361938
    },
    {
    description: "Beard",
    confidence: 96.08412384986877
    },
    {
    description: "Moustache",
    confidence: 89.65100646018982
    },
    {
    description: "Glasses",
    confidence: 89.43015933036804
    },
    {
    description: "Hairstyle",
    confidence: 86.29165291786194
    },
    {
    description: "Finger",
    confidence: 80.48399090766907
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    },
    {
    description: "Vision care",
    confidence: 76.70782208442688
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9196065068244934,
    bounding_box: [
    {
    x: 0.5149674415588379,
    y: 0.2597139775753021
    },
    {
    x: 0.6843761801719666,
    y: 0.2597139775753021
    },
    {
    x: 0.6843761801719666,
    y: 0.3837105929851532
    },
    {
    x: 0.5149674415588379,
    y: 0.3837105929851532
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8174204230308533,
    bounding_box: [
    {
    x: 0.13792751729488373,
    y: 0.03627241030335426
    },
    {
    x: 0.9945494532585144,
    y: 0.03627241030335426
    },
    {
    x: 0.9945494532585144,
    y: 0.987083911895752
    },
    {
    x: 0.13792751729488373,
    y: 0.987083911895752
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7680467963218689,
    bounding_box: [
    {
    x: 0.1622101068496704,
    y: 0.45440876483917236
    },
    {
    x: 0.9849588871002197,
    y: 0.45440876483917236
    },
    {
    x: 0.9849588871002197,
    y: 0.9860913753509521
    },
    {
    x: 0.1622101068496704,
    y: 0.9860913753509521
    }
    ]
    },
    {
    description: "Watch",
    confidence: 0.5127732753753662,
    bounding_box: [
    {
    x: 0.8224042057991028,
    y: 0.8314029574394226
    },
    {
    x: 0.899554967880249,
    y: 0.8314029574394226
    },
    {
    x: 0.899554967880249,
    y: 0.9591640830039978
    },
    {
    x: 0.8224042057991028,
    y: 0.9591640830039978
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47271,
    time_in_milliseconds: 1577276,
    labels: [
    {
    description: "Hair",
    confidence: 98.61547350883484
    },
    {
    description: "Facial hair",
    confidence: 97.77401089668274
    },
    {
    description: "Beard",
    confidence: 95.38753032684326
    },
    {
    description: "Moustache",
    confidence: 91.31665229797363
    },
    {
    description: "Houseplant",
    confidence: 89.55773711204529
    },
    {
    description: "Glasses",
    confidence: 89.24311399459839
    },
    {
    description: "Hairstyle",
    confidence: 86.59101724624634
    },
    {
    description: "Eyewear",
    confidence: 86.2449049949646
    },
    {
    description: "Dreadlocks",
    confidence: 83.28136801719666
    },
    {
    description: "Snapshot",
    confidence: 82.50965476036072
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9389072060585022,
    bounding_box: [
    {
    x: 0.514087975025177,
    y: 0.2543702721595764
    },
    {
    x: 0.6823360323905945,
    y: 0.2543702721595764
    },
    {
    x: 0.6823360323905945,
    y: 0.3701575994491577
    },
    {
    x: 0.514087975025177,
    y: 0.3701575994491577
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.86356520652771,
    bounding_box: [
    {
    x: 0.3303855359554291,
    y: 0.46238744258880615
    },
    {
    x: 0.8986488580703735,
    y: 0.46238744258880615
    },
    {
    x: 0.8986488580703735,
    y: 0.9925040602684021
    },
    {
    x: 0.3303855359554291,
    y: 0.9925040602684021
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7623995542526245,
    bounding_box: [
    {
    x: 0.3181667625904083,
    y: 0.06982316821813583
    },
    {
    x: 0.8941593170166016,
    y: 0.06982316821813583
    },
    {
    x: 0.8941593170166016,
    y: 0.9923827648162842
    },
    {
    x: 0.3181667625904083,
    y: 0.9923827648162842
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7173943519592285,
    bounding_box: [
    {
    x: 0.3312074542045593,
    y: 0.05286899581551552
    },
    {
    x: 0.906880795955658,
    y: 0.05286899581551552
    },
    {
    x: 0.906880795955658,
    y: 0.9901292324066162
    },
    {
    x: 0.3312074542045593,
    y: 0.9901292324066162
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5074443221092224,
    bounding_box: [
    {
    x: 0.725363552570343,
    y: 0
    },
    {
    x: 0.994301974773407,
    y: 0
    },
    {
    x: 0.994301974773407,
    y: 0.7375524640083313
    },
    {
    x: 0.725363552570343,
    y: 0.7375524640083313
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47358,
    time_in_milliseconds: 1580179,
    labels: [
    {
    description: "Hair",
    confidence: 98.86558055877686
    },
    {
    description: "Photograph",
    confidence: 96.05494737625122
    },
    {
    description: "Shoulder",
    confidence: 94.38497424125671
    },
    {
    description: "Eyewear",
    confidence: 94.320547580719
    },
    {
    description: "Glasses",
    confidence: 94.04132962226868
    },
    {
    description: "Facial hair",
    confidence: 93.88203024864197
    },
    {
    description: "Hairstyle",
    confidence: 91.24740958213806
    },
    {
    description: "Beard",
    confidence: 87.82284259796143
    },
    {
    description: "Snapshot",
    confidence: 86.81764006614685
    },
    {
    description: "Arm",
    confidence: 85.64975261688232
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9343609809875488,
    bounding_box: [
    {
    x: 0.5456807017326355,
    y: 0.27368292212486267
    },
    {
    x: 0.7279050946235657,
    y: 0.27368292212486267
    },
    {
    x: 0.7279050946235657,
    y: 0.4100673198699951
    },
    {
    x: 0.5456807017326355,
    y: 0.4100673198699951
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8277073502540588,
    bounding_box: [
    {
    x: 0.3146551549434662,
    y: 0.05127260088920593
    },
    {
    x: 0.8968709707260132,
    y: 0.05127260088920593
    },
    {
    x: 0.8968709707260132,
    y: 0.9896523952484131
    },
    {
    x: 0.3146551549434662,
    y: 0.9896523952484131
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6858178973197937,
    bounding_box: [
    {
    x: 0.3256309926509857,
    y: 0.4577295780181885
    },
    {
    x: 0.8988773822784424,
    y: 0.4577295780181885
    },
    {
    x: 0.8988773822784424,
    y: 0.9971754550933838
    },
    {
    x: 0.3256309926509857,
    y: 0.9971754550933838
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6228097677230835,
    bounding_box: [
    {
    x: 0.31849586963653564,
    y: 0.4409295320510864
    },
    {
    x: 0.8908593654632568,
    y: 0.4409295320510864
    },
    {
    x: 0.8908593654632568,
    y: 0.9960811138153076
    },
    {
    x: 0.31849586963653564,
    y: 0.9960811138153076
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5679863095283508,
    bounding_box: [
    {
    x: 0.7291867733001709,
    y: 0
    },
    {
    x: 0.9955470561981201,
    y: 0
    },
    {
    x: 0.9955470561981201,
    y: 0.7485843300819397
    },
    {
    x: 0.7291867733001709,
    y: 0.7485843300819397
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5075269937515259,
    bounding_box: [
    {
    x: 0.1980554610490799,
    y: 0.6398434638977051
    },
    {
    x: 0.34527352452278137,
    y: 0.6398434638977051
    },
    {
    x: 0.34527352452278137,
    y: 0.9316914677619934
    },
    {
    x: 0.1980554610490799,
    y: 0.9316914677619934
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47416,
    time_in_milliseconds: 1582113,
    labels: [
    {
    description: "Hair",
    confidence: 98.28462600708008
    },
    {
    description: "Facial hair",
    confidence: 97.30031490325928
    },
    {
    description: "Eyewear",
    confidence: 96.90362811088562
    },
    {
    description: "Beard",
    confidence: 92.9957389831543
    },
    {
    description: "Glasses",
    confidence: 92.50534772872925
    },
    {
    description: "Moustache",
    confidence: 87.0031476020813
    },
    {
    description: "Hairstyle",
    confidence: 84.23047661781311
    },
    {
    description: "Arm",
    confidence: 82.12746381759644
    },
    {
    description: "Finger",
    confidence: 78.45591902732849
    },
    {
    description: "Hand",
    confidence: 77.59860754013062
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9445876479148865,
    bounding_box: [
    {
    x: 0.5184199213981628,
    y: 0.23351772129535675
    },
    {
    x: 0.7134480476379395,
    y: 0.23351772129535675
    },
    {
    x: 0.7134480476379395,
    y: 0.3621915578842163
    },
    {
    x: 0.5184199213981628,
    y: 0.3621915578842163
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8051043748855591,
    bounding_box: [
    {
    x: 0.2591005265712738,
    y: 0.03016032837331295
    },
    {
    x: 0.8970704078674316,
    y: 0.03016032837331295
    },
    {
    x: 0.8970704078674316,
    y: 0.9961081147193909
    },
    {
    x: 0.2591005265712738,
    y: 0.9961081147193909
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7708161473274231,
    bounding_box: [
    {
    x: 0.2746444642543793,
    y: 0.427688330411911
    },
    {
    x: 0.8873388767242432,
    y: 0.427688330411911
    },
    {
    x: 0.8873388767242432,
    y: 0.9951982498168945
    },
    {
    x: 0.2746444642543793,
    y: 0.9951982498168945
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6221521496772766,
    bounding_box: [
    {
    x: 0.20091599225997925,
    y: 0.6416258811950684
    },
    {
    x: 0.33953967690467834,
    y: 0.6416258811950684
    },
    {
    x: 0.33953967690467834,
    y: 0.9262883067131042
    },
    {
    x: 0.20091599225997925,
    y: 0.9262883067131042
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.6074346899986267,
    bounding_box: [
    {
    x: 0.7305066585540771,
    y: 0
    },
    {
    x: 0.9935217499732971,
    y: 0
    },
    {
    x: 0.9935217499732971,
    y: 0.7320654392242432
    },
    {
    x: 0.7305066585540771,
    y: 0.7320654392242432
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47474,
    time_in_milliseconds: 1584049,
    labels: [
    {
    description: "Hair",
    confidence: 98.84324073791504
    },
    {
    description: "Eyewear",
    confidence: 97.64163494110107
    },
    {
    description: "Afro",
    confidence: 94.20872330665588
    },
    {
    description: "Facial hair",
    confidence: 91.85858368873596
    },
    {
    description: "Glasses",
    confidence: 91.39916896820068
    },
    {
    description: "Hairstyle",
    confidence: 91.0068929195404
    },
    {
    description: "Beard",
    confidence: 89.46991562843323
    },
    {
    description: "Moustache",
    confidence: 89.42519426345825
    },
    {
    description: "Snapshot",
    confidence: 82.91832208633423
    },
    {
    description: "Forehead",
    confidence: 82.17551112174988
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9402284026145935,
    bounding_box: [
    {
    x: 0.5115899443626404,
    y: 0.25272640585899353
    },
    {
    x: 0.6945490837097168,
    y: 0.25272640585899353
    },
    {
    x: 0.6945490837097168,
    y: 0.3800116777420044
    },
    {
    x: 0.5115899443626404,
    y: 0.3800116777420044
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.809508740901947,
    bounding_box: [
    {
    x: 0.17507517337799072,
    y: 0.03396999463438988
    },
    {
    x: 0.9865865707397461,
    y: 0.03396999463438988
    },
    {
    x: 0.9865865707397461,
    y: 0.9900173544883728
    },
    {
    x: 0.17507517337799072,
    y: 0.9900173544883728
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7973576188087463,
    bounding_box: [
    {
    x: 0.1974068135023117,
    y: 0.4628119170665741
    },
    {
    x: 0.9874542355537415,
    y: 0.4628119170665741
    },
    {
    x: 0.9874542355537415,
    y: 0.996312141418457
    },
    {
    x: 0.1974068135023117,
    y: 0.996312141418457
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47503,
    time_in_milliseconds: 1585016,
    labels: [
    {
    description: "Hair",
    confidence: 97.92969822883606
    },
    {
    description: "Facial hair",
    confidence: 95.44806480407715
    },
    {
    description: "Glasses",
    confidence: 93.97377371788025
    },
    {
    description: "Beard",
    confidence: 90.90986847877502
    },
    {
    description: "Moustache",
    confidence: 89.97657299041748
    },
    {
    description: "Houseplant",
    confidence: 89.89484906196594
    },
    {
    description: "Eyewear",
    confidence: 88.56593370437622
    },
    {
    description: "Hairstyle",
    confidence: 84.23047661781311
    },
    {
    description: "Snapshot",
    confidence: 82.50965476036072
    },
    {
    description: "Smile",
    confidence: 80.0797700881958
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9426901340484619,
    bounding_box: [
    {
    x: 0.5313237309455872,
    y: 0.2474251538515091
    },
    {
    x: 0.712458610534668,
    y: 0.2474251538515091
    },
    {
    x: 0.712458610534668,
    y: 0.3709675073623657
    },
    {
    x: 0.5313237309455872,
    y: 0.3709675073623657
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8267984390258789,
    bounding_box: [
    {
    x: 0.28870534896850586,
    y: 0.4576477110385895
    },
    {
    x: 0.9415913224220276,
    y: 0.4576477110385895
    },
    {
    x: 0.9415913224220276,
    y: 0.9930871725082397
    },
    {
    x: 0.28870534896850586,
    y: 0.9930871725082397
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.766610860824585,
    bounding_box: [
    {
    x: 0.28605470061302185,
    y: 0.03897453844547272
    },
    {
    x: 0.9317086935043335,
    y: 0.03897453844547272
    },
    {
    x: 0.9317086935043335,
    y: 0.9915854930877686
    },
    {
    x: 0.28605470061302185,
    y: 0.9915854930877686
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5681090354919434,
    bounding_box: [
    {
    x: 0.19762498140335083,
    y: 0.6374070048332214
    },
    {
    x: 0.34713709354400635,
    y: 0.6374070048332214
    },
    {
    x: 0.34713709354400635,
    y: 0.933323323726654
    },
    {
    x: 0.19762498140335083,
    y: 0.933323323726654
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5383800864219666,
    bounding_box: [
    {
    x: 0.7201189994812012,
    y: 0
    },
    {
    x: 0.9931337237358093,
    y: 0
    },
    {
    x: 0.9931337237358093,
    y: 0.7310602068901062
    },
    {
    x: 0.7201189994812012,
    y: 0.7310602068901062
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47561,
    time_in_milliseconds: 1586952,
    labels: [
    {
    description: "Hair",
    confidence: 98.50696921348572
    },
    {
    description: "Facial hair",
    confidence: 98.22438359260559
    },
    {
    description: "Beard",
    confidence: 97.63650894165039
    },
    {
    description: "Eyewear",
    confidence: 96.90362811088562
    },
    {
    description: "Moustache",
    confidence: 93.10405254364014
    },
    {
    description: "Glasses",
    confidence: 92.20112562179565
    },
    {
    description: "Hairstyle",
    confidence: 87.11950778961182
    },
    {
    description: "T-shirt",
    confidence: 78.29992771148682
    },
    {
    description: "Vision care",
    confidence: 75.9702742099762
    },
    {
    description: "Finger",
    confidence: 71.64893746376038
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9344574809074402,
    bounding_box: [
    {
    x: 0.5164865851402283,
    y: 0.2616322338581085
    },
    {
    x: 0.6810181736946106,
    y: 0.2616322338581085
    },
    {
    x: 0.6810181736946106,
    y: 0.3791900873184204
    },
    {
    x: 0.5164865851402283,
    y: 0.3791900873184204
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7395016551017761,
    bounding_box: [
    {
    x: 0.25734642148017883,
    y: 0.06174488365650177
    },
    {
    x: 0.9477330446243286,
    y: 0.06174488365650177
    },
    {
    x: 0.9477330446243286,
    y: 0.9944936633110046
    },
    {
    x: 0.25734642148017883,
    y: 0.9944936633110046
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7316024303436279,
    bounding_box: [
    {
    x: 0.2340942621231079,
    y: 0.06526889652013779
    },
    {
    x: 0.9596635699272156,
    y: 0.06526889652013779
    },
    {
    x: 0.9596635699272156,
    y: 0.9949437975883484
    },
    {
    x: 0.2340942621231079,
    y: 0.9949437975883484
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7308047413825989,
    bounding_box: [
    {
    x: 0.28908589482307434,
    y: 0.45095229148864746
    },
    {
    x: 0.8665698766708374,
    y: 0.45095229148864746
    },
    {
    x: 0.8665698766708374,
    y: 0.9900763630867004
    },
    {
    x: 0.28908589482307434,
    y: 0.9900763630867004
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47706,
    time_in_milliseconds: 1591790,
    labels: [
    {
    description: "Eyewear",
    confidence: 98.96903038024902
    },
    {
    description: "Hair",
    confidence: 98.37164878845215
    },
    {
    description: "Facial hair",
    confidence: 97.5275456905365
    },
    {
    description: "Glasses",
    confidence: 96.25548720359802
    },
    {
    description: "Beard",
    confidence: 96.04039788246155
    },
    {
    description: "Moustache",
    confidence: 90.96090793609619
    },
    {
    description: "Vision care",
    confidence: 86.37670874595642
    },
    {
    description: "Forehead",
    confidence: 86.29716038703918
    },
    {
    description: "Hairstyle",
    confidence: 84.74541306495667
    },
    {
    description: "Snapshot",
    confidence: 84.114009141922
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9283827543258667,
    bounding_box: [
    {
    x: 0.5125338435173035,
    y: 0.24488089978694916
    },
    {
    x: 0.6776077151298523,
    y: 0.24488089978694916
    },
    {
    x: 0.6776077151298523,
    y: 0.36051031947135925
    },
    {
    x: 0.5125338435173035,
    y: 0.36051031947135925
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8319100141525269,
    bounding_box: [
    {
    x: 0.13417868316173553,
    y: 0.05361354351043701
    },
    {
    x: 0.9790322780609131,
    y: 0.05361354351043701
    },
    {
    x: 0.9790322780609131,
    y: 0.9919062256813049
    },
    {
    x: 0.13417868316173553,
    y: 0.9919062256813049
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.697087287902832,
    bounding_box: [
    {
    x: 0.20348256826400757,
    y: 0.47048521041870117
    },
    {
    x: 0.9702126979827881,
    y: 0.47048521041870117
    },
    {
    x: 0.9702126979827881,
    y: 0.995314359664917
    },
    {
    x: 0.20348256826400757,
    y: 0.995314359664917
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5488799214363098,
    bounding_box: [
    {
    x: 0.08163923770189285,
    y: 0.09360777586698532
    },
    {
    x: 0.23897784948349,
    y: 0.09360777586698532
    },
    {
    x: 0.23897784948349,
    y: 0.32078731060028076
    },
    {
    x: 0.08163923770189285,
    y: 0.32078731060028076
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47735,
    time_in_milliseconds: 1592757,
    labels: [
    {
    description: "Hair",
    confidence: 98.52972030639648
    },
    {
    description: "Facial hair",
    confidence: 97.23858833312988
    },
    {
    description: "Photograph",
    confidence: 95.75302600860596
    },
    {
    description: "Eyewear",
    confidence: 95.63094973564148
    },
    {
    description: "Glasses",
    confidence: 94.82280611991882
    },
    {
    description: "Facial expression",
    confidence: 94.43857669830322
    },
    {
    description: "Beard",
    confidence: 94.41088438034058
    },
    {
    description: "Moustache",
    confidence: 92.4228847026825
    },
    {
    description: "Hairstyle",
    confidence: 88.15163969993591
    },
    {
    description: "Snapshot",
    confidence: 85.50759553909302
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9349279999732971,
    bounding_box: [
    {
    x: 0.5206406712532043,
    y: 0.25283393263816833
    },
    {
    x: 0.682200014591217,
    y: 0.25283393263816833
    },
    {
    x: 0.682200014591217,
    y: 0.3665367066860199
    },
    {
    x: 0.5206406712532043,
    y: 0.3665367066860199
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8665714859962463,
    bounding_box: [
    {
    x: 0.3034602701663971,
    y: 0.4628899097442627
    },
    {
    x: 0.9057621955871582,
    y: 0.4628899097442627
    },
    {
    x: 0.9057621955871582,
    y: 0.9922235608100891
    },
    {
    x: 0.3034602701663971,
    y: 0.9922235608100891
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7955630421638489,
    bounding_box: [
    {
    x: 0.30953559279441833,
    y: 0.05816909298300743
    },
    {
    x: 0.9114596843719482,
    y: 0.05816909298300743
    },
    {
    x: 0.9114596843719482,
    y: 0.9880359172821045
    },
    {
    x: 0.30953559279441833,
    y: 0.9880359172821045
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6103736162185669,
    bounding_box: [
    {
    x: 0.19616498053073883,
    y: 0.6371200084686279
    },
    {
    x: 0.3474918305873871,
    y: 0.6371200084686279
    },
    {
    x: 0.3474918305873871,
    y: 0.934701144695282
    },
    {
    x: 0.19616498053073883,
    y: 0.934701144695282
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47793,
    time_in_milliseconds: 1594693,
    labels: [
    {
    description: "Hair",
    confidence: 97.50097393989563
    },
    {
    description: "Eyewear",
    confidence: 91.54864549636841
    },
    {
    description: "Glasses",
    confidence: 90.72930812835693
    },
    {
    description: "Hairstyle",
    confidence: 83.63670110702515
    },
    {
    description: "Vision care",
    confidence: 77.97924876213074
    },
    {
    description: "Facial hair",
    confidence: 73.0988085269928
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Finger",
    confidence: 64.58866596221924
    },
    {
    description: "Afro",
    confidence: 63.566094636917114
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9368021488189697,
    bounding_box: [
    {
    x: 0.5140333771705627,
    y: 0.28721168637275696
    },
    {
    x: 0.6961169838905334,
    y: 0.28721168637275696
    },
    {
    x: 0.6961169838905334,
    y: 0.4103792905807495
    },
    {
    x: 0.5140333771705627,
    y: 0.4103792905807495
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.761405885219574,
    bounding_box: [
    {
    x: 0.10013812780380249,
    y: 0.06520793586969376
    },
    {
    x: 0.9172918200492859,
    y: 0.06520793586969376
    },
    {
    x: 0.9172918200492859,
    y: 0.9953970313072205
    },
    {
    x: 0.10013812780380249,
    y: 0.9953970313072205
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7474652528762817,
    bounding_box: [
    {
    x: 0.08044939488172531,
    y: 0.04999972879886627
    },
    {
    x: 0.9509243965148926,
    y: 0.04999972879886627
    },
    {
    x: 0.9509243965148926,
    y: 0.9944838881492615
    },
    {
    x: 0.08044939488172531,
    y: 0.9944838881492615
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6493669748306274,
    bounding_box: [
    {
    x: 0.24361681938171387,
    y: 0.45376262068748474
    },
    {
    x: 0.912456214427948,
    y: 0.45376262068748474
    },
    {
    x: 0.912456214427948,
    y: 0.9970026016235352
    },
    {
    x: 0.24361681938171387,
    y: 0.9970026016235352
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5210395455360413,
    bounding_box: [
    {
    x: 0.002836707280948758,
    y: 0.7094399333000183
    },
    {
    x: 0.11704662442207336,
    y: 0.7094399333000183
    },
    {
    x: 0.11704662442207336,
    y: 0.9945578575134277
    },
    {
    x: 0.002836707280948758,
    y: 0.9945578575134277
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47851,
    time_in_milliseconds: 1596628.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.5966682434082
    },
    {
    description: "Facial hair",
    confidence: 95.96086144447327
    },
    {
    description: "Photograph",
    confidence: 94.9849009513855
    },
    {
    description: "Beard",
    confidence: 92.51722693443298
    },
    {
    description: "Hairstyle",
    confidence: 88.78235816955566
    },
    {
    description: "Moustache",
    confidence: 88.70149254798889
    },
    {
    description: "Glasses",
    confidence: 88.6415183544159
    },
    {
    description: "Houseplant",
    confidence: 87.16990947723389
    },
    {
    description: "Snapshot",
    confidence: 82.91832208633423
    },
    {
    description: "Eyewear",
    confidence: 80.6023359298706
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.942863404750824,
    bounding_box: [
    {
    x: 0.5205895900726318,
    y: 0.2702024281024933
    },
    {
    x: 0.6971852779388428,
    y: 0.2702024281024933
    },
    {
    x: 0.6971852779388428,
    y: 0.3981144428253174
    },
    {
    x: 0.5205895900726318,
    y: 0.3981144428253174
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8862960338592529,
    bounding_box: [
    {
    x: 0.33125603199005127,
    y: 0.45651695132255554
    },
    {
    x: 0.9083845019340515,
    y: 0.45651695132255554
    },
    {
    x: 0.9083845019340515,
    y: 0.9941959381103516
    },
    {
    x: 0.33125603199005127,
    y: 0.9941959381103516
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7965126633644104,
    bounding_box: [
    {
    x: 0.3308030068874359,
    y: 0.046867936849594116
    },
    {
    x: 0.9139571189880371,
    y: 0.046867936849594116
    },
    {
    x: 0.9139571189880371,
    y: 0.9907016754150391
    },
    {
    x: 0.3308030068874359,
    y: 0.9907016754150391
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5211708545684814,
    bounding_box: [
    {
    x: 0.7234734892845154,
    y: 0
    },
    {
    x: 0.9972937107086182,
    y: 0
    },
    {
    x: 0.9972937107086182,
    y: 0.7302001118659973
    },
    {
    x: 0.7234734892845154,
    y: 0.7302001118659973
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 47996,
    time_in_milliseconds: 1601466.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.38719367980957
    },
    {
    description: "Eyewear",
    confidence: 97.3164975643158
    },
    {
    description: "Facial hair",
    confidence: 91.65114164352417
    },
    {
    description: "Glasses",
    confidence: 90.58366417884827
    },
    {
    description: "Beard",
    confidence: 90.10791778564453
    },
    {
    description: "Hairstyle",
    confidence: 86.86584234237671
    },
    {
    description: "Moustache",
    confidence: 83.77387523651123
    },
    {
    description: "Vision care",
    confidence: 76.93713307380676
    },
    {
    description: "Finger",
    confidence: 73.21174144744873
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9207088351249695,
    bounding_box: [
    {
    x: 0.5174948573112488,
    y: 0.2503293454647064
    },
    {
    x: 0.6781985759735107,
    y: 0.2503293454647064
    },
    {
    x: 0.6781985759735107,
    y: 0.3715376853942871
    },
    {
    x: 0.5174948573112488,
    y: 0.3715376853942871
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8255635499954224,
    bounding_box: [
    {
    x: 0.2204187512397766,
    y: 0.06814185529947281
    },
    {
    x: 0.9867770075798035,
    y: 0.06814185529947281
    },
    {
    x: 0.9867770075798035,
    y: 0.9933500289916992
    },
    {
    x: 0.2204187512397766,
    y: 0.9933500289916992
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8019410371780396,
    bounding_box: [
    {
    x: 0.22253203392028809,
    y: 0.47866901755332947
    },
    {
    x: 0.9762519001960754,
    y: 0.47866901755332947
    },
    {
    x: 0.9762519001960754,
    y: 0.9934841394424438
    },
    {
    x: 0.22253203392028809,
    y: 0.9934841394424438
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48054,
    time_in_milliseconds: 1603402,
    labels: [
    {
    description: "Hair",
    confidence: 98.93511533737183
    },
    {
    description: "Shoulder",
    confidence: 94.38497424125671
    },
    {
    description: "Hairstyle",
    confidence: 92.53571629524231
    },
    {
    description: "Black hair",
    confidence: 92.53156185150146
    },
    {
    description: "Glasses",
    confidence: 91.64290428161621
    },
    {
    description: "Eyewear",
    confidence: 90.94614386558533
    },
    {
    description: "Afro",
    confidence: 86.8710458278656
    },
    {
    description: "Muscle",
    confidence: 85.50108671188354
    },
    {
    description: "Chest",
    confidence: 84.67520475387573
    },
    {
    description: "Nose",
    confidence: 84.19631719589233
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.92049640417099,
    bounding_box: [
    {
    x: 0.5443932414054871,
    y: 0.31250444054603577
    },
    {
    x: 0.7334239482879639,
    y: 0.31250444054603577
    },
    {
    x: 0.7334239482879639,
    y: 0.4494658410549164
    },
    {
    x: 0.5443932414054871,
    y: 0.4494658410549164
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7848654389381409,
    bounding_box: [
    {
    x: 0.2603472173213959,
    y: 0.044748563319444656
    },
    {
    x: 0.9064353704452515,
    y: 0.044748563319444656
    },
    {
    x: 0.9064353704452515,
    y: 0.9963014721870422
    },
    {
    x: 0.2603472173213959,
    y: 0.9963014721870422
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6015599966049194,
    bounding_box: [
    {
    x: 0.2611337900161743,
    y: 0.47149357199668884
    },
    {
    x: 0.9083976149559021,
    y: 0.47149357199668884
    },
    {
    x: 0.9083976149559021,
    y: 0.9973958730697632
    },
    {
    x: 0.2611337900161743,
    y: 0.9973958730697632
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5626466870307922,
    bounding_box: [
    {
    x: 0.1998036503791809,
    y: 0.6382554173469543
    },
    {
    x: 0.3491925895214081,
    y: 0.6382554173469543
    },
    {
    x: 0.3491925895214081,
    y: 0.9164661765098572
    },
    {
    x: 0.1998036503791809,
    y: 0.9164661765098572
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5400696396827698,
    bounding_box: [
    {
    x: 0.3006630837917328,
    y: 0.47845733165740967
    },
    {
    x: 0.9160939455032349,
    y: 0.47845733165740967
    },
    {
    x: 0.9160939455032349,
    y: 0.9924235939979553
    },
    {
    x: 0.3006630837917328,
    y: 0.9924235939979553
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48083,
    time_in_milliseconds: 1604369.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.52972030639648
    },
    {
    description: "Facial hair",
    confidence: 96.85657620429993
    },
    {
    description: "Beard",
    confidence: 93.50811839103699
    },
    {
    description: "Moustache",
    confidence: 92.97406673431396
    },
    {
    description: "Houseplant",
    confidence: 88.75904083251953
    },
    {
    description: "Hairstyle",
    confidence: 87.35474944114685
    },
    {
    description: "Glasses",
    confidence: 86.36079430580139
    },
    {
    description: "Eyewear",
    confidence: 73.85087013244629
    },
    {
    description: "Smile",
    confidence: 69.44891214370728
    },
    {
    description: "Plant",
    confidence: 68.2474136352539
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9449629783630371,
    bounding_box: [
    {
    x: 0.5108353495597839,
    y: 0.2771609425544739
    },
    {
    x: 0.6842564940452576,
    y: 0.2771609425544739
    },
    {
    x: 0.6842564940452576,
    y: 0.40186360478401184
    },
    {
    x: 0.5108353495597839,
    y: 0.40186360478401184
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8141974806785583,
    bounding_box: [
    {
    x: 0.34608638286590576,
    y: 0.4638577997684479
    },
    {
    x: 0.9039306640625,
    y: 0.4638577997684479
    },
    {
    x: 0.9039306640625,
    y: 0.9933874607086182
    },
    {
    x: 0.34608638286590576,
    y: 0.9933874607086182
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.725792646408081,
    bounding_box: [
    {
    x: 0.3252926766872406,
    y: 0.06668432801961899
    },
    {
    x: 0.8977142572402954,
    y: 0.06668432801961899
    },
    {
    x: 0.8977142572402954,
    y: 0.9905664920806885
    },
    {
    x: 0.3252926766872406,
    y: 0.9905664920806885
    }
    ]
    },
    {
    description: "Window",
    confidence: 0.5330663919448853,
    bounding_box: [
    {
    x: 0.7236029505729675,
    y: 0
    },
    {
    x: 0.994744598865509,
    y: 0
    },
    {
    x: 0.994744598865509,
    y: 0.7316797375679016
    },
    {
    x: 0.7236029505729675,
    y: 0.7316797375679016
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.5257914662361145,
    bounding_box: [
    {
    x: 0.19633106887340546,
    y: 0.638246476650238
    },
    {
    x: 0.34877756237983704,
    y: 0.638246476650238
    },
    {
    x: 0.34877756237983704,
    y: 0.9351269602775574
    },
    {
    x: 0.19633106887340546,
    y: 0.9351269602775574
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48112,
    time_in_milliseconds: 1605337,
    labels: [
    {
    description: "Hair",
    confidence: 98.74509572982788
    },
    {
    description: "Facial hair",
    confidence: 97.77401089668274
    },
    {
    description: "Beard",
    confidence: 94.92135643959045
    },
    {
    description: "Moustache",
    confidence: 93.82684230804443
    },
    {
    description: "Houseplant",
    confidence: 92.16049313545227
    },
    {
    description: "Hairstyle",
    confidence: 88.92095685005188
    },
    {
    description: "Glasses",
    confidence: 88.42616081237793
    },
    {
    description: "Eyewear",
    confidence: 77.68263220787048
    },
    {
    description: "Plant",
    confidence: 70.26593685150146
    },
    {
    description: "Smile",
    confidence: 64.1064703464508
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9362017512321472,
    bounding_box: [
    {
    x: 0.51068514585495,
    y: 0.28057244420051575
    },
    {
    x: 0.6807141900062561,
    y: 0.28057244420051575
    },
    {
    x: 0.6807141900062561,
    y: 0.40396854281425476
    },
    {
    x: 0.51068514585495,
    y: 0.40396854281425476
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8402178287506104,
    bounding_box: [
    {
    x: 0.34088680148124695,
    y: 0.4646235406398773
    },
    {
    x: 0.8961402177810669,
    y: 0.4646235406398773
    },
    {
    x: 0.8961402177810669,
    y: 0.9951059818267822
    },
    {
    x: 0.34088680148124695,
    y: 0.9951059818267822
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8236756920814514,
    bounding_box: [
    {
    x: 0.3358701467514038,
    y: 0.047182004898786545
    },
    {
    x: 0.9047546982765198,
    y: 0.047182004898786545
    },
    {
    x: 0.9047546982765198,
    y: 0.9905226230621338
    },
    {
    x: 0.3358701467514038,
    y: 0.9905226230621338
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48141,
    time_in_milliseconds: 1606305,
    labels: [
    {
    description: "News",
    confidence: 86.16944551467896
    },
    {
    description: "Glasses",
    confidence: 73.67156744003296
    },
    {
    description: "Media",
    confidence: 66.78166389465332
    },
    {
    description: "Eyewear",
    confidence: 61.17182970046997
    },
    {
    description: "Photo caption",
    confidence: 53.15094590187073
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9433158040046692,
    bounding_box: [
    {
    x: 0.6404227614402771,
    y: 0.2954738140106201
    },
    {
    x: 0.7782835960388184,
    y: 0.2954738140106201
    },
    {
    x: 0.7782835960388184,
    y: 0.3943289816379547
    },
    {
    x: 0.6404227614402771,
    y: 0.3943289816379547
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.9407472610473633,
    bounding_box: [
    {
    x: 0.18947066366672516,
    y: 0.34738507866859436
    },
    {
    x: 0.32717597484588623,
    y: 0.34738507866859436
    },
    {
    x: 0.32717597484588623,
    y: 0.4406949579715729
    },
    {
    x: 0.18947066366672516,
    y: 0.4406949579715729
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8619599938392639,
    bounding_box: [
    {
    x: 0.5144527554512024,
    y: 0.12579771876335144
    },
    {
    x: 0.8780474066734314,
    y: 0.12579771876335144
    },
    {
    x: 0.8780474066734314,
    y: 0.8488912582397461
    },
    {
    x: 0.5144527554512024,
    y: 0.8488912582397461
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8561623692512512,
    bounding_box: [
    {
    x: 0.13103972375392914,
    y: 0.15882618725299835
    },
    {
    x: 0.5040847659111023,
    y: 0.15882618725299835
    },
    {
    x: 0.5040847659111023,
    y: 0.860878050327301
    },
    {
    x: 0.13103972375392914,
    y: 0.860878050327301
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7256101369857788,
    bounding_box: [
    {
    x: 0.12815667688846588,
    y: 0.291763037443161
    },
    {
    x: 0.49646925926208496,
    y: 0.291763037443161
    },
    {
    x: 0.49646925926208496,
    y: 0.8392724990844727
    },
    {
    x: 0.12815667688846588,
    y: 0.8392724990844727
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6207571625709534,
    bounding_box: [
    {
    x: 0.513355553150177,
    y: 0.2172894924879074
    },
    {
    x: 0.875682532787323,
    y: 0.2172894924879074
    },
    {
    x: 0.875682532787323,
    y: 0.8325251936912537
    },
    {
    x: 0.513355553150177,
    y: 0.8325251936912537
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48344,
    time_in_milliseconds: 1613078,
    labels: [
    {
    description: "Hair",
    confidence: 98.47680330276489
    },
    {
    description: "Facial hair",
    confidence: 97.23858833312988
    },
    {
    description: "Glasses",
    confidence: 96.03727459907532
    },
    {
    description: "Eyewear",
    confidence: 95.79759240150452
    },
    {
    description: "Photograph",
    confidence: 95.1403021812439
    },
    {
    description: "Facial expression",
    confidence: 94.4551408290863
    },
    {
    description: "Beard",
    confidence: 94.16764378547668
    },
    {
    description: "Shoulder",
    confidence: 92.8443968296051
    },
    {
    description: "T-shirt",
    confidence: 89.1805112361908
    },
    {
    description: "Head",
    confidence: 88.42743635177612
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9135087728500366,
    bounding_box: [
    {
    x: 0.5383785367012024,
    y: 0.2285502701997757
    },
    {
    x: 0.695163905620575,
    y: 0.2285502701997757
    },
    {
    x: 0.695163905620575,
    y: 0.3320710361003876
    },
    {
    x: 0.5383785367012024,
    y: 0.3320710361003876
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7891162037849426,
    bounding_box: [
    {
    x: 0.05772453173995018,
    y: 0.05653643235564232
    },
    {
    x: 0.9905214309692383,
    y: 0.05653643235564232
    },
    {
    x: 0.9905214309692383,
    y: 0.9906795024871826
    },
    {
    x: 0.05772453173995018,
    y: 0.9906795024871826
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48373,
    time_in_milliseconds: 1614046,
    labels: [
    {
    description: "Hair",
    confidence: 97.54149913787842
    },
    {
    description: "Houseplant",
    confidence: 96.13254070281982
    },
    {
    description: "Photograph",
    confidence: 95.6760823726654
    },
    {
    description: "Facial hair",
    confidence: 94.89953517913818
    },
    {
    description: "Beard",
    confidence: 90.46363830566406
    },
    {
    description: "Glasses",
    confidence: 88.84918093681335
    },
    {
    description: "Eyewear",
    confidence: 88.56593370437622
    },
    {
    description: "Snapshot",
    confidence: 86.35382056236267
    },
    {
    description: "Shoulder",
    confidence: 80.12816905975342
    },
    {
    description: "Arm",
    confidence: 79.10864353179932
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8960754871368408,
    bounding_box: [
    {
    x: 0.48490533232688904,
    y: 0.25982943177223206
    },
    {
    x: 0.6141372919082642,
    y: 0.25982943177223206
    },
    {
    x: 0.6141372919082642,
    y: 0.3597654402256012
    },
    {
    x: 0.48490533232688904,
    y: 0.3597654402256012
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8101953864097595,
    bounding_box: [
    {
    x: 0.23886145651340485,
    y: 0.016485771164298058
    },
    {
    x: 0.9864127039909363,
    y: 0.016485771164298058
    },
    {
    x: 0.9864127039909363,
    y: 0.9937641620635986
    },
    {
    x: 0.23886145651340485,
    y: 0.9937641620635986
    }
    ]
    },
    {
    description: "Houseplant",
    confidence: 0.6871572732925415,
    bounding_box: [
    {
    x: 0.19767950475215912,
    y: 0.6398938298225403
    },
    {
    x: 0.3493793308734894,
    y: 0.6398938298225403
    },
    {
    x: 0.3493793308734894,
    y: 0.9323546886444092
    },
    {
    x: 0.19767950475215912,
    y: 0.9323546886444092
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5328270196914673,
    bounding_box: [
    {
    x: 0.2944782078266144,
    y: 0.3628629744052887
    },
    {
    x: 0.9895538091659546,
    y: 0.3628629744052887
    },
    {
    x: 0.9895538091659546,
    y: 0.990633487701416
    },
    {
    x: 0.2944782078266144,
    y: 0.990633487701416
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48402,
    time_in_milliseconds: 1615013.0000000002,
    labels: [
    {
    description: "Hair",
    confidence: 98.48300218582153
    },
    {
    description: "Eyewear",
    confidence: 97.3164975643158
    },
    {
    description: "Facial hair",
    confidence: 95.37612199783325
    },
    {
    description: "Glasses",
    confidence: 92.4062728881836
    },
    {
    description: "Beard",
    confidence: 91.32717847824097
    },
    {
    description: "Hairstyle",
    confidence: 88.92095685005188
    },
    {
    description: "Afro",
    confidence: 82.80925750732422
    },
    {
    description: "Moustache",
    confidence: 80.55905103683472
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    },
    {
    description: "Vision care",
    confidence: 76.70782208442688
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9367201328277588,
    bounding_box: [
    {
    x: 0.4595441520214081,
    y: 0.24702799320220947
    },
    {
    x: 0.6410406231880188,
    y: 0.24702799320220947
    },
    {
    x: 0.6410406231880188,
    y: 0.3799033463001251
    },
    {
    x: 0.4595441520214081,
    y: 0.3799033463001251
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6748965382575989,
    bounding_box: [
    {
    x: 0.03860345482826233,
    y: 0.010706394910812378
    },
    {
    x: 0.9921104907989502,
    y: 0.010706394910812378
    },
    {
    x: 0.9921104907989502,
    y: 0.9844166040420532
    },
    {
    x: 0.03860345482826233,
    y: 0.9844166040420532
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48431,
    time_in_milliseconds: 1615981,
    labels: [
    {
    description: "Library",
    confidence: 73.65115880966187
    },
    {
    description: "Bookcase",
    confidence: 59.72661375999451
    },
    {
    description: "Media",
    confidence: 53.86474132537842
    },
    {
    description: "Author",
    confidence: 51.33486986160278
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.8876335620880127,
    bounding_box: [
    {
    x: 0.43276214599609375,
    y: 0.38459327816963196
    },
    {
    x: 0.5598399043083191,
    y: 0.38459327816963196
    },
    {
    x: 0.5598399043083191,
    y: 0.46942195296287537
    },
    {
    x: 0.43276214599609375,
    y: 0.46942195296287537
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8750935196876526,
    bounding_box: [
    {
    x: 0.6262685656547546,
    y: 0.02842262201011181
    },
    {
    x: 0.9772801399230957,
    y: 0.02842262201011181
    },
    {
    x: 0.9772801399230957,
    y: 0.9816625118255615
    },
    {
    x: 0.6262685656547546,
    y: 0.9816625118255615
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8544402718544006,
    bounding_box: [
    {
    x: 0.3150304853916168,
    y: 0.2688450515270233
    },
    {
    x: 0.7442054152488708,
    y: 0.2688450515270233
    },
    {
    x: 0.7442054152488708,
    y: 0.9973958730697632
    },
    {
    x: 0.3150304853916168,
    y: 0.9973958730697632
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7449780106544495,
    bounding_box: [
    {
    x: 0.3180209696292877,
    y: 0.23530441522598267
    },
    {
    x: 0.7415182590484619,
    y: 0.23530441522598267
    },
    {
    x: 0.7415182590484619,
    y: 0.9926990866661072
    },
    {
    x: 0.3180209696292877,
    y: 0.9926990866661072
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5331249237060547,
    bounding_box: [
    {
    x: 0.3112908601760864,
    y: 0.5074501633644104
    },
    {
    x: 0.7313447594642639,
    y: 0.5074501633644104
    },
    {
    x: 0.7313447594642639,
    y: 0.9920051097869873
    },
    {
    x: 0.3112908601760864,
    y: 0.9920051097869873
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48489,
    time_in_milliseconds: 1617916.0000000002,
    labels: [
    {
    description: "Text",
    confidence: 95.35306096076965
    },
    {
    description: "Font",
    confidence: 94.47617530822754
    },
    {
    description: "Blue",
    confidence: 91.18428230285645
    },
    {
    description: "Electric blue",
    confidence: 81.70627355575562
    },
    {
    description: "Graphic design",
    confidence: 74.91511702537537
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Logo",
    confidence: 71.05835676193237
    },
    {
    description: "Graphics",
    confidence: 69.03585195541382
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 48605,
    time_in_milliseconds: 1621786,
    labels: [
    {
    description: "Font",
    confidence: 92.4447238445282
    },
    {
    description: "Text",
    confidence: 91.57753586769104
    },
    {
    description: "Electric blue",
    confidence: 84.79061126708984
    },
    {
    description: "Graphics",
    confidence: 65.15349745750427
    },
    {
    description: "Company",
    confidence: 64.37512636184692
    },
    {
    description: "Logo",
    confidence: 62.4750554561615
    },
    {
    description: "Brand",
    confidence: 56.534504890441895
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.605249285697937,
    bounding_box: [
    {
    x: 0.0018966743955388665,
    y: 0
    },
    {
    x: 0.9973958134651184,
    y: 0
    },
    {
    x: 0.9973958134651184,
    y: 0.9867150783538818
    },
    {
    x: 0.0018966743955388665,
    y: 0.9867150783538818
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48634,
    time_in_milliseconds: 1622755,
    labels: [
    {
    description: "Black",
    confidence: 98.49877953529358
    },
    {
    description: "Darkness",
    confidence: 97.3947525024414
    },
    {
    description: "White",
    confidence: 96.54507637023926
    },
    {
    description: "Sky",
    confidence: 93.36194396018982
    },
    {
    description: "Light",
    confidence: 91.6901707649231
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Text",
    confidence: 91.21394753456116
    },
    {
    description: "Brown",
    confidence: 88.69289755821228
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Atmosphere",
    confidence: 82.49121904373169
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 48692,
    time_in_milliseconds: 1624689,
    labels: [
    {
    description: "Font",
    confidence: 95.37133574485779
    },
    {
    description: "Text",
    confidence: 92.43778586387634
    },
    {
    description: "Electric blue",
    confidence: 76.88719034194946
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Graphics",
    confidence: 56.19082450866699
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7041611075401306,
    bounding_box: [
    {
    x: 0.045114949345588684,
    y: 0.0015016329707577825
    },
    {
    x: 0.9946041107177734,
    y: 0.0015016329707577825
    },
    {
    x: 0.9946041107177734,
    y: 0.9973958134651184
    },
    {
    x: 0.045114949345588684,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 48721,
    time_in_milliseconds: 1625658.0000000002,
    labels: [
    {
    description: "Text",
    confidence: 94.75095868110657
    },
    {
    description: "Font",
    confidence: 93.52027773857117
    },
    {
    description: "Blue",
    confidence: 92.6959753036499
    },
    {
    description: "Electric blue",
    confidence: 85.43184995651245
    },
    {
    description: "Graphic design",
    confidence: 77.59997844696045
    },
    {
    description: "Logo",
    confidence: 74.01036620140076
    },
    {
    description: "Graphics",
    confidence: 69.59721446037292
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 48837,
    time_in_milliseconds: 1629528,
    labels: [
    {
    description: "Library",
    confidence: 65.6439483165741
    },
    {
    description: "Book",
    confidence: 58.80803465843201
    },
    {
    description: "Glasses",
    confidence: 56.08964562416077
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Author",
    confidence: 50.18947720527649
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.907477855682373,
    bounding_box: [
    {
    x: 0.4314519464969635,
    y: 0.379772424697876
    },
    {
    x: 0.5649327635765076,
    y: 0.379772424697876
    },
    {
    x: 0.5649327635765076,
    y: 0.4708092212677002
    },
    {
    x: 0.4314519464969635,
    y: 0.4708092212677002
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8750194907188416,
    bounding_box: [
    {
    x: 0.616036593914032,
    y: 0.02567031979560852
    },
    {
    x: 0.9737054705619812,
    y: 0.02567031979560852
    },
    {
    x: 0.9737054705619812,
    y: 0.9789679050445557
    },
    {
    x: 0.616036593914032,
    y: 0.9789679050445557
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8578579425811768,
    bounding_box: [
    {
    x: 0.32823505997657776,
    y: 0.22792327404022217
    },
    {
    x: 0.8695179224014282,
    y: 0.22792327404022217
    },
    {
    x: 0.8695179224014282,
    y: 0.9968476295471191
    },
    {
    x: 0.32823505997657776,
    y: 0.9968476295471191
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6570085287094116,
    bounding_box: [
    {
    x: 0.3245059847831726,
    y: 0.5448355078697205
    },
    {
    x: 0.8668022155761719,
    y: 0.5448355078697205
    },
    {
    x: 0.8668022155761719,
    y: 0.9875573515892029
    },
    {
    x: 0.3245059847831726,
    y: 0.9875573515892029
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 49214,
    time_in_milliseconds: 1642107,
    labels: [
    {
    description: "Facial expression",
    confidence: 94.4733738899231
    },
    {
    description: "News",
    confidence: 92.7912175655365
    },
    {
    description: "Television program",
    confidence: 82.0477843284607
    },
    {
    description: "Newscaster",
    confidence: 79.92779016494751
    },
    {
    description: "Television presenter",
    confidence: 73.07571768760681
    },
    {
    description: "Blond",
    confidence: 72.81453013420105
    },
    {
    description: "Photography",
    confidence: 70.60428261756897
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Journalist",
    confidence: 70.04107236862183
    },
    {
    description: "Electric blue",
    confidence: 67.55332946777344
    }
    ],
    objects: [
    {
    description: "Necklace",
    confidence: 0.8780575394630432,
    bounding_box: [
    {
    x: 0.19734467566013336,
    y: 0.5354450345039368
    },
    {
    x: 0.2726692855358124,
    y: 0.5354450345039368
    },
    {
    x: 0.2726692855358124,
    y: 0.825771152973175
    },
    {
    x: 0.19734467566013336,
    y: 0.825771152973175
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8737311363220215,
    bounding_box: [
    {
    x: 0.10353898257017136,
    y: 0.16048550605773926
    },
    {
    x: 0.36270764470100403,
    y: 0.16048550605773926
    },
    {
    x: 0.36270764470100403,
    y: 0.8425779938697815
    },
    {
    x: 0.10353898257017136,
    y: 0.8425779938697815
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8718656897544861,
    bounding_box: [
    {
    x: 0.6390090584754944,
    y: 0.1261252611875534
    },
    {
    x: 0.8794615268707275,
    y: 0.1261252611875534
    },
    {
    x: 0.8794615268707275,
    y: 0.8557167649269104
    },
    {
    x: 0.6390090584754944,
    y: 0.8557167649269104
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8328730463981628,
    bounding_box: [
    {
    x: 0.642360508441925,
    y: 0.1416272521018982
    },
    {
    x: 0.8851322531700134,
    y: 0.1416272521018982
    },
    {
    x: 0.8851322531700134,
    y: 0.839942216873169
    },
    {
    x: 0.642360508441925,
    y: 0.839942216873169
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7791939377784729,
    bounding_box: [
    {
    x: 0.3697119653224945,
    y: 0.12751157581806183
    },
    {
    x: 0.63362056016922,
    y: 0.12751157581806183
    },
    {
    x: 0.63362056016922,
    y: 0.8431001305580139
    },
    {
    x: 0.3697119653224945,
    y: 0.8431001305580139
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7622424364089966,
    bounding_box: [
    {
    x: 0.37328267097473145,
    y: 0.41098761558532715
    },
    {
    x: 0.6156328320503235,
    y: 0.41098761558532715
    },
    {
    x: 0.6156328320503235,
    y: 0.8336274027824402
    },
    {
    x: 0.37328267097473145,
    y: 0.8336274027824402
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6469946503639221,
    bounding_box: [
    {
    x: 0.37562355399131775,
    y: 0.4213981628417969
    },
    {
    x: 0.617989718914032,
    y: 0.4213981628417969
    },
    {
    x: 0.617989718914032,
    y: 0.8368564248085022
    },
    {
    x: 0.37562355399131775,
    y: 0.8368564248085022
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6164969205856323,
    bounding_box: [
    {
    x: 0.694847822189331,
    y: 0.41860464215278625
    },
    {
    x: 0.8524218201637268,
    y: 0.41860464215278625
    },
    {
    x: 0.8524218201637268,
    y: 0.8366100192070007
    },
    {
    x: 0.694847822189331,
    y: 0.8366100192070007
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5822897553443909,
    bounding_box: [
    {
    x: 0.12059419602155685,
    y: 0.4220171868801117
    },
    {
    x: 0.3485288918018341,
    y: 0.4220171868801117
    },
    {
    x: 0.3485288918018341,
    y: 0.8281693458557129
    },
    {
    x: 0.12059419602155685,
    y: 0.8281693458557129
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 49359,
    time_in_milliseconds: 1646945.0000000002,
    labels: [
    {
    description: "Library",
    confidence: 82.3911964893341
    },
    {
    description: "Glasses",
    confidence: 80.04875183105469
    },
    {
    description: "Bookcase",
    confidence: 79.54884171485901
    },
    {
    description: "Book",
    confidence: 73.27474355697632
    },
    {
    description: "Shelving",
    confidence: 63.58347535133362
    },
    {
    description: "Publication",
    confidence: 61.657220125198364
    },
    {
    description: "Furniture",
    confidence: 59.37383770942688
    },
    {
    description: "Author",
    confidence: 52.10326910018921
    },
    {
    description: "Librarian",
    confidence: 51.521944999694824
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9325626492500305,
    bounding_box: [
    {
    x: 0.42384353280067444,
    y: 0.41486045718193054
    },
    {
    x: 0.5601401925086975,
    y: 0.41486045718193054
    },
    {
    x: 0.5601401925086975,
    y: 0.506460964679718
    },
    {
    x: 0.42384353280067444,
    y: 0.506460964679718
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8856123685836792,
    bounding_box: [
    {
    x: 0.6244394183158875,
    y: 0.0199201051145792
    },
    {
    x: 0.9814603328704834,
    y: 0.0199201051145792
    },
    {
    x: 0.9814603328704834,
    y: 0.9847617745399475
    },
    {
    x: 0.6244394183158875,
    y: 0.9847617745399475
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8588706851005554,
    bounding_box: [
    {
    x: 0.33064889907836914,
    y: 0.25599542260169983
    },
    {
    x: 0.8452381491661072,
    y: 0.25599542260169983
    },
    {
    x: 0.8452381491661072,
    y: 0.9934086799621582
    },
    {
    x: 0.33064889907836914,
    y: 0.9934086799621582
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6675490140914917,
    bounding_box: [
    {
    x: 0.32213425636291504,
    y: 0.5551027059555054
    },
    {
    x: 0.8344798684120178,
    y: 0.5551027059555054
    },
    {
    x: 0.8344798684120178,
    y: 0.9860141277313232
    },
    {
    x: 0.32213425636291504,
    y: 0.9860141277313232
    }
    ]
    },
    {
    description: "Television",
    confidence: 0.5012795925140381,
    bounding_box: [
    {
    x: 0.0020502619445323944,
    y: 0
    },
    {
    x: 0.39263737201690674,
    y: 0
    },
    {
    x: 0.39263737201690674,
    y: 0.4755136966705322
    },
    {
    x: 0.0020502619445323944,
    y: 0.4755136966705322
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 49446,
    time_in_milliseconds: 1649848.0000000002,
    labels: [
    {
    description: "Television program",
    confidence: 94.29493546485901
    },
    {
    description: "News",
    confidence: 86.16944551467896
    },
    {
    description: "Newscaster",
    confidence: 82.84215927124023
    },
    {
    description: "Television presenter",
    confidence: 75.86968541145325
    },
    {
    description: "Newsreader",
    confidence: 70.30230164527893
    },
    {
    description: "Media",
    confidence: 67.45004653930664
    },
    {
    description: "Journalist",
    confidence: 50.151413679122925
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.871250569820404,
    bounding_box: [
    {
    x: 0.18712949752807617,
    y: 0.12081387639045715
    },
    {
    x: 0.4642418324947357,
    y: 0.12081387639045715
    },
    {
    x: 0.4642418324947357,
    y: 0.5030704736709595
    },
    {
    x: 0.18712949752807617,
    y: 0.5030704736709595
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8609530329704285,
    bounding_box: [
    {
    x: 0.5734597444534302,
    y: 0.11838358640670776
    },
    {
    x: 0.8140570521354675,
    y: 0.11838358640670776
    },
    {
    x: 0.8140570521354675,
    y: 0.49816271662712097
    },
    {
    x: 0.5734597444534302,
    y: 0.49816271662712097
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.823811411857605,
    bounding_box: [
    {
    x: 0.6252900958061218,
    y: 0.5303950309753418
    },
    {
    x: 0.7994783520698547,
    y: 0.5303950309753418
    },
    {
    x: 0.7994783520698547,
    y: 0.9208330512046814
    },
    {
    x: 0.6252900958061218,
    y: 0.9208330512046814
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7988888621330261,
    bounding_box: [
    {
    x: 0.1853281408548355,
    y: 0.5313169956207275
    },
    {
    x: 0.41454169154167175,
    y: 0.5313169956207275
    },
    {
    x: 0.41454169154167175,
    y: 0.9263878464698792
    },
    {
    x: 0.1853281408548355,
    y: 0.9263878464698792
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.750579297542572,
    bounding_box: [
    {
    x: 0.19080407917499542,
    y: 0.2836162745952606
    },
    {
    x: 0.46205347776412964,
    y: 0.2836162745952606
    },
    {
    x: 0.46205347776412964,
    y: 0.4989436864852905
    },
    {
    x: 0.19080407917499542,
    y: 0.4989436864852905
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6375119686126709,
    bounding_box: [
    {
    x: 0.5734657645225525,
    y: 0.27623575925827026
    },
    {
    x: 0.814302384853363,
    y: 0.27623575925827026
    },
    {
    x: 0.814302384853363,
    y: 0.49005448818206787
    },
    {
    x: 0.5734657645225525,
    y: 0.49005448818206787
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6034289002418518,
    bounding_box: [
    {
    x: 0.34181535243988037,
    y: 0.061658505350351334
    },
    {
    x: 0.4976809024810791,
    y: 0.061658505350351334
    },
    {
    x: 0.4976809024810791,
    y: 0.49212923645973206
    },
    {
    x: 0.34181535243988037,
    y: 0.49212923645973206
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.5951385498046875,
    bounding_box: [
    {
    x: 0.6754496097564697,
    y: 0.7026877999305725
    },
    {
    x: 0.7629603743553162,
    y: 0.7026877999305725
    },
    {
    x: 0.7629603743553162,
    y: 0.9083852171897888
    },
    {
    x: 0.6754496097564697,
    y: 0.9083852171897888
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5928443670272827,
    bounding_box: [
    {
    x: 0.18252120912075043,
    y: 0.5474831461906433
    },
    {
    x: 0.41102370619773865,
    y: 0.5474831461906433
    },
    {
    x: 0.41102370619773865,
    y: 0.9226918816566467
    },
    {
    x: 0.18252120912075043,
    y: 0.9226918816566467
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5145391225814819,
    bounding_box: [
    {
    x: 0.19142843782901764,
    y: 0.2754327952861786
    },
    {
    x: 0.4573080539703369,
    y: 0.2754327952861786
    },
    {
    x: 0.4573080539703369,
    y: 0.49584725499153137
    },
    {
    x: 0.19142843782901764,
    y: 0.49584725499153137
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 50838,
    time_in_milliseconds: 1696294,
    labels: [
    {
    description: "Red",
    confidence: 96.83879613876343
    },
    {
    description: "Pink",
    confidence: 93.9879298210144
    },
    {
    description: "Rose",
    confidence: 92.32633113861084
    },
    {
    description: "Beauty",
    confidence: 89.60622549057007
    },
    {
    description: "Skin",
    confidence: 88.11389803886414
    },
    {
    description: "Rose family",
    confidence: 86.05600595474243
    },
    {
    description: "Flower",
    confidence: 84.78626608848572
    },
    {
    description: "Maroon",
    confidence: 83.3278238773346
    },
    {
    description: "Outerwear",
    confidence: 79.208242893219
    },
    {
    description: "Fashion",
    confidence: 78.85615825653076
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.9153279662132263,
    bounding_box: [
    {
    x: 0.19452954828739166,
    y: 0.4700356423854828
    },
    {
    x: 0.5931335687637329,
    y: 0.4700356423854828
    },
    {
    x: 0.5931335687637329,
    y: 0.9891798496246338
    },
    {
    x: 0.19452954828739166,
    y: 0.9891798496246338
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8701759576797485,
    bounding_box: [
    {
    x: 0.19207769632339478,
    y: 0.2122485488653183
    },
    {
    x: 0.6331313252449036,
    y: 0.2122485488653183
    },
    {
    x: 0.6331313252449036,
    y: 0.9947648048400879
    },
    {
    x: 0.19207769632339478,
    y: 0.9947648048400879
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.7713949084281921,
    bounding_box: [
    {
    x: 0.3972190320491791,
    y: 0.5834486484527588
    },
    {
    x: 0.47860291600227356,
    y: 0.5834486484527588
    },
    {
    x: 0.47860291600227356,
    y: 0.9042918682098389
    },
    {
    x: 0.3972190320491791,
    y: 0.9042918682098389
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 51650,
    time_in_milliseconds: 1723388.0000000002,
    labels: [
    {
    description: "Television program",
    confidence: 93.17719340324402
    },
    {
    description: "News",
    confidence: 83.33110213279724
    },
    {
    description: "Newscaster",
    confidence: 82.08314776420593
    },
    {
    description: "Television presenter",
    confidence: 74.24116730690002
    },
    {
    description: "Newsreader",
    confidence: 66.96739196777344
    },
    {
    description: "Media",
    confidence: 59.586501121520996
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8642077445983887,
    bounding_box: [
    {
    x: 0.5912285447120667,
    y: 0.1210227683186531
    },
    {
    x: 0.7963135242462158,
    y: 0.1210227683186531
    },
    {
    x: 0.7963135242462158,
    y: 0.4965381622314453
    },
    {
    x: 0.5912285447120667,
    y: 0.4965381622314453
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8503283262252808,
    bounding_box: [
    {
    x: 0.1715085506439209,
    y: 0.11651596426963806
    },
    {
    x: 0.46431124210357666,
    y: 0.11651596426963806
    },
    {
    x: 0.46431124210357666,
    y: 0.5015516877174377
    },
    {
    x: 0.1715085506439209,
    y: 0.5015516877174377
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8048256635665894,
    bounding_box: [
    {
    x: 0.1866312175989151,
    y: 0.5313980579376221
    },
    {
    x: 0.41599956154823303,
    y: 0.5313980579376221
    },
    {
    x: 0.41599956154823303,
    y: 0.924048662185669
    },
    {
    x: 0.1866312175989151,
    y: 0.924048662185669
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.779242992401123,
    bounding_box: [
    {
    x: 0.6195464134216309,
    y: 0.5153464674949646
    },
    {
    x: 0.8019946217536926,
    y: 0.5153464674949646
    },
    {
    x: 0.8019946217536926,
    y: 0.9273090362548828
    },
    {
    x: 0.6195464134216309,
    y: 0.9273090362548828
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7409342527389526,
    bounding_box: [
    {
    x: 0.17362092435359955,
    y: 0.2759898602962494
    },
    {
    x: 0.4607411026954651,
    y: 0.2759898602962494
    },
    {
    x: 0.4607411026954651,
    y: 0.4962821304798126
    },
    {
    x: 0.17362092435359955,
    y: 0.4962821304798126
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7229737639427185,
    bounding_box: [
    {
    x: 0.590736985206604,
    y: 0.17268700897693634
    },
    {
    x: 0.7952979207038879,
    y: 0.17268700897693634
    },
    {
    x: 0.7952979207038879,
    y: 0.4940034747123718
    },
    {
    x: 0.590736985206604,
    y: 0.4940034747123718
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6492988467216492,
    bounding_box: [
    {
    x: 0.1668347716331482,
    y: 0.27836090326309204
    },
    {
    x: 0.4695216715335846,
    y: 0.27836090326309204
    },
    {
    x: 0.4695216715335846,
    y: 0.4954988956451416
    },
    {
    x: 0.1668347716331482,
    y: 0.4954988956451416
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6413429975509644,
    bounding_box: [
    {
    x: 0.3448422849178314,
    y: 0.0652056559920311
    },
    {
    x: 0.49725377559661865,
    y: 0.0652056559920311
    },
    {
    x: 0.49725377559661865,
    y: 0.48655542731285095
    },
    {
    x: 0.3448422849178314,
    y: 0.48655542731285095
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.584583580493927,
    bounding_box: [
    {
    x: 0.18365465104579926,
    y: 0.5474595427513123
    },
    {
    x: 0.41216516494750977,
    y: 0.5474595427513123
    },
    {
    x: 0.41216516494750977,
    y: 0.92153000831604
    },
    {
    x: 0.18365465104579926,
    y: 0.92153000831604
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 51766,
    time_in_milliseconds: 1727258,
    labels: [
    {
    description: "Bookcase",
    confidence: 85.42452454566956
    },
    {
    description: "Library",
    confidence: 81.6836953163147
    },
    {
    description: "Book",
    confidence: 77.6689887046814
    },
    {
    description: "Shelving",
    confidence: 73.48225712776184
    },
    {
    description: "Publication",
    confidence: 66.21810793876648
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Glasses",
    confidence: 65.22085666656494
    },
    {
    description: "Furniture",
    confidence: 63.24014663696289
    },
    {
    description: "Shelf",
    confidence: 57.54020810127258
    },
    {
    description: "Author",
    confidence: 51.499366760253906
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9370517730712891,
    bounding_box: [
    {
    x: 0.4292732775211334,
    y: 0.3931427001953125
    },
    {
    x: 0.5662526488304138,
    y: 0.3931427001953125
    },
    {
    x: 0.5662526488304138,
    y: 0.48379865288734436
    },
    {
    x: 0.4292732775211334,
    y: 0.48379865288734436
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8602291941642761,
    bounding_box: [
    {
    x: 0.622890293598175,
    y: 0.02836531214416027
    },
    {
    x: 0.9832066893577576,
    y: 0.02836531214416027
    },
    {
    x: 0.9832066893577576,
    y: 0.9856421947479248
    },
    {
    x: 0.622890293598175,
    y: 0.9856421947479248
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7980181574821472,
    bounding_box: [
    {
    x: 0.2993720471858978,
    y: 0.22034870088100433
    },
    {
    x: 0.7573275566101074,
    y: 0.22034870088100433
    },
    {
    x: 0.7573275566101074,
    y: 0.9869460463523865
    },
    {
    x: 0.2993720471858978,
    y: 0.9869460463523865
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6461837887763977,
    bounding_box: [
    {
    x: 0.2889130413532257,
    y: 0.5608481168746948
    },
    {
    x: 0.7673234939575195,
    y: 0.5608481168746948
    },
    {
    x: 0.7673234939575195,
    y: 0.9825472831726074
    },
    {
    x: 0.2889130413532257,
    y: 0.9825472831726074
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 51853,
    time_in_milliseconds: 1730162,
    labels: [
    {
    description: "Beauty",
    confidence: 86.38556003570557
    },
    {
    description: "Blond",
    confidence: 83.71514081954956
    },
    {
    description: "Fashion",
    confidence: 82.369065284729
    },
    {
    description: "Long hair",
    confidence: 78.55142951011658
    },
    {
    description: "Dress",
    confidence: 72.17389345169067
    },
    {
    description: "Leg",
    confidence: 69.20332312583923
    },
    {
    description: "Little black dress",
    confidence: 66.1935031414032
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Fashion accessory",
    confidence: 61.625415086746216
    },
    {
    description: "Model",
    confidence: 61.1376166343689
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8508257269859314,
    bounding_box: [
    {
    x: 0.3828899562358856,
    y: 0.1469993144273758
    },
    {
    x: 0.8440721035003662,
    y: 0.1469993144273758
    },
    {
    x: 0.8440721035003662,
    y: 0.9892705082893372
    },
    {
    x: 0.3828899562358856,
    y: 0.9892705082893372
    }
    ]
    },
    {
    description: "Dress",
    confidence: 0.7056815028190613,
    bounding_box: [
    {
    x: 0.42465949058532715,
    y: 0.45661938190460205
    },
    {
    x: 0.7486940026283264,
    y: 0.45661938190460205
    },
    {
    x: 0.7486940026283264,
    y: 0.9915928840637207
    },
    {
    x: 0.42465949058532715,
    y: 0.9915928840637207
    }
    ]
    },
    {
    description: "Guitar",
    confidence: 0.5069863796234131,
    bounding_box: [
    {
    x: 0.003009303705766797,
    y: 0.5969671010971069
    },
    {
    x: 0.1044679805636406,
    y: 0.5969671010971069
    },
    {
    x: 0.1044679805636406,
    y: 0.9960859417915344
    },
    {
    x: 0.003009303705766797,
    y: 0.9960859417915344
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 52317,
    time_in_milliseconds: 1745643,
    labels: [
    {
    description: "Library",
    confidence: 78.48470211029053
    },
    {
    description: "Book",
    confidence: 76.91397070884705
    },
    {
    description: "Bookcase",
    confidence: 73.65607023239136
    },
    {
    description: "Publication",
    confidence: 65.40831327438354
    },
    {
    description: "Glasses",
    confidence: 62.70098090171814
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Author",
    confidence: 51.33486986160278
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9320510625839233,
    bounding_box: [
    {
    x: 0.4211941659450531,
    y: 0.4301384687423706
    },
    {
    x: 0.5577877759933472,
    y: 0.4301384687423706
    },
    {
    x: 0.5577877759933472,
    y: 0.5205274224281311
    },
    {
    x: 0.4211941659450531,
    y: 0.5205274224281311
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8669952154159546,
    bounding_box: [
    {
    x: 0.6261138916015625,
    y: 0.018047329038381577
    },
    {
    x: 0.9797298908233643,
    y: 0.018047329038381577
    },
    {
    x: 0.9797298908233643,
    y: 0.9852100014686584
    },
    {
    x: 0.6261138916015625,
    y: 0.9852100014686584
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8571064472198486,
    bounding_box: [
    {
    x: 0.29965248703956604,
    y: 0.2433365136384964
    },
    {
    x: 0.8679797649383545,
    y: 0.2433365136384964
    },
    {
    x: 0.8679797649383545,
    y: 0.9949478507041931
    },
    {
    x: 0.29965248703956604,
    y: 0.9949478507041931
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 52433,
    time_in_milliseconds: 1749515,
    labels: [
    {
    description: "Fur",
    confidence: 93.39231252670288
    },
    {
    description: "Long hair",
    confidence: 83.2375168800354
    },
    {
    description: "Fur clothing",
    confidence: 58.62507224082947
    },
    {
    description: "Fashion accessory",
    confidence: 54.58008050918579
    },
    {
    description: "Black hair",
    confidence: 51.65854096412659
    },
    {
    description: "Jacket",
    confidence: 51.004964113235474
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.9266855716705322,
    bounding_box: [
    {
    x: 0.30911028385162354,
    y: 0.365492582321167
    },
    {
    x: 0.7263233661651611,
    y: 0.365492582321167
    },
    {
    x: 0.7263233661651611,
    y: 0.9833421111106873
    },
    {
    x: 0.30911028385162354,
    y: 0.9833421111106873
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8703801035881042,
    bounding_box: [
    {
    x: 0.310239315032959,
    y: 0.2012445330619812
    },
    {
    x: 0.7402179837226868,
    y: 0.2012445330619812
    },
    {
    x: 0.7402179837226868,
    y: 0.9815171360969543
    },
    {
    x: 0.310239315032959,
    y: 0.9815171360969543
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.7288579940795898,
    bounding_box: [
    {
    x: 0.7636246681213379,
    y: 0.5457757115364075
    },
    {
    x: 0.8796999454498291,
    y: 0.5457757115364075
    },
    {
    x: 0.8796999454498291,
    y: 0.7255703806877136
    },
    {
    x: 0.7636246681213379,
    y: 0.7255703806877136
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7084156274795532,
    bounding_box: [
    {
    x: 0.3122405707836151,
    y: 0.07949455082416534
    },
    {
    x: 0.746446430683136,
    y: 0.07949455082416534
    },
    {
    x: 0.746446430683136,
    y: 0.9922505021095276
    },
    {
    x: 0.3122405707836151,
    y: 0.9922505021095276
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6503446698188782,
    bounding_box: [
    {
    x: 0.8083532452583313,
    y: 0.16755153238773346
    },
    {
    x: 0.9060325026512146,
    y: 0.16755153238773346
    },
    {
    x: 0.9060325026512146,
    y: 0.4129791557788849
    },
    {
    x: 0.8083532452583313,
    y: 0.4129791557788849
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5723423957824707,
    bounding_box: [
    {
    x: 0.6724070906639099,
    y: 0.5485503077507019
    },
    {
    x: 0.7622447609901428,
    y: 0.5485503077507019
    },
    {
    x: 0.7622447609901428,
    y: 0.7000257968902588
    },
    {
    x: 0.6724070906639099,
    y: 0.7000257968902588
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 52694,
    time_in_milliseconds: 1758223.0000000002,
    labels: [
    {
    description: "Television program",
    confidence: 93.73987913131714
    },
    {
    description: "News",
    confidence: 86.57316565513611
    },
    {
    description: "Newscaster",
    confidence: 83.03004503250122
    },
    {
    description: "Television presenter",
    confidence: 79.24026846885681
    },
    {
    description: "Newsreader",
    confidence: 67.81728267669678
    },
    {
    description: "Media",
    confidence: 66.0740077495575
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.888001024723053,
    bounding_box: [
    {
    x: 0.6104759573936462,
    y: 0.12031640857458115
    },
    {
    x: 0.8209273815155029,
    y: 0.12031640857458115
    },
    {
    x: 0.8209273815155029,
    y: 0.49315592646598816
    },
    {
    x: 0.6104759573936462,
    y: 0.49315592646598816
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8784506916999817,
    bounding_box: [
    {
    x: 0.17726188898086548,
    y: 0.11068067699670792
    },
    {
    x: 0.4690288305282593,
    y: 0.11068067699670792
    },
    {
    x: 0.4690288305282593,
    y: 0.5002050995826721
    },
    {
    x: 0.17726188898086548,
    y: 0.5002050995826721
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8501014709472656,
    bounding_box: [
    {
    x: 0.6139025688171387,
    y: 0.5152278542518616
    },
    {
    x: 0.8048456311225891,
    y: 0.5152278542518616
    },
    {
    x: 0.8048456311225891,
    y: 0.9248964190483093
    },
    {
    x: 0.6139025688171387,
    y: 0.9248964190483093
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8496991395950317,
    bounding_box: [
    {
    x: 0.19265437126159668,
    y: 0.5176120400428772
    },
    {
    x: 0.43249866366386414,
    y: 0.5176120400428772
    },
    {
    x: 0.43249866366386414,
    y: 0.9188687801361084
    },
    {
    x: 0.19265437126159668,
    y: 0.9188687801361084
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8097785711288452,
    bounding_box: [
    {
    x: 0.6108611226081848,
    y: 0.13453681766986847
    },
    {
    x: 0.8165628910064697,
    y: 0.13453681766986847
    },
    {
    x: 0.8165628910064697,
    y: 0.49394655227661133
    },
    {
    x: 0.6108611226081848,
    y: 0.49394655227661133
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7502707242965698,
    bounding_box: [
    {
    x: 0.17991192638874054,
    y: 0.2731301188468933
    },
    {
    x: 0.46896982192993164,
    y: 0.2731301188468933
    },
    {
    x: 0.46896982192993164,
    y: 0.49413755536079407
    },
    {
    x: 0.17991192638874054,
    y: 0.49413755536079407
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7225368022918701,
    bounding_box: [
    {
    x: 0.6082276105880737,
    y: 0.15908993780612946
    },
    {
    x: 0.8177425861358643,
    y: 0.15908993780612946
    },
    {
    x: 0.8177425861358643,
    y: 0.49351322650909424
    },
    {
    x: 0.6082276105880737,
    y: 0.49351322650909424
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6777815222740173,
    bounding_box: [
    {
    x: 0.1925082951784134,
    y: 0.5418819785118103
    },
    {
    x: 0.423965185880661,
    y: 0.5418819785118103
    },
    {
    x: 0.423965185880661,
    y: 0.9211246371269226
    },
    {
    x: 0.1925082951784134,
    y: 0.9211246371269226
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.6437159776687622,
    bounding_box: [
    {
    x: 0.6737517714500427,
    y: 0.6966562271118164
    },
    {
    x: 0.7689619064331055,
    y: 0.6966562271118164
    },
    {
    x: 0.7689619064331055,
    y: 0.9162001013755798
    },
    {
    x: 0.6737517714500427,
    y: 0.9162001013755798
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6370903253555298,
    bounding_box: [
    {
    x: 0.3423539102077484,
    y: 0.0659232884645462
    },
    {
    x: 0.49832725524902344,
    y: 0.0659232884645462
    },
    {
    x: 0.49832725524902344,
    y: 0.4890982508659363
    },
    {
    x: 0.3423539102077484,
    y: 0.4890982508659363
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 53071,
    time_in_milliseconds: 1770802.0000000002,
    labels: [
    {
    description: "Facial expression",
    confidence: 94.43857669830322
    },
    {
    description: "Fun",
    confidence: 80.11429905891418
    },
    {
    description: "Shoulder",
    confidence: 75.07902979850769
    },
    {
    description: "Leg",
    confidence: 73.3493447303772
    },
    {
    description: "Long hair",
    confidence: 71.19807600975037
    },
    {
    description: "Smile",
    confidence: 69.44891214370728
    },
    {
    description: "Muscle",
    confidence: 67.94060468673706
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Audio equipment",
    confidence: 59.66783165931702
    },
    {
    description: "Flash photography",
    confidence: 58.30838084220886
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8495999574661255,
    bounding_box: [
    {
    x: 0.3719230890274048,
    y: 0.14455927908420563
    },
    {
    x: 0.7286800742149353,
    y: 0.14455927908420563
    },
    {
    x: 0.7286800742149353,
    y: 0.9973958134651184
    },
    {
    x: 0.3719230890274048,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Dress",
    confidence: 0.6761100888252258,
    bounding_box: [
    {
    x: 0.390046626329422,
    y: 0.19630832970142365
    },
    {
    x: 0.7173981070518494,
    y: 0.19630832970142365
    },
    {
    x: 0.7173981070518494,
    y: 0.98721843957901
    },
    {
    x: 0.390046626329422,
    y: 0.98721843957901
    }
    ]
    },
    {
    description: "Musical instrument",
    confidence: 0.5688082575798035,
    bounding_box: [
    {
    x: 0.7080767750740051,
    y: 0.5118908882141113
    },
    {
    x: 0.8835607171058655,
    y: 0.5118908882141113
    },
    {
    x: 0.8835607171058655,
    y: 0.9925420880317688
    },
    {
    x: 0.7080767750740051,
    y: 0.9925420880317688
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5294589996337891,
    bounding_box: [
    {
    x: 0.8250691294670105,
    y: 0.6306498646736145
    },
    {
    x: 0.9599714875221252,
    y: 0.6306498646736145
    },
    {
    x: 0.9599714875221252,
    y: 0.7914553284645081
    },
    {
    x: 0.8250691294670105,
    y: 0.7914553284645081
    }
    ]
    },
    {
    description: "Guitar",
    confidence: 0.5128452181816101,
    bounding_box: [
    {
    x: 0.002448874060064554,
    y: 0.6056926846504211
    },
    {
    x: 0.10171099752187729,
    y: 0.6056926846504211
    },
    {
    x: 0.10171099752187729,
    y: 0.997225821018219
    },
    {
    x: 0.002448874060064554,
    y: 0.997225821018219
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 53129,
    time_in_milliseconds: 1772737,
    labels: [
    {
    description: "Library",
    confidence: 85.33704280853271
    },
    {
    description: "Book",
    confidence: 76.52376294136047
    },
    {
    description: "Bookcase",
    confidence: 72.24631905555725
    },
    {
    description: "Publication",
    confidence: 63.64659070968628
    },
    {
    description: "Furniture",
    confidence: 56.74918293952942
    }
    ],
    objects: [
    {
    description: "Bookcase",
    confidence: 0.8814505338668823,
    bounding_box: [
    {
    x: 0.6280384659767151,
    y: 0.023082338273525238
    },
    {
    x: 0.9817125201225281,
    y: 0.023082338273525238
    },
    {
    x: 0.9817125201225281,
    y: 0.9863767027854919
    },
    {
    x: 0.6280384659767151,
    y: 0.9863767027854919
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8289822936058044,
    bounding_box: [
    {
    x: 0.27629002928733826,
    y: 0.255198210477829
    },
    {
    x: 0.7021262645721436,
    y: 0.255198210477829
    },
    {
    x: 0.7021262645721436,
    y: 0.9899308681488037
    },
    {
    x: 0.27629002928733826,
    y: 0.9899308681488037
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.65510094165802,
    bounding_box: [
    {
    x: 0.3803553283214569,
    y: 0.4327433109283447
    },
    {
    x: 0.6953199505805969,
    y: 0.4327433109283447
    },
    {
    x: 0.6953199505805969,
    y: 0.9889662265777588
    },
    {
    x: 0.3803553283214569,
    y: 0.9889662265777588
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 53274,
    time_in_milliseconds: 1777575,
    labels: [
    {
    description: "Television program",
    confidence: 93.85143518447876
    },
    {
    description: "Newscaster",
    confidence: 77.65620946884155
    },
    {
    description: "News",
    confidence: 74.11618232727051
    },
    {
    description: "Television presenter",
    confidence: 74.05170202255249
    },
    {
    description: "Media",
    confidence: 65.32303094863892
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Newsreader",
    confidence: 57.73985981941223
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8472445607185364,
    bounding_box: [
    {
    x: 0.5829355120658875,
    y: 0.10260265320539474
    },
    {
    x: 0.8114018440246582,
    y: 0.10260265320539474
    },
    {
    x: 0.8114018440246582,
    y: 0.4983525574207306
    },
    {
    x: 0.5829355120658875,
    y: 0.4983525574207306
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8409655690193176,
    bounding_box: [
    {
    x: 0.15022529661655426,
    y: 0.1159488782286644
    },
    {
    x: 0.41060441732406616,
    y: 0.1159488782286644
    },
    {
    x: 0.41060441732406616,
    y: 0.49673768877983093
    },
    {
    x: 0.15022529661655426,
    y: 0.49673768877983093
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8220980167388916,
    bounding_box: [
    {
    x: 0.20348191261291504,
    y: 0.5225335955619812
    },
    {
    x: 0.4365069568157196,
    y: 0.5225335955619812
    },
    {
    x: 0.4365069568157196,
    y: 0.9223025441169739
    },
    {
    x: 0.20348191261291504,
    y: 0.9223025441169739
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7677144408226013,
    bounding_box: [
    {
    x: 0.6189277768135071,
    y: 0.5158765912055969
    },
    {
    x: 0.8095893263816833,
    y: 0.5158765912055969
    },
    {
    x: 0.8095893263816833,
    y: 0.925818681716919
    },
    {
    x: 0.6189277768135071,
    y: 0.925818681716919
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7099469304084778,
    bounding_box: [
    {
    x: 0.17257805168628693,
    y: 0.1633756309747696
    },
    {
    x: 0.41387245059013367,
    y: 0.1633756309747696
    },
    {
    x: 0.41387245059013367,
    y: 0.4963672161102295
    },
    {
    x: 0.17257805168628693,
    y: 0.4963672161102295
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6293323040008545,
    bounding_box: [
    {
    x: 0.3407458961009979,
    y: 0.07232125103473663
    },
    {
    x: 0.5036909580230713,
    y: 0.07232125103473663
    },
    {
    x: 0.5036909580230713,
    y: 0.49694913625717163
    },
    {
    x: 0.3407458961009979,
    y: 0.49694913625717163
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5923060178756714,
    bounding_box: [
    {
    x: 0.5854422450065613,
    y: 0.12679941952228546
    },
    {
    x: 0.8088469505310059,
    y: 0.12679941952228546
    },
    {
    x: 0.8088469505310059,
    y: 0.4996182918548584
    },
    {
    x: 0.5854422450065613,
    y: 0.4996182918548584
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5420752763748169,
    bounding_box: [
    {
    x: 0.2049570083618164,
    y: 0.6674523949623108
    },
    {
    x: 0.4339733123779297,
    y: 0.6674523949623108
    },
    {
    x: 0.4339733123779297,
    y: 0.9174256324768066
    },
    {
    x: 0.2049570083618164,
    y: 0.9174256324768066
    }
    ]
    },
    {
    description: "Coat",
    confidence: 0.5038774609565735,
    bounding_box: [
    {
    x: 0.2027100920677185,
    y: 0.546436607837677
    },
    {
    x: 0.425741583108902,
    y: 0.546436607837677
    },
    {
    x: 0.425741583108902,
    y: 0.9190897941589355
    },
    {
    x: 0.2027100920677185,
    y: 0.9190897941589355
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 53419,
    time_in_milliseconds: 1782414,
    labels: [
    {
    description: "Text",
    confidence: 96.9158411026001
    },
    {
    description: "Font",
    confidence: 80.4016649723053
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Icon",
    confidence: 61.083823442459106
    },
    {
    description: "Screenshot",
    confidence: 54.2565643787384
    },
    {
    description: "Rectangle",
    confidence: 50.47368407249451
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 53709,
    time_in_milliseconds: 1792091.0000000002,
    labels: [
    {
    description: "Television program",
    confidence: 94.07369494438171
    },
    {
    description: "News",
    confidence: 81.88608288764954
    },
    {
    description: "Newscaster",
    confidence: 80.32768964767456
    },
    {
    description: "Television presenter",
    confidence: 72.4654495716095
    },
    {
    description: "Media",
    confidence: 70.31227350234985
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Newsreader",
    confidence: 59.705954790115356
    },
    {
    description: "Television",
    confidence: 50.44626593589783
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8713430166244507,
    bounding_box: [
    {
    x: 0.17366425693035126,
    y: 0.12216237187385559
    },
    {
    x: 0.4184662103652954,
    y: 0.12216237187385559
    },
    {
    x: 0.4184662103652954,
    y: 0.4957786500453949
    },
    {
    x: 0.17366425693035126,
    y: 0.4957786500453949
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8440423011779785,
    bounding_box: [
    {
    x: 0.20217569172382355,
    y: 0.5212211012840271
    },
    {
    x: 0.43755626678466797,
    y: 0.5212211012840271
    },
    {
    x: 0.43755626678466797,
    y: 0.9224288463592529
    },
    {
    x: 0.20217569172382355,
    y: 0.9224288463592529
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8397858738899231,
    bounding_box: [
    {
    x: 0.6143176555633545,
    y: 0.5104289650917053
    },
    {
    x: 0.829261302947998,
    y: 0.5104289650917053
    },
    {
    x: 0.829261302947998,
    y: 0.931471049785614
    },
    {
    x: 0.6143176555633545,
    y: 0.931471049785614
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.832582950592041,
    bounding_box: [
    {
    x: 0.6073673367500305,
    y: 0.12610045075416565
    },
    {
    x: 0.8111536502838135,
    y: 0.12610045075416565
    },
    {
    x: 0.8111536502838135,
    y: 0.4937930405139923
    },
    {
    x: 0.6073673367500305,
    y: 0.4937930405139923
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6882618069648743,
    bounding_box: [
    {
    x: 0.20232230424880981,
    y: 0.5630808472633362
    },
    {
    x: 0.4274963438510895,
    y: 0.5630808472633362
    },
    {
    x: 0.4274963438510895,
    y: 0.9197939038276672
    },
    {
    x: 0.20232230424880981,
    y: 0.9197939038276672
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6662781238555908,
    bounding_box: [
    {
    x: 0.34320351481437683,
    y: 0.06976675242185593
    },
    {
    x: 0.5017415881156921,
    y: 0.06976675242185593
    },
    {
    x: 0.5017415881156921,
    y: 0.4964880049228668
    },
    {
    x: 0.34320351481437683,
    y: 0.4964880049228668
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5890119671821594,
    bounding_box: [
    {
    x: 0.6072130799293518,
    y: 0.15328963100910187
    },
    {
    x: 0.8118205070495605,
    y: 0.15328963100910187
    },
    {
    x: 0.8118205070495605,
    y: 0.4947643280029297
    },
    {
    x: 0.6072130799293518,
    y: 0.4947643280029297
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5829638838768005,
    bounding_box: [
    {
    x: 0.6281559467315674,
    y: 0.6922017931938171
    },
    {
    x: 0.7990062832832336,
    y: 0.6922017931938171
    },
    {
    x: 0.7990062832832336,
    y: 0.929010808467865
    },
    {
    x: 0.6281559467315674,
    y: 0.929010808467865
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5022753477096558,
    bounding_box: [
    {
    x: 0.20564979314804077,
    y: 0.6671866774559021
    },
    {
    x: 0.4340033233165741,
    y: 0.6671866774559021
    },
    {
    x: 0.4340033233165741,
    y: 0.9182291030883789
    },
    {
    x: 0.20564979314804077,
    y: 0.9182291030883789
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 53883,
    time_in_milliseconds: 1797896.0000000002,
    labels: [
    {
    description: "Text",
    confidence: 96.69932126998901
    },
    {
    description: "Font",
    confidence: 80.4016649723053
    },
    {
    description: "Technology",
    confidence: 73.20890426635742
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    },
    {
    description: "Screenshot",
    confidence: 58.81657004356384
    },
    {
    description: "Icon",
    confidence: 51.391929388046265
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 54144,
    time_in_milliseconds: 1806604,
    labels: [
    {
    description: "Television program",
    confidence: 94.73503828048706
    },
    {
    description: "Newscaster",
    confidence: 85.94812750816345
    },
    {
    description: "News",
    confidence: 81.2539279460907
    },
    {
    description: "Television presenter",
    confidence: 70.27566432952881
    },
    {
    description: "Newsreader",
    confidence: 68.93330216407776
    },
    {
    description: "Media",
    confidence: 67.45004653930664
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9012833833694458,
    bounding_box: [
    {
    x: 0.18521648645401,
    y: 0.10442128032445908
    },
    {
    x: 0.4017016589641571,
    y: 0.10442128032445908
    },
    {
    x: 0.4017016589641571,
    y: 0.5003476738929749
    },
    {
    x: 0.18521648645401,
    y: 0.5003476738929749
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.856288492679596,
    bounding_box: [
    {
    x: 0.6056234240531921,
    y: 0.11643893271684647
    },
    {
    x: 0.8111079335212708,
    y: 0.11643893271684647
    },
    {
    x: 0.8111079335212708,
    y: 0.49594107270240784
    },
    {
    x: 0.6056234240531921,
    y: 0.49594107270240784
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.823777437210083,
    bounding_box: [
    {
    x: 0.6154608726501465,
    y: 0.5217025279998779
    },
    {
    x: 0.8237497806549072,
    y: 0.5217025279998779
    },
    {
    x: 0.8237497806549072,
    y: 0.9311240315437317
    },
    {
    x: 0.6154608726501465,
    y: 0.9311240315437317
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8153662085533142,
    bounding_box: [
    {
    x: 0.2019502967596054,
    y: 0.5304033160209656
    },
    {
    x: 0.43251216411590576,
    y: 0.5304033160209656
    },
    {
    x: 0.43251216411590576,
    y: 0.9224624037742615
    },
    {
    x: 0.2019502967596054,
    y: 0.9224624037742615
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6799721717834473,
    bounding_box: [
    {
    x: 0.3416960537433624,
    y: 0.0707954689860344
    },
    {
    x: 0.5011207461357117,
    y: 0.0707954689860344
    },
    {
    x: 0.5011207461357117,
    y: 0.5003935694694519
    },
    {
    x: 0.3416960537433624,
    y: 0.5003935694694519
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6673387885093689,
    bounding_box: [
    {
    x: 0.20047108829021454,
    y: 0.5644772052764893
    },
    {
    x: 0.42404308915138245,
    y: 0.5644772052764893
    },
    {
    x: 0.42404308915138245,
    y: 0.9189878106117249
    },
    {
    x: 0.20047108829021454,
    y: 0.9189878106117249
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6413122415542603,
    bounding_box: [
    {
    x: 0.6169421076774597,
    y: 0.5269766449928284
    },
    {
    x: 0.8198429942131042,
    y: 0.5269766449928284
    },
    {
    x: 0.8198429942131042,
    y: 0.9244293570518494
    },
    {
    x: 0.6169421076774597,
    y: 0.9244293570518494
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5666559934616089,
    bounding_box: [
    {
    x: 0.6053721308708191,
    y: 0.14424102008342743
    },
    {
    x: 0.8110318779945374,
    y: 0.14424102008342743
    },
    {
    x: 0.8110318779945374,
    y: 0.496349573135376
    },
    {
    x: 0.6053721308708191,
    y: 0.496349573135376
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5248464941978455,
    bounding_box: [
    {
    x: 0.6094409823417664,
    y: 0.11101751774549484
    },
    {
    x: 0.8042551875114441,
    y: 0.11101751774549484
    },
    {
    x: 0.8042551875114441,
    y: 0.4896813929080963
    },
    {
    x: 0.6094409823417664,
    y: 0.4896813929080963
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 54231,
    time_in_milliseconds: 1809508,
    labels: [
    {
    description: "Red",
    confidence: 96.13075256347656
    },
    {
    description: "Rose",
    confidence: 92.95228123664856
    },
    {
    description: "Pink",
    confidence: 92.15061068534851
    },
    {
    description: "Skin",
    confidence: 89.55914974212646
    },
    {
    description: "Beauty",
    confidence: 88.88567686080933
    },
    {
    description: "Rose family",
    confidence: 87.26897239685059
    },
    {
    description: "Flower",
    confidence: 84.70141887664795
    },
    {
    description: "Petal",
    confidence: 81.91075921058655
    },
    {
    description: "Organ",
    confidence: 78.70044112205505
    },
    {
    description: "Garden roses",
    confidence: 77.21019387245178
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.9040705561637878,
    bounding_box: [
    {
    x: 0.23396165668964386,
    y: 0.4596259295940399
    },
    {
    x: 0.6058545708656311,
    y: 0.4596259295940399
    },
    {
    x: 0.6058545708656311,
    y: 0.987476110458374
    },
    {
    x: 0.23396165668964386,
    y: 0.987476110458374
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8891549706459045,
    bounding_box: [
    {
    x: 0.23817288875579834,
    y: 0.2100043147802353
    },
    {
    x: 0.6072701215744019,
    y: 0.2100043147802353
    },
    {
    x: 0.6072701215744019,
    y: 0.9912144541740417
    },
    {
    x: 0.23817288875579834,
    y: 0.9912144541740417
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.8421503305435181,
    bounding_box: [
    {
    x: 0.4013529121875763,
    y: 0.583274245262146
    },
    {
    x: 0.4707880914211273,
    y: 0.583274245262146
    },
    {
    x: 0.4707880914211273,
    y: 0.9051321148872375
    },
    {
    x: 0.4013529121875763,
    y: 0.9051321148872375
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 54318,
    time_in_milliseconds: 1812410,
    labels: [
    {
    description: "Library",
    confidence: 85.99100112915039
    },
    {
    description: "Bookcase",
    confidence: 79.54884171485901
    },
    {
    description: "Glasses",
    confidence: 74.8481273651123
    },
    {
    description: "Book",
    confidence: 73.03446531295776
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    },
    {
    description: "Shelving",
    confidence: 62.84765601158142
    },
    {
    description: "Furniture",
    confidence: 61.48442625999451
    },
    {
    description: "Publication",
    confidence: 59.97973680496216
    },
    {
    description: "Author",
    confidence: 52.63846516609192
    },
    {
    description: "Librarian",
    confidence: 52.47353911399841
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9199104905128479,
    bounding_box: [
    {
    x: 0.4415437877178192,
    y: 0.37860795855522156
    },
    {
    x: 0.5738472938537598,
    y: 0.37860795855522156
    },
    {
    x: 0.5738472938537598,
    y: 0.4689514636993408
    },
    {
    x: 0.4415437877178192,
    y: 0.4689514636993408
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8948240280151367,
    bounding_box: [
    {
    x: 0.6229282021522522,
    y: 0.02329368144273758
    },
    {
    x: 0.980130672454834,
    y: 0.02329368144273758
    },
    {
    x: 0.980130672454834,
    y: 0.9829857349395752
    },
    {
    x: 0.6229282021522522,
    y: 0.9829857349395752
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8655256032943726,
    bounding_box: [
    {
    x: 0.3338634967803955,
    y: 0.2260715514421463
    },
    {
    x: 0.7630221843719482,
    y: 0.2260715514421463
    },
    {
    x: 0.7630221843719482,
    y: 0.9973958134651184
    },
    {
    x: 0.3338634967803955,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 54608,
    time_in_milliseconds: 1822086,
    labels: [
    {
    description: "Television program",
    confidence: 94.51532363891602
    },
    {
    description: "News",
    confidence: 82.48612880706787
    },
    {
    description: "Newscaster",
    confidence: 80.32768964767456
    },
    {
    description: "Television presenter",
    confidence: 71.4025616645813
    },
    {
    description: "Media",
    confidence: 67.45004653930664
    },
    {
    description: "Newsreader",
    confidence: 63.14414739608765
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8763189315795898,
    bounding_box: [
    {
    x: 0.1960887461900711,
    y: 0.13558563590049744
    },
    {
    x: 0.40735796093940735,
    y: 0.13558563590049744
    },
    {
    x: 0.40735796093940735,
    y: 0.4992441236972809
    },
    {
    x: 0.1960887461900711,
    y: 0.4992441236972809
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8419696092605591,
    bounding_box: [
    {
    x: 0.5946769118309021,
    y: 0.13153357803821564
    },
    {
    x: 0.8136422038078308,
    y: 0.13153357803821564
    },
    {
    x: 0.8136422038078308,
    y: 0.49411624670028687
    },
    {
    x: 0.5946769118309021,
    y: 0.49411624670028687
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8309664130210876,
    bounding_box: [
    {
    x: 0.2002137452363968,
    y: 0.516818106174469
    },
    {
    x: 0.41037991642951965,
    y: 0.516818106174469
    },
    {
    x: 0.41037991642951965,
    y: 0.9226517677307129
    },
    {
    x: 0.2002137452363968,
    y: 0.9226517677307129
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7967146039009094,
    bounding_box: [
    {
    x: 0.6276718974113464,
    y: 0.5153989791870117
    },
    {
    x: 0.8196389675140381,
    y: 0.5153989791870117
    },
    {
    x: 0.8196389675140381,
    y: 0.9258764386177063
    },
    {
    x: 0.6276718974113464,
    y: 0.9258764386177063
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7348528504371643,
    bounding_box: [
    {
    x: 0.19437234103679657,
    y: 0.15074372291564941
    },
    {
    x: 0.41121748089790344,
    y: 0.15074372291564941
    },
    {
    x: 0.41121748089790344,
    y: 0.4980714023113251
    },
    {
    x: 0.19437234103679657,
    y: 0.4980714023113251
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6915980577468872,
    bounding_box: [
    {
    x: 0.6284312009811401,
    y: 0.5243728160858154
    },
    {
    x: 0.8155558705329895,
    y: 0.5243728160858154
    },
    {
    x: 0.8155558705329895,
    y: 0.9191591143608093
    },
    {
    x: 0.6284312009811401,
    y: 0.9191591143608093
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5957971811294556,
    bounding_box: [
    {
    x: 0.19630615413188934,
    y: 0.5356156229972839
    },
    {
    x: 0.4069019556045532,
    y: 0.5356156229972839
    },
    {
    x: 0.4069019556045532,
    y: 0.9206302762031555
    },
    {
    x: 0.19630615413188934,
    y: 0.9206302762031555
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.5769988298416138,
    bounding_box: [
    {
    x: 0.34524986147880554,
    y: 0.07017995417118073
    },
    {
    x: 0.5000720620155334,
    y: 0.07017995417118073
    },
    {
    x: 0.5000720620155334,
    y: 0.4935898780822754
    },
    {
    x: 0.34524986147880554,
    y: 0.4935898780822754
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5501506924629211,
    bounding_box: [
    {
    x: 0.5949528217315674,
    y: 0.16070036590099335
    },
    {
    x: 0.8122535347938538,
    y: 0.16070036590099335
    },
    {
    x: 0.8122535347938538,
    y: 0.4959132671356201
    },
    {
    x: 0.5949528217315674,
    y: 0.4959132671356201
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 55072,
    time_in_milliseconds: 1837568,
    labels: [
    {
    description: "Media",
    confidence: 74.43045973777771
    },
    {
    description: "Bookcase",
    confidence: 63.09630870819092
    },
    {
    description: "Library",
    confidence: 60.70360541343689
    },
    {
    description: "Glasses",
    confidence: 59.71137881278992
    },
    {
    description: "Furniture",
    confidence: 56.74918293952942
    },
    {
    description: "Television",
    confidence: 56.08621835708618
    },
    {
    description: "Shelving",
    confidence: 53.6568284034729
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9370741248130798,
    bounding_box: [
    {
    x: 0.5092648267745972,
    y: 0.39366233348846436
    },
    {
    x: 0.6523003578186035,
    y: 0.39366233348846436
    },
    {
    x: 0.6523003578186035,
    y: 0.49466678500175476
    },
    {
    x: 0.5092648267745972,
    y: 0.49466678500175476
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8148703575134277,
    bounding_box: [
    {
    x: 0.2707306444644928,
    y: 0.23573346436023712
    },
    {
    x: 0.7729835510253906,
    y: 0.23573346436023712
    },
    {
    x: 0.7729835510253906,
    y: 0.9946389198303223
    },
    {
    x: 0.2707306444644928,
    y: 0.9946389198303223
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.7497204542160034,
    bounding_box: [
    {
    x: 0.6241520047187805,
    y: 0.02247556857764721
    },
    {
    x: 0.9788479804992676,
    y: 0.02247556857764721
    },
    {
    x: 0.9788479804992676,
    y: 0.9852721095085144
    },
    {
    x: 0.6241520047187805,
    y: 0.9852721095085144
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6782052516937256,
    bounding_box: [
    {
    x: 0.2888742983341217,
    y: 0.5714977383613586
    },
    {
    x: 0.766202986240387,
    y: 0.5714977383613586
    },
    {
    x: 0.766202986240387,
    y: 0.9864811897277832
    },
    {
    x: 0.2888742983341217,
    y: 0.9864811897277832
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 55304,
    time_in_milliseconds: 1845310.0000000002,
    labels: [
    {
    description: "Television program",
    confidence: 93.73987913131714
    },
    {
    description: "News",
    confidence: 83.33110213279724
    },
    {
    description: "Newscaster",
    confidence: 83.2172155380249
    },
    {
    description: "Television presenter",
    confidence: 72.25760817527771
    },
    {
    description: "Media",
    confidence: 69.79514360427856
    },
    {
    description: "Newsreader",
    confidence: 66.96739196777344
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8523950576782227,
    bounding_box: [
    {
    x: 0.17904604971408844,
    y: 0.12254530191421509
    },
    {
    x: 0.3883720338344574,
    y: 0.12254530191421509
    },
    {
    x: 0.3883720338344574,
    y: 0.49837708473205566
    },
    {
    x: 0.17904604971408844,
    y: 0.49837708473205566
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8399180173873901,
    bounding_box: [
    {
    x: 0.5968198180198669,
    y: 0.13026975095272064
    },
    {
    x: 0.814196765422821,
    y: 0.13026975095272064
    },
    {
    x: 0.814196765422821,
    y: 0.49282485246658325
    },
    {
    x: 0.5968198180198669,
    y: 0.49282485246658325
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8361911177635193,
    bounding_box: [
    {
    x: 0.2000046819448471,
    y: 0.5178150534629822
    },
    {
    x: 0.41576799750328064,
    y: 0.5178150534629822
    },
    {
    x: 0.41576799750328064,
    y: 0.9232713580131531
    },
    {
    x: 0.2000046819448471,
    y: 0.9232713580131531
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7793532013893127,
    bounding_box: [
    {
    x: 0.6191404461860657,
    y: 0.5236495137214661
    },
    {
    x: 0.8100357055664062,
    y: 0.5236495137214661
    },
    {
    x: 0.8100357055664062,
    y: 0.9210354685783386
    },
    {
    x: 0.6191404461860657,
    y: 0.9210354685783386
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7768034338951111,
    bounding_box: [
    {
    x: 0.1824570745229721,
    y: 0.1464460790157318
    },
    {
    x: 0.3917205333709717,
    y: 0.1464460790157318
    },
    {
    x: 0.3917205333709717,
    y: 0.4992988407611847
    },
    {
    x: 0.1824570745229721,
    y: 0.4992988407611847
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6866106986999512,
    bounding_box: [
    {
    x: 0.3428713381290436,
    y: 0.06886279582977295
    },
    {
    x: 0.5020373463630676,
    y: 0.06886279582977295
    },
    {
    x: 0.5020373463630676,
    y: 0.5015787482261658
    },
    {
    x: 0.3428713381290436,
    y: 0.5015787482261658
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6637481451034546,
    bounding_box: [
    {
    x: 0.5974909663200378,
    y: 0.1549447923898697
    },
    {
    x: 0.8118652701377869,
    y: 0.1549447923898697
    },
    {
    x: 0.8118652701377869,
    y: 0.49450838565826416
    },
    {
    x: 0.5974909663200378,
    y: 0.49450838565826416
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6411457061767578,
    bounding_box: [
    {
    x: 0.1964220255613327,
    y: 0.5449677109718323
    },
    {
    x: 0.41067031025886536,
    y: 0.5449677109718323
    },
    {
    x: 0.41067031025886536,
    y: 0.9219169020652771
    },
    {
    x: 0.1964220255613327,
    y: 0.9219169020652771
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.6048941612243652,
    bounding_box: [
    {
    x: 0.6802769303321838,
    y: 0.7063278555870056
    },
    {
    x: 0.773476779460907,
    y: 0.7063278555870056
    },
    {
    x: 0.773476779460907,
    y: 0.9111027121543884
    },
    {
    x: 0.6802769303321838,
    y: 0.9111027121543884
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 55942,
    time_in_milliseconds: 1866598.0000000002,
    labels: [
    {
    description: "Library",
    confidence: 82.15657472610474
    },
    {
    description: "Bookcase",
    confidence: 72.81899452209473
    },
    {
    description: "Book",
    confidence: 72.54347205162048
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Publication",
    confidence: 61.1176073551178
    },
    {
    description: "Shelving",
    confidence: 54.762256145477295
    },
    {
    description: "Author",
    confidence: 53.92909049987793
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.931005597114563,
    bounding_box: [
    {
    x: 0.4087807238101959,
    y: 0.3667861223220825
    },
    {
    x: 0.5381588935852051,
    y: 0.3667861223220825
    },
    {
    x: 0.5381588935852051,
    y: 0.45963752269744873
    },
    {
    x: 0.4087807238101959,
    y: 0.45963752269744873
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8807681798934937,
    bounding_box: [
    {
    x: 0.6184108853340149,
    y: 0.029994197189807892
    },
    {
    x: 0.978888988494873,
    y: 0.029994197189807892
    },
    {
    x: 0.978888988494873,
    y: 0.9827437400817871
    },
    {
    x: 0.6184108853340149,
    y: 0.9827437400817871
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7921706438064575,
    bounding_box: [
    {
    x: 0.30655017495155334,
    y: 0.2187633514404297
    },
    {
    x: 0.7626184821128845,
    y: 0.2187633514404297
    },
    {
    x: 0.7626184821128845,
    y: 0.9866223335266113
    },
    {
    x: 0.30655017495155334,
    y: 0.9866223335266113
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 56203,
    time_in_milliseconds: 1875307,
    labels: [
    {
    description: "Fur",
    confidence: 96.90845012664795
    },
    {
    description: "Long hair",
    confidence: 79.55131530761719
    },
    {
    description: "Fur clothing",
    confidence: 72.91516661643982
    },
    {
    description: "Canidae",
    confidence: 61.11551523208618
    },
    {
    description: "Dog breed",
    confidence: 60.0924551486969
    },
    {
    description: "Companion dog",
    confidence: 59.79946851730347
    },
    {
    description: "Shih tzu",
    confidence: 57.24225044250488
    },
    {
    description: "Non-Sporting Group",
    confidence: 56.7579984664917
    },
    {
    description: "Fashion accessory",
    confidence: 54.58008050918579
    },
    {
    description: "Black hair",
    confidence: 51.65854096412659
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.9096732139587402,
    bounding_box: [
    {
    x: 0.2321057915687561,
    y: 0.3150498569011688
    },
    {
    x: 0.6510237455368042,
    y: 0.3150498569011688
    },
    {
    x: 0.6510237455368042,
    y: 0.9859747886657715
    },
    {
    x: 0.2321057915687561,
    y: 0.9859747886657715
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8142359256744385,
    bounding_box: [
    {
    x: 0.14884783327579498,
    y: 0.00888617243617773
    },
    {
    x: 0.7433685660362244,
    y: 0.00888617243617773
    },
    {
    x: 0.7433685660362244,
    y: 0.9973958134651184
    },
    {
    x: 0.14884783327579498,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Belt",
    confidence: 0.7200387716293335,
    bounding_box: [
    {
    x: 0.36263033747673035,
    y: 0.8856348395347595
    },
    {
    x: 0.5084611177444458,
    y: 0.8856348395347595
    },
    {
    x: 0.5084611177444458,
    y: 0.9935824275016785
    },
    {
    x: 0.36263033747673035,
    y: 0.9935824275016785
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5618632435798645,
    bounding_box: [
    {
    x: 0.7600491046905518,
    y: 0.5463405847549438
    },
    {
    x: 0.8816214203834534,
    y: 0.5463405847549438
    },
    {
    x: 0.8816214203834534,
    y: 0.7250888347625732
    },
    {
    x: 0.7600491046905518,
    y: 0.7250888347625732
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5388467907905579,
    bounding_box: [
    {
    x: 0.8058838844299316,
    y: 0.16569305956363678
    },
    {
    x: 0.908830463886261,
    y: 0.16569305956363678
    },
    {
    x: 0.908830463886261,
    y: 0.41486021876335144
    },
    {
    x: 0.8058838844299316,
    y: 0.41486021876335144
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 56580,
    time_in_milliseconds: 1887886.0000000002,
    labels: [
    {
    description: "Television program",
    confidence: 93.96269917488098
    },
    {
    description: "Newscaster",
    confidence: 81.89145922660828
    },
    {
    description: "News",
    confidence: 77.06887125968933
    },
    {
    description: "Television presenter",
    confidence: 71.83493971824646
    },
    {
    description: "Media",
    confidence: 64.52412009239197
    },
    {
    description: "Newsreader",
    confidence: 62.53387928009033
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.880202054977417,
    bounding_box: [
    {
    x: 0.6193724274635315,
    y: 0.5162174105644226
    },
    {
    x: 0.8135073184967041,
    y: 0.5162174105644226
    },
    {
    x: 0.8135073184967041,
    y: 0.9286553859710693
    },
    {
    x: 0.6193724274635315,
    y: 0.9286553859710693
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8764512538909912,
    bounding_box: [
    {
    x: 0.6067870855331421,
    y: 0.12842495739459991
    },
    {
    x: 0.8085383772850037,
    y: 0.12842495739459991
    },
    {
    x: 0.8085383772850037,
    y: 0.49526941776275635
    },
    {
    x: 0.6067870855331421,
    y: 0.49526941776275635
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8679826855659485,
    bounding_box: [
    {
    x: 0.1816541999578476,
    y: 0.11289534717798233
    },
    {
    x: 0.46611058712005615,
    y: 0.11289534717798233
    },
    {
    x: 0.46611058712005615,
    y: 0.5013324618339539
    },
    {
    x: 0.1816541999578476,
    y: 0.5013324618339539
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8465186953544617,
    bounding_box: [
    {
    x: 0.1918839067220688,
    y: 0.5115112662315369
    },
    {
    x: 0.3862665891647339,
    y: 0.5115112662315369
    },
    {
    x: 0.3862665891647339,
    y: 0.9163109660148621
    },
    {
    x: 0.1918839067220688,
    y: 0.9163109660148621
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7275798916816711,
    bounding_box: [
    {
    x: 0.18557631969451904,
    y: 0.2774409353733063
    },
    {
    x: 0.4638087749481201,
    y: 0.2774409353733063
    },
    {
    x: 0.4638087749481201,
    y: 0.4957563877105713
    },
    {
    x: 0.18557631969451904,
    y: 0.4957563877105713
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7163943648338318,
    bounding_box: [
    {
    x: 0.6222690939903259,
    y: 0.5226306319236755
    },
    {
    x: 0.8111581802368164,
    y: 0.5226306319236755
    },
    {
    x: 0.8111581802368164,
    y: 0.9171748161315918
    },
    {
    x: 0.6222690939903259,
    y: 0.9171748161315918
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6875532269477844,
    bounding_box: [
    {
    x: 0.6072807908058167,
    y: 0.2728206515312195
    },
    {
    x: 0.8012940287590027,
    y: 0.2728206515312195
    },
    {
    x: 0.8012940287590027,
    y: 0.4940456449985504
    },
    {
    x: 0.6072807908058167,
    y: 0.4940456449985504
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.6642487645149231,
    bounding_box: [
    {
    x: 0.6720554232597351,
    y: 0.7055479884147644
    },
    {
    x: 0.7673904299736023,
    y: 0.7055479884147644
    },
    {
    x: 0.7673904299736023,
    y: 0.9098410606384277
    },
    {
    x: 0.6720554232597351,
    y: 0.9098410606384277
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.5399333238601685,
    bounding_box: [
    {
    x: 0.3420765697956085,
    y: 0.0684899315237999
    },
    {
    x: 0.4993574619293213,
    y: 0.0684899315237999
    },
    {
    x: 0.4993574619293213,
    y: 0.48589026927948
    },
    {
    x: 0.3420765697956085,
    y: 0.48589026927948
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 57131,
    time_in_milliseconds: 1906271.0000000002,
    labels: [
    {
    description: "Fur",
    confidence: 95.88156342506409
    },
    {
    description: "Long hair",
    confidence: 80.94472289085388
    },
    {
    description: "Fur clothing",
    confidence: 76.10112428665161
    },
    {
    description: "Textile",
    confidence: 65.63324928283691
    },
    {
    description: "Fashion accessory",
    confidence: 65.08980393409729
    },
    {
    description: "Black hair",
    confidence: 60.66192984580994
    },
    {
    description: "Jacket",
    confidence: 56.33876919746399
    },
    {
    description: "Handbag",
    confidence: 55.25553226470947
    },
    {
    description: "Hair coloring",
    confidence: 55.079203844070435
    },
    {
    description: "Brown hair",
    confidence: 53.05126905441284
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.9171450138092041,
    bounding_box: [
    {
    x: 0.33111634850502014,
    y: 0.30780282616615295
    },
    {
    x: 0.6975026726722717,
    y: 0.30780282616615295
    },
    {
    x: 0.6975026726722717,
    y: 0.9893512725830078
    },
    {
    x: 0.33111634850502014,
    y: 0.9893512725830078
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8593000173568726,
    bounding_box: [
    {
    x: 0.33255085349082947,
    y: 0.07093939185142517
    },
    {
    x: 0.7123579382896423,
    y: 0.07093939185142517
    },
    {
    x: 0.7123579382896423,
    y: 0.9973958730697632
    },
    {
    x: 0.33255085349082947,
    y: 0.9973958730697632
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.678204357624054,
    bounding_box: [
    {
    x: 0.8085630536079407,
    y: 0.16664601862430573
    },
    {
    x: 0.9059350490570068,
    y: 0.16664601862430573
    },
    {
    x: 0.9059350490570068,
    y: 0.41421160101890564
    },
    {
    x: 0.8085630536079407,
    y: 0.41421160101890564
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.6613538861274719,
    bounding_box: [
    {
    x: 0.7620956897735596,
    y: 0.5476541519165039
    },
    {
    x: 0.8787033557891846,
    y: 0.5476541519165039
    },
    {
    x: 0.8787033557891846,
    y: 0.7251976132392883
    },
    {
    x: 0.7620956897735596,
    y: 0.7251976132392883
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5847676992416382,
    bounding_box: [
    {
    x: 0.6775948405265808,
    y: 0.5487640500068665
    },
    {
    x: 0.7593218684196472,
    y: 0.5487640500068665
    },
    {
    x: 0.7593218684196472,
    y: 0.7134783864021301
    },
    {
    x: 0.6775948405265808,
    y: 0.7134783864021301
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 57537,
    time_in_milliseconds: 1919818,
    labels: [
    {
    description: "Television program",
    confidence: 94.73503828048706
    },
    {
    description: "Newscaster",
    confidence: 87.53042221069336
    },
    {
    description: "News",
    confidence: 85.3078544139862
    },
    {
    description: "Television presenter",
    confidence: 77.21598148345947
    },
    {
    description: "Newsreader",
    confidence: 75.27446150779724
    },
    {
    description: "Media",
    confidence: 72.57829308509827
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Electronic device",
    confidence: 60.98915934562683
    },
    {
    description: "Television",
    confidence: 56.08621835708618
    },
    {
    description: "Journalist",
    confidence: 52.44980454444885
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8887848854064941,
    bounding_box: [
    {
    x: 0.6104283928871155,
    y: 0.12807896733283997
    },
    {
    x: 0.8122484087944031,
    y: 0.12807896733283997
    },
    {
    x: 0.8122484087944031,
    y: 0.4950662851333618
    },
    {
    x: 0.6104283928871155,
    y: 0.4950662851333618
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8686890006065369,
    bounding_box: [
    {
    x: 0.2061791568994522,
    y: 0.5165790915489197
    },
    {
    x: 0.41231802105903625,
    y: 0.5165790915489197
    },
    {
    x: 0.41231802105903625,
    y: 0.9201171398162842
    },
    {
    x: 0.2061791568994522,
    y: 0.9201171398162842
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8672028183937073,
    bounding_box: [
    {
    x: 0.18077777326107025,
    y: 0.11527488380670547
    },
    {
    x: 0.4712003469467163,
    y: 0.11527488380670547
    },
    {
    x: 0.4712003469467163,
    y: 0.5003755688667297
    },
    {
    x: 0.18077777326107025,
    y: 0.5003755688667297
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.839091956615448,
    bounding_box: [
    {
    x: 0.6189907193183899,
    y: 0.5164571404457092
    },
    {
    x: 0.807604968547821,
    y: 0.5164571404457092
    },
    {
    x: 0.807604968547821,
    y: 0.9170742034912109
    },
    {
    x: 0.6189907193183899,
    y: 0.9170742034912109
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.8096701502799988,
    bounding_box: [
    {
    x: 0.20207899808883667,
    y: 0.5490659475326538
    },
    {
    x: 0.4080566167831421,
    y: 0.5490659475326538
    },
    {
    x: 0.4080566167831421,
    y: 0.9189245104789734
    },
    {
    x: 0.20207899808883667,
    y: 0.9189245104789734
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7648958563804626,
    bounding_box: [
    {
    x: 0.18235482275485992,
    y: 0.2729395627975464
    },
    {
    x: 0.46848440170288086,
    y: 0.2729395627975464
    },
    {
    x: 0.46848440170288086,
    y: 0.49586740136146545
    },
    {
    x: 0.18235482275485992,
    y: 0.49586740136146545
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.7102168202400208,
    bounding_box: [
    {
    x: 0.6115725040435791,
    y: 0.15550346672534943
    },
    {
    x: 0.8104701042175293,
    y: 0.15550346672534943
    },
    {
    x: 0.8104701042175293,
    y: 0.49850547313690186
    },
    {
    x: 0.6115725040435791,
    y: 0.49850547313690186
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.6292856931686401,
    bounding_box: [
    {
    x: 0.6716153621673584,
    y: 0.7036933898925781
    },
    {
    x: 0.7691982388496399,
    y: 0.7036933898925781
    },
    {
    x: 0.7691982388496399,
    y: 0.9108831882476807
    },
    {
    x: 0.6716153621673584,
    y: 0.9108831882476807
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6016541719436646,
    bounding_box: [
    {
    x: 0.6299458146095276,
    y: 0.6930686831474304
    },
    {
    x: 0.7929425239562988,
    y: 0.6930686831474304
    },
    {
    x: 0.7929425239562988,
    y: 0.9275413155555725
    },
    {
    x: 0.6299458146095276,
    y: 0.9275413155555725
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.561240017414093,
    bounding_box: [
    {
    x: 0.6125316619873047,
    y: 0.271954745054245
    },
    {
    x: 0.8034214973449707,
    y: 0.271954745054245
    },
    {
    x: 0.8034214973449707,
    y: 0.49478986859321594
    },
    {
    x: 0.6125316619873047,
    y: 0.49478986859321594
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 58407,
    time_in_milliseconds: 1948846,
    labels: [
    {
    description: "Red",
    confidence: 95.34010887145996
    },
    {
    description: "Beauty",
    confidence: 87.0676040649414
    },
    {
    description: "Rose",
    confidence: 86.97060942649841
    },
    {
    description: "Pink",
    confidence: 84.49604511260986
    },
    {
    description: "Cheek",
    confidence: 79.94794845581055
    },
    {
    description: "Rose family",
    confidence: 76.98363661766052
    },
    {
    description: "Flower",
    confidence: 76.53787136077881
    },
    {
    description: "Human",
    confidence: 75.91204047203064
    },
    {
    description: "Petal",
    confidence: 74.02684092521667
    },
    {
    description: "Photography",
    confidence: 72.45198488235474
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.9289903044700623,
    bounding_box: [
    {
    x: 0.167454794049263,
    y: 0.443534255027771
    },
    {
    x: 0.6309665441513062,
    y: 0.443534255027771
    },
    {
    x: 0.6309665441513062,
    y: 0.9920621514320374
    },
    {
    x: 0.167454794049263,
    y: 0.9920621514320374
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8791696429252625,
    bounding_box: [
    {
    x: 0.14948929846286774,
    y: 0.15212632715702057
    },
    {
    x: 0.6407819986343384,
    y: 0.15212632715702057
    },
    {
    x: 0.6407819986343384,
    y: 0.9973958134651184
    },
    {
    x: 0.14948929846286774,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.7221361994743347,
    bounding_box: [
    {
    x: 0.3561144173145294,
    y: 0.5438686609268188
    },
    {
    x: 0.44241616129875183,
    y: 0.5438686609268188
    },
    {
    x: 0.44241616129875183,
    y: 0.9902369379997253
    },
    {
    x: 0.3561144173145294,
    y: 0.9902369379997253
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 59219,
    time_in_milliseconds: 1975940,
    labels: [
    {
    description: "Red",
    confidence: 96.13075256347656
    },
    {
    description: "Pink",
    confidence: 91.72913432121277
    },
    {
    description: "Beauty",
    confidence: 89.60622549057007
    },
    {
    description: "Skin",
    confidence: 89.55914974212646
    },
    {
    description: "Rose",
    confidence: 88.74686360359192
    },
    {
    description: "Flower",
    confidence: 81.49528503417969
    },
    {
    description: "Rose family",
    confidence: 81.43767714500427
    },
    {
    description: "Cheek",
    confidence: 79.94794845581055
    },
    {
    description: "Petal",
    confidence: 78.81518602371216
    },
    {
    description: "Human",
    confidence: 75.91204047203064
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.9108335375785828,
    bounding_box: [
    {
    x: 0.21128976345062256,
    y: 0.44943130016326904
    },
    {
    x: 0.6181590557098389,
    y: 0.44943130016326904
    },
    {
    x: 0.6181590557098389,
    y: 0.9912670254707336
    },
    {
    x: 0.21128976345062256,
    y: 0.9912670254707336
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8475362062454224,
    bounding_box: [
    {
    x: 0.20719783008098602,
    y: 0.18329457938671112
    },
    {
    x: 0.6295037865638733,
    y: 0.18329457938671112
    },
    {
    x: 0.6295037865638733,
    y: 0.994743824005127
    },
    {
    x: 0.20719783008098602,
    y: 0.994743824005127
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.8141976594924927,
    bounding_box: [
    {
    x: 0.39535021781921387,
    y: 0.6258057951927185
    },
    {
    x: 0.4530923366546631,
    y: 0.6258057951927185
    },
    {
    x: 0.4530923366546631,
    y: 0.9856848120689392
    },
    {
    x: 0.39535021781921387,
    y: 0.9856848120689392
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 59306,
    time_in_milliseconds: 1978843,
    labels: [
    {
    description: "Red",
    confidence: 96.02789282798767
    },
    {
    description: "Beauty",
    confidence: 90.06934762001038
    },
    {
    description: "Pink",
    confidence: 89.94855880737305
    },
    {
    description: "Skin",
    confidence: 89.55914974212646
    },
    {
    description: "Rose",
    confidence: 88.2659375667572
    },
    {
    description: "Flower",
    confidence: 81.49528503417969
    },
    {
    description: "Rose family",
    confidence: 81.05145692825317
    },
    {
    description: "Smile",
    confidence: 78.8342833518982
    },
    {
    description: "Outerwear",
    confidence: 74.90161657333374
    },
    {
    description: "Human",
    confidence: 74.6764600276947
    }
    ],
    objects: [
    {
    description: "Outerwear",
    confidence: 0.9122828245162964,
    bounding_box: [
    {
    x: 0.21368783712387085,
    y: 0.4577813446521759
    },
    {
    x: 0.5950945615768433,
    y: 0.4577813446521759
    },
    {
    x: 0.5950945615768433,
    y: 0.990206241607666
    },
    {
    x: 0.21368783712387085,
    y: 0.990206241607666
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8520859479904175,
    bounding_box: [
    {
    x: 0.2113262414932251,
    y: 0.18664711713790894
    },
    {
    x: 0.6061076521873474,
    y: 0.18664711713790894
    },
    {
    x: 0.6061076521873474,
    y: 0.9945502877235413
    },
    {
    x: 0.2113262414932251,
    y: 0.9945502877235413
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.721519947052002,
    bounding_box: [
    {
    x: 0.39994367957115173,
    y: 0.6104281544685364
    },
    {
    x: 0.458749383687973,
    y: 0.6104281544685364
    },
    {
    x: 0.458749383687973,
    y: 0.8467998504638672
    },
    {
    x: 0.39994367957115173,
    y: 0.8467998504638672
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 59567,
    time_in_milliseconds: 1987552.0000000002,
    labels: [
    {
    description: "Television program",
    confidence: 92.2564446926117
    },
    {
    description: "News",
    confidence: 82.77485370635986
    },
    {
    description: "Newscaster",
    confidence: 78.07886600494385
    },
    {
    description: "Television presenter",
    confidence: 68.82582902908325
    },
    {
    description: "Media",
    confidence: 68.0826723575592
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Newsreader",
    confidence: 58.731478452682495
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8706371188163757,
    bounding_box: [
    {
    x: 0.18026860058307648,
    y: 0.11842280626296997
    },
    {
    x: 0.46446657180786133,
    y: 0.11842280626296997
    },
    {
    x: 0.46446657180786133,
    y: 0.5015208125114441
    },
    {
    x: 0.18026860058307648,
    y: 0.5015208125114441
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8643912672996521,
    bounding_box: [
    {
    x: 0.5980894565582275,
    y: 0.12041422724723816
    },
    {
    x: 0.8240723013877869,
    y: 0.12041422724723816
    },
    {
    x: 0.8240723013877869,
    y: 0.4922894537448883
    },
    {
    x: 0.5980894565582275,
    y: 0.4922894537448883
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8540523052215576,
    bounding_box: [
    {
    x: 0.6153718829154968,
    y: 0.5089136958122253
    },
    {
    x: 0.8628861308097839,
    y: 0.5089136958122253
    },
    {
    x: 0.8628861308097839,
    y: 0.9266422390937805
    },
    {
    x: 0.6153718829154968,
    y: 0.9266422390937805
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.84051513671875,
    bounding_box: [
    {
    x: 0.193475604057312,
    y: 0.5135785341262817
    },
    {
    x: 0.41928181052207947,
    y: 0.5135785341262817
    },
    {
    x: 0.41928181052207947,
    y: 0.9234516620635986
    },
    {
    x: 0.193475604057312,
    y: 0.9234516620635986
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7523038983345032,
    bounding_box: [
    {
    x: 0.1809726506471634,
    y: 0.284893661737442
    },
    {
    x: 0.4635375738143921,
    y: 0.284893661737442
    },
    {
    x: 0.4635375738143921,
    y: 0.4973580837249756
    },
    {
    x: 0.1809726506471634,
    y: 0.4973580837249756
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6326379179954529,
    bounding_box: [
    {
    x: 0.3442424535751343,
    y: 0.06803762167692184
    },
    {
    x: 0.5002037882804871,
    y: 0.06803762167692184
    },
    {
    x: 0.5002037882804871,
    y: 0.4834505617618561
    },
    {
    x: 0.3442424535751343,
    y: 0.4834505617618561
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.5983359217643738,
    bounding_box: [
    {
    x: 0.6789271831512451,
    y: 0.7088324427604675
    },
    {
    x: 0.7761953473091125,
    y: 0.7088324427604675
    },
    {
    x: 0.7761953473091125,
    y: 0.9071450233459473
    },
    {
    x: 0.6789271831512451,
    y: 0.9071450233459473
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5522545576095581,
    bounding_box: [
    {
    x: 0.18460161983966827,
    y: 0.2790334224700928
    },
    {
    x: 0.46124881505966187,
    y: 0.2790334224700928
    },
    {
    x: 0.46124881505966187,
    y: 0.49511560797691345
    },
    {
    x: 0.18460161983966827,
    y: 0.49511560797691345
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5496581196784973,
    bounding_box: [
    {
    x: 0.5972490906715393,
    y: 0.1375870704650879
    },
    {
    x: 0.8214849829673767,
    y: 0.1375870704650879
    },
    {
    x: 0.8214849829673767,
    y: 0.4944859743118286
    },
    {
    x: 0.5972490906715393,
    y: 0.4944859743118286
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5193772912025452,
    bounding_box: [
    {
    x: 0.18891404569149017,
    y: 0.515792727470398
    },
    {
    x: 0.4126310646533966,
    y: 0.515792727470398
    },
    {
    x: 0.4126310646533966,
    y: 0.9237334728240967
    },
    {
    x: 0.18891404569149017,
    y: 0.9237334728240967
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 60640,
    time_in_milliseconds: 2023354,
    labels: [
    {
    description: "Shoulder",
    confidence: 87.3346209526062
    },
    {
    description: "Fashion",
    confidence: 78.85615825653076
    },
    {
    description: "Joint",
    confidence: 73.9563524723053
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Black hair",
    confidence: 56.975001096725464
    },
    {
    description: "Long hair",
    confidence: 56.41460418701172
    },
    {
    description: "Little black dress",
    confidence: 54.76270914077759
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8520318269729614,
    bounding_box: [
    {
    x: 0.39312922954559326,
    y: 0.16247230768203735
    },
    {
    x: 0.8632950782775879,
    y: 0.16247230768203735
    },
    {
    x: 0.8632950782775879,
    y: 0.9929147362709045
    },
    {
    x: 0.39312922954559326,
    y: 0.9929147362709045
    }
    ]
    },
    {
    description: "Dress",
    confidence: 0.704277753829956,
    bounding_box: [
    {
    x: 0.42377331852912903,
    y: 0.449789434671402
    },
    {
    x: 0.7034218907356262,
    y: 0.449789434671402
    },
    {
    x: 0.7034218907356262,
    y: 0.9933207035064697
    },
    {
    x: 0.42377331852912903,
    y: 0.9933207035064697
    }
    ]
    },
    {
    description: "Guitar",
    confidence: 0.6406920552253723,
    bounding_box: [
    {
    x: 0.0014672637917101383,
    y: 0.6008304953575134
    },
    {
    x: 0.10535449534654617,
    y: 0.6008304953575134
    },
    {
    x: 0.10535449534654617,
    y: 0.9953394532203674
    },
    {
    x: 0.0014672637917101383,
    y: 0.9953394532203674
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 61655,
    time_in_milliseconds: 2057222.0000000002,
    labels: [
    {
    description: "Television program",
    confidence: 86.03726625442505
    },
    {
    description: "Photography",
    confidence: 67.78864860534668
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Media",
    confidence: 64.52412009239197
    },
    {
    description: "Collage",
    confidence: 56.94475769996643
    },
    {
    description: "Television presenter",
    confidence: 52.931809425354004
    },
    {
    description: "Newscaster",
    confidence: 52.90130972862244
    },
    {
    description: "Art",
    confidence: 50.218719244003296
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8774503469467163,
    bounding_box: [
    {
    x: 0.18430788815021515,
    y: 0.12482258677482605
    },
    {
    x: 0.47688496112823486,
    y: 0.12482258677482605
    },
    {
    x: 0.47688496112823486,
    y: 0.49693265557289124
    },
    {
    x: 0.18430788815021515,
    y: 0.49693265557289124
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8698170781135559,
    bounding_box: [
    {
    x: 0.5816431641578674,
    y: 0.11543523520231247
    },
    {
    x: 0.8012893199920654,
    y: 0.11543523520231247
    },
    {
    x: 0.8012893199920654,
    y: 0.49498069286346436
    },
    {
    x: 0.5816431641578674,
    y: 0.49498069286346436
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8593684434890747,
    bounding_box: [
    {
    x: 0.2077115923166275,
    y: 0.5228802561759949
    },
    {
    x: 0.422358900308609,
    y: 0.5228802561759949
    },
    {
    x: 0.422358900308609,
    y: 0.9210192561149597
    },
    {
    x: 0.2077115923166275,
    y: 0.9210192561149597
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8443699479103088,
    bounding_box: [
    {
    x: 0.6261163353919983,
    y: 0.5209058523178101
    },
    {
    x: 0.8259724974632263,
    y: 0.5209058523178101
    },
    {
    x: 0.8259724974632263,
    y: 0.9237265586853027
    },
    {
    x: 0.6261163353919983,
    y: 0.9237265586853027
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7379675507545471,
    bounding_box: [
    {
    x: 0.6280296444892883,
    y: 0.5289481282234192
    },
    {
    x: 0.8230839371681213,
    y: 0.5289481282234192
    },
    {
    x: 0.8230839371681213,
    y: 0.9178181290626526
    },
    {
    x: 0.6280296444892883,
    y: 0.9178181290626526
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.6672701239585876,
    bounding_box: [
    {
    x: 0.18363581597805023,
    y: 0.28575220704078674
    },
    {
    x: 0.47553837299346924,
    y: 0.28575220704078674
    },
    {
    x: 0.47553837299346924,
    y: 0.4949897527694702
    },
    {
    x: 0.18363581597805023,
    y: 0.4949897527694702
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6660763025283813,
    bounding_box: [
    {
    x: 0.20705144107341766,
    y: 0.5634509921073914
    },
    {
    x: 0.41850194334983826,
    y: 0.5634509921073914
    },
    {
    x: 0.41850194334983826,
    y: 0.9192026257514954
    },
    {
    x: 0.20705144107341766,
    y: 0.9192026257514954
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.6201437711715698,
    bounding_box: [
    {
    x: 0.5824699997901917,
    y: 0.15449348092079163
    },
    {
    x: 0.8019671440124512,
    y: 0.15449348092079163
    },
    {
    x: 0.8019671440124512,
    y: 0.4941427707672119
    },
    {
    x: 0.5824699997901917,
    y: 0.4941427707672119
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.6030573844909668,
    bounding_box: [
    {
    x: 0.6277812719345093,
    y: 0.6995410919189453
    },
    {
    x: 0.8034675121307373,
    y: 0.6995410919189453
    },
    {
    x: 0.8034675121307373,
    y: 0.9200088381767273
    },
    {
    x: 0.6277812719345093,
    y: 0.9200088381767273
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.5923424959182739,
    bounding_box: [
    {
    x: 0.6853070855140686,
    y: 0.7009677290916443
    },
    {
    x: 0.7773841023445129,
    y: 0.7009677290916443
    },
    {
    x: 0.7773841023445129,
    y: 0.9114674925804138
    },
    {
    x: 0.6853070855140686,
    y: 0.9114674925804138
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 61916,
    time_in_milliseconds: 2065931,
    labels: [
    {
    description: "Font",
    confidence: 95.20275592803955
    },
    {
    description: "Text",
    confidence: 95.04482746124268
    },
    {
    description: "Blue",
    confidence: 91.18428230285645
    },
    {
    description: "Electric blue",
    confidence: 81.10349774360657
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Graphic design",
    confidence: 70.45729160308838
    },
    {
    description: "Graphics",
    confidence: 67.06898808479309
    },
    {
    description: "Logo",
    confidence: 65.32379984855652
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5422204732894897,
    bounding_box: [
    {
    x: 0.0779694989323616,
    y: 0.08728001266717911
    },
    {
    x: 0.9973958134651184,
    y: 0.08728001266717911
    },
    {
    x: 0.9973958134651184,
    y: 0.9913585782051086
    },
    {
    x: 0.0779694989323616,
    y: 0.9913585782051086
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62032,
    time_in_milliseconds: 2069801,
    labels: [
    {
    description: "Font",
    confidence: 94.65967416763306
    },
    {
    description: "Text",
    confidence: 93.56654286384583
    },
    {
    description: "Electric blue",
    confidence: 84.43899154663086
    },
    {
    description: "Graphics",
    confidence: 63.03960084915161
    },
    {
    description: "Brand",
    confidence: 60.91187000274658
    },
    {
    description: "Company",
    confidence: 60.73097586631775
    },
    {
    description: "Logo",
    confidence: 58.70729088783264
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.5323361158370972,
    bounding_box: [
    {
    x: 0.0074013047851622105,
    y: 0
    },
    {
    x: 0.9973958134651184,
    y: 0
    },
    {
    x: 0.9973958134651184,
    y: 0.9919036030769348
    },
    {
    x: 0.0074013047851622105,
    y: 0.9919036030769348
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62061,
    time_in_milliseconds: 2070768,
    labels: [
    {
    description: "Black",
    confidence: 98.49877953529358
    },
    {
    description: "Darkness",
    confidence: 97.3947525024414
    },
    {
    description: "White",
    confidence: 96.54507637023926
    },
    {
    description: "Sky",
    confidence: 93.36194396018982
    },
    {
    description: "Light",
    confidence: 91.6901707649231
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Text",
    confidence: 91.21394753456116
    },
    {
    description: "Brown",
    confidence: 88.69289755821228
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Atmosphere",
    confidence: 82.49121904373169
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 62119,
    time_in_milliseconds: 2072703,
    labels: [
    {
    description: "Font",
    confidence: 94.4378674030304
    },
    {
    description: "Text",
    confidence: 91.89756512641907
    },
    {
    description: "Electric blue",
    confidence: 86.98182702064514
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Graphics",
    confidence: 56.19082450866699
    },
    {
    description: "Logo",
    confidence: 55.351418256759644
    },
    {
    description: "Company",
    confidence: 54.9457848072052
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7909656167030334,
    bounding_box: [
    {
    x: 0.08512174338102341,
    y: 0
    },
    {
    x: 0.9973958134651184,
    y: 0
    },
    {
    x: 0.9973958134651184,
    y: 0.9923637509346008
    },
    {
    x: 0.08512174338102341,
    y: 0.9923637509346008
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62148,
    time_in_milliseconds: 2073672,
    labels: [
    {
    description: "Text",
    confidence: 94.66919302940369
    },
    {
    description: "Font",
    confidence: 93.92736554145813
    },
    {
    description: "Blue",
    confidence: 91.18428230285645
    },
    {
    description: "Electric blue",
    confidence: 83.22898149490356
    },
    {
    description: "Graphic design",
    confidence: 74.3398666381836
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Logo",
    confidence: 73.25320839881897
    },
    {
    description: "Graphics",
    confidence: 68.77840757369995
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6288463473320007,
    bounding_box: [
    {
    x: 0.2535291612148285,
    y: 0.1121005117893219
    },
    {
    x: 0.9973958730697632,
    y: 0.1121005117893219
    },
    {
    x: 0.9973958730697632,
    y: 0.9936360120773315
    },
    {
    x: 0.2535291612148285,
    y: 0.9936360120773315
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62264,
    time_in_milliseconds: 2077542,
    labels: [
    {
    description: "Emblem",
    confidence: 81.79901242256165
    },
    {
    description: "Badge",
    confidence: 80.56789636611938
    },
    {
    description: "Competition event",
    confidence: 80.32378554344177
    },
    {
    description: "Championship",
    confidence: 77.22464203834534
    },
    {
    description: "Security",
    confidence: 73.28997254371643
    },
    {
    description: "Logo",
    confidence: 70.02647519111633
    },
    {
    description: "Font",
    confidence: 68.57237815856934
    },
    {
    description: "Symbol",
    confidence: 68.09903383255005
    },
    {
    description: "Police",
    confidence: 62.658798694610596
    },
    {
    description: "Crest",
    confidence: 61.45352125167847
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 62438,
    time_in_milliseconds: 2083347.0000000002,
    labels: [
    {
    description: "Banner",
    confidence: 67.02175736427307
    },
    {
    description: "Logo",
    confidence: 61.34564280509949
    },
    {
    description: "Brand",
    confidence: 60.91187000274658
    },
    {
    description: "Advertising",
    confidence: 56.558191776275635
    },
    {
    description: "Badge",
    confidence: 54.096418619155884
    }
    ],
    objects: [
    {
    description: "Top",
    confidence: 0.8404771089553833,
    bounding_box: [
    {
    x: 0.016079241409897804,
    y: 0.31936463713645935
    },
    {
    x: 0.294656366109848,
    y: 0.31936463713645935
    },
    {
    x: 0.294656366109848,
    y: 0.9965193271636963
    },
    {
    x: 0.016079241409897804,
    y: 0.9965193271636963
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.593242347240448,
    bounding_box: [
    {
    x: 0.6875540614128113,
    y: 0.34712541103363037
    },
    {
    x: 0.7994644045829773,
    y: 0.34712541103363037
    },
    {
    x: 0.7994644045829773,
    y: 0.8241519331932068
    },
    {
    x: 0.6875540614128113,
    y: 0.8241519331932068
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62467,
    time_in_milliseconds: 2084316.0000000002,
    labels: [
    {
    description: "Text",
    confidence: 90.30671715736389
    },
    {
    description: "Font",
    confidence: 81.95118308067322
    },
    {
    description: "Logo",
    confidence: 57.14039206504822
    },
    {
    description: "Banner",
    confidence: 52.90626883506775
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.77559894323349,
    bounding_box: [
    {
    x: 0.030408261343836784,
    y: 0.020444953814148903
    },
    {
    x: 0.9900288581848145,
    y: 0.020444953814148903
    },
    {
    x: 0.9900288581848145,
    y: 0.9894077777862549
    },
    {
    x: 0.030408261343836784,
    y: 0.9894077777862549
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62583,
    time_in_milliseconds: 2088186.0000000002,
    labels: [
    {
    description: "Text",
    confidence: 85.2487862110138
    },
    {
    description: "Font",
    confidence: 78.25835347175598
    },
    {
    description: "Logo",
    confidence: 74.01036620140076
    },
    {
    description: "Electric blue",
    confidence: 70.31877636909485
    },
    {
    description: "Graphics",
    confidence: 68.02747845649719
    },
    {
    description: "Advertising",
    confidence: 65.56830406188965
    },
    {
    description: "Games",
    confidence: 63.133203983306885
    },
    {
    description: "Talent show",
    confidence: 61.59961223602295
    },
    {
    description: "Graphic design",
    confidence: 61.37281060218811
    },
    {
    description: "Brand",
    confidence: 58.21748375892639
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6869073510169983,
    bounding_box: [
    {
    x: 0.08264379948377609,
    y: 0.009619362652301788
    },
    {
    x: 0.9695470333099365,
    y: 0.009619362652301788
    },
    {
    x: 0.9695470333099365,
    y: 0.9920933246612549
    },
    {
    x: 0.08264379948377609,
    y: 0.9920933246612549
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62612,
    time_in_milliseconds: 2089153.0000000002,
    labels: [
    {
    description: "Newsreader",
    confidence: 98.39743375778198
    },
    {
    description: "News",
    confidence: 97.80344367027283
    },
    {
    description: "Television presenter",
    confidence: 96.78792357444763
    },
    {
    description: "Newscaster",
    confidence: 96.49463295936584
    },
    {
    description: "Face",
    confidence: 95.38267254829407
    },
    {
    description: "Blond",
    confidence: 92.99880862236023
    },
    {
    description: "Spokesperson",
    confidence: 90.76018333435059
    },
    {
    description: "Journalist",
    confidence: 86.43829822540283
    },
    {
    description: "Forehead",
    confidence: 78.22763919830322
    },
    {
    description: "Iris",
    confidence: 74.77608323097229
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7572567462921143,
    bounding_box: [
    {
    x: 0.1768316775560379,
    y: 0.013240337371826172
    },
    {
    x: 0.9417093396186829,
    y: 0.013240337371826172
    },
    {
    x: 0.9417093396186829,
    y: 0.9775285124778748
    },
    {
    x: 0.1768316775560379,
    y: 0.9775285124778748
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6520180106163025,
    bounding_box: [
    {
    x: 0.29131075739860535,
    y: 0.03701427951455116
    },
    {
    x: 0.7635406851768494,
    y: 0.03701427951455116
    },
    {
    x: 0.7635406851768494,
    y: 0.8982766270637512
    },
    {
    x: 0.29131075739860535,
    y: 0.8982766270637512
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62641,
    time_in_milliseconds: 2090122.0000000002,
    labels: [
    {
    description: "Newsreader",
    confidence: 98.39743375778198
    },
    {
    description: "News",
    confidence: 97.08071947097778
    },
    {
    description: "Television presenter",
    confidence: 94.80222463607788
    },
    {
    description: "Newscaster",
    confidence: 94.602370262146
    },
    {
    description: "Spokesperson",
    confidence: 90.03613591194153
    },
    {
    description: "Journalist",
    confidence: 81.00449442863464
    },
    {
    description: "Media",
    confidence: 76.7995536327362
    },
    {
    description: "Electric blue",
    confidence: 58.18745493888855
    },
    {
    description: "Television program",
    confidence: 51.34713053703308
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8305858969688416,
    bounding_box: [
    {
    x: 0.2577248215675354,
    y: 0.04922415316104889
    },
    {
    x: 0.741206705570221,
    y: 0.04922415316104889
    },
    {
    x: 0.741206705570221,
    y: 0.7907886505126953
    },
    {
    x: 0.2577248215675354,
    y: 0.7907886505126953
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.7063828706741333,
    bounding_box: [
    {
    x: 0.25657543540000916,
    y: 0.5737291574478149
    },
    {
    x: 0.7539801001548767,
    y: 0.5737291574478149
    },
    {
    x: 0.7539801001548767,
    y: 0.7727611064910889
    },
    {
    x: 0.25657543540000916,
    y: 0.7727611064910889
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62815,
    time_in_milliseconds: 2095927.0000000002,
    labels: [
    {
    description: "Text",
    confidence: 89.7243082523346
    },
    {
    description: "Font",
    confidence: 78.25835347175598
    },
    {
    description: "Media",
    confidence: 71.72972559928894
    },
    {
    description: "Photo caption",
    confidence: 70.43102383613586
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Screenshot",
    confidence: 58.81657004356384
    },
    {
    description: "News",
    confidence: 58.79448056221008
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7319361567497253,
    bounding_box: [
    {
    x: 0.3951692581176758,
    y: 0.26426631212234497
    },
    {
    x: 0.6151265501976013,
    y: 0.26426631212234497
    },
    {
    x: 0.6151265501976013,
    y: 0.7869469523429871
    },
    {
    x: 0.3951692581176758,
    y: 0.7869469523429871
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.59943687915802,
    bounding_box: [
    {
    x: 0.04354799911379814,
    y: 0.2923398017883301
    },
    {
    x: 0.154175266623497,
    y: 0.2923398017883301
    },
    {
    x: 0.154175266623497,
    y: 0.538620114326477
    },
    {
    x: 0.04354799911379814,
    y: 0.538620114326477
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 62960,
    time_in_milliseconds: 2100765.0000000005,
    labels: [
    {
    description: "Blond",
    confidence: 95.53552865982056
    },
    {
    description: "Long hair",
    confidence: 74.27447438240051
    },
    {
    description: "Hair coloring",
    confidence: 57.97973871231079
    },
    {
    description: "Brown hair",
    confidence: 55.654191970825195
    },
    {
    description: "Drink",
    confidence: 53.42121720314026
    }
    ],
    objects: [
    {
    description: "Canned packaged goods",
    confidence: 0.8793323636054993,
    bounding_box: [
    {
    x: 0.3324909508228302,
    y: 0.1967657059431076
    },
    {
    x: 0.4081849157810211,
    y: 0.1967657059431076
    },
    {
    x: 0.4081849157810211,
    y: 0.4035981595516205
    },
    {
    x: 0.3324909508228302,
    y: 0.4035981595516205
    }
    ]
    },
    {
    description: "Dress",
    confidence: 0.8146653175354004,
    bounding_box: [
    {
    x: 0.3153447210788727,
    y: 0.36547544598579407
    },
    {
    x: 0.6947858929634094,
    y: 0.36547544598579407
    },
    {
    x: 0.6947858929634094,
    y: 0.9955582618713379
    },
    {
    x: 0.3153447210788727,
    y: 0.9955582618713379
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7408843040466309,
    bounding_box: [
    {
    x: 0.3084735572338104,
    y: 0.10421434044837952
    },
    {
    x: 0.7024843692779541,
    y: 0.10421434044837952
    },
    {
    x: 0.7024843692779541,
    y: 0.97465980052948
    },
    {
    x: 0.3084735572338104,
    y: 0.97465980052948
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 63076,
    time_in_milliseconds: 2104635,
    labels: [
    {
    description: "Finger",
    confidence: 60.68736910820007
    },
    {
    description: "Tin can",
    confidence: 60.66986918449402
    },
    {
    description: "Drink",
    confidence: 53.42121720314026
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6713337302207947,
    bounding_box: [
    {
    x: 0.41045188903808594,
    y: 0.22249121963977814
    },
    {
    x: 0.6050559282302856,
    y: 0.22249121963977814
    },
    {
    x: 0.6050559282302856,
    y: 0.7848727107048035
    },
    {
    x: 0.41045188903808594,
    y: 0.7848727107048035
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 63279,
    time_in_milliseconds: 2111409,
    labels: [
    {
    description: "Hair",
    confidence: 96.63026928901672
    },
    {
    description: "Eyewear",
    confidence: 94.320547580719
    },
    {
    description: "Blond",
    confidence: 90.39552807807922
    },
    {
    description: "Glasses",
    confidence: 84.64290499687195
    },
    {
    description: "Long hair",
    confidence: 72.87996411323547
    },
    {
    description: "Games",
    confidence: 72.86816835403442
    },
    {
    description: "Hand",
    confidence: 69.69173550605774
    },
    {
    description: "Headgear",
    confidence: 67.47809648513794
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Fashion accessory",
    confidence: 61.625415086746216
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.812157154083252,
    bounding_box: [
    {
    x: 0.35235854983329773,
    y: 0.004065969027578831
    },
    {
    x: 0.5455188751220703,
    y: 0.004065969027578831
    },
    {
    x: 0.5455188751220703,
    y: 0.42057421803474426
    },
    {
    x: 0.35235854983329773,
    y: 0.42057421803474426
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5863786339759827,
    bounding_box: [
    {
    x: 0.15137870609760284,
    y: 0.06983942538499832
    },
    {
    x: 0.8814849257469177,
    y: 0.06983942538499832
    },
    {
    x: 0.8814849257469177,
    y: 0.9850043654441833
    },
    {
    x: 0.15137870609760284,
    y: 0.9850043654441833
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 63308,
    time_in_milliseconds: 2112376,
    labels: [
    {
    description: "Blond",
    confidence: 82.07627534866333
    },
    {
    description: "Neck",
    confidence: 65.35770893096924
    },
    {
    description: "Drink",
    confidence: 58.10244679450989
    },
    {
    description: "Long hair",
    confidence: 56.41460418701172
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7859770059585571,
    bounding_box: [
    {
    x: 0.22970972955226898,
    y: 0.2495986372232437
    },
    {
    x: 0.34614548087120056,
    y: 0.2495986372232437
    },
    {
    x: 0.34614548087120056,
    y: 0.6291940808296204
    },
    {
    x: 0.22970972955226898,
    y: 0.6291940808296204
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 63569,
    time_in_milliseconds: 2121086.0000000005,
    labels: [
    {
    description: "Text",
    confidence: 85.2487862110138
    },
    {
    description: "Font",
    confidence: 84.10601615905762
    },
    {
    description: "Logo",
    confidence: 75.67700147628784
    },
    {
    description: "Advertising",
    confidence: 69.12822127342224
    },
    {
    description: "Graphics",
    confidence: 68.77840757369995
    },
    {
    description: "Graphic design",
    confidence: 65.83430171012878
    },
    {
    description: "Electric blue",
    confidence: 63.79328370094299
    },
    {
    description: "Games",
    confidence: 63.133203983306885
    },
    {
    description: "Talent show",
    confidence: 61.59961223602295
    },
    {
    description: "Signage",
    confidence: 57.87496566772461
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.6599627137184143,
    bounding_box: [
    {
    x: 0.11860284954309464,
    y: 0.016537224873900414
    },
    {
    x: 0.8968989849090576,
    y: 0.016537224873900414
    },
    {
    x: 0.8968989849090576,
    y: 0.9314202666282654
    },
    {
    x: 0.11860284954309464,
    y: 0.9314202666282654
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 63598,
    time_in_milliseconds: 2122053,
    labels: [
    {
    description: "Font",
    confidence: 78.25835347175598
    },
    {
    description: "Logo",
    confidence: 69.46904063224792
    },
    {
    description: "Graphics",
    confidence: 63.993048667907715
    },
    {
    description: "Games",
    confidence: 63.133203983306885
    },
    {
    description: "Talent show",
    confidence: 61.59961223602295
    },
    {
    description: "Advertising",
    confidence: 59.40808653831482
    },
    {
    description: "Competition event",
    confidence: 56.94236755371094
    },
    {
    description: "Graphic design",
    confidence: 54.66613173484802
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7135634422302246,
    bounding_box: [
    {
    x: 0.09417370706796646,
    y: 0.005107210949063301
    },
    {
    x: 0.9825848937034607,
    y: 0.005107210949063301
    },
    {
    x: 0.9825848937034607,
    y: 0.987832248210907
    },
    {
    x: 0.09417370706796646,
    y: 0.987832248210907
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 63743,
    time_in_milliseconds: 2126891,
    labels: [
    {
    description: "Font",
    confidence: 87.1282696723938
    },
    {
    description: "Text",
    confidence: 85.2487862110138
    },
    {
    description: "Logo",
    confidence: 68.25647354125977
    },
    {
    description: "Advertising",
    confidence: 63.80993723869324
    },
    {
    description: "Graphics",
    confidence: 62.49194145202637
    },
    {
    description: "Brand",
    confidence: 58.21748375892639
    },
    {
    description: "Signage",
    confidence: 55.267053842544556
    },
    {
    description: "Label",
    confidence: 55.13033866882324
    },
    {
    description: "Games",
    confidence: 52.11578011512756
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.8214975595474243,
    bounding_box: [
    {
    x: 0.04248104616999626,
    y: 0.0062245032750070095
    },
    {
    x: 0.9755997657775879,
    y: 0.0062245032750070095
    },
    {
    x: 0.9755997657775879,
    y: 0.994335949420929
    },
    {
    x: 0.04248104616999626,
    y: 0.994335949420929
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 63772,
    time_in_milliseconds: 2127859,
    labels: [
    {
    description: "Black",
    confidence: 98.49877953529358
    },
    {
    description: "Darkness",
    confidence: 97.3947525024414
    },
    {
    description: "White",
    confidence: 96.54507637023926
    },
    {
    description: "Sky",
    confidence: 93.36194396018982
    },
    {
    description: "Light",
    confidence: 91.6901707649231
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Text",
    confidence: 91.21394753456116
    },
    {
    description: "Brown",
    confidence: 88.69289755821228
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Atmosphere",
    confidence: 82.49121904373169
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 63830,
    time_in_milliseconds: 2129794.0000000005,
    labels: [
    {
    description: "Font",
    confidence: 95.02331018447876
    },
    {
    description: "Text",
    confidence: 91.89756512641907
    },
    {
    description: "Electric blue",
    confidence: 76.88719034194946
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Graphics",
    confidence: 63.03960084915161
    },
    {
    description: "Logo",
    confidence: 58.70729088783264
    },
    {
    description: "Graphic design",
    confidence: 53.16992402076721
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7767260670661926,
    bounding_box: [
    {
    x: 0.1393907368183136,
    y: 0
    },
    {
    x: 0.9973957538604736,
    y: 0
    },
    {
    x: 0.9973957538604736,
    y: 0.9962272644042969
    },
    {
    x: 0.1393907368183136,
    y: 0.9962272644042969
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 63859,
    time_in_milliseconds: 2130762,
    labels: [
    {
    description: "Text",
    confidence: 94.75095868110657
    },
    {
    description: "Font",
    confidence: 94.19525861740112
    },
    {
    description: "Blue",
    confidence: 92.6959753036499
    },
    {
    description: "Electric blue",
    confidence: 84.79061126708984
    },
    {
    description: "Graphic design",
    confidence: 77.16283798217773
    },
    {
    description: "Logo",
    confidence: 73.63916635513306
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Graphics",
    confidence: 69.27334070205688
    },
    {
    description: "Brand",
    confidence: 52.02584266662598
    },
    {
    description: "Illustration",
    confidence: 50.40763020515442
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 63975,
    time_in_milliseconds: 2134632,
    labels: [
    {
    description: "Glasses",
    confidence: 65.22085666656494
    },
    {
    description: "Library",
    confidence: 64.62681293487549
    },
    {
    description: "Book",
    confidence: 53.07499170303345
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9105024337768555,
    bounding_box: [
    {
    x: 0.43261364102363586,
    y: 0.37779513001441956
    },
    {
    x: 0.5669813752174377,
    y: 0.37779513001441956
    },
    {
    x: 0.5669813752174377,
    y: 0.46796151995658875
    },
    {
    x: 0.43261364102363586,
    y: 0.46796151995658875
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8747040033340454,
    bounding_box: [
    {
    x: 0.3241417109966278,
    y: 0.22481127083301544
    },
    {
    x: 0.8849300146102905,
    y: 0.22481127083301544
    },
    {
    x: 0.8849300146102905,
    y: 0.9891427159309387
    },
    {
    x: 0.3241417109966278,
    y: 0.9891427159309387
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8648833632469177,
    bounding_box: [
    {
    x: 0.6114239692687988,
    y: 0.01573444902896881
    },
    {
    x: 0.9752336144447327,
    y: 0.01573444902896881
    },
    {
    x: 0.9752336144447327,
    y: 0.9818882942199707
    },
    {
    x: 0.6114239692687988,
    y: 0.9818882942199707
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7421247959136963,
    bounding_box: [
    {
    x: 0.3307691514492035,
    y: 0.5381315350532532
    },
    {
    x: 0.8630803823471069,
    y: 0.5381315350532532
    },
    {
    x: 0.8630803823471069,
    y: 0.9860446453094482
    },
    {
    x: 0.3307691514492035,
    y: 0.9860446453094482
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5980202555656433,
    bounding_box: [
    {
    x: 0.32470712065696716,
    y: 0.26383259892463684
    },
    {
    x: 0.8723714351654053,
    y: 0.26383259892463684
    },
    {
    x: 0.8723714351654053,
    y: 0.9943716526031494
    },
    {
    x: 0.32470712065696716,
    y: 0.9943716526031494
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 64178,
    time_in_milliseconds: 2141405,
    labels: [
    {
    description: "Library",
    confidence: 71.03082537651062
    },
    {
    description: "Glasses",
    confidence: 51.58061981201172
    },
    {
    description: "Librarian",
    confidence: 50.87888836860657
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.908982515335083,
    bounding_box: [
    {
    x: 0.4227045476436615,
    y: 0.40587055683135986
    },
    {
    x: 0.5628311038017273,
    y: 0.40587055683135986
    },
    {
    x: 0.5628311038017273,
    y: 0.4888697564601898
    },
    {
    x: 0.4227045476436615,
    y: 0.4888697564601898
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8721169233322144,
    bounding_box: [
    {
    x: 0.3339524567127228,
    y: 0.25163939595222473
    },
    {
    x: 0.8807926177978516,
    y: 0.25163939595222473
    },
    {
    x: 0.8807926177978516,
    y: 0.9964882135391235
    },
    {
    x: 0.3339524567127228,
    y: 0.9964882135391235
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8577569723129272,
    bounding_box: [
    {
    x: 0.6164881587028503,
    y: 0.02953251264989376
    },
    {
    x: 0.9753566384315491,
    y: 0.02953251264989376
    },
    {
    x: 0.9753566384315491,
    y: 0.9852954745292664
    },
    {
    x: 0.6164881587028503,
    y: 0.9852954745292664
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7190898656845093,
    bounding_box: [
    {
    x: 0.33729982376098633,
    y: 0.5372923612594604
    },
    {
    x: 0.8700143694877625,
    y: 0.5372923612594604
    },
    {
    x: 0.8700143694877625,
    y: 0.9871408343315125
    },
    {
    x: 0.33729982376098633,
    y: 0.9871408343315125
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 64207,
    time_in_milliseconds: 2142373,
    labels: [
    {
    description: "Black",
    confidence: 98.45905303955078
    },
    {
    description: "Darkness",
    confidence: 96.96907997131348
    },
    {
    description: "White",
    confidence: 95.94447612762451
    },
    {
    description: "Sky",
    confidence: 93.81980895996094
    },
    {
    description: "Text",
    confidence: 93.07222962379456
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Light",
    confidence: 90.89804291725159
    },
    {
    description: "Font",
    confidence: 88.23639750480652
    },
    {
    description: "Brown",
    confidence: 87.93631196022034
    },
    {
    description: "Atmosphere",
    confidence: 80.43394088745117
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 71167,
    time_in_milliseconds: 2374605,
    labels: [
    {
    description: "Darkness",
    confidence: 81.64865374565125
    },
    {
    description: "Facial hair",
    confidence: 79.50589656829834
    },
    {
    description: "Media",
    confidence: 74.08702969551086
    },
    {
    description: "Design",
    confidence: 73.75450134277344
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Glasses",
    confidence: 67.38030314445496
    },
    {
    description: "Presentation",
    confidence: 66.64341688156128
    },
    {
    description: "Conversation",
    confidence: 65.68495035171509
    },
    {
    description: "Room",
    confidence: 65.66392779350281
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9371339678764343,
    bounding_box: [
    {
    x: 0.5393865704536438,
    y: 0.3704666793346405
    },
    {
    x: 0.6757245659828186,
    y: 0.3704666793346405
    },
    {
    x: 0.6757245659828186,
    y: 0.47388336062431335
    },
    {
    x: 0.5393865704536438,
    y: 0.47388336062431335
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.842853844165802,
    bounding_box: [
    {
    x: 0.345607191324234,
    y: 0.2445569485425949
    },
    {
    x: 0.8744783401489258,
    y: 0.2445569485425949
    },
    {
    x: 0.8744783401489258,
    y: 0.9908556938171387
    },
    {
    x: 0.345607191324234,
    y: 0.9908556938171387
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7413129210472107,
    bounding_box: [
    {
    x: 0.3509436547756195,
    y: 0.5431914925575256
    },
    {
    x: 0.8632874488830566,
    y: 0.5431914925575256
    },
    {
    x: 0.8632874488830566,
    y: 0.9885978698730469
    },
    {
    x: 0.3509436547756195,
    y: 0.9885978698730469
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6672042012214661,
    bounding_box: [
    {
    x: 0.575132429599762,
    y: 0.0783129632472992
    },
    {
    x: 0.9708409309387207,
    y: 0.0783129632472992
    },
    {
    x: 0.9708409309387207,
    y: 0.978445291519165
    },
    {
    x: 0.575132429599762,
    y: 0.978445291519165
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71196,
    time_in_milliseconds: 2375573.0000000005,
    labels: [
    {
    description: "Library",
    confidence: 78.99165749549866
    },
    {
    description: "Furniture",
    confidence: 53.31730842590332
    },
    {
    description: "Book",
    confidence: 51.52108073234558
    },
    {
    description: "Author",
    confidence: 50.18947720527649
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9208800196647644,
    bounding_box: [
    {
    x: 0.46320417523384094,
    y: 0.39078882336616516
    },
    {
    x: 0.5996547341346741,
    y: 0.39078882336616516
    },
    {
    x: 0.5996547341346741,
    y: 0.47562047839164734
    },
    {
    x: 0.46320417523384094,
    y: 0.47562047839164734
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8329582810401917,
    bounding_box: [
    {
    x: 0.6258667707443237,
    y: 0.009654141031205654
    },
    {
    x: 0.9661090970039368,
    y: 0.009654141031205654
    },
    {
    x: 0.9661090970039368,
    y: 0.9653644561767578
    },
    {
    x: 0.6258667707443237,
    y: 0.9653644561767578
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7920282483100891,
    bounding_box: [
    {
    x: 0.3612931966781616,
    y: 0.23808163404464722
    },
    {
    x: 0.9016914963722229,
    y: 0.23808163404464722
    },
    {
    x: 0.9016914963722229,
    y: 0.9863499999046326
    },
    {
    x: 0.3612931966781616,
    y: 0.9863499999046326
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7408229112625122,
    bounding_box: [
    {
    x: 0.36810776591300964,
    y: 0.5370692610740662
    },
    {
    x: 0.8775975704193115,
    y: 0.5370692610740662
    },
    {
    x: 0.8775975704193115,
    y: 0.9858785271644592
    },
    {
    x: 0.36810776591300964,
    y: 0.9858785271644592
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7188799381256104,
    bounding_box: [
    {
    x: 0.3585774600505829,
    y: 0.2758740186691284
    },
    {
    x: 0.885695219039917,
    y: 0.2758740186691284
    },
    {
    x: 0.885695219039917,
    y: 0.991809606552124
    },
    {
    x: 0.3585774600505829,
    y: 0.991809606552124
    }
    ]
    },
    {
    description: "Outerwear",
    confidence: 0.5317047238349915,
    bounding_box: [
    {
    x: 0.3778374195098877,
    y: 0.5437650084495544
    },
    {
    x: 0.8555428385734558,
    y: 0.5437650084495544
    },
    {
    x: 0.8555428385734558,
    y: 0.9781139492988586
    },
    {
    x: 0.3778374195098877,
    y: 0.9781139492988586
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71283,
    time_in_milliseconds: 2378476,
    labels: [
    {
    description: "Blue",
    confidence: 96.94558382034302
    },
    {
    description: "Eyewear",
    confidence: 89.4751250743866
    },
    {
    description: "Design",
    confidence: 73.01200032234192
    },
    {
    description: "Glasses",
    confidence: 70.90374231338501
    },
    {
    description: "Electric blue",
    confidence: 63.79328370094299
    },
    {
    description: "Photography",
    confidence: 62.42249608039856
    },
    {
    description: "Vision care",
    confidence: 54.48664426803589
    },
    {
    description: "Picture frame",
    confidence: 51.31804943084717
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9412870407104492,
    bounding_box: [
    {
    x: 0.43047043681144714,
    y: 0.4032738506793976
    },
    {
    x: 0.5726122260093689,
    y: 0.4032738506793976
    },
    {
    x: 0.5726122260093689,
    y: 0.4846055805683136
    },
    {
    x: 0.43047043681144714,
    y: 0.4846055805683136
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7769387364387512,
    bounding_box: [
    {
    x: 0.40879181027412415,
    y: 0.29170921444892883
    },
    {
    x: 0.6254828572273254,
    y: 0.29170921444892883
    },
    {
    x: 0.6254828572273254,
    y: 0.9108197689056396
    },
    {
    x: 0.40879181027412415,
    y: 0.9108197689056396
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71312,
    time_in_milliseconds: 2379444,
    labels: [
    {
    description: "Text",
    confidence: 95.04482746124268
    },
    {
    description: "Font",
    confidence: 93.73186230659485
    },
    {
    description: "Blue",
    confidence: 93.71774196624756
    },
    {
    description: "Electric blue",
    confidence: 87.79448866844177
    },
    {
    description: "Graphic design",
    confidence: 77.16283798217773
    },
    {
    description: "Logo",
    confidence: 75.04498362541199
    },
    {
    description: "Graphics",
    confidence: 70.06589770317078
    },
    {
    description: "Brand",
    confidence: 54.51956391334534
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 71428,
    time_in_milliseconds: 2383314.0000000005,
    labels: [
    {
    description: "Black",
    confidence: 98.49877953529358
    },
    {
    description: "Darkness",
    confidence: 97.3947525024414
    },
    {
    description: "White",
    confidence: 96.54507637023926
    },
    {
    description: "Sky",
    confidence: 93.36194396018982
    },
    {
    description: "Light",
    confidence: 91.6901707649231
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Text",
    confidence: 91.21394753456116
    },
    {
    description: "Brown",
    confidence: 88.69289755821228
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Atmosphere",
    confidence: 82.49121904373169
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 71515,
    time_in_milliseconds: 2386217,
    labels: [
    {
    description: "Library",
    confidence: 69.5090651512146
    },
    {
    description: "Glasses",
    confidence: 69.25603747367859
    },
    {
    description: "Book",
    confidence: 59.34723615646362
    },
    {
    description: "Author",
    confidence: 51.163989305496216
    },
    {
    description: "Librarian",
    confidence: 50.87888836860657
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.931606650352478,
    bounding_box: [
    {
    x: 0.4163832664489746,
    y: 0.38095465302467346
    },
    {
    x: 0.5426842570304871,
    y: 0.38095465302467346
    },
    {
    x: 0.5426842570304871,
    y: 0.47565600275993347
    },
    {
    x: 0.4163832664489746,
    y: 0.47565600275993347
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.8699092864990234,
    bounding_box: [
    {
    x: 0.62929767370224,
    y: 0.012089545838534832
    },
    {
    x: 0.9648535847663879,
    y: 0.012089545838534832
    },
    {
    x: 0.9648535847663879,
    y: 0.9596176147460938
    },
    {
    x: 0.62929767370224,
    y: 0.9596176147460938
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8079705834388733,
    bounding_box: [
    {
    x: 0.35749873518943787,
    y: 0.2477526217699051
    },
    {
    x: 0.8697479963302612,
    y: 0.2477526217699051
    },
    {
    x: 0.8697479963302612,
    y: 0.9875290989875793
    },
    {
    x: 0.35749873518943787,
    y: 0.9875290989875793
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.727321982383728,
    bounding_box: [
    {
    x: 0.3587971031665802,
    y: 0.5271472334861755
    },
    {
    x: 0.868477463722229,
    y: 0.5271472334861755
    },
    {
    x: 0.868477463722229,
    y: 0.9863942265510559
    },
    {
    x: 0.3587971031665802,
    y: 0.9863942265510559
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7082352638244629,
    bounding_box: [
    {
    x: 0.3549234867095947,
    y: 0.2788718640804291
    },
    {
    x: 0.8691077828407288,
    y: 0.2788718640804291
    },
    {
    x: 0.8691077828407288,
    y: 0.9916812181472778
    },
    {
    x: 0.3549234867095947,
    y: 0.9916812181472778
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71689,
    time_in_milliseconds: 2392023,
    labels: [
    {
    description: "Library",
    confidence: 76.39887928962708
    },
    {
    description: "Glasses",
    confidence: 56.08964562416077
    },
    {
    description: "Book",
    confidence: 51.52108073234558
    }
    ],
    objects: [
    {
    description: "Glasses",
    confidence: 0.9015254378318787,
    bounding_box: [
    {
    x: 0.41543397307395935,
    y: 0.3982066810131073
    },
    {
    x: 0.5461116433143616,
    y: 0.3982066810131073
    },
    {
    x: 0.5461116433143616,
    y: 0.49238279461860657
    },
    {
    x: 0.41543397307395935,
    y: 0.49238279461860657
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8212506771087646,
    bounding_box: [
    {
    x: 0.33824265003204346,
    y: 0.24631720781326294
    },
    {
    x: 0.8896413445472717,
    y: 0.24631720781326294
    },
    {
    x: 0.8896413445472717,
    y: 0.9858412742614746
    },
    {
    x: 0.33824265003204346,
    y: 0.9858412742614746
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6968517303466797,
    bounding_box: [
    {
    x: 0.3396519124507904,
    y: 0.26297661662101746
    },
    {
    x: 0.8791128396987915,
    y: 0.26297661662101746
    },
    {
    x: 0.8791128396987915,
    y: 0.9943090677261353
    },
    {
    x: 0.3396519124507904,
    y: 0.9943090677261353
    }
    ]
    },
    {
    description: "Bookcase",
    confidence: 0.6730952262878418,
    bounding_box: [
    {
    x: 0.5708619952201843,
    y: 0.05943295732140541
    },
    {
    x: 0.9803783297538757,
    y: 0.05943295732140541
    },
    {
    x: 0.9803783297538757,
    y: 0.9751155376434326
    },
    {
    x: 0.5708619952201843,
    y: 0.9751155376434326
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.5979712009429932,
    bounding_box: [
    {
    x: 0.3514765202999115,
    y: 0.5266063809394836
    },
    {
    x: 0.871178150177002,
    y: 0.5266063809394836
    },
    {
    x: 0.871178150177002,
    y: 0.9938284754753113
    },
    {
    x: 0.3514765202999115,
    y: 0.9938284754753113
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71718,
    time_in_milliseconds: 2392990.0000000005,
    labels: [
    {
    description: "Hair",
    confidence: 97.1860945224762
    },
    {
    description: "Facial hair",
    confidence: 96.61180377006531
    },
    {
    description: "Beard",
    confidence: 94.92135643959045
    },
    {
    description: "Plaid",
    confidence: 91.18629693984985
    },
    {
    description: "Tartan",
    confidence: 82.99878239631653
    },
    {
    description: "Moustache",
    confidence: 82.55648612976074
    },
    {
    description: "Fun",
    confidence: 82.20452070236206
    },
    {
    description: "Cool",
    confidence: 81.33383393287659
    },
    {
    description: "Wall",
    confidence: 81.25073313713074
    },
    {
    description: "Brick",
    confidence: 77.32693552970886
    }
    ],
    objects: [
    {
    description: "Hat",
    confidence: 0.9221956133842468,
    bounding_box: [
    {
    x: 0.4377251863479614,
    y: 0.056157588958740234
    },
    {
    x: 0.6354228854179382,
    y: 0.056157588958740234
    },
    {
    x: 0.6354228854179382,
    y: 0.4031173884868622
    },
    {
    x: 0.4377251863479614,
    y: 0.4031173884868622
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8413684368133545,
    bounding_box: [
    {
    x: 0.27006345987319946,
    y: 0.2020900994539261
    },
    {
    x: 0.7545228600502014,
    y: 0.2020900994539261
    },
    {
    x: 0.7545228600502014,
    y: 0.9896650910377502
    },
    {
    x: 0.27006345987319946,
    y: 0.9896650910377502
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.7943822145462036,
    bounding_box: [
    {
    x: 0.2814246714115143,
    y: 0.35571062564849854
    },
    {
    x: 0.748401403427124,
    y: 0.35571062564849854
    },
    {
    x: 0.748401403427124,
    y: 0.9914684891700745
    },
    {
    x: 0.2814246714115143,
    y: 0.9914684891700745
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71747,
    time_in_milliseconds: 2393958,
    labels: [
    {
    description: "Pianist",
    confidence: 98.2115089893341
    },
    {
    description: "Musician",
    confidence: 95.045006275177
    },
    {
    description: "Jazz pianist",
    confidence: 86.80184483528137
    },
    {
    description: "Musical instrument",
    confidence: 86.78944110870361
    },
    {
    description: "Organist",
    confidence: 86.71236634254456
    },
    {
    description: "Piano",
    confidence: 84.21273231506348
    },
    {
    description: "Recital",
    confidence: 83.24280381202698
    },
    {
    description: "Music",
    confidence: 79.59921956062317
    },
    {
    description: "Technology",
    confidence: 78.18163633346558
    },
    {
    description: "Musical keyboard",
    confidence: 77.14201807975769
    }
    ],
    objects: [
    {
    description: "Piano",
    confidence: 0.8814202547073364,
    bounding_box: [
    {
    x: 0.46289917826652527,
    y: 0.1308722347021103
    },
    {
    x: 0.9901381731033325,
    y: 0.1308722347021103
    },
    {
    x: 0.9901381731033325,
    y: 0.989460289478302
    },
    {
    x: 0.46289917826652527,
    y: 0.989460289478302
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.862677276134491,
    bounding_box: [
    {
    x: 0.19281499087810516,
    y: 0.07734105736017227
    },
    {
    x: 0.5469409823417664,
    y: 0.07734105736017227
    },
    {
    x: 0.5469409823417664,
    y: 0.97959965467453
    },
    {
    x: 0.19281499087810516,
    y: 0.97959965467453
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71805,
    time_in_milliseconds: 2395894,
    labels: [
    {
    description: "Musical instrument",
    confidence: 99.25483465194702
    },
    {
    description: "Brass instrument",
    confidence: 97.56918549537659
    },
    {
    description: "Saxophonist",
    confidence: 97.26510047912598
    },
    {
    description: "Saxophone",
    confidence: 97.12859988212585
    },
    {
    description: "Wind instrument",
    confidence: 96.8504250049591
    },
    {
    description: "Woodwind instrument",
    confidence: 89.79511857032776
    },
    {
    description: "Clarinet family",
    confidence: 86.52953505516052
    },
    {
    description: "Music",
    confidence: 76.84196829795837
    },
    {
    description: "Baritone saxophone",
    confidence: 76.84120535850525
    },
    {
    description: "Horn",
    confidence: 74.15834665298462
    }
    ],
    objects: [
    {
    description: "Saxophone",
    confidence: 0.8192785382270813,
    bounding_box: [
    {
    x: 0.1518993228673935,
    y: 0.14171954989433289
    },
    {
    x: 0.5561058521270752,
    y: 0.14171954989433289
    },
    {
    x: 0.5561058521270752,
    y: 0.9903262853622437
    },
    {
    x: 0.1518993228673935,
    y: 0.9903262853622437
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8173539042472839,
    bounding_box: [
    {
    x: 0.35405269265174866,
    y: 0.1545836329460144
    },
    {
    x: 0.7670575976371765,
    y: 0.1545836329460144
    },
    {
    x: 0.7670575976371765,
    y: 0.9879159927368164
    },
    {
    x: 0.35405269265174866,
    y: 0.9879159927368164
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71863,
    time_in_milliseconds: 2397828,
    labels: [
    {
    description: "Brass instrument",
    confidence: 98.8861620426178
    },
    {
    description: "Musical instrument",
    confidence: 98.84474277496338
    },
    {
    description: "Wind instrument",
    confidence: 95.82825303077698
    },
    {
    description: "Types of trombone",
    confidence: 93.5620367527008
    },
    {
    description: "Horn",
    confidence: 84.47836637496948
    },
    {
    description: "Trumpeter",
    confidence: 75.1746118068695
    },
    {
    description: "Trumpet",
    confidence: 74.20051693916321
    },
    {
    description: "Trombone",
    confidence: 68.75906586647034
    },
    {
    description: "Mellophone",
    confidence: 66.19678735733032
    },
    {
    description: "Music",
    confidence: 65.54656028747559
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.796390950679779,
    bounding_box: [
    {
    x: 0.5039733052253723,
    y: 0.5848453044891357
    },
    {
    x: 0.5923146605491638,
    y: 0.5848453044891357
    },
    {
    x: 0.5923146605491638,
    y: 0.777886152267456
    },
    {
    x: 0.5039733052253723,
    y: 0.777886152267456
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7073114514350891,
    bounding_box: [
    {
    x: 0.47736141085624695,
    y: 0.11619699001312256
    },
    {
    x: 0.9973958730697632,
    y: 0.11619699001312256
    },
    {
    x: 0.9973958730697632,
    y: 0.9905467629432678
    },
    {
    x: 0.47736141085624695,
    y: 0.9905467629432678
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7068347334861755,
    bounding_box: [
    {
    x: 0.07946356385946274,
    y: 0.1436033546924591
    },
    {
    x: 0.48604249954223633,
    y: 0.1436033546924591
    },
    {
    x: 0.48604249954223633,
    y: 0.9860231876373291
    },
    {
    x: 0.07946356385946274,
    y: 0.9860231876373291
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71892,
    time_in_milliseconds: 2398796.0000000005,
    labels: [
    {
    description: "Musical instrument",
    confidence: 99.25483465194702
    },
    {
    description: "Brass instrument",
    confidence: 99.25421476364136
    },
    {
    description: "Wind instrument",
    confidence: 98.06576371192932
    },
    {
    description: "Music",
    confidence: 94.15656924247742
    },
    {
    description: "Woodwind instrument",
    confidence: 91.60529971122742
    },
    {
    description: "Clarinet family",
    confidence: 90.11457562446594
    },
    {
    description: "Saxophone",
    confidence: 89.07622694969177
    },
    {
    description: "Types of trombone",
    confidence: 88.45107555389404
    },
    {
    description: "Saxophonist",
    confidence: 85.547536611557
    },
    {
    description: "Jazz",
    confidence: 83.07757377624512
    }
    ],
    objects: [
    {
    description: "Packaged goods",
    confidence: 0.7782297134399414,
    bounding_box: [
    {
    x: 0.5063806176185608,
    y: 0.5810007452964783
    },
    {
    x: 0.6004091501235962,
    y: 0.5810007452964783
    },
    {
    x: 0.6004091501235962,
    y: 0.8020601272583008
    },
    {
    x: 0.5063806176185608,
    y: 0.8020601272583008
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7379101514816284,
    bounding_box: [
    {
    x: 0.06845751404762268,
    y: 0.18214084208011627
    },
    {
    x: 0.35966381430625916,
    y: 0.18214084208011627
    },
    {
    x: 0.35966381430625916,
    y: 0.9973958134651184
    },
    {
    x: 0.06845751404762268,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5797145366668701,
    bounding_box: [
    {
    x: 0.4711841344833374,
    y: 0.06538598239421844
    },
    {
    x: 0.9894020557403564,
    y: 0.06538598239421844
    },
    {
    x: 0.9894020557403564,
    y: 0.9890002608299255
    },
    {
    x: 0.4711841344833374,
    y: 0.9890002608299255
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71921,
    time_in_milliseconds: 2399764,
    labels: [
    {
    description: "Guitar",
    confidence: 99.48479533195496
    },
    {
    description: "Musical instrument",
    confidence: 99.25483465194702
    },
    {
    description: "String instrument",
    confidence: 98.7891674041748
    },
    {
    description: "Guitarist",
    confidence: 98.763108253479
    },
    {
    description: "String instrument",
    confidence: 98.72276782989502
    },
    {
    description: "Musician",
    confidence: 98.46047759056091
    },
    {
    description: "Plucked string instruments",
    confidence: 98.22171926498413
    },
    {
    description: "Music",
    confidence: 96.82190418243408
    },
    {
    description: "Bass guitar",
    confidence: 89.0790343284607
    },
    {
    description: "String instrument accessory",
    confidence: 88.66894245147705
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8785976767539978,
    bounding_box: [
    {
    x: 0.15333352982997894,
    y: 0.09698823094367981
    },
    {
    x: 0.6339202523231506,
    y: 0.09698823094367981
    },
    {
    x: 0.6339202523231506,
    y: 0.9943873882293701
    },
    {
    x: 0.15333352982997894,
    y: 0.9943873882293701
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.8524739146232605,
    bounding_box: [
    {
    x: 0.3662123382091522,
    y: 0.19208282232284546
    },
    {
    x: 0.4871554672718048,
    y: 0.19208282232284546
    },
    {
    x: 0.4871554672718048,
    y: 0.2988465130329132
    },
    {
    x: 0.3662123382091522,
    y: 0.2988465130329132
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7752447724342346,
    bounding_box: [
    {
    x: 0.1776455044746399,
    y: 0.3656226694583893
    },
    {
    x: 0.5195818543434143,
    y: 0.3656226694583893
    },
    {
    x: 0.5195818543434143,
    y: 0.8546150326728821
    },
    {
    x: 0.1776455044746399,
    y: 0.8546150326728821
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.7247198820114136,
    bounding_box: [
    {
    x: 0.3380107879638672,
    y: 0.37698718905448914
    },
    {
    x: 0.43029454350471497,
    y: 0.37698718905448914
    },
    {
    x: 0.43029454350471497,
    y: 0.8301713466644287
    },
    {
    x: 0.3380107879638672,
    y: 0.8301713466644287
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7040546536445618,
    bounding_box: [
    {
    x: 0.17979562282562256,
    y: 0.2544167637825012
    },
    {
    x: 0.5891698002815247,
    y: 0.2544167637825012
    },
    {
    x: 0.5891698002815247,
    y: 0.9130889773368835
    },
    {
    x: 0.17979562282562256,
    y: 0.9130889773368835
    }
    ]
    },
    {
    description: "Guitar",
    confidence: 0.6448912024497986,
    bounding_box: [
    {
    x: 0.19062739610671997,
    y: 0.48706820607185364
    },
    {
    x: 0.8812281489372253,
    y: 0.48706820607185364
    },
    {
    x: 0.8812281489372253,
    y: 0.9973164796829224
    },
    {
    x: 0.19062739610671997,
    y: 0.9973164796829224
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71950,
    time_in_milliseconds: 2400731,
    labels: [
    {
    description: "String instrument",
    confidence: 99.33299422264099
    },
    {
    description: "Musical instrument",
    confidence: 98.84474277496338
    },
    {
    description: "Tololoche",
    confidence: 98.44939112663269
    },
    {
    description: "String instrument",
    confidence: 98.42050671577454
    },
    {
    description: "Violin family",
    confidence: 98.089998960495
    },
    {
    description: "Double bass",
    confidence: 97.27705121040344
    },
    {
    description: "Bowed string instrument",
    confidence: 96.78374528884888
    },
    {
    description: "Violone",
    confidence: 96.49099707603455
    },
    {
    description: "Cello",
    confidence: 95.77394127845764
    },
    {
    description: "Cellist",
    confidence: 95.04721164703369
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8107689023017883,
    bounding_box: [
    {
    x: 0.39239701628685,
    y: 0
    },
    {
    x: 0.7894875407218933,
    y: 0
    },
    {
    x: 0.7894875407218933,
    y: 0.9973958134651184
    },
    {
    x: 0.39239701628685,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Cello",
    confidence: 0.7844864726066589,
    bounding_box: [
    {
    x: 0.5692917704582214,
    y: 0
    },
    {
    x: 0.9888569712638855,
    y: 0
    },
    {
    x: 0.9888569712638855,
    y: 0.9950022101402283
    },
    {
    x: 0.5692917704582214,
    y: 0.9950022101402283
    }
    ]
    },
    {
    description: "Hat",
    confidence: 0.5406330823898315,
    bounding_box: [
    {
    x: 0.46045294404029846,
    y: 0.005622176453471184
    },
    {
    x: 0.6509453654289246,
    y: 0.005622176453471184
    },
    {
    x: 0.6509453654289246,
    y: 0.3105678856372833
    },
    {
    x: 0.46045294404029846,
    y: 0.3105678856372833
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 71979,
    time_in_milliseconds: 2401699,
    labels: [
    {
    description: "Musical instrument",
    confidence: 99.25483465194702
    },
    {
    description: "Saxophonist",
    confidence: 98.11120629310608
    },
    {
    description: "Saxophone",
    confidence: 97.52888679504395
    },
    {
    description: "Wind instrument",
    confidence: 96.8504250049591
    },
    {
    description: "Brass instrument",
    confidence: 95.67376971244812
    },
    {
    description: "Woodwind instrument",
    confidence: 92.05107092857361
    },
    {
    description: "Clarinet family",
    confidence: 91.86869859695435
    },
    {
    description: "Music",
    confidence: 82.67993330955505
    },
    {
    description: "Baritone saxophone",
    confidence: 79.03936505317688
    },
    {
    description: "Reed instrument",
    confidence: 73.90320301055908
    }
    ],
    objects: [
    {
    description: "Saxophone",
    confidence: 0.8971587419509888,
    bounding_box: [
    {
    x: 0.18057656288146973,
    y: 0.15692219138145447
    },
    {
    x: 0.5658038258552551,
    y: 0.15692219138145447
    },
    {
    x: 0.5658038258552551,
    y: 0.9916577339172363
    },
    {
    x: 0.18057656288146973,
    y: 0.9916577339172363
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8765313029289246,
    bounding_box: [
    {
    x: 0.3813721835613251,
    y: 0.1668330430984497
    },
    {
    x: 0.7770962715148926,
    y: 0.1668330430984497
    },
    {
    x: 0.7770962715148926,
    y: 0.991663932800293
    },
    {
    x: 0.3813721835613251,
    y: 0.991663932800293
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72095,
    time_in_milliseconds: 2405570,
    labels: [
    {
    description: "Pianist",
    confidence: 97.80104756355286
    },
    {
    description: "Musician",
    confidence: 92.8981602191925
    },
    {
    description: "Piano",
    confidence: 82.27304816246033
    },
    {
    description: "Technology",
    confidence: 73.20890426635742
    },
    {
    description: "Player piano",
    confidence: 70.92517018318176
    },
    {
    description: "Jazz pianist",
    confidence: 70.22687792778015
    },
    {
    description: "Electronic device",
    confidence: 68.96653175354004
    },
    {
    description: "Organist",
    confidence: 65.31196236610413
    },
    {
    description: "Musical keyboard",
    confidence: 63.285720348358154
    },
    {
    description: "Musical instrument",
    confidence: 61.87419295310974
    }
    ],
    objects: [
    {
    description: "Piano",
    confidence: 0.8723586201667786,
    bounding_box: [
    {
    x: 0.4646444320678711,
    y: 0.13638074696063995
    },
    {
    x: 0.9894620776176453,
    y: 0.13638074696063995
    },
    {
    x: 0.9894620776176453,
    y: 0.9896898865699768
    },
    {
    x: 0.4646444320678711,
    y: 0.9896898865699768
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8517563939094543,
    bounding_box: [
    {
    x: 0.18307989835739136,
    y: 0.08846832066774368
    },
    {
    x: 0.5465753674507141,
    y: 0.08846832066774368
    },
    {
    x: 0.5465753674507141,
    y: 0.9784350395202637
    },
    {
    x: 0.18307989835739136,
    y: 0.9784350395202637
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7800981998443604,
    bounding_box: [
    {
    x: 0.1912008672952652,
    y: 0.08302748203277588
    },
    {
    x: 0.5543728470802307,
    y: 0.08302748203277588
    },
    {
    x: 0.5543728470802307,
    y: 0.9610744118690491
    },
    {
    x: 0.1912008672952652,
    y: 0.9610744118690491
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72211,
    time_in_milliseconds: 2409440,
    labels: [
    {
    description: "Drum",
    confidence: 99.29832816123962
    },
    {
    description: "Musical instrument",
    confidence: 98.84474277496338
    },
    {
    description: "Percussion",
    confidence: 97.31919765472412
    },
    {
    description: "Percussionist",
    confidence: 94.55687999725342
    },
    {
    description: "Conga",
    confidence: 93.7038004398346
    },
    {
    description: "Musician",
    confidence: 93.04608106613159
    },
    {
    description: "Drumhead",
    confidence: 91.90034866333008
    },
    {
    description: "Drums",
    confidence: 91.86508059501648
    },
    {
    description: "Tom-tom drum",
    confidence: 91.3508415222168
    },
    {
    description: "Membranophone",
    confidence: 90.45383334159851
    }
    ],
    objects: [
    {
    description: "Drum",
    confidence: 0.9242727756500244,
    bounding_box: [
    {
    x: 0.09862003475427628,
    y: 0.6818541884422302
    },
    {
    x: 0.417797714471817,
    y: 0.6818541884422302
    },
    {
    x: 0.417797714471817,
    y: 0.9956904053688049
    },
    {
    x: 0.09862003475427628,
    y: 0.9956904053688049
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.915226936340332,
    bounding_box: [
    {
    x: 0.39970555901527405,
    y: 0.0813300833106041
    },
    {
    x: 0.7083904147148132,
    y: 0.0813300833106041
    },
    {
    x: 0.7083904147148132,
    y: 0.9666005969047546
    },
    {
    x: 0.39970555901527405,
    y: 0.9666005969047546
    }
    ]
    },
    {
    description: "Drum",
    confidence: 0.890734851360321,
    bounding_box: [
    {
    x: 0.42248764634132385,
    y: 0.6481965780258179
    },
    {
    x: 0.673940896987915,
    y: 0.6481965780258179
    },
    {
    x: 0.673940896987915,
    y: 0.9954739212989807
    },
    {
    x: 0.42248764634132385,
    y: 0.9954739212989807
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.5266672968864441,
    bounding_box: [
    {
    x: 0.40921488404273987,
    y: 0.2966165840625763
    },
    {
    x: 0.6599807739257812,
    y: 0.2966165840625763
    },
    {
    x: 0.6599807739257812,
    y: 0.6752910017967224
    },
    {
    x: 0.40921488404273987,
    y: 0.6752910017967224
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72269,
    time_in_milliseconds: 2411376,
    labels: [
    {
    description: "Facial hair",
    confidence: 97.14186787605286
    },
    {
    description: "Beard",
    confidence: 95.72392106056213
    },
    {
    description: "Plaid",
    confidence: 85.20304560661316
    },
    {
    description: "Moustache",
    confidence: 80.86875081062317
    },
    {
    description: "Music",
    confidence: 77.84346342086792
    },
    {
    description: "Hat",
    confidence: 76.28358602523804
    },
    {
    description: "Design",
    confidence: 72.03227281570435
    },
    {
    description: "Headgear",
    confidence: 71.11897468566895
    },
    {
    description: "Fun",
    confidence: 70.39424180984497
    },
    {
    description: "Cool",
    confidence: 70.3183650970459
    }
    ],
    objects: [
    {
    description: "Hat",
    confidence: 0.8914640545845032,
    bounding_box: [
    {
    x: 0.39680901169776917,
    y: 0
    },
    {
    x: 0.6042935848236084,
    y: 0
    },
    {
    x: 0.6042935848236084,
    y: 0.32977160811424255
    },
    {
    x: 0.39680901169776917,
    y: 0.32977160811424255
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7622938752174377,
    bounding_box: [
    {
    x: 0.2255711555480957,
    y: 0.004571928177028894
    },
    {
    x: 0.7599244713783264,
    y: 0.004571928177028894
    },
    {
    x: 0.7599244713783264,
    y: 0.9951470494270325
    },
    {
    x: 0.2255711555480957,
    y: 0.9951470494270325
    }
    ]
    },
    {
    description: "Shirt",
    confidence: 0.701806902885437,
    bounding_box: [
    {
    x: 0.259080708026886,
    y: 0.29672977328300476
    },
    {
    x: 0.720618724822998,
    y: 0.29672977328300476
    },
    {
    x: 0.720618724822998,
    y: 0.989854097366333
    },
    {
    x: 0.259080708026886,
    y: 0.989854097366333
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72356,
    time_in_milliseconds: 2414278.0000000005,
    labels: [
    {
    description: "Pianist",
    confidence: 97.22616672515869
    },
    {
    description: "Musician",
    confidence: 93.41450929641724
    },
    {
    description: "Piano",
    confidence: 80.33227920532227
    },
    {
    description: "Player piano",
    confidence: 77.36467719078064
    },
    {
    description: "Technology",
    confidence: 74.57471489906311
    },
    {
    description: "Jazz pianist",
    confidence: 71.51846885681152
    },
    {
    description: "Musical instrument",
    confidence: 70.70480585098267
    },
    {
    description: "Electronic device",
    confidence: 68.96653175354004
    },
    {
    description: "Organist",
    confidence: 60.0030243396759
    },
    {
    description: "Keyboard",
    confidence: 56.89011216163635
    }
    ],
    objects: [
    {
    description: "Piano",
    confidence: 0.8729310631752014,
    bounding_box: [
    {
    x: 0.474330335855484,
    y: 0.12947960197925568
    },
    {
    x: 0.9890315532684326,
    y: 0.12947960197925568
    },
    {
    x: 0.9890315532684326,
    y: 0.9896945953369141
    },
    {
    x: 0.474330335855484,
    y: 0.9896945953369141
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8613258600234985,
    bounding_box: [
    {
    x: 0.18129956722259521,
    y: 0.10220574587583542
    },
    {
    x: 0.5540255904197693,
    y: 0.10220574587583542
    },
    {
    x: 0.5540255904197693,
    y: 0.9879865646362305
    },
    {
    x: 0.18129956722259521,
    y: 0.9879865646362305
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72501,
    time_in_milliseconds: 2419117,
    labels: [
    {
    description: "Musical instrument",
    confidence: 98.99763464927673
    },
    {
    description: "Brass instrument",
    confidence: 97.95631766319275
    },
    {
    description: "Wind instrument",
    confidence: 96.8504250049591
    },
    {
    description: "Saxophone",
    confidence: 94.94052529335022
    },
    {
    description: "Saxophonist",
    confidence: 94.00354623794556
    },
    {
    description: "Woodwind instrument",
    confidence: 87.84346580505371
    },
    {
    description: "Clarinet family",
    confidence: 84.36217308044434
    },
    {
    description: "Brass",
    confidence: 75.22680163383484
    },
    {
    description: "Reed instrument",
    confidence: 67.58617162704468
    },
    {
    description: "Music",
    confidence: 61.178117990493774
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7483854293823242,
    bounding_box: [
    {
    x: 0.3854281008243561,
    y: 0.16101014614105225
    },
    {
    x: 0.7707169651985168,
    y: 0.16101014614105225
    },
    {
    x: 0.7707169651985168,
    y: 0.9840877652168274
    },
    {
    x: 0.3854281008243561,
    y: 0.9840877652168274
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7085517048835754,
    bounding_box: [
    {
    x: 0.3956162631511688,
    y: 0.15053001046180725
    },
    {
    x: 0.77596515417099,
    y: 0.15053001046180725
    },
    {
    x: 0.77596515417099,
    y: 0.9819502830505371
    },
    {
    x: 0.3956162631511688,
    y: 0.9819502830505371
    }
    ]
    },
    {
    description: "Saxophone",
    confidence: 0.6617510318756104,
    bounding_box: [
    {
    x: 0.23686812818050385,
    y: 0.1594599038362503
    },
    {
    x: 0.5934141278266907,
    y: 0.1594599038362503
    },
    {
    x: 0.5934141278266907,
    y: 0.9846691489219666
    },
    {
    x: 0.23686812818050385,
    y: 0.9846691489219666
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72559,
    time_in_milliseconds: 2421052,
    labels: [
    {
    description: "Guitar",
    confidence: 99.48479533195496
    },
    {
    description: "Musical instrument",
    confidence: 99.25483465194702
    },
    {
    description: "String instrument",
    confidence: 99.08444881439209
    },
    {
    description: "Plucked string instruments",
    confidence: 98.80210161209106
    },
    {
    description: "String instrument",
    confidence: 98.75277280807495
    },
    {
    description: "Guitarist",
    confidence: 98.56156706809998
    },
    {
    description: "Musician",
    confidence: 98.02060127258301
    },
    {
    description: "Music",
    confidence: 97.05347418785095
    },
    {
    description: "Bass guitar",
    confidence: 94.42563056945801
    },
    {
    description: "Electric guitar",
    confidence: 93.23468208312988
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.9171733260154724,
    bounding_box: [
    {
    x: 0.2511337399482727,
    y: 0.07380077987909317
    },
    {
    x: 0.6641247272491455,
    y: 0.07380077987909317
    },
    {
    x: 0.6641247272491455,
    y: 0.9973958134651184
    },
    {
    x: 0.2511337399482727,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Guitar",
    confidence: 0.8395073413848877,
    bounding_box: [
    {
    x: 0.26784831285476685,
    y: 0.388213187456131
    },
    {
    x: 0.8968673348426819,
    y: 0.388213187456131
    },
    {
    x: 0.8968673348426819,
    y: 0.9973957538604736
    },
    {
    x: 0.26784831285476685,
    y: 0.9973957538604736
    }
    ]
    },
    {
    description: "Glasses",
    confidence: 0.8189295530319214,
    bounding_box: [
    {
    x: 0.40266668796539307,
    y: 0.16116806864738464
    },
    {
    x: 0.5221993327140808,
    y: 0.16116806864738464
    },
    {
    x: 0.5221993327140808,
    y: 0.25793638825416565
    },
    {
    x: 0.40266668796539307,
    y: 0.25793638825416565
    }
    ]
    },
    {
    description: "Necklace",
    confidence: 0.7996927499771118,
    bounding_box: [
    {
    x: 0.4156022071838379,
    y: 0.3551884889602661
    },
    {
    x: 0.48337456583976746,
    y: 0.3551884889602661
    },
    {
    x: 0.48337456583976746,
    y: 0.7301163673400879
    },
    {
    x: 0.4156022071838379,
    y: 0.7301163673400879
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.7772610783576965,
    bounding_box: [
    {
    x: 0.2611384987831116,
    y: 0.30484309792518616
    },
    {
    x: 0.6011123061180115,
    y: 0.30484309792518616
    },
    {
    x: 0.6011123061180115,
    y: 0.8085103034973145
    },
    {
    x: 0.2611384987831116,
    y: 0.8085103034973145
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7199040055274963,
    bounding_box: [
    {
    x: 0.24599961936473846,
    y: 0.07080120593309402
    },
    {
    x: 0.65895015001297,
    y: 0.07080120593309402
    },
    {
    x: 0.65895015001297,
    y: 0.9729610085487366
    },
    {
    x: 0.24599961936473846,
    y: 0.9729610085487366
    }
    ]
    },
    {
    description: "Sunglasses",
    confidence: 0.5000421404838562,
    bounding_box: [
    {
    x: 0.4036126434803009,
    y: 0.15511755645275116
    },
    {
    x: 0.5196521878242493,
    y: 0.15511755645275116
    },
    {
    x: 0.5196521878242493,
    y: 0.25267156958580017
    },
    {
    x: 0.4036126434803009,
    y: 0.25267156958580017
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72675,
    time_in_milliseconds: 2424922,
    labels: [
    {
    description: "Brass instrument",
    confidence: 98.8861620426178
    },
    {
    description: "Musical instrument",
    confidence: 98.49271774291992
    },
    {
    description: "Wind instrument",
    confidence: 96.57107591629028
    },
    {
    description: "Types of trombone",
    confidence: 91.92036390304565
    },
    {
    description: "Trumpet",
    confidence: 91.58791303634644
    },
    {
    description: "Trumpeter",
    confidence: 87.35270500183105
    },
    {
    description: "Trombone",
    confidence: 83.23551416397095
    },
    {
    description: "Saxhorn",
    confidence: 82.69297480583191
    },
    {
    description: "Flugelhorn",
    confidence: 82.69287943840027
    },
    {
    description: "Cornet",
    confidence: 81.58572912216187
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8660596609115601,
    bounding_box: [
    {
    x: 0.46796128153800964,
    y: 0.13172774016857147
    },
    {
    x: 0.994253396987915,
    y: 0.13172774016857147
    },
    {
    x: 0.994253396987915,
    y: 0.9934746623039246
    },
    {
    x: 0.46796128153800964,
    y: 0.9934746623039246
    }
    ]
    },
    {
    description: "Trumpet",
    confidence: 0.7062109112739563,
    bounding_box: [
    {
    x: 0.13143198192119598,
    y: 0.21692462265491486
    },
    {
    x: 0.7352403998374939,
    y: 0.21692462265491486
    },
    {
    x: 0.7352403998374939,
    y: 0.581494927406311
    },
    {
    x: 0.13143198192119598,
    y: 0.581494927406311
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72733,
    time_in_milliseconds: 2426858,
    labels: [
    {
    description: "String instrument",
    confidence: 99.31777119636536
    },
    {
    description: "Musical instrument",
    confidence: 98.11597466468811
    },
    {
    description: "Tololoche",
    confidence: 98.00004363059998
    },
    {
    description: "String instrument",
    confidence: 97.9684829711914
    },
    {
    description: "Violin family",
    confidence: 97.608482837677
    },
    {
    description: "Bowed string instrument",
    confidence: 96.44219279289246
    },
    {
    description: "Double bass",
    confidence: 96.1266577243805
    },
    {
    description: "Violone",
    confidence: 95.61315178871155
    },
    {
    description: "Cello",
    confidence: 94.8915421962738
    },
    {
    description: "Cellist",
    confidence: 92.19955205917358
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8310068845748901,
    bounding_box: [
    {
    x: 0.3749196529388428,
    y: 0.0432671420276165
    },
    {
    x: 0.7844970226287842,
    y: 0.0432671420276165
    },
    {
    x: 0.7844970226287842,
    y: 0.9815497398376465
    },
    {
    x: 0.3749196529388428,
    y: 0.9815497398376465
    }
    ]
    },
    {
    description: "Cello",
    confidence: 0.8222904205322266,
    bounding_box: [
    {
    x: 0.5560529828071594,
    y: 0
    },
    {
    x: 0.9935296177864075,
    y: 0
    },
    {
    x: 0.9935296177864075,
    y: 0.9973958134651184
    },
    {
    x: 0.5560529828071594,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Headphones",
    confidence: 0.6083561778068542,
    bounding_box: [
    {
    x: 0.4152270555496216,
    y: 0.011450063437223434
    },
    {
    x: 0.6139703989028931,
    y: 0.011450063437223434
    },
    {
    x: 0.6139703989028931,
    y: 0.3327064514160156
    },
    {
    x: 0.4152270555496216,
    y: 0.3327064514160156
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.5921993255615234,
    bounding_box: [
    {
    x: 0.37527015805244446,
    y: 0.3326672613620758
    },
    {
    x: 0.7131080031394958,
    y: 0.3326672613620758
    },
    {
    x: 0.7131080031394958,
    y: 0.9835300445556641
    },
    {
    x: 0.37527015805244446,
    y: 0.9835300445556641
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.5502706170082092,
    bounding_box: [
    {
    x: 0.37347832322120667,
    y: 0.007447467651218176
    },
    {
    x: 0.7995002865791321,
    y: 0.007447467651218176
    },
    {
    x: 0.7995002865791321,
    y: 0.9973958134651184
    },
    {
    x: 0.37347832322120667,
    y: 0.9973958134651184
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72849,
    time_in_milliseconds: 2430729.0000000005,
    labels: [
    {
    description: "Musical instrument",
    confidence: 99.25483465194702
    },
    {
    description: "Brass instrument",
    confidence: 99.25421476364136
    },
    {
    description: "Wind instrument",
    confidence: 97.15757369995117
    },
    {
    description: "Types of trombone",
    confidence: 94.34712529182434
    },
    {
    description: "Music",
    confidence: 93.91993880271912
    },
    {
    description: "Trumpet",
    confidence: 84.73812341690063
    },
    {
    description: "Trombone",
    confidence: 81.37341141700745
    },
    {
    description: "Woodwind instrument",
    confidence: 78.41085195541382
    },
    {
    description: "Jazz",
    confidence: 78.2814621925354
    },
    {
    description: "Trumpeter",
    confidence: 75.1746118068695
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.7242950201034546,
    bounding_box: [
    {
    x: 0.4649462401866913,
    y: 0.13853347301483154
    },
    {
    x: 0.9949711561203003,
    y: 0.13853347301483154
    },
    {
    x: 0.9949711561203003,
    y: 0.9920895099639893
    },
    {
    x: 0.4649462401866913,
    y: 0.9920895099639893
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.6900994181632996,
    bounding_box: [
    {
    x: 0.06943444162607193,
    y: 0.17729997634887695
    },
    {
    x: 0.4888469874858856,
    y: 0.17729997634887695
    },
    {
    x: 0.4888469874858856,
    y: 0.9851901531219482
    },
    {
    x: 0.06943444162607193,
    y: 0.9851901531219482
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.6427873373031616,
    bounding_box: [
    {
    x: 0.5047526955604553,
    y: 0.59641033411026
    },
    {
    x: 0.5868964195251465,
    y: 0.59641033411026
    },
    {
    x: 0.5868964195251465,
    y: 0.7846202850341797
    },
    {
    x: 0.5047526955604553,
    y: 0.7846202850341797
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72907,
    time_in_milliseconds: 2432663,
    labels: [
    {
    description: "Drum",
    confidence: 99.28547143936157
    },
    {
    description: "Musical instrument",
    confidence: 98.84474277496338
    },
    {
    description: "Percussion",
    confidence: 97.19945192337036
    },
    {
    description: "Musician",
    confidence: 94.14990544319153
    },
    {
    description: "Percussionist",
    confidence: 93.842214345932
    },
    {
    description: "Conga",
    confidence: 92.36593246459961
    },
    {
    description: "Drumhead",
    confidence: 91.0787045955658
    },
    {
    description: "Tom-tom drum",
    confidence: 89.95575904846191
    },
    {
    description: "Membranophone",
    confidence: 89.67534899711609
    },
    {
    description: "Drums",
    confidence: 89.48744535446167
    }
    ],
    objects: [
    {
    description: "Drum",
    confidence: 0.9342954158782959,
    bounding_box: [
    {
    x: 0.09737125039100647,
    y: 0.6810173392295837
    },
    {
    x: 0.416380375623703,
    y: 0.6810173392295837
    },
    {
    x: 0.416380375623703,
    y: 0.9955644011497498
    },
    {
    x: 0.09737125039100647,
    y: 0.9955644011497498
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.913803219795227,
    bounding_box: [
    {
    x: 0.3890201151371002,
    y: 0.07321065664291382
    },
    {
    x: 0.7113984227180481,
    y: 0.07321065664291382
    },
    {
    x: 0.7113984227180481,
    y: 0.9566548466682434
    },
    {
    x: 0.3890201151371002,
    y: 0.9566548466682434
    }
    ]
    },
    {
    description: "Drum",
    confidence: 0.8944504261016846,
    bounding_box: [
    {
    x: 0.4217664301395416,
    y: 0.6614285707473755
    },
    {
    x: 0.6766757965087891,
    y: 0.6614285707473755
    },
    {
    x: 0.6766757965087891,
    y: 0.9955695271492004
    },
    {
    x: 0.4217664301395416,
    y: 0.9955695271492004
    }
    ]
    },
    {
    description: "Top",
    confidence: 0.6094478368759155,
    bounding_box: [
    {
    x: 0.4030320644378662,
    y: 0.2983708381652832
    },
    {
    x: 0.66151362657547,
    y: 0.2983708381652832
    },
    {
    x: 0.66151362657547,
    y: 0.6691367030143738
    },
    {
    x: 0.4030320644378662,
    y: 0.6691367030143738
    }
    ]
    },
    {
    description: "Clothing",
    confidence: 0.509067177772522,
    bounding_box: [
    {
    x: 0.39813414216041565,
    y: 0.0856376364827156
    },
    {
    x: 0.7279499173164368,
    y: 0.0856376364827156
    },
    {
    x: 0.7279499173164368,
    y: 0.9888473153114319
    },
    {
    x: 0.39813414216041565,
    y: 0.9888473153114319
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72936,
    time_in_milliseconds: 2433631.0000000005,
    labels: [
    {
    description: "Pianist",
    confidence: 98.2115089893341
    },
    {
    description: "Musician",
    confidence: 95.35109996795654
    },
    {
    description: "Jazz pianist",
    confidence: 83.11955332756042
    },
    {
    description: "Piano",
    confidence: 82.70365595817566
    },
    {
    description: "Player piano",
    confidence: 79.14842367172241
    },
    {
    description: "Music",
    confidence: 76.84196829795837
    },
    {
    description: "Musical instrument",
    confidence: 76.60303711891174
    },
    {
    description: "Organist",
    confidence: 76.42290592193604
    },
    {
    description: "Technology",
    confidence: 76.42231583595276
    },
    {
    description: "Recital",
    confidence: 74.0535020828247
    }
    ],
    objects: [
    {
    description: "Piano",
    confidence: 0.8905556201934814,
    bounding_box: [
    {
    x: 0.46545568108558655,
    y: 0.13483946025371552
    },
    {
    x: 0.9899883270263672,
    y: 0.13483946025371552
    },
    {
    x: 0.9899883270263672,
    y: 0.990631103515625
    },
    {
    x: 0.46545568108558655,
    y: 0.990631103515625
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.8430463075637817,
    bounding_box: [
    {
    x: 0.20007199048995972,
    y: 0.08346155285835266
    },
    {
    x: 0.5365443825721741,
    y: 0.08346155285835266
    },
    {
    x: 0.5365443825721741,
    y: 0.9687962532043457
    },
    {
    x: 0.20007199048995972,
    y: 0.9687962532043457
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 72994,
    time_in_milliseconds: 2435566.0000000005,
    labels: [
    {
    description: "Brass instrument",
    confidence: 94.70747113227844
    },
    {
    description: "Facial hair",
    confidence: 94.62777972221375
    },
    {
    description: "Beard",
    confidence: 92.76137351989746
    },
    {
    description: "Musical instrument",
    confidence: 89.70560431480408
    },
    {
    description: "Music",
    confidence: 76.84196829795837
    },
    {
    description: "Wind instrument",
    confidence: 76.28190517425537
    },
    {
    description: "Trumpet",
    confidence: 70.63174247741699
    },
    {
    description: "Drink",
    confidence: 70.0955867767334
    },
    {
    description: "Alcohol",
    confidence: 65.6622052192688
    },
    {
    description: "Muscle",
    confidence: 64.24193978309631
    }
    ],
    objects: [
    {
    description: "Person",
    confidence: 0.8650558590888977,
    bounding_box: [
    {
    x: 0.318636029958725,
    y: 0.08831292390823364
    },
    {
    x: 0.6514917016029358,
    y: 0.08831292390823364
    },
    {
    x: 0.6514917016029358,
    y: 0.9936842918395996
    },
    {
    x: 0.318636029958725,
    y: 0.9936842918395996
    }
    ]
    },
    {
    description: "Saxophone",
    confidence: 0.8302596807479858,
    bounding_box: [
    {
    x: 0.010679338127374649,
    y: 0.20293569564819336
    },
    {
    x: 0.2138829380273819,
    y: 0.20293569564819336
    },
    {
    x: 0.2138829380273819,
    y: 0.9973958134651184
    },
    {
    x: 0.010679338127374649,
    y: 0.9973958134651184
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7703033089637756,
    bounding_box: [
    {
    x: 0.6392501592636108,
    y: 0.12469840794801712
    },
    {
    x: 0.9916183352470398,
    y: 0.12469840794801712
    },
    {
    x: 0.9916183352470398,
    y: 0.987955629825592
    },
    {
    x: 0.6392501592636108,
    y: 0.987955629825592
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7359114289283752,
    bounding_box: [
    {
    x: 0.32175561785697937,
    y: 0.09488461166620255
    },
    {
    x: 0.6371070742607117,
    y: 0.09488461166620255
    },
    {
    x: 0.6371070742607117,
    y: 0.9673944115638733
    },
    {
    x: 0.32175561785697937,
    y: 0.9673944115638733
    }
    ]
    },
    {
    description: "Person",
    confidence: 0.7158731818199158,
    bounding_box: [
    {
    x: 0.00560107221826911,
    y: 0.18356581032276154
    },
    {
    x: 0.3225024938583374,
    y: 0.18356581032276154
    },
    {
    x: 0.3225024938583374,
    y: 0.9948949217796326
    },
    {
    x: 0.00560107221826911,
    y: 0.9948949217796326
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.7119939923286438,
    bounding_box: [
    {
    x: 0.6579246520996094,
    y: 0.6158033013343811
    },
    {
    x: 0.7310214042663574,
    y: 0.6158033013343811
    },
    {
    x: 0.7310214042663574,
    y: 0.8045145869255066
    },
    {
    x: 0.6579246520996094,
    y: 0.8045145869255066
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 73052,
    time_in_milliseconds: 2437502,
    labels: [
    {
    description: "Water",
    confidence: 91.00271463394165
    },
    {
    description: "Bird",
    confidence: 86.83387041091919
    },
    {
    description: "Grass family",
    confidence: 80.7116687297821
    },
    {
    description: "Beak",
    confidence: 77.54819393157959
    },
    {
    description: "Drawing",
    confidence: 69.09903883934021
    },
    {
    description: "Tree",
    confidence: 68.69218349456787
    },
    {
    description: "Line art",
    confidence: 68.26000213623047
    },
    {
    description: "Crane",
    confidence: 68.04771423339844
    },
    {
    description: "Heron",
    confidence: 65.93765616416931
    },
    {
    description: "Crane-like bird",
    confidence: 63.29675912857056
    }
    ],
    objects: [
    {
    description: "Bird",
    confidence: 0.8926138877868652,
    bounding_box: [
    {
    x: 0.5097483992576599,
    y: 0.27454978227615356
    },
    {
    x: 0.5971435308456421,
    y: 0.27454978227615356
    },
    {
    x: 0.5971435308456421,
    y: 0.6633872389793396
    },
    {
    x: 0.5097483992576599,
    y: 0.6633872389793396
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 73139,
    time_in_milliseconds: 2440405,
    labels: [
    {
    description: "Blue",
    confidence: 92.6959753036499
    },
    {
    description: "Light",
    confidence: 88.34760785102844
    },
    {
    description: "Lens flare",
    confidence: 75.6406307220459
    },
    {
    description: "Circle",
    confidence: 74.45924878120422
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Atmosphere",
    confidence: 72.89918065071106
    },
    {
    description: "Electric blue",
    confidence: 72.47074842453003
    },
    {
    description: "Space",
    confidence: 72.1732497215271
    },
    {
    description: "Graphics",
    confidence: 69.69752907752991
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 73168,
    time_in_milliseconds: 2441372.0000000005,
    labels: [
    {
    description: "Blue",
    confidence: 95.43808102607727
    },
    {
    description: "Aqua",
    confidence: 86.17390394210815
    },
    {
    description: "Design",
    confidence: 72.03227281570435
    },
    {
    description: "Technology",
    confidence: 67.57805347442627
    },
    {
    description: "Graphic design",
    confidence: 63.03711533546448
    },
    {
    description: "Graphics",
    confidence: 54.627203941345215
    }
    ],
    objects: [
    {
    description: "Luggage & bags",
    confidence: 0.6444457173347473,
    bounding_box: [
    {
    x: 0,
    y: 0
    },
    {
    x: 0.986332893371582,
    y: 0
    },
    {
    x: 0.986332893371582,
    y: 0.9879999756813049
    },
    {
    x: 0,
    y: 0.9879999756813049
    }
    ]
    },
    {
    description: "Packaged goods",
    confidence: 0.5335773825645447,
    bounding_box: [
    {
    x: 0.5972902178764343,
    y: 0.7853081822395325
    },
    {
    x: 0.9304646849632263,
    y: 0.7853081822395325
    },
    {
    x: 0.9304646849632263,
    y: 0.9945926070213318
    },
    {
    x: 0.5972902178764343,
    y: 0.9945926070213318
    }
    ]
    }
    ],
    faces: [ ]
    },
    {
    frame_number: 73197,
    time_in_milliseconds: 2442340,
    labels: [
    {
    description: "Text",
    confidence: 91.89756512641907
    },
    {
    description: "Font",
    confidence: 81.95118308067322
    },
    {
    description: "Logo",
    confidence: 77.09203958511353
    },
    {
    description: "Sky",
    confidence: 73.26111197471619
    },
    {
    description: "Graphics",
    confidence: 69.27334070205688
    },
    {
    description: "Electric blue",
    confidence: 63.79328370094299
    },
    {
    description: "Graphic design",
    confidence: 60.45575141906738
    },
    {
    description: "Brand",
    confidence: 58.21748375892639
    },
    {
    description: "Advertising",
    confidence: 53.032636642456055
    }
    ],
    objects: [ ],
    faces: [ ]
    },
    {
    frame_number: 73226,
    time_in_milliseconds: 2443308,
    labels: [
    {
    description: "Black",
    confidence: 98.49877953529358
    },
    {
    description: "Darkness",
    confidence: 97.3947525024414
    },
    {
    description: "White",
    confidence: 96.54507637023926
    },
    {
    description: "Sky",
    confidence: 93.36194396018982
    },
    {
    description: "Light",
    confidence: 91.6901707649231
    },
    {
    description: "Red",
    confidence: 91.53681993484497
    },
    {
    description: "Text",
    confidence: 91.21394753456116
    },
    {
    description: "Brown",
    confidence: 88.69289755821228
    },
    {
    description: "Font",
    confidence: 85.57851910591125
    },
    {
    description: "Atmosphere",
    confidence: 82.49121904373169
    }
    ],
    objects: [ ],
    faces: [ ]
    }
    ]