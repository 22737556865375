import { Box, makeStyles, Typography } from "@material-ui/core"
import React, { FC, useState } from "react"
import { nerData } from "../../data/nerData"
import { objectLabelData } from "../../data/objectLabelData"
import { topicData } from "../../data/topicData"
import { videoIntelligenceData } from "../../data/videoIntelligenceData"
import Switch from "../Switch"

const useStyles = makeStyles({
    comparison: {
        display: "flex"
    },
    section: {
        width: 600
    },
    labelToggle: {
        display: "flex",
        alignItems: "center"
    }
})

const Comparison: FC = () => {
    const classes = useStyles()
    const [showWords, setShowWords] = useState<boolean>(false)
    const [showNer, setShowNer] = useState<boolean>(false)
    const [showObjectLabels, setShowObjectLabels] = useState<boolean>(false)

    const toggleWords = () => setShowWords(!showWords)

    const toggleNer = () => setShowNer(!showNer)

    const toggleObjectLabels = () => setShowObjectLabels(!showObjectLabels)

    return (
        <div>
            <Box marginBottom={1}>
                <Typography variant="h3" component="h1">
                    Comparing Google's Raw Output with DBMO
                </Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h5" component="h2">
                    The Late Show, 07/16/2020, W. Kamau Bell, The Chicks
                </Typography>
            </Box>
            <div className={classes.comparison}>
                <section className={classes.section}>
                    <Box marginBottom={1}>
                        <div className={classes.labelToggle}>
                            <Typography variant="h6">Video Intelligence Words</Typography>
                            <Switch checked={showWords} onChange={toggleWords} />
                        </div>
                        {showWords && <pre>{JSON.stringify(videoIntelligenceData, null, 2)}</pre>}
                    </Box>
                    <Box marginBottom={1}>
                        <div className={classes.labelToggle}>
                            <Typography variant="h6">Named Entities</Typography>
                            <Switch checked={showNer} onChange={toggleNer} />
                        </div>
                        {showNer && <pre>{JSON.stringify(nerData, null, 2)}</pre>}
                    </Box>
                    <Box marginBottom={1}>
                        <div className={classes.labelToggle}>
                            <Typography variant="h6">Object Labels</Typography>
                            <Switch checked={showObjectLabels} onChange={toggleObjectLabels} />
                        </div>
                        {showObjectLabels && <pre>{JSON.stringify(objectLabelData, null, 2)}</pre>}
                    </Box>
                </section>
                <section className={classes.section}>
                    <Typography variant="h6">DBMO Topics</Typography>
                    <pre>{JSON.stringify(topicData, null, 2)}</pre>
                </section>
            </div>
        </div>
    )
}

export default Comparison
