import { Box, Grid, makeStyles, Slider, Typography } from "@material-ui/core"
import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from "react"
import { XCircle } from "react-feather"
import { useHistory, useParams } from "react-router"
import { mediaUuidKey, projectId, VideoWidth } from "../../constants"
import { getBucketPath, useGetVideo } from "../../graphql/media"
import { useApiService } from "../../providers/ApiServiceProvider"
import { useGetLoginData, useResultsVideo } from "../../store"
import { ArtifactJson, Frame, SummaryImage, Topic, VideoWithArtifact, Word } from "../../types/types"
import Button from "../Button"
import Filter from "./Filter"
import Frames from "./Frames"
import {
    getAttributesFromArtifact,
    getTopicsFromArtifact,
    getTotalInstancesCount,
    getWordsAndFramesFromArtifact
} from "./helpers"
import ImageSummary from "./ImageSummary"
import TextButton from "./TextButton"
import Topics from "./Topics"
import Transcript from "./Transcript"
import Video from "./Video"
import Words from "./Words"

const useStyles = makeStyles({
    results: {
        display: "flex",
        marginTop: 24
    },
    resultsSection: {
        width: 501,
        marginRight: 48,
        marginTop: 24
    },
    confidenceSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    confidenceLabel: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        lineHeight: "24px",
        color: "#2F232D",
        margin: 0
    },
    confidenceSlider: {
        width: "100%"
    },
    confidenceSliderRoot: {
        height: 8,
        padding: 0
    },
    confidenceSliderRail: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        height: 8,
        borderRadius: 8
    },
    confidenceSliderTrack: {
        backgroundColor: "#73B3FF",
        height: 8,
        borderRadius: 8
    },
    confidenceSliderThumb: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(54, 53, 69, 0.15)",
        border: "2px solid #73B3FF",
        height: 20,
        width: 20,
        "&:hover": {
            backgroundColor: "#ECF4FE"
        },
        "&:active": {
            backgroundColor: "#73B3FF"
        }
    },
    topicDataSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #D4D3D3",
        minHeight: 72,
        padding: 24,
        paddingLeft: 48,
        paddingRight: 48
    },
    topicBarsSection: {
        padding: 24,
        paddingLeft: 48,
        paddingRight: 48
    },
    loadingCircle: {
        color: "#004FFF"
    },
    textButton: {
        color: "#3A5A80",
        textTransform: "none",
        fontSize: 16,
        lineHeight: "24px"
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    spacedOut: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    button: {
        width: "100%"
    },
    line: {
        width: 501,
        height: 1,
        backgroundColor: "#D4D3D3",
        marginTop: 24,
        marginBottom: 24
    },
    bold: {
        fontWeight: 600
    },
    topicsCard: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        borderRadius: 8,
        width: 501,
        paddingTop: 24,
        paddingBottom: 24,
        marginTop: 24
    },
    topicsCardContent: {
        paddingLeft: 24,
        paddingRight: 24
    },
    overviewCard: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        borderRadius: 8,
        width: 721
    },
    attributes: {
        display: "flex"
    },
    confidence: {
        color: "#6A6068"
    },
    resetIcon: {
        backgroundColor: "#ECF4FE",
        borderRadius: "50%"
    },
    resetText: {
        marginLeft: 4
    },
    elementsDetected: {
        color: "#6A6068"
    },
    topicFilters: {
        display: "flex",
        alignItems: "top",
        justifyContent: "space-between"
    }
})

const InitialConfidence = 0.6

const Results: FC = () => {
    const classes = useStyles()
    const loginData = useGetLoginData()
    const [resultsMedia, setResultsMedia] = useResultsVideo()
    const { uuid } = useParams<{ uuid: string }>()
    const mediaData = useGetVideo({ variables: { media_uuid: uuid } })
    const [mediaArtifact, setMediaArtifact] = useState<ArtifactJson | undefined>(undefined)
    const history = useHistory()
    const [attributes, setAttributes] = useState<string[] | undefined>(undefined)
    const [topics, setTopics] = useState<Topic[] | undefined>(undefined)
    const [selectedTopics, setSelectedTopics] = useState<Topic[]>([])
    const [words, setWords] = useState<Word[] | undefined>(undefined)
    const [frames, setFrames] = useState<Frame[] | undefined>(undefined)
    const [transcript, setTranscript] = useState<Word[] | undefined>(undefined)
    const [word, setWord] = useState<Word | undefined>(undefined)
    const [frame, setFrame] = useState<Frame | undefined>(undefined)
    const [confidence, setConfidence] = useState<number>(InitialConfidence)
    const [viewTranscript, setViewTranscript] = useState<boolean>(false)
    const videoRef = useRef<HTMLVideoElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [duration, setDuration] = useState<number>(0)
    const [instancesFound, setInstancesFound] = useState<number>(0)
    const [filter, setFilter] = useState<string>("All")
    const [showAllTopics, setShowAllTopics] = useState<boolean>(false)
    const [showTopicExpander, setShowTopicExpander] = useState<boolean>(false)
    const [refreshedData, setRefreshedData] = useState<boolean>(false)
    const [summaryImages, setSummaryImages] = useState<SummaryImage[] | undefined>(undefined)
    const [summaryImageFolder, setSummaryImageFolder] = useState<string | undefined>(undefined)
    const [title, setTitle] = useState<string | undefined>(undefined)
    const ApiService = useApiService()

    const setTotalInstancesFound = useCallback(
        (newTopics: Topic[]) => {
            setInstancesFound(
                getTotalInstancesCount({
                    newTopics,
                    words,
                    frames
                })
            )
        },
        [frames, words]
    )

    const getAttributes = useCallback(
        (mediaArtifact: ArtifactJson, { getThemeAttributes }: { getThemeAttributes: boolean }) =>
            getAttributesFromArtifact(mediaArtifact, { getThemeAttributes }),
        []
    )

    const getTopics = useCallback(
        (
            mediaArtifact: ArtifactJson,
            topicData?: {
                topicFilter?: string
                topicConfidence?: number
                getAllTopics?: boolean
            }
        ) =>
            getTopicsFromArtifact(
                mediaArtifact,
                topicData?.topicFilter ?? filter,
                topicData?.topicConfidence ?? confidence,
                topicData?.getAllTopics ?? showAllTopics
            ),
        [filter, confidence, showAllTopics]
    )

    const getWordsAndFrames = useCallback(
        (newTopics: Topic[], mediaArtifact: ArtifactJson) => getWordsAndFramesFromArtifact(newTopics, mediaArtifact),
        []
    )

    const getResultsMedia = useCallback(async () => {
        const mediaObject = mediaData.data?.media[0]

        if (mediaObject === undefined) {
            throw Error(`Could not find url on media result`)
        }

        const url = mediaObject.url
        const bucketPath = getBucketPath(url)
        console.log(`bucketPath ${bucketPath}`)

        const bucketFilePath = `${bucketPath}/${mediaData.data?.media[0].file_name ?? ""}`
        const contentType = mediaData.data?.media[0].type ?? ""

        const response = await ApiService.getReadableSignedUrl(bucketFilePath, contentType)

        if (response?.data) {
            setResultsMedia({
                mediaUrl: response.data,
                media: mediaData.data?.media[0] as VideoWithArtifact
            })

            setRefreshedData(true)
        }
    }, [ApiService, mediaData, setResultsMedia])

    useEffect(() => {
        const mediaRecord = mediaData.data?.media?.[0]
        const artifactJson = mediaRecord?.latest_media_artifact?.artifact_json
        if (artifactJson && !mediaArtifact) {
            if (!artifactJson.video_duration) {
                console.warn("video_duration is not found on artifact_json")
            }
            const viewTranscript = (!!mediaRecord && mediaRecord.type === 'text')
            setViewTranscript(viewTranscript)
            setMediaArtifact(artifactJson)

            if (uuid) {
                sessionStorage.setItem(mediaUuidKey, uuid)
            }

            const isProductionEnvironment = projectId.includes("production")

            if (!isProductionEnvironment) {
                setSummaryImages(artifactJson.summaryFrames ?? artifactJson.summaryImages)
                setSummaryImageFolder(mediaRecord?.file_name.split(".")[0])
            }

            const attributes = getAttributes(artifactJson, { getThemeAttributes: !isProductionEnvironment })
            setAttributes(attributes)

            const newTopics = getTopics(artifactJson)
            const newWordsAndFrames = getWordsAndFrames(newTopics.topics, artifactJson)
            setShowTopicExpander(newTopics.allTopics.length > 9)
            setTopics(newTopics.topics)
            setFrames(newWordsAndFrames.newFrames)
            setWords(newWordsAndFrames.newWords)
            setInstancesFound(newWordsAndFrames.newFrames.length + newWordsAndFrames.newWords.length)
            setTranscript(artifactJson.phrases)
            setTitle(mediaRecord?.title)
            setDuration(artifactJson.video_duration / 1000)

            if (!refreshedData && mediaRecord) {
                getResultsMedia()
            }
        }
    }, [
        ApiService,
        getResultsMedia,
        getAttributes,
        getTopics,
        getWordsAndFrames,
        history,
        loginData,
        mediaArtifact,
        mediaData.data,
        refreshedData,
        resultsMedia,
        setResultsMedia,
        uuid
    ])

    const clearBoundingBoxes = () => {
        const context = canvasRef.current?.getContext("2d")
        if (!context) {
            return
        }
        context.clearRect(0, 0, VideoWidth, 1000)
    }

    const reset = () => {
        clearBoundingBoxes()
        if (!resultsMedia || !mediaArtifact) {
            return
        }

        const newTopics = getTopics(mediaArtifact, {
            topicFilter: "All",
            topicConfidence: InitialConfidence,
            getAllTopics: false
        })

        setShowTopicExpander(newTopics.allTopics.length > 9)
        const newWordsAndFrames = getWordsAndFrames(newTopics.topics, mediaArtifact)
        setTopics(newTopics.topics)
        setFrames(newWordsAndFrames.newFrames)
        setWords(newWordsAndFrames.newWords)
        setInstancesFound(newWordsAndFrames.newFrames.length + newWordsAndFrames.newWords.length)
        setSelectedTopics([])
        setWord(undefined)
        setFrame(undefined)
        setConfidence(InitialConfidence)
        setFilter("All")
        setShowAllTopics(false)
    }

    const onSelectTopic = (topic: Topic) => (event: MouseEvent) => {
        console.log(`[onSelectTopics] topic ${topic.topic}`)
        clearBoundingBoxes()
        let newSelectedTopics: Topic[] = []
        if (
            selectedTopics.length === 0 ||
            selectedTopics.length > 1 ||
            (selectedTopics.length === 1 && selectedTopics[0].id !== topic.id)
        ) {
            newSelectedTopics = [topic]
        }
        console.log(`[onSelectTopics] newSelectedTopics ${newSelectedTopics.map(t => t.topic)}`)
        setSelectedTopics(newSelectedTopics)
        setTotalInstancesFound(newSelectedTopics)
        setWord(undefined)
        setFrame(undefined)
    }

    const onSelectFrame = (newFrame: Frame) => {
        clearBoundingBoxes()
        setFrame(newFrame.id !== frame?.id || !frame ? newFrame : undefined)
        setWord(undefined)
        if (topics && selectedTopics.length === 0) {
            const seenTopicIds: string[] = []
            const newSelectedTopics: Topic[] = []
            newFrame.objects?.forEach(object =>
                object.topics?.forEach(topic => {
                    if (!seenTopicIds.includes(topic.id)) {
                        seenTopicIds.push(topic.id)
                        const newTopic = topics.find(t => t.id === topic.id)
                        if (newTopic) {
                            newSelectedTopics.push(newTopic)
                        }
                    }
                })
            )
            setSelectedTopics(newFrame.id !== frame?.id || !frame ? newSelectedTopics : [])
            setTotalInstancesFound(newFrame.id !== frame?.id || !frame ? newSelectedTopics : topics)
        }
        if (videoRef.current) {
            videoRef.current.currentTime = newFrame.timestamp / 1000
        }
    }

    const onSelectWord = (newWord: Word) => {
        clearBoundingBoxes()
        setWord(newWord.id !== word?.id || !word ? newWord : undefined)
        setViewTranscript(true)
        setFrame(undefined)
        if (topics && selectedTopics.length === 0) {
            const newSelectedTopics: Topic[] = []
            newWord.topics?.forEach(topic => {
                const newTopic = topics.find(t => t.id === topic.id)
                if (newTopic) {
                    newSelectedTopics.push(newTopic)
                }
            })
            setSelectedTopics(newWord.id !== word?.id || !word ? newSelectedTopics : [])
            setTotalInstancesFound(newWord.id !== word?.id || !word ? newSelectedTopics : topics)
        }
        if (videoRef.current) {
            videoRef.current.currentTime = newWord.time[0] / 1000
        }
    }

    const onSelectSummaryImage = (summaryImage: SummaryImage) => (event: MouseEvent<HTMLButtonElement>) => {
        if (videoRef.current) {
            videoRef.current.currentTime = summaryImage.timestamp / 1000
        }
    }

    const onChangeShowAllTopics = () => {
        if (!mediaArtifact) {
            return
        }

        const newTopics = getTopics(mediaArtifact, {
            getAllTopics: !showAllTopics
        })
        const newWordsAndFrames = getWordsAndFrames(newTopics.topics, mediaArtifact)

        setTopics(newTopics.topics)
        setFrames(newWordsAndFrames.newFrames)
        setWords(newWordsAndFrames.newWords)
        setTotalInstancesFound(newTopics.allTopics)
        setShowAllTopics(!showAllTopics)
    }

    const onChangeConfidence = (event: any, newValue: number | number[]) => {
        const newConfidence = newValue as number
        if (newConfidence === confidence) {
            return
        }
        setConfidence(newConfidence)
    }

    const onChangeCommittedConfidence = (event: any, newValue: number | number[]) => {
        if (!mediaArtifact) {
            return
        }

        const newConfidence = newValue as number
        const newTopics = getTopics(mediaArtifact, {
            topicConfidence: newConfidence
        })

        const newWordsAndFrames = getWordsAndFrames(newTopics.topics, mediaArtifact)
        setTopics(newTopics.topics)
        setFrames(newWordsAndFrames.newFrames)
        setWords(newWordsAndFrames.newWords)
        setShowTopicExpander(newTopics.allTopics.length > 9)
        setTotalInstancesFound(newTopics.allTopics)
        setConfidence(newConfidence)
    }

    const onChangeFilter = (newFilter: string) => {
        if (!mediaArtifact) {
            return
        }
        if (newFilter === filter) {
            return
        }

        const newTopics = getTopics(mediaArtifact, { topicFilter: newFilter })
        const newWordsAndFrames = getWordsAndFrames(newTopics.topics, mediaArtifact)

        setTopics(newTopics.topics)
        setFrames(newWordsAndFrames.newFrames)
        setWords(newWordsAndFrames.newWords)
        setShowTopicExpander(newTopics.allTopics.length > 9)
        setTotalInstancesFound(newTopics.allTopics)
        setFilter(newFilter)
    }

    const onViewTranscript = () => setViewTranscript(!viewTranscript)

    const chooseAnotherVideo = () => {
        sessionStorage.removeItem(mediaUuidKey)
        history.push("/choose-video")
    }

    return (
        <div className={classes.results}>
            <div className={classes.resultsSection}>
                {refreshedData && (
                    <>
                        <Box marginBottom={3}>
                            <Typography className={classes.bold} variant="h5" component="h1">
                                {title}
                            </Typography>
                        </Box>
                        <Typography variant="subtitle1">
                            {resultsMedia?.media.show && `${resultsMedia?.media.show}`}
                            {resultsMedia?.media.show && resultsMedia?.media.season_episode && " | "}
                            {resultsMedia?.media.season_episode && `${resultsMedia?.media.season_episode}`}
                            {(resultsMedia?.media.show || resultsMedia?.media.season_episode) &&
                                resultsMedia?.media.publisher &&
                                " | "}
                            {resultsMedia?.media.publisher && `${resultsMedia?.media.publisher}`}
                        </Typography>
                    </>
                )}
                <div className={classes.line} />
                {attributes && attributes.length > 0 && (
                    <>
                        <Box marginBottom={1}>
                            <Typography variant="h6">
                                <span className={classes.bold}>Attributes</span>
                                {` (${attributes?.length})`}
                            </Typography>
                        </Box>
                        <Grid container={true} className={classes.attributes} spacing={1}>
                            {attributes.map((attribute, index, array) => (
                                <Grid item={true} key={attribute}>
                                    <Typography variant="h6">
                                        {attribute}
                                        {index < array.length - 1 && ", "}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
                <div className={classes.topicsCard}>
                    <div className={classes.topicsCardContent}>
                        <div className={classes.topicFilters}>
                            <Typography variant="h6">
                                <span className={classes.bold}>Topics</span>
                                {` (${topics?.length ?? 0})`}
                            </Typography>
                            <Filter setFilter={onChangeFilter} filter={filter} topics={topics} />
                        </div>
                        <Box marginBottom={4} marginTop={3}>
                            <Box className={classes.confidenceSection} marginBottom={1}>
                                <h6 id="confidence-threshold" className={classes.confidenceLabel}>
                                    Confidence Threshold
                                </h6>
                                <Typography variant="caption" className={classes.confidence}>
                                    {confidence.toFixed(2)}
                                </Typography>
                            </Box>
                            <Slider
                                value={confidence}
                                min={0}
                                max={1}
                                step={0.01}
                                aria-labelledby="confidence-threshold"
                                className={classes.confidenceSlider}
                                onChange={onChangeConfidence}
                                onChangeCommitted={onChangeCommittedConfidence}
                                classes={{
                                    root: classes.confidenceSliderRoot,
                                    rail: classes.confidenceSliderRail,
                                    track: classes.confidenceSliderTrack,
                                    thumb: classes.confidenceSliderThumb
                                }}
                            />
                        </Box>
                    </div>
                    <Topics
                        topics={topics}
                        selectedTopics={selectedTopics}
                        onSelectTopic={onSelectTopic}
                        showAllTopics={showAllTopics}
                        showTopicExpander={showTopicExpander}
                        onChangeShowAllTopics={onChangeShowAllTopics}
                    />
                    <div className={classes.topicsCardContent}>
                        <Box marginTop={2} className={classes.spacedOut}>
                            <Typography variant="caption" className={classes.elementsDetected}>
                                * Elements detected
                            </Typography>
                            <TextButton className={classes.textButton} onClick={reset}>
                                <XCircle className={classes.resetIcon} color="#3A5A80" strokeWidth={1} size={16} />
                                <span className={classes.resetText}>Reset All</span>
                            </TextButton>
                        </Box>
                    </div>
                </div>
                {summaryImageFolder && summaryImages && (summaryImages.length ?? 0) > 0 && (
                    <Box marginTop={3}>
                        <ImageSummary
                            mediaFolder={summaryImageFolder}
                            summaryImages={summaryImages}
                            onSelectSummaryImage={onSelectSummaryImage}
                        />
                    </Box>
                )}
                <Box className={classes.center} marginTop={3}>
                    <Button className={classes.button} onClick={chooseAnotherVideo}>
                        Choose Another Video
                    </Button>
                </Box>
            </div>
            <div className={classes.overviewCard}>
                <div className={classes.topicDataSection}>
                    <Typography className={classes.bold} variant="h5" component="h2">
                        {selectedTopics.length === 1
                            ? selectedTopics[0].topic
                            : selectedTopics.length > 1
                            ? selectedTopics.map(topic => topic.topic).join(", ")
                            : "Overview"}
                    </Typography>
                    <Typography variant="subtitle2">{instancesFound} Total Instances Found</Typography>
                </div>
                <div className={classes.topicBarsSection}>
                    {(resultsMedia?.media.type !== 'text') ?
                        <Box marginBottom={5}>
                            <Video videoRef={videoRef} canvasRef={canvasRef} frame={frame} onSelectFrame={onSelectFrame} />
                        </Box>
                        : null
                    }
                    {(frames && frames.length > 0) ?
                        <Box marginBottom={5}>
                            <Frames
                                frames={frames}
                                setFrame={onSelectFrame}
                                selectedFrame={frame}
                                duration={duration}
                                selectedTopics={selectedTopics}
                                topics={topics}
                            />
                        </Box>
                        : null
                    }
                        <Box marginBottom={5}>
                            <Words
                                words={words}
                                setWord={onSelectWord}
                                selectedWord={word}
                                duration={duration}
                                selectedTopics={selectedTopics}
                                topics={topics}
                            />
                        </Box>

                    <Transcript
                        viewTranscript={viewTranscript}
                        onViewTranscript={onViewTranscript}
                        words={transcript}
                        selectedWord={word}
                        setWord={onSelectWord}
                    />
                </div>
            </div>
        </div>
    )
}

export default Results
