import { useMutation, useSubscription } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { TopicSheet } from "../types/types"

export interface GetTopicSheetsData {
    topic_sheet: TopicSheet[]
}

export const useGetTopicSheets = () =>
    useSubscription<GetTopicSheetsData>(gql`
        subscription getTopicSheets {
            topic_sheet(where: { status: { _eq: "success" } }, order_by: { updated_at: desc }) {
                id
                user_id
                filename
                status
            }
        }
    `)

export interface DeleteDefaultTopicSheetData {
    delete_default_topic_sheet: {
        affected_rows: number
    }
}

export const useDeleteDefaultTopicSheet = () =>
    useMutation<DeleteDefaultTopicSheetData>(
        gql`
            mutation deleteDefaultTopicSheet {
                delete_default_topic_sheet(where: { id: { _neq: -1 } }) {
                    affected_rows
                }
            }
        `
    )

export interface InsertDefaultTopicSheetData {
    insert_default_topic_sheet: {
        returning: Array<{
            id: number
            topic_sheet_id: number
        }>
    }
}

export interface InsertDefaultTopicSheetVars {
    topic_sheet_id: number
}

export const useInsertDefaultTopicSheet = () =>
    useMutation<InsertDefaultTopicSheetData, InsertDefaultTopicSheetVars>(
        gql`
            mutation insertDefaultTopicSheet($topic_sheet_id: Int) {
                insert_default_topic_sheet(objects: { topic_sheet_id: $topic_sheet_id }) {
                    returning {
                        id
                        topic_sheet_id
                    }
                }
            }
        `
    )

export interface GetDefaultTopicSheetData {
    default_topic_sheet: Array<{
        topic_sheet: TopicSheet
    }>
}

export const useGetDefaultTopicSheet = () =>
    useSubscription<GetDefaultTopicSheetData>(
        gql`
            subscription getDefaultTopicSheet {
                default_topic_sheet {
                    topic_sheet {
                        id
                        user_id
                        filename
                        status
                    }
                }
            }
        `
    )
