import React, { FC, useState, MouseEvent, useEffect } from "react"
import { Box, makeStyles, Popover, Typography } from "@material-ui/core"
import { Check, ChevronDown, ChevronUp } from "react-feather"
import clsx from "clsx"
import { Topic } from "../../types/types"
import LinesEllipsis from "react-lines-ellipsis"

const useStyles = makeStyles({
    filter: {
        width: 140
    },
    select: {
        height: 26,
        width: 140,
        backgroundColor: "#FFFFFF",
        border: 0,
        padding: 0,
        margin: 0,
        outline: 0,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #2F232D"
    },
    options: {
        width: 140,
        backgroundColor: "#FFFFFF",
        borderRadius: 0,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        border: "1px solid #2F232D",
        borderTop: 0,
        boxShadow: "none"
    },
    option: {
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "14px",
        color: "#2F232D",
        width: 140,
        height: 40,
        border: 0,
        padding: 0,
        margin: 0,
        outline: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ECF4FE"
        },
        "&:active": {
            backgroundColor: "#73B3FF"
        }
    },
    selectedOptionText: {
        width: 120,
        textAlign: "left"
    },
    optionText: {
        width: 100,
        textAlign: "left"
    },
    selectedOption: {
        backgroundColor: "#ECF4FE"
    }
})

interface Props {
    setFilter: (filter: string) => void
    filter: string
    topics?: Topic[]
}

const Filter: FC<Props> = ({ filter, setFilter, topics }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [options, setOptions] = useState<string[]>([])

    useEffect(() => {
        if (topics && options.length === 0) {
            const newOptions: string[] = []
            topics.forEach(topic => {
                if (topic.topicCategory && !newOptions.includes(topic.topicCategory.name)) {
                    newOptions.push(topic.topicCategory.name)
                }
            })
            newOptions.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
            newOptions.unshift("All")
            setOptions(newOptions)
        }
    }, [topics, options])

    const onClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)

    const onClose = () => setAnchorEl(null)

    const onSelectFilter = (filter: string) => (event: MouseEvent) => {
        setFilter(filter)
        onClose()
    }

    const open = Boolean(anchorEl)
    const id = open ? "filter-options" : undefined

    return (
        <div>
            <Box marginBottom={1}>
                <Typography variant="caption">Filter:</Typography>
            </Box>
            <button aria-describedby={id} className={classes.select} onClick={onClick}>
                <Typography variant="body2" component="div" className={classes.selectedOptionText}>
                    <LinesEllipsis text={filter} maxLine={1} ellipsis="..." trimRight={true} basedOn="letters" />
                </Typography>
                {open ? <ChevronUp color="#2F232D" strokeWidth={1} /> : <ChevronDown color="#2F232D" strokeWidth={1} />}
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                classes={{
                    paper: classes.options
                }}
            >
                {options.map(option => (
                    <button
                        onClick={onSelectFilter(option)}
                        className={clsx(classes.option, option === filter && classes.selectedOption)}
                        key={`filter option ${option}`}
                    >
                        <LinesEllipsis
                            className={classes.optionText}
                            text={option}
                            maxLine={1}
                            ellipsis="..."
                            trimRight={true}
                            basedOn="letters"
                        />
                        {option === filter && <Check color="#2F232D" strokeWidth={1} />}
                    </button>
                ))}
            </Popover>
        </div>
    )
}

export default Filter
