import { CircularProgress, makeStyles } from "@material-ui/core"
import React, { FC, MouseEvent, useEffect, useRef, useState } from "react"
import { ChevronDown, ChevronUp } from "react-feather"
import { Word } from "../../types/types"
import TextButton from "./TextButton"
import TranscriptWord from "./TranscriptWord"

const useStyles = makeStyles({
    transcript: {
        backgroundColor: "#FFFFFF",
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        height: 400,
        width: 625,
        overflowY: "auto",
        borderRadius: "8px 8px 0px 0px",
        border: "1px solid #D4D3D3",
        marginTop: 16,
        position: "relative"
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    loadingCircle: {
        color: "#73B3FF"
    },
    buttonContent: {
        width: 144,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
})

interface Props {
    viewTranscript: boolean
    onViewTranscript: () => void
    setWord: (word: Word) => void
    words?: Word[]
    selectedWord?: Word
}

const Transcript: FC<Props> = ({ viewTranscript, onViewTranscript, setWord, words, selectedWord }) => {
    const classes = useStyles()
    const transcriptRef = useRef<HTMLDivElement>(null)
    const [wordRefs, setWordRefs] = useState<{ [key: string]: HTMLButtonElement }>({})

    useEffect(() => {
        if (selectedWord && transcriptRef.current) {
            const word = wordRefs[selectedWord.id]
            transcriptRef.current.scrollTop = word.offsetTop
        }
    })

    const onClick = (word: Word) => (event: MouseEvent) => setWord(word)

    return (
        <>
            {words ? (
                <>
                    <div className={classes.center}>
                        <TextButton onClick={onViewTranscript}>
                            {viewTranscript ? (
                                <div className={classes.buttonContent}>
                                    {"Hide Transcript"}
                                    <ChevronUp color="#2F232D" />
                                </div>
                            ) : (
                                <div className={classes.buttonContent}>
                                    {"View Transcript"}
                                    <ChevronDown color="#2F232D" />
                                </div>
                            )}
                        </TextButton>
                    </div>
                    {viewTranscript && (
                        <div className={classes.transcript} ref={transcriptRef}>
                            {words.map((word, index) => (
                                <TranscriptWord
                                    key={`transcript word ${word.id}`}
                                    word={word}
                                    onClick={onClick}
                                    selectedWord={selectedWord}
                                    showSpace={index < words?.length - 1}
                                    wordRefs={wordRefs}
                                    setWordRefs={setWordRefs}
                                />
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <section className={classes.center}>
                    <CircularProgress className={classes.loadingCircle} />
                </section>
            )}
        </>
    )
}

export default Transcript
