import React, { ChangeEvent, FC, useState } from "react"
import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { useApiService } from "../providers/ApiServiceProvider"
import { UserCreationState } from "../constants"
import { Link } from "react-router-dom"
import Logo from "./Logo"
import Button from "./Button"
import Input from "./Input"
import zxcvbn from "zxcvbn"
import validator from "validator"

const useStyles = makeStyles({
    register: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        marginTop: -88,
        color: "#2F232D",
        maxWidth: 300
    },
    tryAgain: {
        textDecoration: "underline",
        cursor: "pointer",
        color: "#2F232D"
    },
    link: {
        fontSize: 14,
        color: "#2F232D",
        marginTop: 16
    },
    title: {
        marginBottom: 40,
        fontWeight: 600
    },
    card: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        borderRadius: 8,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    button: {
        width: 120
    },
    loadingCircle: {
        color: "#004FFF"
    },
    error: {
        color: "red"
    }
})

const Register: FC = () => {
    const classes = useStyles()
    const [error, setError] = useState<boolean>(false)
    const [userCreationState, setUserCreationState] = useState<UserCreationState | undefined>(undefined)
    const [registering, setRegistering] = useState<boolean>(false)
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [strength, setStrength] = useState<number | undefined>(undefined)
    const [passwordFeedback, setPasswordFeedback] = useState<string[]>([])
    const [passwordError, setPasswordError] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<boolean>(false)
    const ApiService = useApiService()

    const onChangeUsername = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailError(false)
        setUsername(event.target.value)
    }

    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        const result = zxcvbn(event.target.value)
        setStrength(result.score)
        setPasswordError(false)
        setPasswordFeedback([])
        setPassword(event.target.value)
    }

    const tryAgain = () => window.location.reload()

    const register = async () => {
        let isValidEmail = validator.isEmail(username)
        const domain = username.split("@")[1]
        if (!["twentyideas.com", "dbmoai.com", "dbmo.ai"].includes(domain)) {
            isValidEmail = false
        }

        const result = zxcvbn(password)
        if (result.score <= 2) {
            const newFeedback = ["The password is not strong enough"]
            if (result.feedback.warning) {
                newFeedback.push(result.feedback.warning)
            }
            if (result.feedback.suggestions && result.feedback.suggestions.length > 0) {
                result.feedback.suggestions.forEach(suggestion => newFeedback.push(suggestion))
            }

            if (!isValidEmail) {
                setEmailError(true)
            }
            setPasswordError(true)
            setPasswordFeedback(newFeedback)
            return
        }

        if (!isValidEmail) {
            setEmailError(true)
            return
        }

        setRegistering(true)

        const registerResponse = await ApiService.register(username, password)
        if (!registerResponse) {
            setRegistering(false)
            setError(true)
            console.error("failed to register")
            return
        }
        setRegistering(false)
        setPasswordError(false)
        setEmailError(false)
        setPasswordFeedback([])
        setUserCreationState(registerResponse.data.userCreationState)
    }

    return (
        <div className={classes.register}>
            <div className={classes.card}>
                <Box marginBottom={3}>
                    <Logo />
                </Box>
                {userCreationState === undefined && !registering && !error ? (
                    <>
                        <Box marginBottom={2}>
                            <p>Create an account</p>
                        </Box>
                        <Box marginBottom={2}>
                            <Input
                                onChange={onChangeUsername}
                                label="Email"
                                placeholder="Email"
                                style={{ width: 250 }}
                            />
                            {emailError && (
                                <ul>
                                    <li className={classes.error}>
                                        <Typography variant="body2" className={classes.error}>
                                            Not a valid email address
                                        </Typography>
                                    </li>
                                </ul>
                            )}
                        </Box>
                        <Box marginBottom={2}>
                            <Input
                                onChange={onChangePassword}
                                label="Password"
                                placeholder="Password"
                                type="password"
                                style={{ width: 250 }}
                            />
                            <Typography variant="body1">
                                {strength
                                    ? `Stength: ${
                                          [0, 1].includes(strength)
                                              ? "very weak"
                                              : strength === 2
                                              ? "weak"
                                              : strength === 3
                                              ? "strong"
                                              : strength === 4
                                              ? "very strong"
                                              : "weak"
                                      }`
                                    : ""}
                            </Typography>
                            {passwordFeedback.length > 0 && (
                                <ul>
                                    {passwordFeedback.map(feedback => (
                                        <li className={classes.error}>
                                            <Typography variant="body2" className={classes.error}>
                                                {feedback}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </Box>
                        <Box marginBottom={2}>
                            <Button
                                className={classes.button}
                                onClick={register}
                                disabled={registering || !username || !password || passwordError || emailError}
                            >
                                Register
                            </Button>
                        </Box>
                    </>
                ) : registering && !error ? (
                    <CircularProgress className={classes.loadingCircle} />
                ) : !error && userCreationState !== undefined ? (
                    <>
                        <p>Thanks for submitting a registration request.</p>
                        {userCreationState === UserCreationState.APPROVED ? (
                            <>
                                <p>Your request has been approved!</p>
                                <Link className={classes.link} to="/">
                                    Login to the app
                                </Link>
                            </>
                        ) : (
                            <p>Your request has not yet been approved.</p>
                        )}
                    </>
                ) : error ? (
                    <p>
                        {"Registration failed, "}
                        <span className={classes.tryAgain} onClick={tryAgain}>
                            please try again.
                        </span>
                    </p>
                ) : null}
            </div>
        </div>
    )
}

export default Register
