import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

export interface GetPipelinesData {
    pipeline: Pipeline[]
}

export interface Pipeline {
    dag_name: string
}

export const useGetPipelines = () =>
    useQuery<GetPipelinesData>(gql`
        query get_pipelines {
            pipeline {
                dag_name
            }
        }
    `)
