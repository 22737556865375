import axios, { AxiosRequestConfig } from "axios"
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from "react"
import {
    accessTokenExpKey,
    accessTokenKey,
    baseUrl,
    CeleryTask,
    LoginState,
    refreshTokenExpKey,
    refreshTokenIntervalInMillieconds,
    refreshTokenKey,
    userIdKey
} from "../constants"
import { useStorageStore } from "../hooks/useStorageStore"
import {
    AuthenticateResponse,
    BulkImportRequestBody,
    CacheMode,
    StringResponse,
    TriggerPipelineRequestBody,
    UserCreationResponse
} from "../types/types"

interface FormError {
    field: string
    messages: string[]
}

interface ApiResponse {
    message?: string
    error: boolean
    formErrors?: FormError[]
    data?: any
}

interface TriggerPipelineResponse extends ApiResponse {
    data?: {
        message: string
        airflowUrl: string
        mediaUuids: string[]
    }
}

axios.defaults.validateStatus = status => {
    return status >= 200 && status < 400
}

export interface IApiService {
    register: (username: string, password: string) => Promise<UserCreationResponse | undefined>
    authenticate: (username: string, password: string) => Promise<AuthenticateResponse | undefined>
    logout: () => void
    getSignedUrl: (filename: string, contentType: string) => Promise<StringResponse | undefined>
    getReadableSignedUrl: (filename: string, contentType: string) => Promise<StringResponse | undefined>
    triggerPipeline: (
        userId: number,
        mediaUuids: string[],
        pipeline: string,
        topicSheetName?: string,
        cacheMode?: CacheMode
    ) => Promise<ApiResponse>
    bulkImport: (
        userId: number,
        manifestPathAndFilename: string,
        pipeline: string,
        cacheMode?: CacheMode
    ) => Promise<ApiResponse>
    downloadFile: (url: string) => Promise<File | undefined>
}

export const ApiServiceContext = createContext<IApiService>({} as IApiService)

export const useApiService = () => useContext<IApiService>(ApiServiceContext)

const ApiServiceProvider: FC = ({ children }) => {
    const { loginData, setLoginData, removeLoginData } = useStorageStore()
    const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false)
    const [startedRefresh, setStartedRefresh] = useState<boolean>(false)

    const refreshToken = useCallback(
        async (token: string) => {
            const url = `${baseUrl}/refreshToken`
            const body = {
                refreshToken: token
            }
            try {
                const response = await axios.post(url, body)
                if (!response || (response && response.data.error)) {
                    console.error(response.data.message)
                    return
                }
                sessionStorage.setItem(userIdKey, `${response.data.data.userId}`)
                sessionStorage.setItem(accessTokenKey, response.data.data.accessToken ?? "")
                sessionStorage.setItem(accessTokenExpKey, `${response.data.data.accessTokenExp}`)
                sessionStorage.setItem(refreshTokenKey, response.data.data.refreshToken ?? "")
                sessionStorage.setItem(refreshTokenExpKey, `${response.data.data.refreshTokenExp}`)

                setLoginData({
                    loginState: LoginState.SUCCESS,
                    userId: response.data.data.userId,
                    accessToken: response.data.data.accessToken,
                    accessTokenExp: response.data.data.accessTokenExp,
                    refreshToken: response.data.data.refreshToken,
                    refreshTokenExp: response.data.data.refreshTokenExp
                })
            } catch (error) {
                console.error(error)
                return
            }
        },
        [setLoginData]
    )

    useEffect(() => {
        if (loginData?.refreshToken) {
            if (!startedRefresh) {
                setStartedRefresh(true)
                setInterval(() => {
                    setTriggerRefresh(true)
                }, refreshTokenIntervalInMillieconds)
            }
            if (triggerRefresh) {
                setTriggerRefresh(false)
                refreshToken(loginData.refreshToken)
            }
        }
    }, [loginData, triggerRefresh, refreshToken, startedRefresh])

    const register = async (username: string, password: string): Promise<UserCreationResponse | undefined> => {
        const url = `${baseUrl}/register`
        const body = {
            username,
            password
        }
        try {
            const response = await axios.post(url, body)
            if (!response || (response && response.data.error)) {
                console.error(response.data.message)
                return
            }
            return response.data
        } catch (error) {
            console.error(error)
            return
        }
    }

    const authenticate = async (username: string, password: string): Promise<AuthenticateResponse | undefined> => {
        const url = `${baseUrl}/authenticate`
        const body = {
            username,
            password
        }
        try {
            const response = await axios.post(url, body)
            if (!response || (response && response.data.error)) {
                const message = response.data.message
                console.error(message)

                if (message === "User not found") {
                    return {
                        error: true,
                        data: {
                            loginState: LoginState.NOT_REGISTERED
                        }
                    }
                } else if (message === "User creation not approved") {
                    return {
                        error: true,
                        data: {
                            loginState: LoginState.NOT_APPROVED
                        }
                    }
                }
                return
            }
            return {
                error: false,
                data: {
                    loginState: LoginState.SUCCESS,
                    userId: response.data.data.userId,
                    accessToken: response.data.data.accessToken,
                    accessTokenExp: response.data.data.accessTokenExp,
                    refreshToken: response.data.data.refreshToken,
                    refreshTokenExp: response.data.data.refreshTokenExp
                }
            }
        } catch (error) {
            console.error(error)
            return
        }
    }

    const logout = () => {
        console.log(`[logOut] clearing session data`)
        sessionStorage.clear()

        console.log(`[logOut] clearing loginData data`)
        removeLoginData()
    }

    const getSignedUrl = async (filename: string, origin: string): Promise<StringResponse | undefined> => {
        const url = `${baseUrl}/getSignedUrl?filename=${filename}&origin=${origin}`
        const headers = getHeaders()
        if (!headers) {
            return
        }
        try {
            const response = await axios.get<StringResponse>(url, headers)
            if (!response || (response && response.data.error)) {
                console.error(response.data.message)
                return
            }
            return response.data
        } catch (error) {
            console.error(error)
            return
        }
    }

    const getReadableSignedUrl = async (filename: string, contentType: string): Promise<StringResponse | undefined> => {
        const url = `${baseUrl}/getReadableSignedUrl?filename=${filename}&contentType=${contentType}`
        const headers = getHeaders()
        if (!headers) {
            return
        }
        try {
            const response = await axios.get<StringResponse>(url, headers)
            if (!response || (response && response.data.error)) {
                console.error(response.data.message)
                return
            }
            return response.data
        } catch (error) {
            console.error(error)
            return
        }
    }

    const triggerPipeline = async (
        userId: number,
        mediaUuids: string[],
        pipeline: string,
        topicSheetName?: string,
        cacheMode?: CacheMode
    ): Promise<TriggerPipelineResponse> => {
        const url = `${baseUrl}/triggerPipeline`
        const headers = getHeaders()
        if (!headers) {
            return {
                error: true,
                message: "Could not get headers"
            }
        }
        const body: TriggerPipelineRequestBody = {
            userId,
            mediaUuids,
            pipeline,
            topicSheetName,
            cacheMode: cacheMode ? cacheMode : CacheMode.CACHE_ENABLED,
            isDefault: pipeline === CeleryTask.update_topic_sheet ? true : undefined
        }
        try {
            const response = await axios.post(url, body, headers)
            return {
                message: "success",
                error: false,
                data: response.data
            }
        } catch (error) {
            console.error(error)
            window.alert(`There was an error.`)
            return {
                message: error.stack,
                error: true
            }
        }
    }

    const bulkImport = async (
        userId: number,
        manifestPathAndFilename: string,
        pipeline: string,
        cacheMode?: CacheMode
    ): Promise<ApiResponse> => {
        const url = `${baseUrl}/bulkImport`
        const headers = getHeaders()
        if (!headers) {
            return {
                error: true,
                message: "Could not find headers."
            }
        }
        const body: BulkImportRequestBody = {
            userId,
            manifestPathAndFilename,
            pipeline,
            cacheMode: cacheMode ? cacheMode : CacheMode.CACHE_ENABLED
        }
        try {
            const response = await axios.post(url, body, headers)
            return {
                error: false,
                message: "success",
                data: response.data
            }
        } catch (error) {
            console.error(error)
            return error.response.data ? error.response : error
        }
    }

    const downloadFile = async (url: string): Promise<File | undefined> => {
        try {
            const response = await axios.get(url)
            if (!response) {
                return
            }
            return new File([response.data], "video.mp4")
        } catch (error) {
            console.error(error)
            return
        }
    }

    const getHeaders = (): AxiosRequestConfig | undefined => {
        if (!loginData) {
            console.error("Attempted to call API without login data")
            return
        }
        return {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${loginData.accessToken}`
            }
        }
    }

    const apiService = {
        register,
        authenticate,
        logout,
        getSignedUrl,
        getReadableSignedUrl,
        triggerPipeline,
        downloadFile,
        bulkImport
    }

    return <ApiServiceContext.Provider value={apiService}>{children}</ApiServiceContext.Provider>
}

export default ApiServiceProvider
