import React, { FC, MouseEvent } from "react"
import { CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { Frame, Topic } from "../../types/types"
import clsx from "clsx"
import { getTimeFromSeconds } from "../../helpers"

const BarWidth = 625

const useStyles = makeStyles({
    frames: {
        width: BarWidth,
        height: 40,
        backgroundColor: "#ECF4FE",
        marginTop: 8,
        position: "relative"
    },
    topicFrame: {
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(115, 178, 255, 0.4)",
        height: 40,
        border: 0,
        outline: 0,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#73B3FF",
            width: "8px !important"
        },
        zIndex: 1
    },
    noTopicFrame: {
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#ECF4FE",
        height: 40,
        border: 0,
        outline: 0
    },
    selectedFrame: {
        height: 55,
        backgroundColor: "#73B3FF",
        boxShadow: "0px 4px 16px rgba(54, 53, 69, 0.15)",
        border: 0
    },
    loadingCircle: {
        color: "#73B3FF"
    },
    videoTimeSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 8
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    title: {
        marginBottom: 12
    },
    frameTime: {
        color: "#2F232D",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        top: 55
    }
})

interface Props {
    frames?: Frame[]
    selectedTopics: Topic[]
    topics?: Topic[]
    setFrame: (frame: Frame) => void
    selectedFrame?: Frame
    duration: number
}

const Frames: FC<Props> = ({ frames, selectedTopics, topics, setFrame, selectedFrame, duration }) => {
    const classes = useStyles()

    const onClick = (frame: Frame) => (event: MouseEvent) => setFrame(frame)

    const getFrameCount = () => {
        if (selectedTopics.length === 0) {
            return frames?.length ?? 0
        }
        return frames?.filter(frame => hasAtLeastOneSelectedTopic(frame)).length ?? 0
    }

    const hasAtLeastOneSelectedTopic = (frame: Frame): boolean => {
        let hasAtLeastOneTopic = false
        selectedTopics
            .map(topic => topic.id)
            .forEach(topicId => {
                frame.objects.forEach(object => {
                    if (object.topics?.map(topic => topic.id).includes(topicId)) {
                        hasAtLeastOneTopic = true
                    }
                })
            })
        return hasAtLeastOneTopic
    }

    const framePosition = (frame: Frame) => {
        if (duration === 0) {
            return
        }
        const pixelsPerSecond = BarWidth / duration
        const framePositionInSeconds = frame.timestamp / 1000
        return framePositionInSeconds * pixelsPerSecond
    }

    return (
        <>
            {frames ? (
                <>
                    <Typography variant="h6" className={classes.title}>
                        {getFrameCount()} Elements Found
                    </Typography>
                    <section className={classes.videoTimeSection}>
                        <Typography variant="caption">0:00</Typography>
                        <Typography variant="caption">{getTimeFromSeconds(duration)}</Typography>
                    </section>
                    <div className={classes.frames}>
                        {frames.map(frame => (
                            <div key={`frame ${frame.id}`}>
                                <div
                                    className={clsx(
                                        selectedTopics.length === 0 || hasAtLeastOneSelectedTopic(frame)
                                            ? classes.topicFrame
                                            : classes.noTopicFrame,
                                        selectedFrame?.id === frame.id && classes.selectedFrame
                                    )}
                                    style={{
                                        left: framePosition(frame),
                                        width: frames.length <= 300 ? 2 : 1
                                    }}
                                    onClick={onClick(frame)}
                                />
                                {selectedFrame?.id === frame.id && (
                                    <Typography
                                        className={classes.frameTime}
                                        style={{ left: framePosition(frame) }}
                                        variant="caption"
                                    >
                                        {getTimeFromSeconds(frame.timestamp / 1000)}
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <section className={classes.center}>
                    <CircularProgress className={classes.loadingCircle} />
                </section>
            )}
        </>
    )
}

export default Frames
