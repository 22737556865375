import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import React from "react"
import ReactDOM from "react-dom"
import App from "./components/App"
import { sentryProjectTag } from "./constants"

Sentry.init({
    dsn: "https://dab04e162baf47d2a232fd47659ecbc1@o261101.ingest.sentry.io/5600905",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
})
Sentry.setTag("project_id", sentryProjectTag)

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
)
