import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core"
import React, { ChangeEvent, FC, useState } from "react"
import { Link } from "react-router-dom"
import { LoginState } from "../constants"
import { useSessionStore } from "../hooks/useSessionStore"
import { useTokenExpired } from "../store"
import Button from "./Button"
import Input from "./Input"
import Logo from "./Logo"

const useStyles = makeStyles({
    login: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        marginTop: -88,
        color: "#2F232D",
        maxWidth: 300
    },
    tryAgain: {
        textDecoration: "underline",
        cursor: "pointer",
        color: "#2F232D"
    },
    link: {
        fontSize: 14,
        color: "#2F232D",
        marginTop: 16
    },
    register: {
        color: "#2F232D"
    },
    title: {
        marginBottom: 40,
        fontWeight: 600
    },
    card: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        borderRadius: 8,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    button: {
        width: 120
    }
})

const Login: FC = () => {
    const classes = useStyles()
    const { login, isBootstrapped } = useSessionStore()
    const [error, setError] = useState<boolean>(false)
    const [loginState, setLoginState] = useState<LoginState>()

    const [loggingIn, setLoggingIn] = useState<boolean>(false)
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [tokenExpired, setTokenExpired] = useTokenExpired()

    const onChangeUsername = (event: ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)

    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)

    const onLogin = async () => {
        setLoggingIn(true)
        const response = await login(username, password)

        if (response.error) {
            setError(true)
            setTokenExpired(false) // TODO where do i get this value
            setLoginState(response.data.loginState)
            setLoggingIn(false)
            return
        }
        setError(false)
        setTokenExpired(false)
        window.location.reload()
    }

    const tryAgain = () => window.location.reload()
    return (
        <div className={classes.login}>
            {!isBootstrapped ? (
                <></>
            ) : (
                <div className={classes.card}>
                    <Box marginBottom={5}>
                        <Logo />
                    </Box>
                    {loggingIn && !error ? (
                        <CircularProgress />
                    ) : loginState === LoginState.NOT_APPROVED ? (
                        <p>Your user account has not yet been approved</p>
                    ) : loginState === LoginState.NOT_REGISTERED ? (
                        <>
                            <p>A user account has not been created for you.</p>
                            <Link className={classes.register} to="/register">
                                Register an Account
                            </Link>
                        </>
                    ) : loginState === LoginState.UNKNOWN ? (
                        <>
                            <p>
                                {"Login failed, "}
                                <span className={classes.tryAgain} onClick={tryAgain}>
                                    please try again.
                                </span>
                            </p>
                            <Link className={classes.link} to="/register">
                                Register an Account
                            </Link>
                        </>
                    ) : (
                        <>
                            {tokenExpired && (
                                <Box marginBottom={2}>
                                    <Typography variant="body2">
                                        Your authentication token expired, please login again
                                    </Typography>
                                </Box>
                            )}
                            <Box marginBottom={2}>
                                <Input
                                    onChange={onChangeUsername}
                                    label="Email"
                                    placeholder="Email"
                                    type="email"
                                    style={{ width: 250 }}
                                />
                            </Box>
                            <Box marginBottom={2}>
                                <Input
                                    onChange={onChangePassword}
                                    label="Password"
                                    placeholder="Password"
                                    type="password"
                                    style={{ width: 250 }}
                                />
                            </Box>
                            <Box marginBottom={2}>
                                <Button
                                    className={classes.button}
                                    onClick={onLogin}
                                    disabled={loggingIn || !username || !password}
                                >
                                    Login
                                </Button>
                            </Box>
                            <Link className={classes.link} to="/register">
                                Register an Account
                            </Link>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default Login
