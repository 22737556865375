import { Box, makeStyles } from "@material-ui/core"
import React, { FC, useState } from "react"
import { Media } from "../../types/types"
import SearchInput from "../chooseVideo/SearchInput"
import VideoChoices from "../chooseVideo/VideoChoices"
import TriggerPipeline from "./TriggerPipeline"

const useStyles = makeStyles({
    title: {
        marginBottom: 40
    },
    content: {
        display: "flex"
    },
    videos: {
        width: 736,
        marginRight: 36
    },
    recentUploads: {
        marginTop: 0,
        marginBottom: 12,
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "14px",
        color: "#2F232D"
    }
})

const SelectVideo: FC = () => {
    const classes = useStyles()
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [media, setMedia] = useState<Media | undefined>(undefined)

    return (
        <Box marginTop={3}>
            <h1 className={classes.recentUploads}>Recent Uploads</h1>
            <section className={classes.content}>
                <section className={classes.videos}>
                    <SearchInput placeholder="Search" setSearchQuery={setSearchQuery} />
                    <VideoChoices searchQuery={searchQuery} setMedia={setMedia} />
                </section>
                <section>
                    <TriggerPipeline media={media} setMedia={setMedia} />
                </section>
            </section>
        </Box>
    )
}

export default SelectVideo
