import React, { FC, CSSProperties } from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles({
    button: {
        height: 40,
        backgroundColor: "#5E65F6",
        borderRadius: 120,
        outline: 0,
        border: 0,
        padding: 0,
        margin: 0,
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "14px",
        color: "#FFFFFF",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#4E54CC"
        },
        "&:active": {
            backgroundColor: "#313480"
        },
        "&:disabled": {
            backgroundColor: "#D4D3D3",
            cursor: "default"
        }
    }
})

interface Props {
    onClick: (value?: any) => void
    disabled?: boolean
    className?: string
    style?: CSSProperties
}

const Button: FC<Props> = ({ onClick, children, disabled, className, style }) => {
    const classes = useStyles()
    return (
        <button onClick={onClick} className={clsx(classes.button, className)} style={style} disabled={disabled}>
            {children}
        </button>
    )
}

export default Button
