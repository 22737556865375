export const videoIntelligenceData = [
    {
    word: "This",
    start_time: 1500,
    end_time: 1800,
    token_index: 0,
    start_char: 0,
    end_char: 4,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "election",
    start_time: 1800,
    end_time: 2300,
    token_index: 1,
    start_char: 5,
    end_char: 13,
    display_word: "election",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 2300,
    end_time: 2300,
    token_index: 2,
    start_char: 14,
    end_char: 16,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 2300,
    end_time: 2500,
    token_index: 3,
    start_char: 17,
    end_char: 22,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2500,
    end_time: 2600,
    token_index: 4,
    start_char: 23,
    end_char: 25,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 2600,
    end_time: 2700,
    token_index: 5,
    start_char: 26,
    end_char: 28,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2700,
    end_time: 2800,
    token_index: 6,
    start_char: 29,
    end_char: 30,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "fraud",
    start_time: 2800,
    end_time: 3300,
    token_index: 7,
    start_char: 31,
    end_char: 36,
    display_word: "fraud",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 3300,
    end_time: 3400,
    token_index: 8,
    start_char: 37,
    end_char: 39,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 3400,
    end_time: 3600,
    token_index: 9,
    start_char: 40,
    end_char: 44,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 3600,
    end_time: 3800,
    token_index: 10,
    start_char: 45,
    end_char: 47,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 3800,
    end_time: 4200,
    token_index: 11,
    start_char: 48,
    end_char: 52,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "these",
    start_time: 4400,
    end_time: 4700,
    token_index: 12,
    start_char: 53,
    end_char: 58,
    display_word: "these",
    period_at_end: false
    },
    {
    word: "mail",
    start_time: 4700,
    end_time: 4900,
    token_index: 13,
    start_char: 59,
    end_char: 63,
    display_word: "mail",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 4900,
    end_time: 5000,
    token_index: 14,
    start_char: 64,
    end_char: 67,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 5000,
    end_time: 5300,
    token_index: 15,
    start_char: 68,
    end_char: 73,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 5300,
    end_time: 5400,
    token_index: 16,
    start_char: 74,
    end_char: 76,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 5400,
    end_time: 5500,
    token_index: 17,
    start_char: 77,
    end_char: 79,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 5500,
    end_time: 5700,
    token_index: 18,
    start_char: 80,
    end_char: 84,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "Democrat",
    start_time: 5700,
    end_time: 6300,
    token_index: 19,
    start_char: 85,
    end_char: 93,
    display_word: "Democrat",
    period_at_end: false
    },
    {
    word: "Governor",
    start_time: 6300,
    end_time: 6800,
    token_index: 20,
    start_char: 94,
    end_char: 102,
    display_word: "Governor",
    period_at_end: false
    },
    {
    word: "sends",
    start_time: 7200,
    end_time: 7600,
    token_index: 21,
    start_char: 103,
    end_char: 108,
    display_word: "sends",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 7600,
    end_time: 7900,
    token_index: 22,
    start_char: 109,
    end_char: 111,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "millions",
    start_time: 8200,
    end_time: 8700,
    token_index: 23,
    start_char: 112,
    end_char: 120,
    display_word: "millions",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 8700,
    end_time: 8800,
    token_index: 24,
    start_char: 121,
    end_char: 123,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "ballots",
    start_time: 8800,
    end_time: 9200,
    token_index: 25,
    start_char: 124,
    end_char: 131,
    display_word: "ballots",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 9200,
    end_time: 9500,
    token_index: 26,
    start_char: 132,
    end_char: 135,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "over",
    start_time: 9500,
    end_time: 9600,
    token_index: 27,
    start_char: 136,
    end_char: 140,
    display_word: "over",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 9600,
    end_time: 9800,
    token_index: 28,
    start_char: 141,
    end_char: 144,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "state.",
    start_time: 9800,
    end_time: 10200,
    token_index: 29,
    start_char: 145,
    end_char: 150,
    display_word: "state",
    period_at_end: true
    },
    {
    word: "Who",
    start_time: 10200,
    end_time: 10600,
    token_index: 30,
    start_char: 152,
    end_char: 155,
    display_word: "Who",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 10600,
    end_time: 10700,
    token_index: 31,
    start_char: 156,
    end_char: 159,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 10700,
    end_time: 10800,
    token_index: 32,
    start_char: 160,
    end_char: 164,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "sending",
    start_time: 10800,
    end_time: 11200,
    token_index: 33,
    start_char: 165,
    end_char: 172,
    display_word: "sending",
    period_at_end: false
    },
    {
    word: "him",
    start_time: 11200,
    end_time: 11300,
    token_index: 34,
    start_char: 173,
    end_char: 176,
    display_word: "him",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 11300,
    end_time: 11500,
    token_index: 35,
    start_char: 177,
    end_char: 179,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 11500,
    end_time: 11600,
    token_index: 36,
    start_char: 180,
    end_char: 183,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "nobody",
    start_time: 11600,
    end_time: 12000,
    token_index: 37,
    start_char: 184,
    end_char: 190,
    display_word: "nobody",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 12000,
    end_time: 12200,
    token_index: 38,
    start_char: 191,
    end_char: 194,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "any",
    start_time: 12200,
    end_time: 12500,
    token_index: 39,
    start_char: 195,
    end_char: 198,
    display_word: "any",
    period_at_end: false
    },
    {
    word: "idea",
    start_time: 12500,
    end_time: 13000,
    token_index: 40,
    start_char: 199,
    end_char: 203,
    display_word: "idea",
    period_at_end: false
    },
    {
    word: "they're",
    start_time: 13300,
    end_time: 13500,
    token_index: 41,
    start_char: 204,
    end_char: 211,
    display_word: "they're",
    period_at_end: false
    },
    {
    word: "sending",
    start_time: 13500,
    end_time: 13800,
    token_index: 42,
    start_char: 212,
    end_char: 219,
    display_word: "sending",
    period_at_end: false
    },
    {
    word: "them",
    start_time: 13800,
    end_time: 14000,
    token_index: 43,
    start_char: 220,
    end_char: 224,
    display_word: "them",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 14000,
    end_time: 14100,
    token_index: 44,
    start_char: 225,
    end_char: 227,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "dogs",
    start_time: 14100,
    end_time: 14900,
    token_index: 45,
    start_char: 228,
    end_char: 232,
    display_word: "dogs",
    period_at_end: false
    },
    {
    word: "actually",
    start_time: 15300,
    end_time: 15700,
    token_index: 46,
    start_char: 233,
    end_char: 241,
    display_word: "actually",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 15700,
    end_time: 16000,
    token_index: 47,
    start_char: 242,
    end_char: 246,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "sent",
    start_time: 16000,
    end_time: 16300,
    token_index: 48,
    start_char: 247,
    end_char: 251,
    display_word: "sent",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 16300,
    end_time: 16400,
    token_index: 49,
    start_char: 252,
    end_char: 255,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "dogs.",
    start_time: 16400,
    end_time: 17000,
    token_index: 50,
    start_char: 256,
    end_char: 260,
    display_word: "dogs",
    period_at_end: true
    },
    {
    word: "Hi,",
    start_time: 27700,
    end_time: 28100,
    token_index: 51,
    start_char: 262,
    end_char: 265,
    display_word: "Hi,",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 28200,
    end_time: 28400,
    token_index: 52,
    start_char: 266,
    end_char: 269,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "Sarah",
    start_time: 28400,
    end_time: 28600,
    token_index: 53,
    start_char: 270,
    end_char: 275,
    display_word: "Sarah",
    period_at_end: false
    },
    {
    word: "McLachlan.",
    start_time: 28600,
    end_time: 29300,
    token_index: 54,
    start_char: 276,
    end_char: 285,
    display_word: "McLachlan",
    period_at_end: true
    },
    {
    word: "Will",
    start_time: 29600,
    end_time: 29800,
    token_index: 55,
    start_char: 287,
    end_char: 291,
    display_word: "Will",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 29800,
    end_time: 30000,
    token_index: 56,
    start_char: 292,
    end_char: 295,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 30000,
    end_time: 30100,
    token_index: 57,
    start_char: 296,
    end_char: 298,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 30100,
    end_time: 30300,
    token_index: 58,
    start_char: 299,
    end_char: 301,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "angel",
    start_time: 30300,
    end_time: 30700,
    token_index: 59,
    start_char: 302,
    end_char: 307,
    display_word: "angel",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 30700,
    end_time: 30900,
    token_index: 60,
    start_char: 308,
    end_char: 311,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 30900,
    end_time: 30900,
    token_index: 61,
    start_char: 312,
    end_char: 313,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "helpless",
    start_time: 30900,
    end_time: 31400,
    token_index: 62,
    start_char: 314,
    end_char: 322,
    display_word: "helpless",
    period_at_end: false
    },
    {
    word: "animal",
    start_time: 31400,
    end_time: 32000,
    token_index: 63,
    start_char: 323,
    end_char: 329,
    display_word: "animal",
    period_at_end: false
    },
    {
    word: "every",
    start_time: 32600,
    end_time: 33000,
    token_index: 64,
    start_char: 330,
    end_char: 335,
    display_word: "every",
    period_at_end: false
    },
    {
    word: "day",
    start_time: 33000,
    end_time: 33500,
    token_index: 65,
    start_char: 336,
    end_char: 339,
    display_word: "day",
    period_at_end: false
    },
    {
    word: "dogs",
    start_time: 33500,
    end_time: 34000,
    token_index: 66,
    start_char: 340,
    end_char: 344,
    display_word: "dogs",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 34000,
    end_time: 34100,
    token_index: 67,
    start_char: 345,
    end_char: 348,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "being",
    start_time: 34100,
    end_time: 34400,
    token_index: 68,
    start_char: 349,
    end_char: 354,
    display_word: "being",
    period_at_end: false
    },
    {
    word: "tempted",
    start_time: 34400,
    end_time: 34900,
    token_index: 69,
    start_char: 355,
    end_char: 362,
    display_word: "tempted",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 34900,
    end_time: 35000,
    token_index: 70,
    start_char: 363,
    end_char: 365,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "vote.",
    start_time: 35000,
    end_time: 35300,
    token_index: 71,
    start_char: 366,
    end_char: 370,
    display_word: "vote",
    period_at_end: true
    },
    {
    word: "Illegally",
    start_time: 35300,
    end_time: 35900,
    token_index: 72,
    start_char: 372,
    end_char: 381,
    display_word: "Illegally",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 35900,
    end_time: 36300,
    token_index: 73,
    start_char: 382,
    end_char: 389,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "ballots",
    start_time: 36300,
    end_time: 36800,
    token_index: 74,
    start_char: 390,
    end_char: 397,
    display_word: "ballots",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 36800,
    end_time: 37000,
    token_index: 75,
    start_char: 398,
    end_char: 401,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "shoved",
    start_time: 37000,
    end_time: 37400,
    token_index: 76,
    start_char: 402,
    end_char: 408,
    display_word: "shoved",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 37400,
    end_time: 37700,
    token_index: 77,
    start_char: 409,
    end_char: 413,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 37700,
    end_time: 37800,
    token_index: 78,
    start_char: 414,
    end_char: 419,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "mail",
    start_time: 37800,
    end_time: 38100,
    token_index: 79,
    start_char: 420,
    end_char: 424,
    display_word: "mail",
    period_at_end: false
    },
    {
    word: "slots",
    start_time: 38100,
    end_time: 38700,
    token_index: 80,
    start_char: 425,
    end_char: 430,
    display_word: "slots",
    period_at_end: false
    },
    {
    word: "dogs",
    start_time: 39300,
    end_time: 39800,
    token_index: 81,
    start_char: 431,
    end_char: 435,
    display_word: "dogs",
    period_at_end: false
    },
    {
    word: "write-in",
    start_time: 39800,
    end_time: 40400,
    token_index: 82,
    start_char: 436,
    end_char: 444,
    display_word: "write-in",
    period_at_end: false
    },
    {
    word: "candidates",
    start_time: 40400,
    end_time: 41300,
    token_index: 83,
    start_char: 445,
    end_char: 455,
    display_word: "candidates",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 41700,
    end_time: 42100,
    token_index: 84,
    start_char: 456,
    end_char: 460,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "Senator",
    start_time: 42100,
    end_time: 42700,
    token_index: 85,
    start_char: 461,
    end_char: 468,
    display_word: "Senator",
    period_at_end: false
    },
    {
    word: "sniff,",
    start_time: 42700,
    end_time: 43300,
    token_index: 86,
    start_char: 469,
    end_char: 475,
    display_word: "sniff,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 43300,
    end_time: 43800,
    token_index: 87,
    start_char: 476,
    end_char: 479,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 44000,
    end_time: 44300,
    token_index: 88,
    start_char: 480,
    end_char: 483,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Governor",
    start_time: 44300,
    end_time: 44900,
    token_index: 89,
    start_char: 484,
    end_char: 492,
    display_word: "Governor",
    period_at_end: false
    },
    {
    word: "cat",
    start_time: 44900,
    end_time: 45400,
    token_index: 90,
    start_char: 493,
    end_char: 496,
    display_word: "cat",
    period_at_end: false
    },
    {
    word: "murderer",
    start_time: 45400,
    end_time: 46000,
    token_index: 91,
    start_char: 497,
    end_char: 505,
    display_word: "murderer",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 46600,
    end_time: 46800,
    token_index: 92,
    start_char: 506,
    end_char: 511,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 46800,
    end_time: 47100,
    token_index: 93,
    start_char: 512,
    end_char: 515,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "There's",
    start_time: 47100,
    end_time: 47300,
    token_index: 94,
    start_char: 517,
    end_char: 524,
    display_word: "There's",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 47300,
    end_time: 47500,
    token_index: 95,
    start_char: 525,
    end_char: 527,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "animal",
    start_time: 47500,
    end_time: 47800,
    token_index: 96,
    start_char: 528,
    end_char: 534,
    display_word: "animal",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 47800,
    end_time: 48000,
    token_index: 97,
    start_char: 535,
    end_char: 539,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "needs",
    start_time: 48000,
    end_time: 48400,
    token_index: 98,
    start_char: 540,
    end_char: 545,
    display_word: "needs",
    period_at_end: false
    },
    {
    word: "you.",
    start_time: 48400,
    end_time: 48800,
    token_index: 99,
    start_char: 546,
    end_char: 549,
    display_word: "you",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 49500,
    end_time: 49800,
    token_index: 100,
    start_char: 551,
    end_char: 553,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "call",
    start_time: 49800,
    end_time: 50100,
    token_index: 101,
    start_char: 554,
    end_char: 558,
    display_word: "call",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 50100,
    end_time: 50200,
    token_index: 102,
    start_char: 559,
    end_char: 562,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "number",
    start_time: 50200,
    end_time: 50500,
    token_index: 103,
    start_char: 563,
    end_char: 569,
    display_word: "number",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 50500,
    end_time: 50700,
    token_index: 104,
    start_char: 570,
    end_char: 572,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 50700,
    end_time: 50900,
    token_index: 105,
    start_char: 573,
    end_char: 577,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "screen",
    start_time: 50900,
    end_time: 51500,
    token_index: 106,
    start_char: 578,
    end_char: 584,
    display_word: "screen",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 51700,
    end_time: 52000,
    token_index: 107,
    start_char: 585,
    end_char: 588,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "help",
    start_time: 52000,
    end_time: 52300,
    token_index: 108,
    start_char: 589,
    end_char: 593,
    display_word: "help",
    period_at_end: false
    },
    {
    word: "these",
    start_time: 52300,
    end_time: 52600,
    token_index: 109,
    start_char: 594,
    end_char: 599,
    display_word: "these",
    period_at_end: false
    },
    {
    word: "troubled",
    start_time: 52600,
    end_time: 53000,
    token_index: 110,
    start_char: 600,
    end_char: 608,
    display_word: "troubled",
    period_at_end: false
    },
    {
    word: "dogs",
    start_time: 53000,
    end_time: 53700,
    token_index: 111,
    start_char: 609,
    end_char: 613,
    display_word: "dogs",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 54100,
    end_time: 54600,
    token_index: 112,
    start_char: 614,
    end_char: 620,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "this.",
    start_time: 54600,
    end_time: 54900,
    token_index: 113,
    start_char: 621,
    end_char: 625,
    display_word: "this",
    period_at_end: true
    },
    {
    word: "I'm",
    start_time: 58300,
    end_time: 58500,
    token_index: 114,
    start_char: 627,
    end_char: 630,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "Donald",
    start_time: 58500,
    end_time: 58900,
    token_index: 115,
    start_char: 631,
    end_char: 637,
    display_word: "Donald",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 58900,
    end_time: 59200,
    token_index: 116,
    start_char: 638,
    end_char: 643,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 59200,
    end_time: 59600,
    token_index: 117,
    start_char: 644,
    end_char: 647,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 59600,
    end_time: 59700,
    token_index: 118,
    start_char: 648,
    end_char: 649,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "am",
    start_time: 59700,
    end_time: 59900,
    token_index: 119,
    start_char: 650,
    end_char: 652,
    display_word: "am",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 59900,
    end_time: 60300,
    token_index: 120,
    start_char: 653,
    end_char: 658,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 60300,
    end_time: 60400,
    token_index: 121,
    start_char: 659,
    end_char: 661,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "hump",
    start_time: 60400,
    end_time: 61000,
    token_index: 122,
    start_char: 662,
    end_char: 666,
    display_word: "hump",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 61000,
    end_time: 61500,
    token_index: 123,
    start_char: 667,
    end_char: 671,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "leg",
    start_time: 61500,
    end_time: 62200,
    token_index: 124,
    start_char: 672,
    end_char: 675,
    display_word: "leg",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 65200,
    end_time: 65400,
    token_index: 125,
    start_char: 676,
    end_char: 680,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "Stephen",
    start_time: 65400,
    end_time: 66000,
    token_index: 126,
    start_char: 681,
    end_char: 688,
    display_word: "Stephen",
    period_at_end: false
    },
    {
    word: "Colbert",
    start_time: 66000,
    end_time: 67200,
    token_index: 127,
    start_char: 689,
    end_char: 696,
    display_word: "Colbert",
    period_at_end: false
    },
    {
    word: "tonight.",
    start_time: 67400,
    end_time: 68200,
    token_index: 128,
    start_char: 697,
    end_char: 704,
    display_word: "tonight",
    period_at_end: true
    },
    {
    word: "I'm",
    start_time: 68300,
    end_time: 68300,
    token_index: 129,
    start_char: 706,
    end_char: 709,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "asking",
    start_time: 68300,
    end_time: 69000,
    token_index: 130,
    start_char: 710,
    end_char: 716,
    display_word: "asking",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 69000,
    end_time: 69200,
    token_index: 131,
    start_char: 717,
    end_char: 720,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 69200,
    end_time: 69400,
    token_index: 132,
    start_char: 721,
    end_char: 722,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "friend",
    start_time: 69400,
    end_time: 70100,
    token_index: 133,
    start_char: 723,
    end_char: 729,
    display_word: "friend",
    period_at_end: false
    },
    {
    word: "Stephen",
    start_time: 70900,
    end_time: 71400,
    token_index: 134,
    start_char: 730,
    end_char: 737,
    display_word: "Stephen",
    period_at_end: false
    },
    {
    word: "welcomes",
    start_time: 71400,
    end_time: 72200,
    token_index: 135,
    start_char: 738,
    end_char: 746,
    display_word: "welcomes",
    period_at_end: false
    },
    {
    word: "W",
    start_time: 72500,
    end_time: 73100,
    token_index: 136,
    start_char: 747,
    end_char: 748,
    display_word: "W",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 75100,
    end_time: 75400,
    token_index: 137,
    start_char: 749,
    end_char: 752,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 75400,
    end_time: 75600,
    token_index: 138,
    start_char: 753,
    end_char: 756,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chick",
    start_time: 75600,
    end_time: 76300,
    token_index: 139,
    start_char: 757,
    end_char: 762,
    display_word: "chick",
    period_at_end: false
    },
    {
    word: "featuring",
    start_time: 76800,
    end_time: 77500,
    token_index: 140,
    start_char: 763,
    end_char: 772,
    display_word: "featuring",
    period_at_end: false
    },
    {
    word: "Jon",
    start_time: 77500,
    end_time: 77900,
    token_index: 141,
    start_char: 773,
    end_char: 776,
    display_word: "Jon",
    period_at_end: false
    },
    {
    word: "Batiste",
    start_time: 77900,
    end_time: 78700,
    token_index: 142,
    start_char: 777,
    end_char: 784,
    display_word: "Batiste",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 78700,
    end_time: 79100,
    token_index: 143,
    start_char: 785,
    end_char: 788,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "stay",
    start_time: 79100,
    end_time: 79700,
    token_index: 144,
    start_char: 789,
    end_char: 793,
    display_word: "stay",
    period_at_end: false
    },
    {
    word: "human",
    start_time: 79700,
    end_time: 80300,
    token_index: 145,
    start_char: 794,
    end_char: 799,
    display_word: "human",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 80800,
    end_time: 81000,
    token_index: 146,
    start_char: 800,
    end_char: 803,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 81000,
    end_time: 81800,
    token_index: 147,
    start_char: 804,
    end_char: 807,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "live",
    start_time: 81900,
    end_time: 83200,
    token_index: 148,
    start_char: 808,
    end_char: 812,
    display_word: "live",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 83200,
    end_time: 83400,
    token_index: 149,
    start_char: 813,
    end_char: 815,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "tape",
    start_time: 83400,
    end_time: 83700,
    token_index: 150,
    start_char: 816,
    end_char: 820,
    display_word: "tape",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 83700,
    end_time: 83900,
    token_index: 151,
    start_char: 821,
    end_char: 825,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 83900,
    end_time: 84000,
    token_index: 152,
    start_char: 826,
    end_char: 827,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "safe",
    start_time: 84000,
    end_time: 84400,
    token_index: 153,
    start_char: 828,
    end_char: 832,
    display_word: "safe",
    period_at_end: false
    },
    {
    word: "distance.",
    start_time: 84400,
    end_time: 84900,
    token_index: 154,
    start_char: 833,
    end_char: 841,
    display_word: "distance",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 85200,
    end_time: 85500,
    token_index: 155,
    start_char: 843,
    end_char: 847,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "Stephen",
    start_time: 85500,
    end_time: 86100,
    token_index: 156,
    start_char: 848,
    end_char: 855,
    display_word: "Stephen",
    period_at_end: false
    },
    {
    word: "Colbert.",
    start_time: 86100,
    end_time: 86900,
    token_index: 157,
    start_char: 856,
    end_char: 863,
    display_word: "Colbert",
    period_at_end: true
    },
    {
    word: "Oh,",
    start_time: 93100,
    end_time: 93200,
    token_index: 158,
    start_char: 865,
    end_char: 868,
    display_word: "Oh,",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 93200,
    end_time: 93400,
    token_index: 159,
    start_char: 869,
    end_char: 871,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 93400,
    end_time: 93600,
    token_index: 160,
    start_char: 872,
    end_char: 875,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 93600,
    end_time: 93700,
    token_index: 161,
    start_char: 876,
    end_char: 878,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 93700,
    end_time: 93800,
    token_index: 162,
    start_char: 879,
    end_char: 881,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 93800,
    end_time: 94300,
    token_index: 163,
    start_char: 882,
    end_char: 886,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 94600,
    end_time: 94800,
    token_index: 164,
    start_char: 887,
    end_char: 890,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "dog",
    start_time: 94800,
    end_time: 95100,
    token_index: 165,
    start_char: 891,
    end_char: 894,
    display_word: "dog",
    period_at_end: false
    },
    {
    word: "ate",
    start_time: 95100,
    end_time: 95200,
    token_index: 166,
    start_char: 895,
    end_char: 898,
    display_word: "ate",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 95200,
    end_time: 95300,
    token_index: 167,
    start_char: 899,
    end_char: 903,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "shoes.",
    start_time: 95300,
    end_time: 95900,
    token_index: 168,
    start_char: 904,
    end_char: 909,
    display_word: "shoes",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 97700,
    end_time: 97800,
    token_index: 169,
    start_char: 911,
    end_char: 912,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "thought",
    start_time: 97800,
    end_time: 97900,
    token_index: 170,
    start_char: 913,
    end_char: 920,
    display_word: "thought",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 97900,
    end_time: 98000,
    token_index: 171,
    start_char: 921,
    end_char: 923,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 98000,
    end_time: 98100,
    token_index: 172,
    start_char: 924,
    end_char: 927,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 98100,
    end_time: 98100,
    token_index: 173,
    start_char: 928,
    end_char: 929,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "different",
    start_time: 98100,
    end_time: 98500,
    token_index: 174,
    start_char: 930,
    end_char: 939,
    display_word: "different",
    period_at_end: false
    },
    {
    word: "pair",
    start_time: 98500,
    end_time: 98700,
    token_index: 175,
    start_char: 940,
    end_char: 944,
    display_word: "pair",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 98700,
    end_time: 98800,
    token_index: 176,
    start_char: 945,
    end_char: 949,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 98800,
    end_time: 98900,
    token_index: 177,
    start_char: 950,
    end_char: 952,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "ate.",
    start_time: 98900,
    end_time: 99300,
    token_index: 178,
    start_char: 953,
    end_char: 956,
    display_word: "ate",
    period_at_end: true
    },
    {
    word: "Well",
    start_time: 99800,
    end_time: 99900,
    token_index: 179,
    start_char: 958,
    end_char: 962,
    display_word: "Well",
    period_at_end: false
    },
    {
    word: "those",
    start_time: 99900,
    end_time: 100200,
    token_index: 180,
    start_char: 963,
    end_char: 968,
    display_word: "those",
    period_at_end: false
    },
    {
    word: "look",
    start_time: 100200,
    end_time: 100500,
    token_index: 181,
    start_char: 969,
    end_char: 973,
    display_word: "look",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 100500,
    end_time: 100600,
    token_index: 182,
    start_char: 974,
    end_char: 975,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "got",
    start_time: 100600,
    end_time: 100800,
    token_index: 183,
    start_char: 976,
    end_char: 979,
    display_word: "got",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 100800,
    end_time: 100900,
    token_index: 184,
    start_char: 980,
    end_char: 983,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "those.",
    start_time: 100900,
    end_time: 101300,
    token_index: 185,
    start_char: 984,
    end_char: 989,
    display_word: "those",
    period_at_end: true
    },
    {
    word: "Welcome",
    start_time: 102800,
    end_time: 103200,
    token_index: 186,
    start_char: 991,
    end_char: 998,
    display_word: "Welcome",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 103200,
    end_time: 103300,
    token_index: 187,
    start_char: 999,
    end_char: 1001,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 103300,
    end_time: 103400,
    token_index: 188,
    start_char: 1002,
    end_char: 1003,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "late",
    start_time: 103400,
    end_time: 103700,
    token_index: 189,
    start_char: 1004,
    end_char: 1008,
    display_word: "late",
    period_at_end: false
    },
    {
    word: "show.",
    start_time: 103700,
    end_time: 104100,
    token_index: 190,
    start_char: 1009,
    end_char: 1013,
    display_word: "show",
    period_at_end: true
    },
    {
    word: "I'm",
    start_time: 104500,
    end_time: 104800,
    token_index: 191,
    start_char: 1015,
    end_char: 1018,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 104800,
    end_time: 104900,
    token_index: 192,
    start_char: 1019,
    end_char: 1023,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "host",
    start_time: 104900,
    end_time: 105100,
    token_index: 193,
    start_char: 1024,
    end_char: 1028,
    display_word: "host",
    period_at_end: false
    },
    {
    word: "Stephen",
    start_time: 105100,
    end_time: 105400,
    token_index: 194,
    start_char: 1029,
    end_char: 1036,
    display_word: "Stephen",
    period_at_end: false
    },
    {
    word: "Colbert.",
    start_time: 105400,
    end_time: 106000,
    token_index: 195,
    start_char: 1037,
    end_char: 1044,
    display_word: "Colbert",
    period_at_end: true
    },
    {
    word: "If",
    start_time: 106000,
    end_time: 106200,
    token_index: 196,
    start_char: 1046,
    end_char: 1048,
    display_word: "If",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 106200,
    end_time: 106300,
    token_index: 197,
    start_char: 1049,
    end_char: 1052,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "haven't",
    start_time: 106300,
    end_time: 106600,
    token_index: 198,
    start_char: 1053,
    end_char: 1060,
    display_word: "haven't",
    period_at_end: false
    },
    {
    word: "checked",
    start_time: 106600,
    end_time: 106900,
    token_index: 199,
    start_char: 1061,
    end_char: 1068,
    display_word: "checked",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 106900,
    end_time: 107000,
    token_index: 200,
    start_char: 1069,
    end_char: 1072,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "sports",
    start_time: 107000,
    end_time: 107400,
    token_index: 201,
    start_char: 1073,
    end_char: 1079,
    display_word: "sports",
    period_at_end: false
    },
    {
    word: "pages",
    start_time: 107400,
    end_time: 107800,
    token_index: 202,
    start_char: 1080,
    end_char: 1085,
    display_word: "pages",
    period_at_end: false
    },
    {
    word: "today",
    start_time: 107800,
    end_time: 108400,
    token_index: 203,
    start_char: 1086,
    end_char: 1091,
    display_word: "today",
    period_at_end: false
    },
    {
    word: "coronavirus",
    start_time: 108400,
    end_time: 109100,
    token_index: 204,
    start_char: 1092,
    end_char: 1103,
    display_word: "coronavirus",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 109100,
    end_time: 109600,
    token_index: 205,
    start_char: 1104,
    end_char: 1106,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "still",
    start_time: 109600,
    end_time: 109900,
    token_index: 206,
    start_char: 1107,
    end_char: 1112,
    display_word: "still",
    period_at_end: false
    },
    {
    word: "winning",
    start_time: 109900,
    end_time: 110200,
    token_index: 207,
    start_char: 1113,
    end_char: 1120,
    display_word: "winning",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 110200,
    end_time: 111100,
    token_index: 208,
    start_char: 1121,
    end_char: 1124,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 111100,
    end_time: 111200,
    token_index: 209,
    start_char: 1125,
    end_char: 1131,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "States",
    start_time: 111200,
    end_time: 111400,
    token_index: 210,
    start_char: 1132,
    end_char: 1138,
    display_word: "States",
    period_at_end: false
    },
    {
    word: "reported",
    start_time: 111400,
    end_time: 112000,
    token_index: 211,
    start_char: 1139,
    end_char: 1147,
    display_word: "reported",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 112000,
    end_time: 112100,
    token_index: 212,
    start_char: 1148,
    end_char: 1150,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "second",
    start_time: 112100,
    end_time: 112400,
    token_index: 213,
    start_char: 1151,
    end_char: 1157,
    display_word: "second",
    period_at_end: false
    },
    {
    word: "highest",
    start_time: 112400,
    end_time: 112800,
    token_index: 214,
    start_char: 1158,
    end_char: 1165,
    display_word: "highest",
    period_at_end: false
    },
    {
    word: "single",
    start_time: 112800,
    end_time: 113000,
    token_index: 215,
    start_char: 1166,
    end_char: 1172,
    display_word: "single",
    period_at_end: false
    },
    {
    word: "day",
    start_time: 113000,
    end_time: 113100,
    token_index: 216,
    start_char: 1173,
    end_char: 1176,
    display_word: "day",
    period_at_end: false
    },
    {
    word: "total",
    start_time: 113100,
    end_time: 113500,
    token_index: 217,
    start_char: 1177,
    end_char: 1182,
    display_word: "total",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 113500,
    end_time: 113500,
    token_index: 218,
    start_char: 1183,
    end_char: 1185,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 113500,
    end_time: 113700,
    token_index: 219,
    start_char: 1186,
    end_char: 1189,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "cases",
    start_time: 113700,
    end_time: 113900,
    token_index: 220,
    start_char: 1190,
    end_char: 1195,
    display_word: "cases",
    period_at_end: false
    },
    {
    word: "yesterday",
    start_time: 113900,
    end_time: 114300,
    token_index: 221,
    start_char: 1196,
    end_char: 1205,
    display_word: "yesterday",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 114300,
    end_time: 115000,
    token_index: 222,
    start_char: 1206,
    end_char: 1210,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "behind",
    start_time: 115000,
    end_time: 115500,
    token_index: 223,
    start_char: 1211,
    end_char: 1217,
    display_word: "behind",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 115500,
    end_time: 115700,
    token_index: 224,
    start_char: 1218,
    end_char: 1221,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "record",
    start_time: 115700,
    end_time: 116000,
    token_index: 225,
    start_char: 1222,
    end_char: 1228,
    display_word: "record",
    period_at_end: false
    },
    {
    word: "set",
    start_time: 116000,
    end_time: 116300,
    token_index: 226,
    start_char: 1229,
    end_char: 1232,
    display_word: "set",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 116300,
    end_time: 116400,
    token_index: 227,
    start_char: 1233,
    end_char: 1236,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "day",
    start_time: 116400,
    end_time: 116600,
    token_index: 228,
    start_char: 1237,
    end_char: 1240,
    display_word: "day",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 116600,
    end_time: 116800,
    token_index: 229,
    start_char: 1241,
    end_char: 1247,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 116800,
    end_time: 117900,
    token_index: 230,
    start_char: 1248,
    end_char: 1249,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 117900,
    end_time: 118100,
    token_index: 231,
    start_char: 1250,
    end_char: 1254,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 118100,
    end_time: 118300,
    token_index: 232,
    start_char: 1255,
    end_char: 1259,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 118300,
    end_time: 118800,
    token_index: 233,
    start_char: 1260,
    end_char: 1267,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 118800,
    end_time: 119000,
    token_index: 234,
    start_char: 1268,
    end_char: 1269,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "looked",
    start_time: 119000,
    end_time: 119200,
    token_index: 235,
    start_char: 1270,
    end_char: 1276,
    display_word: "looked",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 119200,
    end_time: 119300,
    token_index: 236,
    start_char: 1277,
    end_char: 1279,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 119300,
    end_time: 119400,
    token_index: 237,
    start_char: 1280,
    end_char: 1282,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 119400,
    end_time: 119500,
    token_index: 238,
    start_char: 1283,
    end_char: 1285,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 119500,
    end_time: 119600,
    token_index: 239,
    start_char: 1286,
    end_char: 1289,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 119600,
    end_time: 120000,
    token_index: 240,
    start_char: 1290,
    end_char: 1291,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "need",
    start_time: 120000,
    end_time: 120300,
    token_index: 241,
    start_char: 1292,
    end_char: 1296,
    display_word: "need",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 120300,
    end_time: 120500,
    token_index: 242,
    start_char: 1297,
    end_char: 1298,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "Guinness",
    start_time: 120500,
    end_time: 121000,
    token_index: 243,
    start_char: 1299,
    end_char: 1307,
    display_word: "Guinness",
    period_at_end: false
    },
    {
    word: "Book",
    start_time: 121000,
    end_time: 121200,
    token_index: 244,
    start_char: 1308,
    end_char: 1312,
    display_word: "Book",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 121200,
    end_time: 121400,
    token_index: 245,
    start_char: 1313,
    end_char: 1315,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "World",
    start_time: 121400,
    end_time: 121600,
    token_index: 246,
    start_char: 1316,
    end_char: 1321,
    display_word: "World",
    period_at_end: false
    },
    {
    word: "Records.",
    start_time: 121600,
    end_time: 122400,
    token_index: 247,
    start_char: 1322,
    end_char: 1329,
    display_word: "Records",
    period_at_end: true
    },
    {
    word: "Now",
    start_time: 122500,
    end_time: 123100,
    token_index: 248,
    start_char: 1331,
    end_char: 1334,
    display_word: "Now",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 123100,
    end_time: 123200,
    token_index: 249,
    start_char: 1335,
    end_char: 1339,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 123200,
    end_time: 123200,
    token_index: 250,
    start_char: 1340,
    end_char: 1343,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "these",
    start_time: 123200,
    end_time: 123400,
    token_index: 251,
    start_char: 1344,
    end_char: 1349,
    display_word: "these",
    period_at_end: false
    },
    {
    word: "cases",
    start_time: 123400,
    end_time: 123800,
    token_index: 252,
    start_char: 1350,
    end_char: 1355,
    display_word: "cases",
    period_at_end: false
    },
    {
    word: "Governors",
    start_time: 123800,
    end_time: 124700,
    token_index: 253,
    start_char: 1356,
    end_char: 1365,
    display_word: "Governors",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 124700,
    end_time: 124700,
    token_index: 254,
    start_char: 1366,
    end_char: 1369,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Mayors",
    start_time: 124700,
    end_time: 125100,
    token_index: 255,
    start_char: 1370,
    end_char: 1376,
    display_word: "Mayors",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 125100,
    end_time: 125200,
    token_index: 256,
    start_char: 1377,
    end_char: 1380,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "scrambling",
    start_time: 125200,
    end_time: 125900,
    token_index: 257,
    start_char: 1381,
    end_char: 1391,
    display_word: "scrambling",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 125900,
    end_time: 126100,
    token_index: 258,
    start_char: 1392,
    end_char: 1394,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "issue",
    start_time: 126100,
    end_time: 126600,
    token_index: 259,
    start_char: 1395,
    end_char: 1400,
    display_word: "issue",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 126600,
    end_time: 126800,
    token_index: 260,
    start_char: 1401,
    end_char: 1404,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "mask",
    start_time: 126800,
    end_time: 127200,
    token_index: 261,
    start_char: 1405,
    end_char: 1409,
    display_word: "mask",
    period_at_end: false
    },
    {
    word: "orders.",
    start_time: 127200,
    end_time: 127600,
    token_index: 262,
    start_char: 1410,
    end_char: 1416,
    display_word: "orders",
    period_at_end: true
    },
    {
    word: "That",
    start_time: 127600,
    end_time: 128000,
    token_index: 263,
    start_char: 1418,
    end_char: 1422,
    display_word: "That",
    period_at_end: false
    },
    {
    word: "seems",
    start_time: 128000,
    end_time: 128300,
    token_index: 264,
    start_char: 1423,
    end_char: 1428,
    display_word: "seems",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 128300,
    end_time: 128400,
    token_index: 265,
    start_char: 1429,
    end_char: 1430,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "little",
    start_time: 128400,
    end_time: 128500,
    token_index: 266,
    start_char: 1431,
    end_char: 1437,
    display_word: "little",
    period_at_end: false
    },
    {
    word: "late.",
    start_time: 128500,
    end_time: 129000,
    token_index: 267,
    start_char: 1438,
    end_char: 1442,
    display_word: "late",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 129199,
    end_time: 129800,
    token_index: 268,
    start_char: 1444,
    end_char: 1448,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 129800,
    end_time: 130000,
    token_index: 269,
    start_char: 1449,
    end_char: 1453,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "deciding",
    start_time: 130000,
    end_time: 130500,
    token_index: 270,
    start_char: 1454,
    end_char: 1462,
    display_word: "deciding",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 130500,
    end_time: 130600,
    token_index: 271,
    start_char: 1463,
    end_char: 1466,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 130600,
    end_time: 130800,
    token_index: 272,
    start_char: 1467,
    end_char: 1469,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "send",
    start_time: 130800,
    end_time: 131200,
    token_index: 273,
    start_char: 1470,
    end_char: 1474,
    display_word: "send",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 131200,
    end_time: 131200,
    token_index: 274,
    start_char: 1475,
    end_char: 1476,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "Mother's",
    start_time: 131200,
    end_time: 131600,
    token_index: 275,
    start_char: 1477,
    end_char: 1485,
    display_word: "Mother's",
    period_at_end: false
    },
    {
    word: "Day",
    start_time: 131600,
    end_time: 131600,
    token_index: 276,
    start_char: 1486,
    end_char: 1489,
    display_word: "Day",
    period_at_end: false
    },
    {
    word: "card",
    start_time: 131600,
    end_time: 131800,
    token_index: 277,
    start_char: 1490,
    end_char: 1494,
    display_word: "card",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 131800,
    end_time: 132100,
    token_index: 278,
    start_char: 1495,
    end_char: 1499,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 132100,
    end_time: 132400,
    token_index: 279,
    start_char: 1500,
    end_char: 1505,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "on.",
    start_time: 132400,
    end_time: 132500,
    token_index: 280,
    start_char: 1506,
    end_char: 1508,
    display_word: "on",
    period_at_end: true
    },
    {
    word: "What",
    start_time: 132600,
    end_time: 132800,
    token_index: 281,
    start_char: 1510,
    end_char: 1514,
    display_word: "What",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 132800,
    end_time: 132900,
    token_index: 282,
    start_char: 1515,
    end_char: 1518,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "hell",
    start_time: 132900,
    end_time: 133200,
    token_index: 283,
    start_char: 1519,
    end_char: 1523,
    display_word: "hell",
    period_at_end: false
    },
    {
    word: "happy",
    start_time: 133200,
    end_time: 133500,
    token_index: 284,
    start_char: 1524,
    end_char: 1529,
    display_word: "happy",
    period_at_end: false
    },
    {
    word: "Fourth",
    start_time: 133500,
    end_time: 133800,
    token_index: 285,
    start_char: 1530,
    end_char: 1536,
    display_word: "Fourth",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 133800,
    end_time: 133900,
    token_index: 286,
    start_char: 1537,
    end_char: 1539,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "July",
    start_time: 133900,
    end_time: 134200,
    token_index: 287,
    start_char: 1540,
    end_char: 1544,
    display_word: "July",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 134200,
    end_time: 134700,
    token_index: 288,
    start_char: 1545,
    end_char: 1548,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "wasn't",
    start_time: 135000,
    end_time: 135300,
    token_index: 289,
    start_char: 1549,
    end_char: 1555,
    display_word: "wasn't",
    period_at_end: false
    },
    {
    word: "wearing",
    start_time: 135300,
    end_time: 135600,
    token_index: 290,
    start_char: 1556,
    end_char: 1563,
    display_word: "wearing",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 135600,
    end_time: 135600,
    token_index: 291,
    start_char: 1564,
    end_char: 1565,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "mask.",
    start_time: 135600,
    end_time: 136100,
    token_index: 292,
    start_char: 1566,
    end_char: 1570,
    display_word: "mask",
    period_at_end: true
    },
    {
    word: "When",
    start_time: 136100,
    end_time: 136200,
    token_index: 293,
    start_char: 1572,
    end_char: 1576,
    display_word: "When",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 136200,
    end_time: 136300,
    token_index: 294,
    start_char: 1577,
    end_char: 1578,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "sign",
    start_time: 136300,
    end_time: 136600,
    token_index: 295,
    start_char: 1579,
    end_char: 1583,
    display_word: "sign",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 136600,
    end_time: 136900,
    token_index: 296,
    start_char: 1584,
    end_char: 1588,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "please",
    start_time: 136900,
    end_time: 137100,
    token_index: 297,
    start_char: 1589,
    end_char: 1595,
    display_word: "please",
    period_at_end: false
    },
    {
    word: "wash",
    start_time: 137100,
    end_time: 137300,
    token_index: 298,
    start_char: 1596,
    end_char: 1600,
    display_word: "wash",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 137300,
    end_time: 137400,
    token_index: 299,
    start_char: 1601,
    end_char: 1605,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "hands.",
    start_time: 137400,
    end_time: 137900,
    token_index: 300,
    start_char: 1606,
    end_char: 1611,
    display_word: "hands",
    period_at_end: true
    },
    {
    word: "One",
    start_time: 139000,
    end_time: 139200,
    token_index: 301,
    start_char: 1613,
    end_char: 1616,
    display_word: "One",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 139200,
    end_time: 139400,
    token_index: 302,
    start_char: 1617,
    end_char: 1619,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 139400,
    end_time: 139700,
    token_index: 303,
    start_char: 1620,
    end_char: 1623,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Masky",
    start_time: 139700,
    end_time: 140300,
    token_index: 304,
    start_char: 1624,
    end_char: 1629,
    display_word: "Masky",
    period_at_end: false
    },
    {
    word: "come",
    start_time: 140300,
    end_time: 140500,
    token_index: 305,
    start_char: 1630,
    end_char: 1634,
    display_word: "come",
    period_at_end: false
    },
    {
    word: "late",
    start_time: 140500,
    end_time: 140800,
    token_index: 306,
    start_char: 1635,
    end_char: 1639,
    display_word: "late",
    period_at_end: false
    },
    {
    word: "Lee's",
    start_time: 140800,
    end_time: 141100,
    token_index: 307,
    start_char: 1640,
    end_char: 1645,
    display_word: "Lee's",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 141400,
    end_time: 141700,
    token_index: 308,
    start_char: 1646,
    end_char: 1648,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "Alabama",
    start_time: 141700,
    end_time: 142100,
    token_index: 309,
    start_char: 1649,
    end_char: 1656,
    display_word: "Alabama",
    period_at_end: false
    },
    {
    word: "governor",
    start_time: 142100,
    end_time: 142600,
    token_index: 310,
    start_char: 1657,
    end_char: 1665,
    display_word: "governor",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 142600,
    end_time: 142900,
    token_index: 311,
    start_char: 1666,
    end_char: 1669,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "lady",
    start_time: 142900,
    end_time: 143200,
    token_index: 312,
    start_char: 1670,
    end_char: 1674,
    display_word: "lady",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 143200,
    end_time: 143300,
    token_index: 313,
    start_char: 1675,
    end_char: 1677,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 143300,
    end_time: 143400,
    token_index: 314,
    start_char: 1678,
    end_char: 1681,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "bake",
    start_time: 143400,
    end_time: 143600,
    token_index: 315,
    start_char: 1682,
    end_char: 1686,
    display_word: "bake",
    period_at_end: false
    },
    {
    word: "sale",
    start_time: 143600,
    end_time: 144000,
    token_index: 316,
    start_char: 1687,
    end_char: 1691,
    display_word: "sale",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 144000,
    end_time: 144100,
    token_index: 317,
    start_char: 1692,
    end_char: 1695,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 144100,
    end_time: 144400,
    token_index: 318,
    start_char: 1696,
    end_char: 1700,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "lied",
    start_time: 144400,
    end_time: 144700,
    token_index: 319,
    start_char: 1701,
    end_char: 1705,
    display_word: "lied",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 144700,
    end_time: 145000,
    token_index: 320,
    start_char: 1706,
    end_char: 1711,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 145000,
    end_time: 145100,
    token_index: 321,
    start_char: 1712,
    end_char: 1715,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "brownies",
    start_time: 145100,
    end_time: 145600,
    token_index: 322,
    start_char: 1716,
    end_char: 1724,
    display_word: "brownies",
    period_at_end: false
    },
    {
    word: "being",
    start_time: 145600,
    end_time: 145900,
    token_index: 323,
    start_char: 1725,
    end_char: 1730,
    display_word: "being",
    period_at_end: false
    },
    {
    word: "gluten-free",
    start_time: 145900,
    end_time: 146800,
    token_index: 324,
    start_char: 1731,
    end_char: 1742,
    display_word: "gluten-free",
    period_at_end: false
    },
    {
    word: "kiv.",
    start_time: 147200,
    end_time: 148100,
    token_index: 325,
    start_char: 1743,
    end_char: 1746,
    display_word: "kiv",
    period_at_end: true
    },
    {
    word: "Governor",
    start_time: 148500,
    end_time: 148900,
    token_index: 326,
    start_char: 1748,
    end_char: 1756,
    display_word: "Governor",
    period_at_end: false
    },
    {
    word: "Ivy",
    start_time: 148900,
    end_time: 149300,
    token_index: 327,
    start_char: 1757,
    end_char: 1760,
    display_word: "Ivy",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 149300,
    end_time: 149700,
    token_index: 328,
    start_char: 1761,
    end_char: 1764,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 149700,
    end_time: 150000,
    token_index: 329,
    start_char: 1765,
    end_char: 1769,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "holding",
    start_time: 150000,
    end_time: 150400,
    token_index: 330,
    start_char: 1770,
    end_char: 1777,
    display_word: "holding",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 150400,
    end_time: 150600,
    token_index: 331,
    start_char: 1778,
    end_char: 1781,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 150600,
    end_time: 150800,
    token_index: 332,
    start_char: 1782,
    end_char: 1784,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "masks.",
    start_time: 150800,
    end_time: 151300,
    token_index: 333,
    start_char: 1785,
    end_char: 1790,
    display_word: "masks",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 151300,
    end_time: 151800,
    token_index: 334,
    start_char: 1792,
    end_char: 1795,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "Alabama",
    start_time: 151900,
    end_time: 152400,
    token_index: 335,
    start_char: 1796,
    end_char: 1803,
    display_word: "Alabama",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 152400,
    end_time: 152700,
    token_index: 336,
    start_char: 1804,
    end_char: 1808,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "hit",
    start_time: 152700,
    end_time: 152900,
    token_index: 337,
    start_char: 1809,
    end_char: 1812,
    display_word: "hit",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 152900,
    end_time: 153100,
    token_index: 338,
    start_char: 1813,
    end_char: 1818,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "single",
    start_time: 153100,
    end_time: 153600,
    token_index: 339,
    start_char: 1819,
    end_char: 1825,
    display_word: "single",
    period_at_end: false
    },
    {
    word: "day",
    start_time: 153600,
    end_time: 153800,
    token_index: 340,
    start_char: 1826,
    end_char: 1829,
    display_word: "day",
    period_at_end: false
    },
    {
    word: "death",
    start_time: 153800,
    end_time: 154200,
    token_index: 341,
    start_char: 1830,
    end_char: 1835,
    display_word: "death",
    period_at_end: false
    },
    {
    word: "record.",
    start_time: 154200,
    end_time: 154800,
    token_index: 342,
    start_char: 1836,
    end_char: 1842,
    display_word: "record",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 154900,
    end_time: 155000,
    token_index: 343,
    start_char: 1844,
    end_char: 1846,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "yesterday",
    start_time: 155000,
    end_time: 155600,
    token_index: 344,
    start_char: 1847,
    end_char: 1856,
    display_word: "yesterday",
    period_at_end: false
    },
    {
    word: "IV",
    start_time: 156000,
    end_time: 156400,
    token_index: 345,
    start_char: 1857,
    end_char: 1859,
    display_word: "IV",
    period_at_end: false
    },
    {
    word: "issued",
    start_time: 156400,
    end_time: 156800,
    token_index: 346,
    start_char: 1860,
    end_char: 1866,
    display_word: "issued",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 156800,
    end_time: 156900,
    token_index: 347,
    start_char: 1867,
    end_char: 1868,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "Statewide",
    start_time: 156900,
    end_time: 157800,
    token_index: 348,
    start_char: 1869,
    end_char: 1878,
    display_word: "Statewide",
    period_at_end: false
    },
    {
    word: "mask",
    start_time: 157800,
    end_time: 158300,
    token_index: 349,
    start_char: 1879,
    end_char: 1883,
    display_word: "mask",
    period_at_end: false
    },
    {
    word: "order",
    start_time: 158300,
    end_time: 158800,
    token_index: 350,
    start_char: 1884,
    end_char: 1889,
    display_word: "order",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 159200,
    end_time: 159300,
    token_index: 351,
    start_char: 1890,
    end_char: 1893,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 159300,
    end_time: 159400,
    token_index: 352,
    start_char: 1894,
    end_char: 1896,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "make",
    start_time: 159400,
    end_time: 159600,
    token_index: 353,
    start_char: 1897,
    end_char: 1901,
    display_word: "make",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 159600,
    end_time: 159700,
    token_index: 354,
    start_char: 1902,
    end_char: 1905,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "order",
    start_time: 159700,
    end_time: 159900,
    token_index: 355,
    start_char: 1906,
    end_char: 1911,
    display_word: "order",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 159900,
    end_time: 160100,
    token_index: 356,
    start_char: 1912,
    end_char: 1916,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "palatable",
    start_time: 160100,
    end_time: 160700,
    token_index: 357,
    start_char: 1917,
    end_char: 1926,
    display_word: "palatable",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 160700,
    end_time: 160800,
    token_index: 358,
    start_char: 1927,
    end_char: 1929,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "Alabama",
    start_time: 160800,
    end_time: 161200,
    token_index: 359,
    start_char: 1930,
    end_char: 1937,
    display_word: "Alabama",
    period_at_end: false
    },
    {
    word: "voters",
    start_time: 161200,
    end_time: 161700,
    token_index: 360,
    start_char: 1938,
    end_char: 1944,
    display_word: "voters",
    period_at_end: false
    },
    {
    word: "The",
    start_time: 162000,
    end_time: 162100,
    token_index: 361,
    start_char: 1945,
    end_char: 1948,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "Masks",
    start_time: 162100,
    end_time: 162700,
    token_index: 362,
    start_char: 1949,
    end_char: 1954,
    display_word: "Masks",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 162700,
    end_time: 162800,
    token_index: 363,
    start_char: 1955,
    end_char: 1959,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 162800,
    end_time: 163000,
    token_index: 364,
    start_char: 1960,
    end_char: 1962,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "deep-fried",
    start_time: 163000,
    end_time: 164100,
    token_index: 365,
    start_char: 1963,
    end_char: 1973,
    display_word: "deep-fried",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 164600,
    end_time: 164800,
    token_index: 366,
    start_char: 1974,
    end_char: 1977,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "elected",
    start_time: 164800,
    end_time: 165200,
    token_index: 367,
    start_char: 1978,
    end_char: 1985,
    display_word: "elected",
    period_at_end: false
    },
    {
    word: "official",
    start_time: 165200,
    end_time: 165600,
    token_index: 368,
    start_char: 1986,
    end_char: 1994,
    display_word: "official",
    period_at_end: false
    },
    {
    word: "who's",
    start_time: 165600,
    end_time: 166100,
    token_index: 369,
    start_char: 1995,
    end_char: 2000,
    display_word: "who's",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 166100,
    end_time: 166300,
    token_index: 370,
    start_char: 2001,
    end_char: 2004,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "giving",
    start_time: 166300,
    end_time: 166600,
    token_index: 371,
    start_char: 2005,
    end_char: 2011,
    display_word: "giving",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 166600,
    end_time: 166800,
    token_index: 372,
    start_char: 2012,
    end_char: 2014,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 166800,
    end_time: 167000,
    token_index: 373,
    start_char: 2015,
    end_char: 2017,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 167000,
    end_time: 167000,
    token_index: 374,
    start_char: 2018,
    end_char: 2021,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "health",
    start_time: 167000,
    end_time: 167400,
    token_index: 375,
    start_char: 2022,
    end_char: 2028,
    display_word: "health",
    period_at_end: false
    },
    {
    word: "Huggers",
    start_time: 167400,
    end_time: 167900,
    token_index: 376,
    start_char: 2029,
    end_char: 2036,
    display_word: "Huggers",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 168300,
    end_time: 168700,
    token_index: 377,
    start_char: 2037,
    end_char: 2039,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "The",
    start_time: 168800,
    end_time: 168900,
    token_index: 378,
    start_char: 2040,
    end_char: 2043,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "governor",
    start_time: 168900,
    end_time: 169400,
    token_index: 379,
    start_char: 2044,
    end_char: 2052,
    display_word: "governor",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 169400,
    end_time: 169500,
    token_index: 380,
    start_char: 2053,
    end_char: 2056,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "face",
    start_time: 169500,
    end_time: 169900,
    token_index: 381,
    start_char: 2057,
    end_char: 2061,
    display_word: "face",
    period_at_end: false
    },
    {
    word: "born",
    start_time: 169900,
    end_time: 170300,
    token_index: 382,
    start_char: 2062,
    end_char: 2066,
    display_word: "born",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 170300,
    end_time: 170400,
    token_index: 383,
    start_char: 2067,
    end_char: 2070,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 170400,
    end_time: 170500,
    token_index: 384,
    start_char: 2071,
    end_char: 2072,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "mask",
    start_time: 170500,
    end_time: 171400,
    token_index: 385,
    start_char: 2073,
    end_char: 2077,
    display_word: "mask",
    period_at_end: false
    },
    {
    word: "Brian",
    start_time: 171400,
    end_time: 171800,
    token_index: 386,
    start_char: 2078,
    end_char: 2083,
    display_word: "Brian",
    period_at_end: false
    },
    {
    word: "Kemp",
    start_time: 171800,
    end_time: 172200,
    token_index: 387,
    start_char: 2084,
    end_char: 2088,
    display_word: "Kemp",
    period_at_end: false
    },
    {
    word: "yesterday",
    start_time: 172200,
    end_time: 172900,
    token_index: 388,
    start_char: 2089,
    end_char: 2098,
    display_word: "yesterday",
    period_at_end: false
    },
    {
    word: "kept",
    start_time: 173300,
    end_time: 173700,
    token_index: 389,
    start_char: 2099,
    end_char: 2103,
    display_word: "kept",
    period_at_end: false
    },
    {
    word: "explicitly",
    start_time: 173700,
    end_time: 174500,
    token_index: 390,
    start_char: 2104,
    end_char: 2114,
    display_word: "explicitly",
    period_at_end: false
    },
    {
    word: "banned",
    start_time: 174500,
    end_time: 175000,
    token_index: 391,
    start_char: 2115,
    end_char: 2121,
    display_word: "banned",
    period_at_end: false
    },
    {
    word: "localities",
    start_time: 175000,
    end_time: 175700,
    token_index: 392,
    start_char: 2122,
    end_char: 2132,
    display_word: "localities",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 175700,
    end_time: 176000,
    token_index: 393,
    start_char: 2133,
    end_char: 2137,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "ordering",
    start_time: 176000,
    end_time: 176500,
    token_index: 394,
    start_char: 2138,
    end_char: 2146,
    display_word: "ordering",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 176500,
    end_time: 176900,
    token_index: 395,
    start_char: 2147,
    end_char: 2153,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 176900,
    end_time: 177000,
    token_index: 396,
    start_char: 2154,
    end_char: 2156,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "wear",
    start_time: 177000,
    end_time: 177300,
    token_index: 397,
    start_char: 2157,
    end_char: 2161,
    display_word: "wear",
    period_at_end: false
    },
    {
    word: "masks.",
    start_time: 177300,
    end_time: 178000,
    token_index: 398,
    start_char: 2162,
    end_char: 2167,
    display_word: "masks",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 178000,
    end_time: 178200,
    token_index: 399,
    start_char: 2169,
    end_char: 2171,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 178200,
    end_time: 178400,
    token_index: 400,
    start_char: 2172,
    end_char: 2175,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "only",
    start_time: 178400,
    end_time: 178900,
    token_index: 401,
    start_char: 2176,
    end_char: 2180,
    display_word: "only",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 179000,
    end_time: 179200,
    token_index: 402,
    start_char: 2181,
    end_char: 2183,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "Governor",
    start_time: 179200,
    end_time: 179600,
    token_index: 403,
    start_char: 2184,
    end_char: 2192,
    display_word: "Governor",
    period_at_end: false
    },
    {
    word: "Kemp",
    start_time: 179600,
    end_time: 179900,
    token_index: 404,
    start_char: 2193,
    end_char: 2197,
    display_word: "Kemp",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 179900,
    end_time: 180300,
    token_index: 405,
    start_char: 2198,
    end_char: 2203,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "nothing.",
    start_time: 180300,
    end_time: 180900,
    token_index: 406,
    start_char: 2204,
    end_char: 2211,
    display_word: "nothing",
    period_at_end: true
    },
    {
    word: "He's",
    start_time: 181100,
    end_time: 181300,
    token_index: 407,
    start_char: 2213,
    end_char: 2217,
    display_word: "He's",
    period_at_end: false
    },
    {
    word: "stopping",
    start_time: 181300,
    end_time: 181700,
    token_index: 408,
    start_char: 2218,
    end_char: 2226,
    display_word: "stopping",
    period_at_end: false
    },
    {
    word: "other",
    start_time: 181700,
    end_time: 181900,
    token_index: 409,
    start_char: 2227,
    end_char: 2232,
    display_word: "other",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 181900,
    end_time: 182300,
    token_index: 410,
    start_char: 2233,
    end_char: 2239,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 182300,
    end_time: 182500,
    token_index: 411,
    start_char: 2240,
    end_char: 2244,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 182500,
    end_time: 182900,
    token_index: 412,
    start_char: 2245,
    end_char: 2250,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "any",
    start_time: 182900,
    end_time: 183200,
    token_index: 413,
    start_char: 2251,
    end_char: 2254,
    display_word: "any",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 183200,
    end_time: 183600,
    token_index: 414,
    start_char: 2255,
    end_char: 2261,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 184200,
    end_time: 184200,
    token_index: 415,
    start_char: 2262,
    end_char: 2265,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 184200,
    end_time: 184300,
    token_index: 416,
    start_char: 2266,
    end_char: 2268,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "make",
    start_time: 184300,
    end_time: 184500,
    token_index: 417,
    start_char: 2269,
    end_char: 2273,
    display_word: "make",
    period_at_end: false
    },
    {
    word: "matters",
    start_time: 184500,
    end_time: 184800,
    token_index: 418,
    start_char: 2274,
    end_char: 2281,
    display_word: "matters",
    period_at_end: false
    },
    {
    word: "worse.",
    start_time: 184800,
    end_time: 185300,
    token_index: 419,
    start_char: 2282,
    end_char: 2287,
    display_word: "worse",
    period_at_end: true
    },
    {
    word: "He",
    start_time: 185400,
    end_time: 185600,
    token_index: 420,
    start_char: 2289,
    end_char: 2291,
    display_word: "He",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 185600,
    end_time: 185800,
    token_index: 421,
    start_char: 2292,
    end_char: 2296,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "launched",
    start_time: 185800,
    end_time: 186200,
    token_index: 422,
    start_char: 2297,
    end_char: 2305,
    display_word: "launched",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 186200,
    end_time: 186200,
    token_index: 423,
    start_char: 2306,
    end_char: 2307,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 186200,
    end_time: 186500,
    token_index: 424,
    start_char: 2308,
    end_char: 2311,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "Georgia",
    start_time: 186500,
    end_time: 186900,
    token_index: 425,
    start_char: 2312,
    end_char: 2319,
    display_word: "Georgia",
    period_at_end: false
    },
    {
    word: "Public",
    start_time: 186900,
    end_time: 187200,
    token_index: 426,
    start_char: 2320,
    end_char: 2326,
    display_word: "Public",
    period_at_end: false
    },
    {
    word: "Health",
    start_time: 187200,
    end_time: 187400,
    token_index: 427,
    start_char: 2327,
    end_char: 2333,
    display_word: "Health",
    period_at_end: false
    },
    {
    word: "campaign",
    start_time: 187400,
    end_time: 188200,
    token_index: 428,
    start_char: 2334,
    end_char: 2342,
    display_word: "campaign",
    period_at_end: false
    },
    {
    word: "lick",
    start_time: 188200,
    end_time: 188600,
    token_index: 429,
    start_char: 2343,
    end_char: 2347,
    display_word: "lick",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 188600,
    end_time: 188800,
    token_index: 430,
    start_char: 2348,
    end_char: 2351,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "elderly.",
    start_time: 188800,
    end_time: 189600,
    token_index: 431,
    start_char: 2352,
    end_char: 2359,
    display_word: "elderly",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 189600,
    end_time: 189800,
    token_index: 432,
    start_char: 2361,
    end_char: 2365,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 189800,
    end_time: 190000,
    token_index: 433,
    start_char: 2366,
    end_char: 2369,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "law.",
    start_time: 190000,
    end_time: 190600,
    token_index: 434,
    start_char: 2370,
    end_char: 2373,
    display_word: "law",
    period_at_end: true
    },
    {
    word: "To",
    start_time: 191800,
    end_time: 191900,
    token_index: 435,
    start_char: 2375,
    end_char: 2377,
    display_word: "To",
    period_at_end: false
    },
    {
    word: "emphasize",
    start_time: 191900,
    end_time: 192500,
    token_index: 436,
    start_char: 2378,
    end_char: 2387,
    display_word: "emphasize",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 192500,
    end_time: 192600,
    token_index: 437,
    start_char: 2388,
    end_char: 2391,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "anti-mask",
    start_time: 192600,
    end_time: 193300,
    token_index: 438,
    start_char: 2392,
    end_char: 2401,
    display_word: "anti-mask",
    period_at_end: false
    },
    {
    word: "Kemp",
    start_time: 193300,
    end_time: 193600,
    token_index: 439,
    start_char: 2402,
    end_char: 2406,
    display_word: "Kemp",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 193600,
    end_time: 193800,
    token_index: 440,
    start_char: 2407,
    end_char: 2413,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 193800,
    end_time: 194000,
    token_index: 441,
    start_char: 2414,
    end_char: 2416,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "When",
    start_time: 194000,
    end_time: 194200,
    token_index: 442,
    start_char: 2417,
    end_char: 2421,
    display_word: "When",
    period_at_end: false
    },
    {
    word: "Donald",
    start_time: 194200,
    end_time: 194400,
    token_index: 443,
    start_char: 2422,
    end_char: 2428,
    display_word: "Donald",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 194400,
    end_time: 194700,
    token_index: 444,
    start_char: 2429,
    end_char: 2434,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "landed",
    start_time: 194700,
    end_time: 195200,
    token_index: 445,
    start_char: 2435,
    end_char: 2441,
    display_word: "landed",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 195200,
    end_time: 195400,
    token_index: 446,
    start_char: 2442,
    end_char: 2444,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "Atlanta",
    start_time: 195400,
    end_time: 195900,
    token_index: 447,
    start_char: 2445,
    end_char: 2452,
    display_word: "Atlanta",
    period_at_end: false
    },
    {
    word: "yesterday",
    start_time: 195900,
    end_time: 196500,
    token_index: 448,
    start_char: 2453,
    end_char: 2462,
    display_word: "yesterday",
    period_at_end: false
    },
    {
    word: "Kemp",
    start_time: 196800,
    end_time: 197200,
    token_index: 449,
    start_char: 2463,
    end_char: 2467,
    display_word: "Kemp",
    period_at_end: false
    },
    {
    word: "greeted",
    start_time: 197200,
    end_time: 197600,
    token_index: 450,
    start_char: 2468,
    end_char: 2475,
    display_word: "greeted",
    period_at_end: false
    },
    {
    word: "him",
    start_time: 197600,
    end_time: 197800,
    token_index: 451,
    start_char: 2476,
    end_char: 2479,
    display_word: "him",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 197800,
    end_time: 197900,
    token_index: 452,
    start_char: 2480,
    end_char: 2482,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 197900,
    end_time: 198000,
    token_index: 453,
    start_char: 2483,
    end_char: 2486,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "airport",
    start_time: 198000,
    end_time: 198800,
    token_index: 454,
    start_char: 2487,
    end_char: 2494,
    display_word: "airport",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 198800,
    end_time: 199000,
    token_index: 455,
    start_char: 2495,
    end_char: 2499,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 199000,
    end_time: 199100,
    token_index: 456,
    start_char: 2500,
    end_char: 2501,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "mask",
    start_time: 199100,
    end_time: 199700,
    token_index: 457,
    start_char: 2502,
    end_char: 2506,
    display_word: "mask",
    period_at_end: false
    },
    {
    word: "dangling",
    start_time: 199700,
    end_time: 200300,
    token_index: 458,
    start_char: 2507,
    end_char: 2515,
    display_word: "dangling",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 200300,
    end_time: 200500,
    token_index: 459,
    start_char: 2516,
    end_char: 2520,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 200500,
    end_time: 200700,
    token_index: 460,
    start_char: 2521,
    end_char: 2524,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "ear",
    start_time: 200700,
    end_time: 201100,
    token_index: 461,
    start_char: 2525,
    end_char: 2528,
    display_word: "ear",
    period_at_end: false
    },
    {
    word: "look",
    start_time: 201300,
    end_time: 201600,
    token_index: 462,
    start_char: 2529,
    end_char: 2533,
    display_word: "look",
    period_at_end: false
    },
    {
    word: "buddy.",
    start_time: 201600,
    end_time: 202100,
    token_index: 463,
    start_char: 2534,
    end_char: 2539,
    display_word: "buddy",
    period_at_end: true
    },
    {
    word: "Either",
    start_time: 202100,
    end_time: 202400,
    token_index: 464,
    start_char: 2541,
    end_char: 2547,
    display_word: "Either",
    period_at_end: false
    },
    {
    word: "wear",
    start_time: 202400,
    end_time: 202700,
    token_index: 465,
    start_char: 2548,
    end_char: 2552,
    display_word: "wear",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 202700,
    end_time: 202800,
    token_index: 466,
    start_char: 2553,
    end_char: 2556,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "damn",
    start_time: 202800,
    end_time: 203100,
    token_index: 467,
    start_char: 2557,
    end_char: 2561,
    display_word: "damn",
    period_at_end: false
    },
    {
    word: "thing",
    start_time: 203100,
    end_time: 203400,
    token_index: 468,
    start_char: 2562,
    end_char: 2567,
    display_word: "thing",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 203400,
    end_time: 203500,
    token_index: 469,
    start_char: 2568,
    end_char: 2570,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 203500,
    end_time: 204000,
    token_index: 470,
    start_char: 2571,
    end_char: 2576,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 204000,
    end_time: 204200,
    token_index: 471,
    start_char: 2577,
    end_char: 2583,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 204200,
    end_time: 204400,
    token_index: 472,
    start_char: 2584,
    end_char: 2588,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "surveying",
    start_time: 204400,
    end_time: 204800,
    token_index: 473,
    start_char: 2589,
    end_char: 2598,
    display_word: "surveying",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 204800,
    end_time: 204900,
    token_index: 474,
    start_char: 2599,
    end_char: 2600,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "nuclear",
    start_time: 204900,
    end_time: 205400,
    token_index: 475,
    start_char: 2601,
    end_char: 2608,
    display_word: "nuclear",
    period_at_end: false
    },
    {
    word: "accident",
    start_time: 205400,
    end_time: 205800,
    token_index: 476,
    start_char: 2609,
    end_char: 2617,
    display_word: "accident",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 205800,
    end_time: 205900,
    token_index: 477,
    start_char: 2618,
    end_char: 2622,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 205900,
    end_time: 206100,
    token_index: 478,
    start_char: 2623,
    end_char: 2627,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "hazmat",
    start_time: 206100,
    end_time: 206600,
    token_index: 479,
    start_char: 2628,
    end_char: 2634,
    display_word: "hazmat",
    period_at_end: false
    },
    {
    word: "suit",
    start_time: 206600,
    end_time: 207200,
    token_index: 480,
    start_char: 2635,
    end_char: 2639,
    display_word: "suit",
    period_at_end: false
    },
    {
    word: "tied",
    start_time: 207200,
    end_time: 207600,
    token_index: 481,
    start_char: 2640,
    end_char: 2644,
    display_word: "tied",
    period_at_end: false
    },
    {
    word: "around",
    start_time: 207600,
    end_time: 207900,
    token_index: 482,
    start_char: 2645,
    end_char: 2651,
    display_word: "around",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 207900,
    end_time: 208100,
    token_index: 483,
    start_char: 2652,
    end_char: 2656,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "shoulders.",
    start_time: 208100,
    end_time: 208800,
    token_index: 484,
    start_char: 2657,
    end_char: 2666,
    display_word: "shoulders",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 209700,
    end_time: 210200,
    token_index: 485,
    start_char: 2668,
    end_char: 2671,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "there's",
    start_time: 210200,
    end_time: 210300,
    token_index: 486,
    start_char: 2672,
    end_char: 2679,
    display_word: "there's",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 210300,
    end_time: 210500,
    token_index: 487,
    start_char: 2680,
    end_char: 2684,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "troubling",
    start_time: 210500,
    end_time: 210900,
    token_index: 488,
    start_char: 2685,
    end_char: 2694,
    display_word: "troubling",
    period_at_end: false
    },
    {
    word: "pandemic",
    start_time: 210900,
    end_time: 211400,
    token_index: 489,
    start_char: 2695,
    end_char: 2703,
    display_word: "pandemic",
    period_at_end: false
    },
    {
    word: "news",
    start_time: 211400,
    end_time: 211700,
    token_index: 490,
    start_char: 2704,
    end_char: 2708,
    display_word: "news",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 211700,
    end_time: 212000,
    token_index: 491,
    start_char: 2709,
    end_char: 2716,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "yesterday",
    start_time: 212000,
    end_time: 212600,
    token_index: 492,
    start_char: 2717,
    end_char: 2726,
    display_word: "yesterday",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 212600,
    end_time: 213100,
    token_index: 493,
    start_char: 2727,
    end_char: 2729,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "learned",
    start_time: 213100,
    end_time: 213300,
    token_index: 494,
    start_char: 2730,
    end_char: 2737,
    display_word: "learned",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 213300,
    end_time: 213500,
    token_index: 495,
    start_char: 2738,
    end_char: 2742,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "Russian",
    start_time: 213500,
    end_time: 214000,
    token_index: 496,
    start_char: 2743,
    end_char: 2750,
    display_word: "Russian",
    period_at_end: false
    },
    {
    word: "hackers",
    start_time: 214000,
    end_time: 214600,
    token_index: 497,
    start_char: 2751,
    end_char: 2758,
    display_word: "hackers",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 214600,
    end_time: 215100,
    token_index: 498,
    start_char: 2759,
    end_char: 2763,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "launched",
    start_time: 215100,
    end_time: 215400,
    token_index: 499,
    start_char: 2764,
    end_char: 2772,
    display_word: "launched",
    period_at_end: false
    },
    {
    word: "cyber",
    start_time: 215400,
    end_time: 215900,
    token_index: 500,
    start_char: 2773,
    end_char: 2778,
    display_word: "cyber",
    period_at_end: false
    },
    {
    word: "attacks",
    start_time: 215900,
    end_time: 216400,
    token_index: 501,
    start_char: 2779,
    end_char: 2786,
    display_word: "attacks",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 216400,
    end_time: 216500,
    token_index: 502,
    start_char: 2787,
    end_char: 2789,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "covid-19",
    start_time: 216500,
    end_time: 217100,
    token_index: 503,
    start_char: 2790,
    end_char: 2798,
    display_word: "covid-19",
    period_at_end: false
    },
    {
    word: "Research",
    start_time: 217100,
    end_time: 218100,
    token_index: 504,
    start_char: 2799,
    end_char: 2807,
    display_word: "Research",
    period_at_end: false
    },
    {
    word: "centers.",
    start_time: 218100,
    end_time: 218500,
    token_index: 505,
    start_char: 2808,
    end_char: 2815,
    display_word: "centers",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 218500,
    end_time: 218800,
    token_index: 506,
    start_char: 2817,
    end_char: 2819,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 218800,
    end_time: 218900,
    token_index: 507,
    start_char: 2820,
    end_char: 2823,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Russians",
    start_time: 218900,
    end_time: 219500,
    token_index: 508,
    start_char: 2824,
    end_char: 2832,
    display_word: "Russians",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 219500,
    end_time: 219700,
    token_index: 509,
    start_char: 2833,
    end_char: 2836,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "getting",
    start_time: 219700,
    end_time: 219900,
    token_index: 510,
    start_char: 2837,
    end_char: 2844,
    display_word: "getting",
    period_at_end: false
    },
    {
    word: "America's",
    start_time: 219900,
    end_time: 220300,
    token_index: 511,
    start_char: 2845,
    end_char: 2854,
    display_word: "America's",
    period_at_end: false
    },
    {
    word: "coronavirus",
    start_time: 220300,
    end_time: 220700,
    token_index: 512,
    start_char: 2855,
    end_char: 2866,
    display_word: "coronavirus",
    period_at_end: false
    },
    {
    word: "data.",
    start_time: 220700,
    end_time: 221400,
    token_index: 513,
    start_char: 2867,
    end_char: 2871,
    display_word: "data",
    period_at_end: true
    },
    {
    word: "Could",
    start_time: 221400,
    end_time: 222300,
    token_index: 514,
    start_char: 2873,
    end_char: 2878,
    display_word: "Could",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 222300,
    end_time: 222500,
    token_index: 515,
    start_char: 2879,
    end_char: 2883,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "share",
    start_time: 222500,
    end_time: 222700,
    token_index: 516,
    start_char: 2884,
    end_char: 2889,
    display_word: "share",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 222700,
    end_time: 222800,
    token_index: 517,
    start_char: 2890,
    end_char: 2892,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 222800,
    end_time: 222900,
    token_index: 518,
    start_char: 2893,
    end_char: 2897,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "us?",
    start_time: 222900,
    end_time: 223200,
    token_index: 519,
    start_char: 2898,
    end_char: 2901,
    display_word: "us?",
    period_at_end: false
    },
    {
    word: "And",
    start_time: 224400,
    end_time: 224600,
    token_index: 520,
    start_char: 2902,
    end_char: 2905,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 224600,
    end_time: 224700,
    token_index: 521,
    start_char: 2906,
    end_char: 2910,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 224700,
    end_time: 224800,
    token_index: 522,
    start_char: 2911,
    end_char: 2914,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 224800,
    end_time: 225000,
    token_index: 523,
    start_char: 2915,
    end_char: 2919,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "any",
    start_time: 225000,
    end_time: 225200,
    token_index: 524,
    start_char: 2920,
    end_char: 2923,
    display_word: "any",
    period_at_end: false
    },
    {
    word: "data",
    start_time: 225200,
    end_time: 225600,
    token_index: 525,
    start_char: 2924,
    end_char: 2928,
    display_word: "data",
    period_at_end: false
    },
    {
    word: "specifically",
    start_time: 225700,
    end_time: 226400,
    token_index: 526,
    start_char: 2929,
    end_char: 2941,
    display_word: "specifically",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 226400,
    end_time: 226500,
    token_index: 527,
    start_char: 2942,
    end_char: 2945,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Russian",
    start_time: 226500,
    end_time: 226900,
    token_index: 528,
    start_char: 2946,
    end_char: 2953,
    display_word: "Russian",
    period_at_end: false
    },
    {
    word: "hackers",
    start_time: 226900,
    end_time: 227300,
    token_index: 529,
    start_char: 2954,
    end_char: 2961,
    display_word: "hackers",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 227300,
    end_time: 227400,
    token_index: 530,
    start_char: 2962,
    end_char: 2966,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "attempting",
    start_time: 227400,
    end_time: 227800,
    token_index: 531,
    start_char: 2967,
    end_char: 2977,
    display_word: "attempting",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 227800,
    end_time: 228000,
    token_index: 532,
    start_char: 2978,
    end_char: 2980,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "steal",
    start_time: 228000,
    end_time: 228400,
    token_index: 533,
    start_char: 2981,
    end_char: 2986,
    display_word: "steal",
    period_at_end: false
    },
    {
    word: "coronavirus",
    start_time: 228400,
    end_time: 228900,
    token_index: 534,
    start_char: 2987,
    end_char: 2998,
    display_word: "coronavirus",
    period_at_end: false
    },
    {
    word: "vaccine",
    start_time: 228900,
    end_time: 229900,
    token_index: 535,
    start_char: 2999,
    end_char: 3006,
    display_word: "vaccine",
    period_at_end: false
    },
    {
    word: "research.",
    start_time: 229900,
    end_time: 230100,
    token_index: 536,
    start_char: 3007,
    end_char: 3015,
    display_word: "research",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 230100,
    end_time: 230900,
    token_index: 537,
    start_char: 3017,
    end_char: 3019,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 230900,
    end_time: 231000,
    token_index: 538,
    start_char: 3020,
    end_char: 3022,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "they're",
    start_time: 231000,
    end_time: 231200,
    token_index: 539,
    start_char: 3023,
    end_char: 3030,
    display_word: "they're",
    period_at_end: false
    },
    {
    word: "successful,",
    start_time: 231200,
    end_time: 231300,
    token_index: 540,
    start_char: 3031,
    end_char: 3042,
    display_word: "successful,",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 231300,
    end_time: 231800,
    token_index: 541,
    start_char: 3043,
    end_char: 3047,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "only",
    start_time: 231800,
    end_time: 232000,
    token_index: 542,
    start_char: 3048,
    end_char: 3052,
    display_word: "only",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 232000,
    end_time: 232100,
    token_index: 543,
    start_char: 3053,
    end_char: 3054,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "matter",
    start_time: 232100,
    end_time: 232200,
    token_index: 544,
    start_char: 3055,
    end_char: 3061,
    display_word: "matter",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 232200,
    end_time: 232400,
    token_index: 545,
    start_char: 3062,
    end_char: 3064,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "time",
    start_time: 232400,
    end_time: 232500,
    token_index: 546,
    start_char: 3065,
    end_char: 3069,
    display_word: "time",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 232500,
    end_time: 232700,
    token_index: 547,
    start_char: 3070,
    end_char: 3076,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 232700,
    end_time: 233100,
    token_index: 548,
    start_char: 3077,
    end_char: 3079,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "start",
    start_time: 233100,
    end_time: 233300,
    token_index: 549,
    start_char: 3080,
    end_char: 3085,
    display_word: "start",
    period_at_end: false
    },
    {
    word: "getting",
    start_time: 233300,
    end_time: 233400,
    token_index: 550,
    start_char: 3086,
    end_char: 3093,
    display_word: "getting",
    period_at_end: false
    },
    {
    word: "spam",
    start_time: 233400,
    end_time: 234000,
    token_index: 551,
    start_char: 3094,
    end_char: 3098,
    display_word: "spam",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 234000,
    end_time: 234100,
    token_index: 552,
    start_char: 3099,
    end_char: 3102,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "bootleg",
    start_time: 234100,
    end_time: 234600,
    token_index: 553,
    start_char: 3103,
    end_char: 3110,
    display_word: "bootleg",
    period_at_end: false
    },
    {
    word: "coronavirus",
    start_time: 234600,
    end_time: 235000,
    token_index: 554,
    start_char: 3111,
    end_char: 3122,
    display_word: "coronavirus",
    period_at_end: false
    },
    {
    word: "cures",
    start_time: 235000,
    end_time: 235600,
    token_index: 555,
    start_char: 3123,
    end_char: 3128,
    display_word: "cures",
    period_at_end: false
    },
    {
    word: "there.",
    start_time: 235600,
    end_time: 237000,
    token_index: 556,
    start_char: 3129,
    end_char: 3134,
    display_word: "there",
    period_at_end: true
    },
    {
    word: "He",
    start_time: 237000,
    end_time: 237100,
    token_index: 557,
    start_char: 3136,
    end_char: 3138,
    display_word: "He",
    period_at_end: false
    },
    {
    word: "may",
    start_time: 237100,
    end_time: 237300,
    token_index: 558,
    start_char: 3139,
    end_char: 3142,
    display_word: "may",
    period_at_end: false
    },
    {
    word: "kill",
    start_time: 237300,
    end_time: 237500,
    token_index: 559,
    start_char: 3143,
    end_char: 3147,
    display_word: "kill",
    period_at_end: false
    },
    {
    word: "super",
    start_time: 237500,
    end_time: 238100,
    token_index: 560,
    start_char: 3148,
    end_char: 3153,
    display_word: "super",
    period_at_end: false
    },
    {
    word: "impressed",
    start_time: 238100,
    end_time: 238600,
    token_index: 561,
    start_char: 3154,
    end_char: 3163,
    display_word: "impressed",
    period_at_end: false
    },
    {
    word: "lack",
    start_time: 238600,
    end_time: 238900,
    token_index: 562,
    start_char: 3164,
    end_char: 3168,
    display_word: "lack",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 238900,
    end_time: 239100,
    token_index: 563,
    start_char: 3169,
    end_char: 3171,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "dying",
    start_time: 239100,
    end_time: 239400,
    token_index: 564,
    start_char: 3172,
    end_char: 3177,
    display_word: "dying",
    period_at_end: false
    },
    {
    word: "plus",
    start_time: 239400,
    end_time: 239700,
    token_index: 565,
    start_char: 3178,
    end_char: 3182,
    display_word: "plus",
    period_at_end: false
    },
    {
    word: "turgid",
    start_time: 239700,
    end_time: 240200,
    token_index: 566,
    start_char: 3183,
    end_char: 3189,
    display_word: "turgid",
    period_at_end: false
    },
    {
    word: "member",
    start_time: 240200,
    end_time: 240500,
    token_index: 567,
    start_char: 3190,
    end_char: 3196,
    display_word: "member",
    period_at_end: false
    },
    {
    word: "all-night",
    start_time: 240500,
    end_time: 241000,
    token_index: 568,
    start_char: 3197,
    end_char: 3206,
    display_word: "all-night",
    period_at_end: false
    },
    {
    word: "Drive",
    start_time: 241000,
    end_time: 241300,
    token_index: 569,
    start_char: 3207,
    end_char: 3212,
    display_word: "Drive",
    period_at_end: false
    },
    {
    word: "women",
    start_time: 241300,
    end_time: 241600,
    token_index: 570,
    start_char: 3213,
    end_char: 3218,
    display_word: "women",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 241600,
    end_time: 241800,
    token_index: 571,
    start_char: 3219,
    end_char: 3221,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "crazy",
    start_time: 241800,
    end_time: 241900,
    token_index: 572,
    start_char: 3222,
    end_char: 3227,
    display_word: "crazy",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 241900,
    end_time: 242500,
    token_index: 573,
    start_char: 3228,
    end_char: 3231,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Knots.",
    start_time: 242500,
    end_time: 242700,
    token_index: 574,
    start_char: 3232,
    end_char: 3237,
    display_word: "Knots",
    period_at_end: true
    },
    {
    word: "Wow",
    start_time: 242700,
    end_time: 243000,
    token_index: 575,
    start_char: 3239,
    end_char: 3242,
    display_word: "Wow",
    period_at_end: false
    },
    {
    word: "by",
    start_time: 243000,
    end_time: 243800,
    token_index: 576,
    start_char: 3243,
    end_char: 3245,
    display_word: "by",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 243800,
    end_time: 244100,
    token_index: 577,
    start_char: 3246,
    end_char: 3249,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 246200,
    end_time: 246300,
    token_index: 578,
    start_char: 3251,
    end_char: 3254,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "attack",
    start_time: 246300,
    end_time: 246600,
    token_index: 579,
    start_char: 3255,
    end_char: 3261,
    display_word: "attack",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 246600,
    end_time: 246800,
    token_index: 580,
    start_char: 3262,
    end_char: 3265,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "perpetrated",
    start_time: 246800,
    end_time: 247500,
    token_index: 581,
    start_char: 3266,
    end_char: 3277,
    display_word: "perpetrated",
    period_at_end: false
    },
    {
    word: "by",
    start_time: 247500,
    end_time: 247800,
    token_index: 582,
    start_char: 3278,
    end_char: 3280,
    display_word: "by",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 247800,
    end_time: 247900,
    token_index: 583,
    start_char: 3281,
    end_char: 3282,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "group",
    start_time: 247900,
    end_time: 248200,
    token_index: 584,
    start_char: 3283,
    end_char: 3288,
    display_word: "group",
    period_at_end: false
    },
    {
    word: "named",
    start_time: 248200,
    end_time: 248800,
    token_index: 585,
    start_char: 3289,
    end_char: 3294,
    display_word: "named",
    period_at_end: false
    },
    {
    word: "apt",
    start_time: 248900,
    end_time: 249400,
    token_index: 586,
    start_char: 3295,
    end_char: 3298,
    display_word: "apt",
    period_at_end: false
    },
    {
    word: "29",
    start_time: 249400,
    end_time: 249900,
    token_index: 587,
    start_char: 3299,
    end_char: 3301,
    display_word: "29",
    period_at_end: false
    },
    {
    word: "also",
    start_time: 249900,
    end_time: 250200,
    token_index: 588,
    start_char: 3302,
    end_char: 3306,
    display_word: "also",
    period_at_end: false
    },
    {
    word: "known",
    start_time: 250200,
    end_time: 250400,
    token_index: 589,
    start_char: 3307,
    end_char: 3312,
    display_word: "known",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 250400,
    end_time: 250600,
    token_index: 590,
    start_char: 3313,
    end_char: 3315,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "Cozy",
    start_time: 250600,
    end_time: 251000,
    token_index: 591,
    start_char: 3316,
    end_char: 3320,
    display_word: "Cozy",
    period_at_end: false
    },
    {
    word: "Bear",
    start_time: 251000,
    end_time: 251500,
    token_index: 592,
    start_char: 3321,
    end_char: 3325,
    display_word: "Bear",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 251800,
    end_time: 251900,
    token_index: 593,
    start_char: 3326,
    end_char: 3328,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 251900,
    end_time: 252100,
    token_index: 594,
    start_char: 3329,
    end_char: 3334,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 252100,
    end_time: 252300,
    token_index: 595,
    start_char: 3335,
    end_char: 3338,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "Alias",
    start_time: 252300,
    end_time: 252900,
    token_index: 596,
    start_char: 3339,
    end_char: 3344,
    display_word: "Alias",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 253200,
    end_time: 253300,
    token_index: 597,
    start_char: 3345,
    end_char: 3348,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Dukes.",
    start_time: 253300,
    end_time: 254000,
    token_index: 598,
    start_char: 3349,
    end_char: 3354,
    display_word: "Dukes",
    period_at_end: true
    },
    {
    word: "Oh,",
    start_time: 254000,
    end_time: 254200,
    token_index: 599,
    start_char: 3356,
    end_char: 3359,
    display_word: "Oh,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 254200,
    end_time: 254200,
    token_index: 600,
    start_char: 3360,
    end_char: 3361,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "knew",
    start_time: 254200,
    end_time: 254500,
    token_index: 601,
    start_char: 3362,
    end_char: 3366,
    display_word: "knew",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 254500,
    end_time: 254600,
    token_index: 602,
    start_char: 3367,
    end_char: 3369,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "could",
    start_time: 254600,
    end_time: 254800,
    token_index: 603,
    start_char: 3370,
    end_char: 3375,
    display_word: "could",
    period_at_end: false
    },
    {
    word: "trust",
    start_time: 254800,
    end_time: 255200,
    token_index: 604,
    start_char: 3376,
    end_char: 3381,
    display_word: "trust",
    period_at_end: false
    },
    {
    word: "those",
    start_time: 255200,
    end_time: 255400,
    token_index: 605,
    start_char: 3382,
    end_char: 3387,
    display_word: "those",
    period_at_end: false
    },
    {
    word: "boys",
    start_time: 255400,
    end_time: 255700,
    token_index: 606,
    start_char: 3388,
    end_char: 3392,
    display_word: "boys",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 255700,
    end_time: 255800,
    token_index: 607,
    start_char: 3393,
    end_char: 3396,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 255800,
    end_time: 255900,
    token_index: 608,
    start_char: 3397,
    end_char: 3402,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "Confederate",
    start_time: 255900,
    end_time: 256600,
    token_index: 609,
    start_char: 3403,
    end_char: 3414,
    display_word: "Confederate",
    period_at_end: false
    },
    {
    word: "car.",
    start_time: 256600,
    end_time: 257200,
    token_index: 610,
    start_char: 3415,
    end_char: 3418,
    display_word: "car",
    period_at_end: true
    },
    {
    word: "Looks",
    start_time: 262500,
    end_time: 262800,
    token_index: 611,
    start_char: 3420,
    end_char: 3425,
    display_word: "Looks",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 262800,
    end_time: 263100,
    token_index: 612,
    start_char: 3426,
    end_char: 3430,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "them",
    start_time: 263100,
    end_time: 263400,
    token_index: 613,
    start_char: 3431,
    end_char: 3435,
    display_word: "them",
    period_at_end: false
    },
    {
    word: "Duke",
    start_time: 263400,
    end_time: 263700,
    token_index: 614,
    start_char: 3436,
    end_char: 3440,
    display_word: "Duke",
    period_at_end: false
    },
    {
    word: "boys",
    start_time: 263700,
    end_time: 264200,
    token_index: 615,
    start_char: 3441,
    end_char: 3445,
    display_word: "boys",
    period_at_end: false
    },
    {
    word: "disrupt",
    start_time: 264200,
    end_time: 264800,
    token_index: 616,
    start_char: 3446,
    end_char: 3453,
    display_word: "disrupt",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 264800,
    end_time: 264900,
    token_index: 617,
    start_char: 3454,
    end_char: 3457,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "democracy",
    start_time: 264900,
    end_time: 265800,
    token_index: 618,
    start_char: 3458,
    end_char: 3467,
    display_word: "democracy",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 265800,
    end_time: 266100,
    token_index: 619,
    start_char: 3468,
    end_char: 3472,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "Rootin",
    start_time: 266100,
    end_time: 266600,
    token_index: 620,
    start_char: 3473,
    end_char: 3479,
    display_word: "Rootin",
    period_at_end: false
    },
    {
    word: "tootin",
    start_time: 266600,
    end_time: 267000,
    token_index: 621,
    start_char: 3480,
    end_char: 3486,
    display_word: "tootin",
    period_at_end: false
    },
    {
    word: "Putin.",
    start_time: 267000,
    end_time: 267700,
    token_index: 622,
    start_char: 3487,
    end_char: 3492,
    display_word: "Putin",
    period_at_end: true
    },
    {
    word: "This",
    start_time: 269300,
    end_time: 269500,
    token_index: 623,
    start_char: 3494,
    end_char: 3498,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "isn't",
    start_time: 269500,
    end_time: 269700,
    token_index: 624,
    start_char: 3499,
    end_char: 3504,
    display_word: "isn't",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 269700,
    end_time: 269800,
    token_index: 625,
    start_char: 3505,
    end_char: 3508,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "first",
    start_time: 269800,
    end_time: 270100,
    token_index: 626,
    start_char: 3509,
    end_char: 3514,
    display_word: "first",
    period_at_end: false
    },
    {
    word: "time",
    start_time: 270100,
    end_time: 270300,
    token_index: 627,
    start_char: 3515,
    end_char: 3519,
    display_word: "time",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 270300,
    end_time: 270400,
    token_index: 628,
    start_char: 3520,
    end_char: 3523,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Dukes",
    start_time: 270400,
    end_time: 270900,
    token_index: 629,
    start_char: 3524,
    end_char: 3529,
    display_word: "Dukes",
    period_at_end: false
    },
    {
    word: "AKA",
    start_time: 270900,
    end_time: 271600,
    token_index: 630,
    start_char: 3530,
    end_char: 3533,
    display_word: "AKA",
    period_at_end: false
    },
    {
    word: "Cozy",
    start_time: 271800,
    end_time: 272200,
    token_index: 631,
    start_char: 3534,
    end_char: 3538,
    display_word: "Cozy",
    period_at_end: false
    },
    {
    word: "Bear",
    start_time: 272200,
    end_time: 272600,
    token_index: 632,
    start_char: 3539,
    end_char: 3543,
    display_word: "Bear",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 273000,
    end_time: 273200,
    token_index: 633,
    start_char: 3544,
    end_char: 3548,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "attacked",
    start_time: 273200,
    end_time: 273600,
    token_index: 634,
    start_char: 3549,
    end_char: 3557,
    display_word: "attacked",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 273600,
    end_time: 273700,
    token_index: 635,
    start_char: 3558,
    end_char: 3561,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 273700,
    end_time: 274000,
    token_index: 636,
    start_char: 3562,
    end_char: 3568,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "States",
    start_time: 274000,
    end_time: 274600,
    token_index: 637,
    start_char: 3569,
    end_char: 3575,
    display_word: "States",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 274700,
    end_time: 275000,
    token_index: 638,
    start_char: 3576,
    end_char: 3583,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "Cozy",
    start_time: 275000,
    end_time: 275400,
    token_index: 639,
    start_char: 3584,
    end_char: 3588,
    display_word: "Cozy",
    period_at_end: false
    },
    {
    word: "Bear",
    start_time: 275400,
    end_time: 275700,
    token_index: 640,
    start_char: 3589,
    end_char: 3593,
    display_word: "Bear",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 275700,
    end_time: 275900,
    token_index: 641,
    start_char: 3594,
    end_char: 3597,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "Of",
    start_time: 276000,
    end_time: 276100,
    token_index: 642,
    start_char: 3598,
    end_char: 3600,
    display_word: "Of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 276100,
    end_time: 276200,
    token_index: 643,
    start_char: 3601,
    end_char: 3604,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "groups",
    start_time: 276200,
    end_time: 276600,
    token_index: 644,
    start_char: 3605,
    end_char: 3611,
    display_word: "groups",
    period_at_end: false
    },
    {
    word: "accused",
    start_time: 276600,
    end_time: 277100,
    token_index: 645,
    start_char: 3612,
    end_char: 3619,
    display_word: "accused",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 277100,
    end_time: 277300,
    token_index: 646,
    start_char: 3620,
    end_char: 3622,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "breaking",
    start_time: 277300,
    end_time: 277700,
    token_index: 647,
    start_char: 3623,
    end_char: 3631,
    display_word: "breaking",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 277700,
    end_time: 278000,
    token_index: 648,
    start_char: 3632,
    end_char: 3636,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 278000,
    end_time: 278100,
    token_index: 649,
    start_char: 3637,
    end_char: 3640,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "DNC",
    start_time: 278100,
    end_time: 278700,
    token_index: 650,
    start_char: 3641,
    end_char: 3644,
    display_word: "DNC",
    period_at_end: false
    },
    {
    word: "Network",
    start_time: 278700,
    end_time: 279400,
    token_index: 651,
    start_char: 3645,
    end_char: 3652,
    display_word: "Network",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 279600,
    end_time: 279700,
    token_index: 652,
    start_char: 3653,
    end_char: 3656,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "stealing",
    start_time: 279700,
    end_time: 280200,
    token_index: 653,
    start_char: 3657,
    end_char: 3665,
    display_word: "stealing",
    period_at_end: false
    },
    {
    word: "emails",
    start_time: 280200,
    end_time: 280800,
    token_index: 654,
    start_char: 3666,
    end_char: 3672,
    display_word: "emails",
    period_at_end: false
    },
    {
    word: "ahead",
    start_time: 280800,
    end_time: 281000,
    token_index: 655,
    start_char: 3673,
    end_char: 3678,
    display_word: "ahead",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 281000,
    end_time: 281100,
    token_index: 656,
    start_char: 3679,
    end_char: 3681,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 281100,
    end_time: 281200,
    token_index: 657,
    start_char: 3682,
    end_char: 3685,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "2016",
    start_time: 281200,
    end_time: 281900,
    token_index: 658,
    start_char: 3686,
    end_char: 3690,
    display_word: "2016",
    period_at_end: false
    },
    {
    word: "presidential",
    start_time: 281900,
    end_time: 282600,
    token_index: 659,
    start_char: 3691,
    end_char: 3703,
    display_word: "presidential",
    period_at_end: false
    },
    {
    word: "election.",
    start_time: 282600,
    end_time: 283200,
    token_index: 660,
    start_char: 3704,
    end_char: 3712,
    display_word: "election",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 283700,
    end_time: 283900,
    token_index: 661,
    start_char: 3714,
    end_char: 3716,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 283900,
    end_time: 284000,
    token_index: 662,
    start_char: 3717,
    end_char: 3722,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 284000,
    end_time: 284200,
    token_index: 663,
    start_char: 3723,
    end_char: 3725,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "surprised",
    start_time: 284200,
    end_time: 284700,
    token_index: 664,
    start_char: 3726,
    end_char: 3735,
    display_word: "surprised",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 284700,
    end_time: 284800,
    token_index: 665,
    start_char: 3736,
    end_char: 3738,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "Election",
    start_time: 284800,
    end_time: 285200,
    token_index: 666,
    start_char: 3739,
    end_char: 3747,
    display_word: "Election",
    period_at_end: false
    },
    {
    word: "night",
    start_time: 285200,
    end_time: 285500,
    token_index: 667,
    start_char: 3748,
    end_char: 3753,
    display_word: "night",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 285500,
    end_time: 285600,
    token_index: 668,
    start_char: 3754,
    end_char: 3758,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 285600,
    end_time: 285800,
    token_index: 669,
    start_char: 3759,
    end_char: 3763,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "announced",
    start_time: 285800,
    end_time: 286200,
    token_index: 670,
    start_char: 3764,
    end_char: 3773,
    display_word: "announced",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 286200,
    end_time: 286300,
    token_index: 671,
    start_char: 3774,
    end_char: 3778,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 286300,
    end_time: 286400,
    token_index: 672,
    start_char: 3779,
    end_char: 3782,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "next",
    start_time: 286400,
    end_time: 286800,
    token_index: 673,
    start_char: 3783,
    end_char: 3787,
    display_word: "next",
    period_at_end: false
    },
    {
    word: "president",
    start_time: 286800,
    end_time: 287300,
    token_index: 674,
    start_char: 3788,
    end_char: 3797,
    display_word: "president",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 287300,
    end_time: 287500,
    token_index: 675,
    start_char: 3798,
    end_char: 3800,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 287500,
    end_time: 287500,
    token_index: 676,
    start_char: 3801,
    end_char: 3804,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 287500,
    end_time: 287900,
    token_index: 677,
    start_char: 3805,
    end_char: 3811,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "States",
    start_time: 287900,
    end_time: 288500,
    token_index: 678,
    start_char: 3812,
    end_char: 3818,
    display_word: "States",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 288800,
    end_time: 289000,
    token_index: 679,
    start_char: 3819,
    end_char: 3821,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "Senator",
    start_time: 289000,
    end_time: 289600,
    token_index: 680,
    start_char: 3822,
    end_char: 3829,
    display_word: "Senator",
    period_at_end: false
    },
    {
    word: "Corrine",
    start_time: 289600,
    end_time: 290400,
    token_index: 681,
    start_char: 3830,
    end_char: 3837,
    display_word: "Corrine",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 290400,
    end_time: 290700,
    token_index: 682,
    start_char: 3838,
    end_char: 3839,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "virus,",
    start_time: 290700,
    end_time: 291600,
    token_index: 683,
    start_char: 3840,
    end_char: 3846,
    display_word: "virus,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 292300,
    end_time: 292500,
    token_index: 684,
    start_char: 3847,
    end_char: 3850,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 292500,
    end_time: 292600,
    token_index: 685,
    start_char: 3851,
    end_char: 3854,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "once",
    start_time: 292600,
    end_time: 292900,
    token_index: 686,
    start_char: 3855,
    end_char: 3859,
    display_word: "once",
    period_at_end: false
    },
    {
    word: "Russia",
    start_time: 292900,
    end_time: 293200,
    token_index: 687,
    start_char: 3860,
    end_char: 3866,
    display_word: "Russia",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 293200,
    end_time: 293300,
    token_index: 688,
    start_char: 3867,
    end_char: 3869,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 293300,
    end_time: 293400,
    token_index: 689,
    start_char: 3870,
    end_char: 3873,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "second",
    start_time: 293400,
    end_time: 293800,
    token_index: 690,
    start_char: 3874,
    end_char: 3880,
    display_word: "second",
    period_at_end: false
    },
    {
    word: "biggest",
    start_time: 293800,
    end_time: 294100,
    token_index: 691,
    start_char: 3881,
    end_char: 3888,
    display_word: "biggest",
    period_at_end: false
    },
    {
    word: "hacking",
    start_time: 294100,
    end_time: 294400,
    token_index: 692,
    start_char: 3889,
    end_char: 3896,
    display_word: "hacking",
    period_at_end: false
    },
    {
    word: "story",
    start_time: 294400,
    end_time: 294700,
    token_index: 693,
    start_char: 3897,
    end_char: 3902,
    display_word: "story",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 294700,
    end_time: 294900,
    token_index: 694,
    start_char: 3903,
    end_char: 3905,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 294900,
    end_time: 295000,
    token_index: 695,
    start_char: 3906,
    end_char: 3909,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "day",
    start_time: 295000,
    end_time: 295200,
    token_index: 696,
    start_char: 3910,
    end_char: 3913,
    display_word: "day",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 295200,
    end_time: 295600,
    token_index: 697,
    start_char: 3914,
    end_char: 3921,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "yesterday",
    start_time: 295600,
    end_time: 296300,
    token_index: 698,
    start_char: 3922,
    end_char: 3931,
    display_word: "yesterday",
    period_at_end: false
    },
    {
    word: "major",
    start_time: 296600,
    end_time: 297100,
    token_index: 699,
    start_char: 3932,
    end_char: 3937,
    display_word: "major",
    period_at_end: false
    },
    {
    word: "Twitter",
    start_time: 297100,
    end_time: 297500,
    token_index: 700,
    start_char: 3938,
    end_char: 3945,
    display_word: "Twitter",
    period_at_end: false
    },
    {
    word: "accounts",
    start_time: 297500,
    end_time: 298000,
    token_index: 701,
    start_char: 3946,
    end_char: 3954,
    display_word: "accounts",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 298000,
    end_time: 298100,
    token_index: 702,
    start_char: 3955,
    end_char: 3959,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "hacked",
    start_time: 298100,
    end_time: 298600,
    token_index: 703,
    start_char: 3960,
    end_char: 3966,
    display_word: "hacked",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 298600,
    end_time: 298800,
    token_index: 704,
    start_char: 3967,
    end_char: 3969,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 298800,
    end_time: 298800,
    token_index: 705,
    start_char: 3970,
    end_char: 3971,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "Bitcoin",
    start_time: 298800,
    end_time: 299500,
    token_index: 706,
    start_char: 3972,
    end_char: 3979,
    display_word: "Bitcoin",
    period_at_end: false
    },
    {
    word: "scam.",
    start_time: 299500,
    end_time: 300200,
    token_index: 707,
    start_char: 3980,
    end_char: 3984,
    display_word: "scam",
    period_at_end: true
    },
    {
    word: "Now,",
    start_time: 300600,
    end_time: 300700,
    token_index: 708,
    start_char: 3986,
    end_char: 3990,
    display_word: "Now,",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 300700,
    end_time: 300800,
    token_index: 709,
    start_char: 3991,
    end_char: 3993,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "you're",
    start_time: 300800,
    end_time: 300900,
    token_index: 710,
    start_char: 3994,
    end_char: 4000,
    display_word: "you're",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 300900,
    end_time: 301000,
    token_index: 711,
    start_char: 4001,
    end_char: 4004,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "familiar",
    start_time: 301000,
    end_time: 301700,
    token_index: 712,
    start_char: 4005,
    end_char: 4013,
    display_word: "familiar",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 301900,
    end_time: 302000,
    token_index: 713,
    start_char: 4014,
    end_char: 4015,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "Bitcoin",
    start_time: 302000,
    end_time: 302700,
    token_index: 714,
    start_char: 4016,
    end_char: 4023,
    display_word: "Bitcoin",
    period_at_end: false
    },
    {
    word: "scam",
    start_time: 302700,
    end_time: 303500,
    token_index: 715,
    start_char: 4024,
    end_char: 4028,
    display_word: "scam",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 303600,
    end_time: 303800,
    token_index: 716,
    start_char: 4029,
    end_char: 4031,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "anything",
    start_time: 303800,
    end_time: 304200,
    token_index: 717,
    start_char: 4032,
    end_char: 4040,
    display_word: "anything",
    period_at_end: false
    },
    {
    word: "involving",
    start_time: 304200,
    end_time: 304800,
    token_index: 718,
    start_char: 4041,
    end_char: 4050,
    display_word: "involving",
    period_at_end: false
    },
    {
    word: "Bitcoin?",
    start_time: 304800,
    end_time: 305600,
    token_index: 719,
    start_char: 4051,
    end_char: 4059,
    display_word: "Bitcoin?",
    period_at_end: false
    },
    {
    word: "The",
    start_time: 306400,
    end_time: 306600,
    token_index: 720,
    start_char: 4060,
    end_char: 4063,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "hack",
    start_time: 306600,
    end_time: 307000,
    token_index: 721,
    start_char: 4064,
    end_char: 4068,
    display_word: "hack",
    period_at_end: false
    },
    {
    word: "targeted",
    start_time: 307000,
    end_time: 307400,
    token_index: 722,
    start_char: 4069,
    end_char: 4077,
    display_word: "targeted",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 307400,
    end_time: 307500,
    token_index: 723,
    start_char: 4078,
    end_char: 4081,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "accounts",
    start_time: 307500,
    end_time: 307900,
    token_index: 724,
    start_char: 4082,
    end_char: 4090,
    display_word: "accounts",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 307900,
    end_time: 308000,
    token_index: 725,
    start_char: 4091,
    end_char: 4093,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 308000,
    end_time: 308200,
    token_index: 726,
    start_char: 4094,
    end_char: 4100,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 308200,
    end_time: 308400,
    token_index: 727,
    start_char: 4101,
    end_char: 4105,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "Barack",
    start_time: 308400,
    end_time: 308600,
    token_index: 728,
    start_char: 4106,
    end_char: 4112,
    display_word: "Barack",
    period_at_end: false
    },
    {
    word: "Obama",
    start_time: 308600,
    end_time: 309300,
    token_index: 729,
    start_char: 4113,
    end_char: 4118,
    display_word: "Obama",
    period_at_end: false
    },
    {
    word: "Joe",
    start_time: 309300,
    end_time: 309600,
    token_index: 730,
    start_char: 4119,
    end_char: 4122,
    display_word: "Joe",
    period_at_end: false
    },
    {
    word: "Biden",
    start_time: 309600,
    end_time: 310200,
    token_index: 731,
    start_char: 4123,
    end_char: 4128,
    display_word: "Biden",
    period_at_end: false
    },
    {
    word: "Mike",
    start_time: 310200,
    end_time: 310500,
    token_index: 732,
    start_char: 4129,
    end_char: 4133,
    display_word: "Mike",
    period_at_end: false
    },
    {
    word: "Bloomberg",
    start_time: 310500,
    end_time: 310900,
    token_index: 733,
    start_char: 4134,
    end_char: 4143,
    display_word: "Bloomberg",
    period_at_end: false
    },
    {
    word: "Elan",
    start_time: 310900,
    end_time: 311500,
    token_index: 734,
    start_char: 4144,
    end_char: 4148,
    display_word: "Elan",
    period_at_end: false
    },
    {
    word: "musk",
    start_time: 311500,
    end_time: 311700,
    token_index: 735,
    start_char: 4149,
    end_char: 4153,
    display_word: "musk",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 311700,
    end_time: 312400,
    token_index: 736,
    start_char: 4154,
    end_char: 4157,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Kanye",
    start_time: 312400,
    end_time: 312900,
    token_index: 737,
    start_char: 4158,
    end_char: 4163,
    display_word: "Kanye",
    period_at_end: false
    },
    {
    word: "West",
    start_time: 312900,
    end_time: 312900,
    token_index: 738,
    start_char: 4164,
    end_char: 4168,
    display_word: "West",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 312900,
    end_time: 313400,
    token_index: 739,
    start_char: 4169,
    end_char: 4173,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "messages.",
    start_time: 313400,
    end_time: 313800,
    token_index: 740,
    start_char: 4174,
    end_char: 4182,
    display_word: "messages",
    period_at_end: true
    },
    {
    word: "Like",
    start_time: 313800,
    end_time: 313900,
    token_index: 741,
    start_char: 4184,
    end_char: 4188,
    display_word: "Like",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 313900,
    end_time: 314700,
    token_index: 742,
    start_char: 4189,
    end_char: 4192,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "giving",
    start_time: 314700,
    end_time: 315000,
    token_index: 743,
    start_char: 4193,
    end_char: 4199,
    display_word: "giving",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 315000,
    end_time: 315100,
    token_index: 744,
    start_char: 4200,
    end_char: 4204,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 315100,
    end_time: 315400,
    token_index: 745,
    start_char: 4205,
    end_char: 4207,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 315400,
    end_time: 315600,
    token_index: 746,
    start_char: 4208,
    end_char: 4211,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "community",
    start_time: 315600,
    end_time: 316000,
    token_index: 747,
    start_char: 4212,
    end_char: 4221,
    display_word: "community",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 316000,
    end_time: 316200,
    token_index: 748,
    start_char: 4222,
    end_char: 4224,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 316200,
    end_time: 316300,
    token_index: 749,
    start_char: 4225,
    end_char: 4227,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "covid-19.",
    start_time: 316300,
    end_time: 316700,
    token_index: 750,
    start_char: 4228,
    end_char: 4236,
    display_word: "covid-19",
    period_at_end: true
    },
    {
    word: "All",
    start_time: 316700,
    end_time: 317600,
    token_index: 751,
    start_char: 4238,
    end_char: 4241,
    display_word: "All",
    period_at_end: false
    },
    {
    word: "Bitcoin",
    start_time: 317600,
    end_time: 318100,
    token_index: 752,
    start_char: 4242,
    end_char: 4249,
    display_word: "Bitcoin",
    period_at_end: false
    },
    {
    word: "sent",
    start_time: 318100,
    end_time: 318300,
    token_index: 753,
    start_char: 4250,
    end_char: 4254,
    display_word: "sent",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 318300,
    end_time: 318400,
    token_index: 754,
    start_char: 4255,
    end_char: 4257,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 318400,
    end_time: 318500,
    token_index: 755,
    start_char: 4258,
    end_char: 4260,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "address",
    start_time: 318500,
    end_time: 318700,
    token_index: 756,
    start_char: 4261,
    end_char: 4268,
    display_word: "address",
    period_at_end: false
    },
    {
    word: "below",
    start_time: 318700,
    end_time: 319100,
    token_index: 757,
    start_char: 4269,
    end_char: 4274,
    display_word: "below",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 319100,
    end_time: 319400,
    token_index: 758,
    start_char: 4275,
    end_char: 4279,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 319400,
    end_time: 319400,
    token_index: 759,
    start_char: 4280,
    end_char: 4282,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "sent",
    start_time: 319400,
    end_time: 319700,
    token_index: 760,
    start_char: 4283,
    end_char: 4287,
    display_word: "sent",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 319700,
    end_time: 319800,
    token_index: 761,
    start_char: 4288,
    end_char: 4292,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "doubled",
    start_time: 319800,
    end_time: 320400,
    token_index: 762,
    start_char: 4293,
    end_char: 4300,
    display_word: "doubled",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 320400,
    end_time: 320500,
    token_index: 763,
    start_char: 4301,
    end_char: 4303,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 320500,
    end_time: 320600,
    token_index: 764,
    start_char: 4304,
    end_char: 4307,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "spend",
    start_time: 320600,
    end_time: 320800,
    token_index: 765,
    start_char: 4308,
    end_char: 4313,
    display_word: "spend",
    period_at_end: false
    },
    {
    word: "$1000",
    start_time: 320800,
    end_time: 321600,
    token_index: 766,
    start_char: 4314,
    end_char: 4319,
    display_word: "$1000",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 321600,
    end_time: 321800,
    token_index: 767,
    start_char: 4320,
    end_char: 4321,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 321800,
    end_time: 322000,
    token_index: 768,
    start_char: 4322,
    end_char: 4326,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "send",
    start_time: 322000,
    end_time: 322200,
    token_index: 769,
    start_char: 4327,
    end_char: 4331,
    display_word: "send",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 322200,
    end_time: 322300,
    token_index: 770,
    start_char: 4332,
    end_char: 4336,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "two",
    start_time: 322300,
    end_time: 322800,
    token_index: 771,
    start_char: 4337,
    end_char: 4340,
    display_word: "two",
    period_at_end: false
    },
    {
    word: "thousand",
    start_time: 322800,
    end_time: 323200,
    token_index: 772,
    start_char: 4341,
    end_char: 4349,
    display_word: "thousand",
    period_at_end: false
    },
    {
    word: "dollars",
    start_time: 323200,
    end_time: 323700,
    token_index: 773,
    start_char: 4350,
    end_char: 4357,
    display_word: "dollars",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 323700,
    end_time: 324900,
    token_index: 774,
    start_char: 4358,
    end_char: 4360,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "Obama",
    start_time: 324900,
    end_time: 325200,
    token_index: 775,
    start_char: 4361,
    end_char: 4366,
    display_word: "Obama",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 325200,
    end_time: 325300,
    token_index: 776,
    start_char: 4367,
    end_char: 4370,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Biden",
    start_time: 325300,
    end_time: 325500,
    token_index: 777,
    start_char: 4371,
    end_char: 4376,
    display_word: "Biden",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 325500,
    end_time: 325800,
    token_index: 778,
    start_char: 4377,
    end_char: 4381,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "promising",
    start_time: 325800,
    end_time: 326200,
    token_index: 779,
    start_char: 4382,
    end_char: 4391,
    display_word: "promising",
    period_at_end: false
    },
    {
    word: "free",
    start_time: 326200,
    end_time: 326400,
    token_index: 780,
    start_char: 4392,
    end_char: 4396,
    display_word: "free",
    period_at_end: false
    },
    {
    word: "money.",
    start_time: 326400,
    end_time: 326700,
    token_index: 781,
    start_char: 4397,
    end_char: 4402,
    display_word: "money",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 326700,
    end_time: 327200,
    token_index: 782,
    start_char: 4404,
    end_char: 4406,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 327200,
    end_time: 327300,
    token_index: 783,
    start_char: 4407,
    end_char: 4412,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 327300,
    end_time: 327400,
    token_index: 784,
    start_char: 4413,
    end_char: 4417,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 327400,
    end_time: 327500,
    token_index: 785,
    start_char: 4418,
    end_char: 4422,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 327500,
    end_time: 327600,
    token_index: 786,
    start_char: 4423,
    end_char: 4426,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "biggest",
    start_time: 327600,
    end_time: 327900,
    token_index: 787,
    start_char: 4427,
    end_char: 4434,
    display_word: "biggest",
    period_at_end: false
    },
    {
    word: "political",
    start_time: 327900,
    end_time: 328100,
    token_index: 788,
    start_char: 4435,
    end_char: 4444,
    display_word: "political",
    period_at_end: false
    },
    {
    word: "scam",
    start_time: 328100,
    end_time: 328700,
    token_index: 789,
    start_char: 4445,
    end_char: 4449,
    display_word: "scam",
    period_at_end: false
    },
    {
    word: "since",
    start_time: 328700,
    end_time: 329100,
    token_index: 790,
    start_char: 4450,
    end_char: 4455,
    display_word: "since",
    period_at_end: false
    },
    {
    word: "Eisenhower",
    start_time: 329100,
    end_time: 329800,
    token_index: 791,
    start_char: 4456,
    end_char: 4466,
    display_word: "Eisenhower",
    period_at_end: false
    },
    {
    word: "roped",
    start_time: 329800,
    end_time: 330200,
    token_index: 792,
    start_char: 4467,
    end_char: 4472,
    display_word: "roped",
    period_at_end: false
    },
    {
    word: "Canada",
    start_time: 330200,
    end_time: 330700,
    token_index: 793,
    start_char: 4473,
    end_char: 4479,
    display_word: "Canada",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 330700,
    end_time: 331000,
    token_index: 794,
    start_char: 4480,
    end_char: 4484,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "Amway.",
    start_time: 331000,
    end_time: 331600,
    token_index: 795,
    start_char: 4485,
    end_char: 4490,
    display_word: "Amway",
    period_at_end: true
    },
    {
    word: "To",
    start_time: 332700,
    end_time: 332900,
    token_index: 796,
    start_char: 4492,
    end_char: 4494,
    display_word: "To",
    period_at_end: false
    },
    {
    word: "prevent",
    start_time: 332900,
    end_time: 333300,
    token_index: 797,
    start_char: 4495,
    end_char: 4502,
    display_word: "prevent",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 333300,
    end_time: 333300,
    token_index: 798,
    start_char: 4503,
    end_char: 4506,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "breach",
    start_time: 333300,
    end_time: 333600,
    token_index: 799,
    start_char: 4507,
    end_char: 4513,
    display_word: "breach",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 333600,
    end_time: 333800,
    token_index: 800,
    start_char: 4514,
    end_char: 4518,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "spreading",
    start_time: 333800,
    end_time: 334400,
    token_index: 801,
    start_char: 4519,
    end_char: 4528,
    display_word: "spreading",
    period_at_end: false
    },
    {
    word: "Twitter",
    start_time: 334900,
    end_time: 335200,
    token_index: 802,
    start_char: 4529,
    end_char: 4536,
    display_word: "Twitter",
    period_at_end: false
    },
    {
    word: "disabled",
    start_time: 335200,
    end_time: 335800,
    token_index: 803,
    start_char: 4537,
    end_char: 4545,
    display_word: "disabled",
    period_at_end: false
    },
    {
    word: "verified",
    start_time: 335800,
    end_time: 336400,
    token_index: 804,
    start_char: 4546,
    end_char: 4554,
    display_word: "verified",
    period_at_end: false
    },
    {
    word: "Twitter",
    start_time: 336400,
    end_time: 336700,
    token_index: 805,
    start_char: 4555,
    end_char: 4562,
    display_word: "Twitter",
    period_at_end: false
    },
    {
    word: "users",
    start_time: 336700,
    end_time: 337000,
    token_index: 806,
    start_char: 4563,
    end_char: 4568,
    display_word: "users",
    period_at_end: false
    },
    {
    word: "ability",
    start_time: 337000,
    end_time: 337500,
    token_index: 807,
    start_char: 4569,
    end_char: 4576,
    display_word: "ability",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 337500,
    end_time: 337700,
    token_index: 808,
    start_char: 4577,
    end_char: 4579,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "tweet",
    start_time: 337700,
    end_time: 338500,
    token_index: 809,
    start_char: 4580,
    end_char: 4585,
    display_word: "tweet",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 338600,
    end_time: 338700,
    token_index: 810,
    start_char: 4586,
    end_char: 4589,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "modify",
    start_time: 338700,
    end_time: 339200,
    token_index: 811,
    start_char: 4590,
    end_char: 4596,
    display_word: "modify",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 339200,
    end_time: 339400,
    token_index: 812,
    start_char: 4597,
    end_char: 4602,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "accounts",
    start_time: 339400,
    end_time: 339800,
    token_index: 813,
    start_char: 4603,
    end_char: 4611,
    display_word: "accounts",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 339800,
    end_time: 340100,
    token_index: 814,
    start_char: 4612,
    end_char: 4615,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "hours",
    start_time: 340100,
    end_time: 340600,
    token_index: 815,
    start_char: 4616,
    end_char: 4621,
    display_word: "hours",
    period_at_end: false
    },
    {
    word: "Wednesday",
    start_time: 340600,
    end_time: 341000,
    token_index: 816,
    start_char: 4622,
    end_char: 4631,
    display_word: "Wednesday",
    period_at_end: false
    },
    {
    word: "evening.",
    start_time: 341000,
    end_time: 341600,
    token_index: 817,
    start_char: 4632,
    end_char: 4639,
    display_word: "evening",
    period_at_end: true
    },
    {
    word: "Luckily.",
    start_time: 341900,
    end_time: 342300,
    token_index: 818,
    start_char: 4641,
    end_char: 4648,
    display_word: "Luckily",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 342300,
    end_time: 342400,
    token_index: 819,
    start_char: 4650,
    end_char: 4651,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 342400,
    end_time: 342500,
    token_index: 820,
    start_char: 4652,
    end_char: 4655,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "able",
    start_time: 342500,
    end_time: 342700,
    token_index: 821,
    start_char: 4656,
    end_char: 4660,
    display_word: "able",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 342700,
    end_time: 342800,
    token_index: 822,
    start_char: 4661,
    end_char: 4663,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "keep",
    start_time: 342800,
    end_time: 343000,
    token_index: 823,
    start_char: 4664,
    end_char: 4668,
    display_word: "keep",
    period_at_end: false
    },
    {
    word: "tweeting",
    start_time: 343000,
    end_time: 343400,
    token_index: 824,
    start_char: 4669,
    end_char: 4677,
    display_word: "tweeting",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 343400,
    end_time: 343600,
    token_index: 825,
    start_char: 4678,
    end_char: 4680,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 343600,
    end_time: 343700,
    token_index: 826,
    start_char: 4681,
    end_char: 4683,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "secret",
    start_time: 343700,
    end_time: 344200,
    token_index: 827,
    start_char: 4684,
    end_char: 4690,
    display_word: "secret",
    period_at_end: false
    },
    {
    word: "second",
    start_time: 344200,
    end_time: 344600,
    token_index: 828,
    start_char: 4691,
    end_char: 4697,
    display_word: "second",
    period_at_end: false
    },
    {
    word: "account",
    start_time: 344600,
    end_time: 345200,
    token_index: 829,
    start_char: 4698,
    end_char: 4705,
    display_word: "account",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 345300,
    end_time: 345700,
    token_index: 830,
    start_char: 4706,
    end_char: 4708,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "Francois",
    start_time: 345700,
    end_time: 346600,
    token_index: 831,
    start_char: 4709,
    end_char: 4717,
    display_word: "Francois",
    period_at_end: false
    },
    {
    word: "perfecto.",
    start_time: 346600,
    end_time: 347600,
    token_index: 832,
    start_char: 4718,
    end_char: 4726,
    display_word: "perfecto",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 348700,
    end_time: 348800,
    token_index: 833,
    start_char: 4728,
    end_char: 4731,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "problem",
    start_time: 348800,
    end_time: 349100,
    token_index: 834,
    start_char: 4732,
    end_char: 4739,
    display_word: "problem",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 349100,
    end_time: 349500,
    token_index: 835,
    start_char: 4740,
    end_char: 4742,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "Twitter",
    start_time: 349900,
    end_time: 350200,
    token_index: 836,
    start_char: 4743,
    end_char: 4750,
    display_word: "Twitter",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 350200,
    end_time: 350500,
    token_index: 837,
    start_char: 4751,
    end_char: 4757,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "disable",
    start_time: 350500,
    end_time: 350900,
    token_index: 838,
    start_char: 4758,
    end_char: 4765,
    display_word: "disable",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 350900,
    end_time: 351200,
    token_index: 839,
    start_char: 4766,
    end_char: 4770,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "celebrity",
    start_time: 351200,
    end_time: 351700,
    token_index: 840,
    start_char: 4771,
    end_char: 4780,
    display_word: "celebrity",
    period_at_end: false
    },
    {
    word: "accounts",
    start_time: 351700,
    end_time: 352200,
    token_index: 841,
    start_char: 4781,
    end_char: 4789,
    display_word: "accounts",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 352200,
    end_time: 352300,
    token_index: 842,
    start_char: 4790,
    end_char: 4792,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 352300,
    end_time: 352400,
    token_index: 843,
    start_char: 4793,
    end_char: 4794,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "result.",
    start_time: 352400,
    end_time: 353100,
    token_index: 844,
    start_char: 4795,
    end_char: 4801,
    display_word: "result",
    period_at_end: true
    },
    {
    word: "When",
    start_time: 353200,
    end_time: 353400,
    token_index: 845,
    start_char: 4803,
    end_char: 4807,
    display_word: "When",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 353400,
    end_time: 353400,
    token_index: 846,
    start_char: 4808,
    end_char: 4811,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "National",
    start_time: 353400,
    end_time: 353800,
    token_index: 847,
    start_char: 4812,
    end_char: 4820,
    display_word: "National",
    period_at_end: false
    },
    {
    word: "Weather",
    start_time: 353800,
    end_time: 354100,
    token_index: 848,
    start_char: 4821,
    end_char: 4828,
    display_word: "Weather",
    period_at_end: false
    },
    {
    word: "Service",
    start_time: 354100,
    end_time: 354400,
    token_index: 849,
    start_char: 4829,
    end_char: 4836,
    display_word: "Service",
    period_at_end: false
    },
    {
    word: "declared",
    start_time: 354400,
    end_time: 354700,
    token_index: 850,
    start_char: 4837,
    end_char: 4845,
    display_word: "declared",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 354700,
    end_time: 354800,
    token_index: 851,
    start_char: 4846,
    end_char: 4847,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "tornado",
    start_time: 354800,
    end_time: 355300,
    token_index: 852,
    start_char: 4848,
    end_char: 4855,
    display_word: "tornado",
    period_at_end: false
    },
    {
    word: "watch",
    start_time: 355300,
    end_time: 355600,
    token_index: 853,
    start_char: 4856,
    end_char: 4861,
    display_word: "watch",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 355600,
    end_time: 355800,
    token_index: 854,
    start_char: 4862,
    end_char: 4865,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "parts",
    start_time: 355800,
    end_time: 356100,
    token_index: 855,
    start_char: 4866,
    end_char: 4871,
    display_word: "parts",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 356100,
    end_time: 356200,
    token_index: 856,
    start_char: 4872,
    end_char: 4874,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "Southern",
    start_time: 356200,
    end_time: 356600,
    token_index: 857,
    start_char: 4875,
    end_char: 4883,
    display_word: "Southern",
    period_at_end: false
    },
    {
    word: "Illinois.",
    start_time: 356600,
    end_time: 357400,
    token_index: 858,
    start_char: 4884,
    end_char: 4892,
    display_word: "Illinois",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 357600,
    end_time: 357900,
    token_index: 859,
    start_char: 4894,
    end_char: 4897,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "agencies",
    start_time: 357900,
    end_time: 358600,
    token_index: 860,
    start_char: 4898,
    end_char: 4906,
    display_word: "agencies",
    period_at_end: false
    },
    {
    word: "Regional",
    start_time: 358600,
    end_time: 359200,
    token_index: 861,
    start_char: 4907,
    end_char: 4915,
    display_word: "Regional",
    period_at_end: false
    },
    {
    word: "Twitter",
    start_time: 359200,
    end_time: 359500,
    token_index: 862,
    start_char: 4916,
    end_char: 4923,
    display_word: "Twitter",
    period_at_end: false
    },
    {
    word: "account",
    start_time: 359500,
    end_time: 359900,
    token_index: 863,
    start_char: 4924,
    end_char: 4931,
    display_word: "account",
    period_at_end: false
    },
    {
    word: "found",
    start_time: 359900,
    end_time: 360100,
    token_index: 864,
    start_char: 4932,
    end_char: 4937,
    display_word: "found",
    period_at_end: false
    },
    {
    word: "itself",
    start_time: 360100,
    end_time: 360500,
    token_index: 865,
    start_char: 4938,
    end_char: 4944,
    display_word: "itself",
    period_at_end: false
    },
    {
    word: "unable",
    start_time: 360500,
    end_time: 361100,
    token_index: 866,
    start_char: 4945,
    end_char: 4951,
    display_word: "unable",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 361100,
    end_time: 361300,
    token_index: 867,
    start_char: 4952,
    end_char: 4954,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "warn",
    start_time: 361300,
    end_time: 361700,
    token_index: 868,
    start_char: 4955,
    end_char: 4959,
    display_word: "warn",
    period_at_end: false
    },
    {
    word: "residents",
    start_time: 361700,
    end_time: 362200,
    token_index: 869,
    start_char: 4960,
    end_char: 4969,
    display_word: "residents",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 362200,
    end_time: 362500,
    token_index: 870,
    start_char: 4970,
    end_char: 4972,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 362500,
    end_time: 362600,
    token_index: 871,
    start_char: 4973,
    end_char: 4976,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "danger.",
    start_time: 362600,
    end_time: 363300,
    token_index: 872,
    start_char: 4977,
    end_char: 4983,
    display_word: "danger",
    period_at_end: true
    },
    {
    word: "That's",
    start_time: 363500,
    end_time: 363700,
    token_index: 873,
    start_char: 4985,
    end_char: 4991,
    display_word: "That's",
    period_at_end: false
    },
    {
    word: "why",
    start_time: 363700,
    end_time: 364000,
    token_index: 874,
    start_char: 4992,
    end_char: 4995,
    display_word: "why",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 364000,
    end_time: 364100,
    token_index: 875,
    start_char: 4996,
    end_char: 4997,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "follow",
    start_time: 364100,
    end_time: 364500,
    token_index: 876,
    start_char: 4998,
    end_char: 5004,
    display_word: "follow",
    period_at_end: false
    },
    {
    word: "only",
    start_time: 364500,
    end_time: 364900,
    token_index: 877,
    start_char: 5005,
    end_char: 5009,
    display_word: "only",
    period_at_end: false
    },
    {
    word: "unverified",
    start_time: 364900,
    end_time: 365900,
    token_index: 878,
    start_char: 5010,
    end_char: 5020,
    display_word: "unverified",
    period_at_end: false
    },
    {
    word: "tornado",
    start_time: 365900,
    end_time: 366300,
    token_index: 879,
    start_char: 5021,
    end_char: 5028,
    display_word: "tornado",
    period_at_end: false
    },
    {
    word: "accounts.",
    start_time: 366300,
    end_time: 367000,
    token_index: 880,
    start_char: 5029,
    end_char: 5037,
    display_word: "accounts",
    period_at_end: true
    },
    {
    word: "Little",
    start_time: 370800,
    end_time: 370900,
    token_index: 881,
    start_char: 5039,
    end_char: 5045,
    display_word: "Little",
    period_at_end: false
    },
    {
    word: "late",
    start_time: 370900,
    end_time: 371400,
    token_index: 882,
    start_char: 5046,
    end_char: 5050,
    display_word: "late",
    period_at_end: false
    },
    {
    word: "little",
    start_time: 372000,
    end_time: 372200,
    token_index: 883,
    start_char: 5051,
    end_char: 5057,
    display_word: "little",
    period_at_end: false
    },
    {
    word: "late",
    start_time: 372200,
    end_time: 372300,
    token_index: 884,
    start_char: 5058,
    end_char: 5062,
    display_word: "late",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 372300,
    end_time: 372400,
    token_index: 885,
    start_char: 5063,
    end_char: 5067,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 372400,
    end_time: 372600,
    token_index: 886,
    start_char: 5068,
    end_char: 5072,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "sound",
    start_time: 372600,
    end_time: 373000,
    token_index: 887,
    start_char: 5073,
    end_char: 5078,
    display_word: "sound",
    period_at_end: false
    },
    {
    word: "I'd",
    start_time: 373000,
    end_time: 373200,
    token_index: 888,
    start_char: 5079,
    end_char: 5082,
    display_word: "I'd",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 373200,
    end_time: 373400,
    token_index: 889,
    start_char: 5083,
    end_char: 5086,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "I've",
    start_time: 373400,
    end_time: 373500,
    token_index: 890,
    start_char: 5087,
    end_char: 5091,
    display_word: "I've",
    period_at_end: false
    },
    {
    word: "already",
    start_time: 373500,
    end_time: 373800,
    token_index: 891,
    start_char: 5092,
    end_char: 5099,
    display_word: "already",
    period_at_end: false
    },
    {
    word: "acted",
    start_time: 373800,
    end_time: 374100,
    token_index: 892,
    start_char: 5100,
    end_char: 5105,
    display_word: "acted",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 374100,
    end_time: 374300,
    token_index: 893,
    start_char: 5106,
    end_char: 5110,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "I've",
    start_time: 374300,
    end_time: 374400,
    token_index: 894,
    start_char: 5111,
    end_char: 5115,
    display_word: "I've",
    period_at_end: false
    },
    {
    word: "heard",
    start_time: 374400,
    end_time: 374700,
    token_index: 895,
    start_char: 5116,
    end_char: 5121,
    display_word: "heard",
    period_at_end: false
    },
    {
    word: "it.",
    start_time: 374700,
    end_time: 375300,
    token_index: 896,
    start_char: 5122,
    end_char: 5124,
    display_word: "it",
    period_at_end: true
    },
    {
    word: "Wow",
    start_time: 375700,
    end_time: 376200,
    token_index: 897,
    start_char: 5126,
    end_char: 5129,
    display_word: "Wow",
    period_at_end: false
    },
    {
    word: "house",
    start_time: 376200,
    end_time: 376500,
    token_index: 898,
    start_char: 5130,
    end_char: 5135,
    display_word: "house",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 376500,
    end_time: 376700,
    token_index: 899,
    start_char: 5136,
    end_char: 5140,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "fell",
    start_time: 376700,
    end_time: 376900,
    token_index: 900,
    start_char: 5141,
    end_char: 5145,
    display_word: "fell",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 376900,
    end_time: 377000,
    token_index: 901,
    start_char: 5146,
    end_char: 5148,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 377000,
    end_time: 377100,
    token_index: 902,
    start_char: 5149,
    end_char: 5150,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "witch",
    start_time: 377100,
    end_time: 377700,
    token_index: 903,
    start_char: 5151,
    end_char: 5156,
    display_word: "witch",
    period_at_end: false
    },
    {
    word: "her",
    start_time: 378700,
    end_time: 378900,
    token_index: 904,
    start_char: 5157,
    end_char: 5160,
    display_word: "her",
    period_at_end: false
    },
    {
    word: "sister",
    start_time: 378900,
    end_time: 379200,
    token_index: 905,
    start_char: 5161,
    end_char: 5167,
    display_word: "sister",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 379200,
    end_time: 379300,
    token_index: 906,
    start_char: 5168,
    end_char: 5170,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 379300,
    end_time: 379400,
    token_index: 907,
    start_char: 5171,
    end_char: 5176,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 379400,
    end_time: 379500,
    token_index: 908,
    start_char: 5177,
    end_char: 5179,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 379500,
    end_time: 379600,
    token_index: 909,
    start_char: 5180,
    end_char: 5182,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "pissed.",
    start_time: 379600,
    end_time: 380300,
    token_index: 910,
    start_char: 5183,
    end_char: 5189,
    display_word: "pissed",
    period_at_end: true
    },
    {
    word: "Got",
    start_time: 384100,
    end_time: 384300,
    token_index: 911,
    start_char: 5191,
    end_char: 5194,
    display_word: "Got",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 384300,
    end_time: 384500,
    token_index: 912,
    start_char: 5195,
    end_char: 5199,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "reference.",
    start_time: 384500,
    end_time: 385100,
    token_index: 913,
    start_char: 5200,
    end_char: 5209,
    display_word: "reference",
    period_at_end: true
    },
    {
    word: "They",
    start_time: 385500,
    end_time: 385600,
    token_index: 914,
    start_char: 5211,
    end_char: 5215,
    display_word: "They",
    period_at_end: false
    },
    {
    word: "should",
    start_time: 385600,
    end_time: 385800,
    token_index: 915,
    start_char: 5216,
    end_char: 5222,
    display_word: "should",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 385800,
    end_time: 386300,
    token_index: 916,
    start_char: 5223,
    end_char: 5226,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 386900,
    end_time: 387000,
    token_index: 917,
    start_char: 5227,
    end_char: 5229,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "still",
    start_time: 387000,
    end_time: 387200,
    token_index: 918,
    start_char: 5230,
    end_char: 5235,
    display_word: "still",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 387200,
    end_time: 387300,
    token_index: 919,
    start_char: 5236,
    end_char: 5241,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 387300,
    end_time: 387500,
    token_index: 920,
    start_char: 5242,
    end_char: 5246,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 387500,
    end_time: 387600,
    token_index: 921,
    start_char: 5247,
    end_char: 5250,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "identity",
    start_time: 387600,
    end_time: 388000,
    token_index: 922,
    start_char: 5251,
    end_char: 5259,
    display_word: "identity",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 388000,
    end_time: 388100,
    token_index: 923,
    start_char: 5260,
    end_char: 5262,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 388100,
    end_time: 388200,
    token_index: 924,
    start_char: 5263,
    end_char: 5266,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "hackers",
    start_time: 388200,
    end_time: 388800,
    token_index: 925,
    start_char: 5267,
    end_char: 5274,
    display_word: "hackers",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 388800,
    end_time: 388900,
    token_index: 926,
    start_char: 5275,
    end_char: 5278,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "experts",
    start_time: 388900,
    end_time: 389400,
    token_index: 927,
    start_char: 5279,
    end_char: 5286,
    display_word: "experts",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 389400,
    end_time: 389700,
    token_index: 928,
    start_char: 5287,
    end_char: 5290,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 389700,
    end_time: 390000,
    token_index: 929,
    start_char: 5291,
    end_char: 5295,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 390000,
    end_time: 390100,
    token_index: 930,
    start_char: 5296,
    end_char: 5299,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "fact",
    start_time: 390100,
    end_time: 390400,
    token_index: 931,
    start_char: 5300,
    end_char: 5304,
    display_word: "fact",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 390400,
    end_time: 390600,
    token_index: 932,
    start_char: 5305,
    end_char: 5309,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 390600,
    end_time: 390700,
    token_index: 933,
    start_char: 5310,
    end_char: 5314,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "attacked",
    start_time: 390700,
    end_time: 391200,
    token_index: 934,
    start_char: 5315,
    end_char: 5323,
    display_word: "attacked",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 391200,
    end_time: 391800,
    token_index: 935,
    start_char: 5324,
    end_char: 5327,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "instead",
    start_time: 392200,
    end_time: 392500,
    token_index: 936,
    start_char: 5328,
    end_char: 5335,
    display_word: "instead",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 392500,
    end_time: 392600,
    token_index: 937,
    start_char: 5336,
    end_char: 5338,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "waiting",
    start_time: 392600,
    end_time: 392900,
    token_index: 938,
    start_char: 5339,
    end_char: 5346,
    display_word: "waiting",
    period_at_end: false
    },
    {
    word: "until",
    start_time: 392900,
    end_time: 393100,
    token_index: 939,
    start_char: 5347,
    end_char: 5352,
    display_word: "until",
    period_at_end: false
    },
    {
    word: "closer",
    start_time: 393100,
    end_time: 393500,
    token_index: 940,
    start_char: 5353,
    end_char: 5359,
    display_word: "closer",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 393500,
    end_time: 393700,
    token_index: 941,
    start_char: 5360,
    end_char: 5362,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 393700,
    end_time: 393800,
    token_index: 942,
    start_char: 5363,
    end_char: 5366,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "election",
    start_time: 393800,
    end_time: 394400,
    token_index: 943,
    start_char: 5367,
    end_char: 5375,
    display_word: "election",
    period_at_end: false
    },
    {
    word: "suggest",
    start_time: 394500,
    end_time: 395000,
    token_index: 944,
    start_char: 5376,
    end_char: 5383,
    display_word: "suggest",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 395000,
    end_time: 395100,
    token_index: 945,
    start_char: 5384,
    end_char: 5388,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 395100,
    end_time: 395200,
    token_index: 946,
    start_char: 5389,
    end_char: 5393,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 395200,
    end_time: 395300,
    token_index: 947,
    start_char: 5394,
    end_char: 5397,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "criminals",
    start_time: 395300,
    end_time: 396100,
    token_index: 948,
    start_char: 5398,
    end_char: 5407,
    display_word: "criminals",
    period_at_end: false
    },
    {
    word: "rather",
    start_time: 396400,
    end_time: 396700,
    token_index: 949,
    start_char: 5408,
    end_char: 5414,
    display_word: "rather",
    period_at_end: false
    },
    {
    word: "than",
    start_time: 396700,
    end_time: 396900,
    token_index: 950,
    start_char: 5415,
    end_char: 5419,
    display_word: "than",
    period_at_end: false
    },
    {
    word: "government",
    start_time: 396900,
    end_time: 397500,
    token_index: 951,
    start_char: 5420,
    end_char: 5430,
    display_word: "government",
    period_at_end: false
    },
    {
    word: "operatives",
    start_time: 397500,
    end_time: 398300,
    token_index: 952,
    start_char: 5431,
    end_char: 5441,
    display_word: "operatives",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 398300,
    end_time: 398600,
    token_index: 953,
    start_char: 5442,
    end_char: 5446,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 398600,
    end_time: 398800,
    token_index: 954,
    start_char: 5447,
    end_char: 5451,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "makes",
    start_time: 398800,
    end_time: 399100,
    token_index: 955,
    start_char: 5452,
    end_char: 5457,
    display_word: "makes",
    period_at_end: false
    },
    {
    word: "no",
    start_time: 399100,
    end_time: 399200,
    token_index: 956,
    start_char: 5458,
    end_char: 5460,
    display_word: "no",
    period_at_end: false
    },
    {
    word: "sense.",
    start_time: 399200,
    end_time: 399900,
    token_index: 957,
    start_char: 5461,
    end_char: 5466,
    display_word: "sense",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 400100,
    end_time: 400200,
    token_index: 958,
    start_char: 5468,
    end_char: 5471,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 400200,
    end_time: 400400,
    token_index: 959,
    start_char: 5472,
    end_char: 5477,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 400400,
    end_time: 400700,
    token_index: 960,
    start_char: 5478,
    end_char: 5482,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "hack",
    start_time: 400700,
    end_time: 401000,
    token_index: 961,
    start_char: 5483,
    end_char: 5487,
    display_word: "hack",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 401000,
    end_time: 401100,
    token_index: 962,
    start_char: 5488,
    end_char: 5490,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "election",
    start_time: 401100,
    end_time: 401500,
    token_index: 963,
    start_char: 5491,
    end_char: 5499,
    display_word: "election",
    period_at_end: false
    },
    {
    word: "without",
    start_time: 401500,
    end_time: 401800,
    token_index: 964,
    start_char: 5500,
    end_char: 5507,
    display_word: "without",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 401800,
    end_time: 402000,
    token_index: 965,
    start_char: 5508,
    end_char: 5513,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 402000,
    end_time: 402000,
    token_index: 966,
    start_char: 5514,
    end_char: 5515,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "test",
    start_time: 402000,
    end_time: 402400,
    token_index: 967,
    start_char: 5516,
    end_char: 5520,
    display_word: "test",
    period_at_end: false
    },
    {
    word: "drive.",
    start_time: 402400,
    end_time: 403000,
    token_index: 968,
    start_char: 5521,
    end_char: 5526,
    display_word: "drive",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 403300,
    end_time: 403500,
    token_index: 969,
    start_char: 5528,
    end_char: 5532,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 403500,
    end_time: 403800,
    token_index: 970,
    start_char: 5533,
    end_char: 5537,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 403800,
    end_time: 403900,
    token_index: 971,
    start_char: 5538,
    end_char: 5541,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Fast",
    start_time: 403900,
    end_time: 404200,
    token_index: 972,
    start_char: 5542,
    end_char: 5546,
    display_word: "Fast",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 404200,
    end_time: 404300,
    token_index: 973,
    start_char: 5547,
    end_char: 5550,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Furious",
    start_time: 404300,
    end_time: 404700,
    token_index: 974,
    start_char: 5551,
    end_char: 5558,
    display_word: "Furious",
    period_at_end: false
    },
    {
    word: "movies.",
    start_time: 404700,
    end_time: 405100,
    token_index: 975,
    start_char: 5559,
    end_char: 5565,
    display_word: "movies",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 405100,
    end_time: 405100,
    token_index: 976,
    start_char: 5567,
    end_char: 5570,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 405100,
    end_time: 405400,
    token_index: 977,
    start_char: 5571,
    end_char: 5576,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "lead",
    start_time: 405400,
    end_time: 405900,
    token_index: 978,
    start_char: 5577,
    end_char: 5581,
    display_word: "lead",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 405900,
    end_time: 406100,
    token_index: 979,
    start_char: 5582,
    end_char: 5586,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "fleeing",
    start_time: 406100,
    end_time: 406500,
    token_index: 980,
    start_char: 5587,
    end_char: 5594,
    display_word: "fleeing",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 406500,
    end_time: 406500,
    token_index: 981,
    start_char: 5595,
    end_char: 5596,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "submarine",
    start_time: 406500,
    end_time: 407100,
    token_index: 982,
    start_char: 5597,
    end_char: 5606,
    display_word: "submarine",
    period_at_end: false
    },
    {
    word: "across",
    start_time: 407100,
    end_time: 407400,
    token_index: 983,
    start_char: 5607,
    end_char: 5613,
    display_word: "across",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 407400,
    end_time: 407500,
    token_index: 984,
    start_char: 5614,
    end_char: 5617,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Siberian",
    start_time: 407500,
    end_time: 408200,
    token_index: 985,
    start_char: 5618,
    end_char: 5626,
    display_word: "Siberian",
    period_at_end: false
    },
    {
    word: "ice",
    start_time: 408200,
    end_time: 408400,
    token_index: 986,
    start_char: 5627,
    end_char: 5630,
    display_word: "ice",
    period_at_end: false
    },
    {
    word: "floe.",
    start_time: 408400,
    end_time: 408700,
    token_index: 987,
    start_char: 5631,
    end_char: 5635,
    display_word: "floe",
    period_at_end: true
    },
    {
    word: "That's",
    start_time: 408700,
    end_time: 408900,
    token_index: 988,
    start_char: 5637,
    end_char: 5643,
    display_word: "That's",
    period_at_end: false
    },
    {
    word: "crazy.",
    start_time: 408900,
    end_time: 409600,
    token_index: 989,
    start_char: 5644,
    end_char: 5649,
    display_word: "crazy",
    period_at_end: true
    },
    {
    word: "Gotta",
    start_time: 409700,
    end_time: 409900,
    token_index: 990,
    start_char: 5651,
    end_char: 5656,
    display_word: "Gotta",
    period_at_end: false
    },
    {
    word: "start",
    start_time: 409900,
    end_time: 410200,
    token_index: 991,
    start_char: 5657,
    end_char: 5662,
    display_word: "start",
    period_at_end: false
    },
    {
    word: "small",
    start_time: 410200,
    end_time: 410500,
    token_index: 992,
    start_char: 5663,
    end_char: 5668,
    display_word: "small",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 410500,
    end_time: 410700,
    token_index: 993,
    start_char: 5669,
    end_char: 5673,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 410700,
    end_time: 410900,
    token_index: 994,
    start_char: 5674,
    end_char: 5678,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "car.",
    start_time: 410900,
    end_time: 411100,
    token_index: 995,
    start_char: 5679,
    end_char: 5682,
    display_word: "car",
    period_at_end: true
    },
    {
    word: "As",
    start_time: 411300,
    end_time: 411500,
    token_index: 996,
    start_char: 5684,
    end_char: 5686,
    display_word: "As",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 411600,
    end_time: 411800,
    token_index: 997,
    start_char: 5687,
    end_char: 5690,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "work",
    start_time: 411800,
    end_time: 412000,
    token_index: 998,
    start_char: 5691,
    end_char: 5695,
    display_word: "work",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 412000,
    end_time: 412100,
    token_index: 999,
    start_char: 5696,
    end_char: 5700,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "way",
    start_time: 412100,
    end_time: 412300,
    token_index: 1000,
    start_char: 5701,
    end_char: 5704,
    display_word: "way",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 412300,
    end_time: 412600,
    token_index: 1001,
    start_char: 5705,
    end_char: 5707,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 412600,
    end_time: 412700,
    token_index: 1002,
    start_char: 5708,
    end_char: 5710,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 412700,
    end_time: 412700,
    token_index: 1003,
    start_char: 5711,
    end_char: 5712,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "Tokyo",
    start_time: 412700,
    end_time: 413200,
    token_index: 1004,
    start_char: 5713,
    end_char: 5718,
    display_word: "Tokyo",
    period_at_end: false
    },
    {
    word: "Drift,",
    start_time: 413200,
    end_time: 413900,
    token_index: 1005,
    start_char: 5719,
    end_char: 5725,
    display_word: "Drift,",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 414000,
    end_time: 414500,
    token_index: 1006,
    start_char: 5726,
    end_char: 5730,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 414500,
    end_time: 414700,
    token_index: 1007,
    start_char: 5731,
    end_char: 5734,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 414700,
    end_time: 415000,
    token_index: 1008,
    start_char: 5735,
    end_char: 5739,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 415000,
    end_time: 415200,
    token_index: 1009,
    start_char: 5740,
    end_char: 5743,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "rock",
    start_time: 415200,
    end_time: 415600,
    token_index: 1010,
    start_char: 5744,
    end_char: 5748,
    display_word: "rock",
    period_at_end: false
    },
    {
    word: "redirector",
    start_time: 415600,
    end_time: 416200,
    token_index: 1011,
    start_char: 5749,
    end_char: 5759,
    display_word: "redirector",
    period_at_end: false
    },
    {
    word: "torpedo",
    start_time: 416200,
    end_time: 416700,
    token_index: 1012,
    start_char: 5760,
    end_char: 5767,
    display_word: "torpedo",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 416700,
    end_time: 416800,
    token_index: 1013,
    start_char: 5768,
    end_char: 5772,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 416800,
    end_time: 417000,
    token_index: 1014,
    start_char: 5773,
    end_char: 5776,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "bare",
    start_time: 417000,
    end_time: 417200,
    token_index: 1015,
    start_char: 5777,
    end_char: 5781,
    display_word: "bare",
    period_at_end: false
    },
    {
    word: "hands.",
    start_time: 417200,
    end_time: 417800,
    token_index: 1016,
    start_char: 5782,
    end_char: 5787,
    display_word: "hands",
    period_at_end: true
    },
    {
    word: "Otherwise,",
    start_time: 417900,
    end_time: 418400,
    token_index: 1017,
    start_char: 5789,
    end_char: 5799,
    display_word: "Otherwise,",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 418400,
    end_time: 418700,
    token_index: 1018,
    start_char: 5800,
    end_char: 5802,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 418700,
    end_time: 418800,
    token_index: 1019,
    start_char: 5803,
    end_char: 5807,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "seems",
    start_time: 418800,
    end_time: 419100,
    token_index: 1020,
    start_char: 5808,
    end_char: 5813,
    display_word: "seems",
    period_at_end: false
    },
    {
    word: "far-fetched.",
    start_time: 419100,
    end_time: 419800,
    token_index: 1021,
    start_char: 5814,
    end_char: 5825,
    display_word: "far-fetched",
    period_at_end: true
    },
    {
    word: "Twitter",
    start_time: 421300,
    end_time: 421600,
    token_index: 1022,
    start_char: 5827,
    end_char: 5834,
    display_word: "Twitter",
    period_at_end: false
    },
    {
    word: "CEO,",
    start_time: 421600,
    end_time: 422100,
    token_index: 1023,
    start_char: 5835,
    end_char: 5839,
    display_word: "CEO,",
    period_at_end: false
    },
    {
    word: "Jack",
    start_time: 422100,
    end_time: 422400,
    token_index: 1024,
    start_char: 5840,
    end_char: 5844,
    display_word: "Jack",
    period_at_end: false
    },
    {
    word: "Dorsey",
    start_time: 422400,
    end_time: 422800,
    token_index: 1025,
    start_char: 5845,
    end_char: 5851,
    display_word: "Dorsey",
    period_at_end: false
    },
    {
    word: "took",
    start_time: 422800,
    end_time: 423100,
    token_index: 1026,
    start_char: 5852,
    end_char: 5856,
    display_word: "took",
    period_at_end: false
    },
    {
    word: "two",
    start_time: 423100,
    end_time: 423700,
    token_index: 1027,
    start_char: 5857,
    end_char: 5860,
    display_word: "two",
    period_at_end: false
    },
    {
    word: "Well",
    start_time: 425000,
    end_time: 425200,
    token_index: 1028,
    start_char: 5861,
    end_char: 5865,
    display_word: "Well",
    period_at_end: false
    },
    {
    word: "Twitter",
    start_time: 425200,
    end_time: 425600,
    token_index: 1029,
    start_char: 5866,
    end_char: 5873,
    display_word: "Twitter",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 426300,
    end_time: 426400,
    token_index: 1030,
    start_char: 5874,
    end_char: 5876,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "express",
    start_time: 426400,
    end_time: 426800,
    token_index: 1031,
    start_char: 5877,
    end_char: 5884,
    display_word: "express",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 426800,
    end_time: 427000,
    token_index: 1032,
    start_char: 5885,
    end_char: 5888,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "discontent",
    start_time: 427000,
    end_time: 427700,
    token_index: 1033,
    start_char: 5889,
    end_char: 5899,
    display_word: "discontent",
    period_at_end: false
    },
    {
    word: "saying",
    start_time: 427700,
    end_time: 428300,
    token_index: 1034,
    start_char: 5900,
    end_char: 5906,
    display_word: "saying",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 428500,
    end_time: 428600,
    token_index: 1035,
    start_char: 5907,
    end_char: 5909,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 428600,
    end_time: 428900,
    token_index: 1036,
    start_char: 5910,
    end_char: 5913,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "feel",
    start_time: 428900,
    end_time: 429200,
    token_index: 1037,
    start_char: 5914,
    end_char: 5918,
    display_word: "feel",
    period_at_end: false
    },
    {
    word: "terrible.",
    start_time: 429200,
    end_time: 429700,
    token_index: 1038,
    start_char: 5919,
    end_char: 5927,
    display_word: "terrible",
    period_at_end: true
    },
    {
    word: "This",
    start_time: 429700,
    end_time: 429900,
    token_index: 1039,
    start_char: 5929,
    end_char: 5933,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "happened.",
    start_time: 429900,
    end_time: 430500,
    token_index: 1040,
    start_char: 5934,
    end_char: 5942,
    display_word: "happened",
    period_at_end: true
    },
    {
    word: "Fun",
    start_time: 430900,
    end_time: 431100,
    token_index: 1041,
    start_char: 5944,
    end_char: 5947,
    display_word: "Fun",
    period_at_end: false
    },
    {
    word: "fact,",
    start_time: 431100,
    end_time: 431800,
    token_index: 1042,
    start_char: 5948,
    end_char: 5953,
    display_word: "fact,",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 431800,
    end_time: 432000,
    token_index: 1043,
    start_char: 5954,
    end_char: 5956,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 432000,
    end_time: 432100,
    token_index: 1044,
    start_char: 5957,
    end_char: 5960,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "feel",
    start_time: 432100,
    end_time: 432400,
    token_index: 1045,
    start_char: 5961,
    end_char: 5965,
    display_word: "feel",
    period_at_end: false
    },
    {
    word: "terrible.",
    start_time: 432400,
    end_time: 432800,
    token_index: 1046,
    start_char: 5966,
    end_char: 5974,
    display_word: "terrible",
    period_at_end: true
    },
    {
    word: "This",
    start_time: 432800,
    end_time: 432900,
    token_index: 1047,
    start_char: 5976,
    end_char: 5980,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "happened",
    start_time: 432900,
    end_time: 433600,
    token_index: 1048,
    start_char: 5981,
    end_char: 5989,
    display_word: "happened",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 433600,
    end_time: 433800,
    token_index: 1049,
    start_char: 5990,
    end_char: 5992,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "Twitter's",
    start_time: 433800,
    end_time: 434200,
    token_index: 1050,
    start_char: 5993,
    end_char: 6002,
    display_word: "Twitter's",
    period_at_end: false
    },
    {
    word: "official",
    start_time: 434200,
    end_time: 434600,
    token_index: 1051,
    start_char: 6003,
    end_char: 6011,
    display_word: "official",
    period_at_end: false
    },
    {
    word: "motto",
    start_time: 434600,
    end_time: 435100,
    token_index: 1052,
    start_char: 6012,
    end_char: 6017,
    display_word: "motto",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 437100,
    end_time: 437300,
    token_index: 1053,
    start_char: 6018,
    end_char: 6021,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "country",
    start_time: 437300,
    end_time: 438100,
    token_index: 1054,
    start_char: 6022,
    end_char: 6029,
    display_word: "country",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 438600,
    end_time: 438900,
    token_index: 1055,
    start_char: 6030,
    end_char: 6033,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 438900,
    end_time: 439000,
    token_index: 1056,
    start_char: 6034,
    end_char: 6036,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "laughter",
    start_time: 439000,
    end_time: 439400,
    token_index: 1057,
    start_char: 6037,
    end_char: 6045,
    display_word: "laughter",
    period_at_end: false
    },
    {
    word: "than",
    start_time: 439400,
    end_time: 439600,
    token_index: 1058,
    start_char: 6046,
    end_char: 6050,
    display_word: "than",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 439600,
    end_time: 439700,
    token_index: 1059,
    start_char: 6051,
    end_char: 6052,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 439700,
    end_time: 440000,
    token_index: 1060,
    start_char: 6053,
    end_char: 6058,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "enjoy",
    start_time: 440000,
    end_time: 440300,
    token_index: 1061,
    start_char: 6059,
    end_char: 6064,
    display_word: "enjoy",
    period_at_end: false
    },
    {
    word: "this.",
    start_time: 440300,
    end_time: 440500,
    token_index: 1062,
    start_char: 6065,
    end_char: 6069,
    display_word: "this",
    period_at_end: true
    },
    {
    word: "Are",
    start_time: 440500,
    end_time: 440600,
    token_index: 1063,
    start_char: 6071,
    end_char: 6074,
    display_word: "Are",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 440600,
    end_time: 440700,
    token_index: 1064,
    start_char: 6075,
    end_char: 6078,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "tired",
    start_time: 440700,
    end_time: 441100,
    token_index: 1065,
    start_char: 6079,
    end_char: 6084,
    display_word: "tired",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 441100,
    end_time: 441200,
    token_index: 1066,
    start_char: 6085,
    end_char: 6087,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 441200,
    end_time: 441300,
    token_index: 1067,
    start_char: 6088,
    end_char: 6092,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "enjoy",
    start_time: 441300,
    end_time: 441700,
    token_index: 1068,
    start_char: 6093,
    end_char: 6098,
    display_word: "enjoy",
    period_at_end: false
    },
    {
    word: "it?",
    start_time: 441700,
    end_time: 442000,
    token_index: 1069,
    start_char: 6099,
    end_char: 6102,
    display_word: "it?",
    period_at_end: false
    },
    {
    word: "Thank",
    start_time: 442000,
    end_time: 442200,
    token_index: 1070,
    start_char: 6103,
    end_char: 6108,
    display_word: "Thank",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 442200,
    end_time: 442200,
    token_index: 1071,
    start_char: 6109,
    end_char: 6112,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "very",
    start_time: 442200,
    end_time: 442500,
    token_index: 1072,
    start_char: 6113,
    end_char: 6117,
    display_word: "very",
    period_at_end: false
    },
    {
    word: "much.",
    start_time: 442500,
    end_time: 442900,
    token_index: 1073,
    start_char: 6118,
    end_char: 6122,
    display_word: "much",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 443900,
    end_time: 444000,
    token_index: 1074,
    start_char: 6124,
    end_char: 6127,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "country",
    start_time: 444000,
    end_time: 444300,
    token_index: 1075,
    start_char: 6128,
    end_char: 6135,
    display_word: "country",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 444300,
    end_time: 444500,
    token_index: 1076,
    start_char: 6136,
    end_char: 6138,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 444500,
    end_time: 444600,
    token_index: 1077,
    start_char: 6139,
    end_char: 6141,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "crisis.",
    start_time: 444600,
    end_time: 445300,
    token_index: 1078,
    start_char: 6142,
    end_char: 6148,
    display_word: "crisis",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 445300,
    end_time: 445400,
    token_index: 1079,
    start_char: 6150,
    end_char: 6153,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 445400,
    end_time: 445400,
    token_index: 1080,
    start_char: 6154,
    end_char: 6157,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "White",
    start_time: 445400,
    end_time: 445600,
    token_index: 1081,
    start_char: 6158,
    end_char: 6163,
    display_word: "White",
    period_at_end: false
    },
    {
    word: "House",
    start_time: 445600,
    end_time: 445800,
    token_index: 1082,
    start_char: 6164,
    end_char: 6169,
    display_word: "House",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 445800,
    end_time: 446000,
    token_index: 1083,
    start_char: 6170,
    end_char: 6172,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 446000,
    end_time: 446200,
    token_index: 1084,
    start_char: 6173,
    end_char: 6178,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "everything",
    start_time: 446200,
    end_time: 446600,
    token_index: 1085,
    start_char: 6179,
    end_char: 6189,
    display_word: "everything",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 446600,
    end_time: 446700,
    token_index: 1086,
    start_char: 6190,
    end_char: 6194,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 446700,
    end_time: 447000,
    token_index: 1087,
    start_char: 6195,
    end_char: 6198,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 447000,
    end_time: 447200,
    token_index: 1088,
    start_char: 6199,
    end_char: 6201,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "stop",
    start_time: 447200,
    end_time: 447700,
    token_index: 1089,
    start_char: 6202,
    end_char: 6206,
    display_word: "stop",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 447700,
    end_time: 447800,
    token_index: 1090,
    start_char: 6207,
    end_char: 6210,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "coronavirus",
    start_time: 447800,
    end_time: 448800,
    token_index: 1091,
    start_char: 6211,
    end_char: 6222,
    display_word: "coronavirus",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 448800,
    end_time: 449200,
    token_index: 1092,
    start_char: 6223,
    end_char: 6226,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 449200,
    end_time: 449400,
    token_index: 1093,
    start_char: 6227,
    end_char: 6229,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "amine",
    start_time: 449400,
    end_time: 449800,
    token_index: 1094,
    start_char: 6230,
    end_char: 6235,
    display_word: "amine",
    period_at_end: false
    },
    {
    word: "Anthony",
    start_time: 449800,
    end_time: 450300,
    token_index: 1095,
    start_char: 6236,
    end_char: 6243,
    display_word: "Anthony",
    period_at_end: false
    },
    {
    word: "Fouch",
    start_time: 450300,
    end_time: 450600,
    token_index: 1096,
    start_char: 6244,
    end_char: 6249,
    display_word: "Fouch",
    period_at_end: false
    },
    {
    word: "e",
    start_time: 450600,
    end_time: 450800,
    token_index: 1097,
    start_char: 6250,
    end_char: 6251,
    display_word: "e",
    period_at_end: false
    },
    {
    word: "Trump's",
    start_time: 451600,
    end_time: 452000,
    token_index: 1098,
    start_char: 6252,
    end_char: 6259,
    display_word: "Trump's",
    period_at_end: false
    },
    {
    word: "cronies",
    start_time: 452000,
    end_time: 452500,
    token_index: 1099,
    start_char: 6260,
    end_char: 6267,
    display_word: "cronies",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 452500,
    end_time: 452600,
    token_index: 1100,
    start_char: 6268,
    end_char: 6272,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "attacked",
    start_time: 452600,
    end_time: 453000,
    token_index: 1101,
    start_char: 6273,
    end_char: 6281,
    display_word: "attacked",
    period_at_end: false
    },
    {
    word: "Fouch",
    start_time: 453000,
    end_time: 453300,
    token_index: 1102,
    start_char: 6282,
    end_char: 6287,
    display_word: "Fouch",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 453300,
    end_time: 453400,
    token_index: 1103,
    start_char: 6288,
    end_char: 6290,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 453400,
    end_time: 453500,
    token_index: 1104,
    start_char: 6291,
    end_char: 6294,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Press",
    start_time: 453500,
    end_time: 454100,
    token_index: 1105,
    start_char: 6295,
    end_char: 6300,
    display_word: "Press",
    period_at_end: false
    },
    {
    word: "dug",
    start_time: 454300,
    end_time: 454500,
    token_index: 1106,
    start_char: 6301,
    end_char: 6304,
    display_word: "dug",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 454500,
    end_time: 454600,
    token_index: 1107,
    start_char: 6305,
    end_char: 6307,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "opposition",
    start_time: 454600,
    end_time: 455100,
    token_index: 1108,
    start_char: 6308,
    end_char: 6318,
    display_word: "opposition",
    period_at_end: false
    },
    {
    word: "research",
    start_time: 455100,
    end_time: 455500,
    token_index: 1109,
    start_char: 6319,
    end_char: 6327,
    display_word: "research",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 455500,
    end_time: 455700,
    token_index: 1110,
    start_char: 6328,
    end_char: 6330,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "him",
    start_time: 455700,
    end_time: 456000,
    token_index: 1111,
    start_char: 6331,
    end_char: 6334,
    display_word: "him",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 456000,
    end_time: 456300,
    token_index: 1112,
    start_char: 6335,
    end_char: 6338,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "stopped",
    start_time: 456300,
    end_time: 456700,
    token_index: 1113,
    start_char: 6339,
    end_char: 6346,
    display_word: "stopped",
    period_at_end: false
    },
    {
    word: "meeting",
    start_time: 456700,
    end_time: 457000,
    token_index: 1114,
    start_char: 6347,
    end_char: 6354,
    display_word: "meeting",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 457000,
    end_time: 457200,
    token_index: 1115,
    start_char: 6355,
    end_char: 6359,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "him",
    start_time: 457200,
    end_time: 457300,
    token_index: 1116,
    start_char: 6360,
    end_char: 6363,
    display_word: "him",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 457300,
    end_time: 457400,
    token_index: 1117,
    start_char: 6364,
    end_char: 6366,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 457400,
    end_time: 457500,
    token_index: 1118,
    start_char: 6367,
    end_char: 6370,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "White",
    start_time: 457500,
    end_time: 457700,
    token_index: 1119,
    start_char: 6371,
    end_char: 6376,
    display_word: "White",
    period_at_end: false
    },
    {
    word: "House,",
    start_time: 457700,
    end_time: 458000,
    token_index: 1120,
    start_char: 6377,
    end_char: 6383,
    display_word: "House,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 458000,
    end_time: 458200,
    token_index: 1121,
    start_char: 6384,
    end_char: 6387,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "still",
    start_time: 458200,
    end_time: 458600,
    token_index: 1122,
    start_char: 6388,
    end_char: 6393,
    display_word: "still",
    period_at_end: false
    },
    {
    word: "Fouch",
    start_time: 458900,
    end_time: 459300,
    token_index: 1123,
    start_char: 6394,
    end_char: 6399,
    display_word: "Fouch",
    period_at_end: false
    },
    {
    word: "he's",
    start_time: 459300,
    end_time: 459400,
    token_index: 1124,
    start_char: 6400,
    end_char: 6404,
    display_word: "he's",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 459400,
    end_time: 459700,
    token_index: 1125,
    start_char: 6405,
    end_char: 6409,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "popular",
    start_time: 459700,
    end_time: 460200,
    token_index: 1126,
    start_char: 6410,
    end_char: 6417,
    display_word: "popular",
    period_at_end: false
    },
    {
    word: "than",
    start_time: 460200,
    end_time: 460400,
    token_index: 1127,
    start_char: 6418,
    end_char: 6422,
    display_word: "than",
    period_at_end: false
    },
    {
    word: "ever",
    start_time: 460400,
    end_time: 460700,
    token_index: 1128,
    start_char: 6423,
    end_char: 6427,
    display_word: "ever",
    period_at_end: false
    },
    {
    word: "case",
    start_time: 460700,
    end_time: 461100,
    token_index: 1129,
    start_char: 6428,
    end_char: 6432,
    display_word: "case",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 461100,
    end_time: 461200,
    token_index: 1130,
    start_char: 6433,
    end_char: 6436,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "point",
    start_time: 461200,
    end_time: 461800,
    token_index: 1131,
    start_char: 6437,
    end_char: 6442,
    display_word: "point",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 461800,
    end_time: 462100,
    token_index: 1132,
    start_char: 6443,
    end_char: 6446,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 462100,
    end_time: 462300,
    token_index: 1133,
    start_char: 6447,
    end_char: 6450,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "cover",
    start_time: 462300,
    end_time: 462700,
    token_index: 1134,
    start_char: 6451,
    end_char: 6456,
    display_word: "cover",
    period_at_end: false
    },
    {
    word: "model",
    start_time: 462700,
    end_time: 463300,
    token_index: 1135,
    start_char: 6457,
    end_char: 6462,
    display_word: "model",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 463500,
    end_time: 463800,
    token_index: 1136,
    start_char: 6463,
    end_char: 6465,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 463800,
    end_time: 464200,
    token_index: 1137,
    start_char: 6466,
    end_char: 6468,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "style",
    start_time: 464200,
    end_time: 464800,
    token_index: 1138,
    start_char: 6469,
    end_char: 6474,
    display_word: "style",
    period_at_end: false
    },
    {
    word: "magazine.",
    start_time: 464800,
    end_time: 466000,
    token_index: 1139,
    start_char: 6475,
    end_char: 6483,
    display_word: "magazine",
    period_at_end: true
    },
    {
    word: "Hummina.",
    start_time: 466300,
    end_time: 466900,
    token_index: 1140,
    start_char: 6485,
    end_char: 6492,
    display_word: "Hummina",
    period_at_end: true
    },
    {
    word: "Hummina.",
    start_time: 466900,
    end_time: 467500,
    token_index: 1141,
    start_char: 6494,
    end_char: 6501,
    display_word: "Hummina",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 467500,
    end_time: 467600,
    token_index: 1142,
    start_char: 6503,
    end_char: 6504,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 467600,
    end_time: 467900,
    token_index: 1143,
    start_char: 6505,
    end_char: 6510,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 467900,
    end_time: 468100,
    token_index: 1144,
    start_char: 6511,
    end_char: 6513,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "found",
    start_time: 468100,
    end_time: 468400,
    token_index: 1145,
    start_char: 6514,
    end_char: 6519,
    display_word: "found",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 468400,
    end_time: 468600,
    token_index: 1146,
    start_char: 6520,
    end_char: 6523,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "latest",
    start_time: 468600,
    end_time: 469100,
    token_index: 1147,
    start_char: 6524,
    end_char: 6530,
    display_word: "latest",
    period_at_end: false
    },
    {
    word: "hot",
    start_time: 469100,
    end_time: 469300,
    token_index: 1148,
    start_char: 6531,
    end_char: 6534,
    display_word: "hot",
    period_at_end: false
    },
    {
    word: "spot.",
    start_time: 469300,
    end_time: 470100,
    token_index: 1149,
    start_char: 6535,
    end_char: 6539,
    display_word: "spot",
    period_at_end: true
    },
    {
    word: "Do",
    start_time: 470500,
    end_time: 470700,
    token_index: 1150,
    start_char: 6541,
    end_char: 6543,
    display_word: "Do",
    period_at_end: false
    },
    {
    word: "those",
    start_time: 470700,
    end_time: 471000,
    token_index: 1151,
    start_char: 6544,
    end_char: 6549,
    display_word: "those",
    period_at_end: false
    },
    {
    word: "novelty",
    start_time: 471000,
    end_time: 471600,
    token_index: 1152,
    start_char: 6550,
    end_char: 6557,
    display_word: "novelty",
    period_at_end: false
    },
    {
    word: "socks",
    start_time: 471600,
    end_time: 472000,
    token_index: 1153,
    start_char: 6558,
    end_char: 6563,
    display_word: "socks",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 472000,
    end_time: 472600,
    token_index: 1154,
    start_char: 6564,
    end_char: 6566,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 473100,
    end_time: 473600,
    token_index: 1155,
    start_char: 6567,
    end_char: 6570,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 473600,
    end_time: 473700,
    token_index: 1156,
    start_char: 6571,
    end_char: 6574,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "way",
    start_time: 473700,
    end_time: 474200,
    token_index: 1157,
    start_char: 6575,
    end_char: 6578,
    display_word: "way",
    period_at_end: false
    },
    {
    word: "up.",
    start_time: 474200,
    end_time: 474700,
    token_index: 1158,
    start_char: 6579,
    end_char: 6581,
    display_word: "up",
    period_at_end: true
    },
    {
    word: "Perhaps",
    start_time: 475800,
    end_time: 476300,
    token_index: 1159,
    start_char: 6583,
    end_char: 6590,
    display_word: "Perhaps",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 476300,
    end_time: 476300,
    token_index: 1160,
    start_char: 6591,
    end_char: 6594,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "most",
    start_time: 476300,
    end_time: 476500,
    token_index: 1161,
    start_char: 6595,
    end_char: 6599,
    display_word: "most",
    period_at_end: false
    },
    {
    word: "stylish",
    start_time: 476500,
    end_time: 476900,
    token_index: 1162,
    start_char: 6600,
    end_char: 6607,
    display_word: "stylish",
    period_at_end: false
    },
    {
    word: "component",
    start_time: 476900,
    end_time: 477400,
    token_index: 1163,
    start_char: 6608,
    end_char: 6617,
    display_word: "component",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 477400,
    end_time: 477600,
    token_index: 1164,
    start_char: 6618,
    end_char: 6620,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 477600,
    end_time: 477800,
    token_index: 1165,
    start_char: 6621,
    end_char: 6624,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "good",
    start_time: 477800,
    end_time: 478000,
    token_index: 1166,
    start_char: 6625,
    end_char: 6629,
    display_word: "good",
    period_at_end: false
    },
    {
    word: "doctors",
    start_time: 478000,
    end_time: 478400,
    token_index: 1167,
    start_char: 6630,
    end_char: 6637,
    display_word: "doctors",
    period_at_end: false
    },
    {
    word: "outfit",
    start_time: 478400,
    end_time: 479000,
    token_index: 1168,
    start_char: 6638,
    end_char: 6644,
    display_word: "outfit",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 479100,
    end_time: 479400,
    token_index: 1169,
    start_char: 6645,
    end_char: 6647,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "those",
    start_time: 479400,
    end_time: 480300,
    token_index: 1170,
    start_char: 6648,
    end_char: 6653,
    display_word: "those",
    period_at_end: false
    },
    {
    word: "Shades",
    start_time: 480300,
    end_time: 481400,
    token_index: 1171,
    start_char: 6654,
    end_char: 6660,
    display_word: "Shades",
    period_at_end: false
    },
    {
    word: "holy",
    start_time: 481900,
    end_time: 482400,
    token_index: 1172,
    start_char: 6661,
    end_char: 6665,
    display_word: "holy",
    period_at_end: false
    },
    {
    word: "CSI,",
    start_time: 482400,
    end_time: 483000,
    token_index: 1173,
    start_char: 6666,
    end_char: 6670,
    display_word: "CSI,",
    period_at_end: false
    },
    {
    word: "Miami",
    start_time: 483000,
    end_time: 483600,
    token_index: 1174,
    start_char: 6671,
    end_char: 6676,
    display_word: "Miami",
    period_at_end: false
    },
    {
    word: "Batman.",
    start_time: 483600,
    end_time: 484400,
    token_index: 1175,
    start_char: 6677,
    end_char: 6683,
    display_word: "Batman",
    period_at_end: true
    },
    {
    word: "Will",
    start_time: 488500,
    end_time: 488700,
    token_index: 1176,
    start_char: 6685,
    end_char: 6689,
    display_word: "Will",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 488700,
    end_time: 488800,
    token_index: 1177,
    start_char: 6690,
    end_char: 6692,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 488800,
    end_time: 489000,
    token_index: 1178,
    start_char: 6693,
    end_char: 6697,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 489000,
    end_time: 489000,
    token_index: 1179,
    start_char: 6698,
    end_char: 6699,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "treatment",
    start_time: 489000,
    end_time: 489800,
    token_index: 1180,
    start_char: 6700,
    end_char: 6709,
    display_word: "treatment",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 490300,
    end_time: 490500,
    token_index: 1181,
    start_char: 6710,
    end_char: 6714,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "remains",
    start_time: 490500,
    end_time: 491000,
    token_index: 1182,
    start_char: 6715,
    end_char: 6722,
    display_word: "remains",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 491000,
    end_time: 491100,
    token_index: 1183,
    start_char: 6723,
    end_char: 6725,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be?",
    start_time: 491100,
    end_time: 491700,
    token_index: 1184,
    start_char: 6726,
    end_char: 6729,
    display_word: "be?",
    period_at_end: false
    },
    {
    word: "Vaccine.",
    start_time: 494100,
    end_time: 495200,
    token_index: 1185,
    start_char: 6730,
    end_char: 6737,
    display_word: "Vaccine",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 499500,
    end_time: 499600,
    token_index: 1186,
    start_char: 6739,
    end_char: 6740,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 499600,
    end_time: 499800,
    token_index: 1187,
    start_char: 6741,
    end_char: 6746,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 499800,
    end_time: 499900,
    token_index: 1188,
    start_char: 6747,
    end_char: 6751,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 499900,
    end_time: 500000,
    token_index: 1189,
    start_char: 6752,
    end_char: 6754,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 500000,
    end_time: 500100,
    token_index: 1190,
    start_char: 6755,
    end_char: 6759,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "vaccine",
    start_time: 500100,
    end_time: 500600,
    token_index: 1191,
    start_char: 6760,
    end_char: 6767,
    display_word: "vaccine",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 500600,
    end_time: 500600,
    token_index: 1192,
    start_char: 6768,
    end_char: 6770,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 500600,
    end_time: 500800,
    token_index: 1193,
    start_char: 6771,
    end_char: 6776,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 500800,
    end_time: 500900,
    token_index: 1194,
    start_char: 6777,
    end_char: 6779,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "work,",
    start_time: 500900,
    end_time: 501400,
    token_index: 1195,
    start_char: 6780,
    end_char: 6785,
    display_word: "work,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 501400,
    end_time: 501500,
    token_index: 1196,
    start_char: 6786,
    end_char: 6789,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 501500,
    end_time: 501700,
    token_index: 1197,
    start_char: 6790,
    end_char: 6794,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "worth.",
    start_time: 501700,
    end_time: 502300,
    token_index: 1198,
    start_char: 6795,
    end_char: 6800,
    display_word: "worth",
    period_at_end: true
    },
    {
    word: "A",
    start_time: 504700,
    end_time: 504800,
    token_index: 1199,
    start_char: 6802,
    end_char: 6803,
    display_word: "A",
    period_at_end: false
    },
    {
    word: "shot.",
    start_time: 504800,
    end_time: 505600,
    token_index: 1200,
    start_char: 6804,
    end_char: 6808,
    display_word: "shot",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 509300,
    end_time: 509300,
    token_index: 1201,
    start_char: 6810,
    end_char: 6811,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "haven't",
    start_time: 509300,
    end_time: 509600,
    token_index: 1202,
    start_char: 6812,
    end_char: 6819,
    display_word: "haven't",
    period_at_end: false
    },
    {
    word: "spoken",
    start_time: 509600,
    end_time: 509900,
    token_index: 1203,
    start_char: 6820,
    end_char: 6826,
    display_word: "spoken",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 509900,
    end_time: 510000,
    token_index: 1204,
    start_char: 6827,
    end_char: 6829,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 510000,
    end_time: 510100,
    token_index: 1205,
    start_char: 6830,
    end_char: 6833,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "president",
    start_time: 510100,
    end_time: 510600,
    token_index: 1206,
    start_char: 6834,
    end_char: 6843,
    display_word: "president",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 510600,
    end_time: 510700,
    token_index: 1207,
    start_char: 6844,
    end_char: 6846,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "months.",
    start_time: 510700,
    end_time: 511300,
    token_index: 1208,
    start_char: 6847,
    end_char: 6853,
    display_word: "months",
    period_at_end: true
    },
    {
    word: "He's",
    start_time: 511600,
    end_time: 511900,
    token_index: 1209,
    start_char: 6855,
    end_char: 6859,
    display_word: "He's",
    period_at_end: false
    },
    {
    word: "avoiding",
    start_time: 511900,
    end_time: 512299,
    token_index: 1210,
    start_char: 6860,
    end_char: 6868,
    display_word: "avoiding",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 512299,
    end_time: 512500,
    token_index: 1211,
    start_char: 6869,
    end_char: 6871,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 512500,
    end_time: 513100,
    token_index: 1212,
    start_char: 6872,
    end_char: 6876,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 515299,
    end_time: 515400,
    token_index: 1213,
    start_char: 6877,
    end_char: 6880,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "plague",
    start_time: 515400,
    end_time: 516400,
    token_index: 1214,
    start_char: 6881,
    end_char: 6887,
    display_word: "plague",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 519900,
    end_time: 520400,
    token_index: 1215,
    start_char: 6888,
    end_char: 6891,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "Dr.",
    start_time: 521500,
    end_time: 521700,
    token_index: 1216,
    start_char: 6892,
    end_char: 6894,
    display_word: "Dr",
    period_at_end: true
    },
    {
    word: "Fauci",
    start_time: 521700,
    end_time: 522100,
    token_index: 1217,
    start_char: 6896,
    end_char: 6901,
    display_word: "Fauci",
    period_at_end: false
    },
    {
    word: "appearing",
    start_time: 522100,
    end_time: 522500,
    token_index: 1218,
    start_char: 6902,
    end_char: 6911,
    display_word: "appearing",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 522500,
    end_time: 522600,
    token_index: 1219,
    start_char: 6912,
    end_char: 6914,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 522600,
    end_time: 522600,
    token_index: 1220,
    start_char: 6915,
    end_char: 6918,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "cover",
    start_time: 522600,
    end_time: 522900,
    token_index: 1221,
    start_char: 6919,
    end_char: 6924,
    display_word: "cover",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 522900,
    end_time: 523000,
    token_index: 1222,
    start_char: 6925,
    end_char: 6927,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 523000,
    end_time: 523200,
    token_index: 1223,
    start_char: 6928,
    end_char: 6930,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "style",
    start_time: 523200,
    end_time: 523500,
    token_index: 1224,
    start_char: 6931,
    end_char: 6936,
    display_word: "style",
    period_at_end: false
    },
    {
    word: "magazine",
    start_time: 523500,
    end_time: 524100,
    token_index: 1225,
    start_char: 6937,
    end_char: 6945,
    display_word: "magazine",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 524100,
    end_time: 524200,
    token_index: 1226,
    start_char: 6946,
    end_char: 6948,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 524200,
    end_time: 524200,
    token_index: 1227,
    start_char: 6949,
    end_char: 6950,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "historic",
    start_time: 524200,
    end_time: 524900,
    token_index: 1228,
    start_char: 6951,
    end_char: 6959,
    display_word: "historic",
    period_at_end: false
    },
    {
    word: "moment",
    start_time: 524900,
    end_time: 525500,
    token_index: 1229,
    start_char: 6960,
    end_char: 6966,
    display_word: "moment",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 525500,
    end_time: 525600,
    token_index: 1230,
    start_char: 6967,
    end_char: 6970,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "American",
    start_time: 525600,
    end_time: 526100,
    token_index: 1231,
    start_char: 6971,
    end_char: 6979,
    display_word: "American",
    period_at_end: false
    },
    {
    word: "Medicine",
    start_time: 526100,
    end_time: 526600,
    token_index: 1232,
    start_char: 6980,
    end_char: 6988,
    display_word: "Medicine",
    period_at_end: false
    },
    {
    word: "almost",
    start_time: 526900,
    end_time: 527300,
    token_index: 1233,
    start_char: 6989,
    end_char: 6995,
    display_word: "almost",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 527300,
    end_time: 527400,
    token_index: 1234,
    start_char: 6996,
    end_char: 6998,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "significant",
    start_time: 527400,
    end_time: 528000,
    token_index: 1235,
    start_char: 6999,
    end_char: 7010,
    display_word: "significant",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 528000,
    end_time: 528100,
    token_index: 1236,
    start_char: 7011,
    end_char: 7013,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 528100,
    end_time: 528400,
    token_index: 1237,
    start_char: 7014,
    end_char: 7018,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "Sports",
    start_time: 528400,
    end_time: 528700,
    token_index: 1238,
    start_char: 7019,
    end_char: 7025,
    display_word: "Sports",
    period_at_end: false
    },
    {
    word: "Illustrated",
    start_time: 528700,
    end_time: 529200,
    token_index: 1239,
    start_char: 7026,
    end_char: 7037,
    display_word: "Illustrated",
    period_at_end: false
    },
    {
    word: "Swimsuit",
    start_time: 529200,
    end_time: 529600,
    token_index: 1240,
    start_char: 7038,
    end_char: 7046,
    display_word: "Swimsuit",
    period_at_end: false
    },
    {
    word: "Edition",
    start_time: 529600,
    end_time: 530100,
    token_index: 1241,
    start_char: 7047,
    end_char: 7054,
    display_word: "Edition",
    period_at_end: false
    },
    {
    word: "featuring",
    start_time: 530100,
    end_time: 530600,
    token_index: 1242,
    start_char: 7055,
    end_char: 7064,
    display_word: "featuring",
    period_at_end: false
    },
    {
    word: "Louis,",
    start_time: 530600,
    end_time: 530900,
    token_index: 1243,
    start_char: 7065,
    end_char: 7071,
    display_word: "Louis,",
    period_at_end: false
    },
    {
    word: "Pasteur.",
    start_time: 530900,
    end_time: 531700,
    token_index: 1244,
    start_char: 7072,
    end_char: 7079,
    display_word: "Pasteur",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 532800,
    end_time: 533300,
    token_index: 1245,
    start_char: 7081,
    end_char: 7085,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "remember,",
    start_time: 533300,
    end_time: 533700,
    token_index: 1246,
    start_char: 7086,
    end_char: 7095,
    display_word: "remember,",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 533700,
    end_time: 534000,
    token_index: 1247,
    start_char: 7096,
    end_char: 7099,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "2020",
    start_time: 534000,
    end_time: 534700,
    token_index: 1248,
    start_char: 7100,
    end_char: 7104,
    display_word: "2020",
    period_at_end: false
    },
    {
    word: "presidential",
    start_time: 534700,
    end_time: 535400,
    token_index: 1249,
    start_char: 7105,
    end_char: 7117,
    display_word: "presidential",
    period_at_end: false
    },
    {
    word: "election",
    start_time: 535400,
    end_time: 536000,
    token_index: 1250,
    start_char: 7118,
    end_char: 7126,
    display_word: "election",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 536000,
    end_time: 536200,
    token_index: 1251,
    start_char: 7127,
    end_char: 7129,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "still",
    start_time: 536200,
    end_time: 536400,
    token_index: 1252,
    start_char: 7130,
    end_char: 7135,
    display_word: "still",
    period_at_end: false
    },
    {
    word: "happening",
    start_time: 536400,
    end_time: 537100,
    token_index: 1253,
    start_char: 7136,
    end_char: 7145,
    display_word: "happening",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 537400,
    end_time: 537500,
    token_index: 1254,
    start_char: 7146,
    end_char: 7149,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "according",
    start_time: 537500,
    end_time: 537800,
    token_index: 1255,
    start_char: 7150,
    end_char: 7159,
    display_word: "according",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 537800,
    end_time: 537900,
    token_index: 1256,
    start_char: 7160,
    end_char: 7162,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 537900,
    end_time: 538000,
    token_index: 1257,
    start_char: 7163,
    end_char: 7164,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "recent",
    start_time: 538000,
    end_time: 538300,
    token_index: 1258,
    start_char: 7165,
    end_char: 7171,
    display_word: "recent",
    period_at_end: false
    },
    {
    word: "poll",
    start_time: 538300,
    end_time: 538700,
    token_index: 1259,
    start_char: 7172,
    end_char: 7176,
    display_word: "poll",
    period_at_end: false
    },
    {
    word: "by",
    start_time: 538900,
    end_time: 539100,
    token_index: 1260,
    start_char: 7177,
    end_char: 7179,
    display_word: "by",
    period_at_end: false
    },
    {
    word: "Dan",
    start_time: 539100,
    end_time: 539200,
    token_index: 1261,
    start_char: 7180,
    end_char: 7183,
    display_word: "Dan",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 539200,
    end_time: 539400,
    token_index: 1262,
    start_char: 7184,
    end_char: 7187,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 539400,
    end_time: 539600,
    token_index: 1263,
    start_char: 7188,
    end_char: 7191,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 539600,
    end_time: 539700,
    token_index: 1264,
    start_char: 7192,
    end_char: 7193,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "15-point",
    start_time: 539700,
    end_time: 541100,
    token_index: 1265,
    start_char: 7194,
    end_char: 7202,
    display_word: "15-point",
    period_at_end: false
    },
    {
    word: "advantage",
    start_time: 541100,
    end_time: 541700,
    token_index: 1266,
    start_char: 7203,
    end_char: 7212,
    display_word: "advantage",
    period_at_end: false
    },
    {
    word: "over",
    start_time: 541700,
    end_time: 542000,
    token_index: 1267,
    start_char: 7213,
    end_char: 7217,
    display_word: "over",
    period_at_end: false
    },
    {
    word: "Chomp.",
    start_time: 542000,
    end_time: 542600,
    token_index: 1268,
    start_char: 7218,
    end_char: 7223,
    display_word: "Chomp",
    period_at_end: true
    },
    {
    word: "Woohoo,",
    start_time: 542700,
    end_time: 543700,
    token_index: 1269,
    start_char: 7225,
    end_char: 7232,
    display_word: "Woohoo,",
    period_at_end: false
    },
    {
    word: "Joe",
    start_time: 543700,
    end_time: 544100,
    token_index: 1270,
    start_char: 7233,
    end_char: 7236,
    display_word: "Joe",
    period_at_end: false
    },
    {
    word: "you're",
    start_time: 544100,
    end_time: 544300,
    token_index: 1271,
    start_char: 7237,
    end_char: 7243,
    display_word: "you're",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 544300,
    end_time: 544800,
    token_index: 1272,
    start_char: 7244,
    end_char: 7249,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 544800,
    end_time: 545500,
    token_index: 1273,
    start_char: 7250,
    end_char: 7255,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "by",
    start_time: 545500,
    end_time: 545700,
    token_index: 1274,
    start_char: 7256,
    end_char: 7258,
    display_word: "by",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 545700,
    end_time: 546200,
    token_index: 1275,
    start_char: 7259,
    end_char: 7264,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "nothing.",
    start_time: 546200,
    end_time: 546600,
    token_index: 1276,
    start_char: 7265,
    end_char: 7272,
    display_word: "nothing",
    period_at_end: true
    },
    {
    word: "Stay",
    start_time: 546600,
    end_time: 547100,
    token_index: 1277,
    start_char: 7274,
    end_char: 7278,
    display_word: "Stay",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 547100,
    end_time: 547400,
    token_index: 1278,
    start_char: 7279,
    end_char: 7281,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 547400,
    end_time: 547500,
    token_index: 1279,
    start_char: 7282,
    end_char: 7285,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "basement.",
    start_time: 547500,
    end_time: 548400,
    token_index: 1280,
    start_char: 7286,
    end_char: 7294,
    display_word: "basement",
    period_at_end: true
    },
    {
    word: "Someone",
    start_time: 548500,
    end_time: 548800,
    token_index: 1281,
    start_char: 7296,
    end_char: 7303,
    display_word: "Someone",
    period_at_end: false
    },
    {
    word: "toss",
    start_time: 548800,
    end_time: 549000,
    token_index: 1282,
    start_char: 7304,
    end_char: 7308,
    display_word: "toss",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 549000,
    end_time: 549100,
    token_index: 1283,
    start_char: 7309,
    end_char: 7310,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "crate",
    start_time: 549100,
    end_time: 549400,
    token_index: 1284,
    start_char: 7311,
    end_char: 7316,
    display_word: "crate",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 549400,
    end_time: 549500,
    token_index: 1285,
    start_char: 7317,
    end_char: 7319,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "Ensure",
    start_time: 549500,
    end_time: 549900,
    token_index: 1286,
    start_char: 7320,
    end_char: 7326,
    display_word: "Ensure",
    period_at_end: false
    },
    {
    word: "down",
    start_time: 549900,
    end_time: 550100,
    token_index: 1287,
    start_char: 7327,
    end_char: 7331,
    display_word: "down",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 550100,
    end_time: 550200,
    token_index: 1288,
    start_char: 7332,
    end_char: 7335,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "steps",
    start_time: 550200,
    end_time: 550600,
    token_index: 1289,
    start_char: 7336,
    end_char: 7341,
    display_word: "steps",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 550600,
    end_time: 550700,
    token_index: 1290,
    start_char: 7342,
    end_char: 7345,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "yanked",
    start_time: 550700,
    end_time: 551000,
    token_index: 1291,
    start_char: 7346,
    end_char: 7352,
    display_word: "yanked",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 551000,
    end_time: 551100,
    token_index: 1292,
    start_char: 7353,
    end_char: 7356,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "knob",
    start_time: 551100,
    end_time: 551400,
    token_index: 1293,
    start_char: 7357,
    end_char: 7361,
    display_word: "knob",
    period_at_end: false
    },
    {
    word: "off",
    start_time: 551400,
    end_time: 551600,
    token_index: 1294,
    start_char: 7362,
    end_char: 7365,
    display_word: "off",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 551600,
    end_time: 551700,
    token_index: 1295,
    start_char: 7366,
    end_char: 7369,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "door.",
    start_time: 551700,
    end_time: 552400,
    token_index: 1296,
    start_char: 7370,
    end_char: 7374,
    display_word: "door",
    period_at_end: true
    },
    {
    word: "Right",
    start_time: 553600,
    end_time: 553800,
    token_index: 1297,
    start_char: 7376,
    end_char: 7381,
    display_word: "Right",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 553800,
    end_time: 554000,
    token_index: 1298,
    start_char: 7382,
    end_char: 7385,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 554000,
    end_time: 554200,
    token_index: 1299,
    start_char: 7386,
    end_char: 7390,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "less",
    start_time: 554200,
    end_time: 554400,
    token_index: 1300,
    start_char: 7391,
    end_char: 7395,
    display_word: "less",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 554400,
    end_time: 554500,
    token_index: 1301,
    start_char: 7396,
    end_char: 7397,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "case",
    start_time: 554500,
    end_time: 554800,
    token_index: 1302,
    start_char: 7398,
    end_char: 7402,
    display_word: "case",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 554800,
    end_time: 554900,
    token_index: 1303,
    start_char: 7403,
    end_char: 7405,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "Biden",
    start_time: 554900,
    end_time: 555300,
    token_index: 1304,
    start_char: 7406,
    end_char: 7411,
    display_word: "Biden",
    period_at_end: false
    },
    {
    word: "beaten",
    start_time: 555300,
    end_time: 555700,
    token_index: 1305,
    start_char: 7412,
    end_char: 7418,
    display_word: "beaten",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 555700,
    end_time: 556300,
    token_index: 1306,
    start_char: 7419,
    end_char: 7424,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 556500,
    end_time: 556600,
    token_index: 1307,
    start_char: 7425,
    end_char: 7428,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 556600,
    end_time: 556800,
    token_index: 1308,
    start_char: 7429,
    end_char: 7433,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "case",
    start_time: 556800,
    end_time: 557100,
    token_index: 1309,
    start_char: 7434,
    end_char: 7438,
    display_word: "case",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 557100,
    end_time: 557200,
    token_index: 1310,
    start_char: 7439,
    end_char: 7441,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "trump",
    start_time: 557200,
    end_time: 557500,
    token_index: 1311,
    start_char: 7442,
    end_char: 7447,
    display_word: "trump",
    period_at_end: false
    },
    {
    word: "beating",
    start_time: 557500,
    end_time: 557800,
    token_index: 1312,
    start_char: 7448,
    end_char: 7455,
    display_word: "beating",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 557800,
    end_time: 558400,
    token_index: 1313,
    start_char: 7456,
    end_char: 7461,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 558600,
    end_time: 558800,
    token_index: 1314,
    start_char: 7462,
    end_char: 7469,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "according",
    start_time: 558800,
    end_time: 559200,
    token_index: 1315,
    start_char: 7470,
    end_char: 7479,
    display_word: "according",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 559200,
    end_time: 559300,
    token_index: 1316,
    start_char: 7480,
    end_char: 7482,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "another",
    start_time: 559300,
    end_time: 559600,
    token_index: 1317,
    start_char: 7483,
    end_char: 7490,
    display_word: "another",
    period_at_end: false
    },
    {
    word: "poll",
    start_time: 559600,
    end_time: 560000,
    token_index: 1318,
    start_char: 7491,
    end_char: 7495,
    display_word: "poll",
    period_at_end: false
    },
    {
    word: "72%",
    start_time: 560200,
    end_time: 561300,
    token_index: 1319,
    start_char: 7496,
    end_char: 7499,
    display_word: "72%",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 561300,
    end_time: 561500,
    token_index: 1320,
    start_char: 7500,
    end_char: 7503,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 561500,
    end_time: 561700,
    token_index: 1321,
    start_char: 7504,
    end_char: 7507,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "u.s.",
    start_time: 561700,
    end_time: 562100,
    token_index: 1322,
    start_char: 7508,
    end_char: 7511,
    display_word: "us",
    period_at_end: true
    },
    {
    word: "Is",
    start_time: 562100,
    end_time: 562300,
    token_index: 1323,
    start_char: 7513,
    end_char: 7515,
    display_word: "Is",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 562300,
    end_time: 562400,
    token_index: 1324,
    start_char: 7516,
    end_char: 7518,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 562400,
    end_time: 562600,
    token_index: 1325,
    start_char: 7519,
    end_char: 7522,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "wrong",
    start_time: 562600,
    end_time: 563200,
    token_index: 1326,
    start_char: 7523,
    end_char: 7528,
    display_word: "wrong",
    period_at_end: false
    },
    {
    word: "track.",
    start_time: 563200,
    end_time: 563900,
    token_index: 1327,
    start_char: 7529,
    end_char: 7534,
    display_word: "track",
    period_at_end: true
    },
    {
    word: "That",
    start_time: 564300,
    end_time: 564500,
    token_index: 1328,
    start_char: 7536,
    end_char: 7540,
    display_word: "That",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 564500,
    end_time: 564700,
    token_index: 1329,
    start_char: 7541,
    end_char: 7543,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 564700,
    end_time: 564900,
    token_index: 1330,
    start_char: 7544,
    end_char: 7545,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "devastating",
    start_time: 564900,
    end_time: 565700,
    token_index: 1331,
    start_char: 7546,
    end_char: 7557,
    display_word: "devastating",
    period_at_end: false
    },
    {
    word: "number",
    start_time: 565700,
    end_time: 566200,
    token_index: 1332,
    start_char: 7558,
    end_char: 7564,
    display_word: "number",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 566200,
    end_time: 566500,
    token_index: 1333,
    start_char: 7565,
    end_char: 7568,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 566500,
    end_time: 566700,
    token_index: 1334,
    start_char: 7569,
    end_char: 7572,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 566700,
    end_time: 566800,
    token_index: 1335,
    start_char: 7573,
    end_char: 7576,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 566800,
    end_time: 566900,
    token_index: 1336,
    start_char: 7577,
    end_char: 7580,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "28",
    start_time: 566900,
    end_time: 567500,
    token_index: 1337,
    start_char: 7581,
    end_char: 7583,
    display_word: "28",
    period_at_end: false
    },
    {
    word: "percent",
    start_time: 567500,
    end_time: 568300,
    token_index: 1338,
    start_char: 7584,
    end_char: 7591,
    display_word: "percent",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 568300,
    end_time: 568500,
    token_index: 1339,
    start_char: 7592,
    end_char: 7595,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "look",
    start_time: 568500,
    end_time: 568600,
    token_index: 1340,
    start_char: 7596,
    end_char: 7600,
    display_word: "look",
    period_at_end: false
    },
    {
    word: "around",
    start_time: 568600,
    end_time: 568900,
    token_index: 1341,
    start_char: 7601,
    end_char: 7607,
    display_word: "around",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 568900,
    end_time: 569000,
    token_index: 1342,
    start_char: 7608,
    end_char: 7611,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 569000,
    end_time: 569400,
    token_index: 1343,
    start_char: 7612,
    end_char: 7615,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "yeah,",
    start_time: 569500,
    end_time: 570000,
    token_index: 1344,
    start_char: 7616,
    end_char: 7621,
    display_word: "yeah,",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 570700,
    end_time: 570900,
    token_index: 1345,
    start_char: 7622,
    end_char: 7626,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "feels",
    start_time: 570900,
    end_time: 571300,
    token_index: 1346,
    start_char: 7627,
    end_char: 7632,
    display_word: "feels",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 571300,
    end_time: 571900,
    token_index: 1347,
    start_char: 7633,
    end_char: 7638,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "America",
    start_time: 572300,
    end_time: 572800,
    token_index: 1348,
    start_char: 7639,
    end_char: 7646,
    display_word: "America",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 572800,
    end_time: 573000,
    token_index: 1349,
    start_char: 7647,
    end_char: 7649,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 573000,
    end_time: 573200,
    token_index: 1350,
    start_char: 7650,
    end_char: 7651,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "perfect",
    start_time: 573200,
    end_time: 573700,
    token_index: 1351,
    start_char: 7652,
    end_char: 7659,
    display_word: "perfect",
    period_at_end: false
    },
    {
    word: "shape",
    start_time: 573700,
    end_time: 574000,
    token_index: 1352,
    start_char: 7660,
    end_char: 7665,
    display_word: "shape",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 574000,
    end_time: 574100,
    token_index: 1353,
    start_char: 7666,
    end_char: 7669,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 574100,
    end_time: 574600,
    token_index: 1354,
    start_char: 7670,
    end_char: 7672,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 574900,
    end_time: 575000,
    token_index: 1355,
    start_char: 7673,
    end_char: 7676,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 575000,
    end_time: 575100,
    token_index: 1356,
    start_char: 7677,
    end_char: 7678,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "agoraphobic",
    start_time: 575100,
    end_time: 575900,
    token_index: 1357,
    start_char: 7679,
    end_char: 7690,
    display_word: "agoraphobic",
    period_at_end: false
    },
    {
    word: "germaphobe",
    start_time: 575900,
    end_time: 576700,
    token_index: 1358,
    start_char: 7691,
    end_char: 7701,
    display_word: "germaphobe",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 576700,
    end_time: 576800,
    token_index: 1359,
    start_char: 7702,
    end_char: 7706,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 576800,
    end_time: 576900,
    token_index: 1360,
    start_char: 7707,
    end_char: 7708,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "fetish",
    start_time: 576900,
    end_time: 577300,
    token_index: 1361,
    start_char: 7709,
    end_char: 7715,
    display_word: "fetish",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 577300,
    end_time: 577400,
    token_index: 1362,
    start_char: 7716,
    end_char: 7719,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "face",
    start_time: 577400,
    end_time: 577700,
    token_index: 1363,
    start_char: 7720,
    end_char: 7724,
    display_word: "face",
    period_at_end: false
    },
    {
    word: "coverings.",
    start_time: 577700,
    end_time: 578200,
    token_index: 1364,
    start_char: 7725,
    end_char: 7734,
    display_word: "coverings",
    period_at_end: true
    },
    {
    word: "Now",
    start_time: 578200,
    end_time: 578300,
    token_index: 1365,
    start_char: 7736,
    end_char: 7739,
    display_word: "Now",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 578300,
    end_time: 578400,
    token_index: 1366,
    start_char: 7740,
    end_char: 7742,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "you'll",
    start_time: 578400,
    end_time: 578500,
    token_index: 1367,
    start_char: 7743,
    end_char: 7749,
    display_word: "you'll",
    period_at_end: false
    },
    {
    word: "excuse",
    start_time: 578500,
    end_time: 578900,
    token_index: 1368,
    start_char: 7750,
    end_char: 7756,
    display_word: "excuse",
    period_at_end: false
    },
    {
    word: "me,",
    start_time: 578900,
    end_time: 579200,
    token_index: 1369,
    start_char: 7757,
    end_char: 7760,
    display_word: "me,",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 579300,
    end_time: 579400,
    token_index: 1370,
    start_char: 7761,
    end_char: 7764,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "gonna",
    start_time: 579400,
    end_time: 579500,
    token_index: 1371,
    start_char: 7765,
    end_char: 7770,
    display_word: "gonna",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 579500,
    end_time: 579600,
    token_index: 1372,
    start_char: 7771,
    end_char: 7773,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "collect",
    start_time: 579600,
    end_time: 580000,
    token_index: 1373,
    start_char: 7774,
    end_char: 7781,
    display_word: "collect",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 580000,
    end_time: 580200,
    token_index: 1374,
    start_char: 7782,
    end_char: 7784,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "urine",
    start_time: 580200,
    end_time: 580500,
    token_index: 1375,
    start_char: 7785,
    end_char: 7790,
    display_word: "urine",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 580500,
    end_time: 580600,
    token_index: 1376,
    start_char: 7791,
    end_char: 7793,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 580600,
    end_time: 580700,
    token_index: 1377,
    start_char: 7794,
    end_char: 7795,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "jar.",
    start_time: 580700,
    end_time: 581300,
    token_index: 1378,
    start_char: 7796,
    end_char: 7799,
    display_word: "jar",
    period_at_end: true
    },
    {
    word: "Even",
    start_time: 584000,
    end_time: 584200,
    token_index: 1379,
    start_char: 7801,
    end_char: 7805,
    display_word: "Even",
    period_at_end: false
    },
    {
    word: "though",
    start_time: 584200,
    end_time: 584300,
    token_index: 1380,
    start_char: 7806,
    end_char: 7812,
    display_word: "though",
    period_at_end: false
    },
    {
    word: "Biden",
    start_time: 584300,
    end_time: 584600,
    token_index: 1381,
    start_char: 7813,
    end_char: 7818,
    display_word: "Biden",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 584600,
    end_time: 584700,
    token_index: 1382,
    start_char: 7819,
    end_char: 7821,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "leading.",
    start_time: 584700,
    end_time: 585000,
    token_index: 1383,
    start_char: 7822,
    end_char: 7829,
    display_word: "leading",
    period_at_end: true
    },
    {
    word: "Now.",
    start_time: 585000,
    end_time: 585300,
    token_index: 1384,
    start_char: 7831,
    end_char: 7834,
    display_word: "Now",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 585300,
    end_time: 585400,
    token_index: 1385,
    start_char: 7836,
    end_char: 7839,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "election",
    start_time: 585400,
    end_time: 585700,
    token_index: 1386,
    start_char: 7840,
    end_char: 7848,
    display_word: "election",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 585700,
    end_time: 585800,
    token_index: 1387,
    start_char: 7849,
    end_char: 7851,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "still",
    start_time: 585800,
    end_time: 586100,
    token_index: 1388,
    start_char: 7852,
    end_char: 7857,
    display_word: "still",
    period_at_end: false
    },
    {
    word: "almost",
    start_time: 586100,
    end_time: 586400,
    token_index: 1389,
    start_char: 7858,
    end_char: 7864,
    display_word: "almost",
    period_at_end: false
    },
    {
    word: "four",
    start_time: 586400,
    end_time: 586500,
    token_index: 1390,
    start_char: 7865,
    end_char: 7869,
    display_word: "four",
    period_at_end: false
    },
    {
    word: "months",
    start_time: 586500,
    end_time: 586800,
    token_index: 1391,
    start_char: 7870,
    end_char: 7876,
    display_word: "months",
    period_at_end: false
    },
    {
    word: "away",
    start_time: 586800,
    end_time: 587200,
    token_index: 1392,
    start_char: 7877,
    end_char: 7881,
    display_word: "away",
    period_at_end: false
    },
    {
    word: "A",
    start_time: 587200,
    end_time: 587300,
    token_index: 1393,
    start_char: 7882,
    end_char: 7883,
    display_word: "A",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 587300,
    end_time: 587400,
    token_index: 1394,
    start_char: 7884,
    end_char: 7887,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 587400,
    end_time: 587600,
    token_index: 1395,
    start_char: 7888,
    end_char: 7891,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "happen",
    start_time: 587600,
    end_time: 587900,
    token_index: 1396,
    start_char: 7892,
    end_char: 7898,
    display_word: "happen",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 587900,
    end_time: 588000,
    token_index: 1397,
    start_char: 7899,
    end_char: 7901,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "four",
    start_time: 588000,
    end_time: 588200,
    token_index: 1398,
    start_char: 7902,
    end_char: 7906,
    display_word: "four",
    period_at_end: false
    },
    {
    word: "months,",
    start_time: 588200,
    end_time: 588600,
    token_index: 1399,
    start_char: 7907,
    end_char: 7914,
    display_word: "months,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 588800,
    end_time: 588900,
    token_index: 1400,
    start_char: 7915,
    end_char: 7918,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 588900,
    end_time: 589000,
    token_index: 1401,
    start_char: 7919,
    end_char: 7923,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "where",
    start_time: 589000,
    end_time: 589300,
    token_index: 1402,
    start_char: 7924,
    end_char: 7929,
    display_word: "where",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 589300,
    end_time: 589400,
    token_index: 1403,
    start_char: 7930,
    end_char: 7931,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 589400,
    end_time: 589700,
    token_index: 1404,
    start_char: 7932,
    end_char: 7935,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "four",
    start_time: 589700,
    end_time: 589800,
    token_index: 1405,
    start_char: 7936,
    end_char: 7940,
    display_word: "four",
    period_at_end: false
    },
    {
    word: "months",
    start_time: 589800,
    end_time: 590000,
    token_index: 1406,
    start_char: 7941,
    end_char: 7947,
    display_word: "months",
    period_at_end: false
    },
    {
    word: "ago",
    start_time: 590000,
    end_time: 590600,
    token_index: 1407,
    start_char: 7948,
    end_char: 7951,
    display_word: "ago",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 590800,
    end_time: 591300,
    token_index: 1408,
    start_char: 7952,
    end_char: 7956,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "other",
    start_time: 591300,
    end_time: 591600,
    token_index: 1409,
    start_char: 7957,
    end_char: 7962,
    display_word: "other",
    period_at_end: false
    },
    {
    word: "people.",
    start_time: 591600,
    end_time: 592300,
    token_index: 1410,
    start_char: 7963,
    end_char: 7969,
    display_word: "people",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 592700,
    end_time: 592900,
    token_index: 1411,
    start_char: 7971,
    end_char: 7972,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 592900,
    end_time: 593200,
    token_index: 1412,
    start_char: 7973,
    end_char: 7976,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 593200,
    end_time: 593300,
    token_index: 1413,
    start_char: 7977,
    end_char: 7979,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "audience.",
    start_time: 593300,
    end_time: 594100,
    token_index: 1414,
    start_char: 7980,
    end_char: 7988,
    display_word: "audience",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 594400,
    end_time: 594700,
    token_index: 1415,
    start_char: 7990,
    end_char: 7991,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "touched",
    start_time: 594700,
    end_time: 595300,
    token_index: 1416,
    start_char: 7992,
    end_char: 7999,
    display_word: "touched",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 595300,
    end_time: 595600,
    token_index: 1417,
    start_char: 8000,
    end_char: 8005,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "hands",
    start_time: 595600,
    end_time: 596600,
    token_index: 1418,
    start_char: 8006,
    end_char: 8011,
    display_word: "hands",
    period_at_end: false
    },
    {
    word: "probably",
    start_time: 596800,
    end_time: 597200,
    token_index: 1419,
    start_char: 8012,
    end_char: 8020,
    display_word: "probably",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 597200,
    end_time: 597300,
    token_index: 1420,
    start_char: 8021,
    end_char: 8024,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 597300,
    end_time: 597400,
    token_index: 1421,
    start_char: 8025,
    end_char: 8026,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 597400,
    end_time: 597600,
    token_index: 1422,
    start_char: 8027,
    end_char: 8032,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "idea",
    start_time: 597600,
    end_time: 598000,
    token_index: 1423,
    start_char: 8033,
    end_char: 8037,
    display_word: "idea",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 598000,
    end_time: 598300,
    token_index: 1424,
    start_char: 8038,
    end_char: 8042,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 598300,
    end_time: 598700,
    token_index: 1425,
    start_char: 8043,
    end_char: 8047,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 599600,
    end_time: 599700,
    token_index: 1426,
    start_char: 8048,
    end_char: 8051,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 599700,
    end_time: 599700,
    token_index: 1427,
    start_char: 8052,
    end_char: 8053,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "won't",
    start_time: 599700,
    end_time: 599900,
    token_index: 1428,
    start_char: 8054,
    end_char: 8059,
    display_word: "won't",
    period_at_end: false
    },
    {
    word: "feel",
    start_time: 599900,
    end_time: 600100,
    token_index: 1429,
    start_char: 8060,
    end_char: 8064,
    display_word: "feel",
    period_at_end: false
    },
    {
    word: "confident",
    start_time: 600100,
    end_time: 600700,
    token_index: 1430,
    start_char: 8065,
    end_char: 8074,
    display_word: "confident",
    period_at_end: false
    },
    {
    word: "Biden",
    start_time: 600700,
    end_time: 601100,
    token_index: 1431,
    start_char: 8075,
    end_char: 8080,
    display_word: "Biden",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 601100,
    end_time: 601200,
    token_index: 1432,
    start_char: 8081,
    end_char: 8083,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 601200,
    end_time: 601400,
    token_index: 1433,
    start_char: 8084,
    end_char: 8087,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 601400,
    end_time: 601500,
    token_index: 1434,
    start_char: 8088,
    end_char: 8092,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "thing",
    start_time: 601500,
    end_time: 601700,
    token_index: 1435,
    start_char: 8093,
    end_char: 8098,
    display_word: "thing",
    period_at_end: false
    },
    {
    word: "until",
    start_time: 601700,
    end_time: 601900,
    token_index: 1436,
    start_char: 8099,
    end_char: 8104,
    display_word: "until",
    period_at_end: false
    },
    {
    word: "he's",
    start_time: 601900,
    end_time: 602100,
    token_index: 1437,
    start_char: 8105,
    end_char: 8109,
    display_word: "he's",
    period_at_end: false
    },
    {
    word: "giving",
    start_time: 602100,
    end_time: 602400,
    token_index: 1438,
    start_char: 8110,
    end_char: 8116,
    display_word: "giving",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 602400,
    end_time: 602600,
    token_index: 1439,
    start_char: 8117,
    end_char: 8120,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "first",
    start_time: 602600,
    end_time: 602900,
    token_index: 1440,
    start_char: 8121,
    end_char: 8126,
    display_word: "first",
    period_at_end: false
    },
    {
    word: "state",
    start_time: 602900,
    end_time: 603100,
    token_index: 1441,
    start_char: 8127,
    end_char: 8132,
    display_word: "state",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 603100,
    end_time: 603200,
    token_index: 1442,
    start_char: 8133,
    end_char: 8135,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 603200,
    end_time: 603300,
    token_index: 1443,
    start_char: 8136,
    end_char: 8139,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "union",
    start_time: 603300,
    end_time: 603800,
    token_index: 1444,
    start_char: 8140,
    end_char: 8145,
    display_word: "union",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 604000,
    end_time: 604100,
    token_index: 1445,
    start_char: 8146,
    end_char: 8149,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 604100,
    end_time: 604400,
    token_index: 1446,
    start_char: 8150,
    end_char: 8154,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 604400,
    end_time: 604600,
    token_index: 1447,
    start_char: 8155,
    end_char: 8159,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "part",
    start_time: 604600,
    end_time: 604800,
    token_index: 1448,
    start_char: 8160,
    end_char: 8164,
    display_word: "part",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 604800,
    end_time: 604900,
    token_index: 1449,
    start_char: 8165,
    end_char: 8167,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "you'll",
    start_time: 604900,
    end_time: 605000,
    token_index: 1450,
    start_char: 8168,
    end_char: 8174,
    display_word: "you'll",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 605000,
    end_time: 605200,
    token_index: 1451,
    start_char: 8175,
    end_char: 8177,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "thinking",
    start_time: 605200,
    end_time: 605600,
    token_index: 1452,
    start_char: 8178,
    end_char: 8186,
    display_word: "thinking",
    period_at_end: false
    },
    {
    word: "Comey",
    start_time: 605600,
    end_time: 606000,
    token_index: 1453,
    start_char: 8187,
    end_char: 8192,
    display_word: "Comey",
    period_at_end: false
    },
    {
    word: "could",
    start_time: 606000,
    end_time: 606200,
    token_index: 1454,
    start_char: 8193,
    end_char: 8198,
    display_word: "could",
    period_at_end: false
    },
    {
    word: "still",
    start_time: 606200,
    end_time: 606500,
    token_index: 1455,
    start_char: 8199,
    end_char: 8204,
    display_word: "still",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 606700,
    end_time: 607000,
    token_index: 1456,
    start_char: 8205,
    end_char: 8209,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "up.",
    start_time: 607000,
    end_time: 607400,
    token_index: 1457,
    start_char: 8210,
    end_char: 8212,
    display_word: "up",
    period_at_end: true
    },
    {
    word: "Of",
    start_time: 608800,
    end_time: 608900,
    token_index: 1458,
    start_char: 8214,
    end_char: 8216,
    display_word: "Of",
    period_at_end: false
    },
    {
    word: "course",
    start_time: 608900,
    end_time: 609200,
    token_index: 1459,
    start_char: 8217,
    end_char: 8223,
    display_word: "course",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 609200,
    end_time: 609400,
    token_index: 1460,
    start_char: 8224,
    end_char: 8229,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "doesn't",
    start_time: 609400,
    end_time: 609600,
    token_index: 1461,
    start_char: 8230,
    end_char: 8237,
    display_word: "doesn't",
    period_at_end: false
    },
    {
    word: "put",
    start_time: 609600,
    end_time: 609800,
    token_index: 1462,
    start_char: 8238,
    end_char: 8241,
    display_word: "put",
    period_at_end: false
    },
    {
    word: "too",
    start_time: 609800,
    end_time: 609900,
    token_index: 1463,
    start_char: 8242,
    end_char: 8245,
    display_word: "too",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 609900,
    end_time: 610100,
    token_index: 1464,
    start_char: 8246,
    end_char: 8250,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "stock",
    start_time: 610100,
    end_time: 610500,
    token_index: 1465,
    start_char: 8251,
    end_char: 8256,
    display_word: "stock",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 610500,
    end_time: 610600,
    token_index: 1466,
    start_char: 8257,
    end_char: 8259,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 610600,
    end_time: 610700,
    token_index: 1467,
    start_char: 8260,
    end_char: 8263,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "polls.",
    start_time: 610700,
    end_time: 611400,
    token_index: 1468,
    start_char: 8264,
    end_char: 8269,
    display_word: "polls",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 611800,
    end_time: 612000,
    token_index: 1469,
    start_char: 8271,
    end_char: 8272,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 612000,
    end_time: 612300,
    token_index: 1470,
    start_char: 8273,
    end_char: 8278,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 612300,
    end_time: 612400,
    token_index: 1471,
    start_char: 8279,
    end_char: 8283,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 612400,
    end_time: 612600,
    token_index: 1472,
    start_char: 8284,
    end_char: 8287,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "enthusiasm",
    start_time: 612600,
    end_time: 613500,
    token_index: 1473,
    start_char: 8288,
    end_char: 8298,
    display_word: "enthusiasm",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 613500,
    end_time: 614100,
    token_index: 1474,
    start_char: 8299,
    end_char: 8302,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 614900,
    end_time: 615200,
    token_index: 1475,
    start_char: 8303,
    end_char: 8305,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "greater",
    start_time: 615200,
    end_time: 615700,
    token_index: 1476,
    start_char: 8306,
    end_char: 8313,
    display_word: "greater",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 615700,
    end_time: 615900,
    token_index: 1477,
    start_char: 8314,
    end_char: 8317,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "maybe",
    start_time: 615900,
    end_time: 616300,
    token_index: 1478,
    start_char: 8318,
    end_char: 8323,
    display_word: "maybe",
    period_at_end: false
    },
    {
    word: "far",
    start_time: 616300,
    end_time: 616500,
    token_index: 1479,
    start_char: 8324,
    end_char: 8327,
    display_word: "far",
    period_at_end: false
    },
    {
    word: "greater",
    start_time: 616500,
    end_time: 616900,
    token_index: 1480,
    start_char: 8328,
    end_char: 8335,
    display_word: "greater",
    period_at_end: false
    },
    {
    word: "than",
    start_time: 616900,
    end_time: 617100,
    token_index: 1481,
    start_char: 8336,
    end_char: 8340,
    display_word: "than",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 617100,
    end_time: 617200,
    token_index: 1482,
    start_char: 8341,
    end_char: 8343,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 617200,
    end_time: 617400,
    token_index: 1483,
    start_char: 8344,
    end_char: 8347,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 617400,
    end_time: 617500,
    token_index: 1484,
    start_char: 8348,
    end_char: 8350,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "2016.",
    start_time: 617500,
    end_time: 618600,
    token_index: 1485,
    start_char: 8351,
    end_char: 8355,
    display_word: "2016",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 619200,
    end_time: 619200,
    token_index: 1486,
    start_char: 8357,
    end_char: 8358,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 619200,
    end_time: 619500,
    token_index: 1487,
    start_char: 8359,
    end_char: 8364,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 619500,
    end_time: 619500,
    token_index: 1488,
    start_char: 8365,
    end_char: 8366,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 619500,
    end_time: 619700,
    token_index: 1489,
    start_char: 8367,
    end_char: 8370,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 619700,
    end_time: 619800,
    token_index: 1490,
    start_char: 8371,
    end_char: 8373,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 619800,
    end_time: 620100,
    token_index: 1491,
    start_char: 8374,
    end_char: 8380,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 620100,
    end_time: 620300,
    token_index: 1492,
    start_char: 8381,
    end_char: 8386,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "want",
    start_time: 620300,
    end_time: 620400,
    token_index: 1493,
    start_char: 8387,
    end_char: 8391,
    display_word: "want",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 620400,
    end_time: 620500,
    token_index: 1494,
    start_char: 8392,
    end_char: 8394,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "talk",
    start_time: 620500,
    end_time: 620700,
    token_index: 1495,
    start_char: 8395,
    end_char: 8399,
    display_word: "talk",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 620700,
    end_time: 621100,
    token_index: 1496,
    start_char: 8400,
    end_char: 8405,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "it.",
    start_time: 621100,
    end_time: 621300,
    token_index: 1497,
    start_char: 8406,
    end_char: 8408,
    display_word: "it",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 621300,
    end_time: 621400,
    token_index: 1498,
    start_char: 8410,
    end_char: 8411,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 621400,
    end_time: 621600,
    token_index: 1499,
    start_char: 8412,
    end_char: 8417,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "they're",
    start_time: 621600,
    end_time: 621700,
    token_index: 1500,
    start_char: 8418,
    end_char: 8425,
    display_word: "they're",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 621700,
    end_time: 621900,
    token_index: 1501,
    start_char: 8426,
    end_char: 8429,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 621900,
    end_time: 622000,
    token_index: 1502,
    start_char: 8430,
    end_char: 8435,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 622000,
    end_time: 622400,
    token_index: 1503,
    start_char: 8436,
    end_char: 8438,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "say.",
    start_time: 623000,
    end_time: 623300,
    token_index: 1504,
    start_char: 8439,
    end_char: 8442,
    display_word: "say",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 623300,
    end_time: 623600,
    token_index: 1505,
    start_char: 8444,
    end_char: 8448,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 623600,
    end_time: 623700,
    token_index: 1506,
    start_char: 8449,
    end_char: 8452,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 623700,
    end_time: 623900,
    token_index: 1507,
    start_char: 8453,
    end_char: 8456,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 623900,
    end_time: 624200,
    token_index: 1508,
    start_char: 8457,
    end_char: 8462,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 624200,
    end_time: 624300,
    token_index: 1509,
    start_char: 8463,
    end_char: 8465,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 624300,
    end_time: 624500,
    token_index: 1510,
    start_char: 8466,
    end_char: 8469,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "Trump.",
    start_time: 624500,
    end_time: 624900,
    token_index: 1511,
    start_char: 8470,
    end_char: 8475,
    display_word: "Trump",
    period_at_end: true
    },
    {
    word: "If",
    start_time: 624900,
    end_time: 625100,
    token_index: 1512,
    start_char: 8477,
    end_char: 8479,
    display_word: "If",
    period_at_end: false
    },
    {
    word: "you're",
    start_time: 625100,
    end_time: 625200,
    token_index: 1513,
    start_char: 8480,
    end_char: 8486,
    display_word: "you're",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 625200,
    end_time: 625500,
    token_index: 1514,
    start_char: 8487,
    end_char: 8492,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "such",
    start_time: 625500,
    end_time: 625800,
    token_index: 1515,
    start_char: 8493,
    end_char: 8497,
    display_word: "such",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 625800,
    end_time: 625800,
    token_index: 1516,
    start_char: 8498,
    end_char: 8499,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 625800,
    end_time: 626100,
    token_index: 1517,
    start_char: 8500,
    end_char: 8505,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "job",
    start_time: 626100,
    end_time: 626900,
    token_index: 1518,
    start_char: 8506,
    end_char: 8509,
    display_word: "job",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 627000,
    end_time: 627100,
    token_index: 1519,
    start_char: 8510,
    end_char: 8514,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "why",
    start_time: 627100,
    end_time: 627300,
    token_index: 1520,
    start_char: 8515,
    end_char: 8518,
    display_word: "why",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 627300,
    end_time: 627400,
    token_index: 1521,
    start_char: 8519,
    end_char: 8522,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 627400,
    end_time: 627600,
    token_index: 1522,
    start_char: 8523,
    end_char: 8527,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "embarrassed",
    start_time: 627600,
    end_time: 628100,
    token_index: 1523,
    start_char: 8528,
    end_char: 8539,
    display_word: "embarrassed",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 628100,
    end_time: 628200,
    token_index: 1524,
    start_char: 8540,
    end_char: 8542,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 628200,
    end_time: 628500,
    token_index: 1525,
    start_char: 8543,
    end_char: 8546,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 628500,
    end_time: 628600,
    token_index: 1526,
    start_char: 8547,
    end_char: 8551,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "name",
    start_time: 628600,
    end_time: 629200,
    token_index: 1527,
    start_char: 8552,
    end_char: 8556,
    display_word: "name",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 629400,
    end_time: 629600,
    token_index: 1528,
    start_char: 8557,
    end_char: 8561,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 629600,
    end_time: 629700,
    token_index: 1529,
    start_char: 8562,
    end_char: 8565,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "propose",
    start_time: 629700,
    end_time: 630200,
    token_index: 1530,
    start_char: 8566,
    end_char: 8573,
    display_word: "propose",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 630200,
    end_time: 630300,
    token_index: 1531,
    start_char: 8574,
    end_char: 8576,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "someone",
    start_time: 630300,
    end_time: 630700,
    token_index: 1532,
    start_char: 8577,
    end_char: 8584,
    display_word: "someone",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 630700,
    end_time: 630800,
    token_index: 1533,
    start_char: 8585,
    end_char: 8588,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 630800,
    end_time: 631000,
    token_index: 1534,
    start_char: 8589,
    end_char: 8594,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "generally",
    start_time: 631000,
    end_time: 631400,
    token_index: 1535,
    start_char: 8595,
    end_char: 8604,
    display_word: "generally",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 631400,
    end_time: 631900,
    token_index: 1536,
    start_char: 8605,
    end_char: 8608,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "Barbara?",
    start_time: 632300,
    end_time: 632900,
    token_index: 1537,
    start_char: 8609,
    end_char: 8617,
    display_word: "Barbara?",
    period_at_end: false
    },
    {
    word: "Will",
    start_time: 633200,
    end_time: 633400,
    token_index: 1538,
    start_char: 8618,
    end_char: 8622,
    display_word: "Will",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 633400,
    end_time: 633500,
    token_index: 1539,
    start_char: 8623,
    end_char: 8626,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "spend",
    start_time: 633500,
    end_time: 633800,
    token_index: 1540,
    start_char: 8627,
    end_char: 8632,
    display_word: "spend",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 633800,
    end_time: 633900,
    token_index: 1541,
    start_char: 8633,
    end_char: 8636,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "rest",
    start_time: 633900,
    end_time: 634200,
    token_index: 1542,
    start_char: 8637,
    end_char: 8641,
    display_word: "rest",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 634200,
    end_time: 634300,
    token_index: 1543,
    start_char: 8642,
    end_char: 8644,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 634300,
    end_time: 634400,
    token_index: 1544,
    start_char: 8645,
    end_char: 8649,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "life",
    start_time: 634400,
    end_time: 634700,
    token_index: 1545,
    start_char: 8650,
    end_char: 8654,
    display_word: "life",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 634700,
    end_time: 634800,
    token_index: 1546,
    start_char: 8655,
    end_char: 8659,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 634800,
    end_time: 635000,
    token_index: 1547,
    start_char: 8660,
    end_char: 8662,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "hiding",
    start_time: 635000,
    end_time: 635400,
    token_index: 1548,
    start_char: 8663,
    end_char: 8669,
    display_word: "hiding",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 635400,
    end_time: 635500,
    token_index: 1549,
    start_char: 8670,
    end_char: 8672,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 635500,
    end_time: 635600,
    token_index: 1550,
    start_char: 8673,
    end_char: 8676,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Attic?",
    start_time: 635600,
    end_time: 635900,
    token_index: 1551,
    start_char: 8677,
    end_char: 8683,
    display_word: "Attic?",
    period_at_end: false
    },
    {
    word: "So",
    start_time: 635900,
    end_time: 636000,
    token_index: 1552,
    start_char: 8684,
    end_char: 8686,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "no",
    start_time: 636000,
    end_time: 636200,
    token_index: 1553,
    start_char: 8687,
    end_char: 8689,
    display_word: "no",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 636200,
    end_time: 636400,
    token_index: 1554,
    start_char: 8690,
    end_char: 8693,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "knows",
    start_time: 636400,
    end_time: 636600,
    token_index: 1555,
    start_char: 8694,
    end_char: 8699,
    display_word: "knows",
    period_at_end: false
    },
    {
    word: "we're",
    start_time: 636600,
    end_time: 636700,
    token_index: 1556,
    start_char: 8700,
    end_char: 8705,
    display_word: "we're",
    period_at_end: false
    },
    {
    word: "married.",
    start_time: 636700,
    end_time: 637400,
    token_index: 1557,
    start_char: 8706,
    end_char: 8713,
    display_word: "married",
    period_at_end: true
    },
    {
    word: "In",
    start_time: 641100,
    end_time: 641200,
    token_index: 1558,
    start_char: 8715,
    end_char: 8717,
    display_word: "In",
    period_at_end: false
    },
    {
    word: "fact",
    start_time: 641200,
    end_time: 641600,
    token_index: 1559,
    start_char: 8718,
    end_char: 8722,
    display_word: "fact",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 641600,
    end_time: 641800,
    token_index: 1560,
    start_char: 8723,
    end_char: 8728,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 641800,
    end_time: 641900,
    token_index: 1561,
    start_char: 8729,
    end_char: 8731,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 641900,
    end_time: 642100,
    token_index: 1562,
    start_char: 8732,
    end_char: 8737,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 642100,
    end_time: 642400,
    token_index: 1563,
    start_char: 8738,
    end_char: 8740,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "well",
    start_time: 642400,
    end_time: 642700,
    token_index: 1564,
    start_char: 8741,
    end_char: 8745,
    display_word: "well",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 642700,
    end_time: 642800,
    token_index: 1565,
    start_char: 8746,
    end_char: 8748,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 642800,
    end_time: 642900,
    token_index: 1566,
    start_char: 8749,
    end_char: 8752,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "polls",
    start_time: 642900,
    end_time: 643500,
    token_index: 1567,
    start_char: 8753,
    end_char: 8758,
    display_word: "polls",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 643700,
    end_time: 643800,
    token_index: 1568,
    start_char: 8759,
    end_char: 8763,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "yesterday",
    start_time: 643800,
    end_time: 644600,
    token_index: 1569,
    start_char: 8764,
    end_char: 8773,
    display_word: "yesterday",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 644700,
    end_time: 644800,
    token_index: 1570,
    start_char: 8774,
    end_char: 8776,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "replaced",
    start_time: 644800,
    end_time: 645400,
    token_index: 1571,
    start_char: 8777,
    end_char: 8785,
    display_word: "replaced",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 645400,
    end_time: 645600,
    token_index: 1572,
    start_char: 8786,
    end_char: 8789,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "campaign",
    start_time: 645600,
    end_time: 646200,
    token_index: 1573,
    start_char: 8790,
    end_char: 8798,
    display_word: "campaign",
    period_at_end: false
    },
    {
    word: "manager.",
    start_time: 646200,
    end_time: 646900,
    token_index: 1574,
    start_char: 8799,
    end_char: 8806,
    display_word: "manager",
    period_at_end: true
    },
    {
    word: "Oh,",
    start_time: 647100,
    end_time: 647300,
    token_index: 1575,
    start_char: 8808,
    end_char: 8811,
    display_word: "Oh,",
    period_at_end: false
    },
    {
    word: "yeah,",
    start_time: 647300,
    end_time: 648100,
    token_index: 1576,
    start_char: 8812,
    end_char: 8817,
    display_word: "yeah,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 648300,
    end_time: 648500,
    token_index: 1577,
    start_char: 8818,
    end_char: 8821,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "always",
    start_time: 648500,
    end_time: 648700,
    token_index: 1578,
    start_char: 8822,
    end_char: 8828,
    display_word: "always",
    period_at_end: false
    },
    {
    word: "replace",
    start_time: 648700,
    end_time: 649100,
    token_index: 1579,
    start_char: 8829,
    end_char: 8836,
    display_word: "replace",
    period_at_end: false
    },
    {
    word: "someone",
    start_time: 649100,
    end_time: 649400,
    token_index: 1580,
    start_char: 8837,
    end_char: 8844,
    display_word: "someone",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 649400,
    end_time: 649600,
    token_index: 1581,
    start_char: 8845,
    end_char: 8849,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 649600,
    end_time: 649900,
    token_index: 1582,
    start_char: 8850,
    end_char: 8856,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 649900,
    end_time: 650000,
    token_index: 1583,
    start_char: 8857,
    end_char: 8860,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 650000,
    end_time: 650400,
    token_index: 1584,
    start_char: 8861,
    end_char: 8866,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "great.",
    start_time: 650400,
    end_time: 651100,
    token_index: 1585,
    start_char: 8867,
    end_char: 8872,
    display_word: "great",
    period_at_end: true
    },
    {
    word: "This",
    start_time: 651200,
    end_time: 651400,
    token_index: 1586,
    start_char: 8874,
    end_char: 8878,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "soups",
    start_time: 651400,
    end_time: 651800,
    token_index: 1587,
    start_char: 8879,
    end_char: 8884,
    display_word: "soups",
    period_at_end: false
    },
    {
    word: "delicious",
    start_time: 651800,
    end_time: 652800,
    token_index: 1588,
    start_char: 8885,
    end_char: 8894,
    display_word: "delicious",
    period_at_end: false
    },
    {
    word: "arrest",
    start_time: 652800,
    end_time: 653400,
    token_index: 1589,
    start_char: 8895,
    end_char: 8901,
    display_word: "arrest",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 653400,
    end_time: 653500,
    token_index: 1590,
    start_char: 8902,
    end_char: 8904,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 653500,
    end_time: 653600,
    token_index: 1591,
    start_char: 8905,
    end_char: 8908,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chef.",
    start_time: 653600,
    end_time: 654400,
    token_index: 1592,
    start_char: 8909,
    end_char: 8913,
    display_word: "chef",
    period_at_end: true
    },
    {
    word: "Guy",
    start_time: 656200,
    end_time: 656400,
    token_index: 1593,
    start_char: 8915,
    end_char: 8918,
    display_word: "Guy",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 656400,
    end_time: 656700,
    token_index: 1594,
    start_char: 8919,
    end_char: 8924,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "pushed",
    start_time: 656700,
    end_time: 656900,
    token_index: 1595,
    start_char: 8925,
    end_char: 8931,
    display_word: "pushed",
    period_at_end: false
    },
    {
    word: "aside",
    start_time: 656900,
    end_time: 657300,
    token_index: 1596,
    start_char: 8932,
    end_char: 8937,
    display_word: "aside",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 657300,
    end_time: 657500,
    token_index: 1597,
    start_char: 8938,
    end_char: 8941,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "former",
    start_time: 657800,
    end_time: 658300,
    token_index: 1598,
    start_char: 8942,
    end_char: 8948,
    display_word: "former",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 658300,
    end_time: 658700,
    token_index: 1599,
    start_char: 8949,
    end_char: 8954,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "campaign",
    start_time: 658700,
    end_time: 659200,
    token_index: 1600,
    start_char: 8955,
    end_char: 8963,
    display_word: "campaign",
    period_at_end: false
    },
    {
    word: "manager",
    start_time: 659200,
    end_time: 659800,
    token_index: 1601,
    start_char: 8964,
    end_char: 8971,
    display_word: "manager",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 659800,
    end_time: 659900,
    token_index: 1602,
    start_char: 8972,
    end_char: 8975,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 659900,
    end_time: 660000,
    token_index: 1603,
    start_char: 8976,
    end_char: 8979,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "official",
    start_time: 660000,
    end_time: 660400,
    token_index: 1604,
    start_char: 8980,
    end_char: 8988,
    display_word: "official",
    period_at_end: false
    },
    {
    word: "template",
    start_time: 660400,
    end_time: 661000,
    token_index: 1605,
    start_char: 8989,
    end_char: 8997,
    display_word: "template",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 661000,
    end_time: 661100,
    token_index: 1606,
    start_char: 8998,
    end_char: 9001,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 661100,
    end_time: 661200,
    token_index: 1607,
    start_char: 9002,
    end_char: 9005,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "NFL",
    start_time: 661200,
    end_time: 661600,
    token_index: 1608,
    start_char: 9006,
    end_char: 9009,
    display_word: "NFL",
    period_at_end: false
    },
    {
    word: "football",
    start_time: 661600,
    end_time: 662300,
    token_index: 1609,
    start_char: 9010,
    end_char: 9018,
    display_word: "football",
    period_at_end: false
    },
    {
    word: "Brad",
    start_time: 662400,
    end_time: 662800,
    token_index: 1610,
    start_char: 9019,
    end_char: 9023,
    display_word: "Brad",
    period_at_end: false
    },
    {
    word: "par.",
    start_time: 662800,
    end_time: 663000,
    token_index: 1611,
    start_char: 9024,
    end_char: 9027,
    display_word: "par",
    period_at_end: true
    },
    {
    word: "Scale.",
    start_time: 663000,
    end_time: 663400,
    token_index: 1612,
    start_char: 9029,
    end_char: 9034,
    display_word: "Scale",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 663400,
    end_time: 663500,
    token_index: 1613,
    start_char: 9036,
    end_char: 9039,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "may",
    start_time: 663500,
    end_time: 663600,
    token_index: 1614,
    start_char: 9040,
    end_char: 9043,
    display_word: "may",
    period_at_end: false
    },
    {
    word: "remember",
    start_time: 663600,
    end_time: 664100,
    token_index: 1615,
    start_char: 9044,
    end_char: 9052,
    display_word: "remember",
    period_at_end: false
    },
    {
    word: "par",
    start_time: 664100,
    end_time: 664300,
    token_index: 1616,
    start_char: 9053,
    end_char: 9056,
    display_word: "par",
    period_at_end: false
    },
    {
    word: "scale",
    start_time: 664300,
    end_time: 664700,
    token_index: 1617,
    start_char: 9057,
    end_char: 9062,
    display_word: "scale",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 664700,
    end_time: 665100,
    token_index: 1618,
    start_char: 9063,
    end_char: 9067,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 665400,
    end_time: 665800,
    token_index: 1619,
    start_char: 9068,
    end_char: 9072,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "Tweet",
    start_time: 665800,
    end_time: 666100,
    token_index: 1620,
    start_char: 9073,
    end_char: 9078,
    display_word: "Tweet",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 666100,
    end_time: 666400,
    token_index: 1621,
    start_char: 9079,
    end_char: 9083,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 666400,
    end_time: 666500,
    token_index: 1622,
    start_char: 9084,
    end_char: 9086,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "May",
    start_time: 666500,
    end_time: 667000,
    token_index: 1623,
    start_char: 9087,
    end_char: 9090,
    display_word: "May",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 667200,
    end_time: 667300,
    token_index: 1624,
    start_char: 9091,
    end_char: 9094,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "nearly",
    start_time: 667300,
    end_time: 667700,
    token_index: 1625,
    start_char: 9095,
    end_char: 9101,
    display_word: "nearly",
    period_at_end: false
    },
    {
    word: "three",
    start_time: 667700,
    end_time: 668000,
    token_index: 1626,
    start_char: 9102,
    end_char: 9107,
    display_word: "three",
    period_at_end: false
    },
    {
    word: "years.",
    start_time: 668000,
    end_time: 668300,
    token_index: 1627,
    start_char: 9108,
    end_char: 9113,
    display_word: "years",
    period_at_end: true
    },
    {
    word: "We've",
    start_time: 668300,
    end_time: 668600,
    token_index: 1628,
    start_char: 9115,
    end_char: 9120,
    display_word: "We've",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 668600,
    end_time: 668700,
    token_index: 1629,
    start_char: 9121,
    end_char: 9125,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "building",
    start_time: 668700,
    end_time: 669100,
    token_index: 1630,
    start_char: 9126,
    end_char: 9134,
    display_word: "building",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 669100,
    end_time: 669200,
    token_index: 1631,
    start_char: 9135,
    end_char: 9136,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "juggernaut",
    start_time: 669200,
    end_time: 669800,
    token_index: 1632,
    start_char: 9137,
    end_char: 9147,
    display_word: "juggernaut",
    period_at_end: false
    },
    {
    word: "campaign",
    start_time: 669800,
    end_time: 670400,
    token_index: 1633,
    start_char: 9148,
    end_char: 9156,
    display_word: "campaign",
    period_at_end: false
    },
    {
    word: "Death",
    start_time: 670500,
    end_time: 670700,
    token_index: 1634,
    start_char: 9157,
    end_char: 9162,
    display_word: "Death",
    period_at_end: false
    },
    {
    word: "Star.",
    start_time: 670700,
    end_time: 671100,
    token_index: 1635,
    start_char: 9163,
    end_char: 9167,
    display_word: "Star",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 671300,
    end_time: 671400,
    token_index: 1636,
    start_char: 9169,
    end_char: 9171,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 671400,
    end_time: 671600,
    token_index: 1637,
    start_char: 9172,
    end_char: 9174,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "firing",
    start_time: 671600,
    end_time: 672000,
    token_index: 1638,
    start_char: 9175,
    end_char: 9181,
    display_word: "firing",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 672000,
    end_time: 672200,
    token_index: 1639,
    start_char: 9182,
    end_char: 9184,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 672200,
    end_time: 672300,
    token_index: 1640,
    start_char: 9185,
    end_char: 9188,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "cylinders",
    start_time: 672300,
    end_time: 672800,
    token_index: 1641,
    start_char: 9189,
    end_char: 9198,
    display_word: "cylinders",
    period_at_end: false
    },
    {
    word: "data",
    start_time: 672800,
    end_time: 673200,
    token_index: 1642,
    start_char: 9199,
    end_char: 9203,
    display_word: "data",
    period_at_end: false
    },
    {
    word: "digital",
    start_time: 673200,
    end_time: 673600,
    token_index: 1643,
    start_char: 9204,
    end_char: 9211,
    display_word: "digital",
    period_at_end: false
    },
    {
    word: "TV,",
    start_time: 673600,
    end_time: 674100,
    token_index: 1644,
    start_char: 9212,
    end_char: 9215,
    display_word: "TV,",
    period_at_end: false
    },
    {
    word: "political",
    start_time: 674100,
    end_time: 674600,
    token_index: 1645,
    start_char: 9216,
    end_char: 9225,
    display_word: "political",
    period_at_end: false
    },
    {
    word: "surrogates",
    start_time: 674600,
    end_time: 675000,
    token_index: 1646,
    start_char: 9226,
    end_char: 9236,
    display_word: "surrogates",
    period_at_end: false
    },
    {
    word: "coalition's",
    start_time: 675000,
    end_time: 675700,
    token_index: 1647,
    start_char: 9237,
    end_char: 9248,
    display_word: "coalition's",
    period_at_end: false
    },
    {
    word: "Etc",
    start_time: 675700,
    end_time: 676600,
    token_index: 1648,
    start_char: 9249,
    end_char: 9252,
    display_word: "Etc",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 676600,
    end_time: 676800,
    token_index: 1649,
    start_char: 9253,
    end_char: 9255,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 676800,
    end_time: 676800,
    token_index: 1650,
    start_char: 9256,
    end_char: 9257,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "few",
    start_time: 676800,
    end_time: 677000,
    token_index: 1651,
    start_char: 9258,
    end_char: 9261,
    display_word: "few",
    period_at_end: false
    },
    {
    word: "days.",
    start_time: 677000,
    end_time: 677400,
    token_index: 1652,
    start_char: 9262,
    end_char: 9266,
    display_word: "days",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 677400,
    end_time: 677500,
    token_index: 1653,
    start_char: 9268,
    end_char: 9270,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "start",
    start_time: 677500,
    end_time: 677800,
    token_index: 1654,
    start_char: 9271,
    end_char: 9276,
    display_word: "start",
    period_at_end: false
    },
    {
    word: "pressing",
    start_time: 677800,
    end_time: 678200,
    token_index: 1655,
    start_char: 9277,
    end_char: 9285,
    display_word: "pressing",
    period_at_end: false
    },
    {
    word: "fire",
    start_time: 678200,
    end_time: 678800,
    token_index: 1656,
    start_char: 9286,
    end_char: 9290,
    display_word: "fire",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 678800,
    end_time: 678900,
    token_index: 1657,
    start_char: 9291,
    end_char: 9294,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 678900,
    end_time: 679000,
    token_index: 1658,
    start_char: 9295,
    end_char: 9298,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "first",
    start_time: 679000,
    end_time: 679300,
    token_index: 1659,
    start_char: 9299,
    end_char: 9304,
    display_word: "first",
    period_at_end: false
    },
    {
    word: "time.",
    start_time: 679300,
    end_time: 679900,
    token_index: 1660,
    start_char: 9305,
    end_char: 9309,
    display_word: "time",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 680500,
    end_time: 680700,
    token_index: 1661,
    start_char: 9311,
    end_char: 9316,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 680700,
    end_time: 680800,
    token_index: 1662,
    start_char: 9317,
    end_char: 9318,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "believe",
    start_time: 680800,
    end_time: 681100,
    token_index: 1663,
    start_char: 9319,
    end_char: 9326,
    display_word: "believe",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 681100,
    end_time: 681200,
    token_index: 1664,
    start_char: 9327,
    end_char: 9329,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 681200,
    end_time: 681400,
    token_index: 1665,
    start_char: 9330,
    end_char: 9334,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "footage",
    start_time: 681400,
    end_time: 681800,
    token_index: 1666,
    start_char: 9335,
    end_char: 9342,
    display_word: "footage",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 681800,
    end_time: 681900,
    token_index: 1667,
    start_char: 9343,
    end_char: 9345,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 681900,
    end_time: 682000,
    token_index: 1668,
    start_char: 9346,
    end_char: 9349,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "moment",
    start_time: 682000,
    end_time: 682300,
    token_index: 1669,
    start_char: 9350,
    end_char: 9356,
    display_word: "moment",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 682300,
    end_time: 682800,
    token_index: 1670,
    start_char: 9357,
    end_char: 9362,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "canned",
    start_time: 682800,
    end_time: 683300,
    token_index: 1671,
    start_char: 9363,
    end_char: 9369,
    display_word: "canned",
    period_at_end: false
    },
    {
    word: "par",
    start_time: 683300,
    end_time: 683500,
    token_index: 1672,
    start_char: 9370,
    end_char: 9373,
    display_word: "par",
    period_at_end: false
    },
    {
    word: "scale.",
    start_time: 683500,
    end_time: 684200,
    token_index: 1673,
    start_char: 9374,
    end_char: 9379,
    display_word: "scale",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 684800,
    end_time: 685100,
    token_index: 1674,
    start_char: 9381,
    end_char: 9382,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "find",
    start_time: 685100,
    end_time: 685500,
    token_index: 1675,
    start_char: 9383,
    end_char: 9387,
    display_word: "find",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 685500,
    end_time: 685700,
    token_index: 1676,
    start_char: 9388,
    end_char: 9390,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "life.",
    start_time: 685700,
    end_time: 685800,
    token_index: 1677,
    start_char: 9391,
    end_char: 9395,
    display_word: "life",
    period_at_end: true
    },
    {
    word: "Game",
    start_time: 686000,
    end_time: 686400,
    token_index: 1678,
    start_char: 9397,
    end_char: 9401,
    display_word: "Game",
    period_at_end: false
    },
    {
    word: "numbers",
    start_time: 686400,
    end_time: 687200,
    token_index: 1679,
    start_char: 9402,
    end_char: 9409,
    display_word: "numbers",
    period_at_end: false
    },
    {
    word: "disturb",
    start_time: 687600,
    end_time: 688500,
    token_index: 1680,
    start_char: 9410,
    end_char: 9417,
    display_word: "disturb",
    period_at_end: false
    },
    {
    word: "obviously,",
    start_time: 689300,
    end_time: 689700,
    token_index: 1681,
    start_char: 9418,
    end_char: 9428,
    display_word: "obviously,",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 689700,
    end_time: 689900,
    token_index: 1682,
    start_char: 9429,
    end_char: 9435,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 689900,
    end_time: 690100,
    token_index: 1683,
    start_char: 9436,
    end_char: 9439,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "real",
    start_time: 690100,
    end_time: 690300,
    token_index: 1684,
    start_char: 9440,
    end_char: 9444,
    display_word: "real",
    period_at_end: false
    },
    {
    word: "footage.",
    start_time: 690300,
    end_time: 691100,
    token_index: 1685,
    start_char: 9445,
    end_char: 9452,
    display_word: "footage",
    period_at_end: true
    },
    {
    word: "Trump",
    start_time: 691100,
    end_time: 691400,
    token_index: 1686,
    start_char: 9454,
    end_char: 9459,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "wouldn't",
    start_time: 691400,
    end_time: 691700,
    token_index: 1687,
    start_char: 9460,
    end_char: 9468,
    display_word: "wouldn't",
    period_at_end: false
    },
    {
    word: "wear",
    start_time: 691700,
    end_time: 691900,
    token_index: 1688,
    start_char: 9469,
    end_char: 9473,
    display_word: "wear",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 691900,
    end_time: 692000,
    token_index: 1689,
    start_char: 9474,
    end_char: 9475,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "mask.",
    start_time: 692000,
    end_time: 692900,
    token_index: 1690,
    start_char: 9476,
    end_char: 9480,
    display_word: "mask",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 693700,
    end_time: 693800,
    token_index: 1691,
    start_char: 9482,
    end_char: 9485,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "according",
    start_time: 693800,
    end_time: 694100,
    token_index: 1692,
    start_char: 9486,
    end_char: 9495,
    display_word: "according",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 694100,
    end_time: 694200,
    token_index: 1693,
    start_char: 9496,
    end_char: 9498,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "insiders",
    start_time: 694200,
    end_time: 694800,
    token_index: 1694,
    start_char: 9499,
    end_char: 9507,
    display_word: "insiders",
    period_at_end: false
    },
    {
    word: "par",
    start_time: 695000,
    end_time: 695200,
    token_index: 1695,
    start_char: 9508,
    end_char: 9511,
    display_word: "par",
    period_at_end: false
    },
    {
    word: "scale",
    start_time: 695200,
    end_time: 695400,
    token_index: 1696,
    start_char: 9512,
    end_char: 9517,
    display_word: "scale",
    period_at_end: false
    },
    {
    word: "hasn't",
    start_time: 695400,
    end_time: 695700,
    token_index: 1697,
    start_char: 9518,
    end_char: 9524,
    display_word: "hasn't",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 695700,
    end_time: 695800,
    token_index: 1698,
    start_char: 9525,
    end_char: 9529,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "completely",
    start_time: 695800,
    end_time: 696500,
    token_index: 1699,
    start_char: 9530,
    end_char: 9540,
    display_word: "completely",
    period_at_end: false
    },
    {
    word: "choked",
    start_time: 696500,
    end_time: 696900,
    token_index: 1700,
    start_char: 9541,
    end_char: 9547,
    display_word: "choked",
    period_at_end: false
    },
    {
    word: "out.",
    start_time: 696900,
    end_time: 697400,
    token_index: 1701,
    start_char: 9548,
    end_char: 9551,
    display_word: "out",
    period_at_end: true
    },
    {
    word: "He's",
    start_time: 697500,
    end_time: 697600,
    token_index: 1702,
    start_char: 9553,
    end_char: 9557,
    display_word: "He's",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 697600,
    end_time: 697900,
    token_index: 1703,
    start_char: 9558,
    end_char: 9562,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 697900,
    end_time: 698100,
    token_index: 1704,
    start_char: 9563,
    end_char: 9567,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "demoted",
    start_time: 698100,
    end_time: 698700,
    token_index: 1705,
    start_char: 9568,
    end_char: 9575,
    display_word: "demoted",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 698700,
    end_time: 699300,
    token_index: 1706,
    start_char: 9576,
    end_char: 9583,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 699300,
    end_time: 699900,
    token_index: 1707,
    start_char: 9584,
    end_char: 9589,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 699900,
    end_time: 700300,
    token_index: 1708,
    start_char: 9590,
    end_char: 9596,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "can't",
    start_time: 700300,
    end_time: 700700,
    token_index: 1709,
    start_char: 9597,
    end_char: 9602,
    display_word: "can't",
    period_at_end: false
    },
    {
    word: "fire",
    start_time: 700700,
    end_time: 701100,
    token_index: 1710,
    start_char: 9603,
    end_char: 9607,
    display_word: "fire",
    period_at_end: false
    },
    {
    word: "Brad.",
    start_time: 701100,
    end_time: 701900,
    token_index: 1711,
    start_char: 9608,
    end_char: 9612,
    display_word: "Brad",
    period_at_end: true
    },
    {
    word: "He",
    start_time: 702500,
    end_time: 702700,
    token_index: 1712,
    start_char: 9614,
    end_char: 9616,
    display_word: "He",
    period_at_end: false
    },
    {
    word: "knows",
    start_time: 702700,
    end_time: 703200,
    token_index: 1713,
    start_char: 9617,
    end_char: 9622,
    display_word: "knows",
    period_at_end: false
    },
    {
    word: "too",
    start_time: 703200,
    end_time: 703400,
    token_index: 1714,
    start_char: 9623,
    end_char: 9626,
    display_word: "too",
    period_at_end: false
    },
    {
    word: "much.",
    start_time: 703400,
    end_time: 704100,
    token_index: 1715,
    start_char: 9627,
    end_char: 9631,
    display_word: "much",
    period_at_end: true
    },
    {
    word: "Look",
    start_time: 704600,
    end_time: 704900,
    token_index: 1716,
    start_char: 9633,
    end_char: 9637,
    display_word: "Look",
    period_at_end: false
    },
    {
    word: "guys.",
    start_time: 704900,
    end_time: 705600,
    token_index: 1717,
    start_char: 9638,
    end_char: 9642,
    display_word: "guys",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 705600,
    end_time: 705800,
    token_index: 1718,
    start_char: 9644,
    end_char: 9646,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "can't",
    start_time: 705800,
    end_time: 706100,
    token_index: 1719,
    start_char: 9647,
    end_char: 9652,
    display_word: "can't",
    period_at_end: false
    },
    {
    word: "fire",
    start_time: 706100,
    end_time: 706500,
    token_index: 1720,
    start_char: 9653,
    end_char: 9657,
    display_word: "fire",
    period_at_end: false
    },
    {
    word: "Brad",
    start_time: 706500,
    end_time: 706800,
    token_index: 1721,
    start_char: 9658,
    end_char: 9662,
    display_word: "Brad",
    period_at_end: false
    },
    {
    word: "completely.",
    start_time: 706800,
    end_time: 707700,
    token_index: 1722,
    start_char: 9663,
    end_char: 9673,
    display_word: "completely",
    period_at_end: true
    },
    {
    word: "He's",
    start_time: 707800,
    end_time: 708100,
    token_index: 1723,
    start_char: 9675,
    end_char: 9679,
    display_word: "He's",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 708100,
    end_time: 708200,
    token_index: 1724,
    start_char: 9680,
    end_char: 9683,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 708200,
    end_time: 708300,
    token_index: 1725,
    start_char: 9684,
    end_char: 9687,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 708300,
    end_time: 708500,
    token_index: 1726,
    start_char: 9688,
    end_char: 9691,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 708500,
    end_time: 708700,
    token_index: 1727,
    start_char: 9692,
    end_char: 9695,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 708700,
    end_time: 708800,
    token_index: 1728,
    start_char: 9696,
    end_char: 9699,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "map",
    start_time: 708800,
    end_time: 709100,
    token_index: 1729,
    start_char: 9700,
    end_char: 9703,
    display_word: "map",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 709100,
    end_time: 709200,
    token_index: 1730,
    start_char: 9704,
    end_char: 9708,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "shows",
    start_time: 709200,
    end_time: 709500,
    token_index: 1731,
    start_char: 9709,
    end_char: 9714,
    display_word: "shows",
    period_at_end: false
    },
    {
    word: "where",
    start_time: 709500,
    end_time: 709700,
    token_index: 1732,
    start_char: 9715,
    end_char: 9720,
    display_word: "where",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 709700,
    end_time: 709800,
    token_index: 1733,
    start_char: 9721,
    end_char: 9724,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 709800,
    end_time: 710000,
    token_index: 1734,
    start_char: 9725,
    end_char: 9728,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "states",
    start_time: 710000,
    end_time: 710500,
    token_index: 1735,
    start_char: 9729,
    end_char: 9735,
    display_word: "states",
    period_at_end: false
    },
    {
    word: "are.",
    start_time: 710500,
    end_time: 711000,
    token_index: 1736,
    start_char: 9736,
    end_char: 9739,
    display_word: "are",
    period_at_end: true
    },
    {
    word: "With",
    start_time: 713900,
    end_time: 714100,
    token_index: 1737,
    start_char: 9741,
    end_char: 9745,
    display_word: "With",
    period_at_end: false
    },
    {
    word: "covid",
    start_time: 714100,
    end_time: 714400,
    token_index: 1738,
    start_char: 9746,
    end_char: 9751,
    display_word: "covid",
    period_at_end: false
    },
    {
    word: "case",
    start_time: 714400,
    end_time: 714700,
    token_index: 1739,
    start_char: 9752,
    end_char: 9756,
    display_word: "case",
    period_at_end: false
    },
    {
    word: "number",
    start_time: 714700,
    end_time: 714900,
    token_index: 1740,
    start_char: 9757,
    end_char: 9763,
    display_word: "number",
    period_at_end: false
    },
    {
    word: "skyrocketing",
    start_time: 714900,
    end_time: 715700,
    token_index: 1741,
    start_char: 9764,
    end_char: 9776,
    display_word: "skyrocketing",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 715700,
    end_time: 715800,
    token_index: 1742,
    start_char: 9777,
    end_char: 9780,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 715800,
    end_time: 716000,
    token_index: 1743,
    start_char: 9781,
    end_char: 9785,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 716000,
    end_time: 716100,
    token_index: 1744,
    start_char: 9786,
    end_char: 9788,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 716100,
    end_time: 716200,
    token_index: 1745,
    start_char: 9789,
    end_char: 9792,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "country",
    start_time: 716200,
    end_time: 716300,
    token_index: 1746,
    start_char: 9793,
    end_char: 9800,
    display_word: "country",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 716300,
    end_time: 716600,
    token_index: 1747,
    start_char: 9801,
    end_char: 9805,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 716600,
    end_time: 716800,
    token_index: 1748,
    start_char: 9806,
    end_char: 9808,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "school",
    start_time: 716800,
    end_time: 717000,
    token_index: 1749,
    start_char: 9809,
    end_char: 9815,
    display_word: "school",
    period_at_end: false
    },
    {
    word: "season",
    start_time: 717000,
    end_time: 717300,
    token_index: 1750,
    start_char: 9816,
    end_char: 9822,
    display_word: "season",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 717300,
    end_time: 717600,
    token_index: 1751,
    start_char: 9823,
    end_char: 9825,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "looking",
    start_time: 717600,
    end_time: 717800,
    token_index: 1752,
    start_char: 9826,
    end_char: 9833,
    display_word: "looking",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 717800,
    end_time: 718100,
    token_index: 1753,
    start_char: 9834,
    end_char: 9838,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 718100,
    end_time: 718400,
    token_index: 1754,
    start_char: 9839,
    end_char: 9843,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 718400,
    end_time: 718700,
    token_index: 1755,
    start_char: 9844,
    end_char: 9848,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 718700,
    end_time: 718800,
    token_index: 1756,
    start_char: 9849,
    end_char: 9851,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "laptop",
    start_time: 718800,
    end_time: 719500,
    token_index: 1757,
    start_char: 9852,
    end_char: 9858,
    display_word: "laptop",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 719500,
    end_time: 720200,
    token_index: 1758,
    start_char: 9859,
    end_char: 9861,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "California",
    start_time: 720200,
    end_time: 720800,
    token_index: 1759,
    start_char: 9862,
    end_char: 9872,
    display_word: "California",
    period_at_end: false
    },
    {
    word: "schools",
    start_time: 720800,
    end_time: 720900,
    token_index: 1760,
    start_char: 9873,
    end_char: 9880,
    display_word: "schools",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 720900,
    end_time: 721200,
    token_index: 1761,
    start_char: 9881,
    end_char: 9883,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "Los",
    start_time: 721200,
    end_time: 721300,
    token_index: 1762,
    start_char: 9884,
    end_char: 9887,
    display_word: "Los",
    period_at_end: false
    },
    {
    word: "Angeles",
    start_time: 721300,
    end_time: 721600,
    token_index: 1763,
    start_char: 9888,
    end_char: 9895,
    display_word: "Angeles",
    period_at_end: false
    },
    {
    word: "San",
    start_time: 721600,
    end_time: 721900,
    token_index: 1764,
    start_char: 9896,
    end_char: 9899,
    display_word: "San",
    period_at_end: false
    },
    {
    word: "Diego",
    start_time: 721900,
    end_time: 722400,
    token_index: 1765,
    start_char: 9900,
    end_char: 9905,
    display_word: "Diego",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 722400,
    end_time: 722500,
    token_index: 1766,
    start_char: 9906,
    end_char: 9909,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Oakland",
    start_time: 722500,
    end_time: 723000,
    token_index: 1767,
    start_char: 9910,
    end_char: 9917,
    display_word: "Oakland",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 723000,
    end_time: 723300,
    token_index: 1768,
    start_char: 9918,
    end_char: 9922,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 723300,
    end_time: 723400,
    token_index: 1769,
    start_char: 9923,
    end_char: 9925,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 723400,
    end_time: 723600,
    token_index: 1770,
    start_char: 9926,
    end_char: 9931,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "full",
    start_time: 723600,
    end_time: 724200,
    token_index: 1771,
    start_char: 9932,
    end_char: 9936,
    display_word: "full",
    period_at_end: false
    },
    {
    word: "distance",
    start_time: 724200,
    end_time: 724700,
    token_index: 1772,
    start_char: 9937,
    end_char: 9945,
    display_word: "distance",
    period_at_end: false
    },
    {
    word: "learning",
    start_time: 724700,
    end_time: 725200,
    token_index: 1773,
    start_char: 9946,
    end_char: 9954,
    display_word: "learning",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 725200,
    end_time: 725500,
    token_index: 1774,
    start_char: 9955,
    end_char: 9957,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 725500,
    end_time: 725600,
    token_index: 1775,
    start_char: 9958,
    end_char: 9961,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "fall",
    start_time: 725600,
    end_time: 726000,
    token_index: 1776,
    start_char: 9962,
    end_char: 9966,
    display_word: "fall",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 726000,
    end_time: 726500,
    token_index: 1777,
    start_char: 9967,
    end_char: 9970,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 726500,
    end_time: 726700,
    token_index: 1778,
    start_char: 9971,
    end_char: 9974,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 726700,
    end_time: 726800,
    token_index: 1779,
    start_char: 9975,
    end_char: 9977,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "many",
    start_time: 726800,
    end_time: 727100,
    token_index: 1780,
    start_char: 9978,
    end_char: 9982,
    display_word: "many",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 727100,
    end_time: 727100,
    token_index: 1781,
    start_char: 9983,
    end_char: 9985,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 727100,
    end_time: 727300,
    token_index: 1782,
    start_char: 9986,
    end_char: 9989,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "nation's",
    start_time: 727300,
    end_time: 727500,
    token_index: 1783,
    start_char: 9990,
    end_char: 9998,
    display_word: "nation's",
    period_at_end: false
    },
    {
    word: "young",
    start_time: 727500,
    end_time: 727800,
    token_index: 1784,
    start_char: 9999,
    end_char: 10004,
    display_word: "young",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 727800,
    end_time: 728000,
    token_index: 1785,
    start_char: 10005,
    end_char: 10011,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "stuck",
    start_time: 728000,
    end_time: 728500,
    token_index: 1786,
    start_char: 10012,
    end_char: 10017,
    display_word: "stuck",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 728500,
    end_time: 728600,
    token_index: 1787,
    start_char: 10018,
    end_char: 10020,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "home",
    start_time: 728600,
    end_time: 728700,
    token_index: 1788,
    start_char: 10021,
    end_char: 10025,
    display_word: "home",
    period_at_end: false
    },
    {
    word: "teen",
    start_time: 728700,
    end_time: 729300,
    token_index: 1789,
    start_char: 10026,
    end_char: 10030,
    display_word: "teen",
    period_at_end: false
    },
    {
    word: "dramas",
    start_time: 729300,
    end_time: 729800,
    token_index: 1790,
    start_char: 10031,
    end_char: 10037,
    display_word: "dramas",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 729800,
    end_time: 729800,
    token_index: 1791,
    start_char: 10038,
    end_char: 10041,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 729800,
    end_time: 730000,
    token_index: 1792,
    start_char: 10042,
    end_char: 10047,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 730000,
    end_time: 730100,
    token_index: 1793,
    start_char: 10048,
    end_char: 10050,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 730100,
    end_time: 730200,
    token_index: 1794,
    start_char: 10051,
    end_char: 10055,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 730200,
    end_time: 730400,
    token_index: 1795,
    start_char: 10056,
    end_char: 10058,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "adapt",
    start_time: 730400,
    end_time: 731000,
    token_index: 1796,
    start_char: 10059,
    end_char: 10064,
    display_word: "adapt",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 731000,
    end_time: 731700,
    token_index: 1797,
    start_char: 10065,
    end_char: 10068,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we've",
    start_time: 731700,
    end_time: 731900,
    token_index: 1798,
    start_char: 10069,
    end_char: 10074,
    display_word: "we've",
    period_at_end: false
    },
    {
    word: "got",
    start_time: 731900,
    end_time: 732100,
    token_index: 1799,
    start_char: 10075,
    end_char: 10078,
    display_word: "got",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 732100,
    end_time: 732200,
    token_index: 1800,
    start_char: 10079,
    end_char: 10080,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "preview",
    start_time: 732200,
    end_time: 732600,
    token_index: 1801,
    start_char: 10081,
    end_char: 10088,
    display_word: "preview",
    period_at_end: false
    },
    {
    word: "Jim",
    start_time: 732900,
    end_time: 733500,
    token_index: 1802,
    start_char: 10089,
    end_char: 10092,
    display_word: "Jim",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 733700,
    end_time: 735100,
    token_index: 1803,
    start_char: 10093,
    end_char: 10097,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "season",
    start_time: 735100,
    end_time: 735500,
    token_index: 1804,
    start_char: 10098,
    end_char: 10104,
    display_word: "season",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 735500,
    end_time: 735700,
    token_index: 1805,
    start_char: 10105,
    end_char: 10107,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "Creek",
    start_time: 735700,
    end_time: 736400,
    token_index: 1806,
    start_char: 10108,
    end_char: 10113,
    display_word: "Creek",
    period_at_end: false
    },
    {
    word: "Dale.",
    start_time: 736400,
    end_time: 736800,
    token_index: 1807,
    start_char: 10114,
    end_char: 10118,
    display_word: "Dale",
    period_at_end: true
    },
    {
    word: "This",
    start_time: 736900,
    end_time: 738200,
    token_index: 1808,
    start_char: 10120,
    end_char: 10124,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "story",
    start_time: 738200,
    end_time: 738700,
    token_index: 1809,
    start_char: 10125,
    end_char: 10130,
    display_word: "story",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 738700,
    end_time: 738900,
    token_index: 1810,
    start_char: 10131,
    end_char: 10133,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 738900,
    end_time: 739000,
    token_index: 1811,
    start_char: 10134,
    end_char: 10139,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "staying",
    start_time: 739000,
    end_time: 739400,
    token_index: 1812,
    start_char: 10140,
    end_char: 10147,
    display_word: "staying",
    period_at_end: false
    },
    {
    word: "home,",
    start_time: 739400,
    end_time: 739800,
    token_index: 1813,
    start_char: 10148,
    end_char: 10153,
    display_word: "home,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 739800,
    end_time: 740400,
    token_index: 1814,
    start_char: 10154,
    end_char: 10157,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 740400,
    end_time: 740500,
    token_index: 1815,
    start_char: 10158,
    end_char: 10162,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "high",
    start_time: 740500,
    end_time: 740600,
    token_index: 1816,
    start_char: 10163,
    end_char: 10167,
    display_word: "high",
    period_at_end: false
    },
    {
    word: "school",
    start_time: 740600,
    end_time: 740700,
    token_index: 1817,
    start_char: 10168,
    end_char: 10174,
    display_word: "school",
    period_at_end: false
    },
    {
    word: "goes",
    start_time: 740700,
    end_time: 741100,
    token_index: 1818,
    start_char: 10175,
    end_char: 10179,
    display_word: "goes",
    period_at_end: false
    },
    {
    word: "online",
    start_time: 741100,
    end_time: 741300,
    token_index: 1819,
    start_char: 10180,
    end_char: 10186,
    display_word: "online",
    period_at_end: false
    },
    {
    word: "well,",
    start_time: 741800,
    end_time: 742200,
    token_index: 1820,
    start_char: 10187,
    end_char: 10192,
    display_word: "well,",
    period_at_end: false
    },
    {
    word: "Doesn't",
    start_time: 742900,
    end_time: 743200,
    token_index: 1821,
    start_char: 10193,
    end_char: 10200,
    display_word: "Doesn't",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 743200,
    end_time: 743700,
    token_index: 1822,
    start_char: 10201,
    end_char: 10205,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "stop.",
    start_time: 743700,
    end_time: 744400,
    token_index: 1823,
    start_char: 10206,
    end_char: 10210,
    display_word: "stop",
    period_at_end: true
    },
    {
    word: "Hey",
    start_time: 745000,
    end_time: 745200,
    token_index: 1824,
    start_char: 10212,
    end_char: 10215,
    display_word: "Hey",
    period_at_end: false
    },
    {
    word: "bro,",
    start_time: 745200,
    end_time: 745400,
    token_index: 1825,
    start_char: 10216,
    end_char: 10220,
    display_word: "bro,",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 745400,
    end_time: 745800,
    token_index: 1826,
    start_char: 10221,
    end_char: 10227,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 746200,
    end_time: 746300,
    token_index: 1827,
    start_char: 10228,
    end_char: 10229,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 746300,
    end_time: 746400,
    token_index: 1828,
    start_char: 10230,
    end_char: 10236,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 746400,
    end_time: 746600,
    token_index: 1829,
    start_char: 10237,
    end_char: 10240,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 746600,
    end_time: 746700,
    token_index: 1830,
    start_char: 10241,
    end_char: 10244,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 746700,
    end_time: 746900,
    token_index: 1831,
    start_char: 10245,
    end_char: 10247,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "social",
    start_time: 746900,
    end_time: 747200,
    token_index: 1832,
    start_char: 10248,
    end_char: 10254,
    display_word: "social",
    period_at_end: false
    },
    {
    word: "studies?",
    start_time: 747200,
    end_time: 747600,
    token_index: 1833,
    start_char: 10255,
    end_char: 10263,
    display_word: "studies?",
    period_at_end: false
    },
    {
    word: "Umm,",
    start_time: 747600,
    end_time: 748100,
    token_index: 1834,
    start_char: 10264,
    end_char: 10268,
    display_word: "Umm,",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 748500,
    end_time: 748800,
    token_index: 1835,
    start_char: 10269,
    end_char: 10273,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "gives",
    start_time: 748800,
    end_time: 749600,
    token_index: 1836,
    start_char: 10274,
    end_char: 10279,
    display_word: "gives",
    period_at_end: false
    },
    {
    word: "none",
    start_time: 750200,
    end_time: 750300,
    token_index: 1837,
    start_char: 10280,
    end_char: 10284,
    display_word: "none",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 750300,
    end_time: 750300,
    token_index: 1838,
    start_char: 10285,
    end_char: 10287,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 750300,
    end_time: 750500,
    token_index: 1839,
    start_char: 10288,
    end_char: 10292,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "business",
    start_time: 750500,
    end_time: 750900,
    token_index: 1840,
    start_char: 10293,
    end_char: 10301,
    display_word: "business",
    period_at_end: false
    },
    {
    word: "casually,",
    start_time: 750900,
    end_time: 751500,
    token_index: 1841,
    start_char: 10302,
    end_char: 10311,
    display_word: "casually,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 752000,
    end_time: 752100,
    token_index: 1842,
    start_char: 10312,
    end_char: 10313,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 752100,
    end_time: 752500,
    token_index: 1843,
    start_char: 10314,
    end_char: 10319,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 752500,
    end_time: 752700,
    token_index: 1844,
    start_char: 10320,
    end_char: 10322,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 752700,
    end_time: 752900,
    token_index: 1845,
    start_char: 10323,
    end_char: 10325,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 752900,
    end_time: 753100,
    token_index: 1846,
    start_char: 10326,
    end_char: 10328,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "business",
    start_time: 753100,
    end_time: 753500,
    token_index: 1847,
    start_char: 10329,
    end_char: 10337,
    display_word: "business",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 753500,
    end_time: 753800,
    token_index: 1848,
    start_char: 10338,
    end_char: 10345,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "we're",
    start_time: 753800,
    end_time: 754000,
    token_index: 1849,
    start_char: 10346,
    end_char: 10351,
    display_word: "we're",
    period_at_end: false
    },
    {
    word: "together.",
    start_time: 754000,
    end_time: 754900,
    token_index: 1850,
    start_char: 10352,
    end_char: 10360,
    display_word: "together",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 755100,
    end_time: 755300,
    token_index: 1851,
    start_char: 10362,
    end_char: 10363,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "saw",
    start_time: 755300,
    end_time: 755500,
    token_index: 1852,
    start_char: 10364,
    end_char: 10367,
    display_word: "saw",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 755500,
    end_time: 755700,
    token_index: 1853,
    start_char: 10368,
    end_char: 10372,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "Zoom",
    start_time: 755700,
    end_time: 755900,
    token_index: 1854,
    start_char: 10373,
    end_char: 10377,
    display_word: "Zoom",
    period_at_end: false
    },
    {
    word: "chats.",
    start_time: 755900,
    end_time: 756600,
    token_index: 1855,
    start_char: 10378,
    end_char: 10383,
    display_word: "chats",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 757100,
    end_time: 757200,
    token_index: 1856,
    start_char: 10385,
    end_char: 10388,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "sent",
    start_time: 757200,
    end_time: 757500,
    token_index: 1857,
    start_char: 10389,
    end_char: 10393,
    display_word: "sent",
    period_at_end: false
    },
    {
    word: "them",
    start_time: 757500,
    end_time: 757600,
    token_index: 1858,
    start_char: 10394,
    end_char: 10398,
    display_word: "them",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 757600,
    end_time: 757800,
    token_index: 1859,
    start_char: 10399,
    end_char: 10401,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "everyone",
    start_time: 757800,
    end_time: 758500,
    token_index: 1860,
    start_char: 10402,
    end_char: 10410,
    display_word: "everyone",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 758500,
    end_time: 758600,
    token_index: 1861,
    start_char: 10411,
    end_char: 10415,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 758600,
    end_time: 758700,
    token_index: 1862,
    start_char: 10416,
    end_char: 10420,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "clearly",
    start_time: 758700,
    end_time: 759600,
    token_index: 1863,
    start_char: 10421,
    end_char: 10428,
    display_word: "clearly",
    period_at_end: false
    },
    {
    word: "meant",
    start_time: 759700,
    end_time: 759900,
    token_index: 1864,
    start_char: 10429,
    end_char: 10434,
    display_word: "meant",
    period_at_end: false
    },
    {
    word: "first",
    start_time: 759900,
    end_time: 760200,
    token_index: 1865,
    start_char: 10435,
    end_char: 10440,
    display_word: "first",
    period_at_end: false
    },
    {
    word: "Ashley.",
    start_time: 760200,
    end_time: 760900,
    token_index: 1866,
    start_char: 10441,
    end_char: 10447,
    display_word: "Ashley",
    period_at_end: true
    },
    {
    word: "Wow,",
    start_time: 761200,
    end_time: 761700,
    token_index: 1867,
    start_char: 10449,
    end_char: 10453,
    display_word: "Wow,",
    period_at_end: false
    },
    {
    word: "someone's",
    start_time: 761700,
    end_time: 762200,
    token_index: 1868,
    start_char: 10454,
    end_char: 10463,
    display_word: "someone's",
    period_at_end: false
    },
    {
    word: "clingy",
    start_time: 762200,
    end_time: 762800,
    token_index: 1869,
    start_char: 10464,
    end_char: 10470,
    display_word: "clingy",
    period_at_end: false
    },
    {
    word: "social",
    start_time: 762900,
    end_time: 763200,
    token_index: 1870,
    start_char: 10471,
    end_char: 10477,
    display_word: "social",
    period_at_end: false
    },
    {
    word: "distance",
    start_time: 763200,
    end_time: 763700,
    token_index: 1871,
    start_char: 10478,
    end_char: 10486,
    display_word: "distance",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 763700,
    end_time: 764300,
    token_index: 1872,
    start_char: 10487,
    end_char: 10491,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "stash.",
    start_time: 764600,
    end_time: 765500,
    token_index: 1873,
    start_char: 10492,
    end_char: 10497,
    display_word: "stash",
    period_at_end: true
    },
    {
    word: "Oops.",
    start_time: 765800,
    end_time: 766400,
    token_index: 1874,
    start_char: 10499,
    end_char: 10503,
    display_word: "Oops",
    period_at_end: true
    },
    {
    word: "Sorry",
    start_time: 766400,
    end_time: 767000,
    token_index: 1875,
    start_char: 10505,
    end_char: 10510,
    display_word: "Sorry",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 767400,
    end_time: 767600,
    token_index: 1876,
    start_char: 10511,
    end_char: 10515,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 767600,
    end_time: 767600,
    token_index: 1877,
    start_char: 10516,
    end_char: 10517,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 767600,
    end_time: 767800,
    token_index: 1878,
    start_char: 10518,
    end_char: 10521,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 767800,
    end_time: 767900,
    token_index: 1879,
    start_char: 10522,
    end_char: 10524,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "mute.",
    start_time: 767900,
    end_time: 768300,
    token_index: 1880,
    start_char: 10525,
    end_char: 10529,
    display_word: "mute",
    period_at_end: true
    },
    {
    word: "Listen",
    start_time: 768700,
    end_time: 769200,
    token_index: 1881,
    start_char: 10531,
    end_char: 10537,
    display_word: "Listen",
    period_at_end: false
    },
    {
    word: "casually.",
    start_time: 769200,
    end_time: 769900,
    token_index: 1882,
    start_char: 10538,
    end_char: 10546,
    display_word: "casually",
    period_at_end: true
    },
    {
    word: "Can",
    start_time: 770100,
    end_time: 770300,
    token_index: 1883,
    start_char: 10548,
    end_char: 10551,
    display_word: "Can",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 770300,
    end_time: 770300,
    token_index: 1884,
    start_char: 10552,
    end_char: 10554,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 770300,
    end_time: 770500,
    token_index: 1885,
    start_char: 10555,
    end_char: 10559,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 770500,
    end_time: 770700,
    token_index: 1886,
    start_char: 10560,
    end_char: 10562,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "somewhere",
    start_time: 770700,
    end_time: 771000,
    token_index: 1887,
    start_char: 10563,
    end_char: 10572,
    display_word: "somewhere",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 771000,
    end_time: 771100,
    token_index: 1888,
    start_char: 10573,
    end_char: 10576,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "talk",
    start_time: 771100,
    end_time: 771200,
    token_index: 1889,
    start_char: 10577,
    end_char: 10581,
    display_word: "talk",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 771200,
    end_time: 771500,
    token_index: 1890,
    start_char: 10582,
    end_char: 10587,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 771500,
    end_time: 771600,
    token_index: 1891,
    start_char: 10588,
    end_char: 10592,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "privately?",
    start_time: 771600,
    end_time: 772200,
    token_index: 1892,
    start_char: 10593,
    end_char: 10603,
    display_word: "privately?",
    period_at_end: false
    },
    {
    word: "Like",
    start_time: 772800,
    end_time: 772900,
    token_index: 1893,
    start_char: 10604,
    end_char: 10608,
    display_word: "Like",
    period_at_end: false
    },
    {
    word: "slack",
    start_time: 772900,
    end_time: 773400,
    token_index: 1894,
    start_char: 10609,
    end_char: 10614,
    display_word: "slack",
    period_at_end: false
    },
    {
    word: "guys",
    start_time: 773500,
    end_time: 774100,
    token_index: 1895,
    start_char: 10615,
    end_char: 10619,
    display_word: "guys",
    period_at_end: false
    },
    {
    word: "come",
    start_time: 774100,
    end_time: 774400,
    token_index: 1896,
    start_char: 10620,
    end_char: 10624,
    display_word: "come",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 774400,
    end_time: 774700,
    token_index: 1897,
    start_char: 10625,
    end_char: 10627,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 775200,
    end_time: 775400,
    token_index: 1898,
    start_char: 10628,
    end_char: 10630,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "these",
    start_time: 775400,
    end_time: 775600,
    token_index: 1899,
    start_char: 10631,
    end_char: 10636,
    display_word: "these",
    period_at_end: false
    },
    {
    word: "most",
    start_time: 775600,
    end_time: 775900,
    token_index: 1900,
    start_char: 10637,
    end_char: 10641,
    display_word: "most",
    period_at_end: false
    },
    {
    word: "difficult",
    start_time: 775900,
    end_time: 776400,
    token_index: 1901,
    start_char: 10642,
    end_char: 10651,
    display_word: "difficult",
    period_at_end: false
    },
    {
    word: "times.",
    start_time: 776400,
    end_time: 777000,
    token_index: 1902,
    start_char: 10652,
    end_char: 10657,
    display_word: "times",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 777300,
    end_time: 777500,
    token_index: 1903,
    start_char: 10659,
    end_char: 10661,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "sexy",
    start_time: 777500,
    end_time: 778000,
    token_index: 1904,
    start_char: 10662,
    end_char: 10666,
    display_word: "sexy",
    period_at_end: false
    },
    {
    word: "teens",
    start_time: 778000,
    end_time: 778700,
    token_index: 1905,
    start_char: 10667,
    end_char: 10672,
    display_word: "teens",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 778700,
    end_time: 778900,
    token_index: 1906,
    start_char: 10673,
    end_char: 10677,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 778900,
    end_time: 779000,
    token_index: 1907,
    start_char: 10678,
    end_char: 10680,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "come",
    start_time: 779000,
    end_time: 779200,
    token_index: 1908,
    start_char: 10681,
    end_char: 10685,
    display_word: "come",
    period_at_end: false
    },
    {
    word: "together",
    start_time: 779200,
    end_time: 779800,
    token_index: 1909,
    start_char: 10686,
    end_char: 10694,
    display_word: "together",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 779900,
    end_time: 780300,
    token_index: 1910,
    start_char: 10695,
    end_char: 10698,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Frozen.",
    start_time: 782100,
    end_time: 782700,
    token_index: 1911,
    start_char: 10699,
    end_char: 10705,
    display_word: "Frozen",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 782900,
    end_time: 783000,
    token_index: 1912,
    start_char: 10707,
    end_char: 10708,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 783000,
    end_time: 783200,
    token_index: 1913,
    start_char: 10709,
    end_char: 10714,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "you're",
    start_time: 783200,
    end_time: 783300,
    token_index: 1914,
    start_char: 10715,
    end_char: 10721,
    display_word: "you're",
    period_at_end: false
    },
    {
    word: "Frozen",
    start_time: 783300,
    end_time: 783800,
    token_index: 1915,
    start_char: 10722,
    end_char: 10728,
    display_word: "Frozen",
    period_at_end: false
    },
    {
    word: "Radisson.",
    start_time: 784600,
    end_time: 785100,
    token_index: 1916,
    start_char: 10729,
    end_char: 10737,
    display_word: "Radisson",
    period_at_end: true
    },
    {
    word: "No,",
    start_time: 786100,
    end_time: 786300,
    token_index: 1917,
    start_char: 10739,
    end_char: 10742,
    display_word: "No,",
    period_at_end: false
    },
    {
    word: "he's",
    start_time: 786300,
    end_time: 786500,
    token_index: 1918,
    start_char: 10743,
    end_char: 10747,
    display_word: "he's",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 786500,
    end_time: 786800,
    token_index: 1919,
    start_char: 10748,
    end_char: 10751,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "frozen.",
    start_time: 786800,
    end_time: 787500,
    token_index: 1920,
    start_char: 10752,
    end_char: 10758,
    display_word: "frozen",
    period_at_end: true
    },
    {
    word: "He's",
    start_time: 789700,
    end_time: 790600,
    token_index: 1921,
    start_char: 10760,
    end_char: 10764,
    display_word: "He's",
    period_at_end: false
    },
    {
    word: "dead.",
    start_time: 790600,
    end_time: 791300,
    token_index: 1922,
    start_char: 10765,
    end_char: 10769,
    display_word: "dead",
    period_at_end: true
    },
    {
    word: "He",
    start_time: 791700,
    end_time: 792000,
    token_index: 1923,
    start_char: 10771,
    end_char: 10773,
    display_word: "He",
    period_at_end: false
    },
    {
    word: "died",
    start_time: 792000,
    end_time: 793000,
    token_index: 1924,
    start_char: 10774,
    end_char: 10778,
    display_word: "died",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 793700,
    end_time: 793800,
    token_index: 1925,
    start_char: 10779,
    end_char: 10783,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 793800,
    end_time: 793900,
    token_index: 1926,
    start_char: 10784,
    end_char: 10787,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "matter.",
    start_time: 793900,
    end_time: 794500,
    token_index: 1927,
    start_char: 10788,
    end_char: 10794,
    display_word: "matter",
    period_at_end: true
    },
    {
    word: "Now.",
    start_time: 794700,
    end_time: 794900,
    token_index: 1928,
    start_char: 10796,
    end_char: 10799,
    display_word: "Now",
    period_at_end: true
    },
    {
    word: "He's",
    start_time: 794900,
    end_time: 795000,
    token_index: 1929,
    start_char: 10801,
    end_char: 10805,
    display_word: "He's",
    period_at_end: false
    },
    {
    word: "finally",
    start_time: 795000,
    end_time: 795300,
    token_index: 1930,
    start_char: 10806,
    end_char: 10813,
    display_word: "finally",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 795300,
    end_time: 795400,
    token_index: 1931,
    start_char: 10814,
    end_char: 10817,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 795400,
    end_time: 795500,
    token_index: 1932,
    start_char: 10818,
    end_char: 10820,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 795500,
    end_time: 795600,
    token_index: 1933,
    start_char: 10821,
    end_char: 10824,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "way.",
    start_time: 795600,
    end_time: 796100,
    token_index: 1934,
    start_char: 10825,
    end_char: 10828,
    display_word: "way",
    period_at_end: true
    },
    {
    word: "Come",
    start_time: 796500,
    end_time: 796600,
    token_index: 1935,
    start_char: 10830,
    end_char: 10834,
    display_word: "Come",
    period_at_end: false
    },
    {
    word: "here,",
    start_time: 796600,
    end_time: 796800,
    token_index: 1936,
    start_char: 10835,
    end_char: 10840,
    display_word: "here,",
    period_at_end: false
    },
    {
    word: "babe.",
    start_time: 796800,
    end_time: 797100,
    token_index: 1937,
    start_char: 10841,
    end_char: 10845,
    display_word: "babe",
    period_at_end: true
    },
    {
    word: "Are",
    start_time: 798700,
    end_time: 798900,
    token_index: 1938,
    start_char: 10847,
    end_char: 10850,
    display_word: "Are",
    period_at_end: false
    },
    {
    word: "you?",
    start_time: 798900,
    end_time: 799200,
    token_index: 1939,
    start_char: 10851,
    end_char: 10855,
    display_word: "you?",
    period_at_end: false
    },
    {
    word: "Okay",
    start_time: 799900,
    end_time: 800100,
    token_index: 1940,
    start_char: 10856,
    end_char: 10860,
    display_word: "Okay",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 800200,
    end_time: 800300,
    token_index: 1941,
    start_char: 10861,
    end_char: 10864,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 800300,
    end_time: 800500,
    token_index: 1942,
    start_char: 10865,
    end_char: 10867,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "up.",
    start_time: 800500,
    end_time: 800700,
    token_index: 1943,
    start_char: 10868,
    end_char: 10870,
    display_word: "up",
    period_at_end: true
    },
    {
    word: "This",
    start_time: 805900,
    end_time: 806200,
    token_index: 1944,
    start_char: 10872,
    end_char: 10876,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "fall",
    start_time: 806200,
    end_time: 806500,
    token_index: 1945,
    start_char: 10877,
    end_char: 10881,
    display_word: "fall",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 806500,
    end_time: 806700,
    token_index: 1946,
    start_char: 10882,
    end_char: 10884,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "quippy.",
    start_time: 806700,
    end_time: 807300,
    token_index: 1947,
    start_char: 10885,
    end_char: 10891,
    display_word: "quippy",
    period_at_end: true
    },
    {
    word: "We're",
    start_time: 807300,
    end_time: 807700,
    token_index: 1948,
    start_char: 10893,
    end_char: 10898,
    display_word: "We're",
    period_at_end: false
    },
    {
    word: "definitely",
    start_time: 807700,
    end_time: 808400,
    token_index: 1949,
    start_char: 10899,
    end_char: 10909,
    display_word: "definitely",
    period_at_end: false
    },
    {
    word: "still",
    start_time: 808400,
    end_time: 808600,
    token_index: 1950,
    start_char: 10910,
    end_char: 10915,
    display_word: "still",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 808600,
    end_time: 808700,
    token_index: 1951,
    start_char: 10916,
    end_char: 10917,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "thing.",
    start_time: 808700,
    end_time: 809300,
    token_index: 1952,
    start_char: 10918,
    end_char: 10923,
    display_word: "thing",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 809700,
    end_time: 809800,
    token_index: 1953,
    start_char: 10925,
    end_char: 10927,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "got",
    start_time: 809800,
    end_time: 809900,
    token_index: 1954,
    start_char: 10928,
    end_char: 10931,
    display_word: "got",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 809900,
    end_time: 810000,
    token_index: 1955,
    start_char: 10932,
    end_char: 10933,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 810000,
    end_time: 810200,
    token_index: 1956,
    start_char: 10934,
    end_char: 10939,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 810200,
    end_time: 810500,
    token_index: 1957,
    start_char: 10940,
    end_char: 10944,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 810500,
    end_time: 810600,
    token_index: 1958,
    start_char: 10945,
    end_char: 10948,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 810600,
    end_time: 810700,
    token_index: 1959,
    start_char: 10949,
    end_char: 10952,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "tonight",
    start_time: 810700,
    end_time: 811300,
    token_index: 1960,
    start_char: 10953,
    end_char: 10960,
    display_word: "tonight",
    period_at_end: false
    },
    {
    word: "W",
    start_time: 811400,
    end_time: 811700,
    token_index: 1961,
    start_char: 10961,
    end_char: 10962,
    display_word: "W",
    period_at_end: false
    },
    {
    word: "kamau",
    start_time: 811700,
    end_time: 812200,
    token_index: 1962,
    start_char: 10963,
    end_char: 10968,
    display_word: "kamau",
    period_at_end: false
    },
    {
    word: "Bell",
    start_time: 812200,
    end_time: 812800,
    token_index: 1963,
    start_char: 10969,
    end_char: 10973,
    display_word: "Bell",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 812800,
    end_time: 813200,
    token_index: 1964,
    start_char: 10974,
    end_char: 10977,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 813200,
    end_time: 813300,
    token_index: 1965,
    start_char: 10978,
    end_char: 10981,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks",
    start_time: 813300,
    end_time: 813700,
    token_index: 1966,
    start_char: 10982,
    end_char: 10988,
    display_word: "chicks",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 813700,
    end_time: 813800,
    token_index: 1967,
    start_char: 10989,
    end_char: 10992,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "here.",
    start_time: 813800,
    end_time: 814100,
    token_index: 1968,
    start_char: 10993,
    end_char: 10997,
    display_word: "here",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 814100,
    end_time: 814300,
    token_index: 1969,
    start_char: 10999,
    end_char: 11002,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 814300,
    end_time: 814400,
    token_index: 1970,
    start_char: 11003,
    end_char: 11007,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 814400,
    end_time: 814500,
    token_index: 1971,
    start_char: 11008,
    end_char: 11010,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "come",
    start_time: 814500,
    end_time: 814700,
    token_index: 1972,
    start_char: 11011,
    end_char: 11015,
    display_word: "come",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 814700,
    end_time: 815200,
    token_index: 1973,
    start_char: 11016,
    end_char: 11020,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 815500,
    end_time: 815600,
    token_index: 1974,
    start_char: 11021,
    end_char: 11022,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 815600,
    end_time: 815900,
    token_index: 1975,
    start_char: 11023,
    end_char: 11026,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "service",
    start_time: 815900,
    end_time: 816500,
    token_index: 1976,
    start_char: 11027,
    end_char: 11034,
    display_word: "service",
    period_at_end: false
    },
    {
    word: "helps",
    start_time: 816600,
    end_time: 816900,
    token_index: 1977,
    start_char: 11035,
    end_char: 11040,
    display_word: "helps",
    period_at_end: false
    },
    {
    word: "peaceful",
    start_time: 816900,
    end_time: 817400,
    token_index: 1978,
    start_char: 11041,
    end_char: 11049,
    display_word: "peaceful",
    period_at_end: false
    },
    {
    word: "protesters",
    start_time: 817400,
    end_time: 818300,
    token_index: 1979,
    start_char: 11050,
    end_char: 11060,
    display_word: "protesters",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 818400,
    end_time: 818600,
    token_index: 1980,
    start_char: 11061,
    end_char: 11064,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 818600,
    end_time: 818700,
    token_index: 1981,
    start_char: 11065,
    end_char: 11070,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "message",
    start_time: 818700,
    end_time: 819100,
    token_index: 1982,
    start_char: 11071,
    end_char: 11078,
    display_word: "message",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 819100,
    end_time: 819500,
    token_index: 1983,
    start_char: 11079,
    end_char: 11082,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "stick",
    start_time: 819500,
    end_time: 819700,
    token_index: 1984,
    start_char: 11083,
    end_char: 11088,
    display_word: "stick",
    period_at_end: false
    },
    {
    word: "around.",
    start_time: 819700,
    end_time: 820200,
    token_index: 1985,
    start_char: 11089,
    end_char: 11095,
    display_word: "around",
    period_at_end: true
    },
    {
    word: "Hey",
    start_time: 836200,
    end_time: 836400,
    token_index: 1986,
    start_char: 11097,
    end_char: 11100,
    display_word: "Hey",
    period_at_end: false
    },
    {
    word: "everybody.",
    start_time: 836400,
    end_time: 836900,
    token_index: 1987,
    start_char: 11101,
    end_char: 11110,
    display_word: "everybody",
    period_at_end: true
    },
    {
    word: "Welcome",
    start_time: 836900,
    end_time: 837300,
    token_index: 1988,
    start_char: 11112,
    end_char: 11119,
    display_word: "Welcome",
    period_at_end: false
    },
    {
    word: "Back.",
    start_time: 837300,
    end_time: 837700,
    token_index: 1989,
    start_char: 11120,
    end_char: 11124,
    display_word: "Back",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 837700,
    end_time: 837800,
    token_index: 1990,
    start_char: 11126,
    end_char: 11131,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "let's",
    start_time: 837800,
    end_time: 838300,
    token_index: 1991,
    start_char: 11132,
    end_char: 11137,
    display_word: "let's",
    period_at_end: false
    },
    {
    word: "let's",
    start_time: 838300,
    end_time: 838800,
    token_index: 1992,
    start_char: 11138,
    end_char: 11143,
    display_word: "let's",
    period_at_end: false
    },
    {
    word: "let's",
    start_time: 838800,
    end_time: 839100,
    token_index: 1993,
    start_char: 11144,
    end_char: 11149,
    display_word: "let's",
    period_at_end: false
    },
    {
    word: "take",
    start_time: 839100,
    end_time: 839300,
    token_index: 1994,
    start_char: 11150,
    end_char: 11154,
    display_word: "take",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 839300,
    end_time: 839400,
    token_index: 1995,
    start_char: 11155,
    end_char: 11156,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "moment",
    start_time: 839400,
    end_time: 839700,
    token_index: 1996,
    start_char: 11157,
    end_char: 11163,
    display_word: "moment",
    period_at_end: false
    },
    {
    word: "here",
    start_time: 839700,
    end_time: 839900,
    token_index: 1997,
    start_char: 11164,
    end_char: 11168,
    display_word: "here",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 839900,
    end_time: 840100,
    token_index: 1998,
    start_char: 11169,
    end_char: 11172,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 840100,
    end_time: 840200,
    token_index: 1999,
    start_char: 11173,
    end_char: 11176,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "hello",
    start_time: 840200,
    end_time: 840500,
    token_index: 2000,
    start_char: 11177,
    end_char: 11182,
    display_word: "hello",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 840500,
    end_time: 840600,
    token_index: 2001,
    start_char: 11183,
    end_char: 11185,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 840600,
    end_time: 840700,
    token_index: 2002,
    start_char: 11186,
    end_char: 11189,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "friend.",
    start_time: 840700,
    end_time: 841000,
    token_index: 2003,
    start_char: 11190,
    end_char: 11196,
    display_word: "friend",
    period_at_end: true
    },
    {
    word: "Mr.",
    start_time: 841000,
    end_time: 841200,
    token_index: 2004,
    start_char: 11198,
    end_char: 11200,
    display_word: "Mr",
    period_at_end: true
    },
    {
    word: "John",
    start_time: 841200,
    end_time: 841500,
    token_index: 2005,
    start_char: 11202,
    end_char: 11206,
    display_word: "John",
    period_at_end: false
    },
    {
    word: "vetti's.",
    start_time: 841500,
    end_time: 842400,
    token_index: 2006,
    start_char: 11207,
    end_char: 11214,
    display_word: "vetti's",
    period_at_end: true
    },
    {
    word: "Hello,",
    start_time: 842400,
    end_time: 842800,
    token_index: 2007,
    start_char: 11216,
    end_char: 11222,
    display_word: "Hello,",
    period_at_end: false
    },
    {
    word: "John.",
    start_time: 842800,
    end_time: 843500,
    token_index: 2008,
    start_char: 11223,
    end_char: 11227,
    display_word: "John",
    period_at_end: true
    },
    {
    word: "Wow,",
    start_time: 844900,
    end_time: 845900,
    token_index: 2009,
    start_char: 11229,
    end_char: 11233,
    display_word: "Wow,",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 846200,
    end_time: 846400,
    token_index: 2010,
    start_char: 11234,
    end_char: 11236,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "gotta",
    start_time: 846400,
    end_time: 846600,
    token_index: 2011,
    start_char: 11237,
    end_char: 11242,
    display_word: "gotta",
    period_at_end: false
    },
    {
    word: "talk",
    start_time: 846600,
    end_time: 847000,
    token_index: 2012,
    start_char: 11243,
    end_char: 11247,
    display_word: "talk",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 847000,
    end_time: 847100,
    token_index: 2013,
    start_char: 11248,
    end_char: 11250,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "you.",
    start_time: 847100,
    end_time: 847100,
    token_index: 2014,
    start_char: 11251,
    end_char: 11254,
    display_word: "you",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 847100,
    end_time: 847300,
    token_index: 2015,
    start_char: 11256,
    end_char: 11258,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 847300,
    end_time: 847400,
    token_index: 2016,
    start_char: 11259,
    end_char: 11263,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 847400,
    end_time: 847600,
    token_index: 2017,
    start_char: 11264,
    end_char: 11268,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "listening",
    start_time: 847600,
    end_time: 848000,
    token_index: 2018,
    start_char: 11269,
    end_char: 11278,
    display_word: "listening",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 848000,
    end_time: 848100,
    token_index: 2019,
    start_char: 11279,
    end_char: 11281,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 848100,
    end_time: 848200,
    token_index: 2020,
    start_char: 11282,
    end_char: 11283,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "song",
    start_time: 848200,
    end_time: 848800,
    token_index: 2021,
    start_char: 11284,
    end_char: 11288,
    display_word: "song",
    period_at_end: false
    },
    {
    word: "by",
    start_time: 848900,
    end_time: 849200,
    token_index: 2022,
    start_char: 11289,
    end_char: 11291,
    display_word: "by",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 849200,
    end_time: 849200,
    token_index: 2023,
    start_char: 11292,
    end_char: 11293,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "friend",
    start_time: 849200,
    end_time: 849500,
    token_index: 2024,
    start_char: 11294,
    end_char: 11300,
    display_word: "friend",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 849500,
    end_time: 849600,
    token_index: 2025,
    start_char: 11301,
    end_char: 11303,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "ours.",
    start_time: 849600,
    end_time: 849900,
    token_index: 2026,
    start_char: 11304,
    end_char: 11308,
    display_word: "ours",
    period_at_end: true
    },
    {
    word: "Mr.",
    start_time: 849900,
    end_time: 850200,
    token_index: 2027,
    start_char: 11310,
    end_char: 11312,
    display_word: "Mr",
    period_at_end: true
    },
    {
    word: "Ben",
    start_time: 850200,
    end_time: 850500,
    token_index: 2028,
    start_char: 11314,
    end_char: 11317,
    display_word: "Ben",
    period_at_end: false
    },
    {
    word: "Folds.",
    start_time: 850500,
    end_time: 851200,
    token_index: 2029,
    start_char: 11318,
    end_char: 11323,
    display_word: "Folds",
    period_at_end: true
    },
    {
    word: "Oh",
    start_time: 851300,
    end_time: 851600,
    token_index: 2030,
    start_char: 11325,
    end_char: 11327,
    display_word: "Oh",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 851600,
    end_time: 851900,
    token_index: 2031,
    start_char: 11328,
    end_char: 11331,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 851900,
    end_time: 852500,
    token_index: 2032,
    start_char: 11332,
    end_char: 11337,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 852600,
    end_time: 852800,
    token_index: 2033,
    start_char: 11338,
    end_char: 11342,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 852800,
    end_time: 852900,
    token_index: 2034,
    start_char: 11343,
    end_char: 11345,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "called",
    start_time: 852900,
    end_time: 853200,
    token_index: 2035,
    start_char: 11346,
    end_char: 11352,
    display_word: "called",
    period_at_end: false
    },
    {
    word: "22",
    start_time: 853200,
    end_time: 853700,
    token_index: 2036,
    start_char: 11353,
    end_char: 11355,
    display_word: "22",
    period_at_end: false
    },
    {
    word: "2020.",
    start_time: 853700,
    end_time: 854500,
    token_index: 2037,
    start_char: 11356,
    end_char: 11360,
    display_word: "2020",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 854500,
    end_time: 854600,
    token_index: 2038,
    start_char: 11362,
    end_char: 11366,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "worth.",
    start_time: 854600,
    end_time: 854900,
    token_index: 2039,
    start_char: 11367,
    end_char: 11372,
    display_word: "worth",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 854900,
    end_time: 855000,
    token_index: 2040,
    start_char: 11374,
    end_char: 11378,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "worth",
    start_time: 855000,
    end_time: 855300,
    token_index: 2041,
    start_char: 11379,
    end_char: 11384,
    display_word: "worth",
    period_at_end: false
    },
    {
    word: "hearing.",
    start_time: 855300,
    end_time: 855800,
    token_index: 2042,
    start_char: 11385,
    end_char: 11392,
    display_word: "hearing",
    period_at_end: true
    },
    {
    word: "Yeah.",
    start_time: 856100,
    end_time: 856700,
    token_index: 2043,
    start_char: 11394,
    end_char: 11398,
    display_word: "Yeah",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 856700,
    end_time: 856900,
    token_index: 2044,
    start_char: 11400,
    end_char: 11404,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 856900,
    end_time: 857100,
    token_index: 2045,
    start_char: 11405,
    end_char: 11408,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "there",
    start_time: 857100,
    end_time: 857300,
    token_index: 2046,
    start_char: 11409,
    end_char: 11414,
    display_word: "there",
    period_at_end: false
    },
    {
    word: "come",
    start_time: 857300,
    end_time: 857500,
    token_index: 2047,
    start_char: 11415,
    end_char: 11419,
    display_word: "come",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 857500,
    end_time: 857700,
    token_index: 2048,
    start_char: 11420,
    end_char: 11424,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "play",
    start_time: 857700,
    end_time: 857900,
    token_index: 2049,
    start_char: 11425,
    end_char: 11429,
    display_word: "play",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 857900,
    end_time: 858200,
    token_index: 2050,
    start_char: 11430,
    end_char: 11434,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "one.",
    start_time: 858200,
    end_time: 858400,
    token_index: 2051,
    start_char: 11435,
    end_char: 11438,
    display_word: "one",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 858500,
    end_time: 858600,
    token_index: 2052,
    start_char: 11440,
    end_char: 11441,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "hope",
    start_time: 858600,
    end_time: 858900,
    token_index: 2053,
    start_char: 11442,
    end_char: 11446,
    display_word: "hope",
    period_at_end: false
    },
    {
    word: "so.",
    start_time: 858900,
    end_time: 859300,
    token_index: 2054,
    start_char: 11447,
    end_char: 11449,
    display_word: "so",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 859300,
    end_time: 859300,
    token_index: 2055,
    start_char: 11451,
    end_char: 11452,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "hope",
    start_time: 859300,
    end_time: 859600,
    token_index: 2056,
    start_char: 11453,
    end_char: 11457,
    display_word: "hope",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 859600,
    end_time: 859900,
    token_index: 2057,
    start_char: 11458,
    end_char: 11460,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 859900,
    end_time: 860000,
    token_index: 2058,
    start_char: 11461,
    end_char: 11463,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "gotta",
    start_time: 860000,
    end_time: 860300,
    token_index: 2059,
    start_char: 11464,
    end_char: 11469,
    display_word: "gotta",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 860300,
    end_time: 860400,
    token_index: 2060,
    start_char: 11470,
    end_char: 11473,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "him",
    start_time: 860400,
    end_time: 860600,
    token_index: 2061,
    start_char: 11474,
    end_char: 11477,
    display_word: "him",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 860600,
    end_time: 860700,
    token_index: 2062,
    start_char: 11478,
    end_char: 11480,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "complete",
    start_time: 860700,
    end_time: 861300,
    token_index: 2063,
    start_char: 11481,
    end_char: 11489,
    display_word: "complete",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 861300,
    end_time: 861600,
    token_index: 2064,
    start_char: 11490,
    end_char: 11493,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 861600,
    end_time: 861700,
    token_index: 2065,
    start_char: 11494,
    end_char: 11495,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "wish",
    start_time: 861700,
    end_time: 862100,
    token_index: 2066,
    start_char: 11496,
    end_char: 11500,
    display_word: "wish",
    period_at_end: false
    },
    {
    word: "I'd",
    start_time: 862100,
    end_time: 862300,
    token_index: 2067,
    start_char: 11501,
    end_char: 11504,
    display_word: "I'd",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 862300,
    end_time: 862500,
    token_index: 2068,
    start_char: 11505,
    end_char: 11507,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "very",
    start_time: 863000,
    end_time: 863200,
    token_index: 2069,
    start_char: 11508,
    end_char: 11512,
    display_word: "very",
    period_at_end: false
    },
    {
    word: "grateful.",
    start_time: 863200,
    end_time: 863800,
    token_index: 2070,
    start_char: 11513,
    end_char: 11521,
    display_word: "grateful",
    period_at_end: true
    },
    {
    word: "Yes.",
    start_time: 864200,
    end_time: 864900,
    token_index: 2071,
    start_char: 11523,
    end_char: 11526,
    display_word: "Yes",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 864900,
    end_time: 865000,
    token_index: 2072,
    start_char: 11528,
    end_char: 11532,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 865000,
    end_time: 865100,
    token_index: 2073,
    start_char: 11533,
    end_char: 11534,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 865100,
    end_time: 865500,
    token_index: 2074,
    start_char: 11535,
    end_char: 11540,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "song",
    start_time: 865500,
    end_time: 865900,
    token_index: 2075,
    start_char: 11541,
    end_char: 11545,
    display_word: "song",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 865900,
    end_time: 866000,
    token_index: 2076,
    start_char: 11546,
    end_char: 11549,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 866000,
    end_time: 866200,
    token_index: 2077,
    start_char: 11550,
    end_char: 11552,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "speaks",
    start_time: 866200,
    end_time: 866600,
    token_index: 2078,
    start_char: 11553,
    end_char: 11559,
    display_word: "speaks",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 866600,
    end_time: 866700,
    token_index: 2079,
    start_char: 11560,
    end_char: 11562,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 866700,
    end_time: 866800,
    token_index: 2080,
    start_char: 11563,
    end_char: 11564,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 866800,
    end_time: 867100,
    token_index: 2081,
    start_char: 11565,
    end_char: 11568,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 867100,
    end_time: 867300,
    token_index: 2082,
    start_char: 11569,
    end_char: 11571,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 867300,
    end_time: 867700,
    token_index: 2083,
    start_char: 11572,
    end_char: 11576,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 867800,
    end_time: 868100,
    token_index: 2084,
    start_char: 11577,
    end_char: 11579,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 868100,
    end_time: 868200,
    token_index: 2085,
    start_char: 11580,
    end_char: 11583,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "facing",
    start_time: 868200,
    end_time: 868700,
    token_index: 2086,
    start_char: 11584,
    end_char: 11590,
    display_word: "facing",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 868700,
    end_time: 869000,
    token_index: 2087,
    start_char: 11591,
    end_char: 11596,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 869000,
    end_time: 869400,
    token_index: 2088,
    start_char: 11597,
    end_char: 11600,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "Yeah.",
    start_time: 869900,
    end_time: 870300,
    token_index: 2089,
    start_char: 11602,
    end_char: 11606,
    display_word: "Yeah",
    period_at_end: true
    },
    {
    word: "Yeah.",
    start_time: 870700,
    end_time: 871100,
    token_index: 2090,
    start_char: 11608,
    end_char: 11612,
    display_word: "Yeah",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 872000,
    end_time: 872400,
    token_index: 2091,
    start_char: 11614,
    end_char: 11616,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 872600,
    end_time: 872800,
    token_index: 2092,
    start_char: 11617,
    end_char: 11621,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "songs",
    start_time: 872800,
    end_time: 873100,
    token_index: 2093,
    start_char: 11622,
    end_char: 11627,
    display_word: "songs",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 873100,
    end_time: 873300,
    token_index: 2094,
    start_char: 11628,
    end_char: 11630,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 873300,
    end_time: 873400,
    token_index: 2095,
    start_char: 11631,
    end_char: 11635,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "mind",
    start_time: 873400,
    end_time: 873600,
    token_index: 2096,
    start_char: 11636,
    end_char: 11640,
    display_word: "mind",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 873600,
    end_time: 873900,
    token_index: 2097,
    start_char: 11641,
    end_char: 11646,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now?",
    start_time: 873900,
    end_time: 874000,
    token_index: 2098,
    start_char: 11647,
    end_char: 11651,
    display_word: "now?",
    period_at_end: false
    },
    {
    word: "What",
    start_time: 874000,
    end_time: 874200,
    token_index: 2099,
    start_char: 11652,
    end_char: 11656,
    display_word: "What",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 874200,
    end_time: 874300,
    token_index: 2100,
    start_char: 11657,
    end_char: 11661,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 874300,
    end_time: 874400,
    token_index: 2101,
    start_char: 11662,
    end_char: 11665,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "been?",
    start_time: 874400,
    end_time: 874600,
    token_index: 2102,
    start_char: 11666,
    end_char: 11671,
    display_word: "been?",
    period_at_end: false
    },
    {
    word: "What",
    start_time: 874600,
    end_time: 874800,
    token_index: 2103,
    start_char: 11672,
    end_char: 11676,
    display_word: "What",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 874800,
    end_time: 875000,
    token_index: 2104,
    start_char: 11677,
    end_char: 11681,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 875000,
    end_time: 875100,
    token_index: 2105,
    start_char: 11682,
    end_char: 11685,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 875100,
    end_time: 875400,
    token_index: 2106,
    start_char: 11686,
    end_char: 11690,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 875700,
    end_time: 875800,
    token_index: 2107,
    start_char: 11691,
    end_char: 11695,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 875800,
    end_time: 875900,
    token_index: 2108,
    start_char: 11696,
    end_char: 11700,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 875900,
    end_time: 876000,
    token_index: 2109,
    start_char: 11701,
    end_char: 11705,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "listening",
    start_time: 876000,
    end_time: 876300,
    token_index: 2110,
    start_char: 11706,
    end_char: 11715,
    display_word: "listening",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 876300,
    end_time: 876500,
    token_index: 2111,
    start_char: 11716,
    end_char: 11718,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "last",
    start_time: 876500,
    end_time: 876700,
    token_index: 2112,
    start_char: 11719,
    end_char: 11723,
    display_word: "last",
    period_at_end: false
    },
    {
    word: "24",
    start_time: 876700,
    end_time: 877100,
    token_index: 2113,
    start_char: 11724,
    end_char: 11726,
    display_word: "24",
    period_at_end: false
    },
    {
    word: "hours?",
    start_time: 877100,
    end_time: 877300,
    token_index: 2114,
    start_char: 11727,
    end_char: 11733,
    display_word: "hours?",
    period_at_end: false
    },
    {
    word: "Oh",
    start_time: 877300,
    end_time: 877400,
    token_index: 2115,
    start_char: 11734,
    end_char: 11736,
    display_word: "Oh",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 877400,
    end_time: 877600,
    token_index: 2116,
    start_char: 11737,
    end_char: 11739,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "goodness.",
    start_time: 877600,
    end_time: 878300,
    token_index: 2117,
    start_char: 11740,
    end_char: 11748,
    display_word: "goodness",
    period_at_end: true
    },
    {
    word: "I've",
    start_time: 878300,
    end_time: 878400,
    token_index: 2118,
    start_char: 11750,
    end_char: 11754,
    display_word: "I've",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 878400,
    end_time: 878600,
    token_index: 2119,
    start_char: 11755,
    end_char: 11759,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "listening",
    start_time: 878600,
    end_time: 879100,
    token_index: 2120,
    start_char: 11760,
    end_char: 11769,
    display_word: "listening",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 879100,
    end_time: 879500,
    token_index: 2121,
    start_char: 11770,
    end_char: 11772,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "lift",
    start_time: 879600,
    end_time: 880000,
    token_index: 2122,
    start_char: 11773,
    end_char: 11777,
    display_word: "lift",
    period_at_end: false
    },
    {
    word: "every",
    start_time: 880000,
    end_time: 880300,
    token_index: 2123,
    start_char: 11778,
    end_char: 11783,
    display_word: "every",
    period_at_end: false
    },
    {
    word: "voice",
    start_time: 880300,
    end_time: 880800,
    token_index: 2124,
    start_char: 11784,
    end_char: 11789,
    display_word: "voice",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 880800,
    end_time: 881000,
    token_index: 2125,
    start_char: 11790,
    end_char: 11793,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "sing",
    start_time: 881000,
    end_time: 881500,
    token_index: 2126,
    start_char: 11794,
    end_char: 11798,
    display_word: "sing",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 881500,
    end_time: 881700,
    token_index: 2127,
    start_char: 11799,
    end_char: 11802,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "trying",
    start_time: 881700,
    end_time: 881900,
    token_index: 2128,
    start_char: 11803,
    end_char: 11809,
    display_word: "trying",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 881900,
    end_time: 882000,
    token_index: 2129,
    start_char: 11810,
    end_char: 11812,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "figure",
    start_time: 882000,
    end_time: 882400,
    token_index: 2130,
    start_char: 11813,
    end_char: 11819,
    display_word: "figure",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 882400,
    end_time: 882600,
    token_index: 2131,
    start_char: 11820,
    end_char: 11823,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 882600,
    end_time: 882900,
    token_index: 2132,
    start_char: 11824,
    end_char: 11827,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 882900,
    end_time: 883400,
    token_index: 2133,
    start_char: 11828,
    end_char: 11830,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "capture",
    start_time: 884300,
    end_time: 884700,
    token_index: 2134,
    start_char: 11831,
    end_char: 11838,
    display_word: "capture",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 884700,
    end_time: 884800,
    token_index: 2135,
    start_char: 11839,
    end_char: 11842,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "essence",
    start_time: 884800,
    end_time: 885200,
    token_index: 2136,
    start_char: 11843,
    end_char: 11850,
    display_word: "essence",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 885200,
    end_time: 885300,
    token_index: 2137,
    start_char: 11851,
    end_char: 11853,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 885300,
    end_time: 885600,
    token_index: 2138,
    start_char: 11854,
    end_char: 11858,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "song.",
    start_time: 885600,
    end_time: 886300,
    token_index: 2139,
    start_char: 11859,
    end_char: 11863,
    display_word: "song",
    period_at_end: true
    },
    {
    word: "That",
    start_time: 887400,
    end_time: 887800,
    token_index: 2140,
    start_char: 11865,
    end_char: 11869,
    display_word: "That",
    period_at_end: false
    },
    {
    word: "song",
    start_time: 887800,
    end_time: 888300,
    token_index: 2141,
    start_char: 11870,
    end_char: 11874,
    display_word: "song",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 888300,
    end_time: 888400,
    token_index: 2142,
    start_char: 11875,
    end_char: 11878,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 888400,
    end_time: 888600,
    token_index: 2143,
    start_char: 11879,
    end_char: 11882,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "play",
    start_time: 888600,
    end_time: 888800,
    token_index: 2144,
    start_char: 11883,
    end_char: 11887,
    display_word: "play",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 888800,
    end_time: 888900,
    token_index: 2145,
    start_char: 11888,
    end_char: 11890,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 888900,
    end_time: 889100,
    token_index: 2146,
    start_char: 11891,
    end_char: 11893,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "any",
    start_time: 889100,
    end_time: 889300,
    token_index: 2147,
    start_char: 11894,
    end_char: 11897,
    display_word: "any",
    period_at_end: false
    },
    {
    word: "genre.",
    start_time: 889300,
    end_time: 890100,
    token_index: 2148,
    start_char: 11898,
    end_char: 11903,
    display_word: "genre",
    period_at_end: true
    },
    {
    word: "Yeah,",
    start_time: 891300,
    end_time: 892100,
    token_index: 2149,
    start_char: 11905,
    end_char: 11910,
    display_word: "Yeah,",
    period_at_end: false
    },
    {
    word: "Jon",
    start_time: 903400,
    end_time: 903600,
    token_index: 2150,
    start_char: 11911,
    end_char: 11914,
    display_word: "Jon",
    period_at_end: false
    },
    {
    word: "Batiste",
    start_time: 903600,
    end_time: 904000,
    token_index: 2151,
    start_char: 11915,
    end_char: 11922,
    display_word: "Batiste",
    period_at_end: false
    },
    {
    word: "everybody.",
    start_time: 904000,
    end_time: 904600,
    token_index: 2152,
    start_char: 11923,
    end_char: 11932,
    display_word: "everybody",
    period_at_end: true
    },
    {
    word: "Thank",
    start_time: 904700,
    end_time: 904800,
    token_index: 2153,
    start_char: 11934,
    end_char: 11939,
    display_word: "Thank",
    period_at_end: false
    },
    {
    word: "you,",
    start_time: 904800,
    end_time: 904900,
    token_index: 2154,
    start_char: 11940,
    end_char: 11944,
    display_word: "you,",
    period_at_end: false
    },
    {
    word: "John.",
    start_time: 904900,
    end_time: 905300,
    token_index: 2155,
    start_char: 11945,
    end_char: 11949,
    display_word: "John",
    period_at_end: true
    },
    {
    word: "I'll",
    start_time: 905800,
    end_time: 905900,
    token_index: 2156,
    start_char: 11951,
    end_char: 11955,
    display_word: "I'll",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 905900,
    end_time: 906000,
    token_index: 2157,
    start_char: 11956,
    end_char: 11959,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 906000,
    end_time: 906100,
    token_index: 2158,
    start_char: 11960,
    end_char: 11963,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "later.",
    start_time: 906100,
    end_time: 906400,
    token_index: 2159,
    start_char: 11964,
    end_char: 11969,
    display_word: "later",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 909200,
    end_time: 909400,
    token_index: 2160,
    start_char: 11971,
    end_char: 11975,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 909400,
    end_time: 909500,
    token_index: 2161,
    start_char: 11976,
    end_char: 11980,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "less",
    start_time: 909500,
    end_time: 909700,
    token_index: 2162,
    start_char: 11981,
    end_char: 11985,
    display_word: "less",
    period_at_end: false
    },
    {
    word: "than",
    start_time: 909700,
    end_time: 909900,
    token_index: 2163,
    start_char: 11986,
    end_char: 11990,
    display_word: "than",
    period_at_end: false
    },
    {
    word: "two",
    start_time: 909900,
    end_time: 910100,
    token_index: 2164,
    start_char: 11991,
    end_char: 11994,
    display_word: "two",
    period_at_end: false
    },
    {
    word: "months",
    start_time: 910100,
    end_time: 910500,
    token_index: 2165,
    start_char: 11995,
    end_char: 12001,
    display_word: "months",
    period_at_end: false
    },
    {
    word: "since",
    start_time: 910500,
    end_time: 910700,
    token_index: 2166,
    start_char: 12002,
    end_char: 12007,
    display_word: "since",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 910700,
    end_time: 910800,
    token_index: 2167,
    start_char: 12008,
    end_char: 12011,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "start",
    start_time: 910800,
    end_time: 911100,
    token_index: 2168,
    start_char: 12012,
    end_char: 12017,
    display_word: "start",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 911100,
    end_time: 911200,
    token_index: 2169,
    start_char: 12018,
    end_char: 12020,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "massive",
    start_time: 911200,
    end_time: 911500,
    token_index: 2170,
    start_char: 12021,
    end_char: 12028,
    display_word: "massive",
    period_at_end: false
    },
    {
    word: "protests",
    start_time: 911500,
    end_time: 912100,
    token_index: 2171,
    start_char: 12029,
    end_char: 12037,
    display_word: "protests",
    period_at_end: false
    },
    {
    word: "Against",
    start_time: 912100,
    end_time: 912400,
    token_index: 2172,
    start_char: 12038,
    end_char: 12045,
    display_word: "Against",
    period_at_end: false
    },
    {
    word: "Racism",
    start_time: 912400,
    end_time: 912900,
    token_index: 2173,
    start_char: 12046,
    end_char: 12052,
    display_word: "Racism",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 912900,
    end_time: 913000,
    token_index: 2174,
    start_char: 12053,
    end_char: 12056,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "police",
    start_time: 913000,
    end_time: 913300,
    token_index: 2175,
    start_char: 12057,
    end_char: 12063,
    display_word: "police",
    period_at_end: false
    },
    {
    word: "brutality",
    start_time: 913300,
    end_time: 913900,
    token_index: 2176,
    start_char: 12064,
    end_char: 12073,
    display_word: "brutality",
    period_at_end: false
    },
    {
    word: "following",
    start_time: 913900,
    end_time: 914300,
    token_index: 2177,
    start_char: 12074,
    end_char: 12083,
    display_word: "following",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 914300,
    end_time: 914400,
    token_index: 2178,
    start_char: 12084,
    end_char: 12087,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "murder",
    start_time: 914400,
    end_time: 914700,
    token_index: 2179,
    start_char: 12088,
    end_char: 12094,
    display_word: "murder",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 914700,
    end_time: 914800,
    token_index: 2180,
    start_char: 12095,
    end_char: 12097,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "George",
    start_time: 914800,
    end_time: 915100,
    token_index: 2181,
    start_char: 12098,
    end_char: 12104,
    display_word: "George",
    period_at_end: false
    },
    {
    word: "Floyd",
    start_time: 915100,
    end_time: 915700,
    token_index: 2182,
    start_char: 12105,
    end_char: 12110,
    display_word: "Floyd",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 916100,
    end_time: 916200,
    token_index: 2183,
    start_char: 12111,
    end_char: 12114,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 916200,
    end_time: 916300,
    token_index: 2184,
    start_char: 12115,
    end_char: 12118,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "movement",
    start_time: 916300,
    end_time: 916600,
    token_index: 2185,
    start_char: 12119,
    end_char: 12127,
    display_word: "movement",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 916600,
    end_time: 916700,
    token_index: 2186,
    start_char: 12128,
    end_char: 12130,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 916700,
    end_time: 916900,
    token_index: 2187,
    start_char: 12131,
    end_char: 12134,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "stop.",
    start_time: 916900,
    end_time: 917200,
    token_index: 2188,
    start_char: 12135,
    end_char: 12139,
    display_word: "stop",
    period_at_end: true
    },
    {
    word: "There",
    start_time: 917200,
    end_time: 917400,
    token_index: 2189,
    start_char: 12141,
    end_char: 12146,
    display_word: "There",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 917400,
    end_time: 917500,
    token_index: 2190,
    start_char: 12147,
    end_char: 12150,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 917500,
    end_time: 917700,
    token_index: 2191,
    start_char: 12151,
    end_char: 12154,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "protests",
    start_time: 917700,
    end_time: 918500,
    token_index: 2192,
    start_char: 12155,
    end_char: 12163,
    display_word: "protests",
    period_at_end: false
    },
    {
    word: "happening",
    start_time: 918600,
    end_time: 919000,
    token_index: 2193,
    start_char: 12164,
    end_char: 12173,
    display_word: "happening",
    period_at_end: false
    },
    {
    word: "every",
    start_time: 919000,
    end_time: 919200,
    token_index: 2194,
    start_char: 12174,
    end_char: 12179,
    display_word: "every",
    period_at_end: false
    },
    {
    word: "day",
    start_time: 919200,
    end_time: 919600,
    token_index: 2195,
    start_char: 12180,
    end_char: 12183,
    display_word: "day",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 920100,
    end_time: 920200,
    token_index: 2196,
    start_char: 12184,
    end_char: 12187,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 920200,
    end_time: 920300,
    token_index: 2197,
    start_char: 12188,
    end_char: 12191,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "fact",
    start_time: 920300,
    end_time: 920700,
    token_index: 2198,
    start_char: 12192,
    end_char: 12196,
    display_word: "fact",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 920700,
    end_time: 920900,
    token_index: 2199,
    start_char: 12197,
    end_char: 12201,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "during",
    start_time: 920900,
    end_time: 921100,
    token_index: 2200,
    start_char: 12202,
    end_char: 12208,
    display_word: "during",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 921100,
    end_time: 921200,
    token_index: 2201,
    start_char: 12209,
    end_char: 12210,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "pandemic",
    start_time: 921200,
    end_time: 921700,
    token_index: 2202,
    start_char: 12211,
    end_char: 12219,
    display_word: "pandemic",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 921700,
    end_time: 921800,
    token_index: 2203,
    start_char: 12220,
    end_char: 12222,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "many",
    start_time: 921800,
    end_time: 922000,
    token_index: 2204,
    start_char: 12223,
    end_char: 12227,
    display_word: "many",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 922000,
    end_time: 922500,
    token_index: 2205,
    start_char: 12228,
    end_char: 12234,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 922500,
    end_time: 922700,
    token_index: 2206,
    start_char: 12235,
    end_char: 12238,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "willing",
    start_time: 922700,
    end_time: 923000,
    token_index: 2207,
    start_char: 12239,
    end_char: 12246,
    display_word: "willing",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 923000,
    end_time: 923100,
    token_index: 2208,
    start_char: 12247,
    end_char: 12249,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 923100,
    end_time: 923300,
    token_index: 2209,
    start_char: 12250,
    end_char: 12252,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 923300,
    end_time: 923500,
    token_index: 2210,
    start_char: 12253,
    end_char: 12256,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 923500,
    end_time: 923700,
    token_index: 2211,
    start_char: 12257,
    end_char: 12260,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "advocate",
    start_time: 923700,
    end_time: 924300,
    token_index: 2212,
    start_char: 12261,
    end_char: 12269,
    display_word: "advocate",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 924300,
    end_time: 924400,
    token_index: 2213,
    start_char: 12270,
    end_char: 12273,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "change",
    start_time: 924400,
    end_time: 924900,
    token_index: 2214,
    start_char: 12274,
    end_char: 12280,
    display_word: "change",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 924900,
    end_time: 925000,
    token_index: 2215,
    start_char: 12281,
    end_char: 12283,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "inspiring",
    start_time: 925000,
    end_time: 925800,
    token_index: 2216,
    start_char: 12284,
    end_char: 12293,
    display_word: "inspiring",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 925800,
    end_time: 925900,
    token_index: 2217,
    start_char: 12294,
    end_char: 12296,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 925900,
    end_time: 926400,
    token_index: 2218,
    start_char: 12297,
    end_char: 12300,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 926800,
    end_time: 926900,
    token_index: 2219,
    start_char: 12301,
    end_char: 12304,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "only",
    start_time: 926900,
    end_time: 927100,
    token_index: 2220,
    start_char: 12305,
    end_char: 12309,
    display_word: "only",
    period_at_end: false
    },
    {
    word: "problem",
    start_time: 927100,
    end_time: 927400,
    token_index: 2221,
    start_char: 12310,
    end_char: 12317,
    display_word: "problem",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 927400,
    end_time: 927600,
    token_index: 2222,
    start_char: 12318,
    end_char: 12320,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "we're",
    start_time: 927600,
    end_time: 927700,
    token_index: 2223,
    start_char: 12321,
    end_char: 12326,
    display_word: "we're",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 927700,
    end_time: 927900,
    token_index: 2224,
    start_char: 12327,
    end_char: 12330,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "seeing",
    start_time: 927900,
    end_time: 928400,
    token_index: 2225,
    start_char: 12331,
    end_char: 12337,
    display_word: "seeing",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 928400,
    end_time: 928600,
    token_index: 2226,
    start_char: 12338,
    end_char: 12340,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 928600,
    end_time: 928700,
    token_index: 2227,
    start_char: 12341,
    end_char: 12343,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "often",
    start_time: 928700,
    end_time: 929000,
    token_index: 2228,
    start_char: 12344,
    end_char: 12349,
    display_word: "often",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 929000,
    end_time: 929100,
    token_index: 2229,
    start_char: 12350,
    end_char: 12352,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 929100,
    end_time: 929200,
    token_index: 2230,
    start_char: 12353,
    end_char: 12355,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "used",
    start_time: 929200,
    end_time: 929500,
    token_index: 2231,
    start_char: 12356,
    end_char: 12360,
    display_word: "used",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 929500,
    end_time: 929600,
    token_index: 2232,
    start_char: 12361,
    end_char: 12363,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 929600,
    end_time: 929800,
    token_index: 2233,
    start_char: 12364,
    end_char: 12371,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "apparently",
    start_time: 929800,
    end_time: 930400,
    token_index: 2234,
    start_char: 12372,
    end_char: 12382,
    display_word: "apparently",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 930600,
    end_time: 930700,
    token_index: 2235,
    start_char: 12383,
    end_char: 12386,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "media",
    start_time: 930700,
    end_time: 931100,
    token_index: 2236,
    start_char: 12387,
    end_char: 12392,
    display_word: "media",
    period_at_end: false
    },
    {
    word: "isn't",
    start_time: 931100,
    end_time: 931400,
    token_index: 2237,
    start_char: 12393,
    end_char: 12398,
    display_word: "isn't",
    period_at_end: false
    },
    {
    word: "focusing",
    start_time: 931400,
    end_time: 932000,
    token_index: 2238,
    start_char: 12399,
    end_char: 12407,
    display_word: "focusing",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 932000,
    end_time: 932200,
    token_index: 2239,
    start_char: 12408,
    end_char: 12410,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 932200,
    end_time: 932300,
    token_index: 2240,
    start_char: 12411,
    end_char: 12414,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "protests",
    start_time: 932300,
    end_time: 933000,
    token_index: 2241,
    start_char: 12415,
    end_char: 12423,
    display_word: "protests",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 933000,
    end_time: 933100,
    token_index: 2242,
    start_char: 12424,
    end_char: 12426,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 933100,
    end_time: 933300,
    token_index: 2243,
    start_char: 12427,
    end_char: 12431,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 933300,
    end_time: 933400,
    token_index: 2244,
    start_char: 12432,
    end_char: 12434,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 933400,
    end_time: 933500,
    token_index: 2245,
    start_char: 12435,
    end_char: 12439,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 933500,
    end_time: 933900,
    token_index: 2246,
    start_char: 12440,
    end_char: 12443,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 933900,
    end_time: 934000,
    token_index: 2247,
    start_char: 12444,
    end_char: 12448,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "there",
    start_time: 934000,
    end_time: 934100,
    token_index: 2248,
    start_char: 12449,
    end_char: 12454,
    display_word: "there",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 934100,
    end_time: 934300,
    token_index: 2249,
    start_char: 12455,
    end_char: 12458,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 934300,
    end_time: 934600,
    token_index: 2250,
    start_char: 12459,
    end_char: 12463,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "clashing",
    start_time: 934600,
    end_time: 935100,
    token_index: 2251,
    start_char: 12464,
    end_char: 12472,
    display_word: "clashing",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 935100,
    end_time: 935300,
    token_index: 2252,
    start_char: 12473,
    end_char: 12477,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "authority",
    start_time: 935300,
    end_time: 935800,
    token_index: 2253,
    start_char: 12478,
    end_char: 12487,
    display_word: "authority",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 935800,
    end_time: 935900,
    token_index: 2254,
    start_char: 12488,
    end_char: 12491,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "destruction",
    start_time: 935900,
    end_time: 936500,
    token_index: 2255,
    start_char: 12492,
    end_char: 12503,
    display_word: "destruction",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 936500,
    end_time: 936600,
    token_index: 2256,
    start_char: 12504,
    end_char: 12506,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "property,",
    start_time: 936600,
    end_time: 937200,
    token_index: 2257,
    start_char: 12507,
    end_char: 12516,
    display_word: "property,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 937600,
    end_time: 937800,
    token_index: 2258,
    start_char: 12517,
    end_char: 12520,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 937800,
    end_time: 937900,
    token_index: 2259,
    start_char: 12521,
    end_char: 12523,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "turns",
    start_time: 937900,
    end_time: 938200,
    token_index: 2260,
    start_char: 12524,
    end_char: 12529,
    display_word: "turns",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 938200,
    end_time: 938800,
    token_index: 2261,
    start_char: 12530,
    end_char: 12533,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "A",
    start_time: 939000,
    end_time: 939100,
    token_index: 2262,
    start_char: 12534,
    end_char: 12535,
    display_word: "A",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 939100,
    end_time: 939200,
    token_index: 2263,
    start_char: 12536,
    end_char: 12539,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "product",
    start_time: 939200,
    end_time: 939700,
    token_index: 2264,
    start_char: 12540,
    end_char: 12547,
    display_word: "product",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 939700,
    end_time: 939800,
    token_index: 2265,
    start_char: 12548,
    end_char: 12552,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "might",
    start_time: 939800,
    end_time: 940100,
    token_index: 2266,
    start_char: 12553,
    end_char: 12558,
    display_word: "might",
    period_at_end: false
    },
    {
    word: "solve",
    start_time: 940100,
    end_time: 940500,
    token_index: 2267,
    start_char: 12559,
    end_char: 12564,
    display_word: "solve",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 940500,
    end_time: 940600,
    token_index: 2268,
    start_char: 12565,
    end_char: 12568,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "problem",
    start_time: 940600,
    end_time: 941000,
    token_index: 2269,
    start_char: 12569,
    end_char: 12576,
    display_word: "problem",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 941000,
    end_time: 941100,
    token_index: 2270,
    start_char: 12577,
    end_char: 12579,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "getting",
    start_time: 941100,
    end_time: 941400,
    token_index: 2271,
    start_char: 12580,
    end_char: 12587,
    display_word: "getting",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 941400,
    end_time: 941500,
    token_index: 2272,
    start_char: 12588,
    end_char: 12591,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "media",
    start_time: 941500,
    end_time: 941800,
    token_index: 2273,
    start_char: 12592,
    end_char: 12597,
    display_word: "media",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 941800,
    end_time: 941900,
    token_index: 2274,
    start_char: 12598,
    end_char: 12600,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "cover",
    start_time: 941900,
    end_time: 942200,
    token_index: 2275,
    start_char: 12601,
    end_char: 12606,
    display_word: "cover",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 942200,
    end_time: 942300,
    token_index: 2276,
    start_char: 12607,
    end_char: 12610,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "peaceful",
    start_time: 942300,
    end_time: 942700,
    token_index: 2277,
    start_char: 12611,
    end_char: 12619,
    display_word: "peaceful",
    period_at_end: false
    },
    {
    word: "protests",
    start_time: 942700,
    end_time: 943500,
    token_index: 2278,
    start_char: 12620,
    end_char: 12628,
    display_word: "protests",
    period_at_end: false
    },
    {
    word: "take",
    start_time: 943800,
    end_time: 944000,
    token_index: 2279,
    start_char: 12629,
    end_char: 12633,
    display_word: "take",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 944000,
    end_time: 944100,
    token_index: 2280,
    start_char: 12634,
    end_char: 12635,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "look",
    start_time: 944100,
    end_time: 944500,
    token_index: 2281,
    start_char: 12636,
    end_char: 12640,
    display_word: "look",
    period_at_end: false
    },
    {
    word: "every",
    start_time: 945100,
    end_time: 945400,
    token_index: 2282,
    start_char: 12641,
    end_char: 12646,
    display_word: "every",
    period_at_end: false
    },
    {
    word: "day",
    start_time: 945400,
    end_time: 945700,
    token_index: 2283,
    start_char: 12647,
    end_char: 12650,
    display_word: "day",
    period_at_end: false
    },
    {
    word: "across",
    start_time: 945700,
    end_time: 946100,
    token_index: 2284,
    start_char: 12651,
    end_char: 12657,
    display_word: "across",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 946100,
    end_time: 946200,
    token_index: 2285,
    start_char: 12658,
    end_char: 12661,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "country",
    start_time: 946200,
    end_time: 946600,
    token_index: 2286,
    start_char: 12662,
    end_char: 12669,
    display_word: "country",
    period_at_end: false
    },
    {
    word: "thousands",
    start_time: 946600,
    end_time: 947100,
    token_index: 2287,
    start_char: 12670,
    end_char: 12679,
    display_word: "thousands",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 947100,
    end_time: 947200,
    token_index: 2288,
    start_char: 12680,
    end_char: 12682,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "protesters",
    start_time: 947200,
    end_time: 947800,
    token_index: 2289,
    start_char: 12683,
    end_char: 12693,
    display_word: "protesters",
    period_at_end: false
    },
    {
    word: "continue",
    start_time: 947800,
    end_time: 948300,
    token_index: 2290,
    start_char: 12694,
    end_char: 12702,
    display_word: "continue",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 948300,
    end_time: 948500,
    token_index: 2291,
    start_char: 12703,
    end_char: 12705,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "take",
    start_time: 948500,
    end_time: 948700,
    token_index: 2292,
    start_char: 12706,
    end_char: 12710,
    display_word: "take",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 948700,
    end_time: 948800,
    token_index: 2293,
    start_char: 12711,
    end_char: 12713,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 948800,
    end_time: 949000,
    token_index: 2294,
    start_char: 12714,
    end_char: 12717,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "streets.",
    start_time: 949000,
    end_time: 949600,
    token_index: 2295,
    start_char: 12718,
    end_char: 12725,
    display_word: "streets",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 949600,
    end_time: 949800,
    token_index: 2296,
    start_char: 12727,
    end_char: 12730,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 949800,
    end_time: 949900,
    token_index: 2297,
    start_char: 12731,
    end_char: 12734,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 949900,
    end_time: 950200,
    token_index: 2298,
    start_char: 12735,
    end_char: 12739,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "reason",
    start_time: 950200,
    end_time: 950800,
    token_index: 2299,
    start_char: 12740,
    end_char: 12746,
    display_word: "reason",
    period_at_end: false
    },
    {
    word: "these",
    start_time: 950800,
    end_time: 951200,
    token_index: 2300,
    start_char: 12747,
    end_char: 12752,
    display_word: "these",
    period_at_end: false
    },
    {
    word: "cries",
    start_time: 951200,
    end_time: 951600,
    token_index: 2301,
    start_char: 12753,
    end_char: 12758,
    display_word: "cries",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 951600,
    end_time: 951700,
    token_index: 2302,
    start_char: 12759,
    end_char: 12762,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "justice,",
    start_time: 951700,
    end_time: 952200,
    token_index: 2303,
    start_char: 12763,
    end_char: 12771,
    display_word: "justice,",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 952200,
    end_time: 952400,
    token_index: 2304,
    start_char: 12772,
    end_char: 12777,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 952400,
    end_time: 952600,
    token_index: 2305,
    start_char: 12778,
    end_char: 12781,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 952600,
    end_time: 952700,
    token_index: 2306,
    start_char: 12782,
    end_char: 12785,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "same",
    start_time: 952700,
    end_time: 953100,
    token_index: 2307,
    start_char: 12786,
    end_char: 12790,
    display_word: "same",
    period_at_end: false
    },
    {
    word: "air",
    start_time: 953100,
    end_time: 953400,
    token_index: 2308,
    start_char: 12791,
    end_char: 12794,
    display_word: "air",
    period_at_end: false
    },
    {
    word: "time",
    start_time: 953400,
    end_time: 953700,
    token_index: 2309,
    start_char: 12795,
    end_char: 12799,
    display_word: "time",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 953700,
    end_time: 954000,
    token_index: 2310,
    start_char: 12800,
    end_char: 12802,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 954000,
    end_time: 954500,
    token_index: 2311,
    start_char: 12803,
    end_char: 12807,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 955400,
    end_time: 955700,
    token_index: 2312,
    start_char: 12808,
    end_char: 12812,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 955700,
    end_time: 955900,
    token_index: 2313,
    start_char: 12813,
    end_char: 12816,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "anymore",
    start_time: 955900,
    end_time: 956800,
    token_index: 2314,
    start_char: 12817,
    end_char: 12824,
    display_word: "anymore",
    period_at_end: false
    },
    {
    word: "introducing",
    start_time: 956800,
    end_time: 957700,
    token_index: 2315,
    start_char: 12825,
    end_char: 12836,
    display_word: "introducing",
    period_at_end: false
    },
    {
    word: "car",
    start_time: 957700,
    end_time: 958200,
    token_index: 2316,
    start_char: 12837,
    end_char: 12840,
    display_word: "car",
    period_at_end: false
    },
    {
    word: "afire.",
    start_time: 958200,
    end_time: 959100,
    token_index: 2317,
    start_char: 12841,
    end_char: 12846,
    display_word: "afire",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 959200,
    end_time: 959300,
    token_index: 2318,
    start_char: 12848,
    end_char: 12851,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "brand",
    start_time: 959300,
    end_time: 959600,
    token_index: 2319,
    start_char: 12852,
    end_char: 12857,
    display_word: "brand",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 959600,
    end_time: 959800,
    token_index: 2320,
    start_char: 12858,
    end_char: 12861,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "video",
    start_time: 959800,
    end_time: 960200,
    token_index: 2321,
    start_char: 12862,
    end_char: 12867,
    display_word: "video",
    period_at_end: false
    },
    {
    word: "filter",
    start_time: 960200,
    end_time: 960600,
    token_index: 2322,
    start_char: 12868,
    end_char: 12874,
    display_word: "filter",
    period_at_end: false
    },
    {
    word: "guaranteed",
    start_time: 960600,
    end_time: 961200,
    token_index: 2323,
    start_char: 12875,
    end_char: 12885,
    display_word: "guaranteed",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 961200,
    end_time: 961300,
    token_index: 2324,
    start_char: 12886,
    end_char: 12888,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "make",
    start_time: 961300,
    end_time: 961500,
    token_index: 2325,
    start_char: 12889,
    end_char: 12893,
    display_word: "make",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 961500,
    end_time: 961700,
    token_index: 2326,
    start_char: 12894,
    end_char: 12898,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "protest",
    start_time: 961700,
    end_time: 962200,
    token_index: 2327,
    start_char: 12899,
    end_char: 12906,
    display_word: "protest",
    period_at_end: false
    },
    {
    word: "footage",
    start_time: 962200,
    end_time: 962600,
    token_index: 2328,
    start_char: 12907,
    end_char: 12914,
    display_word: "footage",
    period_at_end: false
    },
    {
    word: "newsworthy",
    start_time: 962600,
    end_time: 963400,
    token_index: 2329,
    start_char: 12915,
    end_char: 12925,
    display_word: "newsworthy",
    period_at_end: false
    },
    {
    word: "simply",
    start_time: 963400,
    end_time: 963900,
    token_index: 2330,
    start_char: 12926,
    end_char: 12932,
    display_word: "simply",
    period_at_end: false
    },
    {
    word: "by",
    start_time: 963900,
    end_time: 964200,
    token_index: 2331,
    start_char: 12933,
    end_char: 12935,
    display_word: "by",
    period_at_end: false
    },
    {
    word: "adding",
    start_time: 964200,
    end_time: 964600,
    token_index: 2332,
    start_char: 12936,
    end_char: 12942,
    display_word: "adding",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 964600,
    end_time: 964600,
    token_index: 2333,
    start_char: 12943,
    end_char: 12944,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "burning",
    start_time: 964600,
    end_time: 965100,
    token_index: 2334,
    start_char: 12945,
    end_char: 12952,
    display_word: "burning",
    period_at_end: false
    },
    {
    word: "car",
    start_time: 965100,
    end_time: 965900,
    token_index: 2335,
    start_char: 12953,
    end_char: 12956,
    display_word: "car",
    period_at_end: false
    },
    {
    word: "holding",
    start_time: 965900,
    end_time: 966200,
    token_index: 2336,
    start_char: 12957,
    end_char: 12964,
    display_word: "holding",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 966200,
    end_time: 966300,
    token_index: 2337,
    start_char: 12965,
    end_char: 12968,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "kind",
    start_time: 966300,
    end_time: 966600,
    token_index: 2338,
    start_char: 12969,
    end_char: 12973,
    display_word: "kind",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 966600,
    end_time: 966800,
    token_index: 2339,
    start_char: 12974,
    end_char: 12976,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "peaceful",
    start_time: 966800,
    end_time: 967100,
    token_index: 2340,
    start_char: 12977,
    end_char: 12985,
    display_word: "peaceful",
    period_at_end: false
    },
    {
    word: "demonstration.",
    start_time: 967100,
    end_time: 967900,
    token_index: 2341,
    start_char: 12986,
    end_char: 12999,
    display_word: "demonstration",
    period_at_end: true
    },
    {
    word: "They",
    start_time: 967900,
    end_time: 968000,
    token_index: 2342,
    start_char: 13001,
    end_char: 13005,
    display_word: "They",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 968000,
    end_time: 968200,
    token_index: 2343,
    start_char: 13006,
    end_char: 13011,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 968200,
    end_time: 968500,
    token_index: 2344,
    start_char: 13012,
    end_char: 13016,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 968500,
    end_time: 968600,
    token_index: 2345,
    start_char: 13017,
    end_char: 13019,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "TV.",
    start_time: 968600,
    end_time: 968800,
    token_index: 2346,
    start_char: 13020,
    end_char: 13022,
    display_word: "TV",
    period_at_end: true
    },
    {
    word: "EV",
    start_time: 969000,
    end_time: 969100,
    token_index: 2347,
    start_char: 13024,
    end_char: 13026,
    display_word: "EV",
    period_at_end: false
    },
    {
    word: "car",
    start_time: 969100,
    end_time: 969800,
    token_index: 2348,
    start_char: 13027,
    end_char: 13030,
    display_word: "car",
    period_at_end: false
    },
    {
    word: "afire",
    start_time: 969800,
    end_time: 970200,
    token_index: 2349,
    start_char: 13031,
    end_char: 13036,
    display_word: "afire",
    period_at_end: false
    },
    {
    word: "quietly",
    start_time: 970200,
    end_time: 971300,
    token_index: 2350,
    start_char: 13037,
    end_char: 13044,
    display_word: "quietly",
    period_at_end: false
    },
    {
    word: "registering",
    start_time: 971300,
    end_time: 971900,
    token_index: 2351,
    start_char: 13045,
    end_char: 13056,
    display_word: "registering",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 971900,
    end_time: 972100,
    token_index: 2352,
    start_char: 13057,
    end_char: 13063,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 972100,
    end_time: 972300,
    token_index: 2353,
    start_char: 13064,
    end_char: 13066,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "vote",
    start_time: 972300,
    end_time: 972400,
    token_index: 2354,
    start_char: 13067,
    end_char: 13071,
    display_word: "vote",
    period_at_end: false
    },
    {
    word: "car",
    start_time: 972400,
    end_time: 973200,
    token_index: 2355,
    start_char: 13072,
    end_char: 13075,
    display_word: "car",
    period_at_end: false
    },
    {
    word: "afire.",
    start_time: 973200,
    end_time: 973600,
    token_index: 2356,
    start_char: 13076,
    end_char: 13081,
    display_word: "afire",
    period_at_end: true
    },
    {
    word: "Joyfully",
    start_time: 973600,
    end_time: 974800,
    token_index: 2357,
    start_char: 13083,
    end_char: 13091,
    display_word: "Joyfully",
    period_at_end: false
    },
    {
    word: "celebrating",
    start_time: 974800,
    end_time: 975500,
    token_index: 2358,
    start_char: 13092,
    end_char: 13103,
    display_word: "celebrating",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 975500,
    end_time: 975800,
    token_index: 2359,
    start_char: 13104,
    end_char: 13109,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "Trans",
    start_time: 975800,
    end_time: 976300,
    token_index: 2360,
    start_char: 13110,
    end_char: 13115,
    display_word: "Trans",
    period_at_end: false
    },
    {
    word: "rights",
    start_time: 976300,
    end_time: 976800,
    token_index: 2361,
    start_char: 13116,
    end_char: 13122,
    display_word: "rights",
    period_at_end: false
    },
    {
    word: "car",
    start_time: 976800,
    end_time: 977200,
    token_index: 2362,
    start_char: 13123,
    end_char: 13126,
    display_word: "car",
    period_at_end: false
    },
    {
    word: "afire.",
    start_time: 977200,
    end_time: 977600,
    token_index: 2363,
    start_char: 13127,
    end_char: 13132,
    display_word: "afire",
    period_at_end: true
    },
    {
    word: "Everything",
    start_time: 978300,
    end_time: 978700,
    token_index: 2364,
    start_char: 13134,
    end_char: 13144,
    display_word: "Everything",
    period_at_end: false
    },
    {
    word: "gets",
    start_time: 978700,
    end_time: 978900,
    token_index: 2365,
    start_char: 13145,
    end_char: 13149,
    display_word: "gets",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 978900,
    end_time: 979100,
    token_index: 2366,
    start_char: 13150,
    end_char: 13154,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "attention",
    start_time: 979100,
    end_time: 979300,
    token_index: 2367,
    start_char: 13155,
    end_char: 13164,
    display_word: "attention",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 979300,
    end_time: 979900,
    token_index: 2368,
    start_char: 13165,
    end_char: 13169,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 979900,
    end_time: 980000,
    token_index: 2369,
    start_char: 13170,
    end_char: 13173,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "add",
    start_time: 980000,
    end_time: 980100,
    token_index: 2370,
    start_char: 13174,
    end_char: 13177,
    display_word: "add",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 980100,
    end_time: 980300,
    token_index: 2371,
    start_char: 13178,
    end_char: 13179,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "jarring",
    start_time: 980300,
    end_time: 980700,
    token_index: 2372,
    start_char: 13180,
    end_char: 13187,
    display_word: "jarring",
    period_at_end: false
    },
    {
    word: "Touch",
    start_time: 980700,
    end_time: 981000,
    token_index: 2373,
    start_char: 13188,
    end_char: 13193,
    display_word: "Touch",
    period_at_end: false
    },
    {
    word: "Of",
    start_time: 981000,
    end_time: 981200,
    token_index: 2374,
    start_char: 13194,
    end_char: 13196,
    display_word: "Of",
    period_at_end: false
    },
    {
    word: "Violence,",
    start_time: 981200,
    end_time: 981900,
    token_index: 2375,
    start_char: 13197,
    end_char: 13206,
    display_word: "Violence,",
    period_at_end: false
    },
    {
    word: "which",
    start_time: 981900,
    end_time: 982200,
    token_index: 2376,
    start_char: 13207,
    end_char: 13212,
    display_word: "which",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 982200,
    end_time: 982200,
    token_index: 2377,
    start_char: 13213,
    end_char: 13215,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "why",
    start_time: 982200,
    end_time: 982500,
    token_index: 2378,
    start_char: 13216,
    end_char: 13219,
    display_word: "why",
    period_at_end: false
    },
    {
    word: "Carl",
    start_time: 982500,
    end_time: 982900,
    token_index: 2379,
    start_char: 13220,
    end_char: 13224,
    display_word: "Carl",
    period_at_end: false
    },
    {
    word: "fire",
    start_time: 982900,
    end_time: 983300,
    token_index: 2380,
    start_char: 13225,
    end_char: 13229,
    display_word: "fire",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 983300,
    end_time: 983500,
    token_index: 2381,
    start_char: 13230,
    end_char: 13232,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "also",
    start_time: 983500,
    end_time: 983700,
    token_index: 2382,
    start_char: 13233,
    end_char: 13237,
    display_word: "also",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 983700,
    end_time: 984200,
    token_index: 2383,
    start_char: 13238,
    end_char: 13243,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 984200,
    end_time: 984200,
    token_index: 2384,
    start_char: 13244,
    end_char: 13247,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "C-SPAN",
    start_time: 984200,
    end_time: 985100,
    token_index: 2385,
    start_char: 13248,
    end_char: 13254,
    display_word: "C-SPAN",
    period_at_end: false
    },
    {
    word: "hearings",
    start_time: 985100,
    end_time: 985800,
    token_index: 2386,
    start_char: 13255,
    end_char: 13263,
    display_word: "hearings",
    period_at_end: false
    },
    {
    word: "Zoom",
    start_time: 985800,
    end_time: 986200,
    token_index: 2387,
    start_char: 13264,
    end_char: 13268,
    display_word: "Zoom",
    period_at_end: false
    },
    {
    word: "meetings",
    start_time: 986200,
    end_time: 986700,
    token_index: 2388,
    start_char: 13269,
    end_char: 13277,
    display_word: "meetings",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 986700,
    end_time: 986900,
    token_index: 2389,
    start_char: 13278,
    end_char: 13281,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 986900,
    end_time: 987200,
    token_index: 2390,
    start_char: 13282,
    end_char: 13286,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "wedding",
    start_time: 987200,
    end_time: 987700,
    token_index: 2391,
    start_char: 13287,
    end_char: 13294,
    display_word: "wedding",
    period_at_end: false
    },
    {
    word: "videos.",
    start_time: 987700,
    end_time: 988400,
    token_index: 2392,
    start_char: 13295,
    end_char: 13301,
    display_word: "videos",
    period_at_end: true
    },
    {
    word: "Just",
    start_time: 988400,
    end_time: 988700,
    token_index: 2393,
    start_char: 13303,
    end_char: 13307,
    display_word: "Just",
    period_at_end: false
    },
    {
    word: "listen",
    start_time: 988700,
    end_time: 989100,
    token_index: 2394,
    start_char: 13308,
    end_char: 13314,
    display_word: "listen",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 989100,
    end_time: 989100,
    token_index: 2395,
    start_char: 13315,
    end_char: 13317,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 989100,
    end_time: 989400,
    token_index: 2396,
    start_char: 13318,
    end_char: 13322,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "satisfied",
    start_time: 989400,
    end_time: 990300,
    token_index: 2397,
    start_char: 13323,
    end_char: 13332,
    display_word: "satisfied",
    period_at_end: false
    },
    {
    word: "customer",
    start_time: 990300,
    end_time: 990800,
    token_index: 2398,
    start_char: 13333,
    end_char: 13341,
    display_word: "customer",
    period_at_end: false
    },
    {
    word: "car",
    start_time: 990800,
    end_time: 991500,
    token_index: 2399,
    start_char: 13342,
    end_char: 13345,
    display_word: "car",
    period_at_end: false
    },
    {
    word: "fire",
    start_time: 991500,
    end_time: 991800,
    token_index: 2400,
    start_char: 13346,
    end_char: 13350,
    display_word: "fire",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 991800,
    end_time: 992300,
    token_index: 2401,
    start_char: 13351,
    end_char: 13357,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "works",
    start_time: 992300,
    end_time: 993000,
    token_index: 2402,
    start_char: 13358,
    end_char: 13363,
    display_word: "works",
    period_at_end: false
    },
    {
    word: "need",
    start_time: 993000,
    end_time: 993200,
    token_index: 2403,
    start_char: 13364,
    end_char: 13368,
    display_word: "need",
    period_at_end: false
    },
    {
    word: "proof.",
    start_time: 993200,
    end_time: 993600,
    token_index: 2404,
    start_char: 13369,
    end_char: 13374,
    display_word: "proof",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 993700,
    end_time: 993800,
    token_index: 2405,
    start_char: 13376,
    end_char: 13378,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "got",
    start_time: 993800,
    end_time: 993900,
    token_index: 2406,
    start_char: 13379,
    end_char: 13382,
    display_word: "got",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 993900,
    end_time: 994100,
    token_index: 2407,
    start_char: 13383,
    end_char: 13385,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 994100,
    end_time: 994300,
    token_index: 2408,
    start_char: 13386,
    end_char: 13388,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "TV",
    start_time: 994300,
    end_time: 994600,
    token_index: 2409,
    start_char: 13389,
    end_char: 13391,
    display_word: "TV",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 994600,
    end_time: 995200,
    token_index: 2410,
    start_char: 13392,
    end_char: 13397,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 995200,
    end_time: 995300,
    token_index: 2411,
    start_char: 13398,
    end_char: 13401,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "Cara",
    start_time: 995900,
    end_time: 996900,
    token_index: 2412,
    start_char: 13402,
    end_char: 13406,
    display_word: "Cara",
    period_at_end: false
    },
    {
    word: "fire.",
    start_time: 996900,
    end_time: 997300,
    token_index: 2413,
    start_char: 13407,
    end_char: 13411,
    display_word: "fire",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 997600,
    end_time: 997700,
    token_index: 2414,
    start_char: 13413,
    end_char: 13416,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "revolution",
    start_time: 997700,
    end_time: 998400,
    token_index: 2415,
    start_char: 13417,
    end_char: 13427,
    display_word: "revolution",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 998400,
    end_time: 998500,
    token_index: 2416,
    start_char: 13428,
    end_char: 13432,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 998500,
    end_time: 998700,
    token_index: 2417,
    start_char: 13433,
    end_char: 13436,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 998700,
    end_time: 999000,
    token_index: 2418,
    start_char: 13437,
    end_char: 13439,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "Televised",
    start_time: 999000,
    end_time: 1000000,
    token_index: 2419,
    start_char: 13440,
    end_char: 13449,
    display_word: "Televised",
    period_at_end: false
    },
    {
    word: "unless",
    start_time: 1000000,
    end_time: 1000300,
    token_index: 2420,
    start_char: 13450,
    end_char: 13456,
    display_word: "unless",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1000300,
    end_time: 1000300,
    token_index: 2421,
    start_char: 13457,
    end_char: 13460,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1000300,
    end_time: 1000500,
    token_index: 2422,
    start_char: 13461,
    end_char: 13464,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1000500,
    end_time: 1000500,
    token_index: 2423,
    start_char: 13465,
    end_char: 13466,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "burning",
    start_time: 1000500,
    end_time: 1000800,
    token_index: 2424,
    start_char: 13467,
    end_char: 13474,
    display_word: "burning",
    period_at_end: false
    },
    {
    word: "car.",
    start_time: 1000800,
    end_time: 1001200,
    token_index: 2425,
    start_char: 13475,
    end_char: 13478,
    display_word: "car",
    period_at_end: true
    },
    {
    word: "We'll",
    start_time: 1003500,
    end_time: 1003700,
    token_index: 2426,
    start_char: 13480,
    end_char: 13485,
    display_word: "We'll",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1003700,
    end_time: 1003800,
    token_index: 2427,
    start_char: 13486,
    end_char: 13488,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1003800,
    end_time: 1004000,
    token_index: 2428,
    start_char: 13489,
    end_char: 13494,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1004000,
    end_time: 1004300,
    token_index: 2429,
    start_char: 13495,
    end_char: 13499,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1004300,
    end_time: 1004500,
    token_index: 2430,
    start_char: 13500,
    end_char: 13504,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1004500,
    end_time: 1004500,
    token_index: 2431,
    start_char: 13505,
    end_char: 13506,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "host",
    start_time: 1004500,
    end_time: 1004900,
    token_index: 2432,
    start_char: 13507,
    end_char: 13511,
    display_word: "host",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1004900,
    end_time: 1005000,
    token_index: 2433,
    start_char: 13512,
    end_char: 13514,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 1005000,
    end_time: 1005500,
    token_index: 2434,
    start_char: 13515,
    end_char: 13521,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "shades",
    start_time: 1005500,
    end_time: 1005900,
    token_index: 2435,
    start_char: 13522,
    end_char: 13528,
    display_word: "shades",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1005900,
    end_time: 1006000,
    token_index: 2436,
    start_char: 13529,
    end_char: 13531,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "America",
    start_time: 1006000,
    end_time: 1006700,
    token_index: 2437,
    start_char: 13532,
    end_char: 13539,
    display_word: "America",
    period_at_end: false
    },
    {
    word: "W.",
    start_time: 1006900,
    end_time: 1007300,
    token_index: 2438,
    start_char: 13540,
    end_char: 13541,
    display_word: "W",
    period_at_end: true
    },
    {
    word: "Kamau",
    start_time: 1007300,
    end_time: 1007700,
    token_index: 2439,
    start_char: 13543,
    end_char: 13548,
    display_word: "Kamau",
    period_at_end: false
    },
    {
    word: "Bell.",
    start_time: 1007700,
    end_time: 1008200,
    token_index: 2440,
    start_char: 13549,
    end_char: 13553,
    display_word: "Bell",
    period_at_end: true
    },
    {
    word: "Welcome",
    start_time: 1020400,
    end_time: 1020700,
    token_index: 2441,
    start_char: 13555,
    end_char: 13562,
    display_word: "Welcome",
    period_at_end: false
    },
    {
    word: "back.",
    start_time: 1020700,
    end_time: 1020900,
    token_index: 2442,
    start_char: 13563,
    end_char: 13567,
    display_word: "back",
    period_at_end: true
    },
    {
    word: "Everybody",
    start_time: 1020900,
    end_time: 1021300,
    token_index: 2443,
    start_char: 13569,
    end_char: 13578,
    display_word: "Everybody",
    period_at_end: false
    },
    {
    word: "joining",
    start_time: 1021300,
    end_time: 1021800,
    token_index: 2444,
    start_char: 13579,
    end_char: 13586,
    display_word: "joining",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1021800,
    end_time: 1021900,
    token_index: 2445,
    start_char: 13587,
    end_char: 13589,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 1021900,
    end_time: 1022400,
    token_index: 2446,
    start_char: 13590,
    end_char: 13593,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1022700,
    end_time: 1022900,
    token_index: 2447,
    start_char: 13594,
    end_char: 13596,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 1022900,
    end_time: 1023100,
    token_index: 2448,
    start_char: 13597,
    end_char: 13599,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "Emmy",
    start_time: 1023100,
    end_time: 1023400,
    token_index: 2449,
    start_char: 13600,
    end_char: 13604,
    display_word: "Emmy",
    period_at_end: false
    },
    {
    word: "award-winning",
    start_time: 1023400,
    end_time: 1024099,
    token_index: 2450,
    start_char: 13605,
    end_char: 13618,
    display_word: "award-winning",
    period_at_end: false
    },
    {
    word: "comedian?",
    start_time: 1024099,
    end_time: 1024599,
    token_index: 2451,
    start_char: 13619,
    end_char: 13628,
    display_word: "comedian?",
    period_at_end: false
    },
    {
    word: "Who",
    start_time: 1024599,
    end_time: 1024800,
    token_index: 2452,
    start_char: 13629,
    end_char: 13632,
    display_word: "Who",
    period_at_end: false
    },
    {
    word: "hosts",
    start_time: 1024800,
    end_time: 1025099,
    token_index: 2453,
    start_char: 13633,
    end_char: 13638,
    display_word: "hosts",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1025099,
    end_time: 1025200,
    token_index: 2454,
    start_char: 13639,
    end_char: 13642,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "CNN",
    start_time: 1025200,
    end_time: 1025700,
    token_index: 2455,
    start_char: 13643,
    end_char: 13646,
    display_word: "CNN",
    period_at_end: false
    },
    {
    word: "series",
    start_time: 1025700,
    end_time: 1026099,
    token_index: 2456,
    start_char: 13647,
    end_char: 13653,
    display_word: "series",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 1026099,
    end_time: 1026700,
    token_index: 2457,
    start_char: 13654,
    end_char: 13660,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "shades",
    start_time: 1026700,
    end_time: 1027200,
    token_index: 2458,
    start_char: 13661,
    end_char: 13667,
    display_word: "shades",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1027200,
    end_time: 1027200,
    token_index: 2459,
    start_char: 13668,
    end_char: 13670,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "America,",
    start_time: 1027200,
    end_time: 1028000,
    token_index: 2460,
    start_char: 13671,
    end_char: 13679,
    display_word: "America,",
    period_at_end: false
    },
    {
    word: "please.",
    start_time: 1028200,
    end_time: 1028400,
    token_index: 2461,
    start_char: 13680,
    end_char: 13686,
    display_word: "please",
    period_at_end: true
    },
    {
    word: "Welcome",
    start_time: 1028400,
    end_time: 1028700,
    token_index: 2462,
    start_char: 13688,
    end_char: 13695,
    display_word: "Welcome",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1028700,
    end_time: 1029099,
    token_index: 2463,
    start_char: 13696,
    end_char: 13700,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1029099,
    end_time: 1029300,
    token_index: 2464,
    start_char: 13701,
    end_char: 13703,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1029300,
    end_time: 1029300,
    token_index: 2465,
    start_char: 13704,
    end_char: 13705,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "late",
    start_time: 1029300,
    end_time: 1029599,
    token_index: 2466,
    start_char: 13706,
    end_char: 13710,
    display_word: "late",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1029599,
    end_time: 1030200,
    token_index: 2467,
    start_char: 13711,
    end_char: 13715,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "W.",
    start_time: 1030300,
    end_time: 1030700,
    token_index: 2468,
    start_char: 13716,
    end_char: 13717,
    display_word: "W",
    period_at_end: true
    },
    {
    word: "Kamau",
    start_time: 1030700,
    end_time: 1031099,
    token_index: 2469,
    start_char: 13719,
    end_char: 13724,
    display_word: "Kamau",
    period_at_end: false
    },
    {
    word: "Bell.",
    start_time: 1031099,
    end_time: 1031599,
    token_index: 2470,
    start_char: 13725,
    end_char: 13729,
    display_word: "Bell",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 1032400,
    end_time: 1032500,
    token_index: 2471,
    start_char: 13731,
    end_char: 13735,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "man,",
    start_time: 1032500,
    end_time: 1032700,
    token_index: 2472,
    start_char: 13736,
    end_char: 13740,
    display_word: "man,",
    period_at_end: false
    },
    {
    word: "thanks",
    start_time: 1032700,
    end_time: 1032900,
    token_index: 2473,
    start_char: 13741,
    end_char: 13747,
    display_word: "thanks",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1032900,
    end_time: 1033000,
    token_index: 2474,
    start_char: 13748,
    end_char: 13751,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "being",
    start_time: 1033000,
    end_time: 1033200,
    token_index: 2475,
    start_char: 13752,
    end_char: 13757,
    display_word: "being",
    period_at_end: false
    },
    {
    word: "here.",
    start_time: 1033200,
    end_time: 1033599,
    token_index: 2476,
    start_char: 13758,
    end_char: 13762,
    display_word: "here",
    period_at_end: true
    },
    {
    word: "Yes.",
    start_time: 1034300,
    end_time: 1034500,
    token_index: 2477,
    start_char: 13764,
    end_char: 13767,
    display_word: "Yes",
    period_at_end: true
    },
    {
    word: "Yes.",
    start_time: 1034500,
    end_time: 1034900,
    token_index: 2478,
    start_char: 13769,
    end_char: 13772,
    display_word: "Yes",
    period_at_end: true
    },
    {
    word: "Yes",
    start_time: 1034900,
    end_time: 1035000,
    token_index: 2479,
    start_char: 13774,
    end_char: 13777,
    display_word: "Yes",
    period_at_end: false
    },
    {
    word: "hold",
    start_time: 1035000,
    end_time: 1035500,
    token_index: 2480,
    start_char: 13778,
    end_char: 13782,
    display_word: "hold",
    period_at_end: false
    },
    {
    word: "hold",
    start_time: 1035500,
    end_time: 1035800,
    token_index: 2481,
    start_char: 13783,
    end_char: 13787,
    display_word: "hold",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1035800,
    end_time: 1036000,
    token_index: 2482,
    start_char: 13788,
    end_char: 13791,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1036000,
    end_time: 1036000,
    token_index: 2483,
    start_char: 13792,
    end_char: 13795,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "applause",
    start_time: 1036000,
    end_time: 1036700,
    token_index: 2484,
    start_char: 13796,
    end_char: 13804,
    display_word: "applause",
    period_at_end: false
    },
    {
    word: "applause.",
    start_time: 1038599,
    end_time: 1039099,
    token_index: 2485,
    start_char: 13805,
    end_char: 13813,
    display_word: "applause",
    period_at_end: true
    },
    {
    word: "Please",
    start_time: 1039099,
    end_time: 1039700,
    token_index: 2486,
    start_char: 13815,
    end_char: 13821,
    display_word: "Please",
    period_at_end: false
    },
    {
    word: "exactly",
    start_time: 1040200,
    end_time: 1040800,
    token_index: 2487,
    start_char: 13822,
    end_char: 13829,
    display_word: "exactly",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1040800,
    end_time: 1041000,
    token_index: 2488,
    start_char: 13830,
    end_char: 13832,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "performers.",
    start_time: 1041000,
    end_time: 1041599,
    token_index: 2489,
    start_char: 13833,
    end_char: 13843,
    display_word: "performers",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 1041599,
    end_time: 1041800,
    token_index: 2490,
    start_char: 13845,
    end_char: 13849,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "always",
    start_time: 1041800,
    end_time: 1042000,
    token_index: 2491,
    start_char: 13850,
    end_char: 13856,
    display_word: "always",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1042000,
    end_time: 1042200,
    token_index: 2492,
    start_char: 13857,
    end_char: 13859,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "here.",
    start_time: 1042200,
    end_time: 1042400,
    token_index: 2493,
    start_char: 13860,
    end_char: 13864,
    display_word: "here",
    period_at_end: true
    },
    {
    word: "We're",
    start_time: 1042400,
    end_time: 1042500,
    token_index: 2494,
    start_char: 13866,
    end_char: 13871,
    display_word: "We're",
    period_at_end: false
    },
    {
    word: "always",
    start_time: 1042500,
    end_time: 1042800,
    token_index: 2495,
    start_char: 13872,
    end_char: 13878,
    display_word: "always",
    period_at_end: false
    },
    {
    word: "hearing",
    start_time: 1042800,
    end_time: 1043099,
    token_index: 2496,
    start_char: 13879,
    end_char: 13886,
    display_word: "hearing",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1043099,
    end_time: 1043200,
    token_index: 2497,
    start_char: 13887,
    end_char: 13890,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Applause.",
    start_time: 1043200,
    end_time: 1043800,
    token_index: 2498,
    start_char: 13891,
    end_char: 13899,
    display_word: "Applause",
    period_at_end: true
    },
    {
    word: "Yes.",
    start_time: 1043900,
    end_time: 1044500,
    token_index: 2499,
    start_char: 13901,
    end_char: 13904,
    display_word: "Yes",
    period_at_end: true
    },
    {
    word: "Um",
    start_time: 1045300,
    end_time: 1045700,
    token_index: 2500,
    start_char: 13906,
    end_char: 13908,
    display_word: "Um",
    period_at_end: false
    },
    {
    word: "nice",
    start_time: 1045900,
    end_time: 1046099,
    token_index: 2501,
    start_char: 13909,
    end_char: 13913,
    display_word: "nice",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1046099,
    end_time: 1046200,
    token_index: 2502,
    start_char: 13914,
    end_char: 13916,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1046200,
    end_time: 1046400,
    token_index: 2503,
    start_char: 13917,
    end_char: 13920,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "you.",
    start_time: 1046400,
    end_time: 1046700,
    token_index: 2504,
    start_char: 13921,
    end_char: 13924,
    display_word: "you",
    period_at_end: true
    },
    {
    word: "You're",
    start_time: 1046800,
    end_time: 1047000,
    token_index: 2505,
    start_char: 13926,
    end_char: 13932,
    display_word: "You're",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1047000,
    end_time: 1047099,
    token_index: 2506,
    start_char: 13933,
    end_char: 13935,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "California.",
    start_time: 1047099,
    end_time: 1048200,
    token_index: 2507,
    start_char: 13936,
    end_char: 13946,
    display_word: "California",
    period_at_end: true
    },
    {
    word: "Yes,",
    start_time: 1048400,
    end_time: 1048600,
    token_index: 2508,
    start_char: 13948,
    end_char: 13952,
    display_word: "Yes,",
    period_at_end: false
    },
    {
    word: "correct.",
    start_time: 1048600,
    end_time: 1049400,
    token_index: 2509,
    start_char: 13953,
    end_char: 13960,
    display_word: "correct",
    period_at_end: true
    },
    {
    word: "Yes.",
    start_time: 1049500,
    end_time: 1049600,
    token_index: 2510,
    start_char: 13962,
    end_char: 13965,
    display_word: "Yes",
    period_at_end: true
    },
    {
    word: "How",
    start_time: 1049700,
    end_time: 1050500,
    token_index: 2511,
    start_char: 13967,
    end_char: 13970,
    display_word: "How",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1050500,
    end_time: 1050600,
    token_index: 2512,
    start_char: 13971,
    end_char: 13973,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "everything",
    start_time: 1050600,
    end_time: 1051000,
    token_index: 2513,
    start_char: 13974,
    end_char: 13984,
    display_word: "everything",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 1051000,
    end_time: 1051200,
    token_index: 2514,
    start_char: 13985,
    end_char: 13988,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "there?",
    start_time: 1051200,
    end_time: 1051700,
    token_index: 2515,
    start_char: 13989,
    end_char: 13995,
    display_word: "there?",
    period_at_end: false
    },
    {
    word: "How",
    start_time: 1051800,
    end_time: 1051900,
    token_index: 2516,
    start_char: 13996,
    end_char: 13999,
    display_word: "How",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1051900,
    end_time: 1052000,
    token_index: 2517,
    start_char: 14000,
    end_char: 14003,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1052000,
    end_time: 1052100,
    token_index: 2518,
    start_char: 14004,
    end_char: 14007,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "writing?",
    start_time: 1052100,
    end_time: 1052300,
    token_index: 2519,
    start_char: 14008,
    end_char: 14016,
    display_word: "writing?",
    period_at_end: false
    },
    {
    word: "Nothing",
    start_time: 1052300,
    end_time: 1052500,
    token_index: 2520,
    start_char: 14017,
    end_char: 14024,
    display_word: "Nothing",
    period_at_end: false
    },
    {
    word: "Toronto",
    start_time: 1052500,
    end_time: 1052800,
    token_index: 2521,
    start_char: 14025,
    end_char: 14032,
    display_word: "Toronto",
    period_at_end: false
    },
    {
    word: "virus.",
    start_time: 1052800,
    end_time: 1053200,
    token_index: 2522,
    start_char: 14033,
    end_char: 14038,
    display_word: "virus",
    period_at_end: true
    },
    {
    word: "All",
    start_time: 1053200,
    end_time: 1053400,
    token_index: 2523,
    start_char: 14040,
    end_char: 14043,
    display_word: "All",
    period_at_end: false
    },
    {
    word: "right,",
    start_time: 1053400,
    end_time: 1054000,
    token_index: 2524,
    start_char: 14044,
    end_char: 14050,
    display_word: "right,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1054100,
    end_time: 1054400,
    token_index: 2525,
    start_char: 14051,
    end_char: 14054,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 1054400,
    end_time: 1054500,
    token_index: 2526,
    start_char: 14055,
    end_char: 14058,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1054500,
    end_time: 1054600,
    token_index: 2527,
    start_char: 14059,
    end_char: 14061,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "feels",
    start_time: 1054600,
    end_time: 1054800,
    token_index: 2528,
    start_char: 14062,
    end_char: 14067,
    display_word: "feels",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1054800,
    end_time: 1054900,
    token_index: 2529,
    start_char: 14068,
    end_char: 14072,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "we're",
    start_time: 1054900,
    end_time: 1055000,
    token_index: 2530,
    start_char: 14073,
    end_char: 14078,
    display_word: "we're",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1055000,
    end_time: 1055300,
    token_index: 2531,
    start_char: 14079,
    end_char: 14083,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "Florida",
    start_time: 1055300,
    end_time: 1055600,
    token_index: 2532,
    start_char: 14084,
    end_char: 14091,
    display_word: "Florida",
    period_at_end: false
    },
    {
    word: "jr.",
    start_time: 1055600,
    end_time: 1056300,
    token_index: 2533,
    start_char: 14092,
    end_char: 14094,
    display_word: "jr",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1056300,
    end_time: 1056400,
    token_index: 2534,
    start_char: 14096,
    end_char: 14097,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1056400,
    end_time: 1056600,
    token_index: 2535,
    start_char: 14098,
    end_char: 14103,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 1056600,
    end_time: 1057000,
    token_index: 2536,
    start_char: 14104,
    end_char: 14107,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1057200,
    end_time: 1057400,
    token_index: 2537,
    start_char: 14108,
    end_char: 14114,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "surprising",
    start_time: 1057400,
    end_time: 1058000,
    token_index: 2538,
    start_char: 14115,
    end_char: 14125,
    display_word: "surprising",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1058000,
    end_time: 1058400,
    token_index: 2539,
    start_char: 14126,
    end_char: 14133,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "California",
    start_time: 1058400,
    end_time: 1059100,
    token_index: 2540,
    start_char: 14134,
    end_char: 14144,
    display_word: "California",
    period_at_end: false
    },
    {
    word: "got",
    start_time: 1059100,
    end_time: 1059300,
    token_index: 2541,
    start_char: 14145,
    end_char: 14148,
    display_word: "got",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1059300,
    end_time: 1059400,
    token_index: 2542,
    start_char: 14149,
    end_char: 14150,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 1059400,
    end_time: 1059600,
    token_index: 2543,
    start_char: 14151,
    end_char: 14154,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1059600,
    end_time: 1059700,
    token_index: 2544,
    start_char: 14155,
    end_char: 14157,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "praise",
    start_time: 1059700,
    end_time: 1060300,
    token_index: 2545,
    start_char: 14158,
    end_char: 14164,
    display_word: "praise",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1060300,
    end_time: 1060600,
    token_index: 2546,
    start_char: 14165,
    end_char: 14168,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1060600,
    end_time: 1060900,
    token_index: 2547,
    start_char: 14169,
    end_char: 14172,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1060900,
    end_time: 1061000,
    token_index: 2548,
    start_char: 14173,
    end_char: 14177,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "brought",
    start_time: 1061000,
    end_time: 1061300,
    token_index: 2549,
    start_char: 14178,
    end_char: 14185,
    display_word: "brought",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1061300,
    end_time: 1061400,
    token_index: 2550,
    start_char: 14186,
    end_char: 14189,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "hammer",
    start_time: 1061400,
    end_time: 1061700,
    token_index: 2551,
    start_char: 14190,
    end_char: 14196,
    display_word: "hammer",
    period_at_end: false
    },
    {
    word: "down",
    start_time: 1061700,
    end_time: 1062400,
    token_index: 2552,
    start_char: 14197,
    end_char: 14201,
    display_word: "down",
    period_at_end: false
    },
    {
    word: "firmly",
    start_time: 1062500,
    end_time: 1063300,
    token_index: 2553,
    start_char: 14202,
    end_char: 14208,
    display_word: "firmly",
    period_at_end: false
    },
    {
    word: "fully",
    start_time: 1063300,
    end_time: 1064300,
    token_index: 2554,
    start_char: 14209,
    end_char: 14214,
    display_word: "fully",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1064700,
    end_time: 1064900,
    token_index: 2555,
    start_char: 14215,
    end_char: 14219,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1064900,
    end_time: 1065000,
    token_index: 2556,
    start_char: 14220,
    end_char: 14224,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1065000,
    end_time: 1065100,
    token_index: 2557,
    start_char: 14225,
    end_char: 14226,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "model",
    start_time: 1065100,
    end_time: 1065500,
    token_index: 2558,
    start_char: 14227,
    end_char: 14232,
    display_word: "model",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1065500,
    end_time: 1065600,
    token_index: 2559,
    start_char: 14233,
    end_char: 14236,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "everybody",
    start_time: 1065600,
    end_time: 1066000,
    token_index: 2560,
    start_char: 14237,
    end_char: 14246,
    display_word: "everybody",
    period_at_end: false
    },
    {
    word: "else.",
    start_time: 1066000,
    end_time: 1066300,
    token_index: 2561,
    start_char: 14247,
    end_char: 14251,
    display_word: "else",
    period_at_end: true
    },
    {
    word: "What",
    start_time: 1066300,
    end_time: 1066400,
    token_index: 2562,
    start_char: 14253,
    end_char: 14257,
    display_word: "What",
    period_at_end: false
    },
    {
    word: "happened",
    start_time: 1066400,
    end_time: 1067100,
    token_index: 2563,
    start_char: 14258,
    end_char: 14266,
    display_word: "happened",
    period_at_end: false
    },
    {
    word: "Orange",
    start_time: 1067800,
    end_time: 1068200,
    token_index: 2564,
    start_char: 14267,
    end_char: 14273,
    display_word: "Orange",
    period_at_end: false
    },
    {
    word: "County's",
    start_time: 1068200,
    end_time: 1068700,
    token_index: 2565,
    start_char: 14274,
    end_char: 14282,
    display_word: "County's",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1068700,
    end_time: 1068800,
    token_index: 2566,
    start_char: 14283,
    end_char: 14287,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "happened?",
    start_time: 1068800,
    end_time: 1069300,
    token_index: 2567,
    start_char: 14288,
    end_char: 14297,
    display_word: "happened?",
    period_at_end: false
    },
    {
    word: "All",
    start_time: 1069600,
    end_time: 1069800,
    token_index: 2568,
    start_char: 14298,
    end_char: 14301,
    display_word: "All",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1069800,
    end_time: 1069900,
    token_index: 2569,
    start_char: 14302,
    end_char: 14304,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "California",
    start_time: 1069900,
    end_time: 1070500,
    token_index: 2570,
    start_char: 14305,
    end_char: 14315,
    display_word: "California",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1070500,
    end_time: 1070600,
    token_index: 2571,
    start_char: 14316,
    end_char: 14318,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1070600,
    end_time: 1070900,
    token_index: 2572,
    start_char: 14319,
    end_char: 14322,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "San",
    start_time: 1070900,
    end_time: 1071100,
    token_index: 2573,
    start_char: 14323,
    end_char: 14326,
    display_word: "San",
    period_at_end: false
    },
    {
    word: "Francisco",
    start_time: 1071100,
    end_time: 1072000,
    token_index: 2574,
    start_char: 14327,
    end_char: 14336,
    display_word: "Francisco",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1072200,
    end_time: 1072700,
    token_index: 2575,
    start_char: 14337,
    end_char: 14340,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "La",
    start_time: 1072900,
    end_time: 1073400,
    token_index: 2576,
    start_char: 14341,
    end_char: 14343,
    display_word: "La",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1073700,
    end_time: 1073800,
    token_index: 2577,
    start_char: 14344,
    end_char: 14345,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1073800,
    end_time: 1074100,
    token_index: 2578,
    start_char: 14346,
    end_char: 14351,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1074100,
    end_time: 1074200,
    token_index: 2579,
    start_char: 14352,
    end_char: 14354,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1074200,
    end_time: 1074400,
    token_index: 2580,
    start_char: 14355,
    end_char: 14359,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1074400,
    end_time: 1074500,
    token_index: 2581,
    start_char: 14360,
    end_char: 14361,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 1074500,
    end_time: 1074800,
    token_index: 2582,
    start_char: 14362,
    end_char: 14365,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1074800,
    end_time: 1074900,
    token_index: 2583,
    start_char: 14366,
    end_char: 14368,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "Orange",
    start_time: 1075600,
    end_time: 1075900,
    token_index: 2584,
    start_char: 14369,
    end_char: 14375,
    display_word: "Orange",
    period_at_end: false
    },
    {
    word: "County",
    start_time: 1075900,
    end_time: 1076300,
    token_index: 2585,
    start_char: 14376,
    end_char: 14382,
    display_word: "County",
    period_at_end: false
    },
    {
    word: "spread",
    start_time: 1076300,
    end_time: 1076600,
    token_index: 2586,
    start_char: 14383,
    end_char: 14389,
    display_word: "spread",
    period_at_end: false
    },
    {
    word: "throughout",
    start_time: 1076600,
    end_time: 1076900,
    token_index: 2587,
    start_char: 14390,
    end_char: 14400,
    display_word: "throughout",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1076900,
    end_time: 1077000,
    token_index: 2588,
    start_char: 14401,
    end_char: 14404,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "state",
    start_time: 1077000,
    end_time: 1077600,
    token_index: 2589,
    start_char: 14405,
    end_char: 14410,
    display_word: "state",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1077800,
    end_time: 1077900,
    token_index: 2590,
    start_char: 14411,
    end_char: 14414,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1077900,
    end_time: 1077900,
    token_index: 2591,
    start_char: 14415,
    end_char: 14416,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1077900,
    end_time: 1078200,
    token_index: 2592,
    start_char: 14417,
    end_char: 14422,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1078200,
    end_time: 1078400,
    token_index: 2593,
    start_char: 14423,
    end_char: 14426,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "governor",
    start_time: 1078400,
    end_time: 1078800,
    token_index: 2594,
    start_char: 14427,
    end_char: 14435,
    display_word: "governor",
    period_at_end: false
    },
    {
    word: "felt",
    start_time: 1078800,
    end_time: 1079100,
    token_index: 2595,
    start_char: 14436,
    end_char: 14440,
    display_word: "felt",
    period_at_end: false
    },
    {
    word: "pressure",
    start_time: 1079100,
    end_time: 1079500,
    token_index: 2596,
    start_char: 14441,
    end_char: 14449,
    display_word: "pressure",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1079500,
    end_time: 1079700,
    token_index: 2597,
    start_char: 14450,
    end_char: 14454,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "them",
    start_time: 1079700,
    end_time: 1080000,
    token_index: 2598,
    start_char: 14455,
    end_char: 14459,
    display_word: "them",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1080000,
    end_time: 1080100,
    token_index: 2599,
    start_char: 14460,
    end_char: 14463,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "also",
    start_time: 1080100,
    end_time: 1080400,
    token_index: 2600,
    start_char: 14464,
    end_char: 14468,
    display_word: "also",
    period_at_end: false
    },
    {
    word: "weird",
    start_time: 1080400,
    end_time: 1080700,
    token_index: 2601,
    start_char: 14469,
    end_char: 14474,
    display_word: "weird",
    period_at_end: false
    },
    {
    word: "pressure",
    start_time: 1080700,
    end_time: 1081100,
    token_index: 2602,
    start_char: 14475,
    end_char: 14483,
    display_word: "pressure",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1081100,
    end_time: 1081300,
    token_index: 2603,
    start_char: 14484,
    end_char: 14488,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "other",
    start_time: 1081300,
    end_time: 1081400,
    token_index: 2604,
    start_char: 14489,
    end_char: 14494,
    display_word: "other",
    period_at_end: false
    },
    {
    word: "Governors",
    start_time: 1081400,
    end_time: 1081800,
    token_index: 2605,
    start_char: 14495,
    end_char: 14504,
    display_word: "Governors",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 1081800,
    end_time: 1082000,
    token_index: 2606,
    start_char: 14505,
    end_char: 14508,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1082000,
    end_time: 1082000,
    token_index: 2607,
    start_char: 14509,
    end_char: 14512,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "opening",
    start_time: 1082000,
    end_time: 1082400,
    token_index: 2608,
    start_char: 14513,
    end_char: 14520,
    display_word: "opening",
    period_at_end: false
    },
    {
    word: "up.",
    start_time: 1082400,
    end_time: 1082600,
    token_index: 2609,
    start_char: 14521,
    end_char: 14523,
    display_word: "up",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1082600,
    end_time: 1083000,
    token_index: 2610,
    start_char: 14525,
    end_char: 14527,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1083200,
    end_time: 1083600,
    token_index: 2611,
    start_char: 14528,
    end_char: 14532,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1083600,
    end_time: 1083800,
    token_index: 2612,
    start_char: 14533,
    end_char: 14534,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1084200,
    end_time: 1084300,
    token_index: 2613,
    start_char: 14535,
    end_char: 14536,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1084300,
    end_time: 1084500,
    token_index: 2614,
    start_char: 14537,
    end_char: 14541,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1084500,
    end_time: 1084600,
    token_index: 2615,
    start_char: 14542,
    end_char: 14544,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1084600,
    end_time: 1084700,
    token_index: 2616,
    start_char: 14545,
    end_char: 14550,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1084700,
    end_time: 1084800,
    token_index: 2617,
    start_char: 14551,
    end_char: 14553,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1084800,
    end_time: 1085000,
    token_index: 2618,
    start_char: 14554,
    end_char: 14558,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1085000,
    end_time: 1085100,
    token_index: 2619,
    start_char: 14559,
    end_char: 14561,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "like,",
    start_time: 1085100,
    end_time: 1085400,
    token_index: 2620,
    start_char: 14562,
    end_char: 14567,
    display_word: "like,",
    period_at_end: false
    },
    {
    word: "maybe",
    start_time: 1085400,
    end_time: 1085800,
    token_index: 2621,
    start_char: 14568,
    end_char: 14573,
    display_word: "maybe",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1085800,
    end_time: 1086000,
    token_index: 2622,
    start_char: 14574,
    end_char: 14578,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1086000,
    end_time: 1086200,
    token_index: 2623,
    start_char: 14579,
    end_char: 14581,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1086200,
    end_time: 1086600,
    token_index: 2624,
    start_char: 14582,
    end_char: 14586,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1087000,
    end_time: 1087200,
    token_index: 2625,
    start_char: 14587,
    end_char: 14590,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "year",
    start_time: 1087200,
    end_time: 1087500,
    token_index: 2626,
    start_char: 14591,
    end_char: 14595,
    display_word: "year",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1087500,
    end_time: 1087600,
    token_index: 2627,
    start_char: 14596,
    end_char: 14598,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "two",
    start_time: 1087600,
    end_time: 1087800,
    token_index: 2628,
    start_char: 14599,
    end_char: 14602,
    display_word: "two",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 1087800,
    end_time: 1088000,
    token_index: 2629,
    start_char: 14603,
    end_char: 14609,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1088000,
    end_time: 1088100,
    token_index: 2630,
    start_char: 14610,
    end_char: 14613,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "apocalypse",
    start_time: 1088100,
    end_time: 1088800,
    token_index: 2631,
    start_char: 14614,
    end_char: 14624,
    display_word: "apocalypse",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1088800,
    end_time: 1088900,
    token_index: 2632,
    start_char: 14625,
    end_char: 14629,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "whenever",
    start_time: 1088900,
    end_time: 1089200,
    token_index: 2633,
    start_char: 14630,
    end_char: 14638,
    display_word: "whenever",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1089200,
    end_time: 1089200,
    token_index: 2634,
    start_char: 14639,
    end_char: 14642,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1089200,
    end_time: 1089300,
    token_index: 2635,
    start_char: 14643,
    end_char: 14646,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1089300,
    end_time: 1089400,
    token_index: 2636,
    start_char: 14647,
    end_char: 14648,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "movie",
    start_time: 1089400,
    end_time: 1089600,
    token_index: 2637,
    start_char: 14649,
    end_char: 14654,
    display_word: "movie",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1089600,
    end_time: 1089800,
    token_index: 2638,
    start_char: 14655,
    end_char: 14660,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1089800,
    end_time: 1089800,
    token_index: 2639,
    start_char: 14661,
    end_char: 14664,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "apocalypse.",
    start_time: 1089800,
    end_time: 1090600,
    token_index: 2640,
    start_char: 14665,
    end_char: 14675,
    display_word: "apocalypse",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 1090700,
    end_time: 1090800,
    token_index: 2641,
    start_char: 14677,
    end_char: 14680,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "never",
    start_time: 1090800,
    end_time: 1091100,
    token_index: 2642,
    start_char: 14681,
    end_char: 14686,
    display_word: "never",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1091100,
    end_time: 1091200,
    token_index: 2643,
    start_char: 14687,
    end_char: 14690,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1091200,
    end_time: 1091300,
    token_index: 2644,
    start_char: 14691,
    end_char: 14694,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "year",
    start_time: 1091300,
    end_time: 1091600,
    token_index: 2645,
    start_char: 14695,
    end_char: 14699,
    display_word: "year",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1091600,
    end_time: 1091700,
    token_index: 2646,
    start_char: 14700,
    end_char: 14702,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "leading",
    start_time: 1091700,
    end_time: 1092000,
    token_index: 2647,
    start_char: 14703,
    end_char: 14710,
    display_word: "leading",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1092000,
    end_time: 1092200,
    token_index: 2648,
    start_char: 14711,
    end_char: 14713,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1092200,
    end_time: 1092400,
    token_index: 2649,
    start_char: 14714,
    end_char: 14716,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "it,",
    start_time: 1092400,
    end_time: 1092500,
    token_index: 2650,
    start_char: 14717,
    end_char: 14720,
    display_word: "it,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1092500,
    end_time: 1092700,
    token_index: 2651,
    start_char: 14721,
    end_char: 14724,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1092700,
    end_time: 1092800,
    token_index: 2652,
    start_char: 14725,
    end_char: 14726,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "feel",
    start_time: 1092800,
    end_time: 1092900,
    token_index: 2653,
    start_char: 14727,
    end_char: 14731,
    display_word: "feel",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1092900,
    end_time: 1093000,
    token_index: 2654,
    start_char: 14732,
    end_char: 14736,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1093000,
    end_time: 1093200,
    token_index: 2655,
    start_char: 14737,
    end_char: 14743,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1093200,
    end_time: 1093300,
    token_index: 2656,
    start_char: 14744,
    end_char: 14748,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1093300,
    end_time: 1093500,
    token_index: 2657,
    start_char: 14749,
    end_char: 14753,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "feels",
    start_time: 1093500,
    end_time: 1093800,
    token_index: 2658,
    start_char: 14754,
    end_char: 14759,
    display_word: "feels",
    period_at_end: false
    },
    {
    word: "like.",
    start_time: 1093800,
    end_time: 1094200,
    token_index: 2659,
    start_char: 14760,
    end_char: 14764,
    display_word: "like",
    period_at_end: true
    },
    {
    word: "Well",
    start_time: 1094600,
    end_time: 1094700,
    token_index: 2660,
    start_char: 14766,
    end_char: 14770,
    display_word: "Well",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1094700,
    end_time: 1095000,
    token_index: 2661,
    start_char: 14771,
    end_char: 14777,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1095000,
    end_time: 1095100,
    token_index: 2662,
    start_char: 14778,
    end_char: 14781,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "almost",
    start_time: 1095100,
    end_time: 1095600,
    token_index: 2663,
    start_char: 14782,
    end_char: 14788,
    display_word: "almost",
    period_at_end: false
    },
    {
    word: "normal,",
    start_time: 1095600,
    end_time: 1096300,
    token_index: 2664,
    start_char: 14789,
    end_char: 14796,
    display_word: "normal,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1096300,
    end_time: 1096400,
    token_index: 2665,
    start_char: 14797,
    end_char: 14800,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know,",
    start_time: 1096400,
    end_time: 1096800,
    token_index: 2666,
    start_char: 14801,
    end_char: 14806,
    display_word: "know,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1097400,
    end_time: 1097500,
    token_index: 2667,
    start_char: 14807,
    end_char: 14810,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1097500,
    end_time: 1097700,
    token_index: 2668,
    start_char: 14811,
    end_char: 14815,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 1097700,
    end_time: 1097900,
    token_index: 2669,
    start_char: 14816,
    end_char: 14821,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1097900,
    end_time: 1098000,
    token_index: 2670,
    start_char: 14822,
    end_char: 14827,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1098000,
    end_time: 1098200,
    token_index: 2671,
    start_char: 14828,
    end_char: 14833,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1098200,
    end_time: 1098300,
    token_index: 2672,
    start_char: 14834,
    end_char: 14837,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "never",
    start_time: 1098300,
    end_time: 1098500,
    token_index: 2673,
    start_char: 14838,
    end_char: 14843,
    display_word: "never",
    period_at_end: false
    },
    {
    word: "seen",
    start_time: 1098500,
    end_time: 1098600,
    token_index: 2674,
    start_char: 14844,
    end_char: 14848,
    display_word: "seen",
    period_at_end: false
    },
    {
    word: "Mad",
    start_time: 1098600,
    end_time: 1098900,
    token_index: 2675,
    start_char: 14849,
    end_char: 14852,
    display_word: "Mad",
    period_at_end: false
    },
    {
    word: "Max",
    start_time: 1098900,
    end_time: 1099200,
    token_index: 2676,
    start_char: 14853,
    end_char: 14856,
    display_word: "Max",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1099200,
    end_time: 1099300,
    token_index: 2677,
    start_char: 14857,
    end_char: 14861,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1099300,
    end_time: 1099400,
    token_index: 2678,
    start_char: 14862,
    end_char: 14865,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "year",
    start_time: 1099400,
    end_time: 1099600,
    token_index: 2679,
    start_char: 14866,
    end_char: 14870,
    display_word: "year",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 1099600,
    end_time: 1099900,
    token_index: 2680,
    start_char: 14871,
    end_char: 14877,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1099900,
    end_time: 1100000,
    token_index: 2681,
    start_char: 14878,
    end_char: 14881,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "move",
    start_time: 1100000,
    end_time: 1100200,
    token_index: 2682,
    start_char: 14882,
    end_char: 14886,
    display_word: "move",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1100200,
    end_time: 1100400,
    token_index: 2683,
    start_char: 14887,
    end_char: 14892,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1100400,
    end_time: 1100600,
    token_index: 2684,
    start_char: 14893,
    end_char: 14897,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "man",
    start_time: 1100600,
    end_time: 1100900,
    token_index: 2685,
    start_char: 14898,
    end_char: 14901,
    display_word: "man",
    period_at_end: false
    },
    {
    word: "gas",
    start_time: 1100900,
    end_time: 1101200,
    token_index: 2686,
    start_char: 14902,
    end_char: 14905,
    display_word: "gas",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1101200,
    end_time: 1101400,
    token_index: 2687,
    start_char: 14906,
    end_char: 14908,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "expensive.",
    start_time: 1101400,
    end_time: 1102200,
    token_index: 2688,
    start_char: 14909,
    end_char: 14918,
    display_word: "expensive",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 1105600,
    end_time: 1105700,
    token_index: 2689,
    start_char: 14920,
    end_char: 14923,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "never",
    start_time: 1105700,
    end_time: 1105900,
    token_index: 2690,
    start_char: 14924,
    end_char: 14929,
    display_word: "never",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1105900,
    end_time: 1106100,
    token_index: 2691,
    start_char: 14930,
    end_char: 14933,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "Denzel",
    start_time: 1106100,
    end_time: 1106500,
    token_index: 2692,
    start_char: 14934,
    end_char: 14940,
    display_word: "Denzel",
    period_at_end: false
    },
    {
    word: "Washington",
    start_time: 1106500,
    end_time: 1107200,
    token_index: 2693,
    start_char: 14941,
    end_char: 14951,
    display_word: "Washington",
    period_at_end: false
    },
    {
    word: "The",
    start_time: 1107200,
    end_time: 1107300,
    token_index: 2694,
    start_char: 14952,
    end_char: 14955,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "Book",
    start_time: 1107300,
    end_time: 1107500,
    token_index: 2695,
    start_char: 14956,
    end_char: 14960,
    display_word: "Book",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1107500,
    end_time: 1107600,
    token_index: 2696,
    start_char: 14961,
    end_char: 14963,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "Eli",
    start_time: 1107600,
    end_time: 1107900,
    token_index: 2697,
    start_char: 14964,
    end_char: 14967,
    display_word: "Eli",
    period_at_end: false
    },
    {
    word: "prequel.",
    start_time: 1107900,
    end_time: 1108400,
    token_index: 2698,
    start_char: 14968,
    end_char: 14975,
    display_word: "prequel",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1108600,
    end_time: 1108700,
    token_index: 2699,
    start_char: 14977,
    end_char: 14978,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "can't",
    start_time: 1108700,
    end_time: 1108900,
    token_index: 2700,
    start_char: 14979,
    end_char: 14984,
    display_word: "can't",
    period_at_end: false
    },
    {
    word: "find",
    start_time: 1108900,
    end_time: 1109200,
    token_index: 2701,
    start_char: 14985,
    end_char: 14989,
    display_word: "find",
    period_at_end: false
    },
    {
    word: "Bibles",
    start_time: 1109200,
    end_time: 1109500,
    token_index: 2702,
    start_char: 14990,
    end_char: 14996,
    display_word: "Bibles",
    period_at_end: false
    },
    {
    word: "anywhere,",
    start_time: 1109500,
    end_time: 1110100,
    token_index: 2703,
    start_char: 14997,
    end_char: 15006,
    display_word: "anywhere,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1110200,
    end_time: 1110300,
    token_index: 2704,
    start_char: 15007,
    end_char: 15010,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know,",
    start_time: 1110300,
    end_time: 1110600,
    token_index: 2705,
    start_char: 15011,
    end_char: 15016,
    display_word: "know,",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1110600,
    end_time: 1110700,
    token_index: 2706,
    start_char: 15017,
    end_char: 15019,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1110700,
    end_time: 1110800,
    token_index: 2707,
    start_char: 15020,
    end_char: 15021,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "feel",
    start_time: 1110800,
    end_time: 1111000,
    token_index: 2708,
    start_char: 15022,
    end_char: 15026,
    display_word: "feel",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1111000,
    end_time: 1111400,
    token_index: 2709,
    start_char: 15027,
    end_char: 15031,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "we're",
    start_time: 1111600,
    end_time: 1111800,
    token_index: 2710,
    start_char: 15032,
    end_char: 15037,
    display_word: "we're",
    period_at_end: false
    },
    {
    word: "maybe",
    start_time: 1111800,
    end_time: 1112500,
    token_index: 2711,
    start_char: 15038,
    end_char: 15043,
    display_word: "maybe",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1112500,
    end_time: 1112800,
    token_index: 2712,
    start_char: 15044,
    end_char: 15046,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1112800,
    end_time: 1112900,
    token_index: 2713,
    start_char: 15047,
    end_char: 15048,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "pre-apocalyptic",
    start_time: 1112900,
    end_time: 1114000,
    token_index: 2714,
    start_char: 15049,
    end_char: 15064,
    display_word: "pre-apocalyptic",
    period_at_end: false
    },
    {
    word: "moment,",
    start_time: 1114000,
    end_time: 1114300,
    token_index: 2715,
    start_char: 15065,
    end_char: 15072,
    display_word: "moment,",
    period_at_end: false
    },
    {
    word: "which",
    start_time: 1114300,
    end_time: 1114500,
    token_index: 2716,
    start_char: 15073,
    end_char: 15078,
    display_word: "which",
    period_at_end: false
    },
    {
    word: "makes",
    start_time: 1114500,
    end_time: 1114600,
    token_index: 2717,
    start_char: 15079,
    end_char: 15084,
    display_word: "makes",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1114600,
    end_time: 1114700,
    token_index: 2718,
    start_char: 15085,
    end_char: 15087,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "feel",
    start_time: 1114700,
    end_time: 1114900,
    token_index: 2719,
    start_char: 15088,
    end_char: 15092,
    display_word: "feel",
    period_at_end: false
    },
    {
    word: "better.",
    start_time: 1114900,
    end_time: 1115200,
    token_index: 2720,
    start_char: 15093,
    end_char: 15099,
    display_word: "better",
    period_at_end: true
    },
    {
    word: "For",
    start_time: 1115200,
    end_time: 1115300,
    token_index: 2721,
    start_char: 15101,
    end_char: 15104,
    display_word: "For",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1115300,
    end_time: 1115500,
    token_index: 2722,
    start_char: 15105,
    end_char: 15109,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "reason.",
    start_time: 1115500,
    end_time: 1115800,
    token_index: 2723,
    start_char: 15110,
    end_char: 15116,
    display_word: "reason",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1116100,
    end_time: 1117300,
    token_index: 2724,
    start_char: 15118,
    end_char: 15119,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "okay.",
    start_time: 1117700,
    end_time: 1118200,
    token_index: 2725,
    start_char: 15120,
    end_char: 15124,
    display_word: "okay",
    period_at_end: true
    },
    {
    word: "I'm",
    start_time: 1119000,
    end_time: 1119200,
    token_index: 2726,
    start_char: 15126,
    end_char: 15129,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1119200,
    end_time: 1119300,
    token_index: 2727,
    start_char: 15130,
    end_char: 15134,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "gonna",
    start_time: 1119300,
    end_time: 1119500,
    token_index: 2728,
    start_char: 15135,
    end_char: 15140,
    display_word: "gonna",
    period_at_end: false
    },
    {
    word: "knock",
    start_time: 1119500,
    end_time: 1119800,
    token_index: 2729,
    start_char: 15141,
    end_char: 15146,
    display_word: "knock",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1119800,
    end_time: 1120100,
    token_index: 2730,
    start_char: 15147,
    end_char: 15151,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1120100,
    end_time: 1120200,
    token_index: 2731,
    start_char: 15152,
    end_char: 15155,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "no",
    start_time: 1120200,
    end_time: 1120400,
    token_index: 2732,
    start_char: 15156,
    end_char: 15158,
    display_word: "no",
    period_at_end: false
    },
    {
    word: "particular",
    start_time: 1120400,
    end_time: 1120900,
    token_index: 2733,
    start_char: 15159,
    end_char: 15169,
    display_word: "particular",
    period_at_end: false
    },
    {
    word: "reason",
    start_time: 1120900,
    end_time: 1121300,
    token_index: 2734,
    start_char: 15170,
    end_char: 15176,
    display_word: "reason",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 1121700,
    end_time: 1121900,
    token_index: 2735,
    start_char: 15177,
    end_char: 15180,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1121900,
    end_time: 1122000,
    token_index: 2736,
    start_char: 15182,
    end_char: 15183,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1122000,
    end_time: 1122200,
    token_index: 2737,
    start_char: 15184,
    end_char: 15188,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "you're",
    start_time: 1122200,
    end_time: 1122900,
    token_index: 2738,
    start_char: 15189,
    end_char: 15195,
    display_word: "you're",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1122900,
    end_time: 1123000,
    token_index: 2739,
    start_char: 15196,
    end_char: 15197,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "father",
    start_time: 1123000,
    end_time: 1123400,
    token_index: 2740,
    start_char: 15198,
    end_char: 15204,
    display_word: "father",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1123400,
    end_time: 1123500,
    token_index: 2741,
    start_char: 15205,
    end_char: 15207,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "three",
    start_time: 1123500,
    end_time: 1123700,
    token_index: 2742,
    start_char: 15208,
    end_char: 15213,
    display_word: "three",
    period_at_end: false
    },
    {
    word: "kids.",
    start_time: 1123700,
    end_time: 1124400,
    token_index: 2743,
    start_char: 15214,
    end_char: 15218,
    display_word: "kids",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 1124600,
    end_time: 1125500,
    token_index: 2744,
    start_char: 15220,
    end_char: 15223,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 1125900,
    end_time: 1126000,
    token_index: 2745,
    start_char: 15224,
    end_char: 15226,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1126000,
    end_time: 1126100,
    token_index: 2746,
    start_char: 15227,
    end_char: 15230,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "want",
    start_time: 1126100,
    end_time: 1126200,
    token_index: 2747,
    start_char: 15231,
    end_char: 15235,
    display_word: "want",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1126200,
    end_time: 1126300,
    token_index: 2748,
    start_char: 15236,
    end_char: 15238,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "ask",
    start_time: 1126300,
    end_time: 1126700,
    token_index: 2749,
    start_char: 15239,
    end_char: 15242,
    display_word: "ask",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1126700,
    end_time: 1126900,
    token_index: 2750,
    start_char: 15243,
    end_char: 15246,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "old",
    start_time: 1126900,
    end_time: 1127000,
    token_index: 2751,
    start_char: 15247,
    end_char: 15250,
    display_word: "old",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1127000,
    end_time: 1127200,
    token_index: 2752,
    start_char: 15251,
    end_char: 15255,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1127200,
    end_time: 1127500,
    token_index: 2753,
    start_char: 15256,
    end_char: 15259,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "nine",
    start_time: 1128000,
    end_time: 1128700,
    token_index: 2754,
    start_char: 15260,
    end_char: 15264,
    display_word: "nine",
    period_at_end: false
    },
    {
    word: "five",
    start_time: 1128700,
    end_time: 1129000,
    token_index: 2755,
    start_char: 15265,
    end_char: 15269,
    display_word: "five",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1129000,
    end_time: 1129000,
    token_index: 2756,
    start_char: 15270,
    end_char: 15273,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1129000,
    end_time: 1129100,
    token_index: 2757,
    start_char: 15274,
    end_char: 15275,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "half",
    start_time: 1129100,
    end_time: 1129500,
    token_index: 2758,
    start_char: 15276,
    end_char: 15280,
    display_word: "half",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1129500,
    end_time: 1129800,
    token_index: 2759,
    start_char: 15281,
    end_char: 15284,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "two",
    start_time: 1129800,
    end_time: 1130200,
    token_index: 2760,
    start_char: 15285,
    end_char: 15288,
    display_word: "two",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1130200,
    end_time: 1130800,
    token_index: 2761,
    start_char: 15289,
    end_char: 15291,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "okay",
    start_time: 1130800,
    end_time: 1131400,
    token_index: 2762,
    start_char: 15292,
    end_char: 15296,
    display_word: "okay",
    period_at_end: false
    },
    {
    word: "nice.",
    start_time: 1132000,
    end_time: 1132400,
    token_index: 2763,
    start_char: 15297,
    end_char: 15301,
    display_word: "nice",
    period_at_end: true
    },
    {
    word: "Okay",
    start_time: 1132400,
    end_time: 1132600,
    token_index: 2764,
    start_char: 15303,
    end_char: 15307,
    display_word: "Okay",
    period_at_end: false
    },
    {
    word: "exactly",
    start_time: 1132600,
    end_time: 1132900,
    token_index: 2765,
    start_char: 15308,
    end_char: 15315,
    display_word: "exactly",
    period_at_end: false
    },
    {
    word: "those.",
    start_time: 1132900,
    end_time: 1133200,
    token_index: 2766,
    start_char: 15316,
    end_char: 15321,
    display_word: "those",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1133200,
    end_time: 1133300,
    token_index: 2767,
    start_char: 15323,
    end_char: 15325,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "those",
    start_time: 1133300,
    end_time: 1133600,
    token_index: 2768,
    start_char: 15326,
    end_char: 15331,
    display_word: "those",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1133600,
    end_time: 1134000,
    token_index: 2769,
    start_char: 15332,
    end_char: 15335,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1134200,
    end_time: 1134300,
    token_index: 2770,
    start_char: 15336,
    end_char: 15339,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "two",
    start_time: 1134300,
    end_time: 1134500,
    token_index: 2771,
    start_char: 15340,
    end_char: 15343,
    display_word: "two",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1134500,
    end_time: 1134600,
    token_index: 2772,
    start_char: 15344,
    end_char: 15346,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1134600,
    end_time: 1134600,
    token_index: 2773,
    start_char: 15347,
    end_char: 15348,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "little",
    start_time: 1134600,
    end_time: 1134800,
    token_index: 2774,
    start_char: 15349,
    end_char: 15355,
    display_word: "little",
    period_at_end: false
    },
    {
    word: "But",
    start_time: 1135300,
    end_time: 1135700,
    token_index: 2775,
    start_char: 15356,
    end_char: 15359,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1135700,
    end_time: 1136200,
    token_index: 2776,
    start_char: 15360,
    end_char: 15363,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1136200,
    end_time: 1136300,
    token_index: 2777,
    start_char: 15364,
    end_char: 15367,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "five",
    start_time: 1136300,
    end_time: 1136500,
    token_index: 2778,
    start_char: 15368,
    end_char: 15372,
    display_word: "five",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1136500,
    end_time: 1136600,
    token_index: 2779,
    start_char: 15373,
    end_char: 15376,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1136600,
    end_time: 1136700,
    token_index: 2780,
    start_char: 15377,
    end_char: 15378,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "half",
    start_time: 1136700,
    end_time: 1137100,
    token_index: 2781,
    start_char: 15379,
    end_char: 15383,
    display_word: "half",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1137100,
    end_time: 1137600,
    token_index: 2782,
    start_char: 15384,
    end_char: 15387,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1137600,
    end_time: 1137600,
    token_index: 2783,
    start_char: 15388,
    end_char: 15391,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "nine",
    start_time: 1137600,
    end_time: 1138100,
    token_index: 2784,
    start_char: 15392,
    end_char: 15396,
    display_word: "nine",
    period_at_end: false
    },
    {
    word: "certainly",
    start_time: 1138100,
    end_time: 1138900,
    token_index: 2785,
    start_char: 15397,
    end_char: 15406,
    display_word: "certainly",
    period_at_end: false
    },
    {
    word: "school-age",
    start_time: 1139300,
    end_time: 1140000,
    token_index: 2786,
    start_char: 15407,
    end_char: 15417,
    display_word: "school-age",
    period_at_end: false
    },
    {
    word: "kids",
    start_time: 1140000,
    end_time: 1140800,
    token_index: 2787,
    start_char: 15418,
    end_char: 15422,
    display_word: "kids",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1141000,
    end_time: 1141100,
    token_index: 2788,
    start_char: 15423,
    end_char: 15425,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1141200,
    end_time: 1141300,
    token_index: 2789,
    start_char: 15426,
    end_char: 15427,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1141300,
    end_time: 1141800,
    token_index: 2790,
    start_char: 15428,
    end_char: 15432,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1141800,
    end_time: 1142000,
    token_index: 2791,
    start_char: 15433,
    end_char: 15436,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "around",
    start_time: 1142000,
    end_time: 1142200,
    token_index: 2792,
    start_char: 15437,
    end_char: 15443,
    display_word: "around",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1142200,
    end_time: 1142300,
    token_index: 2793,
    start_char: 15444,
    end_char: 15447,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 1142300,
    end_time: 1142500,
    token_index: 2794,
    start_char: 15448,
    end_char: 15454,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "States,",
    start_time: 1142500,
    end_time: 1142700,
    token_index: 2795,
    start_char: 15455,
    end_char: 15462,
    display_word: "States,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1142700,
    end_time: 1142800,
    token_index: 2796,
    start_char: 15463,
    end_char: 15466,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "especially",
    start_time: 1142800,
    end_time: 1143100,
    token_index: 2797,
    start_char: 15467,
    end_char: 15477,
    display_word: "especially",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1143100,
    end_time: 1143100,
    token_index: 2798,
    start_char: 15478,
    end_char: 15480,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "California",
    start_time: 1143100,
    end_time: 1143700,
    token_index: 2799,
    start_char: 15481,
    end_char: 15491,
    display_word: "California",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1143700,
    end_time: 1143800,
    token_index: 2800,
    start_char: 15492,
    end_char: 15495,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1143800,
    end_time: 1144200,
    token_index: 2801,
    start_char: 15496,
    end_char: 15500,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "school",
    start_time: 1144200,
    end_time: 1144500,
    token_index: 2802,
    start_char: 15501,
    end_char: 15507,
    display_word: "school",
    period_at_end: false
    },
    {
    word: "districts",
    start_time: 1144500,
    end_time: 1144900,
    token_index: 2803,
    start_char: 15508,
    end_char: 15517,
    display_word: "districts",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1144900,
    end_time: 1145000,
    token_index: 2804,
    start_char: 15518,
    end_char: 15521,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "having",
    start_time: 1145000,
    end_time: 1145400,
    token_index: 2805,
    start_char: 15522,
    end_char: 15528,
    display_word: "having",
    period_at_end: false
    },
    {
    word: "kids",
    start_time: 1145400,
    end_time: 1145800,
    token_index: 2806,
    start_char: 15529,
    end_char: 15533,
    display_word: "kids",
    period_at_end: false
    },
    {
    word: "come",
    start_time: 1145800,
    end_time: 1145900,
    token_index: 2807,
    start_char: 15534,
    end_char: 15538,
    display_word: "come",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1145900,
    end_time: 1146400,
    token_index: 2808,
    start_char: 15539,
    end_char: 15543,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1146500,
    end_time: 1146800,
    token_index: 2809,
    start_char: 15544,
    end_char: 15548,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "aren't",
    start_time: 1146800,
    end_time: 1147600,
    token_index: 2810,
    start_char: 15549,
    end_char: 15555,
    display_word: "aren't",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1147700,
    end_time: 1147900,
    token_index: 2811,
    start_char: 15556,
    end_char: 15558,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1147900,
    end_time: 1148000,
    token_index: 2812,
    start_char: 15559,
    end_char: 15560,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "parent.",
    start_time: 1148000,
    end_time: 1149000,
    token_index: 2813,
    start_char: 15561,
    end_char: 15567,
    display_word: "parent",
    period_at_end: true
    },
    {
    word: "How",
    start_time: 1149100,
    end_time: 1149600,
    token_index: 2814,
    start_char: 15569,
    end_char: 15572,
    display_word: "How",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 1149600,
    end_time: 1149700,
    token_index: 2815,
    start_char: 15573,
    end_char: 15575,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1149700,
    end_time: 1149900,
    token_index: 2816,
    start_char: 15576,
    end_char: 15579,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "feel",
    start_time: 1149900,
    end_time: 1150100,
    token_index: 2817,
    start_char: 15580,
    end_char: 15584,
    display_word: "feel",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1150100,
    end_time: 1150400,
    token_index: 2818,
    start_char: 15585,
    end_char: 15590,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "that?",
    start_time: 1150400,
    end_time: 1150600,
    token_index: 2819,
    start_char: 15591,
    end_char: 15596,
    display_word: "that?",
    period_at_end: false
    },
    {
    word: "But",
    start_time: 1150600,
    end_time: 1150700,
    token_index: 2820,
    start_char: 15597,
    end_char: 15600,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "everybody",
    start_time: 1150700,
    end_time: 1151000,
    token_index: 2821,
    start_char: 15601,
    end_char: 15610,
    display_word: "everybody",
    period_at_end: false
    },
    {
    word: "wants",
    start_time: 1151000,
    end_time: 1151300,
    token_index: 2822,
    start_char: 15611,
    end_char: 15616,
    display_word: "wants",
    period_at_end: false
    },
    {
    word: "kids",
    start_time: 1151300,
    end_time: 1151500,
    token_index: 2823,
    start_char: 15617,
    end_char: 15621,
    display_word: "kids",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1151500,
    end_time: 1151600,
    token_index: 2824,
    start_char: 15622,
    end_char: 15624,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1151600,
    end_time: 1151700,
    token_index: 2825,
    start_char: 15625,
    end_char: 15627,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "able",
    start_time: 1151700,
    end_time: 1151800,
    token_index: 2826,
    start_char: 15628,
    end_char: 15632,
    display_word: "able",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1151800,
    end_time: 1152000,
    token_index: 2827,
    start_char: 15633,
    end_char: 15635,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 1152000,
    end_time: 1152200,
    token_index: 2828,
    start_char: 15636,
    end_char: 15638,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 1152200,
    end_time: 1152400,
    token_index: 2829,
    start_char: 15639,
    end_char: 15642,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "educated.",
    start_time: 1152400,
    end_time: 1153000,
    token_index: 2830,
    start_char: 15643,
    end_char: 15651,
    display_word: "educated",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 1153000,
    end_time: 1153500,
    token_index: 2831,
    start_char: 15653,
    end_char: 15656,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "yes",
    start_time: 1153800,
    end_time: 1154200,
    token_index: 2832,
    start_char: 15657,
    end_char: 15660,
    display_word: "yes",
    period_at_end: false
    },
    {
    word: "anxiety.",
    start_time: 1154200,
    end_time: 1155100,
    token_index: 2833,
    start_char: 15661,
    end_char: 15668,
    display_word: "anxiety",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1155500,
    end_time: 1155600,
    token_index: 2834,
    start_char: 15670,
    end_char: 15671,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "mean,",
    start_time: 1155600,
    end_time: 1155900,
    token_index: 2835,
    start_char: 15672,
    end_char: 15677,
    display_word: "mean,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1155900,
    end_time: 1156200,
    token_index: 2836,
    start_char: 15678,
    end_char: 15679,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 1156200,
    end_time: 1156500,
    token_index: 2837,
    start_char: 15680,
    end_char: 15685,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "believe",
    start_time: 1156500,
    end_time: 1156800,
    token_index: 2838,
    start_char: 15686,
    end_char: 15693,
    display_word: "believe",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1156800,
    end_time: 1156900,
    token_index: 2839,
    start_char: 15694,
    end_char: 15697,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "kids",
    start_time: 1156900,
    end_time: 1157100,
    token_index: 2840,
    start_char: 15698,
    end_char: 15702,
    display_word: "kids",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1157100,
    end_time: 1157200,
    token_index: 2841,
    start_char: 15703,
    end_char: 15706,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1157200,
    end_time: 1157400,
    token_index: 2842,
    start_char: 15707,
    end_char: 15712,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1157400,
    end_time: 1157500,
    token_index: 2843,
    start_char: 15713,
    end_char: 15717,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1157500,
    end_time: 1157600,
    token_index: 2844,
    start_char: 15718,
    end_char: 15720,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "school.",
    start_time: 1157600,
    end_time: 1158000,
    token_index: 2845,
    start_char: 15721,
    end_char: 15727,
    display_word: "school",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1158000,
    end_time: 1158000,
    token_index: 2846,
    start_char: 15729,
    end_char: 15730,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1158000,
    end_time: 1158300,
    token_index: 2847,
    start_char: 15731,
    end_char: 15736,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1158300,
    end_time: 1158400,
    token_index: 2848,
    start_char: 15737,
    end_char: 15741,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1158400,
    end_time: 1158600,
    token_index: 2849,
    start_char: 15742,
    end_char: 15746,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1158600,
    end_time: 1158700,
    token_index: 2850,
    start_char: 15747,
    end_char: 15748,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "domino",
    start_time: 1158700,
    end_time: 1159100,
    token_index: 2851,
    start_char: 15749,
    end_char: 15755,
    display_word: "domino",
    period_at_end: false
    },
    {
    word: "effect",
    start_time: 1159100,
    end_time: 1159400,
    token_index: 2852,
    start_char: 15756,
    end_char: 15762,
    display_word: "effect",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1159400,
    end_time: 1159500,
    token_index: 2853,
    start_char: 15763,
    end_char: 15765,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1159500,
    end_time: 1159700,
    token_index: 2854,
    start_char: 15766,
    end_char: 15770,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 1159700,
    end_time: 1160100,
    token_index: 2855,
    start_char: 15771,
    end_char: 15774,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 1160200,
    end_time: 1160400,
    token_index: 2856,
    start_char: 15775,
    end_char: 15778,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "school",
    start_time: 1160400,
    end_time: 1160600,
    token_index: 2857,
    start_char: 15779,
    end_char: 15785,
    display_word: "school",
    period_at_end: false
    },
    {
    word: "district",
    start_time: 1160600,
    end_time: 1161000,
    token_index: 2858,
    start_char: 15786,
    end_char: 15794,
    display_word: "district",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 1161000,
    end_time: 1161100,
    token_index: 2859,
    start_char: 15795,
    end_char: 15797,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1161100,
    end_time: 1161100,
    token_index: 2860,
    start_char: 15798,
    end_char: 15799,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "time",
    start_time: 1161100,
    end_time: 1161700,
    token_index: 2861,
    start_char: 15800,
    end_char: 15804,
    display_word: "time",
    period_at_end: false
    },
    {
    word: "until",
    start_time: 1161900,
    end_time: 1162200,
    token_index: 2862,
    start_char: 15805,
    end_char: 15810,
    display_word: "until",
    period_at_end: false
    },
    {
    word: "they're",
    start_time: 1162200,
    end_time: 1162400,
    token_index: 2863,
    start_char: 15811,
    end_char: 15818,
    display_word: "they're",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1162400,
    end_time: 1162600,
    token_index: 2864,
    start_char: 15819,
    end_char: 15822,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "go.",
    start_time: 1162600,
    end_time: 1162900,
    token_index: 2865,
    start_char: 15823,
    end_char: 15825,
    display_word: "go",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1162900,
    end_time: 1163000,
    token_index: 2866,
    start_char: 15827,
    end_char: 15828,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1163000,
    end_time: 1163300,
    token_index: 2867,
    start_char: 15829,
    end_char: 15833,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 1163300,
    end_time: 1163500,
    token_index: 2868,
    start_char: 15834,
    end_char: 15837,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "sort",
    start_time: 1163500,
    end_time: 1163600,
    token_index: 2869,
    start_char: 15838,
    end_char: 15842,
    display_word: "sort",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1163600,
    end_time: 1163700,
    token_index: 2870,
    start_char: 15843,
    end_char: 15845,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "preparing",
    start_time: 1163700,
    end_time: 1164000,
    token_index: 2871,
    start_char: 15846,
    end_char: 15855,
    display_word: "preparing",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1164000,
    end_time: 1164200,
    token_index: 2872,
    start_char: 15856,
    end_char: 15859,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1164200,
    end_time: 1164200,
    token_index: 2873,
    start_char: 15860,
    end_char: 15863,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "fact",
    start_time: 1164200,
    end_time: 1164500,
    token_index: 2874,
    start_char: 15864,
    end_char: 15868,
    display_word: "fact",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1164500,
    end_time: 1164700,
    token_index: 2875,
    start_char: 15869,
    end_char: 15873,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1164700,
    end_time: 1164900,
    token_index: 2876,
    start_char: 15874,
    end_char: 15878,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1165000,
    end_time: 1165200,
    token_index: 2877,
    start_char: 15879,
    end_char: 15883,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1165600,
    end_time: 1165800,
    token_index: 2878,
    start_char: 15884,
    end_char: 15886,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1165800,
    end_time: 1165900,
    token_index: 2879,
    start_char: 15887,
    end_char: 15890,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1165900,
    end_time: 1166000,
    token_index: 2880,
    start_char: 15891,
    end_char: 15893,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "wife",
    start_time: 1166000,
    end_time: 1166300,
    token_index: 2881,
    start_char: 15894,
    end_char: 15898,
    display_word: "wife",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1166300,
    end_time: 1166400,
    token_index: 2882,
    start_char: 15899,
    end_char: 15903,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1166400,
    end_time: 1166600,
    token_index: 2883,
    start_char: 15904,
    end_char: 15910,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1166600,
    end_time: 1166800,
    token_index: 2884,
    start_char: 15911,
    end_char: 15916,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1166800,
    end_time: 1166800,
    token_index: 2885,
    start_char: 15917,
    end_char: 15919,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1166800,
    end_time: 1167000,
    token_index: 2886,
    start_char: 15920,
    end_char: 15924,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1167000,
    end_time: 1167300,
    token_index: 2887,
    start_char: 15925,
    end_char: 15927,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1167300,
    end_time: 1167700,
    token_index: 2888,
    start_char: 15928,
    end_char: 15934,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "bone",
    start_time: 1168100,
    end_time: 1168400,
    token_index: 2889,
    start_char: 15935,
    end_char: 15939,
    display_word: "bone",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1168400,
    end_time: 1168600,
    token_index: 2890,
    start_char: 15940,
    end_char: 15942,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1168600,
    end_time: 1168700,
    token_index: 2891,
    start_char: 15943,
    end_char: 15945,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1168700,
    end_time: 1168800,
    token_index: 2892,
    start_char: 15946,
    end_char: 15949,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1168800,
    end_time: 1169000,
    token_index: 2893,
    start_char: 15950,
    end_char: 15952,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "educate",
    start_time: 1169200,
    end_time: 1169600,
    token_index: 2894,
    start_char: 15953,
    end_char: 15960,
    display_word: "educate",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1169600,
    end_time: 1169700,
    token_index: 2895,
    start_char: 15961,
    end_char: 15964,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "kids",
    start_time: 1169700,
    end_time: 1170300,
    token_index: 2896,
    start_char: 15965,
    end_char: 15969,
    display_word: "kids",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 1170400,
    end_time: 1170600,
    token_index: 2897,
    start_char: 15970,
    end_char: 15972,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1170600,
    end_time: 1170700,
    token_index: 2898,
    start_char: 15973,
    end_char: 15976,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "same",
    start_time: 1170700,
    end_time: 1171000,
    token_index: 2899,
    start_char: 15977,
    end_char: 15981,
    display_word: "same",
    period_at_end: false
    },
    {
    word: "time.",
    start_time: 1171000,
    end_time: 1171200,
    token_index: 2900,
    start_char: 15982,
    end_char: 15986,
    display_word: "time",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1171200,
    end_time: 1171300,
    token_index: 2901,
    start_char: 15988,
    end_char: 15989,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1171300,
    end_time: 1171500,
    token_index: 2902,
    start_char: 15990,
    end_char: 15994,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1171500,
    end_time: 1171700,
    token_index: 2903,
    start_char: 15995,
    end_char: 15999,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1171700,
    end_time: 1171900,
    token_index: 2904,
    start_char: 16000,
    end_char: 16003,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "teachers",
    start_time: 1171900,
    end_time: 1172300,
    token_index: 2905,
    start_char: 16004,
    end_char: 16012,
    display_word: "teachers",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 1172300,
    end_time: 1172600,
    token_index: 2906,
    start_char: 16013,
    end_char: 16015,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1172600,
    end_time: 1172800,
    token_index: 2907,
    start_char: 16016,
    end_char: 16019,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "school",
    start_time: 1172800,
    end_time: 1173600,
    token_index: 2908,
    start_char: 16020,
    end_char: 16026,
    display_word: "school",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 1173800,
    end_time: 1173900,
    token_index: 2909,
    start_char: 16027,
    end_char: 16031,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1173900,
    end_time: 1174100,
    token_index: 2910,
    start_char: 16032,
    end_char: 16034,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "plugged",
    start_time: 1174100,
    end_time: 1174400,
    token_index: 2911,
    start_char: 16035,
    end_char: 16042,
    display_word: "plugged",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1174400,
    end_time: 1174500,
    token_index: 2912,
    start_char: 16043,
    end_char: 16045,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1174500,
    end_time: 1174600,
    token_index: 2913,
    start_char: 16046,
    end_char: 16049,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "trying",
    start_time: 1174600,
    end_time: 1174900,
    token_index: 2914,
    start_char: 16050,
    end_char: 16056,
    display_word: "trying",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1174900,
    end_time: 1175000,
    token_index: 2915,
    start_char: 16057,
    end_char: 16059,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "help",
    start_time: 1175000,
    end_time: 1175200,
    token_index: 2916,
    start_char: 16060,
    end_char: 16064,
    display_word: "help",
    period_at_end: false
    },
    {
    word: "them.",
    start_time: 1175200,
    end_time: 1175600,
    token_index: 2917,
    start_char: 16065,
    end_char: 16069,
    display_word: "them",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 1175800,
    end_time: 1176100,
    token_index: 2918,
    start_char: 16071,
    end_char: 16075,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1176100,
    end_time: 1176600,
    token_index: 2919,
    start_char: 16076,
    end_char: 16080,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1176800,
    end_time: 1177000,
    token_index: 2920,
    start_char: 16081,
    end_char: 16085,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1177000,
    end_time: 1177100,
    token_index: 2921,
    start_char: 16086,
    end_char: 16088,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "should",
    start_time: 1177100,
    end_time: 1177300,
    token_index: 2922,
    start_char: 16089,
    end_char: 16095,
    display_word: "should",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1177300,
    end_time: 1177500,
    token_index: 2923,
    start_char: 16096,
    end_char: 16100,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "done",
    start_time: 1177500,
    end_time: 1177700,
    token_index: 2924,
    start_char: 16101,
    end_char: 16105,
    display_word: "done",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1177700,
    end_time: 1177800,
    token_index: 2925,
    start_char: 16106,
    end_char: 16108,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1177800,
    end_time: 1178000,
    token_index: 2926,
    start_char: 16109,
    end_char: 16113,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "interim",
    start_time: 1178000,
    end_time: 1178400,
    token_index: 2927,
    start_char: 16114,
    end_char: 16121,
    display_word: "interim",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1178400,
    end_time: 1178600,
    token_index: 2928,
    start_char: 16122,
    end_char: 16124,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1178600,
    end_time: 1178700,
    token_index: 2929,
    start_char: 16125,
    end_char: 16127,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "conceive",
    start_time: 1178700,
    end_time: 1179100,
    token_index: 2930,
    start_char: 16128,
    end_char: 16136,
    display_word: "conceive",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1179100,
    end_time: 1179300,
    token_index: 2931,
    start_char: 16137,
    end_char: 16140,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "School",
    start_time: 1179300,
    end_time: 1179700,
    token_index: 2932,
    start_char: 16141,
    end_char: 16147,
    display_word: "School",
    period_at_end: false
    },
    {
    word: "works",
    start_time: 1179700,
    end_time: 1180300,
    token_index: 2933,
    start_char: 16148,
    end_char: 16153,
    display_word: "works",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1180400,
    end_time: 1181000,
    token_index: 2934,
    start_char: 16154,
    end_char: 16161,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1181300,
    end_time: 1181500,
    token_index: 2935,
    start_char: 16162,
    end_char: 16166,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1181500,
    end_time: 1181600,
    token_index: 2936,
    start_char: 16167,
    end_char: 16170,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1181600,
    end_time: 1181800,
    token_index: 2937,
    start_char: 16171,
    end_char: 16176,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1181800,
    end_time: 1181800,
    token_index: 2938,
    start_char: 16177,
    end_char: 16179,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "work",
    start_time: 1181800,
    end_time: 1182000,
    token_index: 2939,
    start_char: 16180,
    end_char: 16184,
    display_word: "work",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1182000,
    end_time: 1182100,
    token_index: 2940,
    start_char: 16185,
    end_char: 16188,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "way",
    start_time: 1182100,
    end_time: 1182200,
    token_index: 2941,
    start_char: 16189,
    end_char: 16192,
    display_word: "way",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1182200,
    end_time: 1182300,
    token_index: 2942,
    start_char: 16193,
    end_char: 16195,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1182300,
    end_time: 1182400,
    token_index: 2943,
    start_char: 16196,
    end_char: 16198,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "work",
    start_time: 1182400,
    end_time: 1182800,
    token_index: 2944,
    start_char: 16199,
    end_char: 16203,
    display_word: "work",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1182800,
    end_time: 1182900,
    token_index: 2945,
    start_char: 16204,
    end_char: 16207,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1182900,
    end_time: 1183000,
    token_index: 2946,
    start_char: 16208,
    end_char: 16209,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "long",
    start_time: 1183000,
    end_time: 1183300,
    token_index: 2947,
    start_char: 16210,
    end_char: 16214,
    display_word: "long",
    period_at_end: false
    },
    {
    word: "time.",
    start_time: 1183300,
    end_time: 1183700,
    token_index: 2948,
    start_char: 16215,
    end_char: 16219,
    display_word: "time",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 1183700,
    end_time: 1183800,
    token_index: 2949,
    start_char: 16221,
    end_char: 16224,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "know,",
    start_time: 1183800,
    end_time: 1184100,
    token_index: 2950,
    start_char: 16225,
    end_char: 16230,
    display_word: "know,",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 1184500,
    end_time: 1184900,
    token_index: 2951,
    start_char: 16231,
    end_char: 16234,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "you're",
    start_time: 1186100,
    end_time: 1186300,
    token_index: 2952,
    start_char: 16235,
    end_char: 16241,
    display_word: "you're",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1186300,
    end_time: 1186400,
    token_index: 2953,
    start_char: 16242,
    end_char: 16243,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "comedian",
    start_time: 1186400,
    end_time: 1187200,
    token_index: 2954,
    start_char: 16244,
    end_char: 16252,
    display_word: "comedian",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1187800,
    end_time: 1188000,
    token_index: 2955,
    start_char: 16253,
    end_char: 16256,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "you've",
    start_time: 1188000,
    end_time: 1188100,
    token_index: 2956,
    start_char: 16257,
    end_char: 16263,
    display_word: "you've",
    period_at_end: false
    },
    {
    word: "got",
    start_time: 1188100,
    end_time: 1188200,
    token_index: 2957,
    start_char: 16264,
    end_char: 16267,
    display_word: "got",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1188200,
    end_time: 1188200,
    token_index: 2958,
    start_char: 16268,
    end_char: 16270,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "CNN",
    start_time: 1188200,
    end_time: 1188800,
    token_index: 2959,
    start_char: 16271,
    end_char: 16274,
    display_word: "CNN",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1188800,
    end_time: 1189400,
    token_index: 2960,
    start_char: 16275,
    end_char: 16279,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 1189700,
    end_time: 1189800,
    token_index: 2961,
    start_char: 16280,
    end_char: 16282,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1189800,
    end_time: 1190000,
    token_index: 2962,
    start_char: 16283,
    end_char: 16287,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "kids",
    start_time: 1190000,
    end_time: 1190400,
    token_index: 2963,
    start_char: 16288,
    end_char: 16292,
    display_word: "kids",
    period_at_end: false
    },
    {
    word: "watch",
    start_time: 1190400,
    end_time: 1190700,
    token_index: 2964,
    start_char: 16293,
    end_char: 16298,
    display_word: "watch",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1190700,
    end_time: 1190900,
    token_index: 2965,
    start_char: 16299,
    end_char: 16302,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "news.",
    start_time: 1190900,
    end_time: 1191500,
    token_index: 2966,
    start_char: 16303,
    end_char: 16307,
    display_word: "news",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1192100,
    end_time: 1192200,
    token_index: 2967,
    start_char: 16309,
    end_char: 16310,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "mean,",
    start_time: 1192200,
    end_time: 1192300,
    token_index: 2968,
    start_char: 16311,
    end_char: 16316,
    display_word: "mean,",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1192300,
    end_time: 1192500,
    token_index: 2969,
    start_char: 16317,
    end_char: 16321,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "keep",
    start_time: 1192500,
    end_time: 1193000,
    token_index: 2970,
    start_char: 16322,
    end_char: 16326,
    display_word: "keep",
    period_at_end: false
    },
    {
    word: "watch",
    start_time: 1193000,
    end_time: 1193300,
    token_index: 2971,
    start_char: 16327,
    end_char: 16332,
    display_word: "watch",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1193300,
    end_time: 1193300,
    token_index: 2972,
    start_char: 16333,
    end_char: 16334,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 1193300,
    end_time: 1193600,
    token_index: 2973,
    start_char: 16335,
    end_char: 16338,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1193600,
    end_time: 1193800,
    token_index: 2974,
    start_char: 16339,
    end_char: 16341,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "news",
    start_time: 1193800,
    end_time: 1194200,
    token_index: 2975,
    start_char: 16342,
    end_char: 16346,
    display_word: "news",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1194200,
    end_time: 1194300,
    token_index: 2976,
    start_char: 16347,
    end_char: 16349,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "kids",
    start_time: 1194300,
    end_time: 1194600,
    token_index: 2977,
    start_char: 16350,
    end_char: 16354,
    display_word: "kids",
    period_at_end: false
    },
    {
    word: "definitely.",
    start_time: 1194600,
    end_time: 1194900,
    token_index: 2978,
    start_char: 16355,
    end_char: 16365,
    display_word: "definitely",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1195600,
    end_time: 1195700,
    token_index: 2979,
    start_char: 16367,
    end_char: 16368,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1195700,
    end_time: 1195900,
    token_index: 2980,
    start_char: 16369,
    end_char: 16374,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "put",
    start_time: 1195900,
    end_time: 1196100,
    token_index: 2981,
    start_char: 16375,
    end_char: 16378,
    display_word: "put",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1196100,
    end_time: 1196300,
    token_index: 2982,
    start_char: 16379,
    end_char: 16381,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "nine-year-old",
    start_time: 1196300,
    end_time: 1196900,
    token_index: 2983,
    start_char: 16382,
    end_char: 16395,
    display_word: "nine-year-old",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1196900,
    end_time: 1197000,
    token_index: 2984,
    start_char: 16396,
    end_char: 16398,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "against",
    start_time: 1197000,
    end_time: 1197300,
    token_index: 2985,
    start_char: 16399,
    end_char: 16406,
    display_word: "against",
    period_at_end: false
    },
    {
    word: "any",
    start_time: 1197300,
    end_time: 1197400,
    token_index: 2986,
    start_char: 16407,
    end_char: 16410,
    display_word: "any",
    period_at_end: false
    },
    {
    word: "other",
    start_time: 1197400,
    end_time: 1197600,
    token_index: 2987,
    start_char: 16411,
    end_char: 16416,
    display_word: "other",
    period_at_end: false
    },
    {
    word: "nine-year-old",
    start_time: 1197600,
    end_time: 1198200,
    token_index: 2988,
    start_char: 16417,
    end_char: 16430,
    display_word: "nine-year-old",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1198200,
    end_time: 1198500,
    token_index: 2989,
    start_char: 16431,
    end_char: 16436,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "Donald",
    start_time: 1198500,
    end_time: 1198900,
    token_index: 2990,
    start_char: 16437,
    end_char: 16443,
    display_word: "Donald",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 1198900,
    end_time: 1199100,
    token_index: 2991,
    start_char: 16444,
    end_char: 16449,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "knowledge",
    start_time: 1199100,
    end_time: 1199700,
    token_index: 2992,
    start_char: 16450,
    end_char: 16459,
    display_word: "knowledge",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1199700,
    end_time: 1199800,
    token_index: 2993,
    start_char: 16460,
    end_char: 16463,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "what's",
    start_time: 1199800,
    end_time: 1200000,
    token_index: 2994,
    start_char: 16464,
    end_char: 16470,
    display_word: "what's",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1200000,
    end_time: 1200200,
    token_index: 2995,
    start_char: 16471,
    end_char: 16476,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1200200,
    end_time: 1200400,
    token_index: 2996,
    start_char: 16477,
    end_char: 16479,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "currently",
    start_time: 1200400,
    end_time: 1200800,
    token_index: 2997,
    start_char: 16480,
    end_char: 16489,
    display_word: "currently",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1200800,
    end_time: 1200900,
    token_index: 2998,
    start_char: 16490,
    end_char: 16492,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1200900,
    end_time: 1201000,
    token_index: 2999,
    start_char: 16493,
    end_char: 16496,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1201000,
    end_time: 1201200,
    token_index: 3000,
    start_char: 16497,
    end_char: 16502,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "house.",
    start_time: 1201200,
    end_time: 1201700,
    token_index: 3001,
    start_char: 16503,
    end_char: 16508,
    display_word: "house",
    period_at_end: true
    },
    {
    word: "She",
    start_time: 1201900,
    end_time: 1202100,
    token_index: 3002,
    start_char: 16510,
    end_char: 16513,
    display_word: "She",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1202100,
    end_time: 1202300,
    token_index: 3003,
    start_char: 16514,
    end_char: 16519,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1202300,
    end_time: 1202400,
    token_index: 3004,
    start_char: 16520,
    end_char: 16522,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "she",
    start_time: 1202700,
    end_time: 1202900,
    token_index: 3005,
    start_char: 16523,
    end_char: 16526,
    display_word: "she",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1202900,
    end_time: 1203100,
    token_index: 3006,
    start_char: 16527,
    end_char: 16532,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "win",
    start_time: 1203100,
    end_time: 1203200,
    token_index: 3007,
    start_char: 16533,
    end_char: 16536,
    display_word: "win",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1203200,
    end_time: 1203400,
    token_index: 3008,
    start_char: 16537,
    end_char: 16541,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "contest.",
    start_time: 1203400,
    end_time: 1204100,
    token_index: 3009,
    start_char: 16542,
    end_char: 16549,
    display_word: "contest",
    period_at_end: true
    },
    {
    word: "Does",
    start_time: 1204200,
    end_time: 1204400,
    token_index: 3010,
    start_char: 16551,
    end_char: 16555,
    display_word: "Does",
    period_at_end: false
    },
    {
    word: "she",
    start_time: 1204400,
    end_time: 1204400,
    token_index: 3011,
    start_char: 16556,
    end_char: 16559,
    display_word: "she",
    period_at_end: false
    },
    {
    word: "ever",
    start_time: 1204400,
    end_time: 1204600,
    token_index: 3012,
    start_char: 16560,
    end_char: 16564,
    display_word: "ever",
    period_at_end: false
    },
    {
    word: "set",
    start_time: 1204600,
    end_time: 1204800,
    token_index: 3013,
    start_char: 16565,
    end_char: 16568,
    display_word: "set",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1204800,
    end_time: 1204900,
    token_index: 3014,
    start_char: 16569,
    end_char: 16572,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1204900,
    end_time: 1205100,
    token_index: 3015,
    start_char: 16573,
    end_char: 16577,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1205100,
    end_time: 1205200,
    token_index: 3016,
    start_char: 16578,
    end_char: 16580,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1205200,
    end_time: 1205300,
    token_index: 3017,
    start_char: 16581,
    end_char: 16585,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "heels",
    start_time: 1205300,
    end_time: 1205700,
    token_index: 3018,
    start_char: 16586,
    end_char: 16591,
    display_word: "heels",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1205700,
    end_time: 1205800,
    token_index: 3019,
    start_char: 16592,
    end_char: 16596,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1205800,
    end_time: 1205900,
    token_index: 3020,
    start_char: 16597,
    end_char: 16601,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1205900,
    end_time: 1206000,
    token_index: 3021,
    start_char: 16602,
    end_char: 16604,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "her",
    start_time: 1206000,
    end_time: 1206100,
    token_index: 3022,
    start_char: 16605,
    end_char: 16608,
    display_word: "her",
    period_at_end: false
    },
    {
    word: "questions?",
    start_time: 1206100,
    end_time: 1206800,
    token_index: 3023,
    start_char: 16609,
    end_char: 16619,
    display_word: "questions?",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1207400,
    end_time: 1207500,
    token_index: 3024,
    start_char: 16620,
    end_char: 16621,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "mean,",
    start_time: 1207500,
    end_time: 1208100,
    token_index: 3025,
    start_char: 16622,
    end_char: 16627,
    display_word: "mean,",
    period_at_end: false
    },
    {
    word: "she's",
    start_time: 1209800,
    end_time: 1210200,
    token_index: 3026,
    start_char: 16628,
    end_char: 16633,
    display_word: "she's",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1210200,
    end_time: 1210400,
    token_index: 3027,
    start_char: 16634,
    end_char: 16638,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1210400,
    end_time: 1210700,
    token_index: 3028,
    start_char: 16639,
    end_char: 16641,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1210700,
    end_time: 1210800,
    token_index: 3029,
    start_char: 16642,
    end_char: 16646,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 1210800,
    end_time: 1211000,
    token_index: 3030,
    start_char: 16647,
    end_char: 16651,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1211000,
    end_time: 1211000,
    token_index: 3031,
    start_char: 16652,
    end_char: 16653,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1211000,
    end_time: 1211100,
    token_index: 3032,
    start_char: 16654,
    end_char: 16657,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1211100,
    end_time: 1211200,
    token_index: 3033,
    start_char: 16658,
    end_char: 16659,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "kid,",
    start_time: 1211200,
    end_time: 1211400,
    token_index: 3034,
    start_char: 16660,
    end_char: 16664,
    display_word: "kid,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1211400,
    end_time: 1211500,
    token_index: 3035,
    start_char: 16665,
    end_char: 16666,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1211500,
    end_time: 1211600,
    token_index: 3036,
    start_char: 16667,
    end_char: 16671,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "sort",
    start_time: 1211600,
    end_time: 1211800,
    token_index: 3037,
    start_char: 16672,
    end_char: 16676,
    display_word: "sort",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1211800,
    end_time: 1211800,
    token_index: 3038,
    start_char: 16677,
    end_char: 16679,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "listen",
    start_time: 1211800,
    end_time: 1212100,
    token_index: 3039,
    start_char: 16680,
    end_char: 16686,
    display_word: "listen",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1212100,
    end_time: 1212100,
    token_index: 3040,
    start_char: 16687,
    end_char: 16689,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1212100,
    end_time: 1212300,
    token_index: 3041,
    start_char: 16690,
    end_char: 16694,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "adults",
    start_time: 1212300,
    end_time: 1212600,
    token_index: 3042,
    start_char: 16695,
    end_char: 16701,
    display_word: "adults",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1212600,
    end_time: 1212700,
    token_index: 3043,
    start_char: 16702,
    end_char: 16706,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "saying",
    start_time: 1212700,
    end_time: 1213000,
    token_index: 3044,
    start_char: 16707,
    end_char: 16713,
    display_word: "saying",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1213000,
    end_time: 1213100,
    token_index: 3045,
    start_char: 16714,
    end_char: 16717,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 1213100,
    end_time: 1213200,
    token_index: 3046,
    start_char: 16718,
    end_char: 16722,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "later",
    start_time: 1213200,
    end_time: 1213400,
    token_index: 3047,
    start_char: 16723,
    end_char: 16728,
    display_word: "later",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1213400,
    end_time: 1213600,
    token_index: 3048,
    start_char: 16729,
    end_char: 16734,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "ask",
    start_time: 1213600,
    end_time: 1213800,
    token_index: 3049,
    start_char: 16735,
    end_char: 16738,
    display_word: "ask",
    period_at_end: false
    },
    {
    word: "questions",
    start_time: 1213800,
    end_time: 1214000,
    token_index: 3050,
    start_char: 16739,
    end_char: 16748,
    display_word: "questions",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1214000,
    end_time: 1214100,
    token_index: 3051,
    start_char: 16749,
    end_char: 16751,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1214100,
    end_time: 1214200,
    token_index: 3052,
    start_char: 16752,
    end_char: 16754,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "mom.",
    start_time: 1214200,
    end_time: 1214600,
    token_index: 3053,
    start_char: 16755,
    end_char: 16758,
    display_word: "mom",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 1214600,
    end_time: 1214700,
    token_index: 3054,
    start_char: 16760,
    end_char: 16763,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1214700,
    end_time: 1215100,
    token_index: 3055,
    start_char: 16764,
    end_char: 16766,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1215200,
    end_time: 1215300,
    token_index: 3056,
    start_char: 16767,
    end_char: 16768,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "called",
    start_time: 1215300,
    end_time: 1215500,
    token_index: 3057,
    start_char: 16769,
    end_char: 16775,
    display_word: "called",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1215500,
    end_time: 1215600,
    token_index: 3058,
    start_char: 16776,
    end_char: 16778,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "daughter",
    start_time: 1215600,
    end_time: 1215900,
    token_index: 3059,
    start_char: 16779,
    end_char: 16787,
    display_word: "daughter",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 1215900,
    end_time: 1216000,
    token_index: 3060,
    start_char: 16788,
    end_char: 16790,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "old-school",
    start_time: 1216000,
    end_time: 1216500,
    token_index: 3061,
    start_char: 16791,
    end_char: 16801,
    display_word: "old-school",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1216500,
    end_time: 1216700,
    token_index: 3062,
    start_char: 16802,
    end_char: 16805,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "Hustler.",
    start_time: 1216700,
    end_time: 1217100,
    token_index: 3063,
    start_char: 16806,
    end_char: 16813,
    display_word: "Hustler",
    period_at_end: true
    },
    {
    word: "Like",
    start_time: 1217100,
    end_time: 1217500,
    token_index: 3064,
    start_char: 16815,
    end_char: 16819,
    display_word: "Like",
    period_at_end: false
    },
    {
    word: "she'll",
    start_time: 1217600,
    end_time: 1217700,
    token_index: 3065,
    start_char: 16820,
    end_char: 16826,
    display_word: "she'll",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1217700,
    end_time: 1217900,
    token_index: 3066,
    start_char: 16827,
    end_char: 16831,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "come",
    start_time: 1217900,
    end_time: 1218100,
    token_index: 3067,
    start_char: 16832,
    end_char: 16836,
    display_word: "come",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1218100,
    end_time: 1218200,
    token_index: 3068,
    start_char: 16837,
    end_char: 16839,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 1218200,
    end_time: 1218300,
    token_index: 3069,
    start_char: 16840,
    end_char: 16842,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "hour",
    start_time: 1218300,
    end_time: 1218500,
    token_index: 3070,
    start_char: 16843,
    end_char: 16847,
    display_word: "hour",
    period_at_end: false
    },
    {
    word: "later",
    start_time: 1218500,
    end_time: 1218700,
    token_index: 3071,
    start_char: 16848,
    end_char: 16853,
    display_word: "later",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1218700,
    end_time: 1218800,
    token_index: 3072,
    start_char: 16854,
    end_char: 16857,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1218800,
    end_time: 1218900,
    token_index: 3073,
    start_char: 16858,
    end_char: 16860,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "like,",
    start_time: 1218900,
    end_time: 1219200,
    token_index: 3074,
    start_char: 16861,
    end_char: 16866,
    display_word: "like,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1219200,
    end_time: 1219200,
    token_index: 3075,
    start_char: 16867,
    end_char: 16868,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1219200,
    end_time: 1219400,
    token_index: 3076,
    start_char: 16869,
    end_char: 16873,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1219400,
    end_time: 1219400,
    token_index: 3077,
    start_char: 16874,
    end_char: 16875,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "few",
    start_time: 1219400,
    end_time: 1219600,
    token_index: 3078,
    start_char: 16876,
    end_char: 16879,
    display_word: "few",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1219600,
    end_time: 1219900,
    token_index: 3079,
    start_char: 16880,
    end_char: 16886,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1219900,
    end_time: 1219900,
    token_index: 3080,
    start_char: 16887,
    end_char: 16888,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "need",
    start_time: 1219900,
    end_time: 1220100,
    token_index: 3081,
    start_char: 16889,
    end_char: 16893,
    display_word: "need",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1220100,
    end_time: 1220100,
    token_index: 3082,
    start_char: 16894,
    end_char: 16896,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "discuss",
    start_time: 1220100,
    end_time: 1220800,
    token_index: 3083,
    start_char: 16897,
    end_char: 16904,
    display_word: "discuss",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1221300,
    end_time: 1221500,
    token_index: 3084,
    start_char: 16905,
    end_char: 16908,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1221500,
    end_time: 1222100,
    token_index: 3085,
    start_char: 16909,
    end_char: 16911,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1222200,
    end_time: 1222400,
    token_index: 3086,
    start_char: 16912,
    end_char: 16914,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1222400,
    end_time: 1222500,
    token_index: 3087,
    start_char: 16915,
    end_char: 16919,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 1222500,
    end_time: 1223000,
    token_index: 3088,
    start_char: 16920,
    end_char: 16925,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "whole",
    start_time: 1223000,
    end_time: 1223400,
    token_index: 3089,
    start_char: 16926,
    end_char: 16931,
    display_word: "whole",
    period_at_end: false
    },
    {
    word: "conversation",
    start_time: 1223400,
    end_time: 1224200,
    token_index: 3090,
    start_char: 16932,
    end_char: 16944,
    display_word: "conversation",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1224200,
    end_time: 1224800,
    token_index: 3091,
    start_char: 16945,
    end_char: 16950,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "George",
    start_time: 1225400,
    end_time: 1225800,
    token_index: 3092,
    start_char: 16951,
    end_char: 16957,
    display_word: "George",
    period_at_end: false
    },
    {
    word: "Floyd",
    start_time: 1225800,
    end_time: 1226400,
    token_index: 3093,
    start_char: 16958,
    end_char: 16963,
    display_word: "Floyd",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1226400,
    end_time: 1226600,
    token_index: 3094,
    start_char: 16964,
    end_char: 16967,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "police",
    start_time: 1226600,
    end_time: 1226800,
    token_index: 3095,
    start_char: 16968,
    end_char: 16974,
    display_word: "police",
    period_at_end: false
    },
    {
    word: "brutality",
    start_time: 1226800,
    end_time: 1227400,
    token_index: 3096,
    start_char: 16975,
    end_char: 16984,
    display_word: "brutality",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1227400,
    end_time: 1227600,
    token_index: 3097,
    start_char: 16985,
    end_char: 16989,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1227600,
    end_time: 1228000,
    token_index: 3098,
    start_char: 16990,
    end_char: 16994,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "while",
    start_time: 1228100,
    end_time: 1228300,
    token_index: 3099,
    start_char: 16995,
    end_char: 17000,
    display_word: "while",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1228300,
    end_time: 1228400,
    token_index: 3100,
    start_char: 17001,
    end_char: 17003,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1228400,
    end_time: 1228400,
    token_index: 3101,
    start_char: 17004,
    end_char: 17008,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1228400,
    end_time: 1228600,
    token_index: 3102,
    start_char: 17009,
    end_char: 17011,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1228600,
    end_time: 1228600,
    token_index: 3103,
    start_char: 17012,
    end_char: 17013,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "tire",
    start_time: 1228600,
    end_time: 1229000,
    token_index: 3104,
    start_char: 17014,
    end_char: 17018,
    display_word: "tire",
    period_at_end: false
    },
    {
    word: "swing.",
    start_time: 1229000,
    end_time: 1229400,
    token_index: 3105,
    start_char: 17019,
    end_char: 17024,
    display_word: "swing",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1229800,
    end_time: 1229900,
    token_index: 3106,
    start_char: 17026,
    end_char: 17027,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1229900,
    end_time: 1230000,
    token_index: 3107,
    start_char: 17028,
    end_char: 17031,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1230000,
    end_time: 1230200,
    token_index: 3108,
    start_char: 17032,
    end_char: 17036,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "spinning",
    start_time: 1230200,
    end_time: 1230500,
    token_index: 3109,
    start_char: 17037,
    end_char: 17045,
    display_word: "spinning",
    period_at_end: false
    },
    {
    word: "around",
    start_time: 1230500,
    end_time: 1230800,
    token_index: 3110,
    start_char: 17046,
    end_char: 17052,
    display_word: "around",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1230800,
    end_time: 1230900,
    token_index: 3111,
    start_char: 17053,
    end_char: 17055,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1230900,
    end_time: 1230900,
    token_index: 3112,
    start_char: 17056,
    end_char: 17059,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "tire",
    start_time: 1230900,
    end_time: 1231200,
    token_index: 3113,
    start_char: 17060,
    end_char: 17064,
    display_word: "tire",
    period_at_end: false
    },
    {
    word: "swing",
    start_time: 1231200,
    end_time: 1231700,
    token_index: 3114,
    start_char: 17065,
    end_char: 17070,
    display_word: "swing",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1231800,
    end_time: 1232000,
    token_index: 3115,
    start_char: 17071,
    end_char: 17074,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "she",
    start_time: 1232000,
    end_time: 1232100,
    token_index: 3116,
    start_char: 17075,
    end_char: 17078,
    display_word: "she",
    period_at_end: false
    },
    {
    word: "wanted",
    start_time: 1232100,
    end_time: 1232400,
    token_index: 3117,
    start_char: 17079,
    end_char: 17085,
    display_word: "wanted",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1232400,
    end_time: 1232500,
    token_index: 3118,
    start_char: 17086,
    end_char: 17087,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "full",
    start_time: 1232500,
    end_time: 1232900,
    token_index: 3119,
    start_char: 17088,
    end_char: 17092,
    display_word: "full",
    period_at_end: false
    },
    {
    word: "explanation",
    start_time: 1232900,
    end_time: 1233500,
    token_index: 3120,
    start_char: 17093,
    end_char: 17104,
    display_word: "explanation",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1233500,
    end_time: 1233600,
    token_index: 3121,
    start_char: 17105,
    end_char: 17107,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1233600,
    end_time: 1233700,
    token_index: 3122,
    start_char: 17108,
    end_char: 17112,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "happened",
    start_time: 1233700,
    end_time: 1234000,
    token_index: 3123,
    start_char: 17113,
    end_char: 17121,
    display_word: "happened",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1234000,
    end_time: 1234100,
    token_index: 3124,
    start_char: 17122,
    end_char: 17126,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "George",
    start_time: 1234100,
    end_time: 1234300,
    token_index: 3125,
    start_char: 17127,
    end_char: 17133,
    display_word: "George",
    period_at_end: false
    },
    {
    word: "Floyd",
    start_time: 1234300,
    end_time: 1234700,
    token_index: 3126,
    start_char: 17134,
    end_char: 17139,
    display_word: "Floyd",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1234700,
    end_time: 1234900,
    token_index: 3127,
    start_char: 17140,
    end_char: 17143,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "police",
    start_time: 1234900,
    end_time: 1235100,
    token_index: 3128,
    start_char: 17144,
    end_char: 17150,
    display_word: "police",
    period_at_end: false
    },
    {
    word: "brutality",
    start_time: 1235100,
    end_time: 1235500,
    token_index: 3129,
    start_char: 17151,
    end_char: 17160,
    display_word: "brutality",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1235500,
    end_time: 1235600,
    token_index: 3130,
    start_char: 17161,
    end_char: 17163,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "America.",
    start_time: 1235600,
    end_time: 1236300,
    token_index: 3131,
    start_char: 17164,
    end_char: 17171,
    display_word: "America",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 1236400,
    end_time: 1236500,
    token_index: 3132,
    start_char: 17173,
    end_char: 17176,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1236500,
    end_time: 1236500,
    token_index: 3133,
    start_char: 17177,
    end_char: 17178,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1236500,
    end_time: 1236600,
    token_index: 3134,
    start_char: 17179,
    end_char: 17182,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1236600,
    end_time: 1236800,
    token_index: 3135,
    start_char: 17183,
    end_char: 17187,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "like,",
    start_time: 1236800,
    end_time: 1237200,
    token_index: 3136,
    start_char: 17188,
    end_char: 17193,
    display_word: "like,",
    period_at_end: false
    },
    {
    word: "wow,",
    start_time: 1239500,
    end_time: 1240200,
    token_index: 3137,
    start_char: 17194,
    end_char: 17198,
    display_word: "wow,",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1240300,
    end_time: 1240500,
    token_index: 3138,
    start_char: 17199,
    end_char: 17203,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1240500,
    end_time: 1240600,
    token_index: 3139,
    start_char: 17204,
    end_char: 17206,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "that?",
    start_time: 1240600,
    end_time: 1240900,
    token_index: 3140,
    start_char: 17207,
    end_char: 17212,
    display_word: "that?",
    period_at_end: false
    },
    {
    word: "How",
    start_time: 1240900,
    end_time: 1241100,
    token_index: 3141,
    start_char: 17213,
    end_char: 17216,
    display_word: "How",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1241100,
    end_time: 1241200,
    token_index: 3142,
    start_char: 17217,
    end_char: 17221,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "does",
    start_time: 1241200,
    end_time: 1241400,
    token_index: 3143,
    start_char: 17222,
    end_char: 17226,
    display_word: "does",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1241400,
    end_time: 1241600,
    token_index: 3144,
    start_char: 17227,
    end_char: 17231,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "feel",
    start_time: 1241600,
    end_time: 1241900,
    token_index: 3145,
    start_char: 17232,
    end_char: 17236,
    display_word: "feel",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1241900,
    end_time: 1242100,
    token_index: 3146,
    start_char: 17237,
    end_char: 17241,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1242100,
    end_time: 1242800,
    token_index: 3147,
    start_char: 17242,
    end_char: 17244,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1242800,
    end_time: 1243000,
    token_index: 3148,
    start_char: 17245,
    end_char: 17249,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1243000,
    end_time: 1243200,
    token_index: 3149,
    start_char: 17250,
    end_char: 17254,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "conversation?",
    start_time: 1243200,
    end_time: 1244200,
    token_index: 3150,
    start_char: 17255,
    end_char: 17268,
    display_word: "conversation?",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1244900,
    end_time: 1245000,
    token_index: 3151,
    start_char: 17269,
    end_char: 17270,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "mean",
    start_time: 1245000,
    end_time: 1245300,
    token_index: 3152,
    start_char: 17271,
    end_char: 17275,
    display_word: "mean",
    period_at_end: false
    },
    {
    word: "weirdly,",
    start_time: 1245300,
    end_time: 1245900,
    token_index: 3153,
    start_char: 17276,
    end_char: 17284,
    display_word: "weirdly,",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1245900,
    end_time: 1246100,
    token_index: 3154,
    start_char: 17285,
    end_char: 17287,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "feels",
    start_time: 1246100,
    end_time: 1246800,
    token_index: 3155,
    start_char: 17288,
    end_char: 17293,
    display_word: "feels",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 1246800,
    end_time: 1247400,
    token_index: 3156,
    start_char: 17294,
    end_char: 17299,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1247400,
    end_time: 1248100,
    token_index: 3157,
    start_char: 17300,
    end_char: 17307,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1248200,
    end_time: 1248400,
    token_index: 3158,
    start_char: 17308,
    end_char: 17309,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1248400,
    end_time: 1248600,
    token_index: 3159,
    start_char: 17310,
    end_char: 17315,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "rather",
    start_time: 1248600,
    end_time: 1248900,
    token_index: 3160,
    start_char: 17316,
    end_char: 17322,
    display_word: "rather",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1248900,
    end_time: 1249100,
    token_index: 3161,
    start_char: 17323,
    end_char: 17327,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1249100,
    end_time: 1249200,
    token_index: 3162,
    start_char: 17328,
    end_char: 17332,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "conversation",
    start_time: 1249200,
    end_time: 1249600,
    token_index: 3163,
    start_char: 17333,
    end_char: 17345,
    display_word: "conversation",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1249600,
    end_time: 1249700,
    token_index: 3164,
    start_char: 17346,
    end_char: 17350,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "her",
    start_time: 1249700,
    end_time: 1249900,
    token_index: 3165,
    start_char: 17351,
    end_char: 17354,
    display_word: "her",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1249900,
    end_time: 1250100,
    token_index: 3166,
    start_char: 17355,
    end_char: 17357,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1250100,
    end_time: 1250200,
    token_index: 3167,
    start_char: 17358,
    end_char: 17362,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1250200,
    end_time: 1250400,
    token_index: 3168,
    start_char: 17363,
    end_char: 17365,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "wife",
    start_time: 1250400,
    end_time: 1250700,
    token_index: 3169,
    start_char: 17366,
    end_char: 17370,
    display_word: "wife",
    period_at_end: false
    },
    {
    word: "having",
    start_time: 1250700,
    end_time: 1251000,
    token_index: 3170,
    start_char: 17371,
    end_char: 17377,
    display_word: "having",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1251000,
    end_time: 1251100,
    token_index: 3171,
    start_char: 17378,
    end_char: 17382,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "her",
    start_time: 1251100,
    end_time: 1251400,
    token_index: 3172,
    start_char: 17383,
    end_char: 17386,
    display_word: "her",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 1251600,
    end_time: 1251800,
    token_index: 3173,
    start_char: 17387,
    end_char: 17391,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1251800,
    end_time: 1251900,
    token_index: 3174,
    start_char: 17392,
    end_char: 17394,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "her",
    start_time: 1251900,
    end_time: 1252100,
    token_index: 3175,
    start_char: 17395,
    end_char: 17398,
    display_word: "her",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1252100,
    end_time: 1252200,
    token_index: 3176,
    start_char: 17399,
    end_char: 17401,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 1252200,
    end_time: 1252400,
    token_index: 3177,
    start_char: 17402,
    end_char: 17404,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "through",
    start_time: 1252400,
    end_time: 1252500,
    token_index: 3178,
    start_char: 17405,
    end_char: 17412,
    display_word: "through",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1252500,
    end_time: 1252600,
    token_index: 3179,
    start_char: 17413,
    end_char: 17416,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "world",
    start_time: 1252600,
    end_time: 1252900,
    token_index: 3180,
    start_char: 17417,
    end_char: 17422,
    display_word: "world",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1252900,
    end_time: 1253000,
    token_index: 3181,
    start_char: 17423,
    end_char: 17426,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1253000,
    end_time: 1253100,
    token_index: 3182,
    start_char: 17427,
    end_char: 17430,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1253100,
    end_time: 1253300,
    token_index: 3183,
    start_char: 17431,
    end_char: 17435,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1253300,
    end_time: 1253400,
    token_index: 3184,
    start_char: 17436,
    end_char: 17439,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1253400,
    end_time: 1253600,
    token_index: 3185,
    start_char: 17440,
    end_char: 17444,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "conversation",
    start_time: 1253600,
    end_time: 1254400,
    token_index: 3186,
    start_char: 17445,
    end_char: 17457,
    display_word: "conversation",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1254500,
    end_time: 1254600,
    token_index: 3187,
    start_char: 17458,
    end_char: 17461,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1254600,
    end_time: 1254700,
    token_index: 3188,
    start_char: 17462,
    end_char: 17466,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1254700,
    end_time: 1254900,
    token_index: 3189,
    start_char: 17467,
    end_char: 17471,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "BL",
    start_time: 1255000,
    end_time: 1255200,
    token_index: 3190,
    start_char: 17472,
    end_char: 17474,
    display_word: "BL",
    period_at_end: false
    },
    {
    word: "spring",
    start_time: 1255200,
    end_time: 1255500,
    token_index: 3191,
    start_char: 17475,
    end_char: 17481,
    display_word: "spring",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1255500,
    end_time: 1255600,
    token_index: 3192,
    start_char: 17482,
    end_char: 17484,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1255600,
    end_time: 1255800,
    token_index: 3193,
    start_char: 17485,
    end_char: 17487,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "her.",
    start_time: 1255800,
    end_time: 1256100,
    token_index: 3194,
    start_char: 17488,
    end_char: 17491,
    display_word: "her",
    period_at_end: true
    },
    {
    word: "Yeah.",
    start_time: 1257000,
    end_time: 1257400,
    token_index: 3195,
    start_char: 17493,
    end_char: 17497,
    display_word: "Yeah",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 1257500,
    end_time: 1257600,
    token_index: 3196,
    start_char: 17499,
    end_char: 17504,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "you've",
    start_time: 1257600,
    end_time: 1257800,
    token_index: 3197,
    start_char: 17505,
    end_char: 17511,
    display_word: "you've",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1257800,
    end_time: 1257900,
    token_index: 3198,
    start_char: 17512,
    end_char: 17516,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "having",
    start_time: 1257900,
    end_time: 1258200,
    token_index: 3199,
    start_char: 17517,
    end_char: 17523,
    display_word: "having",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1258200,
    end_time: 1258400,
    token_index: 3200,
    start_char: 17524,
    end_char: 17528,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "conversation",
    start_time: 1258400,
    end_time: 1259300,
    token_index: 3201,
    start_char: 17529,
    end_char: 17541,
    display_word: "conversation",
    period_at_end: false
    },
    {
    word: "publicly",
    start_time: 1259900,
    end_time: 1260600,
    token_index: 3202,
    start_char: 17542,
    end_char: 17550,
    display_word: "publicly",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1261100,
    end_time: 1261400,
    token_index: 3203,
    start_char: 17551,
    end_char: 17553,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "CNN",
    start_time: 1261400,
    end_time: 1261900,
    token_index: 3204,
    start_char: 17554,
    end_char: 17557,
    display_word: "CNN",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1261900,
    end_time: 1262000,
    token_index: 3205,
    start_char: 17558,
    end_char: 17562,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1262000,
    end_time: 1262100,
    token_index: 3206,
    start_char: 17563,
    end_char: 17567,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1262100,
    end_time: 1262300,
    token_index: 3207,
    start_char: 17568,
    end_char: 17572,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 1262300,
    end_time: 1262700,
    token_index: 3208,
    start_char: 17573,
    end_char: 17579,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "shades",
    start_time: 1262700,
    end_time: 1262900,
    token_index: 3209,
    start_char: 17580,
    end_char: 17586,
    display_word: "shades",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1262900,
    end_time: 1263000,
    token_index: 3210,
    start_char: 17587,
    end_char: 17589,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "America",
    start_time: 1263000,
    end_time: 1263500,
    token_index: 3211,
    start_char: 17590,
    end_char: 17597,
    display_word: "America",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 1263500,
    end_time: 1263700,
    token_index: 3212,
    start_char: 17598,
    end_char: 17601,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1263700,
    end_time: 1263900,
    token_index: 3213,
    start_char: 17602,
    end_char: 17607,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 1263900,
    end_time: 1264000,
    token_index: 3214,
    start_char: 17608,
    end_char: 17612,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "its",
    start_time: 1264000,
    end_time: 1264200,
    token_index: 3215,
    start_char: 17613,
    end_char: 17616,
    display_word: "its",
    period_at_end: false
    },
    {
    word: "fifth",
    start_time: 1264200,
    end_time: 1264500,
    token_index: 3216,
    start_char: 17617,
    end_char: 17622,
    display_word: "fifth",
    period_at_end: false
    },
    {
    word: "season",
    start_time: 1264500,
    end_time: 1265200,
    token_index: 3217,
    start_char: 17623,
    end_char: 17629,
    display_word: "season",
    period_at_end: false
    },
    {
    word: "four",
    start_time: 1265800,
    end_time: 1266100,
    token_index: 3218,
    start_char: 17630,
    end_char: 17634,
    display_word: "four",
    period_at_end: false
    },
    {
    word: "years",
    start_time: 1266100,
    end_time: 1266500,
    token_index: 3219,
    start_char: 17635,
    end_char: 17640,
    display_word: "years",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 1266500,
    end_time: 1267000,
    token_index: 3220,
    start_char: 17641,
    end_char: 17644,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 1267600,
    end_time: 1267900,
    token_index: 3221,
    start_char: 17646,
    end_char: 17650,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "celebrated",
    start_time: 1267900,
    end_time: 1268800,
    token_index: 3222,
    start_char: 17651,
    end_char: 17661,
    display_word: "celebrated",
    period_at_end: false
    },
    {
    word: "emmy-winning.",
    start_time: 1268800,
    end_time: 1269700,
    token_index: 3223,
    start_char: 17662,
    end_char: 17674,
    display_word: "emmy-winning",
    period_at_end: true
    },
    {
    word: "How",
    start_time: 1270600,
    end_time: 1271000,
    token_index: 3224,
    start_char: 17676,
    end_char: 17679,
    display_word: "How",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1271000,
    end_time: 1271100,
    token_index: 3225,
    start_char: 17680,
    end_char: 17684,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1271100,
    end_time: 1271300,
    token_index: 3226,
    start_char: 17685,
    end_char: 17688,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1271300,
    end_time: 1271500,
    token_index: 3227,
    start_char: 17689,
    end_char: 17691,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "prescient",
    start_time: 1271500,
    end_time: 1272100,
    token_index: 3228,
    start_char: 17692,
    end_char: 17701,
    display_word: "prescient",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1272100,
    end_time: 1272200,
    token_index: 3229,
    start_char: 17702,
    end_char: 17704,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1272200,
    end_time: 1272500,
    token_index: 3230,
    start_char: 17705,
    end_char: 17709,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1272500,
    end_time: 1272700,
    token_index: 3231,
    start_char: 17710,
    end_char: 17714,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1272700,
    end_time: 1272800,
    token_index: 3232,
    start_char: 17715,
    end_char: 17718,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 1272800,
    end_time: 1273100,
    token_index: 3233,
    start_char: 17719,
    end_char: 17725,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "States",
    start_time: 1273100,
    end_time: 1273500,
    token_index: 3234,
    start_char: 17726,
    end_char: 17732,
    display_word: "States",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1273500,
    end_time: 1273600,
    token_index: 3235,
    start_char: 17733,
    end_char: 17738,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "eventually",
    start_time: 1273600,
    end_time: 1274100,
    token_index: 3236,
    start_char: 17739,
    end_char: 17749,
    display_word: "eventually",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1274100,
    end_time: 1274200,
    token_index: 3237,
    start_char: 17750,
    end_char: 17754,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "racial",
    start_time: 1274200,
    end_time: 1274500,
    token_index: 3238,
    start_char: 17755,
    end_char: 17761,
    display_word: "racial",
    period_at_end: false
    },
    {
    word: "problems.",
    start_time: 1274500,
    end_time: 1275100,
    token_index: 3239,
    start_char: 17762,
    end_char: 17770,
    display_word: "problems",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 1277900,
    end_time: 1278200,
    token_index: 3240,
    start_char: 17772,
    end_char: 17775,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1278500,
    end_time: 1278600,
    token_index: 3241,
    start_char: 17776,
    end_char: 17777,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1278600,
    end_time: 1278700,
    token_index: 3242,
    start_char: 17778,
    end_char: 17782,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "these",
    start_time: 1278700,
    end_time: 1278900,
    token_index: 3243,
    start_char: 17783,
    end_char: 17788,
    display_word: "these",
    period_at_end: false
    },
    {
    word: "books",
    start_time: 1278900,
    end_time: 1279200,
    token_index: 3244,
    start_char: 17789,
    end_char: 17794,
    display_word: "books",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1279200,
    end_time: 1279200,
    token_index: 3245,
    start_char: 17795,
    end_char: 17797,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1279200,
    end_time: 1279400,
    token_index: 3246,
    start_char: 17798,
    end_char: 17800,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "house",
    start_time: 1279400,
    end_time: 1279700,
    token_index: 3247,
    start_char: 17801,
    end_char: 17806,
    display_word: "house",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1279700,
    end_time: 1279800,
    token_index: 3248,
    start_char: 17807,
    end_char: 17811,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1279800,
    end_time: 1280000,
    token_index: 3249,
    start_char: 17812,
    end_char: 17816,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1280000,
    end_time: 1280200,
    token_index: 3250,
    start_char: 17817,
    end_char: 17820,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1280300,
    end_time: 1280400,
    token_index: 3251,
    start_char: 17821,
    end_char: 17825,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1280400,
    end_time: 1280600,
    token_index: 3252,
    start_char: 17826,
    end_char: 17830,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1280600,
    end_time: 1280600,
    token_index: 3253,
    start_char: 17831,
    end_char: 17834,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "word",
    start_time: 1280600,
    end_time: 1280900,
    token_index: 3254,
    start_char: 17835,
    end_char: 17839,
    display_word: "word",
    period_at_end: false
    },
    {
    word: "history",
    start_time: 1280900,
    end_time: 1281300,
    token_index: 3255,
    start_char: 17840,
    end_char: 17847,
    display_word: "history",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1281300,
    end_time: 1281400,
    token_index: 3256,
    start_char: 17848,
    end_char: 17850,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "them.",
    start_time: 1281400,
    end_time: 1281800,
    token_index: 3257,
    start_char: 17851,
    end_char: 17855,
    display_word: "them",
    period_at_end: true
    },
    {
    word: "How",
    start_time: 1282000,
    end_time: 1282100,
    token_index: 3258,
    start_char: 17857,
    end_char: 17860,
    display_word: "How",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 1282100,
    end_time: 1282300,
    token_index: 3259,
    start_char: 17861,
    end_char: 17864,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1282300,
    end_time: 1282400,
    token_index: 3260,
    start_char: 17865,
    end_char: 17866,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "certainly",
    start_time: 1282400,
    end_time: 1282600,
    token_index: 3261,
    start_char: 17867,
    end_char: 17876,
    display_word: "certainly",
    period_at_end: false
    },
    {
    word: "read",
    start_time: 1282600,
    end_time: 1282900,
    token_index: 3262,
    start_char: 17877,
    end_char: 17881,
    display_word: "read",
    period_at_end: false
    },
    {
    word: "them?",
    start_time: 1282900,
    end_time: 1283200,
    token_index: 3263,
    start_char: 17882,
    end_char: 17887,
    display_word: "them?",
    period_at_end: false
    },
    {
    word: "All",
    start_time: 1283900,
    end_time: 1284000,
    token_index: 3264,
    start_char: 17888,
    end_char: 17891,
    display_word: "All",
    period_at_end: false
    },
    {
    word: "right.",
    start_time: 1284000,
    end_time: 1284500,
    token_index: 3265,
    start_char: 17892,
    end_char: 17897,
    display_word: "right",
    period_at_end: true
    },
    {
    word: "All",
    start_time: 1284800,
    end_time: 1284900,
    token_index: 3266,
    start_char: 17899,
    end_char: 17902,
    display_word: "All",
    period_at_end: false
    },
    {
    word: "right.",
    start_time: 1284900,
    end_time: 1285000,
    token_index: 3267,
    start_char: 17903,
    end_char: 17908,
    display_word: "right",
    period_at_end: true
    },
    {
    word: "Thank",
    start_time: 1285000,
    end_time: 1285300,
    token_index: 3268,
    start_char: 17910,
    end_char: 17915,
    display_word: "Thank",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1285300,
    end_time: 1285400,
    token_index: 3269,
    start_char: 17916,
    end_char: 17919,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "Malik.",
    start_time: 1285400,
    end_time: 1285700,
    token_index: 3270,
    start_char: 17920,
    end_char: 17925,
    display_word: "Malik",
    period_at_end: true
    },
    {
    word: "Right",
    start_time: 1285700,
    end_time: 1286100,
    token_index: 3271,
    start_char: 17927,
    end_char: 17932,
    display_word: "Right",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1286200,
    end_time: 1286300,
    token_index: 3272,
    start_char: 17933,
    end_char: 17934,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "buy",
    start_time: 1286300,
    end_time: 1286500,
    token_index: 3273,
    start_char: 17935,
    end_char: 17938,
    display_word: "buy",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1286500,
    end_time: 1286700,
    token_index: 3274,
    start_char: 17939,
    end_char: 17942,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "books",
    start_time: 1286700,
    end_time: 1286900,
    token_index: 3275,
    start_char: 17943,
    end_char: 17948,
    display_word: "books",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1286900,
    end_time: 1287200,
    token_index: 3276,
    start_char: 17949,
    end_char: 17953,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1287200,
    end_time: 1287200,
    token_index: 3277,
    start_char: 17954,
    end_char: 17955,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "try",
    start_time: 1287200,
    end_time: 1287400,
    token_index: 3278,
    start_char: 17956,
    end_char: 17959,
    display_word: "try",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1287400,
    end_time: 1287500,
    token_index: 3279,
    start_char: 17960,
    end_char: 17962,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "read",
    start_time: 1287500,
    end_time: 1287700,
    token_index: 3280,
    start_char: 17963,
    end_char: 17967,
    display_word: "read",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1287700,
    end_time: 1287800,
    token_index: 3281,
    start_char: 17968,
    end_char: 17971,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "books",
    start_time: 1287800,
    end_time: 1288400,
    token_index: 3282,
    start_char: 17972,
    end_char: 17977,
    display_word: "books",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1288400,
    end_time: 1288600,
    token_index: 3283,
    start_char: 17978,
    end_char: 17981,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 1288600,
    end_time: 1289100,
    token_index: 3284,
    start_char: 17982,
    end_char: 17986,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "off",
    start_time: 1289100,
    end_time: 1289300,
    token_index: 3285,
    start_char: 17987,
    end_char: 17990,
    display_word: "off",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1289300,
    end_time: 1289400,
    token_index: 3286,
    start_char: 17991,
    end_char: 17994,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 1289400,
    end_time: 1289500,
    token_index: 3287,
    start_char: 17995,
    end_char: 17998,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "like,",
    start_time: 1289500,
    end_time: 1289700,
    token_index: 3288,
    start_char: 17999,
    end_char: 18004,
    display_word: "like,",
    period_at_end: false
    },
    {
    word: "hey,",
    start_time: 1289700,
    end_time: 1290300,
    token_index: 3289,
    start_char: 18005,
    end_char: 18009,
    display_word: "hey,",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1290300,
    end_time: 1290600,
    token_index: 3290,
    start_char: 18010,
    end_char: 18016,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "what's",
    start_time: 1290600,
    end_time: 1290800,
    token_index: 3291,
    start_char: 18017,
    end_char: 18023,
    display_word: "what's",
    period_at_end: false
    },
    {
    word: "happening",
    start_time: 1290800,
    end_time: 1291100,
    token_index: 3292,
    start_char: 18024,
    end_char: 18033,
    display_word: "happening",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1291100,
    end_time: 1291400,
    token_index: 3293,
    start_char: 18034,
    end_char: 18039,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 1291400,
    end_time: 1292000,
    token_index: 3294,
    start_char: 18040,
    end_char: 18043,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1292100,
    end_time: 1292700,
    token_index: 3295,
    start_char: 18045,
    end_char: 18047,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "apparently",
    start_time: 1292800,
    end_time: 1293700,
    token_index: 3296,
    start_char: 18048,
    end_char: 18058,
    display_word: "apparently",
    period_at_end: false
    },
    {
    word: "according",
    start_time: 1293700,
    end_time: 1294100,
    token_index: 3297,
    start_char: 18059,
    end_char: 18068,
    display_word: "according",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1294100,
    end_time: 1294100,
    token_index: 3298,
    start_char: 18069,
    end_char: 18071,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "these",
    start_time: 1294100,
    end_time: 1294300,
    token_index: 3299,
    start_char: 18072,
    end_char: 18077,
    display_word: "these",
    period_at_end: false
    },
    {
    word: "history",
    start_time: 1294300,
    end_time: 1294700,
    token_index: 3300,
    start_char: 18078,
    end_char: 18085,
    display_word: "history",
    period_at_end: false
    },
    {
    word: "books.",
    start_time: 1294700,
    end_time: 1294900,
    token_index: 3301,
    start_char: 18086,
    end_char: 18091,
    display_word: "books",
    period_at_end: true
    },
    {
    word: "I've",
    start_time: 1294900,
    end_time: 1295100,
    token_index: 3302,
    start_char: 18093,
    end_char: 18097,
    display_word: "I've",
    period_at_end: false
    },
    {
    word: "read",
    start_time: 1295100,
    end_time: 1295500,
    token_index: 3303,
    start_char: 18098,
    end_char: 18102,
    display_word: "read",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1295900,
    end_time: 1296000,
    token_index: 3304,
    start_char: 18103,
    end_char: 18106,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 1296000,
    end_time: 1296300,
    token_index: 3305,
    start_char: 18107,
    end_char: 18113,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "States",
    start_time: 1296300,
    end_time: 1296600,
    token_index: 3306,
    start_char: 18114,
    end_char: 18120,
    display_word: "States",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1296600,
    end_time: 1296700,
    token_index: 3307,
    start_char: 18121,
    end_char: 18124,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "founded",
    start_time: 1296700,
    end_time: 1297200,
    token_index: 3308,
    start_char: 18125,
    end_char: 18132,
    display_word: "founded",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1297200,
    end_time: 1297400,
    token_index: 3309,
    start_char: 18133,
    end_char: 18135,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1297400,
    end_time: 1297600,
    token_index: 3310,
    start_char: 18136,
    end_char: 18141,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1297600,
    end_time: 1298100,
    token_index: 3311,
    start_char: 18142,
    end_char: 18151,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1298100,
    end_time: 1298200,
    token_index: 3312,
    start_char: 18152,
    end_char: 18155,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "racism.",
    start_time: 1298200,
    end_time: 1298900,
    token_index: 3313,
    start_char: 18156,
    end_char: 18162,
    display_word: "racism",
    period_at_end: true
    },
    {
    word: "Okay,",
    start_time: 1299000,
    end_time: 1299600,
    token_index: 3314,
    start_char: 18164,
    end_char: 18169,
    display_word: "Okay,",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1299900,
    end_time: 1300000,
    token_index: 3315,
    start_char: 18170,
    end_char: 18173,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1300000,
    end_time: 1300100,
    token_index: 3316,
    start_char: 18174,
    end_char: 18177,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "fuel",
    start_time: 1300100,
    end_time: 1300600,
    token_index: 3317,
    start_char: 18178,
    end_char: 18182,
    display_word: "fuel",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1300600,
    end_time: 1300700,
    token_index: 3318,
    start_char: 18183,
    end_char: 18185,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "America",
    start_time: 1300700,
    end_time: 1301200,
    token_index: 3319,
    start_char: 18186,
    end_char: 18193,
    display_word: "America",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1301200,
    end_time: 1301400,
    token_index: 3320,
    start_char: 18194,
    end_char: 18196,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1301400,
    end_time: 1302000,
    token_index: 3321,
    start_char: 18197,
    end_char: 18203,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1302000,
    end_time: 1302100,
    token_index: 3322,
    start_char: 18204,
    end_char: 18207,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1302100,
    end_time: 1302200,
    token_index: 3323,
    start_char: 18208,
    end_char: 18214,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1302200,
    end_time: 1302400,
    token_index: 3324,
    start_char: 18215,
    end_char: 18219,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "keeps",
    start_time: 1302400,
    end_time: 1302600,
    token_index: 3325,
    start_char: 18220,
    end_char: 18225,
    display_word: "keeps",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1302600,
    end_time: 1302800,
    token_index: 3326,
    start_char: 18226,
    end_char: 18231,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1302800,
    end_time: 1303200,
    token_index: 3327,
    start_char: 18232,
    end_char: 18241,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1303200,
    end_time: 1303700,
    token_index: 3328,
    start_char: 18242,
    end_char: 18247,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1303900,
    end_time: 1304100,
    token_index: 3329,
    start_char: 18248,
    end_char: 18251,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "until",
    start_time: 1304100,
    end_time: 1304500,
    token_index: 3330,
    start_char: 18252,
    end_char: 18257,
    display_word: "until",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1304500,
    end_time: 1304700,
    token_index: 3331,
    start_char: 18258,
    end_char: 18260,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "actually",
    start_time: 1304700,
    end_time: 1305300,
    token_index: 3332,
    start_char: 18261,
    end_char: 18269,
    display_word: "actually",
    period_at_end: false
    },
    {
    word: "redesign",
    start_time: 1305400,
    end_time: 1306000,
    token_index: 3333,
    start_char: 18270,
    end_char: 18278,
    display_word: "redesign",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1306000,
    end_time: 1306000,
    token_index: 3334,
    start_char: 18279,
    end_char: 18282,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "whole",
    start_time: 1306000,
    end_time: 1306200,
    token_index: 3335,
    start_char: 18283,
    end_char: 18288,
    display_word: "whole",
    period_at_end: false
    },
    {
    word: "system.",
    start_time: 1306200,
    end_time: 1306900,
    token_index: 3336,
    start_char: 18289,
    end_char: 18295,
    display_word: "system",
    period_at_end: true
    },
    {
    word: "Is",
    start_time: 1307200,
    end_time: 1307300,
    token_index: 3337,
    start_char: 18297,
    end_char: 18299,
    display_word: "Is",
    period_at_end: false
    },
    {
    word: "where",
    start_time: 1307300,
    end_time: 1307500,
    token_index: 3338,
    start_char: 18300,
    end_char: 18305,
    display_word: "where",
    period_at_end: false
    },
    {
    word: "we're",
    start_time: 1307500,
    end_time: 1307600,
    token_index: 3339,
    start_char: 18306,
    end_char: 18311,
    display_word: "we're",
    period_at_end: false
    },
    {
    word: "at.",
    start_time: 1307600,
    end_time: 1308100,
    token_index: 3340,
    start_char: 18312,
    end_char: 18314,
    display_word: "at",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 1308500,
    end_time: 1308800,
    token_index: 3341,
    start_char: 18316,
    end_char: 18321,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "let's",
    start_time: 1308800,
    end_time: 1309000,
    token_index: 3342,
    start_char: 18322,
    end_char: 18327,
    display_word: "let's",
    period_at_end: false
    },
    {
    word: "let",
    start_time: 1309000,
    end_time: 1309200,
    token_index: 3343,
    start_char: 18328,
    end_char: 18331,
    display_word: "let",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1309200,
    end_time: 1309400,
    token_index: 3344,
    start_char: 18332,
    end_char: 18336,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "takes",
    start_time: 1309400,
    end_time: 1309700,
    token_index: 3345,
    start_char: 18337,
    end_char: 18342,
    display_word: "takes",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1309700,
    end_time: 1309800,
    token_index: 3346,
    start_char: 18343,
    end_char: 18345,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1309800,
    end_time: 1309900,
    token_index: 3347,
    start_char: 18346,
    end_char: 18348,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1309900,
    end_time: 1310000,
    token_index: 3348,
    start_char: 18349,
    end_char: 18353,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "first",
    start_time: 1310000,
    end_time: 1310400,
    token_index: 3349,
    start_char: 18354,
    end_char: 18359,
    display_word: "first",
    period_at_end: false
    },
    {
    word: "episode",
    start_time: 1310400,
    end_time: 1311100,
    token_index: 3350,
    start_char: 18360,
    end_char: 18367,
    display_word: "episode",
    period_at_end: false
    },
    {
    word: "The",
    start_time: 1311100,
    end_time: 1311200,
    token_index: 3351,
    start_char: 18368,
    end_char: 18371,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1312100,
    end_time: 1312300,
    token_index: 3352,
    start_char: 18372,
    end_char: 18374,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "Sunday",
    start_time: 1312300,
    end_time: 1312700,
    token_index: 3353,
    start_char: 18375,
    end_char: 18381,
    display_word: "Sunday",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1312700,
    end_time: 1313000,
    token_index: 3354,
    start_char: 18382,
    end_char: 18386,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "Sunday.",
    start_time: 1313000,
    end_time: 1313400,
    token_index: 3355,
    start_char: 18387,
    end_char: 18393,
    display_word: "Sunday",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 1313400,
    end_time: 1313500,
    token_index: 3356,
    start_char: 18395,
    end_char: 18397,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "starts",
    start_time: 1313500,
    end_time: 1314200,
    token_index: 3357,
    start_char: 18398,
    end_char: 18404,
    display_word: "starts",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1314500,
    end_time: 1314700,
    token_index: 3358,
    start_char: 18405,
    end_char: 18408,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "First",
    start_time: 1314700,
    end_time: 1314900,
    token_index: 3359,
    start_char: 18409,
    end_char: 18414,
    display_word: "First",
    period_at_end: false
    },
    {
    word: "episodes",
    start_time: 1314900,
    end_time: 1315400,
    token_index: 3360,
    start_char: 18415,
    end_char: 18423,
    display_word: "episodes",
    period_at_end: false
    },
    {
    word: "title",
    start_time: 1315400,
    end_time: 1315700,
    token_index: 3361,
    start_char: 18424,
    end_char: 18429,
    display_word: "title",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1315700,
    end_time: 1316000,
    token_index: 3362,
    start_char: 18430,
    end_char: 18432,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "where",
    start_time: 1316000,
    end_time: 1316300,
    token_index: 3363,
    start_char: 18433,
    end_char: 18438,
    display_word: "where",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 1316300,
    end_time: 1316400,
    token_index: 3364,
    start_char: 18439,
    end_char: 18441,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1316400,
    end_time: 1316600,
    token_index: 3365,
    start_char: 18442,
    end_char: 18444,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 1316600,
    end_time: 1316900,
    token_index: 3366,
    start_char: 18445,
    end_char: 18449,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "start",
    start_time: 1316900,
    end_time: 1317200,
    token_index: 3367,
    start_char: 18450,
    end_char: 18455,
    display_word: "start",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1317200,
    end_time: 1317400,
    token_index: 3368,
    start_char: 18456,
    end_char: 18460,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1317400,
    end_time: 1317600,
    token_index: 3369,
    start_char: 18461,
    end_char: 18466,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1317600,
    end_time: 1318500,
    token_index: 3370,
    start_char: 18467,
    end_char: 18476,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "W",
    start_time: 1319100,
    end_time: 1319500,
    token_index: 3371,
    start_char: 18477,
    end_char: 18478,
    display_word: "W",
    period_at_end: false
    },
    {
    word: "kamau",
    start_time: 1319500,
    end_time: 1319900,
    token_index: 3372,
    start_char: 18479,
    end_char: 18484,
    display_word: "kamau",
    period_at_end: false
    },
    {
    word: "Bell,",
    start_time: 1319900,
    end_time: 1320200,
    token_index: 3373,
    start_char: 18485,
    end_char: 18490,
    display_word: "Bell,",
    period_at_end: false
    },
    {
    word: "where",
    start_time: 1320200,
    end_time: 1320400,
    token_index: 3374,
    start_char: 18491,
    end_char: 18496,
    display_word: "where",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 1320400,
    end_time: 1320500,
    token_index: 3375,
    start_char: 18497,
    end_char: 18499,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1320500,
    end_time: 1320700,
    token_index: 3376,
    start_char: 18500,
    end_char: 18502,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 1320700,
    end_time: 1321000,
    token_index: 3377,
    start_char: 18503,
    end_char: 18507,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "start",
    start_time: 1321000,
    end_time: 1321300,
    token_index: 3378,
    start_char: 18508,
    end_char: 18513,
    display_word: "start",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1321300,
    end_time: 1321500,
    token_index: 3379,
    start_char: 18514,
    end_char: 18518,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1321500,
    end_time: 1321700,
    token_index: 3380,
    start_char: 18519,
    end_char: 18524,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy?",
    start_time: 1321700,
    end_time: 1322500,
    token_index: 3381,
    start_char: 18525,
    end_char: 18535,
    display_word: "supremacy?",
    period_at_end: false
    },
    {
    word: "First",
    start_time: 1322600,
    end_time: 1322800,
    token_index: 3382,
    start_char: 18536,
    end_char: 18541,
    display_word: "First",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1322800,
    end_time: 1322800,
    token_index: 3383,
    start_char: 18542,
    end_char: 18544,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "all,",
    start_time: 1322800,
    end_time: 1322900,
    token_index: 3384,
    start_char: 18545,
    end_char: 18549,
    display_word: "all,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1322900,
    end_time: 1322900,
    token_index: 3385,
    start_char: 18550,
    end_char: 18551,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1322900,
    end_time: 1323100,
    token_index: 3386,
    start_char: 18552,
    end_char: 18556,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1323100,
    end_time: 1323300,
    token_index: 3387,
    start_char: 18557,
    end_char: 18561,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "title",
    start_time: 1323300,
    end_time: 1323500,
    token_index: 3388,
    start_char: 18562,
    end_char: 18567,
    display_word: "title",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1323500,
    end_time: 1323700,
    token_index: 3389,
    start_char: 18568,
    end_char: 18575,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1323700,
    end_time: 1323800,
    token_index: 3390,
    start_char: 18576,
    end_char: 18578,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "sounds",
    start_time: 1323800,
    end_time: 1324100,
    token_index: 3391,
    start_char: 18579,
    end_char: 18585,
    display_word: "sounds",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1324100,
    end_time: 1324600,
    token_index: 3392,
    start_char: 18586,
    end_char: 18590,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1324600,
    end_time: 1324800,
    token_index: 3393,
    start_char: 18591,
    end_char: 18595,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1324800,
    end_time: 1324900,
    token_index: 3394,
    start_char: 18596,
    end_char: 18597,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "B-side",
    start_time: 1324900,
    end_time: 1325400,
    token_index: 3395,
    start_char: 18598,
    end_char: 18604,
    display_word: "B-side",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1325400,
    end_time: 1325600,
    token_index: 3396,
    start_char: 18605,
    end_char: 18609,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "Hamilton",
    start_time: 1325600,
    end_time: 1326000,
    token_index: 3397,
    start_char: 18610,
    end_char: 18618,
    display_word: "Hamilton",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1326000,
    end_time: 1326000,
    token_index: 3398,
    start_char: 18619,
    end_char: 18621,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "something.",
    start_time: 1326000,
    end_time: 1326500,
    token_index: 3399,
    start_char: 18622,
    end_char: 18631,
    display_word: "something",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 1326500,
    end_time: 1326600,
    token_index: 3400,
    start_char: 18633,
    end_char: 18636,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "yeah,",
    start_time: 1326600,
    end_time: 1326700,
    token_index: 3401,
    start_char: 18637,
    end_char: 18642,
    display_word: "yeah,",
    period_at_end: false
    },
    {
    word: "sure.",
    start_time: 1328300,
    end_time: 1328600,
    token_index: 3402,
    start_char: 18643,
    end_char: 18647,
    display_word: "sure",
    period_at_end: true
    },
    {
    word: "Sure.",
    start_time: 1328600,
    end_time: 1329100,
    token_index: 3403,
    start_char: 18649,
    end_char: 18653,
    display_word: "Sure",
    period_at_end: true
    },
    {
    word: "Yeah.",
    start_time: 1329400,
    end_time: 1330100,
    token_index: 3404,
    start_char: 18655,
    end_char: 18659,
    display_word: "Yeah",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 1330600,
    end_time: 1330800,
    token_index: 3405,
    start_char: 18661,
    end_char: 18666,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1330800,
    end_time: 1330900,
    token_index: 3406,
    start_char: 18667,
    end_char: 18669,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "start",
    start_time: 1330900,
    end_time: 1331200,
    token_index: 3407,
    start_char: 18670,
    end_char: 18675,
    display_word: "start",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1331200,
    end_time: 1331600,
    token_index: 3408,
    start_char: 18676,
    end_char: 18680,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "accepting",
    start_time: 1331700,
    end_time: 1332300,
    token_index: 3409,
    start_char: 18681,
    end_char: 18690,
    display_word: "accepting",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1332300,
    end_time: 1332400,
    token_index: 3410,
    start_char: 18691,
    end_char: 18694,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "fact",
    start_time: 1332400,
    end_time: 1332700,
    token_index: 3411,
    start_char: 18695,
    end_char: 18699,
    display_word: "fact",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1332700,
    end_time: 1332900,
    token_index: 3412,
    start_char: 18700,
    end_char: 18704,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1332900,
    end_time: 1333100,
    token_index: 3413,
    start_char: 18705,
    end_char: 18707,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1333100,
    end_time: 1333100,
    token_index: 3414,
    start_char: 18708,
    end_char: 18709,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1333100,
    end_time: 1333300,
    token_index: 3415,
    start_char: 18710,
    end_char: 18714,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "said",
    start_time: 1333300,
    end_time: 1333700,
    token_index: 3416,
    start_char: 18715,
    end_char: 18719,
    display_word: "said",
    period_at_end: false
    },
    {
    word: "America",
    start_time: 1333700,
    end_time: 1334400,
    token_index: 3417,
    start_char: 18720,
    end_char: 18727,
    display_word: "America",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1334400,
    end_time: 1334700,
    token_index: 3418,
    start_char: 18728,
    end_char: 18730,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "built",
    start_time: 1334700,
    end_time: 1335000,
    token_index: 3419,
    start_char: 18731,
    end_char: 18736,
    display_word: "built",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1335000,
    end_time: 1335200,
    token_index: 3420,
    start_char: 18737,
    end_char: 18739,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1335200,
    end_time: 1335300,
    token_index: 3421,
    start_char: 18740,
    end_char: 18745,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1335300,
    end_time: 1336000,
    token_index: 3422,
    start_char: 18746,
    end_char: 18755,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1336000,
    end_time: 1336200,
    token_index: 3423,
    start_char: 18756,
    end_char: 18760,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1336200,
    end_time: 1336300,
    token_index: 3424,
    start_char: 18761,
    end_char: 18764,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "whole",
    start_time: 1336300,
    end_time: 1336600,
    token_index: 3425,
    start_char: 18765,
    end_char: 18770,
    display_word: "whole",
    period_at_end: false
    },
    {
    word: "history.",
    start_time: 1336600,
    end_time: 1336900,
    token_index: 3426,
    start_char: 18771,
    end_char: 18778,
    display_word: "history",
    period_at_end: true
    },
    {
    word: "This",
    start_time: 1337100,
    end_time: 1337200,
    token_index: 3427,
    start_char: 18780,
    end_char: 18784,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "country",
    start_time: 1337200,
    end_time: 1337600,
    token_index: 3428,
    start_char: 18785,
    end_char: 18792,
    display_word: "country",
    period_at_end: false
    },
    {
    word: "started",
    start_time: 1337600,
    end_time: 1338100,
    token_index: 3429,
    start_char: 18793,
    end_char: 18800,
    display_word: "started",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1338100,
    end_time: 1338500,
    token_index: 3430,
    start_char: 18801,
    end_char: 18803,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "genocide",
    start_time: 1338600,
    end_time: 1339600,
    token_index: 3431,
    start_char: 18804,
    end_char: 18812,
    display_word: "genocide",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1339700,
    end_time: 1340200,
    token_index: 3432,
    start_char: 18813,
    end_char: 18816,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "slavery",
    start_time: 1340200,
    end_time: 1341000,
    token_index: 3433,
    start_char: 18817,
    end_char: 18824,
    display_word: "slavery",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1341200,
    end_time: 1341500,
    token_index: 3434,
    start_char: 18825,
    end_char: 18828,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1341500,
    end_time: 1341600,
    token_index: 3435,
    start_char: 18829,
    end_char: 18833,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1341600,
    end_time: 1342000,
    token_index: 3436,
    start_char: 18834,
    end_char: 18838,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "everything",
    start_time: 1342000,
    end_time: 1342300,
    token_index: 3437,
    start_char: 18839,
    end_char: 18849,
    display_word: "everything",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1342300,
    end_time: 1342500,
    token_index: 3438,
    start_char: 18850,
    end_char: 18856,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "built",
    start_time: 1342500,
    end_time: 1342900,
    token_index: 3439,
    start_char: 18857,
    end_char: 18862,
    display_word: "built",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 1342900,
    end_time: 1343000,
    token_index: 3440,
    start_char: 18863,
    end_char: 18866,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1343000,
    end_time: 1343100,
    token_index: 3441,
    start_char: 18867,
    end_char: 18871,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "built",
    start_time: 1343100,
    end_time: 1343400,
    token_index: 3442,
    start_char: 18872,
    end_char: 18877,
    display_word: "built",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1343400,
    end_time: 1343500,
    token_index: 3443,
    start_char: 18878,
    end_char: 18880,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "top",
    start_time: 1343500,
    end_time: 1343700,
    token_index: 3444,
    start_char: 18881,
    end_char: 18884,
    display_word: "top",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1343700,
    end_time: 1343800,
    token_index: 3445,
    start_char: 18885,
    end_char: 18887,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1343800,
    end_time: 1344000,
    token_index: 3446,
    start_char: 18888,
    end_char: 18892,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1344000,
    end_time: 1344200,
    token_index: 3447,
    start_char: 18893,
    end_char: 18896,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1344200,
    end_time: 1344600,
    token_index: 3448,
    start_char: 18897,
    end_char: 18901,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "built",
    start_time: 1344600,
    end_time: 1344900,
    token_index: 3449,
    start_char: 18902,
    end_char: 18907,
    display_word: "built",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1344900,
    end_time: 1345100,
    token_index: 3450,
    start_char: 18908,
    end_char: 18910,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "ways",
    start_time: 1345100,
    end_time: 1345400,
    token_index: 3451,
    start_char: 18911,
    end_char: 18915,
    display_word: "ways",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1345400,
    end_time: 1345500,
    token_index: 3452,
    start_char: 18916,
    end_char: 18918,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "make",
    start_time: 1345500,
    end_time: 1345600,
    token_index: 3453,
    start_char: 18919,
    end_char: 18923,
    display_word: "make",
    period_at_end: false
    },
    {
    word: "sure",
    start_time: 1345600,
    end_time: 1345800,
    token_index: 3454,
    start_char: 18924,
    end_char: 18928,
    display_word: "sure",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1345800,
    end_time: 1345900,
    token_index: 3455,
    start_char: 18929,
    end_char: 18933,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1345900,
    end_time: 1345900,
    token_index: 3456,
    start_char: 18934,
    end_char: 18937,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1345900,
    end_time: 1346200,
    token_index: 3457,
    start_char: 18938,
    end_char: 18944,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 1346200,
    end_time: 1346600,
    token_index: 3458,
    start_char: 18945,
    end_char: 18948,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "created",
    start_time: 1346800,
    end_time: 1347200,
    token_index: 3459,
    start_char: 18949,
    end_char: 18956,
    display_word: "created",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1347200,
    end_time: 1347300,
    token_index: 3460,
    start_char: 18957,
    end_char: 18960,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "genocide",
    start_time: 1347300,
    end_time: 1347700,
    token_index: 3461,
    start_char: 18961,
    end_char: 18969,
    display_word: "genocide",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1347700,
    end_time: 1347800,
    token_index: 3462,
    start_char: 18970,
    end_char: 18973,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "slavery",
    start_time: 1347800,
    end_time: 1348200,
    token_index: 3463,
    start_char: 18974,
    end_char: 18981,
    display_word: "slavery",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1348200,
    end_time: 1348500,
    token_index: 3464,
    start_char: 18982,
    end_char: 18986,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "them",
    start_time: 1348500,
    end_time: 1348700,
    token_index: 3465,
    start_char: 18987,
    end_char: 18991,
    display_word: "them",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1348700,
    end_time: 1348800,
    token_index: 3466,
    start_char: 18992,
    end_char: 18995,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1348800,
    end_time: 1348900,
    token_index: 3467,
    start_char: 18996,
    end_char: 18999,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 1348900,
    end_time: 1349000,
    token_index: 3468,
    start_char: 19000,
    end_char: 19005,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "descendants",
    start_time: 1349000,
    end_time: 1349700,
    token_index: 3469,
    start_char: 19006,
    end_char: 19017,
    display_word: "descendants",
    period_at_end: false
    },
    {
    word: "could",
    start_time: 1349700,
    end_time: 1350000,
    token_index: 3470,
    start_char: 19018,
    end_char: 19023,
    display_word: "could",
    period_at_end: false
    },
    {
    word: "benefit",
    start_time: 1350000,
    end_time: 1350400,
    token_index: 3471,
    start_char: 19024,
    end_char: 19031,
    display_word: "benefit",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1350400,
    end_time: 1350700,
    token_index: 3472,
    start_char: 19032,
    end_char: 19036,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "it.",
    start_time: 1350700,
    end_time: 1350900,
    token_index: 3473,
    start_char: 19037,
    end_char: 19039,
    display_word: "it",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1351100,
    end_time: 1351500,
    token_index: 3474,
    start_char: 19041,
    end_char: 19043,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1351500,
    end_time: 1351800,
    token_index: 3475,
    start_char: 19044,
    end_char: 19048,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1351800,
    end_time: 1352100,
    token_index: 3476,
    start_char: 19049,
    end_char: 19055,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1352300,
    end_time: 1352500,
    token_index: 3477,
    start_char: 19056,
    end_char: 19060,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1352500,
    end_time: 1352900,
    token_index: 3478,
    start_char: 19061,
    end_char: 19067,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1352900,
    end_time: 1353000,
    token_index: 3479,
    start_char: 19068,
    end_char: 19070,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1353000,
    end_time: 1353100,
    token_index: 3480,
    start_char: 19071,
    end_char: 19074,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "first",
    start_time: 1353100,
    end_time: 1353300,
    token_index: 3481,
    start_char: 19075,
    end_char: 19080,
    display_word: "first",
    period_at_end: false
    },
    {
    word: "few",
    start_time: 1353300,
    end_time: 1353500,
    token_index: 3482,
    start_char: 19081,
    end_char: 19084,
    display_word: "few",
    period_at_end: false
    },
    {
    word: "minutes",
    start_time: 1353500,
    end_time: 1353700,
    token_index: 3483,
    start_char: 19085,
    end_char: 19092,
    display_word: "minutes",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1353700,
    end_time: 1353800,
    token_index: 3484,
    start_char: 19093,
    end_char: 19095,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1353800,
    end_time: 1353900,
    token_index: 3485,
    start_char: 19096,
    end_char: 19099,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "show.",
    start_time: 1353900,
    end_time: 1354100,
    token_index: 3486,
    start_char: 19100,
    end_char: 19104,
    display_word: "show",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 1354100,
    end_time: 1354300,
    token_index: 3487,
    start_char: 19106,
    end_char: 19108,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 1354300,
    end_time: 1354700,
    token_index: 3488,
    start_char: 19109,
    end_char: 19112,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "look",
    start_time: 1354900,
    end_time: 1355100,
    token_index: 3489,
    start_char: 19113,
    end_char: 19117,
    display_word: "look",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 1355100,
    end_time: 1355400,
    token_index: 3490,
    start_char: 19118,
    end_char: 19123,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 1355400,
    end_time: 1355500,
    token_index: 3491,
    start_char: 19124,
    end_char: 19127,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "confused",
    start_time: 1355500,
    end_time: 1356000,
    token_index: 3492,
    start_char: 19128,
    end_char: 19136,
    display_word: "confused",
    period_at_end: false
    },
    {
    word: "between",
    start_time: 1356000,
    end_time: 1356300,
    token_index: 3493,
    start_char: 19137,
    end_char: 19144,
    display_word: "between",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1356300,
    end_time: 1356500,
    token_index: 3494,
    start_char: 19145,
    end_char: 19150,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacist",
    start_time: 1356500,
    end_time: 1357600,
    token_index: 3495,
    start_char: 19151,
    end_char: 19162,
    display_word: "supremacist",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1357600,
    end_time: 1357700,
    token_index: 3496,
    start_char: 19163,
    end_char: 19166,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1357700,
    end_time: 1357900,
    token_index: 3497,
    start_char: 19167,
    end_char: 19172,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy,",
    start_time: 1357900,
    end_time: 1358700,
    token_index: 3498,
    start_char: 19173,
    end_char: 19183,
    display_word: "supremacy,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1359000,
    end_time: 1359100,
    token_index: 3499,
    start_char: 19184,
    end_char: 19187,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know,",
    start_time: 1359100,
    end_time: 1359500,
    token_index: 3500,
    start_char: 19188,
    end_char: 19193,
    display_word: "know,",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1359600,
    end_time: 1359700,
    token_index: 3501,
    start_char: 19194,
    end_char: 19195,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1359700,
    end_time: 1359900,
    token_index: 3502,
    start_char: 19196,
    end_char: 19201,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacist",
    start_time: 1359900,
    end_time: 1360500,
    token_index: 3503,
    start_char: 19202,
    end_char: 19213,
    display_word: "supremacist",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1360500,
    end_time: 1360700,
    token_index: 3504,
    start_char: 19214,
    end_char: 19216,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1360700,
    end_time: 1360800,
    token_index: 3505,
    start_char: 19217,
    end_char: 19220,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "clan",
    start_time: 1360800,
    end_time: 1361100,
    token_index: 3506,
    start_char: 19221,
    end_char: 19225,
    display_word: "clan",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1361100,
    end_time: 1361300,
    token_index: 3507,
    start_char: 19226,
    end_char: 19228,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "Neo-Nazi",
    start_time: 1361300,
    end_time: 1362100,
    token_index: 3508,
    start_char: 19229,
    end_char: 19237,
    display_word: "Neo-Nazi",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1362300,
    end_time: 1362400,
    token_index: 3509,
    start_char: 19238,
    end_char: 19241,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1362400,
    end_time: 1362600,
    token_index: 3510,
    start_char: 19242,
    end_char: 19247,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1362600,
    end_time: 1363300,
    token_index: 3511,
    start_char: 19248,
    end_char: 19257,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1363300,
    end_time: 1363400,
    token_index: 3512,
    start_char: 19258,
    end_char: 19260,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1363400,
    end_time: 1363500,
    token_index: 3513,
    start_char: 19261,
    end_char: 19264,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "fact",
    start_time: 1363500,
    end_time: 1363700,
    token_index: 3514,
    start_char: 19265,
    end_char: 19269,
    display_word: "fact",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1363700,
    end_time: 1364000,
    token_index: 3515,
    start_char: 19270,
    end_char: 19274,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "44",
    start_time: 1364000,
    end_time: 1364700,
    token_index: 3516,
    start_char: 19275,
    end_char: 19277,
    display_word: "44",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 1364700,
    end_time: 1364800,
    token_index: 3517,
    start_char: 19278,
    end_char: 19281,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1364800,
    end_time: 1365000,
    token_index: 3518,
    start_char: 19282,
    end_char: 19284,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "45",
    start_time: 1365000,
    end_time: 1365600,
    token_index: 3519,
    start_char: 19285,
    end_char: 19287,
    display_word: "45",
    period_at_end: false
    },
    {
    word: "presidents",
    start_time: 1365600,
    end_time: 1366100,
    token_index: 3520,
    start_char: 19288,
    end_char: 19298,
    display_word: "presidents",
    period_at_end: false
    },
    {
    word: "presidents",
    start_time: 1366200,
    end_time: 1366800,
    token_index: 3521,
    start_char: 19299,
    end_char: 19309,
    display_word: "presidents",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1366900,
    end_time: 1367000,
    token_index: 3522,
    start_char: 19310,
    end_char: 19314,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1367000,
    end_time: 1367000,
    token_index: 3523,
    start_char: 19315,
    end_char: 19319,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "Wait",
    start_time: 1367200,
    end_time: 1367400,
    token_index: 3524,
    start_char: 19320,
    end_char: 19324,
    display_word: "Wait",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1367400,
    end_time: 1367400,
    token_index: 3525,
    start_char: 19325,
    end_char: 19326,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "minute.",
    start_time: 1367400,
    end_time: 1367900,
    token_index: 3526,
    start_char: 19327,
    end_char: 19333,
    display_word: "minute",
    period_at_end: true
    },
    {
    word: "That's",
    start_time: 1368000,
    end_time: 1368300,
    token_index: 3527,
    start_char: 19335,
    end_char: 19341,
    display_word: "That's",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1368300,
    end_time: 1368400,
    token_index: 3528,
    start_char: 19342,
    end_char: 19345,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "system",
    start_time: 1368400,
    end_time: 1368800,
    token_index: 3529,
    start_char: 19346,
    end_char: 19352,
    display_word: "system",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1368800,
    end_time: 1369000,
    token_index: 3530,
    start_char: 19353,
    end_char: 19355,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1369000,
    end_time: 1369200,
    token_index: 3531,
    start_char: 19356,
    end_char: 19361,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy.",
    start_time: 1369200,
    end_time: 1369800,
    token_index: 3532,
    start_char: 19362,
    end_char: 19371,
    display_word: "supremacy",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 1370300,
    end_time: 1370400,
    token_index: 3533,
    start_char: 19373,
    end_char: 19375,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1370400,
    end_time: 1370900,
    token_index: 3534,
    start_char: 19376,
    end_char: 19379,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1371600,
    end_time: 1371700,
    token_index: 3535,
    start_char: 19380,
    end_char: 19381,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "broom",
    start_time: 1371700,
    end_time: 1371900,
    token_index: 3536,
    start_char: 19382,
    end_char: 19387,
    display_word: "broom",
    period_at_end: false
    },
    {
    word: "X",
    start_time: 1371900,
    end_time: 1372100,
    token_index: 3537,
    start_char: 19388,
    end_char: 19389,
    display_word: "X",
    period_at_end: false
    },
    {
    word: "candy",
    start_time: 1372100,
    end_time: 1372500,
    token_index: 3538,
    start_char: 19390,
    end_char: 19395,
    display_word: "candy",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1372500,
    end_time: 1372700,
    token_index: 3539,
    start_char: 19396,
    end_char: 19398,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1372700,
    end_time: 1372800,
    token_index: 3540,
    start_char: 19399,
    end_char: 19402,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1372800,
    end_time: 1373400,
    token_index: 3541,
    start_char: 19403,
    end_char: 19407,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1373400,
    end_time: 1373800,
    token_index: 3542,
    start_char: 19408,
    end_char: 19410,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1374000,
    end_time: 1374000,
    token_index: 3543,
    start_char: 19411,
    end_char: 19412,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "month",
    start_time: 1374000,
    end_time: 1374300,
    token_index: 3544,
    start_char: 19413,
    end_char: 19418,
    display_word: "month",
    period_at_end: false
    },
    {
    word: "ago.",
    start_time: 1374300,
    end_time: 1374800,
    token_index: 3545,
    start_char: 19419,
    end_char: 19422,
    display_word: "ago",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 1374900,
    end_time: 1374900,
    token_index: 3546,
    start_char: 19424,
    end_char: 19427,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "guys",
    start_time: 1374900,
    end_time: 1375100,
    token_index: 3547,
    start_char: 19428,
    end_char: 19432,
    display_word: "guys",
    period_at_end: false
    },
    {
    word: "later",
    start_time: 1375100,
    end_time: 1375500,
    token_index: 3548,
    start_char: 19433,
    end_char: 19438,
    display_word: "later",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1377000,
    end_time: 1377200,
    token_index: 3549,
    start_char: 19439,
    end_char: 19442,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "guys",
    start_time: 1377200,
    end_time: 1377300,
    token_index: 3550,
    start_char: 19443,
    end_char: 19447,
    display_word: "guys",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1377300,
    end_time: 1377500,
    token_index: 3551,
    start_char: 19448,
    end_char: 19452,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1377500,
    end_time: 1377600,
    token_index: 3552,
    start_char: 19453,
    end_char: 19454,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "beef.",
    start_time: 1377600,
    end_time: 1378100,
    token_index: 3553,
    start_char: 19455,
    end_char: 19459,
    display_word: "beef",
    period_at_end: true
    },
    {
    word: "No.",
    start_time: 1378600,
    end_time: 1378800,
    token_index: 3554,
    start_char: 19461,
    end_char: 19463,
    display_word: "No",
    period_at_end: true
    },
    {
    word: "No,",
    start_time: 1378800,
    end_time: 1379200,
    token_index: 3555,
    start_char: 19465,
    end_char: 19468,
    display_word: "No,",
    period_at_end: false
    },
    {
    word: "he's",
    start_time: 1379700,
    end_time: 1379900,
    token_index: 3556,
    start_char: 19469,
    end_char: 19473,
    display_word: "he's",
    period_at_end: false
    },
    {
    word: "smarter",
    start_time: 1379900,
    end_time: 1380200,
    token_index: 3557,
    start_char: 19474,
    end_char: 19481,
    display_word: "smarter",
    period_at_end: false
    },
    {
    word: "than",
    start_time: 1380200,
    end_time: 1380300,
    token_index: 3558,
    start_char: 19482,
    end_char: 19486,
    display_word: "than",
    period_at_end: false
    },
    {
    word: "me.",
    start_time: 1380300,
    end_time: 1380600,
    token_index: 3559,
    start_char: 19487,
    end_char: 19489,
    display_word: "me",
    period_at_end: true
    },
    {
    word: "He",
    start_time: 1381700,
    end_time: 1381900,
    token_index: 3560,
    start_char: 19491,
    end_char: 19493,
    display_word: "He",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1381900,
    end_time: 1382100,
    token_index: 3561,
    start_char: 19494,
    end_char: 19496,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "very",
    start_time: 1382100,
    end_time: 1382300,
    token_index: 3562,
    start_char: 19497,
    end_char: 19501,
    display_word: "very",
    period_at_end: false
    },
    {
    word: "tall",
    start_time: 1382300,
    end_time: 1382500,
    token_index: 3563,
    start_char: 19502,
    end_char: 19506,
    display_word: "tall",
    period_at_end: false
    },
    {
    word: "bars",
    start_time: 1382500,
    end_time: 1383000,
    token_index: 3564,
    start_char: 19507,
    end_char: 19511,
    display_word: "bars",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1383300,
    end_time: 1383500,
    token_index: 3565,
    start_char: 19512,
    end_char: 19515,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 1383500,
    end_time: 1383600,
    token_index: 3566,
    start_char: 19516,
    end_char: 19518,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1383600,
    end_time: 1383700,
    token_index: 3567,
    start_char: 19519,
    end_char: 19522,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "talking",
    start_time: 1383700,
    end_time: 1384000,
    token_index: 3568,
    start_char: 19523,
    end_char: 19530,
    display_word: "talking",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1384000,
    end_time: 1384300,
    token_index: 3569,
    start_char: 19531,
    end_char: 19536,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1384300,
    end_time: 1384500,
    token_index: 3570,
    start_char: 19537,
    end_char: 19540,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1384500,
    end_time: 1384600,
    token_index: 3571,
    start_char: 19541,
    end_char: 19545,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1384600,
    end_time: 1384700,
    token_index: 3572,
    start_char: 19546,
    end_char: 19548,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1384700,
    end_time: 1384800,
    token_index: 3573,
    start_char: 19549,
    end_char: 19552,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "roots",
    start_time: 1384800,
    end_time: 1385000,
    token_index: 3574,
    start_char: 19553,
    end_char: 19558,
    display_word: "roots",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1385000,
    end_time: 1385000,
    token_index: 3575,
    start_char: 19559,
    end_char: 19561,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1385000,
    end_time: 1385200,
    token_index: 3576,
    start_char: 19562,
    end_char: 19567,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1385200,
    end_time: 1386100,
    token_index: 3577,
    start_char: 19568,
    end_char: 19577,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1386200,
    end_time: 1386400,
    token_index: 3578,
    start_char: 19578,
    end_char: 19580,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1386400,
    end_time: 1386600,
    token_index: 3579,
    start_char: 19581,
    end_char: 19584,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "idea",
    start_time: 1386600,
    end_time: 1387200,
    token_index: 3580,
    start_char: 19585,
    end_char: 19589,
    display_word: "idea",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1387200,
    end_time: 1387400,
    token_index: 3581,
    start_char: 19590,
    end_char: 19592,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 1387400,
    end_time: 1387900,
    token_index: 3582,
    start_char: 19593,
    end_char: 19597,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "whiteness",
    start_time: 1388100,
    end_time: 1388600,
    token_index: 3583,
    start_char: 19598,
    end_char: 19607,
    display_word: "whiteness",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1388600,
    end_time: 1388700,
    token_index: 3584,
    start_char: 19608,
    end_char: 19610,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1388700,
    end_time: 1389400,
    token_index: 3585,
    start_char: 19611,
    end_char: 19617,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1389400,
    end_time: 1389600,
    token_index: 3586,
    start_char: 19618,
    end_char: 19620,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1389600,
    end_time: 1389700,
    token_index: 3587,
    start_char: 19621,
    end_char: 19623,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1389700,
    end_time: 1390000,
    token_index: 3588,
    start_char: 19624,
    end_char: 19629,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1390000,
    end_time: 1390100,
    token_index: 3589,
    start_char: 19630,
    end_char: 19632,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1390100,
    end_time: 1390500,
    token_index: 3590,
    start_char: 19633,
    end_char: 19635,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1391000,
    end_time: 1391200,
    token_index: 3591,
    start_char: 19636,
    end_char: 19638,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1391200,
    end_time: 1391200,
    token_index: 3592,
    start_char: 19639,
    end_char: 19640,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "modern",
    start_time: 1391200,
    end_time: 1391600,
    token_index: 3593,
    start_char: 19641,
    end_char: 19647,
    display_word: "modern",
    period_at_end: false
    },
    {
    word: "sense",
    start_time: 1391600,
    end_time: 1391900,
    token_index: 3594,
    start_char: 19648,
    end_char: 19653,
    display_word: "sense",
    period_at_end: false
    },
    {
    word: "started",
    start_time: 1391900,
    end_time: 1392100,
    token_index: 3595,
    start_char: 19654,
    end_char: 19661,
    display_word: "started",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1392100,
    end_time: 1392300,
    token_index: 3596,
    start_char: 19662,
    end_char: 19667,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "five",
    start_time: 1392300,
    end_time: 1392600,
    token_index: 3597,
    start_char: 19668,
    end_char: 19672,
    display_word: "five",
    period_at_end: false
    },
    {
    word: "hundred",
    start_time: 1392600,
    end_time: 1392900,
    token_index: 3598,
    start_char: 19673,
    end_char: 19680,
    display_word: "hundred",
    period_at_end: false
    },
    {
    word: "years",
    start_time: 1392900,
    end_time: 1393100,
    token_index: 3599,
    start_char: 19681,
    end_char: 19686,
    display_word: "years",
    period_at_end: false
    },
    {
    word: "ago,",
    start_time: 1393100,
    end_time: 1393600,
    token_index: 3600,
    start_char: 19687,
    end_char: 19691,
    display_word: "ago,",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1393800,
    end_time: 1393900,
    token_index: 3601,
    start_char: 19692,
    end_char: 19695,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1393900,
    end_time: 1394000,
    token_index: 3602,
    start_char: 19696,
    end_char: 19698,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1394000,
    end_time: 1394200,
    token_index: 3603,
    start_char: 19699,
    end_char: 19702,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1394200,
    end_time: 1394400,
    token_index: 3604,
    start_char: 19703,
    end_char: 19706,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "motivated",
    start_time: 1394400,
    end_time: 1395400,
    token_index: 3605,
    start_char: 19707,
    end_char: 19716,
    display_word: "motivated",
    period_at_end: false
    },
    {
    word: "economically",
    start_time: 1395400,
    end_time: 1396200,
    token_index: 3606,
    start_char: 19717,
    end_char: 19729,
    display_word: "economically",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1396200,
    end_time: 1396300,
    token_index: 3607,
    start_char: 19730,
    end_char: 19732,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1396300,
    end_time: 1397000,
    token_index: 3608,
    start_char: 19733,
    end_char: 19736,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "Rest",
    start_time: 1397100,
    end_time: 1397400,
    token_index: 3609,
    start_char: 19737,
    end_char: 19741,
    display_word: "Rest",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1397400,
    end_time: 1397600,
    token_index: 3610,
    start_char: 19742,
    end_char: 19744,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "order",
    start_time: 1397600,
    end_time: 1398000,
    token_index: 3611,
    start_char: 19745,
    end_char: 19750,
    display_word: "order",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1398000,
    end_time: 1398200,
    token_index: 3612,
    start_char: 19751,
    end_char: 19753,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "set",
    start_time: 1398200,
    end_time: 1398500,
    token_index: 3613,
    start_char: 19754,
    end_char: 19757,
    display_word: "set",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1398500,
    end_time: 1398600,
    token_index: 3614,
    start_char: 19758,
    end_char: 19760,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1398600,
    end_time: 1398700,
    token_index: 3615,
    start_char: 19761,
    end_char: 19764,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Europeans",
    start_time: 1398700,
    end_time: 1399400,
    token_index: 3616,
    start_char: 19765,
    end_char: 19774,
    display_word: "Europeans",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1399400,
    end_time: 1399500,
    token_index: 3617,
    start_char: 19775,
    end_char: 19779,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "taking",
    start_time: 1399500,
    end_time: 1399800,
    token_index: 3618,
    start_char: 19780,
    end_char: 19786,
    display_word: "taking",
    period_at_end: false
    },
    {
    word: "over",
    start_time: 1399800,
    end_time: 1399900,
    token_index: 3619,
    start_char: 19787,
    end_char: 19791,
    display_word: "over",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1399900,
    end_time: 1400100,
    token_index: 3620,
    start_char: 19792,
    end_char: 19796,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "land",
    start_time: 1400100,
    end_time: 1400700,
    token_index: 3621,
    start_char: 19797,
    end_char: 19801,
    display_word: "land",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1400900,
    end_time: 1401700,
    token_index: 3622,
    start_char: 19802,
    end_char: 19805,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "capturing",
    start_time: 1402500,
    end_time: 1403000,
    token_index: 3623,
    start_char: 19806,
    end_char: 19815,
    display_word: "capturing",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1403000,
    end_time: 1403100,
    token_index: 3624,
    start_char: 19816,
    end_char: 19819,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "enslaving",
    start_time: 1403100,
    end_time: 1403500,
    token_index: 3625,
    start_char: 19820,
    end_char: 19829,
    display_word: "enslaving",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1403500,
    end_time: 1403600,
    token_index: 3626,
    start_char: 19830,
    end_char: 19833,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1403600,
    end_time: 1403900,
    token_index: 3627,
    start_char: 19834,
    end_char: 19840,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1403900,
    end_time: 1404000,
    token_index: 3628,
    start_char: 19841,
    end_char: 19843,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "Africa",
    start_time: 1404000,
    end_time: 1404400,
    token_index: 3629,
    start_char: 19844,
    end_char: 19850,
    display_word: "Africa",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1404400,
    end_time: 1404600,
    token_index: 3630,
    start_char: 19851,
    end_char: 19854,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1404600,
    end_time: 1404800,
    token_index: 3631,
    start_char: 19855,
    end_char: 19858,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1404800,
    end_time: 1405000,
    token_index: 3632,
    start_char: 19859,
    end_char: 19861,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1405000,
    end_time: 1405400,
    token_index: 3633,
    start_char: 19862,
    end_char: 19866,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1405400,
    end_time: 1405400,
    token_index: 3634,
    start_char: 19867,
    end_char: 19868,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "rationale",
    start_time: 1405400,
    end_time: 1406200,
    token_index: 3635,
    start_char: 19869,
    end_char: 19878,
    display_word: "rationale",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1406500,
    end_time: 1407100,
    token_index: 3636,
    start_char: 19879,
    end_char: 19885,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1407100,
    end_time: 1407200,
    token_index: 3637,
    start_char: 19886,
    end_char: 19889,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "created",
    start_time: 1407200,
    end_time: 1407700,
    token_index: 3638,
    start_char: 19890,
    end_char: 19897,
    display_word: "created",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1407700,
    end_time: 1407900,
    token_index: 3639,
    start_char: 19898,
    end_char: 19900,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1407900,
    end_time: 1408100,
    token_index: 3640,
    start_char: 19901,
    end_char: 19903,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1408100,
    end_time: 1408300,
    token_index: 3641,
    start_char: 19904,
    end_char: 19908,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1408300,
    end_time: 1408700,
    token_index: 3642,
    start_char: 19909,
    end_char: 19911,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1409000,
    end_time: 1409300,
    token_index: 3643,
    start_char: 19912,
    end_char: 19915,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1409300,
    end_time: 1409600,
    token_index: 3644,
    start_char: 19916,
    end_char: 19920,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "economic",
    start_time: 1409600,
    end_time: 1410200,
    token_index: 3645,
    start_char: 19921,
    end_char: 19929,
    display_word: "economic",
    period_at_end: false
    },
    {
    word: "self-interest.",
    start_time: 1410200,
    end_time: 1411200,
    token_index: 3646,
    start_char: 19930,
    end_char: 19943,
    display_word: "self-interest",
    period_at_end: true
    },
    {
    word: "Yeah",
    start_time: 1412100,
    end_time: 1412600,
    token_index: 3647,
    start_char: 19945,
    end_char: 19949,
    display_word: "Yeah",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 1412600,
    end_time: 1412900,
    token_index: 3648,
    start_char: 19950,
    end_char: 19956,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "that.",
    start_time: 1412900,
    end_time: 1413100,
    token_index: 3649,
    start_char: 19957,
    end_char: 19961,
    display_word: "that",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1413100,
    end_time: 1413100,
    token_index: 3650,
    start_char: 19963,
    end_char: 19964,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1413100,
    end_time: 1413300,
    token_index: 3651,
    start_char: 19965,
    end_char: 19970,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1413300,
    end_time: 1413400,
    token_index: 3652,
    start_char: 19971,
    end_char: 19973,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1413400,
    end_time: 1413500,
    token_index: 3653,
    start_char: 19974,
    end_char: 19977,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1413500,
    end_time: 1413700,
    token_index: 3654,
    start_char: 19978,
    end_char: 19982,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "nationalism",
    start_time: 1413700,
    end_time: 1414200,
    token_index: 3655,
    start_char: 19983,
    end_char: 19994,
    display_word: "nationalism",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1414200,
    end_time: 1414400,
    token_index: 3656,
    start_char: 19995,
    end_char: 19998,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "tribalism,",
    start_time: 1414400,
    end_time: 1415000,
    token_index: 3657,
    start_char: 19999,
    end_char: 20009,
    display_word: "tribalism,",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1415000,
    end_time: 1415200,
    token_index: 3658,
    start_char: 20010,
    end_char: 20014,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "nobody",
    start_time: 1415200,
    end_time: 1415600,
    token_index: 3659,
    start_char: 20015,
    end_char: 20021,
    display_word: "nobody",
    period_at_end: false
    },
    {
    word: "saw",
    start_time: 1415600,
    end_time: 1416200,
    token_index: 3660,
    start_char: 20022,
    end_char: 20025,
    display_word: "saw",
    period_at_end: false
    },
    {
    word: "anybody",
    start_time: 1416500,
    end_time: 1416900,
    token_index: 3661,
    start_char: 20026,
    end_char: 20033,
    display_word: "anybody",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1416900,
    end_time: 1417000,
    token_index: 3662,
    start_char: 20034,
    end_char: 20036,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1417000,
    end_time: 1417100,
    token_index: 3663,
    start_char: 20037,
    end_char: 20041,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "race",
    start_time: 1417100,
    end_time: 1417500,
    token_index: 3664,
    start_char: 20042,
    end_char: 20046,
    display_word: "race",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1417500,
    end_time: 1417600,
    token_index: 3665,
    start_char: 20047,
    end_char: 20049,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1417600,
    end_time: 1418300,
    token_index: 3666,
    start_char: 20050,
    end_char: 20056,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1418600,
    end_time: 1418800,
    token_index: 3667,
    start_char: 20057,
    end_char: 20060,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 1418800,
    end_time: 1418900,
    token_index: 3668,
    start_char: 20061,
    end_char: 20065,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 1418900,
    end_time: 1419000,
    token_index: 3669,
    start_char: 20066,
    end_char: 20070,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1419000,
    end_time: 1419100,
    token_index: 3670,
    start_char: 20071,
    end_char: 20075,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "decided",
    start_time: 1419100,
    end_time: 1419600,
    token_index: 3671,
    start_char: 20076,
    end_char: 20083,
    display_word: "decided",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1419600,
    end_time: 1419700,
    token_index: 3672,
    start_char: 20084,
    end_char: 20087,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "only",
    start_time: 1419700,
    end_time: 1419800,
    token_index: 3673,
    start_char: 20088,
    end_char: 20092,
    display_word: "only",
    period_at_end: false
    },
    {
    word: "way",
    start_time: 1419800,
    end_time: 1419900,
    token_index: 3674,
    start_char: 20093,
    end_char: 20096,
    display_word: "way",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1419900,
    end_time: 1420000,
    token_index: 3675,
    start_char: 20097,
    end_char: 20101,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 1420000,
    end_time: 1420100,
    token_index: 3676,
    start_char: 20102,
    end_char: 20105,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "turn",
    start_time: 1420100,
    end_time: 1420300,
    token_index: 3677,
    start_char: 20106,
    end_char: 20110,
    display_word: "turn",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1420300,
    end_time: 1420400,
    token_index: 3678,
    start_char: 20111,
    end_char: 20113,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "justify",
    start_time: 1420400,
    end_time: 1421200,
    token_index: 3679,
    start_char: 20114,
    end_char: 20121,
    display_word: "justify",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1421200,
    end_time: 1421400,
    token_index: 3680,
    start_char: 20122,
    end_char: 20129,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1421400,
    end_time: 1421500,
    token_index: 3681,
    start_char: 20130,
    end_char: 20134,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1421500,
    end_time: 1421900,
    token_index: 3682,
    start_char: 20135,
    end_char: 20139,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "many",
    start_time: 1421900,
    end_time: 1422100,
    token_index: 3683,
    start_char: 20140,
    end_char: 20144,
    display_word: "many",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1422100,
    end_time: 1422200,
    token_index: 3684,
    start_char: 20145,
    end_char: 20147,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "them",
    start_time: 1422200,
    end_time: 1422300,
    token_index: 3685,
    start_char: 20148,
    end_char: 20152,
    display_word: "them",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1422300,
    end_time: 1422400,
    token_index: 3686,
    start_char: 20153,
    end_char: 20157,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "Christians",
    start_time: 1422400,
    end_time: 1423100,
    token_index: 3687,
    start_char: 20158,
    end_char: 20168,
    display_word: "Christians",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1423300,
    end_time: 1423400,
    token_index: 3688,
    start_char: 20169,
    end_char: 20173,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1423400,
    end_time: 1423600,
    token_index: 3689,
    start_char: 20174,
    end_char: 20178,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1423600,
    end_time: 1423700,
    token_index: 3690,
    start_char: 20179,
    end_char: 20182,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1423700,
    end_time: 1423800,
    token_index: 3691,
    start_char: 20183,
    end_char: 20186,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1423800,
    end_time: 1424000,
    token_index: 3692,
    start_char: 20187,
    end_char: 20192,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "thing",
    start_time: 1424000,
    end_time: 1424200,
    token_index: 3693,
    start_char: 20193,
    end_char: 20198,
    display_word: "thing",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1424200,
    end_time: 1424300,
    token_index: 3694,
    start_char: 20199,
    end_char: 20201,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 1424300,
    end_time: 1424800,
    token_index: 3695,
    start_char: 20202,
    end_char: 20204,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1425000,
    end_time: 1425200,
    token_index: 3696,
    start_char: 20205,
    end_char: 20208,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1425200,
    end_time: 1425300,
    token_index: 3697,
    start_char: 20209,
    end_char: 20211,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "dehumanize",
    start_time: 1425300,
    end_time: 1426200,
    token_index: 3698,
    start_char: 20212,
    end_char: 20222,
    display_word: "dehumanize",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1426200,
    end_time: 1426500,
    token_index: 3699,
    start_char: 20223,
    end_char: 20229,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1426500,
    end_time: 1426600,
    token_index: 3700,
    start_char: 20230,
    end_char: 20233,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 1426600,
    end_time: 1426700,
    token_index: 3701,
    start_char: 20234,
    end_char: 20237,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1426700,
    end_time: 1426900,
    token_index: 3702,
    start_char: 20238,
    end_char: 20242,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1426900,
    end_time: 1427000,
    token_index: 3703,
    start_char: 20243,
    end_char: 20247,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "Averages",
    start_time: 1427200,
    end_time: 1427600,
    token_index: 3704,
    start_char: 20248,
    end_char: 20256,
    display_word: "Averages",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1427600,
    end_time: 1427700,
    token_index: 3705,
    start_char: 20257,
    end_char: 20261,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1427700,
    end_time: 1427800,
    token_index: 3706,
    start_char: 20262,
    end_char: 20265,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Native",
    start_time: 1427800,
    end_time: 1428100,
    token_index: 3707,
    start_char: 20266,
    end_char: 20272,
    display_word: "Native",
    period_at_end: false
    },
    {
    word: "Americans",
    start_time: 1428100,
    end_time: 1428600,
    token_index: 3708,
    start_char: 20273,
    end_char: 20282,
    display_word: "Americans",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1428700,
    end_time: 1428800,
    token_index: 3709,
    start_char: 20283,
    end_char: 20285,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1428800,
    end_time: 1428900,
    token_index: 3710,
    start_char: 20286,
    end_char: 20290,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1428900,
    end_time: 1429100,
    token_index: 3711,
    start_char: 20291,
    end_char: 20295,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "animals",
    start_time: 1429100,
    end_time: 1429600,
    token_index: 3712,
    start_char: 20296,
    end_char: 20303,
    display_word: "animals",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1429600,
    end_time: 1429700,
    token_index: 3713,
    start_char: 20304,
    end_char: 20308,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1429700,
    end_time: 1430000,
    token_index: 3714,
    start_char: 20309,
    end_char: 20314,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "folks.",
    start_time: 1430000,
    end_time: 1430300,
    token_index: 3715,
    start_char: 20315,
    end_char: 20320,
    display_word: "folks",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1430300,
    end_time: 1430500,
    token_index: 3716,
    start_char: 20322,
    end_char: 20324,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1430500,
    end_time: 1430500,
    token_index: 3717,
    start_char: 20325,
    end_char: 20326,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1430500,
    end_time: 1430900,
    token_index: 3718,
    start_char: 20327,
    end_char: 20332,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1430900,
    end_time: 1431100,
    token_index: 3719,
    start_char: 20333,
    end_char: 20337,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1431100,
    end_time: 1431300,
    token_index: 3720,
    start_char: 20338,
    end_char: 20341,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1431300,
    end_time: 1431400,
    token_index: 3721,
    start_char: 20342,
    end_char: 20344,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "come",
    start_time: 1431400,
    end_time: 1431500,
    token_index: 3722,
    start_char: 20345,
    end_char: 20349,
    display_word: "come",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1431500,
    end_time: 1431600,
    token_index: 3723,
    start_char: 20350,
    end_char: 20354,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1431600,
    end_time: 1431700,
    token_index: 3724,
    start_char: 20355,
    end_char: 20356,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "way",
    start_time: 1431700,
    end_time: 1431800,
    token_index: 3725,
    start_char: 20357,
    end_char: 20360,
    display_word: "way",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1431800,
    end_time: 1431900,
    token_index: 3726,
    start_char: 20361,
    end_char: 20363,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 1431900,
    end_time: 1432300,
    token_index: 3727,
    start_char: 20364,
    end_char: 20366,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1432600,
    end_time: 1432800,
    token_index: 3728,
    start_char: 20367,
    end_char: 20370,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "only",
    start_time: 1432800,
    end_time: 1433100,
    token_index: 3729,
    start_char: 20371,
    end_char: 20375,
    display_word: "only",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1433200,
    end_time: 1433300,
    token_index: 3730,
    start_char: 20376,
    end_char: 20378,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1433300,
    end_time: 1433500,
    token_index: 3731,
    start_char: 20379,
    end_char: 20383,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "we're",
    start_time: 1433500,
    end_time: 1433600,
    token_index: 3732,
    start_char: 20384,
    end_char: 20389,
    display_word: "we're",
    period_at_end: false
    },
    {
    word: "doing",
    start_time: 1433600,
    end_time: 1434200,
    token_index: 3733,
    start_char: 20390,
    end_char: 20395,
    display_word: "doing",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1434300,
    end_time: 1434400,
    token_index: 3734,
    start_char: 20396,
    end_char: 20399,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1434400,
    end_time: 1434500,
    token_index: 3735,
    start_char: 20400,
    end_char: 20404,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "actually",
    start_time: 1434500,
    end_time: 1434800,
    token_index: 3736,
    start_char: 20405,
    end_char: 20413,
    display_word: "actually",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1434800,
    end_time: 1434900,
    token_index: 3737,
    start_char: 20414,
    end_char: 20417,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1434900,
    end_time: 1435200,
    token_index: 3738,
    start_char: 20418,
    end_char: 20423,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "thing",
    start_time: 1435200,
    end_time: 1435400,
    token_index: 3739,
    start_char: 20424,
    end_char: 20429,
    display_word: "thing",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1435400,
    end_time: 1435500,
    token_index: 3740,
    start_char: 20430,
    end_char: 20432,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "do.",
    start_time: 1435500,
    end_time: 1435900,
    token_index: 3741,
    start_char: 20433,
    end_char: 20435,
    display_word: "do",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 1436000,
    end_time: 1436400,
    token_index: 3742,
    start_char: 20437,
    end_char: 20442,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1436400,
    end_time: 1436600,
    token_index: 3743,
    start_char: 20443,
    end_char: 20446,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1436600,
    end_time: 1436800,
    token_index: 3744,
    start_char: 20447,
    end_char: 20451,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "any",
    start_time: 1437200,
    end_time: 1437400,
    token_index: 3745,
    start_char: 20452,
    end_char: 20455,
    display_word: "any",
    period_at_end: false
    },
    {
    word: "number",
    start_time: 1437400,
    end_time: 1437800,
    token_index: 3746,
    start_char: 20456,
    end_char: 20462,
    display_word: "number",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1437800,
    end_time: 1437900,
    token_index: 3747,
    start_char: 20463,
    end_char: 20465,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "modern-day",
    start_time: 1438100,
    end_time: 1438600,
    token_index: 3748,
    start_char: 20466,
    end_char: 20476,
    display_word: "modern-day",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1438600,
    end_time: 1438900,
    token_index: 3749,
    start_char: 20477,
    end_char: 20482,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "politicians",
    start_time: 1438900,
    end_time: 1439400,
    token_index: 3750,
    start_char: 20483,
    end_char: 20494,
    display_word: "politicians",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1439400,
    end_time: 1439600,
    token_index: 3751,
    start_char: 20495,
    end_char: 20499,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "said",
    start_time: 1439600,
    end_time: 1439900,
    token_index: 3752,
    start_char: 20500,
    end_char: 20504,
    display_word: "said",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1439900,
    end_time: 1440200,
    token_index: 3753,
    start_char: 20505,
    end_char: 20509,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "well",
    start_time: 1440200,
    end_time: 1440400,
    token_index: 3754,
    start_char: 20510,
    end_char: 20514,
    display_word: "well",
    period_at_end: false
    },
    {
    word: "slavery",
    start_time: 1440400,
    end_time: 1440700,
    token_index: 3755,
    start_char: 20515,
    end_char: 20522,
    display_word: "slavery",
    period_at_end: false
    },
    {
    word: "wasn't",
    start_time: 1440700,
    end_time: 1440900,
    token_index: 3756,
    start_char: 20523,
    end_char: 20529,
    display_word: "wasn't",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1440900,
    end_time: 1441100,
    token_index: 3757,
    start_char: 20530,
    end_char: 20534,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "bad",
    start_time: 1441100,
    end_time: 1441600,
    token_index: 3758,
    start_char: 20535,
    end_char: 20538,
    display_word: "bad",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1441600,
    end_time: 1441800,
    token_index: 3759,
    start_char: 20539,
    end_char: 20544,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1441800,
    end_time: 1442100,
    token_index: 3760,
    start_char: 20545,
    end_char: 20551,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "actually",
    start_time: 1442100,
    end_time: 1442300,
    token_index: 3761,
    start_char: 20552,
    end_char: 20560,
    display_word: "actually",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1442300,
    end_time: 1442600,
    token_index: 3762,
    start_char: 20561,
    end_char: 20564,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "three",
    start_time: 1442600,
    end_time: 1442800,
    token_index: 3763,
    start_char: 20565,
    end_char: 20570,
    display_word: "three",
    period_at_end: false
    },
    {
    word: "meals",
    start_time: 1442800,
    end_time: 1443000,
    token_index: 3764,
    start_char: 20571,
    end_char: 20576,
    display_word: "meals",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1443000,
    end_time: 1443100,
    token_index: 3765,
    start_char: 20577,
    end_char: 20578,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "day",
    start_time: 1443100,
    end_time: 1443300,
    token_index: 3766,
    start_char: 20579,
    end_char: 20582,
    display_word: "day",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1443300,
    end_time: 1443400,
    token_index: 3767,
    start_char: 20583,
    end_char: 20586,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "enjoy",
    start_time: 1443400,
    end_time: 1443800,
    token_index: 3768,
    start_char: 20587,
    end_char: 20592,
    display_word: "enjoy",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1443800,
    end_time: 1444000,
    token_index: 3769,
    start_char: 20593,
    end_char: 20595,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1444000,
    end_time: 1444400,
    token_index: 3770,
    start_char: 20596,
    end_char: 20602,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "still",
    start_time: 1444400,
    end_time: 1444900,
    token_index: 3771,
    start_char: 20603,
    end_char: 20608,
    display_word: "still",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1445000,
    end_time: 1445100,
    token_index: 3772,
    start_char: 20609,
    end_char: 20610,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "justification",
    start_time: 1445100,
    end_time: 1445700,
    token_index: 3773,
    start_char: 20611,
    end_char: 20624,
    display_word: "justification",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1445700,
    end_time: 1445800,
    token_index: 3774,
    start_char: 20625,
    end_char: 20629,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "happens.",
    start_time: 1445800,
    end_time: 1446400,
    token_index: 3775,
    start_char: 20630,
    end_char: 20637,
    display_word: "happens",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1447300,
    end_time: 1447400,
    token_index: 3776,
    start_char: 20639,
    end_char: 20640,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1447400,
    end_time: 1447600,
    token_index: 3777,
    start_char: 20641,
    end_char: 20645,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1447600,
    end_time: 1447700,
    token_index: 3778,
    start_char: 20646,
    end_char: 20650,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1447700,
    end_time: 1447800,
    token_index: 3779,
    start_char: 20651,
    end_char: 20655,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1447800,
    end_time: 1448100,
    token_index: 3780,
    start_char: 20656,
    end_char: 20659,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1448100,
    end_time: 1448300,
    token_index: 3781,
    start_char: 20660,
    end_char: 20662,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1448300,
    end_time: 1448400,
    token_index: 3782,
    start_char: 20663,
    end_char: 20665,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1448400,
    end_time: 1448600,
    token_index: 3783,
    start_char: 20666,
    end_char: 20670,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "season.",
    start_time: 1448600,
    end_time: 1449300,
    token_index: 3784,
    start_char: 20671,
    end_char: 20677,
    display_word: "season",
    period_at_end: true
    },
    {
    word: "Why",
    start_time: 1450100,
    end_time: 1450300,
    token_index: 3785,
    start_char: 20679,
    end_char: 20682,
    display_word: "Why",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1450300,
    end_time: 1450500,
    token_index: 3786,
    start_char: 20683,
    end_char: 20686,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1450500,
    end_time: 1450600,
    token_index: 3787,
    start_char: 20687,
    end_char: 20689,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "important",
    start_time: 1450600,
    end_time: 1450900,
    token_index: 3788,
    start_char: 20690,
    end_char: 20699,
    display_word: "important",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1450900,
    end_time: 1451000,
    token_index: 3789,
    start_char: 20700,
    end_char: 20703,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1451000,
    end_time: 1451100,
    token_index: 3790,
    start_char: 20704,
    end_char: 20707,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1451100,
    end_time: 1451200,
    token_index: 3791,
    start_char: 20708,
    end_char: 20710,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 1451200,
    end_time: 1451300,
    token_index: 3792,
    start_char: 20711,
    end_char: 20714,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1451300,
    end_time: 1451400,
    token_index: 3793,
    start_char: 20715,
    end_char: 20719,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1451400,
    end_time: 1451600,
    token_index: 3794,
    start_char: 20720,
    end_char: 20723,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1451600,
    end_time: 1451700,
    token_index: 3795,
    start_char: 20724,
    end_char: 20726,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "there?",
    start_time: 1451700,
    end_time: 1452000,
    token_index: 3796,
    start_char: 20727,
    end_char: 20733,
    display_word: "there?",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1452600,
    end_time: 1452700,
    token_index: 3797,
    start_char: 20734,
    end_char: 20735,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "mean,",
    start_time: 1452700,
    end_time: 1452900,
    token_index: 3798,
    start_char: 20736,
    end_char: 20741,
    display_word: "mean,",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1452900,
    end_time: 1453100,
    token_index: 3799,
    start_char: 20742,
    end_char: 20744,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1453100,
    end_time: 1453500,
    token_index: 3800,
    start_char: 20745,
    end_char: 20748,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1453500,
    end_time: 1453600,
    token_index: 3801,
    start_char: 20749,
    end_char: 20751,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 1453600,
    end_time: 1453700,
    token_index: 3802,
    start_char: 20752,
    end_char: 20754,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "83",
    start_time: 1453700,
    end_time: 1454200,
    token_index: 3803,
    start_char: 20755,
    end_char: 20757,
    display_word: "83",
    period_at_end: false
    },
    {
    word: "old",
    start_time: 1454200,
    end_time: 1454300,
    token_index: 3804,
    start_char: 20758,
    end_char: 20761,
    display_word: "old",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1454300,
    end_time: 1454600,
    token_index: 3805,
    start_char: 20762,
    end_char: 20767,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "woman,",
    start_time: 1454600,
    end_time: 1454900,
    token_index: 3806,
    start_char: 20768,
    end_char: 20774,
    display_word: "woman,",
    period_at_end: false
    },
    {
    word: "which",
    start_time: 1454900,
    end_time: 1455000,
    token_index: 3807,
    start_char: 20775,
    end_char: 20780,
    display_word: "which",
    period_at_end: false
    },
    {
    word: "makes",
    start_time: 1455000,
    end_time: 1455200,
    token_index: 3808,
    start_char: 20781,
    end_char: 20786,
    display_word: "makes",
    period_at_end: false
    },
    {
    word: "her",
    start_time: 1455200,
    end_time: 1455300,
    token_index: 3809,
    start_char: 20787,
    end_char: 20790,
    display_word: "her",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 1455300,
    end_time: 1455400,
    token_index: 3810,
    start_char: 20791,
    end_char: 20793,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "expert",
    start_time: 1455400,
    end_time: 1455800,
    token_index: 3811,
    start_char: 20794,
    end_char: 20800,
    display_word: "expert",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1455800,
    end_time: 1455900,
    token_index: 3812,
    start_char: 20801,
    end_char: 20803,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "racism.",
    start_time: 1455900,
    end_time: 1456400,
    token_index: 3813,
    start_char: 20804,
    end_char: 20810,
    display_word: "racism",
    period_at_end: true
    },
    {
    word: "First",
    start_time: 1456400,
    end_time: 1456600,
    token_index: 3814,
    start_char: 20812,
    end_char: 20817,
    display_word: "First",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1456600,
    end_time: 1456700,
    token_index: 3815,
    start_char: 20818,
    end_char: 20820,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "all.",
    start_time: 1456700,
    end_time: 1457000,
    token_index: 3816,
    start_char: 20821,
    end_char: 20824,
    display_word: "all",
    period_at_end: true
    },
    {
    word: "It's",
    start_time: 1457100,
    end_time: 1457200,
    token_index: 3817,
    start_char: 20826,
    end_char: 20830,
    display_word: "It's",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1457600,
    end_time: 1457800,
    token_index: 3818,
    start_char: 20831,
    end_char: 20836,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1457800,
    end_time: 1458200,
    token_index: 3819,
    start_char: 20837,
    end_char: 20841,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1458400,
    end_time: 1458500,
    token_index: 3820,
    start_char: 20842,
    end_char: 20845,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "also",
    start_time: 1458500,
    end_time: 1459100,
    token_index: 3821,
    start_char: 20846,
    end_char: 20850,
    display_word: "also",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1459300,
    end_time: 1459400,
    token_index: 3822,
    start_char: 20851,
    end_char: 20852,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "put",
    start_time: 1459400,
    end_time: 1459600,
    token_index: 3823,
    start_char: 20853,
    end_char: 20856,
    display_word: "put",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1459600,
    end_time: 1459700,
    token_index: 3824,
    start_char: 20857,
    end_char: 20859,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "dad",
    start_time: 1459700,
    end_time: 1460100,
    token_index: 3825,
    start_char: 20860,
    end_char: 20863,
    display_word: "dad",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1460100,
    end_time: 1460200,
    token_index: 3826,
    start_char: 20864,
    end_char: 20866,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "episode",
    start_time: 1460200,
    end_time: 1460700,
    token_index: 3827,
    start_char: 20867,
    end_char: 20874,
    display_word: "episode",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1460700,
    end_time: 1461000,
    token_index: 3828,
    start_char: 20875,
    end_char: 20877,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "season",
    start_time: 1461300,
    end_time: 1461700,
    token_index: 3829,
    start_char: 20878,
    end_char: 20884,
    display_word: "season",
    period_at_end: false
    },
    {
    word: "three",
    start_time: 1461700,
    end_time: 1462200,
    token_index: 3830,
    start_char: 20885,
    end_char: 20890,
    display_word: "three",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1462400,
    end_time: 1462500,
    token_index: 3831,
    start_char: 20891,
    end_char: 20894,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1462500,
    end_time: 1462700,
    token_index: 3832,
    start_char: 20895,
    end_char: 20897,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1462700,
    end_time: 1463000,
    token_index: 3833,
    start_char: 20898,
    end_char: 20901,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 1463000,
    end_time: 1463200,
    token_index: 3834,
    start_char: 20902,
    end_char: 20905,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1463200,
    end_time: 1463300,
    token_index: 3835,
    start_char: 20906,
    end_char: 20910,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "clear",
    start_time: 1463300,
    end_time: 1463700,
    token_index: 3836,
    start_char: 20911,
    end_char: 20916,
    display_word: "clear",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1463700,
    end_time: 1464000,
    token_index: 3837,
    start_char: 20917,
    end_char: 20921,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "since",
    start_time: 1464000,
    end_time: 1464200,
    token_index: 3838,
    start_char: 20922,
    end_char: 20927,
    display_word: "since",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 1464200,
    end_time: 1464300,
    token_index: 3839,
    start_char: 20928,
    end_char: 20930,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1464300,
    end_time: 1464600,
    token_index: 3840,
    start_char: 20931,
    end_char: 20934,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1464600,
    end_time: 1464900,
    token_index: 3841,
    start_char: 20935,
    end_char: 20937,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1465300,
    end_time: 1465600,
    token_index: 3842,
    start_char: 20938,
    end_char: 20942,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "she",
    start_time: 1465600,
    end_time: 1465800,
    token_index: 3843,
    start_char: 20943,
    end_char: 20946,
    display_word: "she",
    period_at_end: false
    },
    {
    word: "needs",
    start_time: 1465800,
    end_time: 1466000,
    token_index: 3844,
    start_char: 20947,
    end_char: 20952,
    display_word: "needs",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1466000,
    end_time: 1466100,
    token_index: 3845,
    start_char: 20953,
    end_char: 20955,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1466100,
    end_time: 1466200,
    token_index: 3846,
    start_char: 20956,
    end_char: 20958,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1466200,
    end_time: 1466300,
    token_index: 3847,
    start_char: 20959,
    end_char: 20961,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1466300,
    end_time: 1466400,
    token_index: 3848,
    start_char: 20962,
    end_char: 20965,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1466400,
    end_time: 1466800,
    token_index: 3849,
    start_char: 20966,
    end_char: 20970,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1467100,
    end_time: 1467300,
    token_index: 3850,
    start_char: 20971,
    end_char: 20974,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "she's",
    start_time: 1467300,
    end_time: 1467400,
    token_index: 3851,
    start_char: 20975,
    end_char: 20980,
    display_word: "she's",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1467400,
    end_time: 1467600,
    token_index: 3852,
    start_char: 20981,
    end_char: 20985,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "very",
    start_time: 1467600,
    end_time: 1468000,
    token_index: 3853,
    start_char: 20986,
    end_char: 20990,
    display_word: "very",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1468000,
    end_time: 1468200,
    token_index: 3854,
    start_char: 20991,
    end_char: 20994,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "they're",
    start_time: 1468200,
    end_time: 1468300,
    token_index: 3855,
    start_char: 20995,
    end_char: 21002,
    display_word: "they're",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1468300,
    end_time: 1468500,
    token_index: 3856,
    start_char: 21003,
    end_char: 21006,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "together.",
    start_time: 1468500,
    end_time: 1469100,
    token_index: 3857,
    start_char: 21007,
    end_char: 21015,
    display_word: "together",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1469100,
    end_time: 1469300,
    token_index: 3858,
    start_char: 21017,
    end_char: 21019,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1469300,
    end_time: 1469500,
    token_index: 3859,
    start_char: 21020,
    end_char: 21022,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1469500,
    end_time: 1469700,
    token_index: 3860,
    start_char: 21023,
    end_char: 21029,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "creates",
    start_time: 1469700,
    end_time: 1470100,
    token_index: 3861,
    start_char: 21030,
    end_char: 21037,
    display_word: "creates",
    period_at_end: false
    },
    {
    word: "problems",
    start_time: 1470100,
    end_time: 1470500,
    token_index: 3862,
    start_char: 21038,
    end_char: 21046,
    display_word: "problems",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1470500,
    end_time: 1470600,
    token_index: 3863,
    start_char: 21047,
    end_char: 21050,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "me.",
    start_time: 1470600,
    end_time: 1470800,
    token_index: 3864,
    start_char: 21051,
    end_char: 21053,
    display_word: "me",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1470800,
    end_time: 1471200,
    token_index: 3865,
    start_char: 21055,
    end_char: 21057,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1471400,
    end_time: 1471400,
    token_index: 3866,
    start_char: 21058,
    end_char: 21059,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1471400,
    end_time: 1471600,
    token_index: 3867,
    start_char: 21060,
    end_char: 21063,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "like,",
    start_time: 1471600,
    end_time: 1471900,
    token_index: 3868,
    start_char: 21064,
    end_char: 21069,
    display_word: "like,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1472200,
    end_time: 1472300,
    token_index: 3869,
    start_char: 21070,
    end_char: 21071,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1472300,
    end_time: 1472600,
    token_index: 3870,
    start_char: 21072,
    end_char: 21077,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "you'll",
    start_time: 1472600,
    end_time: 1472700,
    token_index: 3871,
    start_char: 21078,
    end_char: 21084,
    display_word: "you'll",
    period_at_end: false
    },
    {
    word: "fit",
    start_time: 1472700,
    end_time: 1472800,
    token_index: 3872,
    start_char: 21085,
    end_char: 21088,
    display_word: "fit",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 1472800,
    end_time: 1473000,
    token_index: 3873,
    start_char: 21089,
    end_char: 21093,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1473000,
    end_time: 1473100,
    token_index: 3874,
    start_char: 21094,
    end_char: 21097,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1473100,
    end_time: 1473300,
    token_index: 3875,
    start_char: 21098,
    end_char: 21103,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1473300,
    end_time: 1473800,
    token_index: 3876,
    start_char: 21104,
    end_char: 21113,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "episode",
    start_time: 1473800,
    end_time: 1474200,
    token_index: 3877,
    start_char: 21114,
    end_char: 21121,
    display_word: "episode",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 1474200,
    end_time: 1474300,
    token_index: 3878,
    start_char: 21122,
    end_char: 21126,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "than",
    start_time: 1474300,
    end_time: 1474500,
    token_index: 3879,
    start_char: 21127,
    end_char: 21131,
    display_word: "than",
    period_at_end: false
    },
    {
    word: "you'll",
    start_time: 1474500,
    end_time: 1474600,
    token_index: 3880,
    start_char: 21132,
    end_char: 21138,
    display_word: "you'll",
    period_at_end: false
    },
    {
    word: "fit",
    start_time: 1474600,
    end_time: 1474700,
    token_index: 3881,
    start_char: 21139,
    end_char: 21142,
    display_word: "fit",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 1474700,
    end_time: 1475000,
    token_index: 3882,
    start_char: 21143,
    end_char: 21147,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1475000,
    end_time: 1475100,
    token_index: 3883,
    start_char: 21148,
    end_char: 21151,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "east",
    start_time: 1475100,
    end_time: 1475400,
    token_index: 3884,
    start_char: 21152,
    end_char: 21156,
    display_word: "east",
    period_at_end: false
    },
    {
    word: "LA",
    start_time: 1475400,
    end_time: 1475600,
    token_index: 3885,
    start_char: 21157,
    end_char: 21159,
    display_word: "LA",
    period_at_end: false
    },
    {
    word: "episode.",
    start_time: 1475600,
    end_time: 1476200,
    token_index: 3886,
    start_char: 21160,
    end_char: 21167,
    display_word: "episode",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1476200,
    end_time: 1476300,
    token_index: 3887,
    start_char: 21169,
    end_char: 21171,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1476300,
    end_time: 1476800,
    token_index: 3888,
    start_char: 21172,
    end_char: 21178,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1476800,
    end_time: 1476900,
    token_index: 3889,
    start_char: 21179,
    end_char: 21182,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1476900,
    end_time: 1477000,
    token_index: 3890,
    start_char: 21183,
    end_char: 21185,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "got",
    start_time: 1477000,
    end_time: 1477200,
    token_index: 3891,
    start_char: 21186,
    end_char: 21189,
    display_word: "got",
    period_at_end: false
    },
    {
    word: "her.",
    start_time: 1477200,
    end_time: 1477500,
    token_index: 3892,
    start_char: 21190,
    end_char: 21193,
    display_word: "her",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1478400,
    end_time: 1478500,
    token_index: 3893,
    start_char: 21195,
    end_char: 21196,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1478500,
    end_time: 1478700,
    token_index: 3894,
    start_char: 21197,
    end_char: 21201,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1478700,
    end_time: 1478900,
    token_index: 3895,
    start_char: 21202,
    end_char: 21206,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1478900,
    end_time: 1479200,
    token_index: 3896,
    start_char: 21207,
    end_char: 21210,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1479200,
    end_time: 1479300,
    token_index: 3897,
    start_char: 21211,
    end_char: 21214,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "very",
    start_time: 1479300,
    end_time: 1479500,
    token_index: 3898,
    start_char: 21215,
    end_char: 21219,
    display_word: "very",
    period_at_end: false
    },
    {
    word: "influential",
    start_time: 1479500,
    end_time: 1480000,
    token_index: 3899,
    start_char: 21220,
    end_char: 21231,
    display_word: "influential",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1480000,
    end_time: 1480300,
    token_index: 3900,
    start_char: 21232,
    end_char: 21237,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1480300,
    end_time: 1480900,
    token_index: 3901,
    start_char: 21238,
    end_char: 21241,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1480900,
    end_time: 1481100,
    token_index: 3902,
    start_char: 21242,
    end_char: 21245,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "sort",
    start_time: 1481100,
    end_time: 1481400,
    token_index: 3903,
    start_char: 21246,
    end_char: 21250,
    display_word: "sort",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1481400,
    end_time: 1481500,
    token_index: 3904,
    start_char: 21251,
    end_char: 21253,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1481500,
    end_time: 1482200,
    token_index: 3905,
    start_char: 21254,
    end_char: 21257,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1482500,
    end_time: 1482600,
    token_index: 3906,
    start_char: 21258,
    end_char: 21261,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "history",
    start_time: 1482600,
    end_time: 1482800,
    token_index: 3907,
    start_char: 21262,
    end_char: 21269,
    display_word: "history",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1482800,
    end_time: 1482900,
    token_index: 3908,
    start_char: 21270,
    end_char: 21272,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1482900,
    end_time: 1482900,
    token_index: 3909,
    start_char: 21273,
    end_char: 21276,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 1482900,
    end_time: 1483200,
    token_index: 3910,
    start_char: 21277,
    end_char: 21283,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "States",
    start_time: 1483200,
    end_time: 1483700,
    token_index: 3911,
    start_char: 21284,
    end_char: 21290,
    display_word: "States",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1483700,
    end_time: 1484100,
    token_index: 3912,
    start_char: 21291,
    end_char: 21294,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1484100,
    end_time: 1484400,
    token_index: 3913,
    start_char: 21295,
    end_char: 21298,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1484400,
    end_time: 1484600,
    token_index: 3914,
    start_char: 21299,
    end_char: 21302,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "way",
    start_time: 1484600,
    end_time: 1484800,
    token_index: 3915,
    start_char: 21303,
    end_char: 21306,
    display_word: "way",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1484800,
    end_time: 1485000,
    token_index: 3916,
    start_char: 21307,
    end_char: 21310,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1485000,
    end_time: 1485300,
    token_index: 3917,
    start_char: 21311,
    end_char: 21316,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "history",
    start_time: 1485300,
    end_time: 1485600,
    token_index: 3918,
    start_char: 21317,
    end_char: 21324,
    display_word: "history",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 1485600,
    end_time: 1485700,
    token_index: 3919,
    start_char: 21325,
    end_char: 21328,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1485700,
    end_time: 1485800,
    token_index: 3920,
    start_char: 21329,
    end_char: 21333,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "ignored",
    start_time: 1485800,
    end_time: 1486500,
    token_index: 3921,
    start_char: 21334,
    end_char: 21341,
    display_word: "ignored",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1486700,
    end_time: 1487000,
    token_index: 3922,
    start_char: 21342,
    end_char: 21346,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "What",
    start_time: 1487500,
    end_time: 1487900,
    token_index: 3923,
    start_char: 21347,
    end_char: 21351,
    display_word: "What",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1487900,
    end_time: 1488000,
    token_index: 3924,
    start_char: 21352,
    end_char: 21355,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1488000,
    end_time: 1488200,
    token_index: 3925,
    start_char: 21356,
    end_char: 21361,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1488200,
    end_time: 1488200,
    token_index: 3926,
    start_char: 21362,
    end_char: 21365,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "explain",
    start_time: 1488200,
    end_time: 1488600,
    token_index: 3927,
    start_char: 21366,
    end_char: 21373,
    display_word: "explain",
    period_at_end: false
    },
    {
    word: "her",
    start_time: 1488600,
    end_time: 1488900,
    token_index: 3928,
    start_char: 21374,
    end_char: 21377,
    display_word: "her",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1488900,
    end_time: 1489000,
    token_index: 3929,
    start_char: 21378,
    end_char: 21380,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "somebody",
    start_time: 1489000,
    end_time: 1489300,
    token_index: 3930,
    start_char: 21381,
    end_char: 21389,
    display_word: "somebody",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 1489300,
    end_time: 1489300,
    token_index: 3931,
    start_char: 21390,
    end_char: 21393,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "hasn't",
    start_time: 1489300,
    end_time: 1489500,
    token_index: 3932,
    start_char: 21394,
    end_char: 21400,
    display_word: "hasn't",
    period_at_end: false
    },
    {
    word: "met",
    start_time: 1489500,
    end_time: 1489600,
    token_index: 3933,
    start_char: 21401,
    end_char: 21404,
    display_word: "met",
    period_at_end: false
    },
    {
    word: "her",
    start_time: 1489600,
    end_time: 1489700,
    token_index: 3934,
    start_char: 21405,
    end_char: 21408,
    display_word: "her",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 1489700,
    end_time: 1490000,
    token_index: 3935,
    start_char: 21409,
    end_char: 21415,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1490000,
    end_time: 1490100,
    token_index: 3936,
    start_char: 21416,
    end_char: 21420,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "me?",
    start_time: 1490100,
    end_time: 1490500,
    token_index: 3937,
    start_char: 21421,
    end_char: 21424,
    display_word: "me?",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1491300,
    end_time: 1491400,
    token_index: 3938,
    start_char: 21425,
    end_char: 21426,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "mean,",
    start_time: 1491400,
    end_time: 1491900,
    token_index: 3939,
    start_char: 21427,
    end_char: 21432,
    display_word: "mean,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1491900,
    end_time: 1492200,
    token_index: 3940,
    start_char: 21433,
    end_char: 21434,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1493000,
    end_time: 1493200,
    token_index: 3941,
    start_char: 21435,
    end_char: 21439,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1493200,
    end_time: 1493200,
    token_index: 3942,
    start_char: 21440,
    end_char: 21441,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "job",
    start_time: 1493200,
    end_time: 1493500,
    token_index: 3943,
    start_char: 21442,
    end_char: 21445,
    display_word: "job",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1493500,
    end_time: 1493700,
    token_index: 3944,
    start_char: 21446,
    end_char: 21448,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 1493700,
    end_time: 1493800,
    token_index: 3945,
    start_char: 21449,
    end_char: 21451,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1493800,
    end_time: 1494000,
    token_index: 3946,
    start_char: 21452,
    end_char: 21454,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "act",
    start_time: 1494000,
    end_time: 1494200,
    token_index: 3947,
    start_char: 21455,
    end_char: 21458,
    display_word: "act",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1494200,
    end_time: 1494300,
    token_index: 3948,
    start_char: 21459,
    end_char: 21463,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1494300,
    end_time: 1494400,
    token_index: 3949,
    start_char: 21464,
    end_char: 21465,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "said,",
    start_time: 1494400,
    end_time: 1494900,
    token_index: 3950,
    start_char: 21466,
    end_char: 21471,
    display_word: "said,",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1495300,
    end_time: 1495500,
    token_index: 3951,
    start_char: 21472,
    end_char: 21474,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1495500,
    end_time: 1495800,
    token_index: 3952,
    start_char: 21475,
    end_char: 21478,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1495800,
    end_time: 1495900,
    token_index: 3953,
    start_char: 21479,
    end_char: 21481,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "such",
    start_time: 1495900,
    end_time: 1496100,
    token_index: 3954,
    start_char: 21482,
    end_char: 21486,
    display_word: "such",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1496100,
    end_time: 1496200,
    token_index: 3955,
    start_char: 21487,
    end_char: 21488,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "hardcore",
    start_time: 1496200,
    end_time: 1496700,
    token_index: 3956,
    start_char: 21489,
    end_char: 21497,
    display_word: "hardcore",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1496700,
    end_time: 1497000,
    token_index: 3957,
    start_char: 21498,
    end_char: 21503,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "woman",
    start_time: 1497000,
    end_time: 1497500,
    token_index: 3958,
    start_char: 21504,
    end_char: 21509,
    display_word: "woman",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1498000,
    end_time: 1498200,
    token_index: 3959,
    start_char: 21510,
    end_char: 21514,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1498200,
    end_time: 1498200,
    token_index: 3960,
    start_char: 21515,
    end_char: 21516,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1498200,
    end_time: 1498400,
    token_index: 3961,
    start_char: 21517,
    end_char: 21520,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "11",
    start_time: 1498400,
    end_time: 1498800,
    token_index: 3962,
    start_char: 21521,
    end_char: 21523,
    display_word: "11",
    period_at_end: false
    },
    {
    word: "years",
    start_time: 1498800,
    end_time: 1499000,
    token_index: 3963,
    start_char: 21524,
    end_char: 21529,
    display_word: "years",
    period_at_end: false
    },
    {
    word: "old",
    start_time: 1499000,
    end_time: 1499200,
    token_index: 3964,
    start_char: 21530,
    end_char: 21533,
    display_word: "old",
    period_at_end: false
    },
    {
    word: "Pro.",
    start_time: 1499200,
    end_time: 1499300,
    token_index: 3965,
    start_char: 21534,
    end_char: 21537,
    display_word: "Pro",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1499300,
    end_time: 1499400,
    token_index: 3966,
    start_char: 21539,
    end_char: 21540,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "realized",
    start_time: 1499400,
    end_time: 1499700,
    token_index: 3967,
    start_char: 21541,
    end_char: 21549,
    display_word: "realized",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1499700,
    end_time: 1499800,
    token_index: 3968,
    start_char: 21550,
    end_char: 21554,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1499800,
    end_time: 1499800,
    token_index: 3969,
    start_char: 21555,
    end_char: 21556,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "cracker",
    start_time: 1499800,
    end_time: 1500300,
    token_index: 3970,
    start_char: 21557,
    end_char: 21564,
    display_word: "cracker",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1500300,
    end_time: 1500400,
    token_index: 3971,
    start_char: 21565,
    end_char: 21568,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "also",
    start_time: 1500400,
    end_time: 1500800,
    token_index: 3972,
    start_char: 21569,
    end_char: 21573,
    display_word: "also",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1500800,
    end_time: 1500900,
    token_index: 3973,
    start_char: 21574,
    end_char: 21575,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "delicious",
    start_time: 1500900,
    end_time: 1501300,
    token_index: 3974,
    start_char: 21576,
    end_char: 21585,
    display_word: "delicious",
    period_at_end: false
    },
    {
    word: "snack.",
    start_time: 1501300,
    end_time: 1501900,
    token_index: 3975,
    start_char: 21586,
    end_char: 21591,
    display_word: "snack",
    period_at_end: true
    },
    {
    word: "That's",
    start_time: 1502500,
    end_time: 1502900,
    token_index: 3976,
    start_char: 21593,
    end_char: 21599,
    display_word: "That's",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1502900,
    end_time: 1503000,
    token_index: 3977,
    start_char: 21600,
    end_char: 21601,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1505000,
    end_time: 1505500,
    token_index: 3978,
    start_char: 21602,
    end_char: 21604,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1506000,
    end_time: 1506200,
    token_index: 3979,
    start_char: 21605,
    end_char: 21607,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1506200,
    end_time: 1506600,
    token_index: 3980,
    start_char: 21608,
    end_char: 21611,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "growing",
    start_time: 1506600,
    end_time: 1506900,
    token_index: 3981,
    start_char: 21612,
    end_char: 21619,
    display_word: "growing",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1506900,
    end_time: 1507100,
    token_index: 3982,
    start_char: 21620,
    end_char: 21622,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1507100,
    end_time: 1507400,
    token_index: 3983,
    start_char: 21623,
    end_char: 21626,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "always",
    start_time: 1507400,
    end_time: 1508000,
    token_index: 3984,
    start_char: 21627,
    end_char: 21633,
    display_word: "always",
    period_at_end: false
    },
    {
    word: "talking",
    start_time: 1508000,
    end_time: 1508400,
    token_index: 3985,
    start_char: 21634,
    end_char: 21641,
    display_word: "talking",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1508400,
    end_time: 1508900,
    token_index: 3986,
    start_char: 21642,
    end_char: 21647,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1508900,
    end_time: 1509600,
    token_index: 3987,
    start_char: 21648,
    end_char: 21654,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1509600,
    end_time: 1509800,
    token_index: 3988,
    start_char: 21655,
    end_char: 21660,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1509800,
    end_time: 1510800,
    token_index: 3989,
    start_char: 21661,
    end_char: 21670,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1511000,
    end_time: 1511200,
    token_index: 3990,
    start_char: 21671,
    end_char: 21674,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "man",
    start_time: 1511200,
    end_time: 1511700,
    token_index: 3991,
    start_char: 21675,
    end_char: 21678,
    display_word: "man",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1511700,
    end_time: 1512000,
    token_index: 3992,
    start_char: 21679,
    end_char: 21683,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1512000,
    end_time: 1512000,
    token_index: 3993,
    start_char: 21684,
    end_char: 21686,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1512000,
    end_time: 1512100,
    token_index: 3994,
    start_char: 21687,
    end_char: 21690,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "70s.",
    start_time: 1512100,
    end_time: 1512500,
    token_index: 3995,
    start_char: 21691,
    end_char: 21694,
    display_word: "70s",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 1512500,
    end_time: 1512500,
    token_index: 3996,
    start_char: 21696,
    end_char: 21698,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1512500,
    end_time: 1512800,
    token_index: 3997,
    start_char: 21699,
    end_char: 21702,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1512800,
    end_time: 1512900,
    token_index: 3998,
    start_char: 21703,
    end_char: 21706,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "man",
    start_time: 1512900,
    end_time: 1513700,
    token_index: 3999,
    start_char: 21707,
    end_char: 21710,
    display_word: "man",
    period_at_end: false
    },
    {
    word: "talking",
    start_time: 1513800,
    end_time: 1514100,
    token_index: 4000,
    start_char: 21711,
    end_char: 21718,
    display_word: "talking",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1514100,
    end_time: 1514200,
    token_index: 4001,
    start_char: 21719,
    end_char: 21724,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1514200,
    end_time: 1514300,
    token_index: 4002,
    start_char: 21725,
    end_char: 21728,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "man",
    start_time: 1514300,
    end_time: 1514800,
    token_index: 4003,
    start_char: 21729,
    end_char: 21732,
    display_word: "man",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1515000,
    end_time: 1515200,
    token_index: 4004,
    start_char: 21733,
    end_char: 21736,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 1515200,
    end_time: 1515300,
    token_index: 4005,
    start_char: 21737,
    end_char: 21739,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 1515300,
    end_time: 1516000,
    token_index: 4006,
    start_char: 21740,
    end_char: 21746,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "shy",
    start_time: 1516000,
    end_time: 1516300,
    token_index: 4007,
    start_char: 21747,
    end_char: 21750,
    display_word: "shy",
    period_at_end: false
    },
    {
    word: "away",
    start_time: 1516300,
    end_time: 1516600,
    token_index: 4008,
    start_char: 21751,
    end_char: 21755,
    display_word: "away",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1516600,
    end_time: 1516800,
    token_index: 4009,
    start_char: 21756,
    end_char: 21760,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "talking.",
    start_time: 1516800,
    end_time: 1517000,
    token_index: 4010,
    start_char: 21761,
    end_char: 21768,
    display_word: "talking",
    period_at_end: true
    },
    {
    word: "To",
    start_time: 1517100,
    end_time: 1517200,
    token_index: 4011,
    start_char: 21770,
    end_char: 21772,
    display_word: "To",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1517200,
    end_time: 1517300,
    token_index: 4012,
    start_char: 21773,
    end_char: 21775,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1517300,
    end_time: 1517600,
    token_index: 4013,
    start_char: 21776,
    end_char: 21781,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "those",
    start_time: 1517600,
    end_time: 1517800,
    token_index: 4014,
    start_char: 21782,
    end_char: 21787,
    display_word: "those",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1517800,
    end_time: 1518300,
    token_index: 4015,
    start_char: 21788,
    end_char: 21794,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 1518400,
    end_time: 1518700,
    token_index: 4016,
    start_char: 21795,
    end_char: 21799,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1518700,
    end_time: 1518700,
    token_index: 4017,
    start_char: 21800,
    end_char: 21802,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1518700,
    end_time: 1518800,
    token_index: 4018,
    start_char: 21803,
    end_char: 21806,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "point",
    start_time: 1518800,
    end_time: 1519000,
    token_index: 4019,
    start_char: 21807,
    end_char: 21812,
    display_word: "point",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1519000,
    end_time: 1519200,
    token_index: 4020,
    start_char: 21813,
    end_char: 21817,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "like,",
    start_time: 1519200,
    end_time: 1519500,
    token_index: 4021,
    start_char: 21818,
    end_char: 21823,
    display_word: "like,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1519600,
    end_time: 1519800,
    token_index: 4022,
    start_char: 21824,
    end_char: 21827,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1519800,
    end_time: 1519900,
    token_index: 4023,
    start_char: 21828,
    end_char: 21832,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1519900,
    end_time: 1520000,
    token_index: 4024,
    start_char: 21833,
    end_char: 21836,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "talk",
    start_time: 1520000,
    end_time: 1520300,
    token_index: 4025,
    start_char: 21837,
    end_char: 21841,
    display_word: "talk",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1520300,
    end_time: 1520400,
    token_index: 4026,
    start_char: 21842,
    end_char: 21844,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1520400,
    end_time: 1520600,
    token_index: 4027,
    start_char: 21845,
    end_char: 21851,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 1520600,
    end_time: 1521100,
    token_index: 4028,
    start_char: 21852,
    end_char: 21855,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1521400,
    end_time: 1521900,
    token_index: 4029,
    start_char: 21856,
    end_char: 21860,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1521900,
    end_time: 1522000,
    token_index: 4030,
    start_char: 21861,
    end_char: 21865,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1522000,
    end_time: 1522200,
    token_index: 4031,
    start_char: 21866,
    end_char: 21868,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "parents",
    start_time: 1522200,
    end_time: 1522500,
    token_index: 4032,
    start_char: 21869,
    end_char: 21876,
    display_word: "parents",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1522500,
    end_time: 1522600,
    token_index: 4033,
    start_char: 21877,
    end_char: 21881,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "kids.",
    start_time: 1522600,
    end_time: 1523000,
    token_index: 4034,
    start_char: 21882,
    end_char: 21886,
    display_word: "kids",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1523000,
    end_time: 1523200,
    token_index: 4035,
    start_char: 21888,
    end_char: 21889,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "remember",
    start_time: 1523200,
    end_time: 1523600,
    token_index: 4036,
    start_char: 21890,
    end_char: 21898,
    display_word: "remember",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1523600,
    end_time: 1523800,
    token_index: 4037,
    start_char: 21899,
    end_char: 21903,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "talk.",
    start_time: 1523800,
    end_time: 1524200,
    token_index: 4038,
    start_char: 21904,
    end_char: 21908,
    display_word: "talk",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1524200,
    end_time: 1524300,
    token_index: 4039,
    start_char: 21910,
    end_char: 21911,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "remember",
    start_time: 1524300,
    end_time: 1524500,
    token_index: 4040,
    start_char: 21912,
    end_char: 21920,
    display_word: "remember",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 1524500,
    end_time: 1524600,
    token_index: 4041,
    start_char: 21921,
    end_char: 21925,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1524600,
    end_time: 1524700,
    token_index: 4042,
    start_char: 21926,
    end_char: 21928,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1524700,
    end_time: 1525000,
    token_index: 4043,
    start_char: 21929,
    end_char: 21932,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "took",
    start_time: 1525000,
    end_time: 1525200,
    token_index: 4044,
    start_char: 21933,
    end_char: 21937,
    display_word: "took",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1525200,
    end_time: 1525200,
    token_index: 4045,
    start_char: 21938,
    end_char: 21940,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1525200,
    end_time: 1525300,
    token_index: 4046,
    start_char: 21941,
    end_char: 21943,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1525300,
    end_time: 1525400,
    token_index: 4047,
    start_char: 21944,
    end_char: 21947,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "store",
    start_time: 1525400,
    end_time: 1525600,
    token_index: 4048,
    start_char: 21948,
    end_char: 21953,
    display_word: "store",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1525600,
    end_time: 1525700,
    token_index: 4049,
    start_char: 21954,
    end_char: 21957,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "said",
    start_time: 1525700,
    end_time: 1526000,
    token_index: 4050,
    start_char: 21958,
    end_char: 21962,
    display_word: "said",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1526000,
    end_time: 1526200,
    token_index: 4051,
    start_char: 21963,
    end_char: 21967,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1526200,
    end_time: 1526300,
    token_index: 4052,
    start_char: 21968,
    end_char: 21970,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "what's",
    start_time: 1526300,
    end_time: 1526500,
    token_index: 4053,
    start_char: 21971,
    end_char: 21977,
    display_word: "what's",
    period_at_end: false
    },
    {
    word: "gonna",
    start_time: 1526500,
    end_time: 1526800,
    token_index: 4054,
    start_char: 21978,
    end_char: 21983,
    display_word: "gonna",
    period_at_end: false
    },
    {
    word: "happen",
    start_time: 1526900,
    end_time: 1527300,
    token_index: 4055,
    start_char: 21984,
    end_char: 21990,
    display_word: "happen",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 1527300,
    end_time: 1527400,
    token_index: 4056,
    start_char: 21991,
    end_char: 21995,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "you're",
    start_time: 1527400,
    end_time: 1527600,
    token_index: 4057,
    start_char: 21996,
    end_char: 22002,
    display_word: "you're",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1527600,
    end_time: 1527700,
    token_index: 4058,
    start_char: 22003,
    end_char: 22004,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "store",
    start_time: 1527700,
    end_time: 1528000,
    token_index: 4059,
    start_char: 22005,
    end_char: 22010,
    display_word: "store",
    period_at_end: false
    },
    {
    word: "by",
    start_time: 1528000,
    end_time: 1528100,
    token_index: 4060,
    start_char: 22011,
    end_char: 22013,
    display_word: "by",
    period_at_end: false
    },
    {
    word: "yourself",
    start_time: 1528100,
    end_time: 1528400,
    token_index: 4061,
    start_char: 22014,
    end_char: 22022,
    display_word: "yourself",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1528400,
    end_time: 1528500,
    token_index: 4062,
    start_char: 22023,
    end_char: 22025,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1528500,
    end_time: 1528500,
    token_index: 4063,
    start_char: 22026,
    end_char: 22027,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "little",
    start_time: 1528500,
    end_time: 1528600,
    token_index: 4064,
    start_char: 22028,
    end_char: 22034,
    display_word: "little",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1528600,
    end_time: 1528900,
    token_index: 4065,
    start_char: 22035,
    end_char: 22040,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "boy.",
    start_time: 1528900,
    end_time: 1529200,
    token_index: 4066,
    start_char: 22041,
    end_char: 22044,
    display_word: "boy",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1529200,
    end_time: 1529500,
    token_index: 4067,
    start_char: 22046,
    end_char: 22048,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1529700,
    end_time: 1529900,
    token_index: 4068,
    start_char: 22049,
    end_char: 22051,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "mom",
    start_time: 1529900,
    end_time: 1530300,
    token_index: 4069,
    start_char: 22052,
    end_char: 22055,
    display_word: "mom",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1530300,
    end_time: 1530600,
    token_index: 4070,
    start_char: 22056,
    end_char: 22062,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "made",
    start_time: 1530600,
    end_time: 1530900,
    token_index: 4071,
    start_char: 22063,
    end_char: 22067,
    display_word: "made",
    period_at_end: false
    },
    {
    word: "sure",
    start_time: 1530900,
    end_time: 1531300,
    token_index: 4072,
    start_char: 22068,
    end_char: 22072,
    display_word: "sure",
    period_at_end: false
    },
    {
    word: "she",
    start_time: 1531300,
    end_time: 1531500,
    token_index: 4073,
    start_char: 22073,
    end_char: 22076,
    display_word: "she",
    period_at_end: false
    },
    {
    word: "wouldn't",
    start_time: 1531500,
    end_time: 1531700,
    token_index: 4074,
    start_char: 22077,
    end_char: 22085,
    display_word: "wouldn't",
    period_at_end: false
    },
    {
    word: "let",
    start_time: 1531700,
    end_time: 1531900,
    token_index: 4075,
    start_char: 22086,
    end_char: 22089,
    display_word: "let",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1531900,
    end_time: 1532000,
    token_index: 4076,
    start_char: 22090,
    end_char: 22092,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "leave",
    start_time: 1532000,
    end_time: 1532200,
    token_index: 4077,
    start_char: 22093,
    end_char: 22098,
    display_word: "leave",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1532200,
    end_time: 1532300,
    token_index: 4078,
    start_char: 22099,
    end_char: 22102,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "house",
    start_time: 1532300,
    end_time: 1532500,
    token_index: 4079,
    start_char: 22103,
    end_char: 22108,
    display_word: "house",
    period_at_end: false
    },
    {
    word: "without",
    start_time: 1532500,
    end_time: 1532800,
    token_index: 4080,
    start_char: 22109,
    end_char: 22116,
    display_word: "without",
    period_at_end: false
    },
    {
    word: "knowing",
    start_time: 1532800,
    end_time: 1532900,
    token_index: 4081,
    start_char: 22117,
    end_char: 22124,
    display_word: "knowing",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1532900,
    end_time: 1533100,
    token_index: 4082,
    start_char: 22125,
    end_char: 22129,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1533100,
    end_time: 1533100,
    token_index: 4083,
    start_char: 22130,
    end_char: 22131,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1533100,
    end_time: 1533300,
    token_index: 4084,
    start_char: 22132,
    end_char: 22135,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1533300,
    end_time: 1533800,
    token_index: 4085,
    start_char: 22136,
    end_char: 22141,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1533800,
    end_time: 1533900,
    token_index: 4086,
    start_char: 22142,
    end_char: 22145,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1533900,
    end_time: 1534000,
    token_index: 4087,
    start_char: 22146,
    end_char: 22150,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1534000,
    end_time: 1534200,
    token_index: 4088,
    start_char: 22151,
    end_char: 22155,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "meant",
    start_time: 1534200,
    end_time: 1534300,
    token_index: 4089,
    start_char: 22156,
    end_char: 22161,
    display_word: "meant",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1534300,
    end_time: 1534400,
    token_index: 4090,
    start_char: 22162,
    end_char: 22164,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1534400,
    end_time: 1534500,
    token_index: 4091,
    start_char: 22165,
    end_char: 22168,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "world",
    start_time: 1534500,
    end_time: 1535000,
    token_index: 4092,
    start_char: 22169,
    end_char: 22174,
    display_word: "world",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1535400,
    end_time: 1535500,
    token_index: 4093,
    start_char: 22175,
    end_char: 22178,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1535500,
    end_time: 1536100,
    token_index: 4094,
    start_char: 22179,
    end_char: 22183,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1536100,
    end_time: 1536200,
    token_index: 4095,
    start_char: 22184,
    end_char: 22187,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1536200,
    end_time: 1536300,
    token_index: 4096,
    start_char: 22188,
    end_char: 22190,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 1536300,
    end_time: 1536400,
    token_index: 4097,
    start_char: 22191,
    end_char: 22194,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1536400,
    end_time: 1536500,
    token_index: 4098,
    start_char: 22195,
    end_char: 22199,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1536500,
    end_time: 1536600,
    token_index: 4099,
    start_char: 22200,
    end_char: 22205,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1536600,
    end_time: 1536700,
    token_index: 4100,
    start_char: 22206,
    end_char: 22209,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1536700,
    end_time: 1537000,
    token_index: 4101,
    start_char: 22210,
    end_char: 22214,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1537000,
    end_time: 1537000,
    token_index: 4102,
    start_char: 22215,
    end_char: 22216,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "you've",
    start_time: 1537000,
    end_time: 1537200,
    token_index: 4103,
    start_char: 22217,
    end_char: 22223,
    display_word: "you've",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1537200,
    end_time: 1537400,
    token_index: 4104,
    start_char: 22224,
    end_char: 22228,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "very",
    start_time: 1537400,
    end_time: 1537500,
    token_index: 4105,
    start_char: 22229,
    end_char: 22233,
    display_word: "very",
    period_at_end: false
    },
    {
    word: "deliberate",
    start_time: 1537500,
    end_time: 1538200,
    token_index: 4106,
    start_char: 22234,
    end_char: 22244,
    display_word: "deliberate",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1538200,
    end_time: 1538500,
    token_index: 4107,
    start_char: 22245,
    end_char: 22250,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1538500,
    end_time: 1538700,
    token_index: 4108,
    start_char: 22251,
    end_char: 22254,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1538700,
    end_time: 1539000,
    token_index: 4109,
    start_char: 22255,
    end_char: 22258,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "define",
    start_time: 1539000,
    end_time: 1539500,
    token_index: 4110,
    start_char: 22259,
    end_char: 22265,
    display_word: "define",
    period_at_end: false
    },
    {
    word: "racism.",
    start_time: 1539500,
    end_time: 1540300,
    token_index: 4111,
    start_char: 22266,
    end_char: 22272,
    display_word: "racism",
    period_at_end: true
    },
    {
    word: "Why",
    start_time: 1540700,
    end_time: 1541200,
    token_index: 4112,
    start_char: 22274,
    end_char: 22277,
    display_word: "Why",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1541200,
    end_time: 1541400,
    token_index: 4113,
    start_char: 22278,
    end_char: 22280,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "important",
    start_time: 1541400,
    end_time: 1541900,
    token_index: 4114,
    start_char: 22281,
    end_char: 22290,
    display_word: "important",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1541900,
    end_time: 1542100,
    token_index: 4115,
    start_char: 22291,
    end_char: 22294,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1542100,
    end_time: 1542400,
    token_index: 4116,
    start_char: 22295,
    end_char: 22298,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1542400,
    end_time: 1542700,
    token_index: 4117,
    start_char: 22299,
    end_char: 22301,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1542700,
    end_time: 1542900,
    token_index: 4118,
    start_char: 22302,
    end_char: 22304,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1542900,
    end_time: 1543100,
    token_index: 4119,
    start_char: 22305,
    end_char: 22307,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "specific",
    start_time: 1543100,
    end_time: 1543600,
    token_index: 4120,
    start_char: 22308,
    end_char: 22316,
    display_word: "specific",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1543600,
    end_time: 1543800,
    token_index: 4121,
    start_char: 22317,
    end_char: 22322,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1543800,
    end_time: 1543800,
    token_index: 4122,
    start_char: 22323,
    end_char: 22327,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "definition?",
    start_time: 1543800,
    end_time: 1544500,
    token_index: 4123,
    start_char: 22328,
    end_char: 22339,
    display_word: "definition?",
    period_at_end: false
    },
    {
    word: "And",
    start_time: 1544500,
    end_time: 1544700,
    token_index: 4124,
    start_char: 22340,
    end_char: 22343,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1544700,
    end_time: 1544900,
    token_index: 4125,
    start_char: 22344,
    end_char: 22348,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1544900,
    end_time: 1545000,
    token_index: 4126,
    start_char: 22349,
    end_char: 22351,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1545000,
    end_time: 1545100,
    token_index: 4127,
    start_char: 22352,
    end_char: 22354,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 1545100,
    end_time: 1545200,
    token_index: 4128,
    start_char: 22355,
    end_char: 22357,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1545200,
    end_time: 1545200,
    token_index: 4129,
    start_char: 22358,
    end_char: 22361,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 1545200,
    end_time: 1545400,
    token_index: 4130,
    start_char: 22362,
    end_char: 22367,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "mind",
    start_time: 1545400,
    end_time: 1545500,
    token_index: 4131,
    start_char: 22368,
    end_char: 22372,
    display_word: "mind",
    period_at_end: false
    },
    {
    word: "sharing",
    start_time: 1545500,
    end_time: 1545900,
    token_index: 4132,
    start_char: 22373,
    end_char: 22380,
    display_word: "sharing",
    period_at_end: false
    },
    {
    word: "well",
    start_time: 1546000,
    end_time: 1546100,
    token_index: 4133,
    start_char: 22381,
    end_char: 22385,
    display_word: "well",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1546100,
    end_time: 1546300,
    token_index: 4134,
    start_char: 22386,
    end_char: 22389,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "me,",
    start_time: 1546300,
    end_time: 1546400,
    token_index: 4135,
    start_char: 22390,
    end_char: 22393,
    display_word: "me,",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1546400,
    end_time: 1546600,
    token_index: 4136,
    start_char: 22394,
    end_char: 22398,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1546600,
    end_time: 1546800,
    token_index: 4137,
    start_char: 22399,
    end_char: 22402,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 1546800,
    end_time: 1546900,
    token_index: 4138,
    start_char: 22403,
    end_char: 22407,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "Bet",
    start_time: 1547100,
    end_time: 1547400,
    token_index: 4139,
    start_char: 22408,
    end_char: 22411,
    display_word: "Bet",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1547400,
    end_time: 1547600,
    token_index: 4140,
    start_char: 22412,
    end_char: 22416,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1547600,
    end_time: 1547800,
    token_index: 4141,
    start_char: 22417,
    end_char: 22419,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "definitions.",
    start_time: 1547800,
    end_time: 1548500,
    token_index: 4142,
    start_char: 22420,
    end_char: 22431,
    display_word: "definitions",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 1548500,
    end_time: 1548600,
    token_index: 4143,
    start_char: 22433,
    end_char: 22436,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "definition",
    start_time: 1548600,
    end_time: 1549100,
    token_index: 4144,
    start_char: 22437,
    end_char: 22447,
    display_word: "definition",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1549100,
    end_time: 1549200,
    token_index: 4145,
    start_char: 22448,
    end_char: 22450,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "makes",
    start_time: 1549200,
    end_time: 1549300,
    token_index: 4146,
    start_char: 22451,
    end_char: 22456,
    display_word: "makes",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1549300,
    end_time: 1549400,
    token_index: 4147,
    start_char: 22457,
    end_char: 22460,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "most",
    start_time: 1549400,
    end_time: 1549600,
    token_index: 4148,
    start_char: 22461,
    end_char: 22465,
    display_word: "most",
    period_at_end: false
    },
    {
    word: "sense.",
    start_time: 1549600,
    end_time: 1549900,
    token_index: 4149,
    start_char: 22466,
    end_char: 22471,
    display_word: "sense",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1549900,
    end_time: 1549900,
    token_index: 4150,
    start_char: 22473,
    end_char: 22474,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1549900,
    end_time: 1550200,
    token_index: 4151,
    start_char: 22475,
    end_char: 22480,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1550200,
    end_time: 1550700,
    token_index: 4152,
    start_char: 22481,
    end_char: 22484,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "most",
    start_time: 1550800,
    end_time: 1551000,
    token_index: 4153,
    start_char: 22485,
    end_char: 22489,
    display_word: "most",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1551000,
    end_time: 1551100,
    token_index: 4154,
    start_char: 22490,
    end_char: 22492,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1551100,
    end_time: 1551200,
    token_index: 4155,
    start_char: 22493,
    end_char: 22497,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "country.",
    start_time: 1551200,
    end_time: 1551700,
    token_index: 4156,
    start_char: 22498,
    end_char: 22505,
    display_word: "country",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 1551700,
    end_time: 1551800,
    token_index: 4157,
    start_char: 22507,
    end_char: 22510,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "dictionary",
    start_time: 1551800,
    end_time: 1552300,
    token_index: 4158,
    start_char: 22511,
    end_char: 22521,
    display_word: "dictionary",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1552300,
    end_time: 1552400,
    token_index: 4159,
    start_char: 22522,
    end_char: 22524,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "defined",
    start_time: 1552400,
    end_time: 1552800,
    token_index: 4160,
    start_char: 22525,
    end_char: 22532,
    display_word: "defined",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1552800,
    end_time: 1553500,
    token_index: 4161,
    start_char: 22533,
    end_char: 22539,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1553800,
    end_time: 1554400,
    token_index: 4162,
    start_char: 22540,
    end_char: 22542,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1554500,
    end_time: 1554700,
    token_index: 4163,
    start_char: 22543,
    end_char: 22547,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 1554700,
    end_time: 1554900,
    token_index: 4164,
    start_char: 22548,
    end_char: 22552,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "One",
    start_time: 1554900,
    end_time: 1555100,
    token_index: 4165,
    start_char: 22553,
    end_char: 22556,
    display_word: "One",
    period_at_end: false
    },
    {
    word: "race",
    start_time: 1555100,
    end_time: 1555300,
    token_index: 4166,
    start_char: 22557,
    end_char: 22561,
    display_word: "race",
    period_at_end: false
    },
    {
    word: "takes",
    start_time: 1555300,
    end_time: 1555600,
    token_index: 4167,
    start_char: 22562,
    end_char: 22567,
    display_word: "takes",
    period_at_end: false
    },
    {
    word: "another",
    start_time: 1555600,
    end_time: 1555900,
    token_index: 4168,
    start_char: 22568,
    end_char: 22575,
    display_word: "another",
    period_at_end: false
    },
    {
    word: "race,",
    start_time: 1555900,
    end_time: 1556400,
    token_index: 4169,
    start_char: 22576,
    end_char: 22581,
    display_word: "race,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1557100,
    end_time: 1557200,
    token_index: 4170,
    start_char: 22582,
    end_char: 22585,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1557200,
    end_time: 1557400,
    token_index: 4171,
    start_char: 22586,
    end_char: 22590,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "doesn't",
    start_time: 1557400,
    end_time: 1557700,
    token_index: 4172,
    start_char: 22591,
    end_char: 22598,
    display_word: "doesn't",
    period_at_end: false
    },
    {
    word: "make",
    start_time: 1557700,
    end_time: 1558000,
    token_index: 4173,
    start_char: 22599,
    end_char: 22603,
    display_word: "make",
    period_at_end: false
    },
    {
    word: "sense",
    start_time: 1558000,
    end_time: 1558300,
    token_index: 4174,
    start_char: 22604,
    end_char: 22609,
    display_word: "sense",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1558300,
    end_time: 1558400,
    token_index: 4175,
    start_char: 22610,
    end_char: 22613,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "definition",
    start_time: 1558400,
    end_time: 1558800,
    token_index: 4176,
    start_char: 22614,
    end_char: 22624,
    display_word: "definition",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1558800,
    end_time: 1558800,
    token_index: 4177,
    start_char: 22625,
    end_char: 22627,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1558800,
    end_time: 1559400,
    token_index: 4178,
    start_char: 22628,
    end_char: 22634,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1559600,
    end_time: 1559800,
    token_index: 4179,
    start_char: 22635,
    end_char: 22642,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1559800,
    end_time: 1560000,
    token_index: 4180,
    start_char: 22643,
    end_char: 22647,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "country",
    start_time: 1560000,
    end_time: 1560600,
    token_index: 4181,
    start_char: 22648,
    end_char: 22655,
    display_word: "country",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 1561400,
    end_time: 1561600,
    token_index: 4182,
    start_char: 22656,
    end_char: 22658,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1561600,
    end_time: 1561600,
    token_index: 4183,
    start_char: 22659,
    end_char: 22660,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1561600,
    end_time: 1561900,
    token_index: 4184,
    start_char: 22661,
    end_char: 22666,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "person",
    start_time: 1561900,
    end_time: 1562100,
    token_index: 4185,
    start_char: 22667,
    end_char: 22673,
    display_word: "person",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1562100,
    end_time: 1562200,
    token_index: 4186,
    start_char: 22674,
    end_char: 22677,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1562200,
    end_time: 1562200,
    token_index: 4187,
    start_char: 22678,
    end_char: 22679,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1562200,
    end_time: 1562500,
    token_index: 4188,
    start_char: 22680,
    end_char: 22685,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "person",
    start_time: 1562500,
    end_time: 1563000,
    token_index: 4189,
    start_char: 22686,
    end_char: 22692,
    display_word: "person",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1563100,
    end_time: 1563300,
    token_index: 4190,
    start_char: 22693,
    end_char: 22699,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1563300,
    end_time: 1563400,
    token_index: 4191,
    start_char: 22700,
    end_char: 22704,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "hate",
    start_time: 1563400,
    end_time: 1563900,
    token_index: 4192,
    start_char: 22705,
    end_char: 22709,
    display_word: "hate",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1564300,
    end_time: 1564400,
    token_index: 4193,
    start_char: 22710,
    end_char: 22713,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1564400,
    end_time: 1564500,
    token_index: 4194,
    start_char: 22714,
    end_char: 22717,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "most",
    start_time: 1564500,
    end_time: 1564700,
    token_index: 4195,
    start_char: 22718,
    end_char: 22722,
    display_word: "most",
    period_at_end: false
    },
    {
    word: "part",
    start_time: 1564700,
    end_time: 1565100,
    token_index: 4196,
    start_char: 22723,
    end_char: 22727,
    display_word: "part",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1565500,
    end_time: 1565900,
    token_index: 4197,
    start_char: 22728,
    end_char: 22731,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1565900,
    end_time: 1566000,
    token_index: 4198,
    start_char: 22732,
    end_char: 22735,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "system",
    start_time: 1566000,
    end_time: 1566400,
    token_index: 4199,
    start_char: 22736,
    end_char: 22742,
    display_word: "system",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1566400,
    end_time: 1566500,
    token_index: 4200,
    start_char: 22743,
    end_char: 22745,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "place",
    start_time: 1566500,
    end_time: 1567100,
    token_index: 4201,
    start_char: 22746,
    end_char: 22751,
    display_word: "place",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1567400,
    end_time: 1567600,
    token_index: 4202,
    start_char: 22752,
    end_char: 22754,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1567600,
    end_time: 1567800,
    token_index: 4203,
    start_char: 22755,
    end_char: 22760,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1567800,
    end_time: 1568500,
    token_index: 4204,
    start_char: 22761,
    end_char: 22770,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "means",
    start_time: 1568500,
    end_time: 1568800,
    token_index: 4205,
    start_char: 22771,
    end_char: 22776,
    display_word: "means",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1568800,
    end_time: 1569300,
    token_index: 4206,
    start_char: 22777,
    end_char: 22781,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 1569300,
    end_time: 1569600,
    token_index: 4207,
    start_char: 22782,
    end_char: 22786,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1569600,
    end_time: 1569900,
    token_index: 4208,
    start_char: 22787,
    end_char: 22792,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1569900,
    end_time: 1570400,
    token_index: 4209,
    start_char: 22793,
    end_char: 22799,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "hate",
    start_time: 1570700,
    end_time: 1571000,
    token_index: 4210,
    start_char: 22800,
    end_char: 22804,
    display_word: "hate",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1571000,
    end_time: 1571200,
    token_index: 4211,
    start_char: 22805,
    end_char: 22810,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "people,",
    start_time: 1571200,
    end_time: 1571600,
    token_index: 4212,
    start_char: 22811,
    end_char: 22818,
    display_word: "people,",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1571600,
    end_time: 1571800,
    token_index: 4213,
    start_char: 22819,
    end_char: 22823,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "actually",
    start_time: 1571800,
    end_time: 1572100,
    token_index: 4214,
    start_char: 22824,
    end_char: 22832,
    display_word: "actually",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1572100,
    end_time: 1572200,
    token_index: 4215,
    start_char: 22833,
    end_char: 22836,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "system",
    start_time: 1572200,
    end_time: 1572900,
    token_index: 4216,
    start_char: 22837,
    end_char: 22843,
    display_word: "system",
    period_at_end: false
    },
    {
    word: "encourages",
    start_time: 1573000,
    end_time: 1573800,
    token_index: 4217,
    start_char: 22844,
    end_char: 22854,
    display_word: "encourages",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1573800,
    end_time: 1573900,
    token_index: 4218,
    start_char: 22855,
    end_char: 22859,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "hatred",
    start_time: 1573900,
    end_time: 1574600,
    token_index: 4219,
    start_char: 22860,
    end_char: 22866,
    display_word: "hatred",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1574600,
    end_time: 1574800,
    token_index: 4220,
    start_char: 22867,
    end_char: 22870,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "puts",
    start_time: 1574800,
    end_time: 1575000,
    token_index: 4221,
    start_char: 22871,
    end_char: 22875,
    display_word: "puts",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1575000,
    end_time: 1575300,
    token_index: 4222,
    start_char: 22876,
    end_char: 22881,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "man",
    start_time: 1575300,
    end_time: 1575400,
    token_index: 4223,
    start_char: 22882,
    end_char: 22885,
    display_word: "man",
    period_at_end: false
    },
    {
    word: "puts",
    start_time: 1575400,
    end_time: 1575600,
    token_index: 4224,
    start_char: 22886,
    end_char: 22890,
    display_word: "puts",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 1575600,
    end_time: 1575800,
    token_index: 4225,
    start_char: 22891,
    end_char: 22896,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1575800,
    end_time: 1576000,
    token_index: 4226,
    start_char: 22897,
    end_char: 22903,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1576000,
    end_time: 1576000,
    token_index: 4227,
    start_char: 22904,
    end_char: 22906,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1576000,
    end_time: 1576100,
    token_index: 4228,
    start_char: 22907,
    end_char: 22910,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "bottle.",
    start_time: 1576100,
    end_time: 1576500,
    token_index: 4229,
    start_char: 22911,
    end_char: 22917,
    display_word: "bottle",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1576500,
    end_time: 1576700,
    token_index: 4230,
    start_char: 22919,
    end_char: 22921,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1576700,
    end_time: 1576700,
    token_index: 4231,
    start_char: 22922,
    end_char: 22923,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1576700,
    end_time: 1576900,
    token_index: 4232,
    start_char: 22924,
    end_char: 22929,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1576900,
    end_time: 1576900,
    token_index: 4233,
    start_char: 22930,
    end_char: 22934,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "Important",
    start_time: 1577000,
    end_time: 1577500,
    token_index: 4234,
    start_char: 22935,
    end_char: 22944,
    display_word: "Important",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1577500,
    end_time: 1577600,
    token_index: 4235,
    start_char: 22945,
    end_char: 22947,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1577600,
    end_time: 1577800,
    token_index: 4236,
    start_char: 22948,
    end_char: 22953,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1577800,
    end_time: 1577900,
    token_index: 4237,
    start_char: 22954,
    end_char: 22956,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1577900,
    end_time: 1578200,
    token_index: 4238,
    start_char: 22957,
    end_char: 22961,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "Prejudice",
    start_time: 1578300,
    end_time: 1578800,
    token_index: 4239,
    start_char: 22962,
    end_char: 22971,
    display_word: "Prejudice",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1578800,
    end_time: 1578900,
    token_index: 4240,
    start_char: 22972,
    end_char: 22974,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1578900,
    end_time: 1579100,
    token_index: 4241,
    start_char: 22975,
    end_char: 22979,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1579100,
    end_time: 1579500,
    token_index: 4242,
    start_char: 22980,
    end_char: 22984,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1579600,
    end_time: 1579700,
    token_index: 4243,
    start_char: 22985,
    end_char: 22986,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 1579700,
    end_time: 1579900,
    token_index: 4244,
    start_char: 22987,
    end_char: 22992,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1579900,
    end_time: 1580000,
    token_index: 4245,
    start_char: 22993,
    end_char: 22997,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1580000,
    end_time: 1580200,
    token_index: 4246,
    start_char: 22998,
    end_char: 23002,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1580200,
    end_time: 1580400,
    token_index: 4247,
    start_char: 23003,
    end_char: 23008,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "guy",
    start_time: 1580400,
    end_time: 1580600,
    token_index: 4248,
    start_char: 23009,
    end_char: 23012,
    display_word: "guy",
    period_at_end: false
    },
    {
    word: "Stephen",
    start_time: 1580600,
    end_time: 1580900,
    token_index: 4249,
    start_char: 23013,
    end_char: 23020,
    display_word: "Stephen",
    period_at_end: false
    },
    {
    word: "Colbert.",
    start_time: 1580900,
    end_time: 1581400,
    token_index: 4250,
    start_char: 23021,
    end_char: 23028,
    display_word: "Colbert",
    period_at_end: true
    },
    {
    word: "That's",
    start_time: 1581400,
    end_time: 1581500,
    token_index: 4251,
    start_char: 23030,
    end_char: 23036,
    display_word: "That's",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1581500,
    end_time: 1581700,
    token_index: 4252,
    start_char: 23037,
    end_char: 23041,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1581700,
    end_time: 1582200,
    token_index: 4253,
    start_char: 23042,
    end_char: 23044,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "hating",
    start_time: 1582200,
    end_time: 1582600,
    token_index: 4254,
    start_char: 23045,
    end_char: 23051,
    display_word: "hating",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1582600,
    end_time: 1583000,
    token_index: 4255,
    start_char: 23052,
    end_char: 23055,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1583300,
    end_time: 1583700,
    token_index: 4256,
    start_char: 23056,
    end_char: 23059,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1583700,
    end_time: 1583900,
    token_index: 4257,
    start_char: 23060,
    end_char: 23064,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "row",
    start_time: 1583900,
    end_time: 1584200,
    token_index: 4258,
    start_char: 23065,
    end_char: 23068,
    display_word: "row",
    period_at_end: false
    },
    {
    word: "true",
    start_time: 1584200,
    end_time: 1584500,
    token_index: 4259,
    start_char: 23069,
    end_char: 23073,
    display_word: "true",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1584500,
    end_time: 1585000,
    token_index: 4260,
    start_char: 23074,
    end_char: 23080,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "needs",
    start_time: 1585000,
    end_time: 1585200,
    token_index: 4261,
    start_char: 23081,
    end_char: 23086,
    display_word: "needs",
    period_at_end: false
    },
    {
    word: "power",
    start_time: 1585200,
    end_time: 1585600,
    token_index: 4262,
    start_char: 23087,
    end_char: 23092,
    display_word: "power",
    period_at_end: false
    },
    {
    word: "behind",
    start_time: 1585600,
    end_time: 1586000,
    token_index: 4263,
    start_char: 23093,
    end_char: 23099,
    display_word: "behind",
    period_at_end: false
    },
    {
    word: "it.",
    start_time: 1586000,
    end_time: 1586100,
    token_index: 4264,
    start_char: 23100,
    end_char: 23102,
    display_word: "it",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 1586100,
    end_time: 1586200,
    token_index: 4265,
    start_char: 23104,
    end_char: 23107,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1586200,
    end_time: 1586200,
    token_index: 4266,
    start_char: 23108,
    end_char: 23109,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1586200,
    end_time: 1586400,
    token_index: 4267,
    start_char: 23110,
    end_char: 23115,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1586400,
    end_time: 1586600,
    token_index: 4268,
    start_char: 23116,
    end_char: 23122,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1586600,
    end_time: 1586700,
    token_index: 4269,
    start_char: 23123,
    end_char: 23127,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1586700,
    end_time: 1586800,
    token_index: 4270,
    start_char: 23128,
    end_char: 23132,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1586800,
    end_time: 1586800,
    token_index: 4271,
    start_char: 23133,
    end_char: 23137,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "trying",
    start_time: 1586800,
    end_time: 1587000,
    token_index: 4272,
    start_char: 23138,
    end_char: 23144,
    display_word: "trying",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1587000,
    end_time: 1587100,
    token_index: 4273,
    start_char: 23145,
    end_char: 23147,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "do.",
    start_time: 1587100,
    end_time: 1587200,
    token_index: 4274,
    start_char: 23148,
    end_char: 23150,
    display_word: "do",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 1587200,
    end_time: 1587300,
    token_index: 4275,
    start_char: 23152,
    end_char: 23155,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1587300,
    end_time: 1587700,
    token_index: 4276,
    start_char: 23156,
    end_char: 23160,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1587900,
    end_time: 1588000,
    token_index: 4277,
    start_char: 23161,
    end_char: 23163,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1588000,
    end_time: 1588200,
    token_index: 4278,
    start_char: 23164,
    end_char: 23170,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "let",
    start_time: 1588200,
    end_time: 1588300,
    token_index: 4279,
    start_char: 23171,
    end_char: 23174,
    display_word: "let",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1588300,
    end_time: 1588500,
    token_index: 4280,
    start_char: 23175,
    end_char: 23181,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "understand",
    start_time: 1588500,
    end_time: 1589000,
    token_index: 4281,
    start_char: 23182,
    end_char: 23192,
    display_word: "understand",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1589000,
    end_time: 1589100,
    token_index: 4282,
    start_char: 23193,
    end_char: 23197,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1589100,
    end_time: 1589500,
    token_index: 4283,
    start_char: 23198,
    end_char: 23202,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1589600,
    end_time: 1589800,
    token_index: 4284,
    start_char: 23203,
    end_char: 23207,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "isn't",
    start_time: 1589800,
    end_time: 1590000,
    token_index: 4285,
    start_char: 23208,
    end_char: 23213,
    display_word: "isn't",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1590000,
    end_time: 1590200,
    token_index: 4286,
    start_char: 23214,
    end_char: 23218,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1590200,
    end_time: 1590400,
    token_index: 4287,
    start_char: 23219,
    end_char: 23224,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "feelings.",
    start_time: 1590400,
    end_time: 1591100,
    token_index: 4288,
    start_char: 23225,
    end_char: 23233,
    display_word: "feelings",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 1591200,
    end_time: 1591300,
    token_index: 4289,
    start_char: 23235,
    end_char: 23238,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 1591300,
    end_time: 1591500,
    token_index: 4290,
    start_char: 23239,
    end_char: 23242,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "prove",
    start_time: 1591500,
    end_time: 1591800,
    token_index: 4291,
    start_char: 23243,
    end_char: 23248,
    display_word: "prove",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1591800,
    end_time: 1592000,
    token_index: 4292,
    start_char: 23249,
    end_char: 23252,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1592000,
    end_time: 1592200,
    token_index: 4293,
    start_char: 23253,
    end_char: 23257,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "stuff",
    start_time: 1592200,
    end_time: 1592600,
    token_index: 4294,
    start_char: 23258,
    end_char: 23263,
    display_word: "stuff",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1592900,
    end_time: 1593400,
    token_index: 4295,
    start_char: 23264,
    end_char: 23270,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1593400,
    end_time: 1593500,
    token_index: 4296,
    start_char: 23271,
    end_char: 23274,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1593500,
    end_time: 1593700,
    token_index: 4297,
    start_char: 23275,
    end_char: 23280,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy",
    start_time: 1593700,
    end_time: 1594200,
    token_index: 4298,
    start_char: 23281,
    end_char: 23290,
    display_word: "supremacy",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1594200,
    end_time: 1594300,
    token_index: 4299,
    start_char: 23291,
    end_char: 23293,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "statistical.",
    start_time: 1594300,
    end_time: 1595100,
    token_index: 4300,
    start_char: 23294,
    end_char: 23305,
    display_word: "statistical",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 1595200,
    end_time: 1595200,
    token_index: 4301,
    start_char: 23307,
    end_char: 23310,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 1595200,
    end_time: 1595400,
    token_index: 4302,
    start_char: 23311,
    end_char: 23314,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "look",
    start_time: 1595400,
    end_time: 1595600,
    token_index: 4303,
    start_char: 23315,
    end_char: 23319,
    display_word: "look",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 1595600,
    end_time: 1595800,
    token_index: 4304,
    start_char: 23320,
    end_char: 23322,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1595800,
    end_time: 1595900,
    token_index: 4305,
    start_char: 23323,
    end_char: 23325,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 1595900,
    end_time: 1596000,
    token_index: 4306,
    start_char: 23326,
    end_char: 23328,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "scientific",
    start_time: 1596000,
    end_time: 1596900,
    token_index: 4307,
    start_char: 23329,
    end_char: 23339,
    display_word: "scientific",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 1597000,
    end_time: 1597400,
    token_index: 4308,
    start_char: 23340,
    end_char: 23343,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "race",
    start_time: 1597500,
    end_time: 1597800,
    token_index: 4309,
    start_char: 23344,
    end_char: 23348,
    display_word: "race",
    period_at_end: false
    },
    {
    word: "isn't",
    start_time: 1597800,
    end_time: 1598000,
    token_index: 4310,
    start_char: 23349,
    end_char: 23354,
    display_word: "isn't",
    period_at_end: false
    },
    {
    word: "scientific.",
    start_time: 1598000,
    end_time: 1598500,
    token_index: 4311,
    start_char: 23355,
    end_char: 23365,
    display_word: "scientific",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 1598500,
    end_time: 1598600,
    token_index: 4312,
    start_char: 23367,
    end_char: 23369,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "made",
    start_time: 1598600,
    end_time: 1598800,
    token_index: 4313,
    start_char: 23370,
    end_char: 23374,
    display_word: "made",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1598800,
    end_time: 1598900,
    token_index: 4314,
    start_char: 23375,
    end_char: 23377,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1598900,
    end_time: 1599000,
    token_index: 4315,
    start_char: 23378,
    end_char: 23380,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1599000,
    end_time: 1599100,
    token_index: 4316,
    start_char: 23381,
    end_char: 23383,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1599100,
    end_time: 1599200,
    token_index: 4317,
    start_char: 23384,
    end_char: 23387,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "said,",
    start_time: 1599200,
    end_time: 1599700,
    token_index: 4318,
    start_char: 23388,
    end_char: 23393,
    display_word: "said,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1599800,
    end_time: 1600000,
    token_index: 4319,
    start_char: 23394,
    end_char: 23397,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1600000,
    end_time: 1600300,
    token_index: 4320,
    start_char: 23398,
    end_char: 23401,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1600300,
    end_time: 1600600,
    token_index: 4321,
    start_char: 23402,
    end_char: 23405,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1601000,
    end_time: 1601200,
    token_index: 4322,
    start_char: 23406,
    end_char: 23409,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1601200,
    end_time: 1601400,
    token_index: 4323,
    start_char: 23410,
    end_char: 23413,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1601400,
    end_time: 1601500,
    token_index: 4324,
    start_char: 23414,
    end_char: 23417,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1601500,
    end_time: 1601800,
    token_index: 4325,
    start_char: 23418,
    end_char: 23424,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1601800,
    end_time: 1601900,
    token_index: 4326,
    start_char: 23425,
    end_char: 23429,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 1601900,
    end_time: 1602000,
    token_index: 4327,
    start_char: 23430,
    end_char: 23432,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 1602000,
    end_time: 1602300,
    token_index: 4328,
    start_char: 23433,
    end_char: 23437,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "racism",
    start_time: 1602300,
    end_time: 1602700,
    token_index: 4329,
    start_char: 23438,
    end_char: 23444,
    display_word: "racism",
    period_at_end: false
    },
    {
    word: "white",
    start_time: 1602700,
    end_time: 1602800,
    token_index: 4330,
    start_char: 23445,
    end_char: 23450,
    display_word: "white",
    period_at_end: false
    },
    {
    word: "supremacy,",
    start_time: 1602800,
    end_time: 1603400,
    token_index: 4331,
    start_char: 23451,
    end_char: 23461,
    display_word: "supremacy,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1603500,
    end_time: 1603600,
    token_index: 4332,
    start_char: 23462,
    end_char: 23465,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 1603600,
    end_time: 1603700,
    token_index: 4333,
    start_char: 23466,
    end_char: 23469,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1603700,
    end_time: 1604100,
    token_index: 4334,
    start_char: 23470,
    end_char: 23473,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "Well",
    start_time: 1605200,
    end_time: 1605500,
    token_index: 4335,
    start_char: 23474,
    end_char: 23478,
    display_word: "Well",
    period_at_end: false
    },
    {
    word: "season",
    start_time: 1605500,
    end_time: 1605900,
    token_index: 4336,
    start_char: 23479,
    end_char: 23485,
    display_word: "season",
    period_at_end: false
    },
    {
    word: "5",
    start_time: 1605900,
    end_time: 1606500,
    token_index: 4337,
    start_char: 23486,
    end_char: 23487,
    display_word: "5",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1606500,
    end_time: 1606700,
    token_index: 4338,
    start_char: 23488,
    end_char: 23490,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "United",
    start_time: 1606700,
    end_time: 1607200,
    token_index: 4339,
    start_char: 23491,
    end_char: 23497,
    display_word: "United",
    period_at_end: false
    },
    {
    word: "shades",
    start_time: 1607200,
    end_time: 1607600,
    token_index: 4340,
    start_char: 23498,
    end_char: 23504,
    display_word: "shades",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1607600,
    end_time: 1607600,
    token_index: 4341,
    start_char: 23505,
    end_char: 23507,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "America",
    start_time: 1607600,
    end_time: 1608200,
    token_index: 4342,
    start_char: 23508,
    end_char: 23515,
    display_word: "America",
    period_at_end: false
    },
    {
    word: "premieres",
    start_time: 1608200,
    end_time: 1608600,
    token_index: 4343,
    start_char: 23516,
    end_char: 23525,
    display_word: "premieres",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 1608600,
    end_time: 1608900,
    token_index: 4344,
    start_char: 23526,
    end_char: 23530,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "Sunday",
    start_time: 1608900,
    end_time: 1609500,
    token_index: 4345,
    start_char: 23531,
    end_char: 23537,
    display_word: "Sunday",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1609600,
    end_time: 1609800,
    token_index: 4346,
    start_char: 23538,
    end_char: 23540,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "CNN",
    start_time: 1609800,
    end_time: 1610600,
    token_index: 4347,
    start_char: 23541,
    end_char: 23544,
    display_word: "CNN",
    period_at_end: false
    },
    {
    word: "W.",
    start_time: 1610700,
    end_time: 1611100,
    token_index: 4348,
    start_char: 23545,
    end_char: 23546,
    display_word: "W",
    period_at_end: true
    },
    {
    word: "Kamau",
    start_time: 1611100,
    end_time: 1611700,
    token_index: 4349,
    start_char: 23548,
    end_char: 23553,
    display_word: "Kamau",
    period_at_end: false
    },
    {
    word: "Bell",
    start_time: 1611700,
    end_time: 1612100,
    token_index: 4350,
    start_char: 23554,
    end_char: 23558,
    display_word: "Bell",
    period_at_end: false
    },
    {
    word: "everybody.",
    start_time: 1612100,
    end_time: 1612700,
    token_index: 4351,
    start_char: 23559,
    end_char: 23568,
    display_word: "everybody",
    period_at_end: true
    },
    {
    word: "Thanks",
    start_time: 1612900,
    end_time: 1613200,
    token_index: 4352,
    start_char: 23570,
    end_char: 23576,
    display_word: "Thanks",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1613200,
    end_time: 1613300,
    token_index: 4353,
    start_char: 23577,
    end_char: 23580,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "being",
    start_time: 1613300,
    end_time: 1613500,
    token_index: 4354,
    start_char: 23581,
    end_char: 23586,
    display_word: "being",
    period_at_end: false
    },
    {
    word: "here.",
    start_time: 1613500,
    end_time: 1613900,
    token_index: 4355,
    start_char: 23587,
    end_char: 23591,
    display_word: "here",
    period_at_end: true
    },
    {
    word: "We'll",
    start_time: 1614100,
    end_time: 1614200,
    token_index: 4356,
    start_char: 23593,
    end_char: 23598,
    display_word: "We'll",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1614200,
    end_time: 1614300,
    token_index: 4357,
    start_char: 23599,
    end_char: 23601,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1614300,
    end_time: 1614500,
    token_index: 4358,
    start_char: 23602,
    end_char: 23607,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1614500,
    end_time: 1614900,
    token_index: 4359,
    start_char: 23608,
    end_char: 23612,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1614900,
    end_time: 1615300,
    token_index: 4360,
    start_char: 23613,
    end_char: 23617,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1615500,
    end_time: 1615900,
    token_index: 4361,
    start_char: 23618,
    end_char: 23621,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks.",
    start_time: 1615900,
    end_time: 1616700,
    token_index: 4362,
    start_char: 23622,
    end_char: 23628,
    display_word: "chicks",
    period_at_end: true
    },
    {
    word: "Welcome",
    start_time: 1629100,
    end_time: 1629500,
    token_index: 4363,
    start_char: 23630,
    end_char: 23637,
    display_word: "Welcome",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1629500,
    end_time: 1629700,
    token_index: 4364,
    start_char: 23638,
    end_char: 23642,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "everybody.",
    start_time: 1629700,
    end_time: 1630400,
    token_index: 4365,
    start_char: 23643,
    end_char: 23652,
    display_word: "everybody",
    period_at_end: true
    },
    {
    word: "My",
    start_time: 1630700,
    end_time: 1630900,
    token_index: 4366,
    start_char: 23654,
    end_char: 23656,
    display_word: "My",
    period_at_end: false
    },
    {
    word: "next",
    start_time: 1630900,
    end_time: 1631300,
    token_index: 4367,
    start_char: 23657,
    end_char: 23661,
    display_word: "next",
    period_at_end: false
    },
    {
    word: "guests",
    start_time: 1631300,
    end_time: 1631800,
    token_index: 4368,
    start_char: 23662,
    end_char: 23668,
    display_word: "guests",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1631800,
    end_time: 1632000,
    token_index: 4369,
    start_char: 23669,
    end_char: 23673,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "113",
    start_time: 1632000,
    end_time: 1633100,
    token_index: 4370,
    start_char: 23674,
    end_char: 23677,
    display_word: "113",
    period_at_end: false
    },
    {
    word: "Grammy",
    start_time: 1633100,
    end_time: 1633600,
    token_index: 4371,
    start_char: 23678,
    end_char: 23684,
    display_word: "Grammy",
    period_at_end: false
    },
    {
    word: "Awards",
    start_time: 1633600,
    end_time: 1634100,
    token_index: 4372,
    start_char: 23685,
    end_char: 23691,
    display_word: "Awards",
    period_at_end: false
    },
    {
    word: "sold",
    start_time: 1634100,
    end_time: 1634300,
    token_index: 4373,
    start_char: 23692,
    end_char: 23696,
    display_word: "sold",
    period_at_end: false
    },
    {
    word: "over.",
    start_time: 1634300,
    end_time: 1634500,
    token_index: 4374,
    start_char: 23697,
    end_char: 23701,
    display_word: "over",
    period_at_end: true
    },
    {
    word: "30",
    start_time: 1634700,
    end_time: 1634900,
    token_index: 4375,
    start_char: 23703,
    end_char: 23705,
    display_word: "30",
    period_at_end: false
    },
    {
    word: "million",
    start_time: 1634900,
    end_time: 1635300,
    token_index: 4376,
    start_char: 23706,
    end_char: 23713,
    display_word: "million",
    period_at_end: false
    },
    {
    word: "records",
    start_time: 1635300,
    end_time: 1635700,
    token_index: 4377,
    start_char: 23714,
    end_char: 23721,
    display_word: "records",
    period_at_end: false
    },
    {
    word: "worldwide",
    start_time: 1635700,
    end_time: 1636400,
    token_index: 4378,
    start_char: 23722,
    end_char: 23731,
    display_word: "worldwide",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1636400,
    end_time: 1636500,
    token_index: 4379,
    start_char: 23732,
    end_char: 23735,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "they're",
    start_time: 1636500,
    end_time: 1636700,
    token_index: 4380,
    start_char: 23736,
    end_char: 23743,
    display_word: "they're",
    period_at_end: false
    },
    {
    word: "making",
    start_time: 1636700,
    end_time: 1637100,
    token_index: 4381,
    start_char: 23744,
    end_char: 23750,
    display_word: "making",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 1637100,
    end_time: 1637300,
    token_index: 4382,
    start_char: 23751,
    end_char: 23754,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "music",
    start_time: 1637300,
    end_time: 1637900,
    token_index: 4383,
    start_char: 23755,
    end_char: 23760,
    display_word: "music",
    period_at_end: false
    },
    {
    word: "after",
    start_time: 1637900,
    end_time: 1638100,
    token_index: 4384,
    start_char: 23761,
    end_char: 23766,
    display_word: "after",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1638100,
    end_time: 1638200,
    token_index: 4385,
    start_char: 23767,
    end_char: 23768,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "14",
    start_time: 1638200,
    end_time: 1639100,
    token_index: 4386,
    start_char: 23769,
    end_char: 23771,
    display_word: "14",
    period_at_end: false
    },
    {
    word: "year",
    start_time: 1639100,
    end_time: 1639300,
    token_index: 4387,
    start_char: 23772,
    end_char: 23776,
    display_word: "year",
    period_at_end: false
    },
    {
    word: "Hiatus,",
    start_time: 1639300,
    end_time: 1640100,
    token_index: 4388,
    start_char: 23777,
    end_char: 23784,
    display_word: "Hiatus,",
    period_at_end: false
    },
    {
    word: "please",
    start_time: 1640300,
    end_time: 1640600,
    token_index: 4389,
    start_char: 23785,
    end_char: 23791,
    display_word: "please",
    period_at_end: false
    },
    {
    word: "welcome",
    start_time: 1640600,
    end_time: 1641200,
    token_index: 4390,
    start_char: 23792,
    end_char: 23799,
    display_word: "welcome",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1641500,
    end_time: 1641700,
    token_index: 4391,
    start_char: 23800,
    end_char: 23803,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks.",
    start_time: 1641700,
    end_time: 1642400,
    token_index: 4392,
    start_char: 23804,
    end_char: 23810,
    display_word: "chicks",
    period_at_end: true
    },
    {
    word: "Hello",
    start_time: 1642700,
    end_time: 1643000,
    token_index: 4393,
    start_char: 23812,
    end_char: 23817,
    display_word: "Hello",
    period_at_end: false
    },
    {
    word: "chicks.",
    start_time: 1643000,
    end_time: 1643800,
    token_index: 4394,
    start_char: 23818,
    end_char: 23824,
    display_word: "chicks",
    period_at_end: true
    },
    {
    word: "Thanks",
    start_time: 1645400,
    end_time: 1645800,
    token_index: 4395,
    start_char: 23826,
    end_char: 23832,
    display_word: "Thanks",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1645800,
    end_time: 1646000,
    token_index: 4396,
    start_char: 23833,
    end_char: 23836,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "being",
    start_time: 1646000,
    end_time: 1646300,
    token_index: 4397,
    start_char: 23837,
    end_char: 23842,
    display_word: "being",
    period_at_end: false
    },
    {
    word: "here.",
    start_time: 1646300,
    end_time: 1646700,
    token_index: 4398,
    start_char: 23843,
    end_char: 23847,
    display_word: "here",
    period_at_end: true
    },
    {
    word: "Natalie",
    start_time: 1647000,
    end_time: 1647500,
    token_index: 4399,
    start_char: 23849,
    end_char: 23856,
    display_word: "Natalie",
    period_at_end: false
    },
    {
    word: "Marty",
    start_time: 1647500,
    end_time: 1647900,
    token_index: 4400,
    start_char: 23857,
    end_char: 23862,
    display_word: "Marty",
    period_at_end: false
    },
    {
    word: "Emily.",
    start_time: 1647900,
    end_time: 1648300,
    token_index: 4401,
    start_char: 23863,
    end_char: 23868,
    display_word: "Emily",
    period_at_end: true
    },
    {
    word: "Nice",
    start_time: 1648300,
    end_time: 1648500,
    token_index: 4402,
    start_char: 23870,
    end_char: 23874,
    display_word: "Nice",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1648500,
    end_time: 1648600,
    token_index: 4403,
    start_char: 23875,
    end_char: 23877,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1648600,
    end_time: 1648900,
    token_index: 4404,
    start_char: 23878,
    end_char: 23881,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "you.",
    start_time: 1648900,
    end_time: 1649200,
    token_index: 4405,
    start_char: 23882,
    end_char: 23885,
    display_word: "you",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 1649600,
    end_time: 1649800,
    token_index: 4406,
    start_char: 23887,
    end_char: 23890,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 1649800,
    end_time: 1650000,
    token_index: 4407,
    start_char: 23891,
    end_char: 23894,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 1651100,
    end_time: 1651300,
    token_index: 4408,
    start_char: 23895,
    end_char: 23898,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1651300,
    end_time: 1651400,
    token_index: 4409,
    start_char: 23899,
    end_char: 23903,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1651400,
    end_time: 1651800,
    token_index: 4410,
    start_char: 23904,
    end_char: 23910,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 1651800,
    end_time: 1651900,
    token_index: 4411,
    start_char: 23911,
    end_char: 23913,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "home",
    start_time: 1651900,
    end_time: 1652100,
    token_index: 4412,
    start_char: 23914,
    end_char: 23918,
    display_word: "home",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 1652100,
    end_time: 1652400,
    token_index: 4413,
    start_char: 23919,
    end_char: 23924,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1652400,
    end_time: 1653000,
    token_index: 4414,
    start_char: 23925,
    end_char: 23929,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1653200,
    end_time: 1653500,
    token_index: 4415,
    start_char: 23930,
    end_char: 23932,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1653500,
    end_time: 1654200,
    token_index: 4416,
    start_char: 23933,
    end_char: 23937,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1655300,
    end_time: 1655400,
    token_index: 4417,
    start_char: 23938,
    end_char: 23940,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1655400,
    end_time: 1655600,
    token_index: 4418,
    start_char: 23941,
    end_char: 23944,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1655600,
    end_time: 1655900,
    token_index: 4419,
    start_char: 23945,
    end_char: 23947,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1655900,
    end_time: 1656000,
    token_index: 4420,
    start_char: 23948,
    end_char: 23951,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "night",
    start_time: 1656000,
    end_time: 1656200,
    token_index: 4421,
    start_char: 23952,
    end_char: 23957,
    display_word: "night",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1656200,
    end_time: 1656400,
    token_index: 4422,
    start_char: 23958,
    end_char: 23960,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1656400,
    end_time: 1656500,
    token_index: 4423,
    start_char: 23961,
    end_char: 23964,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "last",
    start_time: 1656500,
    end_time: 1657000,
    token_index: 4424,
    start_char: 23965,
    end_char: 23969,
    display_word: "last",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1657000,
    end_time: 1657400,
    token_index: 4425,
    start_char: 23970,
    end_char: 23974,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1657400,
    end_time: 1657500,
    token_index: 4426,
    start_char: 23975,
    end_char: 23979,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1657500,
    end_time: 1657600,
    token_index: 4427,
    start_char: 23980,
    end_char: 23982,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 1657600,
    end_time: 1657800,
    token_index: 4428,
    start_char: 23983,
    end_char: 23986,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1657800,
    end_time: 1657900,
    token_index: 4429,
    start_char: 23987,
    end_char: 23989,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1657900,
    end_time: 1658000,
    token_index: 4430,
    start_char: 23990,
    end_char: 23993,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "theater",
    start_time: 1658000,
    end_time: 1658400,
    token_index: 4431,
    start_char: 23994,
    end_char: 24001,
    display_word: "theater",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 1658400,
    end_time: 1658600,
    token_index: 4432,
    start_char: 24002,
    end_char: 24008,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1658600,
    end_time: 1658800,
    token_index: 4433,
    start_char: 24009,
    end_char: 24011,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1658800,
    end_time: 1658900,
    token_index: 4434,
    start_char: 24012,
    end_char: 24015,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1658900,
    end_time: 1659000,
    token_index: 4435,
    start_char: 24016,
    end_char: 24018,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "bug",
    start_time: 1659000,
    end_time: 1659400,
    token_index: 4436,
    start_char: 24019,
    end_char: 24022,
    display_word: "bug",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 1659400,
    end_time: 1659700,
    token_index: 4437,
    start_char: 24023,
    end_char: 24026,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1659700,
    end_time: 1660200,
    token_index: 4438,
    start_char: 24027,
    end_char: 24034,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1660200,
    end_time: 1660300,
    token_index: 4439,
    start_char: 24035,
    end_char: 24037,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1660300,
    end_time: 1660400,
    token_index: 4440,
    start_char: 24038,
    end_char: 24041,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "coronavirus",
    start_time: 1660400,
    end_time: 1660900,
    token_index: 4441,
    start_char: 24042,
    end_char: 24053,
    display_word: "coronavirus",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1660900,
    end_time: 1662000,
    token_index: 4442,
    start_char: 24054,
    end_char: 24057,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1662000,
    end_time: 1662200,
    token_index: 4443,
    start_char: 24058,
    end_char: 24062,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "supposed",
    start_time: 1662200,
    end_time: 1662500,
    token_index: 4444,
    start_char: 24063,
    end_char: 24071,
    display_word: "supposed",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1662500,
    end_time: 1662600,
    token_index: 4445,
    start_char: 24072,
    end_char: 24074,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1662600,
    end_time: 1662700,
    token_index: 4446,
    start_char: 24075,
    end_char: 24077,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1662700,
    end_time: 1662800,
    token_index: 4447,
    start_char: 24078,
    end_char: 24080,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "guest",
    start_time: 1662800,
    end_time: 1663100,
    token_index: 4448,
    start_char: 24081,
    end_char: 24086,
    display_word: "guest",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1663100,
    end_time: 1663300,
    token_index: 4449,
    start_char: 24087,
    end_char: 24093,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "night.",
    start_time: 1663300,
    end_time: 1663600,
    token_index: 4450,
    start_char: 24094,
    end_char: 24099,
    display_word: "night",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 1664500,
    end_time: 1665200,
    token_index: 4451,
    start_char: 24101,
    end_char: 24104,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "instead",
    start_time: 1665600,
    end_time: 1666400,
    token_index: 4452,
    start_char: 24105,
    end_char: 24112,
    display_word: "instead",
    period_at_end: false
    },
    {
    word: "Sanjay",
    start_time: 1666500,
    end_time: 1666900,
    token_index: 4453,
    start_char: 24113,
    end_char: 24119,
    display_word: "Sanjay",
    period_at_end: false
    },
    {
    word: "Gupta",
    start_time: 1666900,
    end_time: 1667300,
    token_index: 4454,
    start_char: 24120,
    end_char: 24125,
    display_word: "Gupta",
    period_at_end: false
    },
    {
    word: "came",
    start_time: 1667300,
    end_time: 1667600,
    token_index: 4455,
    start_char: 24126,
    end_char: 24130,
    display_word: "came",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1667600,
    end_time: 1668000,
    token_index: 4456,
    start_char: 24131,
    end_char: 24133,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1668600,
    end_time: 1669100,
    token_index: 4457,
    start_char: 24134,
    end_char: 24137,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1669400,
    end_time: 1669800,
    token_index: 4458,
    start_char: 24138,
    end_char: 24142,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "rocked",
    start_time: 1669800,
    end_time: 1670300,
    token_index: 4459,
    start_char: 24143,
    end_char: 24149,
    display_word: "rocked",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1670300,
    end_time: 1670500,
    token_index: 4460,
    start_char: 24150,
    end_char: 24153,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1670500,
    end_time: 1670700,
    token_index: 4461,
    start_char: 24154,
    end_char: 24158,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "head",
    start_time: 1670700,
    end_time: 1671100,
    token_index: 4462,
    start_char: 24159,
    end_char: 24163,
    display_word: "head",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 1671300,
    end_time: 1674500,
    token_index: 4463,
    start_char: 24164,
    end_char: 24170,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1674500,
    end_time: 1674600,
    token_index: 4464,
    start_char: 24171,
    end_char: 24175,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "gas",
    start_time: 1674600,
    end_time: 1675000,
    token_index: 4465,
    start_char: 24176,
    end_char: 24179,
    display_word: "gas",
    period_at_end: false
    },
    {
    word: "water.",
    start_time: 1675000,
    end_time: 1675500,
    token_index: 4466,
    start_char: 24180,
    end_char: 24185,
    display_word: "water",
    period_at_end: true
    },
    {
    word: "Oh,",
    start_time: 1675800,
    end_time: 1675900,
    token_index: 4467,
    start_char: 24187,
    end_char: 24190,
    display_word: "Oh,",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 1675900,
    end_time: 1676300,
    token_index: 4468,
    start_char: 24191,
    end_char: 24193,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "Goodbye",
    start_time: 1676300,
    end_time: 1676700,
    token_index: 4469,
    start_char: 24194,
    end_char: 24201,
    display_word: "Goodbye",
    period_at_end: false
    },
    {
    word: "Earl.",
    start_time: 1676700,
    end_time: 1677000,
    token_index: 4470,
    start_char: 24202,
    end_char: 24206,
    display_word: "Earl",
    period_at_end: true
    },
    {
    word: "He",
    start_time: 1677000,
    end_time: 1677200,
    token_index: 4471,
    start_char: 24208,
    end_char: 24210,
    display_word: "He",
    period_at_end: false
    },
    {
    word: "knew",
    start_time: 1677200,
    end_time: 1677300,
    token_index: 4472,
    start_char: 24211,
    end_char: 24215,
    display_word: "knew",
    period_at_end: false
    },
    {
    word: "Goodbye",
    start_time: 1677300,
    end_time: 1677700,
    token_index: 4473,
    start_char: 24216,
    end_char: 24223,
    display_word: "Goodbye",
    period_at_end: false
    },
    {
    word: "Earl",
    start_time: 1677700,
    end_time: 1678500,
    token_index: 4474,
    start_char: 24224,
    end_char: 24228,
    display_word: "Earl",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1678700,
    end_time: 1678800,
    token_index: 4475,
    start_char: 24229,
    end_char: 24232,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1678800,
    end_time: 1678900,
    token_index: 4476,
    start_char: 24233,
    end_char: 24235,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1678900,
    end_time: 1679100,
    token_index: 4477,
    start_char: 24236,
    end_char: 24240,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "one,",
    start_time: 1679100,
    end_time: 1679500,
    token_index: 4478,
    start_char: 24241,
    end_char: 24245,
    display_word: "one,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1680100,
    end_time: 1680300,
    token_index: 4479,
    start_char: 24246,
    end_char: 24249,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1680300,
    end_time: 1680300,
    token_index: 4480,
    start_char: 24250,
    end_char: 24251,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1680300,
    end_time: 1680500,
    token_index: 4481,
    start_char: 24252,
    end_char: 24256,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1680500,
    end_time: 1680500,
    token_index: 4482,
    start_char: 24257,
    end_char: 24258,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 1680500,
    end_time: 1680600,
    token_index: 4483,
    start_char: 24259,
    end_char: 24262,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "play",
    start_time: 1680600,
    end_time: 1680800,
    token_index: 4484,
    start_char: 24263,
    end_char: 24267,
    display_word: "play",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1680800,
    end_time: 1680900,
    token_index: 4485,
    start_char: 24268,
    end_char: 24271,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "banjo.",
    start_time: 1680900,
    end_time: 1681400,
    token_index: 4486,
    start_char: 24272,
    end_char: 24277,
    display_word: "banjo",
    period_at_end: true
    },
    {
    word: "Anyway,",
    start_time: 1681400,
    end_time: 1681600,
    token_index: 4487,
    start_char: 24279,
    end_char: 24286,
    display_word: "Anyway,",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1681600,
    end_time: 1681800,
    token_index: 4488,
    start_char: 24287,
    end_char: 24291,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1681800,
    end_time: 1681900,
    token_index: 4489,
    start_char: 24292,
    end_char: 24294,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "nice",
    start_time: 1681900,
    end_time: 1682100,
    token_index: 4490,
    start_char: 24295,
    end_char: 24299,
    display_word: "nice",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1682100,
    end_time: 1682300,
    token_index: 4491,
    start_char: 24300,
    end_char: 24302,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1682300,
    end_time: 1682500,
    token_index: 4492,
    start_char: 24303,
    end_char: 24306,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "you,",
    start_time: 1682500,
    end_time: 1682800,
    token_index: 4493,
    start_char: 24307,
    end_char: 24311,
    display_word: "you,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1683100,
    end_time: 1683200,
    token_index: 4494,
    start_char: 24312,
    end_char: 24315,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1683200,
    end_time: 1683300,
    token_index: 4495,
    start_char: 24316,
    end_char: 24318,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1683300,
    end_time: 1683500,
    token_index: 4496,
    start_char: 24319,
    end_char: 24322,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1683500,
    end_time: 1683600,
    token_index: 4497,
    start_char: 24323,
    end_char: 24325,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "long",
    start_time: 1683600,
    end_time: 1684000,
    token_index: 4498,
    start_char: 24326,
    end_char: 24330,
    display_word: "long",
    period_at_end: false
    },
    {
    word: "ago",
    start_time: 1684000,
    end_time: 1684600,
    token_index: 4499,
    start_char: 24331,
    end_char: 24334,
    display_word: "ago",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1685500,
    end_time: 1686000,
    token_index: 4500,
    start_char: 24335,
    end_char: 24339,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1686300,
    end_time: 1686400,
    token_index: 4501,
    start_char: 24340,
    end_char: 24342,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1686400,
    end_time: 1686500,
    token_index: 4502,
    start_char: 24343,
    end_char: 24346,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1686500,
    end_time: 1686700,
    token_index: 4503,
    start_char: 24347,
    end_char: 24349,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "long",
    start_time: 1686700,
    end_time: 1686900,
    token_index: 4504,
    start_char: 24350,
    end_char: 24354,
    display_word: "long",
    period_at_end: false
    },
    {
    word: "ago",
    start_time: 1686900,
    end_time: 1687200,
    token_index: 4505,
    start_char: 24355,
    end_char: 24358,
    display_word: "ago",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1687200,
    end_time: 1687300,
    token_index: 4506,
    start_char: 24359,
    end_char: 24363,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 1687300,
    end_time: 1687600,
    token_index: 4507,
    start_char: 24364,
    end_char: 24368,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 1687600,
    end_time: 1687800,
    token_index: 4508,
    start_char: 24369,
    end_char: 24373,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1687800,
    end_time: 1687800,
    token_index: 4509,
    start_char: 24374,
    end_char: 24377,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1687800,
    end_time: 1688000,
    token_index: 4510,
    start_char: 24378,
    end_char: 24382,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "called",
    start_time: 1688000,
    end_time: 1688200,
    token_index: 4511,
    start_char: 24383,
    end_char: 24389,
    display_word: "called",
    period_at_end: false
    },
    {
    word: "The",
    start_time: 1688200,
    end_time: 1688300,
    token_index: 4512,
    start_char: 24390,
    end_char: 24393,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "Dixie",
    start_time: 1688300,
    end_time: 1688700,
    token_index: 4513,
    start_char: 24394,
    end_char: 24399,
    display_word: "Dixie",
    period_at_end: false
    },
    {
    word: "Chicks",
    start_time: 1688700,
    end_time: 1689300,
    token_index: 4514,
    start_char: 24400,
    end_char: 24406,
    display_word: "Chicks",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1689500,
    end_time: 1689700,
    token_index: 4515,
    start_char: 24407,
    end_char: 24410,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "after",
    start_time: 1689700,
    end_time: 1689900,
    token_index: 4516,
    start_char: 24411,
    end_char: 24416,
    display_word: "after",
    period_at_end: false
    },
    {
    word: "31",
    start_time: 1689900,
    end_time: 1690500,
    token_index: 4517,
    start_char: 24417,
    end_char: 24419,
    display_word: "31",
    period_at_end: false
    },
    {
    word: "years.",
    start_time: 1690500,
    end_time: 1690900,
    token_index: 4518,
    start_char: 24420,
    end_char: 24425,
    display_word: "years",
    period_at_end: true
    },
    {
    word: "You've",
    start_time: 1690900,
    end_time: 1691100,
    token_index: 4519,
    start_char: 24427,
    end_char: 24433,
    display_word: "You've",
    period_at_end: false
    },
    {
    word: "changed",
    start_time: 1691100,
    end_time: 1691600,
    token_index: 4520,
    start_char: 24434,
    end_char: 24441,
    display_word: "changed",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1691600,
    end_time: 1691700,
    token_index: 4521,
    start_char: 24442,
    end_char: 24444,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1691700,
    end_time: 1691800,
    token_index: 4522,
    start_char: 24445,
    end_char: 24448,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks.",
    start_time: 1691800,
    end_time: 1692400,
    token_index: 4523,
    start_char: 24449,
    end_char: 24455,
    display_word: "chicks",
    period_at_end: true
    },
    {
    word: "Tell",
    start_time: 1692600,
    end_time: 1692800,
    token_index: 4524,
    start_char: 24457,
    end_char: 24461,
    display_word: "Tell",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 1692800,
    end_time: 1692900,
    token_index: 4525,
    start_char: 24462,
    end_char: 24464,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "why",
    start_time: 1692900,
    end_time: 1693100,
    token_index: 4526,
    start_char: 24465,
    end_char: 24468,
    display_word: "why",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1693100,
    end_time: 1693200,
    token_index: 4527,
    start_char: 24469,
    end_char: 24472,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "changed",
    start_time: 1693200,
    end_time: 1693500,
    token_index: 4528,
    start_char: 24473,
    end_char: 24480,
    display_word: "changed",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1693500,
    end_time: 1693600,
    token_index: 4529,
    start_char: 24481,
    end_char: 24485,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "name",
    start_time: 1693600,
    end_time: 1693800,
    token_index: 4530,
    start_char: 24486,
    end_char: 24490,
    display_word: "name",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 1693800,
    end_time: 1694100,
    token_index: 4531,
    start_char: 24491,
    end_char: 24494,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 1695900,
    end_time: 1696300,
    token_index: 4532,
    start_char: 24496,
    end_char: 24501,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1696300,
    end_time: 1696400,
    token_index: 4533,
    start_char: 24502,
    end_char: 24508,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1696400,
    end_time: 1696800,
    token_index: 4534,
    start_char: 24509,
    end_char: 24516,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1696800,
    end_time: 1696900,
    token_index: 4535,
    start_char: 24517,
    end_char: 24519,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "everything",
    start_time: 1696900,
    end_time: 1697200,
    token_index: 4536,
    start_char: 24520,
    end_char: 24530,
    display_word: "everything",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1697200,
    end_time: 1697400,
    token_index: 4537,
    start_char: 24531,
    end_char: 24537,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1697400,
    end_time: 1697700,
    token_index: 4538,
    start_char: 24538,
    end_char: 24543,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1697700,
    end_time: 1697900,
    token_index: 4539,
    start_char: 24544,
    end_char: 24546,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1697900,
    end_time: 1697900,
    token_index: 4540,
    start_char: 24547,
    end_char: 24549,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1697900,
    end_time: 1698000,
    token_index: 4541,
    start_char: 24550,
    end_char: 24553,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "world.",
    start_time: 1698000,
    end_time: 1698400,
    token_index: 4542,
    start_char: 24554,
    end_char: 24559,
    display_word: "world",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 1698400,
    end_time: 1698500,
    token_index: 4543,
    start_char: 24561,
    end_char: 24563,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1698500,
    end_time: 1698600,
    token_index: 4544,
    start_char: 24564,
    end_char: 24567,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1698600,
    end_time: 1698900,
    token_index: 4545,
    start_char: 24568,
    end_char: 24573,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "time",
    start_time: 1698900,
    end_time: 1699300,
    token_index: 4546,
    start_char: 24574,
    end_char: 24578,
    display_word: "time",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1699300,
    end_time: 1699400,
    token_index: 4547,
    start_char: 24579,
    end_char: 24581,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1699400,
    end_time: 1699500,
    token_index: 4548,
    start_char: 24582,
    end_char: 24586,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "wanted",
    start_time: 1699500,
    end_time: 1699700,
    token_index: 4549,
    start_char: 24587,
    end_char: 24593,
    display_word: "wanted",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1699700,
    end_time: 1699800,
    token_index: 4550,
    start_char: 24594,
    end_char: 24596,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "change",
    start_time: 1699800,
    end_time: 1700200,
    token_index: 4551,
    start_char: 24597,
    end_char: 24603,
    display_word: "change",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1700200,
    end_time: 1700500,
    token_index: 4552,
    start_char: 24604,
    end_char: 24606,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1700500,
    end_time: 1700900,
    token_index: 4553,
    start_char: 24607,
    end_char: 24610,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1700900,
    end_time: 1701000,
    token_index: 4554,
    start_char: 24611,
    end_char: 24612,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "long",
    start_time: 1701000,
    end_time: 1701300,
    token_index: 4555,
    start_char: 24613,
    end_char: 24617,
    display_word: "long",
    period_at_end: false
    },
    {
    word: "time",
    start_time: 1701300,
    end_time: 1701600,
    token_index: 4556,
    start_char: 24618,
    end_char: 24622,
    display_word: "time",
    period_at_end: false
    },
    {
    word: "actually",
    start_time: 1701600,
    end_time: 1702100,
    token_index: 4557,
    start_char: 24623,
    end_char: 24631,
    display_word: "actually",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1702100,
    end_time: 1702300,
    token_index: 4558,
    start_char: 24632,
    end_char: 24635,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1702300,
    end_time: 1702600,
    token_index: 4559,
    start_char: 24636,
    end_char: 24638,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "started",
    start_time: 1702900,
    end_time: 1703300,
    token_index: 4560,
    start_char: 24639,
    end_char: 24646,
    display_word: "started",
    period_at_end: false
    },
    {
    word: "using",
    start_time: 1703300,
    end_time: 1703900,
    token_index: 4561,
    start_char: 24647,
    end_char: 24652,
    display_word: "using",
    period_at_end: false
    },
    {
    word: "dcx",
    start_time: 1703900,
    end_time: 1704600,
    token_index: 4562,
    start_char: 24653,
    end_char: 24656,
    display_word: "dcx",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1704600,
    end_time: 1704700,
    token_index: 4563,
    start_char: 24657,
    end_char: 24658,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 1704700,
    end_time: 1705100,
    token_index: 4564,
    start_char: 24659,
    end_char: 24662,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1705100,
    end_time: 1705300,
    token_index: 4565,
    start_char: 24663,
    end_char: 24666,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1705300,
    end_time: 1705300,
    token_index: 4566,
    start_char: 24667,
    end_char: 24670,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks",
    start_time: 1705300,
    end_time: 1705700,
    token_index: 4567,
    start_char: 24671,
    end_char: 24677,
    display_word: "chicks",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1705700,
    end_time: 1705800,
    token_index: 4568,
    start_char: 24678,
    end_char: 24679,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "lot",
    start_time: 1705800,
    end_time: 1706100,
    token_index: 4569,
    start_char: 24680,
    end_char: 24683,
    display_word: "lot",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 1706100,
    end_time: 1706300,
    token_index: 4570,
    start_char: 24684,
    end_char: 24688,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1706300,
    end_time: 1706400,
    token_index: 4571,
    start_char: 24689,
    end_char: 24691,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "could",
    start_time: 1706400,
    end_time: 1707000,
    token_index: 4572,
    start_char: 24692,
    end_char: 24697,
    display_word: "could",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1707200,
    end_time: 1707400,
    token_index: 4573,
    start_char: 24698,
    end_char: 24700,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "hoped",
    start_time: 1707400,
    end_time: 1707700,
    token_index: 4574,
    start_char: 24701,
    end_char: 24706,
    display_word: "hoped",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1707700,
    end_time: 1707800,
    token_index: 4575,
    start_char: 24707,
    end_char: 24709,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1707800,
    end_time: 1707900,
    token_index: 4576,
    start_char: 24710,
    end_char: 24715,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "catch",
    start_time: 1707900,
    end_time: 1708300,
    token_index: 4577,
    start_char: 24716,
    end_char: 24721,
    display_word: "catch",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1708300,
    end_time: 1708500,
    token_index: 4578,
    start_char: 24722,
    end_char: 24724,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1708500,
    end_time: 1709100,
    token_index: 4579,
    start_char: 24725,
    end_char: 24728,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1709100,
    end_time: 1709300,
    token_index: 4580,
    start_char: 24729,
    end_char: 24731,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 1709300,
    end_time: 1709900,
    token_index: 4581,
    start_char: 24732,
    end_char: 24738,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1710200,
    end_time: 1710800,
    token_index: 4582,
    start_char: 24739,
    end_char: 24741,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1711600,
    end_time: 1711800,
    token_index: 4583,
    start_char: 24742,
    end_char: 24744,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1711800,
    end_time: 1711900,
    token_index: 4584,
    start_char: 24745,
    end_char: 24748,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "moment",
    start_time: 1711900,
    end_time: 1712300,
    token_index: 4585,
    start_char: 24749,
    end_char: 24755,
    display_word: "moment",
    period_at_end: false
    },
    {
    word: "now,",
    start_time: 1712300,
    end_time: 1712700,
    token_index: 4586,
    start_char: 24756,
    end_char: 24760,
    display_word: "now,",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1712700,
    end_time: 1712900,
    token_index: 4587,
    start_char: 24761,
    end_char: 24763,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1712900,
    end_time: 1713700,
    token_index: 4588,
    start_char: 24764,
    end_char: 24768,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "felt",
    start_time: 1713700,
    end_time: 1714000,
    token_index: 4589,
    start_char: 24769,
    end_char: 24773,
    display_word: "felt",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1714000,
    end_time: 1714200,
    token_index: 4590,
    start_char: 24774,
    end_char: 24778,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1714200,
    end_time: 1714400,
    token_index: 4591,
    start_char: 24779,
    end_char: 24781,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1714400,
    end_time: 1714700,
    token_index: 4592,
    start_char: 24782,
    end_char: 24785,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1715300,
    end_time: 1715800,
    token_index: 4593,
    start_char: 24786,
    end_char: 24791,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1715800,
    end_time: 1715900,
    token_index: 4594,
    start_char: 24792,
    end_char: 24795,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1715900,
    end_time: 1716000,
    token_index: 4595,
    start_char: 24796,
    end_char: 24798,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 1716000,
    end_time: 1716200,
    token_index: 4596,
    start_char: 24799,
    end_char: 24805,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 1716200,
    end_time: 1716400,
    token_index: 4597,
    start_char: 24806,
    end_char: 24810,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1716400,
    end_time: 1716600,
    token_index: 4598,
    start_char: 24811,
    end_char: 24815,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1716600,
    end_time: 1716900,
    token_index: 4599,
    start_char: 24816,
    end_char: 24818,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1716900,
    end_time: 1717000,
    token_index: 4600,
    start_char: 24819,
    end_char: 24822,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1717000,
    end_time: 1717100,
    token_index: 4601,
    start_char: 24823,
    end_char: 24827,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1717100,
    end_time: 1717600,
    token_index: 4602,
    start_char: 24828,
    end_char: 24832,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 1717700,
    end_time: 1717900,
    token_index: 4603,
    start_char: 24833,
    end_char: 24837,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "discussion",
    start_time: 1717900,
    end_time: 1718400,
    token_index: 4604,
    start_char: 24838,
    end_char: 24848,
    display_word: "discussion",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1718400,
    end_time: 1718800,
    token_index: 4605,
    start_char: 24849,
    end_char: 24854,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "it.",
    start_time: 1718800,
    end_time: 1719000,
    token_index: 4606,
    start_char: 24855,
    end_char: 24857,
    display_word: "it",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 1719000,
    end_time: 1719100,
    token_index: 4607,
    start_char: 24859,
    end_char: 24861,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1719100,
    end_time: 1719300,
    token_index: 4608,
    start_char: 24862,
    end_char: 24866,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1719300,
    end_time: 1719500,
    token_index: 4609,
    start_char: 24867,
    end_char: 24870,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "wanted",
    start_time: 1719500,
    end_time: 1719700,
    token_index: 4610,
    start_char: 24871,
    end_char: 24877,
    display_word: "wanted",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1719700,
    end_time: 1719800,
    token_index: 4611,
    start_char: 24878,
    end_char: 24880,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 1719800,
    end_time: 1720000,
    token_index: 4612,
    start_char: 24881,
    end_char: 24883,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1720000,
    end_time: 1720500,
    token_index: 4613,
    start_char: 24884,
    end_char: 24886,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1720600,
    end_time: 1720800,
    token_index: 4614,
    start_char: 24887,
    end_char: 24892,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "away.",
    start_time: 1720800,
    end_time: 1721300,
    token_index: 4615,
    start_char: 24893,
    end_char: 24897,
    display_word: "away",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 1721300,
    end_time: 1721800,
    token_index: 4616,
    start_char: 24899,
    end_char: 24902,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1721800,
    end_time: 1722300,
    token_index: 4617,
    start_char: 24903,
    end_char: 24905,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1722400,
    end_time: 1722400,
    token_index: 4618,
    start_char: 24906,
    end_char: 24908,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 1722400,
    end_time: 1722900,
    token_index: 4619,
    start_char: 24909,
    end_char: 24912,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "well",
    start_time: 1723700,
    end_time: 1724100,
    token_index: 4620,
    start_char: 24913,
    end_char: 24917,
    display_word: "well",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1724200,
    end_time: 1724400,
    token_index: 4621,
    start_char: 24918,
    end_char: 24924,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "great",
    start_time: 1724400,
    end_time: 1724600,
    token_index: 4622,
    start_char: 24925,
    end_char: 24930,
    display_word: "great",
    period_at_end: false
    },
    {
    word: "were.",
    start_time: 1724600,
    end_time: 1725100,
    token_index: 4623,
    start_char: 24931,
    end_char: 24935,
    display_word: "were",
    period_at_end: true
    },
    {
    word: "Was",
    start_time: 1725300,
    end_time: 1725400,
    token_index: 4624,
    start_char: 24937,
    end_char: 24940,
    display_word: "Was",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1725400,
    end_time: 1725500,
    token_index: 4625,
    start_char: 24941,
    end_char: 24943,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "always",
    start_time: 1725500,
    end_time: 1725800,
    token_index: 4626,
    start_char: 24944,
    end_char: 24950,
    display_word: "always",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1725800,
    end_time: 1726000,
    token_index: 4627,
    start_char: 24951,
    end_char: 24956,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1726000,
    end_time: 1726000,
    token_index: 4628,
    start_char: 24957,
    end_char: 24959,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1726000,
    end_time: 1726100,
    token_index: 4629,
    start_char: 24960,
    end_char: 24962,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1726100,
    end_time: 1726200,
    token_index: 4630,
    start_char: 24963,
    end_char: 24966,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks",
    start_time: 1726200,
    end_time: 1726600,
    token_index: 4631,
    start_char: 24967,
    end_char: 24973,
    display_word: "chicks",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1726600,
    end_time: 1726700,
    token_index: 4632,
    start_char: 24974,
    end_char: 24976,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "whether",
    start_time: 1726700,
    end_time: 1727100,
    token_index: 4633,
    start_char: 24977,
    end_char: 24984,
    display_word: "whether",
    period_at_end: false
    },
    {
    word: "other",
    start_time: 1727100,
    end_time: 1727400,
    token_index: 4634,
    start_char: 24985,
    end_char: 24990,
    display_word: "other",
    period_at_end: false
    },
    {
    word: "contenders",
    start_time: 1727400,
    end_time: 1728100,
    token_index: 4635,
    start_char: 24991,
    end_char: 25001,
    display_word: "contenders",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1728100,
    end_time: 1728200,
    token_index: 4636,
    start_char: 25002,
    end_char: 25005,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1728200,
    end_time: 1728300,
    token_index: 4637,
    start_char: 25006,
    end_char: 25009,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "name",
    start_time: 1728300,
    end_time: 1728800,
    token_index: 4638,
    start_char: 25010,
    end_char: 25014,
    display_word: "name",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1729100,
    end_time: 1729400,
    token_index: 4639,
    start_char: 25015,
    end_char: 25018,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1729400,
    end_time: 1729400,
    token_index: 4640,
    start_char: 25019,
    end_char: 25021,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "make",
    start_time: 1729400,
    end_time: 1729600,
    token_index: 4641,
    start_char: 25022,
    end_char: 25026,
    display_word: "make",
    period_at_end: false
    },
    {
    word: "sure",
    start_time: 1729600,
    end_time: 1729700,
    token_index: 4642,
    start_char: 25027,
    end_char: 25031,
    display_word: "sure",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1729700,
    end_time: 1729800,
    token_index: 4643,
    start_char: 25032,
    end_char: 25034,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1729800,
    end_time: 1730000,
    token_index: 4644,
    start_char: 25035,
    end_char: 25038,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1730000,
    end_time: 1730000,
    token_index: 4645,
    start_char: 25039,
    end_char: 25042,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "ducks",
    start_time: 1730000,
    end_time: 1730400,
    token_index: 4646,
    start_char: 25043,
    end_char: 25048,
    display_word: "ducks",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1730400,
    end_time: 1730400,
    token_index: 4647,
    start_char: 25049,
    end_char: 25051,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1730400,
    end_time: 1730500,
    token_index: 4648,
    start_char: 25052,
    end_char: 25053,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "row",
    start_time: 1730500,
    end_time: 1730800,
    token_index: 4649,
    start_char: 25054,
    end_char: 25057,
    display_word: "row",
    period_at_end: false
    },
    {
    word: "legally.",
    start_time: 1730800,
    end_time: 1731300,
    token_index: 4650,
    start_char: 25058,
    end_char: 25065,
    display_word: "legally",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 1731300,
    end_time: 1731700,
    token_index: 4651,
    start_char: 25067,
    end_char: 25070,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1731700,
    end_time: 1731800,
    token_index: 4652,
    start_char: 25071,
    end_char: 25073,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1731800,
    end_time: 1731900,
    token_index: 4653,
    start_char: 25074,
    end_char: 25077,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "meantime,",
    start_time: 1731900,
    end_time: 1732300,
    token_index: 4654,
    start_char: 25078,
    end_char: 25087,
    display_word: "meantime,",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1732300,
    end_time: 1732300,
    token_index: 4655,
    start_char: 25088,
    end_char: 25090,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "thought",
    start_time: 1732300,
    end_time: 1732500,
    token_index: 4656,
    start_char: 25091,
    end_char: 25098,
    display_word: "thought",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1732500,
    end_time: 1732700,
    token_index: 4657,
    start_char: 25099,
    end_char: 25102,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks",
    start_time: 1732700,
    end_time: 1732900,
    token_index: 4658,
    start_char: 25103,
    end_char: 25109,
    display_word: "chicks",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1732900,
    end_time: 1733000,
    token_index: 4659,
    start_char: 25110,
    end_char: 25115,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1733000,
    end_time: 1733100,
    token_index: 4660,
    start_char: 25116,
    end_char: 25118,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1733100,
    end_time: 1733200,
    token_index: 4661,
    start_char: 25119,
    end_char: 25122,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "easiest",
    start_time: 1733200,
    end_time: 1733600,
    token_index: 4662,
    start_char: 25123,
    end_char: 25130,
    display_word: "easiest",
    period_at_end: false
    },
    {
    word: "thing",
    start_time: 1733600,
    end_time: 1733800,
    token_index: 4663,
    start_char: 25131,
    end_char: 25136,
    display_word: "thing",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1733800,
    end_time: 1734000,
    token_index: 4664,
    start_char: 25137,
    end_char: 25140,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1734000,
    end_time: 1734100,
    token_index: 4665,
    start_char: 25141,
    end_char: 25143,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 1734100,
    end_time: 1734300,
    token_index: 4666,
    start_char: 25144,
    end_char: 25147,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1734300,
    end_time: 1734500,
    token_index: 4667,
    start_char: 25148,
    end_char: 25152,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1734500,
    end_time: 1734600,
    token_index: 4668,
    start_char: 25153,
    end_char: 25155,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "entertain",
    start_time: 1734600,
    end_time: 1735000,
    token_index: 4669,
    start_char: 25156,
    end_char: 25165,
    display_word: "entertain",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1735000,
    end_time: 1735000,
    token_index: 4670,
    start_char: 25166,
    end_char: 25167,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "few",
    start_time: 1735000,
    end_time: 1735200,
    token_index: 4671,
    start_char: 25168,
    end_char: 25171,
    display_word: "few",
    period_at_end: false
    },
    {
    word: "other",
    start_time: 1735200,
    end_time: 1735400,
    token_index: 4672,
    start_char: 25172,
    end_char: 25177,
    display_word: "other",
    period_at_end: false
    },
    {
    word: "things.",
    start_time: 1735400,
    end_time: 1736000,
    token_index: 4673,
    start_char: 25178,
    end_char: 25184,
    display_word: "things",
    period_at_end: true
    },
    {
    word: "One",
    start_time: 1736900,
    end_time: 1737100,
    token_index: 4674,
    start_char: 25186,
    end_char: 25189,
    display_word: "One",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1737100,
    end_time: 1737100,
    token_index: 4675,
    start_char: 25190,
    end_char: 25192,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1737100,
    end_time: 1737200,
    token_index: 4676,
    start_char: 25193,
    end_char: 25196,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "ones",
    start_time: 1737200,
    end_time: 1737500,
    token_index: 4677,
    start_char: 25197,
    end_char: 25201,
    display_word: "ones",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1737500,
    end_time: 1737600,
    token_index: 4678,
    start_char: 25202,
    end_char: 25206,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "came",
    start_time: 1737600,
    end_time: 1737800,
    token_index: 4679,
    start_char: 25207,
    end_char: 25211,
    display_word: "came",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1737800,
    end_time: 1738100,
    token_index: 4680,
    start_char: 25212,
    end_char: 25214,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1738100,
    end_time: 1738900,
    token_index: 4681,
    start_char: 25215,
    end_char: 25218,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1739600,
    end_time: 1739700,
    token_index: 4682,
    start_char: 25219,
    end_char: 25222,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "initials",
    start_time: 1739700,
    end_time: 1740500,
    token_index: 4683,
    start_char: 25223,
    end_char: 25231,
    display_word: "initials",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1740600,
    end_time: 1740800,
    token_index: 4684,
    start_char: 25232,
    end_char: 25236,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "spelled",
    start_time: 1740800,
    end_time: 1741100,
    token_index: 4685,
    start_char: 25237,
    end_char: 25244,
    display_word: "spelled",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 1741100,
    end_time: 1741300,
    token_index: 4686,
    start_char: 25245,
    end_char: 25248,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1741300,
    end_time: 1741500,
    token_index: 4687,
    start_char: 25249,
    end_char: 25251,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "men.",
    start_time: 1741500,
    end_time: 1742100,
    token_index: 4688,
    start_char: 25252,
    end_char: 25255,
    display_word: "men",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1742100,
    end_time: 1742200,
    token_index: 4689,
    start_char: 25257,
    end_char: 25259,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "we'd",
    start_time: 1742200,
    end_time: 1742400,
    token_index: 4690,
    start_char: 25260,
    end_char: 25264,
    display_word: "we'd",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 1742400,
    end_time: 1742600,
    token_index: 4691,
    start_char: 25265,
    end_char: 25267,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1742600,
    end_time: 1742900,
    token_index: 4692,
    start_char: 25268,
    end_char: 25272,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "chicks",
    start_time: 1742900,
    end_time: 1743300,
    token_index: 4693,
    start_char: 25273,
    end_char: 25279,
    display_word: "chicks",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1743300,
    end_time: 1743400,
    token_index: 4694,
    start_char: 25280,
    end_char: 25282,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "mend.",
    start_time: 1743400,
    end_time: 1744000,
    token_index: 4695,
    start_char: 25283,
    end_char: 25287,
    display_word: "mend",
    period_at_end: true
    },
    {
    word: "Sure.",
    start_time: 1745100,
    end_time: 1745400,
    token_index: 4696,
    start_char: 25289,
    end_char: 25293,
    display_word: "Sure",
    period_at_end: true
    },
    {
    word: "Sure.",
    start_time: 1745400,
    end_time: 1745600,
    token_index: 4697,
    start_char: 25295,
    end_char: 25299,
    display_word: "Sure",
    period_at_end: true
    },
    {
    word: "Sure,",
    start_time: 1745600,
    end_time: 1746000,
    token_index: 4698,
    start_char: 25301,
    end_char: 25306,
    display_word: "Sure,",
    period_at_end: false
    },
    {
    word: "Marty",
    start_time: 1746100,
    end_time: 1746400,
    token_index: 4699,
    start_char: 25307,
    end_char: 25312,
    display_word: "Marty",
    period_at_end: false
    },
    {
    word: "Emily",
    start_time: 1746400,
    end_time: 1746700,
    token_index: 4700,
    start_char: 25313,
    end_char: 25318,
    display_word: "Emily",
    period_at_end: false
    },
    {
    word: "Natalie.",
    start_time: 1746700,
    end_time: 1747000,
    token_index: 4701,
    start_char: 25319,
    end_char: 25326,
    display_word: "Natalie",
    period_at_end: true
    },
    {
    word: "Got",
    start_time: 1747000,
    end_time: 1747300,
    token_index: 4702,
    start_char: 25328,
    end_char: 25331,
    display_word: "Got",
    period_at_end: false
    },
    {
    word: "it.",
    start_time: 1747300,
    end_time: 1747500,
    token_index: 4703,
    start_char: 25332,
    end_char: 25334,
    display_word: "it",
    period_at_end: true
    },
    {
    word: "Yeah,",
    start_time: 1747500,
    end_time: 1747900,
    token_index: 4704,
    start_char: 25336,
    end_char: 25341,
    display_word: "Yeah,",
    period_at_end: false
    },
    {
    word: "anything",
    start_time: 1747900,
    end_time: 1748100,
    token_index: 4705,
    start_char: 25342,
    end_char: 25350,
    display_word: "anything",
    period_at_end: false
    },
    {
    word: "else",
    start_time: 1748100,
    end_time: 1748600,
    token_index: 4706,
    start_char: 25351,
    end_char: 25355,
    display_word: "else",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1748900,
    end_time: 1749100,
    token_index: 4707,
    start_char: 25356,
    end_char: 25358,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1749100,
    end_time: 1749400,
    token_index: 4708,
    start_char: 25359,
    end_char: 25362,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1749400,
    end_time: 1749600,
    token_index: 4709,
    start_char: 25363,
    end_char: 25367,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "names",
    start_time: 1749600,
    end_time: 1750300,
    token_index: 4710,
    start_char: 25368,
    end_char: 25373,
    display_word: "names",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1750300,
    end_time: 1750400,
    token_index: 4711,
    start_char: 25374,
    end_char: 25376,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1750400,
    end_time: 1750500,
    token_index: 4712,
    start_char: 25377,
    end_char: 25380,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "past",
    start_time: 1750500,
    end_time: 1750900,
    token_index: 4713,
    start_char: 25381,
    end_char: 25385,
    display_word: "past",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1750900,
    end_time: 1751000,
    token_index: 4714,
    start_char: 25386,
    end_char: 25390,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1751000,
    end_time: 1751100,
    token_index: 4715,
    start_char: 25391,
    end_char: 25393,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "would",
    start_time: 1751100,
    end_time: 1751300,
    token_index: 4716,
    start_char: 25394,
    end_char: 25399,
    display_word: "would",
    period_at_end: false
    },
    {
    word: "call",
    start_time: 1751300,
    end_time: 1751400,
    token_index: 4717,
    start_char: 25400,
    end_char: 25404,
    display_word: "call",
    period_at_end: false
    },
    {
    word: "ourselves",
    start_time: 1751400,
    end_time: 1751900,
    token_index: 4718,
    start_char: 25405,
    end_char: 25414,
    display_word: "ourselves",
    period_at_end: false
    },
    {
    word: "kind",
    start_time: 1751900,
    end_time: 1752100,
    token_index: 4719,
    start_char: 25415,
    end_char: 25419,
    display_word: "kind",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1752100,
    end_time: 1752100,
    token_index: 4720,
    start_char: 25420,
    end_char: 25422,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "as",
    start_time: 1752100,
    end_time: 1752300,
    token_index: 4721,
    start_char: 25423,
    end_char: 25425,
    display_word: "as",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1752300,
    end_time: 1752500,
    token_index: 4722,
    start_char: 25426,
    end_char: 25429,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "Alter",
    start_time: 1752500,
    end_time: 1752800,
    token_index: 4723,
    start_char: 25430,
    end_char: 25435,
    display_word: "Alter",
    period_at_end: false
    },
    {
    word: "Egos",
    start_time: 1752800,
    end_time: 1753200,
    token_index: 4724,
    start_char: 25436,
    end_char: 25440,
    display_word: "Egos",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1753200,
    end_time: 1753300,
    token_index: 4725,
    start_char: 25441,
    end_char: 25444,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1753300,
    end_time: 1753400,
    token_index: 4726,
    start_char: 25445,
    end_char: 25447,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "could",
    start_time: 1753400,
    end_time: 1753600,
    token_index: 4727,
    start_char: 25448,
    end_char: 25453,
    display_word: "could",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1753600,
    end_time: 1753800,
    token_index: 4728,
    start_char: 25454,
    end_char: 25458,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "pulled",
    start_time: 1753800,
    end_time: 1754000,
    token_index: 4729,
    start_char: 25459,
    end_char: 25465,
    display_word: "pulled",
    period_at_end: false
    },
    {
    word: "those",
    start_time: 1754000,
    end_time: 1754300,
    token_index: 4730,
    start_char: 25466,
    end_char: 25471,
    display_word: "those",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1754300,
    end_time: 1754500,
    token_index: 4731,
    start_char: 25472,
    end_char: 25476,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1754500,
    end_time: 1754600,
    token_index: 4732,
    start_char: 25477,
    end_char: 25480,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "past.",
    start_time: 1754600,
    end_time: 1755100,
    token_index: 4733,
    start_char: 25481,
    end_char: 25485,
    display_word: "past",
    period_at_end: true
    },
    {
    word: "Last",
    start_time: 1755200,
    end_time: 1755500,
    token_index: 4734,
    start_char: 25487,
    end_char: 25491,
    display_word: "Last",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 1755500,
    end_time: 1755800,
    token_index: 4735,
    start_char: 25492,
    end_char: 25495,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1755800,
    end_time: 1756200,
    token_index: 4736,
    start_char: 25496,
    end_char: 25499,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "Puss",
    start_time: 1756700,
    end_time: 1756900,
    token_index: 4737,
    start_char: 25500,
    end_char: 25504,
    display_word: "Puss",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1756900,
    end_time: 1757000,
    token_index: 4738,
    start_char: 25505,
    end_char: 25507,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "Boots.",
    start_time: 1757000,
    end_time: 1757600,
    token_index: 4739,
    start_char: 25508,
    end_char: 25513,
    display_word: "Boots",
    period_at_end: true
    },
    {
    word: "Okay,",
    start_time: 1758300,
    end_time: 1758800,
    token_index: 4740,
    start_char: 25515,
    end_char: 25520,
    display_word: "Okay,",
    period_at_end: false
    },
    {
    word: "there's",
    start_time: 1759300,
    end_time: 1759500,
    token_index: 4741,
    start_char: 25521,
    end_char: 25528,
    display_word: "there's",
    period_at_end: false
    },
    {
    word: "already",
    start_time: 1759500,
    end_time: 1759700,
    token_index: 4742,
    start_char: 25529,
    end_char: 25536,
    display_word: "already",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1759700,
    end_time: 1759700,
    token_index: 4743,
    start_char: 25537,
    end_char: 25538,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "band",
    start_time: 1759700,
    end_time: 1760300,
    token_index: 4744,
    start_char: 25539,
    end_char: 25543,
    display_word: "band",
    period_at_end: false
    },
    {
    word: "called",
    start_time: 1760300,
    end_time: 1760700,
    token_index: 4745,
    start_char: 25544,
    end_char: 25550,
    display_word: "called",
    period_at_end: false
    },
    {
    word: "that.",
    start_time: 1760700,
    end_time: 1761400,
    token_index: 4746,
    start_char: 25551,
    end_char: 25555,
    display_word: "that",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 1762600,
    end_time: 1763000,
    token_index: 4747,
    start_char: 25557,
    end_char: 25562,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1764600,
    end_time: 1764800,
    token_index: 4748,
    start_char: 25563,
    end_char: 25567,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1764800,
    end_time: 1764900,
    token_index: 4749,
    start_char: 25568,
    end_char: 25571,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "that?",
    start_time: 1764900,
    end_time: 1765000,
    token_index: 4750,
    start_char: 25572,
    end_char: 25577,
    display_word: "that?",
    period_at_end: false
    },
    {
    word: "Last",
    start_time: 1765000,
    end_time: 1765200,
    token_index: 4751,
    start_char: 25578,
    end_char: 25582,
    display_word: "Last",
    period_at_end: false
    },
    {
    word: "one?",
    start_time: 1765200,
    end_time: 1765500,
    token_index: 4752,
    start_char: 25583,
    end_char: 25587,
    display_word: "one?",
    period_at_end: false
    },
    {
    word: "Willie",
    start_time: 1766600,
    end_time: 1766900,
    token_index: 4753,
    start_char: 25588,
    end_char: 25594,
    display_word: "Willie",
    period_at_end: false
    },
    {
    word: "puddin?",
    start_time: 1766900,
    end_time: 1767500,
    token_index: 4754,
    start_char: 25595,
    end_char: 25602,
    display_word: "puddin?",
    period_at_end: false
    },
    {
    word: "Well,",
    start_time: 1767800,
    end_time: 1767900,
    token_index: 4755,
    start_char: 25603,
    end_char: 25608,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1767900,
    end_time: 1768000,
    token_index: 4756,
    start_char: 25609,
    end_char: 25611,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "puddin",
    start_time: 1768000,
    end_time: 1768400,
    token_index: 4757,
    start_char: 25612,
    end_char: 25618,
    display_word: "puddin",
    period_at_end: false
    },
    {
    word: "okay.",
    start_time: 1768400,
    end_time: 1768900,
    token_index: 4758,
    start_char: 25619,
    end_char: 25623,
    display_word: "okay",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1769000,
    end_time: 1769000,
    token_index: 4759,
    start_char: 25625,
    end_char: 25626,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1769000,
    end_time: 1769300,
    token_index: 4760,
    start_char: 25627,
    end_char: 25631,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1769300,
    end_time: 1769700,
    token_index: 4761,
    start_char: 25632,
    end_char: 25634,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1771400,
    end_time: 1771400,
    token_index: 4762,
    start_char: 25635,
    end_char: 25636,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "few",
    start_time: 1771400,
    end_time: 1771600,
    token_index: 4763,
    start_char: 25637,
    end_char: 25640,
    display_word: "few",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1771600,
    end_time: 1772000,
    token_index: 4764,
    start_char: 25641,
    end_char: 25647,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1772000,
    end_time: 1772400,
    token_index: 4765,
    start_char: 25648,
    end_char: 25652,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "tweeted",
    start_time: 1773900,
    end_time: 1774200,
    token_index: 4766,
    start_char: 25653,
    end_char: 25660,
    display_word: "tweeted",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 1774200,
    end_time: 1774600,
    token_index: 4767,
    start_char: 25661,
    end_char: 25664,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1774600,
    end_time: 1774800,
    token_index: 4768,
    start_char: 25665,
    end_char: 25669,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "criticism",
    start_time: 1774800,
    end_time: 1775600,
    token_index: 4769,
    start_char: 25670,
    end_char: 25679,
    display_word: "criticism",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1775600,
    end_time: 1775800,
    token_index: 4770,
    start_char: 25680,
    end_char: 25682,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1775800,
    end_time: 1775900,
    token_index: 4771,
    start_char: 25683,
    end_char: 25686,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "name",
    start_time: 1775900,
    end_time: 1776200,
    token_index: 4772,
    start_char: 25687,
    end_char: 25691,
    display_word: "name",
    period_at_end: false
    },
    {
    word: "change.",
    start_time: 1776200,
    end_time: 1776900,
    token_index: 4773,
    start_char: 25692,
    end_char: 25698,
    display_word: "change",
    period_at_end: true
    },
    {
    word: "Most",
    start_time: 1777000,
    end_time: 1777200,
    token_index: 4774,
    start_char: 25700,
    end_char: 25704,
    display_word: "Most",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1777200,
    end_time: 1777900,
    token_index: 4775,
    start_char: 25705,
    end_char: 25711,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1777900,
    end_time: 1778100,
    token_index: 4776,
    start_char: 25712,
    end_char: 25716,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1778100,
    end_time: 1778300,
    token_index: 4777,
    start_char: 25717,
    end_char: 25723,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "liked",
    start_time: 1778300,
    end_time: 1778700,
    token_index: 4778,
    start_char: 25724,
    end_char: 25729,
    display_word: "liked",
    period_at_end: false
    },
    {
    word: "it.",
    start_time: 1778700,
    end_time: 1779100,
    token_index: 4779,
    start_char: 25730,
    end_char: 25732,
    display_word: "it",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 1779500,
    end_time: 1779600,
    token_index: 4780,
    start_char: 25734,
    end_char: 25737,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "there",
    start_time: 1779600,
    end_time: 1779700,
    token_index: 4781,
    start_char: 25738,
    end_char: 25743,
    display_word: "there",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1779700,
    end_time: 1779800,
    token_index: 4782,
    start_char: 25744,
    end_char: 25747,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1779800,
    end_time: 1779800,
    token_index: 4783,
    start_char: 25748,
    end_char: 25749,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "couple",
    start_time: 1779800,
    end_time: 1780100,
    token_index: 4784,
    start_char: 25750,
    end_char: 25756,
    display_word: "couple",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1780100,
    end_time: 1780400,
    token_index: 4785,
    start_char: 25757,
    end_char: 25763,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 1780400,
    end_time: 1780500,
    token_index: 4786,
    start_char: 25764,
    end_char: 25767,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "there",
    start_time: 1780500,
    end_time: 1780800,
    token_index: 4787,
    start_char: 25768,
    end_char: 25773,
    display_word: "there",
    period_at_end: false
    },
    {
    word: "Sean",
    start_time: 1781100,
    end_time: 1781400,
    token_index: 4788,
    start_char: 25774,
    end_char: 25778,
    display_word: "Sean",
    period_at_end: false
    },
    {
    word: "Spicer",
    start_time: 1781400,
    end_time: 1781900,
    token_index: 4789,
    start_char: 25779,
    end_char: 25785,
    display_word: "Spicer",
    period_at_end: false
    },
    {
    word: "said",
    start_time: 1781900,
    end_time: 1782200,
    token_index: 4790,
    start_char: 25786,
    end_char: 25790,
    display_word: "said",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1782200,
    end_time: 1782300,
    token_index: 4791,
    start_char: 25791,
    end_char: 25794,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Dixie",
    start_time: 1782300,
    end_time: 1782700,
    token_index: 4792,
    start_char: 25795,
    end_char: 25800,
    display_word: "Dixie",
    period_at_end: false
    },
    {
    word: "Chicks",
    start_time: 1782700,
    end_time: 1783000,
    token_index: 4793,
    start_char: 25801,
    end_char: 25807,
    display_word: "Chicks",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1783000,
    end_time: 1783100,
    token_index: 4794,
    start_char: 25808,
    end_char: 25811,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "dropping",
    start_time: 1783100,
    end_time: 1783600,
    token_index: 4795,
    start_char: 25812,
    end_char: 25820,
    display_word: "dropping",
    period_at_end: false
    },
    {
    word: "Dixie",
    start_time: 1783600,
    end_time: 1784100,
    token_index: 4796,
    start_char: 25821,
    end_char: 25826,
    display_word: "Dixie",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 1784100,
    end_time: 1784300,
    token_index: 4797,
    start_char: 25827,
    end_char: 25831,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 1784300,
    end_time: 1784400,
    token_index: 4798,
    start_char: 25832,
    end_char: 25837,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "name",
    start_time: 1784400,
    end_time: 1784900,
    token_index: 4799,
    start_char: 25838,
    end_char: 25842,
    display_word: "name",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1784900,
    end_time: 1785100,
    token_index: 4800,
    start_char: 25843,
    end_char: 25846,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1785300,
    end_time: 1785300,
    token_index: 4801,
    start_char: 25847,
    end_char: 25848,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 1785300,
    end_time: 1785500,
    token_index: 4802,
    start_char: 25849,
    end_char: 25853,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1785500,
    end_time: 1785700,
    token_index: 4803,
    start_char: 25854,
    end_char: 25858,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1785700,
    end_time: 1785800,
    token_index: 4804,
    start_char: 25859,
    end_char: 25861,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "called",
    start_time: 1785800,
    end_time: 1786100,
    token_index: 4805,
    start_char: 25862,
    end_char: 25868,
    display_word: "called",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1786100,
    end_time: 1786100,
    token_index: 4806,
    start_char: 25869,
    end_char: 25872,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks,",
    start_time: 1786100,
    end_time: 1786700,
    token_index: 4807,
    start_char: 25873,
    end_char: 25880,
    display_word: "chicks,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1786900,
    end_time: 1787100,
    token_index: 4808,
    start_char: 25881,
    end_char: 25884,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1787100,
    end_time: 1787300,
    token_index: 4809,
    start_char: 25885,
    end_char: 25889,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "women",
    start_time: 1787300,
    end_time: 1787600,
    token_index: 4810,
    start_char: 25890,
    end_char: 25895,
    display_word: "women",
    period_at_end: false
    },
    {
    word: "might",
    start_time: 1787600,
    end_time: 1787800,
    token_index: 4811,
    start_char: 25896,
    end_char: 25901,
    display_word: "might",
    period_at_end: false
    },
    {
    word: "find",
    start_time: 1787800,
    end_time: 1788000,
    token_index: 4812,
    start_char: 25902,
    end_char: 25906,
    display_word: "find",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1788000,
    end_time: 1788200,
    token_index: 4813,
    start_char: 25907,
    end_char: 25911,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "offensive.",
    start_time: 1788200,
    end_time: 1788800,
    token_index: 4814,
    start_char: 25912,
    end_char: 25921,
    display_word: "offensive",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1788800,
    end_time: 1788900,
    token_index: 4815,
    start_char: 25923,
    end_char: 25925,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1788900,
    end_time: 1789000,
    token_index: 4816,
    start_char: 25926,
    end_char: 25927,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "propose",
    start_time: 1789000,
    end_time: 1789500,
    token_index: 4817,
    start_char: 25928,
    end_char: 25935,
    display_word: "propose",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1789500,
    end_time: 1789700,
    token_index: 4818,
    start_char: 25936,
    end_char: 25940,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1789700,
    end_time: 1790000,
    token_index: 4819,
    start_char: 25941,
    end_char: 25945,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "go",
    start_time: 1790000,
    end_time: 1790300,
    token_index: 4820,
    start_char: 25946,
    end_char: 25948,
    display_word: "go",
    period_at_end: false
    },
    {
    word: "by",
    start_time: 1790300,
    end_time: 1790900,
    token_index: 4821,
    start_char: 25949,
    end_char: 25951,
    display_word: "by",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1791200,
    end_time: 1791800,
    token_index: 4822,
    start_char: 25952,
    end_char: 25955,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1792700,
    end_time: 1793300,
    token_index: 4823,
    start_char: 25956,
    end_char: 25959,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1794600,
    end_time: 1795100,
    token_index: 4824,
    start_char: 25960,
    end_char: 25964,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1795200,
    end_time: 1795500,
    token_index: 4825,
    start_char: 25965,
    end_char: 25969,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "clown",
    start_time: 1795500,
    end_time: 1796000,
    token_index: 4826,
    start_char: 25970,
    end_char: 25975,
    display_word: "clown",
    period_at_end: false
    },
    {
    word: "called",
    start_time: 1796000,
    end_time: 1796300,
    token_index: 4827,
    start_char: 25976,
    end_char: 25982,
    display_word: "called",
    period_at_end: false
    },
    {
    word: "Senator",
    start_time: 1796300,
    end_time: 1796700,
    token_index: 4828,
    start_char: 25983,
    end_char: 25990,
    display_word: "Senator",
    period_at_end: false
    },
    {
    word: "Ted",
    start_time: 1796700,
    end_time: 1797100,
    token_index: 4829,
    start_char: 25991,
    end_char: 25994,
    display_word: "Ted",
    period_at_end: false
    },
    {
    word: "Cruz",
    start_time: 1797100,
    end_time: 1797500,
    token_index: 4830,
    start_char: 25995,
    end_char: 25999,
    display_word: "Cruz",
    period_at_end: false
    },
    {
    word: "said",
    start_time: 1797500,
    end_time: 1797900,
    token_index: 4831,
    start_char: 26000,
    end_char: 26004,
    display_word: "said",
    period_at_end: false
    },
    {
    word: "band",
    start_time: 1797900,
    end_time: 1798300,
    token_index: 4832,
    start_char: 26005,
    end_char: 26009,
    display_word: "band",
    period_at_end: false
    },
    {
    word: "trying",
    start_time: 1798300,
    end_time: 1798600,
    token_index: 4833,
    start_char: 26010,
    end_char: 26016,
    display_word: "trying",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1798600,
    end_time: 1798700,
    token_index: 4834,
    start_char: 26017,
    end_char: 26019,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "sell",
    start_time: 1798700,
    end_time: 1799100,
    token_index: 4835,
    start_char: 26020,
    end_char: 26024,
    display_word: "sell",
    period_at_end: false
    },
    {
    word: "country",
    start_time: 1799100,
    end_time: 1799600,
    token_index: 4836,
    start_char: 26025,
    end_char: 26032,
    display_word: "country",
    period_at_end: false
    },
    {
    word: "records",
    start_time: 1799600,
    end_time: 1800100,
    token_index: 4837,
    start_char: 26033,
    end_char: 26040,
    display_word: "records",
    period_at_end: false
    },
    {
    word: "tells",
    start_time: 1800100,
    end_time: 1800400,
    token_index: 4838,
    start_char: 26041,
    end_char: 26046,
    display_word: "tells",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1800400,
    end_time: 1800500,
    token_index: 4839,
    start_char: 26047,
    end_char: 26050,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "entire",
    start_time: 1800500,
    end_time: 1800900,
    token_index: 4840,
    start_char: 26051,
    end_char: 26057,
    display_word: "entire",
    period_at_end: false
    },
    {
    word: "South",
    start_time: 1800900,
    end_time: 1801400,
    token_index: 4841,
    start_char: 26058,
    end_char: 26063,
    display_word: "South",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1801400,
    end_time: 1801900,
    token_index: 4842,
    start_char: 26064,
    end_char: 26066,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1802300,
    end_time: 1802400,
    token_index: 4843,
    start_char: 26067,
    end_char: 26068,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "assume",
    start_time: 1802400,
    end_time: 1803100,
    token_index: 4844,
    start_char: 26069,
    end_char: 26075,
    display_word: "assume",
    period_at_end: false
    },
    {
    word: "off",
    start_time: 1803400,
    end_time: 1803800,
    token_index: 4845,
    start_char: 26076,
    end_char: 26079,
    display_word: "off",
    period_at_end: false
    },
    {
    word: "adopts",
    start_time: 1804300,
    end_time: 1804800,
    token_index: 4846,
    start_char: 26080,
    end_char: 26086,
    display_word: "adopts",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1804800,
    end_time: 1804800,
    token_index: 4847,
    start_char: 26087,
    end_char: 26088,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "diminutive",
    start_time: 1804800,
    end_time: 1805300,
    token_index: 4848,
    start_char: 26089,
    end_char: 26099,
    display_word: "diminutive",
    period_at_end: false
    },
    {
    word: "term",
    start_time: 1805300,
    end_time: 1805600,
    token_index: 4849,
    start_char: 26100,
    end_char: 26104,
    display_word: "term",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1805600,
    end_time: 1805700,
    token_index: 4850,
    start_char: 26105,
    end_char: 26108,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "women",
    start_time: 1805700,
    end_time: 1805900,
    token_index: 4851,
    start_char: 26109,
    end_char: 26114,
    display_word: "women",
    period_at_end: false
    },
    {
    word: "instead",
    start_time: 1805900,
    end_time: 1806500,
    token_index: 4852,
    start_char: 26115,
    end_char: 26122,
    display_word: "instead",
    period_at_end: false
    },
    {
    word: "any",
    start_time: 1806600,
    end_time: 1806800,
    token_index: 4853,
    start_char: 26123,
    end_char: 26126,
    display_word: "any",
    period_at_end: false
    },
    {
    word: "response",
    start_time: 1806800,
    end_time: 1807300,
    token_index: 4854,
    start_char: 26127,
    end_char: 26135,
    display_word: "response",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1807300,
    end_time: 1807400,
    token_index: 4855,
    start_char: 26136,
    end_char: 26138,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "these",
    start_time: 1807400,
    end_time: 1807700,
    token_index: 4856,
    start_char: 26139,
    end_char: 26144,
    display_word: "these",
    period_at_end: false
    },
    {
    word: "fellows.",
    start_time: 1807700,
    end_time: 1808300,
    token_index: 4857,
    start_char: 26145,
    end_char: 26152,
    display_word: "fellows",
    period_at_end: true
    },
    {
    word: "Peace.",
    start_time: 1809700,
    end_time: 1810000,
    token_index: 4858,
    start_char: 26154,
    end_char: 26159,
    display_word: "Peace",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1810000,
    end_time: 1810100,
    token_index: 4859,
    start_char: 26161,
    end_char: 26162,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "love",
    start_time: 1810100,
    end_time: 1810700,
    token_index: 4860,
    start_char: 26163,
    end_char: 26167,
    display_word: "love",
    period_at_end: false
    },
    {
    word: "peace",
    start_time: 1810700,
    end_time: 1810900,
    token_index: 4861,
    start_char: 26168,
    end_char: 26173,
    display_word: "peace",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1810900,
    end_time: 1811100,
    token_index: 4862,
    start_char: 26174,
    end_char: 26177,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "love",
    start_time: 1811100,
    end_time: 1811400,
    token_index: 4863,
    start_char: 26178,
    end_char: 26182,
    display_word: "love",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1811500,
    end_time: 1811600,
    token_index: 4864,
    start_char: 26183,
    end_char: 26186,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "last",
    start_time: 1811600,
    end_time: 1811900,
    token_index: 4865,
    start_char: 26187,
    end_char: 26191,
    display_word: "last",
    period_at_end: false
    },
    {
    word: "time",
    start_time: 1811900,
    end_time: 1812100,
    token_index: 4866,
    start_char: 26192,
    end_char: 26196,
    display_word: "time",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1812100,
    end_time: 1812200,
    token_index: 4867,
    start_char: 26197,
    end_char: 26198,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "saw",
    start_time: 1812200,
    end_time: 1812500,
    token_index: 4868,
    start_char: 26199,
    end_char: 26202,
    display_word: "saw",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1812500,
    end_time: 1813000,
    token_index: 4869,
    start_char: 26203,
    end_char: 26206,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "folks",
    start_time: 1813000,
    end_time: 1813600,
    token_index: 4870,
    start_char: 26207,
    end_char: 26212,
    display_word: "folks",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1814000,
    end_time: 1814300,
    token_index: 4871,
    start_char: 26213,
    end_char: 26216,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 1814300,
    end_time: 1815100,
    token_index: 4872,
    start_char: 26217,
    end_char: 26219,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "And",
    start_time: 1815200,
    end_time: 1815600,
    token_index: 4873,
    start_char: 26220,
    end_char: 26223,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1815600,
    end_time: 1816600,
    token_index: 4874,
    start_char: 26224,
    end_char: 26227,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "six",
    start_time: 1816600,
    end_time: 1817700,
    token_index: 4875,
    start_char: 26228,
    end_char: 26231,
    display_word: "six",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1817700,
    end_time: 1818500,
    token_index: 4876,
    start_char: 26232,
    end_char: 26235,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1818600,
    end_time: 1819100,
    token_index: 4877,
    start_char: 26236,
    end_char: 26239,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "time",
    start_time: 1819100,
    end_time: 1819400,
    token_index: 4878,
    start_char: 26240,
    end_char: 26244,
    display_word: "time",
    period_at_end: false
    },
    {
    word: "100",
    start_time: 1819400,
    end_time: 1819800,
    token_index: 4879,
    start_char: 26245,
    end_char: 26248,
    display_word: "100",
    period_at_end: false
    },
    {
    word: "dinner",
    start_time: 1819800,
    end_time: 1820200,
    token_index: 4880,
    start_char: 26249,
    end_char: 26255,
    display_word: "dinner",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1821300,
    end_time: 1822100,
    token_index: 4881,
    start_char: 26256,
    end_char: 26259,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1822100,
    end_time: 1822300,
    token_index: 4882,
    start_char: 26260,
    end_char: 26262,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 1822300,
    end_time: 1822400,
    token_index: 4883,
    start_char: 26263,
    end_char: 26266,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "talk",
    start_time: 1822400,
    end_time: 1822700,
    token_index: 4884,
    start_char: 26267,
    end_char: 26271,
    display_word: "talk",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1822700,
    end_time: 1822800,
    token_index: 4885,
    start_char: 26272,
    end_char: 26275,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "very",
    start_time: 1822800,
    end_time: 1823000,
    token_index: 4886,
    start_char: 26276,
    end_char: 26280,
    display_word: "very",
    period_at_end: false
    },
    {
    word: "long.",
    start_time: 1823000,
    end_time: 1823700,
    token_index: 4887,
    start_char: 26281,
    end_char: 26285,
    display_word: "long",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 1824000,
    end_time: 1824300,
    token_index: 4888,
    start_char: 26287,
    end_char: 26290,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 1824300,
    end_time: 1824400,
    token_index: 4889,
    start_char: 26291,
    end_char: 26294,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1824400,
    end_time: 1824500,
    token_index: 4890,
    start_char: 26295,
    end_char: 26297,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1824500,
    end_time: 1824600,
    token_index: 4891,
    start_char: 26298,
    end_char: 26301,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1824600,
    end_time: 1824900,
    token_index: 4892,
    start_char: 26302,
    end_char: 26308,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1824900,
    end_time: 1825100,
    token_index: 4893,
    start_char: 26309,
    end_char: 26313,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1825100,
    end_time: 1825200,
    token_index: 4894,
    start_char: 26314,
    end_char: 26316,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "could",
    start_time: 1825200,
    end_time: 1825400,
    token_index: 4895,
    start_char: 26317,
    end_char: 26322,
    display_word: "could",
    period_at_end: false
    },
    {
    word: "appreciate",
    start_time: 1825400,
    end_time: 1826000,
    token_index: 4896,
    start_char: 26323,
    end_char: 26333,
    display_word: "appreciate",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1826000,
    end_time: 1826300,
    token_index: 4897,
    start_char: 26334,
    end_char: 26339,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "each",
    start_time: 1826300,
    end_time: 1826400,
    token_index: 4898,
    start_char: 26340,
    end_char: 26344,
    display_word: "each",
    period_at_end: false
    },
    {
    word: "other",
    start_time: 1826400,
    end_time: 1827000,
    token_index: 4899,
    start_char: 26345,
    end_char: 26350,
    display_word: "other",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1827000,
    end_time: 1827500,
    token_index: 4900,
    start_char: 26351,
    end_char: 26353,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "getting",
    start_time: 1827500,
    end_time: 1827800,
    token_index: 4901,
    start_char: 26354,
    end_char: 26361,
    display_word: "getting",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1827800,
    end_time: 1827900,
    token_index: 4902,
    start_char: 26362,
    end_char: 26364,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1827900,
    end_time: 1828100,
    token_index: 4903,
    start_char: 26365,
    end_char: 26369,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "trouble",
    start_time: 1828100,
    end_time: 1828600,
    token_index: 4904,
    start_char: 26370,
    end_char: 26377,
    display_word: "trouble",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1828600,
    end_time: 1828700,
    token_index: 4905,
    start_char: 26378,
    end_char: 26381,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "saying",
    start_time: 1828700,
    end_time: 1829100,
    token_index: 4906,
    start_char: 26382,
    end_char: 26388,
    display_word: "saying",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1829100,
    end_time: 1829400,
    token_index: 4907,
    start_char: 26389,
    end_char: 26395,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1829400,
    end_time: 1829700,
    token_index: 4908,
    start_char: 26396,
    end_char: 26401,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "George",
    start_time: 1829700,
    end_time: 1830000,
    token_index: 4909,
    start_char: 26402,
    end_char: 26408,
    display_word: "George",
    period_at_end: false
    },
    {
    word: "w--",
    start_time: 1830000,
    end_time: 1830200,
    token_index: 4910,
    start_char: 26409,
    end_char: 26412,
    display_word: "w--",
    period_at_end: false
    },
    {
    word: "Bush",
    start_time: 1830200,
    end_time: 1830500,
    token_index: 4911,
    start_char: 26413,
    end_char: 26417,
    display_word: "Bush",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1830500,
    end_time: 1830900,
    token_index: 4912,
    start_char: 26418,
    end_char: 26425,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1830900,
    end_time: 1831000,
    token_index: 4913,
    start_char: 26426,
    end_char: 26428,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1831000,
    end_time: 1831100,
    token_index: 4914,
    start_char: 26429,
    end_char: 26432,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1831100,
    end_time: 1831300,
    token_index: 4915,
    start_char: 26433,
    end_char: 26438,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1831300,
    end_time: 1831300,
    token_index: 4916,
    start_char: 26439,
    end_char: 26440,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "week",
    start_time: 1831300,
    end_time: 1831500,
    token_index: 4917,
    start_char: 26441,
    end_char: 26445,
    display_word: "week",
    period_at_end: false
    },
    {
    word: "after",
    start_time: 1831500,
    end_time: 1831900,
    token_index: 4918,
    start_char: 26446,
    end_char: 26451,
    display_word: "after",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1831900,
    end_time: 1831900,
    token_index: 4919,
    start_char: 26452,
    end_char: 26453,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "done",
    start_time: 1831900,
    end_time: 1832200,
    token_index: 4920,
    start_char: 26454,
    end_char: 26458,
    display_word: "done",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1832200,
    end_time: 1832300,
    token_index: 4921,
    start_char: 26459,
    end_char: 26461,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "Correspondents",
    start_time: 1832300,
    end_time: 1833000,
    token_index: 4922,
    start_char: 26462,
    end_char: 26476,
    display_word: "Correspondents",
    period_at_end: false
    },
    {
    word: "Dinner",
    start_time: 1833000,
    end_time: 1833200,
    token_index: 4923,
    start_char: 26477,
    end_char: 26483,
    display_word: "Dinner",
    period_at_end: false
    },
    {
    word: "speech",
    start_time: 1833200,
    end_time: 1833800,
    token_index: 4924,
    start_char: 26484,
    end_char: 26490,
    display_word: "speech",
    period_at_end: false
    },
    {
    word: "down",
    start_time: 1834500,
    end_time: 1834800,
    token_index: 4925,
    start_char: 26491,
    end_char: 26495,
    display_word: "down",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1834800,
    end_time: 1835200,
    token_index: 4926,
    start_char: 26496,
    end_char: 26498,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "Washington",
    start_time: 1835200,
    end_time: 1835900,
    token_index: 4927,
    start_char: 26499,
    end_char: 26509,
    display_word: "Washington",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1836300,
    end_time: 1837100,
    token_index: 4928,
    start_char: 26510,
    end_char: 26513,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1837400,
    end_time: 1837500,
    token_index: 4929,
    start_char: 26514,
    end_char: 26518,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1837500,
    end_time: 1837700,
    token_index: 4930,
    start_char: 26519,
    end_char: 26522,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1837700,
    end_time: 1837800,
    token_index: 4931,
    start_char: 26523,
    end_char: 26525,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "2006",
    start_time: 1837800,
    end_time: 1838800,
    token_index: 4932,
    start_char: 26526,
    end_char: 26530,
    display_word: "2006",
    period_at_end: false
    },
    {
    word: "when",
    start_time: 1838800,
    end_time: 1839000,
    token_index: 4933,
    start_char: 26531,
    end_char: 26535,
    display_word: "when",
    period_at_end: false
    },
    {
    word: "owners",
    start_time: 1839000,
    end_time: 1839400,
    token_index: 4934,
    start_char: 26536,
    end_char: 26542,
    display_word: "owners",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1839400,
    end_time: 1839600,
    token_index: 4935,
    start_char: 26543,
    end_char: 26549,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "criticizing",
    start_time: 1839600,
    end_time: 1840200,
    token_index: 4936,
    start_char: 26550,
    end_char: 26561,
    display_word: "criticizing",
    period_at_end: false
    },
    {
    word: "him",
    start_time: 1840200,
    end_time: 1840800,
    token_index: 4937,
    start_char: 26562,
    end_char: 26565,
    display_word: "him",
    period_at_end: false
    },
    {
    word: "publicly",
    start_time: 1841000,
    end_time: 1841800,
    token_index: 4938,
    start_char: 26566,
    end_char: 26574,
    display_word: "publicly",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1841800,
    end_time: 1842500,
    token_index: 4939,
    start_char: 26575,
    end_char: 26578,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1842500,
    end_time: 1842500,
    token_index: 4940,
    start_char: 26579,
    end_char: 26582,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "guys",
    start_time: 1842500,
    end_time: 1842700,
    token_index: 4941,
    start_char: 26583,
    end_char: 26587,
    display_word: "guys",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 1842700,
    end_time: 1842900,
    token_index: 4942,
    start_char: 26588,
    end_char: 26593,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1842900,
    end_time: 1843000,
    token_index: 4943,
    start_char: 26594,
    end_char: 26598,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "three",
    start_time: 1843000,
    end_time: 1843300,
    token_index: 4944,
    start_char: 26599,
    end_char: 26604,
    display_word: "three",
    period_at_end: false
    },
    {
    word: "years",
    start_time: 1843300,
    end_time: 1843400,
    token_index: 4945,
    start_char: 26605,
    end_char: 26610,
    display_word: "years",
    period_at_end: false
    },
    {
    word: "before",
    start_time: 1843400,
    end_time: 1843800,
    token_index: 4946,
    start_char: 26611,
    end_char: 26617,
    display_word: "before",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1843800,
    end_time: 1843900,
    token_index: 4947,
    start_char: 26618,
    end_char: 26620,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "2003.",
    start_time: 1843900,
    end_time: 1845100,
    token_index: 4948,
    start_char: 26621,
    end_char: 26625,
    display_word: "2003",
    period_at_end: true
    },
    {
    word: "and",
    start_time: 1845700,
    end_time: 1846200,
    token_index: 4949,
    start_char: 26627,
    end_char: 26630,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1847500,
    end_time: 1847900,
    token_index: 4950,
    start_char: 26631,
    end_char: 26632,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "admire",
    start_time: 1847900,
    end_time: 1848600,
    token_index: 4951,
    start_char: 26633,
    end_char: 26639,
    display_word: "admire",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1848600,
    end_time: 1848900,
    token_index: 4952,
    start_char: 26640,
    end_char: 26644,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1848900,
    end_time: 1849000,
    token_index: 4953,
    start_char: 26645,
    end_char: 26648,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "spoke",
    start_time: 1849000,
    end_time: 1849300,
    token_index: 4954,
    start_char: 26649,
    end_char: 26654,
    display_word: "spoke",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 1849300,
    end_time: 1849400,
    token_index: 4955,
    start_char: 26655,
    end_char: 26659,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "mind.",
    start_time: 1849400,
    end_time: 1850100,
    token_index: 4956,
    start_char: 26660,
    end_char: 26664,
    display_word: "mind",
    period_at_end: true
    },
    {
    word: "And",
    start_time: 1851100,
    end_time: 1851800,
    token_index: 4957,
    start_char: 26666,
    end_char: 26669,
    display_word: "And",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1851900,
    end_time: 1852400,
    token_index: 4958,
    start_char: 26670,
    end_char: 26673,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1852400,
    end_time: 1852900,
    token_index: 4959,
    start_char: 26674,
    end_char: 26677,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1852900,
    end_time: 1853400,
    token_index: 4960,
    start_char: 26678,
    end_char: 26681,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "obviously",
    start_time: 1853400,
    end_time: 1854000,
    token_index: 4961,
    start_char: 26682,
    end_char: 26691,
    display_word: "obviously",
    period_at_end: false
    },
    {
    word: "history",
    start_time: 1854000,
    end_time: 1854400,
    token_index: 4962,
    start_char: 26692,
    end_char: 26699,
    display_word: "history",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 1854400,
    end_time: 1854500,
    token_index: 4963,
    start_char: 26700,
    end_char: 26703,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "proven",
    start_time: 1854500,
    end_time: 1854900,
    token_index: 4964,
    start_char: 26704,
    end_char: 26710,
    display_word: "proven",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1854900,
    end_time: 1855100,
    token_index: 4965,
    start_char: 26711,
    end_char: 26714,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "write",
    start_time: 1855100,
    end_time: 1855700,
    token_index: 4966,
    start_char: 26715,
    end_char: 26720,
    display_word: "write",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1856100,
    end_time: 1856700,
    token_index: 4967,
    start_char: 26721,
    end_char: 26726,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "being",
    start_time: 1856700,
    end_time: 1857000,
    token_index: 4968,
    start_char: 26727,
    end_char: 26732,
    display_word: "being",
    period_at_end: false
    },
    {
    word: "dragged",
    start_time: 1857000,
    end_time: 1857300,
    token_index: 4969,
    start_char: 26733,
    end_char: 26740,
    display_word: "dragged",
    period_at_end: false
    },
    {
    word: "into",
    start_time: 1857300,
    end_time: 1857500,
    token_index: 4970,
    start_char: 26741,
    end_char: 26745,
    display_word: "into",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1857500,
    end_time: 1857600,
    token_index: 4971,
    start_char: 26746,
    end_char: 26747,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "boondoggle",
    start_time: 1857600,
    end_time: 1858500,
    token_index: 4972,
    start_char: 26748,
    end_char: 26758,
    display_word: "boondoggle",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1858800,
    end_time: 1859300,
    token_index: 4973,
    start_char: 26759,
    end_char: 26761,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "Iraq",
    start_time: 1859300,
    end_time: 1859600,
    token_index: 4974,
    start_char: 26762,
    end_char: 26766,
    display_word: "Iraq",
    period_at_end: false
    },
    {
    word: "under",
    start_time: 1859600,
    end_time: 1859800,
    token_index: 4975,
    start_char: 26767,
    end_char: 26772,
    display_word: "under",
    period_at_end: false
    },
    {
    word: "false",
    start_time: 1859800,
    end_time: 1860100,
    token_index: 4976,
    start_char: 26773,
    end_char: 26778,
    display_word: "false",
    period_at_end: false
    },
    {
    word: "pretenses",
    start_time: 1860100,
    end_time: 1860800,
    token_index: 4977,
    start_char: 26779,
    end_char: 26788,
    display_word: "pretenses",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1860800,
    end_time: 1860900,
    token_index: 4978,
    start_char: 26789,
    end_char: 26792,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "all",
    start_time: 1860900,
    end_time: 1861000,
    token_index: 4979,
    start_char: 26793,
    end_char: 26796,
    display_word: "all",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1861000,
    end_time: 1861500,
    token_index: 4980,
    start_char: 26797,
    end_char: 26800,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1861500,
    end_time: 1861700,
    token_index: 4981,
    start_char: 26801,
    end_char: 26804,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chaos",
    start_time: 1861700,
    end_time: 1862100,
    token_index: 4982,
    start_char: 26805,
    end_char: 26810,
    display_word: "chaos",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1862100,
    end_time: 1862300,
    token_index: 4983,
    start_char: 26811,
    end_char: 26814,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1862300,
    end_time: 1862400,
    token_index: 4984,
    start_char: 26815,
    end_char: 26818,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "tragedy",
    start_time: 1862400,
    end_time: 1862800,
    token_index: 4985,
    start_char: 26819,
    end_char: 26826,
    display_word: "tragedy",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1862800,
    end_time: 1863100,
    token_index: 4986,
    start_char: 26827,
    end_char: 26831,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "caused",
    start_time: 1863100,
    end_time: 1863700,
    token_index: 4987,
    start_char: 26832,
    end_char: 26838,
    display_word: "caused",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1865800,
    end_time: 1866000,
    token_index: 4988,
    start_char: 26839,
    end_char: 26842,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 1866000,
    end_time: 1866200,
    token_index: 4989,
    start_char: 26843,
    end_char: 26846,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1866200,
    end_time: 1866400,
    token_index: 4990,
    start_char: 26847,
    end_char: 26851,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 1866400,
    end_time: 1866500,
    token_index: 4991,
    start_char: 26852,
    end_char: 26855,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1866500,
    end_time: 1866500,
    token_index: 4992,
    start_char: 26856,
    end_char: 26858,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1866500,
    end_time: 1866600,
    token_index: 4993,
    start_char: 26859,
    end_char: 26862,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "best",
    start_time: 1866600,
    end_time: 1866800,
    token_index: 4994,
    start_char: 26863,
    end_char: 26867,
    display_word: "best",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1866800,
    end_time: 1866900,
    token_index: 4995,
    start_char: 26868,
    end_char: 26874,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1866900,
    end_time: 1867000,
    token_index: 4996,
    start_char: 26875,
    end_char: 26879,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "ever",
    start_time: 1867000,
    end_time: 1867200,
    token_index: 4997,
    start_char: 26880,
    end_char: 26884,
    display_word: "ever",
    period_at_end: false
    },
    {
    word: "happened",
    start_time: 1867200,
    end_time: 1867400,
    token_index: 4998,
    start_char: 26885,
    end_char: 26893,
    display_word: "happened",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1867400,
    end_time: 1867500,
    token_index: 4999,
    start_char: 26894,
    end_char: 26897,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1867500,
    end_time: 1867500,
    token_index: 5000,
    start_char: 26898,
    end_char: 26899,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "band",
    start_time: 1867500,
    end_time: 1867900,
    token_index: 5001,
    start_char: 26900,
    end_char: 26904,
    display_word: "band",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 1867900,
    end_time: 1868200,
    token_index: 5002,
    start_char: 26905,
    end_char: 26909,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "though",
    start_time: 1868200,
    end_time: 1868300,
    token_index: 5003,
    start_char: 26910,
    end_char: 26916,
    display_word: "though",
    period_at_end: false
    },
    {
    word: "there",
    start_time: 1868300,
    end_time: 1868400,
    token_index: 5004,
    start_char: 26917,
    end_char: 26922,
    display_word: "there",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1868400,
    end_time: 1868500,
    token_index: 5005,
    start_char: 26923,
    end_char: 26926,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "enormous",
    start_time: 1868500,
    end_time: 1869000,
    token_index: 5006,
    start_char: 26927,
    end_char: 26935,
    display_word: "enormous",
    period_at_end: false
    },
    {
    word: "backlash",
    start_time: 1869000,
    end_time: 1869600,
    token_index: 5007,
    start_char: 26936,
    end_char: 26944,
    display_word: "backlash",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1869600,
    end_time: 1869700,
    token_index: 5008,
    start_char: 26945,
    end_char: 26948,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "there",
    start_time: 1869700,
    end_time: 1869800,
    token_index: 5009,
    start_char: 26949,
    end_char: 26954,
    display_word: "there",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1869800,
    end_time: 1869900,
    token_index: 5010,
    start_char: 26955,
    end_char: 26959,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "threats",
    start_time: 1869900,
    end_time: 1870200,
    token_index: 5011,
    start_char: 26960,
    end_char: 26967,
    display_word: "threats",
    period_at_end: false
    },
    {
    word: "against",
    start_time: 1870200,
    end_time: 1870700,
    token_index: 5012,
    start_char: 26968,
    end_char: 26975,
    display_word: "against",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1870700,
    end_time: 1870800,
    token_index: 5013,
    start_char: 26976,
    end_char: 26979,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1870800,
    end_time: 1870900,
    token_index: 5014,
    start_char: 26980,
    end_char: 26983,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "there",
    start_time: 1870900,
    end_time: 1871000,
    token_index: 5015,
    start_char: 26984,
    end_char: 26989,
    display_word: "there",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1871000,
    end_time: 1871100,
    token_index: 5016,
    start_char: 26990,
    end_char: 26993,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1871100,
    end_time: 1871200,
    token_index: 5017,
    start_char: 26994,
    end_char: 26995,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "boycott.",
    start_time: 1871200,
    end_time: 1872000,
    token_index: 5018,
    start_char: 26996,
    end_char: 27003,
    display_word: "boycott",
    period_at_end: true
    },
    {
    word: "Why",
    start_time: 1872200,
    end_time: 1872500,
    token_index: 5019,
    start_char: 27005,
    end_char: 27008,
    display_word: "Why",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1872500,
    end_time: 1872800,
    token_index: 5020,
    start_char: 27009,
    end_char: 27012,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1872800,
    end_time: 1872900,
    token_index: 5021,
    start_char: 27013,
    end_char: 27015,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 1872900,
    end_time: 1873100,
    token_index: 5022,
    start_char: 27016,
    end_char: 27019,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1873100,
    end_time: 1873100,
    token_index: 5023,
    start_char: 27020,
    end_char: 27022,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1873100,
    end_time: 1873200,
    token_index: 5024,
    start_char: 27023,
    end_char: 27026,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "best",
    start_time: 1873200,
    end_time: 1873400,
    token_index: 5025,
    start_char: 27027,
    end_char: 27031,
    display_word: "best",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1873400,
    end_time: 1873600,
    token_index: 5026,
    start_char: 27032,
    end_char: 27038,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1873600,
    end_time: 1873800,
    token_index: 5027,
    start_char: 27039,
    end_char: 27043,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "happened",
    start_time: 1873800,
    end_time: 1874100,
    token_index: 5028,
    start_char: 27044,
    end_char: 27052,
    display_word: "happened",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1874100,
    end_time: 1874200,
    token_index: 5029,
    start_char: 27053,
    end_char: 27055,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "you?",
    start_time: 1874200,
    end_time: 1874400,
    token_index: 5030,
    start_char: 27056,
    end_char: 27060,
    display_word: "you?",
    period_at_end: false
    },
    {
    word: "It",
    start_time: 1875200,
    end_time: 1875300,
    token_index: 5031,
    start_char: 27061,
    end_char: 27063,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1875300,
    end_time: 1875500,
    token_index: 5032,
    start_char: 27064,
    end_char: 27067,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "kind",
    start_time: 1875500,
    end_time: 1875700,
    token_index: 5033,
    start_char: 27068,
    end_char: 27072,
    display_word: "kind",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1875700,
    end_time: 1875800,
    token_index: 5034,
    start_char: 27073,
    end_char: 27075,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "liberating",
    start_time: 1875800,
    end_time: 1876700,
    token_index: 5035,
    start_char: 27076,
    end_char: 27086,
    display_word: "liberating",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1876700,
    end_time: 1876900,
    token_index: 5036,
    start_char: 27087,
    end_char: 27089,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1876900,
    end_time: 1877300,
    token_index: 5037,
    start_char: 27090,
    end_char: 27094,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1877300,
    end_time: 1877400,
    token_index: 5038,
    start_char: 27095,
    end_char: 27099,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1877400,
    end_time: 1877600,
    token_index: 5039,
    start_char: 27100,
    end_char: 27103,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "audience",
    start_time: 1877600,
    end_time: 1878300,
    token_index: 5040,
    start_char: 27104,
    end_char: 27112,
    display_word: "audience",
    period_at_end: false
    },
    {
    word: "knew",
    start_time: 1878300,
    end_time: 1878900,
    token_index: 5041,
    start_char: 27113,
    end_char: 27117,
    display_word: "knew",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 1879000,
    end_time: 1879100,
    token_index: 5042,
    start_char: 27118,
    end_char: 27121,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1879100,
    end_time: 1879300,
    token_index: 5043,
    start_char: 27122,
    end_char: 27124,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1879300,
    end_time: 1879700,
    token_index: 5044,
    start_char: 27125,
    end_char: 27129,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1879700,
    end_time: 1879900,
    token_index: 5045,
    start_char: 27130,
    end_char: 27133,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1879900,
    end_time: 1880000,
    token_index: 5046,
    start_char: 27134,
    end_char: 27138,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1880000,
    end_time: 1880200,
    token_index: 5047,
    start_char: 27139,
    end_char: 27141,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "thought",
    start_time: 1880200,
    end_time: 1880700,
    token_index: 5048,
    start_char: 27142,
    end_char: 27149,
    display_word: "thought",
    period_at_end: false
    },
    {
    word: "We've",
    start_time: 1881900,
    end_time: 1882200,
    token_index: 5049,
    start_char: 27150,
    end_char: 27155,
    display_word: "We've",
    period_at_end: false
    },
    {
    word: "always",
    start_time: 1882200,
    end_time: 1882600,
    token_index: 5050,
    start_char: 27156,
    end_char: 27162,
    display_word: "always",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1882600,
    end_time: 1883000,
    token_index: 5051,
    start_char: 27163,
    end_char: 27167,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "very",
    start_time: 1883000,
    end_time: 1883600,
    token_index: 5052,
    start_char: 27168,
    end_char: 27172,
    display_word: "very",
    period_at_end: false
    },
    {
    word: "liberal",
    start_time: 1883600,
    end_time: 1884300,
    token_index: 5053,
    start_char: 27173,
    end_char: 27180,
    display_word: "liberal",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1884400,
    end_time: 1885200,
    token_index: 5054,
    start_char: 27181,
    end_char: 27184,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "spoken",
    start_time: 1886000,
    end_time: 1886400,
    token_index: 5055,
    start_char: 27185,
    end_char: 27191,
    display_word: "spoken",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1886400,
    end_time: 1886500,
    token_index: 5056,
    start_char: 27192,
    end_char: 27195,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "minds.",
    start_time: 1886500,
    end_time: 1887000,
    token_index: 5057,
    start_char: 27196,
    end_char: 27201,
    display_word: "minds",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 1887000,
    end_time: 1887100,
    token_index: 5058,
    start_char: 27203,
    end_char: 27206,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1887100,
    end_time: 1887300,
    token_index: 5059,
    start_char: 27207,
    end_char: 27211,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1887300,
    end_time: 1887400,
    token_index: 5060,
    start_char: 27212,
    end_char: 27215,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1887400,
    end_time: 1887600,
    token_index: 5061,
    start_char: 27216,
    end_char: 27220,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1887600,
    end_time: 1887800,
    token_index: 5062,
    start_char: 27221,
    end_char: 27224,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 1887800,
    end_time: 1888300,
    token_index: 5063,
    start_char: 27225,
    end_char: 27228,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "moment",
    start_time: 1888300,
    end_time: 1888700,
    token_index: 5064,
    start_char: 27229,
    end_char: 27235,
    display_word: "moment",
    period_at_end: false
    },
    {
    word: "where",
    start_time: 1888700,
    end_time: 1889200,
    token_index: 5065,
    start_char: 27236,
    end_char: 27241,
    display_word: "where",
    period_at_end: false
    },
    {
    word: "Natalie",
    start_time: 1889200,
    end_time: 1889600,
    token_index: 5066,
    start_char: 27242,
    end_char: 27249,
    display_word: "Natalie",
    period_at_end: false
    },
    {
    word: "said",
    start_time: 1889600,
    end_time: 1889900,
    token_index: 5067,
    start_char: 27250,
    end_char: 27254,
    display_word: "said",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1889900,
    end_time: 1890100,
    token_index: 5068,
    start_char: 27255,
    end_char: 27259,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1890100,
    end_time: 1890400,
    token_index: 5069,
    start_char: 27260,
    end_char: 27263,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "Firestorm",
    start_time: 1890400,
    end_time: 1891100,
    token_index: 5070,
    start_char: 27264,
    end_char: 27273,
    display_word: "Firestorm",
    period_at_end: false
    },
    {
    word: "erupted.",
    start_time: 1891100,
    end_time: 1891800,
    token_index: 5071,
    start_char: 27274,
    end_char: 27281,
    display_word: "erupted",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 1892300,
    end_time: 1892500,
    token_index: 5072,
    start_char: 27283,
    end_char: 27286,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "yeah,",
    start_time: 1892500,
    end_time: 1892700,
    token_index: 5073,
    start_char: 27287,
    end_char: 27292,
    display_word: "yeah,",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1892700,
    end_time: 1892900,
    token_index: 5074,
    start_char: 27293,
    end_char: 27295,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1892900,
    end_time: 1893100,
    token_index: 5075,
    start_char: 27296,
    end_char: 27300,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1893100,
    end_time: 1893300,
    token_index: 5076,
    start_char: 27301,
    end_char: 27303,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "kind",
    start_time: 1893300,
    end_time: 1893500,
    token_index: 5077,
    start_char: 27304,
    end_char: 27308,
    display_word: "kind",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1893500,
    end_time: 1893600,
    token_index: 5078,
    start_char: 27309,
    end_char: 27311,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "felt",
    start_time: 1893600,
    end_time: 1893800,
    token_index: 5079,
    start_char: 27312,
    end_char: 27316,
    display_word: "felt",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1893800,
    end_time: 1894000,
    token_index: 5080,
    start_char: 27317,
    end_char: 27321,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "okay",
    start_time: 1894000,
    end_time: 1894200,
    token_index: 5081,
    start_char: 27322,
    end_char: 27326,
    display_word: "okay",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1894200,
    end_time: 1894300,
    token_index: 5082,
    start_char: 27327,
    end_char: 27330,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "cats",
    start_time: 1894300,
    end_time: 1894600,
    token_index: 5083,
    start_char: 27331,
    end_char: 27335,
    display_word: "cats",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 1894600,
    end_time: 1894800,
    token_index: 5084,
    start_char: 27336,
    end_char: 27339,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1894800,
    end_time: 1894800,
    token_index: 5085,
    start_char: 27340,
    end_char: 27342,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1894800,
    end_time: 1894900,
    token_index: 5086,
    start_char: 27343,
    end_char: 27346,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "bag",
    start_time: 1894900,
    end_time: 1895600,
    token_index: 5087,
    start_char: 27347,
    end_char: 27350,
    display_word: "bag",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1895800,
    end_time: 1896500,
    token_index: 5088,
    start_char: 27351,
    end_char: 27354,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "that's",
    start_time: 1896500,
    end_time: 1896900,
    token_index: 5089,
    start_char: 27355,
    end_char: 27361,
    display_word: "that's",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 1896900,
    end_time: 1897000,
    token_index: 5090,
    start_char: 27362,
    end_char: 27365,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1897000,
    end_time: 1897200,
    token_index: 5091,
    start_char: 27366,
    end_char: 27368,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1897200,
    end_time: 1897600,
    token_index: 5092,
    start_char: 27369,
    end_char: 27372,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1897600,
    end_time: 1898100,
    token_index: 5093,
    start_char: 27373,
    end_char: 27376,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1898100,
    end_time: 1898800,
    token_index: 5094,
    start_char: 27377,
    end_char: 27380,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 1898800,
    end_time: 1898900,
    token_index: 5095,
    start_char: 27381,
    end_char: 27384,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1898900,
    end_time: 1899000,
    token_index: 5096,
    start_char: 27385,
    end_char: 27387,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1899000,
    end_time: 1899200,
    token_index: 5097,
    start_char: 27388,
    end_char: 27391,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1899200,
    end_time: 1899700,
    token_index: 5098,
    start_char: 27392,
    end_char: 27394,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "being",
    start_time: 1899700,
    end_time: 1900000,
    token_index: 5099,
    start_char: 27395,
    end_char: 27400,
    display_word: "being",
    period_at_end: false
    },
    {
    word: "patriotic",
    start_time: 1900000,
    end_time: 1900800,
    token_index: 5100,
    start_char: 27401,
    end_char: 27410,
    display_word: "patriotic",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1900800,
    end_time: 1901200,
    token_index: 5101,
    start_char: 27411,
    end_char: 27413,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "worry",
    start_time: 1901200,
    end_time: 1901400,
    token_index: 5102,
    start_char: 27414,
    end_char: 27419,
    display_word: "worry",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1901400,
    end_time: 1901700,
    token_index: 5103,
    start_char: 27420,
    end_char: 27425,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1901700,
    end_time: 1901800,
    token_index: 5104,
    start_char: 27426,
    end_char: 27429,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "troops",
    start_time: 1901800,
    end_time: 1902200,
    token_index: 5105,
    start_char: 27430,
    end_char: 27436,
    display_word: "troops",
    period_at_end: false
    },
    {
    word: "In",
    start_time: 1902200,
    end_time: 1902400,
    token_index: 5106,
    start_char: 27437,
    end_char: 27439,
    display_word: "In",
    period_at_end: false
    },
    {
    word: "Harm's",
    start_time: 1902400,
    end_time: 1902800,
    token_index: 5107,
    start_char: 27440,
    end_char: 27446,
    display_word: "Harm's",
    period_at_end: false
    },
    {
    word: "Way",
    start_time: 1902800,
    end_time: 1903300,
    token_index: 5108,
    start_char: 27447,
    end_char: 27450,
    display_word: "Way",
    period_at_end: false
    },
    {
    word: "With",
    start_time: 1905100,
    end_time: 1905300,
    token_index: 5109,
    start_char: 27451,
    end_char: 27455,
    display_word: "With",
    period_at_end: false
    },
    {
    word: "The",
    start_time: 1905300,
    end_time: 1905400,
    token_index: 5110,
    start_char: 27456,
    end_char: 27459,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "Invasion.",
    start_time: 1905400,
    end_time: 1906000,
    token_index: 5111,
    start_char: 27460,
    end_char: 27468,
    display_word: "Invasion",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 1906000,
    end_time: 1906100,
    token_index: 5112,
    start_char: 27470,
    end_char: 27472,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1906100,
    end_time: 1906200,
    token_index: 5113,
    start_char: 27473,
    end_char: 27476,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 1906200,
    end_time: 1906500,
    token_index: 5114,
    start_char: 27477,
    end_char: 27481,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "kind",
    start_time: 1906500,
    end_time: 1906700,
    token_index: 5115,
    start_char: 27482,
    end_char: 27486,
    display_word: "kind",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1906700,
    end_time: 1906900,
    token_index: 5116,
    start_char: 27487,
    end_char: 27489,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "ironic",
    start_time: 1906900,
    end_time: 1907400,
    token_index: 5117,
    start_char: 27490,
    end_char: 27496,
    display_word: "ironic",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1907400,
    end_time: 1907600,
    token_index: 5118,
    start_char: 27497,
    end_char: 27501,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1907600,
    end_time: 1907900,
    token_index: 5119,
    start_char: 27502,
    end_char: 27508,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1907900,
    end_time: 1908200,
    token_index: 5120,
    start_char: 27509,
    end_char: 27513,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "saying",
    start_time: 1908300,
    end_time: 1909000,
    token_index: 5121,
    start_char: 27514,
    end_char: 27520,
    display_word: "saying",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1909000,
    end_time: 1909300,
    token_index: 5122,
    start_char: 27521,
    end_char: 27524,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1909300,
    end_time: 1909500,
    token_index: 5123,
    start_char: 27525,
    end_char: 27529,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "she",
    start_time: 1909500,
    end_time: 1909800,
    token_index: 5124,
    start_char: 27530,
    end_char: 27533,
    display_word: "she",
    period_at_end: false
    },
    {
    word: "said,",
    start_time: 1909800,
    end_time: 1910300,
    token_index: 5125,
    start_char: 27534,
    end_char: 27539,
    display_word: "said,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1910300,
    end_time: 1910800,
    token_index: 5126,
    start_char: 27540,
    end_char: 27543,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "She",
    start_time: 1911000,
    end_time: 1911200,
    token_index: 5127,
    start_char: 27544,
    end_char: 27547,
    display_word: "She",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 1911200,
    end_time: 1911400,
    token_index: 5128,
    start_char: 27548,
    end_char: 27554,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "support",
    start_time: 1911400,
    end_time: 1911800,
    token_index: 5129,
    start_char: 27555,
    end_char: 27562,
    display_word: "support",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1911800,
    end_time: 1911900,
    token_index: 5130,
    start_char: 27563,
    end_char: 27566,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "troops.",
    start_time: 1911900,
    end_time: 1912700,
    token_index: 5131,
    start_char: 27567,
    end_char: 27573,
    display_word: "troops",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1913700,
    end_time: 1913800,
    token_index: 5132,
    start_char: 27575,
    end_char: 27576,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1913800,
    end_time: 1914000,
    token_index: 5133,
    start_char: 27577,
    end_char: 27582,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 1914000,
    end_time: 1914100,
    token_index: 5134,
    start_char: 27583,
    end_char: 27585,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1914100,
    end_time: 1914200,
    token_index: 5135,
    start_char: 27586,
    end_char: 27589,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "ask",
    start_time: 1914200,
    end_time: 1914500,
    token_index: 5136,
    start_char: 27590,
    end_char: 27593,
    display_word: "ask",
    period_at_end: false
    },
    {
    word: "people",
    start_time: 1914500,
    end_time: 1914800,
    token_index: 5137,
    start_char: 27594,
    end_char: 27600,
    display_word: "people",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1914800,
    end_time: 1914800,
    token_index: 5138,
    start_char: 27601,
    end_char: 27602,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 1914800,
    end_time: 1915000,
    token_index: 5139,
    start_char: 27603,
    end_char: 27608,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 1915000,
    end_time: 1915200,
    token_index: 5140,
    start_char: 27609,
    end_char: 27613,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1915200,
    end_time: 1915400,
    token_index: 5141,
    start_char: 27614,
    end_char: 27618,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 1915400,
    end_time: 1915500,
    token_index: 5142,
    start_char: 27619,
    end_char: 27621,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1915500,
    end_time: 1915600,
    token_index: 5143,
    start_char: 27622,
    end_char: 27626,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1915600,
    end_time: 1915800,
    token_index: 5144,
    start_char: 27627,
    end_char: 27631,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1915800,
    end_time: 1916000,
    token_index: 5145,
    start_char: 27632,
    end_char: 27636,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "she",
    start_time: 1916000,
    end_time: 1916200,
    token_index: 5146,
    start_char: 27637,
    end_char: 27640,
    display_word: "she",
    period_at_end: false
    },
    {
    word: "said.",
    start_time: 1916200,
    end_time: 1916600,
    token_index: 5147,
    start_char: 27641,
    end_char: 27645,
    display_word: "said",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 1916600,
    end_time: 1916600,
    token_index: 5148,
    start_char: 27647,
    end_char: 27649,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1916600,
    end_time: 1916700,
    token_index: 5149,
    start_char: 27650,
    end_char: 27653,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1916700,
    end_time: 1917100,
    token_index: 5150,
    start_char: 27654,
    end_char: 27656,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "innocuous,",
    start_time: 1917100,
    end_time: 1917800,
    token_index: 5151,
    start_char: 27657,
    end_char: 27667,
    display_word: "innocuous,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1917800,
    end_time: 1917900,
    token_index: 5152,
    start_char: 27668,
    end_char: 27671,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know.",
    start_time: 1917900,
    end_time: 1918200,
    token_index: 5153,
    start_char: 27672,
    end_char: 27676,
    display_word: "know",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 1919300,
    end_time: 1919700,
    token_index: 5154,
    start_char: 27678,
    end_char: 27683,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1919800,
    end_time: 1920500,
    token_index: 5155,
    start_char: 27684,
    end_char: 27687,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1920500,
    end_time: 1920700,
    token_index: 5156,
    start_char: 27688,
    end_char: 27692,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1920700,
    end_time: 1920700,
    token_index: 5157,
    start_char: 27693,
    end_char: 27694,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 1920700,
    end_time: 1920900,
    token_index: 5158,
    start_char: 27695,
    end_char: 27698,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "way",
    start_time: 1920900,
    end_time: 1921300,
    token_index: 5159,
    start_char: 27699,
    end_char: 27702,
    display_word: "way",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1921500,
    end_time: 1921900,
    token_index: 5160,
    start_char: 27703,
    end_char: 27705,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "talk",
    start_time: 1921900,
    end_time: 1922200,
    token_index: 5161,
    start_char: 27706,
    end_char: 27710,
    display_word: "talk",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 1922200,
    end_time: 1922400,
    token_index: 5162,
    start_char: 27711,
    end_char: 27716,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1922400,
    end_time: 1922500,
    token_index: 5163,
    start_char: 27717,
    end_char: 27720,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1922500,
    end_time: 1922700,
    token_index: 5164,
    start_char: 27721,
    end_char: 27724,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "feel.",
    start_time: 1922700,
    end_time: 1923200,
    token_index: 5165,
    start_char: 27725,
    end_char: 27729,
    display_word: "feel",
    period_at_end: true
    },
    {
    word: "The",
    start_time: 1923400,
    end_time: 1923400,
    token_index: 5166,
    start_char: 27731,
    end_char: 27734,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 1923400,
    end_time: 1923600,
    token_index: 5167,
    start_char: 27735,
    end_char: 27738,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "album",
    start_time: 1923600,
    end_time: 1923900,
    token_index: 5168,
    start_char: 27739,
    end_char: 27744,
    display_word: "album",
    period_at_end: false
    },
    {
    word: "gas",
    start_time: 1923900,
    end_time: 1924300,
    token_index: 5169,
    start_char: 27745,
    end_char: 27748,
    display_word: "gas",
    period_at_end: false
    },
    {
    word: "lighter",
    start_time: 1924300,
    end_time: 1924800,
    token_index: 5170,
    start_char: 27749,
    end_char: 27756,
    display_word: "lighter",
    period_at_end: false
    },
    {
    word: "comes",
    start_time: 1924800,
    end_time: 1925200,
    token_index: 5171,
    start_char: 27757,
    end_char: 27762,
    display_word: "comes",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 1925200,
    end_time: 1925400,
    token_index: 5172,
    start_char: 27763,
    end_char: 27766,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "tomorrow.",
    start_time: 1925400,
    end_time: 1925800,
    token_index: 5173,
    start_char: 27767,
    end_char: 27775,
    display_word: "tomorrow",
    period_at_end: true
    },
    {
    word: "It",
    start_time: 1925800,
    end_time: 1925900,
    token_index: 5174,
    start_char: 27777,
    end_char: 27779,
    display_word: "It",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1925900,
    end_time: 1926000,
    token_index: 5175,
    start_char: 27780,
    end_char: 27783,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "supposed",
    start_time: 1926000,
    end_time: 1926300,
    token_index: 5176,
    start_char: 27784,
    end_char: 27792,
    display_word: "supposed",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1926300,
    end_time: 1926400,
    token_index: 5177,
    start_char: 27793,
    end_char: 27795,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1926400,
    end_time: 1926500,
    token_index: 5178,
    start_char: 27796,
    end_char: 27798,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "released",
    start_time: 1926500,
    end_time: 1926800,
    token_index: 5179,
    start_char: 27799,
    end_char: 27807,
    display_word: "released",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1926800,
    end_time: 1927000,
    token_index: 5180,
    start_char: 27808,
    end_char: 27810,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "May",
    start_time: 1927000,
    end_time: 1927500,
    token_index: 5181,
    start_char: 27811,
    end_char: 27814,
    display_word: "May",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1927700,
    end_time: 1927900,
    token_index: 5182,
    start_char: 27815,
    end_char: 27818,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 1927900,
    end_time: 1928000,
    token_index: 5183,
    start_char: 27819,
    end_char: 27823,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 1928000,
    end_time: 1928300,
    token_index: 5184,
    start_char: 27824,
    end_char: 27827,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1928300,
    end_time: 1928400,
    token_index: 5185,
    start_char: 27828,
    end_char: 27830,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1928400,
    end_time: 1929100,
    token_index: 5186,
    start_char: 27831,
    end_char: 27833,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "put",
    start_time: 1929100,
    end_time: 1929300,
    token_index: 5187,
    start_char: 27834,
    end_char: 27837,
    display_word: "put",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1929300,
    end_time: 1929500,
    token_index: 5188,
    start_char: 27838,
    end_char: 27840,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "hold",
    start_time: 1929500,
    end_time: 1930000,
    token_index: 5189,
    start_char: 27841,
    end_char: 27845,
    display_word: "hold",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 1930000,
    end_time: 1930400,
    token_index: 5190,
    start_char: 27846,
    end_char: 27853,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1930400,
    end_time: 1930500,
    token_index: 5191,
    start_char: 27854,
    end_char: 27856,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1930500,
    end_time: 1930600,
    token_index: 5192,
    start_char: 27857,
    end_char: 27860,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "covid",
    start_time: 1930600,
    end_time: 1931000,
    token_index: 5193,
    start_char: 27861,
    end_char: 27866,
    display_word: "covid",
    period_at_end: false
    },
    {
    word: "crisis.",
    start_time: 1931000,
    end_time: 1931500,
    token_index: 5194,
    start_char: 27867,
    end_char: 27873,
    display_word: "crisis",
    period_at_end: true
    },
    {
    word: "What",
    start_time: 1931500,
    end_time: 1931900,
    token_index: 5195,
    start_char: 27875,
    end_char: 27879,
    display_word: "What",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1931900,
    end_time: 1932000,
    token_index: 5196,
    start_char: 27880,
    end_char: 27883,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1932000,
    end_time: 1932100,
    token_index: 5197,
    start_char: 27884,
    end_char: 27888,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1932100,
    end_time: 1932500,
    token_index: 5198,
    start_char: 27889,
    end_char: 27893,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1932500,
    end_time: 1933000,
    token_index: 5199,
    start_char: 27894,
    end_char: 27896,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 1933000,
    end_time: 1933500,
    token_index: 5200,
    start_char: 27897,
    end_char: 27900,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "create",
    start_time: 1933500,
    end_time: 1934300,
    token_index: 5201,
    start_char: 27901,
    end_char: 27907,
    display_word: "create",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 1934300,
    end_time: 1934500,
    token_index: 5202,
    start_char: 27908,
    end_char: 27910,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "album",
    start_time: 1934500,
    end_time: 1934800,
    token_index: 5203,
    start_char: 27911,
    end_char: 27916,
    display_word: "album",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1934800,
    end_time: 1935200,
    token_index: 5204,
    start_char: 27917,
    end_char: 27919,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 1935200,
    end_time: 1935300,
    token_index: 5205,
    start_char: 27920,
    end_char: 27923,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "music",
    start_time: 1935300,
    end_time: 1935700,
    token_index: 5206,
    start_char: 27924,
    end_char: 27929,
    display_word: "music",
    period_at_end: false
    },
    {
    word: "together",
    start_time: 1935700,
    end_time: 1935800,
    token_index: 5207,
    start_char: 27930,
    end_char: 27938,
    display_word: "together",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1935800,
    end_time: 1936300,
    token_index: 5208,
    start_char: 27939,
    end_char: 27942,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "14",
    start_time: 1936300,
    end_time: 1936900,
    token_index: 5209,
    start_char: 27943,
    end_char: 27945,
    display_word: "14",
    period_at_end: false
    },
    {
    word: "years",
    start_time: 1936900,
    end_time: 1937000,
    token_index: 5210,
    start_char: 27946,
    end_char: 27951,
    display_word: "years",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1937000,
    end_time: 1937600,
    token_index: 5211,
    start_char: 27952,
    end_char: 27955,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "then",
    start_time: 1937600,
    end_time: 1938000,
    token_index: 5212,
    start_char: 27956,
    end_char: 27960,
    display_word: "then",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1938000,
    end_time: 1938800,
    token_index: 5213,
    start_char: 27961,
    end_char: 27963,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "ready",
    start_time: 1938800,
    end_time: 1939100,
    token_index: 5214,
    start_char: 27964,
    end_char: 27969,
    display_word: "ready",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1939100,
    end_time: 1939400,
    token_index: 5215,
    start_char: 27970,
    end_char: 27972,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1939400,
    end_time: 1939600,
    token_index: 5216,
    start_char: 27973,
    end_char: 27977,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1939600,
    end_time: 1939800,
    token_index: 5217,
    start_char: 27978,
    end_char: 27980,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1939800,
    end_time: 1939900,
    token_index: 5218,
    start_char: 27981,
    end_char: 27984,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "world",
    start_time: 1939900,
    end_time: 1940000,
    token_index: 5219,
    start_char: 27985,
    end_char: 27990,
    display_word: "world",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1940000,
    end_time: 1940300,
    token_index: 5220,
    start_char: 27991,
    end_char: 27994,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1940300,
    end_time: 1940500,
    token_index: 5221,
    start_char: 27995,
    end_char: 27999,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1940500,
    end_time: 1940700,
    token_index: 5222,
    start_char: 28000,
    end_char: 28002,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "hit",
    start_time: 1940700,
    end_time: 1941000,
    token_index: 5223,
    start_char: 28003,
    end_char: 28006,
    display_word: "hit",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1941000,
    end_time: 1941200,
    token_index: 5224,
    start_char: 28007,
    end_char: 28010,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "brakes",
    start_time: 1941200,
    end_time: 1941300,
    token_index: 5225,
    start_char: 28011,
    end_char: 28017,
    display_word: "brakes",
    period_at_end: false
    },
    {
    word: "at",
    start_time: 1941300,
    end_time: 1942100,
    token_index: 5226,
    start_char: 28018,
    end_char: 28020,
    display_word: "at",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1942100,
    end_time: 1942200,
    token_index: 5227,
    start_char: 28021,
    end_char: 28024,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "last",
    start_time: 1942200,
    end_time: 1942600,
    token_index: 5228,
    start_char: 28025,
    end_char: 28029,
    display_word: "last",
    period_at_end: false
    },
    {
    word: "minute.",
    start_time: 1942600,
    end_time: 1942800,
    token_index: 5229,
    start_char: 28030,
    end_char: 28036,
    display_word: "minute",
    period_at_end: true
    },
    {
    word: "What",
    start_time: 1942800,
    end_time: 1943000,
    token_index: 5230,
    start_char: 28038,
    end_char: 28042,
    display_word: "What",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1943000,
    end_time: 1943100,
    token_index: 5231,
    start_char: 28043,
    end_char: 28046,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "that?",
    start_time: 1943100,
    end_time: 1943300,
    token_index: 5232,
    start_char: 28047,
    end_char: 28052,
    display_word: "that?",
    period_at_end: false
    },
    {
    word: "What",
    start_time: 1943300,
    end_time: 1943800,
    token_index: 5233,
    start_char: 28053,
    end_char: 28057,
    display_word: "What",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1943800,
    end_time: 1943900,
    token_index: 5234,
    start_char: 28058,
    end_char: 28061,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1943900,
    end_time: 1944000,
    token_index: 5235,
    start_char: 28062,
    end_char: 28066,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "process",
    start_time: 1944000,
    end_time: 1944300,
    token_index: 5236,
    start_char: 28067,
    end_char: 28074,
    display_word: "process",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 1944300,
    end_time: 1944800,
    token_index: 5237,
    start_char: 28075,
    end_char: 28077,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 1944800,
    end_time: 1944900,
    token_index: 5238,
    start_char: 28078,
    end_char: 28082,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1944900,
    end_time: 1945000,
    token_index: 5239,
    start_char: 28083,
    end_char: 28086,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1945000,
    end_time: 1945000,
    token_index: 5240,
    start_char: 28087,
    end_char: 28091,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "feeling",
    start_time: 1945000,
    end_time: 1945200,
    token_index: 5241,
    start_char: 28092,
    end_char: 28099,
    display_word: "feeling",
    period_at_end: false
    },
    {
    word: "like?",
    start_time: 1945200,
    end_time: 1945400,
    token_index: 5242,
    start_char: 28100,
    end_char: 28105,
    display_word: "like?",
    period_at_end: false
    },
    {
    word: "That",
    start_time: 1946600,
    end_time: 1946800,
    token_index: 5243,
    start_char: 28106,
    end_char: 28110,
    display_word: "That",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 1946800,
    end_time: 1947000,
    token_index: 5244,
    start_char: 28111,
    end_char: 28114,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1947000,
    end_time: 1947100,
    token_index: 5245,
    start_char: 28115,
    end_char: 28116,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "huge",
    start_time: 1947100,
    end_time: 1947500,
    token_index: 5246,
    start_char: 28117,
    end_char: 28121,
    display_word: "huge",
    period_at_end: false
    },
    {
    word: "bummer.",
    start_time: 1947500,
    end_time: 1947800,
    token_index: 5247,
    start_char: 28122,
    end_char: 28128,
    display_word: "bummer",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1947800,
    end_time: 1947900,
    token_index: 5248,
    start_char: 28130,
    end_char: 28131,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1947900,
    end_time: 1948200,
    token_index: 5249,
    start_char: 28132,
    end_char: 28137,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "mostly",
    start_time: 1948200,
    end_time: 1948700,
    token_index: 5250,
    start_char: 28138,
    end_char: 28144,
    display_word: "mostly",
    period_at_end: false
    },
    {
    word: "mean",
    start_time: 1948700,
    end_time: 1948800,
    token_index: 5251,
    start_char: 28145,
    end_char: 28149,
    display_word: "mean",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1948800,
    end_time: 1949000,
    token_index: 5252,
    start_char: 28150,
    end_char: 28152,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1949000,
    end_time: 1949100,
    token_index: 5253,
    start_char: 28153,
    end_char: 28157,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1949100,
    end_time: 1949300,
    token_index: 5254,
    start_char: 28158,
    end_char: 28164,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "excited",
    start_time: 1949300,
    end_time: 1949800,
    token_index: 5255,
    start_char: 28165,
    end_char: 28172,
    display_word: "excited",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1949800,
    end_time: 1949900,
    token_index: 5256,
    start_char: 28173,
    end_char: 28176,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1949900,
    end_time: 1950100,
    token_index: 5257,
    start_char: 28177,
    end_char: 28179,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1950100,
    end_time: 1950200,
    token_index: 5258,
    start_char: 28180,
    end_char: 28183,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1950200,
    end_time: 1950400,
    token_index: 5259,
    start_char: 28184,
    end_char: 28190,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "excited",
    start_time: 1950400,
    end_time: 1950900,
    token_index: 5260,
    start_char: 28191,
    end_char: 28198,
    display_word: "excited",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1950900,
    end_time: 1951100,
    token_index: 5261,
    start_char: 28199,
    end_char: 28202,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 1951100,
    end_time: 1951300,
    token_index: 5262,
    start_char: 28203,
    end_char: 28206,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "fans",
    start_time: 1951300,
    end_time: 1951800,
    token_index: 5263,
    start_char: 28207,
    end_char: 28211,
    display_word: "fans",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1951800,
    end_time: 1951900,
    token_index: 5264,
    start_char: 28212,
    end_char: 28214,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "hear",
    start_time: 1951900,
    end_time: 1952300,
    token_index: 5265,
    start_char: 28215,
    end_char: 28219,
    display_word: "hear",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1952300,
    end_time: 1952400,
    token_index: 5266,
    start_char: 28220,
    end_char: 28223,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "new",
    start_time: 1952400,
    end_time: 1952600,
    token_index: 5267,
    start_char: 28224,
    end_char: 28227,
    display_word: "new",
    period_at_end: false
    },
    {
    word: "album.",
    start_time: 1952600,
    end_time: 1953300,
    token_index: 5268,
    start_char: 28228,
    end_char: 28233,
    display_word: "album",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 1953400,
    end_time: 1953500,
    token_index: 5269,
    start_char: 28235,
    end_char: 28238,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1953500,
    end_time: 1953700,
    token_index: 5270,
    start_char: 28239,
    end_char: 28241,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "also",
    start_time: 1953700,
    end_time: 1954200,
    token_index: 5271,
    start_char: 28242,
    end_char: 28246,
    display_word: "also",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1954200,
    end_time: 1954900,
    token_index: 5272,
    start_char: 28247,
    end_char: 28251,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "following",
    start_time: 1954900,
    end_time: 1955400,
    token_index: 5273,
    start_char: 28252,
    end_char: 28261,
    display_word: "following",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1955400,
    end_time: 1955700,
    token_index: 5274,
    start_char: 28262,
    end_char: 28266,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "up",
    start_time: 1955700,
    end_time: 1956000,
    token_index: 5275,
    start_char: 28267,
    end_char: 28269,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 1956000,
    end_time: 1956200,
    token_index: 5276,
    start_char: 28270,
    end_char: 28274,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1956200,
    end_time: 1956200,
    token_index: 5277,
    start_char: 28275,
    end_char: 28276,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "tour.",
    start_time: 1956200,
    end_time: 1956800,
    token_index: 5278,
    start_char: 28277,
    end_char: 28281,
    display_word: "tour",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 1956800,
    end_time: 1956900,
    token_index: 5279,
    start_char: 28283,
    end_char: 28285,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1956900,
    end_time: 1957000,
    token_index: 5280,
    start_char: 28286,
    end_char: 28290,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "supposed",
    start_time: 1957000,
    end_time: 1957300,
    token_index: 5281,
    start_char: 28291,
    end_char: 28299,
    display_word: "supposed",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1957300,
    end_time: 1957400,
    token_index: 5282,
    start_char: 28300,
    end_char: 28302,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1957400,
    end_time: 1957600,
    token_index: 5283,
    start_char: 28303,
    end_char: 28305,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 1957600,
    end_time: 1957800,
    token_index: 5284,
    start_char: 28306,
    end_char: 28308,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "tour.",
    start_time: 1957800,
    end_time: 1958100,
    token_index: 5285,
    start_char: 28309,
    end_char: 28313,
    display_word: "tour",
    period_at_end: true
    },
    {
    word: "This",
    start_time: 1958100,
    end_time: 1958300,
    token_index: 5286,
    start_char: 28315,
    end_char: 28319,
    display_word: "This",
    period_at_end: false
    },
    {
    word: "summer",
    start_time: 1958300,
    end_time: 1958800,
    token_index: 5287,
    start_char: 28320,
    end_char: 28326,
    display_word: "summer",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 1958800,
    end_time: 1958900,
    token_index: 5288,
    start_char: 28327,
    end_char: 28330,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1958900,
    end_time: 1959100,
    token_index: 5289,
    start_char: 28331,
    end_char: 28334,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 1959100,
    end_time: 1959400,
    token_index: 5290,
    start_char: 28335,
    end_char: 28339,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "supposed",
    start_time: 1959400,
    end_time: 1959600,
    token_index: 5291,
    start_char: 28340,
    end_char: 28348,
    display_word: "supposed",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1959600,
    end_time: 1959700,
    token_index: 5292,
    start_char: 28349,
    end_char: 28351,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 1959700,
    end_time: 1959800,
    token_index: 5293,
    start_char: 28352,
    end_char: 28354,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "June",
    start_time: 1959800,
    end_time: 1960100,
    token_index: 5294,
    start_char: 28355,
    end_char: 28359,
    display_word: "June",
    period_at_end: false
    },
    {
    word: "6.",
    start_time: 1960100,
    end_time: 1960700,
    token_index: 5295,
    start_char: 28360,
    end_char: 28361,
    display_word: "6",
    period_at_end: true
    },
    {
    word: "Both",
    start_time: 1961400,
    end_time: 1961700,
    token_index: 5296,
    start_char: 28363,
    end_char: 28367,
    display_word: "Both",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1961700,
    end_time: 1961800,
    token_index: 5297,
    start_char: 28368,
    end_char: 28370,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 1961800,
    end_time: 1962000,
    token_index: 5298,
    start_char: 28371,
    end_char: 28373,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "sons",
    start_time: 1962000,
    end_time: 1962700,
    token_index: 5299,
    start_char: 28374,
    end_char: 28378,
    display_word: "sons",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 1962700,
    end_time: 1962900,
    token_index: 5300,
    start_char: 28379,
    end_char: 28383,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "bands",
    start_time: 1962900,
    end_time: 1963600,
    token_index: 5301,
    start_char: 28384,
    end_char: 28389,
    display_word: "bands",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1963600,
    end_time: 1964100,
    token_index: 5302,
    start_char: 28390,
    end_char: 28393,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "they're",
    start_time: 1964300,
    end_time: 1964500,
    token_index: 5303,
    start_char: 28394,
    end_char: 28401,
    display_word: "they're",
    period_at_end: false
    },
    {
    word: "awesome",
    start_time: 1964500,
    end_time: 1964800,
    token_index: 5304,
    start_char: 28402,
    end_char: 28409,
    display_word: "awesome",
    period_at_end: false
    },
    {
    word: "musicians",
    start_time: 1964800,
    end_time: 1965400,
    token_index: 5305,
    start_char: 28410,
    end_char: 28419,
    display_word: "musicians",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1965400,
    end_time: 1965500,
    token_index: 5306,
    start_char: 28420,
    end_char: 28423,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 1965500,
    end_time: 1965700,
    token_index: 5307,
    start_char: 28424,
    end_char: 28428,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 1965700,
    end_time: 1965900,
    token_index: 5308,
    start_char: 28429,
    end_char: 28433,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 1965900,
    end_time: 1966000,
    token_index: 5309,
    start_char: 28434,
    end_char: 28439,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1966000,
    end_time: 1966100,
    token_index: 5310,
    start_char: 28440,
    end_char: 28442,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "open",
    start_time: 1966100,
    end_time: 1966500,
    token_index: 5311,
    start_char: 28443,
    end_char: 28447,
    display_word: "open",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1966500,
    end_time: 1966700,
    token_index: 5312,
    start_char: 28448,
    end_char: 28451,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "us.",
    start_time: 1966700,
    end_time: 1967000,
    token_index: 5313,
    start_char: 28452,
    end_char: 28454,
    display_word: "us",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 1967000,
    end_time: 1967300,
    token_index: 5314,
    start_char: 28456,
    end_char: 28458,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "yeah,",
    start_time: 1967300,
    end_time: 1967600,
    token_index: 5315,
    start_char: 28459,
    end_char: 28464,
    display_word: "yeah,",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 1967600,
    end_time: 1967900,
    token_index: 5316,
    start_char: 28465,
    end_char: 28469,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1967900,
    end_time: 1968000,
    token_index: 5317,
    start_char: 28470,
    end_char: 28473,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "rest",
    start_time: 1968000,
    end_time: 1968300,
    token_index: 5318,
    start_char: 28474,
    end_char: 28478,
    display_word: "rest",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1968300,
    end_time: 1968400,
    token_index: 5319,
    start_char: 28479,
    end_char: 28481,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1968400,
    end_time: 1968500,
    token_index: 5320,
    start_char: 28482,
    end_char: 28485,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "world",
    start_time: 1968500,
    end_time: 1969200,
    token_index: 5321,
    start_char: 28486,
    end_char: 28491,
    display_word: "world",
    period_at_end: false
    },
    {
    word: "plans",
    start_time: 1970200,
    end_time: 1970600,
    token_index: 5322,
    start_char: 28492,
    end_char: 28497,
    display_word: "plans",
    period_at_end: false
    },
    {
    word: "changed",
    start_time: 1970600,
    end_time: 1971200,
    token_index: 5323,
    start_char: 28498,
    end_char: 28505,
    display_word: "changed",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1971200,
    end_time: 1971400,
    token_index: 5324,
    start_char: 28506,
    end_char: 28509,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "things",
    start_time: 1971400,
    end_time: 1971700,
    token_index: 5325,
    start_char: 28510,
    end_char: 28516,
    display_word: "things",
    period_at_end: false
    },
    {
    word: "changed,",
    start_time: 1971700,
    end_time: 1972200,
    token_index: 5326,
    start_char: 28517,
    end_char: 28525,
    display_word: "changed,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1972200,
    end_time: 1972700,
    token_index: 5327,
    start_char: 28526,
    end_char: 28529,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 1973300,
    end_time: 1973500,
    token_index: 5328,
    start_char: 28530,
    end_char: 28533,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1973500,
    end_time: 1973600,
    token_index: 5329,
    start_char: 28534,
    end_char: 28537,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "album",
    start_time: 1973600,
    end_time: 1973900,
    token_index: 5330,
    start_char: 28538,
    end_char: 28543,
    display_word: "album",
    period_at_end: false
    },
    {
    word: "release,",
    start_time: 1973900,
    end_time: 1974200,
    token_index: 5331,
    start_char: 28544,
    end_char: 28552,
    display_word: "release,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 1974200,
    end_time: 1974300,
    token_index: 5332,
    start_char: 28553,
    end_char: 28554,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "actually",
    start_time: 1974300,
    end_time: 1974700,
    token_index: 5333,
    start_char: 28555,
    end_char: 28563,
    display_word: "actually",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1974700,
    end_time: 1975300,
    token_index: 5334,
    start_char: 28564,
    end_char: 28569,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1975600,
    end_time: 1975800,
    token_index: 5335,
    start_char: 28570,
    end_char: 28574,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 1975800,
    end_time: 1976000,
    token_index: 5336,
    start_char: 28575,
    end_char: 28579,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "good.",
    start_time: 1976000,
    end_time: 1976000,
    token_index: 5337,
    start_char: 28580,
    end_char: 28584,
    display_word: "good",
    period_at_end: true
    },
    {
    word: "Good.",
    start_time: 1976100,
    end_time: 1976300,
    token_index: 5338,
    start_char: 28586,
    end_char: 28590,
    display_word: "Good",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 1976300,
    end_time: 1976300,
    token_index: 5339,
    start_char: 28592,
    end_char: 28593,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "think",
    start_time: 1976300,
    end_time: 1976600,
    token_index: 5340,
    start_char: 28594,
    end_char: 28599,
    display_word: "think",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1976600,
    end_time: 1976700,
    token_index: 5341,
    start_char: 28600,
    end_char: 28602,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "needed",
    start_time: 1976700,
    end_time: 1977300,
    token_index: 5342,
    start_char: 28603,
    end_char: 28609,
    display_word: "needed",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1977300,
    end_time: 1977400,
    token_index: 5343,
    start_char: 28610,
    end_char: 28611,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "few",
    start_time: 1977400,
    end_time: 1977600,
    token_index: 5344,
    start_char: 28612,
    end_char: 28615,
    display_word: "few",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 1977600,
    end_time: 1977800,
    token_index: 5345,
    start_char: 28616,
    end_char: 28620,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "months",
    start_time: 1977800,
    end_time: 1978200,
    token_index: 5346,
    start_char: 28621,
    end_char: 28627,
    display_word: "months",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1978200,
    end_time: 1978500,
    token_index: 5347,
    start_char: 28628,
    end_char: 28630,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1978500,
    end_time: 1978800,
    token_index: 5348,
    start_char: 28631,
    end_char: 28637,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 1978800,
    end_time: 1979100,
    token_index: 5349,
    start_char: 28638,
    end_char: 28641,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "everything",
    start_time: 1979100,
    end_time: 1979800,
    token_index: 5350,
    start_char: 28642,
    end_char: 28652,
    display_word: "everything",
    period_at_end: false
    },
    {
    word: "together",
    start_time: 1981000,
    end_time: 1981600,
    token_index: 5351,
    start_char: 28653,
    end_char: 28661,
    display_word: "together",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1981600,
    end_time: 1981900,
    token_index: 5352,
    start_char: 28662,
    end_char: 28665,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "release",
    start_time: 1981900,
    end_time: 1982200,
    token_index: 5353,
    start_char: 28666,
    end_char: 28673,
    display_word: "release",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1982200,
    end_time: 1982400,
    token_index: 5354,
    start_char: 28674,
    end_char: 28676,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1982400,
    end_time: 1982500,
    token_index: 5355,
    start_char: 28677,
    end_char: 28680,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 1982500,
    end_time: 1982600,
    token_index: 5356,
    start_char: 28681,
    end_char: 28683,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "want",
    start_time: 1982600,
    end_time: 1982900,
    token_index: 5357,
    start_char: 28684,
    end_char: 28688,
    display_word: "want",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1982900,
    end_time: 1983100,
    token_index: 5358,
    start_char: 28689,
    end_char: 28691,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 1983100,
    end_time: 1983400,
    token_index: 5359,
    start_char: 28692,
    end_char: 28695,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "yeah,",
    start_time: 1983400,
    end_time: 1983600,
    token_index: 5360,
    start_char: 28696,
    end_char: 28701,
    display_word: "yeah,",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 1983600,
    end_time: 1983800,
    token_index: 5361,
    start_char: 28702,
    end_char: 28706,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "interesting",
    start_time: 1983800,
    end_time: 1984400,
    token_index: 5362,
    start_char: 28707,
    end_char: 28718,
    display_word: "interesting",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 1984400,
    end_time: 1984500,
    token_index: 5363,
    start_char: 28719,
    end_char: 28723,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "see",
    start_time: 1984500,
    end_time: 1985000,
    token_index: 5364,
    start_char: 28724,
    end_char: 28727,
    display_word: "see",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 1985900,
    end_time: 1986000,
    token_index: 5365,
    start_char: 28728,
    end_char: 28731,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "know,",
    start_time: 1986000,
    end_time: 1986200,
    token_index: 5366,
    start_char: 28732,
    end_char: 28737,
    display_word: "know,",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 1986200,
    end_time: 1986300,
    token_index: 5367,
    start_char: 28738,
    end_char: 28741,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1986300,
    end_time: 1986500,
    token_index: 5368,
    start_char: 28742,
    end_char: 28744,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "goes.",
    start_time: 1986500,
    end_time: 1987000,
    token_index: 5369,
    start_char: 28745,
    end_char: 28749,
    display_word: "goes",
    period_at_end: true
    },
    {
    word: "Well",
    start_time: 1987800,
    end_time: 1988000,
    token_index: 5370,
    start_char: 28751,
    end_char: 28755,
    display_word: "Well",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1988000,
    end_time: 1988100,
    token_index: 5371,
    start_char: 28756,
    end_char: 28759,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "single",
    start_time: 1988100,
    end_time: 1988500,
    token_index: 5372,
    start_char: 28760,
    end_char: 28766,
    display_word: "single",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 1988500,
    end_time: 1988700,
    token_index: 5373,
    start_char: 28767,
    end_char: 28771,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 1988700,
    end_time: 1988900,
    token_index: 5374,
    start_char: 28772,
    end_char: 28776,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 1988900,
    end_time: 1989000,
    token_index: 5375,
    start_char: 28777,
    end_char: 28779,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1989000,
    end_time: 1989100,
    token_index: 5376,
    start_char: 28780,
    end_char: 28783,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "audience",
    start_time: 1989100,
    end_time: 1989400,
    token_index: 5377,
    start_char: 28784,
    end_char: 28792,
    display_word: "audience",
    period_at_end: false
    },
    {
    word: "watching",
    start_time: 1989400,
    end_time: 1989700,
    token_index: 5378,
    start_char: 28793,
    end_char: 28801,
    display_word: "watching",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1989700,
    end_time: 1989800,
    token_index: 5379,
    start_char: 28802,
    end_char: 28807,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 1989800,
    end_time: 1990000,
    token_index: 5380,
    start_char: 28808,
    end_char: 28811,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "may",
    start_time: 1990000,
    end_time: 1990100,
    token_index: 5381,
    start_char: 28812,
    end_char: 28815,
    display_word: "may",
    period_at_end: false
    },
    {
    word: "already",
    start_time: 1990100,
    end_time: 1990500,
    token_index: 5382,
    start_char: 28816,
    end_char: 28823,
    display_word: "already",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 1990500,
    end_time: 1991100,
    token_index: 5383,
    start_char: 28824,
    end_char: 28828,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1991100,
    end_time: 1991600,
    token_index: 5384,
    start_char: 28829,
    end_char: 28831,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 1991600,
    end_time: 1992000,
    token_index: 5385,
    start_char: 28832,
    end_char: 28837,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March,",
    start_time: 1992000,
    end_time: 1992800,
    token_index: 5386,
    start_char: 28838,
    end_char: 28844,
    display_word: "March,",
    period_at_end: false
    },
    {
    word: "which",
    start_time: 1992800,
    end_time: 1993000,
    token_index: 5387,
    start_char: 28845,
    end_char: 28850,
    display_word: "which",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 1993000,
    end_time: 1993100,
    token_index: 5388,
    start_char: 28851,
    end_char: 28853,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 1993100,
    end_time: 1993500,
    token_index: 5389,
    start_char: 28854,
    end_char: 28855,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "really",
    start_time: 1993500,
    end_time: 1994000,
    token_index: 5390,
    start_char: 28856,
    end_char: 28862,
    display_word: "really",
    period_at_end: false
    },
    {
    word: "powerful",
    start_time: 1994000,
    end_time: 1995100,
    token_index: 5391,
    start_char: 28863,
    end_char: 28871,
    display_word: "powerful",
    period_at_end: false
    },
    {
    word: "protest",
    start_time: 1995100,
    end_time: 1995700,
    token_index: 5392,
    start_char: 28872,
    end_char: 28879,
    display_word: "protest",
    period_at_end: false
    },
    {
    word: "Anthem",
    start_time: 1995700,
    end_time: 1996300,
    token_index: 5393,
    start_char: 28880,
    end_char: 28886,
    display_word: "Anthem",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 1996700,
    end_time: 1997100,
    token_index: 5394,
    start_char: 28887,
    end_char: 28890,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 1997100,
    end_time: 1997200,
    token_index: 5395,
    start_char: 28891,
    end_char: 28893,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "seems",
    start_time: 1997200,
    end_time: 1997600,
    token_index: 5396,
    start_char: 28894,
    end_char: 28899,
    display_word: "seems",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 1997600,
    end_time: 1998200,
    token_index: 5397,
    start_char: 28900,
    end_char: 28902,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "fitting",
    start_time: 1998300,
    end_time: 1998900,
    token_index: 5398,
    start_char: 28903,
    end_char: 28910,
    display_word: "fitting",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 1998900,
    end_time: 1999000,
    token_index: 5399,
    start_char: 28911,
    end_char: 28913,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 1999000,
    end_time: 1999100,
    token_index: 5400,
    start_char: 28914,
    end_char: 28917,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "mower",
    start_time: 1999100,
    end_time: 1999600,
    token_index: 5401,
    start_char: 28918,
    end_char: 28923,
    display_word: "mower",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 1999600,
    end_time: 1999700,
    token_index: 5402,
    start_char: 28924,
    end_char: 28926,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 1999700,
    end_time: 2000000,
    token_index: 5403,
    start_char: 28927,
    end_char: 28932,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 2000000,
    end_time: 2000200,
    token_index: 5404,
    start_char: 28933,
    end_char: 28936,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "When",
    start_time: 2000200,
    end_time: 2000400,
    token_index: 5405,
    start_char: 28938,
    end_char: 28942,
    display_word: "When",
    period_at_end: false
    },
    {
    word: "did",
    start_time: 2000400,
    end_time: 2000500,
    token_index: 5406,
    start_char: 28943,
    end_char: 28946,
    display_word: "did",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 2000500,
    end_time: 2000600,
    token_index: 5407,
    start_char: 28947,
    end_char: 28950,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "actually",
    start_time: 2000600,
    end_time: 2000800,
    token_index: 5408,
    start_char: 28951,
    end_char: 28959,
    display_word: "actually",
    period_at_end: false
    },
    {
    word: "write",
    start_time: 2000800,
    end_time: 2001100,
    token_index: 5409,
    start_char: 28960,
    end_char: 28965,
    display_word: "write",
    period_at_end: false
    },
    {
    word: "that?",
    start_time: 2001100,
    end_time: 2001500,
    token_index: 5410,
    start_char: 28966,
    end_char: 28971,
    display_word: "that?",
    period_at_end: false
    },
    {
    word: "Years",
    start_time: 2003300,
    end_time: 2003500,
    token_index: 5411,
    start_char: 28972,
    end_char: 28977,
    display_word: "Years",
    period_at_end: false
    },
    {
    word: "ago",
    start_time: 2003500,
    end_time: 2004000,
    token_index: 5412,
    start_char: 28978,
    end_char: 28981,
    display_word: "ago",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 2004100,
    end_time: 2004300,
    token_index: 5413,
    start_char: 28982,
    end_char: 28985,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 2004300,
    end_time: 2004400,
    token_index: 5414,
    start_char: 28986,
    end_char: 28989,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2004400,
    end_time: 2004500,
    token_index: 5415,
    start_char: 28990,
    end_char: 28992,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2004500,
    end_time: 2004700,
    token_index: 5416,
    start_char: 28993,
    end_char: 28996,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "first",
    start_time: 2004700,
    end_time: 2004900,
    token_index: 5417,
    start_char: 28997,
    end_char: 29002,
    display_word: "first",
    period_at_end: false
    },
    {
    word: "ones",
    start_time: 2004900,
    end_time: 2005100,
    token_index: 5418,
    start_char: 29003,
    end_char: 29007,
    display_word: "ones",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 2005100,
    end_time: 2005200,
    token_index: 5419,
    start_char: 29008,
    end_char: 29010,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "started",
    start_time: 2005200,
    end_time: 2005700,
    token_index: 5420,
    start_char: 29011,
    end_char: 29018,
    display_word: "started",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2005700,
    end_time: 2005800,
    token_index: 5421,
    start_char: 29019,
    end_char: 29021,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "took",
    start_time: 2005800,
    end_time: 2006000,
    token_index: 5422,
    start_char: 29022,
    end_char: 29026,
    display_word: "took",
    period_at_end: false
    },
    {
    word: "us",
    start_time: 2006000,
    end_time: 2006100,
    token_index: 5423,
    start_char: 29027,
    end_char: 29029,
    display_word: "us",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2006100,
    end_time: 2006100,
    token_index: 5424,
    start_char: 29030,
    end_char: 29031,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "while",
    start_time: 2006100,
    end_time: 2006300,
    token_index: 5425,
    start_char: 29032,
    end_char: 29037,
    display_word: "while",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2006300,
    end_time: 2006400,
    token_index: 5426,
    start_char: 29038,
    end_char: 29040,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 2006400,
    end_time: 2006500,
    token_index: 5427,
    start_char: 29041,
    end_char: 29044,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2006500,
    end_time: 2006600,
    token_index: 5428,
    start_char: 29045,
    end_char: 29048,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "production",
    start_time: 2006600,
    end_time: 2007100,
    token_index: 5429,
    start_char: 29049,
    end_char: 29059,
    display_word: "production",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 2007100,
    end_time: 2007300,
    token_index: 5430,
    start_char: 29060,
    end_char: 29062,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2007300,
    end_time: 2007500,
    token_index: 5431,
    start_char: 29063,
    end_char: 29065,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2007500,
    end_time: 2007700,
    token_index: 5432,
    start_char: 29066,
    end_char: 29069,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "get",
    start_time: 2007700,
    end_time: 2007800,
    token_index: 5433,
    start_char: 29070,
    end_char: 29073,
    display_word: "get",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2007800,
    end_time: 2008200,
    token_index: 5434,
    start_char: 29074,
    end_char: 29076,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2008200,
    end_time: 2008300,
    token_index: 5435,
    start_char: 29077,
    end_char: 29080,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "way",
    start_time: 2008300,
    end_time: 2008500,
    token_index: 5436,
    start_char: 29081,
    end_char: 29084,
    display_word: "way",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2008500,
    end_time: 2008600,
    token_index: 5437,
    start_char: 29085,
    end_char: 29087,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 2008600,
    end_time: 2008700,
    token_index: 5438,
    start_char: 29088,
    end_char: 29090,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 2008700,
    end_time: 2008800,
    token_index: 5439,
    start_char: 29091,
    end_char: 29093,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2008800,
    end_time: 2008900,
    token_index: 5440,
    start_char: 29094,
    end_char: 29097,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "album,",
    start_time: 2008900,
    end_time: 2009400,
    token_index: 5441,
    start_char: 29098,
    end_char: 29104,
    display_word: "album,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 2009400,
    end_time: 2009600,
    token_index: 5442,
    start_char: 29105,
    end_char: 29108,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2009600,
    end_time: 2009700,
    token_index: 5443,
    start_char: 29109,
    end_char: 29112,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "lyrics",
    start_time: 2009700,
    end_time: 2010100,
    token_index: 5444,
    start_char: 29113,
    end_char: 29119,
    display_word: "lyrics",
    period_at_end: false
    },
    {
    word: "were",
    start_time: 2010100,
    end_time: 2010400,
    token_index: 5445,
    start_char: 29120,
    end_char: 29124,
    display_word: "were",
    period_at_end: false
    },
    {
    word: "basically",
    start_time: 2010400,
    end_time: 2011100,
    token_index: 5446,
    start_char: 29125,
    end_char: 29134,
    display_word: "basically",
    period_at_end: false
    },
    {
    word: "written",
    start_time: 2011100,
    end_time: 2011600,
    token_index: 5447,
    start_char: 29135,
    end_char: 29142,
    display_word: "written",
    period_at_end: false
    },
    {
    word: "two",
    start_time: 2011800,
    end_time: 2011900,
    token_index: 5448,
    start_char: 29143,
    end_char: 29146,
    display_word: "two",
    period_at_end: false
    },
    {
    word: "years",
    start_time: 2011900,
    end_time: 2012200,
    token_index: 5449,
    start_char: 29147,
    end_char: 29152,
    display_word: "years",
    period_at_end: false
    },
    {
    word: "ago",
    start_time: 2012200,
    end_time: 2012600,
    token_index: 5450,
    start_char: 29153,
    end_char: 29156,
    display_word: "ago",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2013600,
    end_time: 2013700,
    token_index: 5451,
    start_char: 29157,
    end_char: 29160,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 2013700,
    end_time: 2013800,
    token_index: 5452,
    start_char: 29161,
    end_char: 29163,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 2013800,
    end_time: 2013900,
    token_index: 5453,
    start_char: 29164,
    end_char: 29167,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 2013900,
    end_time: 2014100,
    token_index: 5454,
    start_char: 29168,
    end_char: 29172,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "extraordinary",
    start_time: 2014100,
    end_time: 2014800,
    token_index: 5455,
    start_char: 29173,
    end_char: 29186,
    display_word: "extraordinary",
    period_at_end: false
    },
    {
    word: "extraordinary",
    start_time: 2014800,
    end_time: 2015700,
    token_index: 5456,
    start_char: 29187,
    end_char: 29200,
    display_word: "extraordinary",
    period_at_end: false
    },
    {
    word: "because",
    start_time: 2015700,
    end_time: 2016500,
    token_index: 5457,
    start_char: 29201,
    end_char: 29208,
    display_word: "because",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 2017300,
    end_time: 2017500,
    token_index: 5458,
    start_char: 29209,
    end_char: 29211,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "some",
    start_time: 2017500,
    end_time: 2017700,
    token_index: 5459,
    start_char: 29212,
    end_char: 29216,
    display_word: "some",
    period_at_end: false
    },
    {
    word: "ways",
    start_time: 2017700,
    end_time: 2018100,
    token_index: 5460,
    start_char: 29217,
    end_char: 29221,
    display_word: "ways",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 2018100,
    end_time: 2018300,
    token_index: 5461,
    start_char: 29222,
    end_char: 29226,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 2018300,
    end_time: 2018500,
    token_index: 5462,
    start_char: 29227,
    end_char: 29230,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 2018500,
    end_time: 2018800,
    token_index: 5463,
    start_char: 29231,
    end_char: 29235,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 2018800,
    end_time: 2019100,
    token_index: 5464,
    start_char: 29236,
    end_char: 29240,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 2019100,
    end_time: 2019400,
    token_index: 5465,
    start_char: 29241,
    end_char: 29245,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "song",
    start_time: 2019400,
    end_time: 2019900,
    token_index: 5466,
    start_char: 29246,
    end_char: 29250,
    display_word: "song",
    period_at_end: false
    },
    {
    word: "seems",
    start_time: 2019900,
    end_time: 2020100,
    token_index: 5467,
    start_char: 29251,
    end_char: 29256,
    display_word: "seems",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 2020100,
    end_time: 2020200,
    token_index: 5468,
    start_char: 29257,
    end_char: 29261,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2020200,
    end_time: 2020300,
    token_index: 5469,
    start_char: 29262,
    end_char: 29264,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 2020300,
    end_time: 2020400,
    token_index: 5470,
    start_char: 29265,
    end_char: 29268,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "written",
    start_time: 2020400,
    end_time: 2020600,
    token_index: 5471,
    start_char: 29269,
    end_char: 29276,
    display_word: "written",
    period_at_end: false
    },
    {
    word: "yesterday.",
    start_time: 2020600,
    end_time: 2021300,
    token_index: 5472,
    start_char: 29277,
    end_char: 29286,
    display_word: "yesterday",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 2022000,
    end_time: 2022100,
    token_index: 5473,
    start_char: 29288,
    end_char: 29289,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "didn't",
    start_time: 2022100,
    end_time: 2022400,
    token_index: 5474,
    start_char: 29290,
    end_char: 29296,
    display_word: "didn't",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 2022400,
    end_time: 2022500,
    token_index: 5475,
    start_char: 29297,
    end_char: 29301,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 2022500,
    end_time: 2022700,
    token_index: 5476,
    start_char: 29302,
    end_char: 29306,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "crazy",
    start_time: 2022700,
    end_time: 2023200,
    token_index: 5477,
    start_char: 29307,
    end_char: 29312,
    display_word: "crazy",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2023200,
    end_time: 2023500,
    token_index: 5478,
    start_char: 29313,
    end_char: 29316,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 2023500,
    end_time: 2024000,
    token_index: 5479,
    start_char: 29317,
    end_char: 29319,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "want",
    start_time: 2024200,
    end_time: 2024300,
    token_index: 5480,
    start_char: 29320,
    end_char: 29324,
    display_word: "want",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2024300,
    end_time: 2024400,
    token_index: 5481,
    start_char: 29325,
    end_char: 29327,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "put",
    start_time: 2024400,
    end_time: 2024500,
    token_index: 5482,
    start_char: 29328,
    end_char: 29331,
    display_word: "put",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2024500,
    end_time: 2024700,
    token_index: 5483,
    start_char: 29332,
    end_char: 29334,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "out.",
    start_time: 2024700,
    end_time: 2024900,
    token_index: 5484,
    start_char: 29335,
    end_char: 29338,
    display_word: "out",
    period_at_end: true
    },
    {
    word: "up",
    start_time: 2027300,
    end_time: 2027400,
    token_index: 5485,
    start_char: 29340,
    end_char: 29342,
    display_word: "up",
    period_at_end: false
    },
    {
    word: "itself",
    start_time: 2027400,
    end_time: 2028000,
    token_index: 5486,
    start_char: 29343,
    end_char: 29349,
    display_word: "itself",
    period_at_end: false
    },
    {
    word: "The",
    start_time: 2028900,
    end_time: 2029000,
    token_index: 5487,
    start_char: 29350,
    end_char: 29353,
    display_word: "The",
    period_at_end: false
    },
    {
    word: "time's",
    start_time: 2029000,
    end_time: 2029500,
    token_index: 5488,
    start_char: 29354,
    end_char: 29360,
    display_word: "time's",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 2029500,
    end_time: 2029700,
    token_index: 5489,
    start_char: 29361,
    end_char: 29366,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 2029700,
    end_time: 2030000,
    token_index: 5490,
    start_char: 29367,
    end_char: 29370,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 2030000,
    end_time: 2030200,
    token_index: 5491,
    start_char: 29371,
    end_char: 29373,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2030200,
    end_time: 2030500,
    token_index: 5492,
    start_char: 29374,
    end_char: 29377,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "black",
    start_time: 2030500,
    end_time: 2030800,
    token_index: 5493,
    start_char: 29378,
    end_char: 29383,
    display_word: "black",
    period_at_end: false
    },
    {
    word: "lives",
    start_time: 2030800,
    end_time: 2031000,
    token_index: 5494,
    start_char: 29384,
    end_char: 29389,
    display_word: "lives",
    period_at_end: false
    },
    {
    word: "movement,",
    start_time: 2031000,
    end_time: 2031700,
    token_index: 5495,
    start_char: 29390,
    end_char: 29399,
    display_word: "movement,",
    period_at_end: false
    },
    {
    word: "but",
    start_time: 2032600,
    end_time: 2032800,
    token_index: 5496,
    start_char: 29400,
    end_char: 29403,
    display_word: "but",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 2032800,
    end_time: 2032900,
    token_index: 5497,
    start_char: 29404,
    end_char: 29406,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "had",
    start_time: 2032900,
    end_time: 2033000,
    token_index: 5498,
    start_char: 29407,
    end_char: 29410,
    display_word: "had",
    period_at_end: false
    },
    {
    word: "gone",
    start_time: 2033000,
    end_time: 2033300,
    token_index: 5499,
    start_char: 29411,
    end_char: 29415,
    display_word: "gone",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2033300,
    end_time: 2034100,
    token_index: 5500,
    start_char: 29416,
    end_char: 29418,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2034600,
    end_time: 2034900,
    token_index: 5501,
    start_char: 29419,
    end_char: 29424,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 2034900,
    end_time: 2035000,
    token_index: 5502,
    start_char: 29425,
    end_char: 29428,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 2035000,
    end_time: 2035200,
    token_index: 5503,
    start_char: 29429,
    end_char: 29432,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "lives",
    start_time: 2035200,
    end_time: 2035600,
    token_index: 5504,
    start_char: 29433,
    end_char: 29438,
    display_word: "lives",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 2035600,
    end_time: 2035600,
    token_index: 5505,
    start_char: 29439,
    end_char: 29441,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "Washington",
    start_time: 2035600,
    end_time: 2036000,
    token_index: 5506,
    start_char: 29442,
    end_char: 29452,
    display_word: "Washington",
    period_at_end: false
    },
    {
    word: "DC",
    start_time: 2036000,
    end_time: 2036500,
    token_index: 5507,
    start_char: 29453,
    end_char: 29455,
    display_word: "DC",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 2036500,
    end_time: 2036700,
    token_index: 5508,
    start_char: 29456,
    end_char: 29460,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "seeing",
    start_time: 2039500,
    end_time: 2039700,
    token_index: 5509,
    start_char: 29461,
    end_char: 29467,
    display_word: "seeing",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 2039700,
    end_time: 2040200,
    token_index: 5510,
    start_char: 29468,
    end_char: 29472,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 2040400,
    end_time: 2040600,
    token_index: 5511,
    start_char: 29473,
    end_char: 29477,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "entire",
    start_time: 2040600,
    end_time: 2041200,
    token_index: 5512,
    start_char: 29478,
    end_char: 29484,
    display_word: "entire",
    period_at_end: false
    },
    {
    word: "movement",
    start_time: 2041200,
    end_time: 2041700,
    token_index: 5513,
    start_char: 29485,
    end_char: 29493,
    display_word: "movement",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2041700,
    end_time: 2041900,
    token_index: 5514,
    start_char: 29494,
    end_char: 29497,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2041900,
    end_time: 2042100,
    token_index: 5515,
    start_char: 29498,
    end_char: 29500,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 2042100,
    end_time: 2042400,
    token_index: 5516,
    start_char: 29501,
    end_char: 29504,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "like,",
    start_time: 2042400,
    end_time: 2042800,
    token_index: 5517,
    start_char: 29505,
    end_char: 29510,
    display_word: "like,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 2043500,
    end_time: 2043500,
    token_index: 5518,
    start_char: 29511,
    end_char: 29512,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "don't",
    start_time: 2043500,
    end_time: 2043600,
    token_index: 5519,
    start_char: 29513,
    end_char: 29518,
    display_word: "don't",
    period_at_end: false
    },
    {
    word: "know",
    start_time: 2043600,
    end_time: 2043700,
    token_index: 5520,
    start_char: 29519,
    end_char: 29523,
    display_word: "know",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2043700,
    end_time: 2043800,
    token_index: 5521,
    start_char: 29524,
    end_char: 29527,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "most",
    start_time: 2043800,
    end_time: 2044000,
    token_index: 5522,
    start_char: 29528,
    end_char: 29532,
    display_word: "most",
    period_at_end: false
    },
    {
    word: "powerful",
    start_time: 2044000,
    end_time: 2044300,
    token_index: 5523,
    start_char: 29533,
    end_char: 29541,
    display_word: "powerful",
    period_at_end: false
    },
    {
    word: "thing",
    start_time: 2044300,
    end_time: 2044500,
    token_index: 5524,
    start_char: 29542,
    end_char: 29547,
    display_word: "thing",
    period_at_end: false
    },
    {
    word: "I've",
    start_time: 2044500,
    end_time: 2044700,
    token_index: 5525,
    start_char: 29548,
    end_char: 29552,
    display_word: "I've",
    period_at_end: false
    },
    {
    word: "ever",
    start_time: 2044700,
    end_time: 2044900,
    token_index: 5526,
    start_char: 29553,
    end_char: 29557,
    display_word: "ever",
    period_at_end: false
    },
    {
    word: "been",
    start_time: 2044900,
    end_time: 2045000,
    token_index: 5527,
    start_char: 29558,
    end_char: 29562,
    display_word: "been",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2045000,
    end_time: 2045000,
    token_index: 5528,
    start_char: 29563,
    end_char: 29564,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "part",
    start_time: 2045000,
    end_time: 2045300,
    token_index: 5529,
    start_char: 29565,
    end_char: 29569,
    display_word: "part",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2045300,
    end_time: 2045600,
    token_index: 5530,
    start_char: 29570,
    end_char: 29572,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2045700,
    end_time: 2046300,
    token_index: 5531,
    start_char: 29573,
    end_char: 29576,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "we",
    start_time: 2046300,
    end_time: 2046400,
    token_index: 5532,
    start_char: 29577,
    end_char: 29579,
    display_word: "we",
    period_at_end: false
    },
    {
    word: "wanted",
    start_time: 2046400,
    end_time: 2046700,
    token_index: 5533,
    start_char: 29580,
    end_char: 29586,
    display_word: "wanted",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2046700,
    end_time: 2046700,
    token_index: 5534,
    start_char: 29587,
    end_char: 29589,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "write",
    start_time: 2046700,
    end_time: 2046900,
    token_index: 5535,
    start_char: 29590,
    end_char: 29595,
    display_word: "write",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 2046900,
    end_time: 2047400,
    token_index: 5536,
    start_char: 29596,
    end_char: 29601,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 2047400,
    end_time: 2047900,
    token_index: 5537,
    start_char: 29602,
    end_char: 29606,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2049000,
    end_time: 2049199,
    token_index: 5538,
    start_char: 29607,
    end_char: 29610,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 2049199,
    end_time: 2049300,
    token_index: 5539,
    start_char: 29611,
    end_char: 29615,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 2049300,
    end_time: 2049400,
    token_index: 5540,
    start_char: 29616,
    end_char: 29619,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2049400,
    end_time: 2049400,
    token_index: 5541,
    start_char: 29620,
    end_char: 29623,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "impetus",
    start_time: 2049400,
    end_time: 2049900,
    token_index: 5542,
    start_char: 29624,
    end_char: 29631,
    display_word: "impetus",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 2049900,
    end_time: 2050000,
    token_index: 5543,
    start_char: 29632,
    end_char: 29635,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2050000,
    end_time: 2050100,
    token_index: 5544,
    start_char: 29636,
    end_char: 29639,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "song.",
    start_time: 2050100,
    end_time: 2050400,
    token_index: 5545,
    start_char: 29640,
    end_char: 29644,
    display_word: "song",
    period_at_end: true
    },
    {
    word: "But",
    start_time: 2050400,
    end_time: 2050600,
    token_index: 5546,
    start_char: 29646,
    end_char: 29649,
    display_word: "But",
    period_at_end: false
    },
    {
    word: "yeah,",
    start_time: 2050600,
    end_time: 2050800,
    token_index: 5547,
    start_char: 29650,
    end_char: 29655,
    display_word: "yeah,",
    period_at_end: false
    },
    {
    word: "it's",
    start_time: 2050800,
    end_time: 2051199,
    token_index: 5548,
    start_char: 29656,
    end_char: 29660,
    display_word: "it's",
    period_at_end: false
    },
    {
    word: "Eerie",
    start_time: 2051199,
    end_time: 2051600,
    token_index: 5549,
    start_char: 29661,
    end_char: 29666,
    display_word: "Eerie",
    period_at_end: false
    },
    {
    word: "how",
    start_time: 2051600,
    end_time: 2052000,
    token_index: 5550,
    start_char: 29667,
    end_char: 29670,
    display_word: "how",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 2052000,
    end_time: 2052300,
    token_index: 5551,
    start_char: 29671,
    end_char: 29675,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2052300,
    end_time: 2052600,
    token_index: 5552,
    start_char: 29676,
    end_char: 29678,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "pertains?",
    start_time: 2052900,
    end_time: 2053699,
    token_index: 5553,
    start_char: 29679,
    end_char: 29688,
    display_word: "pertains?",
    period_at_end: false
    },
    {
    word: "Right",
    start_time: 2054500,
    end_time: 2054699,
    token_index: 5554,
    start_char: 29689,
    end_char: 29694,
    display_word: "Right",
    period_at_end: false
    },
    {
    word: "here",
    start_time: 2054699,
    end_time: 2054900,
    token_index: 5555,
    start_char: 29695,
    end_char: 29699,
    display_word: "here",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 2054900,
    end_time: 2055100,
    token_index: 5556,
    start_char: 29700,
    end_char: 29705,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "now.",
    start_time: 2055100,
    end_time: 2055500,
    token_index: 5557,
    start_char: 29706,
    end_char: 29709,
    display_word: "now",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 2056000,
    end_time: 2056199,
    token_index: 5558,
    start_char: 29711,
    end_char: 29714,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "guys",
    start_time: 2056199,
    end_time: 2056500,
    token_index: 5559,
    start_char: 29715,
    end_char: 29719,
    display_word: "guys",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 2056500,
    end_time: 2056600,
    token_index: 5560,
    start_char: 29720,
    end_char: 29723,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "performing",
    start_time: 2056600,
    end_time: 2057199,
    token_index: 5561,
    start_char: 29724,
    end_char: 29734,
    display_word: "performing",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2057199,
    end_time: 2057600,
    token_index: 5562,
    start_char: 29735,
    end_char: 29740,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2057600,
    end_time: 2058100,
    token_index: 5563,
    start_char: 29741,
    end_char: 29746,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 2058199,
    end_time: 2058400,
    token_index: 5564,
    start_char: 29747,
    end_char: 29749,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "just",
    start_time: 2058400,
    end_time: 2058699,
    token_index: 5565,
    start_char: 29750,
    end_char: 29754,
    display_word: "just",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2058699,
    end_time: 2058699,
    token_index: 5566,
    start_char: 29755,
    end_char: 29756,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "moment",
    start_time: 2058699,
    end_time: 2059400,
    token_index: 5567,
    start_char: 29757,
    end_char: 29763,
    display_word: "moment",
    period_at_end: false
    },
    {
    word: "stick",
    start_time: 2059699,
    end_time: 2060000,
    token_index: 5568,
    start_char: 29764,
    end_char: 29769,
    display_word: "stick",
    period_at_end: false
    },
    {
    word: "around",
    start_time: 2060000,
    end_time: 2060400,
    token_index: 5569,
    start_char: 29770,
    end_char: 29776,
    display_word: "around",
    period_at_end: false
    },
    {
    word: "everybody.",
    start_time: 2060400,
    end_time: 2061199,
    token_index: 5570,
    start_char: 29777,
    end_char: 29786,
    display_word: "everybody",
    period_at_end: true
    },
    {
    word: "We",
    start_time: 2061300,
    end_time: 2061400,
    token_index: 5571,
    start_char: 29788,
    end_char: 29790,
    display_word: "We",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 2061400,
    end_time: 2061600,
    token_index: 5572,
    start_char: 29791,
    end_char: 29795,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 2061600,
    end_time: 2061900,
    token_index: 5573,
    start_char: 29796,
    end_char: 29800,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "more",
    start_time: 2061900,
    end_time: 2062600,
    token_index: 5574,
    start_char: 29801,
    end_char: 29805,
    display_word: "more",
    period_at_end: false
    },
    {
    word: "chicks.",
    start_time: 2062900,
    end_time: 2063800,
    token_index: 5575,
    start_char: 29806,
    end_char: 29812,
    display_word: "chicks",
    period_at_end: true
    },
    {
    word: "Thank",
    start_time: 2064800,
    end_time: 2065100,
    token_index: 5576,
    start_char: 29814,
    end_char: 29819,
    display_word: "Thank",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 2065100,
    end_time: 2065199,
    token_index: 5577,
    start_char: 29820,
    end_char: 29823,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "all.",
    start_time: 2065199,
    end_time: 2065400,
    token_index: 5578,
    start_char: 29824,
    end_char: 29827,
    display_word: "all",
    period_at_end: true
    },
    {
    word: "First",
    start_time: 2089100,
    end_time: 2089300,
    token_index: 5579,
    start_char: 29829,
    end_char: 29834,
    display_word: "First",
    period_at_end: false
    },
    {
    word: "daughter",
    start_time: 2089300,
    end_time: 2089699,
    token_index: 5580,
    start_char: 29835,
    end_char: 29843,
    display_word: "daughter",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2089699,
    end_time: 2089800,
    token_index: 5581,
    start_char: 29844,
    end_char: 29847,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "advisor",
    start_time: 2089800,
    end_time: 2090400,
    token_index: 5582,
    start_char: 29848,
    end_char: 29855,
    display_word: "advisor",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2090400,
    end_time: 2090600,
    token_index: 5583,
    start_char: 29856,
    end_char: 29858,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2090600,
    end_time: 2090699,
    token_index: 5584,
    start_char: 29859,
    end_char: 29862,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "president",
    start_time: 2090699,
    end_time: 2091199,
    token_index: 5585,
    start_char: 29863,
    end_char: 29872,
    display_word: "president",
    period_at_end: false
    },
    {
    word: "Ivanka",
    start_time: 2091199,
    end_time: 2091600,
    token_index: 5586,
    start_char: 29873,
    end_char: 29879,
    display_word: "Ivanka",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 2091600,
    end_time: 2091900,
    token_index: 5587,
    start_char: 29880,
    end_char: 29885,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "today",
    start_time: 2091900,
    end_time: 2092100,
    token_index: 5588,
    start_char: 29886,
    end_char: 29891,
    display_word: "today",
    period_at_end: false
    },
    {
    word: "finding",
    start_time: 2092100,
    end_time: 2092400,
    token_index: 5589,
    start_char: 29892,
    end_char: 29899,
    display_word: "finding",
    period_at_end: false
    },
    {
    word: "herself",
    start_time: 2092400,
    end_time: 2092800,
    token_index: 5590,
    start_char: 29900,
    end_char: 29907,
    display_word: "herself",
    period_at_end: false
    },
    {
    word: "accused",
    start_time: 2092800,
    end_time: 2093199,
    token_index: 5591,
    start_char: 29908,
    end_char: 29915,
    display_word: "accused",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2093199,
    end_time: 2093300,
    token_index: 5592,
    start_char: 29916,
    end_char: 29918,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "violating",
    start_time: 2093300,
    end_time: 2093900,
    token_index: 5593,
    start_char: 29919,
    end_char: 29928,
    display_word: "violating",
    period_at_end: false
    },
    {
    word: "Federal",
    start_time: 2093900,
    end_time: 2094500,
    token_index: 5594,
    start_char: 29929,
    end_char: 29936,
    display_word: "Federal",
    period_at_end: false
    },
    {
    word: "ethics",
    start_time: 2094500,
    end_time: 2094900,
    token_index: 5595,
    start_char: 29937,
    end_char: 29943,
    display_word: "ethics",
    period_at_end: false
    },
    {
    word: "laws",
    start_time: 2094900,
    end_time: 2095199,
    token_index: 5596,
    start_char: 29944,
    end_char: 29948,
    display_word: "laws",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 2095199,
    end_time: 2095400,
    token_index: 5597,
    start_char: 29949,
    end_char: 29953,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2095400,
    end_time: 2095400,
    token_index: 5598,
    start_char: 29954,
    end_char: 29955,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "tweet",
    start_time: 2095400,
    end_time: 2096100,
    token_index: 5599,
    start_char: 29956,
    end_char: 29961,
    display_word: "tweet",
    period_at_end: false
    },
    {
    word: "Ivanka",
    start_time: 2096400,
    end_time: 2096699,
    token_index: 5600,
    start_char: 29962,
    end_char: 29968,
    display_word: "Ivanka",
    period_at_end: false
    },
    {
    word: "tweeted",
    start_time: 2096699,
    end_time: 2097000,
    token_index: 5601,
    start_char: 29969,
    end_char: 29976,
    display_word: "tweeted",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 2097000,
    end_time: 2097200,
    token_index: 5602,
    start_char: 29977,
    end_char: 29981,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "picture",
    start_time: 2097200,
    end_time: 2097900,
    token_index: 5603,
    start_char: 29982,
    end_char: 29989,
    display_word: "picture",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2097900,
    end_time: 2098100,
    token_index: 5604,
    start_char: 29990,
    end_char: 29992,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "herself.",
    start_time: 2098100,
    end_time: 2098400,
    token_index: 5605,
    start_char: 29993,
    end_char: 30000,
    display_word: "herself",
    period_at_end: true
    },
    {
    word: "Holding",
    start_time: 2098400,
    end_time: 2098800,
    token_index: 5606,
    start_char: 30002,
    end_char: 30009,
    display_word: "Holding",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2098800,
    end_time: 2098900,
    token_index: 5607,
    start_char: 30010,
    end_char: 30011,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 2098900,
    end_time: 2099200,
    token_index: 5608,
    start_char: 30012,
    end_char: 30015,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2099200,
    end_time: 2099400,
    token_index: 5609,
    start_char: 30016,
    end_char: 30018,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "Goya",
    start_time: 2099400,
    end_time: 2099700,
    token_index: 5610,
    start_char: 30019,
    end_char: 30023,
    display_word: "Goya",
    period_at_end: false
    },
    {
    word: "beans.",
    start_time: 2099700,
    end_time: 2100300,
    token_index: 5611,
    start_char: 30024,
    end_char: 30029,
    display_word: "beans",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 2100300,
    end_time: 2100900,
    token_index: 5612,
    start_char: 30031,
    end_char: 30035,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "guess",
    start_time: 2100900,
    end_time: 2101300,
    token_index: 5613,
    start_char: 30036,
    end_char: 30041,
    display_word: "guess",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 2101300,
    end_time: 2101700,
    token_index: 5614,
    start_char: 30042,
    end_char: 30046,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "everyone",
    start_time: 2101700,
    end_time: 2102300,
    token_index: 5615,
    start_char: 30047,
    end_char: 30055,
    display_word: "everyone",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2102300,
    end_time: 2102500,
    token_index: 5616,
    start_char: 30056,
    end_char: 30059,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "White",
    start_time: 2102500,
    end_time: 2102800,
    token_index: 5617,
    start_char: 30060,
    end_char: 30065,
    display_word: "White",
    period_at_end: false
    },
    {
    word: "House",
    start_time: 2102800,
    end_time: 2103200,
    token_index: 5618,
    start_char: 30066,
    end_char: 30071,
    display_word: "House",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 2103200,
    end_time: 2103400,
    token_index: 5619,
    start_char: 30072,
    end_char: 30074,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 2103400,
    end_time: 2103600,
    token_index: 5620,
    start_char: 30075,
    end_char: 30078,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "sale.",
    start_time: 2103600,
    end_time: 2104400,
    token_index: 5621,
    start_char: 30079,
    end_char: 30083,
    display_word: "sale",
    period_at_end: true
    },
    {
    word: "Say",
    start_time: 2104400,
    end_time: 2104600,
    token_index: 5622,
    start_char: 30085,
    end_char: 30088,
    display_word: "Say",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 2104600,
    end_time: 2104700,
    token_index: 5623,
    start_char: 30089,
    end_char: 30092,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "like",
    start_time: 2104700,
    end_time: 2104900,
    token_index: 5624,
    start_char: 30093,
    end_char: 30097,
    display_word: "like",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2104900,
    end_time: 2105000,
    token_index: 5625,
    start_char: 30098,
    end_char: 30101,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "president",
    start_time: 2105000,
    end_time: 2105600,
    token_index: 5626,
    start_char: 30102,
    end_char: 30111,
    display_word: "president",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2105600,
    end_time: 2105700,
    token_index: 5627,
    start_char: 30112,
    end_char: 30115,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "maybe",
    start_time: 2105700,
    end_time: 2105900,
    token_index: 5628,
    start_char: 30116,
    end_char: 30121,
    display_word: "maybe",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 2105900,
    end_time: 2106100,
    token_index: 5629,
    start_char: 30122,
    end_char: 30125,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "daughter",
    start_time: 2106100,
    end_time: 2106500,
    token_index: 5630,
    start_char: 30126,
    end_char: 30134,
    display_word: "daughter",
    period_at_end: false
    },
    {
    word: "who's",
    start_time: 2106500,
    end_time: 2106700,
    token_index: 5631,
    start_char: 30135,
    end_char: 30140,
    display_word: "who's",
    period_at_end: false
    },
    {
    word: "also",
    start_time: 2106700,
    end_time: 2107000,
    token_index: 5632,
    start_char: 30141,
    end_char: 30145,
    display_word: "also",
    period_at_end: false
    },
    {
    word: "his",
    start_time: 2107000,
    end_time: 2107100,
    token_index: 5633,
    start_char: 30146,
    end_char: 30149,
    display_word: "his",
    period_at_end: false
    },
    {
    word: "adviser",
    start_time: 2107100,
    end_time: 2107700,
    token_index: 5634,
    start_char: 30150,
    end_char: 30157,
    display_word: "adviser",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 2107700,
    end_time: 2107900,
    token_index: 5635,
    start_char: 30158,
    end_char: 30162,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "endorse",
    start_time: 2107900,
    end_time: 2108400,
    token_index: 5636,
    start_char: 30163,
    end_char: 30170,
    display_word: "endorse",
    period_at_end: false
    },
    {
    word: "your",
    start_time: 2108400,
    end_time: 2108700,
    token_index: 5637,
    start_char: 30171,
    end_char: 30175,
    display_word: "your",
    period_at_end: false
    },
    {
    word: "product",
    start_time: 2108700,
    end_time: 2109500,
    token_index: 5638,
    start_char: 30176,
    end_char: 30183,
    display_word: "product",
    period_at_end: false
    },
    {
    word: "even",
    start_time: 2109500,
    end_time: 2109700,
    token_index: 5639,
    start_char: 30184,
    end_char: 30188,
    display_word: "even",
    period_at_end: false
    },
    {
    word: "if",
    start_time: 2109700,
    end_time: 2109800,
    token_index: 5640,
    start_char: 30189,
    end_char: 30191,
    display_word: "if",
    period_at_end: false
    },
    {
    word: "she",
    start_time: 2109800,
    end_time: 2110000,
    token_index: 5641,
    start_char: 30192,
    end_char: 30195,
    display_word: "she",
    period_at_end: false
    },
    {
    word: "thinks",
    start_time: 2110000,
    end_time: 2110400,
    token_index: 5642,
    start_char: 30196,
    end_char: 30202,
    display_word: "thinks",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2110400,
    end_time: 2110600,
    token_index: 5643,
    start_char: 30203,
    end_char: 30205,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 2110600,
    end_time: 2110800,
    token_index: 5644,
    start_char: 30206,
    end_char: 30208,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2110800,
    end_time: 2111000,
    token_index: 5645,
    start_char: 30209,
    end_char: 30210,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "telescope.",
    start_time: 2111000,
    end_time: 2112200,
    token_index: 5646,
    start_char: 30211,
    end_char: 30220,
    display_word: "telescope",
    period_at_end: true
    },
    {
    word: "Well,",
    start_time: 2112200,
    end_time: 2112900,
    token_index: 5647,
    start_char: 30222,
    end_char: 30227,
    display_word: "Well,",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 2112900,
    end_time: 2113100,
    token_index: 5648,
    start_char: 30228,
    end_char: 30229,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "am",
    start_time: 2113100,
    end_time: 2113400,
    token_index: 5649,
    start_char: 30230,
    end_char: 30232,
    display_word: "am",
    period_at_end: false
    },
    {
    word: "not",
    start_time: 2113400,
    end_time: 2113800,
    token_index: 5650,
    start_char: 30233,
    end_char: 30236,
    display_word: "not",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2113800,
    end_time: 2113900,
    token_index: 5651,
    start_char: 30237,
    end_char: 30238,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "sellout",
    start_time: 2113900,
    end_time: 2114500,
    token_index: 5652,
    start_char: 30239,
    end_char: 30246,
    display_word: "sellout",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 2114500,
    end_time: 2114700,
    token_index: 5653,
    start_char: 30247,
    end_char: 30249,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 2114700,
    end_time: 2115000,
    token_index: 5654,
    start_char: 30250,
    end_char: 30253,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "out",
    start_time: 2115000,
    end_time: 2115200,
    token_index: 5655,
    start_char: 30254,
    end_char: 30257,
    display_word: "out",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2115200,
    end_time: 2115300,
    token_index: 5656,
    start_char: 30258,
    end_char: 30260,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "here",
    start_time: 2115300,
    end_time: 2115500,
    token_index: 5657,
    start_char: 30261,
    end_char: 30265,
    display_word: "here",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2115500,
    end_time: 2115700,
    token_index: 5658,
    start_char: 30266,
    end_char: 30269,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 2115700,
    end_time: 2115900,
    token_index: 5659,
    start_char: 30270,
    end_char: 30273,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "taking",
    start_time: 2115900,
    end_time: 2116300,
    token_index: 5660,
    start_char: 30274,
    end_char: 30280,
    display_word: "taking",
    period_at_end: false
    },
    {
    word: "this",
    start_time: 2116300,
    end_time: 2116700,
    token_index: 5661,
    start_char: 30281,
    end_char: 30285,
    display_word: "this",
    period_at_end: false
    },
    {
    word: "I",
    start_time: 2116700,
    end_time: 2116900,
    token_index: 5662,
    start_char: 30286,
    end_char: 30287,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "know.",
    start_time: 2116900,
    end_time: 2117000,
    token_index: 5663,
    start_char: 30288,
    end_char: 30292,
    display_word: "know",
    period_at_end: true
    },
    {
    word: "Guy",
    start_time: 2117200,
    end_time: 2117400,
    token_index: 5664,
    start_char: 30294,
    end_char: 30297,
    display_word: "Guy",
    period_at_end: false
    },
    {
    word: "who",
    start_time: 2117400,
    end_time: 2117500,
    token_index: 5665,
    start_char: 30298,
    end_char: 30301,
    display_word: "who",
    period_at_end: false
    },
    {
    word: "buys",
    start_time: 2117500,
    end_time: 2117700,
    token_index: 5666,
    start_char: 30302,
    end_char: 30306,
    display_word: "buys",
    period_at_end: false
    },
    {
    word: "hair",
    start_time: 2117700,
    end_time: 2118100,
    token_index: 5667,
    start_char: 30307,
    end_char: 30311,
    display_word: "hair",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2118100,
    end_time: 2118300,
    token_index: 5668,
    start_char: 30312,
    end_char: 30315,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "unlike",
    start_time: 2118300,
    end_time: 2118800,
    token_index: 5669,
    start_char: 30316,
    end_char: 30322,
    display_word: "unlike",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 2118800,
    end_time: 2119400,
    token_index: 5670,
    start_char: 30323,
    end_char: 30326,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 2119400,
    end_time: 2119600,
    token_index: 5671,
    start_char: 30327,
    end_char: 30329,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "has",
    start_time: 2119600,
    end_time: 2120000,
    token_index: 5672,
    start_char: 30330,
    end_char: 30333,
    display_word: "has",
    period_at_end: false
    },
    {
    word: "integrity",
    start_time: 2120000,
    end_time: 2121000,
    token_index: 5673,
    start_char: 30334,
    end_char: 30343,
    display_word: "integrity",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2134500,
    end_time: 2134600,
    token_index: 5674,
    start_char: 30344,
    end_char: 30347,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "now",
    start_time: 2134600,
    end_time: 2135000,
    token_index: 5675,
    start_char: 30348,
    end_char: 30351,
    display_word: "now",
    period_at_end: false
    },
    {
    word: "performing",
    start_time: 2135000,
    end_time: 2135600,
    token_index: 5676,
    start_char: 30352,
    end_char: 30362,
    display_word: "performing",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2135600,
    end_time: 2136000,
    token_index: 5677,
    start_char: 30363,
    end_char: 30368,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2136000,
    end_time: 2136500,
    token_index: 5678,
    start_char: 30369,
    end_char: 30374,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 2136600,
    end_time: 2136800,
    token_index: 5679,
    start_char: 30375,
    end_char: 30379,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "their",
    start_time: 2136800,
    end_time: 2136900,
    token_index: 5680,
    start_char: 30380,
    end_char: 30385,
    display_word: "their",
    period_at_end: false
    },
    {
    word: "album",
    start_time: 2136900,
    end_time: 2137600,
    token_index: 5681,
    start_char: 30386,
    end_char: 30391,
    display_word: "album",
    period_at_end: false
    },
    {
    word: "gas",
    start_time: 2137600,
    end_time: 2137900,
    token_index: 5682,
    start_char: 30392,
    end_char: 30395,
    display_word: "gas",
    period_at_end: false
    },
    {
    word: "lighter",
    start_time: 2137900,
    end_time: 2138400,
    token_index: 5683,
    start_char: 30396,
    end_char: 30403,
    display_word: "lighter",
    period_at_end: false
    },
    {
    word: "ladies",
    start_time: 2138700,
    end_time: 2138900,
    token_index: 5684,
    start_char: 30404,
    end_char: 30410,
    display_word: "ladies",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2138900,
    end_time: 2139000,
    token_index: 5685,
    start_char: 30411,
    end_char: 30414,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "gentlemen",
    start_time: 2139000,
    end_time: 2139600,
    token_index: 5686,
    start_char: 30415,
    end_char: 30424,
    display_word: "gentlemen",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2140200,
    end_time: 2140300,
    token_index: 5687,
    start_char: 30425,
    end_char: 30428,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "chicks.",
    start_time: 2140300,
    end_time: 2140900,
    token_index: 5688,
    start_char: 30429,
    end_char: 30435,
    display_word: "chicks",
    period_at_end: true
    },
    {
    word: "Much",
    start_time: 2149800,
    end_time: 2150100,
    token_index: 5689,
    start_char: 30437,
    end_char: 30441,
    display_word: "Much",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 2150100,
    end_time: 2150700,
    token_index: 5690,
    start_char: 30442,
    end_char: 30446,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "too",
    start_time: 2150700,
    end_time: 2150900,
    token_index: 5691,
    start_char: 30447,
    end_char: 30450,
    display_word: "too",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 2150900,
    end_time: 2151000,
    token_index: 5692,
    start_char: 30451,
    end_char: 30455,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "on",
    start_time: 2151600,
    end_time: 2151700,
    token_index: 5693,
    start_char: 30456,
    end_char: 30458,
    display_word: "on",
    period_at_end: false
    },
    {
    word: "Trump",
    start_time: 2151700,
    end_time: 2152400,
    token_index: 5694,
    start_char: 30459,
    end_char: 30464,
    display_word: "Trump",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2152500,
    end_time: 2152900,
    token_index: 5695,
    start_char: 30465,
    end_char: 30470,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2152900,
    end_time: 2153600,
    token_index: 5696,
    start_char: 30471,
    end_char: 30476,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2153600,
    end_time: 2153800,
    token_index: 5697,
    start_char: 30477,
    end_char: 30479,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 2153800,
    end_time: 2154100,
    token_index: 5698,
    start_char: 30480,
    end_char: 30482,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "trunk.",
    start_time: 2154600,
    end_time: 2155400,
    token_index: 5699,
    start_char: 30483,
    end_char: 30488,
    display_word: "trunk",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 2155400,
    end_time: 2155800,
    token_index: 5700,
    start_char: 30490,
    end_char: 30494,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "hey,",
    start_time: 2155800,
    end_time: 2156300,
    token_index: 5701,
    start_char: 30495,
    end_char: 30499,
    display_word: "hey,",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 2156300,
    end_time: 2156400,
    token_index: 5702,
    start_char: 30500,
    end_char: 30503,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 2156400,
    end_time: 2156700,
    token_index: 5703,
    start_char: 30504,
    end_char: 30506,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "army",
    start_time: 2156700,
    end_time: 2157100,
    token_index: 5704,
    start_char: 30507,
    end_char: 30511,
    display_word: "army",
    period_at_end: false
    },
    {
    word: "or",
    start_time: 2157100,
    end_time: 2157400,
    token_index: 5705,
    start_char: 30512,
    end_char: 30514,
    display_word: "or",
    period_at_end: false
    },
    {
    word: "woman",
    start_time: 2157400,
    end_time: 2157900,
    token_index: 5706,
    start_char: 30515,
    end_char: 30520,
    display_word: "woman",
    period_at_end: false
    },
    {
    word: "army",
    start_time: 2159700,
    end_time: 2160000,
    token_index: 5707,
    start_char: 30521,
    end_char: 30525,
    display_word: "army",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2160000,
    end_time: 2160200,
    token_index: 5708,
    start_char: 30526,
    end_char: 30528,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "One",
    start_time: 2160200,
    end_time: 2160700,
    token_index: 5709,
    start_char: 30529,
    end_char: 30532,
    display_word: "One",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2160900,
    end_time: 2161600,
    token_index: 5710,
    start_char: 30533,
    end_char: 30538,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2161600,
    end_time: 2162300,
    token_index: 5711,
    start_char: 30539,
    end_char: 30544,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2162300,
    end_time: 2162500,
    token_index: 5712,
    start_char: 30545,
    end_char: 30547,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "My",
    start_time: 2162500,
    end_time: 2162800,
    token_index: 5713,
    start_char: 30548,
    end_char: 30550,
    display_word: "My",
    period_at_end: false
    },
    {
    word: "Own",
    start_time: 2162800,
    end_time: 2163400,
    token_index: 5714,
    start_char: 30551,
    end_char: 30554,
    display_word: "Own",
    period_at_end: false
    },
    {
    word: "Drum",
    start_time: 2163400,
    end_time: 2163900,
    token_index: 5715,
    start_char: 30555,
    end_char: 30559,
    display_word: "Drum",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2164100,
    end_time: 2164500,
    token_index: 5716,
    start_char: 30560,
    end_char: 30565,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 2164500,
    end_time: 2165200,
    token_index: 5717,
    start_char: 30566,
    end_char: 30570,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2165200,
    end_time: 2165500,
    token_index: 5718,
    start_char: 30571,
    end_char: 30573,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "My",
    start_time: 2165500,
    end_time: 2165800,
    token_index: 5719,
    start_char: 30574,
    end_char: 30576,
    display_word: "My",
    period_at_end: false
    },
    {
    word: "Own",
    start_time: 2165800,
    end_time: 2166300,
    token_index: 5720,
    start_char: 30577,
    end_char: 30580,
    display_word: "Own",
    period_at_end: false
    },
    {
    word: "Drum.",
    start_time: 2166300,
    end_time: 2166900,
    token_index: 5721,
    start_char: 30581,
    end_char: 30585,
    display_word: "Drum",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 2167100,
    end_time: 2167700,
    token_index: 5722,
    start_char: 30587,
    end_char: 30591,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "hey,",
    start_time: 2167700,
    end_time: 2167900,
    token_index: 5723,
    start_char: 30592,
    end_char: 30596,
    display_word: "hey,",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 2167900,
    end_time: 2168100,
    token_index: 5724,
    start_char: 30597,
    end_char: 30600,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 2168100,
    end_time: 2168300,
    token_index: 5725,
    start_char: 30601,
    end_char: 30603,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "army",
    start_time: 2168300,
    end_time: 2168800,
    token_index: 5726,
    start_char: 30604,
    end_char: 30608,
    display_word: "army",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2168800,
    end_time: 2169000,
    token_index: 5727,
    start_char: 30609,
    end_char: 30611,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 2169000,
    end_time: 2169600,
    token_index: 5728,
    start_char: 30612,
    end_char: 30615,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "woman",
    start_time: 2170800,
    end_time: 2171300,
    token_index: 5729,
    start_char: 30616,
    end_char: 30621,
    display_word: "woman",
    period_at_end: false
    },
    {
    word: "army",
    start_time: 2171300,
    end_time: 2171800,
    token_index: 5730,
    start_char: 30622,
    end_char: 30626,
    display_word: "army",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2171800,
    end_time: 2171900,
    token_index: 5731,
    start_char: 30627,
    end_char: 30629,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "One",
    start_time: 2171900,
    end_time: 2172500,
    token_index: 5732,
    start_char: 30630,
    end_char: 30633,
    display_word: "One",
    period_at_end: false
    },
    {
    word: "bring",
    start_time: 2172600,
    end_time: 2172800,
    token_index: 5733,
    start_char: 30634,
    end_char: 30639,
    display_word: "bring",
    period_at_end: false
    },
    {
    word: "us",
    start_time: 2172800,
    end_time: 2172900,
    token_index: 5734,
    start_char: 30640,
    end_char: 30642,
    display_word: "us",
    period_at_end: false
    },
    {
    word: "back",
    start_time: 2172900,
    end_time: 2173200,
    token_index: 5735,
    start_char: 30643,
    end_char: 30647,
    display_word: "back",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2173200,
    end_time: 2173300,
    token_index: 5736,
    start_char: 30648,
    end_char: 30651,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "he",
    start_time: 2173300,
    end_time: 2173600,
    token_index: 5737,
    start_char: 30652,
    end_char: 30654,
    display_word: "he",
    period_at_end: false
    },
    {
    word: "could",
    start_time: 2173600,
    end_time: 2173800,
    token_index: 5738,
    start_char: 30655,
    end_char: 30660,
    display_word: "could",
    period_at_end: false
    },
    {
    word: "She",
    start_time: 2173800,
    end_time: 2174000,
    token_index: 5739,
    start_char: 30661,
    end_char: 30664,
    display_word: "She",
    period_at_end: false
    },
    {
    word: "Don't",
    start_time: 2174000,
    end_time: 2174400,
    token_index: 5740,
    start_char: 30665,
    end_char: 30670,
    display_word: "Don't",
    period_at_end: false
    },
    {
    word: "Like",
    start_time: 2174400,
    end_time: 2174700,
    token_index: 5741,
    start_char: 30671,
    end_char: 30675,
    display_word: "Like",
    period_at_end: false
    },
    {
    word: "Mondays",
    start_time: 2174700,
    end_time: 2175500,
    token_index: 5742,
    start_char: 30676,
    end_char: 30683,
    display_word: "Mondays",
    period_at_end: false
    },
    {
    word: "underpaid",
    start_time: 2175500,
    end_time: 2176300,
    token_index: 5743,
    start_char: 30684,
    end_char: 30693,
    display_word: "underpaid",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 2176300,
    end_time: 2176500,
    token_index: 5744,
    start_char: 30694,
    end_char: 30696,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "Tripoli.",
    start_time: 2176500,
    end_time: 2177000,
    token_index: 5745,
    start_char: 30697,
    end_char: 30704,
    display_word: "Tripoli",
    period_at_end: true
    },
    {
    word: "Is",
    start_time: 2177200,
    end_time: 2177200,
    token_index: 5746,
    start_char: 30706,
    end_char: 30708,
    display_word: "Is",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2177200,
    end_time: 2177400,
    token_index: 5747,
    start_char: 30709,
    end_char: 30712,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "long",
    start_time: 2177400,
    end_time: 2177600,
    token_index: 5748,
    start_char: 30713,
    end_char: 30717,
    display_word: "long",
    period_at_end: false
    },
    {
    word: "hallways",
    start_time: 2177600,
    end_time: 2178300,
    token_index: 5749,
    start_char: 30718,
    end_char: 30726,
    display_word: "hallways",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 2178300,
    end_time: 2178500,
    token_index: 5750,
    start_char: 30727,
    end_char: 30730,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "yourself",
    start_time: 2178500,
    end_time: 2179000,
    token_index: 5751,
    start_char: 30731,
    end_char: 30739,
    display_word: "yourself",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2179000,
    end_time: 2179000,
    token_index: 5752,
    start_char: 30740,
    end_char: 30741,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "weapon",
    start_time: 2179000,
    end_time: 2179500,
    token_index: 5753,
    start_char: 30742,
    end_char: 30748,
    display_word: "weapon",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2179500,
    end_time: 2179800,
    token_index: 5754,
    start_char: 30749,
    end_char: 30752,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "take",
    start_time: 2179800,
    end_time: 2180000,
    token_index: 5755,
    start_char: 30753,
    end_char: 30757,
    display_word: "take",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2180000,
    end_time: 2180200,
    token_index: 5756,
    start_char: 30758,
    end_char: 30760,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2180200,
    end_time: 2180300,
    token_index: 5757,
    start_char: 30761,
    end_char: 30763,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2180300,
    end_time: 2180500,
    token_index: 5758,
    start_char: 30764,
    end_char: 30767,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "Gunnery?",
    start_time: 2180500,
    end_time: 2181300,
    token_index: 5759,
    start_char: 30768,
    end_char: 30776,
    display_word: "Gunnery?",
    period_at_end: false
    },
    {
    word: "I'll",
    start_time: 2181300,
    end_time: 2181600,
    token_index: 5760,
    start_char: 30777,
    end_char: 30781,
    display_word: "I'll",
    period_at_end: false
    },
    {
    word: "cut",
    start_time: 2181600,
    end_time: 2181800,
    token_index: 5761,
    start_char: 30782,
    end_char: 30785,
    display_word: "cut",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2181800,
    end_time: 2181900,
    token_index: 5762,
    start_char: 30786,
    end_char: 30789,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "fish.",
    start_time: 2181900,
    end_time: 2182400,
    token_index: 5763,
    start_char: 30790,
    end_char: 30794,
    display_word: "fish",
    period_at_end: true
    },
    {
    word: "Do",
    start_time: 2182400,
    end_time: 2182500,
    token_index: 5764,
    start_char: 30796,
    end_char: 30798,
    display_word: "Do",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 2182500,
    end_time: 2182600,
    token_index: 5765,
    start_char: 30799,
    end_char: 30802,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "ain't",
    start_time: 2182600,
    end_time: 2182800,
    token_index: 5766,
    start_char: 30803,
    end_char: 30808,
    display_word: "ain't",
    period_at_end: false
    },
    {
    word: "going",
    start_time: 2182800,
    end_time: 2183100,
    token_index: 5767,
    start_char: 30809,
    end_char: 30814,
    display_word: "going",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2183100,
    end_time: 2183300,
    token_index: 5768,
    start_char: 30815,
    end_char: 30817,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2183300,
    end_time: 2183400,
    token_index: 5769,
    start_char: 30818,
    end_char: 30821,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "gun",
    start_time: 2183400,
    end_time: 2183800,
    token_index: 5770,
    start_char: 30822,
    end_char: 30825,
    display_word: "gun",
    period_at_end: false
    },
    {
    word: "range",
    start_time: 2183800,
    end_time: 2184200,
    token_index: 5771,
    start_char: 30826,
    end_char: 30831,
    display_word: "range",
    period_at_end: false
    },
    {
    word: "that",
    start_time: 2184200,
    end_time: 2184500,
    token_index: 5772,
    start_char: 30832,
    end_char: 30836,
    display_word: "that",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2184500,
    end_time: 2184600,
    token_index: 5773,
    start_char: 30837,
    end_char: 30839,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 2184600,
    end_time: 2185100,
    token_index: 5774,
    start_char: 30840,
    end_char: 30844,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 2185500,
    end_time: 2185600,
    token_index: 5775,
    start_char: 30845,
    end_char: 30848,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "sons",
    start_time: 2185600,
    end_time: 2186100,
    token_index: 5776,
    start_char: 30849,
    end_char: 30853,
    display_word: "sons",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2186100,
    end_time: 2186400,
    token_index: 5777,
    start_char: 30854,
    end_char: 30857,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "daughters",
    start_time: 2186400,
    end_time: 2187100,
    token_index: 5778,
    start_char: 30858,
    end_char: 30867,
    display_word: "daughters",
    period_at_end: false
    },
    {
    word: "watching",
    start_time: 2187100,
    end_time: 2187600,
    token_index: 5779,
    start_char: 30868,
    end_char: 30876,
    display_word: "watching",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 2187600,
    end_time: 2187900,
    token_index: 5780,
    start_char: 30877,
    end_char: 30880,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "youth",
    start_time: 2187900,
    end_time: 2188200,
    token_index: 5781,
    start_char: 30881,
    end_char: 30886,
    display_word: "youth",
    period_at_end: false
    },
    {
    word: "have",
    start_time: 2188200,
    end_time: 2188400,
    token_index: 5782,
    start_char: 30887,
    end_char: 30891,
    display_word: "have",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2188400,
    end_time: 2188500,
    token_index: 5783,
    start_char: 30892,
    end_char: 30894,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "solve",
    start_time: 2188500,
    end_time: 2189000,
    token_index: 5784,
    start_char: 30895,
    end_char: 30900,
    display_word: "solve",
    period_at_end: false
    },
    {
    word: "our",
    start_time: 2189000,
    end_time: 2189200,
    token_index: 5785,
    start_char: 30901,
    end_char: 30904,
    display_word: "our",
    period_at_end: false
    },
    {
    word: "problems.",
    start_time: 2189200,
    end_time: 2190100,
    token_index: 5786,
    start_char: 30905,
    end_char: 30913,
    display_word: "problems",
    period_at_end: true
    },
    {
    word: "I",
    start_time: 2190200,
    end_time: 2190300,
    token_index: 5787,
    start_char: 30915,
    end_char: 30916,
    display_word: "I",
    period_at_end: false
    },
    {
    word: "follow",
    start_time: 2190300,
    end_time: 2190700,
    token_index: 5788,
    start_char: 30917,
    end_char: 30923,
    display_word: "follow",
    period_at_end: false
    },
    {
    word: "them.",
    start_time: 2190700,
    end_time: 2191100,
    token_index: 5789,
    start_char: 30924,
    end_char: 30928,
    display_word: "them",
    period_at_end: true
    },
    {
    word: "So",
    start_time: 2191100,
    end_time: 2191300,
    token_index: 5790,
    start_char: 30930,
    end_char: 30932,
    display_word: "So",
    period_at_end: false
    },
    {
    word: "who's",
    start_time: 2191300,
    end_time: 2191600,
    token_index: 5791,
    start_char: 30933,
    end_char: 30938,
    display_word: "who's",
    period_at_end: false
    },
    {
    word: "coming",
    start_time: 2191600,
    end_time: 2192200,
    token_index: 5792,
    start_char: 30939,
    end_char: 30945,
    display_word: "coming",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 2192200,
    end_time: 2192500,
    token_index: 5793,
    start_char: 30946,
    end_char: 30950,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 2192500,
    end_time: 2192800,
    token_index: 5794,
    start_char: 30951,
    end_char: 30953,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "after",
    start_time: 2193000,
    end_time: 2193300,
    token_index: 5795,
    start_char: 30954,
    end_char: 30959,
    display_word: "after",
    period_at_end: false
    },
    {
    word: "you",
    start_time: 2193300,
    end_time: 2193600,
    token_index: 5796,
    start_char: 30960,
    end_char: 30963,
    display_word: "you",
    period_at_end: false
    },
    {
    word: "love",
    start_time: 2193600,
    end_time: 2194000,
    token_index: 5797,
    start_char: 30964,
    end_char: 30968,
    display_word: "love",
    period_at_end: false
    },
    {
    word: "me?",
    start_time: 2194000,
    end_time: 2194200,
    token_index: 5798,
    start_char: 30969,
    end_char: 30972,
    display_word: "me?",
    period_at_end: false
    },
    {
    word: "I've",
    start_time: 2194200,
    end_time: 2194600,
    token_index: 5799,
    start_char: 30973,
    end_char: 30977,
    display_word: "I've",
    period_at_end: false
    },
    {
    word: "already",
    start_time: 2194600,
    end_time: 2195100,
    token_index: 5800,
    start_char: 30978,
    end_char: 30985,
    display_word: "already",
    period_at_end: false
    },
    {
    word: "hating",
    start_time: 2195100,
    end_time: 2195500,
    token_index: 5801,
    start_char: 30986,
    end_char: 30992,
    display_word: "hating",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 2195500,
    end_time: 2195900,
    token_index: 5802,
    start_char: 30993,
    end_char: 30995,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "drunk",
    start_time: 2198300,
    end_time: 2199000,
    token_index: 5803,
    start_char: 30996,
    end_char: 31001,
    display_word: "drunk",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2199100,
    end_time: 2199500,
    token_index: 5804,
    start_char: 31002,
    end_char: 31007,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2199500,
    end_time: 2200100,
    token_index: 5805,
    start_char: 31008,
    end_char: 31013,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2200100,
    end_time: 2200300,
    token_index: 5806,
    start_char: 31014,
    end_char: 31016,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "My",
    start_time: 2200300,
    end_time: 2200700,
    token_index: 5807,
    start_char: 31017,
    end_char: 31019,
    display_word: "My",
    period_at_end: false
    },
    {
    word: "Own",
    start_time: 2200700,
    end_time: 2201200,
    token_index: 5808,
    start_char: 31020,
    end_char: 31023,
    display_word: "Own",
    period_at_end: false
    },
    {
    word: "Drum.",
    start_time: 2201200,
    end_time: 2201800,
    token_index: 5809,
    start_char: 31024,
    end_char: 31028,
    display_word: "Drum",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 2202000,
    end_time: 2202300,
    token_index: 5810,
    start_char: 31030,
    end_char: 31034,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "hey,",
    start_time: 2202400,
    end_time: 2202800,
    token_index: 5811,
    start_char: 31035,
    end_char: 31039,
    display_word: "hey,",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 2202800,
    end_time: 2203000,
    token_index: 5812,
    start_char: 31040,
    end_char: 31043,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 2203000,
    end_time: 2203200,
    token_index: 5813,
    start_char: 31044,
    end_char: 31046,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "army",
    start_time: 2203200,
    end_time: 2203700,
    token_index: 5814,
    start_char: 31047,
    end_char: 31051,
    display_word: "army",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2203700,
    end_time: 2203900,
    token_index: 5815,
    start_char: 31052,
    end_char: 31054,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "One.",
    start_time: 2203900,
    end_time: 2204400,
    token_index: 5816,
    start_char: 31055,
    end_char: 31058,
    display_word: "One",
    period_at_end: true
    },
    {
    word: "Jump",
    start_time: 2210000,
    end_time: 2210500,
    token_index: 5817,
    start_char: 31060,
    end_char: 31064,
    display_word: "Jump",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2210700,
    end_time: 2211200,
    token_index: 5818,
    start_char: 31065,
    end_char: 31070,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2211200,
    end_time: 2211800,
    token_index: 5819,
    start_char: 31071,
    end_char: 31076,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2211800,
    end_time: 2212000,
    token_index: 5820,
    start_char: 31077,
    end_char: 31079,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 2212000,
    end_time: 2212200,
    token_index: 5821,
    start_char: 31080,
    end_char: 31082,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "drum.",
    start_time: 2212800,
    end_time: 2213500,
    token_index: 5822,
    start_char: 31083,
    end_char: 31087,
    display_word: "drum",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 2213600,
    end_time: 2214000,
    token_index: 5823,
    start_char: 31089,
    end_char: 31093,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "baby.",
    start_time: 2214000,
    end_time: 2214500,
    token_index: 5824,
    start_char: 31094,
    end_char: 31098,
    display_word: "baby",
    period_at_end: true
    },
    {
    word: "I'm",
    start_time: 2214500,
    end_time: 2214700,
    token_index: 5825,
    start_char: 31100,
    end_char: 31103,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 2214700,
    end_time: 2214900,
    token_index: 5826,
    start_char: 31104,
    end_char: 31106,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "army",
    start_time: 2214900,
    end_time: 2215400,
    token_index: 5827,
    start_char: 31107,
    end_char: 31111,
    display_word: "army",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2215400,
    end_time: 2215600,
    token_index: 5828,
    start_char: 31112,
    end_char: 31114,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "one",
    start_time: 2215600,
    end_time: 2216100,
    token_index: 5829,
    start_char: 31115,
    end_char: 31118,
    display_word: "one",
    period_at_end: false
    },
    {
    word: "tell",
    start_time: 2219100,
    end_time: 2219300,
    token_index: 5830,
    start_char: 31119,
    end_char: 31123,
    display_word: "tell",
    period_at_end: false
    },
    {
    word: "me",
    start_time: 2219300,
    end_time: 2219500,
    token_index: 5831,
    start_char: 31124,
    end_char: 31126,
    display_word: "me",
    period_at_end: false
    },
    {
    word: "what",
    start_time: 2222400,
    end_time: 2222600,
    token_index: 5832,
    start_char: 31127,
    end_char: 31131,
    display_word: "what",
    period_at_end: false
    },
    {
    word: "they",
    start_time: 2222600,
    end_time: 2222700,
    token_index: 5833,
    start_char: 31132,
    end_char: 31136,
    display_word: "they",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 2222700,
    end_time: 2223000,
    token_index: 5834,
    start_char: 31137,
    end_char: 31140,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2223000,
    end_time: 2223100,
    token_index: 5835,
    start_char: 31141,
    end_char: 31144,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "can",
    start_time: 2223100,
    end_time: 2223500,
    token_index: 5836,
    start_char: 31145,
    end_char: 31148,
    display_word: "can",
    period_at_end: false
    },
    {
    word: "do",
    start_time: 2223500,
    end_time: 2223800,
    token_index: 5837,
    start_char: 31149,
    end_char: 31151,
    display_word: "do",
    period_at_end: false
    },
    {
    word: "with",
    start_time: 2223800,
    end_time: 2224000,
    token_index: 5838,
    start_char: 31152,
    end_char: 31156,
    display_word: "with",
    period_at_end: false
    },
    {
    word: "the",
    start_time: 2224000,
    end_time: 2224100,
    token_index: 5839,
    start_char: 31157,
    end_char: 31160,
    display_word: "the",
    period_at_end: false
    },
    {
    word: "bodies",
    start_time: 2224100,
    end_time: 2224900,
    token_index: 5840,
    start_char: 31161,
    end_char: 31167,
    display_word: "bodies",
    period_at_end: false
    },
    {
    word: "temperatures",
    start_time: 2224900,
    end_time: 2225500,
    token_index: 5841,
    start_char: 31168,
    end_char: 31180,
    display_word: "temperatures",
    period_at_end: false
    },
    {
    word: "rise",
    start_time: 2225500,
    end_time: 2226100,
    token_index: 5842,
    start_char: 31181,
    end_char: 31185,
    display_word: "rise",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2226100,
    end_time: 2226300,
    token_index: 5843,
    start_char: 31186,
    end_char: 31189,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "cities",
    start_time: 2226300,
    end_time: 2226800,
    token_index: 5844,
    start_char: 31190,
    end_char: 31196,
    display_word: "cities",
    period_at_end: false
    },
    {
    word: "are",
    start_time: 2226800,
    end_time: 2227000,
    token_index: 5845,
    start_char: 31197,
    end_char: 31200,
    display_word: "are",
    period_at_end: false
    },
    {
    word: "sinking.",
    start_time: 2227000,
    end_time: 2227900,
    token_index: 5846,
    start_char: 31201,
    end_char: 31208,
    display_word: "sinking",
    period_at_end: true
    },
    {
    word: "I'll",
    start_time: 2227900,
    end_time: 2228200,
    token_index: 5847,
    start_char: 31210,
    end_char: 31214,
    display_word: "I'll",
    period_at_end: false
    },
    {
    word: "cut",
    start_time: 2228200,
    end_time: 2228400,
    token_index: 5848,
    start_char: 31215,
    end_char: 31218,
    display_word: "cut",
    period_at_end: false
    },
    {
    word: "that.",
    start_time: 2228400,
    end_time: 2228700,
    token_index: 5849,
    start_char: 31219,
    end_char: 31223,
    display_word: "that",
    period_at_end: true
    },
    {
    word: "You",
    start_time: 2228700,
    end_time: 2228900,
    token_index: 5850,
    start_char: 31225,
    end_char: 31228,
    display_word: "You",
    period_at_end: false
    },
    {
    word: "know,",
    start_time: 2228900,
    end_time: 2229100,
    token_index: 5851,
    start_char: 31229,
    end_char: 31234,
    display_word: "know,",
    period_at_end: false
    },
    {
    word: "you've",
    start_time: 2229100,
    end_time: 2229300,
    token_index: 5852,
    start_char: 31235,
    end_char: 31241,
    display_word: "you've",
    period_at_end: false
    },
    {
    word: "city",
    start_time: 2229300,
    end_time: 2229700,
    token_index: 5853,
    start_char: 31242,
    end_char: 31246,
    display_word: "city",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 2229700,
    end_time: 2230000,
    token_index: 5854,
    start_char: 31247,
    end_char: 31249,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "sinking.",
    start_time: 2230000,
    end_time: 2230600,
    token_index: 5855,
    start_char: 31250,
    end_char: 31257,
    display_word: "sinking",
    period_at_end: true
    },
    {
    word: "That's",
    start_time: 2230900,
    end_time: 2231200,
    token_index: 5856,
    start_char: 31259,
    end_char: 31265,
    display_word: "That's",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 2231200,
    end_time: 2231300,
    token_index: 5857,
    start_char: 31266,
    end_char: 31268,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "truth",
    start_time: 2231300,
    end_time: 2231900,
    token_index: 5858,
    start_char: 31269,
    end_char: 31274,
    display_word: "truth",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2231900,
    end_time: 2232000,
    token_index: 5859,
    start_char: 31275,
    end_char: 31278,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "truth",
    start_time: 2232000,
    end_time: 2232600,
    token_index: 5860,
    start_char: 31279,
    end_char: 31284,
    display_word: "truth",
    period_at_end: false
    },
    {
    word: "is",
    start_time: 2232600,
    end_time: 2232900,
    token_index: 5861,
    start_char: 31285,
    end_char: 31287,
    display_word: "is",
    period_at_end: false
    },
    {
    word: "fiction.",
    start_time: 2232900,
    end_time: 2233600,
    token_index: 5862,
    start_char: 31288,
    end_char: 31295,
    display_word: "fiction",
    period_at_end: true
    },
    {
    word: "Everybody's",
    start_time: 2233700,
    end_time: 2234300,
    token_index: 5863,
    start_char: 31297,
    end_char: 31308,
    display_word: "Everybody's",
    period_at_end: false
    },
    {
    word: "talking",
    start_time: 2234300,
    end_time: 2234900,
    token_index: 5864,
    start_char: 31309,
    end_char: 31316,
    display_word: "talking",
    period_at_end: false
    },
    {
    word: "who's",
    start_time: 2234900,
    end_time: 2235200,
    token_index: 5865,
    start_char: 31317,
    end_char: 31322,
    display_word: "who's",
    period_at_end: false
    },
    {
    word: "gonna",
    start_time: 2235200,
    end_time: 2235700,
    token_index: 5866,
    start_char: 31323,
    end_char: 31328,
    display_word: "gonna",
    period_at_end: false
    },
    {
    word: "listen.",
    start_time: 2235700,
    end_time: 2236300,
    token_index: 5867,
    start_char: 31329,
    end_char: 31335,
    display_word: "listen",
    period_at_end: true
    },
    {
    word: "Happen",
    start_time: 2238000,
    end_time: 2238400,
    token_index: 5868,
    start_char: 31337,
    end_char: 31343,
    display_word: "Happen",
    period_at_end: false
    },
    {
    word: "in",
    start_time: 2238400,
    end_time: 2238600,
    token_index: 5869,
    start_char: 31344,
    end_char: 31346,
    display_word: "in",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2238600,
    end_time: 2238700,
    token_index: 5870,
    start_char: 31347,
    end_char: 31348,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "sink",
    start_time: 2238700,
    end_time: 2239300,
    token_index: 5871,
    start_char: 31349,
    end_char: 31353,
    display_word: "sink",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2239300,
    end_time: 2239700,
    token_index: 5872,
    start_char: 31354,
    end_char: 31355,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2245600,
    end_time: 2246000,
    token_index: 5873,
    start_char: 31356,
    end_char: 31361,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2246000,
    end_time: 2246700,
    token_index: 5874,
    start_char: 31362,
    end_char: 31367,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "2012",
    start_time: 2246700,
    end_time: 2246900,
    token_index: 5875,
    start_char: 31368,
    end_char: 31372,
    display_word: "2012",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 2255600,
    end_time: 2255900,
    token_index: 5876,
    start_char: 31373,
    end_char: 31375,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2257200,
    end_time: 2257700,
    token_index: 5877,
    start_char: 31376,
    end_char: 31381,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "March",
    start_time: 2257700,
    end_time: 2258300,
    token_index: 5878,
    start_char: 31382,
    end_char: 31387,
    display_word: "March",
    period_at_end: false
    },
    {
    word: "to",
    start_time: 2258300,
    end_time: 2258500,
    token_index: 5879,
    start_char: 31388,
    end_char: 31390,
    display_word: "to",
    period_at_end: false
    },
    {
    word: "my",
    start_time: 2258500,
    end_time: 2258700,
    token_index: 5880,
    start_char: 31391,
    end_char: 31393,
    display_word: "my",
    period_at_end: false
    },
    {
    word: "drum.",
    start_time: 2259400,
    end_time: 2260000,
    token_index: 5881,
    start_char: 31394,
    end_char: 31398,
    display_word: "drum",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 2260200,
    end_time: 2260500,
    token_index: 5882,
    start_char: 31400,
    end_char: 31404,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "hey,",
    start_time: 2260500,
    end_time: 2261000,
    token_index: 5883,
    start_char: 31405,
    end_char: 31409,
    display_word: "hey,",
    period_at_end: false
    },
    {
    word: "I'm",
    start_time: 2261000,
    end_time: 2261200,
    token_index: 5884,
    start_char: 31410,
    end_char: 31413,
    display_word: "I'm",
    period_at_end: false
    },
    {
    word: "an",
    start_time: 2261200,
    end_time: 2261400,
    token_index: 5885,
    start_char: 31414,
    end_char: 31416,
    display_word: "an",
    period_at_end: false
    },
    {
    word: "army",
    start_time: 2261400,
    end_time: 2261900,
    token_index: 5886,
    start_char: 31417,
    end_char: 31421,
    display_word: "army",
    period_at_end: false
    },
    {
    word: "of",
    start_time: 2261900,
    end_time: 2262100,
    token_index: 5887,
    start_char: 31422,
    end_char: 31424,
    display_word: "of",
    period_at_end: false
    },
    {
    word: "One.",
    start_time: 2262100,
    end_time: 2262700,
    token_index: 5888,
    start_char: 31425,
    end_char: 31428,
    display_word: "One",
    period_at_end: true
    },
    {
    word: "Thanks",
    start_time: 2375000,
    end_time: 2375200,
    token_index: 5889,
    start_char: 31430,
    end_char: 31436,
    display_word: "Thanks",
    period_at_end: false
    },
    {
    word: "so",
    start_time: 2375200,
    end_time: 2375300,
    token_index: 5890,
    start_char: 31437,
    end_char: 31439,
    display_word: "so",
    period_at_end: false
    },
    {
    word: "much",
    start_time: 2375300,
    end_time: 2375500,
    token_index: 5891,
    start_char: 31440,
    end_char: 31444,
    display_word: "much",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 2375500,
    end_time: 2375600,
    token_index: 5892,
    start_char: 31445,
    end_char: 31448,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "being",
    start_time: 2375600,
    end_time: 2375800,
    token_index: 5893,
    start_char: 31449,
    end_char: 31454,
    display_word: "being",
    period_at_end: false
    },
    {
    word: "here",
    start_time: 2375800,
    end_time: 2376000,
    token_index: 5894,
    start_char: 31455,
    end_char: 31459,
    display_word: "here",
    period_at_end: false
    },
    {
    word: "chicks",
    start_time: 2376000,
    end_time: 2376500,
    token_index: 5895,
    start_char: 31460,
    end_char: 31466,
    display_word: "chicks",
    period_at_end: false
    },
    {
    word: "will",
    start_time: 2376500,
    end_time: 2376600,
    token_index: 5896,
    start_char: 31467,
    end_char: 31471,
    display_word: "will",
    period_at_end: false
    },
    {
    word: "be",
    start_time: 2376600,
    end_time: 2376700,
    token_index: 5897,
    start_char: 31472,
    end_char: 31474,
    display_word: "be",
    period_at_end: false
    },
    {
    word: "right",
    start_time: 2376700,
    end_time: 2376900,
    token_index: 5898,
    start_char: 31475,
    end_char: 31480,
    display_word: "right",
    period_at_end: false
    },
    {
    word: "back.",
    start_time: 2376900,
    end_time: 2377500,
    token_index: 5899,
    start_char: 31481,
    end_char: 31485,
    display_word: "back",
    period_at_end: true
    },
    {
    word: "That's",
    start_time: 2385600,
    end_time: 2385900,
    token_index: 5900,
    start_char: 31487,
    end_char: 31493,
    display_word: "That's",
    period_at_end: false
    },
    {
    word: "it",
    start_time: 2385900,
    end_time: 2386000,
    token_index: 5901,
    start_char: 31494,
    end_char: 31496,
    display_word: "it",
    period_at_end: false
    },
    {
    word: "for",
    start_time: 2386000,
    end_time: 2386200,
    token_index: 5902,
    start_char: 31497,
    end_char: 31500,
    display_word: "for",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2386200,
    end_time: 2386200,
    token_index: 5903,
    start_char: 31501,
    end_char: 31502,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "late",
    start_time: 2386200,
    end_time: 2386400,
    token_index: 5904,
    start_char: 31503,
    end_char: 31507,
    display_word: "late",
    period_at_end: false
    },
    {
    word: "show",
    start_time: 2386400,
    end_time: 2386600,
    token_index: 5905,
    start_char: 31508,
    end_char: 31512,
    display_word: "show",
    period_at_end: false
    },
    {
    word: "everybody.",
    start_time: 2386600,
    end_time: 2387300,
    token_index: 5906,
    start_char: 31513,
    end_char: 31522,
    display_word: "everybody",
    period_at_end: true
    },
    {
    word: "Hey,",
    start_time: 2387400,
    end_time: 2387900,
    token_index: 5907,
    start_char: 31524,
    end_char: 31528,
    display_word: "Hey,",
    period_at_end: false
    },
    {
    word: "let's",
    start_time: 2387900,
    end_time: 2388100,
    token_index: 5908,
    start_char: 31529,
    end_char: 31534,
    display_word: "let's",
    period_at_end: false
    },
    {
    word: "say",
    start_time: 2388100,
    end_time: 2388300,
    token_index: 5909,
    start_char: 31535,
    end_char: 31538,
    display_word: "say",
    period_at_end: false
    },
    {
    word: "goodnight",
    start_time: 2388300,
    end_time: 2388700,
    token_index: 5910,
    start_char: 31539,
    end_char: 31548,
    display_word: "goodnight",
    period_at_end: false
    },
    {
    word: "was",
    start_time: 2388700,
    end_time: 2388900,
    token_index: 5911,
    start_char: 31549,
    end_char: 31552,
    display_word: "was",
    period_at_end: false
    },
    {
    word: "a",
    start_time: 2388900,
    end_time: 2388900,
    token_index: 5912,
    start_char: 31553,
    end_char: 31554,
    display_word: "a",
    period_at_end: false
    },
    {
    word: "music",
    start_time: 2388900,
    end_time: 2389300,
    token_index: 5913,
    start_char: 31555,
    end_char: 31560,
    display_word: "music",
    period_at_end: false
    },
    {
    word: "from",
    start_time: 2389300,
    end_time: 2389400,
    token_index: 5914,
    start_char: 31561,
    end_char: 31565,
    display_word: "from",
    period_at_end: false
    },
    {
    word: "Jon",
    start_time: 2389400,
    end_time: 2389700,
    token_index: 5915,
    start_char: 31566,
    end_char: 31569,
    display_word: "Jon",
    period_at_end: false
    },
    {
    word: "Batiste",
    start_time: 2389700,
    end_time: 2390200,
    token_index: 5916,
    start_char: 31570,
    end_char: 31577,
    display_word: "Batiste",
    period_at_end: false
    },
    {
    word: "and",
    start_time: 2390200,
    end_time: 2390300,
    token_index: 5917,
    start_char: 31578,
    end_char: 31581,
    display_word: "and",
    period_at_end: false
    },
    {
    word: "stay",
    start_time: 2390300,
    end_time: 2390500,
    token_index: 5918,
    start_char: 31582,
    end_char: 31586,
    display_word: "stay",
    period_at_end: false
    },
    {
    word: "human.",
    start_time: 2390500,
    end_time: 2390900,
    token_index: 5919,
    start_char: 31587,
    end_char: 31592,
    display_word: "human",
    period_at_end: true
    },
    {
    word: "How",
    start_time: 2390900,
    end_time: 2391000,
    token_index: 5920,
    start_char: 31594,
    end_char: 31597,
    display_word: "How",
    period_at_end: false
    },
    {
    word: "about",
    start_time: 2391000,
    end_time: 2391200,
    token_index: 5921,
    start_char: 31598,
    end_char: 31603,
    display_word: "about",
    period_at_end: false
    },
    {
    word: "that?",
    start_time: 2391200,
    end_time: 2391700,
    token_index: 5922,
    start_char: 31604,
    end_char: 31609,
    display_word: "that?",
    period_at_end: false
    }
    ]