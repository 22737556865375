export const nerData = [
    {
    name: "Brian Kemp",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Brian_Kemp",
    mid: "/m/0bwl7t0"
    },
    salience: 0.034870278,
    mentions: [
    {
    text: {
    content: "mask",
    beginOffset: 2073
    },
    type: "COMMON"
    },
    {
    text: {
    content: "Brian Kemp",
    beginOffset: 2078
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Kemp",
    beginOffset: 2193
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Governor",
    beginOffset: 2184
    },
    type: "COMMON"
    },
    {
    text: {
    content: "Kemp",
    beginOffset: 2402
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Kemp",
    beginOffset: 2463
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "woman",
    type: "PERSON",
    salience: 0.020578258,
    mentions: [
    {
    text: {
    content: "mom",
    beginOffset: 16755
    },
    type: "COMMON"
    },
    {
    text: {
    content: "mom",
    beginOffset: 20745
    },
    type: "COMMON"
    },
    {
    text: {
    content: "woman",
    beginOffset: 20768
    },
    type: "COMMON"
    },
    {
    text: {
    content: "mom",
    beginOffset: 20898
    },
    type: "COMMON"
    },
    {
    text: {
    content: "mom",
    beginOffset: 21475
    },
    type: "COMMON"
    },
    {
    text: {
    content: "woman",
    beginOffset: 21504
    },
    type: "COMMON"
    },
    {
    text: {
    content: "mom",
    beginOffset: 21608
    },
    type: "COMMON"
    },
    {
    text: {
    content: "mom",
    beginOffset: 21929
    },
    type: "COMMON"
    },
    {
    text: {
    content: "mom",
    beginOffset: 22052
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Donald Trump",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Donald_Trump",
    mid: "/m/0cqt90"
    },
    salience: 0.019463753,
    mentions: [
    {
    text: {
    content: "Donald Trump",
    beginOffset: 631
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Donald Trump",
    beginOffset: 2422
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 6252
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 8224
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 8723
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 8949
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 9357
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 9454
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Donald Trump",
    beginOffset: 16437
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 7419
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 7456
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 8457
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 8470
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 9584
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Trump",
    beginOffset: 30459
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "pair",
    type: "EVENT",
    salience: 0.016391873,
    mentions: [
    {
    text: {
    content: "pair",
    beginOffset: 940
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "law",
    type: "OTHER",
    salience: 0.01582183,
    mentions: [
    {
    text: {
    content: "law",
    beginOffset: 2370
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Joe Biden",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Joe_Biden",
    mid: "/m/012gx2"
    },
    salience: 0.014228293,
    mentions: [
    {
    text: {
    content: "Joe Biden",
    beginOffset: 4119
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Biden",
    beginOffset: 4371
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Biden",
    beginOffset: 7406
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Biden",
    beginOffset: 7813
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Biden",
    beginOffset: 8075
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Joe",
    beginOffset: 7233
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Russians",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Russians",
    mid: "/m/0g6ff"
    },
    salience: 0.013945534,
    mentions: [
    {
    text: {
    content: "Russians",
    beginOffset: 2824
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.012121137,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 26868
    },
    type: "COMMON"
    },
    {
    text: {
    content: "things",
    beginOffset: 27032
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Democrat",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Democratic_Party_(United_States)",
    mid: "/m/0d075m"
    },
    salience: 0.011975669,
    mentions: [
    {
    text: {
    content: "Governor",
    beginOffset: 94
    },
    type: "COMMON"
    },
    {
    text: {
    content: "Democrat",
    beginOffset: 85
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "White House",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/White_House",
    mid: "/m/081sq"
    },
    salience: 0.011398418,
    mentions: [
    {
    text: {
    content: "White House",
    beginOffset: 6158
    },
    type: "PROPER"
    },
    {
    text: {
    content: "White House",
    beginOffset: 30060
    },
    type: "PROPER"
    },
    {
    text: {
    content: "White House",
    beginOffset: 6371
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "hackers",
    type: "PERSON",
    salience: 0.008652916,
    mentions: [
    {
    text: {
    content: "hackers",
    beginOffset: 2751
    },
    type: "COMMON"
    },
    {
    text: {
    content: "hackers",
    beginOffset: 2954
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Lee",
    type: "PERSON",
    salience: 0.008058366,
    mentions: [
    {
    text: {
    content: "Lee",
    beginOffset: 1640
    },
    type: "PROPER"
    },
    {
    text: {
    content: "governor",
    beginOffset: 1657
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cases",
    type: "OTHER",
    salience: 0.007329992,
    mentions: [
    {
    text: {
    content: "cases",
    beginOffset: 1190
    },
    type: "COMMON"
    },
    {
    text: {
    content: "cases",
    beginOffset: 1350
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "song",
    type: "WORK_OF_ART",
    salience: 0.006815589,
    mentions: [
    {
    text: {
    content: "song",
    beginOffset: 11541
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "election",
    type: "EVENT",
    salience: 0.0064705294,
    mentions: [
    {
    text: {
    content: "election",
    beginOffset: 5
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "supremacy",
    type: "OTHER",
    salience: 0.005990777,
    mentions: [
    {
    text: {
    content: "supremacy",
    beginOffset: 18142
    },
    type: "COMMON"
    },
    {
    text: {
    content: "supremacy",
    beginOffset: 18232
    },
    type: "COMMON"
    },
    {
    text: {
    content: "supremacy",
    beginOffset: 19248
    },
    type: "COMMON"
    },
    {
    text: {
    content: "fact",
    beginOffset: 19265
    },
    type: "COMMON"
    },
    {
    text: {
    content: "supremacy",
    beginOffset: 19362
    },
    type: "COMMON"
    },
    {
    text: {
    content: "supremacy",
    beginOffset: 23281
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Anthony Fouch",
    type: "PERSON",
    salience: 0.00598476,
    mentions: [
    {
    text: {
    content: "amine",
    beginOffset: 6230
    },
    type: "COMMON"
    },
    {
    text: {
    content: "Anthony Fouch",
    beginOffset: 6236
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Fouch",
    beginOffset: 6282
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Fouch",
    beginOffset: 6394
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "thing",
    type: "OTHER",
    salience: 0.005627755,
    mentions: [
    {
    text: {
    content: "thing",
    beginOffset: 2562
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "animal",
    type: "OTHER",
    salience: 0.0056083933,
    mentions: [
    {
    text: {
    content: "animal",
    beginOffset: 528
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "state",
    type: "LOCATION",
    salience: 0.0054831137,
    mentions: [
    {
    text: {
    content: "state",
    beginOffset: 145
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Guy Trump",
    type: "PERSON",
    salience: 0.005468742,
    mentions: [
    {
    text: {
    content: "Guy Trump",
    beginOffset: 8915
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Guy",
    beginOffset: 30294
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Twitter",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Twitter",
    mid: "/m/0289n8t"
    },
    salience: 0.0052577495,
    mentions: [
    {
    text: {
    content: "Twitter",
    beginOffset: 3938
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Twitter",
    beginOffset: 4529
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Twitter",
    beginOffset: 4555
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Twitter",
    beginOffset: 4743
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Twitter",
    beginOffset: 4916
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Twitter",
    beginOffset: 5866
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Twitter",
    beginOffset: 5993
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "one",
    type: "PERSON",
    salience: 0.004840661,
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 26291
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Frozen Radisson",
    type: "PERSON",
    salience: 0.0047401334,
    mentions: [
    {
    text: {
    content: "Frozen Radisson",
    beginOffset: 10722
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "mask",
    type: "OTHER",
    salience: 0.0046007168,
    mentions: [
    {
    text: {
    content: "mask",
    beginOffset: 1566
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "masks",
    type: "CONSUMER_GOOD",
    salience: 0.004588099,
    mentions: [
    {
    text: {
    content: "masks",
    beginOffset: 2162
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "justification",
    type: "OTHER",
    salience: 0.004565405,
    mentions: [
    {
    text: {
    content: "justification",
    beginOffset: 20611
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "official",
    type: "PERSON",
    salience: 0.0045246314,
    mentions: [
    {
    text: {
    content: "official",
    beginOffset: 1986
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Corrine",
    type: "PERSON",
    salience: 0.004392486,
    mentions: [
    {
    text: {
    content: "Corrine",
    beginOffset: 3830
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Senator",
    beginOffset: 3822
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "School",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/School",
    mid: "/m/06zdj"
    },
    salience: 0.0042859195,
    mentions: [
    {
    text: {
    content: "School",
    beginOffset: 16141
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "ballots",
    type: "OTHER",
    salience: 0.0042625302,
    mentions: [
    {
    text: {
    content: "ballots",
    beginOffset: 390
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Governors",
    type: "PERSON",
    salience: 0.0041601546,
    mentions: [
    {
    text: {
    content: "Governors",
    beginOffset: 14495
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "group",
    type: "ORGANIZATION",
    salience: 0.0041561145,
    mentions: [
    {
    text: {
    content: "group",
    beginOffset: 3283
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "boys",
    type: "PERSON",
    salience: 0.0041548195,
    mentions: [
    {
    text: {
    content: "boys",
    beginOffset: 3388
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "boys",
    type: "PERSON",
    salience: 0.004153557,
    mentions: [
    {
    text: {
    content: "boys",
    beginOffset: 3441
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "dogs",
    type: "OTHER",
    salience: 0.004045115,
    mentions: [
    {
    text: {
    content: "dogs",
    beginOffset: 256
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "one",
    type: "OTHER",
    salience: 0.003955223,
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 11435
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.0039538783,
    mentions: [
    {
    text: {
    content: "fuel",
    beginOffset: 18178
    },
    type: "COMMON"
    },
    {
    text: {
    content: "racism",
    beginOffset: 18197
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Everybody",
    type: "PERSON",
    salience: 0.003888055,
    mentions: [
    {
    text: {
    content: "Everybody",
    beginOffset: 13569
    },
    type: "COMMON"
    },
    {
    text: {
    content: "comedian",
    beginOffset: 13619
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0038314133,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 18938
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fraud",
    type: "OTHER",
    salience: 0.003809724,
    mentions: [
    {
    text: {
    content: "fraud",
    beginOffset: 31
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ballots",
    type: "OTHER",
    salience: 0.0035186028,
    mentions: [
    {
    text: {
    content: "ballots",
    beginOffset: 124
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mail",
    type: "OTHER",
    salience: 0.0035186028,
    mentions: [
    {
    text: {
    content: "mail",
    beginOffset: 59
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "system",
    type: "OTHER",
    salience: 0.003470133,
    mentions: [
    {
    text: {
    content: "system",
    beginOffset: 19346
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Brad",
    type: "PERSON",
    salience: 0.0034239097,
    mentions: [
    {
    text: {
    content: "Brad",
    beginOffset: 9019
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Brad",
    beginOffset: 9608
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Brad",
    beginOffset: 9658
    },
    type: "PROPER"
    },
    {
    text: {
    content: "one",
    beginOffset: 9684
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "time",
    type: "OTHER",
    salience: 0.003404161,
    mentions: [
    {
    text: {
    content: "time",
    beginOffset: 3515
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0032244443,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 2147
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0032213908,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 2233
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0032011967,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 4094
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Stephen Colbert",
    type: "PERSON",
    metadata: {
    mid: "/m/01xdf5",
    wikipedia_url: "https://en.wikipedia.org/wiki/Stephen_Colbert"
    },
    salience: 0.0031312222,
    mentions: [
    {
    text: {
    content: "Stephen Colbert",
    beginOffset: 681
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Stephen",
    beginOffset: 730
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Stephen Colbert",
    beginOffset: 848
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Stephen Colbert",
    beginOffset: 1029
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Stephen Colbert",
    beginOffset: 23013
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "millions",
    type: "OTHER",
    salience: 0.003090362,
    mentions: [
    {
    text: {
    content: "millions",
    beginOffset: 112
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "account",
    type: "OTHER",
    salience: 0.00306182,
    mentions: [
    {
    text: {
    content: "account",
    beginOffset: 4924
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.0030465408,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 2255
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Sarah McLachlan",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Sarah_McLachlan",
    mid: "/m/0133x7"
    },
    salience: 0.0030218202,
    mentions: [
    {
    text: {
    content: "Sarah McLachlan",
    beginOffset: 270
    },
    type: "PROPER"
    },
    {
    text: {
    content: "angel",
    beginOffset: 302
    },
    type: "COMMON"
    },
    {
    text: {
    content: "comedian",
    beginOffset: 16244
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "impetus",
    type: "OTHER",
    salience: 0.002933541,
    mentions: [
    {
    text: {
    content: "thing",
    beginOffset: 29542
    },
    type: "COMMON"
    },
    {
    text: {
    content: "impetus",
    beginOffset: 29624
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tragedy",
    type: "OTHER",
    salience: 0.0029263743,
    mentions: [
    {
    text: {
    content: "tragedy",
    beginOffset: 26819
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mask orders",
    type: "OTHER",
    salience: 0.0029055267,
    mentions: [
    {
    text: {
    content: "mask orders",
    beginOffset: 1405
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mask order",
    type: "OTHER",
    salience: 0.0028917638,
    mentions: [
    {
    text: {
    content: "mask order",
    beginOffset: 1879
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chick",
    type: "OTHER",
    salience: 0.0028860667,
    mentions: [
    {
    text: {
    content: "chick",
    beginOffset: 757
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "president",
    type: "PERSON",
    salience: 0.002873293,
    mentions: [
    {
    text: {
    content: "president",
    beginOffset: 3788
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "United States",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/United_States",
    mid: "/m/09c7w0"
    },
    salience: 0.0027939915,
    mentions: [
    {
    text: {
    content: "United States",
    beginOffset: 1125
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United States",
    beginOffset: 3805
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United",
    beginOffset: 13515
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United",
    beginOffset: 13654
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United",
    beginOffset: 17573
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United",
    beginOffset: 23491
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America",
    beginOffset: 2845
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America",
    beginOffset: 7639
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America",
    beginOffset: 13671
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America",
    beginOffset: 17164
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America",
    beginOffset: 17590
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America",
    beginOffset: 18186
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America",
    beginOffset: 18720
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America",
    beginOffset: 23508
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United States",
    beginOffset: 3562
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United States",
    beginOffset: 15448
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United States",
    beginOffset: 17719
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United States",
    beginOffset: 18107
    },
    type: "PROPER"
    },
    {
    text: {
    content: "United States",
    beginOffset: 21277
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "kids",
    type: "PERSON",
    salience: 0.0027590904,
    mentions: [
    {
    text: {
    content: "kids",
    beginOffset: 15698
    },
    type: "COMMON"
    },
    {
    text: {
    content: "kids",
    beginOffset: 15965
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mom",
    type: "PERSON",
    salience: 0.0026958091,
    mentions: [
    {
    text: {
    content: "mom",
    beginOffset: 20656
    },
    type: "COMMON"
    },
    {
    text: {
    content: "mom",
    beginOffset: 20720
    },
    type: "COMMON"
    },
    {
    text: {
    content: "mom",
    beginOffset: 21207
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "EVENT",
    salience: 0.002634211,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 1009
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "TV",
    type: "OTHER",
    salience: 0.0026270251,
    mentions: [
    {
    text: {
    content: "TV",
    beginOffset: 9212
    },
    type: "COMMON"
    },
    {
    text: {
    content: "TV",
    beginOffset: 13020
    },
    type: "COMMON"
    },
    {
    text: {
    content: "TV",
    beginOffset: 13389
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "song",
    type: "WORK_OF_ART",
    salience: 0.0025909536,
    mentions: [
    {
    text: {
    content: "song",
    beginOffset: 29246
    },
    type: "COMMON"
    },
    {
    text: {
    content: "song",
    beginOffset: 29640
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "daughter",
    type: "PERSON",
    salience: 0.0025621932,
    mentions: [
    {
    text: {
    content: "daughter",
    beginOffset: 16779
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mom",
    type: "PERSON",
    salience: 0.002556323,
    mentions: [
    {
    text: {
    content: "mom",
    beginOffset: 1545
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "name",
    type: "OTHER",
    salience: 0.0025475253,
    mentions: [
    {
    text: {
    content: "name",
    beginOffset: 8552
    },
    type: "COMMON"
    },
    {
    text: {
    content: "name",
    beginOffset: 24486
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Governor",
    type: "PERSON",
    salience: 0.0025289003,
    mentions: [
    {
    text: {
    content: "Governor",
    beginOffset: 484
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Governors",
    type: "PERSON",
    salience: 0.0024910823,
    mentions: [
    {
    text: {
    content: "Governors",
    beginOffset: 1356
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Governor",
    type: "PERSON",
    salience: 0.0024818284,
    mentions: [
    {
    text: {
    content: "Governor",
    beginOffset: 1748
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "masks",
    type: "OTHER",
    salience: 0.0024818284,
    mentions: [
    {
    text: {
    content: "masks",
    beginOffset: 1785
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mask",
    type: "OTHER",
    salience: 0.0024737227,
    mentions: [
    {
    text: {
    content: "mask",
    beginOffset: 2502
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "telescope",
    type: "CONSUMER_GOOD",
    salience: 0.0024512568,
    mentions: [
    {
    text: {
    content: "telescope",
    beginOffset: 30211
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hacking story",
    type: "OTHER",
    salience: 0.0024476708,
    mentions: [
    {
    text: {
    content: "hacking story",
    beginOffset: 3889
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "wife",
    type: "PERSON",
    salience: 0.0024432482,
    mentions: [
    {
    text: {
    content: "wife",
    beginOffset: 15894
    },
    type: "COMMON"
    },
    {
    text: {
    content: "wife",
    beginOffset: 17366
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "car",
    type: "CONSUMER_GOOD",
    salience: 0.0023909037,
    mentions: [
    {
    text: {
    content: "car",
    beginOffset: 3415
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0023356401,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 25757
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "governor",
    type: "PERSON",
    salience: 0.002246715,
    mentions: [
    {
    text: {
    content: "governor",
    beginOffset: 2044
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "election",
    type: "EVENT",
    salience: 0.0022247315,
    mentions: [
    {
    text: {
    content: "election",
    beginOffset: 3704
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Election",
    type: "EVENT",
    salience: 0.002224087,
    mentions: [
    {
    text: {
    content: "Election",
    beginOffset: 3739
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "accounts",
    type: "OTHER",
    salience: 0.002224087,
    mentions: [
    {
    text: {
    content: "accounts",
    beginOffset: 3946
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "accounts",
    type: "OTHER",
    salience: 0.0022228425,
    mentions: [
    {
    text: {
    content: "accounts",
    beginOffset: 4082
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Jack Dorsey",
    type: "PERSON",
    metadata: {
    mid: "/m/04y8bn6",
    wikipedia_url: "https://en.wikipedia.org/wiki/Jack_Dorsey"
    },
    salience: 0.0022101097,
    mentions: [
    {
    text: {
    content: "CEO",
    beginOffset: 5835
    },
    type: "COMMON"
    },
    {
    text: {
    content: "Jack Dorsey",
    beginOffset: 5840
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "protesters",
    type: "PERSON",
    salience: 0.0021699301,
    mentions: [
    {
    text: {
    content: "protesters",
    beginOffset: 11050
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "coronavirus",
    type: "OTHER",
    salience: 0.0021571137,
    mentions: [
    {
    text: {
    content: "coronavirus",
    beginOffset: 1092
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "scam",
    type: "OTHER",
    salience: 0.0021422487,
    mentions: [
    {
    text: {
    content: "scam",
    beginOffset: 4024
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "dogs",
    type: "OTHER",
    salience: 0.0021064957,
    mentions: [
    {
    text: {
    content: "dogs",
    beginOffset: 228
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "day dogs",
    type: "OTHER",
    salience: 0.0020885938,
    mentions: [
    {
    text: {
    content: "day dogs",
    beginOffset: 336
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mail slots dogs",
    type: "OTHER",
    salience: 0.002082863,
    mentions: [
    {
    text: {
    content: "mail slots dogs",
    beginOffset: 420
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "virus",
    type: "OTHER",
    salience: 0.0020784158,
    mentions: [
    {
    text: {
    content: "virus",
    beginOffset: 3840
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "magazine",
    type: "WORK_OF_ART",
    salience: 0.0020743173,
    mentions: [
    {
    text: {
    content: "magazine",
    beginOffset: 6475
    },
    type: "COMMON"
    },
    {
    text: {
    content: "magazine",
    beginOffset: 6937
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "dogs",
    type: "OTHER",
    salience: 0.0020742514,
    mentions: [
    {
    text: {
    content: "dogs",
    beginOffset: 609
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "number",
    type: "OTHER",
    salience: 0.0020742514,
    mentions: [
    {
    text: {
    content: "number",
    beginOffset: 563
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "distance",
    type: "OTHER",
    salience: 0.0020678428,
    mentions: [
    {
    text: {
    content: "distance",
    beginOffset: 833
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "look",
    type: "OTHER",
    salience: 0.002058501,
    mentions: [
    {
    text: {
    content: "look",
    beginOffset: 969
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Bitcoin",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Bitcoin",
    mid: "/m/05p0rrx"
    },
    salience: 0.0020560573,
    mentions: [
    {
    text: {
    content: "Bitcoin",
    beginOffset: 3972
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Bitcoin",
    beginOffset: 4016
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Bitcoin",
    beginOffset: 4051
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Bitcoin",
    beginOffset: 4242
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "record",
    type: "OTHER",
    salience: 0.0020532447,
    mentions: [
    {
    text: {
    content: "record",
    beginOffset: 1222
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "death record",
    type: "OTHER",
    salience: 0.0020430193,
    mentions: [
    {
    text: {
    content: "death record",
    beginOffset: 1830
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Looks",
    type: "OTHER",
    salience: 0.002029592,
    mentions: [
    {
    text: {
    content: "Looks",
    beginOffset: 3420
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hack",
    type: "OTHER",
    salience: 0.002027267,
    mentions: [
    {
    text: {
    content: "hack",
    beginOffset: 4064
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0020248923,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 12228
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "books",
    type: "WORK_OF_ART",
    salience: 0.0019957297,
    mentions: [
    {
    text: {
    content: "books",
    beginOffset: 17789
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "music",
    type: "WORK_OF_ART",
    salience: 0.001995061,
    mentions: [
    {
    text: {
    content: "music",
    beginOffset: 23755
    },
    type: "COMMON"
    },
    {
    text: {
    content: "music",
    beginOffset: 27924
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "anything",
    type: "OTHER",
    salience: 0.0019537613,
    mentions: [
    {
    text: {
    content: "anything",
    beginOffset: 4032
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "whiteness",
    type: "OTHER",
    salience: 0.0019509441,
    mentions: [
    {
    text: {
    content: "whiteness",
    beginOffset: 19598
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "card",
    type: "OTHER",
    salience: 0.0019369376,
    mentions: [
    {
    text: {
    content: "card",
    beginOffset: 1490
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "order",
    type: "OTHER",
    salience: 0.0019262137,
    mentions: [
    {
    text: {
    content: "order",
    beginOffset: 1906
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "campaign",
    type: "EVENT",
    salience: 0.0019235276,
    mentions: [
    {
    text: {
    content: "campaign",
    beginOffset: 2334
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "guys",
    type: "PERSON",
    salience: 0.0019233219,
    mentions: [
    {
    text: {
    content: "guys",
    beginOffset: 19428
    },
    type: "COMMON"
    },
    {
    text: {
    content: "guys",
    beginOffset: 26583
    },
    type: "COMMON"
    },
    {
    text: {
    content: "guys",
    beginOffset: 29715
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.0019106888,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 14771
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "attacks",
    type: "EVENT",
    salience: 0.001890303,
    mentions: [
    {
    text: {
    content: "attacks",
    beginOffset: 2779
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "bummer",
    type: "PERSON",
    salience: 0.0018679894,
    mentions: [
    {
    text: {
    content: "bummer",
    beginOffset: 28122
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "enthusiasm",
    type: "OTHER",
    salience: 0.0018559395,
    mentions: [
    {
    text: {
    content: "enthusiasm",
    beginOffset: 8288
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "daughter",
    type: "PERSON",
    salience: 0.0018353411,
    mentions: [
    {
    text: {
    content: "daughter",
    beginOffset: 30126
    },
    type: "COMMON"
    },
    {
    text: {
    content: "adviser",
    beginOffset: 30150
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "animal",
    type: "OTHER",
    salience: 0.0018343343,
    mentions: [
    {
    text: {
    content: "animal",
    beginOffset: 323
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "friend",
    type: "PERSON",
    salience: 0.0018161086,
    mentions: [
    {
    text: {
    content: "friend",
    beginOffset: 723
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "The Masks",
    type: "ORGANIZATION",
    salience: 0.0018059212,
    mentions: [
    {
    text: {
    content: "The Masks",
    beginOffset: 1945
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "hands",
    type: "OTHER",
    salience: 0.0017972589,
    mentions: [
    {
    text: {
    content: "hands",
    beginOffset: 1606
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "nothing",
    type: "OTHER",
    salience: 0.0017925384,
    mentions: [
    {
    text: {
    content: "nothing",
    beginOffset: 2204
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "matters",
    type: "OTHER",
    salience: 0.0017917055,
    mentions: [
    {
    text: {
    content: "matters",
    beginOffset: 2274
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "news",
    type: "OTHER",
    salience: 0.001787961,
    mentions: [
    {
    text: {
    content: "news",
    beginOffset: 2704
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "coronavirus data",
    type: "OTHER",
    salience: 0.0017872837,
    mentions: [
    {
    text: {
    content: "coronavirus data",
    beginOffset: 2855
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "data",
    type: "OTHER",
    salience: 0.0017859892,
    mentions: [
    {
    text: {
    content: "data",
    beginOffset: 2924
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "coronavirus vaccine research",
    type: "OTHER",
    salience: 0.0017859892,
    mentions: [
    {
    text: {
    content: "coronavirus vaccine research",
    beginOffset: 2987
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "matter",
    type: "OTHER",
    salience: 0.0017853697,
    mentions: [
    {
    text: {
    content: "matter",
    beginOffset: 3055
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "women",
    type: "PERSON",
    salience: 0.0017847675,
    mentions: [
    {
    text: {
    content: "women",
    beginOffset: 3213
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "democracy",
    type: "OTHER",
    salience: 0.0017825128,
    mentions: [
    {
    text: {
    content: "democracy",
    beginOffset: 3458
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "scam",
    type: "OTHER",
    salience: 0.0017814677,
    mentions: [
    {
    text: {
    content: "scam",
    beginOffset: 3980
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everything",
    type: "OTHER",
    salience: 0.0017526952,
    mentions: [
    {
    text: {
    content: "everything",
    beginOffset: 24520
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everything",
    type: "OTHER",
    salience: 0.0017354265,
    mentions: [
    {
    text: {
    content: "everything",
    beginOffset: 18839
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lot",
    type: "OTHER",
    salience: 0.0017172894,
    mentions: [
    {
    text: {
    content: "lot",
    beginOffset: 7884
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "product",
    type: "CONSUMER_GOOD",
    salience: 0.0017116718,
    mentions: [
    {
    text: {
    content: "product",
    beginOffset: 12540
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "nobody",
    type: "PERSON",
    salience: 0.0016872708,
    mentions: [
    {
    text: {
    content: "nobody",
    beginOffset: 184
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "school district",
    type: "ORGANIZATION",
    salience: 0.0016761746,
    mentions: [
    {
    text: {
    content: "school district",
    beginOffset: 15779
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "criminals",
    type: "PERSON",
    salience: 0.0016755873,
    mentions: [
    {
    text: {
    content: "experts",
    beginOffset: 5279
    },
    type: "COMMON"
    },
    {
    text: {
    content: "criminals",
    beginOffset: 5398
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "candidates",
    type: "PERSON",
    salience: 0.0016683398,
    mentions: [
    {
    text: {
    content: "candidates",
    beginOffset: 445
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cat murderer",
    type: "PERSON",
    salience: 0.0016683398,
    mentions: [
    {
    text: {
    content: "cat murderer",
    beginOffset: 493
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "treatment",
    type: "OTHER",
    salience: 0.0016658136,
    mentions: [
    {
    text: {
    content: "treatment",
    beginOffset: 6700
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "musicians",
    type: "PERSON",
    salience: 0.0016583169,
    mentions: [
    {
    text: {
    content: "sons",
    beginOffset: 28374
    },
    type: "COMMON"
    },
    {
    text: {
    content: "musicians",
    beginOffset: 28410
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "bake sale",
    type: "EVENT",
    salience: 0.0016381774,
    mentions: [
    {
    text: {
    content: "bake sale",
    beginOffset: 1682
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "face",
    type: "OTHER",
    salience: 0.0016356007,
    mentions: [
    {
    text: {
    content: "face",
    beginOffset: 2057
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "health",
    type: "OTHER",
    salience: 0.0016356007,
    mentions: [
    {
    text: {
    content: "health",
    beginOffset: 2022
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "attack",
    type: "EVENT",
    salience: 0.0016266687,
    mentions: [
    {
    text: {
    content: "attack",
    beginOffset: 3255
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "groups",
    type: "ORGANIZATION",
    salience: 0.0016251849,
    mentions: [
    {
    text: {
    content: "groups",
    beginOffset: 3605
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "messages",
    type: "WORK_OF_ART",
    salience: 0.0016238047,
    mentions: [
    {
    text: {
    content: "messages",
    beginOffset: 4174
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "election",
    type: "EVENT",
    salience: 0.0016010478,
    mentions: [
    {
    text: {
    content: "election",
    beginOffset: 7118
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fact",
    type: "OTHER",
    salience: 0.0015707293,
    mentions: [
    {
    text: {
    content: "fact",
    beginOffset: 12192
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "California",
    type: "LOCATION",
    metadata: {
    mid: "/m/01n7q",
    wikipedia_url: "https://en.wikipedia.org/wiki/California"
    },
    salience: 0.0015444611,
    mentions: [
    {
    text: {
    content: "California",
    beginOffset: 9862
    },
    type: "PROPER"
    },
    {
    text: {
    content: "California",
    beginOffset: 13936
    },
    type: "PROPER"
    },
    {
    text: {
    content: "California",
    beginOffset: 14134
    },
    type: "PROPER"
    },
    {
    text: {
    content: "California",
    beginOffset: 14305
    },
    type: "PROPER"
    },
    {
    text: {
    content: "California",
    beginOffset: 15481
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Death Star",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Death_Star",
    mid: "/m/0f325"
    },
    salience: 0.0015431487,
    mentions: [
    {
    text: {
    content: "Death Star",
    beginOffset: 9157
    },
    type: "PROPER"
    },
    {
    text: {
    content: "juggernaut campaign",
    beginOffset: 9137
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.001540639,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 20050
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Mother's Day",
    type: "EVENT",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Mother's_Day",
    mid: "/m/0cjjt"
    },
    salience: 0.0014697742,
    mentions: [
    {
    text: {
    content: "Mother's Day",
    beginOffset: 1477
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "problem",
    type: "OTHER",
    salience: 0.0014683766,
    mentions: [
    {
    text: {
    content: "problem",
    beginOffset: 12310
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Masky",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Mask",
    mid: "/m/01kr41"
    },
    salience: 0.001467123,
    mentions: [
    {
    text: {
    content: "Masky",
    beginOffset: 1624
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "CNN",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/CNN",
    mid: "/m/0gsgr"
    },
    salience: 0.0014653218,
    mentions: [
    {
    text: {
    content: "CNN",
    beginOffset: 13643
    },
    type: "PROPER"
    },
    {
    text: {
    content: "CNN",
    beginOffset: 16271
    },
    type: "PROPER"
    },
    {
    text: {
    content: "CNN",
    beginOffset: 17554
    },
    type: "PROPER"
    },
    {
    text: {
    content: "CNN",
    beginOffset: 23541
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Senator sniff",
    type: "OTHER",
    salience: 0.0014652264,
    mentions: [
    {
    text: {
    content: "Senator sniff",
    beginOffset: 461
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "screen",
    type: "OTHER",
    salience: 0.0014591678,
    mentions: [
    {
    text: {
    content: "screen",
    beginOffset: 578
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "leg",
    type: "OTHER",
    salience: 0.0014567703,
    mentions: [
    {
    text: {
    content: "leg",
    beginOffset: 672
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tape",
    type: "OTHER",
    salience: 0.0014546589,
    mentions: [
    {
    text: {
    content: "tape",
    beginOffset: 816
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "dog",
    type: "OTHER",
    salience: 0.0014510686,
    mentions: [
    {
    text: {
    content: "dog",
    beginOffset: 891
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "shoes",
    type: "CONSUMER_GOOD",
    salience: 0.0014510686,
    mentions: [
    {
    text: {
    content: "shoes",
    beginOffset: 904
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "thing",
    type: "OTHER",
    salience: 0.0014501519,
    mentions: [
    {
    text: {
    content: "thing",
    beginOffset: 8093
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "total",
    type: "OTHER",
    salience: 0.0014443884,
    mentions: [
    {
    text: {
    content: "total",
    beginOffset: 1177
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Mayors",
    type: "PERSON",
    salience: 0.0014433105,
    mentions: [
    {
    text: {
    content: "Mayors",
    beginOffset: 1370
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Fourth",
    type: "PERSON",
    salience: 0.0014404248,
    mentions: [
    {
    text: {
    content: "Fourth",
    beginOffset: 1530
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hell",
    type: "OTHER",
    salience: 0.0014404248,
    mentions: [
    {
    text: {
    content: "hell",
    beginOffset: 1519
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lady",
    type: "PERSON",
    salience: 0.0014387352,
    mentions: [
    {
    text: {
    content: "lady",
    beginOffset: 1670
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kiv",
    type: "OTHER",
    salience: 0.0014387352,
    mentions: [
    {
    text: {
    content: "kiv",
    beginOffset: 1743
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "localities",
    type: "LOCATION",
    salience: 0.001436472,
    mentions: [
    {
    text: {
    content: "localities",
    beginOffset: 2122
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "voters",
    type: "PERSON",
    salience: 0.001436472,
    mentions: [
    {
    text: {
    content: "voters",
    beginOffset: 1938
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "elderly",
    type: "OTHER",
    salience: 0.0014344687,
    mentions: [
    {
    text: {
    content: "elderly",
    beginOffset: 2352
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "airport",
    type: "LOCATION",
    salience: 0.0014332505,
    mentions: [
    {
    text: {
    content: "airport",
    beginOffset: 2487
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ear look buddy",
    type: "PERSON",
    salience: 0.0014332505,
    mentions: [
    {
    text: {
    content: "ear look buddy",
    beginOffset: 2525
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hazmat suit",
    type: "OTHER",
    salience: 0.0014326718,
    mentions: [
    {
    text: {
    content: "hazmat suit",
    beginOffset: 2628
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "accident",
    type: "EVENT",
    salience: 0.0014326718,
    mentions: [
    {
    text: {
    content: "accident",
    beginOffset: 2609
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "shoulders",
    type: "OTHER",
    salience: 0.0014326718,
    mentions: [
    {
    text: {
    content: "shoulders",
    beginOffset: 2657
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Research centers",
    type: "LOCATION",
    salience: 0.0014321118,
    mentions: [
    {
    text: {
    content: "Research centers",
    beginOffset: 2799
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "coronavirus cures",
    type: "OTHER",
    salience: 0.0014300362,
    mentions: [
    {
    text: {
    content: "coronavirus cures",
    beginOffset: 3111
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "spam",
    type: "OTHER",
    salience: 0.0014300362,
    mentions: [
    {
    text: {
    content: "spam",
    beginOffset: 3094
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "member",
    type: "PERSON",
    salience: 0.0014295537,
    mentions: [
    {
    text: {
    content: "member",
    beginOffset: 3190
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "emails",
    type: "WORK_OF_ART",
    salience: 0.001427324,
    mentions: [
    {
    text: {
    content: "emails",
    beginOffset: 3666
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "musk",
    type: "OTHER",
    salience: 0.0014261118,
    mentions: [
    {
    text: {
    content: "musk",
    beginOffset: 4149
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "community",
    type: "OTHER",
    salience: 0.0014257259,
    mentions: [
    {
    text: {
    content: "community",
    beginOffset: 4212
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "books",
    type: "WORK_OF_ART",
    salience: 0.0014090901,
    mentions: [
    {
    text: {
    content: "books",
    beginOffset: 17943
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "rationale racism",
    type: "OTHER",
    salience: 0.0014081639,
    mentions: [
    {
    text: {
    content: "rationale racism",
    beginOffset: 19869
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lack",
    type: "OTHER",
    salience: 0.0013947746,
    mentions: [
    {
    text: {
    content: "lack",
    beginOffset: 3164
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Jon Batiste",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Jon_Batiste",
    mid: "/m/0411jny"
    },
    salience: 0.0013859094,
    mentions: [
    {
    text: {
    content: "Jon Batiste",
    beginOffset: 773
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Jon Batiste",
    beginOffset: 11911
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Jon Batiste",
    beginOffset: 31566
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.0013790193,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 26302
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "human",
    type: "PERSON",
    salience: 0.0013769537,
    mentions: [
    {
    text: {
    content: "human",
    beginOffset: 794
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "government operatives",
    type: "ORGANIZATION",
    salience: 0.0013730458,
    mentions: [
    {
    text: {
    content: "government operatives",
    beginOffset: 5420
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cries",
    type: "OTHER",
    salience: 0.00135413,
    mentions: [
    {
    text: {
    content: "cries",
    beginOffset: 12753
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Cozy Bear",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Cozy_Bear",
    mid: "/g/11b8szm9gt"
    },
    salience: 0.0013462611,
    mentions: [
    {
    text: {
    content: "Cozy Bear",
    beginOffset: 3316
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Cozy Bear",
    beginOffset: 3584
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Touch Of Violence",
    type: "OTHER",
    salience: 0.0013250324,
    mentions: [
    {
    text: {
    content: "Touch Of Violence",
    beginOffset: 13188
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "map",
    type: "OTHER",
    salience: 0.0013150434,
    mentions: [
    {
    text: {
    content: "map",
    beginOffset: 9700
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "president",
    type: "PERSON",
    salience: 0.0012784774,
    mentions: [
    {
    text: {
    content: "president",
    beginOffset: 30102
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "car",
    type: "CONSUMER_GOOD",
    salience: 0.0012677618,
    mentions: [
    {
    text: {
    content: "car",
    beginOffset: 12953
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "pressure",
    type: "OTHER",
    salience: 0.0012345364,
    mentions: [
    {
    text: {
    content: "pressure",
    beginOffset: 14441
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "polls",
    type: "OTHER",
    salience: 0.0012324544,
    mentions: [
    {
    text: {
    content: "polls",
    beginOffset: 8753
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sports pages",
    type: "OTHER",
    salience: 0.0012307111,
    mentions: [
    {
    text: {
    content: "sports pages",
    beginOffset: 1073
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "high school",
    type: "ORGANIZATION",
    salience: 0.001219424,
    mentions: [
    {
    text: {
    content: "high school",
    beginOffset: 10163
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "thing",
    type: "OTHER",
    salience: 0.0012050086,
    mentions: [
    {
    text: {
    content: "thing",
    beginOffset: 20424
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Fauci",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Anthony_Fauci",
    mid: "/m/04k4m3"
    },
    salience: 0.0012024471,
    mentions: [
    {
    text: {
    content: "Fauci",
    beginOffset: 6896
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Dr.",
    beginOffset: 6892
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0011989537,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 7963
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Alabama",
    type: "LOCATION",
    metadata: {
    mid: "/m/0gyh",
    wikipedia_url: "https://en.wikipedia.org/wiki/Alabama"
    },
    salience: 0.0011986922,
    mentions: [
    {
    text: {
    content: "Alabama",
    beginOffset: 1649
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Alabama",
    beginOffset: 1930
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Alabama",
    beginOffset: 1796
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0011982042,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 8374
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "scale",
    type: "OTHER",
    salience: 0.0011898783,
    mentions: [
    {
    text: {
    content: "scale",
    beginOffset: 9057
    },
    type: "COMMON"
    },
    {
    text: {
    content: "scale",
    beginOffset: 9374
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "brownies",
    type: "OTHER",
    salience: 0.0011669685,
    mentions: [
    {
    text: {
    content: "brownies",
    beginOffset: 1716
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "car",
    type: "CONSUMER_GOOD",
    salience: 0.0011628179,
    mentions: [
    {
    text: {
    content: "car",
    beginOffset: 13123
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "W",
    type: "ORGANIZATION",
    salience: 0.0011524094,
    mentions: [
    {
    text: {
    content: "W",
    beginOffset: 747
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "mask",
    type: "OTHER",
    salience: 0.0011475205,
    mentions: [
    {
    text: {
    content: "mask",
    beginOffset: 9476
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hate",
    type: "OTHER",
    salience: 0.0011441708,
    mentions: [
    {
    text: {
    content: "hate",
    beginOffset: 22705
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "country",
    type: "LOCATION",
    salience: 0.0011125731,
    mentions: [
    {
    text: {
    content: "country",
    beginOffset: 6128
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everything",
    type: "OTHER",
    salience: 0.0011016183,
    mentions: [
    {
    text: {
    content: "everything",
    beginOffset: 6179
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "trump",
    type: "OTHER",
    salience: 0.0010989658,
    mentions: [
    {
    text: {
    content: "trump",
    beginOffset: 7442
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everything",
    type: "OTHER",
    salience: 0.0010957832,
    mentions: [
    {
    text: {
    content: "everything",
    beginOffset: 28642
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Russian",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Russia",
    mid: "/m/06bnz"
    },
    salience: 0.001088174,
    mentions: [
    {
    text: {
    content: "Russian",
    beginOffset: 2743
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Russian",
    beginOffset: 2946
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Russia",
    beginOffset: 3860
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Guinness Book of World Records",
    type: "WORK_OF_ART",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Guinness_World_Records",
    mid: "/m/0pmx5"
    },
    salience: 0.0010858979,
    mentions: [
    {
    text: {
    content: "Guinness Book of World Records",
    beginOffset: 1299
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "title",
    type: "OTHER",
    salience: 0.0010607718,
    mentions: [
    {
    text: {
    content: "title",
    beginOffset: 18562
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Alias the Dukes",
    type: "WORK_OF_ART",
    salience: 0.0010537393,
    mentions: [
    {
    text: {
    content: "Alias the Dukes",
    beginOffset: 3339
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Confederate",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Confederate_States_of_America",
    mid: "/m/020d5"
    },
    salience: 0.0010534108,
    mentions: [
    {
    text: {
    content: "Confederate",
    beginOffset: 3403
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Duke",
    type: "ORGANIZATION",
    salience: 0.0010530906,
    mentions: [
    {
    text: {
    content: "Duke",
    beginOffset: 3436
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Dukes AKA Cozy Bear",
    type: "ORGANIZATION",
    salience: 0.0010527781,
    mentions: [
    {
    text: {
    content: "Dukes AKA Cozy Bear",
    beginOffset: 3524
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "fiction",
    type: "OTHER",
    salience: 0.0010318356,
    mentions: [
    {
    text: {
    content: "truth",
    beginOffset: 31279
    },
    type: "COMMON"
    },
    {
    text: {
    content: "fiction",
    beginOffset: 31288
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.0010259786,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 8850
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "idea",
    type: "OTHER",
    salience: 0.0010118622,
    mentions: [
    {
    text: {
    content: "idea",
    beginOffset: 199
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "BL",
    type: "ORGANIZATION",
    salience: 0.0010071478,
    mentions: [
    {
    text: {
    content: "BL",
    beginOffset: 17472
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "election",
    type: "EVENT",
    salience: 0.0010013327,
    mentions: [
    {
    text: {
    content: "election",
    beginOffset: 7840
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "IV",
    type: "OTHER",
    salience: 0.0009927595,
    mentions: [
    {
    text: {
    content: "IV",
    beginOffset: 1857
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "goodnight",
    type: "WORK_OF_ART",
    salience: 0.000973407,
    mentions: [
    {
    text: {
    content: "goodnight",
    beginOffset: 31539
    },
    type: "COMMON"
    },
    {
    text: {
    content: "music",
    beginOffset: 31555
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "number",
    type: "OTHER",
    salience: 0.00097172364,
    mentions: [
    {
    text: {
    content: "number",
    beginOffset: 7558
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "problem",
    type: "OTHER",
    salience: 0.00096669194,
    mentions: [
    {
    text: {
    content: "problem",
    beginOffset: 4732
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Georgia Public Health",
    type: "ORGANIZATION",
    salience: 0.0009649374,
    mentions: [
    {
    text: {
    content: "Georgia Public Health",
    beginOffset: 2312
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "genocide",
    type: "OTHER",
    salience: 0.0009610194,
    mentions: [
    {
    text: {
    content: "genocide",
    beginOffset: 18961
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Rootin",
    type: "OTHER",
    salience: 0.00096041587,
    mentions: [
    {
    text: {
    content: "Rootin",
    beginOffset: 3473
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "case",
    type: "OTHER",
    salience: 0.0009601829,
    mentions: [
    {
    text: {
    content: "case",
    beginOffset: 7398
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "troops",
    type: "PERSON",
    salience: 0.00095988606,
    mentions: [
    {
    text: {
    content: "troops",
    beginOffset: 27430
    },
    type: "COMMON"
    },
    {
    text: {
    content: "troops",
    beginOffset: 27567
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "house",
    type: "LOCATION",
    salience: 0.0009593387,
    mentions: [
    {
    text: {
    content: "house",
    beginOffset: 5130
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "state",
    type: "LOCATION",
    salience: 0.0009475982,
    mentions: [
    {
    text: {
    content: "state",
    beginOffset: 8127
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "names",
    type: "OTHER",
    salience: 0.00094689394,
    mentions: [
    {
    text: {
    content: "names",
    beginOffset: 25368
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "All",
    type: "OTHER",
    salience: 0.00093575177,
    mentions: [
    {
    text: {
    content: "All",
    beginOffset: 14298
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.0009262323,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 5701
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.0009241548,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 6575
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "teachers",
    type: "PERSON",
    salience: 0.0009215844,
    mentions: [
    {
    text: {
    content: "teachers",
    beginOffset: 16004
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "John vetti",
    type: "PERSON",
    salience: 0.0009191246,
    mentions: [
    {
    text: {
    content: "John vetti",
    beginOffset: 11202
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Mr.",
    beginOffset: 11198
    },
    type: "COMMON"
    },
    {
    text: {
    content: "John",
    beginOffset: 11223
    },
    type: "PROPER"
    },
    {
    text: {
    content: "John",
    beginOffset: 11945
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "car",
    type: "CONSUMER_GOOD",
    salience: 0.0008985626,
    mentions: [
    {
    text: {
    content: "car",
    beginOffset: 5679
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "vaccine",
    type: "OTHER",
    salience: 0.00089777284,
    mentions: [
    {
    text: {
    content: "vaccine",
    beginOffset: 6760
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "country lot",
    type: "OTHER",
    salience: 0.00089757144,
    mentions: [
    {
    text: {
    content: "country lot",
    beginOffset: 6022
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Press",
    type: "ORGANIZATION",
    salience: 0.00089695735,
    mentions: [
    {
    text: {
    content: "Press",
    beginOffset: 6295
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "president",
    type: "PERSON",
    salience: 0.0008955583,
    mentions: [
    {
    text: {
    content: "president",
    beginOffset: 6834
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lot",
    type: "OTHER",
    salience: 0.00089352776,
    mentions: [
    {
    text: {
    content: "lot",
    beginOffset: 8367
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everything",
    type: "OTHER",
    salience: 0.0008931513,
    mentions: [
    {
    text: {
    content: "everything",
    beginOffset: 13974
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Sanjay Gupta",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Sanjay_Gupta",
    mid: "/m/02yyk6"
    },
    salience: 0.00086859526,
    mentions: [
    {
    text: {
    content: "Sanjay Gupta",
    beginOffset: 24113
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00086705596,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 27502
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "shades",
    type: "OTHER",
    salience: 0.00086181425,
    mentions: [
    {
    text: {
    content: "shades",
    beginOffset: 13661
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Huggers",
    type: "PERSON",
    salience: 0.00084863,
    mentions: [
    {
    text: {
    content: "Huggers",
    beginOffset: 2029
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Atlanta",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Atlanta",
    mid: "/m/013yq"
    },
    salience: 0.0008467265,
    mentions: [
    {
    text: {
    content: "Atlanta",
    beginOffset: 2445
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Knots",
    type: "OTHER",
    salience: 0.0008445423,
    mentions: [
    {
    text: {
    content: "Knots",
    beginOffset: 3232
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Putin",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Vladimir_Putin",
    mid: "/m/08193"
    },
    salience: 0.0008434752,
    mentions: [
    {
    text: {
    content: "Putin",
    beginOffset: 3487
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "DNC Network",
    type: "OTHER",
    salience: 0.00084322493,
    mentions: [
    {
    text: {
    content: "DNC Network",
    beginOffset: 3641
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Elan",
    type: "ORGANIZATION",
    salience: 0.00084250874,
    mentions: [
    {
    text: {
    content: "Elan",
    beginOffset: 4144
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Mike Bloomberg",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Michael_Bloomberg",
    mid: "/m/09pfj"
    },
    salience: 0.00084250874,
    mentions: [
    {
    text: {
    content: "Mike Bloomberg",
    beginOffset: 4129
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Kanye West",
    type: "PERSON",
    metadata: {
    mid: "/m/02l840",
    wikipedia_url: "https://en.wikipedia.org/wiki/Kanye_West"
    },
    salience: 0.00084250874,
    mentions: [
    {
    text: {
    content: "Kanye West",
    beginOffset: 4158
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "accounts",
    type: "OTHER",
    salience: 0.0008393529,
    mentions: [
    {
    text: {
    content: "accounts",
    beginOffset: 4603
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "account",
    type: "OTHER",
    salience: 0.00083893945,
    mentions: [
    {
    text: {
    content: "account",
    beginOffset: 4698
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "celebrity accounts",
    type: "OTHER",
    salience: 0.00083873887,
    mentions: [
    {
    text: {
    content: "celebrity accounts",
    beginOffset: 4771
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fact",
    type: "OTHER",
    salience: 0.00083743525,
    mentions: [
    {
    text: {
    content: "fact",
    beginOffset: 5300
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "election",
    type: "EVENT",
    salience: 0.00083743525,
    mentions: [
    {
    text: {
    content: "election",
    beginOffset: 5367
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "election",
    type: "EVENT",
    salience: 0.0008372619,
    mentions: [
    {
    text: {
    content: "election",
    beginOffset: 5491
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ones",
    type: "OTHER",
    salience: 0.0008370482,
    mentions: [
    {
    text: {
    content: "ones",
    beginOffset: 25197
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fact",
    type: "OTHER",
    salience: 0.00083582196,
    mentions: [
    {
    text: {
    content: "fact",
    beginOffset: 5948
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "some",
    type: "OTHER",
    salience: 0.0008320785,
    mentions: [
    {
    text: {
    content: "some",
    beginOffset: 19541
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tornado accounts",
    type: "OTHER",
    salience: 0.0008279001,
    mentions: [
    {
    text: {
    content: "tornado accounts",
    beginOffset: 5021
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.000811868,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 13057
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0008057326,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 10005
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Ben Folds",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Ben_Folds",
    mid: "/m/03f6fl0"
    },
    salience: 0.00080456183,
    mentions: [
    {
    text: {
    content: "Ben Folds",
    beginOffset: 11314
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Mr.",
    beginOffset: 11310
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "car",
    type: "CONSUMER_GOOD",
    salience: 0.0008038366,
    mentions: [
    {
    text: {
    content: "car",
    beginOffset: 12837
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "case",
    type: "OTHER",
    salience: 0.0008001463,
    mentions: [
    {
    text: {
    content: "case",
    beginOffset: 6428
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "coronavirus",
    type: "OTHER",
    salience: 0.0008001463,
    mentions: [
    {
    text: {
    content: "coronavirus",
    beginOffset: 6211
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "steps",
    type: "OTHER",
    salience: 0.0007983295,
    mentions: [
    {
    text: {
    content: "steps",
    beginOffset: 7336
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Someone",
    type: "PERSON",
    salience: 0.0007983295,
    mentions: [
    {
    text: {
    content: "Someone",
    beginOffset: 7296
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "case",
    type: "OTHER",
    salience: 0.00079821964,
    mentions: [
    {
    text: {
    content: "case",
    beginOffset: 7434
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "daughter",
    type: "PERSON",
    salience: 0.00079809496,
    mentions: [
    {
    text: {
    content: "daughter",
    beginOffset: 29835
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kids",
    type: "PERSON",
    salience: 0.0007918825,
    mentions: [
    {
    text: {
    content: "kids",
    beginOffset: 15529
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "supremacist",
    type: "OTHER",
    salience: 0.00078817,
    mentions: [
    {
    text: {
    content: "supremacist",
    beginOffset: 19202
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00076836726,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 27594
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "think",
    type: "OTHER",
    salience: 0.00076627504,
    mentions: [
    {
    text: {
    content: "think",
    beginOffset: 14822
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hackers",
    type: "PERSON",
    salience: 0.00076373597,
    mentions: [
    {
    text: {
    content: "hackers",
    beginOffset: 5267
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "thing",
    type: "OTHER",
    salience: 0.00076040806,
    mentions: [
    {
    text: {
    content: "thing",
    beginOffset: 10918
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "part",
    type: "OTHER",
    salience: 0.0007520885,
    mentions: [
    {
    text: {
    content: "part",
    beginOffset: 8160
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kids",
    type: "PERSON",
    salience: 0.0007445077,
    mentions: [
    {
    text: {
    content: "kids",
    beginOffset: 15617
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Everything",
    type: "OTHER",
    salience: 0.0007434671,
    mentions: [
    {
    text: {
    content: "Everything",
    beginOffset: 13134
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everybody",
    type: "PERSON",
    salience: 0.00074231904,
    mentions: [
    {
    text: {
    content: "everybody",
    beginOffset: 14237
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everybody",
    type: "PERSON",
    salience: 0.00074165413,
    mentions: [
    {
    text: {
    content: "everybody",
    beginOffset: 15601
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0007411456,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 19834
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "country",
    type: "LOCATION",
    salience: 0.0007382659,
    mentions: [
    {
    text: {
    content: "country",
    beginOffset: 18785
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everyone",
    type: "PERSON",
    salience: 0.00073759264,
    mentions: [
    {
    text: {
    content: "everyone",
    beginOffset: 10402
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everybody",
    type: "PERSON",
    salience: 0.0007362432,
    mentions: [
    {
    text: {
    content: "everybody",
    beginOffset: 11101
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everybody",
    type: "PERSON",
    salience: 0.00073486374,
    mentions: [
    {
    text: {
    content: "everybody",
    beginOffset: 11923
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Ivanka Trump",
    type: "PERSON",
    metadata: {
    mid: "/m/061wfm",
    wikipedia_url: "https://en.wikipedia.org/wiki/Ivanka_Trump"
    },
    salience: 0.00072945456,
    mentions: [
    {
    text: {
    content: "president",
    beginOffset: 29863
    },
    type: "COMMON"
    },
    {
    text: {
    content: "Ivanka Trump",
    beginOffset: 29873
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Ivanka",
    beginOffset: 29962
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "sister",
    type: "PERSON",
    salience: 0.0007222968,
    mentions: [
    {
    text: {
    content: "sister",
    beginOffset: 5161
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "parts",
    type: "OTHER",
    salience: 0.0007213751,
    mentions: [
    {
    text: {
    content: "parts",
    beginOffset: 4866
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "poll",
    type: "OTHER",
    salience: 0.0007170872,
    mentions: [
    {
    text: {
    content: "poll",
    beginOffset: 7172
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "poll",
    type: "OTHER",
    salience: 0.0007166858,
    mentions: [
    {
    text: {
    content: "poll",
    beginOffset: 7491
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "polls",
    type: "OTHER",
    salience: 0.000715845,
    mentions: [
    {
    text: {
    content: "polls",
    beginOffset: 8264
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "all",
    type: "OTHER",
    salience: 0.00071184186,
    mentions: [
    {
    text: {
    content: "all",
    beginOffset: 18545
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.0007105976,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 10982
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "slack guys",
    type: "PERSON",
    salience: 0.00070207886,
    mentions: [
    {
    text: {
    content: "slack guys",
    beginOffset: 10609
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.00069344183,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 16880
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "service",
    type: "OTHER",
    salience: 0.00069240056,
    mentions: [
    {
    text: {
    content: "service",
    beginOffset: 11027
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "initials",
    type: "OTHER",
    salience: 0.0006912219,
    mentions: [
    {
    text: {
    content: "initials",
    beginOffset: 25223
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "look",
    type: "OTHER",
    salience: 0.00068764715,
    mentions: [
    {
    text: {
    content: "look",
    beginOffset: 19113
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "motto",
    type: "OTHER",
    salience: 0.0006858372,
    mentions: [
    {
    text: {
    content: "motto",
    beginOffset: 6012
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "some",
    type: "OTHER",
    salience: 0.00068493764,
    mentions: [
    {
    text: {
    content: "some",
    beginOffset: 15544
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "shape",
    type: "OTHER",
    salience: 0.0006835325,
    mentions: [
    {
    text: {
    content: "shape",
    beginOffset: 7660
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "two",
    type: "PERSON",
    salience: 0.0006763715,
    mentions: [
    {
    text: {
    content: "two",
    beginOffset: 15340
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "time",
    type: "OTHER",
    salience: 0.00066995114,
    mentions: [
    {
    text: {
    content: "time",
    beginOffset: 9305
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hands",
    type: "OTHER",
    salience: 0.00066994043,
    mentions: [
    {
    text: {
    content: "hands",
    beginOffset: 5782
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "opposition research",
    type: "OTHER",
    salience: 0.0006688713,
    mentions: [
    {
    text: {
    content: "opposition research",
    beginOffset: 6308
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "times",
    type: "OTHER",
    salience: 0.0006685083,
    mentions: [
    {
    text: {
    content: "times",
    beginOffset: 10652
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Vaccine",
    type: "OTHER",
    salience: 0.0006681271,
    mentions: [
    {
    text: {
    content: "Vaccine",
    beginOffset: 6730
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cover",
    type: "OTHER",
    salience: 0.0006677306,
    mentions: [
    {
    text: {
    content: "cover",
    beginOffset: 6919
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "nothing",
    type: "OTHER",
    salience: 0.0006675393,
    mentions: [
    {
    text: {
    content: "nothing",
    beginOffset: 7265
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "face coverings",
    type: "OTHER",
    salience: 0.00066708005,
    mentions: [
    {
    text: {
    content: "face coverings",
    beginOffset: 7720
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "audience",
    type: "PERSON",
    salience: 0.00066664536,
    mentions: [
    {
    text: {
    content: "audience",
    beginOffset: 7980
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hands",
    type: "OTHER",
    salience: 0.0006665612,
    mentions: [
    {
    text: {
    content: "hands",
    beginOffset: 8006
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "idea",
    type: "OTHER",
    salience: 0.0006665612,
    mentions: [
    {
    text: {
    content: "idea",
    beginOffset: 8033
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Racism",
    type: "OTHER",
    salience: 0.00066626444,
    mentions: [
    {
    text: {
    content: "Racism",
    beginOffset: 12046
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "EVENT",
    salience: 0.00065805315,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 13711
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lot",
    type: "OTHER",
    salience: 0.0006579535,
    mentions: [
    {
    text: {
    content: "lot",
    beginOffset: 16335
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Ivy",
    type: "ORGANIZATION",
    salience: 0.0006558996,
    mentions: [
    {
    text: {
    content: "Ivy",
    beginOffset: 1757
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "supremacy",
    type: "OTHER",
    salience: 0.0006554608,
    mentions: [
    {
    text: {
    content: "supremacy",
    beginOffset: 18467
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "white supremacy",
    type: "OTHER",
    salience: 0.0006554608,
    mentions: [
    {
    text: {
    content: "white supremacy",
    beginOffset: 18519
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "supremacy",
    type: "OTHER",
    salience: 0.00065530825,
    mentions: [
    {
    text: {
    content: "supremacy",
    beginOffset: 18746
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "supremacy",
    type: "OTHER",
    salience: 0.0006552181,
    mentions: [
    {
    text: {
    content: "supremacy",
    beginOffset: 19173
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "supremacist",
    type: "OTHER",
    salience: 0.0006552181,
    mentions: [
    {
    text: {
    content: "supremacist",
    beginOffset: 19151
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "white supremacy",
    type: "OTHER",
    salience: 0.0006550407,
    mentions: [
    {
    text: {
    content: "white supremacy",
    beginOffset: 19562
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Ted Cruz",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Ted_Cruz",
    mid: "/m/07j6ty"
    },
    salience: 0.0006543149,
    mentions: [
    {
    text: {
    content: "Ted Cruz",
    beginOffset: 25991
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Senator",
    beginOffset: 25983
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Barack Obama",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Barack_Obama",
    mid: "/m/02mjmr"
    },
    salience: 0.0006539945,
    mentions: [
    {
    text: {
    content: "Barack Obama",
    beginOffset: 4106
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Obama",
    beginOffset: 4361
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "thing",
    type: "OTHER",
    salience: 0.00064951606,
    mentions: [
    {
    text: {
    content: "thing",
    beginOffset: 25131
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "component",
    type: "OTHER",
    salience: 0.0006439158,
    mentions: [
    {
    text: {
    content: "component",
    beginOffset: 6608
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "some",
    type: "OTHER",
    salience: 0.00064358645,
    mentions: [
    {
    text: {
    content: "some",
    beginOffset: 16597
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "single",
    type: "WORK_OF_ART",
    salience: 0.00064099976,
    mentions: [
    {
    text: {
    content: "single",
    beginOffset: 28760
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "state",
    type: "LOCATION",
    salience: 0.00064066617,
    mentions: [
    {
    text: {
    content: "state",
    beginOffset: 14405
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "life",
    type: "OTHER",
    salience: 0.0006372048,
    mentions: [
    {
    text: {
    content: "life",
    beginOffset: 9391
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "books",
    type: "WORK_OF_ART",
    salience: 0.00062359235,
    mentions: [
    {
    text: {
    content: "books",
    beginOffset: 17972
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "governor",
    type: "PERSON",
    salience: 0.0006230612,
    mentions: [
    {
    text: {
    content: "governor",
    beginOffset: 14427
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "school",
    type: "ORGANIZATION",
    salience: 0.0006225082,
    mentions: [
    {
    text: {
    content: "school",
    beginOffset: 15721
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "school",
    type: "ORGANIZATION",
    salience: 0.0006224088,
    mentions: [
    {
    text: {
    content: "school",
    beginOffset: 16020
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "states",
    type: "LOCATION",
    salience: 0.00062152796,
    mentions: [
    {
    text: {
    content: "states",
    beginOffset: 9729
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "country",
    type: "LOCATION",
    salience: 0.0006194625,
    mentions: [
    {
    text: {
    content: "country",
    beginOffset: 9793
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "schools",
    type: "ORGANIZATION",
    salience: 0.0006194625,
    mentions: [
    {
    text: {
    content: "schools",
    beginOffset: 9873
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.0006184446,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 10825
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fire",
    type: "OTHER",
    salience: 0.00061828597,
    mentions: [
    {
    text: {
    content: "fire",
    beginOffset: 13225
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.0006160655,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 18156
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.000615554,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 19611
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "test drive",
    type: "OTHER",
    salience: 0.0006115826,
    mentions: [
    {
    text: {
    content: "test drive",
    beginOffset: 5516
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "movies",
    type: "WORK_OF_ART",
    salience: 0.00061145815,
    mentions: [
    {
    text: {
    content: "movies",
    beginOffset: 5559
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "discontent",
    type: "OTHER",
    salience: 0.000610752,
    mentions: [
    {
    text: {
    content: "discontent",
    beginOffset: 5889
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "crisis",
    type: "OTHER",
    salience: 0.00061010715,
    mentions: [
    {
    text: {
    content: "crisis",
    beginOffset: 6142
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cover model",
    type: "OTHER",
    salience: 0.0006100049,
    mentions: [
    {
    text: {
    content: "cover model",
    beginOffset: 6451
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.00060923054,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 23156
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.0006070414,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 24967
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.00060701877,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 25103
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "car",
    type: "CONSUMER_GOOD",
    salience: 0.00060541666,
    mentions: [
    {
    text: {
    content: "car",
    beginOffset: 13072
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "car",
    type: "CONSUMER_GOOD",
    salience: 0.00060541666,
    mentions: [
    {
    text: {
    content: "car",
    beginOffset: 13027
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "car",
    type: "CONSUMER_GOOD",
    salience: 0.0006052178,
    mentions: [
    {
    text: {
    content: "car",
    beginOffset: 13475
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "much",
    type: "OTHER",
    salience: 0.0006043895,
    mentions: [
    {
    text: {
    content: "much",
    beginOffset: 9781
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "pressure",
    type: "OTHER",
    salience: 0.0006043318,
    mentions: [
    {
    text: {
    content: "pressure",
    beginOffset: 14475
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kids",
    type: "PERSON",
    salience: 0.00060402526,
    mentions: [
    {
    text: {
    content: "kids",
    beginOffset: 15214
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kids",
    type: "PERSON",
    salience: 0.00060392584,
    mentions: [
    {
    text: {
    content: "kids",
    beginOffset: 15418
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.00060230953,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 16275
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "many",
    type: "PERSON",
    salience: 0.0006021484,
    mentions: [
    {
    text: {
    content: "many",
    beginOffset: 9978
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.00060191023,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 17568
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "EVENT",
    salience: 0.00060130795,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 19100
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.0006012258,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 19403
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lot",
    type: "OTHER",
    salience: 0.0005987161,
    mentions: [
    {
    text: {
    content: "lot",
    beginOffset: 11565
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lot",
    type: "OTHER",
    salience: 0.0005969341,
    mentions: [
    {
    text: {
    content: "lot",
    beginOffset: 14362
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "look",
    type: "OTHER",
    salience: 0.0005887997,
    mentions: [
    {
    text: {
    content: "look",
    beginOffset: 12636
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "protesters",
    type: "PERSON",
    salience: 0.0005854883,
    mentions: [
    {
    text: {
    content: "protesters",
    beginOffset: 12683
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "protests",
    type: "EVENT",
    salience: 0.0005854883,
    mentions: [
    {
    text: {
    content: "protests",
    beginOffset: 12415
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "move",
    type: "OTHER",
    salience: 0.00058215286,
    mentions: [
    {
    text: {
    content: "move",
    beginOffset: 14882
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hold",
    type: "OTHER",
    salience: 0.0005807572,
    mentions: [
    {
    text: {
    content: "hold",
    beginOffset: 13783
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "breach",
    type: "EVENT",
    salience: 0.0005797523,
    mentions: [
    {
    text: {
    content: "breach",
    beginOffset: 4507
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "story",
    type: "WORK_OF_ART",
    salience: 0.0005788333,
    mentions: [
    {
    text: {
    content: "story",
    beginOffset: 10125
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "protests",
    type: "EVENT",
    salience: 0.0005783607,
    mentions: [
    {
    text: {
    content: "protests",
    beginOffset: 12029
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.0005775018,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 25873
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.0005760926,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 23418
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.0005709649,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 16189
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sense",
    type: "OTHER",
    salience: 0.0005701068,
    mentions: [
    {
    text: {
    content: "sense",
    beginOffset: 5461
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Anthem",
    type: "EVENT",
    salience: 0.0005676543,
    mentions: [
    {
    text: {
    content: "Anthem",
    beginOffset: 28880
    },
    type: "PROPER"
    },
    {
    text: {
    content: "protest",
    beginOffset: 28872
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "one",
    type: "OTHER",
    salience: 0.0005667138,
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 26852
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "protests",
    type: "EVENT",
    salience: 0.00056424923,
    mentions: [
    {
    text: {
    content: "protests",
    beginOffset: 12155
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fact",
    type: "OTHER",
    salience: 0.00056209473,
    mentions: [
    {
    text: {
    content: "fact",
    beginOffset: 15864
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fact",
    type: "OTHER",
    salience: 0.0005605317,
    mentions: [
    {
    text: {
    content: "fact",
    beginOffset: 8718
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "song",
    type: "WORK_OF_ART",
    salience: 0.0005578472,
    mentions: [
    {
    text: {
    content: "song",
    beginOffset: 11284
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "songs",
    type: "WORK_OF_ART",
    salience: 0.0005573042,
    mentions: [
    {
    text: {
    content: "songs",
    beginOffset: 11622
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "song",
    type: "WORK_OF_ART",
    salience: 0.0005571455,
    mentions: [
    {
    text: {
    content: "song",
    beginOffset: 11859
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "song",
    type: "WORK_OF_ART",
    salience: 0.0005571064,
    mentions: [
    {
    text: {
    content: "song",
    beginOffset: 11870
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "old-school",
    type: "OTHER",
    salience: 0.0005568696,
    mentions: [
    {
    text: {
    content: "old-school",
    beginOffset: 16791
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00055677874,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 25641
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kids",
    type: "PERSON",
    salience: 0.0005537722,
    mentions: [
    {
    text: {
    content: "kids",
    beginOffset: 16288
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kids",
    type: "PERSON",
    salience: 0.0005537432,
    mentions: [
    {
    text: {
    content: "kids",
    beginOffset: 16350
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "presidents",
    type: "PERSON",
    salience: 0.00055282604,
    mentions: [
    {
    text: {
    content: "presidents",
    beginOffset: 19288
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "presidents",
    type: "PERSON",
    salience: 0.00055282604,
    mentions: [
    {
    text: {
    content: "presidents",
    beginOffset: 19299
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "scam",
    type: "OTHER",
    salience: 0.0005454112,
    mentions: [
    {
    text: {
    content: "scam",
    beginOffset: 4445
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "problem",
    type: "OTHER",
    salience: 0.00054021453,
    mentions: [
    {
    text: {
    content: "problem",
    beginOffset: 12569
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "man",
    type: "PERSON",
    salience: 0.000539685,
    mentions: [
    {
    text: {
    content: "man",
    beginOffset: 13736
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "address",
    type: "LOCATION",
    salience: 0.00053872954,
    mentions: [
    {
    text: {
    content: "address",
    beginOffset: 4261
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "money",
    type: "OTHER",
    salience: 0.00053872954,
    mentions: [
    {
    text: {
    content: "money",
    beginOffset: 4397
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "users ability",
    type: "OTHER",
    salience: 0.0005384531,
    mentions: [
    {
    text: {
    content: "users ability",
    beginOffset: 4563
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "result",
    type: "OTHER",
    salience: 0.00053805916,
    mentions: [
    {
    text: {
    content: "result",
    beginOffset: 4795
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tornado watch",
    type: "CONSUMER_GOOD",
    salience: 0.00053793297,
    mentions: [
    {
    text: {
    content: "tornado watch",
    beginOffset: 4848
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "agencies",
    type: "ORGANIZATION",
    salience: 0.0005378091,
    mentions: [
    {
    text: {
    content: "agencies",
    beginOffset: 4898
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "danger",
    type: "OTHER",
    salience: 0.0005378091,
    mentions: [
    {
    text: {
    content: "danger",
    beginOffset: 4977
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "residents",
    type: "PERSON",
    salience: 0.0005378091,
    mentions: [
    {
    text: {
    content: "residents",
    beginOffset: 4960
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sound",
    type: "OTHER",
    salience: 0.00053756824,
    mentions: [
    {
    text: {
    content: "sound",
    beginOffset: 5073
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "witch",
    type: "OTHER",
    salience: 0.00053745107,
    mentions: [
    {
    text: {
    content: "witch",
    beginOffset: 5151
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "someone",
    type: "PERSON",
    salience: 0.00053725584,
    mentions: [
    {
    text: {
    content: "someone",
    beginOffset: 8577
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "identity",
    type: "OTHER",
    salience: 0.00053722283,
    mentions: [
    {
    text: {
    content: "identity",
    beginOffset: 5251
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "someone",
    type: "PERSON",
    salience: 0.0005370061,
    mentions: [
    {
    text: {
    content: "someone",
    beginOffset: 8837
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ice floe",
    type: "OTHER",
    salience: 0.0005368948,
    mentions: [
    {
    text: {
    content: "ice floe",
    beginOffset: 5627
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "submarine",
    type: "OTHER",
    salience: 0.0005368948,
    mentions: [
    {
    text: {
    content: "submarine",
    beginOffset: 5597
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "guys",
    type: "PERSON",
    salience: 0.00053614663,
    mentions: [
    {
    text: {
    content: "guys",
    beginOffset: 9638
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "laughter",
    type: "OTHER",
    salience: 0.00053609285,
    mentions: [
    {
    text: {
    content: "laughter",
    beginOffset: 6037
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "season",
    type: "EVENT",
    salience: 0.0005359875,
    mentions: [
    {
    text: {
    content: "season",
    beginOffset: 10098
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "school season",
    type: "EVENT",
    salience: 0.0005359875,
    mentions: [
    {
    text: {
    content: "school season",
    beginOffset: 9809
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cronies",
    type: "PERSON",
    salience: 0.000535726,
    mentions: [
    {
    text: {
    content: "cronies",
    beginOffset: 6260
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "someone",
    type: "PERSON",
    salience: 0.0005356805,
    mentions: [
    {
    text: {
    content: "someone",
    beginOffset: 10454
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "spot",
    type: "OTHER",
    salience: 0.00053546386,
    mentions: [
    {
    text: {
    content: "spot",
    beginOffset: 6535
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "doctors",
    type: "PERSON",
    salience: 0.00053529476,
    mentions: [
    {
    text: {
    content: "doctors",
    beginOffset: 6630
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "outfit",
    type: "OTHER",
    salience: 0.00053529476,
    mentions: [
    {
    text: {
    content: "outfit",
    beginOffset: 6638
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "shot",
    type: "OTHER",
    salience: 0.0005349692,
    mentions: [
    {
    text: {
    content: "shot",
    beginOffset: 6804
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "plague",
    type: "EVENT",
    salience: 0.00053481234,
    mentions: [
    {
    text: {
    content: "plague",
    beginOffset: 6881
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "all",
    type: "OTHER",
    salience: 0.00053477887,
    mentions: [
    {
    text: {
    content: "all",
    beginOffset: 24867
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "advantage",
    type: "OTHER",
    salience: 0.0005347353,
    mentions: [
    {
    text: {
    content: "advantage",
    beginOffset: 7203
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "basement",
    type: "LOCATION",
    salience: 0.00053458393,
    mentions: [
    {
    text: {
    content: "basement",
    beginOffset: 7286
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "door",
    type: "OTHER",
    salience: 0.00053450954,
    mentions: [
    {
    text: {
    content: "door",
    beginOffset: 7370
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "knob",
    type: "OTHER",
    salience: 0.00053450954,
    mentions: [
    {
    text: {
    content: "knob",
    beginOffset: 7357
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "crate",
    type: "OTHER",
    salience: 0.00053450954,
    mentions: [
    {
    text: {
    content: "crate",
    beginOffset: 7311
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "track",
    type: "OTHER",
    salience: 0.00053436327,
    mentions: [
    {
    text: {
    content: "track",
    beginOffset: 7529
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "germaphobe",
    type: "OTHER",
    salience: 0.0005342913,
    mentions: [
    {
    text: {
    content: "germaphobe",
    beginOffset: 7691
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fetish",
    type: "OTHER",
    salience: 0.0005342913,
    mentions: [
    {
    text: {
    content: "fetish",
    beginOffset: 7709
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "jar",
    type: "CONSUMER_GOOD",
    salience: 0.0005342202,
    mentions: [
    {
    text: {
    content: "jar",
    beginOffset: 7796
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "urine",
    type: "OTHER",
    salience: 0.0005342202,
    mentions: [
    {
    text: {
    content: "urine",
    beginOffset: 7785
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "union",
    type: "ORGANIZATION",
    salience: 0.0005338757,
    mentions: [
    {
    text: {
    content: "union",
    beginOffset: 8140
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "stock",
    type: "OTHER",
    salience: 0.00053380895,
    mentions: [
    {
    text: {
    content: "stock",
    beginOffset: 8251
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "course",
    type: "OTHER",
    salience: 0.00053380895,
    mentions: [
    {
    text: {
    content: "course",
    beginOffset: 8217
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "conversation",
    type: "OTHER",
    salience: 0.0005329006,
    mentions: [
    {
    text: {
    content: "conversation",
    beginOffset: 16932
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "rock redirector torpedo",
    type: "OTHER",
    salience: 0.00053001393,
    mentions: [
    {
    text: {
    content: "rock redirector torpedo",
    beginOffset: 5744
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "country thousands",
    type: "PERSON",
    salience: 0.00052985654,
    mentions: [
    {
    text: {
    content: "country thousands",
    beginOffset: 12662
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.000526812,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 25178
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.0005264315,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 26389
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "business",
    type: "OTHER",
    salience: 0.00052260247,
    mentions: [
    {
    text: {
    content: "business",
    beginOffset: 10329
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "novelty socks",
    type: "CONSUMER_GOOD",
    salience: 0.00052235153,
    mentions: [
    {
    text: {
    content: "novelty socks",
    beginOffset: 6550
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "stick",
    type: "CONSUMER_GOOD",
    salience: 0.0005215898,
    mentions: [
    {
    text: {
    content: "stick",
    beginOffset: 11083
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "message",
    type: "OTHER",
    salience: 0.0005215898,
    mentions: [
    {
    text: {
    content: "message",
    beginOffset: 11071
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "white house",
    type: "LOCATION",
    salience: 0.00051552727,
    mentions: [
    {
    text: {
    content: "white house",
    beginOffset: 16497
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "adults",
    type: "PERSON",
    salience: 0.00051517034,
    mentions: [
    {
    text: {
    content: "adults",
    beginOffset: 16695
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "house",
    type: "LOCATION",
    salience: 0.00051516294,
    mentions: [
    {
    text: {
    content: "house",
    beginOffset: 17801
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fact",
    type: "OTHER",
    salience: 0.000514771,
    mentions: [
    {
    text: {
    content: "fact",
    beginOffset: 18695
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "customer car fire",
    type: "EVENT",
    salience: 0.0005139542,
    mentions: [
    {
    text: {
    content: "customer car fire",
    beginOffset: 13333
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "shades",
    type: "OTHER",
    salience: 0.000513854,
    mentions: [
    {
    text: {
    content: "shades",
    beginOffset: 13522
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Twitter",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://de.wikipedia.org/wiki/Twitter_Inc.",
    mid: "/m/0hn1vcg"
    },
    salience: 0.00051292364,
    mentions: [
    {
    text: {
    content: "Twitter",
    beginOffset: 5827
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "school districts",
    type: "ORGANIZATION",
    salience: 0.00051279017,
    mentions: [
    {
    text: {
    content: "school districts",
    beginOffset: 15501
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fire",
    type: "OTHER",
    salience: 0.00051074533,
    mentions: [
    {
    text: {
    content: "fire",
    beginOffset: 9286
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Game numbers",
    type: "PERSON",
    salience: 0.0005105834,
    mentions: [
    {
    text: {
    content: "Game numbers",
    beginOffset: 9397
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kind",
    type: "OTHER",
    salience: 0.0005105663,
    mentions: [
    {
    text: {
    content: "kind",
    beginOffset: 12969
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "case number",
    type: "OTHER",
    salience: 0.000510171,
    mentions: [
    {
    text: {
    content: "case number",
    beginOffset: 9752
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "distance",
    type: "OTHER",
    salience: 0.0005098788,
    mentions: [
    {
    text: {
    content: "distance",
    beginOffset: 10478
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cats",
    type: "OTHER",
    salience: 0.00050638115,
    mentions: [
    {
    text: {
    content: "cats",
    beginOffset: 27331
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Everybody",
    type: "PERSON",
    salience: 0.00050265307,
    mentions: [
    {
    text: {
    content: "Everybody",
    beginOffset: 31297
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Thanks",
    type: "OTHER",
    salience: 0.0004961773,
    mentions: [
    {
    text: {
    content: "Thanks",
    beginOffset: 23570
    },
    type: "COMMON"
    },
    {
    text: {
    content: "Thanks",
    beginOffset: 23826
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "army",
    type: "ORGANIZATION",
    salience: 0.00049473904,
    mentions: [
    {
    text: {
    content: "army",
    beginOffset: 31107
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "world",
    type: "LOCATION",
    salience: 0.0004937203,
    mentions: [
    {
    text: {
    content: "world",
    beginOffset: 17417
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "season",
    type: "EVENT",
    salience: 0.00049367105,
    mentions: [
    {
    text: {
    content: "season",
    beginOffset: 17623
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "history books",
    type: "WORK_OF_ART",
    salience: 0.00049345434,
    mentions: [
    {
    text: {
    content: "history books",
    beginOffset: 18078
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "something",
    type: "OTHER",
    salience: 0.00049331406,
    mentions: [
    {
    text: {
    content: "something",
    beginOffset: 18622
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "history",
    type: "OTHER",
    salience: 0.0004932224,
    mentions: [
    {
    text: {
    content: "history",
    beginOffset: 18771
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.000492472,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 24449
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.0004924161,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 24671
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.00049230584,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 25273
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "news",
    type: "OTHER",
    salience: 0.0004906633,
    mentions: [
    {
    text: {
    content: "news",
    beginOffset: 16303
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "problems",
    type: "OTHER",
    salience: 0.0004875796,
    mentions: [
    {
    text: {
    content: "problems",
    beginOffset: 17762
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "word history",
    type: "OTHER",
    salience: 0.0004875556,
    mentions: [
    {
    text: {
    content: "word history",
    beginOffset: 17835
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "protest footage",
    type: "WORK_OF_ART",
    salience: 0.0004849664,
    mentions: [
    {
    text: {
    content: "protest footage",
    beginOffset: 12899
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "thanks",
    type: "OTHER",
    salience: 0.00048455663,
    mentions: [
    {
    text: {
    content: "thanks",
    beginOffset: 13741
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "man gas",
    type: "OTHER",
    salience: 0.00048394955,
    mentions: [
    {
    text: {
    content: "man gas",
    beginOffset: 14898
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "football",
    type: "OTHER",
    salience: 0.0004820424,
    mentions: [
    {
    text: {
    content: "football",
    beginOffset: 9010
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Scale",
    type: "OTHER",
    salience: 0.00048198865,
    mentions: [
    {
    text: {
    content: "Scale",
    beginOffset: 9029
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "footage",
    type: "WORK_OF_ART",
    salience: 0.00048162576,
    mentions: [
    {
    text: {
    content: "footage",
    beginOffset: 9445
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "scale",
    type: "OTHER",
    salience: 0.00048152605,
    mentions: [
    {
    text: {
    content: "scale",
    beginOffset: 9512
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "goodness",
    type: "OTHER",
    salience: 0.00047932737,
    mentions: [
    {
    text: {
    content: "goodness",
    beginOffset: 11740
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "movement",
    type: "OTHER",
    salience: 0.00047912728,
    mentions: [
    {
    text: {
    content: "movement",
    beginOffset: 12119
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "footage",
    type: "WORK_OF_ART",
    salience: 0.00047583014,
    mentions: [
    {
    text: {
    content: "footage",
    beginOffset: 9335
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "guys",
    type: "PERSON",
    salience: 0.00047519992,
    mentions: [
    {
    text: {
    content: "guys",
    beginOffset: 19443
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hammer",
    type: "CONSUMER_GOOD",
    salience: 0.00047333675,
    mentions: [
    {
    text: {
    content: "hammer",
    beginOffset: 14190
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "conversation",
    type: "EVENT",
    salience: 0.00046993943,
    mentions: [
    {
    text: {
    content: "conversation",
    beginOffset: 17445
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "conversation",
    type: "EVENT",
    salience: 0.00046989258,
    mentions: [
    {
    text: {
    content: "conversation",
    beginOffset: 17529
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "shades",
    type: "OTHER",
    salience: 0.00046989258,
    mentions: [
    {
    text: {
    content: "shades",
    beginOffset: 17580
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "episode",
    type: "WORK_OF_ART",
    salience: 0.00046959697,
    mentions: [
    {
    text: {
    content: "episode",
    beginOffset: 18360
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "conversation",
    type: "OTHER",
    salience: 0.00046421,
    mentions: [
    {
    text: {
    content: "conversation",
    beginOffset: 17255
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "conversation",
    type: "OTHER",
    salience: 0.0004641867,
    mentions: [
    {
    text: {
    content: "conversation",
    beginOffset: 17333
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "In Harm's Way With The Invasion",
    type: "WORK_OF_ART",
    salience: 0.0004592659,
    mentions: [
    {
    text: {
    content: "In Harm's Way With The Invasion",
    beginOffset: 27437
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "cracker",
    type: "OTHER",
    salience: 0.0004565357,
    mentions: [
    {
    text: {
    content: "cracker",
    beginOffset: 21557
    },
    type: "COMMON"
    },
    {
    text: {
    content: "snack",
    beginOffset: 21586
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "man",
    type: "PERSON",
    salience: 0.0004530294,
    mentions: [
    {
    text: {
    content: "man",
    beginOffset: 21707
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "episodes title",
    type: "OTHER",
    salience: 0.0004524186,
    mentions: [
    {
    text: {
    content: "episodes title",
    beginOffset: 18415
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "media",
    type: "OTHER",
    salience: 0.00045158117,
    mentions: [
    {
    text: {
    content: "media",
    beginOffset: 12592
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "media",
    type: "OTHER",
    salience: 0.00045158117,
    mentions: [
    {
    text: {
    content: "media",
    beginOffset: 12387
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "reason",
    type: "OTHER",
    salience: 0.00045155053,
    mentions: [
    {
    text: {
    content: "reason",
    beginOffset: 12740
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "demonstration",
    type: "EVENT",
    salience: 0.00045152006,
    mentions: [
    {
    text: {
    content: "demonstration",
    beginOffset: 12986
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "applause applause",
    type: "OTHER",
    salience: 0.00045105416,
    mentions: [
    {
    text: {
    content: "applause applause",
    beginOffset: 13796
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "reason",
    type: "OTHER",
    salience: 0.00045049714,
    mentions: [
    {
    text: {
    content: "reason",
    beginOffset: 15110
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "reason",
    type: "OTHER",
    salience: 0.00045044694,
    mentions: [
    {
    text: {
    content: "reason",
    beginOffset: 15170
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "interim",
    type: "OTHER",
    salience: 0.00045015494,
    mentions: [
    {
    text: {
    content: "interim",
    beginOffset: 16114
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "rest",
    type: "OTHER",
    salience: 0.00044905493,
    mentions: [
    {
    text: {
    content: "rest",
    beginOffset: 8637
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cylinders data",
    type: "OTHER",
    salience: 0.0004486003,
    mentions: [
    {
    text: {
    content: "cylinders data",
    beginOffset: 9189
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "nation",
    type: "LOCATION",
    salience: 0.00044804762,
    mentions: [
    {
    text: {
    content: "nation",
    beginOffset: 9990
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "matter",
    type: "OTHER",
    salience: 0.0004473884,
    mentions: [
    {
    text: {
    content: "matter",
    beginOffset: 10788
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "friend",
    type: "PERSON",
    salience: 0.0004469416,
    mentions: [
    {
    text: {
    content: "friend",
    beginOffset: 11190
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "friend",
    type: "PERSON",
    salience: 0.00044680043,
    mentions: [
    {
    text: {
    content: "friend",
    beginOffset: 11294
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mind",
    type: "OTHER",
    salience: 0.0004463655,
    mentions: [
    {
    text: {
    content: "mind",
    beginOffset: 11636
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "police brutality",
    type: "OTHER",
    salience: 0.0004460836,
    mentions: [
    {
    text: {
    content: "police brutality",
    beginOffset: 12057
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "video filter",
    type: "CONSUMER_GOOD",
    salience: 0.0004458729,
    mentions: [
    {
    text: {
    content: "video filter",
    beginOffset: 12862
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "America W. Kamau Bell",
    type: "PERSON",
    salience: 0.00044482778,
    mentions: [
    {
    text: {
    content: "kamau Bell",
    beginOffset: 10963
    },
    type: "PROPER"
    },
    {
    text: {
    content: "America W. Kamau Bell",
    beginOffset: 13532
    },
    type: "PROPER"
    },
    {
    text: {
    content: "kamau Bell",
    beginOffset: 18479
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "system",
    type: "OTHER",
    salience: 0.00044300564,
    mentions: [
    {
    text: {
    content: "system",
    beginOffset: 18289
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "W",
    type: "OTHER",
    salience: 0.0004429428,
    mentions: [
    {
    text: {
    content: "W",
    beginOffset: 18477
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "gun range",
    type: "OTHER",
    salience: 0.00044268358,
    mentions: [
    {
    text: {
    content: "gun range",
    beginOffset: 30822
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "order",
    type: "OTHER",
    salience: 0.0004426589,
    mentions: [
    {
    text: {
    content: "order",
    beginOffset: 19745
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sense",
    type: "OTHER",
    salience: 0.0004426589,
    mentions: [
    {
    text: {
    content: "sense",
    beginOffset: 19648
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "George Floyd",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/George_Floyd",
    mid: "/m/02qxc9m"
    },
    salience: 0.0004408764,
    mentions: [
    {
    text: {
    content: "George Floyd",
    beginOffset: 12098
    },
    type: "PROPER"
    },
    {
    text: {
    content: "George Floyd",
    beginOffset: 16951
    },
    type: "PROPER"
    },
    {
    text: {
    content: "George Floyd",
    beginOffset: 17127
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "movie",
    type: "WORK_OF_ART",
    salience: 0.00043706936,
    mentions: [
    {
    text: {
    content: "movie",
    beginOffset: 14649
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "domino effect",
    type: "OTHER",
    salience: 0.0004337771,
    mentions: [
    {
    text: {
    content: "domino effect",
    beginOffset: 15749
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "W. Kamau Bell",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/W._Kamau_Bell",
    mid: "/m/0kbgybr"
    },
    salience: 0.00042788396,
    mentions: [
    {
    text: {
    content: "W. Kamau Bell",
    beginOffset: 13716
    },
    type: "PROPER"
    },
    {
    text: {
    content: "W. Kamau Bell",
    beginOffset: 23545
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.00042356373,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 31460
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "work",
    type: "OTHER",
    salience: 0.00042315468,
    mentions: [
    {
    text: {
    content: "work",
    beginOffset: 16199
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.0004225812,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 27699
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "clan",
    type: "PERSON",
    salience: 0.00042240947,
    mentions: [
    {
    text: {
    content: "clan",
    beginOffset: 19221
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "bars",
    type: "OTHER",
    salience: 0.00042229512,
    mentions: [
    {
    text: {
    content: "bars",
    beginOffset: 19507
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lot",
    type: "OTHER",
    salience: 0.00042061234,
    mentions: [
    {
    text: {
    content: "lot",
    beginOffset: 24659
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "need proof",
    type: "OTHER",
    salience: 0.00041910296,
    mentions: [
    {
    text: {
    content: "need proof",
    beginOffset: 13364
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Orange County",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Orange_County,_California",
    mid: "/m/0cb4j"
    },
    salience: 0.0004157851,
    mentions: [
    {
    text: {
    content: "Orange County",
    beginOffset: 14267
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Orange County",
    beginOffset: 14369
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "somebody",
    type: "PERSON",
    salience: 0.00041567636,
    mentions: [
    {
    text: {
    content: "somebody",
    beginOffset: 21381
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lot",
    type: "OTHER",
    salience: 0.00041546338,
    mentions: [
    {
    text: {
    content: "lot",
    beginOffset: 24680
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "news",
    type: "OTHER",
    salience: 0.00041292567,
    mentions: [
    {
    text: {
    content: "news",
    beginOffset: 16342
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "police brutality",
    type: "OTHER",
    salience: 0.000412798,
    mentions: [
    {
    text: {
    content: "police brutality",
    beginOffset: 16968
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "police brutality",
    type: "OTHER",
    salience: 0.00041277704,
    mentions: [
    {
    text: {
    content: "police brutality",
    beginOffset: 17144
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "slavery",
    type: "OTHER",
    salience: 0.00041227945,
    mentions: [
    {
    text: {
    content: "slavery",
    beginOffset: 18817
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "slavery",
    type: "OTHER",
    salience: 0.00041227945,
    mentions: [
    {
    text: {
    content: "slavery",
    beginOffset: 18974
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "idea",
    type: "OTHER",
    salience: 0.00041213012,
    mentions: [
    {
    text: {
    content: "idea",
    beginOffset: 19585
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "self-interest",
    type: "OTHER",
    salience: 0.00041213012,
    mentions: [
    {
    text: {
    content: "self-interest",
    beginOffset: 19930
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "nationalism",
    type: "PERSON",
    salience: 0.00041209345,
    mentions: [
    {
    text: {
    content: "nationalism",
    beginOffset: 19983
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "change",
    type: "OTHER",
    salience: 0.00041183666,
    mentions: [
    {
    text: {
    content: "change",
    beginOffset: 12274
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "justice",
    type: "OTHER",
    salience: 0.00041180872,
    mentions: [
    {
    text: {
    content: "justice",
    beginOffset: 12763
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "rights",
    type: "OTHER",
    salience: 0.00041169845,
    mentions: [
    {
    text: {
    content: "rights",
    beginOffset: 13116
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "wedding videos",
    type: "WORK_OF_ART",
    salience: 0.00041167127,
    mentions: [
    {
    text: {
    content: "wedding videos",
    beginOffset: 13287
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hearings",
    type: "EVENT",
    salience: 0.00041167127,
    mentions: [
    {
    text: {
    content: "hearings",
    beginOffset: 13255
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "host",
    type: "PERSON",
    salience: 0.00041156396,
    mentions: [
    {
    text: {
    content: "host",
    beginOffset: 13507
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "performers",
    type: "PERSON",
    salience: 0.0004113306,
    mentions: [
    {
    text: {
    content: "performers",
    beginOffset: 13833
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "virus",
    type: "OTHER",
    salience: 0.00041108349,
    mentions: [
    {
    text: {
    content: "virus",
    beginOffset: 14033
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "model",
    type: "OTHER",
    salience: 0.0004110355,
    mentions: [
    {
    text: {
    content: "model",
    beginOffset: 14227
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "apocalypse",
    type: "EVENT",
    salience: 0.00041096436,
    mentions: [
    {
    text: {
    content: "apocalypse",
    beginOffset: 14665
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "apocalypse",
    type: "EVENT",
    salience: 0.00041096436,
    mentions: [
    {
    text: {
    content: "apocalypse",
    beginOffset: 14614
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "half",
    type: "PERSON",
    salience: 0.0004107119,
    mentions: [
    {
    text: {
    content: "half",
    beginOffset: 15379
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "parent",
    type: "PERSON",
    salience: 0.0004107119,
    mentions: [
    {
    text: {
    content: "parent",
    beginOffset: 15561
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Gotta",
    type: "PERSON",
    salience: 0.00041062466,
    mentions: [
    {
    text: {
    content: "Gotta",
    beginOffset: 5651
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "job",
    type: "OTHER",
    salience: 0.000409581,
    mentions: [
    {
    text: {
    content: "job",
    beginOffset: 8506
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "life",
    type: "OTHER",
    salience: 0.00040953272,
    mentions: [
    {
    text: {
    content: "life",
    beginOffset: 8650
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "campaign manager",
    type: "PERSON",
    salience: 0.00040943755,
    mentions: [
    {
    text: {
    content: "campaign manager",
    beginOffset: 8790
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "template",
    type: "OTHER",
    salience: 0.00040929814,
    mentions: [
    {
    text: {
    content: "template",
    beginOffset: 8989
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Tweet",
    type: "WORK_OF_ART",
    salience: 0.0004092073,
    mentions: [
    {
    text: {
    content: "Tweet",
    beginOffset: 9073
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fall",
    type: "OTHER",
    salience: 0.00040861408,
    mentions: [
    {
    text: {
    content: "fall",
    beginOffset: 9962
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "business",
    type: "OTHER",
    salience: 0.00040849566,
    mentions: [
    {
    text: {
    content: "business",
    beginOffset: 10293
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.00040836455,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 10940
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hearing",
    type: "EVENT",
    salience: 0.00040735118,
    mentions: [
    {
    text: {
    content: "hearing",
    beginOffset: 11385
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Hummina",
    type: "PERSON",
    salience: 0.00040698113,
    mentions: [
    {
    text: {
    content: "Hummina",
    beginOffset: 6485
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Hummina",
    beginOffset: 6494
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Shades holy CSI",
    type: "ORGANIZATION",
    salience: 0.0004045474,
    mentions: [
    {
    text: {
    content: "Shades holy CSI",
    beginOffset: 6654
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "murder",
    type: "EVENT",
    salience: 0.00040184273,
    mentions: [
    {
    text: {
    content: "murder",
    beginOffset: 12088
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "half",
    type: "OTHER",
    salience: 0.00040076178,
    mentions: [
    {
    text: {
    content: "half",
    beginOffset: 15276
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "start",
    type: "EVENT",
    salience: 0.00039692354,
    mentions: [
    {
    text: {
    content: "start",
    beginOffset: 12012
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "woman",
    type: "PERSON",
    salience: 0.00039540936,
    mentions: [
    {
    text: {
    content: "woman",
    beginOffset: 30616
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "records",
    type: "OTHER",
    salience: 0.00039525022,
    mentions: [
    {
    text: {
    content: "records",
    beginOffset: 23714
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "American Medicine",
    type: "OTHER",
    salience: 0.0003944537,
    mentions: [
    {
    text: {
    content: "American Medicine",
    beginOffset: 6971
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Pasteur",
    type: "PERSON",
    salience: 0.0003944537,
    mentions: [
    {
    text: {
    content: "Pasteur",
    beginOffset: 7072
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Comey",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/James_Comey",
    mid: "/m/06r04p"
    },
    salience: 0.00039376286,
    mentions: [
    {
    text: {
    content: "Comey",
    beginOffset: 8187
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "teens",
    type: "PERSON",
    salience: 0.00039334976,
    mentions: [
    {
    text: {
    content: "teens",
    beginOffset: 10667
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "each other",
    type: "PERSON",
    salience: 0.00038647276,
    mentions: [
    {
    text: {
    content: "each other",
    beginOffset: 26340
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "offensive",
    type: "OTHER",
    salience: 0.00038631956,
    mentions: [
    {
    text: {
    content: "offensive",
    beginOffset: 25912
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.00038271007,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 29081
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "one",
    type: "PERSON",
    salience: 0.00038178908,
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 28986
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lot",
    type: "OTHER",
    salience: 0.0003784921,
    mentions: [
    {
    text: {
    content: "lot",
    beginOffset: 14151
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.00037721402,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 28510
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "contest",
    type: "EVENT",
    salience: 0.0003765439,
    mentions: [
    {
    text: {
    content: "contest",
    beginOffset: 16542
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "questions",
    type: "OTHER",
    salience: 0.00037652446,
    mentions: [
    {
    text: {
    content: "questions",
    beginOffset: 16609
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "questions",
    type: "OTHER",
    salience: 0.00037650508,
    mentions: [
    {
    text: {
    content: "questions",
    beginOffset: 16739
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tire swing",
    type: "OTHER",
    salience: 0.0003764666,
    mentions: [
    {
    text: {
    content: "tire swing",
    beginOffset: 17014
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tire swing",
    type: "OTHER",
    salience: 0.00037644748,
    mentions: [
    {
    text: {
    content: "tire swing",
    beginOffset: 17060
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "genocide",
    type: "OTHER",
    salience: 0.0003759937,
    mentions: [
    {
    text: {
    content: "genocide",
    beginOffset: 18804
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ways",
    type: "OTHER",
    salience: 0.0003759937,
    mentions: [
    {
    text: {
    content: "ways",
    beginOffset: 18911
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "candy",
    type: "OTHER",
    salience: 0.0003759251,
    mentions: [
    {
    text: {
    content: "candy",
    beginOffset: 19390
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "roots",
    type: "OTHER",
    salience: 0.0003758575,
    mentions: [
    {
    text: {
    content: "roots",
    beginOffset: 19553
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "nobody",
    type: "PERSON",
    salience: 0.00037582408,
    mentions: [
    {
    text: {
    content: "nobody",
    beginOffset: 20015
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0003708031,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 25705
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "coronavirus",
    type: "OTHER",
    salience: 0.00037073065,
    mentions: [
    {
    text: {
    content: "coronavirus",
    beginOffset: 24042
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "world",
    type: "LOCATION",
    salience: 0.00037063155,
    mentions: [
    {
    text: {
    content: "world",
    beginOffset: 24554
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "name",
    type: "OTHER",
    salience: 0.00037057587,
    mentions: [
    {
    text: {
    content: "name",
    beginOffset: 25010
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "name",
    type: "OTHER",
    salience: 0.00037034668,
    mentions: [
    {
    text: {
    content: "name",
    beginOffset: 25838
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "history",
    type: "OTHER",
    salience: 0.00037026848,
    mentions: [
    {
    text: {
    content: "history",
    beginOffset: 26692
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "campaign manager",
    type: "PERSON",
    salience: 0.00036635005,
    mentions: [
    {
    text: {
    content: "campaign manager",
    beginOffset: 8955
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Zoom",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Zoom_Video_Communications",
    mid: "/m/011c8m4f"
    },
    salience: 0.00036630066,
    mentions: [
    {
    text: {
    content: "Zoom",
    beginOffset: 10373
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Zoom",
    beginOffset: 13264
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "backlash",
    type: "OTHER",
    salience: 0.00036238038,
    mentions: [
    {
    text: {
    content: "backlash",
    beginOffset: 26936
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "band",
    type: "ORGANIZATION",
    salience: 0.00036238038,
    mentions: [
    {
    text: {
    content: "band",
    beginOffset: 26900
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "protests",
    type: "EVENT",
    salience: 0.0003621592,
    mentions: [
    {
    text: {
    content: "protests",
    beginOffset: 12620
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Francois",
    type: "PERSON",
    salience: 0.00036200733,
    mentions: [
    {
    text: {
    content: "Francois",
    beginOffset: 4709
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "authority",
    type: "OTHER",
    salience: 0.00036168657,
    mentions: [
    {
    text: {
    content: "authority",
    beginOffset: 12478
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "streets",
    type: "LOCATION",
    salience: 0.00036168657,
    mentions: [
    {
    text: {
    content: "streets",
    beginOffset: 12718
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "destruction",
    type: "OTHER",
    salience: 0.00036168657,
    mentions: [
    {
    text: {
    content: "destruction",
    beginOffset: 12492
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "pandemic",
    type: "EVENT",
    salience: 0.00036168657,
    mentions: [
    {
    text: {
    content: "pandemic",
    beginOffset: 12211
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "property",
    type: "OTHER",
    salience: 0.00036168657,
    mentions: [
    {
    text: {
    content: "property",
    beginOffset: 12507
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "attention",
    type: "OTHER",
    salience: 0.00036154132,
    mentions: [
    {
    text: {
    content: "attention",
    beginOffset: 13155
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "meetings",
    type: "EVENT",
    salience: 0.00036154132,
    mentions: [
    {
    text: {
    content: "meetings",
    beginOffset: 13269
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "revolution",
    type: "EVENT",
    salience: 0.00036147045,
    mentions: [
    {
    text: {
    content: "revolution",
    beginOffset: 13417
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "series",
    type: "WORK_OF_ART",
    salience: 0.00036137772,
    mentions: [
    {
    text: {
    content: "series",
    beginOffset: 13647
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "praise",
    type: "OTHER",
    salience: 0.000360983,
    mentions: [
    {
    text: {
    content: "praise",
    beginOffset: 14158
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "spread",
    type: "EVENT",
    salience: 0.00036094122,
    mentions: [
    {
    text: {
    content: "spread",
    beginOffset: 14383
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "prequel",
    type: "OTHER",
    salience: 0.00036085892,
    mentions: [
    {
    text: {
    content: "prequel",
    beginOffset: 14968
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "anywhere",
    type: "LOCATION",
    salience: 0.00036083857,
    mentions: [
    {
    text: {
    content: "anywhere",
    beginOffset: 14997
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "father",
    type: "PERSON",
    salience: 0.00036075813,
    mentions: [
    {
    text: {
    content: "father",
    beginOffset: 15198
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "anxiety",
    type: "OTHER",
    salience: 0.00036064017,
    mentions: [
    {
    text: {
    content: "anxiety",
    beginOffset: 15661
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "arrest",
    type: "EVENT",
    salience: 0.0003594976,
    mentions: [
    {
    text: {
    content: "arrest",
    beginOffset: 8895
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chef",
    type: "PERSON",
    salience: 0.0003594976,
    mentions: [
    {
    text: {
    content: "chef",
    beginOffset: 8909
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "par",
    type: "OTHER",
    salience: 0.00035945716,
    mentions: [
    {
    text: {
    content: "par",
    beginOffset: 9024
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "surrogates coalition",
    type: "ORGANIZATION",
    salience: 0.00035929904,
    mentions: [
    {
    text: {
    content: "surrogates coalition",
    beginOffset: 9226
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "distance learning",
    type: "OTHER",
    salience: 0.0003588564,
    mentions: [
    {
    text: {
    content: "distance learning",
    beginOffset: 9937
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "laptop",
    type: "CONSUMER_GOOD",
    salience: 0.0003588564,
    mentions: [
    {
    text: {
    content: "laptop",
    beginOffset: 9852
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "home teen dramas",
    type: "OTHER",
    salience: 0.0003588564,
    mentions: [
    {
    text: {
    content: "home teen dramas",
    beginOffset: 10021
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "skyrocketing",
    type: "OTHER",
    salience: 0.0003588564,
    mentions: [
    {
    text: {
    content: "skyrocketing",
    beginOffset: 9764
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "home",
    type: "LOCATION",
    salience: 0.00035882145,
    mentions: [
    {
    text: {
    content: "home",
    beginOffset: 10148
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "social studies",
    type: "OTHER",
    salience: 0.00035878678,
    mentions: [
    {
    text: {
    content: "social studies",
    beginOffset: 10248
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chats",
    type: "OTHER",
    salience: 0.00035871827,
    mentions: [
    {
    text: {
    content: "chats",
    beginOffset: 10378
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "stash",
    type: "OTHER",
    salience: 0.00035865084,
    mentions: [
    {
    text: {
    content: "stash",
    beginOffset: 10492
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "somewhere",
    type: "LOCATION",
    salience: 0.00035851897,
    mentions: [
    {
    text: {
    content: "somewhere",
    beginOffset: 10563
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "babe",
    type: "PERSON",
    salience: 0.0003582361,
    mentions: [
    {
    text: {
    content: "babe",
    beginOffset: 10841
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "play",
    type: "OTHER",
    salience: 0.0003576932,
    mentions: [
    {
    text: {
    content: "play",
    beginOffset: 11425
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "voice",
    type: "OTHER",
    salience: 0.0003574073,
    mentions: [
    {
    text: {
    content: "voice",
    beginOffset: 11784
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "essence",
    type: "OTHER",
    salience: 0.0003574073,
    mentions: [
    {
    text: {
    content: "essence",
    beginOffset: 11843
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "genre",
    type: "OTHER",
    salience: 0.00035738223,
    mentions: [
    {
    text: {
    content: "genre",
    beginOffset: 11898
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everybody",
    type: "PERSON",
    salience: 0.0003563099,
    mentions: [
    {
    text: {
    content: "everybody",
    beginOffset: 29777
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everybody",
    type: "PERSON",
    salience: 0.00035598973,
    mentions: [
    {
    text: {
    content: "everybody",
    beginOffset: 31513
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "country records",
    type: "OTHER",
    salience: 0.00035249544,
    mentions: [
    {
    text: {
    content: "country records",
    beginOffset: 26025
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "act",
    type: "OTHER",
    salience: 0.00035167157,
    mentions: [
    {
    text: {
    content: "act",
    beginOffset: 21455
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Umm",
    type: "PERSON",
    salience: 0.00035115928,
    mentions: [
    {
    text: {
    content: "Umm",
    beginOffset: 10264
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "guest",
    type: "PERSON",
    salience: 0.00034845996,
    mentions: [
    {
    text: {
    content: "guest",
    beginOffset: 24081
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.00034375404,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 30429
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "The Dixie Chicks",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/The_Chicks",
    mid: "/m/018ndc"
    },
    salience: 0.00034145103,
    mentions: [
    {
    text: {
    content: "The Dixie Chicks",
    beginOffset: 24390
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Dixie Chicks",
    beginOffset: 25795
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.00033962145,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 29806
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "house",
    type: "LOCATION",
    salience: 0.0003381973,
    mentions: [
    {
    text: {
    content: "house",
    beginOffset: 22103
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "reference",
    type: "OTHER",
    salience: 0.0003364922,
    mentions: [
    {
    text: {
    content: "reference",
    beginOffset: 5200
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "band",
    type: "ORGANIZATION",
    salience: 0.0003325984,
    mentions: [
    {
    text: {
    content: "band",
    beginOffset: 25539
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Bibles",
    type: "WORK_OF_ART",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/LDS_edition_of_the_Bible",
    mid: "/m/015j7"
    },
    salience: 0.00033210518,
    mentions: [
    {
    text: {
    content: "Bibles",
    beginOffset: 14990
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "knowledge",
    type: "OTHER",
    salience: 0.00033070837,
    mentions: [
    {
    text: {
    content: "knowledge",
    beginOffset: 16450
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "heels",
    type: "OTHER",
    salience: 0.00033067414,
    mentions: [
    {
    text: {
    content: "heels",
    beginOffset: 16586
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "explanation",
    type: "OTHER",
    salience: 0.00033060653,
    mentions: [
    {
    text: {
    content: "explanation",
    beginOffset: 17093
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "B-side",
    type: "OTHER",
    salience: 0.00033028462,
    mentions: [
    {
    text: {
    content: "B-side",
    beginOffset: 18598
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "descendants",
    type: "PERSON",
    salience: 0.00033020801,
    mentions: [
    {
    text: {
    content: "descendants",
    beginOffset: 19006
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "top",
    type: "OTHER",
    salience: 0.00033020801,
    mentions: [
    {
    text: {
    content: "top",
    beginOffset: 18881
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "broom",
    type: "LOCATION",
    salience: 0.00033014774,
    mentions: [
    {
    text: {
    content: "broom",
    beginOffset: 19382
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "land",
    type: "LOCATION",
    salience: 0.0003300884,
    mentions: [
    {
    text: {
    content: "land",
    beginOffset: 19797
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tribalism",
    type: "OTHER",
    salience: 0.00033005903,
    mentions: [
    {
    text: {
    content: "tribalism",
    beginOffset: 19999
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "anybody",
    type: "PERSON",
    salience: 0.00033005903,
    mentions: [
    {
    text: {
    content: "anybody",
    beginOffset: 20026
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "beef",
    type: "OTHER",
    salience: 0.00032609142,
    mentions: [
    {
    text: {
    content: "beef",
    beginOffset: 19455
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "album gas lighter",
    type: "OTHER",
    salience: 0.00032496,
    mentions: [
    {
    text: {
    content: "album gas lighter",
    beginOffset: 27739
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fire",
    type: "OTHER",
    salience: 0.0003218284,
    mentions: [
    {
    text: {
    content: "fire",
    beginOffset: 13407
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "folks",
    type: "PERSON",
    salience: 0.00031930918,
    mentions: [
    {
    text: {
    content: "folks",
    beginOffset: 26207
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Eisenhower",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Dwight_D._Eisenhower",
    mid: "/m/028rk"
    },
    salience: 0.00031816217,
    mentions: [
    {
    text: {
    content: "Eisenhower",
    beginOffset: 4456
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Amway",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Amway",
    mid: "/m/0tdx"
    },
    salience: 0.00031816217,
    mentions: [
    {
    text: {
    content: "Amway",
    beginOffset: 4485
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Canada",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Canada",
    mid: "/m/0d060g"
    },
    salience: 0.00031816217,
    mentions: [
    {
    text: {
    content: "Canada",
    beginOffset: 4473
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Southern Illinois",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Southern_Precinct,_Williamson_County,_Illinois",
    mid: "/m/0gg4rh7"
    },
    salience: 0.00031777407,
    mentions: [
    {
    text: {
    content: "Southern Illinois",
    beginOffset: 4875
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Fast and Furious",
    type: "WORK_OF_ART",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Fast_&_Furious",
    mid: "/m/0czbbsz"
    },
    salience: 0.0003172243,
    mentions: [
    {
    text: {
    content: "Fast and Furious",
    beginOffset: 5542
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Siberian",
    type: "LOCATION",
    salience: 0.00031716077,
    mentions: [
    {
    text: {
    content: "Siberian",
    beginOffset: 5618
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Tokyo Drift",
    type: "WORK_OF_ART",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/The_Fast_and_the_Furious:_Tokyo_Drift",
    mid: "/m/08c6k9"
    },
    salience: 0.00031697622,
    mentions: [
    {
    text: {
    content: "Tokyo Drift",
    beginOffset: 5713
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Miami Batman",
    type: "PERSON",
    salience: 0.00031621556,
    mentions: [
    {
    text: {
    content: "Miami Batman",
    beginOffset: 6671
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Sports Illustrated Swimsuit Edition",
    type: "WORK_OF_ART",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Sports_Illustrated_Swimsuit_Issue",
    mid: "/m/03jmqb"
    },
    salience: 0.00031593058,
    mentions: [
    {
    text: {
    content: "Sports Illustrated Swimsuit Edition",
    beginOffset: 7019
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Chomp",
    type: "OTHER",
    salience: 0.00031588506,
    mentions: [
    {
    text: {
    content: "Chomp",
    beginOffset: 7218
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Dan",
    type: "PERSON",
    salience: 0.00031588506,
    mentions: [
    {
    text: {
    content: "Dan",
    beginOffset: 7180
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Woohoo",
    type: "OTHER",
    salience: 0.0003158401,
    mentions: [
    {
    text: {
    content: "Woohoo",
    beginOffset: 7225
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "u.s.",
    type: "LOCATION",
    salience: 0.00031570822,
    mentions: [
    {
    text: {
    content: "u.s.",
    beginOffset: 7508
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.0003156587,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 28335
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.0003152514,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 31508
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "dad",
    type: "PERSON",
    salience: 0.00031483738,
    mentions: [
    {
    text: {
    content: "dad",
    beginOffset: 20860
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "San Francisco",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/San_Francisco",
    mid: "/m/0d6lp"
    },
    salience: 0.00031060108,
    mentions: [
    {
    text: {
    content: "San Francisco",
    beginOffset: 14323
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "women",
    type: "PERSON",
    salience: 0.000309571,
    mentions: [
    {
    text: {
    content: "women",
    beginOffset: 26109
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mind",
    type: "OTHER",
    salience: 0.00030952744,
    mentions: [
    {
    text: {
    content: "mind",
    beginOffset: 26660
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "minds",
    type: "OTHER",
    salience: 0.00030949508,
    mentions: [
    {
    text: {
    content: "minds",
    beginOffset: 27196
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "contenders",
    type: "PERSON",
    salience: 0.00030794853,
    mentions: [
    {
    text: {
    content: "contenders",
    beginOffset: 24991
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "National Weather Service",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/National_Weather_Service",
    mid: "/m/01q4np"
    },
    salience: 0.00030452356,
    mentions: [
    {
    text: {
    content: "National Weather Service",
    beginOffset: 4812
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "peace",
    type: "OTHER",
    salience: 0.0003002373,
    mentions: [
    {
    text: {
    content: "peace",
    beginOffset: 26168
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "much",
    type: "OTHER",
    salience: 0.00029883784,
    mentions: [
    {
    text: {
    content: "much",
    beginOffset: 31440
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Louis",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Louis_Pasteur",
    mid: "/m/04hcc"
    },
    salience: 0.0002934239,
    mentions: [
    {
    text: {
    content: "Louis",
    beginOffset: 7065
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "no one",
    type: "PERSON",
    salience: 0.00029014563,
    mentions: [
    {
    text: {
    content: "no one",
    beginOffset: 8687
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Thanks",
    type: "OTHER",
    salience: 0.00028622884,
    mentions: [
    {
    text: {
    content: "Thanks",
    beginOffset: 31430
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Welcome Back",
    type: "WORK_OF_ART",
    salience: 0.00028360155,
    mentions: [
    {
    text: {
    content: "Welcome Back",
    beginOffset: 11112
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "band",
    type: "ORGANIZATION",
    salience: 0.0002833049,
    mentions: [
    {
    text: {
    content: "band",
    beginOffset: 26005
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "row",
    type: "OTHER",
    salience: 0.00028250914,
    mentions: [
    {
    text: {
    content: "row",
    beginOffset: 25054
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "anything",
    type: "OTHER",
    salience: 0.0002824258,
    mentions: [
    {
    text: {
    content: "anything",
    beginOffset: 25342
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "name change",
    type: "OTHER",
    salience: 0.0002823545,
    mentions: [
    {
    text: {
    content: "name change",
    beginOffset: 25687
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Peace",
    type: "OTHER",
    salience: 0.0002823144,
    mentions: [
    {
    text: {
    content: "Peace",
    beginOffset: 26154
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "dinner",
    type: "EVENT",
    salience: 0.00028230448,
    mentions: [
    {
    text: {
    content: "dinner",
    beginOffset: 26249
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "boycott",
    type: "OTHER",
    salience: 0.00028039626,
    mentions: [
    {
    text: {
    content: "boycott",
    beginOffset: 26996
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "discussion",
    type: "OTHER",
    salience: 0.0002790821,
    mentions: [
    {
    text: {
    content: "discussion",
    beginOffset: 24838
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "production",
    type: "OTHER",
    salience: 0.00027680476,
    mentions: [
    {
    text: {
    content: "production",
    beginOffset: 29049
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "none",
    type: "OTHER",
    salience: 0.00027359556,
    mentions: [
    {
    text: {
    content: "none",
    beginOffset: 10280
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "army",
    type: "ORGANIZATION",
    salience: 0.00027001218,
    mentions: [
    {
    text: {
    content: "army",
    beginOffset: 30507
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "army",
    type: "ORGANIZATION",
    salience: 0.00027001218,
    mentions: [
    {
    text: {
    content: "army",
    beginOffset: 30521
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "army",
    type: "ORGANIZATION",
    salience: 0.00027000371,
    mentions: [
    {
    text: {
    content: "army",
    beginOffset: 30622
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "army",
    type: "ORGANIZATION",
    salience: 0.00027000371,
    mentions: [
    {
    text: {
    content: "army",
    beginOffset: 30604
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "army",
    type: "ORGANIZATION",
    salience: 0.00026994516,
    mentions: [
    {
    text: {
    content: "army",
    beginOffset: 31047
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "army",
    type: "ORGANIZATION",
    salience: 0.00026987138,
    mentions: [
    {
    text: {
    content: "army",
    beginOffset: 31417
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "women",
    type: "PERSON",
    salience: 0.00026704418,
    mentions: [
    {
    text: {
    content: "women",
    beginOffset: 25890
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.0002665895,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 20545
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Applause",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Applause",
    mid: "/m/028ght"
    },
    salience: 0.0002664011,
    mentions: [
    {
    text: {
    content: "Applause",
    beginOffset: 13891
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "movement",
    type: "OTHER",
    salience: 0.00026283736,
    mentions: [
    {
    text: {
    content: "movement",
    beginOffset: 29485
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "thing",
    type: "OTHER",
    salience: 0.0002616253,
    mentions: [
    {
    text: {
    content: "thing",
    beginOffset: 20193
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Los Angeles",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Los_Angeles",
    mid: "/m/030qb3t"
    },
    salience: 0.00025955107,
    mentions: [
    {
    text: {
    content: "Los Angeles",
    beginOffset: 9884
    },
    type: "PROPER"
    },
    {
    text: {
    content: "LA",
    beginOffset: 21157
    },
    type: "PROPER"
    },
    {
    text: {
    content: "La",
    beginOffset: 14341
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "world",
    type: "LOCATION",
    salience: 0.0002589532,
    mentions: [
    {
    text: {
    content: "world",
    beginOffset: 27985
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "album",
    type: "WORK_OF_ART",
    salience: 0.0002589532,
    mentions: [
    {
    text: {
    content: "album",
    beginOffset: 27911
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "album",
    type: "WORK_OF_ART",
    salience: 0.00025891833,
    mentions: [
    {
    text: {
    content: "album",
    beginOffset: 28228
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "album",
    type: "WORK_OF_ART",
    salience: 0.00025883282,
    mentions: [
    {
    text: {
    content: "album",
    beginOffset: 29098
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "problems",
    type: "OTHER",
    salience: 0.00025867653,
    mentions: [
    {
    text: {
    content: "problems",
    beginOffset: 30905
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lyrics",
    type: "WORK_OF_ART",
    salience: 0.0002507619,
    mentions: [
    {
    text: {
    content: "lyrics",
    beginOffset: 29113
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "theater",
    type: "LOCATION",
    salience: 0.0002482104,
    mentions: [
    {
    text: {
    content: "theater",
    beginOffset: 23994
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "gas water",
    type: "OTHER",
    salience: 0.0002482008,
    mentions: [
    {
    text: {
    content: "gas water",
    beginOffset: 24176
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ducks",
    type: "OTHER",
    salience: 0.00024810675,
    mentions: [
    {
    text: {
    content: "ducks",
    beginOffset: 25043
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "men",
    type: "PERSON",
    salience: 0.0002480883,
    mentions: [
    {
    text: {
    content: "men",
    beginOffset: 25252
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mend",
    type: "OTHER",
    salience: 0.0002480791,
    mentions: [
    {
    text: {
    content: "mend",
    beginOffset: 25283
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Alter Egos",
    type: "OTHER",
    salience: 0.00024803355,
    mentions: [
    {
    text: {
    content: "Alter Egos",
    beginOffset: 25430
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "criticism",
    type: "OTHER",
    salience: 0.00024797095,
    mentions: [
    {
    text: {
    content: "criticism",
    beginOffset: 25670
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fellows",
    type: "PERSON",
    salience: 0.0002479445,
    mentions: [
    {
    text: {
    content: "fellows",
    beginOffset: 26145
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "term",
    type: "OTHER",
    salience: 0.0002479445,
    mentions: [
    {
    text: {
    content: "term",
    beginOffset: 26100
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "response",
    type: "OTHER",
    salience: 0.0002479445,
    mentions: [
    {
    text: {
    content: "response",
    beginOffset: 26127
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Correspondents Dinner speech",
    type: "OTHER",
    salience: 0.00024791827,
    mentions: [
    {
    text: {
    content: "Correspondents Dinner speech",
    beginOffset: 26462
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "owners",
    type: "PERSON",
    salience: 0.00024791827,
    mentions: [
    {
    text: {
    content: "owners",
    beginOffset: 26536
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "trouble",
    type: "OTHER",
    salience: 0.00024791827,
    mentions: [
    {
    text: {
    content: "trouble",
    beginOffset: 26370
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "pretenses",
    type: "OTHER",
    salience: 0.00024790093,
    mentions: [
    {
    text: {
    content: "pretenses",
    beginOffset: 26779
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "boondoggle",
    type: "OTHER",
    salience: 0.00024790093,
    mentions: [
    {
    text: {
    content: "boondoggle",
    beginOffset: 26748
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chaos",
    type: "OTHER",
    salience: 0.00024790093,
    mentions: [
    {
    text: {
    content: "chaos",
    beginOffset: 26805
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "bag",
    type: "OTHER",
    salience: 0.00024786653,
    mentions: [
    {
    text: {
    content: "bag",
    beginOffset: 27347
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Hustler",
    type: "PERSON",
    salience: 0.0002468189,
    mentions: [
    {
    text: {
    content: "Hustler",
    beginOffset: 16806
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "drum",
    type: "OTHER",
    salience: 0.00024617845,
    mentions: [
    {
    text: {
    content: "drum",
    beginOffset: 31083
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "drum",
    type: "OTHER",
    salience: 0.00024612612,
    mentions: [
    {
    text: {
    content: "drum",
    beginOffset: 31394
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "can",
    type: "CONSUMER_GOOD",
    salience: 0.00024458894,
    mentions: [
    {
    text: {
    content: "can",
    beginOffset: 30012
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00024441967,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 21845
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00024433885,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 22793
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00024433885,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 22897
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00024433885,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 22811
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "stuff racism",
    type: "OTHER",
    salience: 0.00024406302,
    mentions: [
    {
    text: {
    content: "stuff racism",
    beginOffset: 23258
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Emmy",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Emmy_Award",
    mid: "/m/013sb1"
    },
    salience: 0.00024309028,
    mentions: [
    {
    text: {
    content: "Emmy",
    beginOffset: 13600
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Natalie Marty Emily",
    type: "PERSON",
    salience: 0.00024129053,
    mentions: [
    {
    text: {
    content: "Natalie Marty Emily",
    beginOffset: 23849
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Natalie",
    beginOffset: 27242
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Frozen",
    type: "WORK_OF_ART",
    salience: 0.00024110853,
    mentions: [
    {
    text: {
    content: "Frozen",
    beginOffset: 10699
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "everyone",
    type: "PERSON",
    salience: 0.00024020307,
    mentions: [
    {
    text: {
    content: "everyone",
    beginOffset: 30047
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "man",
    type: "PERSON",
    salience: 0.0002381081,
    mentions: [
    {
    text: {
    content: "man",
    beginOffset: 22882
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "all",
    type: "OTHER",
    salience: 0.00023471536,
    mentions: [
    {
    text: {
    content: "all",
    beginOffset: 20821
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "movement",
    type: "EVENT",
    salience: 0.00023237687,
    mentions: [
    {
    text: {
    content: "movement",
    beginOffset: 29390
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "album gas",
    type: "OTHER",
    salience: 0.0002322952,
    mentions: [
    {
    text: {
    content: "album gas",
    beginOffset: 30386
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "woman",
    type: "PERSON",
    salience: 0.0002322806,
    mentions: [
    {
    text: {
    content: "woman",
    beginOffset: 30515
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "daughters",
    type: "PERSON",
    salience: 0.00023225162,
    mentions: [
    {
    text: {
    content: "daughters",
    beginOffset: 30858
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "things",
    type: "OTHER",
    salience: 0.00023114588,
    mentions: [
    {
    text: {
    content: "things",
    beginOffset: 21788
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "clown",
    type: "OTHER",
    salience: 0.00023028089,
    mentions: [
    {
    text: {
    content: "clown",
    beginOffset: 25970
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "bands",
    type: "OTHER",
    salience: 0.00022959227,
    mentions: [
    {
    text: {
    content: "bands",
    beginOffset: 28384
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "product",
    type: "CONSUMER_GOOD",
    salience: 0.00022721103,
    mentions: [
    {
    text: {
    content: "product",
    beginOffset: 30176
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "preview",
    type: "WORK_OF_ART",
    salience: 0.00022472127,
    mentions: [
    {
    text: {
    content: "preview",
    beginOffset: 10081
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everybody",
    type: "PERSON",
    salience: 0.0002237174,
    mentions: [
    {
    text: {
    content: "everybody",
    beginOffset: 23559
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "everybody",
    type: "PERSON",
    salience: 0.00022369079,
    mentions: [
    {
    text: {
    content: "everybody",
    beginOffset: 23643
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "rest",
    type: "OTHER",
    salience: 0.00022179676,
    mentions: [
    {
    text: {
    content: "rest",
    beginOffset: 28474
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "weapon",
    type: "OTHER",
    salience: 0.00022158067,
    mentions: [
    {
    text: {
    content: "weapon",
    beginOffset: 30742
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "audience",
    type: "PERSON",
    salience: 0.00021637816,
    mentions: [
    {
    text: {
    content: "audience",
    beginOffset: 28784
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "integrity",
    type: "OTHER",
    salience: 0.00021627393,
    mentions: [
    {
    text: {
    content: "integrity",
    beginOffset: 30334
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "supremacy",
    type: "OTHER",
    salience: 0.00021603922,
    mentions: [
    {
    text: {
    content: "supremacy",
    beginOffset: 21661
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "white supremacy",
    type: "OTHER",
    salience: 0.00021594964,
    mentions: [
    {
    text: {
    content: "white supremacy",
    beginOffset: 22755
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "supremacy",
    type: "OTHER",
    salience: 0.0002158887,
    mentions: [
    {
    text: {
    content: "supremacy",
    beginOffset: 23451
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.00021587154,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 23622
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.00021584601,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 23804
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "chicks",
    type: "OTHER",
    salience: 0.00021583756,
    mentions: [
    {
    text: {
    content: "chicks",
    beginOffset: 23818
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "EV",
    type: "CONSUMER_GOOD",
    salience: 0.00021359925,
    mentions: [
    {
    text: {
    content: "EV",
    beginOffset: 13024
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Trans",
    type: "PERSON",
    salience: 0.00021358508,
    mentions: [
    {
    text: {
    content: "Trans",
    beginOffset: 13110
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Carl",
    type: "PERSON",
    salience: 0.00021357097,
    mentions: [
    {
    text: {
    content: "Carl",
    beginOffset: 13220
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "C-SPAN",
    type: "OTHER",
    metadata: {
    mid: "/m/0kc7r",
    wikipedia_url: "https://en.wikipedia.org/wiki/C-SPAN"
    },
    salience: 0.00021357097,
    mentions: [
    {
    text: {
    content: "C-SPAN",
    beginOffset: 13248
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Cara",
    type: "PERSON",
    salience: 0.00021354298,
    mentions: [
    {
    text: {
    content: "Cara",
    beginOffset: 13402
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Toronto",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Toronto",
    mid: "/m/0h7h6"
    },
    salience: 0.00021326603,
    mentions: [
    {
    text: {
    content: "Toronto",
    beginOffset: 14025
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Florida jr",
    type: "PERSON",
    salience: 0.00021325356,
    mentions: [
    {
    text: {
    content: "Florida jr",
    beginOffset: 14084
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Mad Max",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://fr.wikipedia.org/wiki/Mad_Max_(série_de_films)",
    mid: "/m/0djrvd7"
    },
    salience: 0.00021317991,
    mentions: [
    {
    text: {
    content: "Mad Max",
    beginOffset: 14849
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Denzel Washington The Book of Eli",
    type: "PERSON",
    salience: 0.00021316785,
    mentions: [
    {
    text: {
    content: "Denzel Washington The Book of Eli",
    beginOffset: 14934
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Barbara",
    type: "PERSON",
    salience: 0.00021248653,
    mentions: [
    {
    text: {
    content: "Barbara",
    beginOffset: 8609
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Attic",
    type: "LOCATION",
    salience: 0.00021246148,
    mentions: [
    {
    text: {
    content: "Attic",
    beginOffset: 8677
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "NFL",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/National_Football_League",
    mid: "/m/059yj"
    },
    salience: 0.00021233977,
    mentions: [
    {
    text: {
    content: "NFL",
    beginOffset: 9006
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Etc",
    type: "ORGANIZATION",
    salience: 0.00021224636,
    mentions: [
    {
    text: {
    content: "Etc",
    beginOffset: 9249
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00021212059,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 20223
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Creek Dale",
    type: "LOCATION",
    salience: 0.00021198488,
    mentions: [
    {
    text: {
    content: "Creek Dale",
    beginOffset: 10108
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Oakland",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Oakland,_California",
    mid: "/m/0dc95"
    },
    salience: 0.00021198488,
    mentions: [
    {
    text: {
    content: "Oakland",
    beginOffset: 9910
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "San Diego",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/San_Diego",
    mid: "/m/071vr"
    },
    salience: 0.00021198488,
    mentions: [
    {
    text: {
    content: "San Diego",
    beginOffset: 9896
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Jim",
    type: "PERSON",
    salience: 0.00021198488,
    mentions: [
    {
    text: {
    content: "Jim",
    beginOffset: 10089
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Ashley",
    type: "PERSON",
    salience: 0.00021188329,
    mentions: [
    {
    text: {
    content: "Ashley",
    beginOffset: 10441
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "audience",
    type: "PERSON",
    salience: 0.00020867599,
    mentions: [
    {
    text: {
    content: "audience",
    beginOffset: 27104
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hallways",
    type: "OTHER",
    salience: 0.00020834572,
    mentions: [
    {
    text: {
    content: "hallways",
    beginOffset: 30718
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Firestorm",
    type: "EVENT",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Firestorm",
    mid: "/m/01qd50"
    },
    salience: 0.00020816892,
    mentions: [
    {
    text: {
    content: "Firestorm",
    beginOffset: 27264
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "fish",
    type: "OTHER",
    salience: 0.00020578873,
    mentions: [
    {
    text: {
    content: "fish",
    beginOffset: 30790
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "youth",
    type: "OTHER",
    salience: 0.00020578234,
    mentions: [
    {
    text: {
    content: "youth",
    beginOffset: 30881
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.00020309335,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 20804
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.00020301505,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 21648
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.00020297259,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 22266
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.00020293085,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 22533
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.00020293085,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 22628
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.00020291437,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 23074
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "racism",
    type: "OTHER",
    salience: 0.00020287359,
    mentions: [
    {
    text: {
    content: "racism",
    beginOffset: 23438
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "race",
    type: "EVENT",
    salience: 0.0002015065,
    mentions: [
    {
    text: {
    content: "race",
    beginOffset: 22557
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.00019831274,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 20966
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "show",
    type: "WORK_OF_ART",
    salience: 0.00019803763,
    mentions: [
    {
    text: {
    content: "show",
    beginOffset: 23970
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hold",
    type: "OTHER",
    salience: 0.00019741907,
    mentions: [
    {
    text: {
    content: "hold",
    beginOffset: 27841
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "covid crisis",
    type: "EVENT",
    salience: 0.00019741907,
    mentions: [
    {
    text: {
    content: "covid crisis",
    beginOffset: 27861
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tour",
    type: "EVENT",
    salience: 0.00019737921,
    mentions: [
    {
    text: {
    content: "tour",
    beginOffset: 28277
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tour",
    type: "EVENT",
    salience: 0.00019737262,
    mentions: [
    {
    text: {
    content: "tour",
    beginOffset: 28309
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ways",
    type: "OTHER",
    salience: 0.00019732062,
    mentions: [
    {
    text: {
    content: "ways",
    beginOffset: 29217
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ones",
    type: "OTHER",
    salience: 0.00019732062,
    mentions: [
    {
    text: {
    content: "ones",
    beginOffset: 29003
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lives",
    type: "OTHER",
    salience: 0.0001973078,
    mentions: [
    {
    text: {
    content: "lives",
    beginOffset: 29384
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "lives",
    type: "OTHER",
    salience: 0.0001973078,
    mentions: [
    {
    text: {
    content: "lives",
    beginOffset: 29433
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "stick",
    type: "CONSUMER_GOOD",
    salience: 0.0001972887,
    mentions: [
    {
    text: {
    content: "stick",
    beginOffset: 29764
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "advisor",
    type: "PERSON",
    salience: 0.00019726974,
    mentions: [
    {
    text: {
    content: "advisor",
    beginOffset: 29848
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "tweet",
    type: "WORK_OF_ART",
    salience: 0.00019726974,
    mentions: [
    {
    text: {
    content: "tweet",
    beginOffset: 29956
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sale",
    type: "OTHER",
    salience: 0.00019725718,
    mentions: [
    {
    text: {
    content: "sale",
    beginOffset: 30079
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sons",
    type: "PERSON",
    salience: 0.00019720146,
    mentions: [
    {
    text: {
    content: "sons",
    beginOffset: 30849
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "bodies temperatures",
    type: "OTHER",
    salience: 0.00019715901,
    mentions: [
    {
    text: {
    content: "bodies temperatures",
    beginOffset: 31161
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "truth",
    type: "OTHER",
    salience: 0.00019714105,
    mentions: [
    {
    text: {
    content: "truth",
    beginOffset: 31269
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "George w",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/George_W._Bush",
    mid: "/m/09b6zr"
    },
    salience: 0.00019639621,
    mentions: [
    {
    text: {
    content: "George w",
    beginOffset: 26402
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "process",
    type: "OTHER",
    salience: 0.00019608536,
    mentions: [
    {
    text: {
    content: "process",
    beginOffset: 28067
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Malik",
    type: "PERSON",
    salience: 0.00019518066,
    mentions: [
    {
    text: {
    content: "Malik",
    beginOffset: 17920
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Hamilton",
    type: "PERSON",
    salience: 0.00019510642,
    mentions: [
    {
    text: {
    content: "Hamilton",
    beginOffset: 18610
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Africa",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Africa",
    mid: "/m/0dg3n1"
    },
    salience: 0.0001949905,
    mentions: [
    {
    text: {
    content: "Africa",
    beginOffset: 19844
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Europeans",
    type: "LOCATION",
    salience: 0.0001949905,
    mentions: [
    {
    text: {
    content: "Europeans",
    beginOffset: 19765
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "part",
    type: "OTHER",
    salience: 0.00018847425,
    mentions: [
    {
    text: {
    content: "part",
    beginOffset: 29565
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.00018803934,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 20093
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.00018803102,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 20357
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "way",
    type: "OTHER",
    salience: 0.00018794947,
    mentions: [
    {
    text: {
    content: "way",
    beginOffset: 21303
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "country",
    type: "LOCATION",
    salience: 0.00018785532,
    mentions: [
    {
    text: {
    content: "country",
    beginOffset: 22498
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "country",
    type: "LOCATION",
    salience: 0.00018784763,
    mentions: [
    {
    text: {
    content: "country",
    beginOffset: 22648
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Marty Emily Natalie",
    type: "PERSON",
    salience: 0.0001829492,
    mentions: [
    {
    text: {
    content: "Marty Emily Natalie",
    beginOffset: 25307
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Dixie",
    type: "LOCATION",
    salience: 0.00018287661,
    mentions: [
    {
    text: {
    content: "Dixie",
    beginOffset: 25821
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Washington",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Washington_(state)",
    mid: "/m/081yw"
    },
    salience: 0.0001828508,
    mentions: [
    {
    text: {
    content: "Washington",
    beginOffset: 26499
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "some",
    type: "PERSON",
    salience: 0.00018220251,
    mentions: [
    {
    text: {
    content: "some",
    beginOffset: 28772
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "kids",
    type: "PERSON",
    salience: 0.00018002941,
    mentions: [
    {
    text: {
    content: "kids",
    beginOffset: 21882
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "My Own Drum",
    type: "ORGANIZATION",
    salience: 0.00017451632,
    mentions: [
    {
    text: {
    content: "My Own Drum",
    beginOffset: 30548
    },
    type: "PROPER"
    },
    {
    text: {
    content: "My Own Drum",
    beginOffset: 30574
    },
    type: "PROPER"
    },
    {
    text: {
    content: "My Own Drum",
    beginOffset: 31017
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "brakes",
    type: "OTHER",
    salience: 0.00017337225,
    mentions: [
    {
    text: {
    content: "brakes",
    beginOffset: 28011
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "album release",
    type: "WORK_OF_ART",
    salience: 0.00017332009,
    mentions: [
    {
    text: {
    content: "album release",
    beginOffset: 28538
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "world plans",
    type: "OTHER",
    salience: 0.00017332009,
    mentions: [
    {
    text: {
    content: "world plans",
    beginOffset: 28486
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "mower",
    type: "CONSUMER_GOOD",
    salience: 0.000173303,
    mentions: [
    {
    text: {
    content: "mower",
    beginOffset: 28918
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "picture",
    type: "WORK_OF_ART",
    salience: 0.00017324697,
    mentions: [
    {
    text: {
    content: "picture",
    beginOffset: 29982
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ethics laws",
    type: "OTHER",
    salience: 0.00017324697,
    mentions: [
    {
    text: {
    content: "ethics laws",
    beginOffset: 29937
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "beans",
    type: "OTHER",
    salience: 0.00017324145,
    mentions: [
    {
    text: {
    content: "beans",
    beginOffset: 30024
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sellout",
    type: "EVENT",
    salience: 0.00017322498,
    mentions: [
    {
    text: {
    content: "sellout",
    beginOffset: 30239
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "gentlemen",
    type: "PERSON",
    salience: 0.00017321951,
    mentions: [
    {
    text: {
    content: "gentlemen",
    beginOffset: 30415
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hair",
    type: "OTHER",
    salience: 0.00017321951,
    mentions: [
    {
    text: {
    content: "hair",
    beginOffset: 30307
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "ladies",
    type: "PERSON",
    salience: 0.00017321951,
    mentions: [
    {
    text: {
    content: "ladies",
    beginOffset: 30404
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "trunk",
    type: "OTHER",
    salience: 0.00017321407,
    mentions: [
    {
    text: {
    content: "trunk",
    beginOffset: 30483
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "baby",
    type: "PERSON",
    salience: 0.00017315503,
    mentions: [
    {
    text: {
    content: "baby",
    beginOffset: 31094
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "one",
    type: "OTHER",
    salience: 0.00017314973,
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 31115
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "cities",
    type: "LOCATION",
    salience: 0.00017314973,
    mentions: [
    {
    text: {
    content: "cities",
    beginOffset: 31190
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "city",
    type: "LOCATION",
    salience: 0.00017313921,
    mentions: [
    {
    text: {
    content: "city",
    beginOffset: 31242
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sink",
    type: "OTHER",
    salience: 0.00017312351,
    mentions: [
    {
    text: {
    content: "sink",
    beginOffset: 31349
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "history",
    type: "OTHER",
    salience: 0.00017295113,
    mentions: [
    {
    text: {
    content: "history",
    beginOffset: 21262
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "point",
    type: "OTHER",
    salience: 0.00017113866,
    mentions: [
    {
    text: {
    content: "point",
    beginOffset: 21807
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.00016882553,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 23175
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Both",
    type: "PERSON",
    salience: 0.00016703781,
    mentions: [
    {
    text: {
    content: "Both",
    beginOffset: 28363
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Goodbye Earl",
    type: "WORK_OF_ART",
    metadata: {
    mid: "/g/1q5jz8j_5"
    },
    salience: 0.0001669406,
    mentions: [
    {
    text: {
    content: "Goodbye Earl",
    beginOffset: 24194
    },
    type: "PROPER"
    },
    {
    text: {
    content: "Goodbye Earl",
    beginOffset: 24216
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "human",
    type: "PERSON",
    salience: 0.00016385375,
    mentions: [
    {
    text: {
    content: "human",
    beginOffset: 31587
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "race",
    type: "EVENT",
    salience: 0.0001633964,
    mentions: [
    {
    text: {
    content: "race",
    beginOffset: 23344
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "season",
    type: "EVENT",
    salience: 0.00016267695,
    mentions: [
    {
    text: {
    content: "season",
    beginOffset: 20671
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "season",
    type: "OTHER",
    salience: 0.00016264863,
    mentions: [
    {
    text: {
    content: "season",
    beginOffset: 20878
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "problems",
    type: "OTHER",
    salience: 0.00016264162,
    mentions: [
    {
    text: {
    content: "problems",
    beginOffset: 21038
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "history",
    type: "OTHER",
    salience: 0.00016262072,
    mentions: [
    {
    text: {
    content: "history",
    beginOffset: 21317
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "man",
    type: "PERSON",
    salience: 0.00016260003,
    mentions: [
    {
    text: {
    content: "man",
    beginOffset: 21675
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "man",
    type: "PERSON",
    salience: 0.00016259319,
    mentions: [
    {
    text: {
    content: "man",
    beginOffset: 21729
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "world",
    type: "LOCATION",
    salience: 0.00016256602,
    mentions: [
    {
    text: {
    content: "world",
    beginOffset: 22169
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "season",
    type: "EVENT",
    salience: 0.00016248673,
    mentions: [
    {
    text: {
    content: "season",
    beginOffset: 23479
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Puss in Boots",
    type: "WORK_OF_ART",
    metadata: {
    mid: "/g/11gdkpzwxl"
    },
    salience: 0.00015970616,
    mentions: [
    {
    text: {
    content: "Puss in Boots",
    beginOffset: 25500
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "right",
    type: "OTHER",
    salience: 0.00015855198,
    mentions: [
    {
    text: {
    content: "right",
    beginOffset: 22200
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "race",
    type: "EVENT",
    salience: 0.00015557853,
    mentions: [
    {
    text: {
    content: "race",
    beginOffset: 22576
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "episode",
    type: "WORK_OF_ART",
    salience: 0.00015481393,
    mentions: [
    {
    text: {
    content: "episode",
    beginOffset: 20867
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "episode",
    type: "WORK_OF_ART",
    salience: 0.0001548006,
    mentions: [
    {
    text: {
    content: "episode",
    beginOffset: 21160
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "episode",
    type: "WORK_OF_ART",
    salience: 0.0001548006,
    mentions: [
    {
    text: {
    content: "episode",
    beginOffset: 21114
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "shades",
    type: "OTHER",
    salience: 0.00015465982,
    mentions: [
    {
    text: {
    content: "shades",
    beginOffset: 23498
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "well slavery",
    type: "OTHER",
    salience: 0.00015381715,
    mentions: [
    {
    text: {
    content: "well slavery",
    beginOffset: 20510
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "One",
    type: "CONSUMER_GOOD",
    salience: 0.00015281935,
    mentions: [
    {
    text: {
    content: "One",
    beginOffset: 30630
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "guy",
    type: "PERSON",
    salience: 0.00014992824,
    mentions: [
    {
    text: {
    content: "guy",
    beginOffset: 23009
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "fans",
    type: "PERSON",
    salience: 0.00014952953,
    mentions: [
    {
    text: {
    content: "fans",
    beginOffset: 28207
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Willie puddin",
    type: "OTHER",
    salience: 0.00014649145,
    mentions: [
    {
    text: {
    content: "Willie puddin",
    beginOffset: 25588
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Bush",
    type: "PERSON",
    salience: 0.00014644985,
    mentions: [
    {
    text: {
    content: "Bush",
    beginOffset: 26413
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Iraq",
    type: "LOCATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Iraq",
    mid: "/m/0d05q4"
    },
    salience: 0.0001464396,
    mentions: [
    {
    text: {
    content: "Iraq",
    beginOffset: 26762
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "politicians",
    type: "PERSON",
    salience: 0.00014606497,
    mentions: [
    {
    text: {
    content: "politicians",
    beginOffset: 20483
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "store",
    type: "LOCATION",
    salience: 0.00014596501,
    mentions: [
    {
    text: {
    content: "store",
    beginOffset: 22005
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "store",
    type: "LOCATION",
    salience: 0.00014596501,
    mentions: [
    {
    text: {
    content: "store",
    beginOffset: 21948
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "definition",
    type: "OTHER",
    salience: 0.0001459529,
    mentions: [
    {
    text: {
    content: "definition",
    beginOffset: 22328
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "definitions",
    type: "OTHER",
    salience: 0.00014594688,
    mentions: [
    {
    text: {
    content: "definitions",
    beginOffset: 22420
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "definition",
    type: "OTHER",
    salience: 0.00014594088,
    mentions: [
    {
    text: {
    content: "definition",
    beginOffset: 22437
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "most",
    type: "OTHER",
    salience: 0.0001459349,
    mentions: [
    {
    text: {
    content: "most",
    beginOffset: 22485
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "definition",
    type: "OTHER",
    salience: 0.00014592893,
    mentions: [
    {
    text: {
    content: "definition",
    beginOffset: 22614
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "part",
    type: "OTHER",
    salience: 0.00014592893,
    mentions: [
    {
    text: {
    content: "part",
    beginOffset: 22723
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "back",
    type: "OTHER",
    salience: 0.00014587615,
    mentions: [
    {
    text: {
    content: "back",
    beginOffset: 23608
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "number",
    type: "PERSON",
    salience: 0.00014381611,
    mentions: [
    {
    text: {
    content: "number",
    beginOffset: 20456
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Sean Spicer",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Sean_Spicer",
    mid: "/g/11c5qs9ghc"
    },
    salience: 0.00013603574,
    mentions: [
    {
    text: {
    content: "Sean Spicer",
    beginOffset: 25774
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "many",
    type: "OTHER",
    salience: 0.0001360028,
    mentions: [
    {
    text: {
    content: "many",
    beginOffset: 20140
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "animals",
    type: "OTHER",
    salience: 0.0001360028,
    mentions: [
    {
    text: {
    content: "animals",
    beginOffset: 20296
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "head",
    type: "PERSON",
    salience: 0.00013406915,
    mentions: [
    {
    text: {
    content: "head",
    beginOffset: 24159
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "people",
    type: "PERSON",
    salience: 0.000131894,
    mentions: [
    {
    text: {
    content: "people",
    beginOffset: 23904
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Tripoli",
    type: "LOCATION",
    salience: 0.00013089555,
    mentions: [
    {
    text: {
    content: "Tripoli",
    beginOffset: 30697
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "system",
    type: "OTHER",
    salience: 0.00012587717,
    mentions: [
    {
    text: {
    content: "system",
    beginOffset: 22837
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "system",
    type: "OTHER",
    salience: 0.00012433615,
    mentions: [
    {
    text: {
    content: "system",
    beginOffset: 22736
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "South",
    type: "LOCATION",
    salience: 0.00012409128,
    mentions: [
    {
    text: {
    content: "South",
    beginOffset: 26058
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "folks",
    type: "PERSON",
    salience: 0.00012403226,
    mentions: [
    {
    text: {
    content: "folks",
    beginOffset: 20315
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "expert",
    type: "PERSON",
    salience: 0.00012400503,
    mentions: [
    {
    text: {
    content: "expert",
    beginOffset: 20794
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "person",
    type: "PERSON",
    salience: 0.00012390582,
    mentions: [
    {
    text: {
    content: "person",
    beginOffset: 22667
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "person",
    type: "PERSON",
    salience: 0.00012390582,
    mentions: [
    {
    text: {
    content: "person",
    beginOffset: 22686
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "home",
    type: "LOCATION",
    salience: 0.00012382226,
    mentions: [
    {
    text: {
    content: "home",
    beginOffset: 23914
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "job",
    type: "OTHER",
    salience: 0.00012245007,
    mentions: [
    {
    text: {
    content: "job",
    beginOffset: 21442
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "banjo",
    type: "OTHER",
    salience: 0.00012147843,
    mentions: [
    {
    text: {
    content: "banjo",
    beginOffset: 24272
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "parents",
    type: "PERSON",
    salience: 0.00011945014,
    mentions: [
    {
    text: {
    content: "parents",
    beginOffset: 21869
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "guests",
    type: "PERSON",
    salience: 0.00011935803,
    mentions: [
    {
    text: {
    content: "guests",
    beginOffset: 23662
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sense",
    type: "OTHER",
    salience: 0.00011548767,
    mentions: [
    {
    text: {
    content: "sense",
    beginOffset: 22466
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "sense",
    type: "OTHER",
    salience: 0.00011547822,
    mentions: [
    {
    text: {
    content: "sense",
    beginOffset: 22604
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Native Americans",
    type: "PERSON",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Native_Americans_in_the_United_States",
    mid: "/m/059_w"
    },
    salience: 0.00011421662,
    mentions: [
    {
    text: {
    content: "Native Americans",
    beginOffset: 20266
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "threats",
    type: "OTHER",
    salience: 0.00010995398,
    mentions: [
    {
    text: {
    content: "threats",
    beginOffset: 26960
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Averages",
    type: "OTHER",
    salience: 0.000108927896,
    mentions: [
    {
    text: {
    content: "Averages",
    beginOffset: 20248
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "meals",
    type: "OTHER",
    salience: 0.000108918284,
    mentions: [
    {
    text: {
    content: "meals",
    beginOffset: 20571
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "boy",
    type: "PERSON",
    salience: 0.00010884375,
    mentions: [
    {
    text: {
    content: "boy",
    beginOffset: 22041
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "dictionary",
    type: "OTHER",
    salience: 0.00010881685,
    mentions: [
    {
    text: {
    content: "dictionary",
    beginOffset: 22511
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "bottle",
    type: "CONSUMER_GOOD",
    salience: 0.00010881685,
    mentions: [
    {
    text: {
    content: "bottle",
    beginOffset: 22911
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "place",
    type: "OTHER",
    salience: 0.00010881685,
    mentions: [
    {
    text: {
    content: "place",
    beginOffset: 22746
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "feelings",
    type: "OTHER",
    salience: 0.00010879921,
    mentions: [
    {
    text: {
    content: "feelings",
    beginOffset: 23225
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Hiatus",
    type: "EVENT",
    salience: 0.00010876463,
    mentions: [
    {
    text: {
    content: "Hiatus",
    beginOffset: 23777
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Washington DC",
    type: "LOCATION",
    metadata: {
    mid: "/m/0rh6k",
    wikipedia_url: "https://en.wikipedia.org/wiki/Washington,_D.C."
    },
    salience: 0.00010235929,
    mentions: [
    {
    text: {
    content: "Washington DC",
    beginOffset: 29442
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Goya",
    type: "ORGANIZATION",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Goya_Foods",
    mid: "/m/01hw5l"
    },
    salience: 0.00010233628,
    mentions: [
    {
    text: {
    content: "Goya",
    beginOffset: 30019
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Gunnery",
    type: "ORGANIZATION",
    salience: 0.00010231049,
    mentions: [
    {
    text: {
    content: "Gunnery",
    beginOffset: 30768
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "power",
    type: "OTHER",
    salience: 0.00009270782,
    mentions: [
    {
    text: {
    content: "power",
    beginOffset: 23087
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "talk",
    type: "OTHER",
    salience: 0.00008997635,
    mentions: [
    {
    text: {
    content: "talk",
    beginOffset: 21904
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "hatred",
    type: "OTHER",
    salience: 0.00008197017,
    mentions: [
    {
    text: {
    content: "hatred",
    beginOffset: 22860
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "Prejudice",
    type: "OTHER",
    metadata: {
    wikipedia_url: "https://en.wikipedia.org/wiki/Prejudice",
    mid: "/m/0k7q8"
    },
    salience: 0.00007732112,
    mentions: [
    {
    text: {
    content: "Prejudice",
    beginOffset: 22962
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Grammy Awards",
    type: "OTHER",
    metadata: {
    mid: "/m/0c4ys",
    wikipedia_url: "https://en.wikipedia.org/wiki/Grammy_Award"
    },
    salience: 0.00007316046,
    mentions: [
    {
    text: {
    content: "Grammy Awards",
    beginOffset: 23678
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Pro",
    type: "PERSON",
    salience: 0.00007010289,
    mentions: [
    {
    text: {
    content: "Pro",
    beginOffset: 21534
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "Christians",
    type: "PERSON",
    salience: 0.00006434499,
    mentions: [
    {
    text: {
    content: "Christians",
    beginOffset: 20158
    },
    type: "PROPER"
    }
    ]
    },
    {
    name: "talk",
    type: "OTHER",
    salience: 0.000044042205,
    mentions: [
    {
    text: {
    content: "talk",
    beginOffset: 21837
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "host",
    type: "PERSON",
    salience: 5.329511e-7,
    mentions: [
    {
    text: {
    content: "host",
    beginOffset: 1024
    },
    type: "COMMON"
    },
    {
    text: {
    content: "kid",
    beginOffset: 16660
    },
    type: "COMMON"
    }
    ]
    },
    {
    name: "2016",
    type: "DATE",
    metadata: {
    year: "2016"
    },
    mentions: [
    {
    text: {
    content: "2016",
    beginOffset: 3686
    }
    }
    ]
    },
    {
    name: "2020",
    type: "DATE",
    metadata: {
    year: "2020"
    },
    mentions: [
    {
    text: {
    content: "2020",
    beginOffset: 7100
    }
    }
    ]
    },
    {
    name: "2016",
    type: "DATE",
    metadata: {
    year: "2016"
    },
    mentions: [
    {
    text: {
    content: "2016",
    beginOffset: 8351
    }
    }
    ]
    },
    {
    name: "2020",
    type: "DATE",
    metadata: {
    year: "2020"
    },
    mentions: [
    {
    text: {
    content: "2020",
    beginOffset: 11356
    }
    }
    ]
    },
    {
    name: "2006",
    type: "DATE",
    metadata: {
    year: "2006"
    },
    mentions: [
    {
    text: {
    content: "2006",
    beginOffset: 26526
    }
    }
    ]
    },
    {
    name: "2003",
    type: "DATE",
    metadata: {
    year: "2003"
    },
    mentions: [
    {
    text: {
    content: "2003",
    beginOffset: 26621
    }
    }
    ]
    },
    {
    name: "June 6",
    type: "DATE",
    metadata: {
    month: "6",
    day: "6"
    },
    mentions: [
    {
    text: {
    content: "June 6",
    beginOffset: 28355
    }
    }
    ]
    },
    {
    name: "March 2012",
    type: "DATE",
    metadata: {
    year: "2012",
    month: "3"
    },
    mentions: [
    {
    text: {
    content: "March 2012",
    beginOffset: 31362
    }
    }
    ]
    },
    {
    name: "$1000",
    type: "PRICE",
    metadata: {
    value: "1000.000000",
    currency: "USD"
    },
    mentions: [
    {
    text: {
    content: "$1000",
    beginOffset: 4314
    }
    }
    ]
    },
    {
    name: "two thousand dollars",
    type: "PRICE",
    metadata: {
    currency: "USD",
    value: "2000.000000"
    },
    mentions: [
    {
    text: {
    content: "two thousand dollars",
    beginOffset: 4337
    }
    }
    ]
    },
    {
    name: "nine",
    type: "NUMBER",
    metadata: {
    value: "9"
    },
    mentions: [
    {
    text: {
    content: "nine",
    beginOffset: 15260
    }
    }
    ]
    },
    {
    name: "two",
    type: "NUMBER",
    metadata: {
    value: "2"
    },
    mentions: [
    {
    text: {
    content: "two",
    beginOffset: 15285
    }
    }
    ]
    },
    {
    name: "three",
    type: "NUMBER",
    metadata: {
    value: "3"
    },
    mentions: [
    {
    text: {
    content: "three",
    beginOffset: 20885
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 26852
    }
    }
    ]
    },
    {
    name: "28",
    type: "NUMBER",
    metadata: {
    value: "28"
    },
    mentions: [
    {
    text: {
    content: "28",
    beginOffset: 7581
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 28986
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 25492
    }
    }
    ]
    },
    {
    name: "14",
    type: "NUMBER",
    metadata: {
    value: "14"
    },
    mentions: [
    {
    text: {
    content: "14",
    beginOffset: 27943
    }
    }
    ]
    },
    {
    name: "One",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "One",
    beginOffset: 31055
    }
    }
    ]
    },
    {
    name: "6",
    type: "NUMBER",
    metadata: {
    value: "6"
    },
    mentions: [
    {
    text: {
    content: "6",
    beginOffset: 28360
    }
    }
    ]
    },
    {
    name: "72",
    type: "NUMBER",
    metadata: {
    value: "72"
    },
    mentions: [
    {
    text: {
    content: "72",
    beginOffset: 7496
    }
    }
    ]
    },
    {
    name: "three",
    type: "NUMBER",
    metadata: {
    value: "3"
    },
    mentions: [
    {
    text: {
    content: "three",
    beginOffset: 9102
    }
    }
    ]
    },
    {
    name: "two thousand",
    type: "NUMBER",
    metadata: {
    value: "2000"
    },
    mentions: [
    {
    text: {
    content: "two thousand",
    beginOffset: 4337
    }
    }
    ]
    },
    {
    name: "2020",
    type: "NUMBER",
    metadata: {
    value: "2020"
    },
    mentions: [
    {
    text: {
    content: "2020",
    beginOffset: 11356
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 11435
    }
    }
    ]
    },
    {
    name: "five",
    type: "NUMBER",
    metadata: {
    value: "5"
    },
    mentions: [
    {
    text: {
    content: "five",
    beginOffset: 15368
    }
    }
    ]
    },
    {
    name: "nine",
    type: "NUMBER",
    metadata: {
    value: "9"
    },
    mentions: [
    {
    text: {
    content: "nine",
    beginOffset: 16417
    }
    }
    ]
    },
    {
    name: "six",
    type: "NUMBER",
    metadata: {
    value: "6"
    },
    mentions: [
    {
    text: {
    content: "six",
    beginOffset: 26228
    }
    }
    ]
    },
    {
    name: "three",
    type: "NUMBER",
    metadata: {
    value: "3"
    },
    mentions: [
    {
    text: {
    content: "three",
    beginOffset: 26599
    }
    }
    ]
    },
    {
    name: "nine",
    type: "NUMBER",
    metadata: {
    value: "9"
    },
    mentions: [
    {
    text: {
    content: "nine",
    beginOffset: 15392
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 27225
    }
    }
    ]
    },
    {
    name: "two",
    type: "NUMBER",
    metadata: {
    value: "2"
    },
    mentions: [
    {
    text: {
    content: "two",
    beginOffset: 11991
    }
    }
    ]
    },
    {
    name: "44",
    type: "NUMBER",
    metadata: {
    value: "44"
    },
    mentions: [
    {
    text: {
    content: "44",
    beginOffset: 19275
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 25583
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 27016
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 24241
    }
    }
    ]
    },
    {
    name: "One",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "One",
    beginOffset: 30529
    }
    }
    ]
    },
    {
    name: "three",
    type: "NUMBER",
    metadata: {
    value: "3"
    },
    mentions: [
    {
    text: {
    content: "three",
    beginOffset: 20565
    }
    }
    ]
    },
    {
    name: "two",
    type: "NUMBER",
    metadata: {
    value: "2"
    },
    mentions: [
    {
    text: {
    content: "two",
    beginOffset: 14599
    }
    }
    ]
    },
    {
    name: "19",
    type: "NUMBER",
    metadata: {
    value: "19"
    },
    mentions: [
    {
    text: {
    content: "19",
    beginOffset: 4234
    }
    }
    ]
    },
    {
    name: "four",
    type: "NUMBER",
    metadata: {
    value: "4"
    },
    mentions: [
    {
    text: {
    content: "four",
    beginOffset: 7936
    }
    }
    ]
    },
    {
    name: "five hundred",
    type: "NUMBER",
    metadata: {
    value: "500"
    },
    mentions: [
    {
    text: {
    content: "five hundred",
    beginOffset: 19668
    }
    }
    ]
    },
    {
    name: "two",
    type: "NUMBER",
    metadata: {
    value: "2"
    },
    mentions: [
    {
    text: {
    content: "two",
    beginOffset: 29143
    }
    }
    ]
    },
    {
    name: "70",
    type: "NUMBER",
    metadata: {
    value: "70"
    },
    mentions: [
    {
    text: {
    content: "70",
    beginOffset: 21691
    }
    }
    ]
    },
    {
    name: "113",
    type: "NUMBER",
    metadata: {
    value: "113"
    },
    mentions: [
    {
    text: {
    content: "113",
    beginOffset: 23674
    }
    }
    ]
    },
    {
    name: "29",
    type: "NUMBER",
    metadata: {
    value: "29"
    },
    mentions: [
    {
    text: {
    content: "29",
    beginOffset: 3299
    }
    }
    ]
    },
    {
    name: "31",
    type: "NUMBER",
    metadata: {
    value: "31"
    },
    mentions: [
    {
    text: {
    content: "31",
    beginOffset: 24417
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 8084
    }
    }
    ]
    },
    {
    name: "One",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "One",
    beginOffset: 31425
    }
    }
    ]
    },
    {
    name: "22",
    type: "NUMBER",
    metadata: {
    value: "22"
    },
    mentions: [
    {
    text: {
    content: "22",
    beginOffset: 11353
    }
    }
    ]
    },
    {
    name: "11",
    type: "NUMBER",
    metadata: {
    value: "11"
    },
    mentions: [
    {
    text: {
    content: "11",
    beginOffset: 21521
    }
    }
    ]
    },
    {
    name: "four",
    type: "NUMBER",
    metadata: {
    value: "4"
    },
    mentions: [
    {
    text: {
    content: "four",
    beginOffset: 7902
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 8690
    }
    }
    ]
    },
    {
    name: "One",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "One",
    beginOffset: 22553
    }
    }
    ]
    },
    {
    name: "2016",
    type: "NUMBER",
    metadata: {
    value: "2016"
    },
    mentions: [
    {
    text: {
    content: "2016",
    beginOffset: 8351
    }
    }
    ]
    },
    {
    name: "15",
    type: "NUMBER",
    metadata: {
    value: "15"
    },
    mentions: [
    {
    text: {
    content: "15",
    beginOffset: 7194
    }
    }
    ]
    },
    {
    name: "2003",
    type: "NUMBER",
    metadata: {
    value: "2003"
    },
    mentions: [
    {
    text: {
    content: "2003",
    beginOffset: 26621
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 30612
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 9684
    }
    }
    ]
    },
    {
    name: "2006",
    type: "NUMBER",
    metadata: {
    value: "2006"
    },
    mentions: [
    {
    text: {
    content: "2006",
    beginOffset: 26526
    }
    }
    ]
    },
    {
    name: "One",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "One",
    beginOffset: 30630
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 1974
    }
    }
    ]
    },
    {
    name: "two",
    type: "NUMBER",
    metadata: {
    value: "2"
    },
    mentions: [
    {
    text: {
    content: "two",
    beginOffset: 15340
    }
    }
    ]
    },
    {
    name: "1000",
    type: "NUMBER",
    metadata: {
    value: "1000"
    },
    mentions: [
    {
    text: {
    content: "1000",
    beginOffset: 4315
    }
    }
    ]
    },
    {
    name: "nine",
    type: "NUMBER",
    metadata: {
    value: "9"
    },
    mentions: [
    {
    text: {
    content: "nine",
    beginOffset: 16382
    }
    }
    ]
    },
    {
    name: "2016",
    type: "NUMBER",
    metadata: {
    value: "2016"
    },
    mentions: [
    {
    text: {
    content: "2016",
    beginOffset: 3686
    }
    }
    ]
    },
    {
    name: "100",
    type: "NUMBER",
    metadata: {
    value: "100"
    },
    mentions: [
    {
    text: {
    content: "100",
    beginOffset: 26245
    }
    }
    ]
    },
    {
    name: "four",
    type: "NUMBER",
    metadata: {
    value: "4"
    },
    mentions: [
    {
    text: {
    content: "four",
    beginOffset: 7865
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 31115
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 15771
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 15775
    }
    }
    ]
    },
    {
    name: "four",
    type: "NUMBER",
    metadata: {
    value: "4"
    },
    mentions: [
    {
    text: {
    content: "four",
    beginOffset: 17630
    }
    }
    ]
    },
    {
    name: "five",
    type: "NUMBER",
    metadata: {
    value: "5"
    },
    mentions: [
    {
    text: {
    content: "five",
    beginOffset: 15265
    }
    }
    ]
    },
    {
    name: "2012",
    type: "NUMBER",
    metadata: {
    value: "2012"
    },
    mentions: [
    {
    text: {
    content: "2012",
    beginOffset: 31368
    }
    }
    ]
    },
    {
    name: "2020",
    type: "NUMBER",
    metadata: {
    value: "2020"
    },
    mentions: [
    {
    text: {
    content: "2020",
    beginOffset: 7100
    }
    }
    ]
    },
    {
    name: "two",
    type: "NUMBER",
    metadata: {
    value: "2"
    },
    mentions: [
    {
    text: {
    content: "two",
    beginOffset: 5857
    }
    }
    ]
    },
    {
    name: "5",
    type: "NUMBER",
    metadata: {
    value: "5"
    },
    mentions: [
    {
    text: {
    content: "5",
    beginOffset: 23486
    }
    }
    ]
    },
    {
    name: "14",
    type: "NUMBER",
    metadata: {
    value: "14"
    },
    mentions: [
    {
    text: {
    content: "14",
    beginOffset: 23769
    }
    }
    ]
    },
    {
    name: "24",
    type: "NUMBER",
    metadata: {
    value: "24"
    },
    mentions: [
    {
    text: {
    content: "24",
    beginOffset: 11724
    }
    }
    ]
    },
    {
    name: "19",
    type: "NUMBER",
    metadata: {
    value: "19"
    },
    mentions: [
    {
    text: {
    content: "19",
    beginOffset: 2796
    }
    }
    ]
    },
    {
    name: "three",
    type: "NUMBER",
    metadata: {
    value: "3"
    },
    mentions: [
    {
    text: {
    content: "three",
    beginOffset: 15208
    }
    }
    ]
    },
    {
    name: "One",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "One",
    beginOffset: 25186
    }
    }
    ]
    },
    {
    name: "45",
    type: "NUMBER",
    metadata: {
    value: "45"
    },
    mentions: [
    {
    text: {
    content: "45",
    beginOffset: 19285
    }
    }
    ]
    },
    {
    name: "one",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "one",
    beginOffset: 26291
    }
    }
    ]
    },
    {
    name: "One",
    type: "NUMBER",
    metadata: {
    value: "1"
    },
    mentions: [
    {
    text: {
    content: "One",
    beginOffset: 1613
    }
    }
    ]
    },
    {
    name: "30 million",
    type: "NUMBER",
    metadata: {
    value: "30000000.000000"
    },
    mentions: [
    {
    text: {
    content: "30 million",
    beginOffset: 23703
    }
    }
    ]
    },
    {
    name: "83",
    type: "NUMBER",
    metadata: {
    value: "83"
    },
    mentions: [
    {
    text: {
    content: "83",
    beginOffset: 20755
    }
    }
    ]
    }
    ]