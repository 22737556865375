import React, { FC, useRef, useState } from "react"
import clsx from "clsx"

interface Props {
    color?: string
    size?: number
    strokeWidth?: number
    className?: string
}

const Volume1: FC<Props> = ({ color, size, strokeWidth, className }) => {
    const svgRef = useRef<SVGSVGElement>(null)
    const [fill, setFill] = useState<string | undefined>(undefined)

    const onMouseOver = () => setFill(color)

    const onMouseOut = () => setFill(undefined)

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={!size ? "24" : size}
            height={!size ? "24" : size}
            viewBox="0 0 24 24"
            fill={!fill ? "none" : fill}
            color={color}
            stroke="currentColor"
            strokeWidth={!strokeWidth ? "1" : strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            className={clsx("feather feather-volume-1", className)}
            ref={svgRef}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
            <path d="M15.54 8.46a5 5 0 0 1 0 7.07" />
        </svg>
    )
}

export default Volume1
