import React, { FC } from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles({
    textButton: {
        backgroundColor: "transparent",
        outline: 0,
        border: 0,
        padding: 0,
        margin: 0,
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 14,
        lineHeight: "14px",
        color: "#2F232D",
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
    }
})

interface Props {
    onClick: (value?: any) => void
    className?: string
}

const TextButton: FC<Props> = ({ children, onClick, className }) => {
    const classes = useStyles()
    return (
        <button className={clsx(classes.textButton, className)} onClick={onClick}>
            {children}
        </button>
    )
}

export default TextButton
