export const topicData = {
    "Brian Kemp": {
    token_signal_list: [
    {
    token_display_name: "Brian Kemp",
    token: "https://en.wikipedia.org/wiki/Brian_Kemp",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 6,
    weight_label: "Auto Added",
    default_weight: 3,
    mention_list: [
    {
    mention_text: "mask",
    mention_start_char: 2073,
    mention_end_char: 2077,
    mention_word_count: 1,
    mention_word_index: 385,
    start_time_milliseconds: 170500,
    end_time_milliseconds: 171800,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8261989821708458
    },
    {
    mention_text: "Brian Kemp",
    mention_start_char: 2078,
    mention_end_char: 2088,
    mention_word_count: 2,
    mention_word_index: 386,
    start_time_milliseconds: 171400,
    end_time_milliseconds: 172200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.82724451814476
    },
    {
    mention_text: "Kemp",
    mention_start_char: 2193,
    mention_end_char: 2197,
    mention_word_count: 1,
    mention_word_index: 404,
    start_time_milliseconds: 179600,
    end_time_milliseconds: 180300,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8330278384559094
    },
    {
    mention_text: "Governor",
    mention_start_char: 2184,
    mention_end_char: 2192,
    mention_word_count: 1,
    mention_word_index: 403,
    start_time_milliseconds: 179200,
    end_time_milliseconds: 179900,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8329573597259269
    },
    {
    mention_text: "Kemp",
    mention_start_char: 2402,
    mention_end_char: 2406,
    mention_word_count: 1,
    mention_word_index: 439,
    start_time_milliseconds: 193300,
    end_time_milliseconds: 193800,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.829566967364173
    },
    {
    mention_text: "Kemp",
    mention_start_char: 2463,
    mention_end_char: 2467,
    mention_word_count: 1,
    mention_word_index: 449,
    start_time_milliseconds: 196800,
    end_time_milliseconds: 197600,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8231953018553023
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Brian_Kemp",
    signal: 14.916572903150753,
    confidence: 0.9229638005744163
    }
    ],
    total_signal: 14.916572903150753,
    total_confidence: 0.9229638005744163,
    topic_category: "Uncategorized"
    },
    "Donald Trump": {
    token_signal_list: [
    {
    token_display_name: "Donald Trump",
    token: "https://en.wikipedia.org/wiki/Donald_Trump",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 15,
    weight_label: "Auto Added",
    default_weight: 3,
    mention_list: [
    {
    mention_text: "Donald Trump",
    mention_start_char: 631,
    mention_end_char: 643,
    mention_word_count: 2,
    mention_word_index: 115,
    start_time_milliseconds: 58500,
    end_time_milliseconds: 59200,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.13822532991781547
    },
    {
    mention_text: "Donald Trump",
    mention_start_char: 2422,
    mention_end_char: 2434,
    mention_word_count: 2,
    mention_word_index: 443,
    start_time_milliseconds: 194200,
    end_time_milliseconds: 194700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.14939162793919408
    },
    {
    mention_text: "Trump",
    mention_start_char: 6252,
    mention_end_char: 6257,
    mention_word_count: 1,
    mention_word_index: 1098,
    start_time_milliseconds: 451600,
    end_time_milliseconds: 452500,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.22666607030821836
    },
    {
    mention_text: "Trump",
    mention_start_char: 8224,
    mention_end_char: 8229,
    mention_word_count: 1,
    mention_word_index: 1460,
    start_time_milliseconds: 609200,
    end_time_milliseconds: 609600,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8109975959076915
    },
    {
    mention_text: "Trump",
    mention_start_char: 8723,
    mention_end_char: 8728,
    mention_word_count: 1,
    mention_word_index: 1560,
    start_time_milliseconds: 641600,
    end_time_milliseconds: 641900,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8955664468052253
    },
    {
    mention_text: "Trump",
    mention_start_char: 8949,
    mention_end_char: 8954,
    mention_word_count: 1,
    mention_word_index: 1599,
    start_time_milliseconds: 658300,
    end_time_milliseconds: 659200,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.9096435541018266
    },
    {
    mention_text: "Trump",
    mention_start_char: 9357,
    mention_end_char: 9362,
    mention_word_count: 1,
    mention_word_index: 1670,
    start_time_milliseconds: 682300,
    end_time_milliseconds: 683300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8178360973631813
    },
    {
    mention_text: "Trump",
    mention_start_char: 9454,
    mention_end_char: 9459,
    mention_word_count: 1,
    mention_word_index: 1686,
    start_time_milliseconds: 691100,
    end_time_milliseconds: 691700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.7582427846597558
    },
    {
    mention_text: "Donald Trump",
    mention_start_char: 16437,
    mention_end_char: 16449,
    mention_word_count: 2,
    mention_word_index: 2990,
    start_time_milliseconds: 1198500,
    end_time_milliseconds: 1199100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.11712293770942
    },
    {
    mention_text: "Trump",
    mention_start_char: 7419,
    mention_end_char: 7424,
    mention_word_count: 1,
    mention_word_index: 1306,
    start_time_milliseconds: 555700,
    end_time_milliseconds: 556600,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.5399863741468602
    },
    {
    mention_text: "Trump",
    mention_start_char: 7456,
    mention_end_char: 7461,
    mention_word_count: 1,
    mention_word_index: 1313,
    start_time_milliseconds: 557800,
    end_time_milliseconds: 558800,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.5533402978885825
    },
    {
    mention_text: "Trump",
    mention_start_char: 8457,
    mention_end_char: 8462,
    mention_word_count: 1,
    mention_word_index: 1508,
    start_time_milliseconds: 623900,
    end_time_milliseconds: 624300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8230438971712978
    },
    {
    mention_text: "Trump",
    mention_start_char: 8470,
    mention_end_char: 8475,
    mention_word_count: 1,
    mention_word_index: 1511,
    start_time_milliseconds: 624500,
    end_time_milliseconds: 625100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8248494132891184
    },
    {
    mention_text: "Trump",
    mention_start_char: 9584,
    mention_end_char: 9589,
    mention_word_count: 1,
    mention_word_index: 1707,
    start_time_milliseconds: 699300,
    end_time_milliseconds: 700300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.7013550218924983
    },
    {
    mention_text: "Trump",
    mention_start_char: 30459,
    mention_end_char: 30464,
    mention_word_count: 1,
    mention_word_index: 5694,
    start_time_milliseconds: 2151700,
    end_time_milliseconds: 2152900,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.10051047172187168
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Donald_Trump",
    signal: 25.100333762467677,
    confidence: 0.989228981226167
    }
    ],
    total_signal: 25.100333762467677,
    total_confidence: 0.989228981226167,
    topic_category: "Uncategorized"
    },
    "Stephen Colbert": {
    token_signal_list: [
    {
    token_display_name: "Stephen Colbert",
    token: "https://en.wikipedia.org/wiki/Stephen_Colbert",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 5,
    weight_label: "Auto Added",
    default_weight: 3,
    mention_list: [
    {
    mention_text: "Stephen Colbert",
    mention_start_char: 681,
    mention_end_char: 696,
    mention_word_count: 2,
    mention_word_index: 126,
    start_time_milliseconds: 65400,
    end_time_milliseconds: 67200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.48575587155629396
    },
    {
    mention_text: "Stephen",
    mention_start_char: 730,
    mention_end_char: 737,
    mention_word_count: 1,
    mention_word_index: 134,
    start_time_milliseconds: 70900,
    end_time_milliseconds: 72200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.4984828830926518
    },
    {
    mention_text: "Stephen Colbert",
    mention_start_char: 848,
    mention_end_char: 863,
    mention_word_count: 2,
    mention_word_index: 156,
    start_time_milliseconds: 85500,
    end_time_milliseconds: 86900,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.514935606167008
    },
    {
    mention_text: "Stephen Colbert",
    mention_start_char: 1029,
    mention_end_char: 1044,
    mention_word_count: 2,
    mention_word_index: 194,
    start_time_milliseconds: 105100,
    end_time_milliseconds: 106000,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.46793015654895037
    },
    {
    mention_text: "Stephen Colbert",
    mention_start_char: 23013,
    mention_end_char: 23028,
    mention_word_count: 2,
    mention_word_index: 4249,
    start_time_milliseconds: 1580600,
    end_time_milliseconds: 1581400,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.06635195165971695
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Stephen_Colbert",
    signal: 6.100369407073863,
    confidence: 0.6726232860515149
    }
    ],
    total_signal: 6.100369407073863,
    total_confidence: 0.6726232860515149,
    topic_category: "Uncategorized"
    },
    "United States": {
    token_signal_list: [
    {
    token_display_name: "United States",
    token: "https://en.wikipedia.org/wiki/United_States",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 19,
    weight_label: "Auto Added",
    default_weight: 3,
    mention_list: [
    {
    mention_text: "United States",
    mention_start_char: 1125,
    mention_end_char: 1138,
    mention_word_count: 2,
    mention_word_index: 209,
    start_time_milliseconds: 111100,
    end_time_milliseconds: 111400,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.1677291336326988
    },
    {
    mention_text: "United States",
    mention_start_char: 3805,
    mention_end_char: 3818,
    mention_word_count: 2,
    mention_word_index: 677,
    start_time_milliseconds: 287500,
    end_time_milliseconds: 288500,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.33853324126317297
    },
    {
    mention_text: "United",
    mention_start_char: 13515,
    mention_end_char: 13521,
    mention_word_count: 1,
    mention_word_index: 2434,
    start_time_milliseconds: 1005000,
    end_time_milliseconds: 1005900,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.4456947286813387
    },
    {
    mention_text: "United",
    mention_start_char: 13654,
    mention_end_char: 13660,
    mention_word_count: 1,
    mention_word_index: 2457,
    start_time_milliseconds: 1026099,
    end_time_milliseconds: 1027200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.4577256564087775
    },
    {
    mention_text: "United",
    mention_start_char: 17573,
    mention_end_char: 17579,
    mention_word_count: 1,
    mention_word_index: 3208,
    start_time_milliseconds: 1262300,
    end_time_milliseconds: 1262900,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8569385387259506
    },
    {
    mention_text: "United",
    mention_start_char: 23491,
    mention_end_char: 23497,
    mention_word_count: 1,
    mention_word_index: 4339,
    start_time_milliseconds: 1606700,
    end_time_milliseconds: 1607600,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.30237263871980663
    },
    {
    mention_text: "America",
    mention_start_char: 2845,
    mention_end_char: 2852,
    mention_word_count: 1,
    mention_word_index: 511,
    start_time_milliseconds: 219900,
    end_time_milliseconds: 220700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.28807790305553416
    },
    {
    mention_text: "America",
    mention_start_char: 7639,
    mention_end_char: 7646,
    mention_word_count: 1,
    mention_word_index: 1348,
    start_time_milliseconds: 572300,
    end_time_milliseconds: 573000,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.1312589995838287
    },
    {
    mention_text: "America",
    mention_start_char: 13671,
    mention_end_char: 13678,
    mention_word_count: 1,
    mention_word_index: 2460,
    start_time_milliseconds: 1027200,
    end_time_milliseconds: 1028400,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.45777587944185977
    },
    {
    mention_text: "America",
    mention_start_char: 17164,
    mention_end_char: 17171,
    mention_word_count: 1,
    mention_word_index: 3131,
    start_time_milliseconds: 1235600,
    end_time_milliseconds: 1236500,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.6928657610727228
    },
    {
    mention_text: "America",
    mention_start_char: 17590,
    mention_end_char: 17597,
    mention_word_count: 1,
    mention_word_index: 3211,
    start_time_milliseconds: 1263000,
    end_time_milliseconds: 1263700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8602217682219756
    },
    {
    mention_text: "America",
    mention_start_char: 18186,
    mention_end_char: 18193,
    mention_word_count: 1,
    mention_word_index: 3319,
    start_time_milliseconds: 1300700,
    end_time_milliseconds: 1301400,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8389215058476077
    },
    {
    mention_text: "America",
    mention_start_char: 18720,
    mention_end_char: 18727,
    mention_word_count: 1,
    mention_word_index: 3417,
    start_time_milliseconds: 1333700,
    end_time_milliseconds: 1334700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.5829929045236565
    },
    {
    mention_text: "America",
    mention_start_char: 23508,
    mention_end_char: 23515,
    mention_word_count: 1,
    mention_word_index: 4342,
    start_time_milliseconds: 1607600,
    end_time_milliseconds: 1608600,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.30195950164817925
    },
    {
    mention_text: "United States",
    mention_start_char: 3562,
    mention_end_char: 3575,
    mention_word_count: 2,
    mention_word_index: 636,
    start_time_milliseconds: 273700,
    end_time_milliseconds: 274600,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.3631553879738549
    },
    {
    mention_text: "United States",
    mention_start_char: 15448,
    mention_end_char: 15461,
    mention_word_count: 2,
    mention_word_index: 2794,
    start_time_milliseconds: 1142300,
    end_time_milliseconds: 1142700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.2707688702894658
    },
    {
    mention_text: "United States",
    mention_start_char: 17719,
    mention_end_char: 17732,
    mention_word_count: 2,
    mention_word_index: 3233,
    start_time_milliseconds: 1272800,
    end_time_milliseconds: 1273500,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8883263101287362
    },
    {
    mention_text: "United States",
    mention_start_char: 18107,
    mention_end_char: 18120,
    mention_word_count: 2,
    mention_word_index: 3305,
    start_time_milliseconds: 1296000,
    end_time_milliseconds: 1296600,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8618762358280997
    },
    {
    mention_text: "United States",
    mention_start_char: 21277,
    mention_end_char: 21290,
    mention_word_count: 2,
    mention_word_index: 3910,
    start_time_milliseconds: 1482900,
    end_time_milliseconds: 1483700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.20885274147098132
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/United_States",
    signal: 27.948143119554747,
    confidence: 0.993877409783697
    }
    ],
    total_signal: 27.948143119554747,
    total_confidence: 0.993877409783697,
    topic_category: "Uncategorized"
    },
    California: {
    token_signal_list: [
    {
    token_display_name: "California",
    token: "https://en.wikipedia.org/wiki/California",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 5,
    weight_label: "Auto Added",
    default_weight: 3,
    mention_list: [
    {
    mention_text: "California",
    mention_start_char: 9862,
    mention_end_char: 9872,
    mention_word_count: 1,
    mention_word_index: 1759,
    start_time_milliseconds: 720200,
    end_time_milliseconds: 720800,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.0882130672678904
    },
    {
    mention_text: "California",
    mention_start_char: 13936,
    mention_end_char: 13946,
    mention_word_count: 1,
    mention_word_index: 2507,
    start_time_milliseconds: 1047099,
    end_time_milliseconds: 1048200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.4079465813031438
    },
    {
    mention_text: "California",
    mention_start_char: 14134,
    mention_end_char: 14144,
    mention_word_count: 1,
    mention_word_index: 2540,
    start_time_milliseconds: 1058400,
    end_time_milliseconds: 1059100,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.4175253253719385
    },
    {
    mention_text: "California",
    mention_start_char: 14305,
    mention_end_char: 14315,
    mention_word_count: 1,
    mention_word_index: 2570,
    start_time_milliseconds: 1069900,
    end_time_milliseconds: 1070500,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.4262220997438823
    },
    {
    mention_text: "California",
    mention_start_char: 15481,
    mention_end_char: 15491,
    mention_word_count: 1,
    mention_word_index: 2799,
    start_time_milliseconds: 1143100,
    end_time_milliseconds: 1143700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.20933776182711114
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/California",
    signal: 4.647734506541898,
    confidence: 0.6057654674592825
    }
    ],
    total_signal: 4.647734506541898,
    total_confidence: 0.6057654674592825,
    topic_category: "Uncategorized"
    },
    CNN: {
    token_signal_list: [
    {
    token_display_name: "CNN",
    token: "https://en.wikipedia.org/wiki/CNN",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 4,
    weight_label: "Auto Added",
    default_weight: 3,
    mention_list: [
    {
    mention_text: "CNN",
    mention_start_char: 13643,
    mention_end_char: 13646,
    mention_word_count: 1,
    mention_word_index: 2455,
    start_time_milliseconds: 1025200,
    end_time_milliseconds: 1025700,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.07896543015599088
    },
    {
    mention_text: "CNN",
    mention_start_char: 16271,
    mention_end_char: 16274,
    mention_word_count: 1,
    mention_word_index: 2959,
    start_time_milliseconds: 1188200,
    end_time_milliseconds: 1188800,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.1271353785361483
    },
    {
    mention_text: "CNN",
    mention_start_char: 17554,
    mention_end_char: 17557,
    mention_word_count: 1,
    mention_word_index: 3204,
    start_time_milliseconds: 1261400,
    end_time_milliseconds: 1261900,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.12156131070369884
    },
    {
    mention_text: "CNN",
    mention_start_char: 23541,
    mention_end_char: 23544,
    mention_word_count: 1,
    mention_word_index: 4347,
    start_time_milliseconds: 1609800,
    end_time_milliseconds: 1610600,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.06210468881369152
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/CNN",
    signal: 1.1693004246285887,
    confidence: 0.43385498456773935
    }
    ],
    total_signal: 1.1693004246285887,
    total_confidence: 0.43385498456773935,
    topic_category: "Uncategorized"
    },
    Coronavirus: {
    token_signal_list: [
    {
    token_display_name: "pandemic",
    token: "pandemic",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Med",
    default_weight: 0.7,
    mention_list: [
    {
    mention_word_index: 489,
    mention_start_char: 2175,
    mention_end_char: 2183,
    mention_word_count: 1,
    mention_text: "pandemic",
    start_time_milliseconds: 210900,
    end_time_milliseconds: 211400,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.7793787224366978
    },
    {
    mention_word_index: 2202,
    mention_start_char: 9837,
    mention_end_char: 9845,
    mention_word_count: 1,
    mention_text: "pandemic",
    start_time_milliseconds: 921200,
    end_time_milliseconds: 921700,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.1697530799925967
    }
    ],
    signal: 0.664392261700506,
    confidence: 0.40923673980782194
    },
    {
    token_display_name: "Coronavirus",
    token: "coronavirus",
    token_type: "word",
    token_frequency: 6,
    weight_label: "High",
    default_weight: 1,
    mention_list: [
    {
    mention_word_index: 204,
    mention_start_char: 873,
    mention_end_char: 884,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 108400,
    end_time_milliseconds: 109100,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.3532378007705363
    },
    {
    mention_word_index: 512,
    mention_start_char: 2311,
    mention_end_char: 2322,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 220300,
    end_time_milliseconds: 220700,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.7835323804853448
    },
    {
    mention_word_index: 534,
    mention_start_char: 2420,
    mention_end_char: 2431,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 228400,
    end_time_milliseconds: 228900,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.7784416341032839
    },
    {
    mention_word_index: 554,
    mention_start_char: 2523,
    mention_end_char: 2534,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 234600,
    end_time_milliseconds: 235000,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.7705392706788273
    },
    {
    mention_word_index: 1091,
    mention_start_char: 5050,
    mention_end_char: 5061,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 447800,
    end_time_milliseconds: 448800,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.4407468618300883
    },
    {
    mention_word_index: 4441,
    mention_start_char: 19301,
    mention_end_char: 19312,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 1660400,
    end_time_milliseconds: 1660900,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.11213659526420834
    }
    ],
    signal: 3.2386345431322887,
    confidence: 0.5368647092469319
    },
    {
    token_display_name: "virus",
    token: "virus",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 683,
    mention_start_char: 3116,
    mention_end_char: 3122,
    mention_word_count: 1,
    mention_text: "virus,",
    start_time_milliseconds: 290700,
    end_time_milliseconds: 291600,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.4149801625599389
    },
    {
    mention_word_index: 2522,
    mention_start_char: 11311,
    mention_end_char: 11316,
    mention_word_count: 1,
    mention_text: "virus",
    start_time_milliseconds: 1052800,
    end_time_milliseconds: 1053200,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.16316900265504974
    }
    ],
    signal: 0.23125966608599546,
    confidence: 0.38847000001935394
    },
    {
    token_display_name: "Anthony Fauci",
    token: "https://en.wikipedia.org/wiki/Anthony_Fauci",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 2,
    weight_label: "Med",
    default_weight: 2.1,
    mention_list: [
    {
    mention_text: "Fauci",
    mention_start_char: 6896,
    mention_end_char: 6901,
    mention_word_count: 1,
    mention_word_index: 1217,
    start_time_milliseconds: 521700,
    end_time_milliseconds: 522100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.6669292194668374
    },
    {
    mention_text: "Dr.",
    mention_start_char: 6892,
    mention_end_char: 6895,
    mention_word_count: 1,
    mention_word_index: 1216,
    start_time_milliseconds: 521500,
    end_time_milliseconds: 521700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.666111579794132
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Anthony_Fauci",
    signal: 2.799385678448036,
    confidence: 0.5149648131132012
    },
    {
    token_display_name: "Donald Trump",
    token: "https://en.wikipedia.org/wiki/Donald_Trump",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 15,
    weight_label: "Low",
    default_weight: 1.2,
    mention_list: [
    {
    mention_text: "Donald Trump",
    mention_start_char: 631,
    mention_end_char: 643,
    mention_word_count: 2,
    mention_word_index: 115,
    start_time_milliseconds: 58500,
    end_time_milliseconds: 59200,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.13822532991781547
    },
    {
    mention_text: "Donald Trump",
    mention_start_char: 2422,
    mention_end_char: 2434,
    mention_word_count: 2,
    mention_word_index: 443,
    start_time_milliseconds: 194200,
    end_time_milliseconds: 194700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.14939162793919408
    },
    {
    mention_text: "Trump",
    mention_start_char: 6252,
    mention_end_char: 6257,
    mention_word_count: 1,
    mention_word_index: 1098,
    start_time_milliseconds: 451600,
    end_time_milliseconds: 452500,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.22666607030821836
    },
    {
    mention_text: "Trump",
    mention_start_char: 8224,
    mention_end_char: 8229,
    mention_word_count: 1,
    mention_word_index: 1460,
    start_time_milliseconds: 609200,
    end_time_milliseconds: 609600,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8109975959076915
    },
    {
    mention_text: "Trump",
    mention_start_char: 8723,
    mention_end_char: 8728,
    mention_word_count: 1,
    mention_word_index: 1560,
    start_time_milliseconds: 641600,
    end_time_milliseconds: 641900,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8955664468052253
    },
    {
    mention_text: "Trump",
    mention_start_char: 8949,
    mention_end_char: 8954,
    mention_word_count: 1,
    mention_word_index: 1599,
    start_time_milliseconds: 658300,
    end_time_milliseconds: 659200,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.9096435541018266
    },
    {
    mention_text: "Trump",
    mention_start_char: 9357,
    mention_end_char: 9362,
    mention_word_count: 1,
    mention_word_index: 1670,
    start_time_milliseconds: 682300,
    end_time_milliseconds: 683300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8178360973631813
    },
    {
    mention_text: "Trump",
    mention_start_char: 9454,
    mention_end_char: 9459,
    mention_word_count: 1,
    mention_word_index: 1686,
    start_time_milliseconds: 691100,
    end_time_milliseconds: 691700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.7582427846597558
    },
    {
    mention_text: "Donald Trump",
    mention_start_char: 16437,
    mention_end_char: 16449,
    mention_word_count: 2,
    mention_word_index: 2990,
    start_time_milliseconds: 1198500,
    end_time_milliseconds: 1199100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.11712293770942
    },
    {
    mention_text: "Trump",
    mention_start_char: 7419,
    mention_end_char: 7424,
    mention_word_count: 1,
    mention_word_index: 1306,
    start_time_milliseconds: 555700,
    end_time_milliseconds: 556600,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.5399863741468602
    },
    {
    mention_text: "Trump",
    mention_start_char: 7456,
    mention_end_char: 7461,
    mention_word_count: 1,
    mention_word_index: 1313,
    start_time_milliseconds: 557800,
    end_time_milliseconds: 558800,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.5533402978885825
    },
    {
    mention_text: "Trump",
    mention_start_char: 8457,
    mention_end_char: 8462,
    mention_word_count: 1,
    mention_word_index: 1508,
    start_time_milliseconds: 623900,
    end_time_milliseconds: 624300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8230438971712978
    },
    {
    mention_text: "Trump",
    mention_start_char: 8470,
    mention_end_char: 8475,
    mention_word_count: 1,
    mention_word_index: 1511,
    start_time_milliseconds: 624500,
    end_time_milliseconds: 625100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8248494132891184
    },
    {
    mention_text: "Trump",
    mention_start_char: 9584,
    mention_end_char: 9589,
    mention_word_count: 1,
    mention_word_index: 1707,
    start_time_milliseconds: 699300,
    end_time_milliseconds: 700300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.7013550218924983
    },
    {
    mention_text: "Trump",
    mention_start_char: 30459,
    mention_end_char: 30464,
    mention_word_count: 1,
    mention_word_index: 5694,
    start_time_milliseconds: 2151700,
    end_time_milliseconds: 2152900,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.10051047172187168
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Donald_Trump",
    signal: 11.636701559292257,
    confidence: 0.8614445773278872
    }
    ],
    total_signal: 18.570373708659083,
    total_confidence: 0.9613605127165183,
    topic_category: "Politics + News"
    },
    "Defund Police": {
    token_signal_list: [
    {
    token_display_name: "racism",
    token: "racism",
    token_type: "word",
    token_frequency: 13,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 2173,
    mention_start_char: 9702,
    mention_end_char: 9708,
    mention_word_count: 1,
    mention_text: "Racism",
    start_time_milliseconds: 912400,
    end_time_milliseconds: 912900,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.6591375298990901
    },
    {
    mention_word_index: 3313,
    mention_start_char: 14601,
    mention_end_char: 14607,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1298200,
    end_time_milliseconds: 1298900,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.5724630993185271
    },
    {
    mention_word_index: 3321,
    mention_start_char: 14633,
    mention_end_char: 14639,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1301400,
    end_time_milliseconds: 1302000,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.5513708952192085
    },
    {
    mention_word_index: 3585,
    mention_start_char: 15767,
    mention_end_char: 15773,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1388700,
    end_time_milliseconds: 1389400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.4423332418200426
    },
    {
    mention_word_index: 3636,
    mention_start_char: 15984,
    mention_end_char: 15990,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1406500,
    end_time_milliseconds: 1407100,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.4652699313439648
    },
    {
    mention_word_index: 3813,
    mention_start_char: 16726,
    mention_end_char: 16732,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1455900,
    end_time_milliseconds: 1456400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.4636499842710416
    },
    {
    mention_word_index: 3987,
    mention_start_char: 17388,
    mention_end_char: 17394,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1508900,
    end_time_milliseconds: 1509600,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.6293829641442408
    },
    {
    mention_word_index: 4111,
    mention_start_char: 17877,
    mention_end_char: 17883,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1539500,
    end_time_milliseconds: 1540300,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8024275190683371
    },
    {
    mention_word_index: 4161,
    mention_start_char: 18090,
    mention_end_char: 18096,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1552800,
    end_time_milliseconds: 1553500,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8598112495913589
    },
    {
    mention_word_index: 4178,
    mention_start_char: 18168,
    mention_end_char: 18174,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1558800,
    end_time_milliseconds: 1559400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8814792776701822
    },
    {
    mention_word_index: 4260,
    mention_start_char: 18530,
    mention_end_char: 18536,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1584500,
    end_time_milliseconds: 1585000,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8418110619966499
    },
    {
    mention_word_index: 4295,
    mention_start_char: 18682,
    mention_end_char: 18688,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1592900,
    end_time_milliseconds: 1593400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.7834441142694392
    },
    {
    mention_word_index: 4329,
    mention_start_char: 18820,
    mention_end_char: 18826,
    mention_word_count: 1,
    mention_text: "racism",
    start_time_milliseconds: 1602300,
    end_time_milliseconds: 1602700,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.7151474987177192
    }
    ],
    signal: 3.4670913469319213,
    confidence: 0.5482043812870908
    },
    {
    token_display_name: "police",
    token: "police",
    token_type: "word",
    token_frequency: 3,
    weight_label: "Med",
    default_weight: 0.7,
    mention_list: [
    {
    mention_word_index: 2175,
    mention_start_char: 9711,
    mention_end_char: 9717,
    mention_word_count: 1,
    mention_text: "police",
    start_time_milliseconds: 913000,
    end_time_milliseconds: 913300,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.06603289299566865
    },
    {
    mention_word_index: 3095,
    mention_start_char: 13644,
    mention_end_char: 13650,
    mention_word_count: 1,
    mention_text: "police",
    start_time_milliseconds: 1226600,
    end_time_milliseconds: 1226800,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.20776140454639577
    },
    {
    mention_word_index: 3128,
    mention_start_char: 13786,
    mention_end_char: 13792,
    mention_word_count: 1,
    mention_text: "police",
    start_time_milliseconds: 1234900,
    end_time_milliseconds: 1235100,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.20748572950022673
    }
    ],
    signal: 1.74098190289399,
    confidence: 0.462121807048843
    },
    {
    token_display_name: "protest",
    token: "protest",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Med",
    default_weight: 0.7,
    mention_list: [
    {
    mention_word_index: 2327,
    mention_start_char: 10398,
    mention_end_char: 10405,
    mention_word_count: 1,
    mention_text: "protest",
    start_time_milliseconds: 961700,
    end_time_milliseconds: 962200,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.5102312013540543
    },
    {
    mention_word_index: 5392,
    mention_start_char: 23129,
    mention_end_char: 23136,
    mention_word_count: 1,
    mention_text: "protest",
    start_time_milliseconds: 1995100,
    end_time_milliseconds: 1995700,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.1683802799004582
    }
    ],
    signal: 0.4750280368781587,
    confidence: 0.4001129770320288
    },
    {
    token_display_name: "police",
    token: "police",
    token_type: "image_label",
    token_frequency: 1,
    weight_label: "Med",
    default_weight: 0.2,
    mention_list: [
    {
    mention_text: "police",
    frame_index: 62264,
    start_time_milliseconds: 2077542,
    end_time_milliseconds: 2077542,
    label_weight: 0.2,
    token_cluster_weight_adjustment: 0.1630522241058132
    }
    ],
    signal: 0.03261044482116264,
    confidence: 0.3790746036179574
    },
    {
    token_display_name: "George Floyd",
    token: "https://en.wikipedia.org/wiki/George_Floyd",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 3,
    weight_label: "Med",
    default_weight: 2.1,
    mention_list: [
    {
    mention_text: "George Floyd",
    mention_start_char: 12098,
    mention_end_char: 12110,
    mention_word_count: 2,
    mention_word_index: 2181,
    start_time_milliseconds: 914800,
    end_time_milliseconds: 915700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.6652772084147159
    },
    {
    mention_text: "George Floyd",
    mention_start_char: 16951,
    mention_end_char: 16963,
    mention_word_count: 2,
    mention_word_index: 3092,
    start_time_milliseconds: 1225400,
    end_time_milliseconds: 1226400,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.906800693063988
    },
    {
    mention_text: "George Floyd",
    mention_start_char: 17127,
    mention_end_char: 17139,
    mention_word_count: 2,
    mention_word_index: 3125,
    start_time_milliseconds: 1234100,
    end_time_milliseconds: 1234700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.9181533221941308
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/George_Floyd",
    signal: 5.229485569712953,
    confidence: 0.6331831672624824
    },
    {
    token_display_name: "police brutality",
    token: "police brutality",
    token_type: "word_multiword",
    token_frequency: 3,
    weight_label: "Med",
    default_weight: 1,
    mention_list: [
    {
    mention_start_char: 12057,
    mention_end_char: 12073,
    mention_word_count: 2,
    mention_text: "police brutality",
    mention_word_index: 2175,
    start_time_milliseconds: 913000,
    end_time_milliseconds: 913900,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.66099891477899
    },
    {
    mention_start_char: 16968,
    mention_end_char: 16984,
    mention_word_count: 2,
    mention_text: "police brutality",
    mention_word_index: 3095,
    start_time_milliseconds: 1226600,
    end_time_milliseconds: 1227400,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.90810738075018
    },
    {
    mention_start_char: 17144,
    mention_end_char: 17160,
    mention_word_count: 2,
    mention_text: "police brutality",
    mention_word_index: 3128,
    start_time_milliseconds: 1234900,
    end_time_milliseconds: 1235500,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.9193826864836563
    }
    ],
    signal: 2.4884889820128264,
    confidence: 0.4994244493548496
    }
    ],
    total_signal: 13.433686283251012,
    total_confidence: 0.899052173762294,
    topic_category: "Politics + News"
    },
    "2020 Presidential Election": {
    token_signal_list: [
    {
    token_display_name: "polls",
    token: "polls",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 1468,
    mention_start_char: 6700,
    mention_end_char: 6705,
    mention_word_count: 1,
    mention_text: "polls",
    start_time_milliseconds: 610700,
    end_time_milliseconds: 611400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.9825847690781949
    },
    {
    mention_word_index: 1567,
    mention_start_char: 7084,
    mention_end_char: 7089,
    mention_word_count: 1,
    mention_text: "polls",
    start_time_milliseconds: 642900,
    end_time_milliseconds: 643500,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.9986468910786782
    }
    ],
    signal: 0.7924926640627493,
    confidence: 0.41544479890067293
    },
    {
    token_display_name: "campaign",
    token: "campaign",
    token_type: "word",
    token_frequency: 4,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 428,
    mention_start_char: 1879,
    mention_end_char: 1887,
    mention_word_count: 1,
    mention_text: "campaign",
    start_time_milliseconds: 187400,
    end_time_milliseconds: 188200,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.3644605016325526
    },
    {
    mention_word_index: 1573,
    mention_start_char: 7115,
    mention_end_char: 7123,
    mention_word_count: 1,
    mention_text: "campaign",
    start_time_milliseconds: 645600,
    end_time_milliseconds: 646200,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.9969221263766185
    },
    {
    mention_word_index: 1600,
    mention_start_char: 7250,
    mention_end_char: 7258,
    mention_word_count: 1,
    mention_text: "campaign",
    start_time_milliseconds: 658700,
    end_time_milliseconds: 659200,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.9965504103798911
    },
    {
    mention_word_index: 1633,
    mention_start_char: 7407,
    mention_end_char: 7415,
    mention_word_count: 1,
    mention_text: "campaign",
    start_time_milliseconds: 669800,
    end_time_milliseconds: 670400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.9891362127433506
    }
    ],
    signal: 1.3388277004529652,
    confidence: 0.44220092419769713
    },
    {
    token_display_name: "vote",
    token: "vote",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 71,
    mention_start_char: 292,
    mention_end_char: 296,
    mention_word_count: 1,
    mention_text: "vote",
    start_time_milliseconds: 35000,
    end_time_milliseconds: 35300,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8234912429241698
    },
    {
    mention_word_index: 2354,
    mention_start_char: 10537,
    mention_end_char: 10541,
    mention_word_count: 1,
    mention_text: "vote",
    start_time_milliseconds: 972300,
    end_time_milliseconds: 972400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.11716993605109116
    }
    ],
    signal: 0.28794638600175165,
    confidence: 0.39116669242187946
    },
    {
    token_display_name: "Donald Trump",
    token: "https://en.wikipedia.org/wiki/Donald_Trump",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 15,
    weight_label: "Med",
    default_weight: 2.1,
    mention_list: [
    {
    mention_text: "Donald Trump",
    mention_start_char: 631,
    mention_end_char: 643,
    mention_word_count: 2,
    mention_word_index: 115,
    start_time_milliseconds: 58500,
    end_time_milliseconds: 59200,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.13822532991781547
    },
    {
    mention_text: "Donald Trump",
    mention_start_char: 2422,
    mention_end_char: 2434,
    mention_word_count: 2,
    mention_word_index: 443,
    start_time_milliseconds: 194200,
    end_time_milliseconds: 194700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.14939162793919408
    },
    {
    mention_text: "Trump",
    mention_start_char: 6252,
    mention_end_char: 6257,
    mention_word_count: 1,
    mention_word_index: 1098,
    start_time_milliseconds: 451600,
    end_time_milliseconds: 452500,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.22666607030821836
    },
    {
    mention_text: "Trump",
    mention_start_char: 8224,
    mention_end_char: 8229,
    mention_word_count: 1,
    mention_word_index: 1460,
    start_time_milliseconds: 609200,
    end_time_milliseconds: 609600,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8109975959076915
    },
    {
    mention_text: "Trump",
    mention_start_char: 8723,
    mention_end_char: 8728,
    mention_word_count: 1,
    mention_word_index: 1560,
    start_time_milliseconds: 641600,
    end_time_milliseconds: 641900,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8955664468052253
    },
    {
    mention_text: "Trump",
    mention_start_char: 8949,
    mention_end_char: 8954,
    mention_word_count: 1,
    mention_word_index: 1599,
    start_time_milliseconds: 658300,
    end_time_milliseconds: 659200,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.9096435541018266
    },
    {
    mention_text: "Trump",
    mention_start_char: 9357,
    mention_end_char: 9362,
    mention_word_count: 1,
    mention_word_index: 1670,
    start_time_milliseconds: 682300,
    end_time_milliseconds: 683300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8178360973631813
    },
    {
    mention_text: "Trump",
    mention_start_char: 9454,
    mention_end_char: 9459,
    mention_word_count: 1,
    mention_word_index: 1686,
    start_time_milliseconds: 691100,
    end_time_milliseconds: 691700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.7582427846597558
    },
    {
    mention_text: "Donald Trump",
    mention_start_char: 16437,
    mention_end_char: 16449,
    mention_word_count: 2,
    mention_word_index: 2990,
    start_time_milliseconds: 1198500,
    end_time_milliseconds: 1199100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.11712293770942
    },
    {
    mention_text: "Trump",
    mention_start_char: 7419,
    mention_end_char: 7424,
    mention_word_count: 1,
    mention_word_index: 1306,
    start_time_milliseconds: 555700,
    end_time_milliseconds: 556600,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.5399863741468602
    },
    {
    mention_text: "Trump",
    mention_start_char: 7456,
    mention_end_char: 7461,
    mention_word_count: 1,
    mention_word_index: 1313,
    start_time_milliseconds: 557800,
    end_time_milliseconds: 558800,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.5533402978885825
    },
    {
    mention_text: "Trump",
    mention_start_char: 8457,
    mention_end_char: 8462,
    mention_word_count: 1,
    mention_word_index: 1508,
    start_time_milliseconds: 623900,
    end_time_milliseconds: 624300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8230438971712978
    },
    {
    mention_text: "Trump",
    mention_start_char: 8470,
    mention_end_char: 8475,
    mention_word_count: 1,
    mention_word_index: 1511,
    start_time_milliseconds: 624500,
    end_time_milliseconds: 625100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8248494132891184
    },
    {
    mention_text: "Trump",
    mention_start_char: 9584,
    mention_end_char: 9589,
    mention_word_count: 1,
    mention_word_index: 1707,
    start_time_milliseconds: 699300,
    end_time_milliseconds: 700300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.7013550218924983
    },
    {
    mention_text: "Trump",
    mention_start_char: 30459,
    mention_end_char: 30464,
    mention_word_count: 1,
    mention_word_index: 5694,
    start_time_milliseconds: 2151700,
    end_time_milliseconds: 2152900,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.10051047172187168
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Donald_Trump",
    signal: 22.817833648545612,
    confidence: 0.9831028160292046
    },
    {
    token_display_name: "Joe Biden",
    token: "https://en.wikipedia.org/wiki/Joe_Biden",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 6,
    weight_label: "Med",
    default_weight: 2.1,
    mention_list: [
    {
    mention_text: "Joe Biden",
    mention_start_char: 4119,
    mention_end_char: 4128,
    mention_word_count: 2,
    mention_word_index: 730,
    start_time_milliseconds: 309300,
    end_time_milliseconds: 310200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.7142774867591347
    },
    {
    mention_text: "Biden",
    mention_start_char: 4371,
    mention_end_char: 4376,
    mention_word_count: 1,
    mention_word_index: 777,
    start_time_milliseconds: 325300,
    end_time_milliseconds: 325800,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.6839310013325077
    },
    {
    mention_text: "Biden",
    mention_start_char: 7406,
    mention_end_char: 7411,
    mention_word_count: 1,
    mention_word_index: 1304,
    start_time_milliseconds: 554900,
    end_time_milliseconds: 555700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8036431324250625
    },
    {
    mention_text: "Biden",
    mention_start_char: 7813,
    mention_end_char: 7818,
    mention_word_count: 1,
    mention_word_index: 1381,
    start_time_milliseconds: 584300,
    end_time_milliseconds: 584700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.7084244286471119
    },
    {
    mention_text: "Biden",
    mention_start_char: 8075,
    mention_end_char: 8080,
    mention_word_count: 1,
    mention_word_index: 1431,
    start_time_milliseconds: 600700,
    end_time_milliseconds: 601200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.5741265074158421
    },
    {
    mention_text: "Joe",
    mention_start_char: 7233,
    mention_end_char: 7236,
    mention_word_count: 1,
    mention_word_index: 1270,
    start_time_milliseconds: 543700,
    end_time_milliseconds: 544300,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.7583645066357635
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Joe_Biden",
    signal: 9.633420986006854,
    confidence: 0.8063841494787494
    },
    {
    token_display_name: "Democratic Party",
    token: "https://en.wikipedia.org/wiki/Democratic_Party_(United_States)",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 2,
    weight_label: "Med",
    default_weight: 2.1,
    mention_list: [
    {
    mention_text: "Governor",
    mention_start_char: 94,
    mention_end_char: 102,
    mention_word_count: 1,
    mention_word_index: 20,
    start_time_milliseconds: 6300,
    end_time_milliseconds: 6800,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.27108936201116685
    },
    {
    mention_text: "Democrat",
    mention_start_char: 85,
    mention_end_char: 93,
    mention_word_count: 1,
    mention_word_index: 19,
    start_time_milliseconds: 5700,
    end_time_milliseconds: 6300,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.27104149521188053
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Democratic_Party_(United_States)",
    signal: 2.163097075612902,
    confidence: 0.4831612241697772
    },
    {
    token_display_name: "Kanye West",
    token: "https://en.wikipedia.org/wiki/Kanye_West",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 1,
    weight_label: "Low",
    default_weight: 1.2,
    mention_list: [
    {
    mention_text: "Kanye West",
    mention_start_char: 4158,
    mention_end_char: 4168,
    mention_word_count: 2,
    mention_word_index: 737,
    start_time_milliseconds: 312400,
    end_time_milliseconds: 312900,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.48319328106134146
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Kanye_West",
    signal: 0.5798319372736097,
    confidence: 0.40515440831565264
    },
    {
    token_display_name: "mail-in ballot",
    token: "mail-in ballot",
    token_type: "topic",
    token_frequency: -1,
    weight_label: "Med",
    default_weight: 0.5,
    mention_list: [ ],
    signal: 0,
    confidence: 0
    },
    {
    token_display_name: "ballots",
    token: "ballots",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 25,
    mention_start_char: 99,
    mention_end_char: 106,
    mention_word_count: 1,
    mention_text: "ballots",
    start_time_milliseconds: 8800,
    end_time_milliseconds: 9200,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8400695318064826
    },
    {
    mention_word_index: 74,
    mention_start_char: 312,
    mention_end_char: 319,
    mention_word_count: 1,
    mention_text: "ballots",
    start_time_milliseconds: 36300,
    end_time_milliseconds: 36800,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8162225841576984
    }
    ],
    signal: 0.19274841674952642,
    confidence: 0.38664182488218457,
    topic_contribution: [
    {
    contributing_topic: "mail-in ballot",
    contributing_signal: 0.19274841674952642,
    contributing_topic_weight: "Med"
    }
    ]
    },
    {
    token_display_name: "mail",
    token: "mail",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 13,
    mention_start_char: 46,
    mention_end_char: 50,
    mention_word_count: 1,
    mention_text: "mail",
    start_time_milliseconds: 4700,
    end_time_milliseconds: 4900,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.47011847839819915
    },
    {
    mention_word_index: 79,
    mention_start_char: 337,
    mention_end_char: 341,
    mention_word_count: 1,
    mention_text: "mail",
    start_time_milliseconds: 37800,
    end_time_milliseconds: 38100,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.4748368244495083
    }
    ],
    signal: 0.1889910605695415,
    confidence: 0.3864636287236772,
    topic_contribution: [
    {
    contributing_topic: "mail-in ballot",
    contributing_signal: 0.1889910605695415,
    contributing_topic_weight: "Med"
    }
    ]
    }
    ],
    total_signal: 37.995189875275514,
    total_confidence: 0.9991747824696757,
    topic_category: "Politics + News"
    },
    Education: {
    token_signal_list: [
    {
    token_display_name: "school",
    token: "school",
    token_type: "word",
    token_frequency: 7,
    weight_label: "Med",
    default_weight: 0.7,
    mention_list: [
    {
    mention_word_index: 1749,
    mention_start_char: 7939,
    mention_end_char: 7945,
    mention_word_count: 1,
    mention_text: "school",
    start_time_milliseconds: 716800,
    end_time_milliseconds: 717000,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.571529869266275
    },
    {
    mention_word_index: 1817,
    mention_start_char: 8229,
    mention_end_char: 8235,
    mention_word_count: 1,
    mention_text: "school",
    start_time_milliseconds: 740600,
    end_time_milliseconds: 740700,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.6264530446825247
    },
    {
    mention_word_index: 2802,
    mention_start_char: 12484,
    mention_end_char: 12490,
    mention_word_count: 1,
    mention_text: "school",
    start_time_milliseconds: 1144200,
    end_time_milliseconds: 1144500,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8309472634295235
    },
    {
    mention_word_index: 2845,
    mention_start_char: 12658,
    mention_end_char: 12664,
    mention_word_count: 1,
    mention_text: "school",
    start_time_milliseconds: 1157600,
    end_time_milliseconds: 1158000,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8637191972610311
    },
    {
    mention_word_index: 2857,
    mention_start_char: 12703,
    mention_end_char: 12709,
    mention_word_count: 1,
    mention_text: "school",
    start_time_milliseconds: 1160400,
    end_time_milliseconds: 1160600,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8643912085049691
    },
    {
    mention_word_index: 2908,
    mention_start_char: 12891,
    mention_end_char: 12897,
    mention_word_count: 1,
    mention_text: "school",
    start_time_milliseconds: 1172800,
    end_time_milliseconds: 1173600,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8548754792153895
    },
    {
    mention_word_index: 2932,
    mention_start_char: 12987,
    mention_end_char: 12993,
    mention_word_count: 1,
    mention_text: "School",
    start_time_milliseconds: 1179300,
    end_time_milliseconds: 1179700,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.8403105314168328
    }
    ],
    signal: 3.01092927661253,
    confidence: 0.5255242574453689
    },
    {
    token_display_name: "high school",
    token: "high school",
    token_type: "word_multiword",
    token_frequency: 1,
    weight_label: "Med",
    default_weight: 1,
    mention_list: [
    {
    mention_start_char: 10163,
    mention_end_char: 10174,
    mention_word_count: 2,
    mention_text: "high school",
    mention_word_index: 1816,
    start_time_milliseconds: 740500,
    end_time_milliseconds: 740700,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.4139000793761685
    }
    ],
    signal: 0.4139000793761685,
    confidence: 0.39718217452143634
    }
    ],
    total_signal: 3.424829355988699,
    total_confidence: 0.5461100814211265,
    topic_category: "Education + Parenting"
    },
    "White Supremacy": {
    token_signal_list: [
    {
    token_display_name: "power",
    token: "power",
    token_type: "word",
    token_frequency: 1,
    weight_label: "Med",
    default_weight: 0.7,
    mention_list: [
    {
    mention_word_index: 4262,
    mention_start_char: 18541,
    mention_end_char: 18546,
    mention_word_count: 1,
    mention_text: "power",
    start_time_milliseconds: 1585200,
    end_time_milliseconds: 1585600,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.0522425585172819
    }
    ],
    signal: 0.03656979096209733,
    confidence: 0.3792610092996208
    },
    {
    token_display_name: "genocide",
    token: "genocide",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 3431,
    mention_start_char: 15122,
    mention_end_char: 15130,
    mention_word_count: 1,
    mention_text: "genocide",
    start_time_milliseconds: 1338600,
    end_time_milliseconds: 1339600,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.19255108818700883
    },
    {
    mention_word_index: 3461,
    mention_start_char: 15249,
    mention_end_char: 15257,
    mention_word_count: 1,
    mention_text: "genocide",
    start_time_milliseconds: 1347300,
    end_time_milliseconds: 1347700,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.19302474510104503
    }
    ],
    signal: 0.15423033331522157,
    confidence: 0.3848165157802515
    }
    ],
    total_signal: 0.1908001242773189,
    total_confidence: 0.3865494214825071,
    topic_category: "Politics + News"
    },
    "trump downplay coronavirus": {
    token_signal_list: [
    {
    token_display_name: "Covid",
    token: "covid",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 1738,
    mention_start_char: 7887,
    mention_end_char: 7892,
    mention_word_count: 1,
    mention_text: "covid",
    start_time_milliseconds: 714100,
    end_time_milliseconds: 714400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.016937688825154975
    },
    {
    mention_word_index: 5193,
    mention_start_char: 22328,
    mention_end_char: 22333,
    mention_word_count: 1,
    mention_text: "covid",
    start_time_milliseconds: 1930600,
    end_time_milliseconds: 1931000,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.016937688825154975
    }
    ],
    signal: 0.01355015106012398,
    confidence: 0.3781777469944212
    },
    {
    token_display_name: "coronavirus",
    token: "coronavirus",
    token_type: "topic",
    token_frequency: -1,
    weight_label: "Med",
    default_weight: 0.5,
    mention_list: [ ],
    signal: 0,
    confidence: 0
    },
    {
    token_display_name: "pandemic",
    token: "pandemic",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Med",
    default_weight: 0.7,
    mention_list: [
    {
    mention_word_index: 489,
    mention_start_char: 2175,
    mention_end_char: 2183,
    mention_word_count: 1,
    mention_text: "pandemic",
    start_time_milliseconds: 210900,
    end_time_milliseconds: 211400,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.7793787224366978
    },
    {
    mention_word_index: 2202,
    mention_start_char: 9837,
    mention_end_char: 9845,
    mention_word_count: 1,
    mention_text: "pandemic",
    start_time_milliseconds: 921200,
    end_time_milliseconds: 921700,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.1697530799925967
    }
    ],
    signal: 0.332196130850253,
    confidence: 0.39327637298245455,
    topic_contribution: [
    {
    contributing_topic: "coronavirus",
    contributing_signal: 0.332196130850253,
    contributing_topic_weight: "Med"
    }
    ]
    },
    {
    token_display_name: "Coronavirus",
    token: "coronavirus",
    token_type: "word",
    token_frequency: 6,
    weight_label: "High",
    default_weight: 1,
    mention_list: [
    {
    mention_word_index: 204,
    mention_start_char: 873,
    mention_end_char: 884,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 108400,
    end_time_milliseconds: 109100,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.3532378007705363
    },
    {
    mention_word_index: 512,
    mention_start_char: 2311,
    mention_end_char: 2322,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 220300,
    end_time_milliseconds: 220700,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.7835323804853448
    },
    {
    mention_word_index: 534,
    mention_start_char: 2420,
    mention_end_char: 2431,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 228400,
    end_time_milliseconds: 228900,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.7784416341032839
    },
    {
    mention_word_index: 554,
    mention_start_char: 2523,
    mention_end_char: 2534,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 234600,
    end_time_milliseconds: 235000,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.7705392706788273
    },
    {
    mention_word_index: 1091,
    mention_start_char: 5050,
    mention_end_char: 5061,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 447800,
    end_time_milliseconds: 448800,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.4407468618300883
    },
    {
    mention_word_index: 4441,
    mention_start_char: 19301,
    mention_end_char: 19312,
    mention_word_count: 1,
    mention_text: "coronavirus",
    start_time_milliseconds: 1660400,
    end_time_milliseconds: 1660900,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.11213659526420834
    }
    ],
    signal: 1.6193172715661444,
    confidence: 0.45607935471971217,
    topic_contribution: [
    {
    contributing_topic: "coronavirus",
    contributing_signal: 1.6193172715661444,
    contributing_topic_weight: "Med"
    }
    ]
    },
    {
    token_display_name: "virus",
    token: "virus",
    token_type: "word",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 683,
    mention_start_char: 3116,
    mention_end_char: 3122,
    mention_word_count: 1,
    mention_text: "virus,",
    start_time_milliseconds: 290700,
    end_time_milliseconds: 291600,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.4149801625599389
    },
    {
    mention_word_index: 2522,
    mention_start_char: 11311,
    mention_end_char: 11316,
    mention_word_count: 1,
    mention_text: "virus",
    start_time_milliseconds: 1052800,
    end_time_milliseconds: 1053200,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.16316900265504974
    }
    ],
    signal: 0.11562983304299773,
    confidence: 0.38299054791931875,
    topic_contribution: [
    {
    contributing_topic: "coronavirus",
    contributing_signal: 0.11562983304299773,
    contributing_topic_weight: "Med"
    }
    ]
    },
    {
    token_display_name: "Anthony Fauci",
    token: "https://en.wikipedia.org/wiki/Anthony_Fauci",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 2,
    weight_label: "Med",
    default_weight: 2.1,
    mention_list: [
    {
    mention_text: "Fauci",
    mention_start_char: 6896,
    mention_end_char: 6901,
    mention_word_count: 1,
    mention_word_index: 1217,
    start_time_milliseconds: 521700,
    end_time_milliseconds: 522100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.6669292194668374
    },
    {
    mention_text: "Dr.",
    mention_start_char: 6892,
    mention_end_char: 6895,
    mention_word_count: 1,
    mention_word_index: 1216,
    start_time_milliseconds: 521500,
    end_time_milliseconds: 521700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.666111579794132
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Anthony_Fauci",
    signal: 1.399692839224018,
    confidence: 0.44520559124848996,
    topic_contribution: [
    {
    contributing_topic: "coronavirus",
    contributing_signal: 1.399692839224018,
    contributing_topic_weight: "Med"
    }
    ]
    },
    {
    token_display_name: "Donald Trump",
    token: "https://en.wikipedia.org/wiki/Donald_Trump",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 15,
    weight_label: "Low",
    default_weight: 1.2,
    mention_list: [
    {
    mention_text: "Donald Trump",
    mention_start_char: 631,
    mention_end_char: 643,
    mention_word_count: 2,
    mention_word_index: 115,
    start_time_milliseconds: 58500,
    end_time_milliseconds: 59200,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.13822532991781547
    },
    {
    mention_text: "Donald Trump",
    mention_start_char: 2422,
    mention_end_char: 2434,
    mention_word_count: 2,
    mention_word_index: 443,
    start_time_milliseconds: 194200,
    end_time_milliseconds: 194700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.14939162793919408
    },
    {
    mention_text: "Trump",
    mention_start_char: 6252,
    mention_end_char: 6257,
    mention_word_count: 1,
    mention_word_index: 1098,
    start_time_milliseconds: 451600,
    end_time_milliseconds: 452500,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.22666607030821836
    },
    {
    mention_text: "Trump",
    mention_start_char: 8224,
    mention_end_char: 8229,
    mention_word_count: 1,
    mention_word_index: 1460,
    start_time_milliseconds: 609200,
    end_time_milliseconds: 609600,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8109975959076915
    },
    {
    mention_text: "Trump",
    mention_start_char: 8723,
    mention_end_char: 8728,
    mention_word_count: 1,
    mention_word_index: 1560,
    start_time_milliseconds: 641600,
    end_time_milliseconds: 641900,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8955664468052253
    },
    {
    mention_text: "Trump",
    mention_start_char: 8949,
    mention_end_char: 8954,
    mention_word_count: 1,
    mention_word_index: 1599,
    start_time_milliseconds: 658300,
    end_time_milliseconds: 659200,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.9096435541018266
    },
    {
    mention_text: "Trump",
    mention_start_char: 9357,
    mention_end_char: 9362,
    mention_word_count: 1,
    mention_word_index: 1670,
    start_time_milliseconds: 682300,
    end_time_milliseconds: 683300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8178360973631813
    },
    {
    mention_text: "Trump",
    mention_start_char: 9454,
    mention_end_char: 9459,
    mention_word_count: 1,
    mention_word_index: 1686,
    start_time_milliseconds: 691100,
    end_time_milliseconds: 691700,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.7582427846597558
    },
    {
    mention_text: "Donald Trump",
    mention_start_char: 16437,
    mention_end_char: 16449,
    mention_word_count: 2,
    mention_word_index: 2990,
    start_time_milliseconds: 1198500,
    end_time_milliseconds: 1199100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.11712293770942
    },
    {
    mention_text: "Trump",
    mention_start_char: 7419,
    mention_end_char: 7424,
    mention_word_count: 1,
    mention_word_index: 1306,
    start_time_milliseconds: 555700,
    end_time_milliseconds: 556600,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.5399863741468602
    },
    {
    mention_text: "Trump",
    mention_start_char: 7456,
    mention_end_char: 7461,
    mention_word_count: 1,
    mention_word_index: 1313,
    start_time_milliseconds: 557800,
    end_time_milliseconds: 558800,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.5533402978885825
    },
    {
    mention_text: "Trump",
    mention_start_char: 8457,
    mention_end_char: 8462,
    mention_word_count: 1,
    mention_word_index: 1508,
    start_time_milliseconds: 623900,
    end_time_milliseconds: 624300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8230438971712978
    },
    {
    mention_text: "Trump",
    mention_start_char: 8470,
    mention_end_char: 8475,
    mention_word_count: 1,
    mention_word_index: 1511,
    start_time_milliseconds: 624500,
    end_time_milliseconds: 625100,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.8248494132891184
    },
    {
    mention_text: "Trump",
    mention_start_char: 9584,
    mention_end_char: 9589,
    mention_word_count: 1,
    mention_word_index: 1707,
    start_time_milliseconds: 699300,
    end_time_milliseconds: 700300,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.7013550218924983
    },
    {
    mention_text: "Trump",
    mention_start_char: 30459,
    mention_end_char: 30464,
    mention_word_count: 1,
    mention_word_index: 5694,
    start_time_milliseconds: 2151700,
    end_time_milliseconds: 2152900,
    label_weight: 2.1,
    token_cluster_weight_adjustment: 0.10051047172187168
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Donald_Trump",
    signal: 5.818350779646129,
    confidence: 0.6600843555286626,
    topic_contribution: [
    {
    contributing_topic: "coronavirus",
    contributing_signal: 5.818350779646129,
    contributing_topic_weight: "Med"
    }
    ]
    }
    ],
    total_signal: 9.298737005389665,
    total_confidence: 0.795718640705583,
    topic_category: "Politics + News"
    },
    Television: {
    token_signal_list: [
    {
    token_display_name: "series",
    token: "series",
    token_type: "word",
    token_frequency: 1,
    weight_label: "Med",
    default_weight: 0.7,
    mention_list: [
    {
    mention_word_index: 2456,
    mention_start_char: 11006,
    mention_end_char: 11012,
    mention_word_count: 1,
    mention_text: "series",
    start_time_milliseconds: 1025700,
    end_time_milliseconds: 1026099,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.3377271014342192
    }
    ],
    signal: 0.23640897100395342,
    confidence: 0.38871468296810024
    },
    {
    token_display_name: "audience",
    token: "audience",
    token_type: "word",
    token_frequency: 3,
    weight_label: "Low",
    default_weight: 0.4,
    mention_list: [
    {
    mention_word_index: 1414,
    mention_start_char: 6472,
    mention_end_char: 6480,
    mention_word_count: 1,
    mention_text: "audience",
    start_time_milliseconds: 593300,
    end_time_milliseconds: 594100,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.16509575652186903
    },
    {
    mention_word_index: 5040,
    mention_start_char: 21732,
    mention_end_char: 21740,
    mention_word_count: 1,
    mention_text: "audience",
    start_time_milliseconds: 1877600,
    end_time_milliseconds: 1878300,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.14640172956780198
    },
    {
    mention_word_index: 5377,
    mention_start_char: 23056,
    mention_end_char: 23064,
    mention_word_count: 1,
    mention_text: "audience",
    start_time_milliseconds: 1989100,
    end_time_milliseconds: 1989400,
    label_weight: 0.4,
    token_cluster_weight_adjustment: 0.14113117458234872
    }
    ],
    signal: 0.18105146426880792,
    confidence: 0.38608718594062136
    },
    {
    token_display_name: "episode",
    token: "episode",
    token_type: "word",
    token_frequency: 4,
    weight_label: "Med",
    default_weight: 0.7,
    mention_list: [
    {
    mention_word_index: 3350,
    mention_start_char: 14765,
    mention_end_char: 14772,
    mention_word_count: 1,
    mention_text: "episode",
    start_time_milliseconds: 1310400,
    end_time_milliseconds: 1311100,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.1717764252164623
    },
    {
    mention_word_index: 3827,
    mention_start_char: 16773,
    mention_end_char: 16780,
    mention_word_count: 1,
    mention_text: "episode",
    start_time_milliseconds: 1460200,
    end_time_milliseconds: 1460700,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.4305971646546411
    },
    {
    mention_word_index: 3877,
    mention_start_char: 16968,
    mention_end_char: 16975,
    mention_word_count: 1,
    mention_text: "episode",
    start_time_milliseconds: 1473800,
    end_time_milliseconds: 1474200,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.4282940099088575
    },
    {
    mention_word_index: 3886,
    mention_start_char: 17005,
    mention_end_char: 17012,
    mention_word_count: 1,
    mention_text: "episode",
    start_time_milliseconds: 1475600,
    end_time_milliseconds: 1476200,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.4279226149097679
    }
    ],
    signal: 1.0210131502828101,
    confidence: 0.42658517104065957
    },
    {
    token_display_name: "tv",
    token: "tv",
    token_type: "word",
    token_frequency: 3,
    weight_label: "High",
    default_weight: 1,
    mention_list: [
    {
    mention_word_index: 1644,
    mention_start_char: 7459,
    mention_end_char: 7462,
    mention_word_count: 1,
    mention_text: "TV,",
    start_time_milliseconds: 673600,
    end_time_milliseconds: 674100,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.171203313108697
    },
    {
    mention_word_index: 2346,
    mention_start_char: 10499,
    mention_end_char: 10501,
    mention_word_count: 1,
    mention_text: "TV",
    start_time_milliseconds: 968600,
    end_time_milliseconds: 968800,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.34611895776553214
    },
    {
    mention_word_index: 2409,
    mention_start_char: 10800,
    mention_end_char: 10802,
    mention_word_count: 1,
    mention_text: "TV",
    start_time_milliseconds: 994300,
    end_time_milliseconds: 994600,
    label_weight: 1,
    token_cluster_weight_adjustment: 0.40290274136681165
    }
    ],
    signal: 0.9202250122410407,
    confidence: 0.4216618610112174
    },
    {
    token_display_name: "television",
    token: "television",
    token_type: "topic",
    token_frequency: -1,
    weight_label: "High",
    default_weight: 0.8,
    mention_list: [ ],
    signal: 0,
    confidence: 0
    }
    ],
    total_signal: 2.358698597796612,
    total_confidence: 0.49293540005827857,
    topic_category: "Entertainment"
    },
    "Joe Biden": {
    token_signal_list: [
    {
    token_display_name: "Joe Biden",
    token: "https://en.wikipedia.org/wiki/Joe_Biden",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 6,
    weight_label: "High",
    default_weight: 3,
    mention_list: [
    {
    mention_text: "Joe Biden",
    mention_start_char: 4119,
    mention_end_char: 4128,
    mention_word_count: 2,
    mention_word_index: 730,
    start_time_milliseconds: 309300,
    end_time_milliseconds: 310200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.7142774867591347
    },
    {
    mention_text: "Biden",
    mention_start_char: 4371,
    mention_end_char: 4376,
    mention_word_count: 1,
    mention_word_index: 777,
    start_time_milliseconds: 325300,
    end_time_milliseconds: 325800,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.6839310013325077
    },
    {
    mention_text: "Biden",
    mention_start_char: 7406,
    mention_end_char: 7411,
    mention_word_count: 1,
    mention_word_index: 1304,
    start_time_milliseconds: 554900,
    end_time_milliseconds: 555700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.8036431324250625
    },
    {
    mention_text: "Biden",
    mention_start_char: 7813,
    mention_end_char: 7818,
    mention_word_count: 1,
    mention_word_index: 1381,
    start_time_milliseconds: 584300,
    end_time_milliseconds: 584700,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.7084244286471119
    },
    {
    mention_text: "Biden",
    mention_start_char: 8075,
    mention_end_char: 8080,
    mention_word_count: 1,
    mention_word_index: 1431,
    start_time_milliseconds: 600700,
    end_time_milliseconds: 601200,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.5741265074158421
    },
    {
    mention_text: "Joe",
    mention_start_char: 7233,
    mention_end_char: 7236,
    mention_word_count: 1,
    mention_word_index: 1270,
    start_time_milliseconds: 543700,
    end_time_milliseconds: 544300,
    label_weight: 3,
    token_cluster_weight_adjustment: 0.7583645066357635
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Joe_Biden",
    signal: 12.728301189646269,
    confidence: 0.8855083749018661
    },
    {
    token_display_name: "Barack Obama",
    token: "https://en.wikipedia.org/wiki/Barack_Obama",
    token_type: "named_entity_with_wikipedia_link",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 1.2,
    mention_list: [
    {
    mention_text: "Barack Obama",
    mention_start_char: 4106,
    mention_end_char: 4118,
    mention_word_count: 2,
    mention_word_index: 728,
    start_time_milliseconds: 308400,
    end_time_milliseconds: 309300,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.7150793074454563
    },
    {
    mention_text: "Obama",
    mention_start_char: 4361,
    mention_end_char: 4366,
    mention_word_count: 1,
    mention_word_index: 775,
    start_time_milliseconds: 324900,
    end_time_milliseconds: 325300,
    label_weight: 1.2,
    token_cluster_weight_adjustment: 0.6850268141152608
    }
    ],
    wikipedia_url: "https://en.wikipedia.org/wiki/Barack_Obama",
    signal: 1.6801273458728605,
    confidence: 0.459097972855359
    },
    {
    token_display_name: "presidential election",
    token: "presidential election",
    token_type: "word_multiword",
    token_frequency: 2,
    weight_label: "Low",
    default_weight: 0.7,
    mention_list: [
    {
    mention_start_char: 3691,
    mention_end_char: 3712,
    mention_word_count: 2,
    mention_text: "presidential election",
    mention_word_index: 659,
    start_time_milliseconds: 281900,
    end_time_milliseconds: 283200,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.62902897179335
    },
    {
    mention_start_char: 7105,
    mention_end_char: 7126,
    mention_word_count: 2,
    mention_text: "presidential election",
    mention_word_index: 1249,
    start_time_milliseconds: 534700,
    end_time_milliseconds: 536000,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.7232243358984737
    }
    ],
    signal: 0.9465773153842765,
    confidence: 0.42294765996999645
    },
    {
    token_display_name: "2020 presidential election",
    token: "2020 presidential election",
    token_type: "word_multiword",
    token_frequency: 1,
    weight_label: "Low",
    default_weight: 0.7,
    mention_list: [
    {
    mention_start_char: 7100,
    mention_end_char: 7126,
    mention_word_count: 3,
    mention_text: "2020 presidential election",
    mention_word_index: 1248,
    start_time_milliseconds: 534000,
    end_time_milliseconds: 536000,
    label_weight: 0.7,
    token_cluster_weight_adjustment: 0.7218361588707217
    }
    ],
    signal: 0.5052853112095052,
    confidence: 0.401566336923148
    }
    ],
    total_signal: 15.86029116211291,
    total_confidence: 0.935357591450209,
    topic_category: "Politics + News"
    }
    }