import { useCallback } from "react"
import { useCookies } from "react-cookie"
import { LoginData } from "../types/types"

enum STORAGE {
    LOGIN_DATA = "loginData",
    LAST_PATH = "lastPath"
}

/**
 * This is for persistant storage that will survive a refresh.
 */
export const useStorageStore = () => {
    const getCookie = <T>(key: STORAGE): T | null => {
        let out: T | null = null
        const cookie = cookies[STORAGE.LOGIN_DATA]
        if (cookie) {
            out = cookie as T
        }
        return out
    }
    const [cookies, setLoginDataCookie, removeLoginDataCookie] = useCookies([STORAGE.LOGIN_DATA])

    return {
        setLoginData: useCallback(
            (loginData: LoginData) => {
                setLoginDataCookie(STORAGE.LOGIN_DATA, loginData, {
                    sameSite: true,
                    path: "/"
                })
            },
            [setLoginDataCookie]
        ),

        removeLoginData: useCallback(() => removeLoginDataCookie(STORAGE.LOGIN_DATA), [removeLoginDataCookie]),
        loginData: getCookie<LoginData>(STORAGE.LOGIN_DATA),

        // We store the lastPath using sessionStore because so it does not persist across tabs.
        // This allows us to paste URL into a new table and land on the correct page.
        setLastPath: useCallback((path: string) => {
            sessionStorage.setItem(STORAGE.LAST_PATH, path)
        }, []),
        lastPath: sessionStorage.getItem(STORAGE.LAST_PATH)
    }
}
