import { Box, makeStyles, Typography } from "@material-ui/core"
import React, { ChangeEvent, FC, useState } from "react"
import {
    useGetTopicSheets,
    useInsertDefaultTopicSheet,
    useDeleteDefaultTopicSheet,
    useGetDefaultTopicSheet
} from "../../graphql/topicSheet"
import { useGetLoginData } from "../../store"
import { TopicSheet } from "../../types/types"
import Button from "../Button"
import ErrorMessage from "../ErrorMessage"
import SelectTopicSheet from "../SelectTopicSheet"

const useStyles = makeStyles({
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    button: {
        marginTop: 16,
        width: "100%"
    },
    bold: {
        fontWeight: 600
    }
})

const ChangeDefaultTopicSheet: FC = () => {
    const classes = useStyles()
    const topicSheetsData = useGetTopicSheets()
    const [deleteTopicSheet, deleteTopicSheetResult] = useDeleteDefaultTopicSheet()
    const [insertTopicSheet, insertTopicSheetResult] = useInsertDefaultTopicSheet()
    const defaultTopicSheetData = useGetDefaultTopicSheet()
    const loginData = useGetLoginData()
    const [topicSheet, setTopicSheet] = useState<TopicSheet | undefined>(undefined)
    const [error, setError] = useState<boolean>(false)

    const onChangeTopicSheet = (event: ChangeEvent<HTMLSelectElement>) => {
        if (!topicSheetsData.data) {
            setError(true)
            return
        }
        const selectedTopicSheet = topicSheetsData.data.topic_sheet.find(p => p.filename === event.target.value)
        setTopicSheet(selectedTopicSheet)
    }

    const submit = async () => {
        if (!loginData || !loginData.userId || !topicSheetsData.data || !topicSheet) {
            console.error("Missing data required to change the default topic sheet")
            return
        }
        const deleteResponse = await deleteTopicSheet()
        const insertResponse = await insertTopicSheet({ variables: { topic_sheet_id: topicSheet.id } })
        if (
            !deleteResponse.data ||
            !insertResponse.data ||
            deleteTopicSheetResult.error ||
            insertTopicSheetResult.error
        ) {
            console.error("Failed to update topic sheet")
            setError(true)
            return
        }
    }

    return (
        <>
            <Box marginBottom={2}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Change Default Topic Sheet
                </Typography>
            </Box>
            <SelectTopicSheet
                topicSheets={topicSheetsData.data?.topic_sheet}
                onChange={onChangeTopicSheet}
                selectedTopicSheet={topicSheet}
                keySuffix="ChangeDefaultTopicSheet"
            />
            <Typography variant="body2">
                {defaultTopicSheetData.data?.default_topic_sheet[0]?.topic_sheet ? (
                    <>
                        <span className={classes.bold}>Current Default:</span>{" "}
                        {defaultTopicSheetData.data?.default_topic_sheet[0].topic_sheet.filename}
                        <br />
                        (status: {defaultTopicSheetData.data?.default_topic_sheet[0].topic_sheet.status})
                    </>
                ) : (
                    "No topic sheet selected"
                )}
            </Typography>
            <Box marginTop={2} className={classes.center}>
                <Button onClick={submit} disabled={!topicSheet} className={classes.button}>
                    Submit New Default Topic Sheet
                </Button>
            </Box>
            <ErrorMessage error={error} />
        </>
    )
}

export default ChangeDefaultTopicSheet
