import React, { FC } from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    logo: {
        height: 24,
        width: 110
    }
})

const Logo: FC = () => {
    const classes = useStyles()
    return (
        <svg
            width="110"
            height="24"
            viewBox="0 0 110 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.logo}
        >
            <path
                d="M35.6742 10.6484H14.7809L5.97783 23.8378H11.7795C12.1286 23.8375 12.4722 23.7513 12.7799 23.5867C13.0876 23.4221 13.35 23.1843 13.5437 22.8944L14.5618 21.3704H28.9876C29.3988 21.3702 29.8036 21.269 30.1664 21.0757C30.5292 20.8824 30.8389 20.603 31.0681 20.262L36.4773 12.1572C36.5748 12.0115 36.6309 11.842 36.6398 11.667C36.6486 11.4919 36.6097 11.3177 36.5274 11.163C36.445 11.0082 36.3221 10.8786 36.1719 10.788C36.0217 10.6974 35.8497 10.6492 35.6742 10.6484Z"
                fill="#1011B9"
            />
            <path
                d="M25.5952 18.0661L33.2512 6.59467C33.3361 6.46536 33.3845 6.31556 33.3912 6.16108C33.398 6.00659 33.3629 5.85315 33.2896 5.71694C33.2163 5.58073 33.1075 5.46682 32.9748 5.38721C32.8421 5.3076 32.6904 5.26526 32.5355 5.26465H11.4453L3.53958 17.1185C3.4532 17.2479 3.4036 17.3982 3.39608 17.5536C3.38855 17.7089 3.42338 17.8633 3.49685 18.0004C3.57032 18.1375 3.67967 18.2522 3.81323 18.332C3.9468 18.4119 4.09957 18.4541 4.25525 18.454H24.8795C25.0215 18.4532 25.1612 18.4174 25.286 18.3497C25.4109 18.2821 25.5171 18.1846 25.5952 18.0661Z"
                fill="#6161FF"
            />
            <path
                d="M21.3141 13.1615L23.9867 9.1867C24.0681 9.06537 24.115 8.92423 24.1224 8.77837C24.1297 8.6325 24.0973 8.48737 24.0285 8.35849C23.9597 8.22961 23.8572 8.12181 23.7318 8.0466C23.6065 7.97139 23.4631 7.93161 23.3168 7.93149H9.76199L11.5456 5.26451H25.4999C25.8475 5.2643 26.1896 5.17877 26.4963 5.01546C26.8029 4.85215 27.0647 4.61606 27.2586 4.32796L29.4361 1.09018C29.5095 0.985653 29.5525 0.862907 29.5605 0.735493C29.5684 0.608078 29.541 0.480951 29.4811 0.368147C29.4213 0.255342 29.3314 0.161249 29.2214 0.0962543C29.1114 0.0312598 28.9856 -0.00210758 28.8578 -0.000163815H9.00609C8.7514 0.000394732 8.50081 0.0639502 8.2767 0.184827C8.05259 0.305704 7.86193 0.480139 7.72176 0.692557L0.123944 12.0698C0.0516197 12.1781 0.0100904 12.304 0.00378736 12.434C-0.00251569 12.5641 0.026644 12.6934 0.088155 12.8082C0.149666 12.9229 0.24122 13.0189 0.353048 13.0858C0.464875 13.1526 0.59278 13.1879 0.723114 13.1879L21.3141 13.1615Z"
                fill="#B8B6FF"
            />
            <path
                d="M25.5952 18.0661L33.2512 6.59467C33.3361 6.46536 33.3845 6.31556 33.3912 6.16108C33.398 6.00659 33.3629 5.85315 33.2896 5.71694C33.2163 5.58073 33.1075 5.46682 32.9748 5.38721C32.8421 5.3076 32.6904 5.26526 32.5355 5.26465H11.4453L3.53958 17.1185C3.4532 17.2479 3.4036 17.3982 3.39608 17.5536C3.38855 17.7089 3.42338 17.8633 3.49685 18.0004C3.57032 18.1375 3.67967 18.2522 3.81323 18.332C3.9468 18.4119 4.09957 18.4541 4.25525 18.454H24.8795C25.0215 18.4532 25.1612 18.4174 25.286 18.3497C25.4109 18.2821 25.5171 18.1846 25.5952 18.0661Z"
                fill="#6161FF"
            />
            <path
                d="M21.3141 13.1615L23.9867 9.1867C24.0681 9.06537 24.115 8.92423 24.1224 8.77837C24.1297 8.6325 24.0973 8.48737 24.0285 8.35849C23.9597 8.22961 23.8572 8.12181 23.7318 8.0466C23.6065 7.97139 23.4631 7.93161 23.3168 7.93149H9.76199L11.5456 5.26451H25.4999C25.8475 5.2643 26.1896 5.17877 26.4963 5.01546C26.8029 4.85215 27.0647 4.61606 27.2586 4.32796L29.4361 1.09018C29.5095 0.985653 29.5525 0.862907 29.5605 0.735493C29.5684 0.608078 29.541 0.480951 29.4811 0.368147C29.4213 0.255342 29.3314 0.161249 29.2214 0.0962543C29.1114 0.0312598 28.9856 -0.00210758 28.8578 -0.000163815H9.00609C8.7514 0.000394732 8.50081 0.0639502 8.2767 0.184827C8.05259 0.305704 7.86193 0.480139 7.72176 0.692557L0.123944 12.0698C0.0516197 12.1781 0.0100904 12.304 0.00378736 12.434C-0.00251569 12.5641 0.026644 12.6934 0.088155 12.8082C0.149666 12.9229 0.24122 13.0189 0.353048 13.0858C0.464875 13.1526 0.59278 13.1879 0.723114 13.1879L21.3141 13.1615Z"
                fill="#B8B6FF"
            />
            <path
                d="M21.3141 13.1615L23.9867 9.1867C24.0681 9.06537 24.115 8.92423 24.1224 8.77837C24.1297 8.6325 24.0973 8.48737 24.0285 8.35849C23.9597 8.22961 23.8572 8.12181 23.7318 8.0466C23.6065 7.97139 23.4631 7.93161 23.3168 7.93149H9.76199L11.5456 5.26451H25.4999C25.8475 5.2643 26.1896 5.17877 26.4963 5.01546C26.8029 4.85215 27.0647 4.61606 27.2586 4.32796L29.4361 1.09018C29.5095 0.985653 29.5525 0.862907 29.5605 0.735493C29.5684 0.608078 29.541 0.480951 29.4811 0.368147C29.4213 0.255342 29.3314 0.161249 29.2214 0.0962543C29.1114 0.0312598 28.9856 -0.00210758 28.8578 -0.000163815H9.00609C8.7514 0.000394732 8.50081 0.0639502 8.2767 0.184827C8.05259 0.305704 7.86193 0.480139 7.72176 0.692557L0.123944 12.0698C0.0516197 12.1781 0.0100904 12.304 0.00378736 12.434C-0.00251569 12.5641 0.026644 12.6934 0.088155 12.8082C0.149666 12.9229 0.24122 13.0189 0.353048 13.0858C0.464875 13.1526 0.59278 13.1879 0.723114 13.1879L21.3141 13.1615Z"
                fill="#B8B6FF"
            />
            <path
                d="M46.3539 19.5014V22.9387C46.3539 23.1797 46.258 23.4109 46.0874 23.5813C45.9167 23.7518 45.6853 23.8475 45.444 23.8475H44.2942V14.0843C44.2942 13.8431 44.39 13.6118 44.5606 13.4412C44.7312 13.2705 44.9626 13.1744 45.2041 13.1741H48.3081C49.6826 13.1741 50.4912 13.5093 51.082 14.1342C51.6226 14.7442 51.921 15.5307 51.921 16.3453C51.921 17.16 51.6226 17.9465 51.082 18.5565C50.5023 19.1661 49.6784 19.5014 48.3081 19.5014H46.3539ZM48.2166 17.6726C48.9184 17.6726 49.2707 17.505 49.5134 17.2459C49.744 17.0032 49.8704 16.68 49.8657 16.3453C49.8708 16.0065 49.7446 15.6789 49.5134 15.431C49.2707 15.1719 48.9184 15.0042 48.2166 15.0042H46.3539V17.6726H48.2166Z"
                fill="#6161FF"
            />
            <path
                d="M54.4496 23.8476H53.3761V13.9307C53.3768 13.6897 53.4732 13.4589 53.644 13.2888C53.8148 13.1187 54.0462 13.0232 54.2873 13.0232H55.3609V22.9402C55.3601 23.1811 55.2638 23.4119 55.093 23.582C54.9222 23.7521 54.6908 23.8477 54.4496 23.8476Z"
                fill="#6161FF"
            />
            <path
                d="M63.838 23.8475H62.8408V23.2532C62.2678 23.7445 61.5349 24.0101 60.7797 23.9999C60.2523 24.0017 59.7304 23.8915 59.2488 23.6765C58.7673 23.4615 58.3369 23.1467 57.9864 22.753C57.3702 22.0301 57.0341 21.1103 57.0391 20.1609C57.0391 17.9802 58.6577 16.3024 60.6729 16.3024C61.4593 16.2904 62.2255 16.5514 62.8408 17.0408C62.8408 16.8832 62.9035 16.732 63.015 16.6206C63.1266 16.5091 63.278 16.4465 63.4358 16.4465H64.7478V22.9331C64.7486 23.0529 64.7256 23.1717 64.6802 23.2826C64.6348 23.3935 64.5679 23.4944 64.4833 23.5794C64.3988 23.6643 64.2982 23.7318 64.1875 23.7778C64.0767 23.8238 63.9579 23.8475 63.838 23.8475ZM62.7645 19.0442C62.5516 18.7596 62.2751 18.5287 61.957 18.3699C61.6388 18.2112 61.2879 18.1289 60.9323 18.1298C59.7714 18.1298 59.0238 19.0899 59.0238 20.1581C59.0238 21.3163 59.863 22.1697 60.9781 22.1697C61.3275 22.1721 61.6724 22.0902 61.9834 21.9311C62.2944 21.7719 62.5623 21.5401 62.7645 21.2554V19.0442Z"
                fill="#6161FF"
            />
            <path
                d="M72.6092 23.8476C72.2055 23.9509 71.7902 24.0021 71.3734 24C70.182 24 69.3124 23.6038 68.8089 22.9332C68.4275 22.4289 68.2541 21.7736 68.2541 20.7165V18.1299H66.1986V17.3624C66.1986 17.1212 66.2944 16.8899 66.465 16.7192C66.6356 16.5486 66.867 16.4525 67.1085 16.4522H68.2596V15.3036C68.2596 15.0626 68.3555 14.8314 68.5261 14.661C68.6968 14.4905 68.9282 14.3948 69.1695 14.3948H70.243V16.4522H72.6855V17.2197C72.6855 17.4609 72.5897 17.6922 72.4191 17.8628C72.2485 18.0335 72.0171 18.1296 71.7756 18.1299H70.243V20.9507C70.243 21.4079 70.3193 21.6822 70.4871 21.8803C70.6868 22.1089 71.0072 22.2461 71.4955 22.2461C71.7667 22.2434 72.0371 22.2179 72.3041 22.1699L72.6092 23.8476Z"
                fill="#6161FF"
            />
            <path
                d="M79.784 14.6995C79.5893 14.6508 79.3896 14.6252 79.189 14.6233C78.7007 14.6233 78.3179 14.7619 78.0794 15.0501C77.9116 15.2634 77.802 15.5377 77.802 16.2235V16.4521H80.0919V17.2197C80.0919 17.4608 79.9961 17.6921 79.8255 17.8628C79.6549 18.0335 79.4235 18.1295 79.182 18.1299H77.7951V22.9388C77.7951 23.1798 77.6992 23.411 77.5286 23.5814C77.3579 23.7519 77.1265 23.8476 76.8852 23.8476H75.8117V18.1299H73.9074V17.3624C73.9074 17.1212 74.0032 16.8899 74.1738 16.7192C74.3444 16.5486 74.5758 16.4525 74.8172 16.4521H75.8159V16.1473C75.8159 14.9586 76.0932 14.241 76.6092 13.738C77.1251 13.2351 77.906 12.8694 78.9143 12.8694C79.3103 12.8636 79.7053 12.9102 80.0891 13.0079L79.784 14.6995Z"
                fill="#6161FF"
            />
            <path
                d="M84.791 16.2998C87.0351 16.2998 88.7286 18.0233 88.7286 20.1583C88.7286 22.2932 87.0351 24.0001 84.791 24.0001C82.5469 24.0001 80.852 22.2932 80.852 20.1583C80.852 18.0233 82.5469 16.2998 84.791 16.2998ZM84.791 22.1699C85.9505 22.1699 86.7438 21.2708 86.7438 20.1583C86.7438 19.0458 85.9505 18.13 84.791 18.13C83.6315 18.13 82.8367 19.0444 82.8367 20.1583C82.8367 21.2722 83.6343 22.1672 84.791 22.1672V22.1699Z"
                fill="#6161FF"
            />
            <path
                d="M94.9116 18.2062C94.677 18.1495 94.4359 18.1239 94.1946 18.13C93.3236 18.13 92.637 18.7548 92.3915 19.1968V22.9375C92.3912 23.1786 92.295 23.4098 92.1241 23.5802C91.9533 23.7506 91.7217 23.8463 91.4803 23.8463H90.4068V17.3624C90.4068 17.121 90.5028 16.8895 90.6737 16.7188C90.8446 16.5481 91.0763 16.4522 91.318 16.4522H92.3153V17.2003C92.7577 16.6462 93.5635 16.2998 94.4678 16.2998C94.6929 16.3014 94.9174 16.3218 95.1391 16.3608L94.9116 18.2062Z"
                fill="#6161FF"
            />
            <path
                d="M96.6717 23.8476V17.3624C96.6717 17.2428 96.6953 17.1245 96.7411 17.014C96.7869 16.9036 96.854 16.8033 96.9386 16.7187C97.0233 16.6342 97.1237 16.5672 97.2343 16.5214C97.3448 16.4757 97.4633 16.4521 97.583 16.4521H98.5802V17.041C99.1299 16.5509 99.8435 16.2844 100.58 16.2942C101.054 16.2938 101.522 16.3978 101.951 16.5987C102.38 16.7996 102.76 17.0925 103.063 17.4566C103.4 17.0976 103.806 16.8101 104.257 16.6114C104.708 16.4126 105.194 16.3066 105.687 16.2997C106.18 16.2965 106.668 16.4013 107.117 16.607C107.565 16.8126 107.963 17.114 108.282 17.4898C108.755 18.069 109.03 18.831 109.03 19.9296V22.9388C109.029 23.18 108.933 23.4111 108.762 23.5815C108.591 23.7519 108.36 23.8476 108.118 23.8476H107.046V19.8534C107.046 19.1815 106.908 18.8462 106.68 18.5871C106.537 18.438 106.365 18.3203 106.174 18.2416C105.983 18.1629 105.777 18.1248 105.571 18.1299C104.715 18.1299 104.045 18.7243 103.784 19.0997C103.822 19.3729 103.842 19.6483 103.845 19.9241V22.9332C103.845 23.1744 103.749 23.4056 103.578 23.576C103.407 23.7464 103.175 23.8421 102.934 23.8421H101.86V19.8534C101.86 19.1815 101.722 18.8462 101.494 18.5871C101.351 18.438 101.179 18.3203 100.988 18.2416C100.797 18.1629 100.591 18.1248 100.385 18.1299C99.5442 18.1299 98.9339 18.6938 98.6593 19.0443V22.9388C98.6593 23.1798 98.5634 23.411 98.3928 23.5814C98.2221 23.7519 97.9907 23.8476 97.7494 23.8476H96.6717Z"
                fill="#6161FF"
            />
            <path
                d="M56.0655 4.61499C57.8491 4.61499 59.1972 5.98519 59.1972 7.68236C59.1972 9.37952 57.8491 10.7386 56.0655 10.7386C54.2818 10.7386 52.9337 9.38091 52.9337 7.68236C52.9337 5.98381 54.2804 4.61499 56.0655 4.61499ZM56.0655 9.28393C56.9878 9.28393 57.6189 8.56765 57.6189 7.68236C57.6189 6.79706 56.9878 6.0697 56.0655 6.0697C55.1431 6.0697 54.5079 6.79844 54.5079 7.68236C54.5079 8.56627 55.1431 9.28254 56.0655 9.28254V9.28393Z"
                fill="#6161FF"
            />
            <path
                d="M60.5329 10.6167V5.62915C60.5333 5.39129 60.6281 5.16329 60.7966 4.99523C60.9651 4.82716 61.1935 4.73277 61.4317 4.73277H62.0502V5.20936C62.5185 4.82321 63.1074 4.61294 63.7146 4.61501C64.1031 4.61402 64.487 4.69826 64.8393 4.86177C65.1916 5.02527 65.5037 5.26406 65.7535 5.56126C66.1418 6.02123 66.361 6.61558 66.361 7.6228V9.72035C66.361 9.95785 66.2666 10.1856 66.0986 10.3537C65.9306 10.5218 65.7027 10.6164 65.465 10.6167H64.7854V7.50088C64.7854 6.91899 64.6467 6.61558 64.4331 6.39253C64.1931 6.17056 63.875 6.0525 63.5482 6.06418C63.2702 6.07212 62.9975 6.14162 62.7498 6.26764C62.502 6.39367 62.2853 6.57308 62.1154 6.79292V9.72035C62.1154 9.83819 62.0922 9.95486 62.047 10.0637C62.0018 10.1725 61.9356 10.2714 61.8521 10.3547C61.7686 10.4379 61.6695 10.5039 61.5605 10.5489C61.4515 10.5939 61.3346 10.6169 61.2167 10.6167H60.5329Z"
                fill="#6161FF"
            />
            <path
                d="M72.2472 10.6165C71.9261 10.6993 71.5956 10.7403 71.2639 10.7384C70.318 10.7384 69.6259 10.4226 69.225 9.88917C68.9213 9.49016 68.7937 8.96785 68.7937 8.13104V6.06951H67.1487V5.63309C67.1491 5.39523 67.244 5.16724 67.4125 4.99917C67.581 4.83111 67.8094 4.73671 68.0475 4.73671H68.7937V3.99689C68.7937 3.75878 68.8884 3.53043 69.0569 3.36207C69.2255 3.19371 69.4541 3.09912 69.6924 3.09912H70.3721V4.73671H72.3138V5.17313C72.3138 5.41086 72.2193 5.63886 72.051 5.80696C71.8827 5.97507 71.6544 6.06951 71.4164 6.06951H70.3721V8.31254C70.3721 8.67552 70.4331 8.89442 70.5662 9.05098C70.7243 9.23385 70.9823 9.34192 71.3679 9.34192C71.5832 9.33972 71.798 9.3198 72.0101 9.28235L72.2472 10.6165Z"
                fill="#6161FF"
            />
            <path
                d="M78.6578 9.68432C78.0864 10.3632 77.1529 10.7386 76.2417 10.7386C74.4816 10.7386 73.1584 9.52638 73.1584 7.68236C73.1584 6.04615 74.268 4.61499 76.0239 4.61499C77.6495 4.61499 78.7979 5.98519 78.7979 7.62278C78.7985 7.77316 78.7865 7.92332 78.7618 8.07166H74.6883C74.7259 8.46342 74.912 8.826 75.2085 9.08512C75.5051 9.34424 75.8896 9.4803 76.2833 9.46542C76.5632 9.45767 76.8387 9.39355 77.0933 9.2769C77.3478 9.16025 77.5761 8.99347 77.7646 8.78655L78.6578 9.68432ZM77.189 6.91344C77.1541 6.62922 77.0164 6.36755 76.8019 6.17769C76.5873 5.98784 76.3106 5.88291 76.0239 5.88267C75.3679 5.88267 74.8339 6.38004 74.7604 6.91344H77.189Z"
                fill="#6161FF"
            />
            <path
                d="M82.3 6.41043L83.465 4.73682H85.2681L83.2958 7.48138L85.5524 10.6208H83.6842L82.2972 8.63268L80.9102 10.6208H79.0461L81.3041 7.48138L79.3263 4.73682H81.1294L82.3 6.41043Z"
                fill="#6161FF"
            />
            <path
                d="M91.0157 10.6165C90.6945 10.6993 90.364 10.7402 90.0323 10.7384C89.085 10.7384 88.3943 10.4226 87.9935 9.88917C87.6897 9.49016 87.5566 8.96785 87.5566 8.13104V6.06951H85.9172V5.63309C85.9176 5.39523 86.0124 5.16724 86.1809 4.99917C86.3494 4.83111 86.5778 4.73671 86.816 4.73671H87.5566V3.99689C87.5566 3.75902 87.6511 3.53088 87.8194 3.36256C87.9876 3.19424 88.2158 3.09949 88.454 3.09912H89.1336V4.73671H91.0753V5.17313C91.0753 5.29096 91.0521 5.40763 91.0069 5.51648C90.9617 5.62532 90.8955 5.7242 90.812 5.80745C90.7285 5.89071 90.6295 5.95671 90.5204 6.00167C90.4114 6.04664 90.2945 6.06969 90.1766 6.06951H89.1322V8.31254C89.1322 8.67552 89.1932 8.89442 89.3264 9.05098C89.4845 9.23386 89.7425 9.34192 90.128 9.34192C90.3438 9.33964 90.5591 9.31971 90.7716 9.28235L91.0157 10.6165Z"
                fill="#6161FF"
            />
            <path
                d="M98.178 4.73683V9.72442C98.178 9.84225 98.1547 9.95893 98.1095 10.0678C98.0643 10.1766 97.9981 10.2755 97.9146 10.3587C97.8312 10.442 97.7321 10.508 97.623 10.553C97.514 10.5979 97.3972 10.621 97.2792 10.6208H96.6606V10.1442C96.2242 10.5344 95.6568 10.7465 95.0712 10.7386C94.6874 10.7401 94.3081 10.656 93.9611 10.4923C93.6141 10.3285 93.3081 10.0894 93.0656 9.79231C92.6883 9.33234 92.4706 8.72552 92.4706 7.85269V5.63599C92.4706 5.51827 92.4938 5.40171 92.5389 5.29295C92.584 5.1842 92.6501 5.08538 92.7334 5.00215C92.8168 4.91891 92.9157 4.85288 93.0246 4.80784C93.1334 4.76279 93.2501 4.7396 93.368 4.7396H94.0476V7.91365C94.0476 8.44705 94.1571 8.71305 94.3402 8.91948C94.4545 9.03874 94.5925 9.13269 94.7454 9.19522C94.8984 9.25776 95.0627 9.28748 95.2279 9.28247C95.8964 9.28247 96.3818 8.83358 96.5996 8.55511V5.63321C96.5998 5.51497 96.6233 5.39792 96.6689 5.2888C96.7145 5.17967 96.7813 5.08062 96.8653 4.99733C96.9493 4.91404 97.049 4.84815 97.1586 4.80344C97.2682 4.75874 97.3855 4.7361 97.5039 4.73683H98.178Z"
                fill="#6161FF"
            />
            <path
                d="M104.745 10.6167H104.127V10.1442C103.672 10.5355 103.089 10.7469 102.489 10.7386C102.069 10.7395 101.655 10.6512 101.272 10.4797C100.889 10.3082 100.548 10.0574 100.27 9.74385C99.7794 9.16891 99.5122 8.4374 99.5164 7.68231C99.5164 5.94912 100.803 4.61494 102.405 4.61494C103.032 4.60736 103.641 4.81747 104.129 5.2093C104.129 5.084 104.179 4.96384 104.268 4.87524C104.357 4.78664 104.477 4.73686 104.602 4.73686H105.647V9.72445C105.646 9.84217 105.623 9.95862 105.577 10.0672C105.531 10.1757 105.465 10.2742 105.381 10.3571C105.297 10.4399 105.198 10.5055 105.089 10.55C104.98 10.5946 104.863 10.6172 104.745 10.6167ZM104.066 6.7984C103.897 6.5713 103.678 6.387 103.425 6.2604C103.172 6.1338 102.892 6.06846 102.609 6.06966C101.687 6.06966 101.092 6.83442 101.092 7.68231C101.092 8.60502 101.759 9.28388 102.645 9.28388C102.923 9.28608 103.198 9.22108 103.445 9.09442C103.692 8.96776 103.905 8.78322 104.066 8.55653V6.7984Z"
                fill="#6161FF"
            />
            <path
                d="M108.145 10.6165H107.465V2.90932C107.465 2.67146 107.56 2.44346 107.729 2.2754C107.897 2.10733 108.126 2.01294 108.364 2.01294H109.043V9.72015C109.043 9.83798 109.02 9.95466 108.975 10.0635C108.93 10.1723 108.864 10.2712 108.78 10.3545C108.697 10.4377 108.598 10.5037 108.489 10.5487C108.38 10.5937 108.263 10.6167 108.145 10.6165Z"
                fill="#6161FF"
            />
            <path
                d="M45.831 6.39512C45.8267 6.77641 45.8989 7.1547 46.0433 7.50766C46.1877 7.86063 46.4015 8.18113 46.6719 8.45024C46.9424 8.71935 47.264 8.93163 47.6179 9.07455C47.9718 9.21747 48.3508 9.28814 48.7325 9.28238C49.1973 9.28783 49.657 9.18425 50.0744 8.97996C50.4919 8.77567 50.8556 8.47637 51.1361 8.10614L52.2651 9.11197C51.512 10.0707 50.2499 10.7371 48.6965 10.7371C46.0473 10.7371 44.193 8.80856 44.193 6.37295C44.193 3.93734 46.0252 2.00881 48.6715 2.00881C49.8264 1.99132 50.9422 2.4265 51.7797 3.22107L50.7228 4.33635C50.4424 4.04943 50.1055 3.82373 49.7333 3.6735C49.3611 3.52326 48.9618 3.45177 48.5605 3.46352C47.0182 3.51617 45.8199 4.84896 45.831 6.39512Z"
                fill="#6161FF"
            />
        </svg>
    )
}

export default Logo
