import React, { FC, useRef, useState } from "react"
import clsx from "clsx"

interface Props {
    color?: string
    size?: number
    strokeWidth?: number
    className?: string
}

const Pause: FC<Props> = ({ color, size, strokeWidth, className }) => {
    const svgRef = useRef<SVGSVGElement>(null)
    const [fill, setFill] = useState<string | undefined>(undefined)

    const onMouseOver = () => setFill(color)

    const onMouseOut = () => setFill(undefined)

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={!size ? "24" : size}
            height={!size ? "24" : size}
            viewBox="0 0 24 24"
            fill={!fill ? "none" : fill}
            color={color}
            stroke="currentColor"
            strokeWidth={!strokeWidth ? "1" : strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            className={clsx("feather feather-pause", className)}
            ref={svgRef}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <rect x="6" y="4" width="4" height="16" />
            <rect x="14" y="4" width="4" height="16" />
        </svg>
    )
}

export default Pause
