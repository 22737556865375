import React, { FC, MouseEvent } from "react"
import { Box, Grid, makeStyles, Typography } from "@material-ui/core"
import { SummaryImage as ISummaryImage } from "../../types/types"
import SummaryImage from "./SummaryImage"

const useStyles = makeStyles({
    summaryImages: {},
    bold: {
        fontWeight: 600
    }
})

interface Props {
    mediaFolder: string
    summaryImages: ISummaryImage[]
    onSelectSummaryImage: (summaryImage: ISummaryImage) => (event: MouseEvent<HTMLButtonElement>) => void
}

const ImageSummary: FC<Props> = ({ mediaFolder, summaryImages, onSelectSummaryImage }) => {
    const classes = useStyles()
    return (
        <>
            <Box marginBottom={1}>
                <Typography variant="h6">
                    <span className={classes.bold}>Image Summary</span>
                    {` (${summaryImages?.length})`}
                </Typography>
            </Box>
            <Grid container={true} spacing={1}>
                {summaryImages.map(image => (
                    <Grid item={true} key={image.filename}>
                        <SummaryImage mediaFolder={mediaFolder} summaryImage={image} onClick={onSelectSummaryImage} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default ImageSummary
