import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core"
import { SummaryImage as ISummaryImage } from "../../types/types"
import { useApiService } from "../../providers/ApiServiceProvider"
import { summaryImagesFolder, uploadBucketOutputFolder } from "../../constants"

const useStyles = makeStyles({
    summaryImage: {
        backgroundColor: "transparent",
        padding: 0,
        margin: 0,
        border: 0,
        outline: 0,
        borderRadius: 0,
        cursor: "pointer",
        height: "fit-content",
        display: "flex",
        alignItems: "center"
    },
    image: {
        width: 161
    },
    placeholder: {
        width: 161,
        height: 92,
        backgroundColor: "#D4D3D3"
    }
})

interface Props {
    summaryImage: ISummaryImage
    mediaFolder: string
    onClick: (summaryImage: ISummaryImage) => (event: MouseEvent<HTMLButtonElement>) => void
}

const SummaryImage: FC<Props> = ({ summaryImage, mediaFolder, onClick }) => {
    const classes = useStyles()
    const ApiService = useApiService()
    const [url, setUrl] = useState<string | undefined>(undefined)

    const getSummaryImageUrl = useCallback(async () => {
        const urlResponse = await ApiService.getReadableSignedUrl(
            `${uploadBucketOutputFolder}/${mediaFolder}/${summaryImagesFolder}/${summaryImage.filename}`,
            "image/jpeg"
        )
        if (!urlResponse) {
            return
        }
        setUrl(urlResponse.data)
    }, [ApiService, mediaFolder, summaryImage.filename])

    useEffect(() => {
        if (!url) {
            getSummaryImageUrl()
        }
    })

    return (
        <button className={classes.summaryImage} onClick={onClick(summaryImage)}>
            {url ? (
                <img src={url} alt="summary video frame" className={classes.image} />
            ) : (
                <div className={classes.placeholder} />
            )}
        </button>
    )
}

export default SummaryImage
