import { Box, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import React, { FC, useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import config from "../../config.json"
import { getBucketPath, useGetMediaArtifact } from "../../graphql/media"
import { tryAgain } from "../../helpers"
import { useApiService } from "../../providers/ApiServiceProvider"
import { useGetLoginData, useGetResultsVideo } from "../../store"

const useStyles = makeStyles(theme => ({
    demo: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            display: "inline-block"
        }
    },
    selectSection: {
        marginLeft: 40,
        marginTop: 40,
        width: 480,
        [theme.breakpoints.down("sm")]: {
            width: 300
        }
    },
    dataSection: {
        paddingRight: 40,
        paddingLeft: 40,
        paddingTop: 40,
        paddingBottom: 40,
        width: "100%",
        overflowY: "scroll",
        height: "calc(100vh - 64px)",
        flex: 1
    },
    data: {
        whiteSpace: "pre-wrap",
        fontSize: 14
    },
    tryAgain: {
        textDecoration: "underline",
        cursor: "pointer",
        color: "#ffffff"
    }
}))

const Admin: FC = () => {
    const classes = useStyles()
    const loginData = useGetLoginData()
    const resultsMedia = useGetResultsVideo()
    const [error, setError] = useState<boolean>(false)
    const [mediaArtifact, setMediaArtifact] = useState<Object | undefined>(undefined)
    const [latestArtifactUrl, setLatestArtifactUrl] = useState<string | undefined>(undefined)
    const [nerUrl, setNerUrl] = useState<string | undefined>(undefined)
    const [objectLabelsUrl, setObjectLabelsUrl] = useState<string | undefined>(undefined)
    const [transcriptionUrl, setTranscriptionUrl] = useState<string | undefined>(undefined)
    const [metricsUrl, setMetricsUrl] = useState<string | undefined>(undefined)
    const [storageUrl, setStorageUrl] = useState<string | undefined>(undefined)
    const history = useHistory()
    const { uuid } = useParams<{ uuid: string }>()
    const mediaArtifactData = useGetMediaArtifact({ variables: { media_uuid: uuid } })
    const theme = useTheme()
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
    const ApiService = useApiService()

    const getUrl = useCallback(
        async (url: string, set: (data: any) => void) => {
            if (!resultsMedia) {
                return
            }
            const readableSignedUrlResponse = await ApiService.getReadableSignedUrl(url, resultsMedia.media.type ?? "")
            if (!readableSignedUrlResponse) {
                return
            }
            set(readableSignedUrlResponse.data)
        },
        [ApiService, resultsMedia]
    )

    useEffect(() => {
        if (mediaArtifactData.error) {
            setError(true)
            return
        }

        if (resultsMedia) {
            if (!resultsMedia.media.url) {
                return
            }
            const bucketPath = getBucketPath(resultsMedia.media.url!)
            if (!latestArtifactUrl) {
                getUrl(`${bucketPath}/latest_artifact.json`, setLatestArtifactUrl)
            }
            if (!nerUrl) {
                getUrl(`${bucketPath}/google_ner.json`, setNerUrl)
            }
            if (!objectLabelsUrl) {
                getUrl(`${bucketPath}/object_labeling.json`, setObjectLabelsUrl)
            }
            if (!transcriptionUrl) {
                getUrl(`${bucketPath}/io_video_transcribe_output.json`, setTranscriptionUrl)
            }
            if (!metricsUrl) {
                getUrl(`${bucketPath}/overall_metrics.json`, setMetricsUrl)
            }
            if (!storageUrl) {
                setStorageUrl(
                    `https://console.cloud.google.com/storage/browser/${config.MEDIA_BUCKET_NAME}/${bucketPath}` +
                        `?project=${config.PROJECT_ID}` +
                        "&pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&prefix=&forceOnObjectsSortingFiltering=false"
                )
            }
        } else if (uuid) {
            console.warn(`TODO o resultsMedia but have a uuid so should fetch media...`)
        }

        if (mediaArtifactData.data) {
            const data = mediaArtifactData.data
            if (data.latest_media_artifact && data.latest_media_artifact.length > 0) {
                const latest_media_artifact = data.latest_media_artifact[0]
                if (latest_media_artifact.artifact_json) {
                    setMediaArtifact(mediaArtifactData.data?.latest_media_artifact[0].artifact_json)
                }
            } else {
                setMediaArtifact("NOT FOUND")
            }
        }
    }, [
        getUrl,
        history,
        latestArtifactUrl,
        loginData,
        mediaArtifactData.data,
        mediaArtifactData.error,
        nerUrl,
        objectLabelsUrl,
        resultsMedia,
        storageUrl,
        transcriptionUrl,
        metricsUrl,
        uuid
    ])

    return (
        <div className={classes.demo}>
            {!error ? (
                <>
                    <section className={classes.selectSection}>
                        <Box marginTop={2}>
                            <video src={resultsMedia?.mediaUrl} controls={true} width={isSmDown ? 300 : 480} />
                            <Typography variant="h4">{resultsMedia?.media.title}</Typography>
                            <Typography variant="subtitle1">{resultsMedia?.media.uuid}</Typography>
                            <Typography variant="subtitle1">Last Updated: {resultsMedia?.media.updated_at}</Typography>
                            {storageUrl && (
                                <Box marginBottom={1}>
                                    <a href={storageUrl} target="_blank" rel="noopener noreferrer">
                                        Storage Bucket
                                    </a>
                                </Box>
                            )}
                            {latestArtifactUrl && (
                                <Box marginBottom={1}>
                                    <a href={latestArtifactUrl} target="_blank" rel="noopener noreferrer">
                                        Latest Artifact
                                    </a>
                                </Box>
                            )}
                            {nerUrl && (
                                <Box marginBottom={1}>
                                    <a href={nerUrl} target="_blank" rel="noopener noreferrer">
                                        NER Results
                                    </a>
                                </Box>
                            )}
                            {objectLabelsUrl && (
                                <Box marginBottom={1}>
                                    <a href={objectLabelsUrl} target="_blank" rel="noopener noreferrer">
                                        Object Labels
                                    </a>
                                </Box>
                            )}
                            {transcriptionUrl && (
                                <Box marginBottom={1}>
                                    <a href={transcriptionUrl} target="_blank" rel="noopener noreferrer">
                                        Transcription Results
                                    </a>
                                </Box>
                            )}
                            {metricsUrl && (
                                <Box marginBottom={1}>
                                    <a href={metricsUrl} target="_blank" rel="noopener noreferrer">
                                        Overall Metrics
                                    </a>
                                </Box>
                            )}
                        </Box>
                    </section>
                    <section className={classes.dataSection}>
                        <pre>{JSON.stringify(mediaArtifact, null, 2)}</pre>
                    </section>
                </>
            ) : (
                <p>
                    {"Sorry, there was a problem, "}
                    <span className={classes.tryAgain} onClick={tryAgain}>
                        please try again.
                    </span>
                </p>
            )}
        </div>
    )
}

export default Admin
