import React, { ChangeEvent, FC } from "react"
import { makeStyles } from "@material-ui/core"
import { Search } from "react-feather"

const useStyles = makeStyles({
    inputContainer: {
        width: 724,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflow: "visible"
    },
    input: {
        width: "100%",
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        borderRadius: 0,
        height: 40,
        fontFamily: "Work Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "20px",
        color: "#2F232D",
        "&::placeholder": {
            fontFamily: "Work Sans, sans-serif",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: "20px",
            color: "#2F232D"
        },
        "&:focus": {
            outline: "initial"
        }
    },
    icon: {
        marginLeft: -44
    }
})

interface Props {
    placeholder: string
    setSearchQuery: (text: string) => void
}

const SearchInput: FC<Props> = ({ placeholder, setSearchQuery }) => {
    const classes = useStyles()

    const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value.toLowerCase())

    return (
        <div className={classes.inputContainer}>
            <input placeholder={placeholder} className={classes.input} onChange={onChange} />
            <Search className={classes.icon} color="#2F232D" size={20} strokeWidth={2} />
        </div>
    )
}

export default SearchInput
