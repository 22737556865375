import { CircularProgress, Fade, Grow, LinearProgress, makeStyles, Slider, Typography } from "@material-ui/core"
import clsx from "clsx"
import React, { FC, RefObject, useEffect, useState } from "react"
import { VideoWidth } from "../../constants"
import { getTimeFromSeconds } from "../../helpers"
import { useGetMediaImages, useGetResultsVideo } from "../../store"
import { Frame } from "../../types/types"
import Pause from "./icons/Pause"
import Play from "./icons/Play"
import Volume0 from "./icons/Volume0"
import Volume1 from "./icons/Volume1"
import Volume2 from "./icons/Volume2"
import VolumeX from "./icons/VolumeX"

const useStyles = makeStyles({
    video: {
        position: "relative"
    },
    canvas: {
        position: "absolute",
        top: 0,
        pointerEvents: "none"
    },
    controlsContainer: ({ height }: { height: number }) => ({
        position: "absolute",
        top: 0,
        background: "linear-gradient(180deg, rgba(47, 35, 45, 0) 83.02%, rgba(38, 26, 36, 0.8) 100%)",
        height,
        width: VideoWidth,
        display: "flex"
    }),
    controls: {
        height: 56,
        width: 561,
        marginLeft: 32,
        marginRight: 32,
        paddingTop: 16,
        alignSelf: "flex-end"
    },
    controlsButtons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 10
    },
    leftControls: {
        display: "flex",
        alignItems: "center"
    },
    rightControls: {
        display: "flex",
        alignItems: "center"
    },
    controlsText: {
        color: "#F9F9FB"
    },
    progressRoot: {
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
        height: 2
    },
    progressBar: {
        backgroundColor: "#73B3FF",
        borderRadius: 12
    },
    startTime: {
        marginRight: 16,
        width: 32
    },
    endTime: {
        marginLeft: 16,
        width: 32,
        textAlign: "right"
    },
    button: {
        padding: 0,
        border: 0,
        margin: 0,
        outline: 0,
        backgroundColor: "transparent",
        cursor: "pointer",
        height: 16
    },
    volume: {
        display: "flex",
        alignItems: "center"
    },
    volumeSlider: {
        marginRight: 8,
        width: 56,
        transformOrigin: "right"
    },
    volumeSliderRoot: {
        backgroundColor: "#FFFFFF",
        height: 2,
        padding: 0
    },
    volumeSliderRail: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #D4D3D3",
        height: 2,
        borderRadius: 12
    },
    volumeSliderTrack: {
        backgroundColor: "#73B3FF",
        height: 2,
        borderRadius: 12
    },
    volumeSliderThumb: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(54, 53, 69, 0.15)",
        border: "1px solid #73B3FF",
        top: 2,
        height: 8,
        width: 8,
        "&:hover": {
            backgroundColor: "#ECF4FE"
        },
        "&:active": {
            backgroundColor: "#73B3FF"
        }
    },
    seekBar: {
        display: "flex"
    },
    loadingCircle: {
        color: "#73B3FF"
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
})

interface VideoData {
    progress: number
    currentTime: number
    duration: number
}

interface Props {
    videoRef: RefObject<HTMLVideoElement>
    canvasRef: RefObject<HTMLCanvasElement>
    frame?: Frame
    onSelectFrame: (frame: Frame) => void
}

const Video: FC<Props> = ({ videoRef, canvasRef, frame, onSelectFrame }) => {
    const [height, setHeight] = useState<number>(0)
    const [volume, setVolume] = useState<number>(0)
    const [videoData, setVideoData] = useState<VideoData>({ progress: 0, currentTime: 0, duration: 0 })
    const [muted, setMuted] = useState<boolean>(false)
    const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false)
    const [showControls, setShowControls] = useState<boolean>(true)
    const [showSeekBar, setShowSeekBar] = useState<boolean>(false)
    const resultsMedia = useGetResultsVideo()
    const mediaImages = useGetMediaImages()
    const classes = useStyles({ height })

    useEffect(() => {
        if (videoRef.current) {
            const boundingRect = videoRef.current?.getBoundingClientRect()
            const videoHeight = boundingRect.bottom - boundingRect.y
            if (height === 0 || height !== videoHeight) {
                setHeight(videoHeight)
            }
            if (volume === 0) {
                setVolume(videoRef.current?.volume)
            }
        }
        if (frame && canvasRef.current && canvasRef.current.getContext) {
            const context = canvasRef.current.getContext("2d")
            if (!context) {
                return
            }
            const boundingRect = videoRef.current?.getBoundingClientRect()
            if (!boundingRect) {
                return
            }
            const videoWidth = boundingRect.right - boundingRect.x
            const videoHeight = boundingRect.bottom - boundingRect.y
            context.font = "14px Work Sans, sans-serif"
            context.fillStyle = "red"
            context.strokeStyle = "red"
            context.lineWidth = 4
            frame.objects.forEach(object => {
                if (!object || !object.bounding_box) {
                    return
                }
                context.beginPath()
                let originX = 0
                let originY = 0
                object.bounding_box.forEach((bounds, i) => {
                    if (i === 0) {
                        originX = bounds.x * videoWidth
                        originY = bounds.y * videoHeight
                        context.moveTo(bounds.x * videoWidth, bounds.y * videoHeight)
                    } else {
                        context.lineTo(bounds.x * videoWidth, bounds.y * videoHeight)
                    }
                })
                context.lineTo(originX, originY)
                context.stroke()
                const text = context.measureText(object.name)
                context.fillText(
                    object.name,
                    object.bounding_box[3].x * videoWidth +
                        ((object.bounding_box[2].x * videoWidth - object.bounding_box[3].x * videoWidth) / 2 -
                            text.width / 2),
                    object.bounding_box[3].y * videoHeight + 20
                )
            })
        }
        if (!frame && canvasRef.current && canvasRef.current.getContext) {
            const context = canvasRef.current.getContext("2d")
            context?.clearRect(0, 0, VideoWidth, height)
        }
    }, [height, frame, videoRef, videoData, volume, canvasRef])

    const clearBoundingBox = () => {
        const context = canvasRef.current?.getContext("2d")
        context?.clearRect(0, 0, VideoWidth, height)
    }

    const play = () => {
        if (frame) {
            onSelectFrame(frame)
        }
        setShowControls(false)
        videoRef.current?.play()
    }

    const pause = () => videoRef.current?.pause()

    const mute = () => {
        if (videoRef.current) {
            videoRef.current.muted = !muted
            setMuted(!muted)
        }
    }

    const onSeekChange = (event: any, newValue: number | number[]) => {
        if (frame) {
            onSelectFrame(frame)
        }
        if (videoRef.current) {
            videoRef.current.currentTime = newValue as number
        }
    }

    const onTimeUpdate = () =>
        setVideoData({
            ...videoData,
            progress: ((videoRef.current?.currentTime ?? 0) / (videoRef.current?.duration ?? 1)) * 100,
            currentTime: videoRef.current?.currentTime ?? 0
        })

    const onCanPlay = () =>
        setVideoData({
            ...videoData,
            duration: videoRef.current?.duration ?? 0
        })

    const onVolumeChange = (event: any, newValue: number | number[]) => {
        if (videoRef.current) {
            setVolume(newValue as number)
            videoRef.current.volume = newValue as number
        }
    }

    const onMouseOverVolume = () => setShowVolumeSlider(true)
    const onMouseOutVolume = () => setShowVolumeSlider(false)

    const onMouseOverControls = () => setShowControls(true)
    const onMouseOutControls = () => setShowControls(false)

    const onMouseOverSeekBar = () => setShowSeekBar(true)
    const onMouseOutSeekBar = () => setShowSeekBar(false)

    return (
        <>
            {resultsMedia?.mediaUrl ? (
                <div className={classes.video}>
                    <video
                        src={resultsMedia?.mediaUrl}
                        controls={false}
                        width={VideoWidth}
                        ref={videoRef}
                        onTimeUpdate={onTimeUpdate}
                        onCanPlay={onCanPlay}
                        onPlay={clearBoundingBox}
                        onPause={clearBoundingBox}
                        onSeeked={clearBoundingBox}
                        poster={resultsMedia?.media.uuid ? mediaImages?.[resultsMedia?.media.uuid] : undefined}
                    />
                    <div
                        className={classes.controlsContainer}
                        onMouseOver={onMouseOverControls}
                        onMouseOut={onMouseOutControls}
                    >
                        <Fade in={showControls} timeout={500}>
                            <div className={classes.controls}>
                                <div onMouseOver={onMouseOverSeekBar} onMouseOut={onMouseOutSeekBar}>
                                    {!showSeekBar ? (
                                        <LinearProgress
                                            classes={{
                                                root: classes.progressRoot,
                                                bar: classes.progressBar
                                            }}
                                            variant="determinate"
                                            value={videoData?.progress}
                                        />
                                    ) : (
                                        <Slider
                                            value={videoData?.currentTime}
                                            min={0}
                                            max={videoData?.duration}
                                            step={0.0001}
                                            className={classes.seekBar}
                                            onChange={onSeekChange}
                                            classes={{
                                                root: classes.volumeSliderRoot,
                                                rail: classes.volumeSliderRail,
                                                track: classes.volumeSliderTrack,
                                                thumb: classes.volumeSliderThumb
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={classes.controlsButtons}>
                                    <div className={classes.leftControls}>
                                        <Typography
                                            variant="overline"
                                            className={clsx(classes.controlsText, classes.startTime)}
                                        >
                                            {getTimeFromSeconds(videoData?.currentTime ?? 0)}
                                        </Typography>
                                        <button onClick={play} className={classes.button}>
                                            <Play color="#F9F9FB" strokeWidth={1} size={16} />
                                        </button>
                                        <button onClick={pause} className={classes.button}>
                                            <Pause color="#F9F9FB" strokeWidth={1} size={16} />
                                        </button>
                                    </div>
                                    <div className={classes.rightControls}>
                                        <div
                                            className={classes.volume}
                                            onMouseOver={onMouseOverVolume}
                                            onMouseOut={onMouseOutVolume}
                                        >
                                            <Grow in={showVolumeSlider} {...(showVolumeSlider ? { timeout: 500 } : {})}>
                                                <Slider
                                                    value={volume}
                                                    min={0}
                                                    max={1}
                                                    step={0.01}
                                                    className={classes.volumeSlider}
                                                    onChange={onVolumeChange}
                                                    classes={{
                                                        root: classes.volumeSliderRoot,
                                                        rail: classes.volumeSliderRail,
                                                        track: classes.volumeSliderTrack,
                                                        thumb: classes.volumeSliderThumb
                                                    }}
                                                />
                                            </Grow>
                                            <button onClick={mute} className={classes.button}>
                                                {muted ? (
                                                    <VolumeX color="#F9F9FB" strokeWidth={1} size={16} />
                                                ) : volume >= 0 && volume <= 0.33 ? (
                                                    <Volume0 color="#F9F9FB" strokeWidth={1} size={16} />
                                                ) : volume > 0.33 && volume <= 0.66 ? (
                                                    <Volume1 color="#F9F9FB" strokeWidth={1} size={16} />
                                                ) : volume > 0.66 && volume <= 1 ? (
                                                    <Volume2 color="#F9F9FB" strokeWidth={1} size={16} />
                                                ) : null}
                                            </button>
                                        </div>
                                        <Typography
                                            variant="overline"
                                            className={clsx(classes.controlsText, classes.endTime)}
                                        >
                                            {getTimeFromSeconds(videoData?.duration ?? 0)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <canvas ref={canvasRef} width={VideoWidth} height={height} className={classes.canvas} />
                </div>
            ) : (
                <section className={classes.center}>
                    <CircularProgress className={classes.loadingCircle} />
                </section>
            )}
        </>
    )
}

export default Video
